define("web-directory/services/engageanalytics", ["exports", "lodash", "ember-purecloud-components/mixins/logger", "web-directory/utils/dates"], function (_exports, _lodash, _logger, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TEAMS_LIMIT = void 0;
  var TEAMS_LIMIT = 100;
  _exports.TEAMS_LIMIT = TEAMS_LIMIT;

  function isValidInterval(interval, options) {
    var intervals = interval.split('/');
    var start = (0, _dates.default)(intervals[0]);
    var end = (0, _dates.default)(intervals[1]);
    var granularity;

    if (options) {
      granularity = options.granularity;
    }

    if (granularity) {
      if (granularity === 'P1D' || granularity === 'P1W') {
        var startUtcHours = start.utc().hours();
        var endUtcHours = end.utc().hours();

        if (startUtcHours !== endUtcHours) {
          return false;
        }
      }
    }

    if (end.minutes() === 0 || end.minutes() === 30) {
      return true;
    } else {
      return false;
    }
  }

  var OBSERVATIONS_QUEUE_URI = 'api/v2/analytics/queues/observations/query';
  var OBSERVATIONS_USERS_URI = 'api/v2/analytics/users/observations/query';
  var AGGREGATES_USERS_URI = 'api/v2/analytics/users/aggregates/query';
  var AGGREGATES_FLOWS_URI = 'api/v2/analytics/flows/aggregates/query';
  var AGGREGATES_CONVERSATIONS_URI = 'api/v2/analytics/conversations/aggregates/query';
  var DETAILS_CONVERSATIONS_URI = 'api/v2/analytics/conversations/details/query';
  var USERS_URI = 'api/v2/users/search';
  var TEAMS_URI = 'api/v2/teams/search';
  var EVAL_FORMS_URI = 'api/v2/quality/forms';
  var EVAL_PUBLISHED_FORMS_URI = 'api/v2/quality/publishedforms/evaluations';
  var SURVEY_FORMS_URI = 'api/v2/quality/forms/surveys';
  var SURVEY_PUBLISHED_FORMS_URI = 'api/v2/quality/publishedforms/surveys';
  var SURVEY_BULK_FORMS_URI = 'api/v2/quality/forms/surveys/bulk';
  var AGGREGATES_EVALUATIONS_URI = 'api/v2/analytics/evaluations/aggregates/query';
  var EVAL_QUERY_URI = 'api/v2/quality/evaluations/query';
  var AGENTS_ACTIVITY_QUERY_URI = 'api/v2/quality/agents/activity';
  var REPORTING_EXPORT_URI = 'api/v2/analytics/reporting/exports';
  var REPORTING_EXPORT_ALL_URI = 'api/v2/analytics/reporting/exports/all';
  var REPORTING_ALERTS_QUERY_URI = 'api/v2/alerting/alerts/query';
  var REPORTING_ALERTS_URI = 'api/v2/alerting/alerts';
  var INSIGHTS_URI = 'api/v2/analytics/reporting/settings/insights';
  var URI_MAP = {
    queue_observations: OBSERVATIONS_QUEUE_URI,
    user_observations: OBSERVATIONS_USERS_URI,
    user_aggregates: AGGREGATES_USERS_URI,
    conversation_aggregates: AGGREGATES_CONVERSATIONS_URI,
    conversation_details: DETAILS_CONVERSATIONS_URI,
    evaluations_aggregates: AGGREGATES_EVALUATIONS_URI,
    flow_aggregates: AGGREGATES_FLOWS_URI
  };

  var _default = Ember.Service.extend(_logger.default, {
    application: Ember.inject.service(),
    ajax: Ember.inject.service(),

    /**
     * This method builds and sends the payload object and version
     * to the apiCore and on success return a response object.
     *
     * @param {Object} payload query object
     * @return {Object} the response from the server
     */
    executeQuery: function executeQuery(endpointKey, interval, options) {
      var payload = Object.assign({}, {
        interval: interval
      }, options);

      if (!isValidInterval(interval, options)) {
        Ember.Logger.error("This interval (".concat(interval, ") you sent to the analytics service is invalid"));
      }

      var endpoint = URI_MAP[endpointKey];
      var uri = this.get('application').pcUri(endpoint);
      var request = this.get('ajax').post(uri, {
        data: payload
      });
      var promise = Ember.RSVP.Promise.cast(request);
      return promise;
    },

    /**
     * Builds filter object from the list of parameters and then calls the executeQuery method.
     * It takes the response from the payload query and calls generateAnalyticsData with the results array.
     *
     * @param  {Array}  idArr - Array of Ids
     * @param  {String} dataSource - The name of the data source to query
     * @param  {Object[]} dimensions - JSON array of the dimensions used to constrain
     *  the groupings, much like a SQL GROUPBY operation. Can be empty to group solely
     *  by time granularity.
     * @param  {String} interval - ISO8601 specified time interval, indicating the
     *  time range for the data requested
     *
     * @param  {Object} [options=undefined] - optional arguments
     * @param  {String} [options.granularity=undefined] - ISO8601 specified time
     *  period (eg. P1D is one day, PT1H is one hour, PT30M is thirty minutes).
     *  Omitting this property will have the analytics service return a default
     *  granularity of PT30M.
     * @param {String} [options.mediaType] - Setting this argument will determine the
     *  media type in which the returned metrics will represent (voice, web chat, etc.).
     *  Omitting this property will have the analytics service return metrics for the
     *  "voice" media type.
     *
     * @return {Promise} A Promise that returns an array of new AnalyticsData objects
     *  created from the server response.
     */
    execDisjunctionAggregateQuery: function execDisjunctionAggregateQuery(endpointKey, idArr, dimensions, interval, options) {
      var opts = Object.assign({}, options, {
        filter: {
          type: 'and',
          clauses: [{
            type: 'or',
            predicates: _lodash.default.map(idArr, function (id) {
              return {
                type: 'dimension',
                dimension: dimensions[0],
                value: id
              };
            })
          }]
        }
      });

      if (options && options.mediaType) {
        opts.filter.predicates = [{
          dimension: 'mediaType',
          value: options.mediaType
        }];
      }

      return this.executeQuery(endpointKey, interval, opts).then(function (response) {
        return response.results;
      });
    },

    /**
     * Builds the filter object and any other optional objects to be included in the payload query
     *
     * In depth documentation of the Analytics Service REST API can be found here:
     *
     * https://confluence.inin.com/display/Development/Analytics+API
     *
     * @param  {String} id             id of thing to query
     * @param  {Array}  dimensions     the dimension name
     * @param  {String} interval       the interval to query
     * @param  {Object} options        the options object
     * @return {Object}                A Promise that returns a new AnalyticsData object created
     *                                   with the server response.
     */
    execAggregateQuery: function execAggregateQuery(endpointKey, id, dimensions, interval, options) {
      var opts = Object.assign({}, options, {
        filter: {
          type: 'and',
          clauses: [{
            type: 'or',
            predicates: [{
              type: 'dimension',
              dimension: dimensions[0],
              value: id
            }]
          }]
        }
      });
      var mediaType;

      if (options && options.mediaType) {
        mediaType = options.mediaType;
        opts.filter.predicates = [{
          dimension: 'mediaType',
          value: mediaType
        }];
      }

      return this.executeQuery(endpointKey, interval, opts).then(function (response) {
        return response.results;
      });
    },
    getQueueObservations: function getQueueObservations(queueIds, interval, options) {
      return this.execDisjunctionAggregateQuery('queue_observations', queueIds, ['queueId'], interval, options);
    },

    /**
       @desc Retrieves conversation aggregates of metrics for an interval.
       @see https://developer.mypurecloud.com/api/rest/v2/analytics/index.html
       @param {object} options - Query options.
       @returns {promise} Promise resolves to conversation aggregate data.
     */
    getConversationAggregates: function getConversationAggregates(options) {
      return this.get('ajax').post(this.get('application').pcV2Uri(AGGREGATES_CONVERSATIONS_URI), {
        data: options
      });
    },
    execConversationQueryV6: function execConversationQueryV6(interval, options) {
      var payload = Object.assign({}, {
        interval: interval,
        order: 'asc',
        orderBy: 'conversationStart',
        paging: {
          pageSize: 25,
          pageNumber: 1
        }
      }, options);
      return this.get('ajax').post(this.get('application').pcUri(DETAILS_CONVERSATIONS_URI), {
        data: payload
      });
    },

    /**
       @desc Retrieves users for a list of participants.
       @see https://developer.mypurecloud.com/api/rest/v2/users/index.html
       @param {array} participants - List of participants.
       @param {boolean} includeInactive - True to include inactive users, false for only active
       @param {boolean} includeDeleted - True to include deleted users, false for only active
       @returns {promise} Promise resolves to user query results.
     */
    getUsers: function getUsers(participants) {
      var includeInactive = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var includeDeleted = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var expand = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
      var userOptions = ['active'];

      if (includeInactive) {
        userOptions.push('inactive');
      }

      if (includeDeleted) {
        userOptions.push('deleted');
      }

      var uniqueIds = _lodash.default.uniq(participants.filter(function (p) {
        return p.userId;
      }).map(function (p) {
        return p.userId;
      })); // API endpoint only supports 50 search criteria values at once
      // Need to break query up into multiple blocks if there are more than 50 IDs to search


      var query = [];

      if (userOptions.length > 1) {
        query.push({
          fields: ['state'],
          values: userOptions,
          type: 'EXACT'
        });
      }

      for (var i = 0; i < uniqueIds.length; i += 50) {
        query.push({
          fields: ['id'],
          values: uniqueIds.slice(i, i + 50),
          type: 'EXACT',
          operator: 'OR'
        });
      }

      return this.get('ajax').post(this.get('application').pcV2Uri(USERS_URI), {
        data: {
          pageSize: uniqueIds.length,
          pageNumber: 1,
          query: query,
          types: ['users'],
          expand: expand
        }
      });
    },

    /**
       @desc Retrieves users for a list of IDs.
       @see https://developer.mypurecloud.com/api/rest/v2/users/index.html
       @param {array} ids - List of user IDs.
       @param {boolean} includeInactive - True to include inactive users, false for only active
       @param {boolean} includeDeleted - True to include deleted users, false for only active
       @returns {promise} Promise resolves to user query results.
    */
    getUsersByIds: function getUsersByIds(ids) {
      var includeInactive = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var includeDeleted = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var expand = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
      return this.getUsers(ids.map(function (id) {
        return {
          userId: id
        };
      }), includeInactive, includeDeleted, expand);
    },
    getTeamsByIds: function getTeamsByIds(ids) {
      var _this = this;

      var uniqueIds = _lodash.default.uniq(ids.filter(function (id) {
        return id;
      }));

      var promises = [];
      var query = [{
        type: 'EXACT',
        fields: ['id'],
        values: uniqueIds
      }];

      if (uniqueIds && uniqueIds.length) {
        // If we have too many team Ids, break it up
        if (uniqueIds.length > TEAMS_LIMIT) {
          _lodash.default.chunk(uniqueIds, TEAMS_LIMIT).forEach(function (chunk) {
            query[0].values = chunk;
            promises.push(_this.get('ajax').post(_this.get('application').pcV2Uri(TEAMS_URI), {
              data: {
                pageSize: chunk.length,
                pageNumber: 1,
                query: query,
                types: ['teams']
              }
            }));
          });
        } else {
          // If we don't have too many, proceed as usual
          promises.push(this.get('ajax').post(this.get('application').pcV2Uri(TEAMS_URI), {
            data: {
              pageSize: uniqueIds.length,
              pageNumber: 1,
              query: query,
              types: ['teams']
            }
          }));
        }
      }

      return Ember.RSVP.allSettled(promises).then(function (results) {
        var data = {
          results: []
        };

        if (results && results.length) {
          results.forEach(function (result) {
            if (result.state === 'fulfilled' && result.value && result.value.results) {
              var value = result.value;
              data.results = _lodash.default.uniq(data.results.concat(result.value.results), function (team) {
                return team.id;
              }); // if we didn't search for a set of IDs, we are looking at a full list, so make sure we still track these values

              if (!(uniqueIds && uniqueIds.length)) {
                data.total = value.total;
                data.pageSize = value.pageSize;
                data.pageCount = value.pageCount;
                data.pageNumber = value.pageNumber;
              } else {
                // we have a selected set of queues, so set the total to the length of the entities array
                data.total = data.results.length;
              }
            }
          });
        }

        return data;
      });
    },

    /**
       @desc Retrieves users for a given name search string.
       @see https://developer.mypurecloud.com/api/rest/v2/users/index.html
       @param {string} name - Name search string.
       @param {object} options - Options for the query including pageSize and pageNumber.
       @param {boolean} includeInactive - True to include inactive users, false for only active
       @param {array} divisions - list of divisionIds
       @param {boolean} includeDeleted - True to include deleted users, false for only active
       @returns {promise} Promise resolves to user query results.
     */
    searchUsersByName: function searchUsersByName(name, options, includeInactive, divisions, includeDeleted) {
      var userOptions = ['active'];

      if (includeInactive) {
        userOptions.push('inactive');
      }

      if (includeDeleted) {
        userOptions.push('deleted');
      }

      var request = {
        data: {
          pageSize: options && options.pageSize || 50,
          pageNumber: options && options.pageNumber || 1,
          query: [{
            fields: ['name'],
            value: (name || '').toLowerCase(),
            type: 'CONTAINS'
          }, {
            fields: ['state'],
            values: userOptions,
            type: 'EXACT',
            operator: 'OR'
          }],
          types: ['users']
        }
      };

      if (!_lodash.default.isEmpty(divisions)) {
        request.data.query.push({
          type: 'EXACT',
          fields: ['divisionId'],
          values: divisions
        });
      }

      return this.get('ajax').post(this.get('application').pcV2Uri(USERS_URI), request);
    },

    /**
       @desc Retrieves a list of published quality evaluation forms.
       @see https://developer.mypurecloud.com/api/rest/v2/quality/index.html
       @param {object} options - Query options.
       @returns {promise} Promise resolves to evaluation form query results.
     */
    getEvaluationForms: function getEvaluationForms(options) {
      return this.get('ajax').ajaxGet(this.get('application').pcV2Uri(EVAL_PUBLISHED_FORMS_URI), {
        data: options
      });
    },

    /**
       @desc Retrieves a quality evaluation form by ID.
       @see https://developer.mypurecloud.com/api/rest/v2/quality/index.html
       @param {string} id - Form GUID.
       @returns {promise} Promise resolves to evaluation form query result.
    */
    getEvaluationForm: function getEvaluationForm(id) {
      return this.get('ajax').ajaxGet(this.get('application').pcV2Uri("".concat(EVAL_FORMS_URI, "/").concat(id)));
    },

    /**
       @desc Retrieves a quality evaluation form's versions.
       @see https://developer.mypurecloud.com/api/rest/v2/quality/index.html
       @param {string} id - Form GUID.
       @param {object} options - Query options.
       @returns {promise} Promise resolves to evaluation form versions query result.
    */
    getEvaluationFormVersions: function getEvaluationFormVersions(id, options) {
      return this.get('ajax').ajaxGet(this.get('application').pcV2Uri("".concat(EVAL_FORMS_URI, "/").concat(id, "/versions")), {
        data: options
      });
    },

    /**
       @desc Retrieves evaluations aggregates of metrics for an interval.
       @see https://developer.mypurecloud.com/api/rest/v2/analytics/index.html
       @param {object} options - Query options.
       @returns {promise} Promise resolves to evaluations aggregate data.
     */
    getEvaluationsAggregates: function getEvaluationsAggregates(options) {
      return this.get('ajax').post(this.get('application').pcV2Uri(AGGREGATES_EVALUATIONS_URI), {
        data: options
      });
    },

    /**
     @desc Retrieves a list of quality evaluations.
     @see https://developer.mypurecloud.com/api/rest/v2/quality/index.html
     @param {object} options - Query options.
     @returns {promise} Promise resolves to evaluation list results.
     */
    getEvaluations: function getEvaluations(options) {
      return this.get('ajax').ajaxGet(this.get('application').pcV2Uri(EVAL_QUERY_URI), {
        data: options
      });
    },

    /**
     @desc Retrieves user aggregates of metrics for an interval.
     @see https://developer.mypurecloud.com/api/rest/v2/analytics/index.html
     @param {object} options - Query options.
     @returns {promise} Promise resolves to user aggregate data.
     */
    getUserAggregates: function getUserAggregates(options) {
      return this.get('ajax').post(this.get('application').pcV2Uri(AGGREGATES_USERS_URI), {
        data: options
      });
    },

    /**
       @desc Retrieves a quality survey form's versions.
       @see https://developer.mypurecloud.com/api/rest/v2/quality/index.html
       @param {string} id - Form GUID.
       @param {object} options - Query options.
       @returns {promise} Promise resolves to survey form versions query result.
    */
    getSurveyFormVersions: function getSurveyFormVersions(id, options) {
      return this.get('ajax').ajaxGet(this.get('application').pcV2Uri("".concat(SURVEY_FORMS_URI, "/").concat(id, "/versions")), {
        data: options
      });
    },

    /**
       @desc Retrieves a list of published quality survey forms.
       @see https://developer.mypurecloud.com/api/rest/v2/quality/index.html
       @param {object} options - Query options.
       @returns {promise} Promise resolves to survey form query results.
     */
    getSurveyForms: function getSurveyForms(options) {
      return this.get('ajax').ajaxGet(this.get('application').pcV2Uri(SURVEY_PUBLISHED_FORMS_URI), {
        data: options
      });
    },

    /**
       @desc Retrieves a list of published quality survey forms.
       @see https://developer.mypurecloud.com/api/rest/v2/quality/index.html
       @param {array} ids - array of survey form IDs.
       @returns {promise} Promise resolves to survey form query results.
     */
    getSurveyFormsByIds: function getSurveyFormsByIds(ids) {
      var parameters = [];

      if (ids && ids.length) {
        ids.forEach(function (id) {
          parameters.push({
            id: id
          });
        });
      }

      return this.get('ajax').ajaxGet(this.get('application').pcV2Uri(SURVEY_BULK_FORMS_URI + '?' + parameters.map(function (parameter) {
        for (var prop in parameter) {
          return encodeURIComponent(prop) + '=' + encodeURIComponent(parameter[prop]);
        }
      }).join('&')));
    },

    /**
     @desc Retrieves a list of agent activities.
     @see https://developer.mypurecloud.com/api/rest/v2/quality/index.html
     @param {Integer} pageSize - page size
     @param {Integer} pageNumber - standard page number, 1 based
     @param {String} startTime - ISO formatted date start time
     @param {String} endTime - ISO formatted date end time
     @param {array} agentUserIds - list of agent user IDs
     @returns {promise} Promise resolves to agent activities results.
     */
    getAgentsActivities: function getAgentsActivities(pageSize, pageNumber, startTime, endTime, agentUserIds) {
      var parameters = [{
        pageSize: pageSize
      }, {
        pageNumber: pageNumber
      }, {
        startTime: startTime
      }, {
        endTime: endTime
      }];

      if (agentUserIds && agentUserIds.length) {
        agentUserIds.forEach(function (agentUserId) {
          parameters.push({
            agentUserId: agentUserId
          });
        });
      }

      return this.get('ajax').ajaxGet(this.get('application').pcV2Uri(AGENTS_ACTIVITY_QUERY_URI + '?' + parameters.map(function (parameter) {
        for (var prop in parameter) {
          return encodeURIComponent(prop) + '=' + encodeURIComponent(parameter[prop]);
        }
      }).join('&')));
    },
    scheduleExport: function scheduleExport(options) {
      return this.get('ajax').post(this.get('application').pcV2Uri(REPORTING_EXPORT_URI), {
        data: options
      });
    },
    getReportingExports: function getReportingExports() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return this.get('ajax').ajaxGet(this.get('application').pcV2Uri(REPORTING_EXPORT_URI), {
        data: options
      });
    },
    getAllReportingExports: function getAllReportingExports() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return this.get('ajax').ajaxGet(this.get('application').pcV2Uri(REPORTING_EXPORT_ALL_URI), {
        data: options
      });
    },
    updateExport: function updateExport(id, options) {
      return this.get('ajax').patch(this.get('application').pcV2Uri("".concat(REPORTING_EXPORT_URI, "/").concat(id)), {
        data: options
      });
    },
    updateExportRun: function updateExportRun(id, options, runId) {
      return this.get('ajax').patch(this.get('application').pcV2Uri("".concat(REPORTING_EXPORT_URI, "/").concat(id, "/history/").concat(runId)), {
        data: options
      });
    },
    deleteExport: function deleteExport(id) {
      return this.get('ajax').del(this.get('application').pcV2Uri("".concat(REPORTING_EXPORT_URI, "/").concat(id)));
    },
    deleteExportRun: function deleteExportRun(id, runId) {
      return this.get('ajax').del(this.get('application').pcV2Uri("".concat(REPORTING_EXPORT_URI, "/").concat(id, "/history/").concat(runId)));
    },
    runExport: function runExport(id, runId) {
      var uri = "".concat(REPORTING_EXPORT_URI, "/").concat(id, "/execute/?runId=").concat(runId);
      return this.get('ajax').post(this.get('application').pcV2Uri(uri));
    },
    cancelExport: function cancelExport(id) {
      return this.get('ajax').ajaxGet(this.get('application').pcV2Uri("".concat(REPORTING_EXPORT_URI, "/").concat(id, "/cancel")));
    },
    cancelExportRun: function cancelExportRun(id, runId) {
      return this.get('ajax').patch(this.get('application').pcV2Uri("".concat(REPORTING_EXPORT_URI, "/").concat(id, "/history/").concat(runId)), {
        data: {
          status: 'CANCELLING'
        }
      });
    },
    getReportingAlerts: function getReportingAlerts() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return this.get('ajax').post(this.get('application').pcV2Uri(REPORTING_ALERTS_QUERY_URI), {
        data: options
      });
    },
    updateAlert: function updateAlert(id, options) {
      return this.get('ajax').put(this.get('application').pcV2Uri("".concat(REPORTING_ALERTS_URI, "/").concat(id)), {
        data: options
      });
    },
    patchAlert: function patchAlert(id, options) {
      return this.get('ajax').patch(this.get('application').pcV2Uri("".concat(REPORTING_ALERTS_URI, "/").concat(id)), {
        data: options
      });
    },
    deleteAlert: function deleteAlert(id) {
      return this.get('ajax').del(this.get('application').pcV2Uri("".concat(REPORTING_ALERTS_URI, "/").concat(id)));
    },
    toggleAllAlertsReadState: function toggleAllAlertsReadState(unread) {
      var uri = this.get('application').pcV2Uri("".concat(REPORTING_ALERTS_URI, "/all"));
      var data = {
        type: 'Unread',
        unread: {
          state: unread
        }
      };
      return this.get('ajax').patch(uri, {
        data: data
      });
    },
    deleteAllAlertNotifications: function deleteAllAlertNotifications() {
      var uri = this.get('application').pcV2Uri("".concat(REPORTING_ALERTS_URI, "/all"));
      return this.get('ajax').delete(uri);
    },

    /**
     @desc Retrieves analytics insights settings.
     @see https://developer.mypurecloud.com/api/rest/v2/analytics/index.html
     @returns {promise} Promise resolves to insights settings object.
     */
    getInsightsSettings: function getInsightsSettings() {
      return this.get('ajax').ajaxGet(this.get('application').pcV2Uri(INSIGHTS_URI));
    },

    /**
       @desc Retrieves ivr flow aggregates of metrics for an interval.
       @see https://developer.mypurecloud.com/api/rest/v2/analytics/index.html
       @param {object} options - Query options.
       @returns {promise} Promise resolves to flow aggregate data.
     */
    getFlowAggregates: function getFlowAggregates(options) {
      return this.get('ajax').post(this.get('application').pcV2Uri(AGGREGATES_FLOWS_URI), {
        data: options
      });
    },

    /**
       @desc Renews expiring scheduled exports.
       @see https://developer.mypurecloud.com/api/rest/v2/analytics/index.html
       @returns {promise}
     */
    renewExpiringExports: function renewExpiringExports() {
      var uri = this.get('application').pcV2Uri("".concat(REPORTING_EXPORT_URI, "/schedule"));
      var data = {
        action: 'RenewExpiringSchedules'
      };
      return this.get('ajax').patch(uri, {
        dataType: 'text',
        data: data
      });
    }
  });

  _exports.default = _default;
});