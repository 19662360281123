define("web-directory/routes/chat-room", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    chat: Ember.inject.service(),
    sidebar: Ember.inject.service(),
    permissions: Ember.inject.service(),
    volt: Ember.inject.service(),
    model: function model(params) {
      /* check permissions here because they haven't loaded yet when init() runs */
      var panels = ['webrtc'];

      if (this.get('permissions.canViewBusinessChat')) {
        panels.push('conversations');
      }

      this.set('sidebar.allowedPopoutPanels', panels);
      return this.get('chat').getChatRoom(params.roomJid);
    },
    afterModel: function afterModel(model, transition) {
      model.set('isPopout', true);
      this.set('chat.popoutRoom', model);

      if (!this.get('permissions.canViewBusinessChat')) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          this.get('sidebar').closePanel('conversations');
        });
        return;
      }

      if (model.get('isSupervisorRoom')) {
        model.set('actionless', true);
        model.set('rosterless', true);
      }

      model.set('showingVideo', transition.queryParams.showingVideo === 'true');
      var voltVideoState = transition.queryParams.voltVideoState;
      Ember.run.scheduleOnce('afterRender', this, function () {
        this.get('sidebar').expandCommunicationSidebar();
        this.get('sidebar').openPanel('conversations');
        /* if we have a video state saved, parse it and pass it into the volt service */

        if (voltVideoState) {
          var voltLoadState = JSON.parse(window.sessionStorage.getItem(voltVideoState) || null);
          var chatRoomJid = model.get('jid');
          window.sessionStorage.removeItem(voltVideoState);
          var logObj = {
            voltVideoStateFromSession: voltLoadState,
            chatRoomJid: chatRoomJid
          };

          if (voltLoadState) {
            Ember.Logger.debug('using volt url from stored session state', logObj);
            this.get('volt').routeToRoomOnLoad(voltLoadState);
          } else {
            Ember.Logger.debug('voltVideoState param found on load, but param did not match current room jid. Not routing to volt route', logObj);
          }
        } // supervisor assistance room is a special place...


        if (model.get('isSupervisorRoom')) {
          this.get('chat').changeActiveRoom(model);
        }
      });
    }
  });

  _exports.default = _default;
});