define("web-directory/components/entity-picture-content/delete-pictures/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hijurF6u",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"explain\"],[13],[0,\"\\n    \"],[1,[33,[\"t\"],[\"images.delete.explanation\"],[[\"number\"],[[28,[\"number\"]]]]],false],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[11,\"div\",[]],[15,\"class\",\"images\"],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"uploads\"]]],null,{\"statements\":[[0,\"        \"],[11,\"div\",[]],[16,\"class\",[34,[\"image \",[33,[\"if\"],[[28,[\"upload\",\"selected\"]],\"selected\"],null]]]],[13],[0,\"\\n            \"],[1,[33,[\"uploaded-image\"],null,[[\"entity\",\"image\",\"isActive\",\"type\",\"canEdit\",\"editing\",\"size\",\"select\"],[[28,[\"entity\"]],[28,[\"upload\",\"image\"]],[28,[\"upload\",\"selected\"]],[28,[\"type\"]],true,true,96,[33,[\"action\"],[[28,[null]],\"selectImage\",[28,[\"upload\"]]],null]]]],false],[0,\"\\n        \"],[14],[0,\"\\n\"]],\"locals\":[\"upload\"]},null],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/entity-picture-content/delete-pictures/template.hbs"
    }
  });

  _exports.default = _default;
});