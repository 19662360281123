define("web-directory/routes/time-machine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Route = Ember.Route,
      inject = Ember.inject,
      RSVP = Ember.RSVP,
      Logger = Ember.Logger;

  var _default = Route.extend({
    queryParams: {
      targetRoute: {
        refreshModel: true
      }
    },
    application: inject.service(),
    intl: inject.service(),
    routeTitle: function routeTitle() {
      return this.get('intl').lookup('timemachine.title');
    },
    model: function model(params, transition) {
      var redirectUrl = window.location.origin || "".concat(window.location.protocol, "//").concat(window.location.host);
      redirectUrl += window.location.pathname;

      if (redirectUrl.charAt(redirectUrl.length - 1) !== '/') {
        redirectUrl += '/';
      }

      var hashRoute = '';
      var targetRoute = (params.targetRoute || '').trim();

      if (targetRoute) {
        if (targetRoute.charAt(0) !== '/') {
          targetRoute = '/' + targetRoute;
        }

        hashRoute = '#' + targetRoute;
      }

      redirectUrl += "".concat(params.buildTag, "/index.html");
      redirectUrl += window.location.search;
      redirectUrl += hashRoute;
      var runtime = this.get('application.runtime');

      if (runtime.local) {
        Logger.error('Timemachine is not available locally.  Use the Git Capacitor.');
        transition.abort();
        this.transitionTo('index');
      } else if (['prod', 'fedramp'].includes(runtime.env)) {
        return {
          redirectUrl: redirectUrl,
          buildTag: params.buildTag
        };
      } else {
        // Return a non-resolving promise to let the redirect complete
        return new RSVP.Promise(function () {
          window.location = redirectUrl;
        });
      }
    },
    actions: {
      confirm: function confirm() {
        var currModel = this.modelFor(this.routeName);

        if (currModel && currModel.redirectUrl) {
          window.location = currModel.redirectUrl;
        } else {
          this.set('application.errorState', {
            type: 'timeMachineFailure'
          });
          this.transitionTo('maintenance');
        }
      },
      cancel: function cancel() {
        this.transitionTo('index');
      }
    }
  });

  _exports.default = _default;
});