define("web-directory/instance-initializers/setup-error-handling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function initialize(instance) {
    var stashLogger = instance.lookup('service:stash-logger');
    Ember.Logger = stashLogger;

    Ember.onerror = function (error) {
      var status = error ? Ember.get(error, 'status') : null;

      if (status === 200) {
        return null;
      } else if (status === 418) {
        var applicationService = instance.lookup('service:application');
        var session = applicationService.get('session');

        if (session) {
          session.logout();
        }
      } // Sometimes Ember RSVP triggers the onError, and sometimes it doesn't,
      // so this will prevent the same error being logged multiple times


      if (error._logged === true || error === 'Request Cancelled') {
        return;
      }

      if (_typeof(error) === 'object') {
        error._logged = true;
      }

      return stashLogger.error("Global Error Caught: ".concat(error));
    };

    Ember.RSVP.on('error', function (error) {
      if (!error) {
        return; // https://github.com/simplabs/ember-simple-auth/issues/439
      }

      if (!instance.isDestroyed) {
        Ember.onerror(error);
      }
    });
    var nrOnError = window.onerror;

    window.onerror = function () {
      if (nrOnError) {
        nrOnError.apply(nrOnError, arguments);
      }

      stashLogger.error(arguments);
      return true; // stash logger will handle and log it
    };
  }

  var _default = {
    name: 'setup-error-handling',
    initialize: initialize
  };
  _exports.default = _default;
});