define("web-directory/helpers/pluralize-text", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    var count = params[1] || 1;
    var text = params[0];

    if (count !== 1) {
      return (0, _emberInflector.pluralize)(text);
    }

    return text;
  });

  _exports.default = _default;
});