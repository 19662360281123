define("web-directory/components/suggest-search-result/component", ["exports", "web-directory/utils/keystroke"], function (_exports, _keystroke) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tabindex: 0,
    classNames: ['entity'],
    classNameBindings: ['entity.groupType:group-entity:person-entity'],
    attributeBindings: ['tabindex'],
    entity: null,
    keyDown: function keyDown(event) {
      var key = (0, _keystroke.eventToString)(event);

      switch (key) {
        case 'ENTER':
          event.preventDefault();
          this.bubbleAction({
            action: 'collapse'
          });
          this.bubbleAction({
            action: 'transitionToRoute',
            actionContext: [this.get('entity.groupType') ? 'group.detail' : 'person.detail', this.get('entity.id')]
          });
          return;

        case 'C':
          event.preventDefault();
          this.bubbleAction({
            action: 'collapse'
          });
          this.bubbleAction({
            action: 'openChatRoom',
            actionContext: [this.get('entity.jid')]
          });
          return;

        case 'V':
          event.preventDefault();
          this.bubbleAction({
            action: 'collapse'
          });
          this.bubbleAction({
            action: 'openChatRoomWithVideo',
            actionContext: [this.get('entity.jid')]
          });
          return;

        case 'DOWN_ARROW':
          event.preventDefault();
          this.$().next().trigger('focus');
          return;

        case 'UP_ARROW':
          event.preventDefault();
          this.$().prev().trigger('focus');
          return;

        case 'ESCAPE':
          event.preventDefault();

          if (this.dismiss) {
            this.dismiss();
          }

      }
    }
  });

  _exports.default = _default;
});