define("web-directory/services/ajax", ["exports", "lodash", "ember-ajax/services/ajax", "ember-purecloud-components/mixins/logger"], function (_exports, _lodash, _ajax, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.REQUEST_TELEMETRY_APP_SUBSECTIONS = void 0;
  _exports.shouldRetryRequest = shouldRetryRequest;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var RateLimitPromise = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin, {
    isRateLimited: false,
    rateLimitResetTime: 0
  });
  /**
   * A symbol to use as a key in the options hash of requests made through the ajax service to specify
   * subsection(s) of the app from which the request is being made. Provided values can be a string or string[].
   * Strings should not include `/` chars as they are the header primary separator.
   * leading/trailing ws will be dropped and slashes will be replaced.
   *
   * Examples:
   * options[REQUEST_TELEMETRY_APP_SUBSECTIONS] = 'my-cool-web-dir-section';
   * options[REQUEST_TELEMETRY_APP_SUBSECTIONS] = ['my-cool-web-dir-section', '1.0.0']
   */

  var REQUEST_TELEMETRY_APP_SUBSECTIONS = Symbol(); // Exported for testing purposes

  _exports.REQUEST_TELEMETRY_APP_SUBSECTIONS = REQUEST_TELEMETRY_APP_SUBSECTIONS;

  function shouldRetryRequest(xhr) {
    if (!xhr || xhr.status !== 429) {
      return false;
    }

    var header = xhr.getResponseHeader('retry-after');
    return !!header && retryStringToNumber(header) !== null;
  }

  function retryStringToNumber(value) {
    var number = parseInt(value, 10);

    if (isNaN(number) || number < 0) {
      return null;
    }

    return number;
  }

  function retryResetTime(xhr) {
    var headerRateLimit = xhr.getResponseHeader('retry-after');

    if (!!headerRateLimit) {
      return retryStringToNumber(headerRateLimit);
    }

    return null;
  }

  function getHeader(headers, name) {
    if (!headers || !name) {
      return;
    }

    var foundKey = Ember.A(Object.keys(headers)).find(function (key) {
      return key.toLowerCase() === name.toLowerCase();
    });

    if (!foundKey) {
      return;
    }

    return headers[foundKey];
  }

  function isRootAPIRequest(url) {
    // Is a domain-root request and contains an API version path
    return url.startsWith('/') && url.includes('/api/v2/');
  }

  var _default = _ajax.default.extend(_logger.default, Ember.Evented, {
    session: Ember.inject.service(),
    application: Ember.inject.service(),
    _timers: null,
    authData: null,
    currentRequest: {},
    // { url: ..., options: ... }
    enableRetryHeaderParsing: Ember.computed.reads('session.features.enableUIAjaxRetryHeader'),
    enableAPIDomainRewrites: Ember.computed.reads('session.rewriteRootAPIRequests'),
    headers: Ember.computed(function () {
      return {
        'Content-Type': 'application/json'
      };
    }),
    trustedHosts: Ember.computed('application.purecloudV2BaseUri', function () {
      var purecloudV2BaseUri = this.get('application.purecloudV2BaseUri');
      var purecloudV2Host = _lodash.default.startsWith(purecloudV2BaseUri, 'https://') ? purecloudV2BaseUri.substring(8) : purecloudV2BaseUri;
      return [purecloudV2Host];
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this._timers = Ember.A([]);
    },
    willDestroy: function willDestroy() {
      if (this._timers) {
        this._timers.forEach(function (timer) {
          return Ember.run.cancel(timer);
        });
      }

      this._super.apply(this, arguments);
    },
    post: function post(url) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (options.data && typeof options.data !== 'string') {
        options.data = JSON.stringify(options.data);
      }

      return this._super.apply(this, arguments);
    },
    put: function put(url) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (options.data && typeof options.data !== 'string') {
        options.data = JSON.stringify(options.data);
      }

      return this._super.apply(this, arguments);
    },
    patch: function patch(url) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (options.data && typeof options.data !== 'string') {
        options.data = JSON.stringify(options.data);
      }

      return this._super.apply(this, arguments);
    },
    ajaxGet: function ajaxGet(url) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (options.data && typeof options.data !== 'string') {
        options.data = $.param(options.data);
      }

      options.method = 'GET';
      return this.request(url, options);
    },
    _scheduleRequest: function _scheduleRequest(url, options, retryTime, deferred) {
      var _this = this;

      var scheduleTime = retryTime * 1000;
      var timer = Ember.run.later(function () {
        _this._timers.removeObject(timer);

        _this.raw(url, options, deferred);
      }, scheduleTime);

      this._timers.pushObject(timer);
    },

    /**
     * Overriden to make sure we are rate-limited by using `raw`, but returning the same payload as
     * inherited `request` function.
     * Please do not make heavy use of the retry option. It is meant as a last resort.
     *
     * @param url {string} request URL
     * @param options {object}
     * @param [options.retry=false] {boolean} Honor the retry header. Defaults to false. Pass true to retry the request automatically
     */
    request: function request(url, options) {
      var reqUrl = this.addApiDomainIfMissing(url);
      return this.raw(reqUrl, options).then(function (_ref) {
        var response = _ref.response;
        return Ember.RSVP.resolve(response);
      }).catch(function (_ref2) {
        var response = _ref2.response;
        return Ember.RSVP.reject(response);
      });
    },
    addApiDomainIfMissing: function addApiDomainIfMissing(url) {
      if (this.get('enableAPIDomainRewrites') && isRootAPIRequest(url)) {
        var apiDomain = window.location.hostname.replace(/^app\./, 'api.');
        return "".concat(window.location.protocol, "//").concat(apiDomain).concat(url);
      } else {
        return url;
      }
    },
    raw: function raw(url) {
      var _this2 = this;

      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var deferred = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var canParseHeaders = this.get('enableRetryHeaderParsing');
      var retryDisabled = options && !options.retry;

      if (retryDisabled || !canParseHeaders) {
        return this._super.apply(this, arguments);
      }

      if (!deferred) {
        deferred = Ember.RSVP.defer();
      }

      var promiseProxy = RateLimitPromise.create({
        promise: deferred.promise
      });

      this._super.apply(this, arguments).then(function (data) {
        return deferred.resolve(data);
      }).catch(function (data) {
        var jqXHR = data.jqXHR;
        var shouldRetry = shouldRetryRequest(jqXHR);
        var retryTime = retryResetTime(jqXHR);

        _this2._logPossibleRetryEvent(url, jqXHR, retryTime, shouldRetry);

        if (!shouldRetry) {
          deferred.reject(data);
          return;
        }

        _this2._scheduleRequest(url, options, retryTime, deferred);

        if (promiseProxy) {
          Ember.set(promiseProxy, 'isRateLimited', true);
          Ember.set(promiseProxy, 'rateLimitResetTime', retryTime);
        }

        _this2.trigger('rateLimited', {
          url: url,
          options: options,
          jqXHR: jqXHR
        });
      });

      return promiseProxy;
    },

    /**
     * Overridden here to preserve error data, since the base
     * ember-ajax class ignored important parts of error responses.
     */
    normalizeErrorResponse: function normalizeErrorResponse(status, headers, payload) {
      var err = this._super(status, headers, payload);

      if (err && (typeof error === "undefined" ? "undefined" : _typeof(error)) === 'object') {
        err.errorResponse = payload;
      }

      return err;
    },
    _logPossibleRetryEvent: function _logPossibleRetryEvent(url, xhr, retryTime, shouldRetry) {
      var headers = xhr.getAllResponseHeaders();

      if (retryTime === null && !!xhr.getResponseHeader('retry-after')) {
        this.logger.error('Found retry header, but unable to parse time:', {
          url: url,
          headers: headers
        });
        return;
      }

      if (!shouldRetry) {
        return;
      }

      this.logger.warn('Request failed, but a retry header was found. Scheduling to run in the future:', {
        url: url,
        headers: headers,
        seconds: retryTime
      });
    },
    generateDetailedMessage: function generateDetailedMessage(status, headers, payload, requestData) {
      var url = requestData.url;

      if (url) {
        // Try to strip out everything but the host + path
        try {
          var urlObject = url.startsWith('/') ? new URL(url, window.location.origin) : new URL(url);
          url = "".concat(urlObject.origin).concat(urlObject.pathname);
        } catch (_) {
          this.logger.info('Redacted possibly sensitive URL:', {
            url: url
          });
          url = 'REDACTED';
        }
      }

      var correlationId;

      try {
        var correlationHeaderKey = 'ININ-Correlation-Id';
        var headerCorrelationId = getHeader(headers, correlationHeaderKey);
        var payloadCorrelationId = !!payload && payload.contextId;
        correlationId = headerCorrelationId || payloadCorrelationId || '(unknown)';
      } catch (_) {
        correlationId = '(unknown)';
      }

      return ['Ember AJAX request', "Method: ".concat(requestData.type), "URL: ".concat(url), "Status: ".concat(status), "Correlation ID: ".concat(correlationId)].join('\n');
    }
  });

  _exports.default = _default;
});