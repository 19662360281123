define("web-directory/templates/data-models/details", ["exports", "ember-engine-custom-fields/templates/data-models/details"], function (_exports, _details) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _details.default;
    }
  });
});