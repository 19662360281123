define("web-directory/components/target-dropdown/filter/component", ["exports", "web-directory/mixins/logger", "web-directory/utils/acd"], function (_exports, _logger, _acd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_logger.default, {
    classNames: ['interaction-conference-filter'],
    classNameBindings: ['isActive:active'],

    /**
     * @attribute
     *
     * The name of the icon to display using `gux-icon`
     */
    icon: '',

    /**
     * @attribute
     *
     * The value that the filter emits when selected
     */
    value: '',

    /**
     * @attribute
     *
     * Additional text to identify the filter on hover of the button.
     * If not provided then no label will be shown.
     */
    label: null,

    /**
     * @attribute
     *
     * The total amount of records corresponding to this filter
     */
    totalElements: 0,

    /**
     * @attribute
     *
     * `true` if this is the currently active filter
     */
    isActive: false,

    /**
     * The max number to be displayed in the filter counter
     */
    maxCount: 99,
    tooltipId: Ember.computed('elementId', function () {
      return "".concat(this.get('elementId'), "-target-dropdown-filter");
    }),

    /**
     * @action
     *
     * Closure action passed from parent
     */
    actionFilterChange: function actionFilterChange(value) {
      return (0, _acd.warnClosureAction)('filterChange', value);
    },
    actions: {
      /**
       * Invoked with new value when filter has been selected
       * @param value The value to emit
       */
      onFilterChange: function onFilterChange(value) {
        this.actionFilterChange(value);
      }
    }
  });

  _exports.default = _default;
});