define("web-directory/authenticators/test", ["exports", "web-directory/authenticators/directory"], function (_exports, _directory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RSVP = Ember.RSVP;

  var _default = _directory.default.extend({
    restore: function restore(data) {
      return RSVP.resolve(data);
    },
    // Fake out user authentication
    authenticate: function authenticate(options) {
      return this._super(options);
    }
  });

  _exports.default = _default;
});