define("web-directory/field-config/field-config", ["exports", "ember-data", "lodash", "web-directory/field-config/field-section"], function (_exports, _emberData, _lodash, _fieldSection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.KEY_BLACKLIST = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var KEY_BLACKLIST = ['id', '_id', 'guid', 'store', 'data', 'currentState', 'name', 'type', 'group', 'title', 'department', 'phone', 'email', 'description', 'organization'];
  _exports.KEY_BLACKLIST = KEY_BLACKLIST;
  var GROUP_BLACKLIST = [].concat(KEY_BLACKLIST, ['expression', 'groupType']);
  var ORG_BLACKLIST = [].concat(KEY_BLACKLIST, ['orgPreferences', 'shortName']);
  var PROFILE_BLACKLIST = [].concat(KEY_BLACKLIST, ['activities', 'profile', 'emptySections', 'fieldSections', 'emptyWriteableSections']);
  var FieldConfig = Ember.Object.extend({
    intl: Ember.inject.service(),
    application: Ember.inject.service(),
    id: Ember.computed.alias('_id'),
    _id: null,
    orgId: null,
    entityType: null,
    version: null,
    sections: [],
    _initModelWithType: function _initModelWithType() {
      var type = this.get('entityType');
      var modelClass = this.modelClass;
      var blacklist;

      switch (type) {
        case 'person':
          blacklist = _toConsumableArray(PROFILE_BLACKLIST);
          break;

        case 'org':
          blacklist = _toConsumableArray(ORG_BLACKLIST);
          break;

        case 'group':
          blacklist = _toConsumableArray(GROUP_BLACKLIST);
          break;

        case 'externalContact':
        default:
          blacklist = [].concat(KEY_BLACKLIST);
          break;
      }

      var fields = {};
      this.get('sections').forEach(function (section) {
        if (!Ember.A(blacklist).includes(section.key)) {
          fields[section.key] = _emberData.default.attr();
        }
      });
      modelClass.reopenClass({
        fieldConfig: this
      }).reopen(fields, {
        fieldConfig: this
      });

      if (type === 'group') {
        var allowedValues = this.getFieldByKey('general.groupType').get('params.allowedValues') || [];
        allowedValues = allowedValues.filter(function (value) {
          return value !== 'purecloud.distributionGroups';
        });
        this.set('groupTypes', allowedValues);
      }
    },
    unknownProperty: function unknownProperty(property) {
      var sections = this.get('sections');

      if (_lodash.default.map(sections, 'key').indexOf(property) > -1) {
        return this.getSectionByKey(property);
      }

      return this._super();
    },
    splitFieldPath: function splitFieldPath(path) {
      var split = path.replace(/^\w+\.\s/gi, '').split('.');
      return {
        sectionKey: split[0],
        fieldKey: split[1]
      };
    },
    getSectionByKey: function getSectionByKey(key) {
      return this.sections.findBy('key', key);
    },
    getFieldByKey: function getFieldByKey(key) {
      var splitField = this.splitFieldPath(key);
      var section = this.getSectionByKey(splitField.sectionKey);

      if (splitField.fieldKey && section) {
        return section.getFieldByKey(splitField.fieldKey);
      }

      return undefined;
    },
    getFieldListByType: function getFieldListByType(type) {
      var fieldList = this.sections.map(function (section) {
        return section.getFieldsByType(type);
      }).filter(function (field) {
        return field !== undefined && field.length > 0;
      });
      return _lodash.default.flatten(fieldList);
    },
    getFieldsByType: function getFieldsByType() {
      this.getFieldListByType.apply(this, arguments);
    },
    getAllowedValues: function getAllowedValues(fieldPath) {
      return this.getFieldByKey(fieldPath).params.allowedValues;
    },
    isFieldRepeatable: function isFieldRepeatable(fieldPath) {
      var field = this.getFieldByKey(fieldPath);

      if (field) {
        return field.repeatable;
      }

      return false;
    },
    isFieldReference: function isFieldReference(fieldPath) {
      var field = this.getFieldByKey(fieldPath);

      if (field) {
        return field.type === 'reference';
      }

      return false;
    },
    isFieldChoice: function isFieldChoice(fieldPath) {
      return this.getFieldByKey(fieldPath).type === 'select';
    },
    groupFieldConfig: Ember.computed(function () {
      return this.getFieldConfig('group', Ember.getOwner(this));
    }),
    personFieldConfig: Ember.computed(function () {
      return this.getFieldConfig('person', Ember.getOwner(this));
    })
  });
  var extra = {
    getFieldConfig: function getFieldConfig(type, owner) {
      return owner.lookup('field-config:' + type);
    }
  };
  FieldConfig.reopenClass({
    createFieldConfig: function createFieldConfig(attributes, container) {
      // Ensure that sections are the proper object
      attributes.sections = attributes.sections.map(function (section) {
        return _fieldSection.default.create(section);
      });
      var instance = container.lookup('field-config:' + attributes.entityType);

      if (instance) {
        instance.setProperties(attributes);
        instance.sections.forEach(function (section) {
          section.set('fieldConfig', instance);
        });

        instance._initModelWithType();

        return instance;
      }

      Ember.Logger.warn('Unknown field-config type:', {
        type: attributes.entityType
      });
    }
  }).reopenClass(extra).reopen(extra);
  var _default = FieldConfig;
  _exports.default = _default;
});