define("web-directory/components/inbox-panel-v2/coaching-appointments/component", ["exports", "web-directory/utils/dates"], function (_exports, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['inbox-message', 'coaching-appointments-message', 'clickable', 'coaching-appointments'],
    classNameBindings: ['coachingAppointment.markedAsRead:read:unread', 'coachingAppointment.actionType:Deleted:Scheduled'],
    hovered: false,
    application: Ember.inject.service(),
    ajax: Ember.inject.service(),
    clock: Ember.inject.service(),
    inbox: Ember.inject.service(),
    intl: Ember.inject.service(),
    notification: Ember.inject.service(),
    coaching: Ember.inject.service(),
    session: Ember.inject.service(),
    modal: Ember.inject.service('modal'),
    mouseEnter: function mouseEnter() {
      this.set('hovered', true);
    },
    mouseLeave: function mouseLeave() {
      this.set('hovered', false);
    },
    actions: {
      changeStatus: function changeStatus(status) {
        this.get('coaching').changeCoachingNotificationReadState(this.get('coachingAppointment'), status);
        this.set('showDropdown', false);
      },
      viewDetails: function viewDetails() {
        this.toggleCoachingModal();
      },
      readAndViewDetails: function readAndViewDetails() {
        this.get('coaching').changeCoachingNotificationReadState(this.get('coachingAppointment'), true);
        this.toggleCoachingModal();
        this.set('showDropdown', false);
      },
      readAndViewDevelopmentPlan: function readAndViewDevelopmentPlan() {
        this.get('coaching').changeCoachingNotificationReadState(this.get('coachingAppointment'), true);
        this.set('showDropdown', false);
        var route = '#/analytics/agents/development/me';

        if (this.get('hasDevelopmentPlan')) {
          var appsUri = this.get('application.purecloudAppsUri');
          var startDate = (0, _dates.default)(this.get('coachingAppointment.dateStart')).startOf('week').format('YYYY-MM-DD');
          var endDate = (0, _dates.default)(startDate).add(1, 'years').format('YYYY-MM-DD');
          var devUri = "/directory/".concat(route, "?dateSpan=custom&start=").concat(startDate, "&end=").concat(endDate, "&intervalKeyValue=Due");
          var developmentUri = new URL(devUri, appsUri);
          window.open(developmentUri.href, '_self');
        } else {
          window.location.hash = route;
        }
      }
    },
    relationship: Ember.computed('coachingAppointment.relationship', function () {
      var relationship = this.get('coachingAppointment.relationship');
      return this.get('intl').t("notification.inbox.coachingAppointment.relationships.".concat(relationship));
    }),
    attendeeName: Ember.computed('coachingAppointment', 'coachingAttendees', function () {
      var attendee = this.get('coachingAppointment').appointment.attendees[0];

      if (attendee != null) {
        var att = this.get('coachingAttendees').get(attendee.id);

        if (att) {
          return att.name;
        }
      } else {
        return '';
      }
    }),
    localizedStatus: Ember.computed('coachingAppointment.status', function () {
      var status = this.get('coachingAppointment').status;
      return this.get('intl').t("notification.inbox.coachingAppointment.status.".concat(status));
    }),
    dateStart: Ember.computed('coachingAppointment.dateStart', function () {
      return this.get('coaching').getFormattedDate(this.get('coachingAppointment.dateStart'));
    }),
    time: Ember.computed('coachingAppointment.dateStart', function () {
      var date = (0, _dates.default)(this.get('coachingAppointment.dateStart'));
      var locale = this.get('intl.isoLocale');
      return date.locale(locale).format('LT');
    }),
    dateFromNow: Ember.computed('clock.minute', 'coachingAppointment.dateStart', function () {
      var date = (0, _dates.default)(this.get('coachingAppointment.dateStart'));
      var now = (0, _dates.default)();
      var locale = this.get('intl.isoLocale');

      if (now.isAfter(date)) {
        return this.get('intl').t('notification.inbox.coachingAppointmentDue', {
          time: date.locale(locale).fromNow()
        });
      } else {
        return date.locale(locale).fromNow();
      }
    }),
    shouldShowInvalidMessage: Ember.computed('coachingAppointment.status', function () {
      var isInvalid = this.get('coachingAppointment.status') == 'InvalidSchedule';
      return isInvalid;
    }),
    toggleCoachingModal: function toggleCoachingModal() {
      var _this = this;

      var inboxItem = this.get('coachingAppointment');

      if (inboxItem.actionType === 'Delete') {
        var intl = this.get('intl');
        var title = intl.t('notification.inbox.coachingAppointment.toast.deleted.title');
        var message = intl.t('notification.inbox.coachingAppointment.toast.deleted.message');
        this.get('notification').info(title, message);
        this.get('coaching').updateOnDelete(inboxItem);
      } else if (inboxItem.status !== 'Completed') {
        if (this.get('hasCoachingCentralUi')) {
          var displayedModal = this.get('modal.displayedModal');

          if (displayedModal) {
            this.get('modal').closeModal();
          } else {
            this.get('modal').showModal({
              modalId: 'wemCoachingUi',
              modalData: {
                appointmentId: this.get('coachingAppointment.appointment.id')
              }
            });
          }
        } else {
          this.set('coaching.activeCoachingModalId', inboxItem.appointment.id);
          Ember.run.next(function () {
            return _this.get('coaching').populateDataForCoachingModal(inboxItem);
          });
        }
      }
    }
  });

  _exports.default = _default;
});