define("web-directory/components/global-side-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['global-side-panel-wrapper'],
    classNameBindings: ['panelTemplate:show-panel:hidden-panel'],
    containerClassNames: ['global-side-panel-component'],
    translucentOverlay: true,
    overlayClassNames: 'translucent',
    intl: inject.service(),
    panel: inject.service('side-panel'),
    headerText: computed.reads('panel.headerText'),
    confirmText: computed('panel.confirmText', function () {
      if (this.get('panel.confirmText')) {
        return this.get('panel.confirmText');
      }

      return this.get('intl').t('common.confirm');
    }),
    cancelText: computed('panel.cancelText', function () {
      if (this.get('panel.cancelText')) {
        return this.get('panel.cancelText');
      }

      return this.get('intl').t('common.cancel');
    }),
    cancel: computed.reads('panel.cancelAction'),
    confirm: computed.reads('panel.confirmAction'),
    showHeader: computed.reads('panel.showHeader'),
    showFooter: computed.reads('panel.showFooter'),
    extraClassNames: computed.reads('panel.panelClasses'),
    panelTemplate: computed.reads('panel.template'),
    panelTemplateProps: computed.reads('panel.templateProps'),
    confirmDisabled: computed.reads('panel.confirmDisabled'),
    noCancel: false,
    actions: {
      close: function close() {
        this.send('sendCancel');
      },
      sendConfirm: function sendConfirm() {
        var _this = this;

        if (this.get('confirm')) {
          var ret = this.get('confirm')();

          if (ret && ret.then) {
            this.set('loading', true);
            ret.then(function () {
              _this.set('loading', false);
            });
          }
        } else {
          this.sendAction('confirm');
        }
      },
      sendCancel: function sendCancel() {
        if (this.attrs.cancel) {
          this.attrs.cancel();
        }

        this.get('panel').resetSidePanel();
      }
    }
  });

  _exports.default = _default;
});