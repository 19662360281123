define("web-directory/models/response", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ResponseModel = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    texts: _emberData.default.attr(),
    dateCreated: _emberData.default.attr(),
    version: _emberData.default.attr(),
    libraries: _emberData.default.attr(),
    substitutions: _emberData.default.attr(),
    responseType: _emberData.default.attr(),
    messagingTemplate: _emberData.default.attr(),
    isOpen: _emberData.default.attr(),
    libraryName: Ember.computed('libraries', function () {
      var libraries = this.get('libraries');
      return libraries && libraries.length ? libraries[0].name : '';
    }),
    isWhatsAppResponse: Ember.computed('responseType', 'messagingTemplate', function () {
      return this.get('responseType') === 'MessagingTemplate' && !!this.get('messagingTemplate.whatsApp');
    }),
    language: Ember.computed.reads('messagingTemplate.whatsApp.language')
  });

  var _default = ResponseModel;
  _exports.default = _default;
});