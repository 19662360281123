define("web-directory/routes/third-party/app-embed", ["exports", "web-directory/models/app-pool", "ember-purecloud-components/mixins/logger", "web-directory/utils/apps"], function (_exports, _appPool, _logger, _apps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Route = Ember.Route,
      inject = Ember.inject,
      get = Ember.get,
      computed = Ember.computed;
  var STANDALONE_APP_ROUTE_LOG_NS = 'Route';

  var _default = Route.extend(_logger.default, {
    apps: inject.service(),
    intl: inject.service(),
    appsProblem: false,
    unknownApp: false,
    currAppName: null,
    routeTitle: computed('appsProblem', 'unknownApp', 'currAppName', function () {
      var result = null;

      if (this.get('appsProblem')) {
        result = this.get('intl').lookup('apps.loadFailedTitle').toString();
      } else if (this.get('unknownApp')) {
        result = this.get('intl').lookup('apps.notFoundTitle').toString();
      } else {
        result = this.get('currAppName') || this.get('intl').lookup('apps.title').toString();
      }

      return result;
    }),
    beforeModel: function beforeModel(transition) {
      var result = this._super(transition);

      if (transition.isActive === false) {
        // Route canceled for auth
        return;
      }

      this.set('appsProblem', false);
      this.set('unknownApp', false);

      if (this.get('apps.appsSupported')) {
        /*
         * Clear currAppName so it will trigger a routeTitle update if the
         *  user is bouncing between a single app and a non-app route.
         */
        this.set('currAppName', null);
        return result;
      } else {
        // Apps are disabled.  go to index
        return this.transitionTo('index');
      }
    },
    model: function model(params) {
      var _this = this;

      var appPool = this.get('apps.standaloneAppPool');
      return appPool.showApp(params.app_id).then(function (poolState) {
        var result = null;

        if (poolState && poolState.name === _appPool.POOL_STATES.SHOWING_APP) {
          _this.set('currAppName', get(poolState, 'details.instance.app.name'));

          result = {
            instanceId: get(poolState, 'details.instance.instanceId')
          };
        }

        return result;
      }).catch(function (poolState) {
        if (poolState && poolState.name === _appPool.POOL_STATES.APP_NOT_FOUND) {
          _this.set('unknownApp', true); // Possible Causes:
          // User using old or unauthorized link
          // User reloaded PureCloud on this route and the app has been deleted or he/she no longer has permission
          // Pool state and menu out of sync (Should not happen)


          _this.logger.warn(_apps.default.buildStandaloneAppLogMsg('Unknown App requested in route param.', STANDALONE_APP_ROUTE_LOG_NS), {
            poolState: poolState,
            remoteData: {
              appId: params.app_id,
              poolState: Ember.get(poolState, 'name')
            }
          });
        } else {
          _this.set('appsProblem', true);

          _this.logger.error(_apps.default.buildStandaloneAppLogMsg('Failed to show App in route', STANDALONE_APP_ROUTE_LOG_NS), {
            poolState: poolState,
            remoteData: {
              appId: params.app_id,
              poolState: Ember.get(poolState, 'name')
            }
          });
        }

        return null;
      }).finally(function () {
        _this.set('apps.standaloneAppPoolVisible', true);
      });
    },
    actions: {
      willTransition: function willTransition() {
        var _this2 = this;

        var model = this.modelFor(this.routeName);

        if (model && model.instanceId) {
          // Determine if the pool is still showing the app for this route.
          // The app for this route may not be shown if the app has been hidden/stopped/closed for
          // another reason (e.g. Removed App closed by user) and we are transitioning to another route (e.g. index).
          var initPoolState = this.get('apps.standaloneAppPool.currState');

          if (initPoolState && initPoolState.name === _appPool.POOL_STATES.SHOWING_APP && get(initPoolState, 'details.instanceId') === model.instanceId) {
            // Pool is showing this route's app, attempt to hide it.
            this.get('apps.standaloneAppPool').hideApp(model.instanceId).catch(function (rejectionPoolState) {
              // Ignore the failure as we still want to proceed and hide the pool
              // Log this as it should not happen
              _this2.logger.error(_apps.default.buildStandaloneAppLogMsg('Failed to hide standalone app when trying to transition out of route', STANDALONE_APP_ROUTE_LOG_NS), {
                initPoolState: initPoolState,
                rejectionPoolState: rejectionPoolState,
                remoteData: {
                  routeAppId: model.instanceId,
                  initPoolState: Ember.get(initPoolState, 'name'),
                  rejectionPoolState: Ember.get(rejectionPoolState, 'name')
                }
              });
            });
          }
        }

        this.set('apps.standaloneAppPoolVisible', false);
        return true;
      }
    }
  });

  _exports.default = _default;
});