define("web-directory/components/search-result-context/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WnBh6f0a",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"search-result-context\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"search-result-context-header\"],[13],[0,\"\\n        \"],[11,\"button\",[]],[15,\"type\",\"button\"],[15,\"class\",\"close\"],[5,[\"action\"],[[28,[null]],\"close\"]],[13],[0,\"\\n            \"],[11,\"span\",[]],[15,\"aria-hidden\",\"true\"],[13],[0,\"×\"],[14],[0,\"\\n        \"],[14],[0,\"\\n        \"],[11,\"h4\",[]],[13],[1,[33,[\"t\"],[\"chat.history\"],null],false],[14],[0,\"\\n    \"],[14],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"search-result-context-messages\"],[13],[0,\"\\n        \"],[1,[33,[\"chat-panel/conversation/conversation-pane/history-bar\"],null,[[\"activeRoom\",\"isForward\",\"loadMoreHistory\",\"noMoreHistoryText\"],[[28,[\"historyRoom\"]],false,[33,[\"action\"],[[28,[null]],\"loadHistoryBeforeFirst\"],null],[33,[\"t\"],[\"chat.noHistory\"],null]]]],false],[0,\"\\n        \"],[1,[33,[\"chat-panel/conversation/conversation-pane/messages\"],null,[[\"room\",\"replyToMessage\",\"isMediaCollapsed\",\"openMentionWarning\"],[[28,[\"historyRoom\"]],[33,[\"action\"],[[28,[null]],\"replyToMessage\"],null],true,[33,[\"action\"],[[28,[null]],[28,[\"openMentionWarning\"]]],null]]]],false],[0,\"\\n        \"],[1,[33,[\"chat-panel/conversation/conversation-pane/history-bar\"],null,[[\"activeRoom\",\"isForward\",\"loadMoreHistory\",\"noMoreHistoryText\"],[[28,[\"historyRoom\"]],true,[33,[\"action\"],[[28,[null]],\"loadHistoryAfterLast\"],null],[33,[\"t\"],[\"chat.noHistory\"],null]]]],false],[0,\"\\n    \"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/search-result-context/template.hbs"
    }
  });

  _exports.default = _default;
});