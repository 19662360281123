define("web-directory/mixins/chat-message-mixin", ["exports", "web-directory/utils/dates", "ember-purecloud-components/utils/jid-helpers"], function (_exports, _dates, _jidHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    acdChatUser: Ember.computed.alias('room.acdCustomerParticipant'),
    isYou: Ember.computed('chatUser.jid', 'session.person.jid', function () {
      return (0, _jidHelpers.compareJids)(this.get('chatUser.jid'), this.get('session.person.jid'));
    }),
    isAcdChatUser: Ember.computed('chatUser.id', 'acdChatUser.id', function () {
      var chatUserId = (0, _jidHelpers.getEntityObjectId)(this.get('chatUser.id'));
      var acdChatUserId = (0, _jidHelpers.getEntityObjectId)(this.get('acdChatUser.id'));
      return chatUserId === acdChatUserId;
    }),
    isLastMessage: Ember.computed('room.messages.lastObject.id', function () {
      return this.get('id') === this.get('room.messages.lastObject.id');
    }),
    messageTime: Ember.computed('time', function () {
      return (0, _dates.displayDateRelativeToToday)(this.get('time'), this.get('intl.isoLocale'));
    }),
    messageDisplay: Ember.computed('correction', function () {
      var correction = this.get('correction');

      if (correction) {
        return correction;
      } else {
        return this;
      }
    }),
    isCorrection: Ember.computed.bool('correction')
  });

  _exports.default = _default;
});