define("web-directory/components/icon-bootstrap/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r8hM9JFN",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"bootstrapIcons\"]]],null,{\"statements\":[[0,\"    \"],[11,\"i\",[]],[15,\"class\",\"ion-chevron-right invisible\"],[13],[14],[0,\"\\n    \"],[11,\"i\",[]],[15,\"class\",\"pc-moon pc-email invisible\"],[13],[14],[0,\"\\n    \"],[11,\"i\",[]],[15,\"class\",\"pc-moon pc-loading invisible\"],[13],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/icon-bootstrap/template.hbs"
    }
  });

  _exports.default = _default;
});