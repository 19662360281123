define("web-directory/components/app-carousel-toolbar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MOvIz3Bp",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"name\"]]],null,{\"statements\":[[0,\"    \"],[9,\"app-carousel-toolbar\",{\"attrs\":[[16,\"name\",[26,[\"name\"]],null],[15,\"theme\",\"legacy\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/app-carousel-toolbar/template.hbs"
    }
  });

  _exports.default = _default;
});