define("web-directory/services/line", ["exports", "web-directory/mixins/conversation"], function (_exports, _conversation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MAX_NUM_STICKERS = 500;
  var BASE_URI_V2 = "api/v2/conversations/messaging/stickers/line?pageSize=".concat(MAX_NUM_STICKERS, "&pageNumber=1");
  var inject = Ember.inject,
      Service = Ember.Service;

  var _default = Service.extend(_conversation.default, {
    application: inject.service(),
    loadStickers: function loadStickers() {
      var url = this.get('application').pcV2Uri(BASE_URI_V2);
      return this.makeRequest(url, {}, 'get');
    }
  });

  _exports.default = _default;
});