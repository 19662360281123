define("web-directory/components/acd-interactions-panel-v2/interaction-panel-sizer/component", ["exports", "web-directory/utils/acd-ts"], function (_exports, _acdTs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['interaction-panel-sizer-v2'],

    /**
     * @attribute
     *
     * The current size that the panel is being viewed on. Options are
     * 25, 50, 75, and 100.
     */
    currentPanelSize: 25,

    /**
     * `true` if the tooltip to make the panel size selection should be visible
     */
    showTooltip: false,

    /**
     * The different options to appear in the tooltip
     */
    options: [{
      size: 100,
      label: 'purecloud.acdInteraction.panelResizerFull'
    }, {
      size: 75,
      label: 'purecloud.acdInteraction.panelResizer3Quarter'
    }, {
      size: 50,
      label: 'purecloud.acdInteraction.panelResizerHalf'
    }, {
      size: 25,
      label: 'purecloud.acdInteraction.panelResizerQuarter'
    }],

    /**
     * @action
     *
     * Invoked when the panel size has been updated from within this component.
     *
     * @param size the size to set the current panel to
     */
    actionSetPanelSize: function actionSetPanelSize(size) {
      return (0, _acdTs.warnClosureAction)('setPanelSize', {
        size: size
      });
    },
    actions: {
      /**
       * Invoked when the user toggles the tooltip visibility in order to make/view their selection
       */
      onToggleTooltip: function onToggleTooltip() {
        this.toggleProperty('showTooltip');
      },

      /**
       * Invoked when a new panel size has been selected and needs to be updated
       *
       * @param size the size to change the panel to
       * @param userClicked `true` if the user initiated this by a clicking action
       */
      onSelectPanelSize: function onSelectPanelSize(size) {
        this.actionSetPanelSize(size);
        this.set('showTooltip', false);
      }
    }
  });

  _exports.default = _default;
});