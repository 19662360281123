define("web-directory/utils/masonry", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deregisterLayout = deregisterLayout;
  _exports.registerLayout = registerLayout;
  _exports.rerunLayouts = rerunLayouts;
  var LAYOUTS = {};

  function registerLayout(layoutId, layout) {
    deregisterLayout(layoutId);
    LAYOUTS[layoutId] = layout;
  }

  function deregisterLayout(layoutId) {
    LAYOUTS[layoutId] = null;
    delete LAYOUTS[layoutId];
  }

  function rerunLayouts() {
    _lodash.default.forOwn(LAYOUTS, function (layout) {
      if (layout && layout.rerun) {
        layout.rerun();
      }
    });
  }
});