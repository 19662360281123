define("web-directory/helpers/facet-label", ["exports", "web-directory/field-config/field-config"], function (_exports, _fieldConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params, namedArgs) {
      var facet = namedArgs.facet;
      var facetName = facet.facetName;
      var splitName = facetName.split('.');
      var section = splitName[0];
      var field = splitName[1];

      if (section && field) {
        var owner = Ember.getOwner(this);

        var fieldObj = _fieldConfig.default.getFieldConfig('person', owner).getSectionByKey(section).getFieldByKey(field);

        if (fieldObj === undefined) {
          var _section = _fieldConfig.default.getFieldConfig('group', owner).getSectionByKey(_section);

          if (!!_section) {
            fieldObj = _section.getFieldByKey(field);
          } else {
            return field;
          }
        }

        if (fieldObj !== undefined) {
          var intl = this.get('intl');
          var path = fieldObj.getFieldPath();
          var lookupPath = path.replace(/\./g, '_');
          var i18nKey;

          if (facet.total > 1) {
            i18nKey = "directedSearch_".concat(lookupPath, "_plural");
          } else {
            i18nKey = "directedSearch_".concat(lookupPath, "_singular");
          }

          if (intl.query(i18nKey)) {
            return intl.lookup(i18nKey);
          } else {
            return fieldObj.get('i18nLabel');
          }
        }
      }

      return facetName;
    }
  });

  _exports.default = _default;
});