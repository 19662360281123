define("web-directory/components/entity-profile/profiles/field-data", ["exports", "web-directory/components/entity-fields/validators", "web-directory/components/entity-profile/profiles/profile"], function (_exports, _validators, _profile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var PERMISSIONED_FIELD_PATHS = ['contactInfo.email_main', 'contactInfo.phone_work', 'contactInfo.voicemaileEnabled', 'general.title', 'hr.empId', 'hr.empType', 'hr.hireDate', 'hr.officialName', 'relationships.manager', 'relationships.administrativeAssistant', 'purecloud.defaultStationId', 'purecloud.siteId', 'purecloud.skills', 'purecloud.languages', 'purecloud.acdAutoAnswer', 'agent.name', 'agent.profileImage', 'primaryContactInfo.email'];
  var POSSIBLE_INTEGRATION_FIELDS = ['contactInfo.phone_other'];

  var _default = Ember.Object.extend({
    session: null,
    permissions: null,
    entity: null,
    field: null,
    valueReference: null,
    value: computed('fieldPath', 'entity.{fieldPath}.[]', 'entity.{fieldPath}.@each._id', function () {
      var id = this.get('valueReference._id');
      var tempId = this.get('valueReference.tempId');
      var fieldPath = this.get('fieldPath');
      var entity = this.get('entity');
      var data = entity ? entity.get(fieldPath) : null;
      var location = this.get('_sectionLocation');
      var value = {};

      if (Array.isArray(data) && data.length) {
        if (location === null || location === undefined || location >= data.length || location < 0) {
          if (tempId) {
            location = data.findIndex(function (d) {
              return d.tempId === tempId;
            });
          } else if (id) {
            location = data.findIndex(function (d) {
              return d._id === id;
            });
          } else {
            location = 0;
          }

          this.set('_sectionLocation', location);
        } else if (id != null) {
          location = data.findIndex(function (d) {
            return d._id === id;
          });
          this.set('_sectionLocation', location);
        }

        if (location != null && location >= 0) {
          value = data[location];
        } else {
          // if no location found or negative index then we assume its at the top
          value = data[0];
        }
      } // if the value does not have an id or tempId then its a new addition and we need to assign a tempId for ui tracking


      if (!value.id && !value.tempId) {
        value.tempId = Ember.generateGuid();
      }

      return value;
    }),
    _sectionLocation: null,
    // Legacy support
    entry: computed.reads('value'),
    fieldValue: computed.reads('value'),
    sectionKey: computed('field', function () {
      return this.get('field.section.key');
    }),
    fieldPath: computed('field', function () {
      return this.get('field').getFieldPath();
    }),
    fieldPathClass: computed('fieldPath', function () {
      var fieldPath = this.get('fieldPath');
      return fieldPath.replace(/\./gi, '-');
    }),
    fieldId: computed('value._id', 'value.tempId', function () {
      return this.get('value._id') || this.get('value.tempId');
    }),
    writeable: computed('entity.type', 'entity.readWritePermissions', 'fieldPath', 'value', function () {
      var type = this.get('entity.type');

      if (type === 'group') {
        return true;
      }

      var canEditProfiles = this.get('permissions.canEditProfiles');
      var path = this.get('fieldPath'); // Integration fields have their own editing requirements

      if (this.get('integrationManaged')) {
        return false;
      }

      if (Ember.A(PERMISSIONED_FIELD_PATHS).includes(path)) {
        // Only admins can edit core contact fields
        return canEditProfiles;
      }

      if (Ember.A(_profile.BUILTIN_SECTIONS).includes(this.get('sectionKey'))) {
        return true;
      }

      var myOwnProfile = this.get('session.person.id') === this.get('entity.id');
      var customKeys = (0, _profile.customSectionKeys)(this.get('entity.fieldConfig.sections'));

      if ((myOwnProfile || canEditProfiles) && Ember.A(customKeys).includes(this.get('sectionKey'))) {
        return true;
      }

      return false;
    }),

    /**
     * @deprecated
     * This computed value is deprecated. Please use `removable` instead.
     */
    removeable: computed(function () {
      Ember.Logger.warn('`components/entity-profile/profiles/field-data.js` - The use of the computed value `removeable` is deprecated. Please update to use `removable` on this class instead.');
      return this.get('removable');
    }),
    removable: computed('integrationManaged', 'field.repeatable', function () {
      var repeatable = this.get('field.repeatable');
      return repeatable && !this.get('integrationManaged');
    }),
    integrationManaged: computed('entity.type', 'entity.readWritePermissions', 'fieldPath', 'value', function () {
      var type = this.get('entity.type');

      if (type === 'group') {
        return false;
      }

      var path = this.get('fieldPath');

      if (Ember.A(POSSIBLE_INTEGRATION_FIELDS).includes(path)) {
        var value = this.get('value');
        return value && value.value && !!value.value.integration;
      }

      return false;
    }),
    isEmpty: computed('field.type', 'value', 'value.value', 'value.value.[]', {
      get: function get() {
        var type = this.get('field.type');
        var value = this.get('value');
        var validator = null;

        if (_validators.default[type]) {
          validator = _validators.default[type];
        } else {
          validator = _validators.default.default;
          Ember.Logger.warn('Using default validator for field:', {
            fieldPath: this.get('fieldPath'),
            field: this.get('field')
          });
        }

        if (!value || Object.keys(value).length === 0) {
          return true;
        }

        return validator.isEmpty(value, this.get('field'));
      },
      set: function set(path, value) {
        return value;
      }
    }),
    isValid: computed('value', 'value.value', 'value.value.[]', {
      get: function get() {
        var type = this.get('field.type');
        var value = this.get('value');
        var validator = null;

        if (_validators.default[type]) {
          validator = _validators.default[type];
        } else {
          validator = _validators.default.default;
          Ember.Logger.warn('Using default validator for field:', {
            fieldPath: this.get('fieldPath'),
            field: this.get('field')
          });
        }

        if (!value || Object.keys(value).length === 0) {
          return true;
        }

        return validator.isValid(value, this.get('field'));
      },
      set: function set(path, value) {
        return value;
      }
    }),
    isPrimaryField: computed('value._id', 'entity.primaryContactInfo.email.firstObject.value.fieldId', function () {
      var primaryFieldId = this.get('entity.primaryContactInfo.email.firstObject.value.fieldId');
      return this.get('value._id') === primaryFieldId;
    }),
    isNewValue: computed('value._id', 'value.tempId', {
      get: function get() {
        return !this.get('value._id');
      },
      set: function set(path, value) {
        return value;
      }
    })
  });

  _exports.default = _default;
});