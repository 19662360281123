define("web-directory/helpers/value-for-choice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params) {
      var intl = this.get('intl');
      var value = params[0];
      var type = params[1];

      if (type === 'location') {
        return intl.lookup('geolocation.formattedLocationName', value);
      }

      if (!value || !value.value) {
        return '';
      }

      if (type === 'email') {
        return value.value;
      }

      if (type === 'voice' || type === 'sms') {
        var number = value.value.number;
        var ext = value.value.extension;
        var html = "<span class='number'>".concat(number, "</span>");
        var extLabel = intl.lookup('entity.fieldTypes.phone.extension');
        return Ember.String.htmlSafe(ext ? "".concat(html, " <span>").concat(extLabel, "</span> <span class='extension'>").concat(ext, "</span>") : html);
      }

      return '';
    }
  });

  _exports.default = _default;
});