define("web-directory/instance-initializers/reopen-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      observer = Ember.observer,
      Route = Ember.Route;
  var _default = {
    name: 'reopen-route',
    initialize: function initialize() {
      Route.reopen({
        intl: inject.service(),
        sidebar: inject.service(),
        application: inject.service(),
        routeTitle: '',
        updateRouteTitle: observer('routeName', 'routeTitle', 'application.{orgName,currentRouteName}', 'sidebar.{showConversations,communicationSidebarExpanded}', function () {
          var parts = [];
          var routeName = this.get('routeName');
          var routeTitle = this.get('routeTitle');
          var appRoute = this.get('application.currentRouteName') || '';
          var orgName = this.get('application.orgName');
          var sidebar = this.get('sidebar');

          if (!routeTitle || appRoute.indexOf(routeName) === -1) {
            return;
          }

          if (sidebar.get('showConversations') && sidebar.get('communicationSidebarExpanded')) {
            parts.push(this.get('intl').lookup('components.navMenu.chat'));
          } else {
            parts.push(typeof routeTitle === 'function' ? routeTitle.call(this) : routeTitle);
          }

          if (orgName) {
            parts.push(orgName);
          }

          window.document.title = parts.join(' - ');
        })
      });
    }
  };
  _exports.default = _default;
});