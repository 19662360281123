define("web-directory/components/alerting-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tRJ+9ckh",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"alerting-modal-container\"],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"modalConfig\"]]],null,{\"statements\":[[0,\"        \"],[1,[33,[\"alerting-modal\"],null,[[\"modalConfig\",\"closeModal\"],[[28,[\"modalConfig\"]],[33,[\"action\"],[[28,[null]],\"closeModal\"],null]]]],false],[0,\"\\n\"]],\"locals\":[]},null],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/alerting-container/template.hbs"
    }
  });

  _exports.default = _default;
});