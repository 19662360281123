define("web-directory/utils/apps", ["exports", "web-directory/utils/types", "@genesys/apps-v2-host"], function (_exports, _types, _AppsV) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SDK_REPO_URL = _exports.LOG_STANDALONE_APP_NAMESPACE = _exports.LOG_ROOT_NAMESPACE = _exports.LOG_INTERAPPTIONS_NAMESPACE = _exports.LOG_API_NAMESPACE = _exports.AppsV2 = void 0;
  _exports.AppsV2 = _AppsV;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  // General Constants
  var SDK_REPO_URL = 'https://github.com/MyPureCloud/client-app-sdk'; // Namespaces for logging, metrics, etc

  _exports.SDK_REPO_URL = SDK_REPO_URL;
  var LOG_ROOT_NAMESPACE = 'PureCloud Client Apps';
  _exports.LOG_ROOT_NAMESPACE = LOG_ROOT_NAMESPACE;
  var LOG_STANDALONE_APP_NAMESPACE = 'Standalone App';
  _exports.LOG_STANDALONE_APP_NAMESPACE = LOG_STANDALONE_APP_NAMESPACE;
  var LOG_API_NAMESPACE = 'API';
  _exports.LOG_API_NAMESPACE = LOG_API_NAMESPACE;
  var LOG_INTERAPPTIONS_NAMESPACE = 'Interapptions';
  _exports.LOG_INTERAPPTIONS_NAMESPACE = LOG_INTERAPPTIONS_NAMESPACE;
  var LOG_SEPARATOR = ' - ';
  var _default = {
    SDK_REPO_URL: SDK_REPO_URL,
    LOG_ROOT_NAMESPACE: LOG_ROOT_NAMESPACE,
    LOG_STANDALONE_APP_NAMESPACE: LOG_STANDALONE_APP_NAMESPACE,
    LOG_API_NAMESPACE: LOG_API_NAMESPACE,
    LOG_INTERAPPTIONS_NAMESPACE: LOG_INTERAPPTIONS_NAMESPACE,
    buildLogMsg: function buildLogMsg(msg) {
      var tokens = [LOG_ROOT_NAMESPACE];

      for (var _len = arguments.length, subNamespaces = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        subNamespaces[_key - 1] = arguments[_key];
      }

      if (subNamespaces.length > 0) {
        subNamespaces.forEach(function (currNamespace) {
          if (Ember.typeOf(currNamespace) !== 'string' || !Ember.isPresent(currNamespace)) {
            throw new Error('invalidNamespaceValue');
          } else {
            tokens.push(currNamespace);
          }
        });
      }

      if (Ember.typeOf(msg) !== 'string' || !Ember.isPresent(msg)) {
        throw new Error('invalidMessage');
      } else {
        tokens.push(msg);
      }

      return tokens.join(LOG_SEPARATOR);
    },
    buildApiLogMsg: function buildApiLogMsg(msg) {
      return this.buildLogMsg(msg, LOG_API_NAMESPACE);
    },
    buildStandaloneAppLogMsg: function buildStandaloneAppLogMsg(msg) {
      for (var _len2 = arguments.length, subNamespaces = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        subNamespaces[_key2 - 1] = arguments[_key2];
      }

      return this.buildLogMsg.apply(this, [msg, LOG_STANDALONE_APP_NAMESPACE].concat(subNamespaces));
    },
    buildInterapptionsLogMsg: function buildInterapptionsLogMsg(msg) {
      for (var _len3 = arguments.length, subNamespaces = new Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
        subNamespaces[_key3 - 1] = arguments[_key3];
      }

      return this.buildLogMsg.apply(this, [msg, LOG_INTERAPPTIONS_NAMESPACE].concat(subNamespaces));
    },
    buildStashLoggerDetails: function buildStashLoggerDetails() {
      var logDetails = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var details = _objectSpread({}, logDetails.data);

      details.remoteData = _objectSpread({}, logDetails.remote);

      if (logDetails.mask) {
        details.remoteData.sensitive = _objectSpread({}, logDetails.mask);
      }

      return details;
    },
    buildViewId: function buildViewId(id) {
      return "app_".concat(id.replaceAll('&', '_'));
    },
    isAppDefV2: function isAppDefV2(toCheck) {
      if (!toCheck || _typeof(toCheck) !== 'object' || !(0, _types.hasKey)(toCheck, 'type') || typeof toCheck.type !== 'string') {
        return false;
      }

      return ['iframe', 'elementSingletonAppDef', 'elementFactoryAppDef', 'dynaWebComp'].includes(toCheck.type);
    },
    isAppContext: function isAppContext(toCheck) {
      if (!toCheck || _typeof(toCheck) !== 'object') {
        return false;
      }

      if (!(0, _types.hasKey)(toCheck, 'type') || typeof toCheck.type !== 'string' || !['conversation', 'workitem'].includes(toCheck.type)) {
        return false;
      }

      if (!(0, _types.hasKey)(toCheck, 'id') || typeof toCheck.id !== 'string') {
        return false;
      }

      if (!(0, _types.hasKey)(toCheck, 'payload') || _typeof(toCheck.payload) !== 'object' || !toCheck.payload) {
        return false;
      }

      return true;
    },
    buildV1IconAsset: function buildV1IconAsset(asset) {
      var type = asset.type;

      switch (type) {
        case 'remoteVectorImage':
          return {
            vector: asset.url.href
          };

        case 'remoteRasterImage':
          if (asset.width !== asset.height) {
            return {};
          }

          return {
            raster: _defineProperty({}, "x".concat(asset.width), asset.url.href)
          };

        case 'remoteRasterImageSet':
          var raster = Object.fromEntries(asset.images.filter(function (curr) {
            return curr.width === curr.height;
          }).map(function (curr) {
            return ["x".concat(curr.width), curr.url.href];
          }));

          if (Object.keys(raster).length === 0) {
            return {};
          }

          return {
            raster: raster
          };

        case 'sparkV3SvgSprite':
          return {
            guxIcon: {
              name: asset.classNames.join(' '),
              decorative: true
            }
          };

        default:
          (0, _types.assertNever)(type);
      }
    },
    buildV2IconAsset: function buildV2IconAsset(asset) {
      if (!asset || _typeof(asset) !== 'object') {
        return undefined;
      }

      if (asset.guxIcon && typeof asset.guxIcon.name === 'string') {
        return {
          type: 'sparkV3SvgSprite',
          classNames: [asset.guxIcon.name]
        };
      } else if (typeof asset.vector === 'string') {
        try {
          return {
            type: 'remoteVectorImage',
            url: new URL(asset.vector)
          };
        } catch (_) {
          return undefined;
        }
      } else if (asset.raster && _typeof(asset.raster) === 'object') {
        // Look though raster props and validate the url
        var RASTER_PROP_PATTERN = /^x(\d+)$/;
        var rasterEntries = Object.entries(asset.raster).filter(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 1),
              currProp = _ref2[0];

          return RASTER_PROP_PATTERN.test(currProp);
        }).filter(function (toCheck) {
          return typeof toCheck[1] === 'string';
        });
        var images = rasterEntries.reduce(function (result, _ref3) {
          var _ref4 = _slicedToArray(_ref3, 2),
              currRasterProp = _ref4[0],
              currRasterValue = _ref4[1];

          var url;

          try {
            url = new URL(currRasterValue);
          } catch (_) {
            // TODO: Log invalid asset url
            return result;
          }

          var match = RASTER_PROP_PATTERN.exec(currRasterProp);

          if (!match) {
            return result;
          }

          var size = parseInt(match[1], 10);

          if (isNaN(size)) {
            return result;
          }

          result.push({
            width: size,
            height: size,
            url: url
          });
          return result;
        }, []);

        if (images.length === 1) {
          var _images$ = images[0],
              url = _images$.url,
              width = _images$.width,
              height = _images$.height;
          return {
            type: 'remoteRasterImage',
            url: url,
            width: width,
            height: height
          };
        }

        if (images.length > 1) {
          return {
            type: 'remoteRasterImageSet',
            images: images
          };
        }
      }

      return undefined;
    }
  };
  _exports.default = _default;
});