define("web-directory/components/group-profile/component", ["exports", "ember-data", "lodash", "web-directory/mixins/entity-controller", "web-directory/field-config/field-section", "web-directory/models/person"], function (_exports, _emberData, _lodash, _entityController, _fieldSection, _person) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      run = Ember.run,
      inject = Ember.inject,
      RSVP = Ember.RSVP;

  var jobEndpoint = function jobEndpoint(id) {
    return "/api/v2/jobs/".concat(id);
  };

  var GroupProfileComponent = Component.extend(_entityController.default, {
    classNames: ['group-profile', 'entity'],
    classNameBindings: ['chatEnabled'],
    intl: inject.service(),
    ajax: inject.service(),
    store: inject.service(),
    session: inject.service(),
    favorites: inject.service(),
    permissions: inject.service(),
    application: inject.service(),
    notification: inject.service(),
    addressformat: inject.service(),
    canViewChat: Ember.computed.bool('permissions.canViewBusinessChat'),
    canViewVideo: Ember.computed.bool('permissions.canViewCollaborateVideo'),
    phoneEnabled: Ember.computed.bool('permissions.phoneEnabled'),
    chatEnabled: Ember.computed.alias('chatService.enabled'),
    model: null,
    showDropdown: false,
    isEditing: false,
    showContactInfo: false,
    saves: [],
    groupRoom: null,
    showMembers: true,
    showLinkDropdown: false,
    displayIndices: false,
    hasLinks: false,
    hasInformals: false,
    isLoading: false,
    displayWarningModal: false,
    activeTab: 'members',
    excludedFieldSections: ['general', 'images', 'membership', 'membershipDraft', 'privacy', 'owners'],
    excludedMembershipFields: ['includeOwners', 'exclusions', 'inclusions'],
    excludedEditingFields: ['compiledQuery'],
    view: Ember.$('<div class="center-indicator"><i class="pc-moon pc-loading pc-spin"></i></div>'),
    toggleLoading: Ember.observer('isLoading', function () {
      if (this.get('_state') === 'inDOM' && this.get('isLoading')) {
        this.$('.field-section .content').append(this.get('view'));
      } else if (this.$()) {
        this.$('.center-indicator').remove();
      }
    }),
    generalExcludedEditingFields: ['groupType'],
    actions: {
      selectTab: function selectTab(tab) {
        this.set('activeTab', tab);
      },
      toggleCallsEnabled: function toggleCallsEnabled() {
        if (!this.get('callsEnabled') && this.get('hasRules')) {
          this.set('displayWarningModal', true);
        } else {
          this.toggleProperty('callsEnabled');
          this.saveCallsEnabledState();
        }

        this.set('isEditing', false);
      },
      toggleEditing: function toggleEditing() {
        if (!this.get('isEditing')) {
          this.get('model').reload();
        }

        this.toggleProperty('isEditing');
      },
      changeEditingMode: function changeEditingMode(editing) {
        this.set('isEditing', editing);
      },
      editGeneralInfo: function editGeneralInfo() {
        this.set('isShowingGeneralModal', true);
      },
      editContactInfo: function editContactInfo() {
        this.set('showContactInfo', true);
      },
      cancelEditContactInfo: function cancelEditContactInfo() {
        this.set('showContactInfo', false);
      },
      editPrivacyRules: function editPrivacyRules() {
        this.set('isShowingPrivacyModal', true);
      },
      toggleLinks: function toggleLinks() {
        this.toggleProperty('showLinkDropdown');
      },
      goToLink: function goToLink(href) {
        if (href.indexOf('http://') !== -1 || href.indexOf('https://') !== -1) {
          window.open(href, '_blank');
        } else {
          window.open('http://' + href, '_blank');
        }
      },
      confirmGeneral: function confirmGeneral() {
        var _this = this;

        var notification = this.get('notification');
        var group = this.get('model');
        group.save().then(function (data) {
          Ember.Logger.debug('GROUP-PROFILE: group saved', data);

          _this.closeAllModals();

          if (!_lodash.default.isEmpty(data)) {
            if (data.job) {
              _this.handleJob(data.job);
            }
          }

          var title = group.get('name');

          var message = _this.get('intl').lookup('notification.group.general');

          notification.info(title, message, {
            forceBrowserNotification: true,
            hideAfter: 5
          });
        }).catch(function (e) {
          Ember.Logger.error('group save failed', {
            error: e
          });
          var title = group.get('name');

          var message = _this.get('intl').lookup('notification.group.saveError');

          notification.error(title, message, {
            forceBrowserNotification: true
          });
          group.reload();
        });
        this.send('setHasLinks');
      },
      cancelGeneral: function cancelGeneral() {
        this.get('model').reload();
        this.closeAllModals();
      },
      confirm: function confirm() {
        var _this2 = this;

        var notification = this.get('notification');
        var model = this.get('model');

        if (model.get('owners.owners.length') === 0) {
          var title = this.get('model.name');
          var message = this.get('intl').lookup('notification.group.noOwners').toString();
          notification.info(title, message, {
            icon: '',
            hideAfter: 8
          });
          model.reload();
        } else {
          this.set('isLoading', true);
          model.save().then(function () {
            var title = _this2.get('model.name');

            var message = _this2.get('intl').lookup('notification.group.general').toString();

            notification.show(title, message, {
              hideAfter: 8
            });

            var job = _this2.store.metadataFor('group').job;

            _this2.handleJob(job);

            _this2.set('isLoading', false);
          });
        }

        return false;
      },
      noop: function noop() {},
      deleteGroup: function deleteGroup() {
        this.set('isShowingDeleteModal', true);
      },
      cancelDelete: function cancelDelete() {
        this.closeAllModals();
      },
      confirmDelete: function confirmDelete() {
        var _this3 = this;

        var notification = this.get('notification');
        var model = this.get('model');
        var id = model.get('id');
        var groupType = model.get('groupType');
        var person = this.get('session.person');
        model.deleteRecord();
        model.save().then(function (group) {
          Ember.Logger.debug('GROUP-PROFILE: Deleted Group: ', group);
          var title = group.get('name');

          var message = _this3.get('intl').lookup('notification.group.deleting');

          notification.info(title, message, {
            icon: '',
            hideAfter: 8
          });

          _this3.closeAllModals();

          window.history.back();
        }).then(function () {
          if (groupType) {
            var type = groupType.toLowerCase();
            var groups = person.get("groups.".concat(type)) || [];
            var groupData = groups.findBy('value', id);
            groups.removeObject(groupData);
          }
        }).catch(function (e) {
          Ember.Logger.error('GROUP-PROFILE: Error deleting group', {
            error: e
          });
          model.set('state', 'active');
          var title = model.get('name');

          var message = _this3.get('intl').lookup('notification.group.deleteError');

          notification.error(title, message, {
            icon: ''
          });
        });
      },
      setHasLinks: function setHasLinks() {
        var links = this.get('model.general.links');

        if (links && links !== undefined) {
          this.set('hasLinks', links.length > 0 && links[0].value !== '');
        } else {
          this.set('hasLinks', false);
        }
      },
      makeCall: function makeCall() {
        this.get('phoneService').makeCall(this.get('phoneNumber'));
      },
      confirmWarningModal: function confirmWarningModal() {
        this.set('model.membership.exclusions', []);
        this.set('model.membership.inclusions', []);
        this.set('displayWarningModal', false);
        this.set('callsEnabled', true);
        this.saveCallsEnabledState();
      },
      closeWarningModal: function closeWarningModal() {
        this.set('displayWarningModal', false);
      },
      toggleGroupProfileEditPopover: function toggleGroupProfileEditPopover(status) {
        this.set('showDropdown', !status);
      }
    },
    closeAllModals: function closeAllModals() {
      this.setProperties({
        isShowingDeleteModal: false,
        isShowingPrivacyModal: false,
        isShowingGeneralModal: false
      });
    },
    ensureCorrectLinksState: Ember.on('init', function () {
      run.scheduleOnce('afterRender', this, function () {
        this.send('setHasLinks');
      });
    }),
    groupProfileEditFeatureToggleEnabled: Ember.computed.readOnly('session.features.epcDecompDropDownGroupProfileEdit'),
    membersTabSelected: Ember.computed.equal('tabSelected', 'members'),
    settingsTabSelected: Ember.computed.equal('tabSelected', 'settings'),
    generalFieldConfigSection: Ember.computed('groupRingVisible', function () {
      var config = this.get('model.fieldConfig');
      var fields = [config.get('general.name'), config.get('general.groupType'), config.get('general.description'), config.get('general.links'), config.get('membership.includeOwners'), config.get('contactInfo.email')];

      var fieldSection = _fieldSection.default.create({
        key: 'general',
        state: 'active',
        fieldConfig: this.get('model.fieldConfig'),
        instructionText: null,
        fieldList: fields
      }); // Override setting general section on non-general fields


      fieldSection.get('includeOwners').set('section', config.get('membership'));
      fieldSection.get('email').set('section', config.get('contactInfo'));
      return fieldSection;
    }),
    privacyFieldConfigSection: Ember.computed(function () {
      var config = this.get('model.fieldConfig');
      var fields = [config.get('privacy.groupVisibility'), config.get('privacy.membershipVisible')];
      return _fieldSection.default.create({
        key: 'privacy',
        state: 'active',
        fieldConfig: config,
        instructionText: null,
        fieldList: fields
      });
    }),
    linksFieldSection: Ember.computed('model.general.links.@each.value', function () {
      var config = this.get('model.fieldConfig');
      var fields = [config.get('general.links')];
      return _fieldSection.default.create({
        key: 'general',
        state: 'active',
        fieldConfig: config,
        instructionText: null,
        fieldList: fields
      });
    }),
    callsEnabled: Ember.computed('model.contactInfo.allowCallConfiguration.firstObject.value', 'phoneEnabled', {
      get: function get() {
        return this.get('phoneEnabled') && this.get('model.contactInfo.allowCallConfiguration.firstObject.value');
      },
      set: function set(key, value) {
        return value;
      }
    }),
    hasRules: Ember.computed('model.membership.inclusions', 'model.membership.exclusions', function () {
      return !_lodash.default.isEmpty(this.get('model.membership.inclusions')) || !_lodash.default.isEmpty(this.get('model.membership.exclusions'));
    }),
    phoneNumber: Ember.computed('model.contactInfo.groupNumber.@each.value', function () {
      return this.get('model.contactInfo.groupNumber.firstObject');
    }),
    dialableNumber: Ember.computed('phoneNumber', function () {
      return this.get('addressformat').format(this.get('phoneNumber'));
    }),
    phoneRef: Ember.computed('model.contactInfo.groupNumber.@each.value', function () {
      var number = this.get('model.contactInfo.groupNumber.firstObject.rel.e164');
      return "tel:#".concat(number);
    }),
    name: Ember.computed('model.general.name.@each.value', function () {
      return this.get('model').getFieldValue('general.name');
    }),
    description: Ember.computed('model.general.description.@each.value', function () {
      return this.get('model').getFieldValue('general.description');
    }),
    groupType: Ember.computed('model.general.groupType.@each.value', function () {
      return this.get('model').getFieldValue('general.groupType');
    }),
    groupVisibility: Ember.computed('model.privacy.groupVisibility.@each.value', function () {
      return this.get('model').getFieldValue('privacy.groupVisibility');
    }),
    membershipVisible: Ember.computed('model.privacy.membershipVisible.@each.value', function () {
      return this.get('model').getFieldValue('privacy.membershipVisible');
    }),
    includeOwners: Ember.computed('model.membership.includeOwners.@each.value', function () {
      return this.get('model').getFieldValue('membership.includeOwners');
    }),
    email: Ember.computed('model.contactInfo.email.@each.value', function () {
      return this.get('model').getFieldValue('contactInfo.email');
    }),
    groupTypeIcon: Ember.computed('model.general.groupType', function () {
      var groupType = this.get('model').getFieldValue('general.groupType').value;
      var icon = 'pc pc-official-group';

      if (groupType === 'groups.social') {
        icon = 'pc pc-social-group';
      }

      return icon;
    }),
    groupOwners: Ember.computed('model.owners.owners.[]', function () {
      var owners = this.get('model').getFieldValue('owners.owners');
      var promise = RSVP.resolve([]);

      if (owners) {
        promise = this.store.findMany('person', owners.mapBy('guid'), _person.BASE_PERSON);
      }

      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    membershipHeader: Ember.computed('model.memberCount', function () {
      var count = this.get('model.memberCount');
      return this.get('intl').lookup('groups.membershipWithCount', {
        count: count
      }).toString();
    }),
    groupRolesHeader: Ember.computed('model.uniqueRoleCount', function () {
      var count = this.get('model.uniqueRoleCount');
      return this.get('intl').lookup('groups.roles.rolesWithCount', {
        count: count
      }).toString();
    }),
    sections: Ember.computed('fieldSections', function () {
      return this.get('fieldSections');
    }),
    canEditLeftRailInfo: Ember.computed.alias('canEditInformation'),
    canEditProfilePicture: Ember.computed.alias('canEditInformation'),
    canEditInformation: Ember.computed('model.owners.owners.[]', 'session.person', 'session.person.isAdmin', 'permissions.canEditGroups', function () {
      var namedPermissions = this.get('session.namedPermissions') || [];
      var isOwner = this.get('model').isPersonOwner(this.get('session.person'));
      var canEditGroups = this.get('permissions.canEditGroups');
      return isOwner || canEditGroups || this.get('session.person.isAdmin') || _lodash.default.includes(namedPermissions, 'group_administration');
    }),
    canEditGroupTelephonySettings: Ember.computed.readOnly('permissions.canEditGroupTelephonySettings', function () {
      return this.get('permissions.canEditGroupTelephonySettings');
    }),
    disableToggleCalls: Ember.computed.not('canEditGroupTelephonySettings'),
    canEditRoles: Ember.computed('session.namedPermissions', function () {
      var hasRolesView = this.get('session').hasPermissionAndDivision('authorization:role:view');
      var hasGrantsEdit = this.get('session').hasPermissionAndDivision('authorization:grant:add');
      return hasRolesView.hasPermission && hasGrantsEdit.hasPermission;
    }),
    canDeleteGroup: Ember.computed.reads('permissions.canDeleteGroup'),
    updateLinksChange: Ember.observer('model.general.links.@each.value', function () {
      this.send('setHasLinks');
    }),
    setHasInformals: Ember.observer('model.images.gallery', 'model.images.gallery.length', function () {
      var informals = this.get('model.images.gallery');

      if (informals && informals !== undefined) {
        this.set('hasInformals', informals.length > 0);
      } else {
        this.set('hasInformals', false);
      }
    }),
    checkMembers: Ember.observer('model.membership', function () {
      var exclusions = this.get('model.membership.exclusions') !== undefined;
      var inclusions = this.get('model.membership.inclusions') !== undefined;
      var canEdit = this.get('canEditMembership.labelKey') === 'owners' || this.get('session.person.isAdmin');
      var value = exclusions || inclusions || canEdit;
      this.set('showMembers', value);
    }),
    canEditMembership: Ember.computed(function () {
      var _this4 = this;

      var owners = this.get('model').getFieldValue('owners.owners');

      var isOwner = _lodash.default.find(owners, function (owner) {
        return owner.value === _this4.get('session.person.id');
      });

      return isOwner || this.get('session.person.isAdmin');
    }),
    disableAction: Ember.computed({
      get: function get() {
        return this.setConfirmDisabled.bind(this);
      },
      set: function set() {
        return this.setConfirmDisabled.bind(this);
      }
    }),
    saveCallsEnabledState: function saveCallsEnabledState() {
      var _this5 = this;

      var model = this.get('model');
      var callsEnabled = this.get('callsEnabled');

      if (!callsEnabled) {
        model.set('contactInfo.groupNumber', [], {
          overwrite: true
        });
      }

      model.set('contactInfo.allowCallConfiguration', callsEnabled);
      return model.save().then(function () {
        model.get('policy').then(function (policy) {
          if (!_this5.get('callsEnabled')) {
            _this5.get('store').unloadRecord(policy);

            _this5.get('store').findRecord('group-policy', model.get('guid')).then(function (policy) {
              _this5.set('policy', policy);
            });
          }
        });
      }).catch(function () {
        _this5.toggleProperty('callsEnabled');

        var title = _this5.get('model.name');

        var message = _this5.get('intl').lookup('groups.groupRing.enableCallsError');

        _this5.get('notification').error(title, message, {
          forceBrowserNotification: true
        });

        return Ember.RSVP.reject();
      });
    },
    setConfirmDisabled: function setConfirmDisabled(value) {
      return this.set('confirmDisabled', value);
    },
    handleJob: function handleJob(job) {
      var _this6 = this;

      if (job) {
        job.interval = window.setInterval(function () {
          _this6.getJobData(job);
        }, 2000);
      }
    },
    getJobData: function getJobData(job) {
      var _this7 = this;

      return this.get('ajax').request(jobEndpoint(job.jobId)).then(function (data) {
        data = data.res;
        var retryJobId = data.retryJobId;

        if (data.status !== 'in-progress' && data.status !== 'pending') {
          window.clearInterval(job.interval);

          if (data.status === 'retry') {
            job.jobId = retryJobId;

            _this7.handleJob(job);
          } else if (job.status !== 'failed') {
            _this7.get('model').reload();
          }
        }
      }).catch(function () {
        window.clearInterval(job.interval);

        _this7.get('model').reload();
      });
    },
    commitRevertHandler: function commitRevertHandler(data) {
      var model = this.get('model');
      model.set('version', data.res.version); // Ember-Data is doing something weird and not marking as saved, so we do this manually

      model.save().then(function (group) {
        group.adapterDidCommit();
      });
    }
  });
  var _default = GroupProfileComponent;
  _exports.default = _default;
});