define("web-directory/mixins/search", ["exports", "ember-data", "lodash", "ember-ajax", "web-directory/utils/external-contact-messaging"], function (_exports, _emberData, _lodash, _emberAjax, _externalContactMessaging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ThenableSearchMixin = _exports.TagSearchMixin = _exports.SearchMixin = _exports.HelpMixin = void 0;
  var SEARCH_ALL = '*';
  var TAGS_ENDPOINT = '/directory/api/v2/tags/skills.skills,skills.certs,general.department,general.title';
  var CANCELLATION_ERROR = 'Request Cancelled';

  function _performSearch() {
    var _this = this;

    if (this.get('isDestroyed') || this.get('isDestroying')) {
      return;
    }

    var value;

    if (this.get('searchQuery.length') > 0) {
      value = this.get('searchQuery');
    } else if (this.get('searchOnEmpty')) {
      value = SEARCH_ALL;
    } else {
      if (this._state !== 'inDOM') {
        return;
      }

      this.get('presenceSubscription').autoSubscribe(null, this, 'search');
      this.set('searchResults', []);
      return;
    }

    var deferred = this.get('pendingSearchRequest');

    if (deferred) {
      deferred.reject(CANCELLATION_ERROR);
    }

    deferred = Ember.RSVP.defer();
    this.set('pendingSearchRequest', deferred);
    var requestArray = [];
    var queueSearchRequest;
    var queuePermission = this.get('permissions.canViewQueue');
    var relatePermission = this.get('permissions.hasExternalContactsViewContactAccess');

    if (this.get('searchQueueOnly') && queuePermission) {
      var queueSearchData = {
        name: "**".concat(value, "**"),
        pageNumber: 1,
        pageSize: this.get('searchLimit')
      };
      var divisionAgnostic = this.get('divisionAgnostic');
      var myQueuesOnly = this.get('myQueuesOnly');

      if (divisionAgnostic) {
        queueSearchData.name = "**".concat(value, "**");
        queueSearchData.divisionAgnostic = true;
      }

      if (myQueuesOnly) {
        queueSearchData.myQueuesOnly = myQueuesOnly;
      }

      queueSearchRequest = this.get('store').query('queue', queueSearchData);
      requestArray.push(queueSearchRequest);
    } else if (this.get('searchRelate') && this.get('searchRelateOnly') && relatePermission) {
      queueSearchRequest = this.get('store').query('externalcontacts/contact', {
        searchTerm: "".concat(value),
        pageSize: this.get('searchLimit'),
        pageNumber: 1,
        originator: this.get('componentClassName'),
        expandField: 'externalOrganization'
      });
      requestArray.push(queueSearchRequest);
    } else {
      if (this.get('searchQueueOnly')) {
        return;
      }

      var query = [{
        value: value,
        type: 'QUERY_STRING'
      }]; // should be the ucIntegrationKey, will be null unless set

      var searchIntegrationKey = this.get('searchIntegrationKey');

      if (searchIntegrationKey) {
        query.push({
          type: 'EXACT',
          fields: ['integration'],
          value: searchIntegrationKey
        });
      }

      var request = null;
      var userRequest = this.get('store').query('public-api-user', {
        query: query,
        sortBy: 'name',
        sortOrder: 'ASC'
      });
      request = userRequest.then(function (entities) {
        var resultArray = entities.content || [];
        var userIds = resultArray.map(function (entry) {
          return entry.id;
        });
        return _this.get('store').findMany('person', userIds);
      });
      requestArray.push(request);

      if (this.get('searchQueue') && queuePermission) {
        var queueSearchQuery;

        if (this.get('divisionAgnostic')) {
          queueSearchQuery = {
            name: value,
            pageNumber: 1,
            pageSize: this.get('searchLimit'),
            divisionAgnostic: true
          };
        } else {
          queueSearchQuery = {
            name: "**".concat(value, "**"),
            pageNumber: 1,
            pageSize: this.get('searchLimit')
          };
        }

        queueSearchRequest = this.get('store').query('queue', queueSearchQuery);
        requestArray.push(queueSearchRequest);
      }

      if (this.get('searchRelate') && relatePermission) {
        queueSearchRequest = this.get('store').query('externalcontacts/contact', {
          searchTerm: "".concat(value),
          pageSize: this.get('searchLimit'),
          originator: this.get('componentClassName'),
          pageNumber: 1,
          expandField: 'externalOrganization'
        });
        requestArray.push(queueSearchRequest);
      }
    }

    Ember.RSVP.allSettled(requestArray).then(function (promises) {
      var results = [];
      promises.forEach(function (promise) {
        if (promise.state === 'rejected') {
          Ember.Logger.warn('Failed to load search request', {
            reason: promise.reason
          });
        } else {
          results.push(promise.value);
        }
      });

      if (results.length === 0) {
        deferred.reject(new Error('All search requests failed'));
      } else {
        deferred.resolve(results);
      }
    });
    deferred.promise.then(function (entities) {
      var results = _lodash.default.map(entities, function (entity) {
        if (!Array.isArray(entity)) {
          return entity.get('content').map(function (x) {
            return x.getRecord();
          });
        } else {
          return entity;
        }
      });

      if (entities.length) {
        _this.set('searchMeta', _lodash.default.head(entities).meta);
      }

      entities = _lodash.default.flatten(results);
      var people = entities.filterBy('type', 'person');

      if (people.length > 0) {
        _this.get('presenceSubscription').autoSubscribe(people, _this, 'search');
      }

      _this.set('searchResults', entities);

      if (_lodash.default.isEmpty(entities)) {
        _this.notifyPropertyChange('searchResults');
      }

      return entities;
    }).finally(function () {
      _this.set('pendingSearchRequest', null);
    });
    this.set('currentSearchRequest', _emberData.default.PromiseArray.create({
      promise: deferred.promise
    }));
  }

  function performTagSearch() {
    var _this2 = this;

    var request = tagSearchRequest(this.get('tagSearchQuery'));
    request.then(function (results) {
      _this2.set('tagSearchResults', results);

      return results;
    });
    this.set('tagSearchRequest', _emberData.default.PromiseObject.create({
      promise: request
    }));
  }

  function tagSearchRequest(query) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      (0, _emberAjax.default)(TAGS_ENDPOINT, {
        type: 'GET',
        data: {
          q: query
        }
      }).then(function (data) {
        var results = data.res;

        if (results.length !== 0) {
          _lodash.default.each(results, function (result) {
            var tag = result.field.substring(result.field.indexOf('.') + 1);
            result.tag = tag;
          });
        }

        resolve(results);
      }, reject);
    }, 'SearchMixin#tagSearchRequest');
  }

  var SearchMixin = Ember.Mixin.create({
    componentClassName: Ember.computed(function () {
      return _externalContactMessaging.componentClassName.call(this);
    }),
    presenceSubscription: Ember.inject.service('presence-subscription'),
    store: Ember.inject.service(),
    permissions: Ember.inject.service(),
    session: Ember.inject.service(),
    searchQuery: null,
    entityFilter: null,
    searchQueue: false,
    searchQueueOnly: false,
    saveHistory: true,
    searchOnEmpty: false,
    searchLimit: 25,
    pendingSearchRequest: null,
    currentSearchRequest: null,
    searchIntegrationKey: null,
    searchResults: Ember.computed(function () {
      return [];
    }),
    performSearch: Ember.observer('searchQuery', 'entityFilter', function () {
      Ember.run.debounce(this, _performSearch, 200);
    })
  });
  _exports.SearchMixin = SearchMixin;
  var ThenableSearchMixin = Ember.Mixin.create({
    componentClassName: Ember.computed(function () {
      return _externalContactMessaging.componentClassName.call(this);
    }),
    store: Ember.inject.service(),
    presenceSubscription: Ember.inject.service('presence-subscription'),
    searchQuery: null,
    entityFilter: null,
    searchQueue: false,
    searchQueueOnly: false,
    saveHistory: true,
    searchOnEmpty: false,
    searchLimit: 25,
    pendingSearchRequest: null,
    currentSearchRequest: null,
    performSearch: function performSearch(searchQuery, entityFilter) {
      this.set('searchQuery', searchQuery);
      this.set('entityFilter', entityFilter);

      _performSearch.apply(this);

      return this.get('currentSearchRequest');
    }
  });
  _exports.ThenableSearchMixin = ThenableSearchMixin;
  var TagSearchMixin = Ember.Mixin.create({
    tagSearchQuery: null,
    tagSearchRequest: null,
    tagSearchResults: Ember.computed(function () {
      return [];
    }),
    performTagSearch: Ember.observer('tagSearchQuery', function () {
      Ember.run.debounce(this, performTagSearch, 150);
    })
  }); // TODO: Remove this after toggle is flipped

  _exports.TagSearchMixin = TagSearchMixin;
  var HelpMixin = Ember.Mixin.create({
    searchQuery: null,
    searchResults: null,
    request: null,
    _performHelpSearch: function _performHelpSearch() {
      var _this3 = this;

      var searchQuery = this.get('searchQuery');

      if (searchQuery) {
        var request = new Ember.RSVP.Promise(function (resolve, reject) {
          Ember.$.ajax({
            type: 'GET',
            cache: false,
            url: 'https://help.mypurecloud.com/purecloud-search-api.php',
            dataType: 'json',
            data: {
              q: searchQuery
            },
            success: function success(data) {
              var results = data ? Ember.get(data, 'results') : null;

              _lodash.default.forEach(results, function (result) {
                if (result.excerpt) {
                  result.excerpt = result.excerpt.replace(/(<([^>]+)>)/gi, '').replace(/&quot;/g, '"').replace(/&#39;/g, "'").replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&').replace(/&#8217;/g, "'");
                }
              });

              _this3.set('searchResults', results);

              resolve(data);
            },
            error: function error(_error) {
              Ember.Logger.log('Error accessing resourceCenter');
              reject(_error);
            }
          });
        });
        this.set('request', _emberData.default.PromiseObject.create({
          promise: request
        }));
      } else {
        this.set('searchResults', null);
      }
    },
    performHelpSearch: Ember.observer('searchQuery', function () {
      Ember.run.debounce(this, this._performHelpSearch, 150);
    })
  });
  _exports.HelpMixin = HelpMixin;
});