define("web-directory/components/feedback-section-v2/component", ["exports", "lodash", "web-directory/utils/dates"], function (_exports, _lodash, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['feedback-section-v2'],
    intl: inject.service(),
    application: inject.service(),
    phone: inject.service(),
    notification: inject.service(),
    session: inject.service(),
    subjectSearchString: null,
    isSubmitting: false,
    isDisabled: computed.or('emptyFields', 'isSubmitting'),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('natureType', this.get('natureTypes.0.data'));
      this.set('requestPriority', this.get('requestPriorities.2.data'));
      this.set('functionalityType', this.get('functionalityTypes.5.data'));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, this._addBehavior);
    },
    _addBehavior: function _addBehavior() {
      return this.$('input.subject').trigger('focus');
    },
    distList: computed.reads('session.publicApiUser.organization.supportURI'),
    natureType: null,
    natureTypes: computed(function () {
      var intl = this.get('intl');
      return [{
        text: intl.lookup('components.navMenu.feedback.natureTypes.problem'),
        data: 'PROBLEM'
      }, {
        text: intl.lookup('components.navMenu.feedback.natureTypes.question'),
        data: 'QUESTION'
      }, {
        text: intl.lookup('components.navMenu.feedback.natureTypes.enhancementRequest'),
        data: 'ENHANCEMENT REQUEST'
      }];
    }),
    requestPriority: null,
    requestPriorities: computed(function () {
      var intl = this.get('intl');
      return [{
        text: intl.lookup('components.navMenu.feedback.requestPriorities.critical'),
        data: 'CRITICAL'
      }, {
        text: intl.lookup('components.navMenu.feedback.requestPriorities.high'),
        data: 'HIGH'
      }, {
        text: intl.lookup('components.navMenu.feedback.requestPriorities.medium'),
        data: 'MEDIUM'
      }, {
        text: intl.lookup('components.navMenu.feedback.requestPriorities.low'),
        data: 'LOW'
      }];
    }),
    functionalityType: null,
    functionalityTypes: computed(function () {
      var intl = this.get('intl');
      return [{
        text: intl.lookup('components.navMenu.feedback.functionalityTypes.admin'),
        data: 'ADMIN'
      }, {
        text: intl.lookup('components.navMenu.feedback.functionalityTypes.documents'),
        data: 'DOCUMENTS'
      }, {
        text: intl.lookup('components.navMenu.feedback.functionalityTypes.chat'),
        data: 'CHAT'
      }, {
        text: intl.lookup('components.navMenu.feedback.functionalityTypes.telephony'),
        data: 'TELEPHONY'
      }, {
        text: intl.lookup('components.navMenu.feedback.functionalityTypes.videoChat'),
        data: 'VIDEO CHAT'
      }, {
        text: intl.lookup('components.navMenu.feedback.functionalityTypes.contactCenter'),
        data: 'CONTACT CENTER'
      }, {
        text: intl.lookup('components.navMenu.feedback.functionalityTypes.login'),
        data: 'LOGIN'
      }, {
        text: intl.lookup('components.navMenu.feedback.functionalityTypes.slowness'),
        data: 'SLOWNESS'
      }, {
        text: intl.lookup('components.navMenu.feedback.functionalityTypes.other'),
        data: 'OTHER'
      }];
    }),
    subject: null,
    requestDescription: null,
    emptyFields: computed('subject', 'requestDescription', function () {
      return _lodash.default.isEmpty(this.get('subject')) || _lodash.default.isEmpty(this.get('requestDescription'));
    }),
    onLastConversationError: Ember.observer('phone.lastConversationError', function () {
      if (this.get('phone.lastConversationError')) {
        this.set('functionalityType', 'TELEPHONY');
        this.set('requestDescription', "\n\n----\n".concat((0, _dates.default)().format('MM/DD/YYYY HH:mm:ss'), "\n[").concat(this.get('phone.lastConversationError.errorInfo.code'), "]: ").concat(this.get('phone.lastConversationError.id'), "\n").concat(this.get('phone.lastConversationError.errorInfo.message')));
      }
    }),
    displayFeedbackForm: false,
    clearInput: function clearInput() {
      return this.setProperties({
        subject: '',
        requestDescription: ''
      });
    },
    getRequest: function getRequest() {
      var desktopOS, desktopVersion, isHosted;
      isHosted = this.get('application.hostedContext.isHostedProp');

      if (isHosted) {
        desktopOS = this.get('application.hostedContext._operatingSystem');
        desktopVersion = this.get('application.hostedContext._version');
      }

      return {
        subject: this.get('subject'),
        topic: this.get('functionalityType'),
        description: this.get('requestDescription'),
        priority: this.get('requestPriority'),
        type: this.get('natureType'),
        data: {
          userAgent: navigator.userAgent,
          clientLocation: window.location.toString(),
          clientTimestamp: (0, _dates.default)().format(),
          clientEmail: this.get('session.user.email'),
          clientOrgName: this.get('application.orgName'),
          clientLocale: this.get('intl.isoLocale'),
          clientType: isHosted ? 'Desktop - ' + desktopOS + ' - ' + desktopVersion : 'Browser',
          clientVersion: this.get('application.semver')
        }
      };
    },
    actions: {
      submit: function submit() {
        var _this = this;

        var intl = this.get('intl');
        var desktopNotification = this.get('notification');
        this.set('isSubmitting', true);
        return this.get('application').sendFeedback(this.getRequest()).then(function () {
          _this.clearInput();

          return desktopNotification.show({
            title: intl.lookup('components.navMenu.feedback.successHeader'),
            message: intl.lookup('components.navMenu.feedback.successTextV2')
          }, Ember.getOwner(_this));
        }).catch(function () {
          return desktopNotification.show({
            type: 'error',
            title: intl.lookup('components.navMenu.feedback.errorHeader').toString(),
            message: intl.lookup('components.navMenu.feedback.errorText').toString()
          }, Ember.getOwner(_this));
        }).finally(function () {
          _this.set('isSubmitting', false);
        });
      },
      cancel: function cancel() {
        this.set('displayFeedbackForm', false);
      },
      showContactForm: function showContactForm() {
        this.set('displayFeedbackForm', true);
      }
    }
  });

  _exports.default = _default;
});