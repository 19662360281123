define("web-directory/components/timeline-stream/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed,
      inject = Ember.inject;
  var CHAT_TYPE_MESSAGES = ['mention', 'direct-message', 'room-message'];

  var _default = Component.extend({
    classNames: ['timeline-stream'],
    intl: inject.service(),
    timeline: inject.service(),
    permissions: inject.service(),
    actions: {
      markAllRead: function markAllRead() {
        this.get('timeline').markAllRead();
      },
      clearAllEvents: function clearAllEvents() {
        this.get('timeline').clearAllEvents();
      }
    },
    newEvents: computed('timeline.events.@each.acknowledged', 'permissions.canViewBusinessChat', function () {
      var eventList = null;

      if (this.get('permissions.canViewBusinessChat')) {
        eventList = this.get('timeline.events');
      } else {
        eventList = this.get('timeline.events').filter(function (entity) {
          if (!CHAT_TYPE_MESSAGES.includes(Ember.get(entity, 'type'))) {
            return true;
          }
        });
      }

      return _lodash.default.reduce(eventList, function (count, event) {
        return event.get('acknowledged') ? count : count + 1;
      }, 0);
    }),
    allAcknowledged: computed('timeline.events.@each.acknowledged', function () {
      var events = this.get('timeline.events');

      if (!events.length) {
        return false;
      }

      var count = _lodash.default.reduce(events, function (count, event) {
        return event.get('acknowledged') ? count : count + 1;
      }, 0);

      return count === 0;
    }),
    updateString: computed('newEvents', function () {
      var intl = this.get('intl');
      var count = this.get('newEvents');
      var args = {
        count: count
      };

      if (count === 1) {
        return intl.lookup('timeline.updateCounter.single', args);
      } else {
        return intl.lookup('timeline.updateCounter.plural', args);
      }
    }),
    timelineSortedEvents: computed('permissions.canViewBusinessChat', 'timeline.sortedEvents.[]', function () {
      if (!this.get('permissions.canViewBusinessChat')) {
        return this.get('timeline.sortedEvents').filter(function (entity) {
          if (!CHAT_TYPE_MESSAGES.includes(Ember.get(entity, 'type'))) {
            return true;
          }
        });
      } else {
        return this.get('timeline.sortedEvents');
      }
    })
  });

  _exports.default = _default;
});