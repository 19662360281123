define("web-directory/components/acd-interactions-panel/profile-search-result/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ProfileSearchResultComponent = Ember.Component.extend({
    classNames: ['search-result'],
    classNameBindings: ['selected'],
    click: function click() {
      return this.sendAction('loadItem', this.get('result'));
    },
    selected: Ember.computed('selectedResult', 'result.id', 'selectedResult.id', function () {
      return this.get('result.id') === this.get('selectedResult.id');
    })
  });
  var _default = ProfileSearchResultComponent;
  _exports.default = _default;
});