define("web-directory/initializers/video-conference-details-service", ["exports", "web-directory/services/video-conference-details"], function (_exports, _videoConferenceDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'setupVideoConferenceDetailsService',
    after: 'setupChatService',
    initialize: function initialize(application) {
      application.register('service:video-conference-details', _videoConferenceDetails.default, {
        singleton: true,
        initialize: true
      });
      application.inject('service:video-conference-details', 'ajax', 'service:ajax');
      application.inject('service:video-conference-details', 'session', 'service:session');
      application.inject('service:video-conference-details', 'pigeon', 'service:pigeon');
      application.inject('service:video-conference-details', 'permissions', 'service:permissions');
      application.inject('service:video-conference-details', 'logger', 'service:stash-logger');
    }
  };
  _exports.default = _default;
});