define("web-directory/routes/engage/interaction-screenrecordings-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      var interactionId = transition.params[this.routeName].interaction_id;
      var timezone = transition.queryParams['timezone'];
      var locationURL = "/screen-recording-ui/#/interactions/".concat(interactionId);
      var isToggleEnabled = this.get('session.features') && this.get('session.features')['qm.release.pure-2967.timezone.selection'];

      if (timezone && isToggleEnabled) {
        locationURL = "".concat(locationURL, "?timezone=").concat(timezone);
      }

      window.location.replace(locationURL);
    }
  });

  _exports.default = _default;
});