define("web-directory/helpers/address-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    addressformat: Ember.inject.service(),
    compute: function compute(params) {
      if (!params || params.length !== 1) {
        Ember.Logger.warn('addressformat helper called with bad arguments');
        return '';
      }

      return this.get('addressformat').formatOrLookupAddressForDisplay(params[0]);
    }
  });

  _exports.default = _default;
});