define("web-directory/mixins/entity", ["exports", "ember-data", "ember-inflector", "lodash/cloneDeep", "lodash/find", "lodash/isEmpty", "lodash/keys", "lodash/parseInt", "lodash/sortBy"], function (_exports, _emberData, _emberInflector, _cloneDeep, _find, _isEmpty, _keys, _parseInt, _sortBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var profilePictureFieldKey = {
    person: 'images.profile',
    group: 'images.activeProfilePicture'
  };
  var imageMappings = {
    person: {
      profile: 'uploads.images',
      informal: 'images.informal',
      agent: 'agent.profileImage'
    },
    group: {
      profile: 'images.profilePictures',
      informal: 'images.gallery'
    }
  };
  var missingFieldsCache = {};
  var EntityMixin = Ember.Mixin.create({
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    _currentSection: null,
    _missingFieldsCache: missingFieldsCache,
    _id: _emberData.default.attr(),
    _entity: _emberData.default.attr(),
    guid: _emberData.default.attr(),
    state: _emberData.default.attr(),
    version: _emberData.default.attr().volatile(),
    mt: _emberData.default.attr(),
    apps: _emberData.default.attr(),
    orgId: _emberData.default.attr(),
    init: function init() {
      Ember.set(this, '_initialData', {});
      Ember.set(this, '_previousData', {});
      Ember.set(this, '_changedSinceSave', []);

      this._super();
    },
    name: Ember.computed('general.name.@each.value', function () {
      return this.getFieldValue('general.name').value;
    }),
    type: Ember.computed(function () {
      if (typeof this.fieldConfig === 'undefined') {
        return '';
      }

      return this.fieldConfig.entityType;
    }),
    profilePicture: Ember.computed('images.profile', 'images.profile.[]', 'images.activeProfilePicture.[]', 'images.profile.@each.ref', function () {
      var pictureKey = profilePictureFieldKey[this.get('type')];
      var fieldValue = this.getFieldValue(pictureKey);

      if (!(0, _isEmpty.default)(fieldValue)) {
        return fieldValue.value;
      } else {
        fieldValue = this.getFieldValue(pictureKey, true);

        if (!(0, _isEmpty.default)(fieldValue.ref)) {
          return fieldValue.ref;
        }
      }

      return null;
    }),
    profilePictureSizes: Ember.computed('profilePicture', function () {
      var profilePicture = this.get('profilePicture');

      if (profilePicture) {
        // Strip off the 'x' in the keys and sort the available sizes.
        return (0, _sortBy.default)((0, _keys.default)(profilePicture).map(function (item) {
          return (0, _parseInt.default)(item.substr(1));
        }));
      }

      return [];
    }),
    uploadedImages: Ember.computed('uploads.images.[]', function () {
      var type = this.get('type');
      var path = imageMappings[type].profile;
      return this.get(path);
    }),
    stateString: Ember.computed('state', function () {
      return this.get('intl').lookup("item.state.".concat(this.get('data.state'))).toString();
    }),
    _self: Ember.computed(function () {
      return this;
    }),
    _missingFields: Ember.computed('_missingFieldsCache', function () {
      return missingFieldsCache[this.get('id')] || [];
    }),
    recordPropertyChange: function recordPropertyChange(fieldPath) {
      var changedSinceSave = this.get('_changedSinceSave');
      var missingFields = this.get('_missingFields');

      if (missingFields.indexOf(fieldPath) !== -1) {
        missingFields.removeObject(fieldPath);
      }

      changedSinceSave.addObject(fieldPath);
      this.notifyPropertyChange(fieldPath);
    },
    changedProperties: function changedProperties() {
      var changedSinceSave = this.get('_changedSinceSave');
      var missingFields = this.get('_missingFields');
      return (changedSinceSave || []).filter(function (field) {
        return missingFields.indexOf(field) < 0;
      });
    },
    getReferencedField: function getReferencedField(fieldPath) {
      var field = this.getFieldValue(fieldPath, true);

      if (field.value && field.value.fieldId !== 'NONE') {
        var referencedField = this.getFieldValue(field.value.fieldPath, true);

        if (Ember.isArray(referencedField) && referencedField.length > 0) {
          return (0, _find.default)(referencedField, {
            _id: field.value.fieldId
          });
        } else if (!(0, _isEmpty.default)(referencedField)) {
          return referencedField;
        } else if (field.ref) {
          // If the reference field path is not loaded, try the field.ref value.
          return {
            value: field.ref
          };
        }

        return referencedField;
      }

      return {};
    },
    getFieldValue: function getFieldValue(fieldPath, referencedLookup) {
      if (fieldPath && typeof this.fieldConfig !== 'undefined') {
        var fieldArray = Ember.get(this, fieldPath);
        var repeatable = this.fieldConfig.isFieldRepeatable(fieldPath);
        var reference = this.fieldConfig.isFieldReference(fieldPath);

        if (!(0, _isEmpty.default)(fieldArray)) {
          if (reference && !referencedLookup) {
            return this.getReferencedField(fieldPath);
          } else if (repeatable) {
            return fieldArray;
          } else {
            return fieldArray[0];
          }
        }

        return repeatable ? [] : {};
      }

      return [];
    },
    isFieldValueEmpty: function isFieldValueEmpty(fieldPath) {
      if (fieldPath) {
        var fieldArray = Ember.get(this, fieldPath);

        if (fieldArray && fieldArray.length > 0) {
          return !fieldArray.some(function (entry) {
            return entry && entry.value;
          });
        }
      }

      return true;
    },
    removeFieldValue: function removeFieldValue(fieldPath, value) {
      var repeatable = this.fieldConfig.isFieldRepeatable(fieldPath);
      var currentFieldValue = this.getFieldValue(fieldPath);

      if (repeatable) {
        var object = (0, _find.default)(currentFieldValue, value);

        if (object && object !== -1) {
          currentFieldValue.removeObject(object);
        }
      }
    },
    reset: function reset() {
      var _this = this;

      var promise = this.reload(); // For some reason, we need to keep the state updated manually

      promise.then(function (record) {
        if (_this.currentState.stateName.indexOf('uncommitted') < 0) {
          _this.adapterDidCommit();
        }

        return record;
      });
      return promise;
    },
    save: function save() {
      var state = this.get('currentState');

      if (state.stateName.includes('deleted')) {
        this.set('isDeleted', true);
      }

      var promise = this._super();

      promise.then(this.onSaveSuccess.bind(this)).catch(this.onSaveError.bind(this));
      return promise;
    },
    onSaveSuccess: function onSaveSuccess(entity) {
      var copy = (0, _cloneDeep.default)(this.get('data'));
      this.set('_initialData', copy); // Clear historical data

      this.set('_previousData', {});
      this.set('versionError', false);

      if (entity._internalModal) {
        var internal = entity._internalModel;

        if (internal.adapterDidCommit) {
          internal.adapterDidCommit();
        }
      }

      return entity;
    },
    onSaveError: function onSaveError(error) {
      var _this2 = this;

      var data = error.responseJSON || {};
      var publicApiError = error.errors && error.errors[0];

      if (publicApiError && publicApiError.title && publicApiError.title.indexOf('version') > -1) {
        // Try to extract error from text inside the public api response
        var regex = /^.*\[(\d+)\].*\[(\d+)\].*$/i;
        var matches = publicApiError.title.match(regex);

        if (matches.length === 3) {
          Ember.Logger.info('Version mismatch, retrying with correct version:', publicApiError);
          this.set('versionError', true);
          var version = matches[2];
          this.set('version', version);
          return Ember.RSVP.resolve(this.save()).then(this.onSaveSuccess.bind(this)).catch(this.onSaveError.bind(this));
        }
      } else if (data && data.payload || Ember.get(error, 'errors.length')) {
        // Check error payload from directory-style error response
        var payload = data.payload || Ember.get(error, 'errors.0.payload') || {};

        if (payload.code === 'VERSION_MISMATCH') {
          Ember.Logger.info('Version mismatch, retrying with correct version:', payload);
          this.set('versionError', true);
          this.set('version', payload.currentVersion);
          return Ember.RSVP.resolve(this.save()).then(this.onSaveSuccess.bind(this)).catch(this.onSaveError.bind(this));
        } else {
          return Ember.RSVP.reject(error);
        }
      } else {
        Ember.Logger.error("Couldn't save field config", error);
        return new Ember.RSVP.Promise(function (_, reject) {
          _this2.reload().then(function () {
            return reject(error);
          });
        });
      }
    },
    saveSection: function saveSection(section) {
      var _this3 = this;

      this.set('_currentSection', section);
      return this.save().catch(function (response) {
        if (response && response.errors) {
          var error = response.errors[0];

          if (error && Ember.get(error, 'payload.code') === 'VERSION_MISMATCH') {
            return Ember.RSVP.resolve(response);
          }
        }

        return Ember.RSVP.reject(response);
      }).finally(function () {
        return _this3.set('_currentSection', null);
      });
    },
    set: function set(field, value, options) {
      options = options || {};
      var overwrite = options.overwrite !== undefined ? options.overwrite : undefined;
      var recordChange = options.recordChange !== undefined ? options.recordChange : true;

      if (field.indexOf('.') === -1 || overwrite === true) {
        return this._super(field, value);
      }

      var sectionKey = field.split('.')[0];

      if (this.get(field) === undefined) {
        if (!this.fieldConfig.getFieldByKey(field)) {
          // This is getting loud...
          // Ember.Logger.error(`The field "${field}" does not exist on entity`, this);
          return undefined;
        }

        if (!this.get(sectionKey)) {
          this._super(sectionKey, {});
        }
      } // Delegate to Ember when not dealing with our own field config


      if (!this.fieldConfig.getSectionByKey(sectionKey)) {
        return this._super(field, value);
      }

      var currentField = this.getFieldValue(field);
      var fieldDef = this.fieldConfig.getFieldByKey(field);

      if (this.fieldConfig.isFieldRepeatable(field)) {
        var data = {
          tempId: Ember.generateGuid(),
          value: value,
          labelKey: fieldDef.labelKeys[0]
        };

        if (currentField && currentField.length > 0) {
          currentField.addObject(data);
        } else {
          currentField = [data];
        }
      } else {
        if (currentField && currentField._id) {
          Ember.set(currentField, 'value', value);
        } else {
          currentField = {
            tempId: Ember.generateGuid(),
            value: value,
            labelKey: fieldDef.labelKeys[0]
          };
        }

        currentField = [currentField];
      }

      if (recordChange) {
        this.recordPropertyChange(field);
      }

      return this._super(field, currentField);
    },
    getPermissableSections: function getPermissableSections() {},
    getReadableSections: function getReadableSections() {},
    getWriteableSections: function getWriteableSections() {},
    getReadableFieldsForSection: function getReadableFieldsForSection() {},
    getWriteableFieldsForSection: function getWriteableFieldsForSection() {},
    deleteField: function deleteField(fieldPath, fieldId) {
      var _this4 = this;

      var id = this.get('id');
      var field = this.getFieldValue(fieldPath);

      if (!fieldId) {
        fieldId = field._id;
      }

      var version = this.get('version');
      var type = (0, _emberInflector.pluralize)(this.get('type'));
      var path = "/directory/api/v2/".concat(type, "/").concat(id, "/").concat(version, "/field/").concat(fieldPath, "/").concat(fieldId);
      return this.get('ajax').del(path).then(function (data) {
        _this4.set('version', data.rel.version);
      });
    },
    linkUploadedImage: function linkUploadedImage(image, crop, imageInfo, uploadType) {
      var _this5 = this;

      var type = this.get('type');
      var path = "/directory/api/v2/images/".concat(image._id, "/link/").concat(type, "/").concat(this.get('id'), "/").concat(imageMappings[type][uploadType]);
      var bbox = null;
      var ratio = imageInfo.ratio;

      if (crop) {
        bbox = {
          x: Math.floor(crop.x / ratio),
          y: Math.floor(crop.y / ratio),
          x2: Math.floor(crop.x2 / ratio),
          y2: Math.floor(crop.y2 / ratio)
        };
      } else {
        bbox = {
          x: 0,
          y: 0,
          x2: Math.floor(imageInfo.width),
          y2: Math.floor(imageInfo.height)
        };
      }

      return this.get('ajax').post(path, {
        data: {
          labelKey: '',
          bbox: bbox
        }
      }).then(function (data) {
        var version = Ember.get(data, 'rel.version');

        if (version) {
          _this5.set('version', version);
        }
      });
    }
  });
  var _default = EntityMixin;
  _exports.default = _default;
});