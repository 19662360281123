define("web-directory/utils/async", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deferPromise = void 0;

  var deferPromise = function deferPromise() {
    var resolve = _lodash.noop;
    var reject = _lodash.noop;
    var promise = new Promise(function (res, rej) {
      resolve = res;
      reject = rej;
    });
    return {
      promise: promise,
      resolve: resolve,
      reject: reject
    };
  };

  _exports.deferPromise = deferPromise;
});