define("web-directory/components/group-profile/group-contact-panel/group-ring-settings/inline-number-input/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var run = Ember.run;

  var _default = Ember.Component.extend({
    classNames: ['inline-number-input'],
    classNameBindings: ['disabled:input-disabled'],
    attrs: {
      update: function update() {}
    },
    value: null,
    disabled: false,
    isValid: Ember.computed('value', function () {
      var regex = /^[0-9]+$/g;
      var value = this.get('value');
      var number = parseInt(value, 10);

      if (_lodash.default.isEmpty(value)) {
        return false;
      }

      return regex.test(value) && number > 0 && number < 100;
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      run.scheduleOnce('afterRender', this, function () {
        return _this.$('input').val(_this.get('value'));
      });
      run.scheduleOnce('afterRender', this, this.attachListeners);
      run.scheduleOnce('afterRender', this, this.disableInput);
    },
    willRender: function willRender() {
      this._super.apply(this, arguments);

      var input = this.$('input');

      if (input) {
        input.off();
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      run.scheduleOnce('afterRender', this, this.attachListeners);
      run.scheduleOnce('afterRender', this, this.disableInput);
      run.scheduleOnce('afterRender', this, this.setValue);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.$('input').off();
    },
    disableInput: function disableInput() {
      var disabled = this.get('disabled');
      this.$('input').attr('disabled', disabled);
      this.set('disabled', disabled);
    },
    attachListeners: function attachListeners() {
      var _this2 = this;

      var input = this.$('input');

      if (this.get('focused')) {
        input.focus().val(input.val());
      }

      input.on('input', function (event) {
        Ember.run(function () {
          var value = event.target.value;

          _this2.set('value', value);

          _this2.attrs.disableSave(!_this2.get('isValid'));

          _this2.attrs.update(value);
        });
      });
      input.on('focus', function () {
        Ember.run(function () {
          return _this2.set('focused', true);
        });
      });
      input.on('blur', function () {
        Ember.run(function () {
          return _this2.set('focused', false);
        });
      });
    },
    setValue: function setValue() {
      var input = this.$('input');
      var value = this.get('value');

      if (input && value) {
        input.val(value);
      }
    }
  });

  _exports.default = _default;
});