define("web-directory/helpers/group-ring-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    name: '',
    compute: function compute(params) {
      var _this = this;

      var groupId = params[0];

      if (!this.get('name')) {
        if (this.get('store').hasRecordForId('group', groupId)) {
          var group = this.get('store').recordForId('group', groupId);

          if (group && group.get('name')) {
            this.set('name', group.get('name'));
            this.recompute();
          }
        } else {
          this.get('store').find('group', groupId).then(function (group) {
            _this.set('name', group.get('name'));

            _this.recompute();
          });
        }
      }

      return this.get('name');
    }
  });

  _exports.default = _default;
});