define("web-directory/serializers/application", ["exports", "ember-data", "lodash", "ember-inflector", "web-directory/components/entity-fields/validators"], function (_exports, _emberData, _lodash, _emberInflector, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filterMissingFields = _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var filterMissingFields = function filterMissingFields(record, json) {
    _lodash.default.forEach(record.fieldConfig.sections, function (section) {
      _lodash.default.forEach(section.fieldList, function (field) {
        var fieldPath = field.get('fieldPath');
        var type = field.get('type');
        var values = record.getFieldValue(fieldPath);
        var splitField = fieldPath.split('.');
        var sectionKey = splitField[0],
            fieldKey = splitField[1];
        var container = json[sectionKey] ? json[sectionKey][fieldKey] : null;

        if (_validators.default[type] && container) {
          var validator = _validators.default[type];

          if (typeof validator.isEmpty === 'function') {
            if (!Array.isArray(values)) {
              values = [values];
            }

            var toRemove = _lodash.default.filter(values, function (value) {
              var isEmpty = validator.isEmpty(value, record, field);

              if (!value || value.value !== undefined && isEmpty) {
                if (container) {
                  Ember.Logger.info('Removing field value:', fieldPath, container);
                  return true;
                }
              }
            });

            container.removeObjects(toRemove);
          }
        }
      });

      if (_lodash.default.isEmpty(json[section.get('key')])) {
        delete json[section.get('key')];
      }
    });

    return json;
  };

  _exports.filterMissingFields = filterMissingFields;
  var OUR_COOL_REQUEST_TYPES = ['query', 'findMany', 'findAll', 'search'];

  var ApplicationSerializer = _emberData.default.RESTSerializer.extend({
    isNewSerializerAPI: true,
    primaryKey: 'guid',
    isPrimaryType: function isPrimaryType(store, typeName, primaryTypeClass) {
      if (!primaryTypeClass) {
        return false;
      }

      return this._super.apply(this, arguments);
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      var data = payload;
      var groupKey = '_entity';

      if (payload.res) {
        data = payload.res;
        delete payload.res;
      } else if (payload.results) {
        data = payload.results;
        delete payload.results;
      }

      if (requestType === 'search') {
        groupKey = '_type';
      }

      if (_lodash.default.includes(OUR_COOL_REQUEST_TYPES, requestType)) {
        if (data && _typeof(data) === 'object' && !Array.isArray(data)) {
          data = _lodash.default.map(data, function (value) {
            return value;
          });
        }

        var finalData = {};

        var groupedData = _lodash.default.groupBy(data, groupKey);

        _lodash.default.forOwn(groupedData, function (list, key) {
          if (key === undefined || key === 'undefined' || key === 'conversations') {
            key = primaryModelClass.modelName;
          }

          var type = (0, _emberInflector.singularize)(key);

          if (type === 'user' && requestType === 'search') {
            type = 'person';
          }

          finalData[type] = list.map(function (data) {
            data.id = data.id || data.guid || data._id;
            return data;
          });
        });

        if (_lodash.default.isEmpty(finalData) && primaryModelClass) {
          finalData[primaryModelClass.modelName] = [];
        }

        var meta = {};

        if (_typeof(payload) === 'object' && !Array.isArray(payload)) {
          meta = payload;
        }

        payload = _lodash.default.merge(finalData, {
          meta: meta
        });
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      var data = {};

      if (payload.res && !payload._id) {
        payload = payload.res;
      }

      if (primaryModelClass.modelName === 'call') {
        payload.id = payload.conversationId;
      }

      data[primaryModelClass.modelName] = payload;
      return this._super(store, primaryModelClass, data, id, requestType);
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      delete json._entity;
      return json;
    }
  });

  var _default = ApplicationSerializer;
  _exports.default = _default;
});