define("web-directory/utils/geolocation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var geolocation = navigator.geolocation;
  var GeolocationUtil = Ember.Object.create({
    hostedContext: null,
    setContext: function setContext(context) {
      this.hostedContext = context;
    },
    fetchCurrentPostion: function fetchCurrentPostion() {
      var context = this.get('hostedContext');

      if (context !== null && context.isHosted() && context.requiresNativeGeolocation()) {
        return context.requestDesktopPromise({
          cmd: 'getGeolocationPosition'
        });
      } else {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          geolocation.getCurrentPosition(function (position) {
            return resolve(position);
          }, function (error) {
            return reject(error);
          });
        });
      }
    },
    watchPosition: function watchPosition(onPositionChangedFn, onErrorFn, options) {
      return geolocation.watchPosition(onPositionChangedFn, onErrorFn, options);
    },
    clearWatch: function clearWatch(watcher) {
      geolocation.clearWatch(watcher);
    },
    isGeolocationAvailable: function isGeolocationAvailable() {
      return 'geolocation' in navigator;
    },
    getCurrentPosition: function getCurrentPosition(success, failure) {
      return geolocation.getCurrentPosition(success, failure);
    }
  });
  var _default = GeolocationUtil;
  _exports.default = _default;
});