define("web-directory/adapters/group-policy", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = _emberData.default.RESTAdapter.extend({
    session: inject.service(),
    application: inject.service(),
    host: computed.reads('application.purecloudV2BaseUri'),
    namespace: "api/v2",
    urlForFindRecord: function urlForFindRecord(id) {
      return this._baseUrl(id);
    },
    urlForUpdateRecord: function urlForUpdateRecord(id) {
      return this._baseUrl(id);
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
      return this.ajax(url, 'PATCH', {
        data: data.groupPolicy
      });
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      if (status < 400) {
        return this._super.apply(this, arguments);
      }

      payload.errors = [{
        status: status,
        title: payload.message,
        details: payload
      }];
      return this._super(status, headers, payload, requestData);
    },
    _baseUrl: function _baseUrl(guid) {
      return "".concat(this.get('host'), "/").concat(this.get('namespace'), "/voicemail/groups/").concat(guid, "/policy");
    }
  });

  _exports.default = _default;
});