define("web-directory/hosted-context/util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EVENTS = void 0;
  var EVENTS = {
    INCOMING_CALL: 'incomingCall',
    TERMINATED_CALL: 'terminatedCall',
    MISSED_CALL: 'missedCall',
    STOPPED_RINGING: 'stoppedRinging',
    PHONE_CONNECTED: 'phoneConnected',
    WEBRTC_CONNECTION_INCOMING: 'webrtcConnectionIncoming',
    WEBRTC_CONNECTION_ANSWERED: 'webrtcConnectionAnswered',
    WEBRTC_CONNECTION_DECLINED: 'webrtcConnectionDeclined',
    WEBRTC_CONNECTION_STARTED: 'webrtcConnectionStarted',
    WEBRTC_CONNECTION_ENDED: 'webrtcConnectionEnded'
  };
  _exports.EVENTS = EVENTS;
});