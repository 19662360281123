define("web-directory/routes/interaction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    interaction: Ember.inject.service(),
    sidebar: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('sidebar.allowedPopoutPanels', ['acdInteractions']);
    },
    model: function model(params) {
      return {
        interactionId: params.interactionId
      };
    },
    afterModel: function afterModel(model) {
      var interactionId = model.interactionId;
      this.set('interaction.requestedInteraction', interactionId);
      Ember.run.scheduleOnce('afterRender', this, function () {
        this.get('sidebar').openPanel('acdInteractions');
      });
    }
  });

  _exports.default = _default;
});