define("web-directory/components/audit-footer/component", ["exports", "ember-purecloud-components/mixins/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_logger.default, {
    auditDrawerOpen: true,
    showAppChrome: false,
    auditDrawerTitle: Ember.computed('auditDrawerOpen', function () {
      if (this.get('auditDrawerOpen')) {
        return this.get('intl').t('admin.audit_footer.hide');
      } else {
        return this.get('intl').t('admin.audit_footer.show');
      }
    }),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    sidebar: Ember.inject.service(),
    notification: Ember.inject.service(),
    route: '/audit-footer',
    init: function init() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.setupFrameRouter();
    },
    setupFrameRouter: function setupFrameRouter() {
      var appUrl = "".concat(window.location.origin, "/audit-viewer-ui/");

      if (window.location.hostname.indexOf('localhost') > -1) {
        appUrl = 'https://localhost:5001/audit-viewer-ui/';
      }

      var frame = this.element.querySelector('frame-router');

      if (!frame) {
        return;
      }

      frame.setupFrames({
        audit: {
          url: "".concat(appUrl, "#/audit-footer"),
          assignedRoute: this.get('route')
        }
      }, {
        locale: this.get('intl.isoRegionalLocale'),
        hostRootUrl: window.location.origin,
        custom: {
          authToken: this.get('session.accessToken')
        }
      });
      frame.messaging.addListener('logger', this.handleLogger.bind(this));
      frame.addEventListener('notifyRequest', this.notifyRequest.bind(this));
    },
    notifyRequest: function notifyRequest(event) {
      var title = event.detail.title;
      var message = event.detail.message;
      var type = event.detail.custom && event.detail.custom.type;
      var options = event.detail.custom || {};

      if (!type || typeof this.get('notification')[type] !== 'function') {
        return;
      }

      var toastIdFromClient = options.id;

      if (options.id) {
        // Use unique ID to avoid conflicts with other iframe clients
        options.id = this.getUniqueToastId(event.detail.clientId, toastIdFromClient); // The desktop-notification component overwrites id with what's in the tag property, so add that as well

        options.tag = options.id;
      }

      this.get('notification')[type](title, message, options);
    },
    handleLogger: function handleLogger(logMessage) {
      if (logMessage && logMessage.payload && logMessage.payload.type === 'error') {
        this.logger.error(logMessage.payload.message, logMessage.payload.error);
      }
    },
    actions: {
      toggleAuditDrawer: function toggleAuditDrawer() {
        this.toggleProperty('auditDrawerOpen');
      }
    }
  });

  _exports.default = _default;
});