define("web-directory/components/entity-field-section/entity-relationships/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ybliki+R",
    "block": "{\"statements\":[[19,\"components/entity-field-section/-section-header\"],[0,\"\\n\\n\"],[11,\"div\",[]],[15,\"class\",\"field-section-content\"],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"allRelationships\"]]],null,{\"statements\":[[0,\"        \"],[1,[33,[\"entity-field-section/entity-relationships/relationship-field\"],null,[[\"entity\",\"editing\",\"relationship\",\"componentUpdated\",\"onUpdateField\",\"onUpdateLabel\"],[[28,[\"entity\"]],[28,[\"editing\"]],[28,[\"relationship\"]],[28,[\"componentUpdated\"]],[33,[\"action\"],[[28,[null]],\"updatedField\"],null],[33,[\"action\"],[[28,[null]],\"updatedLabel\"],null]]]],false],[0,\"\\n\"]],\"locals\":[\"relationship\"]},null],[14],[0,\"\\n\\n\"],[6,[\"if\"],[[28,[\"showRemoveSectionModal\"]]],null,{\"statements\":[[6,[\"modal-component\"],null,[[\"extraClassNames\",\"showHeader\",\"confirmText\",\"cancelText\",\"confirm\",\"cancel\"],[\"remove-section-confirmation\",false,[33,[\"t\"],[\"common.confirm\"],null],[33,[\"t\"],[\"common.cancel\"],null],[33,[\"action\"],[[28,[null]],\"removeSection\"],null],[33,[\"action\"],[[28,[null]],\"cancelRemoveSection\"],null]]],{\"statements\":[[0,\"        \"],[11,\"p\",[]],[13],[0,\"\\n            \"],[1,[33,[\"t\"],[\"person.personProfile.removeSectionWarning\"],null],false],[0,\"\\n        \"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},null],[0,\"\\n\"],[6,[\"if\"],[[28,[\"editing\"]]],null,{\"statements\":[[0,\"    \"],[19,\"components/entity-field-section/-section-footer\"],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":true}",
    "meta": {
      "moduleName": "web-directory/components/entity-field-section/entity-relationships/template.hbs"
    }
  });

  _exports.default = _default;
});