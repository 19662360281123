define("web-directory/mixins/acd/interaction-timers", ["exports", "web-directory/utils/dates", "lodash"], function (_exports, _dates, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Mixin.create({
    logger: inject.service('stash-logger'),
    session: inject.service(),
    lastInteractionTime: computed('participantForCurrentUser.connectedTime', 'messages.[]', 'isEmail', function () {
      if (this.get('isEmail')) {
        var mostRecentMessage = _lodash.default.head(this.get('messages'));

        return mostRecentMessage && mostRecentMessage.get('time');
      }

      if (!this.get('participantForCurrentUser.connectedTime')) {
        return (0, _dates.default)();
      }

      return (0, _dates.default)(this.get('participantForCurrentUser.connectedTime'));
    }),
    connectedTime: computed('participantForCurrentUser.{connectedTime,calls.firstObject.connectedTime}', 'isCall', function () {
      if (this.get('isCall') && this.get('participantForCurrentUser.calls.firstObject.connectedTime')) {
        return (0, _dates.default)(this.get('participantForCurrentUser.calls.firstObject.connectedTime'));
      } else if (!this.get('participantForCurrentUser.connectedTime')) {
        return (0, _dates.default)();
      }

      return (0, _dates.default)(this.get('participantForCurrentUser.connectedTime'));
    }),
    endTime: computed('type', 'participantForCurrentUser.endTime', 'participantForCurrentUser.calls.firstObject.disconnectedTime', 'participantForCurrentUser.callbacks.firstObject.disconnectedTime', 'participantForCurrentUser.chats.firstObject.disconnectedTime', 'participantForCurrentUser.emails.firstObject.disconnectedTime', function () {
      var endTime;
      var type = this.get('type');

      switch (type) {
        case 'chat':
          endTime = this.get('participantForCurrentUser.chats.firstObject.disconnectedTime');
          break;

        case 'call':
          endTime = this.get('participantForCurrentUser.calls.firstObject.disconnectedTime');
          break;

        case 'callback':
          endTime = this.get('participantForCurrentUser.callbacks.firstObject.disconnectedTime');
          break;

        case 'email':
          endTime = this.get('participantForCurrentUser.emails.firstObject.disconnectedTime');
          break;

        case 'message':
          endTime = this.get('participantForCurrentUser.messages.firstObject.disconnectedTime');
          break;
      }

      endTime = endTime || this.get('participantForCurrentUser.endTime');
      return endTime ? (0, _dates.default)(endTime) : null;
    }),
    alertingStartTime: computed('type', 'participantForCurrentUser.calls.firstObject.startAlertingTime', 'participantForCurrentUser.callbacks.firstObject.startAlertingTime', 'participantForCurrentUser.chats.firstObject.startAlertingTime', 'participantForCurrentUser.emails.firstObject.startAlertingTime', function () {
      var alertingStartTime;
      var type = this.get('type');

      switch (type) {
        case 'chat':
          alertingStartTime = this.get('participantForCurrentUser.chats.firstObject.startAlertingTime');
          break;

        case 'call':
          alertingStartTime = this.get('startAlertingTime') || this.get('participantForCurrentUser.calls.firstObject.startAlertingTime');

          if (!alertingStartTime) {
            alertingStartTime = (0, _dates.default)();
            this.set('startAlertingTime', alertingStartTime.format());
          }

          break;

        case 'callback':
          alertingStartTime = this.get('participantForCurrentUser.callbacks.firstObject.startAlertingTime');
          break;

        case 'email':
          alertingStartTime = this.get('participantForCurrentUser.emails.firstObject.startAlertingTime');
          break;

        case 'message':
          alertingStartTime = this.get('participantForCurrentUser.messages.firstObject.startAlertingTime');
          break;
      }

      alertingStartTime = alertingStartTime || (0, _dates.default)();
      return (0, _dates.default)(alertingStartTime);
    }),
    // Alerting time remaing
    showAlertingTimeout: computed('participantForCurrentUser.alertingTimeoutMs', 'isAlerting', function () {
      return !!this.get('participantForCurrentUser.alertingTimeoutMs') && this.get('isAlerting');
    }),
    alertingTimeRemaining: computed('participantForCurrentUser.alertingTimeoutMs', 'isAlerting', 'alertingStartTime', function () {
      var timeoutValue = this.get('participantForCurrentUser.alertingTimeoutMs');
      return (0, _dates.default)(this.get('alertingStartTime')).add(timeoutValue, 'milliseconds');
    }),
    // Time entered Queue
    timeInQueue: computed('participantForQueue.{connectedTime,endTime}', function () {
      if (!this.get('participantForQueue.endTime')) {
        return this.get('participantForQueue.connectedTime');
      }

      return null;
    }),
    // Time spent in Queue duration
    timeInQueueDuration: computed('participantForQueue.{connectedTime,endTime}', function () {
      if (this.get('participantForQueue.endTime')) {
        var connectedTime = this.get('participantForQueue.connectedTime');
        var endTime = (0, _dates.default)(this.get('participantForQueue.endTime'));
        var elapsedTime = endTime.diff(connectedTime);

        if (elapsedTime < 0) {
          return null;
        }

        return elapsedTime;
      }

      return null;
    }),
    // Connected Duration timer
    connectedDuration: computed('endTime', 'connectedTime', function () {
      var connectedTime = this.get('connectedTime');

      if (connectedTime && this.get('endTime')) {
        return (0, _dates.default)(this.get('endTime')).diff((0, _dates.default)(connectedTime));
      }

      return null;
    }),
    //On hold timer
    _currentHoldMoment: null,
    heldStartTime: computed('isHeld', 'isConsultParticipantHolding', function () {
      var isConsultParticipantHolding = this.get('isConsultParticipantHolding');
      var isHeld = this.get('isHeld');

      if (isConsultParticipantHolding) {
        // There is no timestamp for start time
        // of a confinement in the payload so we'll cache it.
        var _currentHoldMoment = this.get('_currentHoldMoment');

        if (_currentHoldMoment) {
          return _currentHoldMoment;
        }

        var now = (0, _dates.default)();
        this.set('_currentHoldMoment', now);
        return now;
      } else if (isHeld) {
        var holdTime = this.get('participantForCurrentUser.calls.firstObject.startHoldTime');
        return (0, _dates.default)(holdTime);
      }

      this.set('_currentHoldMoment', null);
      return null;
    }),
    lastMessageTime: computed('room.messages.lastObject', 'latestMessage.timestamp', 'latestMessage.messageTime', 'isChat', 'isSms', function () {
      if (this.get('isChat')) {
        var last = this.get('room.messages.lastObject');

        if (last) {
          return last.get('time') || null;
        }

        return null;
      } else if (this.get('isSms')) {
        return (0, _dates.default)(this.get('latestMessage.timestamp') || this.get('latestMessage.messageTime')).format();
      }
    }),
    //After-call work timer
    acwStartTimer: computed('isDisconnected', 'isTerminated', 'isNonDiscretionaryCompletion', 'endTime', function () {
      if ((this.get('isDisconnected') || this.get('isTerminated')) && !this.get('isNonDiscretionaryCompletion') && this.get('endTime')) {
        return (0, _dates.default)(this.get('endTime'));
      }

      return null;
    }),
    acwTimeoutTimer: computed('isDisconnected', 'isTerminated', 'isNonDiscretionaryCompletion', 'endTime', 'participantForCurrentUser.wrapupTimeoutMs', 'isDestroyed', 'isDestroying', function () {
      var _this = this;

      if ((this.get('isDisconnected') || this.get('isTerminated')) && this.get('isNonDiscretionaryCompletion')) {
        var timeoutValue = this.get('participantForCurrentUser.wrapupTimeoutMs');
        var elapsedTime = (0, _dates.default)().diff(this.get('endTime'));
        timeoutValue = elapsedTime > timeoutValue ? 0 : timeoutValue - elapsedTime;
        Ember.run.later(function () {
          if (!_this.get('isDestroyed') && !_this.get('isDestroying')) {
            _this.modelSet('isAfterCallWorkTimerExpired', true);
          }
        }, timeoutValue);
        return (0, _dates.default)().add(timeoutValue, 'ms');
      }

      return null;
    })
  });

  _exports.default = _default;
});