define("web-directory/services/conversation-timeline-logger", ["exports", "lodash", "moment"], function (_exports, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    stashLogger: Ember.inject.service(),
    session: Ember.inject.service(),
    phone: Ember.inject.service(),
    personGuid: Ember.computed.reads('session.person.guid'),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('conversationTimelines', {});
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      _lodash.default.forEach(this.get('conversationTimelines'), function (conversationTimeline) {
        Ember.run.cancel(conversationTimeline.sendTimeout);
        Ember.run.cancel(conversationTimeline.endingTimeout);
      });
    },
    curateMessage: function curateMessage(level, topic) {
      var isPigeon = topic === 'service:pigeon';

      if (isPigeon) {
        return false;
      }

      for (var _len = arguments.length, itemsToLog = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        itemsToLog[_key - 2] = arguments[_key];
      }

      var item = _lodash.default.find(itemsToLog, function (item) {
        return item && (item.conversationId || isPigeon && item.update && item.update.id) || item.remoteData && item.remoteData.conversationId;
      });

      if (!item) {
        return false;
      }

      var conversationId = item.conversationId || item.update && item.update.id || item.remoteData && item.remoteData.conversationId;
      return this._addToTimeline(level, conversationId, itemsToLog, isPigeon);
    },
    _addToTimeline: function _addToTimeline(level, conversationId, itemsToLog, isPigeon) {
      var _this = this;

      if (!conversationId) {
        return false;
      }

      var clientTime = (0, _moment.default)();
      var message;
      var extra;

      if (isPigeon && itemsToLog.length > 1) {
        message = 'CP Update - simplified';
        var pigeonLog = {
          participants: []
        };

        _lodash.default.forEach(itemsToLog[1].update.participants, function (participant) {
          var calls, videos;

          var participantIsCurrentUser = participant.userId === _this.get('personGuid');

          if (participant.calls) {
            calls = participant.calls.map(function (call) {
              return {
                id: call.id,
                confined: call.confined,
                held: call.held,
                muted: call.muted,
                state: call.state
              };
            });
          }

          if (participant.videos) {
            videos = participant.videos.map(function (video) {
              return {
                sharingScreen: video.sharingScreen,
                state: video.state,
                audioMuted: video.audioMuted,
                videoMuted: video.videoMuted
              };
            });
          }

          if (!calls && !videos) {
            return false;
          }

          var participantObject = {
            calls: calls,
            videos: videos,
            userId: participant.userId
          };

          if (participantIsCurrentUser) {
            participantObject.isCurrentUser = true;
          }

          pigeonLog.participants.push(participantObject);
        });

        if (!pigeonLog.participants.length) {
          return false;
        }

        extra = pigeonLog;
      } else {
        var itemWithMessage = itemsToLog.find(function (item) {
          return item.message;
        });

        if (itemWithMessage) {
          message = itemWithMessage.message;
        } else if (!itemWithMessage && typeof itemsToLog[0] === 'string') {
          message = itemsToLog.shift();
        }

        if (itemsToLog.length) {
          extra = itemsToLog.filter(function (i) {
            return i.remoteData;
          }).map(function (i) {
            return i.remoteData;
          });
        }
      }

      var trace = this.get('stashLogger').createTrace(level, 'conversation-timeline-v2', [message, {
        remoteData: {
          conversationId: conversationId,
          message: message,
          extra: extra,
          clientTime: clientTime
        }
      }]);
      this.get('stashLogger.logs').pushObject(trace);
      return true;
    }
  });

  _exports.default = _default;
});