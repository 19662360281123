define("web-directory/initializers/sidebar-service", ["exports", "web-directory/services/sidebar"], function (_exports, _sidebar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'setupSidebarService',
    after: 'setupPreferencesService',
    initialize: function initialize(application) {
      application.register('service:sidebar', _sidebar.default, {
        singleton: true,
        initialize: true
      });
      application.inject('service:sidebar', 'preferences', 'service:preferences');
      application.inject('controller', 'sidebarService', 'service:sidebar');
      application.inject('route', 'sidebarService', 'service:sidebar');
      application.inject('component', 'sidebarService', 'service:sidebar');
    }
  };
  _exports.default = _default;
});