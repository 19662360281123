define("web-directory/components/acd-interactions-panel/interaction-sms/sms-message/sms-attachments/component", ["exports", "web-directory/mixins/acd-attachment-url-mixin", "web-directory/utils/acd-ts"], function (_exports, _acdAttachmentUrlMixin, _acdTs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed,
      get = Ember.get,
      inject = Ember.inject,
      run = Ember.run;

  var _default = Component.extend(_acdAttachmentUrlMixin.default, {
    classNames: ['sms-attachments-box'],
    classNameBindings: ['isSticker'],
    acdsms: inject.service(),
    session: inject.service(),
    media: null,
    fileName: computed.or('media.name', 'url'),
    isAgentMessage: computed.equal('message.direction', 'outbound'),
    isCustomerMessage: computed.equal('message.direction', 'inbound'),
    url: computed('media.url', 'message.stickers.firstObject.url', 'message.normalizedMessage.content.firstObject.attachment.url', 'message.normalizedMessage.content.firstObject.story.url', function () {
      return this.get('media.url') || this.get('message.stickers.firstObject.url') || this.get('message.normalizedMessage.content.firstObject.attachment.url') || this.get('message.normalizedMessage.content.firstObject.story.url');
    }),
    mediaMimiType: computed('mediaType', function () {
      var mediaType = this.get('mediaType');

      if (/^image/.test(mediaType)) {
        return 'image';
      } else if (/^audio/.test(mediaType)) {
        return 'audio';
      } else if (/^video/.test(mediaType)) {
        return 'video';
      }

      return 'file';
    }),
    fileSize: computed.readOnly('media.contentLengthBytes'),
    mediaType: computed('media', 'media.mediaType', 'message.media.length', 'message.stickers.length', 'message.content.firstObject.attachment.mediaType', 'message.normalizedMessage.content.firstObject.attachment.mediaType', 'message.normalizedMessage.content.firstObject.story.type', function () {
      if (this.get('media') && this.get('message.media.length') > 0) {
        return this.get('media.mediaType');
      } else if (this.get('message.stickers') && this.get('message.stickers.length') > 0) {
        return 'sticker';
      } else if (this.get('message.normalizedMessage.content.firstObject.attachment.mediaType')) {
        return this.get('message.normalizedMessage.content.firstObject.attachment.mediaType');
      } else if (this.get('message.normalizedMessage.content.firstObject.story.type')) {
        return this.get('message.normalizedMessage.content.firstObject.story.type');
      }
    }),
    isSticker: computed.equal('mediaType', 'sticker'),
    isImage: computed.equal('mediaMimiType', 'image'),
    isAudio: computed.equal('mediaMimiType', 'audio'),
    isVideo: computed.equal('mediaMimiType', 'video'),
    isGenericFile: computed.equal('mediaMimiType', 'file'),
    mediaHidden: computed.alias('media.mediaHidden'),
    isInvalidMedia: computed.alias('media.isInvalidMedia'),
    message: null,
    interaction: null,
    init: function init() {
      this._super.apply(this, arguments);

      run.scheduleOnce('afterRender', this, this.updateBody);
    },
    actions: {
      toggleMediaHidden: function toggleMediaHidden() {
        var _this = this;

        this.toggleProperty('mediaHidden');

        if (!this.get('mediaHidden')) {
          run.next(function () {
            _this.setupImageOnErrorEvent();
          });
        }
      }
    },

    /**
     * @action
     * @param {string} id The id for the attachment in a message
     * @param {boolean} value True if image fails to load
     */
    actionOnAttachmentLoad: function actionOnAttachmentLoad(id, value) {
      return (0, _acdTs.warnClosureAction)('actionOnAttachmentLoad', {
        id: id,
        value: value
      });
    },
    setupImageOnLoadEvent: function setupImageOnLoadEvent() {
      var _this2 = this;

      this.$('img').on('load', function () {
        _this2.get('acdsms').trigger('scrollToBottom', _this2.get('interaction.id'));

        var message = _this2.get('message');

        _this2.actionOnAttachmentLoad(get(message, 'id'), true);
      });
    },
    setupImageOnErrorEvent: function setupImageOnErrorEvent() {
      var _this3 = this;

      var imageTag = this.$('img');
      imageTag.on('error', function () {
        var imgSrc = imageTag.attr('src');

        var altSrc = _this3.embeddedUrl(imgSrc);

        if (imgSrc && altSrc && imgSrc !== altSrc) {
          imageTag.attr('src', altSrc);
        }

        var message = _this3.get('message');

        _this3.actionOnAttachmentLoad(get(message, 'id'), false);
      });
    },
    didInsertElement: function didInsertElement() {
      var _this4 = this;

      this._super.apply(this, arguments);

      run.scheduleOnce('afterRender', this, function () {
        _this4.setupImageOnLoadEvent();

        _this4.setupImageOnErrorEvent();
      });
    },
    updateBody: function updateBody() {
      var textBodyHasNoContent = this.get('message.textBody') && this.get('message.textBody').trim().length == 0;

      if (!this.get('isSticker') && (textBodyHasNoContent || this.get('isAgentMessage'))) {
        this.set('message.textBody', '');
      }
    }
  });

  _exports.default = _default;
});