define("web-directory/views/card/tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    templateName: 'card/tree/tree',
    classNames: ['card-tree-container', 'container-fluid'],
    attachTextInputListeners: Ember.on('didInsertElement', function () {
      var fields = $.merge(Ember.$('.ember-text-area'), Ember.$('.ember-text-field'));
      return fields.on('keydown', function (event) {
        return event.stopPropagation();
      });
    }),
    removeTextInputListeners: Ember.on('willClearRender', function () {
      var fields = $.merge(Ember.$('.ember-text-area'), Ember.$('.ember-text-field'));
      return fields.off('keydown');
    })
  });

  _exports.default = _default;
});