define("web-directory/services/addressformat", ["exports", "ember-data", "lodash", "web-directory/models/queue", "web-directory/models/person", "web-directory/models/external-contact", "ember-purecloud-components/utils/address-format"], function (_exports, _emberData, _lodash, _queue, _person, _externalContact, _addressFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var wordCharsRegex = /[^\w]/g; // Matches anything that is a word character (i.e[a-zA-Z0-9_])

  var nonDigitRegex = /\D/g; // Matches anything that isn't a digit

  var removeLeadingPlusRegex = /^[+]+/g; // Used to remove the pluses at the beginning of the number

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    countryCode: Ember.computed('intl.isoLocale', function () {
      if (this.get('intl.isoLocale')) {
        return this.get('intl.isoLocale').toUpperCase().split('-').pop();
      } else {
        return null;
      }
    }),

    /**
     * This method is meant to take in a varying number of types and return the appropriate
     * string to make a call. It is used primarily for phone:makeCall
     *
     * @param  {string|object|model} itemToFormat the item we are trying to format
     * @return {string}              the formatted version of itemToFormat
     */
    format: function format(itemToFormat) {
      if (itemToFormat instanceof _person.default) {
        return itemToFormat.get('guid');
      }

      if (itemToFormat instanceof _queue.default) {
        return itemToFormat.get('id');
      }

      if (itemToFormat instanceof _externalContact.default) {
        return itemToFormat.get('phone.rel.e164');
      }

      if (itemToFormat && _typeof(itemToFormat) === 'object') {
        return this.formatFieldConfigEntry(itemToFormat);
      }

      if (typeof itemToFormat === 'string') {
        return this.formatAddress(itemToFormat);
      }

      return itemToFormat;
    },

    /**
     * Format numbers to E164. If number has sip: prefix, format sipAddress
     * @param  {string} phoneNumber the number to formatAddress
     * @param  {string} country     the countryCode
     * @param  {string} subMsgType  the message subtype (SMS, WhatsApp, etc.)
     * @return {string}             the formatted number
     */
    formatAddress: function formatAddress(phoneNumber, country, subMsgType) {
      if (_lodash.default.startsWith(phoneNumber, 'sip:')) {
        return this.formatSipAddress(phoneNumber);
      }

      if (_lodash.default.startsWith(phoneNumber, 'tel:')) {
        phoneNumber = phoneNumber.split(':').pop();
      }

      if (this.get('session.features.acdOutboundWhatsApp') && subMsgType === 'whatsapp') {
        phoneNumber = phoneNumber.replace(wordCharsRegex, '');
      } else if (this.get('session.features.acdOutboundOpenMessaging') && subMsgType === 'open') {
        return phoneNumber;
      } else {
        var defaultCountryCode = this.get('session.org.orgDefaultCountryCode') || null; // Default country setting in admin

        var countryCode = country || defaultCountryCode || this.get('countryCode'); // make a guess on the country code

        if (intlTelInputUtils.isValidNumber(phoneNumber, countryCode)) {
          var extension = intlTelInputUtils.getExtension(phoneNumber, countryCode);
          var e164 = intlTelInputUtils.formatNumber(phoneNumber, countryCode, intlTelInputUtils.numberFormat.E164);

          if (intlTelInputUtils.isValidNumber(e164, countryCode)) {
            // check again b/c libphonenumber could 'E164' a number into an invalid (e.g., '5555555' => '+15555555')
            if (extension) {
              // add extension back b/c libphonenumber strips it out after E164ing the number
              return "".concat(e164, ";ext=").concat(extension);
            }

            return e164;
          }
        }
      }

      return phoneNumber;
    },

    /**
     * Format sip numbers to remove extraneous parameters
     * @param  {string} address the number to formatSipAddress
     * @return {string}             the formatted address
     */
    formatSipAddress: function formatSipAddress(address) {
      var regex = /(sip:)([^;]*)?(?:[^@]*)(@.*)/;
      var addressParts = address.match(regex);

      if (!addressParts || addressParts.length < 3) {
        return address;
      }

      if (intlTelInputUtils.isValidNumber(addressParts[2]) || intlTelInputUtils.isValidNumber(addressParts[2], this.get('countryCode'))) {
        return this.formatAddress(addressParts[2]);
      }

      return addressParts.slice(1).join('');
    },
    formatOrLookupAddressForDisplay: function formatOrLookupAddressForDisplay(address, country) {
      return Ember.RSVP.resolve(this.formatAddressForDisplay(address, country, undefined, {
        doLookup: true
      }));
    },
    lookupAddressFormatResultId: function lookupAddressFormatResultId() {
      var result = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var fallback = result.displayText || '';

      try {
        if (result.jid) {
          var existingRecord = this.get('store').peekAll('person').findBy('jid', result.jid);

          if (existingRecord) {
            return new _emberData.default.PromiseObject({
              promise: Ember.RSVP.resolve(existingRecord.get('name'))
            });
          }

          var promise = this.get('store').findMany('person', [result.jid]).then(function (records) {
            if (records && records.length === 1) {
              return records[0].get('name');
            }

            return fallback;
          }).catch(function () {
            return fallback;
          }); // don't log this, it just means the lookup failed, return fallback

          return new _emberData.default.PromiseObject({
            promise: promise
          }); // has to be re-wrapped since we then/catch the original store promise
        }

        if (result.queueId) {
          var _promise = this.get('store').findRecord('queue', result.queueId).then(function (queue) {
            return queue.get('name');
          }).catch(function () {
            return fallback;
          });

          return new _emberData.default.PromiseObject({
            promise: _promise
          });
        }
      } catch (e) {// no op;
      }

      return new _emberData.default.PromiseObject({
        promise: Ember.RSVP.resolve(fallback)
      });
    },
    resolveAddressFormatResult: function resolveAddressFormatResult() {
      var result = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var fallback = result.displayText || '';

      if (opts.doLookup) {
        if (result.isEntity) {
          return this.lookupAddressFormatResultId(result);
        }

        return new _emberData.default.PromiseObject({
          promise: Ember.RSVP.resolve(fallback)
        });
      }

      return fallback;
    },

    /**
     * Format International Numbers (e.g., +1 (919) 555-5555)
     * If phoneNumber has 'sip:' prefix, call formatSipAddressForDisplay
     * @param  {string} address     the number to formatAddress
     * @param  {string} country     the countryCode
     * @param  {string} subMsgType  the message subtype (SMS, WhatsApp, etc.)
     * @param  {object} opts        options with `doLookup` bool for performing async lookup of username or queue name for sip addresses
     * @return {string}             the formatted number (always string by default)
     * @return {Promise}            a Promise which will resolve to the username of the associated sip address, if applicable (will return promise only if { doLookup: true } is provided)
     */
    formatAddressForDisplay: function formatAddressForDisplay(address, country, subMsgType) {
      var opts = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

      if (this.get('session.features.acdOutboundWhatsApp') && subMsgType === 'whatsapp') {
        return address.replace(removeLeadingPlusRegex, '');
      }

      if (this.get('session.features.acdOutboundOpenMessaging') && subMsgType === 'open') {
        return address;
      }

      return this.resolveAddressFormatResult((0, _addressFormat.formatAddressForDisplay)(address, country), opts);
    },

    /**
     * This is a mild improvement over our naiive formatting of just stripping 'sip:'
     * it does a regex to try to find a jid, in the case that the address might identify a purecloud user
     * otherwise, otherwise, see if the address part is a phone number, and try to format it as such. Otherwise, fallback
     * to the original sip address, without the prefix
     */
    formatSipAddressForDisplay: function formatSipAddressForDisplay(address, opts) {
      return this.resolveAddressFormatResult((0, _addressFormat.formatSipAddressForDisplay)(address), opts);
    },

    /**
     * Checks to see if a number is valid. Passing in strict will not allow things to pass through.
     * (strict option may seem weird but the intent is to allow individual authors to make the decision on
     *  whether this method is blocking or should just pass the number through -- to the api for example)
     * @param  {string}  phoneNumber the phone number to Checks
     * @param  {string}  country     the country countryCode
     * @param  {Boolean} strict      should return false if doesn't pass isValidNumber
     * @param  {string}  subMsgType  the message subtype (SMS, WhatsApp, etc.)
     * @return {Boolean}
     */
    isValidNumber: function isValidNumber(phoneNumber, country, strict, subMsgType) {
      try {
        if (this.get('session.features.acdOutboundWhatsApp') && subMsgType === 'whatsapp') {
          phoneNumber = phoneNumber.replace(removeLeadingPlusRegex, '');
          return !phoneNumber.match(nonDigitRegex);
        }

        var defaultCountryCode = this.get('session.org.orgDefaultCountryCode') || null; // Default country setting in admin

        var countryCode = country || defaultCountryCode || this.get('countryCode'); // make a guess on the country code

        var isValid = intlTelInputUtils.isValidNumber(phoneNumber, countryCode);
        return strict ? isValid : true;
      } catch (e) {
        return !strict;
      }
    },

    /**
     * Remove 'tel:' or 'sip:' from a string
     * @param  {string} phoneNumber the number to format
     * @return {string}             the number sans (tel:|sip:)
     */
    formatRawNumber: function formatRawNumber(phoneNumber) {
      if (_lodash.default.includes(phoneNumber, 'tel')) {
        return phoneNumber.replace(/tel:/gi, '');
      } else if (_lodash.default.includes(phoneNumber, 'sip')) {
        return phoneNumber.replace(/sip:/gi, '');
      } else {
        return phoneNumber;
      }
    },

    /**
     * Format a field config entry value
     * @param  {object} itemToFormat the thing to format
     * @return {string}              the formatted string
     */
    formatFieldConfigEntry: function formatFieldConfigEntry(itemToFormat) {
      var e164 = Ember.get(itemToFormat, 'rel.e164');

      if (e164) {
        return e164;
      }

      var number = Ember.get(itemToFormat, 'value.number');
      number = number != null ? number.replace(/\D/g, '') : void 0;
      var extension = Ember.get(itemToFormat, 'value.extension');
      extension = extension != null ? extension.replace(/\D/g, '') : void 0;

      if (number) {
        // number = this.formatAddress(number);
        if (extension) {
          itemToFormat = number + ';ext=' + extension;
        } else {
          itemToFormat = '' + number;
        }
      } else if (extension) {
        itemToFormat = extension;
      }

      return itemToFormat;
    }
  });

  _exports.default = _default;
});