define("web-directory/services/agent-schedule", ["exports", "ember-purecloud-components/mixins/logger", "web-directory/utils/dates", "ember-ajax/errors", "lodash"], function (_exports, _logger, _dates, _errors, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OUT_OF_ADHERENCE = 'OutOfAdherence';
  var SCHEDULE_ROUTE = '#/analytics/agents/schedule/me';
  var NEW_SCHEDULE_ROUTE = '#/activity/schedule';

  var _default = Ember.Service.extend(Ember.Evented, _logger.default, {
    application: Ember.inject.service(),
    engage: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    pigeon: Ember.inject.service(),
    permissions: Ember.inject.service(),
    notification: Ember.inject.service(),
    sidebar: Ember.inject.service(),
    outOfAdherenceNotification: null,
    scheduleUpdateNotifications: {},
    adherenceRegistered: false,
    scheduleUpdateRegistered: false,
    loadAdherenceData: function loadAdherenceData() {
      var _this = this;

      var guid = this.get('session.person.guid');

      if (guid && this.get('hasAdherenceAccess')) {
        var url = this.get('application').pcUri("api/v2/workforcemanagement/adherence?userId=".concat(guid));
        this.get('ajax').ajaxGet(url).then(function (response) {
          var adherenceData = null;

          if (response.length > 0) {
            adherenceData = response[0];
          }

          _this.handleOutOfAdherenceState(adherenceData);
        }).catch(function (error) {
          if (!(0, _errors.isAbortError)(error)) {
            Ember.Logger.error('Error getting adherence data', {
              error: error
            });
          }
        });
      }
    },
    handleAdherenceChange: function handleAdherenceChange(event) {
      if (event && event.user && event.user.id === this.get('session.person.guid')) {
        this.handleOutOfAdherenceState(event);
      }
    },
    checkAdherenceState: function checkAdherenceState() {
      var guid = this.get('session.person.guid');
      var showAdherence = this.get('hasAdherenceNotifyAccess');

      if (guid && showAdherence && !this.get('adherenceRegistered')) {
        this.loadAdherenceData();
        var adherenceTopic = "v2.users.".concat(guid, ".workforcemanagement.adherence");
        this.get('pigeon').registerPriorityTopic(adherenceTopic);
        this.get('pigeon').on('adherence', this, this.handleAdherenceChange);
        this.get('pigeon').on('connect', this, this.loadAdherenceData);
        this.set('adherenceRegistered', true);
      }
    },
    initializeScheduleUpdateListener: function initializeScheduleUpdateListener() {
      var guid = this.get('session.person.guid');
      var showScheduleUpdates = this.get('hasScheduleUpdateAccess');

      if (guid && showScheduleUpdates && !this.get('scheduleUpdateRegistered')) {
        var scheduleUpdateTopic = "v2.users.".concat(guid, ".workforcemanagement.schedules");
        this.get('pigeon').registerPriorityTopic(scheduleUpdateTopic);
        this.get('pigeon').on('schedules', this, this.handleScheduleUpdate);
        this.set('scheduleUpdateRegistered', true);
      }
    },
    goToSchedule: function goToSchedule() {
      var route = this.get('hasAgentActivityAccess') ? NEW_SCHEDULE_ROUTE : SCHEDULE_ROUTE;
      window.location.hash = route;
      this.get('sidebar').ensureRouteVisibility();
    },
    hideNotification: function hideNotification(notification, options) {
      notification.hide();

      if (options) {
        delete this.get("scheduleUpdateNotifications.".concat(options.type, "-").concat(options.date));
      } else {
        this.set('outOfAdherenceNotification', null);
      }
    },
    handleScheduleUpdate: function handleScheduleUpdate(data) {
      var _this2 = this;

      var scheduleData = data;

      if (scheduleData && scheduleData.user && scheduleData.user.id === this.get('session.person.guid')) {
        var updates = scheduleData.updates;
        var startDate = (0, _dates.default)(scheduleData.startDate).format('YYYY-MM-DD');
        var isWfmDateFormatEnhancementToggleEnabled = this.get('session.features')['wfmUI.DateFormatEnhancement'];
        var dateFormat = isWfmDateFormatEnhancementToggleEnabled ? this.get('intl').t('notification.inbox.dateShortMonthDayFormat').toString() : this.get('intl').lookup('notification.purecloud.schedule.dateFormat').toString();
        var listSeparator = this.get('intl').lookup('notification.purecloud.schedule.listSeparator').toString(); // Notify if scheduled shifts change, not if TOR was added/removed

        if (updates && updates.length > 0) {
          // Loop through each type of update (Added, Edited, Deleted) and notify agent of the associated changes
          updates.forEach(function (update) {
            var notifyNew = true;
            var type = update.type;

            var notification = _this2.get("scheduleUpdateNotifications.".concat(type, "-").concat(startDate));

            var shiftStartDates = update.shiftStartDates;

            if (!shiftStartDates && !_lodash.default.isEmpty(update.shifts)) {
              shiftStartDates = update.shifts.map(function (x) {
                return x.startDate;
              });
            }

            var dateString;

            if (_lodash.default.isEmpty(shiftStartDates)) {
              var start = (0, _dates.default)(startDate).format(dateFormat);
              var end = (0, _dates.default)(scheduleData.endDate).format(dateFormat);
              dateString = _this2.get('intl').lookup('components.inbox.dateRange', {
                start: start,
                end: end
              });
            } else {
              var sortedDates = shiftStartDates.sort(function (a, b) {
                a = (0, _dates.default)(a).format('YYYYMMDD');
                b = (0, _dates.default)(b).format('YYYYMMDD');
                return a.localeCompare(b);
              });
              dateString = sortedDates.map(function (date) {
                return (0, _dates.default)(date).format(dateFormat);
              }).join(listSeparator);
            } // If we already have a notification for this update, show it. If the update is different,
            // remove the old notification and show a new one


            if (notification) {
              if (notification.meta && notification.meta.topic === dateString) {
                notifyNew = false;

                if (!Ember.get(notification, 'notification.shown')) {
                  notification.showNotification();
                }
              } else {
                _this2.hideNotification(notification, {
                  type: type,
                  date: startDate
                });
              }
            }

            if (notifyNew && dateString) {
              var message = _this2.get('intl').lookup("notification.purecloud.schedule.".concat(type), {
                dates: dateString
              });

              notification = _this2.get('notification').info(_this2.get('intl').lookup('notification.purecloud.schedule.title').toString(), message.toString(), {
                target: _this2,
                hideAfter: 0,
                actionHandlers: {
                  goToSchedule: {
                    label: _this2.get('intl').lookup('notification.purecloud.outOfAdherence.action').toString(),
                    action: function action() {
                      _this2.goToSchedule();

                      _this2.hideNotification(notification, {
                        type: type,
                        date: startDate
                      });
                    }
                  }
                },
                meta: {
                  topic: dateString
                },
                showNotificationEverywhere: true
              });

              _this2.set("scheduleUpdateNotifications.".concat(type, "-").concat(startDate), notification);
            }
          }, this);
        }
      }
    },
    handleOutOfAdherenceState: function handleOutOfAdherenceState(data) {
      var _this3 = this;

      var notification = this.get('outOfAdherenceNotification');
      var adherenceData = data;

      if (adherenceData) {
        var adherenceState = adherenceData.adherenceState;
        var scheduledActivity = adherenceData.scheduledActivityCategory;
        var outOfAdherence = adherenceState === OUT_OF_ADHERENCE;

        if (outOfAdherence) {
          var notifyNew = true;

          if (notification) {
            // If we already have a notification for this activity, show it. If the activity is different,
            // remove the old notification and show a new one
            if (notification.meta && notification.meta.topic === scheduledActivity) {
              notifyNew = false;

              if (!Ember.get(notification, 'notification.shown')) {
                // If the notification is already shown, don't show it again, otherwise show it
                notification.showNotification();
              }
            } else {
              this.hideNotification(notification);
            }
          }

          if (notifyNew && scheduledActivity) {
            notification = this.get('notification').info(this.get('intl').lookup('notification.purecloud.outOfAdherence.title').toString(), this.get('intl').lookup('notification.purecloud.outOfAdherence.' + scheduledActivity).toString(), {
              target: this,
              hideAfter: 0,
              actionHandlers: {
                goToSchedule: {
                  label: this.get('intl').lookup('notification.purecloud.outOfAdherence.action').toString(),
                  action: function action() {
                    _this3.goToSchedule();

                    _this3.hideNotification(notification);
                  }
                }
              },
              meta: {
                topic: scheduledActivity
              },
              showNotificationEverywhere: true
            });
            this.set('outOfAdherenceNotification', notification);
          }
        } else if (notification) {
          this.hideNotification(notification);
        }
      } else if (notification) {
        this.hideNotification(notification);
      }
    },
    handleAdherenceAccessChange: Ember.observer('hasAdherenceAccess', function () {
      this.checkAdherenceState();
    }),
    handleScheduleUpdateAccessChange: Ember.observer('hasScheduleUpdateAccess', function () {
      this.initializeScheduleUpdateListener();
    }),
    hasAdherenceNotifyAccess: Ember.computed.reads('permissions.hasAgentAdherenceNotifyAccess'),
    hasAdherenceAccess: Ember.computed.reads('permissions.hasAgentScheduleAccess'),
    hasScheduleUpdateAccess: Ember.computed.and('permissions.hasAgentScheduleAccess', 'permissions.hasAgentScheduleNotificationAccess'),
    hasAgentActivityAccess: Ember.computed.reads('permissions.canViewAgentActivity'),
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      var guid = this.get('session.person.guid');
      var adherenceTopic = "v2.users.".concat(guid, ".workforcemanagement.adherence");
      var scheduleUpdateTopic = "v2.users.".concat(guid, ".workforcemanagement.schedules");
      this.get('pigeon').unRegisterTopic(adherenceTopic);
      this.get('pigeon').unRegisterTopic(scheduleUpdateTopic);
      this.get('pigeon').off('adherence', this, this.handleAdherenceChange);
      this.get('pigeon').off('schedules', this, this.handleScheduleUpdate);
      this.get('pigeon').off('connect', this, this.loadAdherenceData);
    }
  });

  _exports.default = _default;
});