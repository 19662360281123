define("web-directory/helpers/facet-term-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params, namedArgs) {
      var facet = namedArgs.facet;
      var term = namedArgs.term;
      var facetName = facet.facetName;

      if (term && facetName === 'general.groupType') {
        facetName = facetName.replace(/[\. ]/g, '_');
        term = term.replace(/[\. ]/g, '_');
        var intl = this.get('intl');
        var i18nKey = ['group', facetName, 'value', term].join('_');

        if (intl.query(i18nKey)) {
          return intl.lookup(i18nKey);
        }
      }

      return term;
    }
  });

  _exports.default = _default;
});