define("web-directory/utils/acd-v2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.replaceHtmlSpecialChars = void 0;

  var replaceHtmlSpecialChars = function replaceHtmlSpecialChars(str) {
    return str === null || str === void 0 ? void 0 : str.replace(/&quot;/g, '"').replace(/&#39;/g, "'").replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&').replace(/&#8217;/g, "'");
  };

  _exports.replaceHtmlSpecialChars = replaceHtmlSpecialChars;
});