define("web-directory/components/characters-used/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'p',
    classNames: ['character-counter', 'small', 'help-block'],
    intl: Ember.inject.service(),
    model: null,
    maxCount: 0,
    charactersUsed: Ember.computed('model.length', function () {
      var length = this.get('model.length') || 0;

      if (length > 1) {
        return this.get('intl').lookup('components.navMenu.feedback.charactersUsed', {
          characterCount: length,
          maxCount: this.get('maxCount')
        });
      }

      return '';
    })
  });

  _exports.default = _default;
});