define("web-directory/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Dqh0IMgn",
    "block": "{\"statements\":[[11,\"span\",[]],[15,\"class\",\"index\"],[13],[0,\"\\n    \"],[1,[33,[\"t\"],[\"auth.index\"],null],false],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/templates/index.hbs"
    }
  });

  _exports.default = _default;
});