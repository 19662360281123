define("web-directory/mixins/acd/after-call-work", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  function maybeLowerCase(str) {
    return str && str.toLowerCase();
  }

  var _default = Ember.Mixin.create({
    notes: null,
    selectedWrapupCode: null,
    wrapupNotSaved: true,
    oldWrapupCode: null,
    skipAfterCallWork: false,
    isAfterCallWorkTimerExpired: false,
    isWrapupCodeSet: computed.notEmpty('selectedWrapupCode'),
    isNotesSet: computed.notEmpty('notes'),
    activeCallWorkCompleted: computed.reads('participantForCurrentUser.wrapup.code'),
    activeCallWorkNeeded: computed('activeCallWorkCompleted', 'participantForCurrentUser.wrapupRequired', function () {
      return !this.get('activeCallWorkCompleted') && this.get('participantForCurrentUser.wrapupRequired');
    }),
    canUseCommunicationACW: computed.readOnly('interaction.orgLevelConversationsSettings.communicationBasedACW'),
    canAgentRequestACW: computed.and('canUseCommunicationACW', 'useCommunicationACW', 'isWrapupPromptAgentRequested'),
    useCommunicationACW: computed('canUseCommunicationACW', 'isWrapupPromptAgentRequested', function () {
      if (this.get('canUseCommunicationACW') && this.get('isWrapupPromptAgentRequested')) {
        return true;
      }

      return this.get('canUseCommunicationACW');
    }),
    isAfterCallWorkCompleted: computed('participantForCurrentUser.wrapup', 'participantForCurrentUser.wrapupRequired', 'skipAfterCallWork', 'isCommunicationAfterCallWorkCompleted', 'useCommunicationACW', function () {
      if (this.get('useCommunicationACW')) {
        return this.get('isCommunicationAfterCallWorkCompleted');
      }

      if (!this.get('participantForCurrentUser.wrapupRequired')) {
        return true;
      } else if (this.get('participantForCurrentUser.wrapup') || this.get('skipAfterCallWork')) {
        return true;
      }

      return false;
    }),
    communicationMedia: computed('participantForCurrentUser.calls.firstObject.wrapup', 'participantForCurrentUser.chats.firstObject.wrapup', 'participantForCurrentUser.emails.firstObject.wrapup', 'participantForCurrentUser.messages.firstObject.wrapup', 'participantForCurrentUser.callbacks.firstObject.wrapup', 'isChat', 'isCall', 'isCallback', 'isEmail', 'isSms', function () {
      var communicationObj = null;

      if (this.get('isCall')) {
        communicationObj = this.get('participantForCurrentUser.calls.firstObject');
      } else if (this.get('isChat')) {
        communicationObj = this.get('participantForCurrentUser.chats.firstObject');
      } else if (this.get('isEmail')) {
        communicationObj = this.get('participantForCurrentUser.emails.firstObject');
      } else if (this.get('isSms')) {
        communicationObj = this.get('participantForCurrentUser.messages.firstObject');
      } else if (this.get('isCallback')) {
        communicationObj = this.get('participantForCurrentUser.callbacks.firstObject');
      }

      return communicationObj;
    }),
    isCommunicationAfterCallWorkCompleted: computed('communicationMedia.wrapup', 'participantForCurrentUser.wrapupRequired', 'skipAfterCallWork', 'canAgentRequestACW', function () {
      if (this.get('canAgentRequestACW')) {
        if (!this.get('communicationMedia.afterCallWorkRequired')) {
          return true;
        }
      } else if (!this.get('participantForCurrentUser.wrapupRequired')) {
        return true;
      }

      return !!this.get('communicationMedia.wrapup') || this.get('skipAfterCallWork');
    }),
    isCommunicationAfterCallWorkRequested: computed.bool('connectedCommunication.afterCallWorkRequired'),
    isAfterCallWorkMandatory: computed('isCall', 'conversation.isAfterCallWorkMandatory', 'isCallback', 'needsCallWrapUp', 'participantForCurrentUser.wrapupPrompt', 'isNonDiscretionaryCompletion', 'canUseCommunicationACW', 'dialerPreview', function () {
      var wrapupPrompt = (this.get('participantForCurrentUser.wrapupPrompt') || '').toLowerCase();

      if (this.get('isCallback')) {
        return wrapupPrompt === 'mandatory' || this.get('dialerPreview');
      } else if (this.get('needsCallWrapUp')) {
        return true;
      } else if (this.get('isCall')) {
        return this.get('conversation.isAfterCallWorkMandatory');
      } else {
        if (this.get('canUseCommunicationACW')) {
          return wrapupPrompt === 'mandatory' || this.get('isNonDiscretionaryCompletion');
        }

        return wrapupPrompt === 'mandatory' || wrapupPrompt === 'timeout' || wrapupPrompt === 'forcedtimeout';
      }
    }),
    isAfterCallWorkForcedTimeout: computed('isCall', 'conversation.isAfterCallWorkForcedTimeout', 'participantForCurrentUser.wrapupPrompt', 'canUseCommunicationACW', function () {
      if (this.get('isCall')) {
        return this.get('conversation.isAfterCallWorkForcedTimeout');
      } else {
        if (this.get('canUseCommunicationACW')) {
          var prompt = (this.get('participantForCurrentUser.wrapupPrompt') || '').toLowerCase();
          return this.get('participantForCurrentUser.wrapupTimeoutMs') && prompt === 'forcedtimeout';
        } else {
          return false;
        }
      }
    }),
    isAfterCallWorkTimeout: computed('isCall', 'conversation.isAfterCallWorkTimeout', 'participantForCurrentUser.wrapupPrompt', 'canUseCommunicationACW', function () {
      if (this.get('isCall')) {
        return this.get('conversation.isAfterCallWorkTimeout');
      } else {
        if (this.get('canUseCommunicationACW')) {
          var prompt = (this.get('participantForCurrentUser.wrapupPrompt') || '').toLowerCase();
          return this.get('participantForCurrentUser.wrapupTimeoutMs') && prompt === 'timeout';
        } else {
          return false;
        }
      }
    }),
    isNonDiscretionaryCompletion: computed('isCall', 'conversation.isNonDiscretionaryCompletion', 'isWrapupPromptAgentRequested', 'isAfterCallWorkForcedTimeout', 'isAfterCallWorkTimeout', 'canUseCommunicationACW', function () {
      if (this.get('isCall')) {
        return this.get('conversation.isNonDiscretionaryCompletion');
      } else {
        if (this.get('canUseCommunicationACW')) {
          return this.get('isAfterCallWorkForcedTimeout') || this.get('isAfterCallWorkTimeout') || this.get('isWrapupPromptAgentRequested');
        } else {
          return false;
        }
      }
    }),
    isWrapupPromptAgentRequested: computed('participantForCurrentUser.wrapupPrompt', function () {
      var wrapupPrompt = maybeLowerCase(this.get('participantForCurrentUser.wrapupPrompt'));
      return wrapupPrompt === 'agentrequested';
    }),
    hasMissedTimer: computed('participantForCurrentUser.wrapup.code', 'isNonDiscretionaryCompletion', function () {
      return this.get('participantForCurrentUser.wrapup.code') === 'ININ-WRAP-UP-TIMEOUT' && this.get('isNonDiscretionaryCompletion');
    })
  });

  _exports.default = _default;
});