define("web-directory/components/call-controls/component", ["exports", "lodash", "web-directory/mixins/promise-loading", "ember-purecloud-components/mixins/logger", "web-directory/models/person", "web-directory/utils/focus-management"], function (_exports, _lodash, _promiseLoading, _logger, _person, _focusManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      observer = Ember.observer,
      run = Ember.run,
      service = Ember.inject.service;

  var _default = Ember.Component.extend(_promiseLoading.default, _logger.default, {
    classNames: ['call-controls-container', 'draggable-dropzone'],
    classNameBindings: ['phone.onCall:multiple-calls', 'dragClass'],
    session: service(),
    sidebar: service(),
    phone: service(),
    chatFrame: service(),
    permissions: service(),
    fax: service(),
    intl: service(),
    chat: service(),
    webrtcSdkService: service('webrtcSdk'),
    webrtc: service(),
    showCallControls: computed.readOnly('sidebar.showCallControls'),
    activeCall: null,
    activeFaxes: computed.reads('fax.activeFaxes'),
    station: computed.reads('phone.station'),
    onConference: computed.reads('activeCall.onConference'),
    onConsult: computed.reads('activeCall.onConsult'),
    activeCallId: computed.reads('activeCall.id'),
    transferPerson: null,
    transferNumber: null,
    transferParticipants: [],
    numberInputType: 'person',
    showTransfer: false,
    showDropdown: false,
    loadingChat: false,
    reloading: false,
    addingParticipants: false,
    itemToDrop: null,
    faxOutbound: computed.reads('permissions.canFax'),
    isActiveFaxes: computed.gte('fax.activeFaxes.length', 1),
    promptForMerge: computed.reads('phone.shouldPromptForMerge'),
    myGuid: computed.reads('session.person.guid'),
    personId: computed.reads('myGuid'),
    noStationSelected: computed.reads('phone.noStationSelected'),
    stationSelected: computed.not('noStationSelected'),
    needStation: computed.reads('phone.needStation'),
    showStationPicker: false,
    enableStationPicker: false,
    // TODO: remove (!features.followMe)
    peerDisconnectedCall: computed.reads('activeCall.peerDisconnectedCall'),
    myselfDisconnectedCall: computed.reads('activeCall.myselfDisconnectedCall'),
    roomJidToAddPartiesTo: null,
    partiesToGetAddedToChat: null,
    debouncePeriod: 1000,
    debounceTimers: [],
    participantsOnCall: [],
    peopleCache: [],
    dialpadShouldSendDtmf: computed.reads('phone.thereIsAConnectedConversation'),
    panels: {
      callHistory: true,
      directory: false,
      dialpad: false,
      inbox: false,
      station: false,
      call: false,
      fax: false,
      phoneSettings: false,
      followMeSettings: false
    },
    dropdowns: {
      held: false
    },
    testing: false,
    useCollaborateChat: computed.readOnly('session.useCollaborateChat'),
    // TODO: move this back to call forwarding mixin or phone service
    // see duplicate in user-dropdown.js
    canCallForward: computed.reads('phone.canCallForward'),
    callForwardingActive: computed.reads('phone.callForwardingActive'),
    offQueue: computed.not('phone.onQueue'),
    showEnableCallForwarding: computed('callForwardingActive', 'offQueue', function () {
      return !this.get('callForwardingActive') && this.get('offQueue');
    }),
    emergencyLocationOn: false,
    showEmergencyLocation: computed('emergencyLocationOn', 'phone.activeCall', function () {
      var onActiveCall = !!this.get('phone.activeCall');
      return this.get('emergencyLocationOn') && !onActiveCall;
    }),
    currentTab: computed.alias('sidebar.substates.callControls.currentTab'),
    persistentConnectionActive: computed('phone.persistentConnectionActive', 'webrtcSdkService.{useSdk,isPersistentConnection,hasActiveSoftphoneSession}', function () {
      if (!this.get('webrtcSdkService.useSdk')) {
        return this.get('phone.persistentConnectionActive');
      }

      return this.get('webrtcSdkService.isPersistentConnection') && this.get('webrtcSdkService.hasActiveSoftphoneSession');
    }),
    isPoppedOut: computed('phone.activeRoom.isPopout', function () {
      return this.get('phone.activeRoom.isPopout');
    }),
    removeScrollAndShrinkDialPad: computed('panels.dialpad', 'addingParticipants', function () {
      return this.get('panels.dialpad') && !this.get('addingParticipants');
    }),
    callControlFeatureToggleEnabled: Ember.computed.readOnly('session.features.epcDecompDropDownCallFaxControl'),
    actions: {
      clearPanels: function clearPanels() {
        this.clearPanels();
      },
      setPanels: function setPanels(obj) {
        var _this = this;

        Object.keys(obj).forEach(function (key) {
          _this.set("panels.".concat(key), obj[key]);
        });
      },
      openStationPicker: function openStationPicker() {
        this.send('changeActivePanel', 'followMeSettings', 'showStationPicker');
      },
      closeStationPicker: function closeStationPicker() {
        this.set('showStationPicker', false);
      },
      callForwarding: function callForwarding() {
        this.send('changeActivePanel', 'followMeSettings');
      },
      cancelCallForwarding: function cancelCallForwarding() {
        this.set('configuringCallForwarding', false);
      },
      disassociateStation: function disassociateStation() {
        return this.get('phone').disassociateStation();
      },
      disconnectPersistentConnection: function disconnectPersistentConnection() {
        if (this.get('webrtcSdkService.useSdk')) {
          return this.get('webrtcSdkService').endAllSoftphoneSessions();
        }

        return this.get('phone').disconnectPersistentConnection();
      },
      chooseStation: function chooseStation(uri) {
        return this.get('phone').chooseStation(uri);
      },
      held: function held() {
        if (this.get('phone.activeCall.onConsult')) {
          this.send('toggleDropdown', 'held');
        } else {
          this.get('phone').held();
        }
      },
      itemDropped: function itemDropped(dropEvent) {
        var conversationId = dropEvent.dataTransfer.getData('text/x-conversationId');
        var droppedConversation = this.get('phone.conversations').findBy('id', conversationId);

        if (!droppedConversation) {
          this.logger.error('Failed to find conversation for dropped conversationId', {
            conversationId: conversationId
          });
          return;
        }

        if (droppedConversation === this.get('phone.activeCall')) {
          this.logger.warn('Cannot merge active call with itself');
          return;
        }

        var callsToMerge = [];
        callsToMerge.push(this.get('phone').activeCall);
        callsToMerge.push(droppedConversation);
        this.get('phone').mergeCalls(callsToMerge);
        this.clearPanels();
        this.set('panels.call', true);
      },
      makeMergeRequest: function makeMergeRequest() {
        this.get('phone').makeMergeRequest();
      },
      cancelMerge: function cancelMerge() {
        this.get('phone').clearMergeData();
      },
      mute: function mute() {
        this.get('phone').mute();
      },
      endCall: function endCall() {
        if (this.get('phone.activeCall.onConsult')) {
          this.send('toggleDropdown', 'hangup');
        } else {
          this.get('phone').endCall();
          this.resetProperties();
        }
      },
      record: function record() {
        this.get('phone').record();
      },
      changeActiveCall: function changeActiveCall(conversation) {
        if (!conversation.get('isIncoming')) {
          this.logger.debug('call-controls:changeActiveCall: switching activeCall');
          this.get('phone').setActiveCall(conversation);
        }
      },
      addParticipant: function addParticipant() {
        this.set('addingParticipants', true);
      },
      dialNumber: function dialNumber(person) {
        var number = this.get('phoneNumber');

        if (number) {
          this.get('phone').makeCall(number.trim(), person);
          this.set('phoneNumber', null);
        }
      },
      toggleShowTransfer: function toggleShowTransfer() {
        this.toggleProperty('showTransfer');
      },
      toggleDropdown: function toggleDropdown(type) {
        if (this.get('phone.activeCall.canUpdateConsult')) {
          this.toggleProperty("dropdowns.".concat(type));
        }
      },
      selectPerson: function selectPerson(person) {
        var info = person.getFieldValue('primaryContactInfo.voice');
        var number = Ember.get(info, 'value.number');

        if (number) {
          this.set('phoneNumber', number);
          this.send('dialNumber', person);
        }
      },
      changeActivePanel: function changeActivePanel(panel, action) {
        this.set('currentTab', panel);

        if (action === 'showStationPicker') {
          this.set('showStationPicker', true);
        }

        if (action === 'addingCall') {
          this.set('addingCall', true);
        } else {
          this.set('addingCall', false);
        }
      },
      // Empty, used as a holder for chat-conversation
      noop: function noop() {
        return;
      },
      toggleNewCallFaxPopover: function toggleNewCallFaxPopover(status) {
        this.set('showDropdown', !status);
      }
    },
    showChatRoom: computed('phone.activeRoom', 'showTransfer', 'permissions.canViewBusinessChat', function () {
      if (!this.get('permissions.canViewBusinessChat')) {
        return false;
      }

      return this.get('phone.activeRoom') && !this.get('showTransfer') && !this.get('loadingChat');
    }),
    isInputNumber: computed.equal('numberInputType', 'number'),
    showTransferButtons: computed.notEmpty('transferParticipants'),
    onCall: computed('phone.{onCall,isActiveCallACD}', function () {
      return this.get('phone.onCall') && !this.get('phone.isActiveCallACD');
    }),
    activeNonAcdCalls: computed.reads('phone.activeNonAcdCalls'),
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      return this.get('debounceTimers').forEach(function (item) {
        return run.cancel(item);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      this._activePanelChanged();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var panels = this.get('panels');
      var panelKeys = Object.keys(panels);
      var isCallOnlyCurrentPanel = panelKeys.every(function (panel) {
        return !panels[panel] || panel === 'call' && panels[panel];
      }); // if not on a call and no active calls and current panel is call, switch to call history

      if (!this.get('onCall') && _lodash.default.isEmpty(this.get('activeNonAcdCalls')) && isCallOnlyCurrentPanel) {
        this.send('changeActivePanel', 'callHistory'); // if there's an active call that is not an agent call or originating from the webrtc-troubleshooter, switch to call panel
      } else if (this.get('activeCall') && !this.get('activeCall.isAgentCall') && !this.get('webrtc.webrtcTroubleshooterCall')) {
        this.send('changeActivePanel', 'call'); // if needs station, switch to station panel
      } else if (this.get('needStation')) {
        this.send('changeActivePanel', 'station'); // otherwise, switch to whatever current tab was passed in unless another tab has been selected
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var chat = this.get('chat');

      if (!(chat.get('connected') && this.get('activeCall'))) {
        return;
      }

      var people = [];
      var store = this.get('store');
      var chatPartiesOnCall = this.get('chatPartiesOnCall');

      if (!Ember.testing) {
        _lodash.default.each(chatPartiesOnCall, function (party) {
          if (store.peekRecord('person', party)) {
            people.push(Ember.RSVP.resolve(store.peekRecord('person', party)));
          } else {
            people.push(store.find('person', party));
          }
        });

        if (!_lodash.default.eq(this.get('peopleCache'), people)) {
          run.scheduleOnce('actions', this, this.setupJidsAndRoom, people);
        }

        this.set('peopleCache', people);
      }
    },
    _activePanelChanged: observer('currentTab', 'phone.activeCall.connectedParties', function () {
      this.set('showDropdown', false);
      var panel = this.get('currentTab');
      this.didUpdateAttrs();
      var key = "panels.".concat(panel);

      if (!this.get(key)) {
        // if panel is not already set, clear and set
        this.logger.debug("call-controls:changeActivePanel: setting panel to ".concat(panel));
        this.clearPanels();
        this.set(key, true);
      }
    }),
    itemToggled: function itemToggled(_, value) {
      this.send('changeActivePanel', value);
      var focusTarget = '.panel-content'; // We auto focus the dialpad when sending dtmf signals
      // PCUCC-89
      // only focus the panel if we're not sending dtmf signals

      if (value !== 'dialpad' && !this.get('dialpadShouldSendDtmf')) {
        (0, _focusManagement.doFocus)(Ember, focusTarget);
      }
    },
    panelConfigs: computed('faxOutbound', 'noStationSelected', 'phone.webrtcPhoneWithIssues', function () {
      return [Ember.Object.create({
        id: 'callHistory',
        showTab: true,
        icon: {
          guxIcon: {
            name: 'phone-call-history',
            decorative: true
          }
        },
        name: this.get('intl').t('purecloud.callHistory'),
        supplementalClassNames: 'tab monochromic-icon',
        sortPriority: 100
      }), Ember.Object.create({
        id: 'dialpad',
        showTab: true,
        icon: {
          guxIcon: {
            name: 'phone-dialpad',
            decorative: true
          }
        },
        name: this.get('intl').t('purecloud.dialPad'),
        supplementalClassNames: 'tab tab-dialpad monochromic-icon',
        sortPriority: 101
      }), Ember.Object.create({
        id: 'inbox',
        showTab: true,
        icon: {
          guxIcon: {
            name: 'inbox',
            decorative: true
          }
        },
        name: this.get('intl').t('components.navMenu.inbox'),
        supplementalClassNames: 'tab monochromic-icon',
        sortPriority: 102
      }), Ember.Object.create({
        id: 'followMeSettings',
        showTab: true,
        icon: {
          guxIcon: {
            name: this.get('noStationSelected') ? 'legacy/phone-disable' : 'phone',
            decorative: true
          }
        },
        name: this.get('intl').t('followMe.panelTitle'),
        supplementalClassNames: this.get('noStationSelected') ? 'tab station-picker no-station' : 'tab station-picker monochromic-icon',
        sortPriority: 103
      }), Ember.Object.create({
        id: 'fax',
        showTab: this.get('faxOutbound'),
        icon: {
          guxIcon: {
            name: 'fax',
            decorative: true
          }
        },
        name: this.get('intl').t('purecloud.fax.sendFax'),
        supplementalClassNames: 'tab monochromic-icon',
        sortPriority: 104
      }), Ember.Object.create({
        id: 'phoneSettings',
        showTab: true,
        icon: {
          guxIcon: {
            name: 'settings',
            decorative: 'true'
          }
        },
        name: this.get('intl').t('followMe.phoneSettings'),
        supplementalClassNames: this.get('phone.webrtcPhoneWithIssues') ? 'no-station tab station-picker' : 'tab station-picker monochromic-icon',
        sortPriority: 105
      })];
    }),
    setupJidsAndRoom: function setupJidsAndRoom(people) {
      var _this2 = this;

      var chat = this.get('chat');
      var partiesThatNeedToGetAdded = [];
      return Ember.RSVP.all(people).then(function (results) {
        // This thenable handles a special case where jids are undefined,
        // do a hard fetch with findMany
        var missingJids = _lodash.default.some(results, function (r) {
          return !r.get('jid');
        });

        if (missingJids) {
          return _this2.get('store').findMany('person', results.mapBy('guid'), _person.BASE_PERSON);
        } else {
          return Ember.RSVP.resolve(results);
        }
      }).then(function (results) {
        // This thenable just figures out which jids need to be added to which room.
        // returns the room object to next thenable
        var missingJids = _lodash.default.some(results, function (r) {
          return !r.get('jid');
        });

        if (missingJids) {
          Ember.Logger.error('Unable to get create chat room because jids were missing for some participants ', _this2.get('activeCallId'));
        }

        partiesThatNeedToGetAdded = results.mapBy('jid');

        if (partiesThatNeedToGetAdded.length === 2 && !_this2.get('onConference')) {
          partiesThatNeedToGetAdded = _lodash.default.remove(results, function (party) {
            return party.get('guid') === _this2.get('myGuid');
          }).mapBy('jid');

          var otherParty = _lodash.default.head(results);

          var jid = otherParty && otherParty.get('jid');

          if (jid) {
            return chat.getChatRoom(jid);
          }
        } else if (_this2.get('onConference') || _this2.get('onConsult')) {
          var jidId;

          if (_this2.get('useCollaborateChat')) {
            // prettier-ignore
            jidId = "adhoc-".concat(_this2.get('activeCallId'), "@conference.").concat(_this2.get('session.org.shortName'), ".orgspan.com");
          } else {
            jidId = "conference-".concat(_this2.get('activeCallId'));
          }

          return chat.joinRoom(jidId);
        }

        return Ember.RSVP.resolve(null);
      }).then(function (room) {
        // Gets the room object and then calls addPartiesToRoom or sets chatroom to null
        var duration = _this2.get('debouncePeriod');

        var debounceTimer;

        if (room) {
          debounceTimer = run.debounce(_this2, _this2.addPartiesToRoom, room, partiesThatNeedToGetAdded, duration, false);
        } else {
          debounceTimer = run.debounce(_this2, function () {
            return _this2.set('phone.activeRoom', null);
          }, duration);
        }

        if (debounceTimer) {
          _this2.get('debounceTimers').push(debounceTimer);
        }

        return Ember.RSVP.resolve(room);
      }).then(function (room) {
        if (room && _this2.get('useCollaborateChat')) {
          _this2.get('chatFrame').onCollaborateChatPhoneCall(_this2.get('activeCall'), room);
        }
      }).catch(function (error) {
        return Ember.Logger.error('could not set up chat room ', error);
      });
    },
    addPartiesToRoom: function addPartiesToRoom(room, partiesThatNeedToGetAdded) {
      var chatService = this.get('chat');
      this.set('partiesToGetAddedToChat', partiesThatNeedToGetAdded);

      if (partiesThatNeedToGetAdded.length > 0) {
        this.set('roomJidToAddPartiesTo', room.get('jid'));
        Ember.Logger.log('Adding parties ' + partiesThatNeedToGetAdded + ' to the room ' + room.get('jid'));
        chatService.addAllParticipantsToRoom(room, partiesThatNeedToGetAdded);
      }

      if (this.get('onConference')) {
        chatService.setRoomSubject(room, this.get('intl').t('purecloud.conference'));
      }

      chatService.loadRoomHistory(room);
      chatService.activateRoom(room);
      this.set('phone.activeRoom', room);
    },

    /*
        find the parties that should get added to this call's chat room.
        should only be connected participants and
        when on transfer, the non-intiators are always connected to the initiator unless
        it is an ad-hoc conference
    */
    chatPartiesOnCall: computed('activeCall.{connectedParties,connectedOnConsult,onConsult,initiator}', 'myGuid', function () {
      var participantsOnCall = this.get('activeCall.connectedParties');
      var initiator = this.get('activeCall.initiator');
      var iAmtheInitiator = initiator && initiator.userId === this.get('myGuid');

      var connectedAndNotConfinedParticipants = _lodash.default.reject(participantsOnCall, function (party) {
        var communication = _lodash.default.head(party.calls) || _lodash.default.head(party.callbacks);

        return communication.confined || communication.confined === 'true';
      });

      if (this.get('onConsult')) {
        // if only two connected, non-confined parties on call, and I'm not the initiator
        if (connectedAndNotConfinedParticipants.length === 2 && !iAmtheInitiator) {
          participantsOnCall = [initiator, this.get('activeCall.myself')];
        } else {
          participantsOnCall = connectedAndNotConfinedParticipants;
        }
      } // Theres a chance participantsOnCall could have some undefined members,
      // set to empty array in that case.


      if (participantsOnCall && !_lodash.default.some(participantsOnCall, function (value) {
        return value === undefined;
      })) {
        participantsOnCall = _lodash.default.reject(participantsOnCall, function (participant) {
          return !!participant.monitoredParticipantId || !!participant.coachedParticipantId || !!participant.bargedParticipantId;
        });
        participantsOnCall = participantsOnCall.filterBy('userId').mapBy('userId');
      } else {
        participantsOnCall = [];
      }

      return participantsOnCall;
    }),
    numberOfChatParties: computed('chatPartiesOnCall.[]', function () {
      return this.get('chatPartiesOnCall.length');
    }),
    chatPartiesClasses: computed('chatPartiesOnCall', function () {
      var classNames = '';

      _lodash.default.each(this.get('chatPartiesOnCall'), function (userId) {
        classNames += "chat-party-".concat(userId, " ");
      });

      return classNames;
    }),
    resetProperties: function resetProperties() {
      this.setProperties({
        phoneNumber: null,
        showTransfer: false,
        addingParticipants: false,
        numberInputType: 'person',
        pickedPerson: null,
        transferParticipants: []
      });
    },
    clearPanels: function clearPanels() {
      this.set('panels', {
        callHistory: false,
        directory: false,
        dialpad: false,
        inbox: false,
        station: false,
        call: false,
        fax: false,
        phoneSettings: false,
        followMeSettings: false
      });
    }
  });

  _exports.default = _default;
});