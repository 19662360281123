define("web-directory/models/chat-room", ["exports", "lodash", "web-directory/utils/dates", "web-directory/mixins/sort-chat-users", "web-directory/models/split-message", "ember-purecloud-components/utils/jid-helpers"], function (_exports, _lodash, _dates, _sortChatUsers, _splitMessage2, _jidHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var POPOUT_HEIGHT = 800;
  var POPOUT_WIDTH = 500;
  var POPOUT_LOCATION = -2000;

  var haveGroupData = function haveGroupData(group) {
    var haveId = !_lodash.default.isEmpty(group.get('id'));
    var haveName = !_lodash.default.isEmpty(group.get('name'));

    var groupId = group.getFieldValue && group.getFieldValue('general.name') && group.getFieldValue('general.name')._id;

    var isApiFieldConfigName = groupId && !_lodash.default.isEmpty(groupId);
    return haveId && haveName && isApiFieldConfigName;
  }; // TODO: Find a better Ember way/spot for this view oriented behavior.
  // This === shared by all chat-room models on purpose.  Rooms decide based
  // mute setting to animate ||!.
  // Similar code exist in interaction.js service


  var MAX_WAIT = 8000;

  var animateChatIcon = _lodash.default.debounce(function () {
    Ember.$('.action-item.chat a').addClass('faa-horizontal animated');
    Ember.run.later(function () {
      Ember.$('.action-item.chat a').removeClass('faa-horizontal animated');
    }, MAX_WAIT);
  }, MAX_WAIT, {
    leading: true,
    trailing: false,
    maxWait: MAX_WAIT
  }); // This size mirrors the 'messagesPerHistoryRequest' property in services/chat


  var PRUNE_SIZE = 25;
  var REMOVE_GC_USER_FROM_CHAT_FT = 'gcucc.ui.removeGcUserFromGroup.PCUCC-2543';
  var ChatRoom = Ember.Object.extend(_sortChatUsers.default, {
    session: Ember.inject.service('session'),
    webrtc: Ember.inject.service('webrtc'),
    batchDataRequests: Ember.inject.service(),
    chat: Ember.inject.service('chat'),
    chatFrame: Ember.inject.service('chatFrame'),
    videoConferenceDetails: Ember.inject.service(),
    jid: null,
    objectId: null,
    group: null,
    oneToOneTarget: null,
    rawSubject: null,
    pendingRoom: false,
    firstMessageTime: null,
    lastMessageTime: null,
    lastMessageTimeRadix: 0,
    roomClosedTimeRadix: 0,
    mentions: 0,
    unseen: 0,
    messageActivity: 0,
    userActivity: 0,
    loadingHistory: false,
    moreHistoryAvailable: false,
    moreForwardHistoryAvailable: false,
    activated: false,
    participants: null,
    acdCustomerParticipant: null,
    messages: null,
    messageCache: null,
    splitMessageCache: null,
    inputCache: null,
    isInRoster: false,
    participantsVisible: false,
    isFavorite: false,
    invited: false,
    showingVideo: false,
    joinCallImmediately: false,
    joinScreenshareImmediately: false,
    type: 'room',
    deferredParticipants: null,
    roomOwners: [],
    setupProperties: Ember.on('init', function () {
      this.setProperties({
        participants: [],
        messages: [],
        messageCache: Ember.Object.create({}),
        splitMessageCache: Ember.Object.create({}),
        firstMessageTime: (0, _dates.default)(0),
        lastMessageTime: (0, _dates.default)(0),
        deferredParticipants: Ember.A([])
      });
    }),
    authenticatedUserIsRoomOwner: Ember.computed('session.person', function () {
      var group = this.get('group');
      return group && group.isPersonOwner(this.get('session.person'));
    }),
    showingVideoObserver: Ember.observer('showingVideo', function () {
      if (!this.get('session.useCollaborateChat')) {
        return;
      }

      this.get('chatFrame').notifyCollaborateChat('collaborate-chat-host-toggled-showing-video', {
        roomJid: this.get('jid'),
        showingVideo: this.get('showingVideo')
      });
    }),
    resizeNewPopout: Ember.observer('popout', function () {
      var popout = this.get('popout');

      if (popout) {
        popout.resizeTo(POPOUT_WIDTH, POPOUT_HEIGHT);
        popout.moveTo(POPOUT_LOCATION, POPOUT_LOCATION);
        popout.focus();
      }
    }),
    videoPeers: Ember.computed.readOnly('videoConferenceDetails.activeCount'),
    videoConversation: Ember.computed('webrtc.conversations.[]', function () {
      var conversations = this.get('webrtc.conversations');

      if (conversations) {
        return conversations.findBy('room.jid', this.get('jid'));
      }

      return null;
    }),
    videoRoomFull: false,
    subject: Ember.computed('rawSubject', 'group.{id,name}', 'oneToOneTarget.name', function () {
      var group = this.get('group');

      if (!group) {
        return this.get('oneToOneTarget.name') || this.get('rawSubject') || this.get('oneToOneTarget.email_main') || '';
      }

      var haveId = !_lodash.default.isEmpty(group.id);
      var haveName = !_lodash.default.isEmpty(group.get('name'));
      var groupId = group.getFieldValue && group.getFieldValue('general.name') && group.id;
      var isApiFieldConfigName = groupId && !_lodash.default.isEmpty(groupId);

      if (haveId && haveName && isApiFieldConfigName) {
        return this.get('groupName');
      } else {
        return this.get('oneToOneTarget.name') || this.get('rawSubject') || this.get('oneToOneTarget.email_main') || '';
      }
    }),
    mapCanRemoveParticipant: function mapCanRemoveParticipant(participantList) {
      var _this = this;

      var group = this.get('group');
      var personId = this.get('session.person.id');
      return participantList.map(function (participant) {
        var isMember = group && group.isPersonIndividual(participant);
        var canRemove = !isMember && !_this.isRoomOwner(participant) && participant.id !== personId;
        Ember.set(participant, 'canBeRemovedFromRoom', canRemove);
        return participant;
      });
    },
    sortedParticipants: Ember.computed('participants.@each.presence', 'allowUserRemoval', function () {
      if (this.get('allowUserRemoval')) {
        var list = this.mapCanRemoveParticipant(this.get('participants'));
        return this.sortChatUsers(list);
      }

      return this.sortChatUsers(this.get('participants'));
    }),
    skipGroupDataCheckEnabled: Ember.computed('session.features', function () {
      var features = this.get('session.features');
      return features && features['gcucc.ui.PCUCC-3104'];
    }),
    groupName: Ember.computed.reads('group.name'),
    isAdhocRoom: Ember.computed('jid', function () {
      var jid = this.get('jid');
      return (0, _jidHelpers.isRoomJid)(jid) && _lodash.default.isEmpty(this.get('groupName'));
    }),
    isAcdRoom: Ember.computed('jid', function () {
      return (0, _jidHelpers.isAcdJid)(this.get('jid'));
    }),
    isSupervisorRoom: Ember.computed('jid', function () {
      return (0, _jidHelpers.isSupervisorJid)(this.get('jid'));
    }),
    isSoftphoneRoom: Ember.computed('jid', function () {
      return (0, _jidHelpers.isSoftphoneJid)(this.get('jid'));
    }),
    isOneToOne: Ember.computed('jid', function () {
      return this.isOneToOneRoom();
    }),
    allowUserRemoval: Ember.computed('session.features', function () {
      var features = this.get('session.features');
      return features && features[REMOVE_GC_USER_FROM_CHAT_FT];
    }),
    processRoomDetails: function processRoomDetails(_ref) {
      var owners = _ref.owners;
      this.set('roomOwners', owners);
    },
    isRoomOwner: function isRoomOwner(person) {
      var group = this.get('group');
      var isGroupOwner = !!group && group.isPersonOwner(person);
      var personId = Ember.get(person, 'id');
      var isOwner = this.get('roomOwners').some(function (owner) {
        return owner.id === personId;
      });
      return isOwner || isGroupOwner;
    },
    getRawDisplaySubject: function getRawDisplaySubject() {
      // Get a version of the room subject that has! been decorated with markdown, for usages in places where the
      // data will show up without being html rendered (such as mentions)
      var group = this.get('group'); // we're skipping the haveGroupData check

      if (this.get('skipGroupDataCheckEnabled')) {
        if (group) {
          return this.get('groupName');
        }
      } else if (group && haveGroupData(group)) {
        return this.get('groupName');
      }

      return this.get('oneToOneTarget.name') || this.get('rawSubject') || this.get('oneToOneTarget.email_main') || '';
    },
    clearMessageCache: function clearMessageCache() {
      this.set('activated', false);
      this.set('messages', []);
      this.set('messageCache', Ember.Object.create({}));
    },
    pruneMessages: function pruneMessages() {
      var _this2 = this;

      var messages = this.get('messages');

      if (messages.length > PRUNE_SIZE) {
        (function () {
          var messageCache = _this2.get('messageCache');

          var splitMessageCache = _this2.get('splitMessageCache');

          var removeCachedItemsCount = messages.length - PRUNE_SIZE;

          for (var i = 0; i < removeCachedItemsCount; i++) {
            var message = messages[i];

            if (message.get('splitMessage')) {
              message.get('messageArray').forEach(function (message) {
                delete messageCache[message.get('id')];
                delete messageCache[message.get('oid')];
                message.destroy();
              });
              delete splitMessageCache[message.get('splitMessageId')];
            }

            delete messageCache[message.get('oid')];
            delete messageCache[message.get('id')];
            message.destroy();
          }

          _this2.set('messages', _lodash.default.takeRight(messages, PRUNE_SIZE));

          _this2.set('moreHistoryAvailable', true);

          _this2.reconcileMessages();
        })();
      }
    },
    addHistoryMessages: function addHistoryMessages(messages) {
      if (messages && messages.length > 0) {
        this.processHistoryModels(messages);
      }
    },
    processHistoryModels: function processHistoryModels(history) {
      history = this.filterSeenMessages(_lodash.default.uniqBy(history, 'id'));
      this.addSelfReference(history);
      this.insertHistory(history);
      this.reconcileMessages();
    },
    reconcileMessages: function reconcileMessages() {
      this.sortChatMessages();
      this.processCorrections();
      this.processSplitMessageHistory();
      this.markConversationBlockBoundaries();
      this.updateFirstUnread();
      this.updateMessageTimes();
    },
    filterSeenMessages: function filterSeenMessages(history) {
      var cache = this.get('messageCache');
      return _lodash.default.filter(history, function (message) {
        return !Object.prototype.hasOwnProperty.call(cache, message.get('id'));
      });
    },
    addSelfReference: function addSelfReference(history) {
      var _this3 = this;

      _lodash.default.each(history, function (message) {
        return message.set('room', _this3);
      });

      return null;
    },
    bumpActivityCount: function bumpActivityCount(message) {
      this.incrementProperty('messageActivity');

      if ((0, _jidHelpers.compareJids)(message.get('chatUser.jid'), this.get('session.person.jid'))) {
        this.incrementProperty('userActivity');
      }
    },
    insertHistory: function insertHistory(historyMessages) {
      this.get('messages').unshiftObjects(historyMessages);
      var cache = this.get('messageCache');

      _lodash.default.each(historyMessages, function (message) {
        cache.set(message.get('id'), message);
      });
    },
    sortChatMessages: function sortChatMessages() {
      var messages = _lodash.default.sortBy(this.get('messages'), function (message) {
        return message.get('time').valueOf();
      });

      this.set('messages', messages);
    },
    processSplitMessageHistory: function processSplitMessageHistory() {
      var _this4 = this;

      var messages = this.get('messages').map(function (message) {
        if (message.get('isSplitMessage')) {
          var splitMessage = _this4.processSplitMessage(message);

          if (splitMessage.get('messageArray.length') <= 1) {
            return splitMessage;
          }

          return null;
        }

        return message;
      }).filter(function (message) {
        return !!message;
      });
      this.set('messages', messages);
    },
    processCorrections: function processCorrections() {
      var messages = this.get('messages');

      _lodash.default.each(messages, function (message) {
        if (message.get('corrects')) {
          var correctedMessage = _lodash.default.find(messages, function (m) {
            return m.get('id') === message.get('corrects');
          });

          if (correctedMessage) {
            correctedMessage.set('correction', message);
          }
        } else if (message.get('isEdited')) {
          message.set('correction', message);
        }
      });

      this.set('messages', messages);
    },
    markConversationBlockBoundaries: function markConversationBlockBoundaries() {
      var messages = this.get('messages');

      if (messages.length === 0) {
        return;
      }

      _lodash.default.head(messages).setProperties({
        startOfBlock: true,
        endOfBlock: true,
        isLastMessage: false,
        isYourLastMessage: false
      });

      _lodash.default.last(messages).setProperties({
        endOfBlock: true,
        isLastMessage: true
      });

      if (messages.length === 1) {
        var message = messages[0];
        message.set('isYourLastMessage', message.get('isYou'));
      }

      if (messages.length === 1) {
        return;
      }

      var isYourLastMessage = null;

      for (var i = 0; i <= messages.length - 2; i++) {
        var last = messages[i];
        var next = messages[i + 1];

        if (last.get('isYou')) {
          isYourLastMessage = last;
        } else {
          last.set('isYourLastMessage', false);
        }

        if (next.get('isYou')) {
          isYourLastMessage = next;
        } else {
          next.set('isYourLastMessage', false);
        }

        if (this.shouldGroupMessages(last, next)) {
          last.set('endOfBlock', false);
          next.set('startOfBlock', false);
        } else {
          last.set('endOfBlock', true);
          next.set('startOfBlock', true);
        }
      }

      if (isYourLastMessage) {
        isYourLastMessage.set('isYourLastMessage', true);
      }
    },
    updateFirstUnread: function updateFirstUnread() {
      if (this.get('firstUnread')) {
        this.set('firstUnread.firstUnread', false);
        this.set('firstUnread', null);
      }

      var messages = this.get('messages');

      for (var i = 0; i < messages.length; i++) {
        var message = messages[i];

        if (message.get('unread')) {
          message.set('firstUnread', true);
          this.set('firstUnread', message);
          return;
        }
      }
    },
    processSplitMessage: function processSplitMessage(message) {
      var splitMessageCache = this.get('splitMessageCache');
      var stanza = message.get('stanza.children').findBy('name', 'multipart-message');
      var splitMessageData = JSON.parse(Ember.get(stanza, 'children.lastObject'));
      var splitMessageParentExists = Object.prototype.hasOwnProperty.call(splitMessageCache, splitMessageData.id);

      if (!splitMessageParentExists) {
        var owner = Ember.getOwner(this);

        var _splitMessage = _splitMessage2.default.create({
          id: message.id,
          oid: message.oid,
          from: message.from,
          to: message.to,
          time: message.time,
          chatUser: message.chatUser,
          splitMessageId: splitMessageData.id,
          totalMessages: splitMessageData.messageCount
        }, owner.ownerInjection());

        this.addSelfReference([_splitMessage]);
        splitMessageCache.set(splitMessageData.id, _splitMessage);
      }

      var splitMessage = splitMessageCache.get(splitMessageData.id);
      message.set('splitMessageIndex', splitMessageData.index);
      splitMessage.addSplitMessage(message);
      return splitMessage;
    },
    addMessage: function addMessage(message) {
      var messages = this.get('messages');
      var cache = this.get('messageCache');
      var msgId = message.get('id') || message.get('oid');
      this.addSelfReference([message]);

      if (Object.prototype.hasOwnProperty.call(cache, msgId)) {
        message.set('duplicate', true);
        return;
      }

      cache.set(msgId, message);

      if (message.get('isSplitMessage')) {
        var splitMessage = this.processSplitMessage(message);

        if (splitMessage.get('messageArray.length') > 1) {
          return;
        }

        message = splitMessage;
      }

      var roomJid = message.get('room.jid');
      var isSupervisor = this.get('isSupervisorRoom');
      var isAcd = this.get('isAcdRoom');

      if (!isAcd && !isSupervisor) {
        this.bumpActivityCount(message);
      }

      if (message.get('unread') && !message.get('corrects')) {
        if (!this.get('muted')) {
          if (roomJid && !isAcd && !isSupervisor) {
            animateChatIcon();
          }
        }

        if (!this.get('firstUnread')) {
          message.set('firstUnread', true);
          this.set('firstUnread', message);
        }
      } // default message to its own block


      message.set('startOfBlock', true);
      message.set('endOfBlock', true);

      var lastMessage = _lodash.default.last(messages);

      messages.pushObject(message);
      this.updateMessageTimes();

      if (message.get('isYou')) {
        var yourLast = _lodash.default.findLast(messages, function (message) {
          return message.get('isYourLastMessage');
        });

        if (yourLast) {
          yourLast.set('isYourLastMessage', false);
        }

        message.set('isYourLastMessage', true);
      }

      if (!lastMessage) {
        return;
      }

      if (message.get('time').isBefore(lastMessage.get('time'))) {
        Ember.Logger.warn('chat-room : Message came in out of order');
        this.reconcileMessages();
        return;
      }

      if (message.get('corrects')) {
        this.processCorrections();
      }

      if (this.shouldGroupMessages(lastMessage, message)) {
        lastMessage.set('endOfBlock', false);
        message.set('startOfBlock', false);
      }
    },
    shouldGroupMessages: function shouldGroupMessages(oldMessage, newMessage) {
      return oldMessage && newMessage && oldMessage.get('from') === newMessage.get('from') && oldMessage.get('unread') === newMessage.get('unread') && !oldMessage.get('corrects') && 2 >= Math.abs(newMessage.get('time').diff(oldMessage.get('time'), 'minutes'));
    },
    updateMessageTimes: function updateMessageTimes() {
      var first = this.get('messages.firstObject');
      var last = this.get('messages.lastObject');
      var firstMessageTime = (0, _dates.default)(first ? first.get('time') : 0);
      var lastMessageTime = (0, _dates.default)(last ? last.get('time') : 0);
      this.setProperties({
        firstMessageTime: firstMessageTime,
        lastMessageTime: lastMessageTime
      });
      return this.updateMessageTimeRadix();
    },
    updateMessageTimeRadix: function updateMessageTimeRadix() {
      // sort messages by last message sent
      var messages = this.get('messages');
      var lastMessage = messages[messages.length - 1];
      return this.set('lastMessageTimeRadix', lastMessage ? lastMessage.get('time').valueOf() : 0);
    },
    updateClosedTimeRadix: function updateClosedTimeRadix() {
      this.set('roomClosedTimeRadix', (0, _dates.default)().valueOf());
    },
    addParticipant: function addParticipant(person) {
      if (person) {
        this.get('participants').addObject(person);
      }
    },
    removeParticipant: function removeParticipant(person) {
      this.get('participants').removeObject(person);
    },
    clearUnreadMessages: function clearUnreadMessages() {
      if (this.get('firstUnread')) {
        this.get('firstUnread').set('firstUnread', false);
      }

      this.set('firstUnread', null);
      var messages = this.get('messages');

      for (var i = 0; i < messages.length; i++) {
        var message = messages[i];
        message.set('unread', false);
      }

      return this.markConversationBlockBoundaries();
    },
    isOneToOneRoom: function isOneToOneRoom() {
      return (0, _jidHelpers.isPersonJid)(this.get('jid'));
    },
    isGroupRoom: function isGroupRoom() {
      return (0, _jidHelpers.isGroupJid)(this.get('jid'));
    },
    isUserInRoom: function isUserInRoom(userjid) {
      return this.get('participants').findBy('jid', userjid);
    },
    clearPending: function clearPending(message) {
      var existingMessage = this.get("messageCache.".concat(message.oid));

      if (existingMessage && _typeof(message) === 'object') {
        existingMessage.set('id', message.id);
        existingMessage.set('time', (0, _dates.default)(message.time));
        existingMessage.set('pending', false);
        this.set("messageCache.".concat(message.oid), null);
        this.set("messageCache.".concat(message.id), existingMessage);
      }
    },
    setSendError: function setSendError(messageId) {
      var message = this.get("messageCache.".concat(messageId));

      if (message) {
        message.set('pending', false);
        message.set('sendError', true);
      }
    },
    addDeferredParticipant: function addDeferredParticipant(jid) {
      this.get('deferredParticipants').addObject(jid);
    },
    loadDeferredParticipants: function loadDeferredParticipants() {
      var _this5 = this;

      var deferred = Ember.A(_toConsumableArray(this.get('deferredParticipants')));
      deferred.forEach(function (jid) {
        _this5.get('batchDataRequests').getChatUser(jid).then(function (person) {
          _this5.addParticipant(person);
        }); // Semi-deferred to run loop


        Ember.run.join(function () {
          return _this5.get('deferredParticipants').removeObject(jid);
        });
      });
    }
  });
  var _default = ChatRoom;
  _exports.default = _default;
});