define("web-directory/initializers/media-providers", ["exports", "web-directory/media-providers/provider", "web-directory/media-providers/zoom", "web-directory/media-providers/purecloud", "web-directory/media-providers/teams"], function (_exports, _provider, _zoom, _purecloud, _teams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _register(application, id, config) {
    application.register(id, config, {
      singleton: false
    });
  }

  function initialize(application) {
    _register(application, 'media-provider:base', _provider.default);

    _register(application, 'media-provider:zoom', _zoom.default);

    _register(application, 'media-provider:purecloud', _purecloud.default);

    _register(application, 'media-provider:teams', _teams.default);
  }

  var _default = {
    name: 'setupMediaProviders',
    after: 'orgspan',
    initialize: initialize
  };
  _exports.default = _default;
});