define("web-directory/components/change-pin/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ChangePinComponent = Ember.Component.extend({
    classNames: ['change-pin'],
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    application: Ember.inject.service(),
    newPin: null,
    newPinRepeat: null,
    newPinError: false,
    errorMessage: null,
    resetError: false,
    resetSuccess: false,
    message: null,
    submitDisabled: Ember.computed('newPin', 'newPinRepeat', function () {
      var newPin = this.get('newPin');
      var repeat = this.get('newPinRepeat');
      var inputValid = newPin && repeat && newPin === repeat;
      return !inputValid;
    }),
    actions: {
      submit: function submit() {
        var _this = this;

        var newPin = this.get('newPin');
        var verifyPin = this.get('newPinRepeat');

        var flag = this._verifyInputs(newPin, verifyPin);

        if (flag) {
          var guid = this.get('session.person.guid');
          var pinData = {
            pin: verifyPin
          };
          var path = this.get('application').pcUri("api/v2/voicemail/userpolicies/".concat(guid));
          var promise = this.get('ajax').patch(path, {
            data: pinData,
            timeout: 5000
          });
          promise.then(function () {
            _this.setProperties({
              resetError: false,
              resetSuccess: true
            });

            _this.resetValues();
          }).catch(function () {
            _this.setProperties({
              resetError: true,
              resetSuccess: false,
              errorMessage: _this.get('intl').lookup('auth.pin.error')
            });
          });
        }
      },
      validate: function validate() {
        if (arguments[0].length < this.get('newPin.length')) {
          return this.set('newPinError', false);
        }

        if (arguments[0] !== this.get('newPin')) {
          return this.set('newPinError', true);
        }

        return this.set('newPinError', false);
      }
    },
    alertMessageFeatureToggleEnabled: Ember.computed('session.features', function () {
      return this.get('session.features')['epc.decomp.alert-message'];
    }),
    resetValues: function resetValues() {
      this.setProperties({
        newPin: null,
        newPinRepeat: null,
        message: null,
        submitDisabled: true
      });
    },
    _verifyInputs: function _verifyInputs(newP, verify) {
      var intl = this.get('intl');

      if (newP.length === 4 && verify.length === 4) {
        if (newP === verify) {
          var flag = /^\d{4}$/.test(verify);

          if (flag) {
            return true;
          } else {
            this.setProperties({
              resetError: true,
              errorMessage: intl.lookup('auth.pin.validation.numberError')
            });
            return false;
          }
        } else {
          this.setProperties({
            resetError: true,
            errorMessage: intl.lookup('auth.pin.validation.pinRepeatError')
          });
          return false;
        }
      } else {
        this.setProperties({
          resetError: true,
          errorMessage: intl.lookup('auth.pin.validation.pinLength')
        });
        return false;
      }
    }
  });
  var _default = ChangePinComponent;
  _exports.default = _default;
});