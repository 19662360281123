define("web-directory/iframe-client-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.modalClients = _exports.centerStageClients = void 0;
  var location = window.location;

  var makeEngageRedirectArgs = function makeEngageRedirectArgs(_ref) {
    var _ref$path = _ref.path,
        path = _ref$path === void 0 ? '' : _ref$path;
    return {
      engagePath: path.replace(/^\/engage\//, '')
    };
  };

  var makeAdminRedirectArgs = function makeAdminRedirectArgs(_ref2) {
    var _ref2$path = _ref2.path,
        path = _ref2$path === void 0 ? '' : _ref2$path;
    return {
      adminPath: path.replace(/^\/admin\//, '')
    };
  };

  var makeAdminV3RedirectArgs = function makeAdminV3RedirectArgs(_ref3) {
    var _ref3$path = _ref3.path,
        path = _ref3$path === void 0 ? '' : _ref3$path;
    return {
      // Query parameters in the redirect were causing some kind of infinite routing loop
      adminPath: path.replace(/^\/admin\//, '').split('?')[0]
    };
  };

  var telephonyAdminKoUri = '/contact-center/components/telephonyAdmin/koApp/#';
  var telephonyAdminUri = '/contact-center/components/telephonyAdmin/app/#';

  var getTelephonyAdminTimeMachineUri = function getTelephonyAdminTimeMachineUri(version, hash) {
    var isLegacy = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    return "/contact-center/components/telephonyAdmin/".concat(version, "/").concat(isLegacy ? 'koApp' : 'app', "/#").concat(hash);
  };

  var modalClients = {
    wemCoachingUi: {
      url: new URL('/wem-coaching-ui/#/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL("/wem-coaching-ui/".concat(version, "/#/"), location).toString();
      },
      assignedRoute: '/wem-coaching-ui',
      defaultTitle: 'Wem Coaching UI'
    },
    wemCoachingUiAddExisting: {
      url: new URL('/wem-coaching-ui/#/addToExisting', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL("/wem-coaching-ui/".concat(version, "/#/addToExisting"), location).toString();
      },
      assignedRoute: '/addToExisting',
      defaultTitle: 'Wem Coaching UI'
    },
    wemLearningUi: {
      url: new URL('/wem-learning-ui/#/performance/modules/manual-assign', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL("/wem-learning-ui/".concat(version, "/#/performance/modules/manual-assign"), location).toString();
      },
      assignedRoute: '/admin/performance/modules/manual-assign',
      defaultTitle: 'Manual Assign Modules'
    },
    iamAdminDeletePolicyModal: {
      url: new URL('/iam-admin/#/policies/delete', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL("/iam-admin/".concat(version, "/#/policies/delete"), location).toString();
      },
      assignedRoute: '/admin/people-permissions/policies/delete',
      defaultTitle: 'Delete Policy'
    },
    recognitionsUi: {
      url: new URL('/recognitions-ui/#/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL("/recognitions-ui/".concat(version, "/#/"), location).toString();
      },
      assignedRoute: '/recognitions-ui',
      defaultTitle: 'Recognitions UI'
    },
    iamAdminDeleteSSOModal: {
      url: new URL('/iam-admin/#/sso/delete', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL("/iam-admin/".concat(version, "/#/sso/delete"), location).toString();
      },
      assignedRoute: '/admin/people-permissions/sso/delete',
      defaultTitle: 'Delete Single-Sign On'
    },
    iamAdminDeleteOAuthClientModal: {
      url: new URL('/iam-admin/#/oauth-clients/delete', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL("/iam-admin/".concat(version, "/#/oauth-clients/delete"), location).toString();
      },
      assignedRoute: '/admin/access-management/oauth-clients/delete',
      defaultTitle: 'Delete OAuth Client'
    }
  };
  _exports.modalClients = modalClients;
  var centerStageClients = {
    liveNow: {
      url: new URL('/journey-micro/gpe-ui-live-now/#/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/journey-micro/gpe-ui-live-now/".concat(version, "/#/");
      },
      assignedRoute: '/admin/live-now',
      defaultTitle: 'Live Now',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.predictiveEngagement.predictiveEngagement'
        }, {
          textKey: 'frameNav.predictiveEngagement.liveNow'
        }],
        menu: 'admin:PREDICTIVE_ENGAGEMENT'
      }
    },
    journeyShapingOutcome: {
      url: new URL('/journey-micro/gpe-ui-journey-shaping/#/journey/outcome', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL("/journey-micro/gpe-ui-journey-shaping/".concat(version, "/#/journey/outcome"), location).toString();
      },
      assignedRoute: '/admin/journey/outcome',
      defaultTitle: 'Journey Shaping Outcome ',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.predictiveEngagement.predictiveEngagement'
        }, {
          textKey: 'frameNav.predictiveEngagement.outcomes'
        }],
        menu: 'admin:PREDICTIVE_ENGAGEMENT'
      }
    },
    journeyShapingSegment: {
      url: new URL('/journey-micro/gpe-ui-journey-shaping/#/journey/segment', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL("/journey-micro/gpe-ui-journey-shaping/".concat(version, "/#/journey/segment"), location).toString();
      },
      assignedRoute: '/admin/journey/segment',
      defaultTitle: 'Journey Shaping Segment',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.predictiveEngagement.predictiveEngagement'
        }, {
          textKey: 'frameNav.predictiveEngagement.segments'
        }],
        menu: 'admin:PREDICTIVE_ENGAGEMENT'
      }
    },
    PEFActionMap: {
      url: new URL('/journey/admin/#/embed/engagement/action-map', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL("/journey/admin/".concat(version, "/#/embed/engagement/action-map"), location).toString();
      },
      assignedRoute: '/admin/embed/engagement/action-map',
      defaultTitle: 'Engagement Action Map',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.predictiveEngagement.predictiveEngagement'
        }, {
          textKey: 'frameNav.predictiveEngagement.actionMaps'
        }],
        menu: 'admin:PREDICTIVE_ENGAGEMENT'
      }
    },
    PEFContentOffer: {
      url: new URL('/journey/admin/#/library/action-template/content-offer', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL("/journey/admin/".concat(version, "/#/library/action-template/content-offer"), location).toString();
      },
      assignedRoute: '/admin/embed/library/action-template/content-offer',
      defaultTitle: 'Engagement Content Offer',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.predictiveEngagement.predictiveEngagement'
        }, {
          textKey: 'frameNav.predictiveEngagement.actionLibrary'
        }],
        menu: 'admin:PREDICTIVE_ENGAGEMENT'
      }
    },
    PEFGlobalSettings: {
      url: new URL('/journey/admin/#/global-settings', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL("/journey/admin/".concat(version, "/#/global-settings"), location).toString();
      },
      assignedRoute: '/admin/embed/global-settings',
      defaultTitle: 'Engagement Global Settings',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.predictiveEngagement.predictiveEngagement'
        }, {
          textKey: 'frameNav.predictiveEngagement.globalSettings'
        }],
        menu: 'admin:PREDICTIVE_ENGAGEMENT'
      }
    },
    PEFAnalyticsVisitor: {
      url: new URL('/journey/admin/#/analytics/visitor-activity', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL("/journey/admin/".concat(version, "/#/analytics/visitor-activity"), location).toString();
      },
      assignedRoute: '/admin/embed/analytics/visitor-activity',
      defaultTitle: 'Engagement Visitor Activity Report',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.predictiveEngagement.predictiveEngagement'
        }, {
          textKey: 'frameNav.predictiveEngagement.visitorActivityReport'
        }],
        menu: 'admin:PREDICTIVE_ENGAGEMENT'
      }
    },
    PEFAnalyticsActionMap: {
      url: new URL('/journey/admin/#/analytics/action-map', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL("/journey/admin/".concat(version, "/#/analytics/action-map"), location).toString();
      },
      assignedRoute: '/admin/embed/analytics/action-map',
      defaultTitle: 'Engagement Action Map Report',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.predictiveEngagement.predictiveEngagement'
        }, {
          textKey: 'frameNav.predictiveEngagement.actionMapReport'
        }],
        menu: 'admin:PREDICTIVE_ENGAGEMENT'
      }
    },
    topicDefinitions: {
      url: new URL('/topics-definitions-ui/#/', location).toString(),
      assignedRoute: '/topics-definitions',
      defaultTitle: 'Topics Definitions',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.quality.qualityAdmin',
          href: '#/engage/quality'
        }],
        //TODO: this should migrate to a different source once PCBA is in iframe-coordinator
        menu: 'engage:quality'
      }
    },
    staCategoryDefinitions: {
      url: new URL('/sta-category-definitions-ui/#/', location).toString(),
      assignedRoute: '/quality/admin/sta-category-definitions',
      defaultTitle: 'STA Category Definitions',
      featureToggle: {
        key: 'staCategories'
      },
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.quality.qualityAdmin',
          href: '#/engage/quality'
        }],
        //TODO: this should migrate to a different source once PCBA is in iframe-coordinator
        menu: 'engage:quality'
      }
    },
    qualityAdminEncryptionKeys: {
      url: new URL('/quality-admin-ui/#/encryptionKeys', location).toString(),
      assignedRoute: '/quality/admin/encryptionKeys',
      defaultTitle: 'Encryption Keys',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.quality.qualityAdmin',
          href: '#/engage/quality'
        }],
        //TODO: this should migrate to a different source once PCBA is in iframe-coordinator
        menu: 'engage:quality'
      },
      featureToggle: {
        key: 'qm.ui.qualityAdmin.encryptionKeys',
        redirect: 'engage.embedded',
        makeRedirectArgs: function makeRedirectArgs() {
          return {
            engagePath: 'quality/admin/encryptionKeys'
          };
        }
      }
    },
    qualityAdminEvaluationForms: {
      url: new URL('/quality-admin-ui/#/evaluationForms', location).toString(),
      assignedRoute: '/quality/admin/evaluationForms',
      defaultTitle: 'Evaluation Forms',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.quality.qualityAdmin',
          href: '#/engage/quality'
        }],
        //TODO: this should migrate to a different source once PCBA is in iframe-coordinator
        menu: 'engage:quality'
      },
      featureToggle: {
        key: 'qm.ui.qualityAdmin.qualityForms',
        redirect: 'engage.embedded',
        makeRedirectArgs: function makeRedirectArgs() {
          return {
            engagePath: 'admin/evaluationForms'
          };
        }
      }
    },
    qualityAdminSurveyForms: {
      url: new URL('/quality-admin-ui/#/surveyForms', location).toString(),
      assignedRoute: '/quality/admin/surveyForms',
      defaultTitle: 'Survey Forms',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.quality.qualityAdmin',
          href: '#/engage/quality'
        }],
        //TODO: this should migrate to a different source once PCBA is in iframe-coordinator
        menu: 'engage:quality'
      },
      featureToggle: {
        key: 'qm.ui.qualityAdmin.qualityForms',
        redirect: 'engage.embedded',
        makeRedirectArgs: function makeRedirectArgs() {
          return {
            engagePath: 'admin/surveyForms'
          };
        }
      }
    },
    quality: {
      url: new URL('/quality-ifc/#/', location).toString(),
      assignedRoute: '/admin/quality',
      defaultTitle: 'Quality Admin',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.quality.qualityAdmin',
          href: '#/engage/quality',
          id: 'quality'
        }],
        menu: 'admin:QUALITY'
      },
      featureToggle: {
        key: 'qm.adminUIDecomp'
      }
    },
    staSettings: {
      url: new URL('/speech-and-text-analytics-settings-ui/#/', location).toString(),
      assignedRoute: '/quality/admin/speech-and-text-analytics',
      defaultTitle: 'Speech and Text Analysis',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.quality.qualityAdmin',
          href: '#/engage/quality'
        }],
        //TODO: this should migrate to a different source once PCBA is in iframe-coordinator
        menu: 'engage:quality'
      }
    },
    dictionaryFeedback: {
      url: new URL('/dictionary-feedback-ui/#/', location).toString(),
      assignedRoute: '/quality/admin/dictionary-management',
      defaultTitle: 'Dictionary Management',
      featureToggle: {
        key: 'staFeedbackDictionaryUI'
      },
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.quality.qualityAdmin',
          href: '#/engage/quality'
        }],
        //TODO: this should migrate to a different source once PCBA is in iframe-coordinator
        menu: 'engage:quality'
      }
    },
    sentimentFeedback: {
      url: new URL('/sentiment-feedback-ui/#/', location).toString(),
      assignedRoute: '/quality/admin/sentiment-feedback',
      defaultTitle: 'Sentiment Feedback',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.quality.qualityAdmin',
          href: '#/engage/quality'
        }],
        //TODO: this should migrate to a different source once PCBA is in iframe-coordinator
        menu: 'engage:quality'
      }
    },
    topicMinerUI: {
      url: new URL('/intent-miner/#/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/intent-miner/".concat(version, "/#/");
      },
      assignedRoute: '/quality/admin/topic-miner',
      defaultTitle: 'Topic Miner',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.quality.qualityAdmin',
          href: '#/engage/quality'
        }],
        //TODO: this should migrate to a different source once PCBA is in iframe-coordinator
        menu: 'engage:quality'
      }
    },
    analyticsUi: {
      url: new URL('/analytics-ui/#/', location).toString(),
      assignedRoute: '/analytics',
      defaultTitle: 'Analytics UI',
      queryParams: ['evaluationId', 'surveyId', 'giaTranscriptVersion', 'assignmentPanel']
    },
    analyticsAdminUi: {
      url: new URL('/analytics-ui/#/admin', location).toString(),
      assignedRoute: '/admin/contact-center/analytics',
      defaultTitle: 'Analytics UI Admin',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.contactCenter.contactCenter'
        }, {
          textKey: 'frameNav.contactCenter.analytics'
        }],
        menu: 'admin:CONTACT_CENTER'
      }
    },
    adminV3TelephonyDids: {
      url: new URL('/admin-v3/#/telephony/did', location).toString(),
      assignedRoute: '/admin/telephony/did',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.telephony.telephony',
          href: '#/admin'
        }, {
          textKey: 'frameNav.telephony.did',
          href: '#/admin/telephony/did'
        }],
        menu: 'admin:TELEPHONY'
      },
      featureToggle: {
        key: 'adminUI.v3.IFC.migration',
        redirect: 'admin',
        makeRedirectArgs: makeAdminV3RedirectArgs
      }
    },
    adminV3Groups: {
      url: new URL('/admin-v3/#/groups', location).toString(),
      assignedRoute: '/admin/groups',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.directory.directory',
          href: '#/admin'
        }, {
          textKey: 'frameNav.directory.groups',
          href: '#/admin/groups'
        }],
        menu: 'admin:DIRECTORY'
      },
      featureToggle: {
        key: 'adminUI.v3.IFC.migration AND (NOT ACT.groups.migrate)',
        redirect: 'admin',
        makeRedirectArgs: makeAdminV3RedirectArgs
      }
    },
    externalSourcesAdminUI: {
      url: new URL('/external-sources-admin-ui/#/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/external-sources-admin-ui/".concat(version, "/#/");
      },
      assignedRoute: '/admin/external-sources',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.directory.directory',
          href: '#/admin'
        }, {
          textKey: 'frameNav.directory.externalSources',
          href: '#/admin/external-sources'
        }],
        menu: 'admin:DIRECTORY'
      }
    },
    directoryAdminGroups: {
      url: new URL('/directory-admin-ui/#/groups', location).toString(),
      assignedRoute: '/admin/groups',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.directory.directory',
          href: '#/admin'
        }, {
          textKey: 'frameNav.directory.groups',
          href: '#/admin/groups'
        }],
        menu: 'admin:DIRECTORY'
      },
      featureToggle: {
        key: 'ACT.groups.migrate',
        redirect: 'admin',
        makeRedirectArgs: makeAdminV3RedirectArgs
      }
    },
    adminV3WorkTeams: {
      url: new URL('/admin-v3/#/teams', location).toString(),
      assignedRoute: '/admin/teams',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.directory.directory',
          href: '#/admin'
        }, {
          textKey: 'frameNav.directory.workTeams',
          href: '#/admin/teams'
        }],
        menu: 'admin:DIRECTORY'
      },
      featureToggle: {
        key: 'adminUI.v3.IFC.migration',
        redirect: 'admin',
        makeRedirectArgs: makeAdminV3RedirectArgs
      }
    },
    messengerAdminConfigurations: {
      url: new URL('/messenger-admin/#/messenger/configurations', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/messenger-admin/".concat(version, "/#/messenger/configurations");
      },
      assignedRoute: '/admin/messenger/configurations',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.messagingAdmin.message',
          href: '#/admin'
        }, {
          textKey: 'frameNav.messagingAdmin.configurations',
          href: '#/admin/messenger/configurations'
        }],
        menu: 'admin:MESSAGE'
      },
      queryParams: ['duplicateId'],
      featureToggle: {
        key: 'NOT webmessaging.4194.messengerAdminRedesign'
      }
    },
    messengerAdminDeployments: {
      url: new URL('/messenger-admin/#/messenger/deployments', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/messenger-admin/".concat(version, "/#/messenger/deployments");
      },
      assignedRoute: '/admin/messenger/deployments',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.messagingAdmin.message',
          href: '#/admin'
        }, {
          textKey: 'frameNav.messagingAdmin.deployments',
          href: '#/admin/messenger/deployments'
        }],
        menu: 'admin:MESSAGE'
      },
      featureToggle: {
        key: 'NOT webmessaging.4194.messengerAdminRedesign'
      }
    },
    messengerAdminConfigurationsV2: {
      url: new URL('/messenger-admin-v2/#/messenger/configurations', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/messenger-admin-v2/".concat(version, "/#/messenger/configurations");
      },
      assignedRoute: '/admin/messenger/configurations',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.messagingAdmin.message',
          href: '#/admin'
        }, {
          textKey: 'frameNav.messagingAdmin.configurations',
          href: '#/admin/messenger/configurations'
        }],
        menu: 'admin:MESSAGE'
      },
      queryParams: ['duplicateId'],
      featureToggle: {
        key: 'webmessaging.4194.messengerAdminRedesign'
      }
    },
    messengerAdminDeploymentsV2: {
      url: new URL('/messenger-admin-v2/#/messenger/deployments', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/messenger-admin-v2/".concat(version, "/#/messenger/deployments");
      },
      assignedRoute: '/admin/messenger/deployments',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.messagingAdmin.message',
          href: '#/admin'
        }, {
          textKey: 'frameNav.messagingAdmin.deployments',
          href: '#/admin/messenger/deployments'
        }],
        menu: 'admin:MESSAGE'
      },
      featureToggle: {
        key: 'webmessaging.4194.messengerAdminRedesign'
      }
    },
    learningModules: {
      url: new URL('/wem-learning-ui/#/performance/modules', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL("/wem-learning-ui/".concat(version, "/#/performance/modules"), location).toString();
      },
      assignedRoute: '/admin/performance/modules',
      defaultTitle: 'Modules UI',
      featureToggle: {
        key: 'performanceV2UI'
      },
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.performanceEngagement.performanceAdmin',
          href: '#/admin',
          active: true
        }],
        menu: 'admin:performance/modules'
      }
    },
    agentHome: {
      url: new URL('/agent-home/#/', location).toString(),
      assignedRoute: '/activity',
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL("/agent-home/".concat(version, "/#/"), location).toString();
      },
      defaultTitle: 'Agent Home'
    },
    performanceNotification: {
      url: new URL('/notification-rules/#/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/notification-rules/".concat(version, "/#/");
      },
      assignedRoute: '/admin/performance/notification',
      defaultTitle: 'Notification Rules',
      featureToggle: {
        key: 'switchboardUI.notificationRules',
        redirect: 'admin',
        makeRedirectArgs: makeAdminRedirectArgs
      },
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.performanceEngagement.performanceAdmin',
          href: '#/admin',
          active: true
        }],
        menu: 'admin:performance/modules'
      }
    },
    gamificationProfilesUi: {
      url: new URL('/gamification-profiles-ui/#/', location).toString(),
      assignedRoute: '/admin/performance/gamification-profiles',
      defaultTitle: 'Gamification Profiles UI',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.performanceEngagement.performanceAdmin',
          href: '#/admin',
          active: true
        }],
        menu: 'admin:performance/modules'
      }
    },
    gamificationSettingsUi: {
      url: new URL('/gamification-settings-ui/#/', location).toString(),
      assignedRoute: '/admin/performance/gamification-settings',
      defaultTitle: 'Gamification Settings UI',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.performanceEngagement.performanceAdmin',
          href: '#/admin',
          active: true
        }],
        menu: 'admin:performance/modules'
      }
    },
    wfmSupervisor: {
      url: new URL('/wfm-supervisor/#/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/wfm-supervisor/".concat(version, "/#/");
      },
      assignedRoute: '/admin/wfm',
      defaultTitle: 'Workforce Management',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.workforceManagement',
          href: '#/admin/wfm',
          id: 'wfm'
        }],
        menu: 'admin:WFM'
      }
    },
    wfmShrinkage: {
      url: new URL('/wfm-shrinkage-ui/#/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/wfm-shrinkage-ui/".concat(version, "/#/");
      },
      assignedRoute: '/admin/wfm/shrinkage/',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.workforceManagement',
          href: '#/admin/wfm',
          id: 'wfm'
        }],
        menu: 'admin:WFM'
      }
    },
    wfmHistoricalDataImport: {
      url: new URL('/wfm-historical-data-import-ui/#/', location).toString(),
      assignedRoute: '/admin/wfm/historicalImportV2/',
      featureToggle: {
        key: 'WEM-431_wfmHDIMultiFileUpload',
        redirect: '/admin/wfm/historicalImport'
      },
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.workforceManagement',
          href: '#/admin/wfm',
          id: 'wfm'
        }],
        menu: 'admin:WFM'
      }
    },
    wfmActivityPlanner: {
      url: new URL('/wfm-activity-planner-ui/#/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/wfm-activity-planner-ui/".concat(version, "/#/");
      },
      assignedRoute: '/admin/wfm/activityplans/',
      featureToggle: {
        key: 'wfmActivityPlannerUI',
        redirect: 'admin',
        makeRedirectArgs: makeAdminRedirectArgs
      },
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.workforceManagement',
          href: '#/admin/wfm',
          id: 'wfm'
        }],
        menu: 'admin:WFM'
      }
    },
    wfmMainForecast: {
      url: new URL('/wfm-main-forecast-ui/#/', location).toString(),
      assignedRoute: '/admin/wfm/main-forecast/',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.workforceManagement',
          href: '#/admin/wfm',
          id: 'wfm'
        }],
        menu: 'admin:WFM'
      }
    },
    wfmHistoricalAdherence: {
      url: new URL('/wfm-historical-adherence/#/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/wfm-historical-adherence/".concat(version, "/#/");
      },
      assignedRoute: '/admin/wfm/adherence/historicalV2',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.workforceManagement',
          href: '#/admin/wfm',
          id: 'wfm'
        }],
        menu: 'admin:WFM'
      }
    },
    wfmCapacityPlanning: {
      url: new URL('/wfm-capacity-planning-ui/#/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL("/wfm-capacity-planning-ui/".concat(version, "/#/"), location).toString();
      },
      assignedRoute: '/admin/wfm/capacity-planning',
      featureToggle: {
        key: 'WEM-1066_LongTermStaffingRequirements OR WEM-1161_CapacityPlanning'
      },
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.workforceManagement',
          href: '#/admin/wfm',
          id: 'wfm'
        }],
        menu: 'admin:WFM'
      }
    },
    wfmWorkplans: {
      url: new URL('/wfm-workplans-ui/#/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL("/wfm-workplans-ui/".concat(version, "/#/"), location).toString();
      },
      assignedRoute: '/admin/wfm/workPlanV2',
      featureToggle: {
        key: 'wfmUI.workPlanBidsAdmin'
      },
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.workforceManagement',
          href: '#/admin/wfm',
          id: 'wfm'
        }],
        menu: 'admin:WFM'
      }
    },
    wfmStaffingGroup: {
      url: new URL('/wfm-staffing-groups/#/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/wfm-staffing-groups/".concat(version, "/#/");
      },
      assignedRoute: '/admin/wfm/staffingGroups',
      featureToggle: {
        key: 'wfm.staffingGroups',
        redirect: '/admin/wfm'
      },
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.workforceManagement',
          href: '#/admin/wfm',
          id: 'wfm'
        }],
        menu: 'admin:WFM'
      }
    },
    wfmTimeOff: {
      url: new URL('/wfm-timeoff-ui/#/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/wfm-timeoff-ui/".concat(version, "/#/");
      },
      assignedRoute: '/admin/wfm/time-off',
      featureToggle: {
        key: 'wfm.timeOffAutoPublish',
        redirect: '/admin/wfm'
      },
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.workforceManagement',
          href: '#/admin/wfm',
          id: 'wfm'
        }],
        menu: 'admin:WFM'
      }
    },
    outbound: {
      url: new URL('/outbound/#/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/outbound/".concat(version, "/#/");
      },
      assignedRoute: '/admin/outbound',
      defaultTitle: 'Outbound',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.outbound',
          href: '#/admin/outbound'
        }],
        menu: 'admin:OUTBOUND'
      }
    },
    performanceExternalMetricDefinitions: {
      url: new URL('/performance-external-metric-definitions-ui/#/', location).toString(),
      assignedRoute: '/admin/performance/external-metric-definitions',
      defaultTitle: 'External Metric Definitions UI',
      featureToggle: {
        key: 'performanceExternalMetrics'
      },
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.performanceEngagement.performanceAdmin',
          href: '#/admin',
          active: true
        }],
        menu: 'admin:performance/modules'
      }
    },
    // Handles old route and redirection to new one
    // With the flag on this should only be hit and used for direct bookmarks to scripts and templates
    // All links inside web-directory and admin-ui should be with the engage in the url
    scripterTemplatesUIOld: {
      url: new URL('/scripter/#/scriptTemplates', location).toString(),
      assignedRoute: '/engage/admin/scriptTemplates',
      defaultTitle: 'Scripter Integration',
      featureToggle: {
        key: 'scripter.ifc.integration',
        redirect: 'engage.embedded',
        makeRedirectArgs: makeEngageRedirectArgs
      },
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.scripter.scriptTemplates',
          href: '#/admin/scriptTemplates',
          active: true
        }],
        menu: 'admin:directory/acdSkills'
      }
    },
    // Handles old route and redirection to new one
    // With the flag on this should only be hit and used for direct bookmarks to scripts and templates
    // All links inside web-directory and admin-ui should be with the engage in the url
    scripterUIOld: {
      url: new URL('/scripter/#/scripts', location).toString(),
      assignedRoute: '/engage/admin/scripts',
      defaultTitle: 'Scripter UI Old',
      featureToggle: {
        key: 'scripter.ifc.integration',
        redirect: 'engage.embedded',
        makeRedirectArgs: makeEngageRedirectArgs
      },
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.scripter.scripts',
          href: '#/admin/scripts',
          active: true
        }],
        menu: 'admin:directory/acdSkills'
      }
    },
    scripterTemplatesUI: {
      url: new URL('/scripter/#/scriptTemplates', location).toString(),
      assignedRoute: '/admin/scriptTemplates',
      defaultTitle: 'Scripter Templates UI',
      featureToggle: {
        key: 'scripter.ifc.integration'
      },
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.scripter.scriptTemplates',
          href: '#/admin/scriptTemplates',
          active: true
        }],
        menu: 'admin:directory/acdSkills'
      }
    },
    scripterUI: {
      url: new URL('/scripter/#/scripts', location).toString(),
      assignedRoute: '/admin/scripts',
      defaultTitle: 'Scripter UI',
      featureToggle: {
        key: 'scripter.ifc.integration'
      },
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.scripter.scripts',
          href: '#/admin/scripts',
          active: true
        }],
        menu: 'admin:directory/acdSkills'
      }
    },
    messagingPlatformsUI: {
      url: new URL('/messaging-admin-ui/#/messaging-platforms', location).toString(),
      assignedRoute: '/admin/messaging/platforms',
      defaultTitle: 'Messaging Platforms UI',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.messagingAdmin.message',
          href: '#/admin'
        }],
        menu: 'admin:MESSAGE'
      }
    },
    platformConfigs: {
      url: new URL('/messaging-admin-ui/#/platform-configs', location).toString(),
      assignedRoute: '/admin/messaging/platform-configs',
      defaultTitle: 'Platform Configs',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.messagingAdmin.message',
          href: '#/admin'
        }, {
          textKey: 'frameNav.messagingAdmin.platformConfigs',
          href: '#/admin/messaging/platforms-configs',
          active: true
        }],
        menu: 'admin:MESSAGE'
      }
    },
    supportedContentProfilesUi: {
      url: new URL('/messaging-admin-ui/#/supported-content-profiles', location).toString(),
      assignedRoute: '/admin/messaging/supported-content-profiles',
      defaultTitle: 'Supported Content Profiles UI',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.messagingAdmin.message',
          href: '#/admin'
        }],
        menu: 'admin:MESSAGE'
      }
    },
    threadingTimelineUi: {
      url: new URL('/messaging-admin-ui/#/threading-timeline', location).toString(),
      assignedRoute: '/admin/messaging/threading-timeline',
      defaultTitle: 'Threading Timeline UI',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.messagingAdmin.message',
          href: '#/admin'
        }],
        menu: 'admin:MESSAGE'
      }
    },
    smsNumberInventory: {
      url: new URL('/messaging-admin-ui/#/sms-numbers', location).toString(),
      assignedRoute: '/admin/messaging/sms-numbers',
      defaultTitle: 'SMS Number Inventory',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.messagingAdmin.message',
          href: '#/admin'
        }],
        menu: 'admin:MESSAGE'
      }
    },
    voiceNumberManagement: {
      url: new URL('/gcv-apps/#/number-management', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/gcv-apps/".concat(version, "/#/number-management");
      },
      assignedRoute: '/admin/telecom/numbers',
      featureToggle: {
        key: 'gcv.newNumberManagement.view',
        redirect: 'admin',
        makeRedirectArgs: makeAdminRedirectArgs
      },
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.genesysCloudVoice.genesysCloudVoice',
          href: '#/admin'
        }, {
          textKey: 'frameNav.genesysCloudVoice.numberManagement',
          href: '#/admin/telecom/numbers',
          active: true
        }],
        menu: 'admin:VOICE_CARRIER_SERVICES'
      }
    },
    integrations: {
      url: new URL('/integrations-admin-ui/#/integrations/apps', location).toString(),
      assignedRoute: '/admin/integrations/apps',
      featureToggle: {
        key: 'integrations.decomposition.REG-1827',
        redirect: 'admin',
        makeRedirectArgs: makeAdminRedirectArgs
      },
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.integrations'
        }],
        menu: 'admin:INTEGRATIONS'
      }
    },
    messageRouting: {
      url: new URL('/messaging-admin-ui/#/message-routing', location).toString(),
      assignedRoute: '/admin/routing/sms',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.routing.routing'
        }],
        menu: 'admin:ROUTING'
      }
    },
    // Handle the double existing admin route. Also will handle when it goes to single admin route
    oldAdminEmailDomains: {
      url: new URL('/messaging-admin-ui/#/email-domains', location).toString(),
      assignedRoute: '/admin/admin/organization/email/domains',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.contactCenter.contactCenter',
          href: '#/admin'
        }],
        menu: 'admin:CONTACT_CENTER'
      }
    },
    adminEmailDomains: {
      url: new URL('/messaging-admin-ui/#/email-domains', location).toString(),
      assignedRoute: '/admin/organization/email/domains',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.contactCenter.contactCenter',
          href: '#/admin'
        }],
        menu: 'admin:CONTACT_CENTER'
      }
    },
    responseManagementUI: {
      url: new URL('/responseManagement/#/', location).toString(),
      assignedRoute: '/admin/responseManagement',
      defaultTitle: 'Response Management UI',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.responseManagement.contactCenter',
          href: '#/admin'
        }, {
          textKey: 'frameNav.responseManagement.responses',
          href: '#/admin/responseManagement/responses'
        }, {
          textKey: 'frameNav.responseManagement.libraries',
          href: '#/admin/responseManagement/libraries'
        }],
        menu: 'admin:directory/acdSkills'
      }
    },
    auditViewerUI: {
      url: new URL('/audit-viewer-ui/#/full-page-audit-viewer', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/audit-viewer-ui/".concat(version, "/#/full-page-audit-viewer");
      },
      assignedRoute: '/admin/troubleshooting/auditviewer',
      defaultTitle: 'Audit Viewer UI',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.troubleshooting',
          active: true
        }],
        menu: 'admin:troubleshooting/auditviewer'
      }
    },
    platformUsageUI: {
      url: new URL('/usage-platform-ui/#/platformusage', location).toString(),
      assignedRoute: '/admin/platformusage',
      defaultTitle: 'Platform Usage UI'
    },
    screenRecordingPlayback: {
      url: new URL('/screen-recording-ui/#/interactions', location).toString(),
      assignedRoute: '/quality/screenrecordings/interactions',
      defaultTitle: 'Screen Recording Playback'
    },
    authOrgsUI: {
      url: new URL('/auth-orgs-ui/#/auth-orgs', location).toString(),
      assignedRoute: '/admin/auth-orgs',
      defaultTitle: 'Auth Orgs UI',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.peoplePermissions',
          active: true
        }],
        menu: 'admin:directory/people'
      }
    },
    partnersUI: {
      url: new URL('/auth-orgs-ui/#/partners', location).toString(),
      assignedRoute: '/partners/ui',
      nav: {
        breadcrumbs: []
      }
    },
    agentUIPanelSettings: {
      url: new URL('/agent-ui-settings-v2/#/', location).toString(),
      assignedRoute: '/admin/contact-center/panel-settings',
      defaultTitle: 'Agent UI Panel Settings',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.contactCenter.contactCenter',
          href: '#/admin'
        }, {
          textKey: 'frameNav.contactCenter.panelManager',
          active: true
        }],
        menu: 'admin:contact-center/panel-settings'
      },
      featureToggle: {
        key: 'agentUIDefaultPanel3129'
      }
    },
    agentUIQueueList: {
      url: new URL('/agent-ui-settings-v2/#/queue-list', location).toString(),
      assignedRoute: '/admin/admin/organization/_queuesV2',
      defaultTitle: 'Agent UI Queues',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.contactCenter.contactCenter',
          href: '#/admin'
        }, {
          textKey: 'frameNav.contactCenter.queues',
          href: '#/admin/admin/organization/_queuesV2'
        }],
        menu: 'admin:contact-center/utilization'
      }
    },
    agentUIQueueDetailsV2: {
      url: new URL('/agent-ui-settings-v2/#/queue', location).toString(),
      assignedRoute: '/admin/organization/queues',
      defaultTitle: 'Agent UI Queue Details V2',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.responseManagement.contactCenter',
          href: '#/admin'
        }, {
          textKey: 'frameNav.contactCenter.queues',
          href: '#/admin/admin/organization/_queuesV2'
        }],
        menu: 'admin:admin/organization/_queuesV2'
      }
    },
    predictiveRoutingUiCustomKpis: {
      url: new URL('/predictive-routing-ui/#/predictive-routing/custom-kpis', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/predictive-routing-ui/".concat(version, "/#/predictive-routing/custom-kpis");
      },
      assignedRoute: '/admin/predictive-routing/custom-kpis',
      defaultTitle: 'Custom KPIs',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.predictiveRouting.predictiveRouting',
          href: '#/admin'
        }, {
          textKey: 'frameNav.predictiveRouting.customKpis',
          href: '#/admin/predictive-routing/custom-kpis'
        }],
        menu: 'admin:PREDICTIVE_ROUTING'
      },
      featureToggle: {
        key: 'gpr.AP-8265.showCustomKpiLink',
        redirect: 'admin',
        makeRedirectArgs: makeAdminRedirectArgs
      }
    },
    agentUIUtilization: {
      url: new URL('/agent-ui-settings/#/utilization', location).toString(),
      assignedRoute: '/admin/contact-center/utilization',
      defaultTitle: 'Agent UI Utilization',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.responseManagement.contactCenter',
          href: '#/admin'
        }, {
          textKey: 'frameNav.contactCenter.utilization',
          active: true
        }],
        menu: 'admin:contact-center/utilization'
      },
      featureToggle: {
        key: 'agentUIThirdPartyUtilization AND (NOT agentUIV2MigrateUtilization)'
      }
    },
    agentUIUtilizationV2: {
      url: new URL('/agent-ui-settings-v2/#/utilization', location).toString(),
      assignedRoute: '/admin/contact-center/utilization',
      defaultTitle: 'Agent UI Utilization V2',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.responseManagement.contactCenter',
          href: '#/admin'
        }, {
          textKey: 'frameNav.contactCenter.utilization',
          active: true
        }],
        menu: 'admin:contact-center/utilization'
      },
      featureToggle: {
        key: 'agentUIThirdPartyUtilization AND agentUIV2MigrateUtilization'
      }
    },
    agentUIWrapupCodesv2: {
      url: new URL('/agent-ui-settings-v2/#/wrapup-codes', location).toString(),
      assignedRoute: '/admin/admin/organization/_wrapupCodesV2',
      defaultTitle: 'Agent UI Wrapup Codes V2',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.responseManagement.contactCenter',
          href: '#/admin'
        }, {
          textKey: 'frameNav.contactCenter.wrapupCodes',
          active: true
        }],
        menu: 'admin:admin/organization/_wrapupCodesV2'
      },
      featureToggle: {
        key: 'agentUIV2MigrateWrapupCodes',
        redirect: 'admin',
        makeRedirectArgs: makeAdminRedirectArgs
      }
    },
    agentUIAcdSkillsv2: {
      url: new URL('/agent-ui-settings-v2/#/acd-skills', location).toString(),
      assignedRoute: '/admin/directory/acdSkills',
      defaultTitle: 'Agent UI ACD Skills V2',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.responseManagement.contactCenter',
          href: '#/admin'
        }, {
          textKey: 'frameNav.contactCenter.acdSkills',
          active: true
        }],
        menu: 'admin:directory/acdSkills'
      },
      featureToggle: {
        key: 'agentUIV2MigrateAcdSkills',
        redirect: 'admin',
        makeRedirectArgs: makeAdminRedirectArgs
      }
    },
    agentAssistAdminUi: {
      url: new URL('/agent-assist-admin-ui/#/', location).toString(),
      assignedRoute: '/admin/assistants',
      defaultTitle: 'Agent Assist Admin UI',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.contactCenter.contactCenter',
          href: '#/admin'
        }, {
          textKey: 'frameNav.contactCenter.assistants',
          href: '#/admin/assistants/list'
        }],
        menu: 'admin:assistants/list'
      }
    },
    agentCopilotAdminUi: {
      url: new URL('/copilot-admin-ui/#/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/copilot-admin-ui/".concat(version, "/#/");
      },
      assignedRoute: '/admin/copilots',
      defaultTitle: 'Agent Copilots Admin UI',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.contactCenter.contactCenter',
          href: '#/admin'
        }, {
          textKey: 'frameNav.contactCenter.copilots',
          href: '#/admin/copilots'
        }],
        menu: 'admin:copilots'
      }
    },
    logCaptureUI: {
      url: new URL('/troubleshooting-ui/#/log-capture', location).toString(),
      assignedRoute: '/admin/troubleshooting/logcapture',
      defaultTitle: 'Log Capture UI',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.troubleshooting',
          active: true
        }],
        menu: 'admin:troubleshooting/auditviewer'
      }
    },
    operationalConsoleUI: {
      url: new URL('/troubleshooting-ui/#/operational-console', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/troubleshooting-ui/".concat(version, "/#/operational-console");
      },
      assignedRoute: '/admin/troubleshooting/operational-console',
      defaultTitle: 'Operational Console UI',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.troubleshooting',
          active: true
        }],
        menu: 'admin:troubleshooting/auditviewer'
      },
      featureToggle: {
        key: 'supportability.PURE-5129.events_ui',
        redirect: 'admin'
      }
    },
    knowledgeWorkbenchArticleUI: {
      url: new URL('/knowledge-workbench/#/knowledgeBases/articles', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/knowledge-workbench/".concat(version, "/#/knowledgeBases/articles");
      },
      assignedRoute: '/admin/knowledge/knowledgeBases/articles',
      defaultTitle: 'Knowledge Workbench Article UI',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.knowledgeWorkbench.knowledge'
        }, {
          textKey: 'frameNav.knowledgeWorkbench.articles',
          href: '#/admin/knowledge/knowledgeBases/articles'
        }],
        menu: 'admin:knowledge/knowledgeBases/articles'
      },
      featureToggle: {
        key: 'DX.AI-509.knowledge-authoring',
        redirect: 'admin'
      }
    },
    knowledgeWorkbenchCategoryUI: {
      url: new URL('/knowledge-workbench/#/knowledgeBases/categories', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/knowledge-workbench/".concat(version, "/#/knowledgeBases/categories");
      },
      assignedRoute: '/admin/knowledge/knowledgeBases/categories',
      defaultTitle: 'Knowledge Workbench Category UI',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.knowledgeWorkbench.knowledge'
        }, {
          textKey: 'frameNav.knowledgeWorkbench.categories'
        }],
        menu: 'admin:knowledge/knowledgeBases/categories'
      },
      featureToggle: {
        key: 'DX.AI-509.knowledge-authoring',
        redirect: 'admin'
      }
    },
    knowledgeWorkbenchImportExportUI: {
      url: new URL('/knowledge-workbench/#/knowledgeBases/import-export', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/knowledge-workbench/".concat(version, "/#/knowledgeBases/import-export");
      },
      assignedRoute: '/admin/knowledge/knowledgeBases/import-export',
      defaultTitle: 'Knowledge Workbench Import Export UI',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.knowledgeWorkbench.knowledge'
        }, {
          textKey: 'frameNav.knowledgeWorkbench.importExport'
        }],
        menu: 'admin:knowledge/knowledgeBases/import-export'
      },
      featureToggle: {
        key: 'DX.AI-509.knowledge-authoring AND (NOT AI-962.in-built-connector)',
        redirect: 'admin'
      }
    },
    knowledgeWorkbenchSourcesUI: {
      url: new URL('/knowledge-workbench/#/knowledgeBases/sources', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/knowledge-workbench/".concat(version, "/#/knowledgeBases/sources");
      },
      assignedRoute: '/admin/knowledge/knowledgeBases/sources',
      defaultTitle: 'Knowledge Workbench Sources UI',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.knowledgeWorkbench.knowledge'
        }, {
          textKey: 'frameNav.knowledgeWorkbench.sources',
          href: '#/admin/knowledge/knowledgeBases/sources'
        }],
        menu: 'admin:knowledge/knowledgeBases/sources'
      },
      featureToggle: {
        key: 'DX.AI-509.knowledge-authoring AND AI-962.in-built-connector',
        redirect: 'admin'
      }
    },
    knowledgeOptimizerUI: {
      url: new URL('/knowledge-optimizer/#/knowledge/optimizer/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/knowledge-optimizer/".concat(version, "/#/knowledge/optimizer/");
      },
      assignedRoute: '/admin/knowledge/optimizer',
      defaultTitle: 'Knowledge Optimizer UI',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.knowledgeOptimizer.knowledge'
        }, {
          textKey: 'frameNav.knowledgeOptimizer.optimizer'
        }],
        menu: 'admin:knowledge/optimizer'
      },
      featureToggle: {
        key: 'DX.AI-496.knowledge-optimizer',
        redirect: 'admin'
      }
    },
    architectComponentsUIOutcome: {
      url: new URL('/architect-components/#/flowoutcomes/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/architect-components/".concat(version, "/#/flowoutcomes/");
      },
      assignedRoute: '/admin/routing/flowoutcomes',
      defaultTitle: 'Flow Outcome',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.architect.architect',
          href: '#/admin'
        }, {
          textKey: 'frameNav.architect.flowOutcomes',
          href: '#/admin/routing/flowoutcomes'
        }],
        menu: 'admin:routing/flowoutcomes'
      }
    },
    architectComponentsUIMilestone: {
      url: new URL('/architect-components/#/flowmilestones/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/architect-components/".concat(version, "/#/flowmilestones/");
      },
      assignedRoute: '/admin/routing/flowmilestones',
      defaultTitle: 'Flow Milestone',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.architect.architect',
          href: '#/admin'
        }, {
          textKey: 'frameNav.architect.flowMilestones',
          href: '#/admin/routing/flowmilestones'
        }],
        menu: 'admin:routing/flowmilestones'
      }
    },
    architectComponentsUIDataTable: {
      url: new URL('/architect-components/#/datatables/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/architect-components/".concat(version, "/#/datatables/");
      },
      assignedRoute: '/admin/routing/datatables',
      defaultTitle: 'Data Table',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.architect.architect',
          href: '#/admin'
        }, {
          textKey: 'frameNav.architect.dataTables',
          href: '#/admin/routing/datatables'
        }],
        menu: 'admin:routing/datatables'
      },
      featureToggle: {
        key: 'archAdminUiDataTables',
        redirect: 'admin',
        makeRedirectArgs: makeAdminRedirectArgs
      }
    },
    triggersUI: {
      url: new URL('/triggers-ui/#/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL("/triggers-ui/".concat(version, "/#/"), location).toString();
      },
      assignedRoute: '/admin/triggers',
      defaultTitle: 'Triggers',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.architect.architect',
          href: '#/admin'
        }, {
          textKey: 'frameNav.architect.triggers',
          href: '#/admin/triggers'
        }],
        menu: 'admin:triggers'
      }
    },
    billingUI: {
      url: new URL('/billing-v2/#/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/billing-v2/".concat(version, "/#/");
      },
      assignedRoute: '/admin/billing'
    },
    contactsAndOrganizationsUI: {
      url: new URL('/contacts-and-organizations-ui/#/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/contacts-and-organizations-ui/".concat(version, "/#/");
      },
      assignedRoute: '/contactsAndOrganizations'
    },
    iamAdminPeoplePermissions: {
      url: new URL('/iam-admin/#/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/iam-admin/".concat(version, "/#/");
      },
      assignedRoute: '/admin/people-permissions',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.peoplePermissions'
        }],
        menu: 'admin:PEOPLE_PERMISSIONS'
      }
    },
    iamAdminIdentity: {
      url: new URL('/iam-admin/#/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/iam-admin/".concat(version, "/#/");
      },
      assignedRoute: '/admin/identity',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.integrations'
        }],
        menu: 'admin:INTEGRATIONS'
      }
    },
    iamAdminAccessManagement: {
      url: new URL('/iam-admin/#/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/iam-admin/".concat(version, "/#/");
      },
      assignedRoute: '/admin/access-management',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.integrations'
        }],
        menu: 'admin:INTEGRATIONS'
      }
    },
    telephonyAdminOperatingSchedules: {
      url: new URL("".concat(telephonyAdminUri, "/routing/scheduling"), location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL(getTelephonyAdminTimeMachineUri(version, '/routing/scheduling'), location).toString();
      },
      assignedRoute: '/admin/routing/scheduling',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.routing.routing'
        }, {
          textKey: 'frameNav.routing.operatingSchedules'
        }],
        menu: 'admin:ROUTING'
      }
    },
    telephonyAdminCallRouting: {
      url: new URL("".concat(telephonyAdminUri, "/routing/ivrs"), location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL(getTelephonyAdminTimeMachineUri(version, '/routing/ivrs'), location).toString();
      },
      assignedRoute: '/admin/routing/ivrs',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.routing.routing'
        }, {
          textKey: 'frameNav.routing.ivrs'
        }],
        menu: 'admin:ROUTING'
      }
    },
    telephonyAdminEmergencyGroups: {
      url: new URL("".concat(telephonyAdminUri, "/routing/emergency-groups"), location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL(getTelephonyAdminTimeMachineUri(version, '/routing/emergency-groups'), location).toString();
      },
      assignedRoute: '/admin/routing/emergency-groups',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.routing.routing'
        }, {
          textKey: 'frameNav.routing.emergencyGroups'
        }],
        menu: 'admin:ROUTING'
      }
    },
    telephonyAdminTopology: {
      url: new URL("".concat(telephonyAdminKoUri, "telephonyAdmin/topology"), location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL(getTelephonyAdminTimeMachineUri(version, 'telephonyAdmin/topology', true), location).toString();
      },
      assignedRoute: '/admin/telephony/topology',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.telephony.telephony'
        }, {
          textKey: 'frameNav.telephony.topology'
        }],
        menu: 'admin:TELEPHONY'
      },
      featureToggle: {
        key: 'telephonyAdminUIKoIFC'
      }
    },
    telephonyAdminMetrics: {
      url: new URL("".concat(telephonyAdminKoUri, "telephonyAdmin/metrics"), location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL(getTelephonyAdminTimeMachineUri(version, 'telephonyAdmin/metrics', true), location).toString();
      },
      assignedRoute: '/admin/telephony/metrics',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.telephony.telephony'
        }, {
          textKey: 'frameNav.telephony.metrics'
        }],
        menu: 'admin:TELEPHONY'
      },
      featureToggle: {
        key: 'telephonyAdminUIKoIFC'
      }
    },
    telephonyAdminTrunks: {
      url: new URL("".concat(telephonyAdminKoUri, "telephonyAdmin/trunks"), location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL(getTelephonyAdminTimeMachineUri(version, 'telephonyAdmin/trunks', true), location).toString();
      },
      assignedRoute: '/admin/telephony/trunks',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.telephony.telephony'
        }, {
          textKey: 'frameNav.telephony.trunks'
        }],
        menu: 'admin:TELEPHONY'
      },
      featureToggle: {
        key: 'telephonyAdminUIKoIFC'
      }
    },
    telephonyAdminSites: {
      url: new URL("".concat(telephonyAdminKoUri, "telephonyAdmin/sites"), location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL(getTelephonyAdminTimeMachineUri(version, 'telephonyAdmin/sites', true), location).toString();
      },
      assignedRoute: '/admin/telephony/sites',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.telephony.telephony'
        }, {
          textKey: 'frameNav.telephony.sites'
        }],
        menu: 'admin:TELEPHONY'
      },
      featureToggle: {
        key: 'telephonyAdminUIKoIFC'
      }
    },
    telephonyAdminEdgeGroups: {
      url: new URL("".concat(telephonyAdminKoUri, "telephonyAdmin/edgeGroups"), location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL(getTelephonyAdminTimeMachineUri(version, 'telephonyAdmin/edgeGroups', true), location).toString();
      },
      assignedRoute: '/admin/telephony/edge-groups',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.telephony.telephony'
        }, {
          textKey: 'frameNav.telephony.edgeGroups'
        }],
        menu: 'admin:TELEPHONY'
      },
      featureToggle: {
        key: 'telephonyAdminUIKoIFC'
      }
    },
    telephonyAdminEdges: {
      url: new URL("".concat(telephonyAdminKoUri, "telephonyAdmin/edges"), location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL(getTelephonyAdminTimeMachineUri(version, 'telephonyAdmin/edges', true), location).toString();
      },
      assignedRoute: '/admin/telephony/edges',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.telephony.telephony'
        }, {
          textKey: 'frameNav.telephony.edges'
        }],
        menu: 'admin:TELEPHONY'
      },
      featureToggle: {
        key: 'telephonyAdminUIKoIFC'
      }
    },
    telephonyAdminPhoneManagement: {
      url: new URL("".concat(telephonyAdminKoUri, "telephonyAdmin/phoneManagement"), location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL(getTelephonyAdminTimeMachineUri(version, 'telephonyAdmin/phoneManagement', true), location).toString();
      },
      assignedRoute: '/admin/telephony/phone-management',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.telephony.telephony'
        }, {
          textKey: 'frameNav.telephony.phoneManagement'
        }],
        menu: 'admin:TELEPHONY'
      },
      featureToggle: {
        key: 'telephonyAdminUIKoIFC'
      }
    },
    telephonyAdminCertificateAuthorities: {
      url: new URL("".concat(telephonyAdminKoUri, "telephonyAdmin/authorizedServices"), location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL(getTelephonyAdminTimeMachineUri(version, 'telephonyAdmin/authorizedServices', true), location).toString();
      },
      assignedRoute: '/admin/telephony/certificate-authorities',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.telephony.telephony'
        }, {
          textKey: 'frameNav.telephony.certificateAuthorities'
        }],
        menu: 'admin:TELEPHONY'
      },
      featureToggle: {
        key: 'telephonyAdminUIKoIFC'
      }
    },
    telephonyAdminExtensions: {
      url: new URL("".concat(telephonyAdminUri, "/telephony/extensions"), location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL(getTelephonyAdminTimeMachineUri(version, '/telephony/extensions'), location).toString();
      },
      assignedRoute: '/admin/telephony/extensions',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.telephony.telephony'
        }, {
          textKey: 'frameNav.telephony.extensions'
        }],
        menu: 'admin:TELEPHONY'
      }
    },
    telephonyAdminSettings: {
      url: new URL("".concat(telephonyAdminUri, "/telephony/settings"), location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return new URL(getTelephonyAdminTimeMachineUri(version, '/telephony/settings'), location).toString();
      },
      assignedRoute: '/admin/telephony/settings',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.telephony.telephony'
        }, {
          textKey: 'frameNav.telephony.settings'
        }],
        menu: 'admin:TELEPHONY'
      }
    },
    nerCustomEntitesUI: {
      url: new URL('/ner-custom-entities-ui/#/', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/ner-custom-entities-ui/".concat(version, "/#/");
      },
      assignedRoute: '/admin/dataprivacy/maskingrules',
      defaultTitle: 'Digital Data Filtering Rules',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.accountSettings.accountSettings'
        }, {
          textKey: 'frameNav.accountSettings.nerCustomUI'
        }],
        menu: 'admin:ACCOUNT_SETTINGS'
      },
      featureToggle: {
        key: 'ner-PURE-4556',
        // NER-780
        redirect: 'admin'
      }
    },
    taskManagementWorkbins: {
      url: new URL('/task-management-workbins/#/workbins', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/task-management-workbins/".concat(version, "/#/workbins");
      },
      assignedRoute: '/admin/workbins',
      defaultTitle: 'Workbins',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.taskManagement.taskManagement',
          href: '#/admin'
        }, {
          textKey: 'frameNav.taskManagement.workbins',
          href: '#/admin/workbins'
        }],
        menu: 'admin:TASK_MANAGEMENT'
      },
      featureToggle: {
        key: 'workItemRouting',
        redirect: 'admin',
        makeRedirectArgs: makeAdminRedirectArgs
      }
    },
    taskManagementWorktypes: {
      url: new URL('/task-management-worktypes/#/worktypes', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/task-management-worktypes/".concat(version, "/#/worktypes");
      },
      assignedRoute: '/admin/worktypes',
      defaultTitle: 'Worktypes',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.taskManagement.taskManagement',
          href: '#/admin'
        }, {
          textKey: 'frameNav.taskManagement.worktypes',
          href: '#/admin/worktypes'
        }],
        menu: 'admin:TASK_MANAGEMENT'
      },
      featureToggle: {
        key: 'workItemRouting',
        redirect: 'admin',
        makeRedirectArgs: makeAdminRedirectArgs
      }
    },
    taskManagementSchemaBuilder: {
      url: new URL('/task-management-schema-builder/#/custom-attributes', location).toString(),
      timeMachineUrl: function timeMachineUrl(version) {
        return "/task-management-schema-builder/".concat(version, "/#/custom-attributes");
      },
      assignedRoute: '/admin/custom-attributes',
      defaultTitle: 'Custom Attributes',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.taskManagement.taskManagement',
          href: '#/admin'
        }, {
          textKey: 'frameNav.taskManagement.schemaBuilder',
          href: '#/admin/custom-attributes'
        }],
        menu: 'admin:TASK_MANAGEMENT'
      },
      featureToggle: {
        key: 'workItemRouting',
        redirect: 'admin',
        makeRedirectArgs: makeAdminRedirectArgs
      }
    },
    disconnectInteractions: {
      url: new URL("/agent-ui-settings-v2/#/disconnect-interactions", location).toString(),
      assignedRoute: '/admin/routing/disconnectInteractions',
      nav: {
        breadcrumbs: [{
          textKey: 'frameNav.routing.routing'
        }, {
          textKey: 'frameNav.routing.disconnectInteractions'
        }],
        menu: 'admin:ROUTING'
      },
      featureToggle: {
        key: 'ACT.disconnectInteractions.migrate',
        //PURE-5424
        redirect: 'admin',
        makeRedirectArgs: makeAdminRedirectArgs
      }
    }
  };
  _exports.centerStageClients = centerStageClients;
});