define("web-directory/components/target-dropdown/types/index", ["exports", "web-directory/components/target-dropdown/types/filter-store-config.type", "web-directory/components/target-dropdown/types/filter.type", "web-directory/components/target-dropdown/types/pagination-options.type", "web-directory/components/target-dropdown/types/conversation-summary.type", "web-directory/components/target-dropdown/types/queue-details.type"], function (_exports, _filterStoreConfig, _filter, _paginationOptions, _conversationSummary, _queueDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(_filterStoreConfig).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _filterStoreConfig[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _filterStoreConfig[key];
      }
    });
  });
  Object.keys(_filter).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _filter[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _filter[key];
      }
    });
  });
  Object.keys(_paginationOptions).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _paginationOptions[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _paginationOptions[key];
      }
    });
  });
  Object.keys(_conversationSummary).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _conversationSummary[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _conversationSummary[key];
      }
    });
  });
  Object.keys(_queueDetails).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _queueDetails[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _queueDetails[key];
      }
    });
  });
});