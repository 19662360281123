define("web-directory/mixins/chat-service/message-notifications", ["exports", "ember-purecloud-components/utils/jid-helpers", "ember-purecloud-components/mixins/logger"], function (_exports, _jidHelpers, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MessageNotificationsMixin = Ember.Mixin.create(_logger.default, {
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    interaction: Ember.inject.service(),
    notification: Ember.inject.service(),
    chat: Ember.inject.service(),
    webrtc: Ember.inject.service(),
    preferences: Ember.inject.service(),
    useCollaborateChat: Ember.computed.readOnly('session.useCollaborateChat'),
    sendMentionNotification: function sendMentionNotification(room, person, message) {
      // You shouldn't get a mention notification for yourself, which happens when sending a quote from another client
      // like mobile
      if (this.get('session.person.id') === person.get('id')) {
        return;
      }

      if (this.shouldShowMentionNotification(room, message)) {
        var roomName = '';

        if (this.get('useCollaborateChat')) {
          roomName = Ember.get(message, 'roomName');
        } else {
          roomName = room.getRawDisplaySubject();
        }

        var title = person.get('name');
        var toastMessage = this.get('intl').lookup('notification.titles.chatMention', {
          roomName: roomName
        }).toString();
        this.get('notification').info(title, toastMessage, {
          icon: person.get('profilePicture.x128'),
          tag: room.get('jid'),
          hideAfter: 10,
          soundId: 'chat',
          shouldShowPopup: true,
          shouldPlaySound: !this.get('preferences.chat.global.soundsMuted'),
          target: this.get('applicationController'),
          volume: this.getNotificationVolume(),
          notificationOutputDeviceId: this.getNotificationOutputDeviceId()
        });
      }
    },
    sendNotification: function sendNotification(message) {
      if (this.shouldShowNotification(message)) {
        var showPopup = this.get('preferences.notifications.global.showGroupNotifications') || message.get('room').isOneToOneRoom();
        var playSound = showPopup && !this.get('preferences.chat.global.soundsMuted');
        var title = this.getNotificationTitle(message.get('room'), message.get('chatUser'), message);
        var messageBody, raw;

        if (message.widgetType === 'video-notification') {
          var messageKey = "chat.video.chatNotifications.".concat(message.get('widget.data.type'));
          var intl = this.get('intl');

          if (intl.exists(messageKey)) {
            messageBody = this.get('intl').lookup(messageKey);
            raw = this.get('intl').t(messageKey);
          } else {
            messageBody = message.get('messageBody');
            raw = message.get('raw');
          }
        } else {
          messageBody = message.get('messageBody');
          raw = message.get('raw');
        }

        this.get('notification').info(title, messageBody, {
          icon: message.get('chatUser.profilePicture.x128'),
          rawMessage: raw,
          tag: message.get('room.jid'),
          hideAfter: 8,
          soundId: 'chat',
          shouldShowPopup: showPopup,
          shouldPlaySound: playSound,
          target: this.get('applicationController'),
          volume: this.getNotificationVolume(),
          notificationOutputDeviceId: this.getNotificationOutputDeviceId()
        });
      }
    },
    sendAcdNotification: function sendAcdNotification(message) {
      if (this.shouldShowNotification(message)) {
        var showPopup = !this.get('interaction.isInteractionViewOpen');
        var playSound = !this.get('preferences.chat.global.soundsMuted');
        var title = message.get('room.acdCustomerParticipant.name');
        var messageBody = message.get('messageBody');
        this.get('notification').info(title, messageBody, {
          icon: message.get('chatUser.profilePicture.x128'),
          rawMessage: message.get('raw'),
          messageStyle: 'acd-toast-notification',
          tag: message.get('room.jid'),
          hideAfter: false,
          soundId: 'chat',
          shouldShowPopup: showPopup,
          shouldPlaySound: playSound,
          target: this.get('applicationController'),
          volume: this.getNotificationVolume(),
          notificationOutputDeviceId: this.getNotificationOutputDeviceId()
        });
      }
    },
    sendEngageNotification: function sendEngageNotification(message) {
      var title = message.title;
      var body = message.body;
      var options = {
        rawMessage: body,
        hideAfter: message.hideAfter,
        markdownToHtml: false,
        shouldShowPopup: true,
        shouldPlaySound: false,
        clickHandler: message.clickHandler,
        target: this.get('applicationController'),
        volume: this.getNotificationVolume(),
        notificationOutputDeviceId: this.getNotificationOutputDeviceId()
      };

      if (message.severity === 'error') {
        this.get('notification').error(title, body, options);
      } else {
        this.get('notification').info(title, body, options);
      }
    },
    shouldShowNotification: function shouldShowNotification(message) {
      var isBusyUser = this.get('session.person.presence') === 'busy' && !message.get('room').isOneToOneRoom();
      var isDuplicate = false;

      if (message.duplicate) {
        isDuplicate = message.get('duplicate');
      }

      var messageAlreadySeen = !message.get('unread');
      var fromLoggedInUser = (0, _jidHelpers.compareJids)(message.get('chatUser.jid'), this.get('session.person.jid'));
      var targetRoomIsMuted = false;
      var roomIsVisible = false;
      var windowFocused = this.windowInFocus();
      var isSupervisorRoom = false;

      if (this.get('useCollaborateChat')) {
        roomIsVisible = message.get('currentSelectedRoom.jid') === message.get('room.jid') && windowFocused;
        targetRoomIsMuted = message.get('isRoomMuted');
      } else {
        roomIsVisible = this.get('sidebarService.visibleChatRoom.jid') === message.get('room.jid') && windowFocused;
        targetRoomIsMuted = this.roomMuted(message.get('room.jid'));
        isSupervisorRoom = message.get('room.isSupervisorRoom');
      } // Negative cases that block notifications are checked first.


      if (isBusyUser || isDuplicate || messageAlreadySeen || fromLoggedInUser || roomIsVisible || targetRoomIsMuted || isSupervisorRoom) {
        return false;
      } else if (!roomIsVisible || !windowFocused) {
        //Then positive cases that indicate a notification should be shown are checked.
        return true;
      } // The final default is to not show a notification


      return false;
    },
    shouldShowMentionNotification: function shouldShowMentionNotification(room, message) {
      var targetRoomIsMuted = false;
      var roomIsVisible = false;
      var groupNotificationsEnabled = false;
      var coveredByMessageNotification = false;
      var windowFocused = this.windowInFocus();

      if (this.get('useCollaborateChat') && message) {
        targetRoomIsMuted = Ember.get(message, 'isRoomMuted');
        roomIsVisible = Ember.get(message, 'currentSelectedRoom.jid') === Ember.get(message, 'to');
      } else if (!this.get('useCollaborateChat')) {
        targetRoomIsMuted = this.roomMuted(room.get('jid'));
        roomIsVisible = this.get('sidebar.visibleChatRoom.jid') === room.get('jid') && windowFocused;
      }

      groupNotificationsEnabled = this.get('preferences.notifications.global.showGroupNotifications');
      coveredByMessageNotification = groupNotificationsEnabled && !targetRoomIsMuted; // Don't show a notification if the message is on screen already or if the notification will be covered
      // by the message notification

      if (roomIsVisible || coveredByMessageNotification) {
        return false;
      } // Unlike messages the default for a mention notification is true


      return true;
    },
    getNotificationTitle: function getNotificationTitle(room, chatUser, message) {
      if (room.isOneToOneRoom()) {
        return chatUser.get('name');
      } else {
        var personName = chatUser.get('name');
        var roomName = '';

        if (this.get('useCollaborateChat') && message) {
          roomName = message.get('roomName');
        } else {
          roomName = room.getRawDisplaySubject();
        }

        return this.get('intl').lookup('notification.titles.chatRoomMessage', {
          personName: personName,
          roomName: roomName
        }).toString();
      }
    },
    sendTestNotification: function sendTestNotification() {
      var title = this.get('intl').lookup('notification.titles.testNotification').toString();
      var message = this.get('intl').lookup('notification.titles.testText').toString();
      this.get('notification').info(title, message, {
        icon: this.get('session.person.profilePicture.x128'),
        tag: 'test-message',
        hideAfter: 8,
        soundId: 'chat',
        shouldShowPopup: true,
        shouldPlaySound: true,
        target: this.get('applicationController'),
        volume: this.getNotificationVolume(),
        notificationOutputDeviceId: this.getNotificationOutputDeviceId(),
        clickHandler: function clickHandler() {}
      });
    },
    getNotificationVolume: function getNotificationVolume() {
      var volume = this.get('chat.chatNotificationVolume');

      if (volume > 1) {
        volume = volume / 100;
      }

      return volume;
    },
    getNotificationOutputDeviceId: function getNotificationOutputDeviceId() {
      return this.get('webrtc.notificationOutputDeviceId');
    }
  });
  var _default = MessageNotificationsMixin;
  _exports.default = _default;
});