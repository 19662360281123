define("web-directory/components/entity-fields/validators/tag", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    isValid: function isValid() {
      return true;
    },
    isEmpty: function isEmpty(tags) {
      var value = tags.value;
      return value === undefined || value === null || _lodash.default.isEmpty(value);
    }
  };
  _exports.default = _default;
});