define("web-directory/models/externalcontacts/twitter-id", ["exports", "ember-data", "web-directory/models/externalcontacts/channel"], function (_exports, _emberData, _channel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TWITTERID_TYPE = void 0;
  var TWITTERID_TYPE = 'externalcontacts/twitter-id';
  _exports.TWITTERID_TYPE = TWITTERID_TYPE;

  var _default = _channel.default.extend({
    type: 'twitter',
    id: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    screenName: _emberData.default.attr('string')
  });

  _exports.default = _default;
});