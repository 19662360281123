define("web-directory/components/agent-assistance-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      observer = Ember.observer,
      inject = Ember.inject,
      RSVP = Ember.RSVP;
  var ROOM_NAME = 'supervisorassistance';
  var AgentAssistancePanelComponent = Ember.Component.extend({
    classNames: ['agent-assistance-panel', 'chat-panel'],
    active: false,
    joined: false,
    room: null,
    chat: inject.service(),
    session: inject.service(),
    stashLogger: inject.service('stash-logger'),
    roster: inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this._checkForJoinedRoom();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('shouldBeActiveRoom')) {
        this.setActiveRoom().catch(function () {
          _this.get('stashLogger').debug('Unable to set supervisor assistance room active.');
        });
      }
    },
    disableFileUploads: computed('session.org', function () {
      try {
        var org = this.get('session').get('org');
        var prefs = JSON.parse(org.getFieldValue('preferences.preferences').value);
        var disableUploads = Ember.get(prefs, 'realtime.disableFileUploads');
        var unuseable = disableUploads === null || disableUploads === undefined;
        return unuseable ? false : disableUploads;
      } catch (err) {
        return false;
      }
    }),
    isEngageUser: computed.reads('session.person.isEngageUser'),
    shouldBeActiveRoom: computed.and('isEngageUser', 'chat.connected', 'active', 'joined'),
    _checkForJoinedRoom: observer('chat.connected', 'isEngageUser', function () {
      if (this.get('chat.connected') && this.get('isEngageUser') && !this.get('joined')) {
        this.joinRoom();
      }
    }),
    joinRoom: function joinRoom() {
      var _this2 = this;

      var chat = this.get('chat');

      if (this.get('chat.connected') && this.get('isEngageUser') && !this.get('joined')) {
        return chat.joinRoom(ROOM_NAME).then(function (room) {
          _this2.set('room', room);

          _this2.set('joined', true);

          return _this2.setActiveRoom().then(function () {
            return chat.loadRoomHistory(room);
          }).catch(function () {
            _this2.get('stashLogger').debug('Unable to set supervisor assistance room active.');
          });
        });
      }
    },
    setActiveRoom: function setActiveRoom() {
      if (!this.isDestroyed || !this.isDestroying) {
        var room = this.get('room');
        var chat = this.get('chat');

        if (room && this.get('chat.connected')) {
          if (this.get('active')) {
            return chat.activateRoom(room).then(function () {
              chat.clearRoomNotificationCounts(room);
            });
          } else {
            return chat.deactivateRoom(room);
          }
        }

        return RSVP.Promise.reject();
      }
    }
  });
  var _default = AgentAssistancePanelComponent;
  _exports.default = _default;
});