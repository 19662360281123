define("web-directory/helpers/initial-letter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    var string = typeof params[0] === 'undefined' ? '' : params[0];
    return string.charAt(0);
  });

  _exports.default = _default;
});