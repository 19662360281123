define("web-directory/components/suggest-search-result/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VDjowgV4",
    "block": "{\"statements\":[[1,[33,[\"entity-mini/default\"],null,[[\"entity\"],[[28,[\"entity\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/suggest-search-result/template.hbs"
    }
  });

  _exports.default = _default;
});