define("web-directory/components/search-facet-term/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VwT/vGvB",
    "block": "{\"statements\":[[11,\"span\",[]],[15,\"class\",\"term\"],[13],[0,\"\\n    \"],[11,\"a\",[]],[15,\"href\",\"#\"],[5,[\"action\"],[[28,[null]],\"selectTerm\"]],[13],[0,\"\\n        \"],[1,[33,[\"facet-term-label\"],null,[[\"facet\",\"term\"],[[28,[\"facet\"]],[28,[\"term\",\"term\"]]]]],false],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[11,\"span\",[]],[15,\"class\",\"count\"],[13],[0,\"\\n    \"],[1,[28,[\"term\",\"count\"]],false],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/search-facet-term/template.hbs"
    }
  });

  _exports.default = _default;
});