define("web-directory/components/integration-badges/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['integration-badges'],
    phoneIntegrations: Ember.inject.service(),
    entity: null,
    size: null,
    useLightIcon: false,
    isSmall: Ember.computed.equal('size', 'small')
  });

  _exports.default = _default;
});