define("web-directory/helpers/short-chat-time", ["exports", "web-directory/utils/dates"], function (_exports, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    var time = params[0];
    return (0, _dates.default)(time).format('h:mm a');
  });

  _exports.default = _default;
});