define("web-directory/utils/promise-array", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function tap(proxy, promise) {
    Ember.set(proxy, 'isFulfilled', false);
    Ember.set(proxy, 'isRejected', false);
    return promise.then(function (result) {
      var payload;
      var content = result;

      if (result && _typeof(result) === 'object') {
        content = result.collection;
        payload = result.payload;
      }

      Ember.set(proxy, 'isFulfilled', true);
      Ember.set(proxy, 'content', content);
      Ember.set(proxy, 'content._payload', payload);
      Ember.set(proxy, '_payload', payload);
      return content;
    }, function (reason) {
      Ember.set(proxy, 'isRejected', true);
      Ember.set(proxy, 'reason', reason);
      throw reason;
    }, 'Ember: PromiseProxy');
  }

  var _default = _emberData.default.PromiseArray.extend({
    _payload: null,
    promise: Ember.computed({
      get: function get() {
        throw Ember.Logger.error("PromiseProxy's promise must be set");
      },
      set: function set(key, promise) {
        return tap(this, promise);
      }
    })
  });

  _exports.default = _default;
});