define("web-directory/components/dropdown-menu/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dropdown-menu-view'],
    showMenu: false,
    actions: {
      toggleMenu: function toggleMenu() {
        this.toggleProperty('showMenu');
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      var id = this.get('elementId');
      Ember.$(window).on("click.".concat(id), function (event) {
        Ember.run(function () {
          var target = $(event.target);
          var parents = target.parents('.dropdown-menu-view');
          var targetOutside = !parents.length || id !== parents.attr('id');
          var targetInput = target.parent('.input').length;
          var listItem = target.prop('tagName') === 'LI';

          if (targetOutside || targetInput || listItem) {
            _this.set('showMenu', false);
          }
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(window).off(".".concat(this.get('elementId')));
    }
  });

  _exports.default = _default;
});