define("web-directory/components/group-profile/group-list/list-item/component", ["exports", "web-directory/utils/keystroke"], function (_exports, _keystroke) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['list-item'],
    classNameBindings: ['editing', 'outOfRotationRange:out-of-range', 'listModel.scrollIntoView:highlight'],
    intl: Ember.inject.service(),
    isSequential: false,
    displayIndices: true,
    listModel: null,
    input: null,
    tetherConstraints: [{
      to: 'scrollParent',
      pin: true
    }],
    hovercardOffset: '0px 0px',
    hovercardArrowOffset: 24,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('listModel.scrollIntoView')) {
        this.scrollIntoView();
      }
    },
    index: Ember.computed.reads('listModel.index'),
    outOfRotationRange: Ember.computed.and('listModel.outOfRange', 'isSequential'),
    displayRotationRangePopup: Ember.computed('listModel.beginningOutOfRange', 'listModel.popupDismissed', 'isSequential', 'editing', function () {
      return this.get('listModel.beginningOutOfRange') && this.get('isSequential') && !this.get('listModel.popupDismissed') && this.get('editing');
    }),
    position: Ember.computed('index', function () {
      return "".concat(this.get('index'), ".");
    }),
    role: Ember.computed('listModel.isOwner', function () {
      if (this.get('listModel.isOwner')) {
        return this.get('intl').t('groups.memberTypes.owner');
      }

      return this.get('intl').t('groups.memberTypes.individual');
    }),
    newIndex: Ember.computed('input', function () {
      return parseInt(this.get('input'));
    }),
    popupTarget: Ember.computed('elementId', function () {
      return "list-item-popup-".concat(this.elementId);
    }),
    actions: {
      indexChanged: function indexChanged() {
        this.changeIndex();
      },
      dismissPopup: function dismissPopup() {
        this.set('listModel.popupDismissed', true);
      },
      setIndexChanged: function setIndexChanged(event) {
        if ((0, _keystroke.eventToString)(event) !== 'ENTER') {
          var value = parseInt(event.target.value, 10);

          if (!isNaN(value)) {
            this.set('newIndex', value);
          }
        }
      },
      submitIndexChange: function submitIndexChange(event) {
        if ((0, _keystroke.eventToString)(event) === 'ENTER') {
          this.changeIndex();
        }
      }
    },
    changeIndex: function changeIndex() {
      this.attrs.changePosition(this.get('index'), this.get('newIndex'));
      this.set('input', null);
    },
    scrollIntoView: function scrollIntoView() {
      var _this = this;

      var parent = this.$().parent();
      var scrollHeight = parent.prop('scrollHeight');
      parent.animate({
        scrollTop: scrollHeight
      }, 1000);
      Ember.run.later(this, function () {
        _this.set('listModel.scrollIntoView', false);
      }, 5000);
    }
  });

  _exports.default = _default;
});