define("web-directory/services/meeting", ["exports", "ember-data", "lodash", "moment"], function (_exports, _emberData, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Promise = Ember.RSVP.Promise;
  var inject = Ember.inject;

  var _default = Ember.Service.extend({
    chat: inject.service(),
    store: inject.service(),
    notification: inject.service(),
    intl: inject.service(),
    session: inject.service(),
    pigeon: inject.service(),
    sidebar: inject.service(),
    _pigeonEventHandler: null,
    filter: (0, _moment.default)(),
    init: function init() {
      this._super.apply(this, arguments);

      this.get('sidebar.showMeetings');

      this._registerToPigeonTopic();

      this._pigeonEventHandler = this._handlePigeonEvent.bind(this);
      this.get('pigeon').on('meetings', this, this._pigeonEventHandler);
    },
    willDestroy: function willDestroy() {
      this.get('pigeon').off('meeting', this._pigeonEventHandler);
    },
    meetings: Ember.computed('filter', {
      get: function get() {
        return _emberData.default.PromiseArray.create({
          promise: this._fetchMeetings(this.get('filter'))
        });
      },
      set: function set(key, value) {
        return _emberData.default.PromiseArray.create({
          promise: Ember.RSVP.resolve(value)
        });
      }
    }),
    unseen: Ember.computed('meetings.content.@each.{unseen}', function () {
      if (this.get('meetings.content')) {
        return this.get('meetings.content').filter(function (meeting) {
          return meeting.unseen;
        }).length > 0;
      }

      return false;
    }),
    sidebarObserver: Ember.observer('sidebar.showMeetings', function () {
      if (!this.get('sidebar.showMeetings') && this.get('unseen')) {
        this.clearUnseen();
      }
    }),
    createMeeting: function createMeeting(record) {
      var _this = this;

      var model = this.get('store').createRecord('meeting', record);
      return model.save().then(function () {
        _this.notifyPropertyChange('meetings');
      }).catch(function (error) {
        model.deleteRecord();
        return Promise.reject(error);
      });
    },
    editMeeting: function editMeeting(meeting, update) {
      var series = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

      if (series) {
        return this._editSeries(meeting, update);
      }

      return this._editOccurrence(meeting, update);
    },
    deleteMeeting: function deleteMeeting(meeting, deleteSeries) {
      var _this2 = this;

      meeting.deleteRecord();
      var options = {};

      if (deleteSeries) {
        options.adapterOptions = {
          deleteSeries: true
        };
      }

      meeting.save(options).finally(function () {
        _this2.notifyPropertyChange('meetings');
      });
    },
    clearUnseen: function clearUnseen() {
      this.get('meetings').then(function (meetings) {
        meetings.forEach(function (meeting) {
          meeting.set('unseen', false);
        });
      });
    },
    _editSeries: function _editSeries(meeting, update) {
      var _this3 = this;

      var id = meeting.get('meeting.id');

      if (!id) {
        meeting.setProperties(update);
        return meeting.save({
          adapterOptions: {
            updateSeries: true
          }
        }).then(function () {
          return _this3.notifyPropertyChange('meetings');
        });
      }

      return this.get('store').findRecord('meeting', id).then(function (parentMeeting) {
        parentMeeting.setProperties(update);
        return parentMeeting.save({
          adapterOptions: {
            updateSeries: true
          }
        }).then(function () {
          return _this3.notifyPropertyChange('meetings');
        });
      });
    },
    _editOccurrence: function _editOccurrence(meeting, update) {
      var _this4 = this;

      meeting.setProperties(update);
      return meeting.save().then(function () {
        _this4.notifyPropertyChange('meetings');
      });
    },
    _registerToPigeonTopic: function _registerToPigeonTopic() {
      var userId = this.get('session.person.guid');
      var meetingTopic = "v2.users.".concat(userId, ".meetings");
      this.get('pigeon').registerPriorityTopic(meetingTopic);
    },
    _fetchMeetings: function _fetchMeetings(date) {
      var _this5 = this;

      return new Promise(function (resolve, reject) {
        if (!_moment.default.isMoment(date)) {
          return reject(new Error('Date provided to fetchMeetings must be a moment'));
        }

        date = date.clone();

        var meetings = _this5.get('store').query('meeting', {
          startDate: date.toISOString(),
          endDate: date.add(30, 'days').toISOString()
        });

        var meetingArray = meetings.then(function (data) {
          return Promise.resolve(data.content.map(function (internal) {
            return internal.getRecord();
          }));
        }).then(function (meetingArray) {
          return _this5._sortMeetings(meetingArray);
        });
        resolve(meetingArray);
      });
    },
    _sortMeetings: function _sortMeetings(meetingArray) {
      return _lodash.default.chain(meetingArray).sortBy(function (meeting) {
        return (0, _moment.default)(meeting.get('startDate')).valueOf();
      }).value();
    },
    _handlePigeonEvent: function _handlePigeonEvent(meetingEvent, _ref) {
      var action = _ref.metadata.action;
      var store = this.get('store');

      switch (action) {
        case 'reminder':
          this._displayNotification(meetingEvent);

          break;

        case 'create':
          this._handleCarrierPigeonUpdate(meetingEvent.id);

          break;

        case 'update':
          if (store.hasRecordForId('meeting', meetingEvent.id)) {
            this._updateSingleOccurrence(store, meetingEvent.id);
          } else {
            this._handleCarrierPigeonUpdate(meetingEvent.id);
          }

          break;

        default:
          this.notifyPropertyChange('meetings');
          break;
      }
    },
    _updateSingleOccurrence: function _updateSingleOccurrence(store, meetingId) {
      var _this6 = this;

      return store.findRecord('meeting', meetingId, {
        reload: true
      }).then(function (record) {
        if (_this6.get('session.person.guid') !== record.get('organizer.id')) {
          record.set('unseen', true);
        }
      }).then(function () {
        return _this6.get('meetings');
      }).then(function (meetings) {
        return _this6._sortMeetings(meetings);
      }).then(function (meetings) {
        return _this6.set('meetings', meetings);
      });
    },
    _handleCarrierPigeonUpdate: function _handleCarrierPigeonUpdate(meetingId) {
      var _this7 = this;

      return this._fetchMeetings(this.get('filter')).then(function (meetings) {
        return _this7._badgeMeetings(meetings, meetingId);
      }).then(function (badgedMeetings) {
        return _this7.set('meetings', badgedMeetings);
      });
    },
    _badgeMeetings: function _badgeMeetings(meetings, meetingId) {
      var session = this.get('session');
      meetings.filter(function (meeting) {
        return meetingId === meeting.get('meeting.id') && session.get('person.guid') !== meeting.get('organizer.id');
      }).forEach(function (meeting) {
        meeting.set('unseen', true);
      });
      return meetings;
    },
    _displayNotification: function _displayNotification(meetingEvent) {
      var _this8 = this;

      var meeting = Ember.Object.create(meetingEvent);
      var notification;

      var notificationCallback = function notificationCallback() {
        var jid = meeting.get('roomId');

        _this8.get('chat').getChatRoom(jid).then(function (room) {
          _this8.get('chat').popoutChatRoom(room, true, false);
        });

        notification.hide();
      };

      var options = {
        target: this,
        forceBrowserNotification: true,
        actionHandlers: {
          attend: {
            label: this.get('intl').t('meetings.notification.attend'),
            action: notificationCallback
          },
          ignore: {
            label: this.get('intl').t('meetings.notification.ignore'),
            action: function action() {
              notification.hide();
            }
          }
        },
        type: 'info',
        hideAfter: null
      };
      var title = meeting.get('subject');
      var time = (0, _moment.default)(meeting.get('startDate')).format('hh:mm A');
      notification = this.get('notification').info(null, this.get('intl').t('meetings.notification.title', {
        title: title,
        time: time
      }), options);
    }
  });

  _exports.default = _default;
});