define("web-directory/adapters/division", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend({
    namespace: 'platform/api/v2/authorization',
    divisionLimit: null,
    pathForType: function pathForType(modelName) {
      return (0, _emberInflector.pluralize)(modelName);
    },
    // custom findAll method sets pageSize equal to division limit
    findAll: function findAll(store, type, sinceToken, snapshotRecordArray) {
      var _this = this;

      var objectCount = Ember.get(snapshotRecordArray, 'adapterOptions.objectCount');
      var url = this.buildURL(type.modelName, null, snapshotRecordArray, 'findAll');
      return Ember.RSVP.resolve(this.get('divisionLimit') || Ember.$.ajax({
        type: 'GET',
        url: '/platform/api/v2/authorization/divisions/limit'
      })).then(function (divisionLimit) {
        _this.set('divisionLimit', divisionLimit);

        return _this.ajax(url, 'GET', {
          data: {
            pageSize: divisionLimit,
            objectCount: objectCount ? true : false
          }
        });
      });
    }
  });

  _exports.default = _default;
});