define("web-directory/services/cobrowse", ["exports", "ember-purecloud-components/mixins/logger", "web-directory/mixins/conversation", "web-directory/utils/acdParticipantStatuses"], function (_exports, _logger, _conversation, _acdParticipantStatuses) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      Service = Ember.Service;

  var _loadCobrowse = function _loadCobrowse(scriptUrl) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      $.getScript(scriptUrl).done(function () {
        resolve();
      }).fail(function (err) {
        Ember.Logger.error('Could not load cobrowse.js script', {
          error: err
        });
        reject(err);
      });
    });
  };

  var _default = Service.extend(_logger.default, _conversation.default, {
    session: inject.service(),
    application: inject.service(),
    cobrowse: null,
    activeSession: null,
    loadCobrowse: function loadCobrowse() {
      var _this = this;

      return _loadCobrowse("".concat(this.get('application.cobrowseUri')) + '/cobrowse-jsapi-v1.js?deploymentKey=DEVELOPMENT').then(function () {
        _this.set('cobrowseLoaded', true);
      }).catch(function (err) {
        _this.logger.error('Co-browse: Failed to load co-browse.', {
          error: err
        });

        throw err;
      });
    },
    startCobrowse: function startCobrowse() {
      var _this2 = this;

      if (!this.get('cobrowseLoaded')) {
        return this.loadCobrowse().then(function () {
          return _this2.startCobrowse();
        });
      }

      this.logger.log('Co-browse: Initiating co-browsing...');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        window.PureCloud.cobrowse.startSharing({
          participant: {
            displayName: _this2.get('session.person.name')
          }
        }, function (err, sharerSession) {
          if (err) {
            _this2.logger.error('Co-browse: Failed to start sharing page', {
              error: err
            });

            return reject(err);
          }

          _this2.logger.log('Co-browse: Initiated. Page is being shared.');

          _this2.set('activeSession', sharerSession);

          _this2.logger.log('Co-browse: Viewer URL: ' + sharerSession.getViewerUrl());

          resolve(sharerSession);
        });
      });
    },
    stopCobrowse: function stopCobrowse() {
      var session = this.get('activeSession');

      if (session) {
        this.set('activeSession', null);
        return session.stop();
      }
    },
    processCobrowseActivationFailure: function processCobrowseActivationFailure(interaction) {
      var sessionId = Ember.get(interaction, 'mostRecentCustomerSessionShare.cobrowseSessionId');

      var isCustomerCobrowseConnected = Ember.get(interaction, 'mostRecentCustomerSessionShare.state').toLowerCase() === _acdParticipantStatuses.default.CONNECTED.toLowerCase();

      if (sessionId && isCustomerCobrowseConnected) {
        var url = this.get('application').pcV2Uri("api/v2/cobrowse/session/".concat(sessionId, "/activationfailure"));
        var options = {
          data: {
            reason: 'INSUFFICIENT_PERMISSIONS'
          }
        };
        this.makeRequest(url, options, 'post');
      }
    }
  });

  _exports.default = _default;
});