define("web-directory/components/entity-field-section/entity-relationships/relationship-field/component", ["exports", "ember-purecloud-components/mixins/logger", "lodash/forEachRight", "lodash/isEmpty", "lodash/uniqBy"], function (_exports, _logger, _forEachRight, _isEmpty, _uniqBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var set = Ember.set,
      inject = Ember.inject,
      computed = Ember.computed,
      observer = Ember.observer,
      Component = Ember.Component;
  var LIMIT = 15;

  function getNextPerson(orderedPeople, people, person) {
    if (person && person.get && person.get('id')) {
      orderedPeople.pushObject(person);
      var manager = person.getFieldValue('relationships.manager');

      if (!manager) {
        return;
      }

      var nextPerson = people.find(function (p) {
        return p.get('id') === manager.guid;
      });

      if (!nextPerson) {
        return;
      }

      return getNextPerson(orderedPeople, people, nextPerson);
    }
  }

  var _default = Component.extend(_logger.default, {
    classNames: ['field-component', 'relationship-field'],
    intl: inject.service(),
    entity: null,
    editing: false,
    relationship: null,
    fieldValuesToAdd: [],
    showingMore: false,
    people: null,
    attrs: {
      onUpdateLabel: function onUpdateLabel() {},
      onUpdateField: function onUpdateField() {}
    },
    actions: {
      selectPerson: function selectPerson(person) {
        var relationship = this.get('relationship');
        relationship.addPerson(person);
        this.get('onUpdateField')(relationship);
      },
      removePerson: function removePerson(person) {
        var relationship = this.get('relationship');
        relationship.removePerson(person);
        this.get('onUpdateField')(relationship);
      },
      removeRelationship: function removeRelationship() {
        var shouldRemove = true;

        if (this.get('relationship.people.length')) {
          var message = this.get('intl').lookup('person.relationships.confirmRemove').toString();
          shouldRemove = confirm(message);
        }

        if (shouldRemove) {
          var relationship = this.get('relationship');

          if (relationship.get('isOtherRelationship')) {
            var key = this.get('relationship.key');
            var values = this.get('entity.relationships.relationships');
            var valuesToRemove = values.filter(function (value) {
              return value.labelKey === key;
            });
            values.removeObjects(valuesToRemove);
          } else {
            var people = relationship.get('people');
            (0, _forEachRight.default)(people, function (person) {
              return relationship.removePerson(person);
            });
          }

          this.get('onUpdateField')(relationship);
        }
      },
      toggleShowing: function toggleShowing() {
        this.toggleProperty('showingMore');
      },
      onUpdateLabel: function onUpdateLabel(newLabel, oldLabel) {
        var people = this.get('people');
        var ids = people.mapBy('id');
        var values = this.get('entity.relationships.relationships').filter(function (value) {
          return (value.labelKey === oldLabel || value.labelkey === oldLabel) && ids.includes(value.value);
        });
        (0, _uniqBy.default)(values, 'value').forEach(function (value) {
          set(value, 'labelKey', newLabel);
        });
        this.get('onUpdateLabel').apply(void 0, arguments);
      }
    },
    canEdit: computed.and('editing', 'relationship.canEdit'),
    canRemove: computed('canEdit', 'people.[]', 'manager', function () {
      var canEdit = this.get('canEdit');

      if (this.get('isManagerField')) {
        return canEdit && !(0, _isEmpty.default)(this.get('manager'));
      }

      return canEdit && !(0, _isEmpty.default)(this.get('people'));
    }),
    manager: computed.reads('entity.manager.content'),
    isManagerField: computed.equal('relationship.key', 'management'),
    updatePeople: observer('relationship.people.[]', 'showingMore', 'isManagerField', function () {
      var _this = this;

      var people = this.get('relationship.people') || Ember.A([]);

      if (this.get('isManagerField')) {
        var cachedPeople = this.get('people');

        if (cachedPeople && people && cachedPeople.length > 0 && people.length === cachedPeople.length) {
          return;
        }

        var orderedPeople = Ember.A([]);
        this.get('entity.manager').then(getNextPerson.bind(this, orderedPeople, people)).catch(function (err) {
          _this.logger.error('Error loading managers:', {
            err: err
          });

          _this.set('people', people);
        }).finally(function () {
          return _this.set('people', orderedPeople);
        });
      }

      if (this.get('showingMore')) {
        this.set('people', people);
        return;
      }

      this.set('people', people.slice(0, LIMIT));
    }),
    hasMore: computed('relationship.people.[]', function () {
      return this.get('relationship.people.length') > LIMIT;
    }),
    haveManager: computed('manager', function () {
      return !(0, _isEmpty.default)(this.get('manager'));
    }),
    hideSection: computed('editing', 'canEdit', 'relationship.people.[]', function () {
      var editing = this.get('editing');
      var canEdit = this.get('canEdit');

      if (editing) {
        return !canEdit;
      }

      return !this.get('relationship.people.length');
    })
  });

  _exports.default = _default;
});