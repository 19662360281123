define("web-directory/serializers/group", ["exports", "web-directory/serializers/application", "lodash", "ember-purecloud-components/utils/dates", "web-directory/utils/uuid"], function (_exports, _application, _lodash, _dates, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var inject = Ember.inject;
  var OUR_COOL_REQUEST_TYPES = ['query', 'findMany', 'findAll', 'search'];

  var _default = _application.default.extend({
    session: inject.service(),
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options); // Never update contactInfo.chat, thats autogenerated


      if (json && json.contactInfo && json.contactInfo.chat) {
        delete json.contactInfo.chat;
      }

      return json;
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      var meta = {};

      if (requestType === 'deleteRecord') {
        payload = payload.entities[0];
        delete payload.entities;
        payload.contactInfo = {
          chat: []
        };
        payload.contactInfo.chat.push({
          value: {
            jid: payload.chat.jabberId
          },
          labelKey: 'chat'
        });
        payload._id = this.getJabberId(payload.chat.jabberId);
        delete payload.chat;
      } else {
        if (payload.general.links && payload.general.links.length > 0) {
          this.generateGroupLinkIds(payload.general.links);
        }

        var jId = payload.contactInfo.chat[0].value.jid;
        payload._id = this.getJabberId(jId);
      }

      payload.guid = payload.id || payload.guid;

      if (payload.guid) {
        payload.policy = {
          id: payload.guid,
          type: 'group-policy'
        };
      }

      if (requestType === 'deleteRecord' && !payload._id) {
        payload._id = id;
      }

      payload.meta = meta;
      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      var _this = this;

      // normalizeArrayResponse was only implmented to handle bevy group results.
      var data = payload;

      if (payload.entities) {
        data = payload.entities;
        delete payload.entities;
      }

      var finalData = {};
      var meta = {};

      if (_typeof(payload) === 'object' && !Array.isArray(payload)) {
        meta = payload;
      }

      var groups = {};

      if (_lodash.default.includes(OUR_COOL_REQUEST_TYPES, requestType)) {
        _lodash.default.forEach(data, function (g) {
          if (g.contactInfo.chat && g.contactInfo.chat.length > 0) {
            var jId = g.contactInfo.chat[0].value.jid;
            g._id = _this.getJabberId(jId);
          }

          if (g.general.links && g.general.links.length > 0) {
            _this.generateGroupLinkIds(g.general.links);
          }

          g.guid = g.id;
          g.orgGuid = g.id;
          g.mt = (0, _dates.default)(g.dateModified).unix();
          g.search_key = g.general.name[0].value.toLowerCase();
          groups[g._id] = g;
        });
      }

      finalData.res = groups;
      finalData.meta = meta;
      return this._super(store, primaryModelClass, finalData, id, requestType);
    },
    getJabberId: function getJabberId(id) {
      var idParts = id.split('@');
      return idParts.length > 0 ? idParts[0] : '';
    },

    /*  When using the public-api/bevy group requests
        the group sub-objects don't contain a _id property.
        Normally this is not an issue but for the general.links
        it does.  The UI uses the _id  property on each link.
        The following methods add/strip unique ids on the links.
    */
    generateGroupLinkIds: function generateGroupLinkIds(groupLinks) {
      _lodash.default.forEach(groupLinks, function (link) {
        if (!link._id) {
          link._id = _uuid.default.generate();
        }
      });
    },
    cleanGroupLinkIds: function cleanGroupLinkIds(groupLinks) {
      _lodash.default.forEach(groupLinks, function (link) {
        if (link._id) {
          delete link._id;
        }
      });
    }
  });

  _exports.default = _default;
});