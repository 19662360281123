define("web-directory/services/group-assigned-roles", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // service for tracking a group's assigned roles and those roles' associated divisions
  var _default = Ember.Service.extend({
    // key: "The role id
    // value: An object containing the following
    //      id: "The role id"
    //      role: "The role object"
    //      divisions: "An array of division objects assigned to the role"
    _map: {},
    changeCount: 0,
    list: Ember.computed('_map', 'changeCount', function () {
      return _lodash.default.map(this.get('_map'), function (roleWithDivisions) {
        return roleWithDivisions;
      });
    }),
    addRole: function addRole(roleId, _ref) {
      var divisions = _ref.divisions,
          id = _ref.id,
          role = _ref.role;
      this.get('_map')[roleId] = {
        divisions: divisions,
        id: id,
        role: role
      };
      this.incrementChangeCount();
    },
    removeRole: function removeRole(roleId) {
      delete this.get('_map')[roleId];
      this.incrementChangeCount();
    },
    getRoleWithDivisions: function getRoleWithDivisions(roleId) {
      return this.get('_map')[roleId];
    },
    setMap: function setMap(assignedRolesMap) {
      this.clear();
      this.set('_map', assignedRolesMap);
    },
    incrementChangeCount: function incrementChangeCount() {
      this.set('changeCount', this.get('changeCount') + 1);
    },
    containsRole: function containsRole(roleId) {
      return !!this.get('_map')[roleId];
    },
    clear: function clear() {
      this.incrementChangeCount();
      this.set('_map', {});
    }
  });

  _exports.default = _default;
});