define("web-directory/services/limits", ["exports", "ember-engine-custom-fields/services/limits"], function (_exports, _limits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _limits.default;
    }
  });
});