define("web-directory/components/inbox-panel-v2/fax-message-v2/component", ["exports", "lodash", "web-directory/utils/dates"], function (_exports, _lodash, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':inbox-message', ':fax-message-v2', 'fax.read::unread', 'faxId', 'fax.deleted:hide', 'hovered:active'],
    inbox: Ember.inject.service(),
    clock: Ember.inject.service(),
    intl: Ember.inject.service(),
    faxId: Ember.computed.reads('fax.id'),
    isNonEditable: true,
    showDropdown: false,
    mouseEnter: function mouseEnter() {
      if (this.get('isNonEditable') === true) {
        return this.set('hovered', true);
      }
    },
    mouseLeave: function mouseLeave() {
      if (this.get('isNonEditable') === true) {
        return this.set('hovered', false);
      }
    },
    date: Ember.computed('fax.dateCreated', function () {
      var date = (0, _dates.default)(this.get('fax.dateCreated'));
      return date.format('LL');
    }),
    time: Ember.computed('fax.dateCreated', function () {
      var date = (0, _dates.default)(this.get('fax.dateCreated'));
      return date.locale(this.get('intl.isoLocale')).format('LT');
    }),
    dateFromNow: Ember.computed('clock.minute', 'fax.dateCreated', function () {
      var date = (0, _dates.default)(this.get('fax.dateCreated'));
      return date.fromNow();
    }),
    actions: {
      triggerShare: function triggerShare() {
        var id = this.get('fax.id');
        var name = this.get('fax.name');
        var content = {
          id: id,
          name: name
        };
        this.sendAction('shareContent', true, content);
      },
      openFax: function openFax() {
        if (this.get('isNonEditable') === false && event.type !== 'click') {
          this.set('name', this.get('fax.name'));
          return this.send('editFax', true);
        } else if (this.get('isNonEditable') === true) {
          return this.get('inbox').readFax(this.get('fax'));
        }
      },
      changeStatus: function changeStatus(status) {
        this.get('inbox').changeFaxReadState(this.get('fax'), status);
      },
      close: function close() {
        this.set('isShowingDeleteConfirmation', false);
      },
      confirmDelete: function confirmDelete() {
        this.set('isShowingDeleteConfirmation', true);
      },
      deleteFax: function deleteFax() {
        this.get('inbox').deleteFax(this.get('fax'));
        this.get('fax').set('deleted', true);
        this.send('close');
      },
      renameFax: function renameFax(newName) {
        if (_lodash.default.isEmpty(newName)) {
          return;
        }

        this.get('inbox').renameFax(this.get('fax'), newName);
        this.send('editFax', true);
      },
      downloadFax: function downloadFax() {
        this.get('inbox').downloadFax(this.get('fax.id'));
      },
      editFax: function editFax(status) {
        this.set('isNonEditable', status);
        this.set('hovered', !status);

        if (status === false) {
          Ember.run.schedule('afterRender', this, function () {
            this.$('.ib-cancellable-textbox input').trigger('focus');
            return;
          });
        }
      }
    }
  });

  _exports.default = _default;
});