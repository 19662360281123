define("web-directory/components/center-stage-frame/component", ["exports", "lodash", "clipboard", "ember-purecloud-components/utils/pigeon"], function (_exports, _lodash, _clipboard, _pigeon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FRAME_PRIORITY_MODIFIER = 5;

  var _default = Ember.Component.extend({
    classNames: ['center-stage-content'],
    classNameBindings: ['visible:visible:hidden'],
    visible: true,
    showMenu: true,
    enableCopyURLIcon: false,
    session: Ember.inject.service(),
    permissionsService: Ember.inject.service('permissions'),
    engage: Ember.inject.service(),
    adminNav: Ember.inject.service(),
    adminui: Ember.inject.service(),
    coaching: Ember.inject.service(),
    frameService: Ember.inject.service('center-stage-frame'),
    inbox: Ember.inject.service(),
    intl: Ember.inject.service(),
    notification: Ember.inject.service(),
    phone: Ember.inject.service(),
    fax: Ember.inject.service(),
    sidebar: Ember.inject.service(),
    // cached route value use for detecting
    // route attribute changes
    _route: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.pageBreadcrumbs = [];
      this.pubSubBindings = {
        'agentHome.openCoachingAppointmentModal': function agentHomeOpenCoachingAppointmentModal(event) {
          _this.get('coaching').openCoachingAppointmentModal(event.payload);
        },
        'agentHome.openCoachingAppointmentsInboxTab': function agentHomeOpenCoachingAppointmentsInboxTab() {
          _this.get('inbox').openCoachingAppointments();
        },
        'agentHome.openLearningAssignmentsInboxTab': function agentHomeOpenLearningAssignmentsInboxTab() {
          _this.get('inbox').openLearningAssignments();
        },
        // Deprecated - use the page.data event
        'page.breadcrumbs': function pageBreadcrumbs(event) {
          _this.updateBreadcrumbs(event.payload);
        },
        // Deprecated - use the page.data event
        enableCopyUrlIcon: function enableCopyUrlIcon(event) {
          _this.updateCopyIcon(event && !!event.payload);
        },
        // Deprecated - use the page.data event
        helpPathChange: function helpPathChange(event) {
          _this.set('helpPath', event && event.payload && event.payload.helpPath || '');
        },
        // Deprecated - use the page.data event
        setDocumentTitle: function setDocumentTitle(event) {
          if (event && event.payload && event.payload.title) {
            document.title = event.payload.title;
          }
        },
        'page.data': function pageData(event) {
          if (event && event.payload) {
            _this.updatePageData(event.payload);
          }
        },
        'page.promptOnLeave': function pagePromptOnLeave(event) {
          if (event) {
            _this.updatePromptOnLeave(event.payload);
          }
        },
        'phone.makeCall': function phoneMakeCall(event) {
          if (event && event.payload) {
            _this.makePhoneCall(event.payload);
          }
        },
        'fax.openFaxPanel': function faxOpenFaxPanel(event) {
          if (event && event.payload) {
            _this.set('fax.address', event.payload.faxNumber);

            _this.get('sidebar').openPanel('callControls');

            _this.get('sidebar').togglePanelSubstate('callControls', 'currentTab', 'fax');
          }
        },
        'sidebar.expandCommunicationSidebar': function sidebarExpandCommunicationSidebar() {
          if (_this.get('sidebar.communicationPanel')) {
            _this.get('sidebar').expandCommunicationSidebar();
          }
        },
        'sidebar.expandAcdPanel': function sidebarExpandAcdPanel() {
          if (!_this.get('sidebar.showAcdInteractions')) {
            _this.get('sidebar').togglePanel('acdInteractions');
          }
        },
        'session.proxyLogin': function sessionProxyLogin(event) {
          if (event && event.payload) {
            _this.get('session').proxyLogin(event.payload.orgId, event.payload.hash);
          }
        }
      };
      this.set('topicPriority', (0, _pigeon.getPigeonMaxPriority)() - FRAME_PRIORITY_MODIFIER);
    },
    updatePromptOnLeave: function updatePromptOnLeave(prompt) {
      this.get('frameService').set('promptOnLeave', prompt);
    },
    makePhoneCall: function makePhoneCall(payload) {
      this.get('phone').makeCall(payload.phoneNumber, payload.callingPerson, payload.onbehalfOfQueue, payload.externalContactId);
    },
    // Updates metadata about the embedded page from a single data structure,
    // removing the need for multiple messages after navigation.
    updatePageData: function updatePageData(pageInfo) {
      if (pageInfo.title) {
        document.title = pageInfo.title;
      }

      this.set('helpPath', pageInfo.helpPath || '');

      if (pageInfo.breadcrumbs) {
        if (pageInfo.breadcrumbs.segments) {
          this.updateBreadcrumbs(pageInfo.breadcrumbs.segments);
        }

        this.updateCopyIcon(pageInfo.breadcrumbs.enableCopyUrl);
      }
    },
    checkBreadcrumbAuthorized: function checkBreadcrumbAuthorized(breadcrumb) {
      var permissionsService = this.get('permissionsService');
      var permissions = breadcrumb.permissions;
      var isBreadcrumbAuthorized = true;

      if (permissions) {
        var allOfThesePermissionsAreRequired = permissions.all || [];
        var oneOfThesePermissionsAreRequired = permissions.any || [];
        isBreadcrumbAuthorized = permissionsService.validateAllPermissions(allOfThesePermissionsAreRequired);

        if (oneOfThesePermissionsAreRequired.length) {
          isBreadcrumbAuthorized = isBreadcrumbAuthorized && permissionsService.validateAnyPermissions(oneOfThesePermissionsAreRequired);
        }
      }

      return isBreadcrumbAuthorized;
    },
    // Updates the breadcrumbs based on data provided by the client
    updateBreadcrumbs: function updateBreadcrumbs(segments) {
      var _this2 = this;

      var baseRoute = this.get('frameService').baseRouteForPath(this.get('route'));

      if (segments && baseRoute) {
        var crumbs = segments.map(function (entry) {
          var path = entry.path,
              text = entry.text,
              active = entry.active,
              isHostPath = entry.isHostPath,
              id = entry.id;
          var hasPath = !!path;

          var isAuthorized = _this2.checkBreadcrumbAuthorized(entry);

          var showAsLink = hasPath && isAuthorized;
          var clientCrumb = {
            text: text,
            id: id
          };

          if (showAsLink) {
            var normalizedBaseRoute = baseRoute.replace(/\/$/, '');
            var normalizedEntryPath = path.replace(/^\//, '');
            var href = isHostPath ? normalizedEntryPath : "#".concat(normalizedBaseRoute, "/").concat(normalizedEntryPath);
            clientCrumb = {
              text: text,
              href: href,
              active: active,
              id: id
            };
          }

          return clientCrumb;
        });
        this.set('pageBreadcrumbs', crumbs);
      } else {
        this.set('pageBreadcrumbs', []);
      }
    },
    // Updates the copy icon state based on data from the client
    updateCopyIcon: function updateCopyIcon(enabled) {
      this.set('enableCopyURLIcon', !!enabled);
      this.updateCopyURLIcon();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var _route = this.get('_route');

      var nextRoute = this.get('route');

      if (getRouteWithoutParams(_route) !== getRouteWithoutParams(nextRoute)) {
        this.setProperties({
          pageBreadcrumbs: [],
          enableCopyURLIcon: false,
          _route: nextRoute
        });
      }
    },
    updateCopyURLIcon: function updateCopyURLIcon() {
      var _this3 = this;

      var url = window.location.href;
      var clipboard = new _clipboard.default('.copy-icon', {
        text: function text() {
          return url;
        }
      });
      clipboard.on('success', function () {
        Ember.run(function () {
          _this3.get('notification').info(null, _this3.get('intl').t('notification.inboxShare.copyUrl').toString(), {
            tag: 'copy-share-uri',
            type: 'info',
            hideAfter: 5
          });
        });
      });
    },
    nav: Ember.computed('route', 'engage.navTree.@each.subLinks', 'session.isAuthenticated', 'adminui.adminEntries', 'adminNav.fullNav', 'adminNav.fullNavTree', function () {
      var navConfig = this.get('frameService').navForPath(this.get('route'));

      if (!navConfig) {
        return null;
      } else {
        var menu = navConfig.menu;

        if (typeof menu == 'string' && menu.startsWith('engage:')) {
          // This case should be temporary until we've migrated all the old PCBA
          // apps to iframe-coordinator
          menu = translateEngageMenu(menu.replace('engage:', ''), this.get('engage.navTree'));
        }

        if (typeof menu == 'string' && menu.startsWith('admin:')) {
          var adminMenu;

          if (this.get('session.isAuthenticated')) {
            adminMenu = this.get('adminNav.fullNavTree');
          } else {
            adminMenu = this.get('adminui.adminEntries');
          }

          menu = translateAdminMenu(menu.replace('admin:', ''), adminMenu);
        }

        return {
          breadcrumbs: navConfig.breadcrumbs || [],
          menu: menu
        };
      }
    }),
    // Combine the static breadcrumbs defined in the iframe-client-config with any
    // supplied by the center-stage content window via updatePageData. Normalize
    // the two into a single representation for use in the template.
    combinedBreadcrumbs: Ember.computed('nav.breadcrumbs', 'pageBreadcrumbs', function () {
      var _this4 = this;

      var staticCrumbs = this.get('nav.breadcrumbs') || [];
      var dynamicCrumbs = this.get('pageBreadcrumbs') || []; // Breadcrumbs defined in the client config will have intl keys that need
      // to be turned into a `text` value consistent with already-translated
      // values provided in the page.data breadcrumbs.

      var staticCrumbsNormalized = staticCrumbs.map(function (crumb) {
        var id = crumb.id,
            href = crumb.href,
            active = crumb.active,
            textKey = crumb.textKey;
        return {
          id: id,
          href: href,
          active: active,
          text: _this4.get('intl').lookup(textKey)
        };
      }); // Join the static and dynamic breadcrumb lists and omit the `href` prop
      // from any marked as `active` as those should not be rendered as links.

      return [].concat(staticCrumbsNormalized, dynamicCrumbs).map(function (crumb) {
        var id = crumb.id,
            href = crumb.href,
            active = crumb.active,
            text = crumb.text;

        if (active) {
          return {
            id: id,
            text: text
          };
        }

        return {
          id: id,
          href: href,
          text: text
        };
      });
    }),
    hasMenu: Ember.computed('nav.menu', function () {
      return this.get('nav') && this.get('nav.menu');
    }),
    helpPath: Ember.computed.alias('frameService.helpPath'),
    actions: {
      toggleMenu: function toggleMenu() {
        this.set('showMenu', !this.get('showMenu'));
      }
    },
    isSidebarFullWidth: Ember.computed.readOnly('sidebar.sidebarExpanded'),
    displayRoute: Ember.computed('route', 'isSidebarFullWidth', function () {
      if (this.get('isSidebarFullWidth')) {
        return 'no-op-route';
      } else {
        return this.get('route');
      }
    })
  });

  _exports.default = _default;

  function translateEngageMenu(engageKey, engageMenu) {
    // Handle local development without embedded engage
    if (engageMenu && engageMenu.find) {
      var navParent = engageMenu.find(function (menuItem) {
        return menuItem.subLinks && menuItem.href === '#' + engageKey;
      });

      if (navParent) {
        return navParent.subLinks.map(function (navItem) {
          var href = navItem.href; // If the link path startsWith a '#/', keep it as is, otherwise
          // prepend '#/engage/'. This lets us distinguish older auto-prefixed
          // links from new links that are fully specified

          if (href.substring(0, 2) !== '#/') {
            href = href.replace(/^#?/, '#/engage/');
          }

          return {
            textKey: navItem.text,
            href: href,
            permissions: extractPermissions(navItem.roles)
          };
        });
      }
    } // Couldn't find it.


    return null;
  } // Find parent menu item by matching items href with adminKey path.
  // Can match by parent path or any of child item paths.


  function translateAdminMenu(adminKey, adminMenu) {
    if (adminMenu && adminMenu.find) {
      var navParent = adminMenu.find(function (menuItem) {
        if (menuItem.subLinks) {
          return menuItem.href === adminKey || menuItem.id === adminKey || menuItem.subLinks.find(function (childMenuItem) {
            return childMenuItem.href == adminKey;
          });
        }
      });

      if (navParent) {
        return navParent.subLinks.map(mapAdminNavItem);
      }
    } // Couldn't find it.


    return null;
  }

  function mapAdminNavItem(navItem) {
    // Set the href to be the base route so that highlighting in the nav menu is handled for sub routes
    if (navItem.href && navItem.href.startsWith('responseManagement')) {
      navItem.href = navItem.href.substring(0, 'responseManagement'.length);
    }

    var mappedNavItem = {
      textKey: navItem.text,
      href: getAdminNavHref(navItem),
      permissions: extractPermissions(navItem.roles)
    };

    if (navItem.id) {
      mappedNavItem.id = navItem.id;
    }

    if (navItem.subLinks) {
      mappedNavItem.subLinks = navItem.subLinks.map(mapAdminNavItem);
    }

    if (navItem.external) {
      mappedNavItem.external = navItem.external;
    }

    return mappedNavItem;
  }

  function getAdminNavHref(adminNavItem) {
    // direct-linked nav items should be used exactly as their href says, no prefixing
    if (adminNavItem.direct) {
      return adminNavItem.href;
    } // dashboardHref is on adminNav.fullNav or engage menu and has the correct href we want


    if (adminNavItem.dashboardHref) {
      return adminNavItem.dashboardHref;
    }

    if (adminNavItem.href) {
      return '#/admin/' + adminNavItem.href;
    }

    return undefined;
  }

  function extractPermissions(engageNavRoles) {
    var navRoles = checkArrayOfArray(engageNavRoles);

    if (navRoles && navRoles.length) {
      return navRoles.filter(function (role) {
        role.startsWith('perm:');
      }).map(function (permStr) {
        permStr.replace(/^perm:/, '');
      });
    } else {
      return [];
    }
  }

  function checkArrayOfArray(navRoles) {
    if (navRoles && navRoles[0] && navRoles[0]['any'] && Array.isArray(navRoles[0]['any'])) {
      return _lodash.default.isEmpty(navRoles[0]['any']) ? [] : navRoles[0]['any'];
    } else {
      return navRoles;
    }
  }

  function getRouteWithoutParams(route) {
    return route ? route.split('?')[0] : null;
  }
});