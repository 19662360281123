define("web-directory/utils/entity-fields", ["exports", "lodash", "web-directory/models/group", "web-directory/models/location", "web-directory/models/person"], function (_exports, _lodash, _group, _location, _person) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MULTI_ENTITY_FIELDS = _exports.FL = void 0;

  var MULTI_ENTITY_FIELDS = _lodash.default.uniq([].concat(_group.BASE_GROUP_FIELDS).concat(_location.BASE_LOCATION_FIELDS).concat(_person.BASE_PERSON_FIELDS));

  _exports.MULTI_ENTITY_FIELDS = MULTI_ENTITY_FIELDS;
  var FL = {
    all: {
      fl: MULTI_ENTITY_FIELDS.join(',')
    },
    group: {
      fl: _group.BASE_GROUP
    },
    person: _person.BASE_PERSON,
    location: _location.BASE_LOCATION
  };
  _exports.FL = FL;
});