define("web-directory/components/entity-fields/validators/select", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    isValid: function isValid(select) {
      if (!select) {
        return false;
      }

      return !!(select && select.value);
    },
    isEmpty: function isEmpty(select) {
      var value = select.value;
      return value === undefined || value === null || _lodash.default.isEmpty(value);
    },
    cleanFieldForEntity: function cleanFieldForEntity(entity, field, entry) {
      if (!entry) {
        return;
      }

      var value = Ember.get(entry, 'value');
      var path = field.getFieldPath();

      if (_lodash.default.isEmpty(value)) {
        var data = entity.get(path);

        if (data) {
          data.removeObject(entry);
        }
      } else {
        Ember.set(entry, 'value', value);
      }
    }
  };
  _exports.default = _default;
});