define("web-directory/services/vendor-implementations/sennheiser", ["exports", "ember-softphone-integration/services/vendor-implementations/sennheiser"], function (_exports, _sennheiser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _sennheiser.default;
    }
  });
  Object.defineProperty(_exports, "eventTypes", {
    enumerable: true,
    get: function get() {
      return _sennheiser.eventTypes;
    }
  });
  Object.defineProperty(_exports, "events", {
    enumerable: true,
    get: function get() {
      return _sennheiser.events;
    }
  });
});