define("web-directory/templates/person/detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aMgaGtpz",
    "block": "{\"statements\":[[1,[33,[\"entity-profile\"],null,[[\"entity\",\"user\",\"editing\",\"editEmergencyLocation\",\"setEditingSection\"],[[28,[\"model\",\"person\"]],[28,[\"model\",\"user\"]],[28,[\"editing\"]],[28,[\"editEmergencyLocation\"]],[33,[\"action\"],[[28,[null]],\"setEditingSection\"],null]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/templates/person/detail.hbs"
    }
  });

  _exports.default = _default;
});