define("web-directory/components/acd-interactions-panel/interaction-notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WHdXNeFV",
    "block": "{\"statements\":[[11,\"h2\",[]],[15,\"class\",\"sr-only\"],[13],[1,[33,[\"t\"],[\"purecloud.acdInteraction.notes\"],null],false],[14],[0,\"\\n\\n\"],[11,\"div\",[]],[15,\"class\",\"sub-panel-body notes-body\"],[13],[0,\"\\n    \"],[1,[26,[\"acd-interactions-panel/interaction-wrapup-state\"]],false],[0,\"\\n\\n\"],[6,[\"if\"],[[28,[\"notesHistory\"]]],null,{\"statements\":[[0,\"        \"],[1,[33,[\"acd-interactions-panel/interaction-notes/interaction-notes-history\"],null,[[\"canViewChat\",\"notesHistory\"],[[28,[\"canViewChat\"]],[28,[\"notesHistory\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},null],[0,\"    \"],[11,\"textarea\",[]],[15,\"id\",\"interaction-notes\"],[15,\"dir\",\"auto\"],[16,\"placeholder\",[34,[[33,[\"unless\"],[[28,[\"readOnly\"]],[33,[\"t\"],[\"purecloud.acdInteraction.notesPlaceholder\"],null]],null]]]],[16,\"aria-label\",[33,[\"t\"],[\"purecloud.acdInteraction.notes\"],null],null],[16,\"readOnly\",[26,[\"readOnly\"]],null],[16,\"value\",[26,[\"notes\"]],null],[16,\"onInput\",[33,[\"action\"],[[28,[null]],\"onUpdateNotes\"],[[\"value\"],[\"target.value\"]]],null],[16,\"onBlur\",[33,[\"action\"],[[28,[null]],\"onUpdateNotes\"],[[\"value\"],[\"target.value\"]]],null],[13],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/acd-interactions-panel/interaction-notes/template.hbs"
    }
  });

  _exports.default = _default;
});