define("web-directory/components/entity-profile/permissions", ["exports", "ember-data", "lodash"], function (_exports, _emberData, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Mixin = Ember.Mixin,
      inject = Ember.inject,
      computed = Ember.computed;

  var _default = Mixin.create({
    session: inject.service(),
    permissions: inject.service(),
    canEditProfile: computed.or('onOwnProfile', 'profile.canEditProfile', 'session.person.isAdmin'),
    canEditName: computed('entity.readWritePermissions', 'editingProfile', 'session.features', 'permissions.canEditProfiles', function () {
      if (!this.get('editingProfile')) {
        return false;
      }

      return this.get('permissions.canEditProfiles');
    }),
    canEditTitle: computed('entity.readWritePermissions', 'editingProfile', 'session.features', 'permissions.canEditProfiles', function () {
      if (!this.get('editingProfile')) {
        return false;
      }

      return this.get('permissions.canEditProfiles');
    }),
    canEditDepartment: computed('editingProfile', 'permissions.canEditProfiles', function () {
      if (!this.get('editingProfile')) {
        return false;
      }

      return this.get('permissions.canEditProfiles');
    }),
    canReadHR: computed.reads('permissions.canReadProfile'),
    onOwnProfile: Ember.computed('entity.id', 'session.person.id', function () {
      return this.get('entity.id') === this.get('session.person.id');
    }),
    showGallery: Ember.computed('entity', 'entity.id', function () {
      var gallery = this.get('entity.images.informal');

      if (gallery) {
        return gallery.length > 0;
      }

      return false;
    }),
    canReadGroups: Ember.computed('entity.groups.{social,official}', function () {
      var groupsFieldConfig = this.get('entity.fieldConfig') ? this.get('entity.fieldConfig').getSectionByKey('groups') : null;
      var groups = this.get('entity.groups');

      if (!groupsFieldConfig || _lodash.default.isEmpty(groups)) {
        return false;
      }

      return Ember.get(groups, 'social.length') || Ember.get(groups, 'official.length');
    }),
    canEditProfilePicture: Ember.computed('canEditProfile', function () {
      return this.get('canEditProfile');
    }),
    canEditGeneralInfo: Ember.computed('onOwnProfile', function () {
      var _this = this;

      var promise = new Ember.RSVP.Promise(function (resolve) {
        resolve(_this.get('onOwnProfile'));
      });
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    canEditContactInfo: Ember.computed('session.{user.isAdmin,features}', 'entity.readWritePermissions', 'onOwnProfile', function () {
      var _this2 = this;

      var promise = new Ember.RSVP.Promise(function (resolve) {
        resolve(_this2.get('onOwnProfile'));
      });
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    canViewLocations: Ember.computed('ownOwnProfile', 'entity.readWritePermissions', 'session.{user.isAdmin,features}', 'entity.fieldConfig.location.location', function () {
      var _this3 = this;

      var locationsField = this.get('entity.fieldConfig.location.location');
      var promise = new Ember.RSVP.Promise(function (resolve) {
        if (_lodash.default.isEmpty(locationsField)) {
          return resolve(false);
        }

        if (_this3.get('session.user.isAdmin')) {
          return resolve(true);
        }

        resolve(_this3.get('onOwnProfile'));
      });
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    canViewInformals: Ember.computed('canEditProfilePicture', 'entity.images.informal', function () {
      var photos = this.get('entity.images.informal');

      if (photos && photos !== undefined) {
        return photos.length > 0;
      }

      return false;
    })
  });

  _exports.default = _default;
});