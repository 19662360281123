define("web-directory/services/headset", ["exports", "ember-softphone-integration/services/headset"], function (_exports, _headset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _headset.default;
    }
  });
});