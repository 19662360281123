define("web-directory/components/acd-interactions-panel/interaction-wrapup-state/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yfSehU9m",
    "block": "{\"statements\":[[11,\"span\",[]],[15,\"class\",\"header-message\"],[16,\"aria-label\",[26,[\"afterCallWorkHeaderText\"]],null],[13],[1,[26,[\"afterCallWorkHeaderText\"]],false],[14],[0,\"\\n\"],[6,[\"if\"],[[33,[\"or\"],[[28,[\"interaction\",\"isDisconnected\"]],[28,[\"interaction\",\"isTerminated\"]]],null]],null,{\"statements\":[[6,[\"if\"],[[28,[\"interaction\",\"isNonDiscretionaryCompletion\"]]],null,{\"statements\":[[0,\"        \"],[11,\"span\",[]],[15,\"class\",\"aftercallwork-timer non-discretionary-acw\"],[13],[0,\"\\n            \"],[1,[33,[\"duration-format\"],null,[[\"endTime\"],[[28,[\"interaction\",\"acwTimeoutTimer\"]]]]],false],[0,\"\\n        \"],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"        \"],[11,\"span\",[]],[15,\"class\",\"aftercallwork-timer\"],[13],[1,[33,[\"duration-format\"],null,[[\"startTime\"],[[28,[\"interaction\",\"acwStartTimer\"]]]]],false],[14],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/acd-interactions-panel/interaction-wrapup-state/template.hbs"
    }
  });

  _exports.default = _default;
});