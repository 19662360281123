define("web-directory/serializers/purecloud", ["exports", "ember-data", "lodash"], function (_exports, _emberData, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    isNewSerializerAPI: true,
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      var data = {};
      data[primaryModelClass.modelName] = payload;
      return this._super(store, primaryModelClass, data, id, requestType);
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      var data = {};
      var type = primaryModelClass.modelName;

      if (payload.entities) {
        data[type] = payload.entities;
        delete payload.entities;
        payload = _lodash.default.merge(data, {
          meta: payload
        });
      } // Support for search results


      if (payload.results) {
        data[type] = payload.results;
        delete payload.results;
        payload = _lodash.default.merge(data, {
          meta: payload
        });
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});