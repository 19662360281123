define("web-directory/services/queue-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AVAILABLE = 'available';
  var ON_QUEUE = 'on_queue';

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    phone: Ember.inject.service(),
    webrtc: Ember.inject.service(),
    presenceService: Ember.inject.service('presence'),
    presenceSubscription: Ember.inject.service(),
    session: Ember.inject.service(),
    sidebar: Ember.inject.service(),
    permissions: Ember.inject.service(),
    notification: Ember.inject.service(),
    logger: Ember.inject.service('stash-logger'),
    presence: Ember.computed.reads('session.person.activityPresence'),
    checkQueue: false,
    selectPhoneNotification: null,
    presenceHistory: [],
    hasStation: Ember.computed.not('phone.noStationSelected'),
    continueOnQueue: function continueOnQueue() {
      var entity = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      if (this.get('selectPhoneNotification')) {
        this.get('selectPhoneNotification').hide();
        this.set('selectPhoneNotification', null);
      }

      var presence = this.get('presence');
      var systemPresence = this.get('session.person.presenceDefinition');

      if (systemPresence) {
        presence = systemPresence;
      }

      this.get('presenceHistory').pushObject(presence);

      if (entity) {
        this.get('presenceService').setUsersPresence(entity, ON_QUEUE);
      } else {
        this.get('presenceSubscription').changePresence(ON_QUEUE);
      }
    },
    selectPhone: function selectPhone() {
      if (this.get('phone.enabled')) {
        this.get('sidebar').togglePanelSubstate('callControls', 'currentTab', 'followMeSettings');
        this.get('sidebar').openPanel('callControls');
      }

      if (this.get('selectPhoneNotification')) {
        this.get('selectPhoneNotification').hide();
      }

      this.set('selectPhoneNotification', null);
    },
    handleToggle: function handleToggle() {
      var _this = this;

      var entity = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var continueOnQueue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var intl = this.get('intl');
      var presence = this.get('presence');

      if (entity && entity.get('presence')) {
        presence = entity.get('presence');
      } // If an entity is provided, do not consider state of checkQueue as that is specific to the current logged-in user.
      // The entity could be any user.


      if ((entity || !this.get('checkQueue')) && presence !== ON_QUEUE) {
        // prompt user to select a phone if they don't
        // have one currently selected
        var hasStation = this.get('hasStation');
        var canMakeCall = this.get('permissions.phoneEnabled');
        var canDisassociateSelf = this.get('permissions.canDisassociateSelf');

        if (!hasStation && canMakeCall && !continueOnQueue) {
          if (this.get('selectPhoneNotification') === null) {
            var actionHandlers = {
              selectPhone: intl.t('notification.purecloud.selectPhone.selectPhone')
            };

            if (canDisassociateSelf) {
              actionHandlers.continueOnQueue = {
                label: intl.t('notification.purecloud.selectPhone.doNotSelectPhone'),
                action: function action() {
                  return _this.continueOnQueue(entity);
                }
              };
            }

            this.set('selectPhoneNotification', this.get('notification').info(null, intl.t('notification.purecloud.selectPhone.message'), {
              forceBrowserNotification: true,
              showCloseButton: false,
              hideAfter: false,
              messageStyle: 'pin-to-top',
              type: 'select-phone-message',
              target: this,
              tag: 'select-phone-message',
              actionHandlers: actionHandlers
            }));
          }
        } else if (this.get('phone.phoneDisabled') && !continueOnQueue) {
          this.set('selectPhoneNotification', this.get('notification').info(null, this.get('phone.phoneDisabledMessage'), {
            showNotificationEverywhere: false,
            showCloseButton: false,
            hideAfter: false,
            messageStyle: 'pin-to-top',
            type: 'select-phone-message',
            target: this,
            tag: 'select-phone-message',
            actionHandlers: {
              selectPhone: intl.t('notification.purecloud.selectPhone.selectPhone'),
              continueOnQueue: {
                label: intl.t('notification.purecloud.selectPhone.doNotSelectPhone'),
                action: function action() {
                  return _this.continueOnQueue(entity);
                }
              }
            }
          }));
        } else {
          this.continueOnQueue(entity);
        }
      } else {
        if (this.get('selectPhoneNotification')) {
          this.get('selectPhoneNotification').hide();
        }

        this.set('selectPhoneNotification', null);
        presence = this.get('presenceHistory').popObject();
        this.get('logger').debug('<services/queue-toggle> going off queue: users previous presence: ', presence);

        if (entity) {
          if (!presence) {
            this.get('presenceService').setUsersPresence(entity, AVAILABLE);
          } else {
            this.get('presenceService').setUsersPresence(entity, presence);
          }
        } else {
          if (!presence) {
            this.get('logger').debug('<services/queue-toggle> going off queue - no presence - setting user to Available');
            this.get('presenceSubscription').changePresence(AVAILABLE);
          } else if (!this.get('presenceSubscription').isPresence(presence, 'offline')) {
            this.get('logger').debug('<services/queue-toggle> going off queue - setting user presence to previous presence: ', presence);
            this.get('presenceSubscription').changePresence(presence);
          } else {
            this.get('logger').debug('<services/queue-toggle> going off queue - presence was offline, setting users presence to Available', presence);
            this.get('presenceSubscription').changePresence(AVAILABLE);
          }
        }
      }
    }
  });

  _exports.default = _default;
});