define("web-directory/components/person-icon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PersonIconComponent = Ember.Component.extend({
    classNames: ['person-icon'],
    favorites: Ember.inject.service(),
    permissions: Ember.inject.service(),
    canViewChat: Ember.computed.reads('permissions.canViewBusinessChat'),
    canViewVideo: Ember.computed.reads('permissions.canViewCollaborateVideo'),
    person: null,
    actions: {
      noop: function noop() {}
    }
  });
  var _default = PersonIconComponent;
  _exports.default = _default;
});