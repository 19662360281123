define("web-directory/models/externalcontacts/line-id", ["exports", "ember-data", "web-directory/models/externalcontacts/channel"], function (_exports, _emberData, _channel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LINEID_TYPE = void 0;
  var LINEID_TYPE = 'externalcontacts/line-id';
  _exports.LINEID_TYPE = LINEID_TYPE;

  var _default = _channel.default.extend({
    type: 'line',
    ids: _emberData.default.attr(),
    displayName: _emberData.default.attr('string')
  });

  _exports.default = _default;
});