define("web-directory/helpers/emoji-text", ["exports", "lodash", "ember-purecloud-components/utils/emoji"], function (_exports, _lodash, _emoji) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Handlebars = Ember.Handlebars,
      Helper = Ember.Helper;

  var _default = Helper.helper(function (params) {
    var text = _lodash.default.head(params);

    if (text) {
      text = (0, _emoji.emojifyString)(Handlebars.Utils.escapeExpression(text));
      return Ember.String.htmlSafe(text);
    }

    return text;
  });

  _exports.default = _default;
});