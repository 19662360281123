define("web-directory/components/group-profile/membership-rule-entry/component", ["exports", "lodash", "web-directory/mixins/logger"], function (_exports, _lodash, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      Component = Ember.Component;

  var _default = Component.extend(_logger.default, {
    classNames: ['group-membership-rule-entry'],
    store: Ember.inject.service(),
    rule: null,
    group: null,
    filter: null,
    editing: false,
    relationships: ['ancestors', 'parent', 'self', 'siblings', 'children', 'descendants'],
    groupedRelationships: [['ancestors', 'parent'], ['self', 'siblings'], ['children', 'descendants']],
    actions: {
      toggleRelationship: function toggleRelationship(relationship) {
        var key = "graph.".concat(relationship);
        this.toggleProperty(key);
        var value = this.get(key);
        var field = this.get('rule');

        var _$last = _lodash.default.last(Ember.get(field, 'value.q')),
            rels = _$last.rels;

        if (value) {
          rels.addObject(relationship);
        } else {
          rels.removeObject(relationship);
        }
      },
      deleteRule: function deleteRule() {
        this.sendAction('deleteRule', this.get('rule'));
      }
    },
    isKeyword: computed('rule.value.q.@each.type', function () {
      var q = this.get('rule.value.q');
      return _lodash.default.head(q).type === 'equals';
    }),
    relationshipGroups: computed('groupedRelationships', 'graph.{self,parent,ancestors,siblings,children,descendants}', function () {
      var _this = this;

      var groupedRelationships = this.get('groupedRelationships');
      return groupedRelationships.map(function (group) {
        return group.map(function (relationship) {
          return {
            relationship: relationship,
            key: "person.graph.".concat(relationship),
            selected: _this.relationshipSelected(relationship)
          };
        });
      });
    }),
    field: computed('group', 'filter.key', function () {
      var key = this.get('filter.key');
      return this.get("group.fieldConfig.membership.".concat(key));
    }),
    person: computed('rule.value.q.@each.value', 'isKeyword', function () {
      if (this.get('isKeyword')) {
        return;
      }

      var q = this.get('rule.value.q');

      var guid = _lodash.default.head(q).guid;

      var oid = _lodash.default.head(q).value;

      if (!guid && !!oid) {
        // We've got a weird case where a guid value was not populated by directory
        // Log and take these errors to directory team so they can migrate
        this.logger.error('Found a group rule entry without a guid value', {
          group: this.get('group.id')
        });
      }

      return this.get('store').findRecord('person', guid || oid);
    }),
    graph: computed('rule.value.q.@each.rels', function () {
      var q = this.get('rule.value.q');
      return this.buildGraphFromRelationships(_lodash.default.head(q).rels);
    }),
    name: computed('person.name', 'isKeyword', 'rule', function () {
      if (this.get('isKeyword')) {
        var q = this.get('rule.value.q');
        return _lodash.default.head(q).value;
      }

      return this.get('person.name');
    }),
    title: computed('person.title', 'isKeyword', 'rule', function () {
      if (this.get('isKeyword')) {
        var q = this.get('rule.value.q');
        return _lodash.default.head(q).key;
      }

      return this.get('person.title');
    }),
    relationshipSelected: function relationshipSelected(relationship) {
      return this.get('graph')[relationship];
    },
    buildGraphFromRelationships: function buildGraphFromRelationships(rels) {
      var graph = {};

      _lodash.default.forEach(rels, function (rel) {
        graph[rel] = true;
      });

      var defaults = {
        ancestors: false,
        parent: false,
        self: false,
        siblings: false,
        children: false,
        descendants: false
      };
      var options = Object.assign({}, defaults, graph);
      return Ember.Object.create(options);
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments); // If this entry fails to load due to the person record
      // not being found, let's log it


      var person = this.get('person');

      if (!person || !person.catch) {
        return;
      }

      person.catch(function (error) {
        _this2.logger.error('Error loading membership rule entry', {
          error: error
        });
      });
    }
  });

  _exports.default = _default;
});