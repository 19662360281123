define("web-directory/initializers/webrtc-sdk-service", ["exports", "web-directory/services/webrtc-sdk", "genesys-cloud-webrtc-sdk"], function (_exports, _webrtcSdk, _genesysCloudWebrtcSdk) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'setupWebrtcSdkService',
    after: 'setupSessionService',
    initialize: function initialize(application) {
      application.register('service:webrtc-sdk', _webrtcSdk.default);
      application.inject('controller', 'webrtc-sdk', 'service:webrtc-sdk');
      application.inject('route', 'webrtc-sdk', 'service:webrtc-sdk');
      application.inject('component', 'webrtc-sdk', 'service:webrtc-sdk');
    }
  };
  _exports.default = _default;
});