define("web-directory/models/user", ["exports", "ember-data", "web-directory/utils/dates"], function (_exports, _emberData, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var UserModel = _emberData.default.Model.extend({
    _id: _emberData.default.attr(),
    lang: _emberData.default.attr(),
    personId: _emberData.default.attr(),
    personGuid: _emberData.default.attr(),
    state: _emberData.default.attr(),
    version: _emberData.default.attr(),
    mt: _emberData.default.attr(),
    email: _emberData.default.attr(),
    welcomeSent: _emberData.default.attr(),
    lastLogin: _emberData.default.attr(),
    clientVersions: _emberData.default.attr(),
    userType: _emberData.default.attr(),
    passwordSaved: _emberData.default.attr(),
    orgId: _emberData.default.attr(),
    intl: Ember.inject.service(),
    person: Ember.computed('personGuid', function () {
      return this.store.find('person', this.get('personGuid'));
    }),
    isAdmin: Ember.computed('userType', function () {
      return this.get('userType') === 'admin';
    }),
    isSuper: Ember.computed('userType', function () {
      return this.get('userType') === 'super';
    }),
    userState: Ember.computed('state', function () {
      return this.get('intl').lookup("item.state.".concat(this.get('state'))).toString();
    }),
    inviteLastSent: Ember.computed('welcomeSent', function () {
      var date = (0, _dates.default)(new Date(this.get('welcomeSent') * 1000));
      return date.format('L');
    })
  });

  UserModel.reopenClass({
    apiEndpoint: 'v2/users'
  });
  var _default = UserModel;
  _exports.default = _default;
});