define("web-directory/components/inbox-panel-v2/share-content/get-shareable-link/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      Component = Ember.Component;

  var _default = Component.extend({
    classNames: ['share-link'],
    intl: inject.service(),
    notification: inject.service(),
    sharingUri: null,
    clipboardInstance: null,
    _addBehavior: function _addBehavior() {
      var _this = this;

      var copyBtn = this.$('.copy-text').get(0);
      var clipboardInstance = new window.Clipboard(copyBtn, {
        text: function text() {
          return _this.get('sharingUri');
        }
      });
      clipboardInstance.on('success', function () {
        Ember.run(function () {
          _this.get('notification').info(null, _this.get('intl').lookup('notification.inboxShare.copyUrl').toString(), {
            tag: 'copy-share-uri',
            type: 'info',
            hideAfter: 8,
            forceBrowserNotification: true
          });
        });
      });
      this.set('clipboardInstance', clipboardInstance);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, this._addBehavior);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('clipboardInstance').destroy();
    }
  });

  _exports.default = _default;
});