define("web-directory/components/group-profile/roles-edit/fixtable/search-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OBDqAlVD",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"search-field-icon\"],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"query\"]]],null,{\"statements\":[[0,\"        \"],[11,\"i\",[]],[15,\"class\",\"pc pc-lg pc-times-circle search-field-reset\"],[15,\"tabindex\",\"0\"],[15,\"role\",\"button\"],[5,[\"action\"],[[28,[null]],\"reset\"]],[13],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"        \"],[11,\"i\",[]],[15,\"class\",\"pc pc-lg pc-icon-magnifying-glass\"],[13],[14],[0,\"\\n\"]],\"locals\":[]}],[14],[0,\"\\n\"],[11,\"div\",[]],[15,\"class\",\"fill-between-floats\"],[15,\"data-t\",\"searchfieldparent\"],[13],[0,\"\\n    \"],[11,\"label\",[]],[15,\"class\",\"hidden\"],[16,\"for\",[34,[[26,[\"elementId\"]],\"-roles-search\"]]],[13],[1,[33,[\"t\"],[\"groups.roles.roleSearch\"],null],false],[14],[0,\"\\n    \"],[1,[33,[\"input\"],null,[[\"id\",\"type\",\"value\",\"placeholder\",\"data-t\"],[[33,[\"concat\"],[[28,[\"elementId\"]],\"-roles-search\"],null],\"text\",[28,[\"query\"]],[28,[\"placeholder\"]],\"input-search\"]]],false],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/group-profile/roles-edit/fixtable/search-field/template.hbs"
    }
  });

  _exports.default = _default;
});