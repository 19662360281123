define("web-directory/services/agent-ui-backend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var Service = Ember.Service,
      inject = Ember.inject;
  var BASE_URI = 'api/v2/apps/agentui';

  var isPanelSettingValid = function isPanelSettingValid() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        type = _ref.type,
        panelId = _ref.panelId;

    var typeValid = type && typeof type === 'string';
    var panelIdValid = panelId && typeof panelId === 'string';
    return typeValid && panelIdValid;
  };

  var _default = Service.extend({
    ajax: inject.service(),
    application: inject.service(),
    logger: inject.service('stash-logger'),
    buildUri: function buildUri(path) {
      return this.get('application').pcV2Uri("".concat(BASE_URI).concat(path));
    },
    getOrganizationDefaultPanelSettings: function getOrganizationDefaultPanelSettings() {
      var _this = this;

      return this.get('ajax').request(this.buildUri('/panels/settings')).then(function (response) {
        if (!response.settings) return {};
        var entries = Object.entries(response.settings).map(function (_ref2) {
          var _ref3 = _slicedToArray(_ref2, 2),
              key = _ref3[0],
              setting = _ref3[1];

          return [key.toLowerCase(), setting];
        });
        var invalidSettings = entries.filter(function (_ref4) {
          var _ref5 = _slicedToArray(_ref4, 2),
              setting = _ref5[1];

          return !isPanelSettingValid(setting);
        });

        if (invalidSettings.length > 0) {
          _this.get('logger').warn('Found invalid org-level panel settings', {
            remoteData: {
              settings: Object.fromEntries(invalidSettings)
            }
          });
        }

        return Object.fromEntries(entries.filter(function (_ref6) {
          var _ref7 = _slicedToArray(_ref6, 2),
              setting = _ref7[1];

          return isPanelSettingValid(setting);
        }));
      });
    }
  });

  _exports.default = _default;
});