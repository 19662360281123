define("web-directory/instance-initializers/screen-recording", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'setupScreenRecordingService',
    initialize: function initialize(instance) {
      var screenRecordingService = instance.lookup('service:screenRecording');
      var sessionService = instance.lookup('service:session');
      screenRecordingService.set('session', sessionService);
    }
  };
  _exports.default = _default;
});