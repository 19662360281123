define("web-directory/components/acd-interactions-panel/interaction-notes/component", ["exports", "web-directory/mixins/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_logger.default, {
    classNames: ['sub-panel', 'interaction-notes'],
    classNameBindings: ['enabled', 'idClassName'],
    attributeBindings: ['role:role', 'tabindex:tabindex'],
    role: 'tabpanel',
    tabindex: '-1',
    idClassName: 'tabpanel-notes',

    /**
     * `true` if the current user can view the chat when clicking on the authors avatar
     */
    canViewChat: false,

    /**
     * `true` if the interaction notes panel can be used and is enabled.
     */
    enabled: false,

    /**
     * The current provisional notes value that the user has inputted in the notes history panel
     */
    notes: null,

    /**
     * An array of the history of notes taken during this interaction. Should be arranged based on endTime.
     */
    notesHistory: null,

    /**
     * `true` if notes is readonly
     */
    readOnly: false,
    actions: {
      /**
       * Triggers when the user either inputs or blurs the textarea component in the markup.
       * @param value the new value of the textarea after it was updated in any capacity.
       */
      onUpdateNotes: function onUpdateNotes(value) {
        this.set('notes', value);
        /**
         * Passed through as a Closure Action
         */

        if (this.updateNotes) {
          this.updateNotes(value);
        } else {
          this.logger.debug('The action `updateNotes` has not been set on the `interaction-notes` component.');
        }
      }
    }
  });

  _exports.default = _default;
});