define("web-directory/components/acd-interactions-panel/interaction/panel-sizer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['interactions-panel-sizer'],
    showTooltip: false,
    setPanelSize: function setPanelSize() {},
    changeScriptSize: function changeScriptSize() {},
    actions: {
      toggleTooltip: function toggleTooltip() {
        this.toggleProperty('showTooltip');
      },
      selectPanelSize: function selectPanelSize(num) {
        var userClicked = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        this.setPanelSize(num, userClicked);
        this.set('showTooltip', false);
      }
    }
  });

  _exports.default = _default;
});