define("web-directory/components/media-providers/video-dropdown/component", ["exports", "popper.js"], function (_exports, _popper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['media-provider-video-dropdown'],
    classNameBindings: ['displayDropdown:display-dropdown:hide'],
    webrtc: Ember.inject.service(),
    mediaProviders: Ember.inject.service(),
    user: null,
    room: null,
    attachTo: null,
    attachClickHandler: false,
    _displayDropdown: true,
    // Default implementation is to just run the provider action
    selectProvider: function selectProvider(provider) {
      Ember.run.once(provider, provider.action, {
        sendNotification: true
      });
      this.send('hide');
    },
    _popper: null,
    _targetEl: null,
    actions: {
      hide: function hide() {
        this.set('_displayDropdown', false);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      Ember.assert(!!this.get('user'), '`user` is required');
      Ember.assert(!!this.get('attachTo'), '`attachTo` is required');
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, this.setup);
    },
    willDestroyElement: function willDestroyElement() {
      if (this._popper) {
        this._popper.destroy();

        this._popper = null;
      }

      var targetEl = this._targetEl;
      var ev = "click.".concat(this.elementId);
      Ember.$(window).off(ev);

      if (targetEl) {
        Ember.$(targetEl).off(ev);
      }

      this._super.apply(this, arguments);
    },
    setup: function setup() {
      var _this = this;

      var targetEl = document.querySelector(this.get('attachTo'));

      if (!targetEl) {
        // Let's wait for a bit and check again...
        // This might happen when we are waiting on asynchronous helpers
        Ember.run.later(function () {
          return _this.setup();
        }, 250);
        return;
      }

      if (this._targetEl) {
        // We've already setup, dont try again
        return;
      }

      this._targetEl = targetEl;
      var popoverEl = this.element;
      this._popper = new _popper.default(targetEl, popoverEl, {
        placement: 'bottom',
        arrowElement: this.$('.media-provider-arrow')[0],
        modifiers: {
          flip: {
            behavior: ['bottom', 'top', 'left']
          },
          preventOverflow: {
            padding: 1
          }
        }
      }); // Attach a click handler to the target so that we can control the dropdown
      // internally instead of externally

      if (this.get('attachClickHandler')) {
        this.set('_displayDropdown', false);
        Ember.$(targetEl).on("click.".concat(this.elementId), Ember.run.bind(this, function () {
          return Ember.run.once(_this, _this.handleClick);
        }));
        Ember.$(window).on("click.".concat(this.elementId), Ember.run.bind(this, this.handleOutsideClick));
      }
    },
    handleClick: function handleClick() {
      var _this2 = this;

      this.toggleProperty('_displayDropdown');
      Ember.run.join(function () {
        return Ember.run.scheduleOnce('afterRender', _this2, function () {
          return _this2._popper && _this2._popper.scheduleUpdate();
        });
      });
    },
    handleOutsideClick: function handleOutsideClick(event) {
      if (!this.get('displayDropdown')) {
        return;
      }

      var target = event.target;
      var isAttachTarget = Ember.$(this.attachTo).is(target);
      var componentParents = Ember.$(target).parent(this.elementId);
      var attachTargetParents = Ember.$(target).parents(this.attachTo);

      if (!isAttachTarget && componentParents.length === 0 && attachTargetParents.length === 0) {
        this.send('hide');
      }
    },
    showingVideo: Ember.computed('webrtc.activeRoom.{showingVideo,isAcdRoom}', 'mediaProviders.usingVideoProvider', function () {
      var showingPurecloudVideo = this.get('webrtc.activeRoom.showingVideo') && !this.get('webrtc.activeRoom.isAcdRoom');
      var showingMediaProvider = this.get('mediaProviders.usingVideoProvider');
      return showingPurecloudVideo || showingMediaProvider;
    }),
    providers: Ember.computed('user', 'room', 'webrtc.activeRoom.oneToOneTarget.presence', function () {
      return this.get('mediaProviders').getVideoProviders(this.get('user'), this.get('room'));
    }),
    displayDropdown: Ember.computed('_displayDropdown', 'attachTo', 'user.profile.content.isOffline', 'showingVideo', 'room', function () {
      var targetEl = this._targetEl;

      if (!targetEl || this.get('showingVideo')) {
        return false;
      }

      var chatRoom = this.get('room');

      if (chatRoom && chatRoom.isGroupRoom()) {
        return this._displayDropdown;
      }

      return this._displayDropdown;
    })
  });

  _exports.default = _default;
});