define("web-directory/instance-initializers/inject-owner", ["exports", "web-directory/components/entity-fields/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(application) {
    _validators.default.reopen(application.ownerInjection());
  }

  var _default = {
    name: 'inject-owner',
    initialize: initialize
  };
  _exports.default = _default;
});