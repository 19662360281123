define("web-directory/components/acd-interactions-panel/interaction-wrapup-codes-copilot/interaction-wrapup-codes-copilot-feedback/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['interaction-wrapup-codes-copilot-feedback'],
    message: '',
    isFeedbackSelected: false,
    feedbackType: '',
    handleInputChange: function handleInputChange(_feedbackType, _val) {},
    actions: {
      onHandlePositiveFeedbackSelect: function onHandlePositiveFeedbackSelect(feedbackType) {
        this.handleInputChange(feedbackType, true);
      },
      onHandleNegativeFeedbackSelect: function onHandleNegativeFeedbackSelect(feedbackType) {
        this.handleInputChange(feedbackType, false);
      },
      onHandleFeedbackRevert: function onHandleFeedbackRevert(feedbackType) {
        this.handleInputChange(feedbackType, undefined);
      }
    }
  });

  _exports.default = _default;
});