define("web-directory/mixins/entity-image-defaults", ["exports", "ember-chat-components/mixins/webrtc-action-utils"], function (_exports, _webrtcActionUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Brings in isOneToOneOfflineOrBusy
  var _default = Ember.Mixin.create(_webrtcActionUtils.default, {
    chat: Ember.inject.service(),
    store: Ember.inject.service(),
    webrtc: Ember.inject.service(),
    session: Ember.inject.service(),
    favorites: Ember.inject.service(),
    geolocation: Ember.inject.service(),
    application: Ember.inject.service(),
    phoneService: Ember.inject.service('phone'),
    mediaProviders: Ember.inject.service(),
    phoneIntegrations: Ember.inject.service(),
    isChatEnabled: Ember.computed.reads('chat.enabled'),
    isWebrtcEnabled: Ember.computed.reads('webrtc.enabled'),
    isGeolocationEnabled: Ember.computed.reads('geolocation.isEnabled'),
    phoneServiceEnabled: Ember.computed.reads('phoneService.enabled'),
    phoneAvailable: Ember.computed.reads('phoneService.phoneAvailable'),
    self: Ember.computed.reads('session.person'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('applicationUrlPrefix', this.get('application.urlprefix') || ''); // Need user details to compute the 'useMediaProviders' property, which is only used by the {{entity-contact-group}} component.

      if (this.get('entity.type') === 'person' && this.classNames.includes('entity-contact-group')) {
        this.get('store').findRecord('public-api-user', this.get('entity.id'), {
          adapterOptions: {
            expand: 'authorization'
          }
        }).then(function (user) {
          return _this.set('user', user);
        });
      }
    },
    callEntity: function callEntity(entity) {
      this.get('phoneService').makeCall(entity);
    },
    toggleFavorite: function toggleFavorite(entity) {
      this.get('favorites').toggleFavorite(entity);
    },
    mediaProviderComponent: 'media-providers/video-dropdown',
    videoProviders: Ember.computed('user', 'user.profile', function () {
      return this.get('mediaProviders').getProvidersForUser('video', this.get('user'));
    }),
    useMediaProviders: Ember.computed('videoProviders.@each.haveAccess', function () {
      var providers = this.get('videoProviders').filter(function (provider) {
        return provider.get('haveAccess');
      });
      return providers.length > 1;
    }),
    integrationBadgesComponent: 'integration-badges',
    useIntegrationBadges: true
  });

  _exports.default = _default;
});