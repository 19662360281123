define("web-directory/instance-initializers/reopen-linkview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'reopen-linkview',
    initialize: function initialize(instance) {
      Ember.LinkComponent.reopen({
        _invoke: function _invoke() {
          var sidebar = instance.lookup('service:sidebar');
          sidebar.ensureRouteVisibility();
          return this._super.apply(this, arguments);
        }
      });
    }
  };
  _exports.default = _default;
});