define("web-directory/serializers/conversation-v2", ["exports", "ember-data", "lodash"], function (_exports, _emberData, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      var data = {};
      payload.links = {
        recordings: "/api/v2/conversations/".concat(payload.id, "/recordings")
      };
      data[primaryModelClass.modelName] = payload;
      return this._super(store, primaryModelClass, data, id, requestType);
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      var data = {};
      var type = primaryModelClass.modelName;

      if (payload.entities) {
        data[type] = _lodash.default.map(payload.entities, function (convo) {
          convo.links = {
            recordings: "/api/v2/conversations/".concat(convo.id, "/recordings")
          };
          return convo;
        });
        payload = data;
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});