define("web-directory/adapters/grant", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend({
    createRecord: function createRecord(store, type, snapshot) {
      return this.ajax("/platform/api/v2/authorization/subjects/".concat(snapshot.record.get('subjectId'), "/divisions/").concat(snapshot.record.get('division.id'), "/roles/").concat(snapshot.record.get('role.id'), "?subjectType=PC_GROUP"), 'POST', {
        dataType: 'text'
      });
    },
    deleteRecord: function deleteRecord(store, type, snapshot) {
      return this.ajax("/platform/api/v2/authorization/subjects/".concat(snapshot.record.get('subjectId'), "/divisions/").concat(snapshot.record.get('division.id'), "/roles/").concat(snapshot.record.get('role.id')), 'DELETE');
    },
    saveBulk: function saveBulk(roleId, subjectIds, divisionIds) {
      return this.ajax("/platform/api/v2/authorization/roles/".concat(roleId, "?subjectType=PC_GROUP"), 'POST', {
        data: {
          subjectIds: subjectIds,
          divisionIds: divisionIds
        }
      });
    },
    ajaxOptions: function ajaxOptions(url, type, options) {
      // get the default RESTAdapter 'ajaxOptions'
      var hash = this._super(url, type, options);

      if (type === 'DELETE' || type === 'POST') {
        hash.dataType = 'text';
      }

      return hash;
    },
    ajaxSuccess: function ajaxSuccess(jqXHR, data) {
      if (typeof data === 'string') {
        // return an empty object so the Serializer
        // handles it correctly
        return {};
      } else {
        return data;
      }
    },
    normalizeErrorResponse: function normalizeErrorResponse(status, headers, payload) {
      // force json-api error format
      var data = {};
      data.errors = [payload];
      return this._super(status, headers, data);
    }
  });

  _exports.default = _default;
});