define("web-directory/media-providers/purecloud", ["exports", "web-directory/media-providers/provider"], function (_exports, _provider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PROVIDER_ID = void 0;
  var PROVIDER_ID = 'purecloud';
  _exports.PROVIDER_ID = PROVIDER_ID;

  var _default = _provider.default.extend({
    intl: Ember.inject.service(),
    chat: Ember.inject.service(),
    webrtc: Ember.inject.service(),
    sidebar: Ember.inject.service(),
    application: Ember.inject.service(),
    mediaProviders: Ember.inject.service(),
    id: PROVIDER_ID,
    data: {},
    type: 'video',
    component: 'webrtc-panel',
    init: function init() {
      this._super.apply(this, arguments);

      this.get('webrtc.activeRoom.showingVideo');
    },
    launchVideoImage: Ember.computed('application.urlprefix', function () {
      return "".concat(this.get('application.urlprefix'), "assets/images/svg/Genesys_Cloud-Expanded-landscape-color.svg");
    }),
    haveAccess: Ember.computed('user.profile.content', 'room', 'webrtc.videoCallCapable', function () {
      if (!this.get('webrtc.videoCallCapable')) {
        return false;
      }

      var user = this.get('room.oneToOneTarget') || this.get('user.profile.content');

      if (!user) {
        // GC video should just generally always be available
        return true;
      }

      return !user.get('isOffline');
    }),
    action: function action(options) {
      var jid = this.getJid(options);
      this.get('application').openChatRoomWithVideo(jid, false);
    },
    label: Ember.computed(function () {
      return this.get('intl').t('mediaProviders.purecloud.label').toString();
    }),
    showingVideoChanged: Ember.observer('webrtc.activeRoom.showingVideo', function () {
      if (!this.get('webrtc.activeRoom.showingVideo') && this.get('isActive')) {
        this.set('mediaProviders.activeProvider', null);
      }
    })
  });

  _exports.default = _default;
});