define("web-directory/helpers/has-group-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      var group = params[0];
      return !!Ember.get(group, 'contactInfo.groupNumber.firstObject.rel.e164');
    }
  });

  _exports.default = _default;
});