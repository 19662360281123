define("web-directory/templates/region-chooser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ty3m6Ht4",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"logo-container\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"logo\"],[13],[14],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[11,\"div\",[]],[15,\"class\",\"panel-container\"],[13],[0,\"\\n    \"],[1,[33,[\"region-chooser\"],null,[[\"desktopRegionId\",\"chooseRegion\"],[[28,[\"desktopRegionId\"]],[33,[\"action\"],[[28,[null]],\"chooseRegion\"],null]]]],false],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[11,\"div\",[]],[15,\"class\",\"brand-footer\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"genesys-logo-container\"],[13],[14],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"brand-footer-text\"],[13],[1,[33,[\"t\"],[\"about.copyright\"],[[\"currentYear\"],[[28,[\"applicationService\",\"currentYear\"]]]]],false],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/templates/region-chooser.hbs"
    }
  });

  _exports.default = _default;
});