define("web-directory/components/email-recipient-input/component", ["exports", "ember-purecloud-components/utils/email", "web-directory/utils/keystroke"], function (_exports, _email, _keystroke) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Many keycode number shortcuts to reuse easily throughout the file
   */
  var SELECT_KEYSTROKES = ['Enter', 'Tab'];
  /**
   * The minimum number of search characters required to initiate a search request
   */

  var DEFAULT_MIN_SEARCH_CHARACTERS_REQUIRED = 2;
  /**
   * The max amount of tags that can be applied.
   */

  var MAX_TAGS = 50;
  /**
   * The tag type to be used for all of the input email selectors
   */

  /**
   * Type extending JQuery to include the bootstrap tags components
   */

  /**
   * Typing for the tags input api
   */

  /**
   * Bare bones type for the incoming recipients. Only typing what is needed at this time.
   */

  /**
   * Inspired by components/call-input.js
   *
   * The principal internal data structure is the "tagsinput" property, which is initially populated according to the
   * contents of the "recipients" array of objects.  Each recipient has an email string property.
   * As the user adds/removes tags, the recipients array is updated with each addition/removal.
   */
  var _default = Ember.Component.extend({
    classNames: ['create-email-input', 'call-input'],
    permissions: Ember.inject.service('permissions'),

    /**
     * @attribute
     *
     * The currently authenticated user object and data
     */
    authUser: null,

    /**
     * @attribute
     *
     * The interaction data that this input is in context of
     */
    interaction: null,

    /**
     * @attribute
     *
     * The placeholder text to show up on the main input
     */
    searchPlaceholder: '',

    /**
     * @attribute
     * @mutable (mut recipientsStr)
     *
     * (?) value containing the email address in the input. Used for comparison checks
     */
    recipientsStr: '',

    /**
     * @attribute
     * @mutable (mut recipients)
     *
     * The array of recipients to be shown in this input as added to the interaction.
     * Todo: look into removing the EmberArray casting
     */
    recipients: Ember.A([]),

    /**
     * @attribute
     *
     * `true` if the main input should autofocus once the component is finished initializing
     */
    autofocus: false,

    /**
     * @permission
     *
     * `true` if the user has the corresponding permission
     */
    searchRelate: Ember.computed.readOnly('permissions.hasExternalContactsViewContactAccess'),

    /**
     * `true` if the dropdown is currently open to the user
     */
    open: false,

    /**
     * `true` if the results of the email dropdown should be showing.
     */
    showResults: Ember.computed.and('searchRelate', 'open'),

    /**
     * The list of filters we should allow in the target selection dropdown
     */
    includeFilters: ['external'],

    /**
     * The current value being searched on
     */
    searchValue: '',

    /**
     * reference to the tags bootstrap input rendered in this component
     */
    tagsinput: null,

    /**
     * abort controller to use for unsubscribing from document listeners for the "off-click"
     * implementation
     */
    abortController: null,
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this._clearThenAddRecipientTags();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._setupInput();
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);

      var tagsElement = this.$('#emailRecipientSelect');

      if (tagsElement) {
        tagsElement.tagsinput('destroy');
      }

      var controller = this.get('abortController');
      controller.abort();
    },

    /**
     * sets up the input by creating it (if it hasn't been created yet), and applying listeners to said input
     * for adding and removing tags.
     */
    _setupInput: function _setupInput() {
      var _this = this,
          _this$get;

      var controller = new AbortController();
      document.addEventListener('click', function (event) {
        if (!_this.get('isDestroyed') && !_this.get('isDestroying') && !_this.element.contains(event.target)) {
          _this.set('open', false);
        }
      }, {
        signal: controller.signal
      });
      this.set('abortController', controller);

      if (!this.get('tagsinput')) {
        this._initTagInput();
      }

      this._clearThenAddRecipientTags();

      var $input = (_this$get = this.get('tagsinput')) === null || _this$get === void 0 ? void 0 : _this$get.$input;

      if (this.get('autofocus')) {
        $input.focus();
      }

      $input.bind('keydown change input', function (event) {
        return Ember.run(function () {
          return _this._searchValueKeyupHandler(event);
        });
      }); // Show results list on focus of input

      $input.bind('focus', function () {
        return Ember.run(function () {
          var value = $input.val();

          if (value.length >= DEFAULT_MIN_SEARCH_CHARACTERS_REQUIRED) {
            _this.set('searchValue', value);

            _this.set('open', true);
          }
        });
      }); // Track user input (addresses typed or copied/pasted by the user, in addition to those tagged from a search)

      $input.bind('change', function () {
        return Ember.run(function () {
          return _this.set('recipientsStr', $input.val());
        });
      }); // have to reference to base component on which the `tagsinput` was initiated on instead
      // of the actual tagsinput reference.

      this.$('#emailRecipientSelect').on('itemRemoved', function (event) {
        return Ember.run(function () {
          return _this._getRecipientEmails().forEach(function (email) {
            if (event.item && email === event.item.id) {
              var recipients = _this.get('recipients');

              var recipient = recipients.findBy('email', email);

              var recipientsStr = _this.get('recipientsStr');

              if (recipient) {
                recipients.removeObject(recipient);

                if (recipientsStr && recipient.email.trim() === _this.get('recipientsStr').trim()) {
                  _this.set('recipientsStr', '');
                }
              }
            }
          });
        });
      });
    },

    /**
     * initializes the bootstrap tag input element onto the `select` element
     */
    _initTagInput: function _initTagInput() {
      var $tagsinput = this.$('#emailRecipientSelect').tagsinput({
        itemValue: 'id',
        itemText: 'value',
        maxTags: MAX_TAGS
      });
      this.set('tagsinput', $tagsinput[0]);
    },

    /**
     * clears all of the tags that are within the tagsinput
     */
    _clearTags: function _clearTags() {
      var input = this.get('tagsinput');

      if (input) {
        input.removeAll();
      }
    },

    /**
     * retrieves all of the recipients provided into the component and maps them into an
     * array of email strings to be used in the tags input
     *
     * @returns an array of the recipients by their email value
     */
    _getRecipientEmails: function _getRecipientEmails() {
      return (this.get('recipients') || []).map(function (recipient) {
        return recipient.email;
      });
    },

    /**
     * Hides the results list if an escape key is pressed or if the search value does not meet
     * the requirements needed to initiate a search. Otherwise, it keeps the results list hidden.
     *
     * @param event browser event
     */
    _searchValueKeyupHandler: function _searchValueKeyupHandler(event) {
      var value = event.target.value;

      if (SELECT_KEYSTROKES.includes(event.key) && (0, _email.isValidEmailAddress)(value)) {
        this._addTag({
          id: value,
          value: value
        });

        this._clearSearchInputValue();
      } else if (!_keystroke.default.isEscape(event) && value.length >= DEFAULT_MIN_SEARCH_CHARACTERS_REQUIRED) {
        this.set('searchValue', value);
        this.set('open', true);
      } else {
        this.set('open', false);
      }
    },

    /**
     * Adds a new data object to the input as a tag object to show visually to the user
     *
     * @param tag the new data to add as a tag in the input
     */
    _addTag: function _addTag(tag) {
      var input = this.get('tagsinput');

      if (input) {
        var tagArray = input.itemsArray;
        var alreadyTagged = tagArray.some(function (t) {
          return t.id === tag.id;
        });

        if (!alreadyTagged) {
          input.add(tag);
        }

        var recipients = this.get('recipients');
        var alreadyHasRecipient = recipients.isAny('email', tag.id);

        if (!alreadyHasRecipient && Ember.get(recipients, 'length') < MAX_TAGS) {
          recipients.pushObject({
            email: tag.id,
            externalContactId: tag.externalContactId
          });
        }
      } else {
        console.warn('The tagsinput has not been initialized');
      }
    },

    /**
     * Clears out all of the added tags into the component and then readds the tags that should be applied.
     * This is needed due to the tag input component having some wonky behavior with adding the same email more than
     * once. This also allows for a refresh from the initial state of the component and applies any new recipients that
     * may have been added from a level above this component.
     */
    _clearThenAddRecipientTags: function _clearThenAddRecipientTags() {
      var _this2 = this;

      this._clearTags();

      this._getRecipientEmails().forEach(function (recipientEmail) {
        return _this2._addTag({
          id: recipientEmail,
          value: recipientEmail
        });
      });
    },

    /**
     * Adds the chosen recipient and creates an email tag for.
     *
     * @param externalContact the external contact data to add to the interaction
     */
    _addRecipientAndEmailTagFor: function _addRecipientAndEmailTagFor(externalContact) {
      var _this$get2;

      var recipients = (_this$get2 = this.get('recipients')) !== null && _this$get2 !== void 0 ? _this$get2 : [];

      if (Ember.get(recipients, 'length') < MAX_TAGS) {
        var _Ember$get, _Ember$get2;

        var email = (_Ember$get = Ember.get(externalContact, 'email')) !== null && _Ember$get !== void 0 ? _Ember$get : Ember.get(externalContact, 'bestEmailAddress');
        var externalContactId = (_Ember$get2 = Ember.get(externalContact, 'id')) !== null && _Ember$get2 !== void 0 ? _Ember$get2 : undefined;

        this._addTag({
          id: email,
          value: email,
          externalContactId: externalContactId
        });

        this._clearSearchInputValue();
      }
    },

    /**
     * Clears the search input value. Typically used when a data item has been selected and we
     * want to reset the search input so the user can continue to search for a new contact.
     */
    _clearSearchInputValue: function _clearSearchInputValue() {
      this.set('open', false);
      this.set('recipientsStr', '');
      this.set('searchValue', '');
      var tagsinput = this.get('tagsinput');

      if (tagsinput) {
        tagsinput.$input.val('');
      }
    },

    /**
     * Handles the selection of an external contact from the template
     *
     * @param externalContact external contact to be added to interaction
     */
    _externalContactSelected: function _externalContactSelected(externalContact) {
      if (Ember.get(externalContact, 'email') || Ember.get(externalContact, 'hasEmailAddress')) {
        this._addRecipientAndEmailTagFor(externalContact);

        this._clearSearchInputValue();

        var tagsinput = this.get('tagsinput');

        if (tagsinput) {
          tagsinput.$input.focus();
        }
      }
    },
    actions: {
      /**
       * Invoked when a user adds an external contact to the interaction
       *
       * @param externalContact the external contact data to add to interaction
       * @param event object provided by browser for event handling
       */
      onExternalContactSelected: function onExternalContactSelected(externalContact) {
        this._externalContactSelected(externalContact);
      }
    }
  });

  _exports.default = _default;
});