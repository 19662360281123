define("web-directory/components/acd-interactions-panel/interaction-transfer/component", ["exports", "web-directory/services/phone-integrations"], function (_exports, _phoneIntegrations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['interaction-transfer'],
    session: Ember.inject.service(),
    permissions: Ember.inject.service(),
    phoneIntegrations: Ember.inject.service(),
    actions: {
      doBlindTransfer: function doBlindTransfer(integrationKey) {
        Ember.run.throttle(this, this._doBlindTransfer, integrationKey, 500);
      },
      doConsultTransfer: function doConsultTransfer(integrationKey) {
        Ember.run.throttle(this, this._doConsultTransfer, integrationKey, 500);
      },
      selectSearchTab: function selectSearchTab(tab) {
        this._setActiveTab(tab);
      }
    },
    searchTabs: Ember.computed('phoneIntegrations.integrations', function () {
      return this.get('phoneIntegrations').getIntegrationTabs();
    }),
    hasTabs: Ember.computed('searchTabs', function () {
      var tabs = this.get('searchTabs');
      return tabs && tabs.length > 0;
    }),
    activeIntegrationTab: _phoneIntegrations.GENESYS_SEARCH_TAB,
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments); // Close toggle if we're switching between interactions


      if (this.get('previousInteractionId') && this.get('showDropdown')) {
        if (this.get('interaction.id') !== this.get('previousInteractionId')) {
          this._resetDropdown();
        }
      }

      this.set('previousInteractionId', this.get('interaction.id'));

      this._resetState();
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this._resetDropdown();
    },
    transferToList: null,
    interaction: null,
    showDropdown: null,
    consult: false,
    previousInteractionId: null,
    disableButton: Ember.computed.lt('transferToList.length', 1),
    _resetTabs: function _resetTabs() {
      // reset the active tab to genesys
      var hasTabs = this.get('hasTabs');

      if (hasTabs) {
        this._setActiveTab(_phoneIntegrations.GENESYS_SEARCH_TAB);
      }
    },
    _doBlindTransfer: function _doBlindTransfer(integrationKey) {
      this.sendAction('doBlindTransfer', integrationKey);

      this._resetDropdown();
    },
    _doConsultTransfer: function _doConsultTransfer(integrationKey) {
      this.sendAction('doConsultTransfer', integrationKey);

      this._resetDropdown();
    },
    _resetDropdown: function _resetDropdown() {
      this.triggerAction({
        action: 'resetDropdown'
      });
    },
    _setActiveTab: function _setActiveTab(tab) {
      this.set('activeIntegrationTab', tab);
    },
    _resetState: function _resetState() {
      this._resetTabs();

      this.get('transferToList').clear();
    }
  });

  _exports.default = _default;
});