define("web-directory/components/infinite-scroll/component", ["exports", "web-directory/mixins/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_logger.default, {
    classNames: ['infinite-scroll'],
    classNameBindings: ['hideScrollbars'],

    /**
     * @Input
     * `true` if the component should be showing a loading state
     */
    loading: false,

    /**
     * `true` if the component should not invoke any infinite scroll
     * interactions
     */
    disabled: false,

    /**
     * `true` if the scrollbar display should be visually hidden
     */
    hideScrollbars: false,

    /**
     * The amount of space in pixels that when the user scrolls within
     * it will trigger the infinite scroll event for the parent to
     * react to.
     * !! If possible do not override this value with an inputted value.
     * The functionality and UX should be consistent so if the threshold
     * needs to increase it should increase everywhere.
     */
    scrollBottomThreshold: 100,

    /**
     * Lifecycle hook triggered when the element is rendered and inserted
     * into the DOM
     */
    didInsertElement: function didInsertElement() {
      var _this = this;

      var element = this.get('element');

      element.onscroll = function () {
        _this.onScroll();
      };
    },

    /**
     * Invoked when the infinite scroll component has detected scrolling
     * behavior within its main element
     */
    onScroll: function onScroll() {
      var element = this.get('element');
      var scrollBottom = element.scrollTop + element.clientHeight;
      var scrollThreshold = scrollBottom + this.get('scrollBottomThreshold');

      if (scrollThreshold >= element.scrollHeight) {
        this.checkInfiniteScroll();
      }
    },

    /**
     * Checks if infinite scrolling can be invoked based on other factors and properties
     */
    checkInfiniteScroll: function checkInfiniteScroll() {
      if (!this.get('loading') && !this.get('disabled')) {
        this.infiniteScroll();
      }
    },

    /**
     * @Action
     * Invoked when the scroll container has reached the scroll threshold to load more data
     */
    infiniteScroll: function infiniteScroll() {
      Ember.Logger.warn('Infinite scroll event closure action has not been set up.');
    }
  });

  _exports.default = _default;
});