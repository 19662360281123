define("web-directory/mixins/authenticated-authorized-route-mixin", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_authenticatedRouteMixin.default, {
    application: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var result = this._super(transition);

      if (transition.isActive) {
        // Authenticated by Simple Auth
        result = Ember.RSVP.resolve(result).then(function () {
          // Authorizing the route
          if (!_this.get('session.hasAppAccess')) {
            transition.abort();

            _this.transitionTo('no-access');

            return Ember.RSVP.reject();
          }

          return true;
        });
      }

      return result;
    }
  });

  _exports.default = _default;
});