define("web-directory/components/region-chooser/component", ["exports", "web-directory/utils/net"], function (_exports, _net) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed,
      Component = Ember.Component;

  var _default = Component.extend({
    classNames: ['panel', 'region-chooser'],
    intl: inject.service(),
    chooseRegion: null,
    desktopRegionId: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!this.get('desktopRegionId')) {
        // Always default just in case consumer forgets to pass in
        this.set('desktopRegionId', 'global');
      }
    },
    regions: computed('intl.locale', 'intl.loaded', function () {
      var intlService = this.get('intl');

      var isTranslatable = function isTranslatable(key) {
        return intlService.get('loaded') && intlService.exists(key);
      }; // Attempt to translate and sort the regions


      return _net.PUBLIC_ENVS.map(function (_ref) {
        var id = _ref.id,
            desktopId = _ref.desktopId,
            translationKey = _ref.translationKey,
            envStatus = _ref.envStatus;
        return {
          desktopId: desktopId,
          label: "".concat(isTranslatable(translationKey) ? intlService.t(translationKey) : id).concat(envStatus === 'beta' ? intlService.t('components.region.betaSuffix') : '')
        };
      }).sort(function (env1, env2) {
        return env1.label.localeCompare(env2.label);
      });
    })
  });

  _exports.default = _default;
});