define("web-directory/components/entity-fields/weblink-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f7TrW8Dk",
    "block": "{\"statements\":[[11,\"div\",[]],[16,\"class\",[34,[\"field-value values \",[28,[\"entry\",\"_id\"]]]]],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"canEdit\"]]],null,{\"statements\":[[0,\"        \"],[11,\"label\",[]],[16,\"for\",[34,[[26,[\"elementId\"]],\"-link\"]]],[15,\"class\",\"hidden\"],[13],[1,[33,[\"t\"],[\"entity.fieldTypes.webLink.name\"],null],false],[14],[0,\"\\n        \"],[1,[33,[\"input\"],null,[[\"id\",\"value\",\"classNames\",\"class\"],[[33,[\"concat\"],[[28,[\"elementId\"]],\"-link\"],null],[28,[\"entry\",\"value\"]],\"form-control\",[33,[\"unless\"],[[28,[\"isValid\"]],\"validation-error\"],null]]]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"        \"],[11,\"span\",[]],[15,\"class\",\"url\"],[13],[0,\"\\n            \"],[1,[33,[\"anchor-tag\"],null,[[\"value\",\"title\"],[[28,[\"entry\",\"value\"]],[28,[\"entry\",\"value\"]]]]],false],[0,\"\\n        \"],[14],[0,\"\\n\"]],\"locals\":[]}],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/entity-fields/weblink-field/template.hbs"
    }
  });

  _exports.default = _default;
});