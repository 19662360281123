define("web-directory/services/coaching", ["exports", "lodash", "web-directory/models/coaching-appointment", "web-directory/utils/dates"], function (_exports, _lodash, _coachingAppointment, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var inject = Ember.inject,
      Service = Ember.Service,
      computed = Ember.computed;

  var _default = Service.extend({
    application: inject.service(),
    ajax: Ember.inject.service(),
    permissions: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    inbox: Ember.inject.service(),
    notification: Ember.inject.service(),
    host: computed.reads('application.purecloudV2BaseUri'),
    namespace: 'api/v2',
    pageNumber: 1,
    pageSize: 500,
    totalItems: 0,
    itemsPerPageOptions: [1, 2, 5, 10, 25, 50, 500],
    activeCoachingModalId: null,
    getPageNumber: computed('pageNumber', function () {
      return this.get('pageNumber');
    }),
    getTotalItems: computed('totalItems', function () {
      return this.get('totalItems');
    }),
    getPageSize: computed('pageSize', function () {
      return this.get('pageSize');
    }),
    patchCoachingNotification: function patchCoachingNotification(notification, markedAsRead) {
      var notificationId = notification.id;
      var url = 'api/v2/coaching/notifications/' + notificationId;
      var fullUrl = this.get('application').pcUri(url);
      return this.get('ajax').patch(fullUrl, {
        data: {
          markedAsRead: markedAsRead
        }
      });
    },

    /**
     *
     * @param {*} expand - (string) Field to expand
     */
    getCoachingNotifications: function getCoachingNotifications(expand) {
      var _this = this;

      var url = 'api/v2/coaching/notifications?pageSize=' + this.pageSize + '&pageNumber=' + this.pageNumber;

      if (expand) {
        url += '&expand=' + expand;
      }

      return this.get('ajax').ajaxGet(this.get('application').pcUri(url)).then(function (response) {
        _this.set('totalItems', response.total);

        if (response.total === 0) {
          _this.set('pageNumber', 0);
        }

        return _lodash.default.map(response.entities, function (item) {
          return _coachingAppointment.default.create(item);
        }).filter(function (item) {
          return item.status !== "Completed" && !(item.markedAsRead === true && item.actionType === "Delete");
        });
      }).catch(function (reason) {
        Ember.Logger.warn('Failed to retrieve notifications:', {
          reason: reason
        });
      });
    },
    getCoachingAppointment: function getCoachingAppointment(appointmentId) {
      return this.get('ajax').ajaxGet(this.get('application').pcUri('/api/v2/coaching/appointments/' + appointmentId));
    },
    getAttendees: function getAttendees(attendeeIds) {
      var url = 'api/v2/users?';

      var _iterator = _createForOfIteratorHelper(attendeeIds),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var id = _step.value;
          url += 'id=' + id + '&';
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      url = url.slice(0, -1);
      return this.get('ajax').ajaxGet(this.get('application').pcUri(url));
    },
    changeNotificationPageNumber: function changeNotificationPageNumber(pageNumber) {
      this.set('pageNumber', pageNumber);
    },
    changeNotificationPageSize: function changeNotificationPageSize(pageSize) {
      this.set('pageSize', pageSize);
      this.set('pageNumber', 1);
    },
    changeCoachingNotificationReadState: function changeCoachingNotificationReadState(notification, read) {
      notification.set('markedAsRead', read);
      var url = this.get('application').pcUri("api/v2/coaching/notifications/".concat(notification.id));
      var data = {
        markedAsRead: read
      };
      this.get('ajax').patch(url, {
        data: data
      });
    },
    getFormattedDate: function getFormattedDate(dateString) {
      var date = (0, _dates.default)(dateString);
      var locale = this.get('intl.isoLocale');
      return date.locale(locale).format('LL');
    },
    getFormattedStartTime: function getFormattedStartTime(dateString) {
      var date = (0, _dates.default)(dateString);
      var locale = this.get('intl.isoLocale');
      return date.locale(locale).format('HH:mm');
    },
    getFormattedEndTime: function getFormattedEndTime(dateString, lengthInMinutes) {
      var date = (0, _dates.default)(dateString).add(lengthInMinutes, 'minutes');
      var locale = this.get('intl.isoLocale');
      return date.locale(locale).format('HH:mm');
    },
    updatePaginationOnCreate: function updatePaginationOnCreate() {
      var notifications = this.get('inbox.coachingAppointments');
      this.set('totalItems', notifications.length);
      this.set('pageNumber', 1);
    },
    updatePaginationOnDelete: function updatePaginationOnDelete(notification) {
      var notifications = this.get('inbox.coachingAppointments');

      if (notification) {
        this.get('inbox').removeCoachingNotification(notification);
      }

      if (notifications.length > 0) {
        this.set('totalItems', this.get('totalItems') - 1);
      } else {
        this.set('totalItems', 0);
        this.set('pageNumber', 0);
      }

      var pageNumber = this.get('pageNumber');
      var noOfPages = Math.ceil(notifications.length / this.get('pageSize'));

      if (pageNumber > 1 || noOfPages > 1) {
        noOfPages = Math.ceil(this.get('totalItems') / this.get('pageSize')); // This check here determines if the new No. of pages is less than the current page number
        // If so, this means that as a result of a deletion, there is one less page of results
        // So set the active page to one less than it currently is

        if (noOfPages < this.get('pageNumber')) {
          this.changeNotificationPageNumber(this.get('pageNumber') - 1);
        }
      }
    },
    openCoachingAppointmentModal: function openCoachingAppointmentModal(appointmentId) {
      var _this2 = this;

      this.set('activeCoachingModalId', appointmentId);
      Ember.run.next(function () {
        return _this2.populateDataForCoachingModal({
          appointment: {
            id: appointmentId
          }
        });
      });
    },
    populateDataForCoachingModal: function populateDataForCoachingModal(notification) {
      var _this3 = this;

      var userPermissions = this.get('permissions.donutPermissions');
      var sessUser = this.get('session.user');
      var user = {
        id: sessUser.id,
        name: sessUser.data.name
      };
      var token = this.get('session.accessToken');
      var baseUri = "".concat(window.location.origin).concat(this.get('application').pcUri('api/v2'));

      if (notification) {
        // Grab the wizard and fill in the details
        var modal = $('wem-coaching-modal');

        if (modal.length > 0) {
          var wizard = modal[0];
          wizard.currentUser = user;
          wizard.currentUserPermissions = userPermissions;
          wizard.featureToggles = this.get('session.enabledFeatures');
          var coaching = this;

          var _notification = this.get('notification');

          var intl = this.get('intl'); // We don't need to remove these listeners; setting the activeId to null removes it from the DOM.

          wizard.addEventListener('modalClosed', function () {
            return coaching.set('activeCoachingModalId', null);
          });
          wizard.addEventListener('saved', function (res) {
            var status = 200;

            if (res.detail && res.detail.status) {
              status = res.detail.status;
            }

            switch (status) {
              case 200:
                {
                  var appts = _this3.get('inbox.coachingAppointments');

                  var currentNotification = appts.find(function (n) {
                    return n.appointment.id === res.detail.parsedBody.id;
                  });
                  currentNotification.set('isOverdue', res.isOverdue);
                  coaching.set('activeCoachingModalId', null); // A pagination update will not need to be called here as this will be handled by the hawk message post appt change

                  break;
                }

              case 202:
                {
                  coaching.set('activeCoachingModalId', null);
                  break;
                }

              case 409:
                {
                  var title = intl.t('notification.inbox.coachingAppointment.toast.conflict.title');
                  var message = intl.t('notification.inbox.coachingAppointment.toast.conflict.message');

                  _notification.error(title, message);

                  break;
                }

              default:
                {
                  var _title = intl.t('notification.inbox.coachingAppointment.toast.badrequest.title');

                  var _message = intl.t('notification.inbox.coachingAppointment.toast.badrequest.message');

                  _notification.error(_title, _message);
                }
            }
          });
          wizard.useDefaultCoachingService(baseUri, token).then(function () {
            wizard.loadAppointment(_this3.activeCoachingModalId);
          });
        }
      }
    }
  });

  _exports.default = _default;
});