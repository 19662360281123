define("web-directory/components/video-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nFcPMZFu",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"mediaProviders\",\"enabled\"]]],null,{\"statements\":[[0,\"    \"],[1,[33,[\"webrtc-panel\"],null,[[\"class\"],[[33,[\"if\"],[[28,[\"hideWebRTCPanel\"]],\"hide\"],null]]]],false],[0,\"\\n    \"],[1,[33,[\"zoom-panel\"],null,[[\"provider\",\"class\"],[[28,[\"activeProvider\"]],[33,[\"if\"],[[33,[\"not\"],[[28,[\"showZoomPanel\"]]],null],\"hide\"],null]]]],false],[0,\"\\n    \"],[1,[33,[\"teams-panel\"],null,[[\"provider\",\"class\"],[[28,[\"activeProvider\"]],[33,[\"if\"],[[33,[\"not\"],[[28,[\"showTeamsPanel\"]]],null],\"hide\"],null]]]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"    \"],[1,[26,[\"webrtc-panel\"]],false],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/video-panel/template.hbs"
    }
  });

  _exports.default = _default;
});