define("web-directory/mixins/acd-attachment-url-mixin", ["exports", "ember-purecloud-components/utils/net"], function (_exports, _net) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;
  var INTERNAL_DL_MATCHER = /\/api\/v[12]\/downloads\/([\w-+]+).*$/i;

  var _default = Ember.Mixin.create({
    session: inject.service(),
    logger: inject.service('stash-logger'),
    application: inject.service(),
    embeddedUrl: function embeddedUrl(imageUrl) {
      var url = imageUrl;
      var isLocal = this.get('application.runtime.local');

      if (!url || isLocal) {
        return url;
      }

      try {
        // Only transform internal PC urls
        if (!_net.default.isExternalDomain(url)) {
          var matches = INTERNAL_DL_MATCHER.exec(url);

          if (matches && matches[1]) {
            var id = matches[1];
            return "".concat(this.get('application').pcV2Uri(['api/v2/apps/downloads', id]), "?contentDisposition=INLINE");
          }
        }
      } catch (error) {
        // Failed to check URL, returning unmodified url below
        this.get('logger').info("ACD embeddedUrl", {
          error: error,
          imageUrl: url
        });
        return url;
      }

      return url;
    }
  });

  _exports.default = _default;
});