define("web-directory/utils/escape", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = escape;
  _exports.unescape = unescape;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var HTMLChars = [{
    char: '&',
    escaped: '&amp;'
  }, {
    char: '<',
    escaped: '&lt;'
  }, {
    char: '>',
    escaped: '&gt;'
  }, {
    char: '"',
    escaped: '&quot;'
  }, {
    char: "'",
    escaped: '&apos;'
  }];

  function regExpEscape(literal_string) {
    return literal_string.replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, '\\$&');
  }

  function escape(obj) {
    if (obj === null || typeof obj === 'function') {
      return null;
    }

    if (typeof obj === 'string') {
      var escapedString = Ember.Handlebars.Utils.escapeExpression(obj); // [RELATE-2592] allow single quotes to make it through the escape pass

      return escapedString.replace(/&#x27;/g, "'");
    }

    if (_typeof(obj) !== 'object') {
      return obj;
    }

    var clone = JSON.parse(JSON.stringify(obj));
    Object.keys(clone).forEach(function (k) {
      clone[k] = escape(clone[k]);
    }); // maintain keys with value of undefined (these get lost in the clone)

    Object.keys(obj).forEach(function (k) {
      // fixup SafeString items that looked like objects
      if (Ember.String.isHTMLSafe(obj[k])) {
        clone[k] = obj[k].toString();
      }

      if (Object.prototype.hasOwnProperty.call(obj, k) && typeof obj[k] === 'undefined') {
        clone[k] = undefined;
      }
    });
    return clone;
  }

  function unescape(obj) {
    if (typeof obj === 'string') {
      var theString = obj;
      HTMLChars.forEach(function (char) {
        // replaceAll isn't available until Chrome 85, so unit tests failed
        // This regex method works, just not as clean
        theString = theString.replace(new RegExp(regExpEscape(char.escaped), 'g'), char.char);
      });
      return theString;
    } else {
      return obj;
    }
  }
});