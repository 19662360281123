define("web-directory/components/mobile-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eEypXrKu",
    "block": "{\"statements\":[[11,\"div\",[]],[16,\"class\",[34,[\"mobile-banner \",[26,[\"mobileVersion\"]]]]],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"banner-text\"],[13],[0,\"\\n        \"],[1,[33,[\"t\"],[\"mobile.featureText\"],null],false],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[11,\"div\",[]],[15,\"class\",\"mobile-links\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"app-link\"],[13],[0,\"\\n        \"],[11,\"a\",[]],[16,\"href\",[34,[[26,[\"appLink\"]]]]],[15,\"class\",\"btn btn-link\"],[13],[0,\"\\n            \"],[1,[33,[\"t\"],[\"mobile.appText\"],null],false],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"detail-link\"],[15,\"tabindex\",\"0\"],[15,\"role\",\"button\"],[5,[\"action\"],[[28,[null]],\"sendClose\"]],[13],[0,\"\\n        \"],[11,\"a\",[]],[15,\"href\",\"\"],[13],[0,\"\\n            \"],[1,[33,[\"t\"],[\"mobile.detailText\"],null],false],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/mobile-modal/template.hbs"
    }
  });

  _exports.default = _default;
});