define("web-directory/components/entity-field-section/-section-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9N5lMway",
    "block": "{\"statements\":[[11,\"div\",[]],[16,\"class\",[34,[\"field-section-header \",[33,[\"if\"],[[28,[\"showInstructionText\"]],\"show-instruction-text\"],null]]]],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"header\"],[13],[0,\"\\n        \"],[11,\"span\",[]],[15,\"class\",\"title\"],[13],[1,[26,[\"sectionName\"]],false],[14],[0,\"\\n    \"],[14],[0,\"\\n\\n\"],[6,[\"if\"],[[28,[\"showEditAction\"]]],null,{\"statements\":[[0,\"        \"],[11,\"div\",[]],[15,\"class\",\"edit-action\"],[13],[0,\"\\n            \"],[11,\"a\",[]],[15,\"href\",\"#\"],[16,\"title\",[33,[\"t\"],[\"fieldSection.sectionEdit\"],[[\"sectionName\"],[[28,[\"sectionName\"]]]]],null],[5,[\"action\"],[[28,[null]],\"editSection\"]],[13],[0,\"\\n                \"],[1,[33,[\"t\"],[\"fieldSection.sectionEdit\"],[[\"sectionName\"],[[28,[\"sectionName\"]]]]],false],[0,\"\\n            \"],[14],[0,\"\\n        \"],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[6,[\"if\"],[[28,[\"showHeaderLock\"]]],null,{\"statements\":[[0,\"        \"],[11,\"span\",[]],[15,\"class\",\"lock\"],[13],[0,\"\\n            \"],[11,\"i\",[]],[15,\"class\",\"pc pc-lock\"],[13],[14],[0,\"\\n        \"],[14],[0,\"\\n    \"]],\"locals\":[]},null]],\"locals\":[]}],[0,\"\\n\"],[6,[\"if\"],[[28,[\"showAddNew\"]]],null,{\"statements\":[[0,\"        \"],[1,[33,[\"entity-field-section/add-new\"],null,[[\"section\",\"fieldValues\",\"disableDropdown\",\"modal\",\"addNewEntry\",\"toggleAddValue\"],[[28,[\"section\"]],[28,[\"fieldValuesToAdd\"]],[28,[\"disableAddValueDropdown\"]],[28,[\"modal\"]],[33,[\"action\"],[[28,[null]],\"addNewEntry\"],null],[33,[\"action\"],[[28,[null]],\"toggleAddValue\"],null]]]],false],[0,\"\\n\"]],\"locals\":[]},null],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/entity-field-section/-section-header/template.hbs"
    }
  });

  _exports.default = _default;
});