define("web-directory/components/application-preferences/interactions/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed,
      inject = Ember.inject;

  var _default = Component.extend({
    classNames: ['queue-settings'],
    intl: inject.service(),
    preferences: inject.service(),
    enableDefaultQueues: false,
    checkboxName: computed(function () {
      return this.get('intl').t('purecloud.acdInteraction.defaultQueueSetting');
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('preferences').getPreference('interactions.defaultQueuesEnabled').then(function (val) {
        _this.set('enableDefaultQueues', val);
      });
    },
    actions: {
      changeSetting: function changeSetting(value) {
        this.set('enableDefaultQueues', value);
        this.get('preferences').setPreference('interactions.defaultQueuesEnabled', value);
      }
    }
  });

  _exports.default = _default;
});