define("web-directory/components/audio-player/seek-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DYiWPUph",
    "block": "{\"statements\":[[11,\"span\",[]],[15,\"class\",\"progress-bar\"],[13],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/audio-player/seek-bar/template.hbs"
    }
  });

  _exports.default = _default;
});