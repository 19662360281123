define("web-directory/serializers/integer", ["exports", "ember-custom-fields-components/serializers/integer"], function (_exports, _integer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _integer.default;
    }
  });
});