define("web-directory/components/timeline-stream/direct-message-renderer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c1bw69Jq",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"header\"],[13],[0,\"\\n\"],[6,[\"entity-image\"],null,[[\"entity\",\"enableMiniCard\",\"size\",\"isLinkable\",\"canViewChat\",\"showPresence\"],[[28,[\"fromPerson\"]],true,48,true,[28,[\"canViewChat\"]],true]],{\"statements\":[[0,\"        \"],[1,[33,[\"integration-badges\"],null,[[\"entity\"],[[28,[\"fromPerson\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"title\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"primary\"],[13],[0,\"\\n            \"],[11,\"span\",[]],[15,\"class\",\"from\"],[13],[1,[28,[\"fromPerson\",\"name\"]],false],[14],[0,\"\\n            \"],[11,\"span\",[]],[15,\"class\",\"description\"],[13],[1,[26,[\"description\"]],false],[14],[0,\"\\n        \"],[14],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"secondary\"],[13],[1,[26,[\"timeDisplay\"]],false],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\"],[6,[\"unless\"],[[28,[\"acknowledged\"]]],null,{\"statements\":[[6,[\"each\"],[[28,[\"messages\"]]],null,{\"statements\":[[6,[\"emoji-dom\"],null,null,{\"statements\":[[0,\"            \"],[1,[28,[\"message\",\"messageBody\"]],true],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[\"message\"]},null]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/timeline-stream/direct-message-renderer/template.hbs"
    }
  });

  _exports.default = _default;
});