define("web-directory/models/response-text", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ResponseTextModel = _emberData.default.Model.extend({
    content: _emberData.default.attr(),
    contentType: _emberData.default.attr()
  });

  var _default = ResponseTextModel;
  _exports.default = _default;
});