define("web-directory/routes/no-access", ["exports", "web-directory/mixins/unprotected-route-mixin"], function (_exports, _unprotectedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unprotectedRouteMixin.default, {
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      return this.get('intl').loadTranslations();
    }
  });

  _exports.default = _default;
});