define("web-directory/components/acd-interactions-panel-v2/interaction-header/header-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @notes
   * instead of using `onclick` (or other on<event>) during the implementation, custom components that use
   * interactive elements in ember as an alias do not work with the `on` portion prefixed. When wanting to
   * add a listener to the events you should drop the `on` and just use `click`.
   *
   * @usage
   * {{acd-interactions-panel-v2/interaction-header/header-button click=(action 'onEvent')}}
   */
  var _default = Ember.Component.extend({
    /**
     * List of class names to be applied to the component on render
     */
    classNames: ['interaction-header-button'],

    /**
     * List of dynamic class names that will change based on the corresponding property values
     */
    classNameBindings: ['accent'],

    /**
     * List of dynamic attributes that will change based on the corresponding property values
     */
    attributeBindings: ['accent', 'type', 'disabled', 'disabled:aria-disabled', 'superscript', 'ariaLabel', 'ariaPressed', 'ariaExpanded'],

    /**
     * The element that this component should render as when created
     */
    tagName: 'button',

    /**
     * The type of the button for html semantic reasons
     */
    type: 'button',

    /**
     * @attribute
     *
     * `true` if the component should not be interacted with
     */
    disabled: false,

    /**
     * @attribute
     *
     * The color the button should render as. Defaults to primary
     */
    accent: 'primary',

    /**
     * @attribute
     *
     * the additional context to add to the button that will appear in the top right
     * corner of the button as a bubble with the content provided.
     */
    superscript: undefined,

    /**
     * @attribute
     * 
     * The aria-label state of the control, used in attribute binding
     */
    ariaLabel: undefined,

    /**
    * @attribute
    * 
    * The aria-pressed state of the control, used in attribute binding
    */
    ariaPressed: undefined,

    /**
    * @attribute
    * 
    * The aria-expanded state of the control, used in attribute binding
    */
    ariaExpanded: undefined
  });

  _exports.default = _default;
});