define("web-directory/instance-initializers/entity-location", ["exports", "ember-purecloud-components/components/entity-location/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    _component.default.reopen({
      session: Ember.inject.service(),
      geolocation: Ember.inject.service(),
      self: Ember.computed.reads('session.person'),
      isGeolocationEnabled: Ember.computed.reads('geolocation.isEnabled')
    });
  }

  var _default = {
    name: 'entity-location',
    initialize: initialize
  };
  _exports.default = _default;
});