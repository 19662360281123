define("web-directory/components/select-2/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * @deprecated If possible use `gux-dropdown` components instead
   */
  var _default = Ember.Component.extend({
    classNames: ['select-2'],
    intl: Ember.inject.service('intl'),
    logger: Ember.inject.service('stash-logger'),

    /**
     * @attribute
     * The main value to display and manage for this component
     */
    value: null,

    /**
     * @attribute
     * The label to associate to this component. This will be rendered as sr-only.
     */
    label: null,

    /**
     * @attribute
     * additional select-2 component options to further configure the component for each separate
     * implementation
     */
    options: {},

    /**
     * caches the fetched/provided results to allow for a quicker query once a value has been
     * selected.
     */
    cachedResults: [],

    /**
     * Persists the previous term that was searched against the components results.
     */
    previousTerm: null,

    /**
     * This is the previous value that was once set to this component. We need to save the previous
     * component in order to quickly compare the incoming value and see if we need to update
     * the select2 component accordingly.
     */
    prevValue: null,

    /**
     * Cached reference to the select2 component being rendered for this component
     */
    _select2: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.setUpSelect();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      // lets determine the value whenever the attrs change, we check if the value
      // truly changes in the following function to be safe.
      this.determineValue();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      try {
        var _this$select2Ref;

        (_this$select2Ref = this.select2Ref()) === null || _this$select2Ref === void 0 ? void 0 : _this$select2Ref.select2('destroy');
      } catch (err) {
        this.get('logger').error('Failed to remove select2 binding.');
      }
    },

    /**
     * @action
     *
     * emitted when the value has been changed for the select input. Defaults to `no-op`
     * due to this action not being required, so no message needs to be emitted.
     *
     * @param _event event information that was emitted from the select2 component
     */
    actionOnChange: function actionOnChange(_event) {},

    /**
     * Returns the cached select2 component or finds it and returns if one hasn't been
     * cached yet.
     *
     * @returns reference to the select2 element
     */
    select2Ref: function select2Ref() {
      var select2 = this.get('_select2');

      if (!select2) {
        select2 = this.$('.select2');
        this.set('_select2', select2);
      }

      return select2;
    },

    /**
     * Determines if the select2 components values needs to be updated to reflect the newly
     * set value. If a value cannot be determined then the select2 component will remove it's value.
     *
     * @param force set to true to ensure that the value will be updated and invoke a change event
     */
    determineValue: function determineValue() {
      var force = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var value = this.get('value');
      var prev = this.get('prevValue');

      if (force || !value && !prev || value !== prev) {
        var _this$select2Ref2, _value$text;

        this.set('prevValue', value ? _objectSpread({}, value) : null);
        (_this$select2Ref2 = this.select2Ref()) === null || _this$select2Ref2 === void 0 ? void 0 : _this$select2Ref2.val((_value$text = value === null || value === void 0 ? void 0 : value.text) !== null && _value$text !== void 0 ? _value$text : '').trigger('change');
      }
    },

    /**
     * Emitted when the select2 component has emitted a change and needs to process the updates
     *
     * @note `event.removed` is provided if the user selects the clear button in the `select-2` component.
     *
     * @param event the JQuery event emitted from the select2 component
     */
    onChange: function onChange(event) {
      var value = this.get('value');

      if (event.val) {
        var _this$get$find;

        value = (_this$get$find = this.get('cachedResults').find(function (v) {
          return v.id === event.val;
        })) !== null && _this$get$find !== void 0 ? _this$get$find : null;
      } else if (this.get('options').allowClear && event.removed) {
        // if no val, then user requested a clear on the component
        value = null;
      } // we only want to invoke the action if the value changed to avoid duplicate rendering updates


      if (value !== this.get('value')) {
        this.set('value', value);
        this.actionOnChange(value);
      }
    },

    /**
     * sets the main select component up merging the default options with the provided options
     * and initializes the needed events for value management.
     */
    setUpSelect: function setUpSelect() {
      var _this = this,
          _this$select2Ref3,
          _this$select2Ref4;

      var options = _objectSpread({
        noCache: false,
        query: function query(_query) {
          if (options.noCache || _query.term !== _this.get('previousTerm')) {
            options.search(_query.term).then(function (results) {
              _this.set('previousTerm', _query.term);

              _this.set('cachedResults', results);

              _query.callback({
                results: results
              });
            });
          } else {
            _query.callback({
              results: _this.get('cachedResults')
            });
          }
        },
        initSelection: function initSelection(element, callback) {
          var value = _this.get('value');

          if (element.val() !== _this.get('previousTerm')) {
            options.search(element.val()).then(function (results) {
              _this.set('previousTerm', element.val());

              _this.set('cachedResults', results);

              callback(results.find(function (v) {
                return v.id === (value === null || value === void 0 ? void 0 : value.id);
              }));
            });
          } else {
            callback(_this.get('cachedResults').find(function (v) {
              return v.id === (value === null || value === void 0 ? void 0 : value.id);
            }));
          }
        },
        formatSearching: function formatSearching() {
          return _this.get('intl').t('components.select2.searching');
        },
        formatNoMatches: function formatNoMatches() {
          return _this.get('intl').t('components.select2.noMatches');
        }
      }, this.get('options'));

      this.set('options', options);
      (_this$select2Ref3 = this.select2Ref()) === null || _this$select2Ref3 === void 0 ? void 0 : _this$select2Ref3.select2(options); // initial set happens before the change is set to avoid an immediate event

      this.determineValue(true);
      (_this$select2Ref4 = this.select2Ref()) === null || _this$select2Ref4 === void 0 ? void 0 : _this$select2Ref4.on('change', function (e) {
        return _this.onChange(e);
      });
    }
  });

  _exports.default = _default;
});