define("web-directory/components/carousel-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zEBWwrzm",
    "block": "{\"statements\":[[6,[\"each\"],[[28,[\"currItems\"]]],null,{\"statements\":[[0,\"    \"],[11,\"li\",[]],[16,\"class\",[34,[[33,[\"if\"],[[28,[\"menuItem\",\"back\"]],\"back\"],null],\"\\n            \",[33,[\"if\"],[[28,[\"menuItem\",\"forward\"]],\"forward\"],null],\"\\n            \",[33,[\"if\"],[[28,[\"menuItem\",\"className\"]],[28,[\"menuItem\",\"className\"]]],null]]]],[16,\"title\",[34,[[28,[\"menuItem\",\"label\"]]]]],[15,\"tabindex\",\"0\"],[15,\"role\",\"button\"],[5,[\"action\"],[[28,[null]],\"onMenuClick\",[28,[\"menuItem\"]]],[[\"preventDefault\"],[false]]],[13],[0,\"\\n\\n\"],[0,\"        \"],[1,[33,[\"menu-item\"],null,[[\"menuItem\",\"showIcon\"],[[28,[\"menuItem\"]],[28,[\"showIcons\"]]]]],false],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[\"menuItem\"]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/carousel-menu/template.hbs"
    }
  });

  _exports.default = _default;
});