define("web-directory/mixins/acd/session-share", ["exports", "ember-data", "ember-purecloud-components/utils/jid-helpers", "lodash/isEmpty", "lodash/maxBy", "web-directory/utils/dates"], function (_exports, _emberData, _jidHelpers, _isEmpty, _maxBy, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var PROVIDER_MEDIATYPE = {
    COBROWSE: 'PureCloud Co-browse',
    SCREENSHARE: 'PureCloud WebRTC screen-share',
    VIDEO: 'PureCloud WebRTC video'
  };

  function aggregateMedia(screenShares, cobrowsesessions, videos) {
    // Ensure provider is identified in array items.
    // This step could be skipped if backend does not remove provider info
    (screenShares || []).forEach(function (mediaObj) {
      if (!mediaObj.mediaType) {
        mediaObj.mediaType = PROVIDER_MEDIATYPE.SCREENSHARE;
      }
    });
    (cobrowsesessions || []).forEach(function (mediaObj) {
      if (!mediaObj.mediaType) {
        mediaObj.mediaType = PROVIDER_MEDIATYPE.COBROWSE;
      }
    });
    (videos || []).forEach(function (mediaObj) {
      if (!mediaObj.mediaType) {
        mediaObj.mediaType = PROVIDER_MEDIATYPE.VIDEO;
      }
    });
    var allMedia = [];

    if (screenShares) {
      allMedia = allMedia.concat(screenShares);
    }

    if (cobrowsesessions) {
      allMedia = allMedia.concat(cobrowsesessions);
    }

    if (videos) {
      allMedia = allMedia.concat(videos);
    }

    return allMedia;
  }

  function getMediaObject(mediaArray) {
    // This function identifies the current active media
    // object from mediaArray mediaArray is expected to contain
    // the secondary media types within a participant i.e screenShares,
    // cobrowseSessions, videos etc.
    // Get media with latest connected Time
    var mediaWithlatestConnectedTime = (0, _maxBy.default)(mediaArray, function (mediaObj) {
      if (mediaObj && mediaObj.connectedTime) {
        return (0, _dates.default)(mediaObj.connectedTime).unix();
      }

      return 0;
    }); // Get media with latest disconnected time

    var mediaWithlatestDisconnectedTime = (0, _maxBy.default)(mediaArray, function (mediaObj) {
      if (mediaObj && mediaObj.disconnectedTime) {
        return (0, _dates.default)(mediaObj.disconnectedTime).unix();
      }

      return 0;
    }); // Get latest active media

    var maxConnectedTime = (0, _dates.default)(mediaWithlatestConnectedTime && mediaWithlatestConnectedTime.connectedTime ? mediaWithlatestConnectedTime.connectedTime : 0);
    var maxDisconnectedTime = (0, _dates.default)(mediaWithlatestDisconnectedTime && mediaWithlatestDisconnectedTime.disconnectedTime ? mediaWithlatestDisconnectedTime.disconnectedTime : 0);
    var activeMedia = maxConnectedTime.isAfter(maxDisconnectedTime) ? mediaWithlatestConnectedTime : mediaWithlatestDisconnectedTime;
    return activeMedia;
  }

  var _default = Ember.Mixin.create({
    ajax: Ember.inject.service(),
    permissions: Ember.inject.service(),
    communication: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionShareCode: null,
    isSessionCodeRequired: false,
    isSessionSharing: false,
    isRequestingEndSessionShare: false,
    sessionShareDisconnectTimer: null,
    sessionShareExplicitlyInitiated: false,
    sessionShareActiveOnLoad: false,
    isCobrowseV2Disconnected: false,
    isCobrowseV2: false,
    // feature toggles & permissions
    canScreenShareOnCall: computed.and('isCall', 'permissions.canViewAcdScreenShare'),
    canScreenShareOnChat: computed.and('isChat', 'permissions.canViewAcdScreenShare'),
    canCobrowseOnCall: computed.and('isCall', 'permissions.canViewAcdCoBrowseForCall'),
    canCobrowseOnChat: computed.and('isChat', 'permissions.canViewAcdCoBrowseForChat'),
    // State
    isRequestingScreenShareOrCoBrowse: false,
    isRequestingVideo: false,
    sessionShareState: computed.reads('mostRecentAgentSessionShare.state'),
    customerShareState: computed.reads('mostRecentCustomerSessionShare.state'),
    hasSessionShareCode: computed.notEmpty('sessionShareCode'),
    sessionShareActive: computed.or('isRequestingSessionShare', 'hasSessionShareCode', 'isSessionSharing'),
    isRequestingSessionShare: computed.or('isRequestingScreenShareOrCoBrowse', 'isRequestingVideo'),
    isSessionShareConnected: computed('sessionShareState', 'isV2Provider', function () {
      var state = this.get('sessionShareState') || '';
      return state.toLowerCase() === 'connected';
    }),
    isSessionShareDisconnected: computed('sessionShareState', 'isRequestingNewSessionShare', function () {
      var state = this.get('sessionShareState') || '';
      return state.toLowerCase() === 'disconnected' && !this.get('isRequestingNewSessionShare');
    }),
    isCustomerInSessionShare: computed('customerShareState', 'isSessionSharing', function () {
      var state = this.get('customerShareState') || '';
      return this.get('isSessionSharing') && state.toLowerCase() === 'connected';
    }),
    isCustomerDisconnectedFromSharedSession: computed('customerShareState', 'isSessionSharing', function () {
      var state = this.get('customerShareState') || '';
      return this.get('isSessionSharing') && state.toLowerCase() === 'disconnected';
    }),
    isAgentDisconnectedFromSharedSession: computed('sessionShareState', 'isSessionSharing', 'isRequestingNewSessionShare', function () {
      var state = this.get('sessionShareState') || '';
      return this.get('isSessionSharing') && state.toLowerCase() === 'disconnected' && !this.get('isRequestingNewSessionShare');
    }),
    isWaitingForAgentToConnect: computed('isSessionShareConnected', 'isAgentDisconnectedFromSharedSession', 'sessionShareState', 'isV2Provider', function () {
      return !this.get('isSessionShareConnected') && !this.get('isAgentDisconnectedFromSharedSession');
    }),
    isWaitingForCustomerToConnect: computed('isSessionCodeRequired', 'isCustomerInSessionShare', 'isCustomerDisconnectedFromSharedSession', 'isV2Provider', function () {
      return this.get('isSessionCodeRequired') && !this.get('isCustomerInSessionShare') && !this.get('isCustomerDisconnectedFromSharedSession');
    }),
    hasCustomerJoinedSessionShareRoom: computed('customerShareState', function () {
      var state = this.get('customerShareState') || '';
      return state.toLowerCase() === 'connected';
    }),
    hasAgentJoinedSessionShareRoom: computed('sessionShareState', function () {
      var state = this.get('sessionShareState') || '';
      return state.toLowerCase() === 'connected';
    }),
    sessionShareRoomJid: computed('mostRecentCustomerSessionShare.context', function () {
      var context = this.get('mostRecentCustomerSessionShare.context');

      if (context && context.indexOf(':') >= 0) {
        return context.substring(context.indexOf(':') + 1);
      } else if (context && (0, _jidHelpers.isRoomJid)(context)) {
        return context;
      }

      return null;
    }),
    // media objects
    mostRecentAgentSessionShare: computed('participantForCurrentUser.{videos.[],screenShares.[],screenshares.[],cobrowsesessions.[]}', function () {
      var screenShares = this.get('participantForCurrentUser.screenShares') || this.get('participantForCurrentUser.screenshares');
      var cobrowsesessions = this.get('participantForCurrentUser.cobrowsesessions');
      var videos = this.get('participantForCurrentUser.videos');
      var allMedia = aggregateMedia(screenShares, cobrowsesessions, videos);

      if (!(0, _isEmpty.default)(allMedia)) {
        var mediaObject = getMediaObject(allMedia);
        this.get('logger').info('getMediaObject for agent', mediaObject);
        return mediaObject;
      }

      return null;
    }),
    mostRecentCustomerSessionShare: computed('participantForCustomer.{videos.[],screenShares.[],screenshares.[],cobrowsesessions.[]}', function () {
      var screenShares = this.get('participantForCustomer.screenShares') || this.get('participantForCustomer.screenshares');
      var cobrowsesessions = this.get('participantForCustomer.cobrowsesessions');
      var videos = this.get('participantForCustomer.videos');
      var allMedia = aggregateMedia(screenShares, cobrowsesessions, videos);

      if (!(0, _isEmpty.default)(allMedia)) {
        var mediaObject = getMediaObject(allMedia);
        this.get('logger').info('getMediaObject for customer', mediaObject);
        return mediaObject;
      }

      return null;
    }),
    // type
    sessionShareType: computed('isScreenShare', 'isVideoShare', 'isCoBrowse', function () {
      if (this.get('isScreenShare')) {
        return 'screen-share';
      } else if (this.get('isVideoShare')) {
        return 'video';
      } else if (this.get('isCoBrowse')) {
        return 'co-browse';
      }

      return null;
    }),
    canScreenShare: computed.or('canScreenShareOnCall', 'canScreenShareOnChat'),
    canCobrowse: computed.or('canCobrowseOnCall', 'canCobrowseOnChat'),
    mediaTypeIsScreenShare: computed.equal('mostRecentCustomerSessionShare.mediaType', PROVIDER_MEDIATYPE.SCREENSHARE),
    mediaTypeIsCobrowse: computed.equal('mostRecentCustomerSessionShare.mediaType', PROVIDER_MEDIATYPE.COBROWSE),
    mediaTypeIsVideo: computed.equal('mostRecentCustomerSessionShare.mediaType', PROVIDER_MEDIATYPE.VIDEO),
    isScreenShare: computed('isRequestingScreenShareOrCoBrowse', 'isRequestingVideo', 'canScreenShare', 'mediaTypeIsScreenShare', function () {
      if (this.get('isRequestingScreenShareOrCoBrowse')) {
        return true;
      }

      if (this.get('isRequestingVideo')) {
        return false;
      }

      return this.get('canScreenShare') && this.get('mediaTypeIsScreenShare');
    }),
    isCoBrowse: computed('isRequestingVideo', 'isRequestingScreenShareOrCoBrowse', 'mediaTypeIsCobrowse', 'canCobrowse', function () {
      if (this.get('isRequestingScreenShareOrCoBrowse') || this.get('isRequestingVideo')) {
        return false;
      }

      return this.get('canCobrowse') && this.get('mediaTypeIsCobrowse');
    }),
    isVideoShare: computed('isRequestingVideo', 'isRequestingScreenShareOrCoBrowse', 'mediaTypeIsVideo', function () {
      return this.get('isRequestingVideo') && !this.get('isRequestingScreenShareOrCoBrowse');
    }),
    // permission errors
    hasScreenSharePermissionError: computed.or('hasCobrowsePermissionError', 'hasVideoPermissionError', 'hasShareError'),
    hasCobrowsePermissionError: computed('mediaTypeIsCobrowse', 'canCobrowse', function () {
      if (this.get('mediaTypeIsCobrowse') && !this.get('canCobrowse')) {
        this.get('logger').error('ERROR: setSessionShareType: Co-Browse: Permissions or feature toggle missing to process acd co-browse events.', this.get('id'));
        return true;
      }

      return false;
    }),
    hasVideoPermissionError: computed('mediaTypeIsVideo', function () {
      if (this.get('mediaTypeIsVideo')) {
        this.get('logger').error('ERROR: setSessionShareType: Video: Permissions or feature toggle missing to process acd video events.', this.get('id'));
        return true;
      }

      return false;
    }),
    hasShareError: computed('mediaTypeIsScreenShare', 'canScreenShare', function () {
      if (this.get('mediaTypeIsScreenShare') && !this.get('canScreenShare')) {
        this.get('logger').error('ERROR: setSessionShareType: Screen-share: Permissions or feature toggle missing to process acd screen-share events.', this.get('id'));
        return true;
      }

      return false;
    }),
    // Timers
    sessionShareConnectedTime: computed('mostRecentAgentSessionShare.connectedTime', 'participantForCurrentUser.connectedTime', function () {
      if (this.get('mostRecentAgentSessionShare.connectedTime')) {
        return (0, _dates.default)(this.get('mostRecentAgentSessionShare.connectedTime'));
      } else if (this.get('participantForCurrentUser.connectedTime')) {
        return (0, _dates.default)(this.get('participantForCurrentUser.connectedTime'));
      }

      return (0, _dates.default)();
    }),
    sessionShareDuration: computed('mostRecentAgentSessionShare.{disconnectedTime,connectedTime}', function () {
      var connectedTime = this.get('mostRecentAgentSessionShare.connectedTime');
      var endTime = this.get('mostRecentAgentSessionShare.disconnectedTime') || (0, _dates.default)();

      if (connectedTime && endTime) {
        return (0, _dates.default)(endTime).diff((0, _dates.default)(connectedTime));
      }

      return null;
    }),
    // V2 Session share media
    activeMediaRequests: computed(function () {
      var url = this.get('communication').mediaRequestUri(this.get('id'));
      return _emberData.default.PromiseArray.create({
        promise: this.get('ajax').request(url).then(function (res) {
          return res.entities;
        })
      });
    })
  });

  _exports.default = _default;
});