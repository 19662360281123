define("web-directory/components/application-preferences/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CAamSFlL",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"preferences-layout\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"preferences-side-nav\"],[13],[0,\"\\n        \"],[11,\"h4\",[]],[15,\"class\",\"preferences-title\"],[13],[0,\"\\n            \"],[1,[33,[\"t\"],[\"components.navMenu.preferences\"],null],false],[0,\"\\n        \"],[14],[0,\"\\n        \"],[11,\"ul\",[]],[15,\"class\",\"nav nav-tabs nav-stacked\"],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"tabs\"]]],null,{\"statements\":[[6,[\"if\"],[[28,[\"tab\",\"canShow\"]]],null,{\"statements\":[[0,\"                    \"],[11,\"li\",[]],[16,\"class\",[34,[[33,[\"if\"],[[28,[\"tab\",\"isActive\"]],\"active\"],null],\" \",[28,[\"tab\",\"key\"]]]]],[13],[0,\"\\n                        \"],[11,\"a\",[]],[15,\"href\",\"#\"],[5,[\"action\"],[[28,[null]],\"changeTab\",[28,[\"tab\",\"key\"]]]],[13],[0,\"\\n                            \"],[1,[28,[\"tab\",\"name\"]],false],[0,\"\\n                        \"],[14],[0,\"\\n                    \"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[\"tab\"]},null],[0,\"        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"preferences-main\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[16,\"class\",[34,[\"preference-tab-\",[28,[\"currentTab\",\"key\"]]]]],[13],[0,\"\\n            \"],[1,[33,[\"component\"],[[28,[\"currentTab\",\"component\"]]],null],false],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/application-preferences/template.hbs"
    }
  });

  _exports.default = _default;
});