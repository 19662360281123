define("web-directory/mixins/phone-service/notifications", ["exports", "web-directory/utils/hosted-context", "web-directory/utils/dates", "lodash"], function (_exports, _hostedContext, _dates, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var observer = Ember.observer,
      run = Ember.run,
      inject = Ember.inject;
  var IGNORED_ERRORS = ['error.ininedgecontrol.session.inactive'];

  var _default = Ember.Mixin.create({
    session: inject.service(),
    webrtcSdkService: inject.service('webrtcSdk'),
    useNotificationProfileOutput: Ember.computed.reads('session.features.notificationOutputPicker'),
    displayIncomingNotification: function displayIncomingNotification(conversation) {
      var _this = this;

      var isHosted, applicationHasFocus;
      var hosted = this.get('application.hostedContext');

      if (hosted) {
        isHosted = hosted.isHosted();
        applicationHasFocus = hosted.applicationHasFocus();
      }

      if (this.get('application.browserHidden') || isHosted && !applicationHasFocus) {
        var title = this.get('intl').lookup('notification.titles.incomingCall').toString();
        var message = this.get('intl').lookup('notification.purecloud.incomingCall').toString();
        var notificationOptions = {
          fallbackToInBrowser: false,
          hideAfter: 8,
          tag: _hostedContext.EVENTS.INCOMING_CALL,
          meta: {
            conversation: conversation
          },
          clickHandler: function clickHandler(conversation, action) {
            if (conversation && action) {
              if (_lodash.default.includes(action, 'ignore')) {
                return _this.toVoicemail(conversation);
              } else if (_lodash.default.includes(action, 'answer')) {
                return _this.answer(conversation);
              }
            }
          }
        };

        if (this.get('useNotificationProfileOutput')) {
          notificationOptions.notificationOutputDeviceId = this.getNotificationOutputDeviceId();
        }

        this.get('notification').info(title, message, notificationOptions);
      }
    },
    // handle errors on users session
    handleParticipantCallError: observer('userCallSessionsWithErrors.@each.calls', 'userCallSessionsWithErrors.calls.@each.disconnectedTime', 'personGuid', 'webrtcSdkService.useSdk', function () {
      var _this2 = this;

      // we will use the sdk the determine and parse call errors
      if (this.get('webrtcSdkService.useSdk')) {
        return;
      }

      var callsWithErrors = this.get('callsWithErrors');
      var userCallSessionsWithErrors = this.get('userCallSessionsWithErrors'); // are there any errors on any of the user's sessions?

      _lodash.default.each(userCallSessionsWithErrors, function (session) {
        var calls = Ember.get(session, 'calls');

        _lodash.default.each(calls, function (call) {
          var endMoment = (0, _dates.default)(call.disconnectedTime);
          var isRecent = (0, _dates.default)().diff(endMoment) < 5000 ? true : false;
          var hasThrownError = Ember.get(call, '_hasThrownError');

          if (isRecent && !hasThrownError) {
            callsWithErrors.pushObject({
              conversationId: session.id,
              call: call
            }); // TODO: We should not be getting calls without error info here PCDWEBK-3674

            if (call.errorInfo && !_lodash.default.includes(IGNORED_ERRORS, call.errorInfo.code)) {
              Ember.set(call, '_hasThrownError', true);

              _this2.showErrorNotification(call.errorInfo);
            }
          }
        });
      });

      if ((this.get('_testIsStationWebrtc') || this.get('station.isWebrtc')) && callsWithErrors.length > 0) {
        _lodash.default.each(callsWithErrors, function (call) {
          var reason = Ember.get(call, 'call.disconnectReasons.firstObject');
          var endMoment = (0, _dates.default)(call.disconnectedTime);
          var isRecent = (0, _dates.default)().diff(endMoment) < 5000 ? true : false;

          if (reason && isRecent && Ember.get(reason, 'type') === 'SIP' && !Ember.get(call, 'call._hasThrownError')) {
            Ember.set(call, 'call._hasThrownError', true);
            run.throttle(_this2, _this2.showSipError, reason, 1000);
          }
        });
      }
    }),
    showSipError: function showSipError(response) {
      var title = this.get('intl').lookup('notification.titles.callError').toString();
      var prefix = this.get('application.urlprefix');
      var messageText = this.get('intl').t('purecloud.error.sipError');
      messageText = "".concat(messageText, " ").concat(response.code, " ").concat(response.phrase);
      this.get('notification').error(title, messageText, {
        tag: 'call-error',
        icon: "".concat(prefix, " assets/images/icons/png/checkmark-circled.png"),
        hideAfter: 5
      });
    },
    translateServerErrors: function translateServerErrors(response) {
      var message = response.message,
          messageParams = response.messageParams,
          messageWithParams = response.messageWithParams,
          code = response.code;
      var intl = this.get('intl');

      switch (code) {
        case 'providerapi.error.no.station.for.edge':
        case 'providerapi.error.station.no.active.edge':
          {
            return this.fetchStation(messageParams.stationId).then(function (response) {
              return messageWithParams.replace('{stationId}', "'".concat(response.get('name'), "'"));
            });
          }

        case 'error.ininedgecontrol.connection.station.notReached':
          {
            var newCode = code + '.physical';

            if (this.get('station.isWebrtc')) {
              newCode = code + '.webrtc';
            }

            return Ember.RSVP.resolve(intl.t(newCode));
          }

        default:
          {
            if (code && intl.exists(code)) {
              return Ember.RSVP.resolve(intl.t(code));
            }

            if (message) {
              return Ember.RSVP.resolve(message);
            }

            return code;
          }
      }
    },
    showErrorNotification: function showErrorNotification(response, err) {
      var _this3 = this;

      if (!response) {
        if (!err) {
          Ember.Logger.error(new Error('Cannot show error notification with no error information'));
          return;
        }

        response = {
          message: err.message
        };
      }

      var title = this.get('intl').lookup('notification.titles.callError').toString();
      var prefix = this.get('application.urlprefix');
      var messageText = this.get('intl').t('purecloud.error.dialpad.call');
      messageText = this.translateServerErrors(response, err);
      return messageText.then(function (text) {
        return _this3.get('notification').error(title, text, {
          tag: 'call-error',
          icon: prefix + 'assets/images/icons/png/checkmark-circled.png',
          hideAfter: 5
        });
      });
    },
    showFeedbackErrorNotification: function showFeedbackErrorNotification(message) {
      var title = this.get('intl').lookup('notification.titles.callError').toString();
      message = '' + message;
      return this.get('notification').error(title, message, {
        target: this.get('applicationController'),
        tag: 'activeCall',
        hideAfter: false,
        actionHandlers: {
          reportError: this.get('intl').lookup('components.navMenu.feedback.contactSupport').toString()
        }
      });
    },
    showNoStationSelected: function showNoStationSelected(conversation) {
      Ember.Logger.error('Cannot answer conversation because there is no active station for the current user', {
        conversationId: conversation.get('id')
      });
      var title = this.get('intl').t('notification.titles.callError');
      var message = this.get('intl').t('purecloud.station.noStationSelected');
      this.get('notification').error(title, message, {
        tag: 'call-error'
      });
    },
    checkForNonACD: function checkForNonACD(conversation) {
      var isAcdCall = this.get('isActiveCallACD');

      if (conversation) {
        isAcdCall = conversation.get('isAgentCall');
      }

      if (!isAcdCall) {
        var message = this.get('intl').lookup('notification.purecloud.nonACDOnQueue');
        return this.get('notification').info(null, message, {
          hideAfter: 5
        });
      }
    }
  });

  _exports.default = _default;
});