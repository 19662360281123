define("web-directory/routes/engage/quality-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    init: function init() {
      this._super.apply(this, arguments);
    },
    redirect: function redirect(model, transition) {
      var features = this.get('session.features');

      if (features['qm.adminUIDecomp']) {
        /*
            Redirect old PCBA/engage Quality Admin routes to iframe-coordinator app, e.g.
            #/engage/quality/admin/foo -> #/admin/quality/foo
            also handles special case for Evaluation and Survey Forms:
            #/engage/admin/evaluationForms -> #/admin/quality/evaluationForms
            #/engage/admin/surveyForms -> #/admin/quality/surveyForms
        */
        this.replaceWith('/admin/quality/' + model.qualityPath.replace(/^\//, ''));
      } else {
        // redirect back to old engage route
        var engagePath = transition.intent.url.replace(/\/engage\//, '');
        this.transitionTo('engage.embedded', {
          engagePath: engagePath
        });
      }
    }
  });

  _exports.default = _default;
});