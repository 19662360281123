define("web-directory/components/app-img/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var Component = Ember.Component,
      computed = Ember.computed,
      get = Ember.get;
  var DEFAULT_IMG_TYPE = 'icon'; // Possible Future Types: smallIcon (16x16), notificationIcon, launcherIcon, splashIcon (512x512)

  var IMG_TYPE_CFG_MAP = {
    icon: {
      width: 24,
      height: 24,
      assetBasePath: 'assets.icon',
      // The max allowable multiplier delta that is acceptable for a flexible image size
      // Formally: delta <= closestSizeMaxMultiplierDelta where delta = Math.abs(1 - imgMultiplier)
      // e.g. x12 - x36 when img size is 24
      closestSizeMaxMultiplierDelta: 1,
      minSupplimentalRes: 1.5,
      maxSupplimentalRes: 3
    },
    tile: {
      width: 128,
      height: 128,
      assetBasePath: 'assets.icon',
      // The max allowable multiplier delta that is acceptable for a flexible image size
      // Formally: delta <= closestSizeMaxMultiplierDelta where delta = Math.abs(1 - imgMultiplier)
      // e.g. x12 - x36 when img size is 24
      closestSizeMaxMultiplierDelta: 1,
      minSupplimentalRes: 1.5,
      maxSupplimentalRes: 3
    }
  };
  var VECTOR_IMG_PROP_NAME = 'vector';
  var RASTER_IMGS_PROP_NAME = 'raster';
  var RASTER_IMG_KEY_REG_EXP = /^x(\d+)$/i;
  var CLASS_NAMES_PROP_NAME = 'classNames';
  var SVG_PATH_PROP_NAME = 'svgSpriteHref';
  var GUX_ICON_PROPS_PATH = 'guxIcon';
  var NO_TITLE_MODE = 'none';
  var NATIVE_TITLE_MODE = 'native';
  var TOOLTIP_TITLE_MODE = 'tooltip';
  var DEFAULT_TITLE_MODE = TOOLTIP_TITLE_MODE;
  var TITLE_MODES = [NO_TITLE_MODE, NATIVE_TITLE_MODE, TOOLTIP_TITLE_MODE];
  /**
   * Generates the image markup for the specified app that is best suited
   * for the specified imgType.  imgType dictates the target environment for
   * the image display while the app's assets contain the dictionary of
   * image categories (icon, launcher, splash, etc.) and sizes (x16, x24, x512, etc.)
   *
   * In general, this algorithm is:
   * <ul>
   *   <li>Utilize the app assets appropriate for the imgType<li>
   *   <li>Use vector (svg) if in config and supported by browser</li>
   *   <li>Use exact or closest (within threshold) sized raster img for src</li>
   *   <li>
   *      Add any supplimental urls (with multiplier > 1 and within threshold)
   *      to srcset attribute if supported by browser
   *   <li>
   *   <li>If no applicable image can be found, use classNames</li>
   *   <li>If no classNames are configured, use the no-img placeholder</li>
   * </ul>
   */

  var _default = Component.extend({
    tagName: 'div',
    classNames: ['app-img'],
    classNameBindings: ['imgType'],
    attributeBindings: ['title'],
    app: null,
    titleMode: computed({
      get: function get() {
        return DEFAULT_TITLE_MODE;
      },
      set: function set(key, value) {
        if (TITLE_MODES.indexOf(value) < 0) {
          Ember.assert("app-img Invalid titleMode: ".concat(value));
        }

        return value;
      }
    }),
    showTooltip: computed.equal('titleMode', TOOLTIP_TITLE_MODE),
    title: computed('titleMode', 'app.name', function () {
      var result = '';
      var appName = this.get('app.name');
      var titleMode = this.get('titleMode');

      if (appName && appName.trim().length > 0 && titleMode === NATIVE_TITLE_MODE) {
        result = appName;
      }

      return result;
    }),
    imgType: computed({
      get: function get() {
        return DEFAULT_IMG_TYPE;
      },
      set: function set(key, value) {
        if (Object.prototype.hasOwnProperty.call(IMG_TYPE_CFG_MAP, value)) {
          return value;
        } else {
          Ember.assert("Invalid app-img type ".concat(value));
        }
      }
    }),
    imgWidth: computed('app', 'imgType', function () {
      return IMG_TYPE_CFG_MAP[this.get('imgType')].width;
    }),
    imgHeight: computed('app', 'imgType', function () {
      return IMG_TYPE_CFG_MAP[this.get('imgType')].height;
    }),
    _sortedRasterMultipliers: computed('app', 'imgType', function () {
      var result = [];
      var cfg = IMG_TYPE_CFG_MAP[this.get('imgType')];
      var allRasterImgs = get(this, "app.".concat(cfg.assetBasePath, ".").concat(RASTER_IMGS_PROP_NAME));

      if (allRasterImgs && _typeof(allRasterImgs) === 'object') {
        _lodash.default.forEach(allRasterImgs, function (rasterUrl, rasterKey) {
          var rasterMatch = RASTER_IMG_KEY_REG_EXP.exec(rasterKey);

          if (rasterMatch) {
            var currRasterWidth = parseInt(rasterMatch[1], 10);

            if (currRasterWidth && currRasterWidth > 0) {
              var currRasterMultiplier = currRasterWidth / cfg.width;
              result.push({
                url: rasterUrl,
                multiplier: currRasterMultiplier
              });
            }
          }
        });
      }

      return result.sort(function (a, b) {
        return a.multiplier - b.multiplier;
      });
    }),

    /**
     * Returns the vectorImgUrl if one exists in the app config
     * and if vector images are supported as img src in the current
     * environment
     */
    _vectorImgUrl: computed('app', 'imgType', function () {
      var result = null;
      var app = this.get('app');
      var cfg = IMG_TYPE_CFG_MAP[this.get('imgType')];

      if (app && Modernizr.svgasimg) {
        result = Ember.get(app, "".concat(cfg.assetBasePath, ".").concat(VECTOR_IMG_PROP_NAME)) || null;
      }

      return result;
    }),
    defaultImgUrl: computed('imgType', '_vectorImgUrl', '_sortedRasterMultipliers', function () {
      var vectorImgUrl = this.get('_vectorImgUrl');

      if (vectorImgUrl) {
        return vectorImgUrl;
      }

      var result = null;
      var cfg = IMG_TYPE_CFG_MAP[this.get('imgType')];
      var allRasters = this.get('_sortedRasterMultipliers');

      if (allRasters.length > 0) {
        var lowestDelta = null;

        for (var index = 0; index < allRasters.length; index++) {
          var curr = allRasters[index];
          var currDelta = Math.abs(1 - curr.multiplier);

          if (lowestDelta === null || currDelta < lowestDelta) {
            lowestDelta = currDelta;
            result = curr.url;
          }
        } // Ensure the best size default img is within the threshold


        if (cfg.closestSizeMaxMultiplierDelta && lowestDelta > cfg.closestSizeMaxMultiplierDelta) {
          result = null;
        }
      }

      return result;
    }),
    imgSrcSet: computed('imgType', '_vectorImgUrl', 'defaultImgUrl', '_sortedRasterMultipliers', function () {
      var result = '';
      var vectorImgUrl = this.get('_vectorImgUrl');
      var defaultImgUrl = this.get('defaultImgUrl');
      var cfg = IMG_TYPE_CFG_MAP[this.get('imgType')];
      var allRasters = this.get('_sortedRasterMultipliers');

      if (Modernizr.srcset && !vectorImgUrl && defaultImgUrl && allRasters.length > 0) {
        var supImgs = [];
        allRasters.forEach(function (_ref) {
          var url = _ref.url,
              multiplier = _ref.multiplier;

          if (url !== defaultImgUrl && multiplier > 1 && (!cfg.minSupplimentalRes || multiplier >= cfg.minSupplimentalRes) && (!cfg.maxSupplimentalRes || multiplier <= cfg.maxSupplimentalRes)) {
            supImgs.push({
              url: url,
              multiplier: multiplier
            });
          }
        });

        if (supImgs.length > 0) {
          // Sort the images by descending multiplier so the best one is choosen
          supImgs.reverse();
          result = supImgs.map(function (curr) {
            return "".concat(curr.url, " ").concat(curr.multiplier, "x");
          }).join(',');
        }
      }

      return result;
    }),
    svgSpriteHref: computed('app', 'imgType', function () {
      var result = null;
      var app = this.get('app');

      if (app) {
        var cfg = IMG_TYPE_CFG_MAP[this.get('imgType')];
        var svgPropPath = "".concat(cfg.assetBasePath, ".").concat(SVG_PATH_PROP_NAME);
        var svgSpriteHref = app.get(svgPropPath);

        if (svgSpriteHref) {
          result = svgSpriteHref;
        }
      }

      return result;
    }),
    imgClasses: computed('app', 'imgType', function () {
      var result = null;
      var app = this.get('app');

      if (app) {
        var cfg = IMG_TYPE_CFG_MAP[this.get('imgType')];
        var classesPath = "".concat(cfg.assetBasePath, ".").concat(CLASS_NAMES_PROP_NAME);
        var classNames = app.get(classesPath);

        if (classNames && Ember.isArray(classNames)) {
          result = classNames.join(' ');
        }
      }

      return result;
    }),
    guxIcon: computed('app', 'imgType', function () {
      var result = null;
      var app = this.get('app');

      if (app) {
        var cfg = IMG_TYPE_CFG_MAP[this.get('imgType')];
        var guxIconPropsPath = "".concat(cfg.assetBasePath, ".").concat(GUX_ICON_PROPS_PATH);
        var guxIconProps = app.get(guxIconPropsPath);

        if (guxIconProps && Object.prototype.hasOwnProperty.call(guxIconProps, 'name') && guxIconProps.hasOwnProperty('decorative')) {
          result = guxIconProps;
        }
      }

      return result;
    })
  });

  _exports.default = _default;
});