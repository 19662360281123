define("web-directory/components/acd-interactions-panel/interaction-session-share/session-share-auto-disconnect/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R/+IGnLS",
    "block": "{\"statements\":[[6,[\"if\"],[[33,[\"and\"],[[28,[\"forceDisconnect\"]],[33,[\"not\"],[[28,[\"showLoadingPanel\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[11,\"div\",[]],[15,\"class\",\"session-please-wait-panel\"],[13],[0,\"\\n        \"],[11,\"span\",[]],[15,\"class\",\"waiting-session-label\"],[13],[0,\"\\n            \"],[1,[26,[\"snail-progress\"]],false],[0,\"\\n            \"],[1,[33,[\"t\"],[\"purecloud.acdInteraction.sessionShare.waitingToCleanUpSession\"],null],false],[0,\"\\n        \"],[14],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"middle-filler\"],[13],[14],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"    \"],[11,\"div\",[]],[15,\"class\",\"middle-filler\"],[13],[14],[0,\"\\n    \"],[11,\"span\",[]],[15,\"class\",\"auto-disconnect-initialize\"],[13],[0,\"\\n        \"],[1,[26,[\"snail-progress\"]],false],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/acd-interactions-panel/interaction-session-share/session-share-auto-disconnect/template.hbs"
    }
  });

  _exports.default = _default;
});