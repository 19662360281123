define("web-directory/controllers/contacts", ["exports", "ember-data", "lodash", "web-directory/models/person"], function (_exports, _emberData, _lodash, _person) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ContactsController = Ember.Controller.extend({
    favoritesNameFilter: null,
    personBinding: 'session.person',
    isOnlyFavorite: false,
    canSearch: false,
    actions: {
      removeFavorite: function removeFavorite(person) {
        var _this = this;

        var path = this.get('applicationService').uri(['api/v2/people', this.get('person.id'), 'favorites', person.get('id')]);
        $.ajax({
          url: path,
          type: 'delete',
          dataType: 'json'
        }).then(function () {
          _this.get('person').reload();

          _this.get('filteredFavorites').removeObject(person);
        });
      },
      addFavorite: function addFavorite(person) {
        this.get('filteredFavorites').addObject(person);
      },
      toggleFavorite: function toggleFavorite(person, isFavorite) {
        if (person.content) {
          person = person.content;
        }

        if (isFavorite) {
          this.send('addFavorite', person);
        } else {
          this.send('removeFavorite', person);
        }
      }
    },
    recents: Ember.computed('person', 'model', function () {
      var apiEndpoint = _person.default.apiEndpoint;
      var url = "/api/".concat(apiEndpoint, "/").concat(this.get('person.id'), "/recents");
      var promise = new Ember.RSVP.Promise(function (resolve) {
        $.getJSON(url, {
          idOnly: true
        }).then(function (data) {
          resolve(data.res);
        });
      });
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    setLocalFavorites: Ember.observer('model', 'person', function () {
      var favorites = this.get('model');

      if (!_lodash.default.isEmpty(favorites)) {
        this.set('filteredFavorites', favorites);
      }
    }),
    favoritesFilterChanged: Ember.observer('favoritesNameFilter', function () {
      this.debouncedFavoritesFilterChanged();
    }),
    createDebouncedFavoritesFilterChanged: Ember.on('init', function () {
      var _this2 = this;

      this.debouncedFavoritesFilterChanged = _lodash.default.debounce(function () {
        Ember.run(function () {
          return _this2._filterChanged();
        });
      }, 250);
    }),
    _filterChanged: function _filterChanged() {
      var filter = this.get('favoritesNameFilter');
      var favorites = this.get('model');

      if (filter) {
        var filtered = this.get('model').filter(function (person) {
          return _lodash.default.includes(person.get('name').toLowerCase(), filter.toLowerCase());
        });
        this.setFavorites(filtered);
      } else {
        this.setFavorites(favorites);
      }
    },
    countFavorites: Ember.observer('filteredFavorites', 'person.favorites.content', 'view', function () {
      try {
        var list = this.get('filteredFavorites');
        this.set('isOnlyFavorite', list.length === 1);
      } catch (err) {
        this.set('isOnlyFavorite', false);
      }
    }),
    checkSearchVisibility: Ember.observer('filteredFavorites', 'person.favorites.content', function () {
      var list = this.get('filteredFavorites');

      if (list !== null && list !== undefined) {
        var value = list.length > 0;
        this.set('canSearch', value);
      }
    }),
    setFavorites: function setFavorites(favorites) {
      this.set('filteredFavorites', favorites);
    }
  });
  var _default = ContactsController;
  _exports.default = _default;
});