define("web-directory/components/application-preferences/theme-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yz4qDjja",
    "block": "{\"statements\":[[11,\"label\",[]],[15,\"class\",\"settings-header-text\"],[13],[1,[33,[\"t\"],[\"preferences.theme.header\"],null],false],[14],[0,\"\\n\\n\"],[11,\"div\",[]],[15,\"class\",\"theme-panel\"],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"themes\"]]],null,{\"statements\":[[0,\"        \"],[11,\"button\",[]],[16,\"class\",[34,[\"theme-card \",[28,[\"theme\",\"name\"]],\" \",[33,[\"if\"],[[28,[\"theme\",\"active\"]],\"active\"],null]]]],[16,\"onclick\",[33,[\"action\"],[[28,[null]],\"setTheme\",[28,[\"theme\"]]],null],null],[15,\"type\",\"button\"],[13],[0,\"\\n            \"],[11,\"p\",[]],[15,\"class\",\"setting-label\"],[13],[0,\"\\n                \"],[1,[33,[\"t\"],[[28,[\"theme\",\"label\"]]],null],false],[0,\"\\n            \"],[14],[0,\"\\n            \"],[11,\"img\",[]],[16,\"alt\",[33,[\"t\"],[\"images.userSubmitted\"],null],null],[16,\"src\",[28,[\"theme\",\"preview\"]],null],[13],[14],[0,\"\\n        \"],[14],[0,\"\\n\"]],\"locals\":[\"theme\"]},null],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/application-preferences/theme-picker/template.hbs"
    }
  });

  _exports.default = _default;
});