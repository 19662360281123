define("web-directory/components/entity-field-section/entity-location/component", ["exports", "ember-purecloud-components/mixins/logger", "web-directory/components/entity-field-section/component", "web-directory/components/entity-field-section/template"], function (_exports, _logger, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _component.default.extend(_logger.default, {
    layout: _template.default,
    scrollOnAddNew: false,
    disableAddValueDropdown: true,
    _cachedGroupFieldData: [],
    _cachedLocations: [],
    actions: {
      toggleAddValue: function toggleAddValue() {
        this.send('addNewEntry', this.get('section.fieldList.0'));
      },
      removeEntry: function removeEntry(fieldData) {
        var entity = this.get('entity');
        var field = fieldData.get('field');
        var entry = fieldData.get('entry');
        var location = fieldData.get('_sectionLocation');
        var path = field.getFieldPath();
        var fields = this.get('fieldData');
        var currentEntry = fields.findBy('_sectionLocation', location);
        fields.removeObject(currentEntry);
        entity.getFieldValue(path).removeObject(entry);
      }
    },
    disableSave: computed(function () {
      return false;
    }),
    getFieldEntries: function getFieldEntries() {
      var _this = this;

      var entries = this._super.apply(this, arguments);

      return entries.filter(function (entry) {
        var value = entry.get('value');

        if (value.value) {
          var location = value.value;

          if (location && !location.locationId) {
            _this.logger.warn('Location without id encountered. Removing.');

            return false;
          }
        }

        return true;
      });
    }
  });

  _exports.default = _default;
});