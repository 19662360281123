define("web-directory/helpers/html-safe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function () {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return Ember.String.htmlSafe(params[0]);
  });

  _exports.default = _default;
});