define("web-directory/services/modal", ["exports", "web-directory/utils/query-parser"], function (_exports, _queryParser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Service = Ember.Service;

  var _default = Service.extend({
    displayedModal: null,
    parsedQueryParams: null,
    modalData: {},
    dataHandler: function dataHandler() {},
    init: function init() {
      this._super.apply(this, arguments);

      this.set('parsedQueryParams', (0, _queryParser.parseQueryParams)());
    },
    showModal: function showModal(modalRequest, dataHandler) {
      this.set('displayedModal', modalRequest.modalId);
      this.set('modalData', modalRequest.modalData);
      this.set('dataHandler', dataHandler);
    },
    closeModal: function closeModal() {
      this.set('displayedModal', null);
    },
    onData: function onData(event) {
      var dataHandler = this.get('dataHandler');

      if (typeof dataHandler === 'function') {
        dataHandler(event);
      }
    }
  });

  _exports.default = _default;
});