define("web-directory/components/inbox-panel-v2/learning-assignments/component", ["exports", "web-directory/utils/dates"], function (_exports, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['inbox-message', 'learning-assignments-message', 'clickable', 'learning-assignments'],
    classNameBindings: ['isUnread:unread'],
    hovered: false,
    application: Ember.inject.service(),
    clock: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    frameService: Ember.inject.service('center-stage-frame'),
    mouseEnter: function mouseEnter() {
      this.set('hovered', true);
    },
    mouseLeave: function mouseLeave() {
      this.set('hovered', false);
    },
    actions: {
      openAssignment: function openAssignment() {
        var assignment = this.get('learningAssignment');
        var appsUri = this.get('application.purecloudAppsUri');
        var centerStageClients = this.get('frameService.centerStageClients');
        var learningAppUri = new URL(centerStageClients.learningModules.url);
        var assignmentUri = new URL("".concat(learningAppUri.pathname, "#/assignments/").concat(assignment.id), appsUri);
        window.open(assignmentUri.href, '_blank');
        this.set('showDropdown', false);
      },
      openDevelopmentPlan: function openDevelopmentPlan() {
        var appsUri = this.get('application.purecloudAppsUri');
        var startDate = (0, _dates.default)(this.get('learningAssignment.dateRecommendedForCompletion')).startOf('week').format('YYYY-MM-DD');
        var endDate = (0, _dates.default)(startDate).add(1, 'years').format('YYYY-MM-DD');
        var path = "/directory/#/analytics/agents/development/me?dateSpan=custom&start=".concat(startDate, "&end=").concat(endDate, "&intervalKeyValue=Due");
        var developmentUri = new URL(path, appsUri);
        window.open(developmentUri.href, '_self');
        this.set('showDropdown', false);
      }
    },
    isModuleOverdue: Ember.computed('learningAssignment.isOverdue', 'session.features', function () {
      var isOverdue = this.get('learningAssignment.isOverdue');
      var isFeatureToggleEnabled = this.get('session.features')['learning.release.assessment.ui'];
      return isOverdue && isFeatureToggleEnabled;
    }),
    isUnread: Ember.computed('learningAssignment.state', function () {
      var assignment = this.get('learningAssignment');
      return assignment.state === 'Assigned' || assignment.state === 'InvalidSchedule';
    }),
    date: Ember.computed('learningAssignment.dateRecommendedForCompletion', function () {
      var date = (0, _dates.default)(this.get('learningAssignment.dateRecommendedForCompletion'));
      return date.locale(this.get('intl.isoLocale')).format('LL');
    }),
    date2: Ember.computed('learningAssignment.dateRecommendedForCompletion', function () {
      var date = (0, _dates.default)(this.get('learningAssignment.dateRecommendedForCompletion'));
      return date.locale(this.get('intl.isoLocale')).format('ll');
    }),
    time: Ember.computed('learningAssignment.dateRecommendedForCompletion', function () {
      var date = (0, _dates.default)(this.get('learningAssignment.dateRecommendedForCompletion'));
      return date.locale(this.get('intl.isoLocale')).format('LT');
    }),
    startTime: Ember.computed('learningAssignment.dateRecommendedForCompletion', 'learningAssignment.module.lengthInMinutes', function () {
      var date = (0, _dates.default)(this.get('learningAssignment.dateRecommendedForCompletion'));

      var duration = _dates.default.duration(this.get('learningAssignment.lengthInMinutes'), 'minutes');

      var startTime = date.subtract(duration);
      return startTime.locale(this.get('intl.isoLocale')).format('LT');
    }),
    duration: Ember.computed('learningAssignment.lengthInMinutes', function () {
      var duration = this.get('learningAssignment.lengthInMinutes');
      var hours = Math.floor(duration / 60);
      var minutes = Math.floor(duration % 60);

      if (hours) {
        return this.get('intl').t('components.timeElapsed.detail.hoursMinutes', {
          hours: hours,
          minutes: minutes
        });
      }

      return this.get('intl').t('components.timeElapsed.detail.minutes', {
        minutes: minutes
      });
    }),
    dateFromNow: Ember.computed('clock.minute', 'learningAssignment.dateRecommendedForCompletion', function () {
      var date = (0, _dates.default)(this.get('learningAssignment.dateRecommendedForCompletion'));
      return date.locale(this.get('intl.isoLocale')).fromNow();
    }),
    hasSchedule: Ember.computed('learningAssignment.lengthInMinutes', function () {
      return !!this.get('learningAssignment.lengthInMinutes');
    }),
    shouldShowInvalidMessage: Ember.computed('learningAssignment.state', function () {
      return this.get('learningAssignment.state') === 'InvalidSchedule';
    })
  });

  _exports.default = _default;
});