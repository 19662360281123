define("web-directory/services/adminui", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var defaultLocale = 'en';

  function isValidPolicy(policy, permission) {
    var policies = policy.split(':');
    var permissions = permission.split(':');
    return policies.reduce(function (isValid, policy, index) {
      if (!isValid) {
        return false;
      }

      var permission = permissions[index];

      if (!permission) {
        return true;
      }

      return permission.split(',').includes(policy);
    }, true);
  }

  function isAuthorized(rules, permissions, roles, userType) {
    if (!rules || !rules.length || userType === 'super') {
      return true;
    }

    return rules.reduce(function (isAuthed, rule) {
      if (isAuthed) {
        return true;
      }

      var _rule$split = rule.split('='),
          _rule$split2 = _slicedToArray(_rule$split, 2),
          key = _rule$split2[0],
          val = _rule$split2[1];

      switch (key) {
        case 'permission':
          return permissions.indexOf(val) >= 0;

        case 'policy':
          return permissions.reduce(function (isValid, permission) {
            return isValid || permission.match(':') && isValidPolicy(val, permission);
          }, false);

        case 'user':
          return userType === val;

        case 'role':
          return roles.indexOf(val) >= 0;

        default:
          return isAuthed;
      }
    }, false);
  }

  var _default = Ember.Service.extend(Ember.Evented, {
    feature: Ember.inject.service(),
    permissions: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notification: Ember.inject.service(),
    search: Ember.inject.service(),
    currentRouteName: '',
    loadingPromise: null,
    menuJSON: null,
    adminPath: null,
    // observed by the embedded-admin component to update its iframe src
    toast: function toast(data) {
      var notificationType = 'info';

      if (data.type === 'success' || data.type === 'error') {
        notificationType = data.type;
      }

      this.get('notification')[notificationType](data.title, data.message, Object.assign({}, {
        markdownToHtml: false,
        shouldShowPopup: true,
        shouldPlaySound: false
      }, data));
    },
    getMenuBreadcrumbs: function getMenuBreadcrumbs(location) {
      var _this = this;

      var menuJSON = this.get('menuJSON');

      if (!menuJSON) {
        return;
      }

      var _this$get = this.get('menuJSON'),
          menu = _this$get.menu,
          translations = _this$get.translations;

      var menuItemsPath = this.getMenuPathToLocation("#/".concat(location), menu, []);
      var breadcrumbPath = menuItemsPath.map(function (item) {
        item.value = _this.getTranslatedText(item.key, translations);

        if (_lodash.default.get(item, 'hash')) {
          item.hash = item.hash.replace(/#\//, '#/admin/');
        }

        return item;
      });
      return {
        crumbs: breadcrumbPath,
        home: false
      };
    },
    getMenuPathToLocation: function getMenuPathToLocation(location, subMenu, currentPath) {
      var menuKeys = Object.keys(subMenu);

      for (var i = 0; i < menuKeys.length; i++) {
        var menuKey = menuKeys[i];
        var menuItem = subMenu[menuKey];

        var menuItemPath = _lodash.default.get(menuItem, 'path');

        if (menuItemPath && new RegExp(menuItemPath, 'i').test(location)) {
          currentPath.push({
            key: menuKey,
            hash: menuItemPath
          }); // currentPath.push({ key: menuKey, hash: null }); // matched location should be text not a link

          return currentPath;
        }

        var children = _lodash.default.get(menuItem, 'children');

        if (children) {
          currentPath.push({
            key: menuKey,
            hash: menuItemPath || null
          });
          var pathLength = currentPath.length;
          var result = this.getMenuPathToLocation(location, children, currentPath);

          if (result.length > pathLength) {
            return currentPath; // location found in submenu!
          } else {
            currentPath.pop(); //location not found in submenu
          }
        }
      }

      return currentPath;
    },
    getTranslatedText: function getTranslatedText(key, translations) {
      var locale = this.getLocale();
      var localeVal = translations[locale] && translations[locale][key];

      if (localeVal) {
        return localeVal;
      }

      var defaultVal = translations[defaultLocale] && translations[defaultLocale][key];

      if (defaultVal) {
        return defaultVal;
      }

      return key;
    },
    getLocale: function getLocale() {
      var _this$get2 = this.get('menuJSON'),
          translations = _this$get2.translations;

      var locale = this.get('intl.isoLocale').replace(/([a-z]{2}).([a-z]{2})/i, '$1-$2').toLowerCase();

      if (!translations[locale]) {
        locale = defaultLocale;
      }

      return locale;
    },
    adminEntries: [],
    loadAdminEntries: Ember.on('init', Ember.observer('permissions.permissions', 'permissions.donutPermissions', 'session.user.userType', function () {
      var _this2 = this;

      var permissions = this.get('permissions.permissions') || [];
      var donutPermissions = this.get('permissions.donutPermissions') || [];
      var userType = this.get('session.user.userType');

      if (userType !== 'super' && !permissions.length && !donutPermissions.length) {
        return;
      }

      var featureService = this.get('feature');
      donutPermissions.forEach(function (policy) {
        var segment = policy.split(':')[2];
        var actions = segment && segment.split(',');
        var domainAndEntity = policy.substr(0, policy.lastIndexOf(':'));

        _lodash.default.forEach(actions, function (action) {
          permissions.push("".concat(domainAndEntity, ":").concat(action));
        });
      });
      var roles = this.get('session.roleNames');
      var menuUrl = '/admin/menu.json';
      var promise = this.get('ajax').request(menuUrl).then(function (response) {
        var menu = response.menu,
            translations = response.translations;

        _this2.set('menuJSON', {
          menu: menu,
          translations: translations
        }); // for use by breadcrumbs
        // collect all features to check against


        var requiredFeatures = [];

        _lodash.default.forOwn(menu, function (menuItem) {
          if (menuItem && menuItem.attributes && menuItem.attributes.feature) {
            requiredFeatures = requiredFeatures.concat(menuItem.attributes.feature.split(' ').map(function (f) {
              return f.replace(/^!/, '');
            }));
          }

          _lodash.default.forOwn(menuItem.children, function (menuSubItem) {
            if (menuSubItem && menuSubItem.attributes && menuSubItem.attributes.feature) {
              requiredFeatures = requiredFeatures.concat(menuSubItem.attributes.feature.split(' ').map(function (f) {
                return f.replace(/^!/, '');
              }));
            }
          });
        }); // get feature toggles from feature service


        return featureService.getFeatureToggles.apply(featureService, _toConsumableArray(requiredFeatures)).then(function (features) {
          // Checks whether the feature associated with this menu item is turned on
          function isAvailable(attributes) {
            // If we have an associated feature...
            if (attributes && attributes.feature) {
              // featureString may contain more than one feature to check in the form of `feature1 feature2`
              // so we split this string by spaces and show the menu item iff all contained features are
              // turned on
              var featureString = attributes.feature;
              return _lodash.default.every(featureString.split(' ').map(function (feature) {
                if (feature.match(/^!/)) {
                  return !features[feature.replace(/^!/, '')];
                } else {
                  return features[feature];
                }
              })); // If there's no feature associated, just turn it on
            } else {
              return true;
            }
          } // check whether the logged in user should be able to see the menu item


          function isAccessible(menuItem) {
            var attributes = menuItem.attributes,
                authorize = menuItem.authorize;
            var allowed = isAuthorized(authorize, permissions, roles, userType);
            var availible = isAvailable(attributes);
            return allowed && availible;
          }

          var entries = [];

          _lodash.default.forOwn(menu, function (menuItem, headingKey) {
            if (!isAccessible(menuItem)) {
              return;
            }

            var subEntries = [];

            _lodash.default.forOwn(menuItem.children, function (menuSubItem, subheadingKey) {
              if (subheadingKey === 'CONTACTS') {
                return;
              }

              if (!isAccessible(menuSubItem)) {
                return;
              }

              subEntries.push({
                id: subheadingKey,
                className: "menu-".concat(subheadingKey.toLowerCase()),
                text: _this2.getTranslatedText(subheadingKey, translations),
                href: menuSubItem.direct ? menuSubItem.path : menuSubItem.path.replace(/^#\//, ''),
                basePath: menuSubItem.basePath,
                routePrefix: menuSubItem.routePrefix,
                subRoute: menuSubItem.route,
                route: menuSubItem.emberRoute,
                attributes: menuSubItem.attributes,
                external: menuSubItem.external,
                direct: menuSubItem.direct
              });
            });

            var processTreeNode = function processTreeNode(treeNode) {
              if (treeNode.section && Array.isArray(treeNode.children)) {
                return {
                  id: treeNode.section,
                  text: _this2.getTranslatedText(treeNode.section, translations),
                  className: "menu-".concat(headingKey.toLowerCase(), "-subgroup-").concat(treeNode.section.toLowerCase()),
                  subLinks: treeNode.children.map(processTreeNode).filter(function (node) {
                    return node;
                  })
                };
              } else if (treeNode.link) {
                return subEntries.find(function (subEntry) {
                  return subEntry.id === treeNode.link;
                });
              }

              return undefined;
            };

            var tree = (Array.isArray(menuItem.tree) ? menuItem.tree : []).map(processTreeNode).filter(function (node) {
              return node;
            });

            if (subEntries.length || menuItem.includeLinksFrom) {
              entries.push({
                id: headingKey,
                className: "menu-".concat(headingKey.toLowerCase()),
                text: _this2.getTranslatedText(headingKey, translations),
                href: subEntries.length ? subEntries[0].href : null,
                subLinks: subEntries,
                tree: tree,
                includeLinksFrom: menuItem.includeLinksFrom,
                attributes: menuItem.attributes
              });
            }
          });

          if (!_this2.isDestroyed) {
            _this2.set('adminEntries', entries);
          }
        });
      });
      this.set('loadingPromise', promise);
    }))
  });

  _exports.default = _default;
});