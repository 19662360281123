define("web-directory/components/edit-profile-picture/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['edit-profile-picture'],
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    application: Ember.inject.service(),
    entity: null,
    editing: false,
    canUploadPicture: true,
    canEditProfilePicture: true,
    canEditInformalPicture: true,
    canEditAgentImage: false,
    showingProfile: true,
    showingInformal: false,
    openCaption: null,
    currentInformal: null,
    attrs: {
      upload: function upload() {},
      select: function select() {},
      delete: function _delete() {},
      changeType: function changeType() {}
    },
    actions: {
      showInformalPhotos: function showInformalPhotos() {
        this.set('showingProfile', false);
        this.set('showingInformal', true);
        this.set('showingAgent', false);
        this.get('changeType')('informal');
      },
      showProfilePictures: function showProfilePictures() {
        this.set('showingProfile', true);
        this.set('showingInformal', false);
        this.set('showingAgent', false);
        this.get('changeType')('profile');
      },
      showAgentPhotos: function showAgentPhotos() {
        this.set('showingProfile', false);
        this.set('showingInformal', false);
        this.set('showingAgent', true);
        this.get('changeType')('agent');
      },
      uploadImage: function uploadImage(type) {
        this.get('upload')(type);
      },
      selectUploadedImage: function selectUploadedImage(type, image, active) {
        this.get('select')(type, image, active);
      },
      deleteUploadedImage: function deleteUploadedImage(type, image, active) {
        this.get('delete')(type, image, active);
      },
      removeAgentImage: function removeAgentImage() {
        var entity = this.get('entity');
        entity.set('agent.profileImage', [], {
          overwrite: true
        });
        var section = entity.get('fieldConfig.agent');
        entity.saveSection(section);
      }
    },
    scrollActiveImageIntoView: Ember.on('didInsertElement', function () {
      Ember.run.scheduleOnce('afterRender', this, function () {
        var activeImg = this.$('img.active'),
            picturesContainer = this.$('.pictures');

        if (activeImg.length > 0 && picturesContainer.length > 0) {
          var target = activeImg[0],
              parent = picturesContainer[0];
          parent.scrollTop = target.parentNode.offsetTop;
        }
      });
    }),
    emptyProfilesText: Ember.computed('entity.type', 'application.session.person.id', 'entity.id', function () {
      var lookup = 'images.upload.yourEmptyProfile';

      if (this.get('entity.type') === 'group') {
        lookup = 'images.upload.groupsEmptyProfile';
      } else if (this.get('application.session.person.id') !== this.get('entity.id')) {
        lookup = 'images.upload.othersEmptyProfile';
      }

      return this.get('intl').lookup(lookup);
    }),
    emptyInformalsText: Ember.computed('entity.type', 'application.session.person.id', 'entity.id', function () {
      var lookup = 'images.upload.yourEmptyInformal';

      if (this.get('entity.type') === 'group') {
        lookup = 'images.upload.groupsEmptyInformal';
      } else if (this.get('application.session.person.id') !== this.get('entity.id')) {
        lookup = 'images.upload.othersEmptyInformal';
      }

      return this.get('intl').lookup(lookup);
    }),
    profileInstructionText: Ember.computed(function () {
      if (this.get('intl').query('person_images_profile_instructionText')) {
        return this.get('intl').lookup('person_images_profile_instructionText').toString();
      }

      return '';
    }),
    informalInstructionText: Ember.computed(function () {
      if (this.get('intl').query('person_images_informal_instructionText')) {
        return this.get('intl').lookup('person_images_informal_instructionText').toString();
      }

      return '';
    }),
    agentImage: Ember.computed('entity.agent.profileImage.@each.value', function () {
      var image = this.get('entity').getFieldValue('agent.profileImage');

      if (_lodash.default.isEmpty(image) || _lodash.default.isEmpty(image.value)) {
        return null;
      }

      return image.value.x200 || image.value.x800 || image.value.x1024;
    })
  });

  _exports.default = _default;
});