define("web-directory/mixins/model-route", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      error: function error(_error) {
        var errorState = {
          type: 'profileFetchServerFailure'
        };
        Ember.Logger.error(_error);

        if (_error && _error.errors) {
          _error = _lodash.default.head(_error.errors);
        }

        if (_error) {
          var code = _error.code;

          if (typeof _error.status === 'number') {
            code = _error.status;
          }

          if (code >= 400 && code < 500) {
            this.transitionTo('index');
          } else if (_error.status >= 500) {
            this.set('application.errorState', errorState);
            this.transitionTo('maintenance');
          }
        } else if (!_error) {
          this.set('application.errorState', errorState);
          this.transitionTo('maintenance');
        }
      }
    }
  });

  _exports.default = _default;
});