define("web-directory/components/nav-sidebar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V/FHt3cf",
    "block": "{\"statements\":[[1,[33,[\"elm-component\"],null,[[\"src\",\"setup\",\"flags\"],[[28,[\"Elm\",\"NavSidebar\"]],[33,[\"action\"],[[28,[null]],\"setupPorts\"],null],[28,[\"flags\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/nav-sidebar/template.hbs"
    }
  });

  _exports.default = _default;
});