define("web-directory/models/internally-hosted-app", ["exports", "lodash", "web-directory/models/app", "web-directory/utils/apps", "web-directory/utils/net"], function (_exports, _lodash, _app, _apps, _net) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var getOwner = Ember.getOwner,
      computed = Ember.computed,
      _copy = Ember.copy,
      typeOf = Ember.typeOf,
      isPresent = Ember.isPresent;
  /**
   * An internally-built PureCloud Client App that is hosted on PureCloud's own infrastructure.
   *
   * The url will be dynamically generated based on the specified config:
   *
   * subdomain=apps: Optional. Subdomain to be prepended to the current purecloud domain.
   * envDetails: Required.  The environment details to build the PureCloud domain.
   * path: Required. The path to the app.  Leading slash will be prepended if omitted.
   * query=?gcHostOrigin={{gcHostOrigin}}&gcTargetEnv={{gcTargetEnv}}&gcLangTag={{gcLangTag}}&gcUsePopupAuth={{gcUsePopupAuth}}: Optional. The querystring for the app. Leading ? will be prepended if omitted.
   *
   * [SECURITY NOTE]: Because these apps will have the same purecloud env tld as the host PureCloud app,
   * they can elevate out of the iframe sandbox.  Because of this, this must always be internal code.
   * This is NOT a hosting option for external apps.
   */

  var InternallyHostedApp = _app.default.extend({
    subdomain: null,
    envDetails: null,
    path: null,
    query: null,
    urlTemplate: computed('subdomain', 'envDetails', 'path', 'query', 'gcHostOrigin', 'gcTargetEnv', {
      get: function get() {
        var result = null;

        var subdomain = this.get('subdomain') || _net.default.getPcApplicationsSubdomain();

        var envDetails = this.get('envDetails');
        var path = this.get('path');
        var query = this.get('query');

        if (typeOf(path) === 'string' && isPresent(path) && envDetails) {
          result = "https://".concat(subdomain, ".").concat(envDetails.runtime.currentDomainName);
          path = path.trim();

          if (path.charAt(0) !== '/') {
            result += '/';
          }

          result += path;

          if (query && typeOf(query) === 'string') {
            query = query.trim();

            if (query.charAt(0) !== '?') {
              result += '?';
            }

            result += query;
          } else {
            result += "?gcHostOrigin=".concat(_app.GC_HOST_ORIGIN_REPLACEMENT_TOKEN, "&gcTargetEnv=").concat(_app.GC_TARGET_ENV_REPLACEMENT_TOKEN, "&pcEnvironment=").concat(_app.PC_ENV_URL_REPLACEMENT_TOKEN, "&pcLangTag=").concat(_app.GC_LANG_TAG_URL_REPLACEMENT_TOKEN, "&pcUsePopupAuth=").concat(_app.GC_USE_POPUP_AUTH_URL_REPLACEMENT_TOKEN, "&gcLangTag=").concat(_app.GC_LANG_TAG_URL_REPLACEMENT_TOKEN, "&gcUsePopupAuth=").concat(_app.GC_USE_POPUP_AUTH_URL_REPLACEMENT_TOKEN);
          }
        }

        return result;
      },
      set: function set() {
        Ember.Logger.error(_apps.default.buildLogMsg('Set called on derrived urlTemplate property instead of path property.'), {
          path: this.get('path'),
          remoteData: {
            appId: this.get('id')
          }
        });
        throw new Error('Cannot directly set urlTemplate. Use path property.');
      }
    }),
    copy: function copy(deep) {
      var owner = getOwner(this);
      var ownerMixin = {};

      if (owner) {
        ownerMixin = owner.ownerInjection();
      }

      return InternallyHostedApp.create(ownerMixin, {
        typeId: this.get('typeId'),
        id: this.get('id'),
        name: this.get('name'),
        subdomain: this.get('subdomain'),
        envDetails: _copy(this.get('envDetails'), deep),
        path: this.get('path'),
        query: this.get('query'),
        langTag: this.get('langTag'),
        envTld: this.get('envTld'),
        usePopupAuth: this.get('usePopupAuth'),
        displayType: this.get('displayType'),
        featureCategory: this.get('featureCategory'),
        sandbox: deep ? _copy(this.get('sandbox'), true) : this.get('sandbox'),
        assets: _copy(this.get('assets'), deep),
        lifecycle: _copy(this.get('lifecycle'), deep),
        permissionsPolicy: deep ? _copy(this.get('permissionsPolicy'), true) : this.get('permissionsPolicy'),
        // interapption props
        interactionId: this.get('interactionId'),
        interactionType: this.get('interactionType'),
        enabledQueueIds: deep ? _lodash.default.cloneDeep(this.get('enabledQueueIds')) : this.get('enabledQueueIds'),
        enabledCommunicationTypes: deep ? _lodash.default.cloneDeep(this.get('enabledCommunicationTypes')) : this.get('enabledCommunicationTypes'),
        enableWorkitem: this.get('enableWorkitem'),
        gcHostOrigin: this.get('gcHostOrigin'),
        gcTargetEnv: this.get('gcTargetEnv')
      });
    }
  });

  var _default = InternallyHostedApp;
  _exports.default = _default;
});