define("web-directory/utils/net", ["exports", "@genesys/service-discovery-web-internal"], function (_exports, _serviceDiscoveryWebInternal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseRuntimeCustom = _exports.isExternalDomain = _exports.getEnvDetails = _exports.getEnvCookieDomain = _exports.getCurrentWindowOrigin = _exports.default = _exports.STABLE_PUBLIC_ENVS = _exports.PUBLIC_ENVS = void 0;
  var VALID_LOCAL_HOSTNAMES = ['localhost', 'localhost.mypurecloud.com'];
  var ENV_TRANSITIONS = {
    dev: 'dca',
    test: 'tca',
    prod: 'pca' // DO NOT ADD NEW ENVS HERE
    // New envs will be defaulted to their true value in service-discovery-web

  };
  var DEPLOYMENT_ID_MAP = {
    dev: 'dca',
    test: 'tca',
    prod: 'us',
    'prod-apne1': 'jp',
    'prod-apne2': 'apne2',
    'prod-aps1': 'aps1',
    'prod-apse2': 'au',
    'prod-cac1': 'cac1',
    'prod-euc1': 'de',
    'prod-euw1': 'ie',
    'prod-euw2': 'euw2',
    'prod-usw2': 'usw2' // DO NOT ADD NEW REGIONS HERE
    // New regions will be defaulted to their name in service-discovery-web

  };
  var REGION_TRANSLATION_KEY_MAP = {
    prod: 'components.region.global',
    'prod-apne1': 'components.region.japan',
    'prod-apne2': 'components.region.apne2',
    'prod-aps1': 'components.region.aps1',
    'prod-apse2': 'components.region.australia',
    'prod-cac1': 'components.region.cac1',
    'prod-euc1': 'components.region.germany',
    'prod-euw1': 'components.region.ireland',
    'prod-euw2': 'components.region.euw2',
    'prod-usw2': 'components.region.usw2' // DO NOT ADD NEW REGIONS HERE
    // New regions will be defaulted to 'name': `components.region.${name}`

  };

  var getPcApplicationsSubdomain = function getPcApplicationsSubdomain() {
    var _window = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window;

    // window passed in for testing
    var subdomain = _window.location.hostname.split('.')[0];

    return subdomain.toLowerCase() === 'app' ? 'app' : 'apps';
  };

  var getEnv = function getEnv(_ref) {
    var env = _ref.env;
    return ENV_TRANSITIONS[env] || env;
  };

  var getRegion = function getRegion(_ref2) {
    var name = _ref2.name,
        region = _ref2.region;
    return DEPLOYMENT_ID_MAP[name] || region;
  };

  var getDeploymentId = function getDeploymentId(_ref3) {
    var name = _ref3.name;
    return DEPLOYMENT_ID_MAP[name] || name;
  };

  var getRegionTranslationKey = function getRegionTranslationKey(_ref4) {
    var name = _ref4.name;
    return REGION_TRANSLATION_KEY_MAP[name] || "components.region.".concat(name);
  };

  var getDesktopId = function getDesktopId(environment) {
    var deploymentId = getDeploymentId(environment);
    return deploymentId === 'us' ? 'global' : deploymentId;
  };

  var publicEnvFilter = {
    env: ['prod', 'fedramp'],
    status: ['beta', 'stable']
  };

  var PUBLIC_ENVS = _serviceDiscoveryWebInternal.default.getEnvironments(publicEnvFilter).map(function (environment) {
    return {
      id: getDeploymentId(environment),
      desktopId: getDesktopId(environment),
      region: getRegion(environment),
      translationKey: getRegionTranslationKey(environment),
      env: getEnv(environment),
      envTld: environment.publicDomainName,
      envStatus: environment.status
    };
  });

  _exports.PUBLIC_ENVS = PUBLIC_ENVS;

  var STABLE_PUBLIC_ENVS = _serviceDiscoveryWebInternal.default.getEnvironments().map(function (environment) {
    return {
      id: getDeploymentId(environment),
      desktopId: getDesktopId(environment),
      region: getRegion(environment),
      translationKey: getRegionTranslationKey(environment),
      env: getEnv(environment),
      envTld: environment.publicDomainName
    };
  });

  _exports.STABLE_PUBLIC_ENVS = STABLE_PUBLIC_ENVS;

  var parseRuntimeCustom = function parseRuntimeCustom() {
    var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.location.href;
    return _serviceDiscoveryWebInternal.default.parseRuntimeFromLocalPath(VALID_LOCAL_HOSTNAMES)(url) || _serviceDiscoveryWebInternal.default.parseDeployedRuntime(url);
  };

  _exports.parseRuntimeCustom = parseRuntimeCustom;

  var getEnvDetails = function getEnvDetails() {
    var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.location.href;
    if (!url || typeof url !== 'string') throw new Error('url must be a non-empty string');
    var runtime = parseRuntimeCustom(url);
    if (!runtime) throw new Error("'".concat(url, "' is not a valid, known Genesys Cloud app location"));
    return {
      runtime: runtime,
      // Attach the service-discovery-web runtime
      local: runtime.local,
      envTld: runtime.local ? new URL(url).hostname : runtime.currentDomainName,
      env: getEnv(runtime),
      region: ['prod', 'fedramp'].includes(runtime.env) ? getRegion(runtime) : null
    };
  };

  _exports.getEnvDetails = getEnvDetails;

  var getEnvCookieDomain = function getEnvCookieDomain() {
    var hostname = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.location.hostname;

    if (!hostname || typeof hostname !== 'string') {
      throw new Error('hostname must be a non-empty string');
    } // Handle the local special cases


    if (hostname === 'localhost') return '';
    if (hostname === 'localhost.mypurecloud.com') return ".localhost.mypurecloud.com";

    var result = _serviceDiscoveryWebInternal.default.parseDeployedRuntime("https://".concat(hostname));

    if (!result) throw new Error("'".concat(hostname, "' is not a valid, known Genesys Cloud app location"));
    return ".".concat(result.currentDomainName);
  };

  _exports.getEnvCookieDomain = getEnvCookieDomain;

  var isExternalDomain = function isExternalDomain(url) {
    return !_serviceDiscoveryWebInternal.default.isKnown(url, {});
  };

  _exports.isExternalDomain = isExternalDomain;

  var getCurrentWindowOrigin = function getCurrentWindowOrigin() {
    return window.location.origin;
  };

  _exports.getCurrentWindowOrigin = getCurrentWindowOrigin;
  var _default = {
    PUBLIC_ENVS: PUBLIC_ENVS,
    STABLE_PUBLIC_ENVS: STABLE_PUBLIC_ENVS,
    getPcApplicationsSubdomain: getPcApplicationsSubdomain,
    getEnvDetails: getEnvDetails,
    getEnvCookieDomain: getEnvCookieDomain,
    isExternalDomain: isExternalDomain,
    getCurrentWindowOrigin: getCurrentWindowOrigin
  };
  _exports.default = _default;
});