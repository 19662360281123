define("web-directory/mixins/external-contacts", ["exports", "web-directory/mixins/relate/relate-features", "web-directory/utils/external-contact-messaging"], function (_exports, _relateFeatures, _externalContactMessaging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;
  var computed = Ember.computed,
      observer = Ember.observer;

  var _default = Ember.Mixin.create(_relateFeatures.default, {
    relate: inject.service(),
    names: inject.service(),
    externalContacts: inject.service(),
    externalContactProfiles: null,
    conversationId: computed.alias('interaction.id'),
    mediaType: computed.alias('interaction.type'),
    participants: computed.alias('interaction.participants'),
    consultAgentParticipant: computed.alias('interaction.consultAgentParticipant'),
    consultParticipant: computed.alias('interaction.consultParticipant'),
    communicationId: computed.alias('interaction.communicationId'),
    hasMultipleExternalContactMatches: computed.gt('externalContactProfiles.length', 1),
    isIncomingFromExternalContacts: computed.and('hasRelateAccess', 'isIncoming', 'hasExternalContactProfiles'),
    isConnectedWithExternalContact: computed.and('hasRelateAccess', 'isConnected', 'hasOneExternalContactProfile'),
    hasExternalContactProfiles: computed.notEmpty('externalContactProfiles'),
    hasOneExternalContactProfile: computed.equal('externalContactProfiles.length', 1),

    /**
     * Returns the "class" of the thing that mixes in this mixin (the "inheritor")
     * @private
     */
    _nameForInheritor: function _nameForInheritor() {
      return _externalContactMessaging.componentClassName.call(this);
    },
    // This CP is used by the navbar.hbs template for for direct incoming (not to an agent's queue) calls
    externalContactFullName: computed('externalContactProfiles.[]', function () {
      var externalProfile = this.get('externalContactProfiles.firstObject');

      if (externalProfile) {
        return this.get('names').fullName(externalProfile);
      }

      return this.get('otherPartyThatIsNotTheInitiator.calls.firstObject.other.name');
    }),
    cleanup: observer('interaction.isInteractionActive', function () {
      if (!this.get('interaction.isInteractionActive')) {
        this.get('relate').interactionEnded(this.get('interaction.id'));
      }
    })
  });

  _exports.default = _default;
});