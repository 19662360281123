define("web-directory/components/audio-player/seek-bar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed,
      Component = Ember.Component;

  var _default = Component.extend({
    classNames: ['seek-bar', 'clickable'],
    clock: inject.service(),
    attrs: {
      sound: null,
      duration: null,
      currentTime: null
    },
    didReceiveAttrs: function didReceiveAttrs(_ref) {
      var _this = this;

      var newAttrs = _ref.newAttrs,
          oldAttrs = _ref.oldAttrs;

      this._super.apply(this, arguments);

      if (!newAttrs || !oldAttrs) {
        return;
      }

      if (newAttrs.currentTime.value !== oldAttrs.currentTime.value) {
        var position = this.get('position');
        var left = position * 100;

        if (left > 100) {
          left = 100;
        }

        Ember.run(function () {
          _this.$('.progress-bar').css('margin-left', "".concat(left, "%"));
        });
      }
    },
    click: function click(event) {
      var seek = event.offsetX / this.$().width();
      var totalSeconds = this.getAttr('duration') / 1000;
      this.sendAction('didSeek', seek * totalSeconds);
    },
    position: computed('sound', 'currentTime', 'duration', function () {
      var sound = this.getAttr('sound');

      if (!sound) {
        return 0;
      }

      return this.getAttr('currentTime') / this.getAttr('duration');
    })
  });

  _exports.default = _default;
});