define("web-directory/components/entity-profile/section-icon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      Component = Ember.Component;

  var _default = Component.extend({
    classNames: ['section-icon', 'clickable'],
    classNameBindings: ['section.key'],
    attrs: {
      sectionInfo: null,
      addSection: function addSection() {}
    },
    section: computed.reads('sectionInfo.section'),
    icon: computed('section.key', function () {}),
    click: function click() {
      this.attrs.addSection(this.get('sectionInfo'));
    }
  });

  _exports.default = _default;
});