define("web-directory/initializers/interapption-providers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  /* global requirejs */
  function initialize(application) {
    var providerConfigMap = {};
    Object.keys(requirejs.entries).filter(function (entry) {
      return entry.startsWith('web-directory/app-providers/');
    }).forEach(function (entryName) {
      var providerName = entryName.replace('web-directory/app-providers/', '');
      var file = requirejs(entryName);
      application.register('app-provider:' + providerName, file.default, {
        singeleton: false,
        instantiate: true
      });
      providerConfigMap[providerName] = file.boolExpr;
    });
    application.register('app-provider:provider-config-map', providerConfigMap, {
      instantiate: false
    });
  }

  var _default = {
    name: 'interapption-providers',
    initialize: initialize
  };
  _exports.default = _default;
});