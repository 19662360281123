define("web-directory/services/names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    _getNamePart: function _getNamePart(externalContact, namePart) {
      var result = externalContact[namePart];
      return (typeof result === 'string' ? result : false) || (externalContact.get ? externalContact.get(namePart) : '');
    },
    _getNames: function _getNames(externalContact) {
      return {
        familyName: this._getNamePart(externalContact, 'lastName'),
        givenName: this._getNamePart(externalContact, 'firstName')
      };
    },
    initials: function initials(externalContact) {
      // TODO: RELATE-1038 UI - ACD Need to localize the code for harvesting initials for display when transferring a call to a Relate External contact
      var _this$_getNames = this._getNames(externalContact),
          familyName = _this$_getNames.familyName,
          givenName = _this$_getNames.givenName;

      if (givenName) {
        var givenNameInitial = givenName.charAt(0).toUpperCase();

        if (familyName) {
          var familyNameInitial = familyName.charAt(0).toUpperCase();
          return this.get('intl').t('purecloud.acdInteraction.relate.misc.initials', {
            givenNameInitial: givenNameInitial,
            familyNameInitial: familyNameInitial
          });
        }

        return givenNameInitial;
      }

      if (familyName) {
        return familyName.charAt(0).toUpperCase();
      }

      return '';
    },
    fullName: function fullName(externalContact) {
      if (!externalContact) {
        return;
      }

      var _this$_getNames2 = this._getNames(externalContact),
          familyName = _this$_getNames2.familyName,
          givenName = _this$_getNames2.givenName;

      var optionalMiddleName = this._getNamePart(externalContact, 'middleName') || null;

      if (familyName) {
        if (givenName) {
          return this.get('intl').lookup('purecloud.acdInteraction.relate.misc.fullName', {
            familyName: familyName,
            givenName: givenName,
            optionalMiddleName: optionalMiddleName
          });
        } else {
          return familyName;
        }
      }

      return givenName;
    }
  });

  _exports.default = _default;
});