define("web-directory/components/tab-ify/tab/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    activeSelected: Ember.observer('parentView.selected', function () {
      Ember.set(this.get('tab'), 'active', this.get('parentView.selected') === this.get('tab.key'));

      if (this.get('parentView.keys')) {
        this.set("parentView.keys.".concat(this.get('tab.key')), this.get('parentView.selected') === this.get('tab.key'));
      }
    }),
    classNames: ['item'],
    classNameBindings: ['tab.active:enabled', 'tab.id'],
    click: function click() {
      this.get('parentView').send('changeSelectedTab', this.get('tab'));
    }
  });

  _exports.default = _default;
});