define("web-directory/components/entity-fields/date-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OFNR3hkG",
    "block": "{\"statements\":[[11,\"div\",[]],[16,\"class\",[34,[\"field-value values \",[28,[\"entry\",\"id\"]]]]],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"canEdit\"]]],null,{\"statements\":[[0,\"        \"],[1,[33,[\"pikaday-input\"],null,[[\"value\",\"classNames\",\"format\",\"placeholder\",\"firstDay\",\"onSelection\",\"options\"],[[28,[\"value\"]],\"form-control\",[28,[\"dateFormat\"]],\"\",0,[33,[\"action\"],[[28,[null]],\"selectDate\"],null],[28,[\"options\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[6,[\"if\"],[[28,[\"entry\",\"value\"]]],null,{\"statements\":[[0,\"            \"],[11,\"span\",[]],[13],[1,[26,[\"formattedValue\"]],true],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"            \"],[11,\"span\",[]],[15,\"class\",\"empty-value\"],[13],[0,\"\\n\"],[0,\"            \"],[14],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[]}],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/entity-fields/date-field/template.hbs"
    }
  });

  _exports.default = _default;
});