define("web-directory/components/inbox-panel-v2/share-content/shared-entity-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w+1C2UVB",
    "block": "{\"statements\":[[1,[33,[\"entity-image\"],null,[[\"entity\",\"size\",\"showPresence\",\"subscribeToPresence\"],[[28,[\"entity\"]],96,true,true]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/inbox-panel-v2/share-content/shared-entity-image/template.hbs"
    }
  });

  _exports.default = _default;
});