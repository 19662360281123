define("web-directory/components/zoom-panel/component", ["exports", "web-directory/mixins/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_logger.default, {
    classNames: ['zoom-panel'],
    application: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    sidebar: Ember.inject.service(),
    notification: Ember.inject.service(),
    mediaProviders: Ember.inject.service(),
    frame: null,
    createZoomMeetingDeferred: null,
    deferredTimer: null,
    route: Ember.computed('mediaProviders.activeProvider.{id,data}', 'session.person.{name,contactInfo}', function () {
      var provider = this.get('mediaProviders.activeProvider');

      if (!provider) {
        return '/zoom/dummy';
      }

      var name = this.get('session.person.name');
      var email = this.get('session.person.contactInfo.email_main.firstObject.value') || '';
      var password = provider.get('data.password') || null;
      var url = "/zoom/".concat(provider.get('data.meetingId'));
      var params = new URLSearchParams();
      params.append('name', name);
      params.append('email', email);

      if (provider.id === 'zoom') {
        if (!!password) {
          params.append('password', password);
        }

        return "".concat(url, "?").concat(params.toString());
      }

      return '/';
    }),
    useLocalZoom: Ember.computed('mediaProviders.activeProvider.debug', function () {
      var debug = this.get('mediaProviders.activeProvider.debug');

      if (debug && debug.useLocalZoom) {
        return true;
      }

      return false;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.setupFrameRouter(true);
      this.get('mediaProviders').setCreateZoomMeetingHandler(this.createZoomMeeting.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      this.get('mediaProviders').removeCreateZoomMeetingHandler();
    },
    setupFrameRouter: function setupFrameRouter() {
      var _this = this;

      var addListeners = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var appUrl = this.appUrl();
      var frame = this.element.querySelector('frame-router');
      frame.setupFrames({
        purecloud: {
          url: "".concat(appUrl, "/#/"),
          assignedRoute: '/'
        },
        zoom: {
          url: "".concat(appUrl, "/#/zoom"),
          assignedRoute: '/zoom',
          allow: 'microphone *; camera *'
        }
      }, {
        locale: this.get('intl.isoRegionalLocale'),
        hostRootUrl: window.location.origin
      });

      if (addListeners) {
        frame.messaging.addListener('notification', Ember.run.bind(this, this.handleNotification));
        frame.messaging.addListener('leaveMeeting', Ember.run.bind(this, function () {
          _this.get('sidebar').openPanel('conversations');

          _this.set('mediaProviders.activeProvider', null);
        }));
        frame.messaging.addListener('createdZoomMeeting', Ember.run.bind(this, this.handleCreatedZoomMeeting));
      }

      this.set('frame', frame);
    },
    appUrl: function appUrl() {
      if (this.get('application').get('runtime').local) {
        return 'https://localhost:5000';
      }

      return "".concat(window.location.origin, "/zoom-integration");
    },
    createZoomMeeting: function createZoomMeeting(data) {
      var _this2 = this;

      if (this.get('createZoomMeetingDeferred')) {
        return this.get('createZoomMeetingDeferred');
      }

      var frame = this.frame;

      if (frame) {
        var deferred = Ember.RSVP.defer();
        this.set('createZoomMeetingDeferred', deferred);

        try {
          frame.publish({
            topic: 'createZoomMeeting',
            payload: data
          });
        } catch (error) {
          this.logger.error('Error publishing to iframe', {
            error: error
          });
          return Ember.RSVP.reject(new Error('Could not publish to the zoom frame to create meeting'));
        } // Lets give the frame 10 seconds to respond


        var deferredTimer = window.setTimeout(function () {
          var currentDeferred = _this2.get('createZoomMeetingDeferred');

          if (currentDeferred) {
            _this2.set('createZoomMeetingDeferred', null);

            currentDeferred.reject(new Error('Timed out creating new meeting'));
          }
        }, 10000);
        this.set('deferredTimer', deferredTimer);
        return deferred.promise;
      }

      return Ember.RSVP.reject(new Error('Could not find the zoom frame to create meeting'));
    },
    handleNotification: function handleNotification(notification) {
      if (notification && notification.payload && notification.payload.type === 'error') {
        var errorKey = 'general';
        var zoomError = notification.payload.data && notification.payload.data.err;

        if (zoomError && zoomError.errorCode) {
          // These are from: https://marketplace.zoom.us/docs/sdk/native-sdks/web/error-codes
          switch (zoomError.errorCode) {
            case 1:
            case 2:
              errorKey = 'general';
              break;

            case 3000:
              errorKey = 'meetingInProgress';
              break;

            case 3001:
              errorKey = 'meetingDoesNotExist';
              break;

            case 3003:
              errorKey = 'notMeetingHost';
              break;

            case 3008:
              errorKey = 'meetingHasNotStarted';
              break;

            case 4001:
              errorKey = 'meetingHasDisconnected';
              break;

            case 3707:
              errorKey = 'meetingNotFound';
              break;

            default:
              errorKey = 'general';
          }
        }

        this.logger.error('Error from Zoom Meetings integration frame', {
          error: notification.payload,
          remoteData: {
            errorKey: errorKey
          }
        });
        this.get('notification').error(this.get('intl').t("mediaProviders.zoom.errors.".concat(errorKey, ".title")), this.get('intl').t("mediaProviders.zoom.errors.".concat(errorKey, ".message")));
      }
    },
    handleCreatedZoomMeeting: function handleCreatedZoomMeeting(data) {
      var deferred = this.get('createZoomMeetingDeferred');
      var deferredTimer = this.get('deferredTimer');

      if (deferred && data && data.payload) {
        this.set('createZoomMeetingDeferred', null);

        if (data.payload.error) {
          deferred.reject(new Error('Error creating meeting inside zoom-integration'));
        } else {
          deferred.resolve(data.payload);
        }

        window.clearTimeout(deferredTimer);
      } else {
        throw new Error('Could not find the deferred promise to share new meeting info');
      }
    }
  });

  _exports.default = _default;
});