define("web-directory/controllers/feedback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller;

  var _default = Controller.extend();

  _exports.default = _default;
});