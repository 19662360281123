define("web-directory/components/group-profile/roles-edit/fixtable/item-range/component", ["exports", "web-directory/components/group-profile/roles-edit/fixtable/item-range/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['fixtable-item-range'],
    layout: _template.default,
    dash: '-',
    firstItemIndex: Ember.computed('currentPage', 'pageSize', function () {
      return (this.get('currentPage') - 1) * this.get('pageSize') + 1;
    }),
    lastItemIndex: Ember.computed('currentPage', 'pageSize', 'totalPages', 'totalRows', function () {
      if (this.get('currentPage') === this.get('totalPages')) {
        return this.get('totalRows');
      } else {
        return this.get('currentPage') * this.get('pageSize');
      }
    }),
    isSingleItem: Ember.computed('firstItemIndex', 'lastItemIndex', function () {
      return this.get('firstItemIndex') === this.get('lastItemIndex');
    })
  });

  _exports.default = _default;
});