define("web-directory/components/entity-field-section/entity-relationships/component", ["exports", "ember-data", "lodash", "web-directory/components/entity-profile/profiles/profile", "web-directory/components/entity-field-section/component", "web-directory/models/person", "web-directory/components/entity-field-section/entity-relationships/relationship-group"], function (_exports, _emberData, _lodash, _profile, _component, _person, _relationshipGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      getOwner = Ember.getOwner,
      computed = Ember.computed;

  var _default = _component.default.extend({
    classNames: ['entity-relationships'],
    intl: inject.service(),
    store: inject.service(),
    session: inject.service(),
    application: inject.service(),
    scrollOnAddNew: false,
    disableAddValueDropdown: true,
    viewingMore: {
      siblings: false,
      reports: false
    },
    _relationships: null,
    actions: {
      toggleAddValue: function toggleAddValue() {
        var field = this.get('repeatableField');
        this.send('addNewEntry', field);
      },
      addNewEntry: function addNewEntry(field) {
        this._super.apply(this, arguments);

        var entity = this.get('entity');
        var path = field.get('fieldPath');

        var fieldValue = _lodash.default.last(entity.get(path));

        var count = this.get('otherRelationships.length');
        var key = this.get('intl').t('person.relationships.relationship', {
          count: !!count ? count : count + 1
        });
        Ember.set(fieldValue, 'labelKey', key);
        this.set('otherRelationships.lastObject.key', key);
        this.notifyPropertyChange('otherRelationshipValues');
      },
      updatedField: function updatedField(relationshipGroup) {
        var excludedFields = this.get('excludedFields');
        var fieldPath = relationshipGroup.get('fieldPath');

        if (!fieldPath || relationshipGroup.get('isOtherRelationship')) {
          return;
        }

        var fieldKey = fieldPath.split('.')[1];

        if (relationshipGroup.get('people.length') === 0) {
          excludedFields.removeObject(fieldKey);
        } else {
          excludedFields.addObject(fieldKey);
        }
      },
      removeSection: function removeSection() {
        var _this = this;

        var reports = this.get('relationships').findBy('key', 'defaultReports');
        var promise = Ember.RSVP.resolve();

        if (reports && reports.get('promise')) {
          promise = reports.get('promise').then(function (people) {
            if (people) {
              people.forEach(function (person) {
                reports.removePerson(person);
              });
            }
          });
        }

        return promise.then(function () {
          var entity = _this.get('entity');

          var fieldList = _this.get('section.fieldList');

          fieldList.forEach(function (field) {
            var path = field.get('fieldPath');

            if (entity.get(path)) {
              entity.set(path, [], {
                overwrite: true
              });
            }
          });
          return _this.saveReports().then(function () {
            return _this.saveSection();
          }).then(function () {
            _this.set('sectionInfo.adding', false);

            _this.set('sectionInfo.editingSection', false);

            _this.attrs.removeSection(_this.get('sectionInfo'));

            _this.send('cancelRemoveSection');
          });
        });
      },
      saveSection: function saveSection() {
        var _this2 = this;

        var intl = this.get('intl');
        var notification = this.get('notification');
        this.set('saving', true);
        this.saveReports().then(function () {
          return _this2.saveSection();
        }).then(function () {
          _this2.set('saving', false);

          _this2.set('editingSection', false);
        }).then(function () {
          // Cache bust!
          _this2.get('entity').clearDirectReportsCache();
        }).catch(function (err) {
          var title = intl.lookup('notification.titles.profileSaveError');
          var message = intl.lookup('notification.fieldSection.error');
          notification.error(title, message);

          _this2.set('saving', false);

          _this2.set('editingSection', false);

          _this2.reset().then(function () {
            _this2.notifyPropertyChange('allRelationships');
          });

          return Ember.RSVP.reject(err);
        });
      },
      updatedLabel: function updatedLabel() {
        var fields = this.get('otherRelationships').filter(function (field) {
          return _lodash.default.isEmpty(field.get('label'));
        });
        this.set('disableSave', fields.length !== 0);
      }
    },
    section: computed('entity.fieldConfig.relationships', function () {
      return this.get('entity.fieldConfig.relationships');
    }),
    manager: computed.reads('entity.manager'),
    management: computed.reads('entity.superiors'),
    assistants: computed.reads('entity.adminAssistants'),
    defaultReports: computed.reads('filteredReports'),
    defaultSiblings: computed.reads('filteredSiblings'),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('_relationships', []);
    },
    relationships: computed('entity.id', 'otherRelationships.[]', 'assistants.[]', 'management.[]', function () {
      var _this3 = this;

      var relationshipKeys = ['management', 'defaultReports', 'defaultSiblings', 'assistants'];
      var cachedGroups = this.get('_relationships');

      if (cachedGroups && cachedGroups.length > 0) {
        // Set relationship promise so that reloads can happen if necessary
        cachedGroups.forEach(function (group) {
          var key = group.get('key');

          var promise = _this3.get(key);

          group.set('promise', promise);
        });
        return cachedGroups;
      }

      var groups = relationshipKeys.map(function (key) {
        var entity = _this3.get('entity');

        var relationship = _this3.get(key);

        if (!relationship) {
          return;
        }

        var data = {
          key: key,
          entity: entity,
          promise: relationship
        };
        Ember.setOwner(data, Ember.getOwner(_this3));
        return _relationshipGroup.default.create(data);
      });
      this.set('_relationships', groups);
      return groups;
    }),
    otherRelationships: computed('otherRelationshipValues', function () {
      var _this4 = this;

      var entity = this.get('entity');
      var values = this.get('otherRelationshipValues');
      return Object.keys(values).map(function (key) {
        var value = values[key];
        var promise = Ember.RSVP.resolve([]);
        var ids = value.mapBy('value').reject(function (id) {
          return !id;
        });

        if (ids && !_lodash.default.isEmpty(ids) && ids[0]) {
          promise = _this4.get('store').findByIds('person', ids, _person.BASE_PERSON);
        }

        var data = {
          key: key,
          promise: promise,
          entity: entity
        };
        Ember.setOwner(data, Ember.getOwner(_this4));
        return _relationshipGroup.default.create(data);
      });
    }),
    allRelationships: computed('otherRelationships.[]', function () {
      return [].concat(this.get('relationships'), this.get('otherRelationships'));
    }),
    otherRelationshipValues: computed('entity.relationships.relationships.[]', function () {
      var entity = this.get('entity');
      var values = entity.getFieldValue('relationships.relationships'); // Fallback to incorrect form of "labelkey" due to directory bug

      return _lodash.default.groupBy(values, function (v) {
        return v.labelKey || v.labelkey;
      });
    }),
    filteredReports: computed.reads('entity.directReports'),
    filteredSiblings: computed('entity.siblings', function () {
      var _this5 = this;

      var promise = this.get('entity.siblings').then(function (siblings) {
        return siblings.filter(function (sibling) {
          return sibling.get('id') !== _this5.get('entity.id');
        });
      });
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    readableFieldPaths: computed('entity.id', function () {
      var profile = _profile.default.getProfileForModel(this.get('entity'), getOwner(this));

      return profile.getReadableFieldPathsForSection(this.get('section'));
    }),
    repeatableField: computed('filteredFields.[]', function () {
      return this.get('filteredFields').filter(function (field) {
        return field.get('repeatable') && field.get('customLabels');
      }).find(function (field) {
        var key = field.get('key');
        return key === 'relationships';
      });
    }),
    hideAddNew: computed('repeatableField', function () {
      return _lodash.default.isEmpty(this.get('repeatableField'));
    }),
    canAddMultiple: computed('repeatableField', function () {
      return this.get('repeatableField.customLabels');
    }),
    excludedFields: computed('relationships.[]', function () {
      return ['directReportCount'];
    }),
    saveReports: function saveReports() {
      var promise = Ember.RSVP.resolve();
      var reports = this.get('relationships').findBy('key', 'defaultReports');

      if (reports && reports.get('reportsToSave')) {
        var people = reports.get('reportsToSave');
        var section = this.get('section');
        var promises = people.map(function (person) {
          // we have to load permissions before saving...this is unconventional
          return person.get('readWritePermissions').then(function () {
            return person.saveSection(section);
          });
        });
        promise = Ember.RSVP.all(promises);
        reports.set('reportsToSave', []);
      }

      return promise;
    }
  });

  _exports.default = _default;
});