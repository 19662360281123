define("web-directory/components/call-history-v2/component", ["exports", "lodash", "lodash/reject", "web-directory/mixins/logger"], function (_exports, _lodash, _reject, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend(_logger.default, {
    timeline: Ember.inject.service('timeline'),
    store: Ember.inject.service('store'),
    className: ['call-history'],
    history: [],
    debounceTimers: [],
    queuedConversationIds: Ember.A([]),
    debouncePeriod: 3000,
    sortedHistory: Ember.computed('history', 'history.[]', 'history.@each.startTime', function () {
      var _this = this;

      return Ember.A(this.get('history')).sort(function (a, b) {
        var aStart = _this.getStartTime(a);

        var bStart = _this.getStartTime(b);

        if (aStart && bStart) {
          // Check if they are invalid values
          var aValue = aStart.valueOf();
          var bValue = bStart.valueOf();

          if (!isNaN(aValue) && !isNaN(bValue)) {
            if (bValue === aValue) {
              return 0;
            }

            return bValue - aValue < 0 ? -1 : 1;
          }
        }

        _this.logger.error('call-history-v2: Encountered invalid startTime information for call history', {
          aStart: aStart,
          bStart: bStart
        });

        return 0;
      });
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.fetchHistory();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var disconnectedConversationIds = (this.get('disconnectedConversations') || []).map(function (conversation) {
        return {
          id: Ember.get(conversation, 'id')
        };
      });

      if (this.get('showingCallHistory') && this.get('showCallControls')) {
        // Find recently disconnected conversations and fetch the recent history.
        var queuedConversationIds = this.get('queuedConversationIds');
        var conversationIdsToFetch = [].concat(_toConsumableArray(queuedConversationIds), _toConsumableArray(disconnectedConversationIds));

        if (conversationIdsToFetch.length > 0) {
          this.get('logger').debug('<call-history-v2> fetching queued conversations: ', conversationIdsToFetch);
          var debounceTimer = Ember.run.debounce(this, this.fetchRecentHistory, conversationIdsToFetch, this.get('debouncePeriod'));
          this.get('debounceTimers').push(debounceTimer);
        }
      } else if (disconnectedConversationIds.length > 0) {
        this.get('logger').debug('<call-history-v2> queuing conversations: ', disconnectedConversationIds);
        this.get('queuedConversationIds').addObjects(disconnectedConversationIds);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      var $el = this.$().parents('.call-controls-panels');
      $el.on('scroll', function () {
        return Ember.run.throttle(_this2, _this2.shouldFetchNextBatch, 1000, false);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('debounceTimers').forEach(function (timer) {
        return Ember.run.cancel(timer);
      });
      var $el = this.$().parents('.call-controls-panels');
      $el.off('scroll');
    },

    /**
     * conversationsNotInStore - takes an array of disconnected calls and returns conversationIds not yet in the store.
     *
     * @param recentDisconnectedCalls The array of recently disconnected calls.
     * @return Array of conversationIds for calls not yet in store.
     */
    conversationsNotInStore: function conversationsNotInStore(recentDisconnectedCalls) {
      var _this3 = this;

      return (0, _reject.default)(recentDisconnectedCalls, function (call) {
        return (0, _lodash.some)(_this3.get('store').peekAll('callv2').content, function (value) {
          return value.id === call.id;
        });
      }).map(function (conversation) {
        return {
          id: conversation.id
        };
      });
    },

    /**
     * fetchHistory - Fetches the history on init.
     * @return {Promise} Resolves to an array of conversations.
     */
    fetchHistory: function fetchHistory() {
      var _this4 = this;

      return this.get('store').query('callv2', {}).then(function (data) {
        // data is a record array, we need the models
        var history = data.map(function (d) {
          return d;
        });

        _this4.set('history', Ember.A([].concat(_this4.get('history'), history)).uniqBy('id'));

        return history;
      });
    },

    /**
     * fetchRecentHistory - Fetch the conversations by conversationId
     *
     * @param conversationsToFetch Array of conversationIds
     */
    fetchRecentHistory: function fetchRecentHistory(conversationsToFetch) {
      var _this5 = this;

      return this.get('store').query('callv2', {
        findLatestCalls: true,
        calls: conversationsToFetch
      }).then(function (data) {
        var conversations = (data.content || []).filter(function (conversation) {
          return Ember.A(conversationsToFetch).isAny('id', Ember.get(conversation, 'id'));
        }).map(function (c) {
          return c.getRecord();
        });

        _this5.set('history', Ember.A([].concat(_this5.get('history'), conversations)).uniqBy('id'));
      }).finally(function () {
        var latestCall = [].concat(_this5.get('store').peekAll('callv2').map(function (c) {
          return c;
        })).pop();

        if (latestCall && (Ember.get(latestCall, 'isMissed') || Ember.get(latestCall, 'missedCall')) && Ember.get(latestCall, 'callEnd')) {
          // make sure latest call was both missed and is actually finished for user
          return _this5.get('timeline').missedCallEvent(latestCall, true);
        }

        _this5.get('queuedConversationIds').clear();
      });
    },

    /**
     * shouldFetchNextBatch - Checks to see if scroll position is near bottom and then fetches more history
     */
    shouldFetchNextBatch: function shouldFetchNextBatch() {
      var $el = this.$().parents('.call-controls-panels');
      var scrollPosition = $el[0].scrollHeight - $el.height() - $el.scrollTop();
      var scrollPositionByHeight = scrollPosition / $el.height();

      if (scrollPositionByHeight < 0.5) {
        Ember.run.throttle(this, this.fetchHistory, 2000);
      }
    },

    /**
     * Get the start time of a particular conversation
     *
     * @param interaction
     * @returns start time of the conversation
     */
    getStartTime: function getStartTime(interaction) {
      var time = null;
      var startTime = Ember.get(interaction, 'startTime');
      var myParticipant = Ember.get(interaction, 'myParticipant');

      if (startTime) {
        time = startTime;
      } else if (myParticipant && myParticipant.startTime) {
        time = myParticipant.startTime;
      } else {
        var otherParty = (Ember.get(interaction, 'otherParties') || []).find(function (party) {
          return !!party.startTime;
        });

        if (otherParty && otherParty.startTime) {
          time = otherParty.startTime;
        }
      }

      if (!time) {
        return null;
      } // Deal with the type serialization that ember-data might be doing based on where
      // our data loads from


      return typeof time === 'string' ? new Date(time) : time;
    },
    actions: {
      /**
       * fetchMoreHistory - fetches more history.
       */
      fetchMoreHistory: function fetchMoreHistory() {
        this.fetchHistory();
      },
      fetchRecentHistory: function fetchRecentHistory(conversationsToFetch) {
        return this.fetchRecentHistory(conversationsToFetch);
      }
    }
  }).reopenClass({
    positionalParams: ['disconnectedConversations']
  });

  _exports.default = _default;
});