define("web-directory/components/consult-transfer/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed,
      Component = Ember.Component;

  var _default = Component.extend({
    classNames: ['consult-transfer'],
    phone: inject.service('phone'),
    session: inject.service('session'),
    conversation: null,
    speaker: null,
    transferring: false,
    actions: {
      changeSpeaker: function changeSpeaker(destination) {
        var _this = this;

        if (!this.get('canUpdateConsult')) {
          return;
        }

        this.get('phone').changeSpeakerForConsult(destination).then(function () {
          Ember.Logger.log('changed active speaker for consult', {
            destination: destination
          });

          _this.set('speaker', destination.toLowerCase());
        }).catch(function (error) {
          Ember.Logger.error('failed to change active speaker for consult', {
            error: error
          });
        });
      },
      cancelConsult: function cancelConsult() {
        var _this2 = this;

        if (!this.get('canUpdateConsult')) {
          return;
        }

        this.get('phone').endConsult().then(function () {
          _this2.notifyPropertyChange('conversation.onConsult');
        }).catch(function (error) {
          Ember.Logger.error('error cancelling consult', {
            error: error
          });
        });
      },
      finishConsult: function finishConsult() {
        var _this3 = this;

        if (!this.get('canUpdateConsult')) {
          return;
        }

        this.set('transferring', true);

        this.get('phone')._endCall(this.get('phone.activeCall')).then(function () {
          Ember.run.later(function () {
            if (!_this3.get('isDestroyed') && !_this3.get('isDestroying')) {
              _this3.set('transferring', false);

              _this3.notifyPropertyChange('conversation.onConsult');
            }
          }, 2000);
        });
      }
    },
    consultParty: computed('conversation.otherParties.[]', function () {
      var parties = this.get('conversation.callParties') || [];
      return parties.find(function (party) {
        if (party.attributes && party.attributes.consultInitiator === 'true') {
          return true;
        }

        return party.id === party.consultParticipantId;
      });
    }),
    otherParty: computed('consultParty', function () {
      var consultParty = this.get('consultParty');
      var parties = this.get('conversation.otherParties.[]') || [];

      if (!consultParty) {
        return null;
      }

      return parties.find(function (party) {
        return party.id !== consultParty.id;
      });
    }),
    isSpeakerConsultParty: computed('speaker', 'consultParty.calls', function () {
      if (this.get('speaker')) {
        return this.get('speaker') === 'object';
      }

      var call = _lodash.default.head(this.get('consultParty.calls'));

      return call && !call.confined;
    }),
    bothSpeaking: computed('speaker', 'consultParty.calls', 'otherParty.calls', function () {
      if (this.get('speaker')) {
        return this.get('speaker') === 'both';
      }

      var consultParty = _lodash.default.head(this.get('consultParty.calls'));

      var otherParty = _lodash.default.head(this.get('otherParty.calls'));

      return consultParty && otherParty && !consultParty.confined && !otherParty.confined;
    }),
    canUpdateConsult: computed.equal('conversation.myself.attributes.consultInitiator', 'true')
  });

  _exports.default = _default;
});