define("web-directory/components/zoom-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZFZkfiFq",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"heading\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"panel-title\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"text text-static\"],[13],[0,\"\\n            \"],[1,[33,[\"t\"],[\"mediaProviders.zoom.zoomMeeting\"],null],false],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\\n    \"],[1,[33,[\"chat-panel/conversation/header/control-buttons-panel\"],null,[[\"showExpandButton\",\"shouldShowPopout\"],[true,false]]],false],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[11,\"div\",[]],[15,\"class\",\"panel-content\"],[13],[0,\"\\n    \"],[9,\"frame-router\",{\"attrs\":[[16,\"route\",[26,[\"route\"]],null]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/zoom-panel/template.hbs"
    }
  });

  _exports.default = _default;
});