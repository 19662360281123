define("web-directory/components/group-profile/group-contact-panel/group-ring-settings/component", ["exports", "ember-data", "lodash", "ember-purecloud-components/utils/browser"], function (_exports, _emberData, _lodash, _browser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var AUDIO_FORMAT = _browser.default.getSuggestedAudioTypeFrom(['webm', 'wav']);

  function isOggFileType(file) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      var fileReader = new FileReader();

      fileReader.onloadend = function (e) {
        var arr = new window.Uint8Array(e.target.result);
        var isOpus = arr[28] === 0x4f && arr[29] === 0x70 && arr[30] === 0x75 && arr[31] === 0x73 && arr[32] === 0x48 && arr[33] === 0x65 && arr[34] === 0x61 && arr[35] === 0x64;
        var isWav = arr[0] === 0x52 && arr[1] === 0x49 && arr[2] === 0x46 && arr[3] === 0x46 && arr[8] === 0x57 && arr[9] === 0x41 && arr[10] === 0x56 && arr[11] === 0x45;
        var isAiff = arr[0] === 0x46 && arr[1] === 0x4f && arr[2] === 0x52 && arr[3] === 0x4d && arr[8] === 0x41 && arr[9] === 0x49 && arr[10] === 0x46 && arr[11] === 0x46; // Only support opus encodings, not vorbis
        // Support WAV and AIFF as well
        // See: https://github.com/sindresorhus/file-type

        if (isOpus || isWav || isAiff) {
          resolve('opus');
        } else {
          reject(false);
        }
      };

      fileReader.readAsArrayBuffer(file);
    });
  }

  var DEFAULT_TRANSCIPTION_DROPDOWN_LANGUAGE = 'en-us';

  var _default = Ember.Component.extend({
    //---- EMBER: BINDINGS ----//
    classNames: ['group-ring-settings'],
    //---- SERVICES ----//
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    phone: Ember.inject.service(),
    pigeon: Ember.inject.service(),
    logger: Ember.inject.service('stash-logger'),
    session: Ember.inject.service(),
    application: Ember.inject.service(),
    notification: Ember.inject.service(),
    addressFormat: Ember.inject.service('addressformat'),
    //---- CONTEXT & STATES ----//
    entity: null,
    number: null,
    extension: null,
    originalNumber: null,
    originalExtension: null,
    orgVoicemailPolicy: null,
    canEditGroupTelephonySettings: true,
    voicemailFile: null,
    showVoicemailGreetingOptions: false,
    resetGreeting: false,
    rotateCallSecondsError: false,
    numberOfRotationsError: false,
    pigeonEventHandler: null,
    disableReplaceOverflowBtn: Ember.computed.not('canEditGroupTelephonySettings'),
    disableRingInputs: Ember.computed('editing', 'canEditGroupTelephonySettings', function () {
      return !this.get('editing') || !this.get('canEditGroupTelephonySettings');
    }),
    hasExtension: Ember.computed.notEmpty('extension'),
    hasNumber: Ember.computed.notEmpty('number'),
    numberOfRotations: Ember.computed.alias('entity.policy.stopRingingAfterRotations'),
    rotateCallSeconds: Ember.computed.alias('entity.policy.rotateCallsSecs'),
    forwardingOn: Ember.computed('entity.policy.overflowGroupId', {
      get: function get() {
        return _emberData.default.PromiseObject.create({
          promise: this.get('overflowGroup').then(function (overflowGroup) {
            return !!overflowGroup;
          })
        });
      },
      set: function set(key, value) {
        return _emberData.default.PromiseObject.create({
          promise: Ember.RSVP.resolve(value)
        });
      }
    }),
    routeSelection: Ember.computed('entity.policy.groupAlertType', {
      get: function get() {
        return this.get('entity.policy.groupAlertType') !== null ? this.get('entity.policy.groupAlertType') : 'RANDOM';
      },
      set: function set(key, value) {
        return this.set('entity.policy.groupAlertType', value);
      }
    }),
    overflowGroup: Ember.computed('entity.policy.overflowGroupId', {
      get: function get() {
        var promise;

        if (!this.get('entity.policy.overflowGroupId')) {
          promise = Ember.RSVP.resolve(null);
        } else {
          promise = this.get('store').findRecord('group', this.get('entity.policy.overflowGroupId'));
        }

        return _emberData.default.PromiseObject.create({
          promise: promise
        });
      },
      set: function set(key, value) {
        return value;
      }
    }),
    editingVoicemailDisabled: Ember.computed('isEmpty', 'isValid', 'haveExtensionError', function () {
      return this.get('isEmpty') || !this.get('isValid') || this.get('haveExtensionError');
    }),
    ringTimeMs: Ember.computed('rotateCallSeconds', 'numberOfRotations', 'numberOfRotationsError', 'rotateCallSecondsError', function () {
      var seconds = parseInt(this.get('rotateCallSeconds'), 10);
      var rotations = parseInt(this.get('numberOfRotations'), 10);

      var _this$getProperties = this.getProperties('numberOfRotationsError', 'rotateCallSecondsError'),
          numberOfRotationsError = _this$getProperties.numberOfRotationsError,
          rotateCallSecondsError = _this$getProperties.rotateCallSecondsError;

      if (numberOfRotationsError || rotateCallSecondsError) {
        return null;
      }

      var total = seconds * rotations;
      return total * 1000;
    }),
    isValid: Ember.computed('invalidExtension', 'invalidPhoneNumber', 'haveNumberAndExtension', 'number', 'extension', 'haveExtensionError', 'duplicateNumberError', function () {
      if (this.get('haveNumberAndExtension')) {
        return false;
      }

      if (this.get('isEmpty')) {
        return false;
      }

      if (!this.get('hasNumber')) {
        return !(this.get('invalidExtension') || this.get('haveExtensionError'));
      }

      return !this.get('invalidPhoneNumber') && !this.get('duplicateNumberError');
    }),
    errorMessage: Ember.computed('isValid', function () {
      if (!this.get('isValid')) {
        if (this.get('isEmpty')) {
          return this.get('intl').t('purecloud.callForwarding.missingNumberAndExtension');
        } else if (this.get('haveNumberAndExtension')) {
          return this.get('intl').t('purecloud.callForwarding.numberAndExtension');
        } else if (this.get('invalidExtension')) {
          return this.get('intl').t('purecloud.callForwarding.invalidExtension');
        } else if (this.get('haveExtensionError')) {
          if (this.get('isExtensionNotInPool')) {
            return this.get('intl').t('groups.groupRing.extensionError');
          }

          return this.get('intl').t('groups.groupRing.inUseError');
        } else if (this.get('duplicateNumberError')) {
          return this.get('intl').t('groups.groupRing.inUseError');
        } else if (this.get('invalidPhoneNumber')) {
          return this.get('intl').t('purecloud.acdInteraction.sms.errors.invalidNumberFormat');
        }
      }
    }),
    duplicateNumberError: Ember.computed('entity.number.rel.errorCode', 'number', 'originalNumber', function () {
      var number = this.get('number');
      var originalNumber = this.get('originalNumber');

      if (originalNumber && number !== originalNumber) {
        return false;
      }

      return this.get('entity.number.rel.errorCode') === 'DUPLICATE_VALUE';
    }),
    haveExtensionError: Ember.computed('entity.number.rel.errorCode', 'extension', 'originalExtension', function () {
      var extension = this.get('extension');
      var originalExtension = this.get('originalExtension');

      if (originalExtension && extension !== originalExtension) {
        return false;
      }

      var code = this.get('entity.number.rel.errorCode');
      return !!code && code !== 'DUPLICATE_VALUE';
    }),
    isExtensionResourceInUse: Ember.computed('haveExtensionError', function () {
      if (!this.get('haveExtensionError')) {
        return false;
      }

      var code = this.get('entity.number.rel.errorCode');
      return code === 'RESOURCE_ALREADY_IN_USE';
    }),
    isExtensionNotInPool: Ember.computed('haveExtensionError', function () {
      if (!this.get('haveExtensionError')) {
        return false;
      }

      var code = this.get('entity.number.rel.errorCode');
      return code === 'EXTENSION_MUST_BE_ASSGINED_TO_AN_EXTENSION_POOL';
    }),
    invalidExtension: Ember.computed('extension', 'haveNumberAndExtension', function () {
      var ext = this.get('extension');
      var regex = /^\d+$/; // simple regex test, not meant to be expansive

      if (!this.get('number') && !_lodash.default.isEmpty(ext)) {
        return !regex.test(ext);
      }

      return false;
    }),
    invalidPhoneNumber: Ember.computed('number', function () {
      if (this.get('hasNumber')) {
        var elem = this.$('.group-ring-phone-input');

        if (!_lodash.default.isEmpty(elem.val())) {
          return !this.$('.group-ring-phone-input').intlTelInput('isValidNumber');
        }
      }

      return false;
    }),
    inputErrorClass: Ember.computed('haveNumberAndExtension', 'isEmpty', 'invalidPhoneNumber', function () {
      if (this.get('haveNumberAndExtension') || this.get('isEmpty')) {
        return 'field-error';
      }

      if (this.get('invalidPhoneNumber')) {
        return 'field-warning';
      }

      return '';
    }),
    isEmpty: Ember.computed('number', 'extension', function () {
      var number = this.get('number');
      var extension = this.get('extension');
      return _lodash.default.isEmpty(number) && _lodash.default.isEmpty(extension);
    }),
    haveNumberAndExtension: Ember.computed('number', 'extension', function () {
      var number = this.get('number');
      var extension = this.get('extension');
      return !_lodash.default.isEmpty(number) && !_lodash.default.isEmpty(extension);
    }),
    callNotice: Ember.computed(function () {
      return this.get('intl').lookup('groups.modal.groupRingNotice').toString();
    }),
    routeTypes: Ember.computed(function () {
      return [{
        value: 'RANDOM',
        translation: this.get('intl').t('groups.groupRing.routeTypes.broadcast')
      }, {
        value: 'SEQUENTIAL',
        translation: this.get('intl').t('groups.groupRing.routeTypes.sequential')
      }, {
        value: 'ROUND_ROBIN',
        translation: this.get('intl').t('groups.groupRing.routeTypes.rotary')
      }];
    }),
    routeSelectionType: Ember.computed('routeSelection', function () {
      switch (this.get('routeSelection')) {
        case 'RANDOM':
          return 'broadcast';

        case 'SEQUENTIAL':
          return 'sequential';

        case 'ROUND_ROBIN':
          return 'rotary';

        default:
          return 'broadcast';
      }
    }),
    routeDescription: Ember.computed('routeSelectionType', function () {
      return this.get('intl').t("groups.groupRing.routeDescriptions.".concat(this.get('routeSelectionType').toLowerCase()));
    }),
    selectedRouteType: Ember.computed('routeSelectionType', function () {
      return this.get('intl').t("groups.groupRing.routeTypes.".concat(this.get('routeSelectionType').toLowerCase()));
    }),
    rotateCallsDisplayText: Ember.computed('rotateCallSeconds', function () {
      return this.get('intl').t('groups.groupRing.displayOnly.rotateCalls', {
        value: this.get('rotateCallSeconds')
      });
    }),
    stopRingingDisplayText: Ember.computed('numberOfRotations', function () {
      return this.get('intl').t('groups.groupRing.displayOnly.stopRinging', {
        value: this.get('numberOfRotations')
      });
    }),
    searchText: Ember.computed(function () {
      return this.get('intl').t('groups.groupRing.groupSearch');
    }),
    playerOptions: Ember.computed(function () {
      return {
        html5: true,
        volume: 0.5,
        format: ['ogg']
      };
    }),
    greetingOptions: Ember.computed(function () {
      // greetings seem to have a low volume
      return {
        html5: true,
        volume: 1.0,
        format: [AUDIO_FORMAT]
      };
    }),
    hasGreeting: Ember.computed('entity.greeting.content', {
      get: function get() {
        var greeting = this.get('entity.greeting.content');
        return !!greeting;
      },
      set: function set(key, value) {
        return value;
      }
    }),
    greetingAudioSource: Ember.computed('entity.greeting', function () {
      var _this = this;

      var greeting = this.get('entity.greeting');
      return greeting.then(function (data) {
        var path = data.greetings.VOICEMAIL.selfUri;

        if (_lodash.default.head(path) === '/') {
          path = path.substring(1);
        }

        path = path.concat('/media');

        var url = _this.get('application').pcV2Uri(path);

        return _this.get('ajax').request(url, {
          data: {
            formatId: AUDIO_FORMAT
          }
        });
      }).then(function (data) {
        return data.mediaFileUri;
      }).catch(function () {
        _this.get('logger').error('Error loading media for group:', {
          groupId: _this.get('entity.guid')
        });

        _this.set('hasGreeting', false);
      });
    }),
    displaySpinner: Ember.computed('entity.greeting.isFulfilled', 'waitingOnGreeting', function () {
      return !this.get('entity.greeting.isFulfilled') || this.get('waitingOnGreeting');
    }),
    reasons: Ember.computed('entity.policy.{enabled,sendEmailNotifications,disableEmailPii}', 'orgVoicemailPolicy', 'session.org', ///
    function () {
      return {
        org: {
          isMissing: _lodash.default.isEmpty(this.get('orgVoicemailPolicy')),
          enablesHipaa: this.get('session.org.hasHipaaEnabled'),
          // disablesVoicemails: !this.get('orgVoicemailPolicy.enabled'),
          // ^^^ NOTE(as-of-writing): This will always be `true` but we ignore it.
          disablesEmailNotifications: !this.get('orgVoicemailPolicy.sendEmailNotifications'),
          disablesPiiInEmailNotifications: this.get('orgVoicemailPolicy.disableEmailPii'),
          disablesVMTranscription: !this.get('orgVoicemailPolicy.includeEmailTranscriptions')
        },
        group: {
          isMissing: _lodash.default.isEmpty(this.get('entity.policy')),
          disablesVoicemails: !this.get('entity.policy.enabled'),
          disablesEmailNotifications: !this.get('entity.policy.sendEmailNotifications') || !this.get('orgVoicemailPolicy.sendEmailNotifications'),
          disablesPiiInEmailNotifications: this.get('entity.policy.disableEmailPii') || this.get('entity.policy.disableEmailPii')
        }
      };
    }),
    // NOTE(as-of-writing):
    //   The property `orgVoicemailPolicy.enabled` exists, but it is unused, uneditable,
    //   and its default value is `false`. Unless/until the org's setting affects
    //   group policies or permissions for voicemails, we should ignore it.
    voicemails: Ember.computed('entity.policy.enabled', 'reasons', ///
    function () {
      var show; // Always show it.

      show = true;
      var disable;

      if (this.get('reasons.org.isMissing')) {
        // Disable it if the org's policy is missing.
        disable = true;
      } else if (this.get('reasons.group.isMissing')) {
        // Disable it if the group's policy is missing.
        disable = true;
      } else if (!this.get('canEditGroupTelephonySettings')) {
        // Disable it if the user cannot edit telephony settings
        // Should not make it this far as the org policy will be missing
        disable = true;
      } else {
        // Use the inverse of `show`.
        disable = !show; // (This is probably unnecessary, but let's be cautious.)
      }

      var state;

      if (this.get('reasons.group.isMissing')) {
        // We cannot know its state if the group's policy is missing.
        state = null;
      } else if (disable && this.get('canEditGroupTelephonySettings')) {
        // Default to `false` if it is disabled.
        // only default the state to false when disabled and user has access to telephony settings
        // if user does not have access to telephony settings, we will want to show the actual value
        // of the voicemail toggle while it is disabled.
        state = false;
      } else {
        // Use the real value.
        state = this.get('entity.policy.enabled');
      }

      return {
        show: show,
        disable: disable,
        state: state
      };
    }),
    transcriptionToggle: Ember.computed('entity.policy.includeEmailTranscriptions', 'reasons', 'emailNotifications.state', ///
    function () {
      var show = this.get('emailNotifications.state');
      var disable = false;

      if (this.get('reasons.org.enablesHipaa') || this.get('reasons.org.disablesPiiInEmailNotifications') || this.get('reasons.group.disablesPiiInEmailNotifications') || this.get('reasons.org.disablesVMTranscription') || !this.get('canEditGroupTelephonySettings')) {
        disable = true;
      }

      var state;

      if (this.get('reasons.group.isMissing')) {
        // We cannot know its state if the group's policy is missing.
        state = null;
      } else if (disable && this.get('canEditGroupTelephonySettings')) {
        state = false;
      } else {
        // Use the real value.
        state = this.get('entity.policy.includeEmailTranscriptions');
      }

      return {
        show: show,
        disable: disable,
        state: state
      };
    }),
    transcriptionDropDown: Ember.computed('entity.policy.languagePreference', 'reasons', 'transcriptionToggle', ///
    function () {
      var show = !this.get('transcriptionToggle.disable') && this.get('transcriptionToggle.state');
      var disable = !this.get('canEditGroupTelephonySettings');
      var state;

      if (this.get('reasons.group.isMissing')) {
        // We cannot know its state if the group's policy is missing.
        state = null;
      } else if (!this.get('entity.policy.languagePreference')) {
        state = DEFAULT_TRANSCIPTION_DROPDOWN_LANGUAGE;
      } else {
        state = this._getLanguagePreference(this.get('entity.policy'));
      }

      return {
        show: show,
        disable: disable,
        state: state
      };
    }),
    transcriptionLanguages: Ember.computed('intl.languages', ///
    function () {
      var languagesObj = this.get('intl').get('languages'); // Populate lanaguagesArr with languages and make the selected language the transcriptionDropDown state

      return Object.keys(languagesObj).map(function (key) {
        return {
          key: key,
          value: languagesObj[key]
        };
      });
    }),
    emailNotifications: Ember.computed('entity.policy.sendEmailNotifications', 'reasons', 'voicemails', ///
    function () {
      var show;

      if (this.get('reasons.group.disablesVoicemails')) {
        // Hide it if the group disables voicemails.
        show = false;
      } else if (!this.get('canEditGroupTelephonySettings')) {
        show = false;
      } else {
        // Inherit `voicemails.show`.
        show = this.get('voicemails.show');
      }

      var disable;

      if (this.get('reasons.org.disablesEmailNotifications')) {
        // Disable it if the org disables email notifications.
        disable = true;
      } else {
        // Inherit `voicemails.disable` or use the inverse of `show`.
        disable = this.get('voicemails.disable') || !show; // (This is probably unnecessary, but let's be cautious.)
      }

      var state;

      if (this.get('reasons.group.isMissing')) {
        // We cannot know its state if the group's policy is missing.
        state = null;
      } else if (disable) {
        // Default to `false` if it is disabled.
        state = false;
      } else {
        // Use the real value.
        state = this.get('entity.policy.sendEmailNotifications');
      }

      return {
        show: show,
        disable: disable,
        state: state
      };
    }),
    piiInEmailNotifications: Ember.computed('emailNotifications', 'entity.policy.disableEmailPii', 'reasons', ///
    function () {
      var show;

      if (this.get('reasons.group.disablesEmailNotifications')) {
        // Hide it if the group disables email notifications.
        show = false;
      } else {
        // Inherit `emailNotifications.show`.
        show = this.get('emailNotifications.show');
      }

      var disable;

      if (this.get('reasons.org.enablesHipaa')) {
        // Disable it if the org enables HIPAA.
        disable = true;
      } else if (this.get('reasons.org.disablesPiiInEmailNotifications')) {
        // Disable it if the org disables PII in email notifications.
        disable = true;
      } else {
        // Inherit `emailNotifications.disable` or use the inverse of `show`.
        disable = this.get('emailNotifications.disable') || !show; // (This is probably unnecessary, but let's be cautious.)
      }

      var state;

      if (this.get('reasons.group.isMissing')) {
        // We cannot know its state if the group's policy is missing.
        state = null;
      } else if (disable) {
        // Default to `false` if it is disabled.
        state = false;
      } else {
        // Use the real value.
        state = !this.get('entity.policy.disableEmailPii');
      }

      return {
        show: show,
        disable: disable,
        state: state
      };
    }),
    //---- EMBER: LIFECYCLE METHODS ----//
    // Initialize the component.
    init: function init() {
      this._super.apply(this, arguments);

      if (this.attrs.appendSave) {
        this.saveFunction = Ember.run.bind(this, this.save);
        this.attrs.appendSave(this.saveFunction);
      }

      this.get('disableSave')(!this.get('isValid'));
      this.pigeonEventHandler = this.handlePigeonEvent.bind(this);
      this.registerToPigeonTopic();
      this.get('pigeon').on('greetings', this, this.pigeonEventHandler);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('saveContactInfo', this.save.bind(this));

      this._setupInputs();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this._setupInputs();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.attrs.removeSave) {
        this.attrs.removeSave(this.saveFunction);
      }

      this.get('pigeon').off('greetings', this.pigeonEventHandler);
    },
    _getLanguagePreference: function _getLanguagePreference(policy) {
      var value = policy.get('languagePreference');

      if (value === 'en_US') {
        // backend stores it as en_US but we use en-us
        value = DEFAULT_TRANSCIPTION_DROPDOWN_LANGUAGE;
      }

      return value;
    },
    // `_setupInputs` is a helper method used exclusively in the lifecycle methods.
    _setupInputs: function _setupInputs() {
      var previousEntity = this.get('_entity');
      var entity = this.get('entity');

      if (entity !== previousEntity) {
        Ember.run.scheduleOnce('afterRender', this, this.setupInput);
        Ember.run.scheduleOnce('afterRender', this, this.initNumber);
        this.set('_entity', entity);
      }
    },
    //---- ACTIONS & OBSERVERS & METHODS ----//
    actions: {
      updateNumber: function updateNumber(event) {
        this.set('number', event.target.value);
      },
      updateExtension: function updateExtension(event) {
        this.set('extension', event.target.value);
      },
      updateRotateCalls: function updateRotateCalls(value) {
        var number = parseInt(value, 10);

        if (typeof number === 'number') {
          this.set('rotateCallSeconds', number);
        }
      },
      updateRotations: function updateRotations(value) {
        var number = parseInt(value, 10);

        if (typeof number === 'number') {
          this.set('numberOfRotations', number);

          if (this.attrs.rotationsChanged) {
            this.attrs.rotationsChanged(number);
          }
        }
      },
      toggleCallForwarding: function toggleCallForwarding() {
        this.set('forwardingOn', !this.get('forwardingOn.content'));

        if (!this.get('forwardingOn.content')) {
          this.set('overflowGroup', null);
        }
      },
      selectOverflowGroup: function selectOverflowGroup(group) {
        this.set('overflowGroup', group);
      },
      replaceOverflowGroup: function replaceOverflowGroup() {
        this.set('overflowGroup', null);
      },
      toggleVoicemails: function toggleVoicemails() {
        // Directly toggle the state in the group's policy.
        return this.toggleProperty('entity.policy.enabled');
      },
      toggleEmailNotifications: function toggleEmailNotifications() {
        // Directly toggle the state in the group's policy.
        return this.toggleProperty('entity.policy.sendEmailNotifications');
      },
      togglePiiInEmailNotifications: function togglePiiInEmailNotifications() {
        // Directly toggle the state in the group's policy.
        return this.toggleProperty('entity.policy.disableEmailPii');
      },
      toggleShowVoicemailGreetingOptions: function toggleShowVoicemailGreetingOptions() {
        return this.toggleProperty('showVoicemailGreetingOptions');
      },
      toggleTranscriptions: function toggleTranscriptions() {
        return this.toggleProperty('entity.policy.includeEmailTranscriptions');
      },
      selectVoicemailFile: function selectVoicemailFile(file, recording) {
        if (recording) {
          this.set('recordingFile', file);
          this.set('voicemailFile', null);
        } else {
          this.set('voicemailFile', file);
          this.set('recordingFile', null);
        }
      },
      selectTranscriptionLanguage: function selectTranscriptionLanguage(event) {
        this.set('entity.policy.languagePreference', event.target.value);
      },
      triggerUpload: function triggerUpload() {
        if (this.get('hasGreeting')) {
          return;
        }

        this.$('input.file-upload').trigger('click');
      },
      chooseFile: function chooseFile() {
        var _this2 = this;

        var input = this.$('input.file-upload');
        var file = Ember.get(input[0], 'files.0') || [];

        if (file) {
          isOggFileType(file).then(function (format) {
            Ember.Logger.info('Voicemail: Correct file type', format);

            _this2.send('selectVoicemailFile', file, false);
          }).catch(function () {
            var title = _this2.get('intl').lookup('notification.group.fileUpload.title');

            var message = _this2.get('intl').lookup('notification.group.fileUpload.message');

            _this2.get('notification').error(title, message);
          });
        } else {
          this.set('errorSelectingFile', true);
        }

        this.set('showVoicemailGreetingOptions', false);
      },
      recordingReady: function recordingReady(file) {
        this.send('selectVoicemailFile', file, true);
        this.set('resetGreeting', false);
      },
      resetRecording: function resetRecording() {
        this.set('recordingFile', null);
      },
      resetFile: function resetFile() {
        this.set('voicemailFile', null);
      },
      resetCurrentGreeting: function resetCurrentGreeting() {
        if (!this.get('hasGreeting')) {
          this.send('resetFile');
          this.send('resetRecording');
        } else {
          this.set('resetGreeting', true);
          this.set('hasGreeting', false);
        }

        this.set('showVoicemailGreetingOptions', false);
      },
      audioPlayerError: function audioPlayerError() {
        this.set('hasGreeting', false);
      },
      disableSave: function disableSave(key, value) {
        this.set(key, value);
        this.get('disableSave')(value);
      }
    },
    setConfirmDisabled: Ember.observer('isValid', function () {
      if (!this.get('isValid')) {
        var badNumber = this.get('invalidPhoneNumber') || this.get('duplicateNumberError');
        var badExtension = this.get('invalidExtension') || this.get('haveExtensionError') || this.get('haveNumberAndExtension');
        var isEmpty = this.get('isEmpty');

        if (badExtension || isEmpty) {
          this.get('disableSave')(true);
        } else if (badNumber) {
          // Don't disable save if the only issue is a bad number
          this.get('disableSave')(false);
        } else {
          // Fall through to disable save
          this.get('disableSave')(true);
        }
      } else {
        this.get('disableSave')(false);
      }
    }),
    editingObserver: Ember.observer('editing', function () {
      if (!this.get('editing')) {
        this.set('voicemailFile', null);
        this.set('recordingFile', null);
        this.notifyPropertyChange('overflowGroup');
        this.notifyPropertyChange('forwardingOn');
        this.initNumber();
      }
    }),
    registerToPigeonTopic: function registerToPigeonTopic() {
      var groupId = this.get('entity.guid');
      var groupTopic = "v2.groups.".concat(groupId, ".greetings");
      this.get('pigeon').registerPriorityTopic(groupTopic);
    },
    handlePigeonEvent: function handlePigeonEvent() {
      this.get('entity').notifyPropertyChange('greeting');
      this.set('waitingOnGreeting', false);
    },
    setupInput: function setupInput() {
      this.$('.group-ring-phone-input').intlTelInput({
        nationalMode: false,
        utilsScript: "".concat(this.get('application.urlprefix'), "assets/intltelinput/utils.js")
      });
    },
    initNumber: function initNumber() {
      var info = this.get('entity.contactInfo.groupNumber.firstObject.value');
      var inputElem = this.$('.group-ring-phone-input');
      var extElem = this.$('.group-ring-extension-input');
      var number = info && info.number ? info.number : '';
      var extension = info && info.extension ? info.extension : '';
      inputElem.intlTelInput('setNumber', number);
      extElem.val(extension);
      this.set('number', number);
      this.set('extension', extension);
      this.set('originalNumber', number);
      this.set('originalExtension', extension);
    },
    save: function save() {
      var overflowGroupId = this.get('overflowGroup') === null ? null : this.get('overflowGroup.guid');
      this.set('entity.policy.overflowGroupId', overflowGroupId);
      this.set('originalExtension', this.get('extension'));

      if (this.get('voicemailFile') || this.get('recordingFile')) {
        this.set('waitingOnGreeting', true);
      }

      if (overflowGroupId === null) {
        this.set('forwardingOn', false);
      }

      return {
        type: 'group-ring-settings',
        isExtensionResourceInUse: this.get('isExtensionResourceInUse'),
        isExtensionNotInPool: this.get('isExtensionNotInPool'),
        recordingFile: this.get('recordingFile'),
        voicemailFile: this.get('voicemailFile'),
        resetGreeting: this.get('resetGreeting'),
        isEmpty: this.get('isEmpty'),
        callback: this.finishedSaving.bind(this)
      };
    },
    finishedSaving: function finishedSaving(succeeded) {
      if (succeeded) {
        this.set('resetGreeting', false);
      } else {
        this.set('waitingOnGreeting', false);
      }
    }
  });

  _exports.default = _default;
});