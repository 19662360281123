define("web-directory/components/schema-editor/field-configuration-editor/tag/component", ["exports", "ember-engine-custom-fields/components/schema-editor/field-configuration-editor/tag/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _component.default;
    }
  });
});