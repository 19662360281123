define("web-directory/templates/login-oauth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EFPOqqdR",
    "block": "{\"statements\":[[1,[33,[\"login-oauth\"],null,[[\"offerMobileApp\",\"mobileOfferDeclined\",\"authenticate\"],[[28,[\"model\",\"offerMobileApp\"]],[33,[\"route-action\"],[\"mobileOfferDeclined\"],null],[33,[\"route-action\"],[\"authenticate\"],null]]]],false],[0,\"\\n\"],[1,[26,[\"icon-bootstrap\"]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/templates/login-oauth.hbs"
    }
  });

  _exports.default = _default;
});