define("web-directory/controllers/internal-logs", ["exports", "clipboard", "lodash"], function (_exports, _clipboard, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller,
      inject = Ember.inject,
      computed = Ember.computed,
      run = Ember.run;

  var _default = Controller.extend({
    stashLogger: inject.service(),
    notification: inject.service(),
    intl: inject.service(),
    init: function init() {
      var _arguments = arguments,
          _this = this;

      this._super();

      run.scheduleOnce('afterRender', function () {
        _this._super(_arguments);

        var copyBtn = new _clipboard.default('.copy-to-clipboard-btn', {
          text: _this._getLogsString.bind(_this)
        });
        copyBtn.on('success', function () {
          Ember.run(function () {
            return _this.get('notification').info(null, _this.get('intl').t('purecloud.copyToClipboardSuccess'));
          });
        });
      });
    },
    _getLogsString: function _getLogsString() {
      return this.get('logs').map(function (log, index) {
        return "".concat(index, ": ").concat(log.topic, " -- ").concat(log.time, " -- ").concat(log.level, ": ").concat(log.stringified);
      }).join('\n') || '';
    },
    loggingOptions: computed('stashLogger.loggingOptions.[]', function () {
      var options = [];
      this.get('stashLogger.loggingOptions').forEach(function (option) {
        if (!option.localLevel) {
          return;
        }

        options.push(Ember.Object.create({
          selected: true
        }, option));
      });
      return options;
    }),
    logs: computed('loggingOptions.@each.selected', 'stashLogger.internalLogs.[]', function () {
      var _this2 = this;

      var logs = this.get('stashLogger.internalLogs').filter(function (_ref) {
        var topic = _ref.topic,
            level = _ref.level,
            itemsToLog = _ref.itemsToLog;
        return _lodash.default.some(_this2.get('loggingOptions').filterBy('selected', true), function (option) {
          return _this2.get('stashLogger').checkItemsAgainstOptions(option, level, topic, itemsToLog).local;
        });
      });
      logs.filter(function (log) {
        return !log.stringified;
      }).forEach(function (log) {
        return log.stringified = JSON.stringify(log.itemsToLog);
      });
      return logs;
    }),
    actions: {
      toggleOption: function toggleOption(option) {
        option.toggleProperty('selected');
      }
    }
  });

  _exports.default = _default;
});