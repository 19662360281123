define("web-directory/serializers/grant", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    extractId: function extractId(modelClass, resourceHash) {
      return resourceHash.subjectId + resourceHash.role.id + resourceHash.division.id;
    }
  });

  _exports.default = _default;
});