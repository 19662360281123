define("web-directory/utils/debug-rsvp", ["exports", "ember-purecloud-components/utils/debug-rsvp"], function (_exports, _debugRsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _debugRsvp.default;
    }
  });
});