define("web-directory/models/recorded-call", ["exports", "ember-data", "ember-purecloud-components/utils/browser"], function (_exports, _emberData, _browser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed,
      RSVP = Ember.RSVP; // CM-4671 : max retry count and retry timeout values for loading user audio recording in inbox

  var maxNumFetchAudioRetry = 10,
      retryAfterMs = 6000;
  var recordingRetryCountMap = new Map(); // CM-4671 : map to store retry count of each user recording api call

  var _default = _emberData.default.Model.extend({
    ajaxService: inject.service('ajax'),
    callerAddress: _emberData.default.attr(),
    callerName: _emberData.default.attr(),
    name: _emberData.default.attr(),
    dateCreated: _emberData.default.attr(),
    dateModified: _emberData.default.attr(),
    contentUri: _emberData.default.attr(),
    workspace: _emberData.default.attr(),
    createdBy: _emberData.default.attr(),
    conversation: _emberData.default.belongsTo('conversation'),
    contentLength: _emberData.default.attr(),
    durationMilliseconds: _emberData.default.attr(),
    thumbnails: _emberData.default.attr(),
    read: _emberData.default.attr(),
    selfUri: _emberData.default.attr(),
    date: computed('dateCreated', function () {
      return this.get('dateCreated');
    }),
    mediaUri: computed('media.contentLocationUri', function () {
      return this.get('media.contentLocationUri');
    }),
    otherParty: computed('conversation', function () {
      var model = this.get('conversation');
      return model.get('otherPartyModel');
    }),
    audioSource: computed('id', function () {
      var formatId = _browser.default.getSuggestedAudioTypeFrom(['webm', 'wav']);

      return this.fetchAudio(formatId).then(function (_ref) {
        var contentLocationUri = _ref.contentLocationUri;
        return contentLocationUri;
      });
    }),
    fetchAudio: function fetchAudio(formatId) {
      var _this = this;

      var reload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var resolver, pollingTimeout;
      var media = this.get('media');

      if (media) {
        resolver = function resolver(resolve) {
          resolve(media);
        };
      } else {
        var adapter = this.store.adapterFor('recorded-call');
        var endpoint = adapter.buildURL('recordedCall', this.get('id') + '/media'); // CM-4671: User Recording Inbox UI: Use async flow to download long-duration user recordings

        resolver = function resolver(resolve, reject) {
          _this.get('ajaxService').raw(endpoint, {
            data: {
              formatId: formatId,
              async: true
            },
            headers: adapter.headers,
            contentType: 'application/json; charset=UTF-8',
            cache: false
          }).then(function (res) {
            var status = res.jqXHR.status,
                userRecRetryCountKey = 'userrec_' + _this.get('id') + '_retrycount',
                // map variable to check indivitual user recording api retry count
            userRecRetryCurrent = recordingRetryCountMap.get(userRecRetryCountKey) || 0;

            if (status === 200) {
              _this.set('media', res.response);

              recordingRetryCountMap.delete(userRecRetryCountKey); // remove the map variable when the api returns 200 status

              return _this.set('audioSource', res.response.contentLocationUri); // to update the source of the audio player
            } else if (++userRecRetryCurrent < maxNumFetchAudioRetry && status === 202) {
              recordingRetryCountMap.set(userRecRetryCountKey, userRecRetryCurrent);
              pollingTimeout = setTimeout(function () {
                return _this.fetchAudio(formatId, reload);
              }, retryAfterMs); // retry after 6 secs to fetch audio call until the api request returns 200 status
            } else if (userRecRetryCurrent >= maxNumFetchAudioRetry && status === 202) {
              // stop the retry fetch audio call once it reaches the max retry count of 10
              if (pollingTimeout) clearTimeout(pollingTimeout);
              if (userRecRetryCountKey) recordingRetryCountMap.delete(userRecRetryCountKey);

              _this.set('audioSource', ''); // setting the audiosource to empty to show the error in audio player when the maximum retry count is done and still receive the 202 resource


              return RSVP.reject(new Error('Failed to load user recording'));
            }
          }, function (reason) {
            _this.set('media', {
              failed: 'Failed to load user recording'
            });

            reject(reason);
          });
        };
      }

      return new RSVP.Promise(resolver);
    }
  }).reopenClass({
    apiEndpoint: 'v2/userrecordings'
  });

  _exports.default = _default;
});