define("web-directory/components/search-page/component", ["exports", "lodash", "web-directory/models/group", "web-directory/models/location", "web-directory/models/person", "web-directory/utils/entity-fields"], function (_exports, _lodash, _group, _location, _person, _entityFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SEARCH_FACET_KEYPATHS = void 0;

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var PAGE_SIZE = 15;
  var FACET_COUNT = 10;
  var PAGE_LIMIT = 4;
  var keypathRegex = /[\w+.]+:"?.[^"]+([\w\s]+)?"?/gi;
  var SEARCH_FACET_KEYPATHS = {
    profileSkills: 'skills.skills',
    department: 'general.department',
    title: 'general.title',
    hobbies: 'bio.hobbies',
    interests: 'bio.interests',
    certifications: 'skills.certs',
    languages: 'languages.name'
  };
  _exports.SEARCH_FACET_KEYPATHS = SEARCH_FACET_KEYPATHS;

  function reverseLookup(obj, value) {
    for (var key in obj) {
      if (obj[key] === value) {
        return key;
      }
    }
  }

  var FacetTerm = Ember.Object.extend({
    facet: null,
    term: null,
    isKeyword: false,
    searchField: Ember.computed('facet.facetName', function () {
      return reverseLookup(SEARCH_FACET_KEYPATHS, this.get('facet.facetName'));
    }),
    toString: function toString() {
      if (this.get('isKeyword')) {
        return this.get('term.term');
      }

      var name = this.get('facet.facetName');
      var term = this.get('term.term');
      return "".concat(name, ":\"").concat(term, "\"");
    }
  });
  var View = {
    classNames: ['container-fluid', 'search-results-page'],
    setCurrentEntityType: Ember.on('didInsertElement', function () {
      var type = this.get('entity');

      if (!type) {
        type = 'all';
      }

      this.$('.filter').removeClass('active');
      this.$('.' + type).addClass('active');
    }),
    attachScrollListener: Ember.on('didInsertElement', function () {
      var _this = this;

      this.$().on('scroll.' + this.get('elementId'), function () {
        _this.loadNextPage();
      });
    }),
    removeListeners: Ember.on('willDestroyElement', function () {
      this.$().off('.' + this.get('elementId'));
    }),
    createDebouncedLoadNextPage: Ember.on('init', function () {
      this.loadNextPage = _lodash.default.debounce(function () {
        var _this2 = this;

        Ember.run(function () {
          if (_this2.get('model.isPending') || _this2.get('_state') !== 'inDOM') {
            return;
          }

          var scroll = _this2.$().scrollTop();

          var height = _this2.$('.search-content').height() - _this2.$().height();

          if (height - scroll <= 500) {
            _this2.send('loadMore');
          }
        });
      }, 150);
    }),
    // Keep loading pages of results until the page is filled with items.
    // The reason this was added was because when permissions are at play,
    // search results can come back with limited data.
    loadResultsToFillPage: Ember.observer('model.isFulfilled', function () {
      if (this.get('model.isFulfilled')) {
        this.loadNextPage();
      }
    }),
    scrollToTopOnPageZero: Ember.observer('page', function () {
      if (this.get('_state') === 'inDOM' && this.get('page') === 0) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          var $el = this.$();

          if ($el && $el.scrollTop) {
            $el.scrollTop(0);
          }
        });
      }
    })
  };
  var Controller = {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    search: Ember.inject.service(),
    notification: Ember.inject.service(),
    presenceSubscription: Ember.inject.service('presence-subscription'),
    integrationsPresence: Ember.inject.service(),
    _searchQuery: null,
    entity: null,
    query: null,
    queryFacets: null,
    saveHistory: true,
    page: 0,
    loading: {
      searchResults: false,
      facets: false
    },
    selectedTerms: [],
    searchResults: [],
    facets: null,
    totalResults: 0,
    actions: {
      changeSearchType: function changeSearchType(type) {
        var currentActive = this.get('entity');

        if (currentActive === type) {
          return false;
        }

        this.$('.filter').removeClass('active');
        this.$('.' + type).addClass('active');
        this.set('entity', type);
      },
      selectTerm: function selectTerm(term, facet) {
        var terms = this.get('selectedTerms');
        var termObj = terms.findBy('term', term);

        if (terms && terms.length === 10) {
          this.notifyTooManySearchFilters();
          return;
        }

        if (termObj !== undefined) {
          this.get('selectedTerms').removeObject(termObj);
        } else {
          termObj = FacetTerm.create({
            term: term,
            facet: facet
          });
          this.get('selectedTerms').addObject(termObj);
        }

        this.updateQueryOnTermsChange();
      },
      removeSelectedTerm: function removeSelectedTerm(term
      /*, facet*/
      ) {
        var termObj = this.get('selectedTerms').findBy('term', term);

        if (termObj) {
          this.get('selectedTerms').removeObject(termObj);
          this.updateQueryOnTermsChange();
        }
      },
      loadMore: function loadMore() {
        if (this.get('hasMoreResults')) {
          this.runSearchQuery(true);
        }
      }
    },
    atPageLimit: Ember.computed.equal('page', PAGE_LIMIT),
    remainingResults: Ember.computed('totalResults', 'page', function () {
      return this.get('totalResults') - this.get('page') * PAGE_SIZE;
    }),
    hasMoreResults: Ember.computed('remainingResults', 'loading.searchResults', function () {
      var moreResults = this.get('remainingResults') > 0;
      var loading = this.get('loading.searchResults');
      return !loading && moreResults;
    }),
    groupedSelectedTerms: Ember.computed('selectedTerms.[]', function () {
      var terms = this.get('selectedTerms');
      var data = [];
      terms.forEach(function (termObj) {
        var term = termObj.get('term');
        var facet = termObj.get('facet');
        var currentGroup = data.findBy('facet.facetName', facet.facetName);

        if (!currentGroup) {
          currentGroup = {
            facet: facet,
            terms: [term]
          };
          data.addObject(currentGroup);
        } else {
          currentGroup.terms.addObject(term);
        }
      });
      return data;
    }),
    isEmpty: Ember.computed('model.{isFulfilled,isRejected}', 'loading.searchResults', 'loading.facets', function () {
      if (this.get('model.isFulfilled')) {
        return this.get('totalResults') === 0;
      } else if (this.get('model.isRejected')) {
        return true;
      }

      return false;
    }),
    isGroups: Ember.computed.equal('entity', 'groups'),
    isLocations: Ember.computed.equal('entity', 'locations'),
    isExternalContacts: Ember.computed.equal('entity', 'externalContacts'),
    isExternalContactsEmpty: Ember.computed('isExternalContacts', 'totalResults', 'query', function () {
      return this.get('isExternalContacts') && this.get('totalResults') === 0 && !this.get('query');
    }),
    haveSelectedTerms: Ember.computed.notEmpty('selectedTerms'),
    displayFacets: Ember.computed('facets', 'selectedTerms', function () {
      var facets = this.get('facets');

      if (!Array.isArray(facets)) {
        return facets;
      } // remove facets which are selected terms


      var selectedTerms = this.get('selectedTerms');
      facets.forEach(function (facet) {
        var facetName = facet.facetName,
            terms = facet.terms;
        facet.terms = terms.filter(function (_ref) {
          var term = _ref.term;
          return !selectedTerms.find(function (selected) {
            return selected.get('facet.facetName') === facetName && selected.get('term.term') === term;
          });
        });
      }); // remove empty facets

      return facets.filter(function (_ref2) {
        var terms = _ref2.terms;
        return !_lodash.default.isEmpty(terms);
      });
    }),
    searchInitial: Ember.on('didInsertElement', function () {
      Ember.run.next(this, this.runSearchQuery);
    }),
    searchOnChange: Ember.observer('entity', 'query', 'queryFacets', function () {
      Ember.run.next(this, this.runSearchQuery);
    }),
    updateQueryOnTermsChange: function updateQueryOnTermsChange() {
      var terms = this.get('selectedTerms');

      var termStrings = _lodash.default.chain(terms).filter(function (term) {
        return !term.get('isKeyword');
      }).map(function (term) {
        return term.toString();
      }).value();

      var queryFacets = termStrings.join(' ');
      this.set('queryFacets', queryFacets);

      var keywordStrings = _lodash.default.map(_lodash.default.filter(terms, function (term) {
        return term.get('isKeyword');
      }), function (term) {
        return term.toString();
      });

      var query = keywordStrings.join(' ');
      this.set('query', query);
    },
    // <Search>
    _baseSearch: function _baseSearch() {
      return {
        pageSize: PAGE_SIZE,
        sortOrder: 'SCORE'
      };
    },
    _baseAggregations: function _baseAggregations() {
      var terms = ['department', 'title', 'profileSkills', 'hobbies', 'interests', 'certifications'];

      var aggregateTerm = function aggregateTerm(name) {
        return {
          name: name,
          field: name,
          type: 'TERM',
          size: FACET_COUNT,
          order: ['COUNT_DESC', 'VALUE_ASC']
        };
      };

      return terms.map(aggregateTerm);
    },
    _valueQuery: function _valueQuery(value) {
      if (value) {
        return [{
          type: 'QUERY_STRING',
          value: value
        }];
      }

      return [{
        type: 'MATCH_ALL'
      }];
    },
    _facetQuery: function _facetQuery(facets) {
      return facets.filter(function (facet) {
        return !facet.get('isKeyword');
      }).map(function (facet) {
        var value = facet.get('term.term');
        var fields = [facet.get('searchField')];
        return {
          value: value,
          fields: fields,
          type: 'EXACT'
        };
      });
    },
    _baseSearchForEntity: function _baseSearchForEntity(entityType) {
      if (entityType === 'people') {
        entityType = 'users';
      }

      if (entityType === 'externalContacts') {
        entityType = 'external_contacts';
      }

      if (entityType === 'all') {
        var types = ['users', 'groups', 'locations'];
        return {
          types: types,
          aggregations: this._baseAggregations(),
          returnFields: _entityFields.MULTI_ENTITY_FIELDS,
          expand: _person.BASE_PERSON_EXPANDS
        };
      }

      if (entityType === 'users') {
        return {
          types: ['users'],
          aggregations: this._baseAggregations(),
          returnFields: _person.BASE_PERSON_FIELDS,
          expand: _person.BASE_PERSON_EXPANDS
        };
      }

      if (entityType === 'locations') {
        return {
          types: ['locations'],
          returnFields: _location.BASE_LOCATION_FIELDS
        };
      }

      if (entityType === 'groups') {
        return {
          types: ['groups'],
          returnFields: _group.BASE_GROUP_FIELDS
        };
      }

      if (entityType === 'external_contacts') {
        return {
          types: ['external_contacts'],
          returnFields: _person.BASE_PERSON_FIELDS
        };
      } // Unsafe to assert because the `entity` query param could
      // be manually modified and blow up the page without warning.


      Ember.Logger.warn("_baseSearchForEntity(): Invalid entityType \"".concat(entityType, "\""));
      return this._baseSearchForEntity('all');
    },
    _buildSearch: function _buildSearch(entityType, search, facets) {
      var query = _toConsumableArray(this._facetQuery(facets));

      var valueQuery = this._valueQuery(search);

      if (search || _lodash.default.isEmpty(facets)) {
        query = [].concat([], query, valueQuery);
      }

      var base = this._baseSearch();

      if (!valueQuery[0].value) {
        base = Object.assign({}, base, {
          sortBy: 'name',
          sortOrder: 'ASC'
        });
      }

      return Object.assign({
        query: query
      }, base, this._baseSearchForEntity(entityType));
    },
    _normalizeSearch: function _normalizeSearch(data) {
      var total = data.total,
          aggregations = data.aggregations,
          results = data.results;
      var models = this.get('search').modelResults(results);

      var facets = this._processFacets(aggregations);

      return {
        results: models,
        total: total,
        facets: facets
      };
    },
    _processFacets: function _processFacets(rawFacets) {
      var facets = _lodash.default.chain(rawFacets).map(function (terms, key) {
        if (!Object.prototype.hasOwnProperty.call(SEARCH_FACET_KEYPATHS, key)) {
          return null;
        }

        return {
          key: key,
          facetName: SEARCH_FACET_KEYPATHS[key],
          terms: _lodash.default.map(terms, function (_ref3) {
            var term = _ref3.key,
                count = _ref3.count;
            return {
              term: term,
              count: count
            };
          })
        };
      }).filter().value();

      var keyOrder = ['department', 'title', 'profileSkills', 'hobbies', 'interests', 'certifications'];
      return _lodash.default.sortBy(facets, function (_ref4) {
        var key = _ref4.key;
        return keyOrder.indexOf(key);
      });
    },
    runSearchQuery: function runSearchQuery(isLoadMore) {
      var _this3 = this;

      if (this.get('loading.searchResults')) {
        return;
      }

      var entity = this.get('entity') || 'all';
      var query = this.get('query');
      var queryFacets = this.get('queryFacets');
      var selectedTerms = this.buildSelectedTerms(query, queryFacets);
      var nextPage = 1;

      if (isLoadMore) {
        nextPage = this.get('page') + 1;
      }

      var search = this._buildSearch(entity, query, selectedTerms);

      search.pageNumber = nextPage;
      var fetch = this.get('search').search(search).then(function (data) {
        return _this3._normalizeSearch(data);
      });
      this.set('loading.searchResults', true);
      this.set('loading.facets', !isLoadMore);
      fetch.then(function (_ref5) {
        var results = _ref5.results,
            total = _ref5.total,
            facets = _ref5.facets;

        var searchResults = _this3.get('searchResults');

        if (!isLoadMore) {
          searchResults.clear();
        }

        searchResults.pushObjects(results);

        _this3.set('page', nextPage);

        _this3.set('totalResults', total);

        _this3.set('facets', facets);

        _this3.set('selectedTerms', selectedTerms);

        return results;
      }).then(function () {
        var results = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        var people = results.filterBy('type', 'person');
        var ids = people.mapBy('id');

        if (ids) {
          return _this3.get('store').findMany('person', ids, _person.BASE_PERSON).then(function (userResults) {
            var integrationsPresence = _this3.get('integrationsPresence');

            if (integrationsPresence && integrationsPresence.get('useIntegrationPresenceDirectorySearch')) {
              return integrationsPresence.alterUsersWithIntegration(userResults);
            }

            return userResults;
          });
        }
      }).catch(function (reason) {
        var code = Ember.get(reason, 'payload.details.0.errorCode');

        if (code === 'TOO_MANY_SEARCH_CRITERIA') {
          _this3.notifyTooManySearchFilters();

          throw reason;
        }

        _this3.set('searchResults', []);

        throw reason;
      }).finally(function () {
        _this3.set('loading.searchResults', false);

        _this3.set('loading.facets', false);

        _this3.set('model', fetch);
      });
    },
    // </Search>
    searchKeywords: function searchKeywords(query) {
      var intoQuoteSegments = /(".*?"|'.*?'|[^"\s]+)+(?=\s*|\s*$)/g;
      return query.split(intoQuoteSegments).filter(function (keyword) {
        return keyword && keyword.trim();
      });
    },
    buildSelectedTerms: function buildSelectedTerms(query, queryFacets) {
      if (!query) {
        query = '';
      }

      if (!queryFacets) {
        queryFacets = '';
      }

      var selectedTerms = [];

      var addSelectedTerm = function addSelectedTerm(facetName, term, isKeyword) {
        selectedTerms.push(FacetTerm.create({
          isKeyword: isKeyword,
          facet: {
            facetName: facetName
          },
          term: {
            term: term
          }
        }));
      };

      var keywordFacetName = this.get('intl').t('search.keyword');
      this.searchKeywords(query).forEach(function (keyword) {
        addSelectedTerm(keywordFacetName, keyword, true);
      });
      var directedSearchTerms = queryFacets.match(keypathRegex);

      _lodash.default.forEach(directedSearchTerms, function (directedSearch) {
        var _directedSearch$split = directedSearch.split(':'),
            _directedSearch$split2 = _toArray(_directedSearch$split),
            facetName = _directedSearch$split2[0],
            searchSplit = _directedSearch$split2.slice(1);

        var value = searchSplit.join(':');

        if (value) {
          var term = value.replace(/"/g, '');
          addSelectedTerm(facetName, term);
        }
      });

      return selectedTerms;
    },
    notifyTooManySearchFilters: function notifyTooManySearchFilters() {
      var title = this.get('intl').t('search.errors.tooManyFilters.title');
      var message = this.get('intl').t('search.errors.tooManyFilters.message');
      this.get('notification').error(title, message);
    }
  };

  var _default = Ember.Component.extend(Controller, View);

  _exports.default = _default;
});