define("web-directory/utils/feature-toggles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    a11ySkipLink: true,
    acdAAEnabledToast: true,
    // ACE-2960
    acdAlerting: true,
    // ACE-2564
    acdAnnounceMonitoring: true,
    // ACE-2630
    acdAnnounceMonitoringDigital: true,
    // ACE-2630
    acdBlindTransferConference: true,
    // ACE-2571
    acdCallbackLogging: true,
    // ACE-3130 | SERVOPS-37862
    acdConferenceMS2: true,
    // PURE-4567 | ACE-2429
    acdConferencing: true,
    // Beta | PURE-2014
    acdConnectedToTwoCallsFix: true,
    // SERVOPS-34068 fix ACE-2594
    acdDialpadDialing: true,
    // Partially Enabled | ACE-1061 | SERVOPS-12594
    acdDivisionAwarePresenceToggle: true,
    // Divisioned Presence | PURE-3836 | ACE-2514
    acdDivisionPresenceProfileToggle: true,
    // Secondary translation fix for regions | ACE-3661
    acdEmailEnhancementsPhase1: true,
    // Partially Enabled | ACE-2543 | PURE-4715
    acdEmailNewDraftFlow: true,
    acdEmailNewLineOnPaste: true,
    // Partially Enabled | ACE-1350 | SERVOPS-17148
    acdEmailPreviewPerformance: true,
    // SERVOPS-35725
    acdEmailReloadImageBtn: true,
    // Partially Enabled | ACE-786 | SERVOPS-8993
    acdEmberTooltips: true,
    // ACE-3876 Ember tooltip new style parity
    acdFullWidth: true,
    // Partially Enabled | ACE-1966 | PURE-4009
    acdGUXToggleOutofOffice: true,
    // ACE-2846
    acdHybridToastNoActiveQueue: true,
    // Partially Enabled | ACE-1440
    acdImprovedBlindTransfer: true,
    // Partially Enabled | ACE-2431 | PURE-4028
    acdInteractionProcessingChain: true,
    // Remove Me
    acdLastLogin: true,
    acdMaxThirdPartyMessageLimit: true,
    // PURE-5733
    acdMessagingPerformance: true,
    acdMultiConsultInitiators: true,
    // ACE-2670
    acdMultipanel: true,
    // ACE-2967
    acdMultipanelForced: true,
    // ACE-4054
    acdNormalizedMessages: true,
    // ACE-2199
    acdOutboundOpenMessaging: true,
    // PURE-4937 | ACE-2912
    acdOutboundWhatsApp: true,
    // PURE-2802 | ACE-2511
    acdParkEmail: true,
    // Partially Enabled | ACE-2880 | MSGUI-358 | PURE-4201
    acdSaveWrapup: true,
    // ACE-2881 | PURE-3664
    acdSecurePause: true,
    // ACE-2443
    acdSelectionEnabled: true,
    acdTagsInput: true,
    // ACE-3640 | ACE-3655
    acdTransferPermissions: true,
    //PURE-4261
    'ACT.disconnectInteractions.migrate': true,
    //PURE-5424 Decomposing Disconnect Interactions Admin Page
    'ACT.groups.migrate': true,
    // ACT-430 Groups List Migration for Directory Admin UI feature
    'adminUI.v3.IFC.migration': true,
    agentAddressOnBehalfOfQueue: true,
    // MSG-5191 | PURE-3846 | TODO MSG-5859: remove toggle once direct routing is GA
    agentUIDefaultPanel3129: true,
    agentUIThirdPartyUtilization: true,
    agentUIV2MigrateAcdSkills: true,
    agentUIV2MigrateUtilization: true,
    agentUIV2MigrateWrapupCodes: true,
    'AI-1014.agent-copilot': true,
    'AI-768.voice-summarisation': true,
    // TODO: AA-2639: Remove once Voice Summarization has been released
    'AI-962.in-built-connector': true,
    'alerting-deprecation-eliminate-old-alerts-component': true,
    // PURE-4930
    'analytics-ui-pure-3731-export-expiration': true,
    'analytics-ui-reporting-pure-4310-static-link': true,
    'analytics-ui-reporting-pure-4709-alerting-push-notifications': true,
    'analytics-ui-reporting-pure-5094-alert-details': true,
    'analytics-ui-reporting-pure-5098-alert-management': true,
    archAdminUiDataTables: true,
    // TODO CW-3402: Remove toggle once architect data tables decomp is released
    ashfordWorkitems35AgentUi: true,
    ashfordWorkitems35AgentUiEventing: true,
    ashfordWorkitems35AgentUiEventingV2: true,
    ashfordWorkitems35AgentUiMainStage: true,
    ashfordWorkitems35AgentUiRosterStatus: true,
    ateamAppsV2InterapptionManager: true,
    // ATEAM-1194
    ateamEmailGadget: true,
    // ATEAM-1097
    backgroundAssistantScreenRecording: true,
    // WEM-80
    'browser-log-debug-enabled': true,
    //PURE-3205
    'CM.5459.documentsDecomposition': true,
    'coaching.central-ui': true,
    'coaching.overdue.ui': true,
    'coaching.usability.ui': true,
    'coaching.wem-addon': true,
    'coaching.wfm': true,
    'cobrowse.popoutAuthWindowFlow': true,
    'cobrowse.v2.voice': true,
    'cobrowse.v2': true,
    cobrowseAssist: true,
    'cobrowseV2-useEmbeddedInterapption': true,
    'comui-disable-legacy-api-url': true,
    'conversation.automateStartAndEndCallback.4052': true,
    // OV-913
    conversationSummary3256: true,
    conversationSummaryProfileCard: true,
    cookieConsentNotification: true,
    'CX.AI-571.smart-advisor': true,
    // AI-571
    'CX.AI-572.smart-advisor-products': true,
    'CX.AI-572.smart-advisor-voice': true,
    'CX.AI-863.add-acw-wrap-up': true,
    'CX.AI-914.smart-advisor-callback': true,
    'CX.AI-921.co-pilot': true,
    DgiMon: true,
    // AI-537
    digitalFirstPURE2942: true,
    'DX.AI-496.knowledge-optimizer': true,
    'DX.AI-509.knowledge-authoring': true,
    'DX.AI-818.co-pilot-advanced-summarization': true,
    'DX.AI-865.wrap-up-code-prediction': true,
    emergencyLocation: true,
    enableUIAjaxRetryHeader: true,
    'epc.decomp.alert-message': true,
    'epc.decomp.drop-down.chat-preferences': true,
    'epc.decomp.drop-down.emoji-picker': true,
    epcDecompDropDownCallFaxControl: true,
    epcDecompDropDownGroupProfileAllMembers: true,
    epcDecompDropDownGroupProfileEdit: true,
    epcDecompDropDownNavOnOffQueue: true,
    epcDecompEntityContactGroup: true,
    epcDecompEntityFavorite: true,
    epcDecompEntityLocation: true,
    epcDecompNavigationSubMenu: true,
    epcDecompPresenceLabel: true,
    extendedChatCommands: true,
    // used for disabling internal commands outside of our org
    freeTrial: true,
    // OB-272
    'gcucc-ui-GCUCCUI-1966': true,
    // GCUCCUI-1966
    'gcucc-ui-PCUCC-3366': true,
    'gcucc-ui-PCUCC-3494': true,
    // PCUCC-3494
    'gcucc-ui-PCUCC-3498': true,
    'gcucc-ui-PCUCC-3734': true,
    // PCUCC-3734
    'gcucc.backend.pcucc-1652-public-api': true,
    // PCUCC-2259
    'gcucc.ui.addChatWakeUpListener': true,
    // PCUCC-1810
    'gcucc.ui.apps.collaborateChat': true,
    // PCUCC-1893; use for collaborate-chat client app
    'gcucc.ui.apps.faxes': true,
    // use ucc ui faxes client app
    'gcucc.ui.apps.voicemail': true,
    // use ucc ui voicemail client app
    'gcucc.ui.chatBadging': true,
    // PCUCC-1008
    'gcucc.ui.decoupleRealtimeConnection': true,
    // PCUCC-2117
    'gcucc.ui.defaultPresenceSource': true,
    // PCUCC-1817
    'gcucc.ui.directRouting': true,
    // PCUCC-1959, PCUCC-1960
    'gcucc.ui.externalPresencePolling': true,
    // PCUCC-427
    'gcucc.ui.PCUCC-3104': true,
    'gcucc.ui.PURE-4771': true,
    'gcucc.ui.removeGcUserFromGroup.PCUCC-2543': true,
    // PCUCC-2543
    'gcucc.ui.useIntegrationPresenceSourceDirectorySearch': true,
    // PCUCC-1007
    'gcucc.ui.usePresenceSourceSuggestSearch': true,
    // PCUCC-820
    'gcucc.ui.userEmailNotificationSetting': true,
    // PCUCC-1091
    'gcucc.ui.useThirdPartyPresenceExpands': true,
    // PCUCC-1546, PCUCC-1379
    'gcv.newNumberManagement.view': true,
    // GCVUI-500
    'gpr.AP-8265.showCustomKpiLink': true,
    // AP-15781
    groupProileNoIndividualsSave: true,
    // PLUG-188
    hawkClientScreenShare: true,
    hideAdhocRecordingsUi: true,
    // PCUCC-4176
    inboxSharing: true,
    'integrations.decomposition.REG-1827': true,
    // INTEGRATIONS ADMIN UI DECOMPOSITION
    interactionNameQueueCallingQueue: true,
    // ACE-1872
    'journey-mgmt-PURE-4785-UI': true,
    'learning.release.assessment.ui': true,
    'learning.restrict-access': true,
    messagingGadget: true,
    // MG-470
    nanEmailGadgetIframe: true,
    // EG-3568
    'ner-PURE-4556': true,
    // NER-780
    'never-free-seating': true,
    notificationOutputPicker: true,
    'PCM-2352-server-side-pings': true,
    performanceExternalMetrics: true,
    performanceV2UI: true,
    playRecordingFromSelectedOutput: true,
    postinoEmailDraftChangedEvents: true,
    'presence.enableDivisionAwareDefinitionEndpoints': true,
    'PURE-3242_barge_in_voice': true,
    // is conversation service FT but also used for UI - ACE1707
    'PURE-3482.cannedResponses.queueSegmentation': true,
    'pure-4222-export-options': true,
    'PURE-5421_CannedResponse_Gadget': true,
    // PURE-5421
    'PURE-5822-work-items-panel-manager-support': true,
    'PURE3301-dynamicGroups': true,
    pure4201EmailParking: true,
    // ACE-2880 backend
    'qm.adminUIDecomp': true,
    // QM-16459
    'qm.release.evaluation.assignee': true,
    // WEM-742
    'qm.release.evaluation.disputes': true,
    // WEM-689
    'qm.release.pure-2967.timezone.selection': true,
    //PURE-2967
    'qm.ui.qualityAdmin.encryptionKeys': true,
    'qm.ui.qualityAdmin.qualityForms': true,
    'relate-ui-profilePanelRefresh': true,
    // RELATE-11001
    relateDynamicSchema: true,
    'reporting-pure-2244-alerting-interface': true,
    responseManagementMessagingTemplate: true,
    'scripter.ifc.integration': true,
    showVideoInThumbnail: true,
    // Show video on thumbnail if there is a screen stream, and participant is major, and video is not muted
    sparkPreferenceIcons: true,
    staCategories: true,
    staFeedbackDictionaryUI: true,
    'supportability.PURE-5129.events_ui': true,
    'switchboardUI.notificationRules': true,
    // WEM-578
    'syn-AI-768-beta': true,
    // TODO: AA-2639: Remove once Voice Summarization has been released
    telephonyAdminUIKoIFC: true,
    uiOptimizeChatParticipantsLoading: true,
    videoActivityIndicator: true,
    // TODO: check automation, then enable in stage, prod
    'webmessaging.4194.messengerAdminRedesign': true,
    // PURE-4194
    webrtcClientNetworkProbeAutoAdjust: true,
    webrtcDebugger: true,
    // used to disable in prod
    webrtcDefaultDeviceProfile: true,
    //PURE-5609
    webrtcDisableDeviceProfileNotifications: true,
    // PURE-5609
    webrtcHeadsetControlsOrchestration: true,
    webrtcMMSDnsCheck: true,
    webrtcRelayOnly: true,
    webrtcSoftphoneViaSdk: true,
    webrtcSoftphoneViaSdkBlacklisted: true,
    webrtcTrackBasedActions: true,
    webrtcTroubleshooterIframe: true,
    webrtcVideoDirectLink: true,
    webrtcWebdirLimitSCConnectAttempts: true,
    webTelemetryRaw: true,
    webUiDarkTheme: true,
    'WEM-1066_LongTermStaffingRequirements': true,
    // WEM-1066
    'WEM-1072_learning_decouple-apis': true,
    // WEM-1072
    'WEM-1161_CapacityPlanning': true,
    // WEM-1161
    'WEM-431_wfmHDIMultiFileUpload': true,
    // WEM-431
    'wfm.alternativeShifts': true,
    // WEM-491
    'wfm.staffingGroups': true,
    'wfm.timeOffAutoPublish': true,
    // WEM-379
    wfmActivityPlannerUI: true,
    'wfmUI.DateFormatEnhancement': true,
    // WEM-959
    'wfmUI.workPlanBidsAdmin': true,
    // WEM-639
    wfpDecisionsDataDownload: true,
    // WFM-7356
    workItemRouting: true // WORKITEMS-1546

  };
  _exports.default = _default;
});