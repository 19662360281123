define("web-directory/services/video-activity", ["exports", "ember-user-activity/services/user-activity"], function (_exports, _userActivity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _userActivity.default.extend({
    IDLE_TIMEOUT: 3000,
    // 3 seconds
    activeEvents: [] // no listeners by default

  });

  _exports.default = _default;
});