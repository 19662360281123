define("web-directory/adapters/callv2", ["exports", "web-directory/adapters/application", "web-directory/utils/dates", "lodash"], function (_exports, _application, _dates, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service,
      computed = Ember.computed;

  var _default = _application.default.extend({
    session: service(),
    application: service(),
    useExternalContactExpand: computed('session.features', function () {
      var features = this.get('session.features');
      return features && features['gcucc.ui.PURE-4771'];
    }),
    paging: {
      pageSize: 25,
      pageNumber: 1
    },
    queryDefaults: {
      currentInterval: (0, _dates.default)(),
      previousInterval: (0, _dates.default)().subtract(1, 'week')
    },
    query: function query(store, typeClass, _query) {
      if (_query.findLatestCalls) {
        return this._fetchLatestCalls.apply(this, arguments);
      }

      return this._fetchHistory.apply(this, arguments);
    },

    /**
     * _fetchLatestCalls - Fetch the latest calls
     * @return Promise
     */
    _fetchLatestCalls: function _fetchLatestCalls() {
      var guid = this.get('session.person.guid');

      if (!guid) {
        return Ember.RSVP.resolve([]);
      }

      var data = {
        interval: "".concat((0, _dates.default)().subtract(2, 'hours').toISOString(), "/").concat((0, _dates.default)().add(2, 'hours').toISOString())
      };
      return this.ajax(this.getHistoryPath(), 'GET', {
        data: data
      });
    },

    /**
     * _fetchHistory - Fetch the history in a given interval. This method handles paging and adjusting the interval parameter
     * depending on how many entities are returned so that subsequent calls will fetch previous conversations.
     * @param  {Object}  query     The default query passed in.
     * @return {Promise}
     */
    _fetchHistory: function _fetchHistory() {
      var _this = this;

      var query = arguments.length <= 2 ? undefined : arguments[2];
      var guid = this.get('session.person.guid');

      if (!guid) {
        return Ember.RSVP.resolve([]);
      }

      var queryDefaults = this.get('queryDefaults');

      var meta = _lodash.default.merge(queryDefaults, query);

      var paging = this.get('paging');
      var data = {
        interval: "".concat(meta.previousInterval.toISOString(), "/").concat(meta.currentInterval.toISOString()),
        pageSize: paging.pageSize,
        pageNumber: paging.pageNumber
      };
      return this.ajax(this.getHistoryPath(), 'GET', {
        data: data
      }).then(function (data) {
        if (data.entities && data.entities.length === _this.get('paging.pageSize')) {
          _this.incrementPageNumber();
        } else {
          _this.decrementWeek();
        }

        return data;
      });
    },

    /**
     * incrementPageNumber - Increments the page number
     */
    incrementPageNumber: function incrementPageNumber() {
      this.set('paging.pageNumber', this.get('paging.pageNumber') + 1);
    },

    /**
     * decrementWeek - Modifies interval to previous week
     */
    decrementWeek: function decrementWeek() {
      this.set('paging.pageNumber', 1);
      var currentInterval = this.get('queryDefaults.currentInterval');
      var previousInterval = this.get('queryDefaults.previousInterval');
      this.set('queryDefaults.currentInterval', currentInterval.subtract(1, 'week'));
      this.set('queryDefaults.previousInterval', previousInterval.subtract(1, 'week'));
    },

    /**
     * getHistoryPath - Generates history uri for xhr
     */
    getHistoryPath: function getHistoryPath() {
      if (this.get('useExternalContactExpand')) {
        return this.get('application').pcUri('api/v2/conversations/calls/history?expand=externalcontact');
      }

      return this.get('application').pcUri('api/v2/conversations/calls/history');
    }
  });

  _exports.default = _default;
});