define("web-directory/mixins/promise-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    addIndicatorForPromise: function addIndicatorForPromise(promise, target) {
      Ember.assert('You must specify a valid target selector', target && typeof target === 'string');

      if (promise && promise.then) {
        var view = Ember.$('<div class="loading-indicator"><i class="pc-moon pc-loading pc-spin"></i></div>');
        Ember.$(target).append(view);
        promise.then(function () {
          view.remove();
        });
      }
    }
  });

  _exports.default = _default;
});