define("web-directory/components/group-profile/roles-edit/fixtable/search-field/component", ["exports", "web-directory/components/group-profile/roles-edit/fixtable/search-field/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['search-field'],
    classNameBindings: ['positionSubclass'],
    debounce: 250,
    query: '',
    queryChanged: Ember.observer('query', function () {
      Ember.run.debounce(this, this.triggerSearch, this.get('debounce'));
    }),
    positionSubclass: Ember.computed('position', function () {
      var position = this.get('position');
      return position ? "search-field-".concat(this.get('position')) : '';
    }),
    triggerSearch: function triggerSearch() {
      this.sendAction('onChange', this.get('query'));
    },
    actions: {
      reset: function reset() {
        this.set('query', '');
        this.triggerSearch();
      }
    }
  });

  _exports.default = _default;
});