define("web-directory/services/timeline", ["exports", "web-directory/utils/dates"], function (_exports, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    events: Ember.A([]),
    cachedEvents: Ember.Object.create({}),
    sortedEvents: Ember.computed('events.@each.timeAt', function () {
      return this.get('events').sort(function (a, b) {
        return b.get('timeAt').diff(a.get('timeAt'));
      });
    }),
    mentionEvent: function mentionEvent(message) {
      // this key ensures mentions are clearable by room, and still have unique keys
      var eventKey = message.get('room.objectId') + '-mention-' + message.get('id');
      var mention = Ember.Object.create({
        message: message,
        eventKey: eventKey,
        type: 'mention',
        timeAt: message.get('time'),
        renderer: 'timeline-stream/mention-renderer'
      });
      this.get('events').unshiftObject(mention);
      this.get('cachedEvents').set(eventKey, mention);
    },
    directMessageEvent: function directMessageEvent(message) {
      var messageKey = message.get('id');
      var eventKey = message.get('chatUser.id');
      var cachedEvents = this.get('cachedEvents');
      var direct = cachedEvents.get(eventKey);

      if (!direct || !!direct && message.get('time').diff(direct.get('timeAt'), 'minutes') >= 10) {
        direct = Ember.Object.create({
          renderer: 'timeline-stream/direct-message-renderer',
          eventKey: eventKey,
          type: 'direct-message',
          timeAt: message.get('time'),
          from: message.get('chatUser'),
          messages: []
        });
        cachedEvents.set(eventKey, direct);
        this.get('events').unshiftObject(direct);
      } else {
        direct.set('timeAt', message.get('time'));
      }

      direct.get('messages').pushObject(message); // Store the event under the message id as well to enable duplicate checking

      cachedEvents.set(messageKey, direct);
    },
    roomMessageEvent: function roomMessageEvent(message) {
      var roomMessages = this.get('cachedEvents');
      var eventKey = message.get('room.objectId');
      var roomEvent = roomMessages.get(eventKey);

      if (!roomEvent || !!roomEvent && message.get('time').diff(roomEvent.get('timeAt'), 'minutes') >= 10) {
        roomEvent = Ember.Object.create({
          renderer: 'timeline-stream/room-message-renderer',
          eventKey: eventKey,
          type: 'room-message',
          timeAt: message.get('time'),
          room: message.get('room'),
          messages: []
        });
        roomMessages.set(eventKey, roomEvent);
        this.get('events').unshiftObject(roomEvent);
      } else {
        roomEvent.set('timeAt', message.get('time'));
      }

      roomEvent.get('messages').pushObject(message);
    },
    markEventAcknowledged: function markEventAcknowledged(event) {
      var key = event.get('eventKey');

      if (key && event.type !== 'missed-call') {
        this.get('cachedEvents').set(key, null);
      }
    },
    missedCallEvent: function missedCallEvent(call, callv2) {
      var eventKey = call.get('id');
      var missedCalls = this.get('cachedEvents');
      var callEvent = missedCalls.get(eventKey);

      if (!callEvent) {
        var timeAt = callv2 ? (0, _dates.default)(call.get('callStart')) : (0, _dates.default)(call.get('sortedSegmentBeginTimes')[0].segmentEnd);
        callEvent = Ember.Object.create({
          renderer: 'timeline-stream/missed-call-renderer',
          eventKey: eventKey,
          call: call,
          voicemail: null,
          type: 'missed-call',
          timeAt: timeAt
        });
        missedCalls.set(eventKey, callEvent);
        this.get('events').unshiftObject(callEvent);
      }
    },
    voicemailEvent: function voicemailEvent(voicemail) {
      var eventKey = voicemail.get('conversation.id');
      var voicemails = this.get('cachedEvents');
      var voicemailEvent = voicemails.get(eventKey);

      if (!voicemailEvent) {
        var timeAt = (0, _dates.default)(voicemail.get('createdDate'));
        voicemailEvent = Ember.Object.create({
          renderer: 'timeline-stream/missed-call-renderer',
          eventKey: eventKey,
          call: voicemail,
          voicemail: voicemail,
          type: 'missed-call',
          timeAt: timeAt
        });
        voicemails.set(eventKey, voicemailEvent);
        this.get('events').unshiftObject(voicemailEvent);
      } else {
        voicemailEvent.set('voicemail', voicemail);
      }
    },
    markAllRead: function markAllRead() {
      var _this = this;

      this.get('events').forEach(function (event) {
        event.set('acknowledged', true);

        _this.markEventAcknowledged(event);
      });
    },
    markRead: function markRead(type, eventId) {
      var _this2 = this;

      var event = this.get('cachedEvents').get(eventId);

      if (!!event) {
        event.set('acknowledged', true);
        this.markEventAcknowledged(event);
      }

      if (type === 'room') {
        //check for mentions and clear them
        (this.get('events') || Ember.A([])).filter(function (event) {
          return event && event.eventKey && event.eventKey.indexOf("".concat(eventId, "-mention")) >= 0;
        }).forEach(function (mention) {
          mention.set('acknowledged', true);

          _this2.markEventAcknowledged(mention);
        });
      }
    },
    clearAllEvents: function clearAllEvents() {
      this.set('events', Ember.A([]));
      this.set('cachedEvents', Ember.Object.create({}));
    }
  });

  _exports.default = _default;
});