define("web-directory/components/acd-interactions-panel/interaction-sms/messaging-system-message/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['messaging-system-message'],
    classNameBindings: ['eventTypeClassName', 'eventSubtype'],
    message: null,
    systemMessageTime: null,
    customerName: null,
    userName: null,
    nameForMessage: Ember.computed('message.direction', 'customerName', 'userName', function () {
      if (this.get('message.direction')) {
        return this.get('message.direction') === 'inbound' ? this.get('customerName') : this.get('userName');
      }

      return;
    }),
    eventType: Ember.computed('message.normalizedMessage.events.firstObject.eventType', function () {
      var type = this.get('message.normalizedMessage.events.firstObject.eventType');
      return type ? type.charAt(0).toLowerCase() + type.substring(1) : '';
    }),
    eventTypeClassName: Ember.computed('eventType', function () {
      if (this.get('eventType')) {
        return this.get('eventType').toLowerCase() + '-system-msg';
      }
    }),
    isSystemMsg: Ember.computed('eventType', function () {
      return this.get('eventType') === 'coBrowse' || this.get('eventType') === 'presence';
    }),
    eventSubtype: Ember.computed('isSystemMsg', 'message.normalizedMessage.events.firstObject.{coBrowse,presence}.type', function () {
      if (this.get('isSystemMsg')) {
        var subtype = this.get('message.normalizedMessage.events.firstObject.coBrowse.type') || this.get('message.normalizedMessage.events.firstObject.presence.type');
        return subtype ? subtype.charAt(0).toLowerCase() + subtype.substring(1) : '';
      }
    }),
    systemMessageLabel: Ember.computed('eventType', 'eventSubtype', function () {
      return 'purecloud.acdInteraction.sms.' + this.get('eventType') + '.' + this.get('eventSubtype');
    })
  });

  _exports.default = _default;
});