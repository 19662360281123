define("web-directory/mixins/sort-chat-users", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var presenceToRadixMapping = {
    available: 1,
    busy: 2,
    away: 3,
    idle: 3,
    offline: 4,
    on_queue: 5,
    out_of_office: 6
  };

  var radixMapping = function radixMapping(user) {
    if (user) {
      return presenceToRadixMapping[user.get('presence')];
    } else {
      return null;
    }
  };

  var userName = function userName(user) {
    return user.get('name') || '';
  };

  var mapUserToSortable = function mapUserToSortable(user) {
    return "".concat(radixMapping(user)).concat(userName(user));
  };

  var mapRoomToSortable = function mapRoomToSortable(room) {
    var radix = radixMapping(room.get('oneToOneTarget'));
    var name = room.get('subject');
    return "".concat(radix).concat(name);
  };

  var SortChatUsersMixin = Ember.Mixin.create({
    sortChatUsers: function sortChatUsers(usersList) {
      return _lodash.default.sortBy(usersList, mapUserToSortable);
    },
    sortOneToOneRooms: function sortOneToOneRooms(rooms) {
      return _lodash.default.sortBy(rooms, mapRoomToSortable);
    }
  });
  var _default = SortChatUsersMixin;
  _exports.default = _default;
});