define("web-directory/adapters/geolocation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend({
    session: Ember.inject.service(),
    application: Ember.inject.service(),
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

      if (url.indexOf('undefined') !== -1 || !this.get('session.person.guid')) {
        // ignore this by resolving undefined. something trying to save geolocation when not authenticated
        return Ember.RSVP.resolve();
      }

      return this.ajax(url, 'PATCH', {
        data: data
      });
    },
    urlForUpdateRecord: function urlForUpdateRecord(id) {
      return this.get('application').pcV2Uri("api/v2/users/".concat(this.get('session.person.guid'), "/geolocations/").concat(id));
    },
    handleResponse: function handleResponse(status) {
      var response = this._super.apply(this, arguments);

      if (response && response.name && response.name === 'Error') {
        response.code = status;
      }

      return response;
    }
  });

  _exports.default = _default;
});