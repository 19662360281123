define("web-directory/components/app-pool/component", ["exports", "web-directory/models/app-pool"], function (_exports, _appPool) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var get = Ember.get,
      Component = Ember.Component,
      computed = Ember.computed;
  /*
      Renders the app-pool which consists of the app instances and exclusive app
      pool states (e.g. app loading failure, Unknown app, pool allocation status).
      This component must always render the instances indicated by the app-pool model
      regardless of state.  The app-pool manages app lifecycle internally and may be
      bringing the app into or taking the app out of service.
  */

  var _default = Component.extend({
    classNames: ['app-pool'],
    classNameBindings: ['poolStateClassName'],
    // attrs
    appPool: null,
    poolStateClassName: computed('appPool.currState', function () {
      var result = '';
      var currState = this.get('appPool.currState');

      if (currState && currState.name) {
        result = "pool-state-".concat(Ember.String.dasherize(currState.name));
      }

      return result;
    }),
    inAutoLoadRetry: computed('appPool.currState.{name,details}', function () {
      return this.get('appPool.currState.name') === _appPool.POOL_STATES.LOAD_FAILED && this.get('appPool.currState.details.loadRetryScheduled') === true || this.get('appPool.currState.name') === _appPool.POOL_STATES.LOADING && this.get('appPool.currState.details.loadRetryAttempt') === true && this.get('appPool.currState.details.autoAttempt') === true;
    }),
    loadFailed: computed.equal('appPool.currState.name', _appPool.POOL_STATES.LOAD_FAILED),
    appNotFound: computed.equal('appPool.currState.name', _appPool.POOL_STATES.APP_NOT_FOUND),
    allocatingApp: computed('appPool.currState', function () {
      var result = null;
      var currState = this.get('appPool.currState');

      if (currState && _typeof(currState) === 'object' && currState.name === _appPool.POOL_STATES.ALLOCATING_SPACE) {
        result = get(currState, 'details.instance.app');
      }

      return result;
    }),
    allocationFailureApp: computed('appPool.currState', function () {
      var result = null;
      var currState = this.get('appPool.currState');

      if (currState && _typeof(currState) === 'object' && currState.name === _appPool.POOL_STATES.ALLOCATION_FAILED) {
        result = get(currState, 'details.app');
      }

      return result;
    }),
    showPoolState: computed.or('inAutoLoadRetry', 'loadFailed', 'appNotFound', 'allocatingApp', 'allocationFailureApp'),
    visibleInstanceId: computed('appPool.currState', function () {
      var result = null;
      var currState = this.get('appPool.currState');

      if (currState && _typeof(currState) === 'object' && currState.name === _appPool.POOL_STATES.SHOWING_APP) {
        result = get(currState, 'details.instance.instanceId');
      }

      return result;
    }),
    actions: {
      reloadApps: function reloadApps() {
        this.get('appPool').reloadApps();
      },
      onAppInstanceApiCall: function onAppInstanceApiCall() {
        var handler = this.get('onAppInstanceApiCall');

        if (handler && typeof handler === 'function') {
          handler.apply(void 0, arguments);
        }
      },
      reloadUpdatedApp: function reloadUpdatedApp(instance) {
        var appPool = this.get('appPool');
        var instanceIdToReload = instance.get('instanceId');
        return appPool.hideApp(instanceIdToReload).then(function () {
          return appPool.stopApp(instanceIdToReload);
        }).then(function () {
          return appPool.reloadApps();
        }).then(function () {
          return appPool.showApp(instance.get('appId'));
        });
      },
      closeDeletedApp: function closeDeletedApp(instance) {
        var _this = this;

        var appPool = this.get('appPool');
        var instanceIdToStop = instance.get('instanceId');
        return appPool.hideApp(instanceIdToStop).then(function () {
          return appPool.stopApp(instanceIdToStop);
        }).then(function () {
          return appPool.reloadApps();
        }).then(function () {
          var handler = _this.get('onDeletedAppClose');

          if (handler) {
            handler(instance);
          }
        });
      }
    }
  });

  _exports.default = _default;
});