define("web-directory/app-providers/conversation-summary-interapptions-provider", ["exports", "web-directory/utils/boolean-expressions"], function (_exports, _booleanExpressions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.boolExpr = void 0;
  var inject = Ember.inject,
      RSVP = Ember.RSVP;

  var hasChatInformation = function hasChatInformation(interaction) {
    var customer = interaction.get('participantForCustomer');
    if (!customer || !customer.attributes) return false;
    var attributes = customer.attributes;
    var phone = attributes['context.phoneNumber'];
    var email = attributes['context.email'];
    var street = attributes['context.addressStreet'];
    var city = attributes['context.addressCity'];
    var state = attributes['context.addressState'];
    var zip = attributes['context.addressPostalCode'];
    var hasCustomFieldData = [1, 2, 3].some(function (num) {
      var value = attributes["context.customField".concat(num)];
      var label = attributes["context.customField".concat(num, "Label")];
      return label || value;
    });
    return [phone, email, street, city, state, zip, hasCustomFieldData].some(function (x) {
      return !!x;
    });
  };

  var hasAdditionalCustomerInformation = function hasAdditionalCustomerInformation(interaction) {
    var PARTICIPANT_DATA_CARD_KEY_PREFIX = 'conversationSummary.participantData.';
    var customerParticipantData = interaction.get('participantForCustomer.attributes') || {};
    return Object.keys(customerParticipantData).some(function (key) {
      return key.startsWith(PARTICIPANT_DATA_CARD_KEY_PREFIX);
    });
  };

  var commonAppProps = {
    sandbox: ['allow-same-origin', 'allow-scripts', 'allow-popups'],
    query: '?pcEnvironment={{pcEnvironment}}&gcHostOrigin={{gcHostOrigin}}&gcTargetEnv={{gcTargetEnv}}&locale={{gcLangTag}}&interactionId={{gcConversationId}}&usePopupAuth={{gcUsePopupAuth}}',
    lifecycle: {
      hooks: {
        bootstrap: true
      }
    }
  };
  var ConversationSummaryInterapptionsProvider = Ember.Object.extend({
    intl: inject.service(),
    permissions: inject.service(),
    session: inject.service(),
    assetPrefix: 'https://inin-index-files-prod.s3.amazonaws.com/agent-conversation-summary/1.0.0/assets',
    id: 'conversationSummaryInterapptionsProvider',
    type: 'programmatic',
    trustedProvider: true,
    monitorInteractionUpdates: true,
    caresAboutExternalContact: Ember.computed.oneWay('session.features.conversationSummaryProfileCard'),
    getAppConfigs: function getAppConfigs(appContext) {
      var appContextType = appContext.type,
          payload = appContext.payload;
      if (appContextType === 'workitem') return RSVP.resolve([]);
      var apps = [];
      var permissions = this.get('permissions');
      var app;
      var agentHasViewPermission = permissions.validateDonutPermission('agentUI:conversationSummary:view');
      var hasChatWithData = payload.get('isChat') && hasChatInformation(payload);
      var hasParticipantData = hasAdditionalCustomerInformation(payload);
      var hasExternalContact = this.get('caresAboutExternalContact') && permissions.validateDonutPermission('externalContacts:contact:view') && payload.get('participantForCustomer.externalContactId');
      var icon = {
        guxIcon: {
          name: 'user-directory',
          decorative: true
        }
      };
      var conversationSummaryIsPopulated = hasChatWithData || hasParticipantData || hasExternalContact;

      if (agentHasViewPermission && conversationSummaryIsPopulated) {
        // set app as conversation summary
        app = Object.assign({}, commonAppProps, {
          id: 'agent-conversation-summary',
          icon: icon,
          monochromicIcon: icon,
          name: this.get('intl').t('purecloud.acdInteraction.conversationSummary'),
          path: '/agent-conversation-summary/#/'
        });
      } else if (hasChatWithData) {
        // set app as interaction details
        app = Object.assign({}, commonAppProps, {
          id: 'interaction-details',
          icon: icon,
          monochromicIcon: icon,
          name: this.get('intl').t('purecloud.acdInteraction.details.details'),
          path: '/agent-conversation-summary/#/interaction-details'
        });
      }

      if (app) {
        var branchName = this._getConversationSummaryTargetBranch(app);

        if (branchName === 'local') {
          app.url = "http://localhost:3000".concat(app.path.replace('/agent-conversation-summary', '')).concat(app.query);
          delete app.path;
          delete app.query;
        } else if (branchName) {
          app.path = app.path.replace('/#/', "/".concat(branchName, "/#/"));
        }

        apps.push(app);
      }

      return RSVP.resolve(apps);
    },
    _getConversationSummaryTargetBranch: function _getConversationSummaryTargetBranch() {
      var queryParams = new URLSearchParams(window.location.search);
      var targetBranch = queryParams.get('conversationSummarySource');
      return targetBranch;
    }
  });
  var boolExpr = (0, _booleanExpressions.ft)('conversationSummary3256');
  _exports.boolExpr = boolExpr;
  var _default = ConversationSummaryInterapptionsProvider;
  _exports.default = _default;
});