define("web-directory/components/entity-mini/default/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PxXkME0C",
    "block": "{\"statements\":[[6,[\"entity-mini\"],null,[[\"entity\",\"showDepartment\",\"showPresence\",\"subscribeToPresence\",\"showFavorite\",\"showActions\",\"clickToProfile\",\"isSearchResult\"],[[28,[\"entity\"]],[28,[\"showDepartment\"]],[28,[\"showPresence\"]],[28,[\"subscribeToPresence\"]],[28,[\"showFavorite\"]],[28,[\"showActions\"]],[28,[\"clickToProfile\"]],[28,[\"isSearchResult\"]]]],{\"statements\":[[6,[\"if\"],[[33,[\"eq\"],[[28,[\"section\"]],\"actions\"],null]],null,{\"statements\":[[0,\"        \"],[1,[33,[\"entity-contact\"],null,[[\"contact\",\"showChat\",\"showFavorite\"],[[28,[\"entity\"]],[28,[\"chat\",\"enabled\"]],[28,[\"showFavorite\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[\"section\"]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/entity-mini/default/template.hbs"
    }
  });

  _exports.default = _default;
});