define("web-directory/routes/engage/quality", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    redirect: function redirect(model, transition) {
      var engagePath = transition.intent.url.replace(/\/engage\//, '');
      var isEmbeddedEngageRoute = engagePath.indexOf('admin') > -1 || engagePath === 'quality';
      var features = this.get('session.features');

      if (features['qm.adminUIDecomp'] && engagePath === 'quality') {
        // To keep current behaviour, navigating to the Quality Admin route (`#/engage/quality`) will redirect to Evaluation Forms view
        return this.replaceWith('/admin/quality/evaluationForms');
      }

      if (isEmbeddedEngageRoute) {
        return this.transitionTo('engage.embedded', {
          engagePath: engagePath
        });
      }
    }
  });

  _exports.default = _default;
});