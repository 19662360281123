define("web-directory/initializers/main", ["exports", "web-directory/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MainInitializer = {
    name: 'orgspan',
    after: 'ember-data',
    initialize: function initialize(application) {
      // TODO: THIS IS BAD...remove later
      window.Orgspan = application;
      application.inject('component', 'store', 'service:store');
      /*
       * Ember is supposed to noop Ember.deprecate calls in production builds:
       * http://emberjs.com/api/classes/Ember.html#method_deprecate
       * However, this is a WIP:
       * https://github.com/ember-cli/rfcs/pull/50
       *
       * Until this RFC is implemented, kill the deprecation chain which will
       * result in noop (e.g. no logging) in non-dev environments.
       */

      if (_environment.default.environment !== 'development') {
        // Register an empty function to break the deprecation chain call
        Ember.Debug.registerDeprecationHandler(function () {});
      }
    }
  };

  if (_environment.default.environment === 'test') {
    MainInitializer.after = ['ember-data'];
  }

  var _default = MainInitializer;
  _exports.default = _default;
});