define("web-directory/components/entity-mini/external-contact/component", ["exports", "web-directory/mixins/relate/relate-features"], function (_exports, _relateFeatures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_relateFeatures.default, {
    classNames: ['external-contact'],
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    externalContact: null,
    showPhones: true,
    showEmails: false,
    showAcceptsSMS: true,
    acdOutboundWhatsApp: Ember.computed.readOnly('session.features.acdOutboundWhatsApp'),
    _getEmailTypes: function _getEmailTypes() {
      return Object.keys(this.externalContact.toJSON()).filter(function (modelKey) {
        return /.*Email$/.test(modelKey);
      });
    },
    _getPhoneTypes: function _getPhoneTypes() {
      var _this = this;

      return Object.keys(this.externalContact.toJSON()).filter(function (modelKey) {
        return _this.get('acdOutboundWhatsApp') ? /.*Phone$|whatsAppId/.test(modelKey) : /.*Phone$/.test(modelKey);
      });
    },
    dropdownTitle: Ember.computed('phones', function () {
      return this.get('intl').t('purecloud.acdInteraction.relate.titles.selectPhone');
    }),
    externalContactHasNoEmailAddresses: Ember.computed.not('externalContact.hasEmailAddresses'),
    phones: Ember.computed(function () {
      var _this2 = this;

      if (!this.get('showPhones')) {
        return [];
      }

      return this._getPhoneTypes().reduce(function (phones, phoneType) {
        if (_this2.get("externalContact.".concat(phoneType, ".display"))) {
          var canUseNonE164 = phoneType === 'otherPhone';

          var phoneTypeTranslated = _this2.get('intl').t("purecloud.acdInteraction.relate.phoneTypes.".concat(phoneType));

          var phoneTypeId = phoneType + '-' + _this2.get("externalContact.".concat(phoneType, ".e164")); //This needs to be unique for tooltips to work.


          return phones.concat({
            type: phoneTypeTranslated,
            phoneTypeId: phoneTypeId.replace(/\s|\+|#|\./g, ''),
            number: _this2.get("externalContact.".concat(phoneType, ".display")),
            e164: _this2.get("externalContact.".concat(phoneType, ".e164")),
            acceptsSMS: _this2.get("externalContact.".concat(phoneType, ".acceptsSMS")),
            canUseNonE164: canUseNonE164
          });
        } else if (_this2.get('acdOutboundWhatsApp') && _this2.get("externalContact.".concat(phoneType, ".phoneNumber.display"))) {
          var _canUseNonE = phoneType === 'otherPhone';

          var typeName = phoneType.replace(/Id/, '').toLowerCase();

          var _phoneTypeTranslated = _this2.get('intl').t("purecloud.acdInteraction.relate.channelTypes.".concat(typeName));

          var _phoneTypeId = phoneType + '-' + _this2.get("externalContact.".concat(phoneType, ".phoneNumber.e164")); //This needs to be unique for tooltips to work.


          return phones.concat({
            type: _phoneTypeTranslated,
            phoneTypeId: _phoneTypeId.replace(/\s|\+|#|\./g, ''),
            number: _this2.get("externalContact.".concat(phoneType, ".phoneNumber.display")),
            e164: _this2.get("externalContact.".concat(phoneType, ".phoneNumber.e164")),
            acceptsSMS: _this2.get("externalContact.".concat(phoneType, ".phoneNumber.acceptsSMS")),
            canUseNonE164: _canUseNonE
          });
        }

        return phones;
      }, []);
    }),
    externalContactHasNoPhoneNumbers: Ember.computed.empty('phones'),
    emails: Ember.computed(function () {
      var _this3 = this;

      if (!this.get('showEmails')) {
        return [];
      }

      return this._getEmailTypes().reduce(function (email, emailType) {
        if (_this3.get("externalContact.".concat(emailType))) {
          return email.concat({
            type: _this3.get('intl').t("purecloud.acdInteraction.relate.emailTypes.".concat(emailType)),
            address: _this3.get("externalContact.".concat(emailType))
          });
        }

        return email;
      }, []);
    }),
    hasMultipleEmails: Ember.computed.gt('emails.length', 1),
    hasMultiplePhoneRecords: Ember.computed.gt('phones.length', 1),
    actions: {
      selectEntityWithEmail: function selectEntityWithEmail(email, emailType, event) {
        this.set('externalContact.email', email);
        this.set('externalContact.emailType', emailType);

        if (typeof this.get('onSelectEmail') === 'function') {
          this.get('onSelectEmail')(email, emailType, event);
        }
      },
      selectEntityWithPhone: function selectEntityWithPhone(phoneNumber, phoneType, event) {
        this.set('externalContact.phoneNumber', phoneNumber);
        this.set('externalContact.phoneType', phoneType);

        if (typeof this.get('onSelectPhone') === 'function') {
          this.get('onSelectPhone')(phoneNumber, phoneType, event);
        }
      },
      onPreventClick: function onPreventClick(event) {
        event.stopPropagation();
      }
    }
  });

  _exports.default = _default;
});