define("web-directory/templates/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0/GFOw7D",
    "block": "{\"statements\":[[1,[33,[\"search-page\"],null,[[\"entity\",\"query\",\"queryFacets\",\"saveHistory\"],[[28,[\"entity\"]],[28,[\"query\"]],[28,[\"queryFacets\"]],[28,[\"saveHistory\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/templates/search.hbs"
    }
  });

  _exports.default = _default;
});