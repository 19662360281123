define("web-directory/components/alert-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "67GQ92QX",
    "block": "{\"statements\":[[11,\"i\",[]],[16,\"class\",[26,[\"icon\"]],null],[13],[14],[0,\"\\n\"],[11,\"div\",[]],[15,\"class\",\"message\"],[13],[18,\"default\"],[14]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/alert-message/template.hbs"
    }
  });

  _exports.default = _default;
});