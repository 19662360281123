define("web-directory/components/timeline-stream/missed-call-renderer/component", ["exports", "lodash", "web-directory/components/timeline-stream/renderer-component-base"], function (_exports, _lodash, _rendererComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var MissedCallRenderer = _rendererComponentBase.default.extend({
    classNameBindings: [':timeline-event', ':missed-call-timeline-event', 'acknowledged'],
    intl: Ember.inject.service(),
    phone: Ember.inject.service('phone'),
    chat: Ember.inject.service('chat'),
    addressformat: Ember.inject.service(),
    permissions: Ember.inject.service(),
    store: Ember.inject.service(),
    canViewChat: Ember.computed.reads('permissions.canViewBusinessChat'),
    call: null,
    voicemail: null,
    showVoicemail: false,
    phoneNumber: null,
    otherPartyGuid: null,
    caller: null,
    callText: Ember.computed('caller', function () {
      var person = this.get('caller');

      if (person) {
        if (this.get('showVoicemail')) {
          return this.get('intl').t('timeline.missedCall.voicemailFrom', {
            person: person
          });
        }

        return this.get('intl').t('timeline.missedCall.callFrom', {
          person: person
        });
      }

      return this.get('intl').t('timeline.missedCall.missed');
    }),
    actions: {
      makeCall: function makeCall() {
        this.get('phone').makeCall(this.get('phoneNumber'));
      }
    },
    didReceiveAttrs: function didReceiveAttrs(_ref) {
      var newAttrs = _ref.newAttrs,
          oldAttrs = _ref.oldAttrs;

      this._super.apply(this, arguments);

      if (!oldAttrs || oldAttrs.voicemail.value === null && newAttrs.voicemail.value) {
        this.setCaller();
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.setCaller();
    },
    person: Ember.computed('otherPartyGuid', function () {
      if (this.get('otherPartyGuid')) {
        return this.store.findRecord('person', this.get('otherPartyGuid'));
      }

      return Ember.RSVP.resolve();
    }),
    setCaller: function setCaller() {
      var _this = this;

      if (this.get('entity.voicemail')) {
        //is a voicemail recording
        var caller = this.get('entity.voicemail.callerAddress') || this.get('entity.voicemail.callerName');
        this.set('showVoicemail', true);
        this.set('caller', caller);
      } //is a missed call with no voicemail


      var callObject = this.get('entity.call');

      var otherParty = callObject.get('initiator') || _lodash.default.last(callObject.get('otherParties'));

      if (!otherParty) {
        return Ember.RSVP.resolve();
      }

      var number = otherParty.ani;

      if (!number) {
        number = otherParty.dnis;
      }

      this.set('phoneNumber', number);
      var otherPartyUserId = otherParty.userId ? otherParty.userId : otherParty.user && otherParty.user.id ? otherParty.user.id : null;

      if (otherPartyUserId) {
        this.set('otherPartyGuid', otherPartyUserId);
        return this.get('store').find('person', otherPartyUserId).then(function (result) {
          _this.set('caller', result.get('name'));
        });
      } else if (otherParty.purpose.toLowerCase() === 'external') {
        try {
          var _caller = this.get('addressformat').formatAddressForDisplay(number);

          this.set('caller', _caller);
        } catch (error) {
          Ember.Logger.warn('invalid phone number');
          this.set('caller', null);
        }
      } else if (otherParty.purpose.toLowerCase() === 'user') {
        var _caller2 = this.get('addressformat').formatAddressForDisplay(number);

        this.set('caller', _caller2);
      } else {
        this.set('caller', number);
      }

      return Ember.RSVP.resolve();
    }
  });

  var _default = MissedCallRenderer;
  _exports.default = _default;
});