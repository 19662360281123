define("web-directory/mixins/faxes", ["exports", "lodash", "ember-ajax", "web-directory/models/fax"], function (_exports, _lodash, _emberAjax, _fax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FaxesMixin = Ember.Mixin.create({
    application: Ember.inject.service(),
    getFaxes: function getFaxes() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        var request = {
          contentType: 'application/json; charset=UTF-8',
          cache: false
        };

        var url = _this.get('application').pcV2Uri('api/v2/fax/documents');

        return (0, _emberAjax.default)(url, request).then(function (_ref) {
          var _ref$entities = _ref.entities,
              entities = _ref$entities === void 0 ? [] : _ref$entities;
          var owner = Ember.getOwner(_this);
          return resolve(_lodash.default.map(entities, function (fax) {
            return _fax.default.create(fax, owner.ownerInjection());
          }));
        });
      });
    },
    readFax: function readFax(fax) {
      this.changeFaxReadState(fax, true);
      var contentUri = fax.get('contentUri');
      var sharingUri = fax.get('sharingUri');

      if (sharingUri) {
        window.open(sharingUri);
      } else {
        (0, _emberAjax.default)(contentUri, {
          cache: false
        }).then(function (contentLocationUri) {
          window.open(contentLocationUri);
        });
      }
    },
    markFaxesRead: function markFaxesRead(faxes) {
      var _this2 = this;

      _lodash.default.each(faxes, function (fax) {
        _this2.changeFaxReadState(fax, true);
      });
    },
    markFaxesUnread: function markFaxesUnread(faxes) {
      var _this3 = this;

      _lodash.default.each(faxes, function (fax) {
        _this3.changeFaxReadState(fax, false);
      });
    },
    changeFaxReadState: function changeFaxReadState(fax, read) {
      fax.set('read', read);
      var id = fax.get('id');
      (0, _emberAjax.default)(this.get('application').pcV2Uri("api/v2/fax/documents/".concat(id)), {
        cache: false,
        method: 'PUT',
        contentType: 'application/json; charset=UTF-8',
        dataType: 'json',
        data: JSON.stringify({
          id: id,
          read: read
        })
      });
    },
    renameFax: function renameFax(fax, name) {
      fax.set('name', name);
      var id = fax.get('id');
      return (0, _emberAjax.default)(this.get('application').pcV2Uri("api/v2/fax/documents/".concat(id)), {
        cache: false,
        method: 'PUT',
        contentType: 'application/json; charset=UTF-8',
        dataType: 'json',
        data: {
          name: name
        }
      });
    },
    deleteFaxes: function deleteFaxes(faxes) {
      var _this4 = this;

      Ember.RSVP.all(_lodash.default.map(faxes, function (fax) {
        _this4.deleteFax(fax);
      }));
    },
    downloadFax: function downloadFax(id) {
      (0, _emberAjax.default)(this.get('application').pcV2Uri("api/v2/fax/documents/".concat(id, "/content")), {
        cache: false,
        method: 'GET',
        contentType: 'application/json; charset=UTF-8',
        dataType: 'json'
      }).then(function (_ref2) {
        var contentLocationUri = _ref2.contentLocationUri;
        window.open(contentLocationUri);
      });
    },
    deleteFax: function deleteFax(fax) {
      (0, _emberAjax.default)(this.get('application').pcV2Uri("api/v2/fax/documents/".concat(fax.get('id'))), {
        cache: false,
        method: 'DELETE',
        contentType: 'application/json; charset=UTF-8'
      });
    }
  });
  var _default = FaxesMixin;
  _exports.default = _default;
});