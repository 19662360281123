define("web-directory/components/common-badge/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['common-badge'],
    classNameBindings: ['accent', 'bold'],

    /**
     * @attribute
     * the accent styling that the badge should be rendered in
     */
    accent: 'info',

    /**
     * @attribute
     * `true` if the badge should utilize the bold, more vibrant, color palette
     */
    bold: false
  });

  _exports.default = _default;
});