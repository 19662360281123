define("web-directory/services/analytics", ["exports", "platform", "web-directory/utils/newrelic"], function (_exports, PlatformJS, _newrelic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildCommonTelemetryContext = buildCommonTelemetryContext;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Service.extend({
    enabled: true,
    application: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    permissions: Ember.inject.service(),
    isContentsquareInitialized: false,
    init: function init() {
      this._super.apply(this, arguments); // Fetch dependent services/props to trigger lazy load and wire setDimensions observer


      this.get('session.isAuthenticated');
      this.get('intl.isoLocale');
      this.initializeContentsquare();
    },
    setupDimensions: true,
    setDimensions: Ember.observer('session.isAuthenticated', 'intl.isoLocale', function () {
      if (this.get('setupDimensions') && this.get('enabled') && this.get('session.isAuthenticated') && this.get('intl.isoLocale')) {
        this.set('setupDimensions', false); // Add contextual props as custom attributes for all NR Events

        Object.entries(buildCommonTelemetryContext({
          applicationService: this.get('application'),
          sessionService: this.get('session'),
          intlService: this.get('intl')
        })).forEach(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
              key = _ref2[0],
              value = _ref2[1];

          (0, _newrelic.setCustomAttribute)(key, value);
        });
        var userId = this.get('session.person.guid');
        (0, _newrelic.addPageAction)('userAuthenticated', {
          userId: userId
        });
      } else {
        this.set('setupDimensions', true);
      }
    }),
    initializeContentsquare: Ember.observer('session.isAuthenticated', function () {
      if (this.get('session.isAuthenticated') && window._uxa) {
        var organizationId = this.get('session.publicApiUser.organization.id');
        var orgShortName = this.get('session.publicApiUser.organization.thirdPartyOrgName');

        if (!this.isContentsquareInitialized) {
          this.sendContentsquareSessionVar('orgId', organizationId);
          this.sendContentsquareSessionVar('orgShortName', orgShortName);
          this.set('isContentsquareInitialized', true);
        }
      }
    }),
    sendContentsquareSessionVar: function sendContentsquareSessionVar(name, value) {
      if (window._uxa) {
        try {
          window._uxa.push(['trackDynamicVariable', {
            key: name,
            value: value
          }]);
        } catch (err) {
          Ember.debug('Attempted to add Contentsquare variables without loading library.');
        }
      }
    }
  });
  /**
   * Builds common properties that could be useful in many telemetry and logging situations.
   * The data must ALWAYS be safe for logging.  i.e. no customer or PII data.
   */


  _exports.default = _default;

  function buildCommonTelemetryContext(_ref3) {
    var applicationService = _ref3.applicationService,
        sessionService = _ref3.sessionService,
        intlService = _ref3.intlService;
    var isHosted = applicationService.get('hostedContext.isHostedProp');
    var commonCtxt = {
      applicationVersion: applicationService.get('semver'),
      organizationId: sessionService.get('org.guid'),
      organization: sessionService.get('org.shortName'),
      userId: sessionService.get('person.guid'),
      uiLanguage: intlService.get('isoLocale'),
      envName: applicationService.get('runtime.name'),
      isPopout: applicationService.get('isPopout'),
      webDirAgent: isHosted ? 'desktop' : 'browser',
      platformJsBrowserName: PlatformJS.name,
      platformJsBrowserVersion: PlatformJS.version,
      platformJsProduct: PlatformJS.product,
      platformJsManufacturer: PlatformJS.manufacturer,
      platformJsLayout: PlatformJS.layout,
      platformJsOperatingSystem: PlatformJS.os.toString(),
      platformJsDescription: PlatformJS.description
    };

    if (isHosted) {
      return Object.assign({}, commonCtxt, {
        desktopOS: applicationService.get('hostedContext._operatingSystem'),
        desktopVersion: applicationService.get('hostedContext._version')
      });
    }

    return commonCtxt;
  }
});