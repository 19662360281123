define("web-directory/components/acd-interactions-panel/interaction-notes/interaction-notes-history/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RxDm8hrw",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"notesHistory\"]]],null,{\"statements\":[[0,\"    \"],[11,\"div\",[]],[15,\"class\",\"notes-history-title\"],[13],[0,\"\\n        \"],[11,\"a\",[]],[15,\"href\",\"#\"],[15,\"class\",\"notes-history-toggle\"],[5,[\"action\"],[[28,[null]],\"toggleShowHistory\"]],[13],[0,\"\\n            \"],[1,[33,[\"t\"],[[33,[\"concat\"],[\"purecloud.acdInteraction.\",[33,[\"if\"],[[28,[\"showNotesHistory\"]],\"notesHistoryHide\",\"notesHistoryShow\"],null]],null]],null],false],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\n\"],[6,[\"if\"],[[28,[\"showNotesHistory\"]]],null,{\"statements\":[[6,[\"each\"],[[28,[\"notesHistory\"]]],null,{\"statements\":[[0,\"        \"],[1,[33,[\"acd-interactions-panel/interaction-notes/interaction-notes-history/interaction-notes-history-item\"],null,[[\"item\",\"canViewChat\"],[[28,[\"item\"]],[28,[\"canViewChat\"]]]]],false],[0,\"\\n\"]],\"locals\":[\"item\"]},null]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/acd-interactions-panel/interaction-notes/interaction-notes-history/template.hbs"
    }
  });

  _exports.default = _default;
});