define("web-directory/components/group-profile/roles-edit/fixtable/division-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    groupAssignedRoles: Ember.inject.service(),
    // tracks the roles/division relationships for display
    isAssigned: Ember.computed('groupAssignedRoles.changeCount', function () {
      return this.get('groupAssignedRoles').containsRole(this.get('dataRow.id'));
    }),
    rowHasToggled: Ember.observer('groupAssignedRoles.changeCount', function () {
      var isAssigned = this.get('groupAssignedRoles').containsRole(this.get('dataRow.id'));
      var wasAssigned = this.get('wasAssigned');

      if (wasAssigned === isAssigned) {
        return;
      } else {
        this.set('wasAssigned', isAssigned);
      }

      if (!isAssigned) {
        var previouslySelected = this.get('selectedDivisions');
        this.set('selectedDivisions', []);

        this._updateDivisions([], previouslySelected);
      } else {
        var homeDivision = this.get('store').peekAll('division').find(function (division) {
          return division.get('homeDivision');
        });
        var selected = [homeDivision];
        this.set('selectedDivisions', selected);

        this._updateDivisions(selected, []);
      }
    }),
    allFutureDivisionsSelected: false,
    actions: {
      updateDivisions: function updateDivisions(currentSelection, info) {
        this._updateDivisions(currentSelection, info.selected);
      }
    },
    _updateDivisions: function _updateDivisions(currentSelection, previousSelection) {
      var allFutureDivision = currentSelection.find(function (division) {
        return division.id === '*';
      }); //don't allow special division to exist with any other division

      if (allFutureDivision) {
        currentSelection = [allFutureDivision];
      }

      this.set('allFutureDivisionsSelected', !!allFutureDivision);
      this.set('selectedDivisions', currentSelection);
      var roleId = this.get('dataRow.id');
      this.bubbleAction('updateDivisions', roleId, currentSelection, previousSelection);
    },
    _loadDivisions: function _loadDivisions() {
      this.set('divisions', this.get('store').peekAll('division'));
    },
    _setSelectedDivisions: function _setSelectedDivisions() {
      // need to set selected divisions from the divisions array to make select work
      var roleId = this.get('dataRow.id');
      var roleDivisions = this.get('groupAssignedRoles').getRoleWithDivisions(roleId) ? Ember.get(this.get('groupAssignedRoles').getRoleWithDivisions(roleId), 'divisions') : [];

      if (roleDivisions && roleDivisions.length) {
        var allDivisions = this.get('divisions');
        var allFutureDivisionsSelected = roleDivisions.some(function (division) {
          return division.id === '*';
        });
        this.set('allFutureDivisionsSelected', allFutureDivisionsSelected);
        var selectedDivisions = [];
        roleDivisions.forEach(function (selectedDivision) {
          selectedDivisions.push(allDivisions.find(function (division) {
            return division.id === selectedDivision.id;
          }));
        });
        this.set('selectedDivisions', selectedDivisions);
      }
    },
    init: function init() {
      this._loadDivisions();

      this._setSelectedDivisions();

      this.set('wasAssigned', this.get('groupAssignedRoles').containsRole(this.get('dataRow.id'))); //for passing into  custom components (e.g. select-footer)

      this.set('extra', {
        rolesEditComponent: this
      });
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});