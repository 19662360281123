define("web-directory/utils/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getActiveTopLevelMenuItem = getActiveTopLevelMenuItem;
  var typeOf = Ember.typeOf;
  /**
   * Returns the first top-level menu item found that should be considered active.
   *
   * The initial pass will be a DFS of the top-level items and decendents looking for an
   * active match based on the menu item route or url properties (both are considered active
   * if they are the active route or a parent of the active route).
   *
   * If no suitable active menu item is found, a path-based search will be performed.
   * This will use the paths associated to top-level menu items specified in
   * menuItemBasePathsMap or the route's effective URL otherwise.
   *
   * @param {array} menu an array of top-level menu items containing 0 or more child menu items.
   * @param {*} The Ember router instance
   * @param {object} options
   * @param {object.menuItemBasePathsMap} a map of top-level menu item instances to an array of string paths.
   *   If you wish to opt out of path matching for a given top-level menu item, provide an empty array for it.
   * @returns the first-located top-level menu item that should be marked as active.
   */

  function getActiveTopLevelMenuItem(menu, router) {
    var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
        menuItemBasePathsMap = _ref.menuItemBasePathsMap;

    if (!menu || typeOf(menu) !== 'array' || !router) {
      logError('Invalid menu or router');
      return null;
    }

    try {
      assertValidRouter(router);
    } catch (e) {
      logError('Invalid router shape', {
        e: e,
        remoteData: {
          reason: e.message
        }
      });
      return null;
    }

    var definitiveMatch = menu.find(function (curr) {
      return isMenuBranchActive(curr, router);
    }) || null;

    if (definitiveMatch) {
      return definitiveMatch;
    }

    return menu.find(function (curr) {
      return isMenuBranchActivePathRoot(curr, router, menuItemBasePathsMap);
    }) || null;
  }

  function isMenuBranchActive(topLevelMenuItem, router) {
    var menuItemType = typeOf(topLevelMenuItem);

    if (!isObjectOrInstanceType(menuItemType)) {
      logWarn('Invalid top level menu item type', {
        remoteData: {
          type: menuItemType
        }
      });
      return false;
    }

    var className = topLevelMenuItem.className,
        label = topLevelMenuItem.label,
        children = topLevelMenuItem.children;

    if (children) {
      if (typeOf(children) !== 'array') {
        logWarn('Top Level Menu Item children prop is not an array', {
          label: label,
          remoteData: {
            className: className
          }
        });
        return false;
      }

      return children.find(function (curr) {
        return isLeafMenuItemActive(curr, router);
      }) || false;
    }

    return isLeafMenuItemActive(topLevelMenuItem, router);
  }

  function isLeafMenuItemActive(menuItem, router) {
    var menuItemType = typeOf(menuItem);

    if (!isObjectOrInstanceType(menuItemType)) {
      logWarn('Invalid leaf menu item type', {
        remoteData: {
          type: menuItemType
        }
      });
      return false;
    }

    var className = menuItem.className,
        label = menuItem.label,
        route = menuItem.route,
        url = menuItem.url;

    if (route) {
      try {
        return isEmberRouteActive(route, router);
      } catch (e) {
        logWarn('Failed while checking ember route active state', {
          label: label,
          error: e,
          remoteData: {
            className: className
          }
        });
        return false;
      }
    } else if (url) {
      if (typeOf(url) !== 'string') {
        logWarn('Menu Item URL is not a string', {
          label: label,
          remoteData: {
            className: className
          }
        });
        return false;
      }

      return formatRoutePath(router.currentURL).startsWith(formatRoutePath(url));
    } else {
      logWarn('Unknown Menu Item Type', {
        label: label,
        remoteData: {
          className: className
        }
      });
      return false;
    }
  }

  function isEmberRouteActive(route, router) {
    var routeType = typeOf(route);

    if (routeType === 'string') {
      return router.isActive(route);
    } else if (routeType === 'object' && route.name) {
      try {
        return router.isActive(route.name, route.segment);
      } catch (_) {
        // The isActive call can fail if the incorrect models and segments are passed for a route
        throw new Error('isActive check failed');
      }
    } else {
      throw new Error('Unexpected Ember Route payload');
    }
  }

  function isMenuBranchActivePathRoot(topLevelMenuItem, router, menuItemBasePathsMap) {
    var menuItemType = typeOf(topLevelMenuItem);

    if (!isObjectOrInstanceType(menuItemType)) {
      logWarn('Invalid top level menu item type', {
        remoteData: {
          type: menuItemType
        }
      });
      return false;
    }

    var className = topLevelMenuItem.className,
        label = topLevelMenuItem.label;

    try {
      var basePaths = getMenuItemBasePaths(topLevelMenuItem, router, menuItemBasePathsMap);

      if (basePaths.length === 0) {
        return false;
      }

      return basePaths.some(function (currBasePath) {
        return formatRoutePath(router.currentURL).startsWith(formatRoutePath(currBasePath));
      });
    } catch (e) {
      logWarn('Failed to get basePaths for top-level menu item', {
        label: label,
        error: e,
        remoteData: {
          className: className
        }
      });
      return false;
    }
  }

  function getMenuItemBasePaths(topLevelMenuItem, router, menuItemBasePathsMap) {
    if (menuItemBasePathsMap && menuItemBasePathsMap.has(topLevelMenuItem)) {
      return menuItemBasePathsMap.get(topLevelMenuItem);
    } // IFC Routes contain two logical, but non-hierarchical routes and one path namespace.
    // top-level menus configured for ifcFoo won't match ifcFoo.internal
    // Utilizing the url of the ember route can support this without the need for explicit path mapping.
    // There are likely other cases where this path approach is helpful.


    var route = topLevelMenuItem.route;

    if (route) {
      var genUrlFn = router.urlFor;

      if (typeOf(genUrlFn) !== 'function') {
        genUrlFn = router.generate;
      }

      if (typeOf(genUrlFn) !== 'function') {
        throw new Error('URL generation function unavailable');
      }

      var genUrlArgs = [];
      var routeType = typeOf(route);

      if (routeType === 'string') {
        genUrlArgs.push(route);
      } else if (isObjectOrInstanceType(routeType)) {
        if (typeOf(route.name) !== 'string') {
          throw new Error('Route name unavailable');
        }

        genUrlArgs.push(route.name);

        if (route.segment) {
          genUrlArgs.push(route.segment);
        }
      } else {
        throw new Error('Unknown Menu Item Type');
      }

      return [genUrlFn.apply(router, genUrlArgs)];
    }
    /*
     * No need to check the url as a basePath here.
     * The url check in isLeafMenuItemActive will have already handled this for
     * menu items using url.
     */


    return [];
  }

  function formatRoutePath(baseRoutePath) {
    var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '#';

    if (baseRoutePath.slice(0, prefix.length) !== prefix) {
      return "".concat(prefix).concat(baseRoutePath);
    }

    return baseRoutePath;
  }

  function isObjectOrInstance(toCheck) {
    return isObjectOrInstanceType(typeOf(toCheck));
  }

  function isObjectOrInstanceType(type) {
    return ['object', 'instance'].includes(type);
  }

  function logError(msg) {
    for (var _len = arguments.length, logData = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      logData[_key - 1] = arguments[_key];
    }

    log.apply(void 0, ['error', msg].concat(logData));
  }

  function logWarn(msg) {
    for (var _len2 = arguments.length, logData = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
      logData[_key2 - 1] = arguments[_key2];
    }

    log.apply(void 0, ['warn', msg].concat(logData));
  }

  function log(level, msg) {
    var _Ember$Logger;

    for (var _len3 = arguments.length, logData = new Array(_len3 > 2 ? _len3 - 2 : 0), _key3 = 2; _key3 < _len3; _key3++) {
      logData[_key3 - 2] = arguments[_key3];
    }

    (_Ember$Logger = Ember.Logger)[level].apply(_Ember$Logger, ["Menu - Active Selection - ".concat(msg)].concat(logData));
  }

  function assertValidRouter(router) {
    if (!isObjectOrInstance(router)) {
      throw new Error('invalid router type');
    }

    if (typeOf(router.currentURL) !== 'string') {
      throw new Error('router.currentURL must be a string');
    }

    if (typeOf(router.isActive) !== 'function') {
      throw new Error('router.isActive must be a function');
    }

    if (typeOf(router.urlFor) !== 'function' && typeOf(router.generate) !== 'function') {
      throw new Error('router must container either a urlFor or generate function');
    }
  }
});