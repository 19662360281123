define("web-directory/components/target-dropdown/thumbnail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nBy7Ja+P",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"thumbnail-wrapper\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"thumbnail-image\"],[16,\"style\",[26,[\"thumbnailStyle\"]],null],[13],[14],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[6,[\"if\"],[[33,[\"not-eq\"],[[28,[\"iconName\"]],null],null]],null,{\"statements\":[[0,\"    \"],[9,\"gux-icon\",{\"attrs\":[[16,\"icon-name\",[26,[\"iconName\"]],null],[15,\"decorative\",\"true\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[6,[\"if\"],[[33,[\"not-eq\"],[[28,[\"text\"]],null],null]],null,{\"statements\":[[0,\"    \"],[11,\"p\",[]],[15,\"class\",\"thumbnail-text\"],[13],[1,[26,[\"text\"]],false],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]}],[0,\"\\n\"],[6,[\"if\"],[[28,[\"showIndicator\"]]],null,{\"statements\":[[0,\"    \"],[11,\"div\",[]],[15,\"class\",\"indicator\"],[13],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/target-dropdown/thumbnail/template.hbs"
    }
  });

  _exports.default = _default;
});