define("web-directory/routes/screen-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      run = Ember.run;

  var _default = Ember.Route.extend({
    chat: inject.service(),
    sidebar: inject.service(),
    model: function model(params) {
      return this.get('chat').getChatRoom(params.roomJid);
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      run.scheduleOnce('afterRender', this, function () {
        var _this = this;

        run.later(function () {
          model.set('isPopout', true);
          model.set('showingVideo', true);
          model.set('joinScreenShareImmediately', true);

          _this.set('chat.popoutRoom', model);
        }, 1000);
        this.get('sidebar').expandCommunicationSidebar();
      });
    }
  });

  _exports.default = _default;
});