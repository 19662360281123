define("web-directory/components/entity-field-section/entity-contact-info/contact-group-choices/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      Component = Ember.Component;

  var _default = Component.extend({
    classNames: ['contact-group-choices'],
    entity: null,
    field: null,
    entry: null,
    showDropdown: false,
    currentLabel: computed.reads('field.i18nLabel'),
    fieldChoices: computed('field', 'entry', function () {
      var keys = this.get('field.labelKeys');
      var entity = this.get('entity');
      var field = this.get('field');
      var path = field.get('fieldPath').replace(/\./gi, '_');
      return _lodash.default.map(keys, function (key) {
        return {
          field: field,
          keyPath: "".concat(entity.get('type'), "_").concat(path, "_labelKey_").concat(key)
        };
      });
    })
  });

  _exports.default = _default;
});