define("web-directory/components/follow-me-settings-panel/forwarding-helpers", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CallForwardingWrapper = void 0;
  _exports.searchForStation = searchForStation;
  var computed = Ember.computed,
      RSVP = Ember.RSVP;
  var PromiseArray = _emberData.default.PromiseArray;

  function searchForStation(store, value, session) {
    var promise = RSVP.resolve([]);

    if (value) {
      promise = store.query('station', {
        name: "*".concat(value, "*")
      }).then(function (results) {
        return results.filter(function (model) {
          if (model.get('isWebrtc')) {
            return model.get('webRtcUserId') === session.get('person.guid');
          }

          return true;
        });
      });
    }

    return PromiseArray.create({
      promise: promise
    });
  }

  var CallForwardingWrapper = Ember.Object.extend({
    new: false,
    saved: false,
    saving: false,
    savingVoicemail: false,
    isInternalDID: false,
    // set on api save error
    store: null,
    callRoute: null,
    // api data
    loadingStation: false,
    target: computed.alias('callRoute.targets.firstObject'),
    isStation: computed.equal('target.type', 'STATION'),
    isNumber: computed.equal('target.type', 'PHONENUMBER'),
    isRemoteStation: computed.reads('station.isRemoteStation'),
    value: computed.reads('target.value'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!this.get('callRoute')) {
        this.set('callRoute', {
          targets: [{}]
        });
      }

      var stationGuid = this.get('value');

      if (stationGuid && this.get('isStation')) {
        this.set('loadingStation', true);
        this.get('store').findRecord('station', stationGuid).then(function (station) {
          _this.set('station', station);
        }).catch(function (error) {
          Ember.Logger.error('Could not locate a station:', {
            error: error,
            guid: stationGuid
          });
        }).finally(function () {
          _this.set('loadingStation', false);
        });
      }
    },
    isExtension: computed('target.value', function () {
      var value = this.get('target.value');

      if (value) {
        return value.indexOf('ext=') !== -1;
      }

      return false;
    }),
    number: computed('callRoute', 'target.value', {
      get: function get() {
        if (!this.get('callRoute') || this.get('isStation')) {
          return null;
        }

        return this.get('target.value');
      },
      set: function set(key, value) {
        if (!value) {
          this.set('target', {});
          return value;
        }

        this.set('target.type', 'PHONENUMBER');
        this.set('target.value', value);
        return value;
      }
    }),
    _station: null,
    station: computed('callRoute', 'target.value', {
      get: function get() {
        if (!this.get('callRoute') || this.get('isNumber') || !this.get('target.value')) {
          return null;
        }

        return this.get('_station');
      },
      set: function set(key, value) {
        if (!value) {
          this.set('_station', null);
          this.set('target', {});
          return value;
        }

        this.set('target.type', 'STATION');
        this.set('target.value', value.get('id'));
        this.set('_station', value);
        return value;
      }
    }),
    toAPIJSON: function toAPIJSON() {
      var type = this.get('target.type');
      var value = this.get('target.value');
      return {
        targets: [{
          type: type,
          value: value
        }],
        timeoutMilliseconds: 12000
      };
    }
  });
  _exports.CallForwardingWrapper = CallForwardingWrapper;
});