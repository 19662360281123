define("web-directory/components/entity-fields/boolean-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hRarZVg1",
    "block": "{\"statements\":[[11,\"div\",[]],[16,\"class\",[34,[\"field-value values \",[28,[\"entry\",\"_id\"]]]]],[13],[0,\"\\n    \"],[1,[33,[\"field-label\"],null,[[\"for\",\"field\",\"entry\",\"entity\",\"editing\"],[[33,[\"concat\"],[[28,[\"elementId\"]],\"-entry-value\"],null],[28,[\"fieldData\",\"field\"]],[28,[\"entry\"]],[28,[\"entity\"]],[28,[\"editing\"]]]]],false],[0,\"\\n\\n\"],[6,[\"if\"],[[28,[\"canEdit\"]]],null,{\"statements\":[[0,\"        \"],[1,[33,[\"input\"],null,[[\"type\",\"id\",\"name\",\"checked\"],[\"checkbox\",[33,[\"concat\"],[[28,[\"elementId\"]],\"-entry-value\"],null],\"entry.value\",[28,[\"entry\",\"value\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"        \"],[11,\"span\",[]],[16,\"class\",[34,[\"label \",[33,[\"if\"],[[28,[\"entry\",\"value\"]],\"label-success\",\"label-danger\"],null]]]],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"entry\",\"value\"]]],null,{\"statements\":[[0,\"                \"],[11,\"i\",[]],[15,\"class\",\"pc pc-check-circle\"],[13],[14],[0,\"\\n                \"],[1,[33,[\"t\"],[\"entity.fieldTypes.boolean.checked-true\"],null],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"                \"],[1,[33,[\"t\"],[\"entity.fieldTypes.boolean.checked-false\"],null],false],[0,\"\\n\"]],\"locals\":[]}],[0,\"        \"],[14],[0,\"\\n\"]],\"locals\":[]}],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/entity-fields/boolean-field/template.hbs"
    }
  });

  _exports.default = _default;
});