define("web-directory/helpers/help-article-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    var type = params[0];

    switch (type) {
      case 'faq':
        return 'faq';

      case 'article':
        return 'article';

      case 'glossary':
        return 'glossary';

      case 'video':
        return 'video-camera';

      case 'post':
        return 'post';

      case 'releasenote':
        return 'releasenote';

      case 'page':
        return 'page';

      default:
        return '';
    }
  });

  _exports.default = _default;
});