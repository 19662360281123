define("web-directory/components/timeline-stream/room-message-renderer/component", ["exports", "web-directory/components/timeline-stream/renderer-component-base"], function (_exports, _rendererComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var RoomMessageRenderer = _rendererComponentBase.default.extend({
    classNameBindings: [':timeline-event', ':room-message-timeline-event', 'acknowledged'],
    intl: Ember.inject.service(),
    chat: Ember.inject.service(),
    sidebar: Ember.inject.service(),
    entity: null,
    roomBinding: 'entity.room',
    messagesBinding: 'entity.messages',
    useCollaborateChat: Ember.computed.readOnly('session.useCollaborateChat'),
    description: Ember.computed('messages.length', function () {
      var intl = this.get('intl');
      var count = this.get('messages.length');

      if (count === 1) {
        return intl.lookup('timeline.room.description-singular');
      } else {
        return intl.lookup('timeline.room.description-plural', {
          count: count
        });
      }
    }),
    roomSubject: Ember.computed('entity.messages', 'entity.room', 'useCollaborateChat', function () {
      var messages = this.get('entity.messages');
      var useCollabChat = this.get('useCollaborateChat');

      if (useCollabChat && messages && messages[0]) {
        return messages[0].roomName;
      }

      var roomSubj = this.get('entity.room.subject');
      return roomSubj;
    }),
    click: function click() {
      this._super();

      if (this.get('entity.eventKey') === 'supervisorassistance') {
        this.get('sidebar').openPanel('agentAssistance');
      } else {
        if (this.get('session.useCollaborateChat')) {
          var room = this.get('room');
          var jid = room.get('jid');
          this.get('chat').openRoom(jid);
        } else {
          this.get('chat').changeActiveRoom(this.get('room'));
        }

        this.get('sidebar').openPanel('conversations');
      }
    }
  });

  var _default = RoomMessageRenderer;
  _exports.default = _default;
});