define("web-directory/components/resource-center-search-v2/component", ["exports", "clipboard"], function (_exports, _clipboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      run = Ember.run,
      computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['resource-center-search-v2'],
    search: inject.service(),
    intl: inject.service(),
    logger: inject.service('stash-logger'),
    searchQuery: null,
    searchResults: null,
    shareBtn: null,
    embedResults: false,
    hasSearched: computed('searchResults', function () {
      return this.get('searchResults') !== null;
    }),
    embedConfig: computed('selectedHelpPage', function () {
      if (this.get('selectedHelpPage')) {
        return {
          id: 'help-panel-embed-feedback',
          url: "".concat(this.get('selectedHelpPage'), "?theme=simplified"),
          sandbox: ['allow-same-origin', 'allow-scripts', 'allow-popups'],
          permissionsPolicy: ['clipboard-write']
        };
      }

      return null;
    }),
    _searchHelp: function _searchHelp() {
      var _this = this;

      var query = this.get('searchQuery');

      if (!query) {
        return;
      }

      this.set('selectedHelpPage', null);
      var data = {
        pageNumber: 1,
        pageSize: 10,
        query: [{
          type: 'SIMPLE',
          value: query,
          fields: ['title', 'content']
        }],
        types: ['resource_articles', 'resource_pages', 'resource_faqs', 'resource_glossary', 'resource_partners', 'resource_releasenotes', 'resource_videos'],
        returnFields: ['ALL_FIELDS']
      };
      return this.get('search').searchDocumentation(data).then(function (data) {
        var results = data.results;

        var models = _this.get('search').modelResults(results);

        _this.set('searchResults', models);
      }).catch(function (err) {
        _this.set('searchResults', []);

        _this.get('logger').error('Help Search Error:', {
          error: err
        });
      });
    },
    _setUpShareBtn: function _setUpShareBtn() {
      var _this2 = this;

      var shareBtn;

      var getShareURL = function getShareURL() {
        return run(function () {
          if (_this2.get('selectedHelpPage')) {
            return _this2.get('selectedHelpPage');
          }

          return '';
        });
      };

      shareBtn = new _clipboard.default('.share-help', {
        text: getShareURL
      });
      shareBtn.on('success', function () {
        var message = _this2.get('intl').lookup('notification.help.copyUrlSimple');

        run(function () {
          return _this2.get('notification').info(null, message);
        });
      });
      this.set('shareBtn', shareBtn);
    },
    actions: {
      searchHelp: function searchHelp() {
        this.get('_timers').push(run.debounce(this, this._searchHelp, 500));
      },
      loadHelpResult: function loadHelpResult(model) {
        this.set('selectedHelpPage', Ember.get(model, 'link'));
      },
      backToResults: function backToResults() {
        this.set('selectedHelpPage', null);
      },
      clearInput: function clearInput() {
        this.set('searchQuery', null);
        this.set('searchResults', null);
      },
      contact: function contact() {
        this.attrs.contact();
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('_timers', []);

      if (this.get('embedResults')) {
        this._setUpShareBtn();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('_timers').forEach(function (item) {
        return run.cancel(item);
      });

      if (this.get('shareBtn')) {
        this.get('shareBtn').destroy();
      }
    }
  });

  _exports.default = _default;
});