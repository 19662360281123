define("web-directory/components/free-trial/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ovv9h/lz",
    "block": "{\"statements\":[[11,\"span\",[]],[15,\"class\",\"trail-days-remaining\"],[16,\"title\",[34,[[26,[\"freeTrialStatus\"]]]]],[13],[1,[26,[\"freeTrialStatus\"]],false],[14],[0,\"\\n\"],[9,\"gux-button\",{\"attrs\":[[15,\"accent\",\"primary\"],[15,\"class\",\"trial-support\"],[16,\"onclick\",[33,[\"action\"],[[28,[null]],\"openTrialSupport\"],null],null],[16,\"gux-title\",[34,[[33,[\"t\"],[\"freeTrial.support\"],null]]]]],\"args\":[[],[]],\"locals\":[],\"statements\":[[0,\"\\n    \"],[11,\"span\",[]],[15,\"class\",\"icon-span\"],[13],[0,\"\\n        \"],[9,\"gux-icon\",{\"attrs\":[[15,\"class\",\"button-icon\"],[15,\"icon-name\",\"customer-journey\"],[15,\"decorative\",\"\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n    \"],[14],[0,\"\\n    \"],[1,[33,[\"t\"],[\"freeTrial.support\"],null],false],[0,\"\\n\"]]}]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/free-trial/template.hbs"
    }
  });

  _exports.default = _default;
});