define("web-directory/components/person-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xo/980Du",
    "block": "{\"statements\":[[6,[\"entity-image\"],null,[[\"entity\",\"size\",\"useInitialsPlaceholder\",\"enableMiniCard\",\"isLinkable\",\"showPresence\",\"showFavorite\",\"subscribeToPresence\",\"canViewChat\",\"canViewVideo\",\"componentUpdated\",\"onContact\"],[[28,[\"person\"]],48,true,true,true,true,[28,[\"favorites\",\"enabled\"]],true,[28,[\"canViewChat\"]],[28,[\"canViewVideo\"]],[28,[\"componentUpdated\"]],[33,[\"action\"],[[28,[null]],\"noop\"],null]]],{\"statements\":[[0,\"    \"],[1,[33,[\"integration-badges\"],null,[[\"entity\"],[[28,[\"person\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/person-icon/template.hbs"
    }
  });

  _exports.default = _default;
});