define("web-directory/components/snail-progress/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "moyX2kMr",
    "block": "{\"statements\":[[11,\"span\",[]],[16,\"class\",[34,[\"wait-segment \",[33,[\"if\"],[[33,[\"eq\"],[[28,[\"activeSegment\"]],0],null],\"active\"],null]]]],[13],[14],[0,\"\\n\"],[11,\"span\",[]],[16,\"class\",[34,[\"wait-segment \",[33,[\"if\"],[[33,[\"eq\"],[[28,[\"activeSegment\"]],1],null],\"active\"],null]]]],[13],[14],[0,\"\\n\"],[11,\"span\",[]],[16,\"class\",[34,[\"wait-segment \",[33,[\"if\"],[[33,[\"eq\"],[[28,[\"activeSegment\"]],2],null],\"active\"],null]]]],[13],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/snail-progress/template.hbs"
    }
  });

  _exports.default = _default;
});