define("web-directory/routes/signup", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    intl: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      return this.get('intl').loadTranslations();
    },
    routeTitle: Ember.computed(function () {
      return this.get('intl').lookup('auth.signUp');
    }),
    setupController: function setupController(controller, model) {
      controller.set('token', model.token);
    }
  });

  _exports.default = _default;
});