define("web-directory/routes/feedback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      Route = Ember.Route;

  var _default = Route.extend({
    application: inject.service(),
    setupController: function setupController(c, m, transition) {
      this._super.apply(this, arguments);

      if (this.get('application.mobileQueryParameter') || Ember.get(transition, 'queryParams.mobile')) {
        this.set('application.context', 'adminui');
      }
    }
  });

  _exports.default = _default;
});