define("web-directory/components/skillgroup-profile/division-picker/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Component.extend({
    ajax: inject.service(),
    application: inject.service(),
    intl: inject.service(),
    logger: inject.service('stash-logger'),
    notification: inject.service(),
    panel: inject.service('side-panel'),
    permissions: inject.service(),
    searchQuery: '',
    skillgroup: null,
    currentDivisions: [],
    selectedDivisions: {},
    selectAll: false,
    currentPage: 1,
    pageSize: 25,
    totalDivisions: 0,
    divisions: [],
    confirmText: computed('intl', function () {
      return this.get('intl').t('common.confirm');
    }),
    cancelText: computed('intl', function () {
      return this.get('intl').t('common.cancel');
    }),
    searchPlaceholder: computed('intl', function () {
      return this.get('intl').t('groups.modal.search');
    }),
    canSelectAll: computed('permissions', function () {
      this.get('permissions').validateDonutPermission('routing:skillgroups:add:*');
    }),
    didInsertElement: function didInsertElement() {
      this.initListeners();
      this.initCurrentDivisions();
      this.getDivisions();
      this.set('selectAll', this.get('skillgroup.memberDivisions').mapBy('id').indexOf('*') >= 0);
      return this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this.$().off(".".concat(this.get('elementId')));
      return this._super.apply(this, arguments);
    },
    initListeners: function initListeners() {
      this.$().on('guxpaginationchange', this.handlePaginationChange.bind(this));
      this.$().on('guxselectionchanged', this.handleSelections.bind(this));
      this.$().on('input', this.handleSearch.bind(this));
      this.$().on('change', this.handleSelections.bind(this));
    },
    initCurrentDivisions: function initCurrentDivisions() {
      var skillgroup = this.get('params')[0];
      this.set('skillgroup', skillgroup);
      this.set('currentDivisions', skillgroup.get('memberDivisions').mapBy('id'));
    },
    getDivisions: function getDivisions() {
      var _this = this;

      Ember.run.throttle(this, function () {
        var data = {
          pageSize: _this.get('pageSize'),
          pageNumber: _this.get('currentPage'),
          permission: 'routing:skillgroup:add'
        };

        var request = _this.get('ajax').ajaxGet(_this.get('application').pcV2Uri('api/v2/authorization/divisionspermitted/paged/me'), {
          data: data
        });

        return request.then(function (results) {
          results.entities.forEach(function (div) {
            if (_this.get('currentDivisions').indexOf(div.id) >= 0) {
              div.added = true;

              if (div.id === _this.get('skillgroup.division.id')) {
                div.permanent = true;
              }
            } else {
              div.added = false;
            }
          });

          _this.set('divisions', results.entities);

          _this.set('totalDivisions', results.total || 0);
        }).catch(function (error) {
          _this.get('logger').error('Error fetching divisions in skillgroup division picker', {
            error: error
          });

          var title = _this.get('intl').t('skillgroups.divisionPicker.divisionFetchErrorTitle');

          var message = _this.get('intl').t('skillgroups.divisionPicker.divisionFetchErrorMessage');

          _this.get('notification').error(title, message);
        });
      }, 500);
    },
    handlePaginationChange: function handlePaginationChange(event) {
      this.setProperties({
        currentPage: event.detail.currentPage,
        pageSize: event.detail.itemsPerPage
      });
      this.getDivisions();
    },
    handleSelections: function handleSelections(event) {
      if (event.detail) {
        this.set('selectedDivisions', event.detail.selectedRowIds);
      } else if (event.target.name === 'select-all') {
        this.set('selectAll', event.target.checked);
      }
    },
    handleSearch: function handleSearch(event) {
      if (typeof event.detail === 'string') {
        this.set('searchQuery', event.detail);
        this.getDivisions();
      }
    },
    actions: {
      saveDivisions: function saveDivisions() {
        var _this2 = this;

        var addDivisionIds = [];
        var removeDivisionIds = [];

        if (this.get('selectAll') && !this.get('divisions').findBy('index', '*')) {
          addDivisionIds = ['*'];
        } else if (!this.get('selectAll') && this.get('divisions').findBy('index', '*')) {
          removeDivisionIds = ['*'];
        }

        this.get('divisions').forEach(function (div) {
          if (div.added && _this2.get('selectedDivisions').indexOf(div.id) < 0) {
            removeDivisionIds.push(div.id);
          } else if (!div.added && _this2.get('selectedDivisions').indexOf(div.id) >= 0) {
            addDivisionIds.push(div.id);
          }
        });
        var path = "api/v2/routing/skillgroups/".concat(this.get('skillgroup.id'), "/members/divisions");
        var url = this.get('application').pcV2Uri(path);
        var data = {
          addDivisionIds: addDivisionIds,
          removeDivisionIds: removeDivisionIds
        };
        this.get('ajax').post(url, {
          data: data
        }).then(function () {
          _this2.get('notification').info(_this2.get('intl').t('skillgroups.divisionPicker.saveTitle'), _this2.get('intl').t('skillgroups.divisionPicker.saveMessage'));

          _this2.get('skillgroup').reload();

          _this2.get('panel').resetSidePanel();
        }).catch(function (error) {
          _this2.get('logger').error('Error saving memberDivisions to skillgroup', {
            error: error
          });

          var title = _this2.get('intl').t('skillgroups.divisionPicker.saveErrorTitle');

          var message = _this2.get('intl').t('skillgroups.divisionPicker.saveErrorMessage');

          if (error.status === 400 && error.payload.message === 'group memberDivisions count exceeds the limit of 50') {
            message = _this2.get('intl').t('skillgroups.divisionPicker.tooManyDivisions');
          }

          _this2.get('notification').error(title, message);
        });
      },
      cancel: function cancel() {
        this.get('panel').resetSidePanel();
      }
    }
  });

  _exports.default = _default;
});