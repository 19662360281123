define("web-directory/components/inbox-panel-v2/delete-confirmation/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isGroupVoicemail: false,
    actions: {
      sendConfirm: function sendConfirm() {
        this.sendAction('confirm');
      },
      sendCancel: function sendCancel() {
        this.sendAction('cancel');
      }
    }
  });

  _exports.default = _default;
});