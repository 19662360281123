define("web-directory/components/out-of-office/component", ["exports", "ember-purecloud-components/utils/dates"], function (_exports, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var run = Ember.run,
      get = Ember.get,
      inject = Ember.inject,
      computed = Ember.computed,
      Component = Ember.Component;

  var _default = Component.extend({
    classNames: ['out-of-office-container'],
    intl: inject.service(),
    session: inject.service(),
    presenceSubscription: inject.service(),
    store: inject.service(),
    showOutOfOffice: false,
    endOfTime: (0, _dates.default)('9999-12-31T00:00:00.000Z'),
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
    indefinite: false,
    outOfOffice: computed('session.person', function () {
      return this.getOutOfOffice();
    }),
    isOutOfOffice: computed('outOfOffice', function () {
      var outOfOffice = this.get('outOfOffice');
      return get(outOfOffice, 'active');
    }),
    outofOfficeToggleFT: computed.readOnly('session.features.acdGUXToggleOutofOffice'),
    dateError: computed('startMoment', 'endMoment', 'indefinite', function () {
      var start = this.get('startMoment');
      var end = this.get('endMoment');

      if (this.get('indefinite')) {
        return false;
      } else if (!start || !end) {
        return true;
      } else if (start && end && !this.get('indefinite')) {
        return !(0, _dates.default)(start).isBefore(end);
      } else {
        return false;
      }
    }),
    placeholder: computed(function () {
      return this.get('intl').t('chat.presence.outOfOffice.placeholder');
    }),
    dateFormat: computed('intl.iosLocale', function () {
      var locale = this.get('intl.isoLocale');

      if (locale === 'no') {
        // moment uses nb as its Norwegian locale
        locale = 'nb';
      }

      return _dates.default.localeData(locale)._longDateFormat.L;
    }),
    datePlaceholder: computed('dateFormat', function () {
      return this.get('dateFormat').replace(/[A-Z]/gi, '-');
    }),
    startMoment: computed('startDate', 'startTime', function () {
      if (this.get('startDate') && this.get('startTime')) {
        var date = (0, _dates.default)(this.get('startDate')).format('YYYY-MM-DD');
        var time = this.get('startTime').format('HH:mmzz');
        return (0, _dates.default)("".concat(date, " ").concat(time));
      }

      return (0, _dates.default)();
    }),
    endMoment: computed('endDate', 'endTime', function () {
      if (this.get('endDate') && this.get('endTime')) {
        var date = (0, _dates.default)(this.get('endDate')).format('YYYY-MM-DD');
        var time = this.get('endTime').format('HH:mmzz');
        return (0, _dates.default)("".concat(date, " ").concat(time));
      }

      return (0, _dates.default)();
    }),
    options: computed('intl.locale', function () {
      var localeData = _dates.default.localeData();

      return {
        i18n: {
          previousMonth: this.get('intl').t('chat.presence.outOfOffice.picker.previousMonth').toString(),
          nextMonth: this.get('intl').t('chat.presence.outOfOffice.picker.nextMonth').toString(),
          months: localeData._months,
          weekdays: localeData._weekdays,
          weekdaysShort: localeData._weekdaysShort
        }
      };
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      run.scheduleOnce('afterRender', this, this.initializeValues);
      var outOfOffice = this.getOutOfOffice();
      this.set('isOutOfOffice', get(outOfOffice, 'active'));
    },
    getOutOfOffice: function getOutOfOffice() {
      var user = this.get('store').peekRecord('person', this.get('session.person.id'));

      if (!user) {
        return {};
      }

      var outOfOffice = user.get('activities.outOfOffice');
      return !!outOfOffice ? outOfOffice : {};
    },
    initializeValues: function initializeValues() {
      var outOfOffice = this.get('outOfOffice');
      var active = this.get('isOutOfOffice');
      var start = get(outOfOffice, 'startDate');
      var end = get(outOfOffice, 'endDate');

      if (start && end && !active) {
        var isInFuture = (0, _dates.default)(end).isAfter();

        if (isInFuture) {
          // set in the future
          this.set('showOutOfOffice', true);
        }
      } else {
        this.set('showOutOfOffice', active);
      }

      if (active || start && end) {
        var startMoment = (0, _dates.default)(start);
        var endMoment = (0, _dates.default)(end);
        var endOfTime = this.get('endOfTime');
        this.setProperties({
          startDate: startMoment.toDate(),
          startTime: startMoment,
          endDate: endMoment.toDate(),
          endTime: endMoment
        });

        if (endMoment.isSame(endOfTime) || endMoment.year() === 9999) {
          this.setProperties({
            endDate: null,
            endTime: null
          });
          this.set('indefinite', true);
        }
      } else {
        this.set('startDate', (0, _dates.default)().toDate());
        this.set('startTime', (0, _dates.default)());
        this.set('endDate', (0, _dates.default)().add(1, 'day').toDate());
        this.set('endTime', (0, _dates.default)().add(1, 'day'));
      }
    },
    actions: {
      selectStartDate: function selectStartDate(date) {
        this.set('startDate', date);
      },
      selectEndDate: function selectEndDate(date) {
        this.set('endDate', date);
      },
      confirmOutOfOffice: function confirmOutOfOffice() {
        if (!this.get('dateError')) {
          var start = this.get('startMoment').toISOString();
          var end = this.get('endMoment').toISOString();

          if (this.get('indefinite')) {
            end = this.get('endOfTime').toISOString();
          }

          this.get('presenceSubscription').setOutOfOffice(start, end, false);
          this.get('close')();
        }
      },
      toggleShowOutOfOffice: function toggleShowOutOfOffice(ev) {
        if (ev && ev.key && ev.key !== 'Enter' && ev.key !== ' ') {
          return;
        }

        this.toggleProperty('showOutOfOffice');
        var outOfOffice = this.get('outOfOffice');
        var start = get(outOfOffice, 'startDate');
        var end = get(outOfOffice, 'endDate');

        if (!this.get('showOutOfOffice')) {
          if (this.get('isOutOfOffice') || start && end) {
            this.get('presenceSubscription').setOutOfOffice(null, null, false);
          }
        }
      },
      toggleIndefinite: function toggleIndefinite(ev) {
        if (ev && ev.key && ev.key !== 'Enter' && ev.key !== ' ') {
          return;
        }

        this.toggleProperty('indefinite');

        if (this.get('indefinite')) {
          this.set('endDate', null);
          this.set('endTime', null);
        } else {
          this.set('endDate', (0, _dates.default)().add(1, 'day').toDate());
          this.set('endTime', (0, _dates.default)().add(1, 'day'));
        }
      }
    }
  });

  _exports.default = _default;
});