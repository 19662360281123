define("web-directory/services/apps-api-handlers/open-panel-handler", ["exports", "web-directory/models/internally-hosted-app"], function (_exports, _internallyHostedApp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.openPanelDemoAppURL = _exports.default = _exports.OPEN_PANEL_API_ACTION_NAME = void 0;
  var RSVP = Ember.RSVP,
      typeOf = Ember.typeOf;
  var OPEN_PANEL_API_ACTION_NAME = 'openInteractionPanel';
  _exports.OPEN_PANEL_API_ACTION_NAME = OPEN_PANEL_API_ACTION_NAME;
  var openPanelDemoAppURL = new URL('https://inin-index-files-dev.s3.amazonaws.com/demo-client-apps/internal/openInteractionPanelDemo.html');
  _exports.openPanelDemoAppURL = openPanelDemoAppURL;
  var OpenPanelHandler = Ember.Object.extend({
    /**
     * Synchronous check to see if this handler is applicable for the specified AppApiCall instance
     *
     * @param {Object} the AppApiCall instance to check if this handler can handle
     *
     * @returns true if the provided AppApiCall instance could be handled by this handler. This check should
     *  be limited to checking if the handler is responsible for this type of AppApiCall rather than if the
     *  payload itself is valid.  If no handler returns true, the error will be that no handler exists which is
     *  a different case than the AppApiCall is invalie. Practically, this means that validation of the payload
     *  should be done in the handle method
     */
    canHandle: function canHandle(appApiCall) {
      return appApiCall.get('action') === OPEN_PANEL_API_ACTION_NAME;
    },
    handle: function handle(appApiCall) {
      var _this = this;

      return new RSVP.Promise(function (resolve, reject) {
        var app = appApiCall.get('srcApp');

        if (!_this.isActiveInterapption(appApiCall.get('srcApp.id'))) {
          reject('This app is not the active interapption');
          return;
        }

        if (!(app instanceof _internallyHostedApp.default)) {
          if (!app.urlTemplate) {
            reject('This app is not internally hosted');
            return;
          }

          try {
            var appURL = new URL(app.urlTemplate);

            if (!(appURL.origin === openPanelDemoAppURL.origin && appURL.pathname === openPanelDemoAppURL.pathname)) {
              reject('This app is not internally hosted');
              return;
            }
          } catch (error) {
            {
              reject('This app is not internally hosted');
              return;
            }
          }
        }

        var panelId = appApiCall.get('actionDetails.panelId');

        if (panelId && typeOf(panelId) === 'string') {
          panelId = panelId.trim();

          if (panelId === '') {
            reject('Panel must be a non-empty string');
            return;
          }

          _this.openPanel(panelId);

          return resolve();
        } else {
          reject('Panel must be a non-empty string');
          return;
        }
      });
    }
  });
  var _default = OpenPanelHandler;
  _exports.default = _default;
});