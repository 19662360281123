define("web-directory/components/entity-field-section/entity-education/component", ["exports", "lodash", "web-directory/components/entity-field-section/component", "web-directory/components/entity-field-section/template"], function (_exports, _lodash, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _component.default.extend({
    layout: _template.default,
    disableAddValueDropdown: true,
    fallbackToEmptyLabel: true,
    actions: {
      toggleAddValue: function toggleAddValue() {
        this.send('addNewEntry', this.get('section.fieldList.0'));
      }
    },
    canAddNew: computed('fieldData.[]', function () {
      var fieldData = this.get('fieldData');
      var empties = fieldData.filterBy('isEmpty', true);
      return empties.length < 1;
    }),
    disableSave: computed('fieldData.@each.{isValid,isEmpty}', function () {
      var fieldData = this.get('fieldData');
      var validFields = fieldData.map(function (field) {
        return field.get('isValid') && !field.get('isEmpty');
      });
      return _lodash.default.reject(validFields).length > 0;
    })
  });

  _exports.default = _default;
});