define("web-directory/components/target-dropdown/filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R+8NqGFd",
    "block": "{\"statements\":[[11,\"button\",[]],[16,\"id\",[26,[\"tooltipId\"]],null],[16,\"class\",[34,[\"filter-button-\",[26,[\"value\"]]]]],[16,\"aria-label\",[26,[\"label\"]],null],[16,\"onclick\",[33,[\"action\"],[[28,[null]],\"onFilterChange\",[28,[\"value\"]]],null],null],[15,\"type\",\"button\"],[13],[0,\"\\n    \"],[9,\"gux-icon\",{\"attrs\":[[16,\"icon-name\",[34,[[26,[\"icon\"]]]]],[15,\"decorative\",\"true\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"record-count\"],[16,\"title\",[34,[[26,[\"totalElements\"]]]]],[13],[0,\"\\n\"],[6,[\"if\"],[[33,[\"eq\"],[[28,[\"totalElements\"]],[31]],null]],null,{\"statements\":[[0,\"            \"],[11,\"i\",[]],[15,\"class\",\"pc-moon pc-loading pc-spin\"],[13],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[6,[\"if\"],[[33,[\"gt\"],[[28,[\"totalElements\"]],[28,[\"maxCount\"]]],null]],null,{\"statements\":[[0,\"            \"],[11,\"p\",[]],[15,\"class\",\"max-count\"],[13],[0,\"\\n                \"],[1,[33,[\"t\"],[\"purecloud.acdInteraction.conferencing.maxFilterCount\"],[[\"count\"],[[28,[\"maxCount\"]]]]],false],[0,\"\\n            \"],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"            \"],[11,\"p\",[]],[15,\"class\",\"count\"],[13],[0,\"\\n                \"],[1,[26,[\"totalElements\"]],false],[0,\"\\n            \"],[14],[0,\"\\n        \"]],\"locals\":[]}]],\"locals\":[]}],[0,\"    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[6,[\"if\"],[[28,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[9,\"gux-tooltip\",{\"attrs\":[[16,\"for\",[26,[\"tooltipId\"]],null]],\"args\":[[],[]],\"locals\":[],\"statements\":[[0,\"\\n        \"],[1,[26,[\"label\"]],false],[0,\"\\n    \"]]}],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/target-dropdown/filter/template.hbs"
    }
  });

  _exports.default = _default;
});