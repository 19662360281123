define("web-directory/components/entity-status/component", ["exports", "lodash", "web-directory/utils/keystroke"], function (_exports, _lodash, _keystroke) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed,
      Component = Ember.Component;

  var isEnter = function isEnter(event) {
    return (0, _keystroke.eventToString)(event) === 'ENTER';
  };

  var MAX_STATUS_LENGTH = 50;

  var stripHtml = function stripHtml(str) {
    return str.replace(/(<([^>]+)>)/gi, '');
  };

  var _default = Component.extend({
    classNames: ['entity-status'],
    classNameBindings: ['editable'],
    layoutName: 'components/entity-status',
    intl: inject.service(),
    session: inject.service(),
    notification: inject.service(),
    streaming: inject.service(),
    entity: null,
    editable: false,
    onProfile: false,
    showPlaceholder: false,
    savedStatus: null,
    isLoading: false,
    maxLength: MAX_STATUS_LENGTH,
    debounceTime: 1000,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('savedStatus', this.get('status'));
    },
    showStatus: computed.or('entity.hasChatStatus', 'editable', 'showPlaceholder'),
    status: computed('entity.chatStatus', {
      get: function get() {
        return this.get('entity.chatStatus');
      },
      set: function set(key, value) {
        return value;
      }
    }),
    isInvalid: computed('status', function () {
      var status = this.get('status');
      return !_lodash.default.isEmpty(status) && status.length > MAX_STATUS_LENGTH;
    }),
    actions: {
      changeStatus: function changeStatus(newStatus, event) {
        if (isEnter(event)) {
          event.preventDefault();
          this.updateStatus();
        }
      },
      changeStatusEventually: function changeStatusEventually(newStatus, event) {
        if (!isEnter(event)) {
          Ember.run.debounce(this, this.updateStatus, this.debounceTime);
        }
      }
    },
    updateStatus: function updateStatus() {
      var _this = this;

      if (this.get('isInvalid')) {
        return;
      }

      var status = this.get('status');
      Ember.Logger.debug("STATUS-MSG: updateStatus(".concat(status, ")"));
      this.set('isLoading', true);
      this.get('streaming').setStatus(status, this.get('session.person')).then(function () {
        _this.set('savedStatus', status);
      }).catch(function (response) {
        var message = stripHtml(response.message || '');

        _this.showUpdateError(message);

        _this.set('status', _this.get('savedStatus'));
      }).finally(function () {
        _this.set('isLoading', false);
      });
    },
    showUpdateError: function showUpdateError(message) {
      var title = this.get('intl').t('chat.statusError');
      var options = {
        hideAfter: 5
      };
      this.get('notification').error(title, message, options);
    }
  });

  _exports.default = _default;
});