define("web-directory/components/acd-interactions-panel/interaction-sms/sms-message-textarea/mixins/sms-textarea-events", ["exports", "web-directory/utils/keystroke"], function (_exports, _keystroke) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.keypress = keypress;

  function keypress(event) {
    if ((0, _keystroke.eventToString)(event) === 'ENTER' && !event.shiftKey) {
      event.preventDefault();
      var message = this.get('messageInput'); //note: Here we can scrub the input if needed

      this.propagateMessageEvent((message || '').trim());
    }
  }
});