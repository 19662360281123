define("web-directory/utils/focus-management", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.doFocus = doFocus;
  _exports.requestFocus = requestFocus;

  function _isSafeToFocus(selector) {
    var currentSelectedElement = Ember.$(window.document.activeElement);

    switch (selector) {
      case '#roster-action-answer-button button':
        // interaction roster
        if (currentSelectedElement) {
          return currentSelectedElement.parent().hasClass('toggle-switch-container');
        }

        return false;

      case '.incoming-controls button.answer-call':
        // active-call-entry
        if (currentSelectedElement) {
          return currentSelectedElement.parent().hasClass('toggle-switch-container');
        }

        return false;

      case '.answer-call-action-bar button.answer-call':
        // call toast
        if (currentSelectedElement) {
          return currentSelectedElement.parent().hasClass('toggle-switch-container');
        }

        return false;

      default:
        return false;
    }
  }

  function doFocus(component, selector) {
    Ember.run.scheduleOnce('afterRender', component, function () {
      var target = component.$(selector);

      if (target) {
        target.focus();
      }
    });
  }

  function requestFocus() {
    var scope = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Ember;
    var selector = arguments.length > 1 ? arguments[1] : undefined;
    var isSafe;

    try {
      isSafe = _isSafeToFocus(selector);
    } catch (e) {
      isSafe = false;
    }

    if (isSafe) {
      Ember.run.next(function () {
        doFocus(scope, selector);
      });
    }
  }
});