define("web-directory/components/entity-fields/email-field/component", ["exports", "lodash", "web-directory/mixins/field-component"], function (_exports, _lodash, _fieldComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Component.extend(_fieldComponent.default, {
    classNameBindings: ['isValid::invalid-value'],
    intl: inject.service(),
    permissions: inject.service(),
    currentTooltip: null,
    isActive: false,
    hasError: computed.not('isValid'),
    actions: {
      setPrimary: function setPrimary() {
        this.set('isPrimary', true);
      },
      focus: function focus() {
        this.set('isActive', true);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!this.get('editing') || this.isDestroyed) {
        return;
      }

      var entry = this.get('entry');
      var empty;

      if (!entry) {
        empty = true;
      } else {
        empty = _lodash.default.isEmpty(entry.value);
      }

      if (empty) {
        var timer = Ember.run.scheduleOnce('afterRender', this, this.setValues);
        this.timers.push(timer);
      }
    },
    isPrimary: computed('entry._id', 'entity.primaryContactInfo.email.firstObject.value.fieldId', 'isEmpty', {
      get: function get() {
        var entryId = this.get('entry._id');
        var fieldId = this.get('entity.primaryContactInfo.email.firstObject.value.fieldId');
        return fieldId && fieldId === entryId && !this.get('isEmpty');
      },
      set: function set(key, value) {
        var entity = this.get('entity');
        var type = this.get('fieldData.field.type');
        entity.setPrimaryContact(type, this.get('entry'), this.get('fieldData.field.fieldPath'), this.get('fieldData'));
        return value;
      }
    }),
    errorMessage: computed('isValid', 'entry.value', 'entity.type', function () {
      var intl = this.get('intl');
      var valid = this.get('isValid');

      var empty = _lodash.default.isEmpty(this.get('entry.value'));

      var isGroup = this.get('entity.type') === 'group';

      if (empty && !isGroup) {
        return intl.lookup('tooltip.fields.email.blankError').toString();
      }

      if (!valid && !empty) {
        return intl.lookup('tooltip.fields.email.invalidError').toString();
      }
    }),
    canSetPrimary: computed.reads('permissions.canEditProfiles'),
    focusOut: function focusOut() {
      this.set('isActive', false);
    },
    setValues: function setValues() {
      var entity = this.get('entity');
      var field = this.get('field');
      var path = field.getFieldPath();
      var entry = this.get('entry');

      if (!_lodash.default.isEmpty(entry)) {
        Ember.set(entry, 'value', '');

        if (!field.get('repeatable')) {
          entity.set(path, [entry], {
            overwrite: true
          });
        }
      } else {
        entity.set(path, '');
      }

      if (!field.get('repeatable')) {
        var value = entity.getFieldValue(path);

        if (Array.isArray(value)) {
          value = value[0];
        }

        this.set('entry', value);
      }
    }
  });

  _exports.default = _default;
});