define("web-directory/components/group-profile/roles-edit/fixtable/division-select/select-footer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    allDivisionsSelected: Ember.computed('select.selected', function () {
      var optionsWithoutSpecial = this.get('select.options').filter(function (division) {
        return division.id !== '*';
      });
      return this.get('select.selected.length') === optionsWithoutSpecial.length;
    }),
    allFutureDivisions: undefined,
    _allFutureDivisionsObserver: function _allFutureDivisionsObserver() {
      var allFutureDivisions = this.get('allFutureDivisions');

      if (allFutureDivisions) {
        var powerSelectAPI = this.get('select');
        var rolesEditComponent = this.get('extra.rolesEditComponent');

        rolesEditComponent._updateDivisions([{
          id: '*'
        }], powerSelectAPI.selected);
      } else {
        this._selectAll();
      }
    },
    actions: {
      selectAll: function selectAll() {
        this._selectAll();
      },
      deselectAll: function deselectAll() {
        if (this.get('allFutureDivisions')) {
          this.removeObserver('allFutureDivisions', this, '_allFutureDivisionsObserver');
          this.set('allFutureDivisions', false);
          this.addObserver('allFutureDivisions', this, '_allFutureDivisionsObserver');
        }

        var powerSelectAPI = this.get('select');
        var rolesEditComponent = this.get('extra.rolesEditComponent');

        rolesEditComponent._updateDivisions([], powerSelectAPI.selected);
      }
    },
    _selectAll: function _selectAll() {
      if (this.get('allFutureDivisions')) {
        return;
      }

      var powerSelectAPI = this.get('select');
      var rolesEditComponent = this.get('extra.rolesEditComponent');
      var optionsWithoutSpecial = powerSelectAPI.options.filter(function (division) {
        return division.id !== '*';
      });

      rolesEditComponent._updateDivisions(optionsWithoutSpecial, powerSelectAPI.selected);
    },
    init: function init() {
      var powerSelectAPI = this.get('select');
      var allFutureDivisions = powerSelectAPI.selected.some(function (division) {
        return division.id === '*';
      });
      this.set('allFutureDivisions', allFutureDivisions);
      this.addObserver('allFutureDivisions', this, '_allFutureDivisionsObserver');
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});