define("web-directory/adapters/recorded-call", ["exports", "web-directory/adapters/purecloud"], function (_exports, _purecloud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _purecloud.default.extend({
    pathForType: function pathForType() {
      return 'v2/userrecordings';
    },
    findAll: function findAll(store, type) {
      return this.ajax(this.buildURL(type.modelName) + '?expand=conversation', 'GET');
    }
  });

  _exports.default = _default;
});