define("web-directory/models/station", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var StationModel = _emberData.default.Model.extend({
    intl: Ember.inject.service(),
    userId: _emberData.default.attr(),
    stationId: _emberData.default.attr(),
    name: _emberData.default.attr(),
    status: _emberData.default.attr(),
    type: _emberData.default.attr(),
    webrtcMediaDscp: _emberData.default.attr(),
    webrtcForceTurn: _emberData.default.attr(),
    persistentConnection: _emberData.default.attr(),
    webRtcUserId: _emberData.default.attr(),
    webRtcCallAppearances: _emberData.default.attr(),
    webRtcRequireMediaHelper: _emberData.default.attr(),
    state: computed('status', function () {
      return this.get('intl').lookup("purecloud.station.status.".concat(this.get('status').toLowerCase()));
    }),
    available: computed('status', function () {
      return this.get('status').toLowerCase() === 'available';
    }),
    isWebrtc: computed('type', 'name', function () {
      return this.get('type') === 'inin_webrtc_softphone';
    }),
    isRemoteStation: computed.equal('type', 'inin_remote')
  });

  StationModel.reopenClass({
    apiEndpoint: 'v2/stations'
  });
  var _default = StationModel;
  _exports.default = _default;
});