define("web-directory/components/entity-fields/validators/multiline-text", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    isValid: function isValid(text, field) {
      if (!text) {
        return false;
      }

      var maxLength = field.get('params.maxLength');

      if (maxLength && text.value) {
        return text.value.length <= maxLength;
      }

      if (field.get('required')) {
        return !_lodash.default.isEmpty(text);
      }

      return text !== undefined && text !== null;
    },
    isEmpty: function isEmpty(text) {
      if (!text) {
        return true;
      }

      var value = text.value;
      return _lodash.default.isEmpty(value);
    }
  };
  _exports.default = _default;
});