define("web-directory/helpers/show-emoji", ["exports", "ember-purecloud-components/utils/emoji"], function (_exports, _emoji) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    var emoji = params[0];
    var isUnicode = params.length > 1 ? params[1] : false;
    var isUnicodeLookAhead = params[1] === 'isUnicodeLookAhead' ? true : false;

    if (isUnicodeLookAhead) {
      return (0, _emoji.emojifyStringUnicode)(":".concat(emoji, ":"));
    }

    return /:\w+:/.test(emoji) || isUnicode ? emoji : ":".concat(emoji, ":");
  });

  _exports.default = _default;
});