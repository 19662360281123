define("web-directory/components/audio-player/component", ["exports", "lodash", "howler-but-with-updates"], function (_exports, _lodash, _howlerButWithUpdates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Howl = _howlerButWithUpdates.default.Howl;
  var get = Ember.get,
      inject = Ember.inject,
      computed = Ember.computed,
      observer = Ember.observer,
      RSVP = Ember.RSVP,
      Component = Ember.Component;

  var _default = Component.extend({
    classNames: ['audio-player-v2'],
    clock: inject.service(),
    logger: inject.service('stash-logger'),
    webrtc: inject.service(),
    phone: inject.service(),
    attrs: {
      source: null,
      options: {},
      markAsRead: function markAsRead() {},
      onError: function onError() {}
    },
    playing: false,
    didEnd: false,
    loading: false,
    loadingError: false,
    registerStopVoicemailHandler: null,
    sound: null,
    playFromSelectedOutput: computed.reads('webrtc.session.features.playRecordingFromSelectedOutput'),
    playbackVolume: computed('phone.callVolume', function () {
      var callVolume = this.get('phone.callVolume');
      var decimalValue = callVolume / 100;
      return decimalValue;
    }),
    selectedOutputDevice: computed('webrtc.defaultOutputDevice', function () {
      var deviceId;
      var defaultOutputDevice = this.get('webrtc.defaultOutputDevice');

      if (defaultOutputDevice) {
        deviceId = defaultOutputDevice.get('deviceId');
      }

      return deviceId;
    }),
    outputDeviceUpdate: observer('webrtc.defaultOutputDevice', function () {
      if (!this.get('sound') || !this.get('playFromSelectedOutput')) {
        return;
      }

      var restartSound = false;

      if (this.get('playing')) {
        this.stopPlaying();
        restartSound = true;
      }

      var newOutputDeviceId = this.get('selectedOutputDevice');
      this.sound.setOutputDevice(newOutputDeviceId);

      if (restartSound) {
        this.sound.play();
      }
    }),
    actions: {
      togglePlay: function togglePlay() {
        if (this.get('playFromSelectedOutput')) {
          var outputDeviceId = this.get('webrtc.defaultOutputDevice.deviceId');

          if (outputDeviceId) {
            this.sound.setOutputDevice(outputDeviceId);
          }

          this.sound.volume(this.get('playbackVolume'));
        }

        if (this.get('playing')) {
          this.sound.pause();
        } else {
          if (this.get('didEnd')) {
            this.sound.seek(0);
          }

          this.sound.play();
        }
      },
      seekSound: function seekSound(value) {
        this.sound.seek(value);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var registerHandler = this.get('registerStopVoicemailHandler');

      if (registerHandler && typeof registerHandler === 'function') {
        registerHandler(this.stopPlaying.bind(this));
      }

      this.setupSound();
    },
    willDestroyElement: function willDestroyElement() {
      if (this.sound) {
        this.sound.off();
        this.sound.pause();
      }

      this._super.apply(this, arguments);
    },
    setupSound: function setupSound() {
      var _this = this;

      this.set('loading', true);
      var paths = this.getAttr('source');
      var promise = RSVP.resolve(paths);
      promise.then(function (sources) {
        if (sources && !Array.isArray(sources)) {
          return [sources];
        }

        return null;
      }).then(function (sources) {
        var useHtml5 = _this.get('playFromSelectedOutput');

        if (sources) {
          var options = _this.get('howlOptions');

          options.src = sources;
          options.html5 = useHtml5;
          _this.sound = new Howl(options);
        } else {
          _this.set('loading', false);

          _this.set('loadingError', true);

          _this.get('logger').warn('There were no audio sources passed into the audio-player');

          _this.attrs.onError();
        }
      }).catch(function (err) {
        _this.set('loading', false);

        _this.set('loadingError', true);

        _this.get('logger').error('There was an error loading recorded audio', err);

        _this.attrs.onError(err);
      });
    },
    duration: computed('loading', function () {
      if (!this.sound) {
        return 0;
      }

      return Math.floor(this.sound.duration() * 1000);
    }),
    currentTime: computed('clock.second', function () {
      if (!this.sound) {
        return 0;
      }

      return Math.floor(this.sound.seek() * 1000);
    }),
    loadingAudio: computed(function () {
      return false;
    }),
    howlOptions: computed('options', function () {
      var defaultOptions = {
        onload: this.onLoad.bind(this),
        onplay: this.onPlay.bind(this),
        onpause: this.onPause.bind(this),
        onend: this.onEnd.bind(this)
      };
      return _lodash.default.merge({}, defaultOptions, this.get('options'));
    }),
    onLoad: function onLoad() {
      this.set('loading', false);

      this._checkSoundDuration();
    },
    onPlay: function onPlay() {
      this.set('playing', true);
    },
    onPause: function onPause() {
      this.set('playing', false);
    },
    onEnd: function onEnd() {
      this.set('playing', false);
      this.set('didEnd', true);

      if (this.attrs.markAsRead) {
        this.attrs.markAsRead();
      }
    },
    stopPlaying: function stopPlaying() {
      this.sound && this.sound.pause();
    },
    _checkSoundDuration: function _checkSoundDuration() {
      var _this2 = this;

      var sound = this.sound;

      if (!sound) {
        return;
      }

      var audio = get(sound, '_sounds.0._node');

      if (audio && audio.duration === Infinity) {
        // We are dealing with a recording here
        // See: http://stackoverflow.com/a/39971175
        audio.currentTime = 1e101;

        audio.ontimeupdate = function () {
          audio.ontimeupdate = function () {};

          audio.currentTime = 0;
          sound._duration = audio.duration;

          _this2.notifyPropertyChange('duration');
        };
      }
    }
  });

  _exports.default = _default;
});