define("web-directory/components/group-profile/group-list/component", ["exports", "ember-data", "npm:fuse.js", "web-directory/components/group-profile/group-contact-panel/component"], function (_exports, _emberData, _npmFuse, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var fuseOptions = {
    shouldSort: false,
    threshold: 0.4,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['person.name'],
    getFn: function getFn(obj, path) {
      return obj.get(path);
    }
  };

  var _default = Ember.Component.extend({
    classNames: ['group-list'],
    groupList: null,
    selectedIndex: null,
    searchQuery: '',
    editing: false,
    displayIndices: true,
    listType: null,
    queryAvailable: Ember.computed.gt('searchQuery.length', 0),
    searchResults: Ember.computed('groupList', 'searchQuery', function () {
      var _this = this;

      if (this.get('queryAvailable')) {
        return _emberData.default.PromiseArray.create({
          promise: this.get('groupList').then(function (data) {
            return new _npmFuse.default(data, fuseOptions).search(_this.get('searchQuery'));
          })
        });
      }

      return _emberData.default.PromiseArray.create({
        promise: Ember.RSVP.resolve(Ember.A([]))
      });
    }),
    renderedList: Ember.computed('searchResults.[]', 'groupList.[]', 'queryAvailable', function () {
      if (this.get('queryAvailable')) {
        return this.get('searchResults');
      }

      return this.get('groupList');
    }),
    canRemove: Ember.computed('renderedList.[]', 'listType', function () {
      if (this.get('listType') !== _component.LIST_TYPES.OWNERS) {
        return true;
      }

      return this.get('renderedList.length') > 1;
    }),
    actions: {
      removeItem: function removeItem(index) {
        if (!this.get('canRemove')) {
          return;
        }

        this.get('groupList').removeAt(index - 1);

        this._recalculateIndices();

        this.notifyParentOfListChange();
      },
      changePosition: function changePosition(from, to) {
        Ember.run.debounce(this, this.changePosition, from, to, 100);
      }
    },
    notifyParentOfListChange: function notifyParentOfListChange() {
      if (this.attrs.listModified) {
        this.attrs.listModified();
      }
    },
    changePosition: function changePosition(from, to) {
      this._changePosition(from, to);

      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      this.set('selectedIndex', null);
      this.notifyParentOfListChange();
    },
    _recalculateIndices: function _recalculateIndices() {
      this.get('groupList').map(function (listModel, index) {
        listModel.set('index', ++index);
      });
    },
    _changePosition: function _changePosition(from, to) {
      var _this2 = this;

      if (!Number.isInteger(to)) {
        return;
      }

      var groupList = this.get('groupList');
      to = Math.min(to, groupList.get('length'));
      to = Math.max(to, 1);
      groupList.then(function (groupArray) {
        var item = groupArray.get(from - 1);
        groupArray.removeAt(from - 1);
        groupArray.insertAt(to - 1, item);

        _this2._recalculateIndices();
      });
    }
  });

  _exports.default = _default;
});