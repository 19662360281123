define("web-directory/components/group-contact-modal/inline-number-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dfLgw2Hk",
    "block": "{\"statements\":[[1,[33,[\"t\"],[[28,[\"key\"]]],null],true]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/group-contact-modal/inline-number-input/template.hbs"
    }
  });

  _exports.default = _default;
});