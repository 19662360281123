define("web-directory/components/acd-interactions-panel/interaction-sms/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IyYSJhM6",
    "block": "{\"statements\":[[1,[33,[\"acd-interactions-panel/interaction-sms/sms-conversation-pane\"],null,[[\"acdNormalizedMessages\",\"interaction\",\"messages\",\"acdMessagingPerformance\",\"loadingMessages\",\"actionDisplayError\",\"customerParticipantName\"],[[28,[\"acdNormalizedMessages\"]],[28,[\"interaction\"]],[28,[\"messages\"]],[28,[\"session\",\"features\",\"acdMessagingPerformance\"]],[28,[\"loadingMessages\"]],[28,[\"actionDisplayError\"]],[28,[\"customerParticipantName\"]]]]],false],[0,\"\\n\\n\"],[6,[\"if\"],[[28,[\"interaction\",\"isConnected\"]]],null,{\"statements\":[[0,\"    \"],[1,[33,[\"acd-interactions-panel/interaction-sms/sms-message-textarea\"],null,[[\"acdNormalizedMessages\",\"interaction\",\"sendingAttachmentMessage\",\"messageInputReplacement\",\"requiresStructuredMessage\",\"structuredMessage\",\"allowedOutboundMedia\",\"updateUserTyping\",\"customerParticipantName\"],[[28,[\"acdNormalizedMessages\"]],[28,[\"interaction\"]],[28,[\"sendingAttachmentMessage\"]],[28,[\"smsTextareaInputReplacement\"]],[28,[\"requiresStructuredMessage\"]],[28,[\"structuredMessage\"]],[28,[\"interaction\",\"allowedOutboundMedia\"]],[28,[\"updateUserTyping\"]],[28,[\"customerParticipantName\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/acd-interactions-panel/interaction-sms/template.hbs"
    }
  });

  _exports.default = _default;
});