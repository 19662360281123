define("web-directory/components/acd-interactions-panel/interaction-queue-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vmtmrDH+",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"interaction-queue-status-container\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"interactions-container\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"interactions-image\"],[13],[0,\"\\n            \"],[11,\"span\",[]],[15,\"class\",\"queue-message\"],[13],[1,[33,[\"t\"],[[28,[\"messageKey\"]]],null],false],[14],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/acd-interactions-panel/interaction-queue-status/template.hbs"
    }
  });

  _exports.default = _default;
});