define("web-directory/components/target-dropdown/data/index", ["exports", "web-directory/components/target-dropdown/data/filter-data.const", "web-directory/components/target-dropdown/data/filter-store-config.const", "web-directory/components/target-dropdown/data/conversation-summary-config.const"], function (_exports, _filterData, _filterStoreConfig, _conversationSummaryConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(_filterData).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _filterData[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _filterData[key];
      }
    });
  });
  Object.keys(_filterStoreConfig).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _filterStoreConfig[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _filterStoreConfig[key];
      }
    });
  });
  Object.keys(_conversationSummaryConfig).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _conversationSummaryConfig[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _conversationSummaryConfig[key];
      }
    });
  });
});