define("web-directory/components/entity-fields/validators/weblink", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function isValidUrl(url) {
    var reg = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/;
    return reg.test(url);
  }

  var _default = {
    isValid: function isValid(url) {
      if (url && Object.prototype.hasOwnProperty.call(url, 'value')) {
        url = url.value;
      }

      return _lodash.default.isEmpty(url) || isValidUrl(url);
    },
    isEmpty: function isEmpty(url) {
      var value = url.value;
      return value === undefined || value === null || _lodash.default.isEmpty(value);
    }
  };
  _exports.default = _default;
});