define("web-directory/models/external-contact", ["exports", "ember-data", "web-directory/mixins/entity"], function (_exports, _emberData, _entity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _emberData.default.Model.extend(_entity.default, {
    title: computed('general.title.@each.value', function () {
      var field = this.getFieldValue('general.title');
      return field && field.value;
    }),
    department: computed('general.department.@each.value', function () {
      var field = this.getFieldValue('general.department');
      return field && field.value;
    }),
    organization: computed('general.organization.@each.value', function () {
      var field = this.getFieldValue('general.organization');
      return field && field.value;
    }),
    description: computed('general.notes.@each.value', function () {
      var field = this.getFieldValue('general.notes')[0];
      return field && field.value;
    }),
    phone: computed('contactInfo.phone.@each.value', function () {
      var field = this.getFieldValue('contactInfo.phone');
      return field && field[0];
    }),
    email: computed('contactInfo.email.@each.value', function () {
      var field = this.getFieldValue('contactInfo.email');
      return field && field.value;
    })
  });

  _exports.default = _default;
});