define("web-directory/components/chat-settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tBpI19Ly",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"settings-drop-down\"],[13],[0,\"\\n    \"],[11,\"span\",[]],[15,\"class\",\"notifications-description\"],[13],[0,\"\\n        \"],[1,[33,[\"t\"],[\"chat.notificationSettings\"],null],false],[0,\"\\n    \"],[14],[0,\"\\n    \"],[1,[33,[\"toggle-switch\"],null,[[\"check\",\"toggleRoomMute\"],[[28,[\"check\"]],\"toggleRoomMute\"]]],false],[0,\"\\n    \"],[11,\"small\",[]],[15,\"class\",\"text-muted\"],[13],[1,[33,[\"t\"],[\"chat.notificationSettingsHelpText\"],null],false],[14],[0,\"\\n    \"],[11,\"small\",[]],[15,\"class\",\"text-muted mention-notifications\"],[13],[1,[33,[\"t\"],[\"chat.notificationSettingsHelpTextMentions\"],null],false],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/chat-settings/template.hbs"
    }
  });

  _exports.default = _default;
});