define("web-directory/components/inbox-panel-v2/share-content/get-shareable-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wPE+yuEY",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"get-sharing-link-area\"],[13],[0,\"\\n    \"],[11,\"span\",[]],[15,\"class\",\"get-sharing-link btn-link is-ready\"],[13],[0,\"\\n        \"],[11,\"i\",[]],[15,\"class\",\"pc pc-share-link sharing-symbol\"],[13],[14],[0,\"\\n        \"],[11,\"span\",[]],[15,\"class\",\"copy-text\"],[13],[1,[33,[\"t\"],[\"components.inbox.labels.shareModal.shareableLink\"],null],false],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/inbox-panel-v2/share-content/get-shareable-link/template.hbs"
    }
  });

  _exports.default = _default;
});