define("web-directory/initializers/device-profiles-v2-service", ["exports", "web-directory/services/device-profiles-v2"], function (_exports, _deviceProfilesV) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'setupDeviceProfilesService',
    after: 'setupWebrtcSdkService',
    initialize: function initialize(application) {
      application.register('service:device-profiles-v2', _deviceProfilesV.default);
      application.inject('controller', 'device-profiles-v2', 'service:device-profiles-v2');
      application.inject('route', 'device-profiles-v2', 'service:device-profiles-v2');
      application.inject('component', 'device-profiles-v2', 'service:device-profiles-v2');
    }
  };
  _exports.default = _default;
});