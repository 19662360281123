define("web-directory/components/time-picker/component", ["exports", "web-directory/utils/dates"], function (_exports, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['time-picker'],
    label: null,
    srOnly: false,
    intl: Ember.inject.service(),
    value: null,
    disabled: null,
    showIcon: false,
    onSelection: null,
    onOpen: null,
    onClose: null,
    // Used instead of new/old attrs in the update function since those are going away
    _cachedValue: null,
    inputId: Ember.computed(function () {
      return "".concat(this.get('elementId'), "-time-picker");
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!this.get('value')) {
        this.set('value', (0, _dates.default)());
      }

      this._cachedValue = this.get('value');
      Ember.run.scheduleOnce('afterRender', this, this._setUpTimepicker);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      if (this._cachedValue === this.get('value')) {
        return;
      }

      var $timepicker = this.$('.timepicker').data('DateTimePicker');

      if ($timepicker) {
        $timepicker.date(this.get('value'));
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var $timepicker = this.$('.timepicker').data('DateTimePicker');

      if ($timepicker) {
        $timepicker.destroy();
      }
    },
    _setUpTimepicker: function _setUpTimepicker() {
      var locale = this.get('intl.isoLocale');

      if (locale === 'no') {
        // moment uses nb as its Norwegian locale
        locale = 'nb';
      }

      this.$('.timepicker').datetimepicker({
        locale: locale,
        format: 'LT',
        defaultDate: this.get('value')
      }).on('dp.change', Ember.run.bind(this, this._selectionEvent)).on('dp.hide', Ember.run.bind(this, this._closeEvent)).on('dp.show', Ember.run.bind(this, this._openEvent));
    },
    _selectionEvent: function _selectionEvent(e) {
      Ember.run.debounce(this, this._updateValue, e, 200);
    },
    _updateValue: function _updateValue(e) {
      var value = e.date;

      if (value === this._cachedValue) {
        return;
      } else if (value) {
        if (!this.get('isDestroyed') && !this.get('isDestroying')) {
          this._cachedValue = value;
          this.set('value', value);

          if (this.get('onSelection')) {
            this.get('onSelection')(this.get('value'));
          }
        }
      } else if (this._cachedValue) {
        var $timepicker = this.$('.timepicker').data('DateTimePicker');

        if ($timepicker) {
          $timepicker.date(this._cachedValue);
        }
      }
    },
    _openEvent: function _openEvent() {
      if (this.get('onOpen')) {
        this.get('onOpen').apply(void 0, arguments);
      }
    },
    _closeEvent: function _closeEvent() {
      if (this.get('onClose')) {
        this.get('onClose').apply(void 0, arguments);
      }
    }
  });

  _exports.default = _default;
});