define("web-directory/initializers/authentication-setup", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin", "web-directory/mixins/authenticated-authorized-route-mixin"], function (_exports, _unauthenticatedRouteMixin, _authenticatedRouteMixin, _authenticatedAuthorizedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'authenticationSetup',
    after: 'orgspan',
    before: 'authenticators',
    initialize: function initialize() {
      /*
       * Protecting ALL routes with ember-simple-auth by default
       *
       * Use unprotected or unauthenticated route mixins to relax
       * permissions on your routes
       */
      Ember.Route.reopen(_authenticatedAuthorizedRouteMixin.default);

      _unauthenticatedRouteMixin.default.reopen({
        beforeModel: function beforeModel() {
          if (this.get('session.isAuthenticated')) {
            return this._super.apply(this, arguments);
          }
          /*
          Intentionally not calling super or doing anthing.  This should
          be the termination of the route hierarchy.  If not, you
          run into the default Ember route which we just set as an
          authenticated-authorized route.
          */

        }
      });

      _authenticatedRouteMixin.default.reopen({
        beforeModel: function beforeModel() {
          if (!this.get('session.isAuthenticated')) {
            return this._super.apply(this, arguments);
          }
          /*
          Intentionally not calling super or doing anthing.  This should
          be the termination of the route hierarchy.  If not, you
          run into the default Ember route which we just set as an
          authenticated-authorized route.
          */

        }
      });
    }
  };
  _exports.default = _default;
});