define("web-directory/components/inbox-panel-v2/share-content/component", ["exports", "lodash", "ember-purecloud-components/mixins/logger"], function (_exports, _lodash, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed,
      Component = Ember.Component;
  var entityType = {
    GROUP: 'GROUP',
    USER: 'USER'
  };
  var shareType = {
    SHARED: 'shared',
    PUBLIC: 'public',
    PRIVATE: 'private'
  };

  var _default = Component.extend(_logger.default, {
    classNames: ['share-content'],
    intl: inject.service(),
    documents: inject.service(),
    listOfMethods: Ember.A([]),
    showMethods: false,
    isFetching: false,
    selectedMethod: null,
    content: null,
    sharedEntities: null,
    shouldConfirm: false,
    sharingUri: null,
    expectedMethods: computed(function () {
      return [shareType.SHARED, shareType.PUBLIC, shareType.PRIVATE];
    }),
    initDropdown: function initDropdown() {
      var methods = this.get('expectedMethods');
      var intl = this.get('intl');
      this.set('listOfMethods', _lodash.default.map(methods, function (methodName) {
        if (intl.exists("components.inbox.labels.shareModal.methods.".concat(methodName, ".title"))) {
          return {
            type: methodName,
            title: intl.lookup("components.inbox.labels.shareModal.methods.".concat(methodName, ".title")).toString(),
            info: intl.lookup("components.inbox.labels.shareModal.methods.".concat(methodName, ".info")).toString()
          };
        }

        return {
          type: methodName,
          title: methodName
        };
      }));
    },
    getShareProps: function getShareProps(id) {
      var _this = this;

      var data = {
        pageSize: 200,
        pageNumber: 1,
        entityId: id,
        expand: 'member'
      };
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('documents').getSharedDocumentDetails(data).then(function (data) {
          var results = data.entities;

          if (results && results.length) {
            results = _this._changeResponse(results);

            var fetchPublicMember = _lodash.default.find(results, function (item) {
              return item.memberType.toLowerCase() === shareType.PUBLIC;
            });

            if (fetchPublicMember) {
              _this._selectMethod(shareType.PUBLIC);
            } else {
              _this._selectMethod(shareType.SHARED);
            }

            var otherMembers = _lodash.default.filter(results, function (item) {
              return item.memberType.toLowerCase() !== shareType.PUBLIC;
            });

            if (otherMembers) {
              _this.set('sharedEntities', otherMembers);
            }
          } else {
            _this._selectMethod(shareType.PRIVATE);
          }

          resolve(data);
        }).catch(function (error) {
          _this.logger.error('INBOX: Failed to fetch share details: ', {
            id: id,
            error: error
          });

          reject(error);
        });
      });
    },
    getShareUri: function getShareUri(id) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.get('documents').getDocumentDetails(id).then(function (data) {
          _this2.set('sharingUri', data.sharingUri);

          resolve(data);
        }).catch(function (error) {
          _this2.logger.error('INBOX: Failed to fetch document details: ', {
            id: id,
            error: error
          });

          reject(error);
        });
      });
    },
    fetchShareDetails: function fetchShareDetails(id) {
      var _this3 = this;

      this.set('isFetching', true);
      var promises = {
        shareProps: this.getShareProps(id),
        shareUri: this.getShareUri(id)
      };
      Ember.RSVP.hash(promises).then(function (hash) {
        if (hash.shareProps && hash.shareUri) {
          _this3.set('isFetching', false);
        }
      });
    },
    _changeResponse: function _changeResponse(results) {
      var newResults = [];
      var newItem = null;

      _lodash.default.each(results, function (item) {
        newItem = {
          id: item.member.id,
          name: item.member.name,
          memberType: item.memberType,
          isGroup: item.memberType === entityType.GROUP
        };
        newResults.push(newItem);
      });

      return newResults;
    },
    _selectMethod: function _selectMethod(type) {
      var lastMethod = this.get('selectedMethod');

      var newMethod = _lodash.default.find(this.get('listOfMethods'), function (method) {
        return method.type === type;
      });

      this.set('selectedMethod', newMethod);

      if (lastMethod) {
        this._handleTypeChange(type, lastMethod);
      }
    },
    _handleTypeChange: function _handleTypeChange(type, lastMethod) {
      var id = this.get('contentId');

      if (type === shareType.PRIVATE) {
        this.makePrivate(lastMethod, id);
      } else if (type === shareType.PUBLIC) {
        this.makePublic();
      } else if (type === shareType.SHARED && lastMethod.type === shareType.PUBLIC) {
        this.get('documents').deleteDocumentShares(id, shareType.PUBLIC);
      }
    },
    makePrivate: function makePrivate(lastMethod, id) {
      if (lastMethod.type === shareType.PUBLIC) {
        this.get('documents').deleteDocumentShares(id, shareType.PUBLIC);
      } else if (lastMethod.type === 'shared') {
        var listOfEntities = this.get('sharedEntities');

        if (listOfEntities && listOfEntities.length) {
          this.set('shouldConfirm', true);
        }
      }
    },
    makePublic: function makePublic() {
      var _this4 = this;

      var id = this.get('contentId');
      var params = {
        sharedEntityType: 'DOCUMENT',
        memberType: shareType.PUBLIC.toUpperCase(),
        sharedEntity: {
          id: id
        }
      };
      return this.get('documents').shareDocument(params).catch(function (error) {
        _this4.logger.error('INBOX-SHARE: Failed to make document public: ', {
          id: id,
          error: error
        });
      });
    },
    shareWithSelectedEntity: function shareWithSelectedEntity(entityId, entityType) {
      var _this5 = this;

      var id = this.get('contentId');
      var params = {
        sharedEntityType: 'DOCUMENT',
        members: [{
          member: {
            id: entityId
          },
          memberType: entityType
        }],
        sharedEntity: {
          id: id
        }
      };
      return this.get('documents').shareDocument(params).catch(function (error) {
        _this5.logger.error('INBOX-SHARE: Failed to share document : ', {
          id: id,
          with: entityId,
          error: error
        });
      });
    },
    didInsertElement: function didInsertElement() {
      var _this6 = this;

      this._super.apply(this, arguments);

      var id = this.get('elementId');
      Ember.$(window).on("click.".concat(id), function (event) {
        Ember.run(function () {
          var target = $(event.target);
          var hasDropdownAsParent = target.parents('.dropdown-toggle').length;

          if (!(target.hasClass('dropdown-toggle') || hasDropdownAsParent)) {
            _this6.set('showMethods', false);
          }
        });
      });
      this.$('.file-name').tooltip({
        trigger: 'hover'
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      var contentId = this.get('contentId');
      this.set('sharedEntities', Ember.A([]));
      this.fetchShareDetails(contentId);
      this.initDropdown();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      Ember.$(window).off(".".concat(this.get('elementId')));
      this.$('.file-name').tooltip('destroy');
    },
    isPrivate: computed.equal('selectedMethod.type', shareType.PRIVATE),
    isPublic: computed.equal('selectedMethod.type', shareType.PUBLIC),
    isShared: computed.equal('selectedMethod.type', shareType.SHARED),
    noSharedEntities: computed.equal('sharedEntities.length', 0),
    showFooter: computed('isShared', 'isPublic', 'isFetching', function () {
      return (this.get('isShared') || this.get('isPublic')) && !this.get('isFetching');
    }),
    contentId: computed('content', function () {
      return this.get('content.id');
    }),
    actions: {
      onEntitySelect: function onEntitySelect(entity) {
        if (entity) {
          if (Ember.get(entity, 'memberType') === entityType.GROUP) {
            Ember.set(entity, 'isGroup', true);
          }

          this.get('sharedEntities').pushObject(entity);

          this._selectMethod('shared');

          this.shareWithSelectedEntity(entity.id, Ember.get(entity, 'memberType'));
        }
      },
      removeEntity: function removeEntity(entity) {
        var id = this.get('contentId');
        var sharedEntities = this.get('sharedEntities');
        this.get('documents').deleteDocumentShares(id, entity.id);
        sharedEntities.removeObject(entity);

        if (sharedEntities.length === 0) {
          this._selectMethod(shareType.PRIVATE);
        }
      },
      selectMethod: function selectMethod(method) {
        this._selectMethod(method.type);
      },
      toggleShowMethods: function toggleShowMethods() {
        this.toggleProperty('showMethods');
      },
      stopSharing: function stopSharing() {
        var id = this.get('contentId');
        var documentsSvc = this.get('documents');
        var listOfEntities = this.get('sharedEntities');

        _lodash.default.each(listOfEntities, function (entity) {
          documentsSvc.deleteDocumentShares(id, entity.id);
        });

        this.set('sharedEntities', Ember.A([]));
        this.set('shouldConfirm', false);
      },
      cancelConfirm: function cancelConfirm() {
        this._selectMethod('shared');

        this.set('shouldConfirm', false);
      },
      dismiss: function dismiss() {
        this.sendAction('onDismiss');
      }
    }
  });

  _exports.default = _default;
});