define("web-directory/components/free-trial/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['free-trial'],
    application: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    trial: Ember.inject.service(),
    freeTrialStatus: '',
    willRender: function willRender() {
      var remaining = this.get('trial').freeTrialDaysRemaining;
      var remainingText = this.get('intl').t('freeTrial.trialStatus', {
        number: remaining
      });
      this.set('freeTrialStatus', remainingText);
    },
    actions: {
      openTrialSupport: function openTrialSupport() {
        this.get('application').displayTrialSupport();
      }
    }
  });

  _exports.default = _default;
});