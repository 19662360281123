define("web-directory/components/entity-picture-content/delete-pictures/component", ["exports", "web-directory/components/entity-picture-content/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var set = Ember.set,
      computed = Ember.computed,
      Component = Ember.Component;

  var _default = Component.extend({
    classNames: ['delete-pictures'],
    type: null,
    entity: null,
    confirm: function confirm() {},
    disable: function disable() {},
    done: function done() {},
    actions: {
      selectImage: function selectImage(upload) {
        var current = upload.selected;
        set(upload, 'selected', !current);
        var limit = this.get('number');
        var total = this.get('uploads.length');
        var currentSelection = this.get('currentSelected.length');
        this.attrs.disable(currentSelection < total - limit);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.disable(true);
      this.set('confirm', this.deleteImages.bind(this));
    },
    number: computed(function () {
      var entity = this.get('entity');
      var entityType = entity.get('type');
      var imageType = this.get('type');
      var field = entity.get("fieldConfig.".concat(_component.imageMappings[entityType][imageType]));
      return field.get('maxRepeat');
    }),
    uploads: computed('entity.uploadedImages.[]', function () {
      var images = this.get('entity.uploadedImages') || [];
      return images.map(function (image) {
        return {
          image: image,
          selected: false
        };
      });
    }),
    currentSelected: computed('uploads.@each.selected', function () {
      return this.get('uploads').filterBy('selected');
    }),
    imagesToSave: computed('uploads.@each.selected', function () {
      return this.get('uploads').rejectBy('selected');
    }),
    deleteImages: function deleteImages() {
      var _this = this;

      var entity = this.get('entity');
      var entityType = entity.get('type');
      var type = this.get('type');
      var fieldPath = _component.imageMappings[entityType][type];
      var images = this.get('imagesToSave').mapBy('image');
      entity.set(fieldPath, images, {
        overwrite: true
      });
      return entity.save().then(function () {
        _this.attrs.done();
      });
    }
  });

  _exports.default = _default;
});