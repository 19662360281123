define("web-directory/components/inbox-panel-v2/reporting-alert/reporting-alert-summary/component", ["exports", "web-directory/utils/reporting-alert-summary/reporting-alert-summary", "web-directory/utils/acd"], function (_exports, _reportingAlertSummary, _acd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    engageanalytics: Ember.inject.service(),
    queueService: Ember.inject.service('queue'),
    logger: Ember.inject.service('stash-logger'),
    alertSummary: Ember.computed.readOnly('reportingAlert.alertSummary'),
    ruleName: Ember.computed.readOnly('reportingAlert.rule.name'),
    mainEntity: '',
    otherEntityDetails: Ember.RSVP.resolve([]),
    ruleNameSeparator: '',
    inboxService: {},

    /**
     * @action
     * When the reporting alert has alert summary, then this action is supposed to
     * set has-alert-summary class in the parent component's element(s).
     */
    setHasAlertSummary: function setHasAlertSummary() {
      return (0, _acd.warnClosureAction)('actionSetHasAlertSummary', null);
    },
    init: function init() {
      this._super.apply(this, arguments);

      var inboxService = this.get('inboxService');
      var reportingAlertSummary = new _reportingAlertSummary.ReportingAlertSummary({
        ruleName: this.get('ruleName'),
        alertSummary: this.get('alertSummary'),
        intl: this.get('intl'),
        cache: inboxService.cache,
        queueService: this.get('queueService'),
        engageanalytics: this.get('engageanalytics'),
        logger: this.get('logger')
      });
      var mainEntity = reportingAlertSummary.getMainEntity();
      var ruleNameSeparator = mainEntity ? ' - ' : '';
      this.setProperties({
        mainEntity: mainEntity,
        ruleNameSeparator: ruleNameSeparator,
        otherEntityDetails: reportingAlertSummary.getOtherEntities()
      });

      if (reportingAlertSummary.hasOtherEntityDetails()) {
        this.get('setHasAlertSummary')();
      }
    }
  });

  _exports.default = _default;
});