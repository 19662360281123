define("web-directory/mixins/email-attachments/drop-attachment-mixin", ["exports", "lodash", "web-directory/mixins/email-attachments/attachment-mixin"], function (_exports, _lodash, _attachmentMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DropAttachmentMixin = Ember.Mixin.create(_attachmentMixin.default, {
    drop: function drop(evt) {
      evt.preventDefault();
      var dt = evt.dataTransfer;
      var files = [];

      _lodash.default.each(dt.items, function (file) {
        return files.push({
          info: file.getAsFile(),
          name: file.getAsFile().name
        });
      });

      this.handlePayloadOfAttachments(files);
    },
    dragover: function dragover(evt) {
      evt.preventDefault();
      evt.dataTransfer.effectAllowed;
    },
    dragend: function dragend(evt) {
      evt.preventDefault();
      var dt = evt.dataTransfer;

      if (dt.items) {
        dt.items.forEach(function (item) {
          return item.remove();
        });
      } else {
        dt.clearData();
      }
    }
  });
  var _default = DropAttachmentMixin;
  _exports.default = _default;
});