define("web-directory/components/entity-fields/validators/person", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    isValid: function isValid(text) {
      return text;
    },
    isEmpty: function isEmpty(person) {
      if (!person) {
        return true;
      }

      var value = person.value;
      return value === undefined || value === null || _lodash.default.isEmpty(value);
    }
  };
  _exports.default = _default;
});