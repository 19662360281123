define("web-directory/components/timeline-stream/room-message-renderer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "05KGz4sR",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"header\"],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"room\",\"group\"]]],null,{\"statements\":[[0,\"        \"],[1,[33,[\"entity-image\"],null,[[\"entity\",\"enableMiniCard\",\"size\",\"isLinkable\",\"showPresence\"],[[28,[\"room\",\"group\"]],true,48,true,true]]],false],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"title\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"primary\"],[13],[0,\"\\n            \"],[11,\"span\",[]],[15,\"class\",\"description\"],[13],[1,[26,[\"description\"]],false],[14],[0,\"\\n            \"],[11,\"span\",[]],[15,\"class\",\"room\"],[13],[1,[33,[\"emoji-text\"],[[28,[\"roomSubject\"]]],null],false],[14],[0,\"\\n        \"],[14],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"secondary\"],[13],[1,[26,[\"timeDisplay\"]],false],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/timeline-stream/room-message-renderer/template.hbs"
    }
  });

  _exports.default = _default;
});