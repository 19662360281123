define("web-directory/models/public-api-user", ["exports", "ember-data", "lodash", "web-directory/utils/dates"], function (_exports, _emberData, _lodash, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = _emberData.default.Model.extend({
    name: attr(),
    chat: attr(),
    department: attr(),
    email: attr(),
    primaryContactInfo: attr(),
    addresses: attr(),
    state: attr(),
    title: attr(),
    username: attr(),
    images: attr(),
    version: attr(),
    station: attr(),
    authorization: attr(),
    fieldConfigs: attr(),
    selfUri: attr(),
    outOfOffice: attr(),
    presence: attr(),
    token: attr(),
    logCapture: attr(),
    type: 'public-api-user',
    intl: inject.service(),
    session: inject.service(),
    isSuper: false,
    // set by session service
    isTrustee: false,
    // set by session service
    person: computed.reads('session.person'),
    isCloned: computed.bool('token.clonedUser'),
    logCaptureEnabled: computed('logCapture.dateExpired', function () {
      var expiration = this.get('logCapture.dateExpired');
      return expiration && (0, _dates.default)().isBefore(_dates.default.utc(expiration));
    }),
    profile: _emberData.default.belongsTo('person', {
      async: true
    }),
    // Legacy properties
    // Here for computed parity with connect-api user
    // Deprecated. To be phased out soon.
    userType: computed('isSuper', 'isTrustee', 'isAdmin', function () {
      if (this.get('isSuper')) {
        return 'super';
      } else if (this.get('isTrustee')) {
        return 'trustee';
      } else if (this.get('isAdmin')) {
        return 'admin';
      } else {
        return 'employee';
      }
    }),
    isAdmin: computed('authorization.roles.@each.name', 'isSuper', function () {
      if (this.get('isSuper')) {
        return true;
      }

      var roles = this.get('authorization.roles') || [];
      var names = roles.mapBy('name');
      return _lodash.default.includes(names, 'admin');
    }),
    userState: computed('state', function () {
      return this.get('intl').lookup("item.state.".concat(this.get('state'))).toString();
    }),
    inviteLastSent: computed('welcomeSent', function () {
      var date = (0, _dates.default)(new Date(this.get('welcomeSent') * 1000));
      return date.format('L');
    })
  });

  _exports.default = _default;
});