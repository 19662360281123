define("web-directory/components/entity-fields/person-field/component", ["exports", "web-directory/mixins/field-component"], function (_exports, _fieldComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldComponent.default, {
    actions: {
      selectPersonFromPicker: function selectPersonFromPicker(person) {
        this.set('entry.value', person.get('id'));
      }
    },
    person: Ember.computed('entry.value', function () {
      var value = this.get('entry.value');

      if (value) {
        return this.store.find('person', value);
      }

      return undefined;
    })
  });

  _exports.default = _default;
});