define("web-directory/serializers/callv2", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONSerializer.extend({
    isNewSerializerAPI: true,
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      payload = payload.entities || [];
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});