define("web-directory/components/group-contact-modal/component", ["exports", "lodash", "ember-purecloud-components/utils/browser"], function (_exports, _lodash, _browser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var AUDIO_FORMAT = _browser.default.getSuggestedAudioTypeFrom(['webm', 'wav']);

  function isOggFileType(file) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      var fileReader = new FileReader();

      fileReader.onloadend = function (e) {
        var arr = new window.Uint8Array(e.target.result);
        var isOpus = arr[28] === 0x4f && arr[29] === 0x70 && arr[30] === 0x75 && arr[31] === 0x73 && arr[32] === 0x48 && arr[33] === 0x65 && arr[34] === 0x61 && arr[35] === 0x64;
        var isWav = arr[0] === 0x52 && arr[1] === 0x49 && arr[2] === 0x46 && arr[3] === 0x46 && arr[8] === 0x57 && arr[9] === 0x41 && arr[10] === 0x56 && arr[11] === 0x45;
        var isAiff = arr[0] === 0x46 && arr[1] === 0x4f && arr[2] === 0x52 && arr[3] === 0x4d && arr[8] === 0x41 && arr[9] === 0x49 && arr[10] === 0x46 && arr[11] === 0x46; // Only support opus encodings, not vorbis
        // Support WAV and AIFF as well
        // See: https://github.com/sindresorhus/file-type

        if (isOpus || isWav || isAiff) {
          resolve('opus');
        } else {
          reject(false);
        }
      };

      fileReader.readAsArrayBuffer(file);
    });
  }

  var GroupContactModalComponent = Ember.Component.extend({
    classNames: ['group-contact-form'],
    classNameBindings: ['group-ring-voicemail'],
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    phone: Ember.inject.service(),
    logger: Ember.inject.service('stash-logger'),
    session: Ember.inject.service(),
    application: Ember.inject.service(),
    notification: Ember.inject.service(),
    entity: Ember.computed.reads('model'),
    attrs: {
      close: function close() {},
      disableModal: function disableModal() {}
    },
    saveContactInfo: function saveContactInfo() {},
    number: null,
    extension: null,
    originalExtension: null,
    voicemailFile: null,
    showVoicemailOptions: false,
    // ^^^ This corresponds to `toggleShowVoicemailGreetingOptions` in `group-profile/group-contact-panel/*`.
    resetGreeting: false,
    rotateCallSecondsError: false,
    numberOfRotationsError: false,
    actions: {
      saveForm: function saveForm() {
        this.saveContactInfo();
      },
      updateNumber: function updateNumber(event) {
        this.set('number', event.target.value);
      },
      updateExtension: function updateExtension(event) {
        this.set('extension', event.target.value);
      },
      updateRotateCalls: function updateRotateCalls(value) {
        var number = parseInt(value, 10);

        if (typeof number === 'number') {
          this.set('rotateCallSeconds', number);
        }
      },
      updateRotations: function updateRotations(value) {
        var number = parseInt(value, 10);

        if (typeof number === 'number') {
          this.set('numberOfRotations', number);
        }
      },
      toggleVoicemail: function toggleVoicemail() {
        this.toggleProperty('voicemailOn');
      },
      toggleEmailNotification: function toggleEmailNotification() {
        this.toggleProperty('emailNotificationOn');
      },
      toggleOptions: function toggleOptions() {
        this.toggleProperty('showVoicemailOptions');
      },
      // ^^^ This corresponds to `toggleShowVoicemailGreetingOptions` in `group-profile/group-contact-panel/*`.
      cancel: function cancel() {
        this.attrs.close();
      },
      selectVoicemailFile: function selectVoicemailFile(file, recording) {
        if (recording) {
          this.set('recordingFile', file);
          this.set('voicemailFile', null);
        } else {
          this.set('voicemailFile', file);
          this.set('recordingFile', null);
        }
      },
      triggerUpload: function triggerUpload() {
        if (this.get('hasGreeting')) {
          return;
        }

        this.$('input.file-upload').trigger('click');
      },
      chooseFile: function chooseFile() {
        var _this = this;

        var input = this.$('input.file-upload');
        var file = Ember.get(input[0], 'files.0') || [];

        if (file) {
          isOggFileType(file).then(function (format) {
            Ember.Logger.info('Voicemail: Correct file type', format);

            _this.send('selectVoicemailFile', file, false);
          }).catch(function () {
            var title = _this.get('intl').lookup('notification.group.fileUpload.title');

            var message = _this.get('intl').lookup('notification.group.fileUpload.message');

            _this.get('notification').error(title, message);
          });
        } else {
          this.set('errorSelectingFile', true);
        }

        this.set('showVoicemailOptions', false);
      },
      recordingReady: function recordingReady(file) {
        this.send('selectVoicemailFile', file, true);
        this.set('resetGreeting', false);
      },
      resetRecording: function resetRecording() {
        this.set('recordingFile', null);
      },
      resetFile: function resetFile() {
        this.set('voicemailFile', null);
      },
      resetCurrentGreeting: function resetCurrentGreeting() {
        if (!this.get('hasGreeting')) {
          this.send('resetFile');
          this.send('resetRecording');
        } else {
          this.set('resetGreeting', true);
          this.set('hasGreeting', false);
        }

        this.set('showVoicemailOptions', false);
      },
      audioPlayerError: function audioPlayerError() {
        this.set('hasGreeting', false);
      },
      disableModal: function disableModal(key, value) {
        this.set(key, value);
        this.attrs.disableModal(value);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('saveContactInfo', this.save.bind(this));
      Ember.run.scheduleOnce('afterRender', this, this.setupInput);
      Ember.run.scheduleOnce('afterRender', this, this.initNumber);
    },
    numberOfRotations: Ember.computed('entity.numberOfRotations', 'entity.numberOfRotations.{content,isFulfilled}', {
      get: function get() {
        return this.get('entity.numberOfRotations.content');
      }
    }),
    rotateCallSeconds: Ember.computed('entity.rotateCallSeconds', 'entity.rotateCallSeconds.{content,isFulfilled}', {
      get: function get() {
        return this.get('entity.rotateCallSeconds.content');
      }
    }),
    _voicemailOn: false,
    voicemailOn: Ember.computed('entity.voicemailEnabled', 'entity.voicemailEnabled.{content,isFulfilled}', 'editingVoicemailDisabled', {
      get: function get() {
        if (this.get('entity.isSaving')) {
          return this._voicemailOn;
        }

        if (this.get('editingVoicemailDisabled')) {
          this._voicemailOn = false;
          return false;
        }

        var value = this.get('entity.voicemailEnabled.isFulfilled') && this.get('entity.voicemailEnabled.content');
        this._voicemailOn = value;
        return value;
      },
      set: function set(key, value) {
        this._voicemailOn = value;
        return value;
      }
    }),
    emailNotificationOn: Ember.computed('entity.emailNotificationEnabled', 'entity.emailNotificationEnabled.{content,isFulfilled}', {
      get: function get() {
        return this.get('entity.emailNotificationEnabled');
      },
      set: function set(key, value) {
        return value;
      }
    }),
    haveExtensionError: Ember.computed('entity.number.rel.errorCode', 'extension', 'originalExtension', function () {
      var extension = this.get('extension');
      var originalExtension = this.get('originalExtension');

      if (originalExtension && extension !== originalExtension || this.get('entity.isSaving')) {
        return false;
      }

      return !!this.get('entity.number.rel.errorCode');
    }),
    isExtensionResourceInUse: Ember.computed('haveExtensionError', function () {
      if (!this.get('haveExtensionError')) {
        return false;
      }

      var code = this.get('entity.number.rel.errorCode');
      return code === 'RESOURCE_ALREADY_IN_USE';
    }),
    isExtensionNotInPool: Ember.computed('haveExtensionError', function () {
      if (!this.get('haveExtensionError')) {
        return false;
      }

      var code = this.get('entity.number.rel.errorCode');
      return code === 'EXTENSION_MUST_BE_ASSGINED_TO_AN_EXTENSION_POOL';
    }),
    editingVoicemailDisabled: Ember.computed('isEmpty', 'isValid', 'haveExtensionError', function () {
      return this.get('isEmpty') || !this.get('isValid') || this.get('haveExtensionError');
    }),
    ringTimeMs: Ember.computed('rotateCallSeconds', 'numberOfRotations', 'numberOfRotationsError', 'rotateCallSecondsError', function () {
      var seconds = parseInt(this.get('rotateCallSeconds'), 10);
      var rotations = parseInt(this.get('numberOfRotations'), 10);

      var _this$getProperties = this.getProperties('numberOfRotationsError', 'rotateCallSecondsError'),
          numberOfRotationsError = _this$getProperties.numberOfRotationsError,
          rotateCallSecondsError = _this$getProperties.rotateCallSecondsError;

      if (numberOfRotationsError || rotateCallSecondsError) {
        return null;
      }

      var total = seconds * rotations;
      return total * 1000;
    }),
    isValid: Ember.computed('invalidExtension', 'invalidPhoneNumber', 'haveNumberAndExtension', 'number', 'extension', function () {
      var number = this.get('number');
      var ext = this.get('extension');

      if (_lodash.default.isEmpty(number) && _lodash.default.isEmpty(ext)) {
        return true;
      }

      if (this.get('haveNumberAndExtension')) {
        return false;
      }

      if (!number) {
        return !this.get('invalidExtension');
      }

      return !this.get('invalidPhoneNumber');
    }),
    isInvalid: Ember.computed.not('isValid'),
    errorMessage: Ember.computed('isValid', function () {
      if (this.get('invalidExtension')) {
        return this.get('intl').lookup('purecloud.callForwarding.invalidExtension').toString();
      } else if (this.get('invalidPhoneNumber')) {
        return this.get('intl').lookup('purecloud.callForwarding.invalidPhoneNumber').toString();
      } else if (this.get('haveNumberAndExtension')) {
        return this.get('intl').lookup('purecloud.callForwarding.numberAndExtension').toString();
      }
    }),
    invalidExtension: Ember.computed('extension', 'haveNumberAndExtension', function () {
      var ext = this.get('extension');
      var regex = /^\d+$/; // simple regex test, not meant to be expansive

      if (!this.get('number') && !_lodash.default.isEmpty(ext)) {
        return !regex.test(ext);
      }

      return false;
    }),
    invalidPhoneNumber: Ember.computed('number', function () {
      var elem = this.$('.group-ring-phone-input');

      if (elem) {
        return !this.$('.group-ring-phone-input').intlTelInput('isValidNumber');
      }

      return false;
    }),
    isEmpty: Ember.computed('number', 'extension', function () {
      var number = this.get('number');
      var extension = this.get('extension');
      return _lodash.default.isEmpty(number) && _lodash.default.isEmpty(extension);
    }),
    haveNumberAndExtension: Ember.computed('number', 'extension', function () {
      var number = this.get('number');
      var extension = this.get('extension');
      return !_lodash.default.isEmpty(number) && !_lodash.default.isEmpty(extension);
    }),
    callNotice: Ember.computed(function () {
      return this.get('intl').lookup('groups.modal.groupRingNotice').toString();
    }),
    showGroupEmail: Ember.computed(function () {
      return false; // maybe true in the future...
    }),
    playerOptions: Ember.computed(function () {
      return {
        html5: true,
        volume: 0.5,
        format: ['ogg']
      };
    }),
    greetingOptions: Ember.computed(function () {
      // greetings seem to have a low volume
      return {
        html5: true,
        volume: 1.0,
        format: [AUDIO_FORMAT]
      };
    }),
    hasGreeting: Ember.computed('entity.greeting.isFulfilled', function () {
      var greeting = this.get('entity.greeting.content');
      return !!greeting;
    }),
    greetingAudioSource: Ember.computed('entity.greeting.isFulfilled', function () {
      var _this2 = this;

      var greeting = this.get('entity.greeting');
      return greeting.then(function (data) {
        var path = data.audioFile.selfUri;

        if (_lodash.default.head(path) === '/') {
          path = path.substring(1);
        }

        var url = _this2.get('application').pcV2Uri(path);

        return _this2.get('ajax').request(url, {
          data: {
            formatId: AUDIO_FORMAT
          }
        });
      }).then(function (data) {
        return data.mediaFileUri;
      }).catch(function () {
        _this2.get('logger').error('Error loading media for group:', {
          groupId: _this2.get('entity.guid')
        });

        _this2.set('hasGreeting', false);
      });
    }),
    showEmailNotification: Ember.computed.not('session.org.hasHipaaEnabled'),
    // ^^^ This corresponds to `emailNotifications.show` in `group-profile/group-contact-panel/*`.
    setConfirmDisabled: Ember.observer('isValid', function () {
      this.attrs.disableModal(!this.get('isValid'));
    }),
    setupInput: function setupInput() {
      this.$('.group-ring-phone-input').intlTelInput({
        nationalMode: false,
        utilsScript: "".concat(this.get('application.urlprefix'), "assets/intltelinput/utils.js")
      });
    },
    initNumber: function initNumber() {
      var info = this.get('entity.contactInfo.groupNumber.firstObject.value');
      var inputElem = this.$('.group-ring-phone-input');
      var extElem = this.$('.group-ring-extension-input');

      if (!_lodash.default.isEmpty(this.get('entity.contactInfo.groupNumber'))) {
        if (_lodash.default.isEmpty(inputElem.intlTelInput('getNumber'))) {
          inputElem.intlTelInput('setNumber', info.number);
        }

        if (_lodash.default.isEmpty(extElem.val())) {
          extElem.val(info.extension);
        }

        this.set('number', info.number);
        this.set('extension', info.extension);
        this.set('originalExtension', info.extension);
      }
    },
    uploadGreeting: function uploadGreeting(file) {
      var _this3 = this;

      if (!file) {
        return Ember.RSVP.resolve({});
      }

      var uploadPath = "".concat(this.get('application.purecloudAppsUri'), "uploads/v2/greetings");
      var data = new FormData();
      data.append('file', file);
      data.append('ownerType', 'group');
      data.append('ownerId', this.get('entity.guid'));
      data.append('name', 'voicemail');
      data.append('greetingType', 'voicemail');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open('POST', uploadPath, true);
        xhr.setRequestHeader('Authorization', "bearer ".concat(_this3.get('session.accessToken')));

        xhr.onreadystatechange = function () {
          // Can't use XMLHttpRequest.DONE cause Pace, so we have to use the instance property
          if (xhr.readyState === xhr.DONE) {
            _this3.uploadComplete(xhr, resolve, reject);
          }
        };

        xhr.send(data);
      });
    },
    uploadComplete: function uploadComplete(xhr, resolve, reject) {
      var responseText = xhr.responseText,
          status = xhr.status;
      var data = JSON.parse(responseText);

      if (status === 200) {
        this.get('logger').info('Successful voicemail greeting upload', data.correlationId);
        resolve(data);
      } else {
        this.get('logger').error('Error uploading voicemail file:', {
          error: responseText
        });
        reject(data);
      }
    },
    save: function save() {
      var _this4 = this;

      var entity = this.get('entity');
      var ajax = this.get('ajax');
      var notification = this.get('notification');
      var application = this.get('application');

      var _this$getProperties2 = this.getProperties('voicemailOn', 'numberOfRotations', 'rotateCallSeconds', 'emailNotificationOn'),
          voicemailOn = _this$getProperties2.voicemailOn,
          numberOfRotations = _this$getProperties2.numberOfRotations,
          rotateCallSeconds = _this$getProperties2.rotateCallSeconds,
          emailNotificationOn = _this$getProperties2.emailNotificationOn;

      this.setNumberData();
      var promise = entity.save();
      var showExtensionErrorNotification = false; // Reset since we are saving

      this.set('originalExtension', null);
      promise.then(function () {
        var haveError = !!entity.get('number.rel.errorCode');

        if (!_this4.get('isEmpty') && !haveError) {
          return entity.get('policy').then(function (policy) {
            policy.setProperties({
              enabled: voicemailOn,
              rotateCallsSecs: rotateCallSeconds,
              stopRingingAfterRotations: numberOfRotations,
              sendEmailNotifications: emailNotificationOn
            });
            return policy.save();
          });
        }

        showExtensionErrorNotification = haveError;
      }).then(function () {
        var resetGreeting = _this4.get('resetGreeting');

        if (resetGreeting) {
          return _this4.get('entity.greeting').then(function (greeting) {
            var path = greeting.selfUri;

            if (_lodash.default.head(path) === '/') {
              path = path.substring(1);
            }

            var url = application.pcV2Uri(path);
            return ajax.delete(url);
          });
        } else {
          var file = _this4.get('recordingFile');

          if (!file) {
            file = _this4.get('voicemailFile');
          }

          return _this4.uploadGreeting(file);
        }
      }).then(function () {
        entity.notifyPropertyChange('greeting');
      });
      return promise.then(function () {
        var title = _this4.get('entity.name');

        var message = _this4.get('intl').lookup('notification.group.general');

        var handler = notification.info.bind(notification);

        if (showExtensionErrorNotification) {
          handler = notification.error.bind(notification);

          var isExtensionResourceInUse = _this4.get('isExtensionResourceInUse');

          var isExtensionNotInPool = _this4.get('isExtensionNotInPool');

          if (isExtensionNotInPool) {
            title = _this4.get('intl').lookup('notification.group.extensionNotInPoolError.title');
            message = _this4.get('intl').lookup('notification.group.extensionNotInPoolError.message');
          } else if (isExtensionResourceInUse) {
            title = _this4.get('intl').lookup('notification.group.resourceInUseError.title');
            message = _this4.get('intl').lookup('notification.group.resourceInUseError.message');
          } else {
            title = _this4.get('intl').lookup('notification.group.groupRingExtensionError.title');
            message = _this4.get('intl').lookup('notification.group.groupRingExtensionError.message');
          }
        }

        handler(title, message, {
          icon: '',
          hideAfter: 8
        });
      }).catch(function (err) {
        var title = _this4.get('entity.name');

        var message = _this4.get('intl').lookup('notification.group.saveError');

        notification.error(title, message, {
          icon: '',
          hideAfter: 8
        });
        Ember.Logger.error(message, err);
      }).finally(function () {
        _this4.attrs.close();

        entity.reload();
      });
    },
    setNumberData: function setNumberData() {
      var number = Ember.$('.group-ring-phone-input').intlTelInput('getNumber');
      var extension = Ember.$('.group-ring-extension-input').val();

      if (_lodash.default.isEmpty(this.get('entity.contactInfo.groupNumber'))) {
        if (_lodash.default.isEmpty(this.get('entity.contactInfo'))) {
          this.set('entity.contactInfo', {});
        }

        var valueData = {
          number: '',
          countryCode: '',
          extension: '',
          acceptsSMS: false
        };
        var emptyData = [{
          value: valueData
        }];
        this.set('entity.contactInfo.groupNumber', emptyData);
      }

      this.set('entity.contactInfo.groupNumber.firstObject.value.number', number);
      this.set('entity.contactInfo.groupNumber.firstObject.value.extension', extension);
    }
  });
  var _default = GroupContactModalComponent;
  _exports.default = _default;
});