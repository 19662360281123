define("web-directory/instance-initializers/ember-tooltips", ["exports", "ember-tooltips/components/lazy-render-wrapper"], function (_exports, _lazyRenderWrapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    // this can be removed after ember-tooltips version is bumped to 3.0.
    // 2.5 defaults this property to off.
    _lazyRenderWrapper.default.reopen({
      enableLazyRendering: true
    });
  }

  var _default = {
    name: 'ember-tooltips',
    initialize: initialize
  };
  _exports.default = _default;
});