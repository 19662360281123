define("web-directory/serializers/queue", ["exports", "web-directory/serializers/purecloud", "lodash"], function (_exports, _purecloud, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _purecloud.default.extend({
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      _lodash.default.forEach(payload.entities, function (entity) {
        entity.type = 'queue';
        return entity;
      });

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      payload.type = 'queue'; // Queue lookup can either go through the typical detail API or through the divisionviews API
      // so we can handle situations where you might run into queue lookups from a different division
      // Handle that response type here
      // See API docs: https://developer.inindca.com/api/rest/v2/routing/#get-api-v2-routing-queues-divisionviews

      if (payload.entities && payload.entities.length === 1) {
        payload = payload.entities[0];
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});