define("web-directory/components/dynamic-popover/dynamic-popover.type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });

  /**
   * @todo support left and right positioning
   */
  // Todo: support other actions
  var PopoverActions = ['close', 'prevent'];
});