define("web-directory/components/fixtable-grid", ["exports", "fixtable-ember/components/fixtable-grid"], function (_exports, _fixtableGrid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fixtableGrid.default.extend({
    classNames: ['fixtable-grid']
  });

  _exports.default = _default;
});