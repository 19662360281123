define("web-directory/components/acd-interactions-panel/interaction-session-share/session-share-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fnPD5NH1",
    "block": "{\"statements\":[[6,[\"each\"],[[28,[\"providers\"]]],null,{\"statements\":[[0,\"    \"],[11,\"li\",[]],[13],[0,\"\\n        \"],[11,\"button\",[]],[15,\"class\",\"btn btn-link btn-sm\"],[15,\"type\",\"button\"],[16,\"onclick\",[33,[\"action\"],[[28,[null]],\"selectProvider\",[28,[\"provider\"]]],null],null],[13],[0,\"\\n            \"],[1,[28,[\"provider\",\"title\"]],false],[0,\"\\n        \"],[14],[0,\"\\n\\n    \"],[14],[0,\"\\n\"]],\"locals\":[\"provider\"]},null],[11,\"div\",[]],[15,\"class\",\"session-share-arrow\"],[13],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/acd-interactions-panel/interaction-session-share/session-share-dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});