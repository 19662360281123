define("web-directory/components/global-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ki3AcmV+",
    "block": "{\"statements\":[[9,\"gux-modal\",{\"attrs\":[[16,\"class\",[26,[\"modalClass\"]],null],[15,\"size\",\"dynamic\"],[16,\"id\",[26,[\"displayedModal\"]],null]],\"args\":[[],[]],\"locals\":[],\"statements\":[[0,\"\\n    \"],[11,\"div\",[]],[15,\"slot\",\"content\"],[13],[0,\"\\n        \"],[1,[33,[\"frame-router\"],null,[[\"clientConfig\",\"route\"],[[28,[\"clientConfig\"]],[28,[\"modalRoute\"]]]]],false],[0,\"\\n    \"],[14],[0,\"\\n\"]]}]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/global-modal/template.hbs"
    }
  });

  _exports.default = _default;
});