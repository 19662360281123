define("web-directory/media-providers/utils/auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildAuthUrl = buildAuthUrl;
  _exports.performIframeAuth = performIframeAuth;
  _exports.queryParamsToObject = queryParamsToObject;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function queryParamsToObject(params) {
    if (!params) {
      return {};
    }

    return params.split('&').reduce(function (acc, param) {
      var keyValue = param.split('=');

      if (keyValue.length === 2) {
        var _keyValue = _slicedToArray(keyValue, 2),
            key = _keyValue[0],
            value = _keyValue[1];

        acc[key] = value;
      }

      return acc;
    }, {});
  }

  function buildAuthUrl(baseUrl, authConfig) {
    var query = Object.keys(authConfig).map(function (key) {
      var value = authConfig[key];
      return "".concat(key, "=").concat(value);
    }).join('&');
    return "".concat(baseUrl, "?").concat(query);
  }

  function performIframeAuth(authUrl, authConfig) {
    return new Promise(function (resolve, reject) {
      var url = buildAuthUrl(authUrl, authConfig);
      var iframe = document.createElement('iframe');
      iframe.style = 'height:0;width:0;position:absolute;z-index:0';
      iframe.src = url;
      document.body.appendChild(iframe);
      var timer, cancelTimer;
      timer = setInterval(function () {
        try {
          if (iframe) {
            var hash = iframe.contentWindow.location.hash;
            var search = iframe.contentWindow.location.search;

            if (hash) {
              var params = queryParamsToObject(hash.substring(1));
              var accessToken = params['access_token'];

              if (accessToken) {
                document.body.removeChild(iframe);
                clearInterval(timer);
                clearTimeout(cancelTimer);
                resolve(accessToken);
              }
            }

            if (search) {
              var _params = queryParamsToObject(search.substring(1));

              var code = _params['code'];

              if (code) {
                clearInterval(timer);
                clearTimeout(cancelTimer);
                document.body.removeChild(iframe);
                resolve(code);
              }
            }
          }
        } catch (e) {// Ignore for now
        }
      }, 20); // After 5 seconds, if we havent resolved, exit
      // A properly authed client shouldnt take long on the redirect

      cancelTimer = setTimeout(function () {
        window.clearTimeout(cancelTimer);
        window.clearInterval(timer);
        reject(new Error('Timeout'));
      }, 5000);
    });
  }
});