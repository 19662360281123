define("web-directory/components/search-facet-group/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['search-facet-group', 'panel', 'panel-default'],
    classNameBindings: ['panelId'],
    facet: null,
    parentId: null,
    selectedTerms: [],
    accordianParentId: Ember.computed('parentId', function () {
      return '#' + this.get('parentId');
    }),
    panelId: Ember.computed('facet.facetName', function () {
      return this.get('facet.facetName').replace(/\./g, '_');
    }),
    href: Ember.computed('panelId', function () {
      return '#' + this.get('panelId');
    })
  });

  _exports.default = _default;
});