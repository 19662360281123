define("web-directory/routes/group/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var GroupIndexRoute = Ember.Route.extend({
    model: function model() {
      return this.store.findAll('group');
    }
  });
  var _default = GroupIndexRoute;
  _exports.default = _default;
});