define("web-directory/field-config/entities/person", ["exports", "web-directory/field-config/field-config", "web-directory/models/person"], function (_exports, _fieldConfig, _person) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fieldConfig.default.extend({
    modelClass: _person.default
  }).reopenClass({
    modelClass: _person.default
  });

  _exports.default = _default;
});