define("web-directory/serializers/call-forwarding", ["exports", "web-directory/serializers/purecloud"], function (_exports, _purecloud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _purecloud.default.extend({
    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      var data = this.serialize(snapshot, options);
      Object.assign(hash, data);
    },
    serialize: function serialize(snapshot) {
      var options = snapshot.adapterOptions;
      var serialized;

      if (options && options.patch) {
        var attrsToSerialize = options.attrs;
        var changed = snapshot.changedAttributes();
        var attrs = Object.keys(changed);

        if (!changed || attrs.length === 0) {
          return this._super.apply(this, arguments);
        }

        var data = {};
        attrs.forEach(function (attr) {
          if (attrsToSerialize) {
            if (attrsToSerialize.indexOf(attr) > -1) {
              data[attr] = snapshot.attr(attr);
            }
          } else {
            data[attr] = snapshot.attr(attr);
          }
        });
        data.enabled = snapshot.attr('enabled');
        data.voicemail = snapshot.attr('voicemail');
        serialized = data;
      } else {
        serialized = this._super.apply(this, arguments);
      }

      delete serialized.phoneNumber;
      return serialized;
    }
  });

  _exports.default = _default;
});