define("web-directory/routes/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // This route's sole purpose is to redirect users to the index page. It's for people who have bookmarked the login page
  var LoginRoute = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      var result = this._super(transition);

      if (transition.isActive === false) {
        // Route canceled for auth
        return result;
      } else {
        return this.transitionTo('index');
      }
    }
  });
  var _default = LoginRoute;
  _exports.default = _default;
});