define("web-directory/instance-initializers/setup-apps-api-handlers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'setup-apps-api-handlers',
    initialize: function initialize(instance) {
      var apiHandlers = [];
      var apiHandlerKeys = instance.lookup('apps-api-handler:keys');

      if (apiHandlerKeys && apiHandlerKeys.length) {
        apiHandlerKeys.forEach(function (currHandlerKey) {
          apiHandlers.push(instance.lookup(currHandlerKey));
        });
      }

      instance.lookup('service:apps').set('globalApiHandlers', apiHandlers);
    }
  };
  _exports.default = _default;
});