define("web-directory/utils/video-frame-checker", ["exports", "ember-webrtc-troubleshoot/utils/video-frame-checker"], function (_exports, _videoFrameChecker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _videoFrameChecker.default;
    }
  });
});