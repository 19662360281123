define("web-directory/routes/admin-emergency-group-detail-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect: function redirect(model) {
      return this.replaceWith("/admin/routing/emergency-groups/".concat(model.emergencyGroupId));
    }
  });

  _exports.default = _default;
});