define("web-directory/components/common-banner/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['common-banner'],
    classNameBindings: ['accent'],

    /**
     * @attribute
     * the accent styling that the badge should be rendered in
     */
    accent: 'info',

    /**
     * @attribute
     * the icon to display in the banner, if no icon is provided it will
     * be omitted in the render.
     */
    icon: undefined,

    /**
     * @attribute
     * what label to give the banner. It will show between the icon and the slotted content
     * of the banner. If one is no provided, then the label will not show.
     */
    label: undefined
  });

  _exports.default = _default;
});