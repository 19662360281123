define("web-directory/components/timeline-stream/direct-message-renderer/component", ["exports", "web-directory/components/timeline-stream/renderer-component-base", "ember-purecloud-components/utils/emoji"], function (_exports, _rendererComponentBase, _emoji) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var DirectMessageRenderer = _rendererComponentBase.default.extend({
    classNameBindings: [':timeline-event', ':direct-message-timeline-event', 'acknowledged'],
    intl: Ember.inject.service(),
    chat: Ember.inject.service(),
    sidebar: Ember.inject.service(),
    permissions: Ember.inject.service(),
    canViewChat: Ember.computed.reads('permissions.canViewBusinessChat'),
    entity: null,
    fromPerson: Ember.computed.reads('entity.from'),
    messages: Ember.computed.reads('entity.messages'),
    runEmojify: Ember.on('didInsertElement', function () {
      Ember.run.scheduleOnce('afterRender', this, function () {
        var contentTag = this.$();

        if (contentTag.length > 0) {
          (0, _emoji.emojifyDom)(contentTag[0]);
        }
      });
    }).observes('messages.length'),
    description: Ember.computed('messages.length', function () {
      var count = this.get('messages.length');
      var intl = this.get('intl');

      if (count === 1) {
        return intl.lookup('timeline.direct.description-singular');
      } else {
        return intl.lookup('timeline.direct.description-plural', {
          count: count
        });
      }
    }),
    click: function click() {
      this._super();

      var jid = this.get('fromPerson.jid');

      if (this.get('session.useCollaborateChat')) {
        this.get('chat').openRoom(jid);
      } else {
        this.get('chat').loadChatRoom(jid);
      }

      this.get('sidebar').openPanel('conversations');
    }
  });

  var _default = DirectMessageRenderer;
  _exports.default = _default;
});