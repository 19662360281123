define("web-directory/components/nav-sidebar/component", ["exports", "web-directory/elm-modules", "ember-purecloud-components/mixins/logger"], function (_exports, _elmModules, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Component.extend(_logger.default, {
    classNames: ['nav-sidebar'],
    Elm: _elmModules.default,
    menu: [],
    router: Orgspan.router,
    elmService: inject.service('elm'),
    urlFragment: computed('router.currentURL', function () {
      var URL = this.get('router.currentURL');

      if (URL) {
        return '#' + URL;
      } else {
        return '';
      }
    }),
    flags: computed('elmService.appContext', 'menu', function () {
      return {
        appContext: this.get('elmService.appContext'),
        menu: this.get('menu') || [],
        windowHash: this.get('urlFragment')
      };
    }),
    hashUpdater: Ember.observer('urlFragment', function () {
      Ember.run.once(this, 'sendHash', this.get('urlFragment'));
    }),
    sendHash: function sendHash(hash) {
      this.sendElmData('hashPort', hash);
    },
    elmPorts: null,
    sendElmData: function sendElmData(portName, data) {
      var sendPort = this.get("elmPorts.".concat(portName));

      if (sendPort) {
        this.logger.debug(["Sending elmPorts.".concat(portName, " to Elm module"), data]);
        sendPort.send(data);
      } else {
        this.logger.debug(["".concat(portName, " not ready to send"), data]);
      }
    },
    sendAppContext: function sendAppContext(data) {
      this.sendElmData('appContext', data);
    },
    sendMenu: function sendMenu(data) {
      this.sendElmData('menuPort', data);
    },
    contextUpdater: Ember.observer('elmService.appContext', function () {
      Ember.run.once(this, 'sendAppContext', this.get('elmService.appContext'));
    }),
    menuUpdater: Ember.observer('menu', function () {
      Ember.run.once(this, 'sendMenu', this.get('menu'));
    }),
    actions: {
      setupPorts: function setupPorts(ports) {
        var _this = this;

        ports.actions.subscribe(function (message) {
          try {
            _this.logger.debug('Elm sent:', message);

            if (message.action) {
              _this.send(message.action, new Ember.Object(message.payload));
            }
          } catch (e) {
            // We need to catch even if the notification throws an error, to prevent the
            // Elm runtime from halting on the exception.
            setTimeout(function () {
              throw e;
            }, 0);
          }
        });
        this.set('elmPorts', ports);
      },
      notifyError: function notifyError(errorInfo) {
        var nowTime = Date.now();
        var title = this.get('intl').t(errorInfo.title);
        var userMessage = this.get('intl').t(errorInfo.userMessage);
        var compositeMsg = "".concat(title, " ").concat(userMessage);

        if (this.get('lastError') != compositeMsg || nowTime - this.get('lastErrorTime') > 5000) {
          this.set('lastError', compositeMsg);
          this.set('lastErrorTime', nowTime);
          this.get('notification').error(title, "".concat(userMessage));
        }

        this.logger.error("".concat(errorInfo.logMessage));
      },
      logError: function logError(errorInfo) {
        this.logger.error(errorInfo.logMessage);
      }
    }
  });

  _exports.default = _default;
});