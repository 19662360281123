define("web-directory/services/trial", ["exports", "ember-purecloud-components/mixins/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_logger.default, {
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    application: Ember.inject.service(),
    freeTrialInfo: {},
    freeTrialDaysRemaining: 0,
    inActiveFreeTrial: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.loadFreeTrialInfo();
    },
    loadFreeTrialInfo: function loadFreeTrialInfo() {
      var _this = this;

      if (!this.get('session.features.freeTrial')) {
        this.set('inActiveFreeTrial', false);
        this.set('freeTrialDaysRemaining', 0);
        return;
      }

      var url = this.get('application').pcV2Uri('api/v2/billing/signup/freetrial');
      return this.get('ajax').request(url, {
        retry: false
      }).then(function (data) {
        _this.set('freeTrialInfo', data);

        _this.set('inActiveFreeTrial', _this.isTrialActive(data));
      }).catch(function () {
        // This request is allowed to fail if not trial is active
        _this.set('inActiveFreeTrial', false);

        _this.set('freeTrialInfo', {});
      });
    },
    isTrialActive: function isTrialActive(trialInfo) {
      if (this.get('session.features.freeTrial') && trialInfo && trialInfo.daysRemaining && trialInfo.daysRemaining > 0) {
        this.set('freeTrialDaysRemaining', trialInfo.daysRemaining);
        return true;
      }

      return false;
    }
  });

  _exports.default = _default;
});