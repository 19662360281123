define("web-directory/components/new-group/component", ["exports", "web-directory/mixins/create-group"], function (_exports, _createGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      Component = Ember.Component;

  var _default = Component.extend(_createGroup.default, {
    classNames: ['new-group'],
    intl: inject.service(),
    attrs: {
      confirm: function confirm() {},
      cancel: function cancel() {},
      setAttribute: function setAttribute() {}
    },
    setHandlers: Ember.on('init', function () {
      var _this = this;

      var setAttr = this.get('setAttribute');
      setAttr('confirm', this.createGroup.bind(this));
      Ember.run.scheduleOnce('afterRender', this, function () {
        _this.$('.form-control:first').focus();
      });
    }),
    checkNameLength: Ember.observer('groupName', function () {
      var isTooLong = this.get('groupName.length') > 140;

      if (isTooLong) {
        this.attrs.disable(true);
        this.$('input.form-control').tooltip({
          placement: 'bottom',
          title: this.get('intl').lookup('groups.admin.nameLengthTooltip'),
          trigger: 'manual'
        });
        this.$('input.form-control').tooltip('show');
      } else {
        this.attrs.disable(false);
        this.$('input.form-control').tooltip('destroy');
      }

      this.set('nameLengthExceeded', isTooLong);
    })
  });

  _exports.default = _default;
});