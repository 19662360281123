define("web-directory/components/group-profile/roles-edit/fixtable/division-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zzVbMcJD",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"isAssigned\"]]],null,{\"statements\":[[6,[\"power-select-multiple\"],null,[[\"renderInPlace\",\"selected\",\"options\",\"searchField\",\"class\",\"placeholder\",\"onchange\",\"extra\",\"noMatchesMessage\",\"selectedItemComponent\",\"afterOptionsComponent\"],[true,[28,[\"selectedDivisions\"]],[28,[\"divisions\"]],\"name\",\"divisions-power-select\",[33,[\"t\"],[\"groups.roles.selectDivisions\"],null],[33,[\"action\"],[[28,[null]],\"updateDivisions\"],null],[28,[\"extra\"]],[33,[\"t\"],[\"groups.roles.noDivisionsFound\"],null],\"group-profile/roles-edit/fixtable/division-select/selected-division\",\"group-profile/roles-edit/fixtable/division-select/select-footer\"]],{\"statements\":[[6,[\"if\"],[[33,[\"not\"],[[33,[\"eq\"],[[28,[\"division\",\"id\"]],\"*\"],null]],null]],null,{\"statements\":[[0,\"            \"],[11,\"div\",[]],[16,\"class\",[33,[\"if\"],[[28,[\"allFutureDivisionsSelected\"]],\"division-select-option disabled\",\"division-select-option\"],null],null],[13],[0,\"\\n                \"],[11,\"div\",[]],[15,\"class\",\"division-name\"],[13],[1,[28,[\"division\",\"name\"]],false],[14],[0,\"\\n                \"],[11,\"div\",[]],[15,\"class\",\"division-description\"],[13],[1,[28,[\"division\",\"description\"]],false],[14],[0,\"\\n            \"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[\"division\"]},null]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/group-profile/roles-edit/fixtable/division-select/template.hbs"
    }
  });

  _exports.default = _default;
});