define("web-directory/components/acd-interactions-panel/interaction-sms/sms-conversation-pane/component", ["exports", "lodash/uniqBy", "moment"], function (_exports, _uniqBy, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var run = Ember.run,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      Component = Ember.Component,
      inject = Ember.inject;

  var _default = Component.extend({
    classNames: ['sms-conversation-pane'],
    acdsms: inject.service(),
    customerParticipantName: null,
    messages: null,
    acdMessagingPerformance: null,
    sortedMessages: computed('sortedMessagesNonUniq.[]', function () {
      return (0, _uniqBy.default)(this.get('sortedMessagesNonUniq'), 'id');
    }),
    sortedMessagesNonUniq: computed.sort('messages.[]', function (a, b) {
      var aTime = a.localTimestamp || a.timestamp || a.messageTime;
      var bTime = b.localTimestamp || b.timestamp || b.messageTime;
      return (0, _moment.default)(aTime).valueOf() - (0, _moment.default)(bTime).valueOf();
    }),
    agentMessages: computed.filterBy('sortedMessages.[]', 'direction', 'outbound'),
    loadingMessages: false,
    acdNormalizedMessages: false,
    selectCardOption: function selectCardOption(btnResponseMsg) {
      var buttonResponse = Ember.get(btnResponseMsg, 'normalizedMessage.content.firstObject.buttonResponse');
      var msgWithMatchingOption = this.getLastMessage(this.searchThroughCardsCarousels(this.cardsCarousels(btnResponseMsg), buttonResponse));
      this.setOptionOnMessage(msgWithMatchingOption, buttonResponse); // If true, set buttonResponse type to "Card" or "Carousel", else it'll remain as "Button"

      if (msgWithMatchingOption) {
        Ember.set(buttonResponse, 'type', Ember.get(msgWithMatchingOption, 'normalizedMessage.content.firstObject.contentType'));
      }
    },
    cardsCarousels: function cardsCarousels(btnResponseMsg) {
      var messagesArr = this.get('messages');
      var btnResponseMessageTimeStamp = (0, _moment.default)(btnResponseMsg.timestamp);
      return messagesArr.filter(function (msg) {
        var isMsgBeforeResponse = (0, _moment.default)(msg.timestamp).isBefore(btnResponseMessageTimeStamp);
        return isMsgBeforeResponse && (Ember.get(msg, 'normalizedMessage.content.firstObject.contentType') === 'Card' || Ember.get(msg, 'normalizedMessage.content.firstObject.contentType') === 'Carousel');
      });
    },
    searchThroughCardsCarousels: function searchThroughCardsCarousels(cardCarouselArr, buttonResponseContent) {
      return cardCarouselArr.filter(function (cardCarouselObj) {
        var currentObjType = Ember.get(cardCarouselObj, 'normalizedMessage.content.firstObject.contentType');

        if (currentObjType === 'Card') {
          return Ember.get(cardCarouselObj, 'normalizedMessage.content.firstObject.card.actions').some(function (option) {
            if (Ember.get(option, 'text') === Ember.get(buttonResponseContent, 'text') && Ember.get(option, 'payload') === Ember.get(buttonResponseContent, 'payload')) {
              return Ember.get(option, 'payload') === Ember.get(buttonResponseContent, 'payload');
            }
          });
        } else if (currentObjType === 'Carousel') {
          return Ember.get(cardCarouselObj, 'normalizedMessage.content.firstObject.carousel.cards').some(function (card) {
            return Ember.get(card, 'actions').some(function (option) {
              if (Ember.get(option, 'text') === Ember.get(buttonResponseContent, 'text') && Ember.get(option, 'payload') === Ember.get(buttonResponseContent, 'payload')) {
                return Ember.get(option, 'payload') === Ember.get(buttonResponseContent, 'payload');
              }
            });
          });
        }
      });
    },
    getLastMessage: function getLastMessage(cardCarouselArr) {
      return Ember.get(cardCarouselArr, 'lastObject');
    },
    setOptionOnMessage: function setOptionOnMessage(message, buttonResponse) {
      if (message) {
        if (Ember.get(message, 'normalizedMessage.content.firstObject.contentType') === 'Card') {
          return Ember.get(message, 'normalizedMessage.content.firstObject.card.actions').some(function (option) {
            if (Ember.get(option, 'text') === Ember.get(buttonResponse, 'text') && Ember.get(option, 'payload') === Ember.get(buttonResponse, 'payload')) {
              Ember.set(option, 'selected', true);
            }
          });
        } else if (Ember.get(message, 'normalizedMessage.content.firstObject.contentType') === 'Carousel') {
          return Ember.get(message, 'normalizedMessage.content.firstObject.carousel.cards').some(function (card) {
            return Ember.get(card, 'actions').some(function (option) {
              if (Ember.get(option, 'text') === Ember.get(buttonResponse, 'text') && Ember.get(option, 'payload') === Ember.get(buttonResponse, 'payload')) {
                Ember.set(option, 'selected', true);
              }
            });
          });
        }
      }
    },
    actions: {
      scrollToBottom: function scrollToBottom() {
        this.scrollToBottom();
      },
      dismissNewMessage: function dismissNewMessage() {
        this.turnOffNewMessageAnnotation();
      },
      selectCardOption: function selectCardOption() {
        this.selectCardOption.apply(this, arguments);
      }
    },
    isAtBottom: true,
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('acdMessagingPerformance')) {
        if (this.get('interaction')) {
          this.set('messages', this.get('acdsms').getInteractionMessages(this.get('interaction.id')));
        }
      } else {
        this.set('messages', []);
      }

      this.checkIfAtBottom();

      var _checkIfAtBottom = this.checkIfAtBottom.bind(this);

      var elementId = ".".concat(this.get('elementId'));
      run.scheduleOnce('afterRender', function () {
        Ember.$('.sms-conversation-pane').on("scroll".concat(elementId), _checkIfAtBottom);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.turnOffNewMessageAnnotation();
      this.get('acdsms').off('newSmsMessage', this, this.handleNewMessage);
      this.get('acdsms').off('scrollToBottom', this, this._scheduleScrollBottom);
    },
    handleNewMessage: function handleNewMessage(messageData) {
      var newMessage = messageData.message;

      if (messageData.interactionId === this.get('interaction.id')) {
        if (!this.get('acdsms').isMessageAdded(newMessage.id, messageData.interactionId)) {
          if (get(newMessage, 'direction').toLowerCase() === 'inbound') {
            set(newMessage, 'isNew', true);
          } else {
            //if this is an outbound message then we can assume they have read any new messages.
            this.turnOffNewMessageAnnotation();
          }

          this.get('messages').pushObject(newMessage);

          if (!this.get('acdMessagingPerformance')) {
            this._scheduleScrollBottom();
          }
        } else {
          //if message is already added maybe it needs to be updated
          var potentialPendingMsg = this.get('messages').find(function (msg) {
            return msg.id === newMessage.id;
          });

          if (potentialPendingMsg) {
            this.updatePendingMessage(potentialPendingMsg, newMessage);
          }
        }
      }
    },
    updatePendingMessage: function updatePendingMessage(pendingMessage, newMessage) {
      if (Ember.get(newMessage, 'status') === 'removed') {
        if (this.get('acdNormalizedMessages')) {
          if ((Ember.get(pendingMessage, 'normalizedMessage.type') || '').toLowerCase() === 'text' && typeof Ember.get(newMessage, 'normalizedMessage.text') === 'undefined') {
            Ember.set(newMessage, 'normalizedMessage.text', '');
          }
        } else {
          var clearTextBody = (typeof Ember.get(pendingMessage, 'textBody') === 'string' || !Ember.get(pendingMessage, 'textBody')) && typeof Ember.get(newMessage, 'textBody') === 'undefined';

          if (clearTextBody) {
            Ember.set(newMessage, 'textBody', '');
          }
        }
      }

      Ember.setProperties(pendingMessage, newMessage);
      var newMsgStateStatus = newMessage.status === 'queued' && newMessage.messageStatus === 'queued' ? 'queued' : '';

      if (newMsgStateStatus === 'queued') {
        Ember.set(pendingMessage, 'pendingMessage', true);
      } else {
        Ember.set(pendingMessage, 'pendingMessage', false);
      }
    },
    turnOffNewMessageAnnotation: function turnOffNewMessageAnnotation() {
      var messages = this.get('sortedMessages');
      messages.forEach(function (message) {
        set(message, 'isNew', false);
      });

      if (this.get('firstNewMessage')) {
        this.set('firstNewMessage.firstNewMessage', false);
      }
    },
    firstNewMessage: computed('sortedMessages', 'agentMessages', function () {
      var messages = this.get('sortedMessages');
      var lastAgentMessage = this.get('agentMessages.lastObject');
      var messagesAfterLastAgentMsg = lastAgentMessage ? messages.slice(messages.indexOf(lastAgentMessage) + 1) : messages;
      var firstNewMessage = messagesAfterLastAgentMsg.find(function (m) {
        return m.isNew;
      });

      if (firstNewMessage && lastAgentMessage && (0, _moment.default)(firstNewMessage.timestamp).isAfter((0, _moment.default)(lastAgentMessage.timestamp))) {
        set(firstNewMessage, 'firstNewMessage', true);
      }

      return firstNewMessage;
    }),
    _scheduleScrollBottom: function _scheduleScrollBottom() {
      var _this = this;

      run.scheduleOnce('afterRender', function () {
        _this.scrollToBottom();
      });
    },
    scrollToBottom: function scrollToBottom() {
      var element = Ember.$('.sms-conversation-pane');

      if (element && element.length > 0) {
        var scrollHeight = element[0].scrollHeight;
        var height = element.height();
        var newMessageAnnotation = this.$('.new-message-annotation');
        var newScrollPosition;

        if (newMessageAnnotation && newMessageAnnotation.length > 0) {
          newScrollPosition = newMessageAnnotation[0].offsetTop - element.position().top;
        } else {
          newScrollPosition = scrollHeight - height;
        }

        element.scrollTop(newScrollPosition);
      }
    },
    checkIfAtBottom: function checkIfAtBottom() {
      var _this2 = this;

      Ember.run(function () {
        var scrollHeight = _this2.get('element.scrollHeight');

        var height = Ember.$('.sms-conversation-pane').height();

        var scrollTop = _this2.get('element.scrollTop');

        var calculatedPosition = parseInt(scrollHeight - height);
        var diff = scrollTop - calculatedPosition;
        var isAtBottomValue = Math.abs(diff) <= 5;

        if (!_this2.isDestroyed && !_this2.isDestroying) {
          _this2.set('isAtBottom', isAtBottomValue);

          _this2.get('acdsms').trigger('isAtBottom', {
            isAtBottom: isAtBottomValue,
            id: _this2.get('interaction.id')
          });
        }
      });
    },
    setupSessionEvents: Ember.on('didInsertElement', function () {
      this.get('acdsms').on('newSmsMessage', this, this.handleNewMessage);
      this.get('acdsms').on('scrollToBottom', this, this._scheduleScrollBottom);
    })
  });

  _exports.default = _default;
});