define("web-directory/models/app", ["exports", "lodash", "web-directory/utils/apps"], function (_exports, _lodash, _apps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.USE_POPUP_AUTH_URL_REPLACEMENT_TOKEN = _exports.PC_ENV_URL_REPLACEMENT_TOKEN = _exports.PC_CONVERSATION_ID_URL_REPLACEMENT_TOKEN = _exports.NO_SUPPORTED_COMMUNICATION_TYPE_FILTER = _exports.LANG_TAG_URL_REPLACEMENT_TOKEN = _exports.INTERAPPTION_PRIMARY_MEDIA_TYPE_FILTERS = _exports.INTERAPPTION_MESSAGE_SUB_TYPE_FILTERS = _exports.INTERAPPTION_COMMUNICATION_TYPE_FILTERS = _exports.GC_WORKITEM_ID_URL_REPLACEMENT_TOKEN = _exports.GC_USE_POPUP_AUTH_URL_REPLACEMENT_TOKEN = _exports.GC_TARGET_ENV_REPLACEMENT_TOKEN = _exports.GC_LANG_TAG_URL_REPLACEMENT_TOKEN = _exports.GC_INTERACTION_TYPE_URL_REPLACEMENT_TOKEN = _exports.GC_INTERACTION_ID_URL_REPLACEMENT_TOKEN = _exports.GC_HOST_ORIGIN_REPLACEMENT_TOKEN = _exports.GC_CONVERSATION_ID_URL_REPLACEMENT_TOKEN = _exports.FEATURE_CATEGORIES = _exports.DISPLAY_TYPES = _exports.ASSET_CATEGORIES = void 0;

  var _TOKEN_REPLACEMENTS;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Copyable = Ember.Copyable,
      computed = Ember.computed,
      copy = Ember.copy,
      getOwner = Ember.getOwner,
      typeOf = Ember.typeOf,
      isPresent = Ember.isPresent;
  var LIFECYCLE_DEFAULTS = {
    ephemeral: false,
    hooks: {
      bootstrap: false,
      focus: false,
      blur: false,
      stop: false
    }
  };
  var DISPLAY_TYPES = {
    STANDALONE: 'standalone',
    WIDGET: 'widget',
    INTERAPPTION: 'interactionWidget'
  };
  _exports.DISPLAY_TYPES = DISPLAY_TYPES;
  var FEATURE_CATEGORIES = {
    DIRECTORY: 'directory',
    CONTACT_CENTER_INSIGHTS: 'contactCenterInsights'
  };
  _exports.FEATURE_CATEGORIES = FEATURE_CATEGORIES;
  var ASSET_CATEGORIES = ['icon', 'monochromicIcon'];
  _exports.ASSET_CATEGORIES = ASSET_CATEGORIES;
  var URL_REPLACEMENT_PREFIX = '{{';
  var URL_REPLACEMENT_SUFFIX = '}}'; // PC

  var LANG_TAG_URL_REPLACEMENT_VAR_NAME = 'pcLangTag';
  var LANG_TAG_URL_REPLACEMENT_TOKEN = "".concat(URL_REPLACEMENT_PREFIX).concat(LANG_TAG_URL_REPLACEMENT_VAR_NAME).concat(URL_REPLACEMENT_SUFFIX);
  _exports.LANG_TAG_URL_REPLACEMENT_TOKEN = LANG_TAG_URL_REPLACEMENT_TOKEN;
  var PC_ENV_URL_REPLACEMENT_VAR_NAME = 'pcEnvironment';
  var PC_ENV_URL_REPLACEMENT_TOKEN = "".concat(URL_REPLACEMENT_PREFIX).concat(PC_ENV_URL_REPLACEMENT_VAR_NAME).concat(URL_REPLACEMENT_SUFFIX);
  _exports.PC_ENV_URL_REPLACEMENT_TOKEN = PC_ENV_URL_REPLACEMENT_TOKEN;
  var PC_CONVERSATION_ID_URL_REPLACEMENT_VAR_NAME = 'pcConversationId';
  var PC_CONVERSATION_ID_URL_REPLACEMENT_TOKEN = "".concat(URL_REPLACEMENT_PREFIX).concat(PC_CONVERSATION_ID_URL_REPLACEMENT_VAR_NAME).concat(URL_REPLACEMENT_SUFFIX);
  _exports.PC_CONVERSATION_ID_URL_REPLACEMENT_TOKEN = PC_CONVERSATION_ID_URL_REPLACEMENT_TOKEN;
  var USE_POPUP_AUTH_URL_REPLACEMENT_VAR_NAME = 'pcUsePopupAuth';
  var USE_POPUP_AUTH_URL_REPLACEMENT_TOKEN = "".concat(URL_REPLACEMENT_PREFIX).concat(USE_POPUP_AUTH_URL_REPLACEMENT_VAR_NAME).concat(URL_REPLACEMENT_SUFFIX); // GC

  _exports.USE_POPUP_AUTH_URL_REPLACEMENT_TOKEN = USE_POPUP_AUTH_URL_REPLACEMENT_TOKEN;
  var GC_LANG_TAG_URL_REPLACEMENT_VAR_NAME = 'gcLangTag';
  var GC_LANG_TAG_URL_REPLACEMENT_TOKEN = "".concat(URL_REPLACEMENT_PREFIX).concat(GC_LANG_TAG_URL_REPLACEMENT_VAR_NAME).concat(URL_REPLACEMENT_SUFFIX);
  _exports.GC_LANG_TAG_URL_REPLACEMENT_TOKEN = GC_LANG_TAG_URL_REPLACEMENT_TOKEN;
  var GC_INTERACTION_ID_URL_REPLACEMENT_VAR_NAME = 'gcInteractionId';
  var GC_INTERACTION_ID_URL_REPLACEMENT_TOKEN = "".concat(URL_REPLACEMENT_PREFIX).concat(GC_INTERACTION_ID_URL_REPLACEMENT_VAR_NAME).concat(URL_REPLACEMENT_SUFFIX);
  _exports.GC_INTERACTION_ID_URL_REPLACEMENT_TOKEN = GC_INTERACTION_ID_URL_REPLACEMENT_TOKEN;
  var GC_CONVERSATION_ID_URL_REPLACEMENT_VAR_NAME = 'gcConversationId';
  var GC_CONVERSATION_ID_URL_REPLACEMENT_TOKEN = "".concat(URL_REPLACEMENT_PREFIX).concat(GC_CONVERSATION_ID_URL_REPLACEMENT_VAR_NAME).concat(URL_REPLACEMENT_SUFFIX);
  _exports.GC_CONVERSATION_ID_URL_REPLACEMENT_TOKEN = GC_CONVERSATION_ID_URL_REPLACEMENT_TOKEN;
  var GC_WORKITEM_ID_URL_REPLACEMENT_VAR_NAME = 'gcWorkitemId';
  var GC_WORKITEM_ID_URL_REPLACEMENT_TOKEN = "".concat(URL_REPLACEMENT_PREFIX).concat(GC_WORKITEM_ID_URL_REPLACEMENT_VAR_NAME).concat(URL_REPLACEMENT_SUFFIX);
  _exports.GC_WORKITEM_ID_URL_REPLACEMENT_TOKEN = GC_WORKITEM_ID_URL_REPLACEMENT_TOKEN;
  var GC_INTERACTION_TYPE_URL_REPLACEMENT_VAR_NAME = 'gcInteractionType';
  var GC_INTERACTION_TYPE_URL_REPLACEMENT_TOKEN = "".concat(URL_REPLACEMENT_PREFIX).concat(GC_INTERACTION_TYPE_URL_REPLACEMENT_VAR_NAME).concat(URL_REPLACEMENT_SUFFIX);
  _exports.GC_INTERACTION_TYPE_URL_REPLACEMENT_TOKEN = GC_INTERACTION_TYPE_URL_REPLACEMENT_TOKEN;
  var GC_USE_POPUP_AUTH_URL_REPLACEMENT_VAR_NAME = 'gcUsePopupAuth';
  var GC_USE_POPUP_AUTH_URL_REPLACEMENT_TOKEN = "".concat(URL_REPLACEMENT_PREFIX).concat(GC_USE_POPUP_AUTH_URL_REPLACEMENT_VAR_NAME).concat(URL_REPLACEMENT_SUFFIX);
  _exports.GC_USE_POPUP_AUTH_URL_REPLACEMENT_TOKEN = GC_USE_POPUP_AUTH_URL_REPLACEMENT_TOKEN;
  var GC_HOST_ORIGIN_REPLACEMENT_VAR_NAME = 'gcHostOrigin';
  var GC_HOST_ORIGIN_REPLACEMENT_TOKEN = "".concat(URL_REPLACEMENT_PREFIX).concat(GC_HOST_ORIGIN_REPLACEMENT_VAR_NAME).concat(URL_REPLACEMENT_SUFFIX);
  _exports.GC_HOST_ORIGIN_REPLACEMENT_TOKEN = GC_HOST_ORIGIN_REPLACEMENT_TOKEN;
  var GC_TARGET_ENV_REPLACEMENT_VAR_NAME = 'gcTargetEnv';
  var GC_TARGET_ENV_REPLACEMENT_TOKEN = "".concat(URL_REPLACEMENT_PREFIX).concat(GC_TARGET_ENV_REPLACEMENT_VAR_NAME).concat(URL_REPLACEMENT_SUFFIX);
  _exports.GC_TARGET_ENV_REPLACEMENT_TOKEN = GC_TARGET_ENV_REPLACEMENT_TOKEN;
  var TOKEN_REPLACEMENTS = (_TOKEN_REPLACEMENTS = {}, _defineProperty(_TOKEN_REPLACEMENTS, LANG_TAG_URL_REPLACEMENT_TOKEN, {
    queryParamName: LANG_TAG_URL_REPLACEMENT_VAR_NAME,
    replacementProp: 'langTag'
  }), _defineProperty(_TOKEN_REPLACEMENTS, PC_ENV_URL_REPLACEMENT_TOKEN, {
    queryParamName: PC_ENV_URL_REPLACEMENT_VAR_NAME,
    replacementProp: 'envTld'
  }), _defineProperty(_TOKEN_REPLACEMENTS, PC_CONVERSATION_ID_URL_REPLACEMENT_TOKEN, {
    queryParamName: PC_CONVERSATION_ID_URL_REPLACEMENT_VAR_NAME,
    replacementProp: 'interactionId'
  }), _defineProperty(_TOKEN_REPLACEMENTS, USE_POPUP_AUTH_URL_REPLACEMENT_TOKEN, {
    queryParamName: USE_POPUP_AUTH_URL_REPLACEMENT_VAR_NAME,
    replacementProp: 'usePopupAuth'
  }), _defineProperty(_TOKEN_REPLACEMENTS, GC_LANG_TAG_URL_REPLACEMENT_TOKEN, {
    queryParamName: GC_LANG_TAG_URL_REPLACEMENT_VAR_NAME,
    replacementProp: 'langTag'
  }), _defineProperty(_TOKEN_REPLACEMENTS, GC_INTERACTION_ID_URL_REPLACEMENT_TOKEN, {
    queryParamName: GC_INTERACTION_ID_URL_REPLACEMENT_VAR_NAME,
    replacementProp: 'interactionId'
  }), _defineProperty(_TOKEN_REPLACEMENTS, GC_CONVERSATION_ID_URL_REPLACEMENT_TOKEN, {
    queryParamName: GC_CONVERSATION_ID_URL_REPLACEMENT_VAR_NAME,
    replacementValue: function replacementValue(interactionId, interactionType) {
      return interactionType === 'conversation' ? interactionId : '';
    }
  }), _defineProperty(_TOKEN_REPLACEMENTS, GC_WORKITEM_ID_URL_REPLACEMENT_TOKEN, {
    queryParamName: GC_WORKITEM_ID_URL_REPLACEMENT_VAR_NAME,
    replacementValue: function replacementValue(interactionId, interactionType) {
      return interactionType === 'workitem' ? interactionId : '';
    }
  }), _defineProperty(_TOKEN_REPLACEMENTS, GC_INTERACTION_TYPE_URL_REPLACEMENT_TOKEN, {
    queryParamName: GC_INTERACTION_TYPE_URL_REPLACEMENT_VAR_NAME,
    replacementProp: 'interactionType'
  }), _defineProperty(_TOKEN_REPLACEMENTS, GC_USE_POPUP_AUTH_URL_REPLACEMENT_TOKEN, {
    queryParamName: GC_USE_POPUP_AUTH_URL_REPLACEMENT_VAR_NAME,
    replacementProp: 'usePopupAuth'
  }), _defineProperty(_TOKEN_REPLACEMENTS, GC_HOST_ORIGIN_REPLACEMENT_TOKEN, {
    queryParamName: GC_HOST_ORIGIN_REPLACEMENT_VAR_NAME,
    replacementProp: 'gcHostOrigin'
  }), _defineProperty(_TOKEN_REPLACEMENTS, GC_TARGET_ENV_REPLACEMENT_TOKEN, {
    queryParamName: GC_TARGET_ENV_REPLACEMENT_VAR_NAME,
    replacementProp: 'gcTargetEnv'
  }), _TOKEN_REPLACEMENTS);
  var URL_REPLACEMENTS = Object.entries(TOKEN_REPLACEMENTS).map(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        replacementToken = _ref2[0],
        _ref2$ = _ref2[1],
        queryParamName = _ref2$.queryParamName,
        replacementProp = _ref2$.replacementProp,
        replacementValue = _ref2$.replacementValue;

    return {
      regExp: new RegExp(replacementToken, 'g'),
      queryParamName: queryParamName,
      replacementProp: replacementProp,
      replacementValue: replacementValue
    };
  });
  var INTERAPPTION_PRIMARY_MEDIA_TYPE_FILTERS = ['chat', 'call', 'callback', 'email'];
  _exports.INTERAPPTION_PRIMARY_MEDIA_TYPE_FILTERS = INTERAPPTION_PRIMARY_MEDIA_TYPE_FILTERS;
  var INTERAPPTION_MESSAGE_SUB_TYPE_FILTERS = ['sms', 'line', 'facebook', 'open', 'instagram', 'twitter', 'webmessaging', 'whatsapp'];
  _exports.INTERAPPTION_MESSAGE_SUB_TYPE_FILTERS = INTERAPPTION_MESSAGE_SUB_TYPE_FILTERS;
  var INTERAPPTION_COMMUNICATION_TYPE_FILTERS = [].concat(INTERAPPTION_PRIMARY_MEDIA_TYPE_FILTERS, INTERAPPTION_MESSAGE_SUB_TYPE_FILTERS);
  _exports.INTERAPPTION_COMMUNICATION_TYPE_FILTERS = INTERAPPTION_COMMUNICATION_TYPE_FILTERS;
  var NO_SUPPORTED_COMMUNICATION_TYPE_FILTER = 'none';
  _exports.NO_SUPPORTED_COMMUNICATION_TYPE_FILTER = NO_SUPPORTED_COMMUNICATION_TYPE_FILTER;
  var App = Ember.Object.extend(Copyable, {
    typeId: null,
    id: null,
    name: null,
    urlTemplate: null,
    langTag: null,
    envTld: null,
    usePopupAuth: false,
    displayType: null,
    // The type of app which dictates how an app behaves, where an app is displayed, etc. - standalone, widget, etc.
    featureCategory: null,
    // The category of an app's behavior for grouping purposes - directory, performance, help, etc.
    permissionsPolicy: null,
    // sandbox, assets, lifecycle, permissionsPolicy - set in init
    // Interapption specific properties
    interactionId: null,
    interactionType: null,

    /**
     * When not empty (>= 1 entry), will restrict the interapption to the specified queues
     */
    enabledQueueIds: null,

    /**
     * When not empty (>= 1 entry), will restrict the interapption to the specified communication types.
     * Currently, this only includes the initial communication type (it does not include elevations).
     * When it includes 'none', it will not display the interapption for any communication type.
     */
    enabledCommunicationTypes: null,
    enableWorkitem: null,
    gcHostOrigin: null,
    gcTargetEnv: null,
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('sandbox')) {
        this.set('sandbox', []);
      }

      if (!this.get('assets')) {
        this.set('assets', {});
      }

      if (!this.get('permissionsPolicy')) {
        this.set('permissionsPolicy', []);
      }

      this.set('lifecycle', _lodash.default.merge(copy(LIFECYCLE_DEFAULTS, true), this.get('lifecycle')));
    },
    url: computed('urlTemplate', 'langTag', 'envTld', 'interactionId', 'usePopupAuth', 'gcHostOrigin', 'gcTargetEnv', {
      get: function get() {
        var _this = this;

        var result = this.get('urlTemplate');

        if (typeOf(result) === 'string' && isPresent(result)) {
          URL_REPLACEMENTS.forEach(function (currReplacement) {
            var replacementValue = '';

            if (currReplacement.replacementValue) {
              if (_this.get('interactionId') && _this.get('interactionType')) replacementValue = currReplacement.replacementValue(_this.get('interactionId'), _this.get('interactionType'));
            } else {
              var rawValue = _this.get(currReplacement.replacementProp);

              replacementValue = isPresent(rawValue) ? rawValue : '';
            }

            if (encodeURIComponent) {
              replacementValue = encodeURIComponent(replacementValue);
              result = result.replace(currReplacement.regExp, replacementValue);
            }
          });
        } else {
          result = '';
        }

        return result;
      },
      set: function set() {
        Ember.Logger.error(_apps.default.buildLogMsg('Set called on derived url property instead of new urlTemplate property.'), {
          urlTemplate: this.get('urlTemplate'),
          remoteData: {
            appId: this.get('id')
          }
        });
        throw new Error('Cannot directly set url.  Use urlTemplate property.');
      }
    }),
    getURLTemplateQueryParams: function getURLTemplateQueryParams() {
      var result = [];
      var urlTemplate = this.get('urlTemplate');

      if (typeOf(urlTemplate) === 'string' && isPresent(urlTemplate)) {
        URL_REPLACEMENTS.forEach(function (currReplacement) {
          if (urlTemplate.search(currReplacement.regExp) !== -1) {
            result.push(currReplacement.queryParamName);
          }
        });
      }

      return result;
    },
    copy: function copy(deep) {
      var owner = getOwner(this);
      var ownerMixin = {};

      if (owner) {
        ownerMixin = owner.ownerInjection();
      }

      return App.create(ownerMixin, {
        typeId: this.get('typeId'),
        id: this.get('id'),
        name: this.get('name'),
        urlTemplate: this.get('urlTemplate'),
        langTag: this.get('langTag'),
        envTld: this.get('envTld'),
        usePopupAuth: this.get('usePopupAuth'),
        displayType: this.get('displayType'),
        featureCategory: this.get('featureCategory'),
        sandbox: deep ? _lodash.default.cloneDeep(this.get('sandbox')) : this.get('sandbox'),
        assets: deep ? _lodash.default.cloneDeep(this.get('assets')) : this.get('assets'),
        lifecycle: deep ? _lodash.default.cloneDeep(this.get('lifecycle')) : this.get('lifecycle'),
        permissionsPolicy: deep ? _lodash.default.cloneDeep(this.get('permissionsPolicy')) : this.get('permissionsPolicy'),
        // interapption props
        interactionId: this.get('interactionId'),
        interactionType: this.get('interactionType'),
        enabledQueueIds: deep ? _lodash.default.cloneDeep(this.get('enabledQueueIds')) : this.get('enabledQueueIds'),
        enabledCommunicationTypes: deep ? _lodash.default.cloneDeep(this.get('enabledCommunicationTypes')) : this.get('enabledCommunicationTypes'),
        enableWorkitem: this.get('enableWorkitem'),
        gcHostOrigin: this.get('gcHostOrigin'),
        gcTargetEnv: this.get('gcTargetEnv')
      });
    }
  });
  var _default = App;
  _exports.default = _default;
});