define("web-directory/components/target-dropdown/thumbnail/component", ["exports", "web-directory/mixins/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend(_logger.default, {
    classNames: ['conversation-thumbnail'],

    /**
     * The participant whose thumbnail should be shown
     */
    participant: null,

    /**
     * @optional
     * The name of the icon to display in an empty thumbnail
     */
    iconName: null,

    /**
     * @optional
     * The text to display in the thumbnail
     */
    text: null,

    /**
     * The color of the indicator to show in the bottom right of the thumbnail.
     * Typically used to show a users current presence
     */
    showIndicator: false,

    /**
     * Returns a formatted thumbnail style to apply to the thumbnail if applicable
     */
    thumbnailStyle: Ember.computed('participant.images', function () {
      var profiles = this.get('participant.images.profile'); // if no profiles to take images from then return

      if (!profiles) {
        return;
      }

      var images = [];

      for (var _i = 0, _Object$entries = Object.entries(profiles[0].ref); _i < _Object$entries.length; _i++) {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
            resolution = _Object$entries$_i[0],
            uri = _Object$entries$_i[1];

        images.push({
          uri: uri,
          resolution: resolution
        });
      }
      /**
       * Find the smallest resolution since these are small views.
       * Todo: make the resolution configurable on the component for larger thumbnails if needed
       */


      var img = images.reduce(function (a, b) {
        var _a$resolution, _b$resolution;

        var aResolution = (_a$resolution = a.resolution) === null || _a$resolution === void 0 ? void 0 : _a$resolution.replace('x', '');
        var bResolution = (_b$resolution = b.resolution) === null || _b$resolution === void 0 ? void 0 : _b$resolution.replace('x', '');

        if (aResolution && bResolution) {
          if (Number.isNaN(+aResolution)) {
            return b;
          } else if (Number.isNaN(+bResolution)) {
            return a;
          }

          return +aResolution <= +bResolution ? a : b;
        }

        return !!a.uri ? a : b;
      }, {});
      return img !== null && img !== void 0 && img.uri ? "background-image: url(".concat(img.uri, ")") : null;
    })
  });

  _exports.default = _default;
});