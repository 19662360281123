define("web-directory/serializers/voicemail-message", ["exports", "web-directory/serializers/purecloud"], function (_exports, _purecloud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _purecloud.default.extend({
    extractMeta: function extractMeta(store, typeClass, payload) {
      var meta = payload.meta;
      delete payload.meta;
      return meta;
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      if (requestType === 'query') {
        payload.entities = payload.results || [];
        delete payload.results;
      }

      return this._super.apply(this, arguments);
    },
    serializeIntoHash: function serializeIntoHash(hash, type) {
      this._super.apply(this, arguments);

      var key = this.payloadKeyFromModelName(type.modelName);
      Object.assign(hash, hash[key]);
      delete hash[key];
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      var changed = snapshot.changedAttributes();

      if (snapshot.record.get('isNew') || changed[key]) {
        this._super(snapshot, json, key, attributes);
      }
    }
  });

  _exports.default = _default;
});