define("web-directory/components/group-profile/dynamic-rules/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      Component = Ember.Component,
      inject = Ember.inject;

  var _default = Component.extend({
    classNames: ['group-acd-expressions'],
    application: inject.service(),
    ajax: inject.service(),
    intl: inject.service(),
    logger: inject.service('stash-logger'),
    notification: inject.service(),
    group: null,
    showSaveModal: false,
    showCancelModal: false,
    loading: false,
    previewValue: null,
    expressionDisplay: computed('group.expression.@each.{display,opAnd}', function () {
      var _this = this;

      var conditions = this.get('group.expression');
      var display = '';
      var valid = true;

      if (!conditions || conditions.length === 0) {
        return this.get('intl').t('groups.expressions.empty');
      }

      conditions.forEach(function (con) {
        if (con.display === null || con.display === undefined) {
          valid = false;
          return;
        }

        display += con.display;

        if (!con.lastCondition) {
          if (!con.opAnd) {
            display += " ".concat(_this.get('intl').t('groups.expressions.or'), " ");
          } else {
            //last rule
            display += " ".concat(_this.get('intl').t('groups.expressions.and'), " ");
          }
        }
      });
      var empty = display.replace(/[()]/g, '').trim().length === 0;

      if (empty) {
        display = this.get('intl').t('groups.expressions.empty');
      } else if (!valid) {
        display = this.get('intl').t('groups.expressions.invalid');
      }

      return display;
    }),
    saveDisabled: computed('group.expression.@each.display', 'loading', function () {
      var invalid = this.get('group.expression').filterBy('display', null).length > 0;
      var loading = this.get('loading');
      return invalid && !loading;
    }),
    maximumExpressions: computed('group.expression.length', 'group.expression.@each.length', function () {
      //we have a limit of 3 skills total
      var skills = this.get('group.expression').mapBy('rules');
      return _lodash.default.flatten(skills).length >= 3;
    }),
    numberPreview: computed('previewValue', function () {
      return isNaN(this.get('previewValue')) ? this.get('intl').t('purecloud.unknown') : this.get('previewValue');
    }),
    actions: {
      selectTab: function selectTab(tab) {
        this.selectTab(tab);
      },
      addCondition: function addCondition() {
        if (!this.get('maximumExpressions')) {
          if (this.get('group.expression') && this.get('group.expression').length > 0) {
            var exp = this.get('group.expression');
            exp.forEach(function (condition) {
              Ember.set(condition, 'lastCondition', false);

              if (condition.operation === null) {
                Ember.set(condition, 'operation', 'AND');
                Ember.set(condition, 'opAnd', true);
              }
            });
            exp.pushObject({
              lastCondition: true,
              operation: 'OR',
              opAnd: false,
              display: null,
              rules: [{
                skill: null,
                comparator: null,
                proficiency: null,
                operation: 'OR',
                lastRule: true,
                opAnd: false
              }]
            });
            Ember.set(exp, 'lastObject.lastCondition', true);
          } else {
            this.set('group.expression', [{
              lastCondition: true,
              operation: 'OR',
              opAnd: false,
              display: null,
              rules: [{
                skill: null,
                comparator: null,
                proficiency: null,
                operation: 'OR',
                lastRule: true,
                opAnd: false
              }]
            }]);
          }
        } else {
          this.get('notification').error(this.get('intl').t('groups.expressions.maxConditionsTitle'), this.get('intl').t('groups.expressions.maxConditionsMessage'));
        }
      },
      removeCondition: function removeCondition(index) {
        var exp = this.get('group.expression');

        if (exp.length === 1) {
          this.set('group.expression', []);
        } else {
          Ember.A(exp).removeAt(index);
          exp.forEach(function (condition) {
            Ember.set(condition, 'lastCondition', false);
          });
          exp.set('lastObject.lastCondition', true);
          exp.set('lastObject.operation', 'OR');
          exp.set('lastObject.opAnd', false);
          this.set('group.expression', exp);
        }
      },
      conditionOperation: function conditionOperation(condition, op) {
        Ember.set(condition, 'operation', op);
        Ember.set(condition, 'opAnd', op == 'AND');
      },
      save: function save() {
        var _this2 = this;

        // use new endpoints to save our changed inclusions
        this.set('loading', true);
        var saveData = this.prepareExpression(this.get('group.expression'));
        var success = true;
        var payload = {
          data: {
            skillConditions: saveData
          }
        };
        var groupId = this.get('group.guid');
        var path = "api/v2/groups/".concat(groupId, "/dynamicsettings");
        var url = this.get('application').pcV2Uri(path);
        this.get('ajax').put(url, payload).then(function () {
          _this2.get('group').reload();
        }).catch(function (error) {
          success = false;

          _this2.get('notification').error(_this2.get('intl').t('groups.expressions.saveErrorTitle'), _this2.get('intl').t('groups.expressions.saveErrorMessage'));

          _this2.get('logger').error('Error trying to save dynamic expression to group', {
            error: error
          });

          _this2.set('loading', false);
        }).finally(function () {
          if (success) {
            _this2.set('group.lastSavedExpression', saveData); // use the preview endpoint to show the new expected number of group members


            _this2.get('notification').info(_this2.get('intl').t('groups.expressions.saveTitle'), _this2.get('intl').t('groups.expressions.saveMessage'));

            var previewPath = "api/v2/groups/dynamicsettings/preview";

            var previewUrl = _this2.get('application').pcV2Uri(previewPath);

            _this2.get('ajax').post(previewUrl, payload).then(function (preview) {
              _this2.set('previewValue', preview.userCount);
            }).catch(function (e) {
              _this2.get('logger').error('Error trying to get preview for dynamic group', {
                e: e
              });
            }).finally(function () {
              _this2.set('loading', false);

              _this2.set('showSaveModal', true);
            });
          }
        });
      },
      openCancelModal: function openCancelModal() {
        this.set('showCancelModal', true);
      },
      closeCancelModal: function closeCancelModal() {
        this.set('showCancelModal', false);
      },
      cancel: function cancel() {
        this.set('showCancelModal', false);
        var group = this.get('group');
        group.reload();

        if (this.get('group.lastSavedExpression')) {
          group.set('expression', group.parseExpression(group.get('lastSavedExpression')));
        } else {
          group.set('expression', group.parseExpression(group.get('_expression.content.skillConditions')));
        }

        this.selectTab('members');
      },
      closeSaveModal: function closeSaveModal() {
        this.set('previewValue', null);
        this.set('showSaveModal', false);
        this.selectTab('members');
      }
    },
    prepareExpression: function prepareExpression(input) {
      var output = [];
      input.forEach(function (con) {
        var save = {
          routingSkillConditions: [],
          languageSkillConditions: []
        };
        var parent = null;
        con.rules.forEach(function (rule, index) {
          var skillPropName = rule.skill.type == 'routing' ? 'routingSkillConditions' : 'languageSkillConditions';
          var ruleType = rule.skill.type == 'routing' ? 'routingSkill' : 'languageSkill';

          if (index === 0) {
            // we're making this rule the current parent skill
            var newObj = {
              comparator: rule.comparator.value,
              proficiency: rule.proficiency.value,
              childConditions: {
                operation: rule.operation,
                routingSkillConditions: [],
                languageSkillConditions: []
              }
            };
            newObj[ruleType] = rule.skill.name;
            parent = newObj;
            save[skillPropName].pushObject(newObj);
            save.operation = con.operation;

            if (input.length === 1) {
              save.operation = rule.operation;
            }
          } else {
            // we're adding this skill as a childCondition
            var _newObj = {
              comparator: rule.comparator.value,
              proficiency: rule.proficiency.value,
              childConditions: {
                operation: rule.operation,
                routingSkillConditions: [],
                languageSkillConditions: []
              }
            };
            _newObj[ruleType] = rule.skill.name;
            parent.childConditions[skillPropName].pushObject(_newObj);
            parent = _newObj;
          }
        });
        output.pushObject(save);
      });
      return output;
    }
  });

  _exports.default = _default;
});