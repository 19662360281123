define("web-directory/instance-initializers/ember-intl", ["exports", "web-directory/config/environment", "ember-intl/utils/links"], function (_exports, _environment, _links) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.instanceInitializer = instanceInitializer;
  var warn = Ember.warn;

  function filterBy(type) {
    return Object.keys(requirejs._eak_seen).filter(function (key) {
      return key.indexOf("".concat(_environment.default.modulePrefix, "/").concat(type, "/")) === 0;
    });
  }

  function instanceInitializer(instance) {
    var service;

    if (typeof instance.lookup === 'function') {
      service = instance.lookup('service:intl');
    } else {
      service = instance.container.lookup('service:intl');
    }

    if (typeof Intl === 'undefined') {
      warn("[ember-intl] Intl API is unavailable in this environment.\nSee: ".concat(_links.default.polyfill), false, {
        id: 'ember-intl-undefined-intljs'
      });
    }

    var cldrs = filterBy('cldrs');

    if (!cldrs.length) {
      warn('[ember-intl] project is missing CLDR data\nIf you are asynchronously loading translation, see: ${links.asyncTranslations}.', false, {
        id: 'ember-intl-missing-cldr-data'
      });
    } else {
      cldrs.map(function (moduleName) {
        return requirejs(moduleName, null, null, true)['default'];
      }).forEach(function (locale) {
        return locale.forEach(service.addLocaleData);
      });
    }

    filterBy('translations').forEach(function (moduleName) {
      var localeSplit = moduleName.split('\/');
      var localeName = localeSplit[localeSplit.length - 1];
      service.addTranslations(localeName, requirejs(moduleName, null, null, true)['default']);
    });
  }

  var _default = {
    name: 'ember-intl',
    initialize: instanceInitializer
  };
  _exports.default = _default;
});