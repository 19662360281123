define("web-directory/instance-initializers/set-application-values", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'set-application-values',
    initialize: function initialize(instance) {
      var application = instance.lookup('application:main');
      application.set('instance', instance);
      application.set('ajax', instance.lookup('ajax:application'));
      application.set('permissions', instance.lookup('permissions:application')); // Probably shouldn't access these this way...might be one of those separation of concerns problems

      application.set('store', instance.lookup('service:store'));
      application.set('router', instance.lookup('router:main'));
    }
  };
  _exports.default = _default;
});