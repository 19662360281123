define("web-directory/utils/safeSet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  _exports.isSafe = isSafe;

  function _default(object, property, value) {
    var set = Ember.set;

    if (property && isSafe(object)) {
      return set(object, property, value);
    }
  }

  function isSafe(object) {
    var get = Ember.get;
    return object && !(get(object, 'isDestroyed') || get(object, 'isDestroying'));
  }
});