define("web-directory/components/pc-style-icon-example", ["exports", "ember-purecloud-style/components/pc-style-icon-example"], function (_exports, _pcStyleIconExample) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _pcStyleIconExample.default;
    }
  });
});