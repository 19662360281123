define("web-directory/components/entity-search/component", ["exports", "ember-data", "lodash", "web-directory/mixins/search", "ember-inflector"], function (_exports, _emberData, _lodash, _search, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UP = 40;
  var DOWN = 38;
  var ESCAPE = 27;
  var ENTER = 13;
  var computed = Ember.computed,
      Component = Ember.Component;

  var _default = Component.extend(_search.ThenableSearchMixin, {
    classNames: ['entity-search'],
    currentSelected: -1,
    intl: Ember.inject.service(),
    search: Ember.inject.service(),
    select: '',
    type: 'all',
    filterFunction: null,
    searchQuery: '',
    userInput: null,
    searchLimit: 10,
    debouncedScheduleQueryUpdate: null,
    relationship: null,
    disabled: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.ensurePluralType();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.attachClickListener();
      this.debouncedScheduleQueryUpdate = _lodash.default.debounce(this._scheduleSearchQueryUpdate, 250, {
        maxWait: 500
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      Ember.$(document).off(".".concat(this.get('elementId')));
    },
    keyDown: function keyDown(event) {
      switch (event.keyCode) {
        case UP:
          return this.selectNext();

        case DOWN:
          return this.selectPrevious();

        case ESCAPE:
          return this.cancelInput();

        case ENTER:
          if (this.get('wrappedSearchResults')) {
            return this.chooseSelected();
          }

          break;

        default:
          this.debouncedScheduleQueryUpdate();
          this.set('currentSelected', -1);
          break;
      }
    },
    actions: {
      setUserInput: function setUserInput(event) {
        Ember.run.debounce(this, this._updateSearchQuery, event.target.value, 250);
      },
      submit: function submit() {
        this.notifyPropertyChange('searchQuery');
      },
      selectResult: function selectResult(entity) {
        this.set('searchQuery', '');
        this.set('userInput', '');
        this.sendAction('select', entity);
      },
      clearSearch: function clearSearch() {
        this.set('searchQuery', '');
        this.set('userInput', '');
        return this.set('currentSelected', -1);
      }
    },
    entityFilter: computed.reads('type'),
    results: computed('searchQuery', function () {
      var _this = this;

      if (!this.get('isEmpty')) {
        var search = this.get('search');
        var entities = this.get('type').split(',');
        return _emberData.default.PromiseArray.create({
          promise: search.performSearch(this.get('searchQuery'), 0, entities).then(function (_ref) {
            var results = _ref.results;
            return _this._buildResults(results);
          })
        });
      }

      return Ember.RSVP.resolve(Ember.A([]));
    }),
    wrappedSearchResults: Ember.computed('results', function () {
      var searchResults = this.get('results');
      return _emberData.default.PromiseArray.create({
        promise: searchResults.then(function (searchResults) {
          return _lodash.default.map(searchResults, function (entity) {
            Ember.set(entity, 'selected', false);
            return entity;
          });
        })
      });
    }),
    loading: computed('wrappedSearchResults.isFulfilled', function () {
      return !this.get('wrappedSearchResults.isFulfilled');
    }),
    haveResults: computed('results.[]', function () {
      return !_lodash.default.isEmpty(this.get('results'));
    }),
    placeholder: computed('type', function () {
      var map = {
        all: 'components.entitySearch.search',
        people: 'components.personSearch.search',
        groups: 'components.groupSearch.search',
        locations: 'components.locationSearch.search'
      };
      var key = map[this.get('type')];

      if (key) {
        return this.get('intl').lookup(key).toString();
      }

      return '';
    }),
    isEmpty: computed('searchQuery', function () {
      return _lodash.default.isEmpty(this.get('searchQuery'));
    }),
    noResultsKey: computed('type', function () {
      if (this.get('type') !== 'locations') {
        return 'components.entitySearch.noResults';
      }

      return 'components.entitySearch.noResultsLocation';
    }),
    ensurePluralType: function ensurePluralType() {
      var type = this.get('type');

      if (!_lodash.default.isEmpty(type) && type !== 'all') {
        var plural = (0, _emberInflector.pluralize)(type);
        this.set('type', plural);
      }
    },
    attachClickListener: function attachClickListener() {
      var _this2 = this;

      Ember.$(document).on("click.".concat(this.get('elementId')), function (event) {
        Ember.run(function () {
          var target = Ember.$(event.target);

          if (!target.parents('.entity-search').length) {
            _this2.set('searchResults', []);
          }
        });
      });
    },
    selectNext: function selectNext() {
      var entities = this.get('wrappedSearchResults.content');
      var nowSelected = this.get('currentSelected');
      var nextSelected = nowSelected + 1;

      if (nowSelected + 1 < entities.length) {
        if (entities[nowSelected]) {
          entities[nowSelected].set('selected', false);
        }

        if (entities[nextSelected]) {
          entities[nextSelected].set('selected', true);
        }

        this.set('currentSelected', nextSelected);
        this.scrolling(true);
      }
    },
    selectPrevious: function selectPrevious() {
      var entities = this.get('wrappedSearchResults');
      var nowSelected = this.get('currentSelected');
      var previousSelected = nowSelected + 1;

      if (nowSelected - 1 >= 0) {
        if (entities[nowSelected]) {
          entities[nowSelected].set('selected', false);
        }

        if (entities[previousSelected]) {
          entities[previousSelected].set('selected', true);
        }

        this.set('currentSelected', previousSelected);
        this.scrolling(false);
      }
    },
    scrolling: function scrolling(next) {
      var areaScroll = this.$('.scrollable-area').scrollTop();
      var entityHeight = this.$('.scroll-entity').height();

      if (next) {
        if (this.currentSelected !== 0) {
          this.$('.scrollable-area').scrollTop(areaScroll + entityHeight);
        }
      } else {
        this.$('.scrollable-area').scrollTop(areaScroll - entityHeight);
      }
    },
    clearInputs: function clearInputs() {
      this.set('searchQuery', null);
      this.set('currentSelected', -1);
    },
    cancelInput: function cancelInput() {
      if (this.get('searchQuery')) {
        this.clearInputs();
      } else {
        this.sendAction('cancel');
        this.clearInputs();
      }
    },
    chooseSelected: function chooseSelected() {
      var entities = this.get('wrappedSearchResults.content');
      var chosen = entities[this.currentSelected];

      if (entities.length === 1) {
        chosen = entities[0];
      }

      if (chosen && !chosen.disabled) {
        this.send('selectResult', chosen);
      }
    },
    _buildResults: function _buildResults(results) {
      var filterFunction = this.get('filterFunction');

      _lodash.default.each(results, function (result) {
        if (result.selected) {
          Ember.set(result, 'selected', false);
        }
      });

      if (results && filterFunction) {
        return filterFunction(results);
      }

      return results;
    },
    _scheduleSearchQueryUpdate: function _scheduleSearchQueryUpdate() {
      var _this3 = this;

      Ember.run.join(function () {
        _this3._updateSearchQuery();
      });
    },
    _updateSearchQuery: function _updateSearchQuery(value) {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      value = value || this.get('userInput');
      this.set('searchQuery', value);
    }
  });

  _exports.default = _default;
});