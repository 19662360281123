define("web-directory/adapters/public-api-user", ["exports", "web-directory/adapters/purecloud"], function (_exports, _purecloud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = _purecloud.default.extend({
    session: inject.service(),
    permissions: inject.service(),
    application: inject.service(),
    host: computed.reads('application.purecloudV2BaseUri'),
    namespace: 'api/v2',
    buildQuery: function buildQuery(snapshot) {
      var query = {
        state: 'deleted'
      };

      if (snapshot.adapterOptions && snapshot.adapterOptions.expand) {
        query.expand = snapshot.adapterOptions.expand;
      }

      return query;
    },
    urlForQuery: function urlForQuery() {
      if (this.get('permissions.canTargetUserSearchOutsideOfDivision')) {
        return "".concat(this.get('host'), "/").concat(this.namespace, "/users/search/conversation/target");
      } else {
        return "".concat(this.get('host'), "/").concat(this.namespace, "/users/search");
      }
    },
    findBelongsTo: function findBelongsTo(store, snapshot, link, relationship) {
      // We only support person belongsTo relationship for profiles
      var adapter = this.store.adapterFor(relationship.type);
      return adapter.findRecord(store, store.modelFor('person'), snapshot.id, snapshot);
    },
    buildURL: function buildURL(modelName, id, modelClass, requestType, snapshotOrQuery) {
      if (requestType === 'query') {
        return this.urlForQuery(id, 'public-api-user', snapshotOrQuery);
      }

      var host = this.get('host') ? this.get('host') : '';
      return "".concat(host, "/").concat(this.namespace, "/").concat(this.pathForType('users'), "/").concat(id);
    },

    /**
     * Overridden to support our custom seach API
     * This is copied directly from the ember-data codebase:
     * https://github.com/emberjs/data/blob/v2.16.0/addon/adapters/rest.js#L541
     */
    query: function query(store, type, _query) {
      var url = this.buildURL(type.modelName, null, null, 'query', _query);
      return this.ajax(url, 'POST', {
        data: _query
      });
    }
  });

  _exports.default = _default;
});