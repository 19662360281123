define("web-directory/components/dial-pad-number/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WwyLnrZN",
    "block": "{\"statements\":[[11,\"span\",[]],[15,\"class\",\"flex flex-col\"],[13],[0,\"\\n    \"],[11,\"span\",[]],[16,\"class\",[34,[\"number \",[26,[\"class\"]]]]],[13],[0,\"\\n        \"],[1,[26,[\"number\"]],false],[0,\"\\n    \"],[14],[0,\"\\n\\n    \"],[11,\"span\",[]],[15,\"class\",\"letters\"],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"letters\"]]],null,{\"statements\":[[0,\"            \"],[1,[26,[\"letters\"]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[6,[\"if\"],[[28,[\"isNumber\"]]],null,{\"statements\":[[0,\"                 \\n\"]],\"locals\":[]},null]],\"locals\":[]}],[0,\"    \"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/dial-pad-number/template.hbs"
    }
  });

  _exports.default = _default;
});