define("web-directory/routes/center-stage-frame", ["exports", "ember-purecloud-components/mixins/logger", "boon-js"], function (_exports, _logger, _boonJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_logger.default, {
    frameService: Ember.inject.service('center-stage-frame'),
    session: Ember.inject.service('session'),
    init: function init() {
      this._super.apply(this, arguments);

      this.queryParams = this.get('frameService').get('allClientQueryParams').reduce(function (queryParamsObj, queryParam) {
        queryParamsObj[queryParam] = {
          refreshModel: true
        };
        return queryParamsObj;
      }, {});
    },
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var result = this._super.apply(this, arguments);

      return Ember.RSVP.resolve(result).then(function () {
        if (transition.isActive === false) {
          // Route canceled higher in the chain (e.g. for auth)
          return;
        }

        var path = transition.intent.url;

        var toggle = _this.get('frameService').toggleForPath(path); // Redirect if a feature toggle is specified, but not set.


        if (toggle) {
          var key = toggle.key,
              _toggle$redirect = toggle.redirect,
              redirect = _toggle$redirect === void 0 ? 'index' : _toggle$redirect,
              _toggle$makeRedirectA = toggle.makeRedirectArgs,
              makeRedirectArgs = _toggle$makeRedirectA === void 0 ? function () {
            return undefined;
          } : _toggle$makeRedirectA;

          if (key && !(0, _boonJs.getEvaluator)(key)(_this.get("session.features"))) {
            var redirectArgs = makeRedirectArgs({
              path: path
            });

            _this.logger.debug('Aborting route transition, the toggle for the frame is not enabled.', {
              toggle: key,
              redirectRoute: redirect,
              redirectArgs: redirectArgs
            });

            if (redirectArgs) {
              _this.replaceWith(redirect, redirectArgs);
            } else {
              _this.replaceWith(redirect);
            }
          }
        }
      });
    },
    updateActiveRoute: function updateActiveRoute() {
      var _this2 = this;

      Ember.run.next(function () {
        return _this2.get('frameService').updateActiveRoute();
      });
    },
    deactivate: function deactivate() {
      this.updateActiveRoute();
    },

    /** Is a full transition currently happening? */
    isTransitioning: false,
    actions: {
      willTransition: function willTransition(transition) {
        this.set('isTransitioning', true);
        var promptOnLeave = this.get('frameService').get('promptOnLeave');
        var router = Ember.getOwner(this).lookup('router:main'); // TODO: use DOM-based modal instead of window.confirm()???

        if (promptOnLeave && !window.confirm(promptOnLeave)) {
          transition.abort();
          this.set('isTransitioning', false); // Hack to avoid address bar and history showing the target route even though we never transitioned:
          // If transition was initiated within Ember then router.currentURL will reflect target route. Otherwise
          // it reflects the URL of the route the router thinks we are on (i.e. previous route when out of sync).
          // They only differ when router is *reacting* to external location change, e.g. user edited address bar
          // or used browser Back/Forward or content in page changed the location directly like <a href="#/foo">.

          var oldURL = router.currentURL;
          var newURL = router.location.getURL();

          if (oldURL != newURL) {
            // Use History.back() because otherwise we have the aborted target route in our history stack
            // even though we never actually transitioned into that route.
            window.history.back(); // Alternatively, could just use setURL() to fix the address bar, but it leaves the bogus history.
            // router.location.setURL(oldURL);
          }
        }
      },
      didTransition: function didTransition() {
        this.updateActiveRoute();
        this.set('isTransitioning', false);
      },
      queryParamsDidChange: function queryParamsDidChange() {
        // Update the active route when query params change, but only when a full transition is not in progress. Ember fires queryParamsDidChange during a full transition and when query params are the only thing that changed.
        if (!this.get('isTransitioning')) {
          this.updateActiveRoute();
        }
      }
    }
  });

  _exports.default = _default;
});