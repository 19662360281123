define("web-directory/components/acd-interactions-panel/interaction-responses/response-detail/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['response-detail'],
    classNameBindings: ['enabled'],
    intl: Ember.inject.service(),
    interaction: null,
    responses: Ember.inject.service(),
    response: null,
    enabled: true,
    allowExpand: true,
    isSearchMode: false,
    isResponseExpanded: false,
    expandedTooltip: Ember.computed('isResponseExpanded', function () {
      var key = this.get('isResponseExpanded') ? 'purecloud.responses.tooltips.collapseResponse' : 'purecloud.responses.tooltips.expandResponse';
      return this.get('intl').lookup(key).toString();
    }),
    responseText: Ember.computed('interaction', 'interaction.isChat', 'response.replaceText', 'useHTMLResponses', function () {
      var responseText = this.get('response.texts.firstObject.content'),
          responses = this.get('responses'),
          response = this.get('response'); // replace substitutions with values matching the current interaction

      if (response && responses) {
        responseText = responses.replaceSubstitutionTokens(response).text;
        response.set('replaceText', responseText);
      }

      if (this.get('useHTMLResponses')) {
        return responses.setTextAlignment(responseText);
      } else {
        var htmlContent = $('<div></div>').html(responseText).html().replace(/(<br>)|(<\/p><p>)/g, '\n'); // Chat, using markdown, needs a specific style

        if (this.get('interaction.isChat')) {
          htmlContent = $('<div></div>').html(responseText).html().replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '\n').replace(/<br>/g, '');
        }

        return $('<div></div>').html(htmlContent).text();
      }
    }),
    actions: {
      sendResponse: function sendResponse(response) {
        this.sendAction('sendResponse', response);
      },
      toggleExpansion: function toggleExpansion(response) {
        var isExpanded = this.get('isResponseExpanded');

        if (isExpanded) {
          this.sendAction('collapseResponse', response);
        } else {
          this.sendAction('expandResponse', response);
        }
      },
      closeResponse: function closeResponse() {
        this.sendAction('collapseResponse');
      }
    }
  });

  _exports.default = _default;
});