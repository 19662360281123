define("web-directory/components/inbox-panel-v2/time-off-requests-v2/component", ["exports", "lodash", "web-directory/utils/dates"], function (_exports, _lodash, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['inbox-message', 'timeOffRequest-message-v2', 'clickable', 'timeOffRequest-message'],
    classNameBindings: ['timeOffRequest.markedAsRead:read:unread'],
    hovered: false,
    clock: Ember.inject.service(),
    inbox: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    wfm: Ember.inject.service(),
    mouseEnter: function mouseEnter() {
      this.set('hovered', true);
    },
    mouseLeave: function mouseLeave() {
      this.set('hovered', false);
    },
    actions: {
      changeStatus: function changeStatus(status) {
        this.get('wfm').changeTimeOffRequestReadState(this.get('timeOffRequest'), status);
      },
      readAndViewDetails: function readAndViewDetails() {
        this.get('wfm').changeTimeOffRequestReadState(this.get('timeOffRequest'), true);
        this.get('wfm').readTimeOffRequest(this.get('timeOffRequest'));
      },
      handleKeyDown: function handleKeyDown(event) {
        if (event.key !== 'Enter') {
          return;
        }

        this.send('readAndViewDetails');
        event.stopPropagation();
      }
    },
    agentName: Ember.computed('timeOffRequest.timeOffRequest.user', function () {
      var userGuid = this.get('timeOffRequest.timeOffRequest.user.id');

      if (userGuid) {
        return this.get('wfm').getAgentNameById(userGuid);
      }

      return null;
    }),
    displayedAgentName: Ember.computed('timeOffRequest.timeOffRequest.user', function () {
      return this.get('agentName') || this.get('intl').t('notification.inbox.unknownAgent');
    }),
    isMyRequest: Ember.computed('timeOffRequest.agentNotification', 'timeOffRequest.timeOffRequest.user', 'session.person.id', function () {
      return this.get('timeOffRequest.agentNotification') && this.get('timeOffRequest.timeOffRequest.user.id') === this.get('session.person.id');
    }),
    localizedStatus: Ember.computed('timeOffRequest.timeOffRequest.status', function () {
      return this.get('intl').t("notification.inbox.timeOffRequest.".concat(this.get('timeOffRequest').timeOffRequest.status));
    }),
    // Formats list of dates to M/D, M/D, M/D-M/D
    // dateRangeMDFormat is M/D, dateRangeFormat is startdate-enddate (for ranges), dateRangerDelimiter is ',' in this example
    dateRangeDescription: Ember.computed('timeOffRequest.timeOffRequest.fullDayManagementUnitDates', 'timeOffRequest.timeOffRequest.partialDayStartDateTimes', function () {
      var fulldates = this.getWithDefault('timeOffRequest.timeOffRequest.fullDayManagementUnitDates', []);
      var partialdates = this.getWithDefault('timeOffRequest.timeOffRequest.partialDayStartDateTimes', []);
      var dates = fulldates.concat(partialdates);
      var rangePairs = [];
      var rangeStart;
      var rangeEnd;
      var datesLength = dates.length;
      var dateFormat = this.get('wfm.isWfmDateFormatEnhancementToggleEnabled') ? this.get('intl').t('notification.inbox.dateShortMonthDayFormat') : this.get('intl').t('notification.inbox.dateRangeMDFormat');

      for (var i = 0; i <= datesLength - 1; i++) {
        var currentDate = (0, _dates.default)(dates[i]);
        var nextDay = (0, _dates.default)(currentDate).add(1, 'day');
        var nextDate = (0, _dates.default)(dates[i + 1]);

        if (i === datesLength - 1 || !nextDay.isSame(nextDate)) {
          if (rangeStart === undefined) {
            rangeStart = currentDate.format(dateFormat);
            rangePairs.push(rangeStart);
          } else {
            rangeEnd = currentDate.format(dateFormat);
            var rangePair = this.get('wfm.isWfmDateFormatEnhancementToggleEnabled') ? this.get('intl').t('components.inbox.dateRange', {
              start: rangeStart,
              end: rangeEnd
            }) : this.get('intl').t('notification.inbox.timeOffRequest.dateRangeFormat', {
              rangeStart: rangeStart,
              rangeEnd: rangeEnd
            });
            rangePairs.push(rangePair);
          }

          rangeStart = undefined;
          rangeEnd = undefined;
        } else {
          if (rangeStart === undefined) {
            rangeStart = currentDate.format(dateFormat);
          }
        }
      }

      var groups = _lodash.default.uniq(rangePairs);

      var list = groups.join(this.get('intl').t('notification.inbox.timeOffRequest.dateRangeDelimiter'));
      return list;
    }),
    date: Ember.computed('timeOffRequest.timestamp', function () {
      var date = (0, _dates.default)(this.get('timeOffRequest.timestamp'));
      var locale = this.get('intl.isoLocale');
      return date.locale(locale).format('LL');
    }),
    time: Ember.computed('timeOffRequest.timestamp', function () {
      var date = (0, _dates.default)(this.get('timeOffRequest.timestamp'));
      var locale = this.get('intl.isoLocale');
      return date.locale(locale).format('LT');
    }),
    dateFromNow: Ember.computed('clock.minute', 'timeOffRequest.timestamp', function () {
      var date = (0, _dates.default)(this.get('timeOffRequest.timestamp'));
      var locale = this.get('intl.isoLocale');
      return date.locale(locale).fromNow();
    })
  });

  _exports.default = _default;
});