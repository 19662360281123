define("web-directory/serializers/geolocation-settings", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    isNewSerializerAPI: true,
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload) {
      var jsonapiPayload = {
        data: {
          type: 'geolocation-settings',
          id: '1',
          attributes: payload
        }
      };
      return jsonapiPayload;
    }
  });

  _exports.default = _default;
});