define("web-directory/routes/engage/telephony-admin-redirect", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    redirect: function redirect(model, transition) {
      var features = this.get('session.features'); // Redirect old PCBA/engage Telephony Admin routes to iframe-coordinator app, e.g.
      // #/engage/telephonyAdmin/phoneManagement/baseSettings -> #/admin/telephony/phone-management/base-settings

      if (features['telephonyAdminUIKoIFC']) {
        var path = model.telephonyPath.replace(/^\//, '').replace('authorizedServices', 'certificate-authorities'); // 'authorized services' is old, the new route should be certificate-authorities

        var parts = path.split('/');

        for (var index = 0; index < 2 && index < parts.length; index++) {
          parts[index] = _lodash.default.kebabCase(parts[index]);
        }

        this.replaceWith('/admin/telephony/' + parts.join('/'));
      } else {
        // redirect back to old engage route
        var engagePath = transition.intent.url.replace(/\/engage\//, '');
        this.transitionTo('engage.embedded', {
          engagePath: engagePath
        });
      }
    }
  });

  _exports.default = _default;
});