define("web-directory/components/acd-interactions-panel/interaction-settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GWzOfLAl",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"settings-section\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"section-body queues\"],[13],[0,\"\\n        \"],[1,[26,[\"components/application-preferences/interactions\"]],false],[0,\"\\n    \"],[14],[0,\"\\n\\n    \"],[11,\"div\",[]],[16,\"class\",[34,[\"section-body media \",[33,[\"unless\"],[[28,[\"showAvSettings\"]],\"hide\"],null]]]],[13],[0,\"\\n        \"],[1,[26,[\"device-selection-with-preview\"]],false],[0,\"\\n    \"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/acd-interactions-panel/interaction-settings/template.hbs"
    }
  });

  _exports.default = _default;
});