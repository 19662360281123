define("web-directory/mixins/chat-message-media", ["exports", "lodash", "web-directory/models/github-item"], function (_exports, _lodash, _githubItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      generateGuid = Ember.generateGuid;
  var imageRegex = /\.(jpe?g|gifv?|png)(\?.*)?(#.*)?$/i;
  var timeStampRegex = /(\d+h)?(\d+m)?(\d+s)?$/i;
  var audioRegex = /\.(mp3|aac|wav)(\?.*)?(#.*)?$/i;
  /*
  https://media4.giphy.com/media/R06WhFXLF1Fmw/200w.gif
  http://i.giphy.com/R06WhFXLF1Fmw.gif
  http://giphy.com/embed/R06WhFXLF1Fmw
  http://giphy.com/gifs/christmas-cat-R06WhFXLF1Fmw
  http://giphy.com/gifs/R06WhFXLF1Fmw
  */

  var giphyRegex = /^https?:\/\/.*?giphy\.com\/.*?([0-9a-z]{13,}).*?$/i;
  var giphyIdIndex = 1;
  var imgurRegex = /^(http:\/\/)?.{1}\.imgur\.com\/([0-9a-z]{0,})\..*$/i; // regex for identifying youtube links
  // adapted from http://stackoverflow.com/a/10315969
  // + revised to include additional timestamp param

  var youtubeRegex = /^(?:https?:\/\/)?(?:www\.|gaming\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=|))((\w|-){11})(?:[&])?(?:t=)?([^&]+)?$/;
  var youtubeIdIndex = 1; // identify the capture group position that contains the ID, convenient in case the regex changes

  var youtubeTimeStampIndex = 3; // adapted from http://stackoverflow.com/questions/13286785/get-video-id-from-vimeo-url
  // + revised to include additional timestamp param

  var vimeoRegex = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?|#)?(?:t=)?([^&]+)?$/i;
  var vimeoIdIndex = 3; // identify the capture group position that contains the ID, convenient in case the regex changes

  var vimeoTimeStampIndex = 4;
  var ghGistRegex = /http(?:s)?:\/\/gist.github\.com\/([a-zA-Z0-9_]+)\/([a-zA-Z0-9]+)(?:\.js)?/i;
  var ghRepoRegex = /http(?:s)?:\/\/github\.com\/([a-zA-Z0-9_\-.]+)\/([a-zA-Z0-9\-.]+)\/?(?:\/issues\/[0-9]+)?$/i;
  var twitterRegex = /http(?:s)?:\/\/(?:mobile.)?twitter\.com\/(?:#!\/)?[a-zA-Z0-9_]+\/status\/([0-9]+)/i;
  var googleSlidesRegex = /https?:\/\/docs.google.com\/presentation\/d\/[a-zA-Z0-9_-]*\//i;
  var soundcloudRegex = /https?:\/\/soundcloud\.com\/(?!(stream|you)).+/i;

  function getImageShortName() {
    var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var pattern = /([A-Z0-9_-]{1,}\.(?:png|jpg|gif|jpeg))/i;
    var match = pattern.exec(url);

    if (match) {
      return window.decodeURI(match.shift());
    }

    return window.decodeURI(url.split('/').pop());
  } // convert timestamp (1m30s) to parseable integer (90).


  function parseTimeStamp(timeStamp) {
    if (timeStamp.indexOf('h') < 0 && timeStamp.indexOf('m') < 0 && timeStamp.indexOf('s') < 0) {
      return parseInt(timeStamp);
    }

    timeStamp = timeStampRegex.exec(timeStamp);
    timeStamp = (timeStamp[1] ? parseInt(timeStamp[1]) * 360 : 0) + (timeStamp[2] ? parseInt(timeStamp[2]) * 60 : 0) + (timeStamp[3] ? parseInt(timeStamp[3]) : 0);
    return timeStamp;
  }

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    mediaItems: computed('images', 'videos', 'gists', 'githubRepos', 'tweets', function () {
      var images = this.get('images');
      var videos = this.get('videos');
      var giphies = this.get('giphies');
      var audios = this.get('audios');
      var gists = this.get('gists');
      var repos = this.get('githubRepos');
      var tweets = this.get('tweets');
      var googleSlides = this.get('googleSlides');
      var soundcloud = this.get('soundcloud');
      return _lodash.default.union(images, videos, giphies, gists, repos, tweets, googleSlides, soundcloud, audios).map(function (item) {
        item.set('mediaRenderer', 'chat-panel/conversation/conversation-pane/messages/widgets/media-item/' + item.get('mediaType'));
        return item;
      });
    }),
    getLinks: function getLinks() {
      return this.get('links') || [];
    },

    /***********
    exampleMediaType: computed('links', function () {
        The 'links' property can sometimes be null/undefined, so use this.getLinks() to safely get a non-null array
         map and filter links to match your media type
        items are required have the following base properties:
        {
            mediaType,
            title
        }
        other properties can be used to render the 'body' of the media
    }),
    ************/
    images: computed('links', function () {
      var links = _lodash.default.filter(this.getLinks(), function (link) {
        if (!link.match(imageRegex)) {
          return false; // don't return any links not matching images
        }

        if (link.match(giphyRegex)) {
          return false; // don't match giphy images here
        }

        if (link.startsWith('http://')) {
          return link.match(imgurRegex); // if it's http, only return if it's imgur (can be upgraded to https)
        }

        return true; // all conditions met
      });

      return _lodash.default.map(links, function (url) {
        var gifv;
        var match = imgurRegex.test(url);

        if (url.endsWith('gifv')) {
          gifv = {
            webm: url.replace('.gifv', '.webm'),
            mp4: url.replace('.gifv', '.mp4')
          };
        }

        if (match) {
          if (url.startsWith('http://')) {
            url = url.replace('http://', 'https://');
          }
        }

        return Ember.Object.create({
          mediaType: 'inline-image',
          url: url,
          gifv: gifv,
          title: getImageShortName(url),
          identifier: generateGuid(null, 'inline-image')
        });
      });
    }),
    videos: computed('links', function () {
      var _this = this;

      var links = this.getLinks();
      var youtubeLinks = links.map(function (link) {
        return youtubeRegex.exec(link);
      }).filter(function (match) {
        return !!match;
      }).map(function (match) {
        var id = match[youtubeIdIndex];
        var timeStamp = match[youtubeTimeStampIndex] ? "?start=".concat(parseTimeStamp(match[youtubeTimeStampIndex])) : '';
        return Ember.Object.create({
          mediaType: 'inline-video',
          title: _this.get('intl').lookup('chat.inlineMedia.youtube').toString(),
          id: id,
          videoUrl: "https://www.youtube.com/embed/".concat(id).concat(timeStamp)
        });
      });
      var vimeoLinks = links.map(function (link) {
        return vimeoRegex.exec(link);
      }).filter(function (match) {
        return !!match;
      }).map(function (match) {
        var id = match[vimeoIdIndex];
        var timeStamp = match[vimeoTimeStampIndex] ? "#t=".concat(parseTimeStamp(match[vimeoTimeStampIndex])) : '';
        return Ember.Object.create({
          mediaType: 'inline-video',
          title: _this.get('intl').lookup('chat.inlineMedia.vimeo').toString(),
          id: id,
          videoUrl: "//player.vimeo.com/video/".concat(id).concat(timeStamp)
        });
      });
      return _lodash.default.union(youtubeLinks, vimeoLinks);
    }),
    giphies: computed('links', function () {
      var _this2 = this;

      return this.getLinks().map(function (link) {
        return giphyRegex.exec(link);
      }).filter(function (match) {
        return !!match;
      }).map(function (match) {
        var id = match[giphyIdIndex];
        return Ember.Object.create({
          mediaType: 'inline-video',
          title: _this2.get('intl').lookup('chat.inlineMedia.giphy').toString(),
          id: id,
          videoUrl: "https://giphy.com/embed/".concat(id, "?html5=true")
        });
      });
    }),
    gists: computed('links', function () {
      var _this3 = this;

      return this.getLinks().map(function (link) {
        var matches = ghGistRegex.exec(link);

        if (matches && (matches.length === 2 || matches.length === 3)) {
          var modelData = {
            mediaType: 'github-gist',
            username: matches[1],
            url: "https://gist.github.com/".concat(matches[1], "/").concat(matches[2], ".json?callback=?")
          };
          Ember.setOwner(modelData, Ember.getOwner(_this3));
          return _githubItem.default.create(modelData);
        }

        return undefined;
      }).filter(function (gist) {
        return !!gist;
      });
    }),
    githubRepos: computed('links', function () {
      var _this4 = this;

      return this.getLinks().map(function (link) {
        var matches = ghRepoRegex.exec(link);

        if (matches && (matches.length === 2 || matches.length === 3)) {
          var modelData = {
            mediaType: 'github-repo',
            username: matches[1],
            repo: matches[2],
            link: link,
            url: "https://api.github.com/repos/".concat(matches[1], "/").concat(matches[2])
          };
          Ember.setOwner(modelData, Ember.getOwner(_this4));
          return _githubItem.default.create(modelData);
        }

        return undefined;
      }).filter(function (repo) {
        return !!repo;
      });
    }),
    tweets: computed('links', function () {
      var _this5 = this;

      return this.getLinks().map(function (link) {
        var matches = twitterRegex.exec(link);

        if (matches && matches.length === 2) {
          return Ember.Object.create({
            mediaType: 'twitter-card',
            title: _this5.get('intl').lookup('chat.inlineMedia.tweet'),
            tweetId: matches[1],
            url: link,
            tweetInfo: null
          });
        }

        return undefined;
      }).filter(function (tweet) {
        return !!tweet;
      });
    }),
    googleSlides: computed('links', function () {
      var _this6 = this;

      return this.getLinks().map(function (link) {
        return googleSlidesRegex.exec(link);
      }).filter(function (match) {
        return !!match;
      }).map(function (match) {
        var url = match[0];
        return Ember.Object.create({
          mediaType: 'google-slides',
          title: _this6.get('intl').lookup('chat.inlineMedia.googleSlides').toString(),
          url: "".concat(url, "embed?start=false&loop=false&delayms=3000")
        });
      });
    }),
    soundcloud: computed('links', function () {
      var _this7 = this;

      return this.getLinks().map(function (link) {
        return soundcloudRegex.exec(link);
      }).filter(function (match) {
        return !!match;
      }).map(function (match) {
        var url = match[0];
        return Ember.Object.create({
          mediaType: 'soundcloud',
          title: _this7.get('intl').lookup('chat.inlineMedia.soundcloud').toString(),
          url: url
        });
      });
    }),
    audios: computed('links', function () {
      var _this8 = this;

      return this.getLinks().map(function (link) {
        return {
          match: !!audioRegex.exec(link),
          link: link
        };
      }).filter(function (res) {
        return res.match;
      }).map(function (res) {
        return Ember.Object.create({
          mediaType: 'inline-audio',
          title: _this8.get('intl').lookup('chat.inlineMedia.audio').toString(),
          url: res.link
        });
      });
    })
  });

  _exports.default = _default;
});