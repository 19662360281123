define("web-directory/components/timeline-stream/renderer-component-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HOUR = 1000 * 60 * 60; //milliseconds in an hour

  var RendererComponentBase = Ember.Component.extend({
    timeline: Ember.inject.service(),
    acknowledgedBinding: 'entity.acknowledged',
    timeAtBinding: 'entity.timeAt',
    timeDisplay: Ember.computed('timeAt', function () {
      var mT = this.get('timeAt');
      var locale = this.get('applicationService.locale');
      mT.locale(locale);
      return "".concat(mT.calendar(), " - ").concat(mT.format('l'));
    }),
    updateTimeDisplay: Ember.on('init', function () {
      Ember.run.later(this, function () {
        this.notifyPropertyChange('timeAt');
        this.updateTimeDisplay();
      }, HOUR);
    }),
    click: function click() {
      this.set('entity.acknowledged', true);
      this.get('timeline').markEventAcknowledged(this.get('entity'));
    }
  });
  var _default = RendererComponentBase;
  _exports.default = _default;
});