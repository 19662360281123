define("web-directory/mixins/acd-callback", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject,
      Mixin = Ember.Mixin,
      observer = Ember.observer,
      Promise = Ember.RSVP.Promise;
  var NOTIFICATION_HANDLED_REJECTIONS = ['CALLABLE_PHONE_NUMBER_COLUMNS_EMPTY', 'CONTACT_ATTEMPT_LIMIT_REACHED', 'CONTACT_CALLABLE_EVALUATION_ERROR', 'CONTACT_UNCALLABLE', 'DO_NOT_CALL', 'INTERACTION_NO_LONGER_EXISTS', 'NO_DESTINATION_ADDRESS', 'NOT_CALLABLE_TIME', 'NUMBER_ATTEMPT_LIMIT_REACHED', 'NUMBER_UNCALLABLE', 'RULE', 'RULE_EVALUATION_ERROR'];

  var _default = Mixin.create({
    intl: inject.service(),
    session: inject.service(),
    acdcallback: inject.service(),
    contactlist: inject.service(),
    interactionService: inject.service('interaction'),
    communication: inject.service(),
    isDialingClbk: false,
    showNumbersDropdownClbk: false,

    /* Localized strings */
    beginCallbackDefaultNumberText: computed('callbackNumbers', function () {
      return this.get('callbackNumbers.0.name') || '';
    }),

    /**
     * Whether or not skipping the callback is allowed.
     */
    isSkipDisabledClbk: computed.or('isDialingClbk', 'interaction.skipDisabled'),

    /**
     * True if the participantForCurrentUser is connected to a call communication, false otherwise.
     */
    hasActiveCall: computed.reads('interaction.hasActiveCall'),

    /**
     * True if the participantForCurrentUser is connected to a callback communication, false otherwise.
     */
    hasActiveCallback: computed.reads('interaction.hasActiveCallback'),

    /**
     * True if there is at least one number left to dial, false otherwise.
     */
    hasCallbackNumbers: computed.gt('callbackNumbers.length', 0),

    /**
     * True if there are multiple phone numbers that can be dialed, false otherwise.
     */
    hasMultipleCallbackNumbers: computed.gt('callbackNumbers.length', 1),

    /**
     * True if the agent has attempted to place a call for the callback, false otherwise.
     */
    hasAttemptedCall: computed('placedCalls.length', 'interaction.placedBlockedCalls.length', function () {
      return this.get('placedCalls.length') || this.get('interaction.placedBlockedCalls.length');
    }),

    /**
     * True if the agent has placed calls for the callback, false otherwise.
     */
    hasPlacedCalls: computed.gt('placedCalls.length', 0),

    /**
     * An array of calls that the agent has already placed.
     */
    placedCalls: computed.reads('interaction.participantForCurrentUser.calls'),

    /**
     * True if the agent has clicked "begin call" but the call hasn't connected yet.
     */
    isPlacingCall: computed.reads('interaction.isPlacingCall'),

    /**
     * An array of phone numbers associated with the callback. If a dialer preview
     * is present, the phone number type is include in the phone number name.
     * {
     *     name: 317-222-2222 (Work)
     *     number: 317-222-2222
     * }
     */
    callbackNumbers: computed.reads('interaction.callbackNumbers'),
    agentAddedCallbackNumbers: computed.alias('interaction.agentAddedCallbackNumbers'),

    /**
     * Places a call for the current callback using the first available phone number.
     */
    beginCallbackDefaultNumber: function beginCallbackDefaultNumber() {
      if (this.get('callbackNumbers.length') > 0) {
        return this.beginCallback(this.get('callbackNumbers.firstObject'));
      } else {
        return Promise.reject('No numbers to dial');
      }
    },
    holdAllOtherCalls: function holdAllOtherCalls() {
      var _this = this;

      var promises = [];
      var allInteractions = this.get('interactionService.activeInteractions');
      allInteractions.forEach(function (currentInteraction) {
        var _currentInteraction$g = currentInteraction.getProperties('type', 'isConnected', 'isHeld'),
            type = _currentInteraction$g.type,
            isConnected = _currentInteraction$g.isConnected,
            isHeld = _currentInteraction$g.isHeld;

        if (type === 'call' && isConnected && !isHeld) {
          promises.push(_this.get('communication').hold(currentInteraction).catch(function () {}));
        }
      });
      return promises;
    },

    /**
     * Answers a callback using the given phone number.
     * @param {Object} phoneNumber - the number to dial, from the callbackNumbers array
     */
    beginCallback: function beginCallback(phoneNumber) {
      var _this2 = this;

      this.set('showNumbersDropdownClbk', false);
      this.set('isDialingClbk', true); //
      // We are no longer auto holding existing calls when a new call is being
      // placed from a callback. This update ensures that other connected calls (if any)
      // are being placed on hold when a callback call is being initiated.
      //

      return Ember.RSVP.all(this.holdAllOtherCalls()).then(function () {
        return _this2.get('acdcallback').beginCallback(_this2.get('interaction'), phoneNumber.number, phoneNumber.phoneColumn);
      }).catch(function (error) {
        var errorCode = Ember.get(error, 'jqXHR.responseJSON.code');
        var reason;

        if (!errorCode || errorCode === 'conversation.error') {
          errorCode = 'begin';
        } else if (errorCode === 'conversation.error.callback.call.cannot.be.placed') {
          _this2.get('interaction.placedBlockedCalls').addObject(phoneNumber.number);

          reason = Ember.get(error, 'jqXHR.responseJSON.messageParams.reason');
          errorCode = 'blocked.' + (reason || 'unknown');
        }

        var messageKey = 'purecloud.acdInteraction.callbacks.errors.' + errorCode;

        if (!_lodash.default.includes(NOTIFICATION_HANDLED_REJECTIONS, reason)) {
          _this2._displayError(messageKey);
        }
      }).finally(function () {
        return _this2.set('isDialingClbk', false);
      }.bind(this));
    },

    /**
     * Skip the current callback; the callback will NOT be routed to another agent.
     */
    skipCallback: function skipCallback() {
      var _this3 = this;

      return this.get('acdcallback').skipCallback(this.get('interaction')).catch(function () {
        return _this3._displayError('purecloud.acdInteraction.callbacks.errors.skip');
      });
    },

    /**
     * Wrap up and disconnect the current callback.
     */
    endCallback: function endCallback() {
      var _this4 = this;

      return this.get('acdcallback').endCallback(this.get('interaction')).catch(function () {
        return _this4._displayError('purecloud.acdInteraction.callbacks.errors.end');
      });
    },

    /**
     * Fetches campaign and contact information for the
     * callback if there is an associated dialer preview.
     */
    fetchDialerPreviewDetails: observer('interaction.dialerPreview', function () {
      var _this5 = this;

      var dialerPreview = this.get('interaction.dialerPreview');

      if (dialerPreview && !this.get('interaction.contact')) {
        return this.get('contactlist').getContact(dialerPreview.contactListId, dialerPreview.contactId).then(function (contact) {
          if (_this5.get('interaction')) {
            _this5.set('interaction.contact', contact);
          }
        });
      }
    }).on('init')
  });

  _exports.default = _default;
});