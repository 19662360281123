define("web-directory/components/acd-interactions-panel/interaction-settings/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['interaction-settings'],
    openSection: 'avSettings',
    showAvSettings: Ember.computed.equal('openSection', 'avSettings'),
    showCallForwarding: Ember.computed.equal('openSection', 'callForwarding'),
    actions: {
      toggleSection: function toggleSection(sectionName) {
        this.set('openSection', sectionName);
      },
      close: function close() {
        this.set('parentView.showDropdown', false);
      }
    }
  });

  _exports.default = _default;
});