define("web-directory/components/entity-fields/boolean-field/component", ["exports", "lodash", "web-directory/mixins/field-component"], function (_exports, _lodash, _fieldComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldComponent.default, {
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.ensurePopulatedField();
    },
    ensurePopulatedField: Ember.observer('editing', function () {
      if (!this.get('editing')) {
        return;
      }

      var entity = this.get('entity');
      var path = this.get('field').getFieldPath();
      var entry = this.get('entry');

      if (_lodash.default.isEmpty(entry) || entry.value === undefined) {
        entity.set(path, false);
      } // Make sure things get bound correctly for input checkbox changes


      this.set('entry', entity.getFieldValue(path));
    })
  });

  _exports.default = _default;
});