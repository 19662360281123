define("web-directory/components/timeline-stream/mention-renderer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FgqqaO1q",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"header\"],[13],[0,\"\\n\"],[6,[\"entity-image\"],null,[[\"entity\",\"enableMiniCard\",\"size\",\"isLinkable\",\"showPresence\"],[[28,[\"fromPerson\"]],true,48,true,true]],{\"statements\":[[0,\"        \"],[1,[33,[\"integration-badges\"],null,[[\"entity\"],[[28,[\"fromPerson\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"title\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"primary\"],[13],[0,\"\\n            \"],[11,\"span\",[]],[15,\"class\",\"from\"],[13],[1,[28,[\"fromPerson\",\"name\"]],false],[14],[0,\"\\n            \"],[11,\"span\",[]],[15,\"class\",\"description\"],[13],[1,[33,[\"t\"],[\"timeline.mention.description\"],null],false],[14],[0,\"\\n            \"],[11,\"span\",[]],[15,\"class\",\"room\"],[13],[1,[33,[\"emoji-text\"],[[28,[\"roomSubject\"]]],null],false],[14],[0,\"\\n        \"],[14],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"secondary\"],[13],[1,[26,[\"timeDisplay\"]],false],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[6,[\"unless\"],[[28,[\"acknowledged\"]]],null,{\"statements\":[[0,\"    \"],[11,\"div\",[]],[15,\"class\",\"widgets-default\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"message-container\"],[13],[0,\"\\n\"],[6,[\"emoji-dom\"],null,null,{\"statements\":[[0,\"                \"],[1,[28,[\"message\",\"messageBody\"]],true],[0,\"\\n\"]],\"locals\":[]},null],[0,\"        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/timeline-stream/mention-renderer/template.hbs"
    }
  });

  _exports.default = _default;
});