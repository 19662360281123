define("web-directory/mixins/phone-service/active-call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Mixin.create({
    activeCall: null,
    isHeld: computed.reads('activeCall.isHeld'),
    isMuted: computed.reads('activeCall.isMuted'),
    isRecording: computed.reads('activeCall.isRecording'),
    isActiveCallACD: computed('activeCall.{isAgentCall,myselfIsMonitor,myselfIsCoach,myselfIsBarged}', function () {
      if (!this.get('activeCall')) {
        return false;
      }

      return this.get('activeCall.isAgentCall') && !this.get('activeCall.myselfIsMonitor') && !this.get('activeCall.myselfIsCoach') && !this.get('activeCall.myselfIsBarged');
    }),
    isOutsideCall: computed('activeCall.otherParty.purpose', function () {
      var purpose = this.get('activeCall.otherParty.purpose');
      return purpose === 'external';
    }),
    myself: computed('activeCall.myself', function () {
      // TODO: can this be removed?
      return this.get('activeCall.myself');
    }),
    onCall: computed.reads('activeCall.onCall'),
    setActiveCall: function setActiveCall(conversation) {
      this.set('activeCall', conversation);
      this.notifyPropertyChange('activeCall');
    },
    cleanupActiveCall: function cleanupActiveCall() {
      return this.setActiveCall(null);
    }
  });

  _exports.default = _default;
});