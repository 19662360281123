define("web-directory/components/person-graph/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PersonGraphComponent = Ember.Component.extend({
    classNames: ['person-graph'],
    classNameBindings: ['mini:mini'],
    attributeBindings: ['width', 'height'],
    person: null,
    graph: null,
    paper: null,
    mini: false,
    trueColor: '#66f',
    trueStrokeColor: 'white',
    falseColor: '#ddd',
    falseStrokeColor: 'white',
    disabledColor: 'transparent',
    disabledStrokeColor: 'transparent',
    disabledSegments: null,
    paths: null,
    baseAttrForPath: {},
    segmentChanged: Ember.observer('graph', 'graph.{ancestors,parent,self,siblings,children,descendants}', function (sender, key) {
      if (this.get('paths')) {
        var segName = key.split('.').reverse()[0];
        var segment = this.get('paths.' + segName);

        if (segment) {
          segment.attr(this.attrForSegment(segName));
        }
      }
    }),
    disabledSegmentsChanged: Ember.observer('disabledSegments.[]', function () {
      var segs = this.get('disabledSegments');
      var graph = this.get('graph');

      if (segs && graph) {
        for (var i = 0; i < segs.length; i++) {
          graph.set(segs[i], false);
          this.segmentChanged(this, 'graph.' + segs[i]);
        }
      }
    }),
    setupGraph: Ember.on('didInsertElement', function () {
      this.set('paths', {});

      if (!this.get('graph')) {
        this.set('graph', Ember.Object.create({
          ancestors: false,
          parent: false,
          self: false,
          siblings: false,
          children: false,
          descendants: false
        }));
      }

      this.drawGraphics();
    }),
    destroyGraphics: Ember.on('willDestroyElement', function () {
      if (this.get('paper')) {
        this.get('paper').remove();
        this.set('paper', null);
      }
    }),
    click: function click(event) {
      if (this.get('mini')) {
        return;
      }

      var target = Ember.$(event.target);

      if (target.is('path')) {
        var segment = target.data('segmentName');
        this.triggerAction({
          action: 'selectRelationship',
          actionContext: segment
        });
      } else if (target.is('img') || target.is('.entity-image')) {
        this.triggerAction({
          action: 'selectRelationship',
          actionContext: 'self'
        });
      }
    },
    isDisabled: function isDisabled(segment) {
      return this.get('disabledSegments') && this.get('disabledSegments').indexOf(segment) > -1;
    },
    drawGraphics: function drawGraphics() {
      this.destroyGraphics();
      var width = this.get('width') || 200;
      var height = this.get('height') || 200;
      var paper = new window.Raphael(this.get('element'), width, height);
      this.set('paper', paper);
      this.drawSegment('ancestors');
      this.drawSegment('parent');
      this.drawSegment('self');
      this.drawSegment('siblings');
      this.drawSegment('children');
      this.drawSegment('descendants');
      paper.renderfix();
      paper.safari();
    },
    drawSegment: function drawSegment(segment) {
      var width = this.get('width') || 200;
      var height = this.get('height') || 200;
      var xform = ['s', width, ',', height, ',0,0'];
      var path = this.get('paper').path(this.pathForSegment(segment));
      var attr = $.extend(this.get('baseAttrForPath'), this.attrForSegment(segment), {
        'stroke-width': 0.5
      });
      path.transform(xform.join('')).attr(attr);
      Ember.$(path.node).data('segmentName', segment);
      this.set('paths.' + segment, path);
    },
    attrForSegment: function attrForSegment(segment) {
      var attr = {};
      attr.cursor = 'pointer';

      if (this.isDisabled(segment)) {
        attr.fill = this.get('disabledColor');
        attr.stroke = this.get('disabledStrokeColor');
        attr.cursor = 'default';
      } else if (this.get('graph.' + segment)) {
        attr.fill = this.get('trueColor');
        attr.stroke = this.get('trueStrokeColor');
      } else {
        attr.fill = this.get('falseColor');
        attr.stroke = this.get('falseStrokeColor');
      }

      if (this.get('mini')) {
        attr.cursor = 'default';
      }

      return attr;
    },
    pathForSegment: function pathForSegment(segment) {
      if (this.get('mini')) {
        return this.get(segment + 'SmallPath');
      } else {
        return this.get(segment + 'Path');
      }
    },
    ancestorsPath: 'M0.439,0.173L0.499,0.114L0.563,0.178L0.563,0.125L0.625,0.125L0.499,0L0.374,0.125L0.439,0.125z',
    parentPath: 'M0.439,0.299C0.458,0.294,0.478,0.29,0.499,0.29c0.022,0,0.043,0.004,0.064,0.011V0.258h0.062L0.499,0.133L0.374,0.258h0.065V0.299z',
    selfPath: 'M0.499,0.313c-0.103,0-0.186,0.083-0.186,0.186s0.083,0.186,0.186,0.186s0.186-0.083,0.186-0.186S0.602,0.313,0.499,0.313z  M0.499,0.624c-0.069,0-0.125-0.056-0.125-0.125c0-0.069,0.056-0.125,0.125-0.125c0.069,0,0.125,0.056,0.125,0.125C0.624,0.568,0.568,0.624,0.499,0.624z',
    siblingsPath: 'M0.298,0.559C0.293,0.54,0.289,0.52,0.289,0.5c0-0.022,0.004-0.044,0.011-0.064H0.173V0.374L0.049,0.5l0.125,0.125V0.559H0.298z M0.698,0.438c0.005,0.019,0.009,0.039,0.009,0.059c0,0.023-0.004,0.044-0.011,0.064h0.126v0.062l0.125-0.126L0.823,0.372v0.065H0.698z',
    childrenPath: 'M0.56,0.699C0.541,0.705,0.521,0.708,0.5,0.708c-0.022,0-0.044-0.004-0.064-0.011v0.043H0.374L0.5,0.865l0.125-0.125H0.56V0.699z',
    descendantsPath: 'M0.559,0.827L0.5,0.886L0.436,0.822L0.436,0.875L0.374,0.875L0.5,1L0.625,0.875L0.559,0.875z',
    ancestorsSmallPath: 'M0.439,0.173L0.499,0.114L0.563,0.178L0.563,0.125L0.625,0.125L0.499,0L0.374,0.125L0.439,0.125z',
    parentSmallPath: 'M0.439,0.343c0.019-0.007,0.039-0.011,0.06-0.011c0.022,0,0.044,0.004,0.064,0.013V0.291h0.062L0.499,0.167L0.374,0.291h0.065V0.343z',
    selfSmallPath: 'M0.499,0.374c-0.069,0-0.125,0.056-0.125,0.125c0,0.069,0.056,0.125,0.125,0.125c0.069,0,0.125-0.057,0.125-0.125c0-0.038-0.017-0.072-0.043-0.095C0.559,0.385,0.53,0.374,0.499,0.374z',
    siblingsSmallPath: 'M0.332,0.499c0-0.023,0.004-0.044,0.013-0.063H0.208V0.374L0.083,0.5l0.125,0.125V0.559h0.136C0.336,0.54,0.332,0.52,0.332,0.499z M0.916,0.498L0.791,0.374v0.065H0.654c0.007,0.019,0.012,0.039,0.012,0.06c0,0.022-0.004,0.043-0.013,0.063h0.138v0.062L0.916,0.498z',
    childrenSmallPath: 'M0.56,0.654L0.56,0.654C0.55,0.658,0.541,0.661,0.53,0.663S0.51,0.666,0.499,0.666c-0.011,0-0.022-0.001-0.033-0.003c-0.01-0.002-0.021-0.005-0.03-0.009v0.054H0.374L0.5,0.833l0.125-0.125H0.56V0.654z',
    descendantsSmallPath: 'M0.559,0.827L0.5,0.886L0.436,0.822L0.436,0.875L0.374,0.875L0.5,1L0.625,0.875L0.559,0.875z'
  });
  var _default = PersonGraphComponent;
  _exports.default = _default;
});