define("web-directory/components/location-display/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['location-display', 'location'],
    entity: null,
    location: null
  });

  _exports.default = _default;
});