define("web-directory/components/integration-badges/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sxX1jhF1",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"entity\"]]],null,{\"statements\":[[0,\"    \"],[11,\"div\",[]],[16,\"class\",[34,[[33,[\"if\"],[[28,[\"isSmall\"]],\"small-integration-badge\",\"\"],null]]]],[13],[0,\"\\n\"],[6,[\"each\"],[[33,[\"phone-integrations\"],[[28,[\"entity\"]]],null]],null,{\"statements\":[[0,\"            \"],[1,[33,[\"integration-badge\"],null,[[\"integration\",\"isSmall\",\"useLightIcon\"],[[28,[\"phoneIntegration\"]],[28,[\"isSmall\"]],[28,[\"useLightIcon\"]]]]],false],[0,\"\\n\"]],\"locals\":[\"phoneIntegration\"]},null],[0,\"    \"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/integration-badges/template.hbs"
    }
  });

  _exports.default = _default;
});