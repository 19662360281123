define("web-directory/utils/external-contact-messaging", ["exports", "web-directory/utils/message-channels", "web-directory/services/relate"], function (_exports, _messageChannels, _relate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.USER_ID = _exports.KNOWN_INBOUND_MESSAGE_SUBTYPES = _exports.IDS = _exports.ID = _exports.I18N_INFO = _exports.CHANNEL_RECORD_PROPERTIES = void 0;
  _exports.componentClassName = componentClassName;
  _exports.getDisplayableName = getDisplayableName;
  _exports.getIdentifier = getIdentifier;
  _exports.getInteractionChannel = getInteractionChannel;
  _exports.getMessageChannelType = getMessageChannelType;
  _exports.getMessengerType = getMessengerType;
  _exports.isInboundMessageType = isInboundMessageType;
  _exports.isNewId = isNewId;
  _exports.notExcluded = notExcluded;
  _exports.push = push;
  _exports.setNewValuesInChannelRecord = setNewValuesInChannelRecord;
  _exports.updateContactWithChannelChanges = updateContactWithChannelChanges;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var I18N_INFO = {
    updateChannelInfoPrompt: 'purecloud.acdInteraction.relate.displayProfile.updateChannelInfoPrompt',
    updateChannelInfoPromptPlural: 'purecloud.acdInteraction.relate.displayProfile.updateChannelInfoPromptPlural',
    updateAllChannelInfoPrompt: 'purecloud.acdInteraction.relate.displayProfile.updateAllChannelInfoPrompt',
    newChannelPrompt: 'purecloud.acdInteraction.relate.displayProfile.newChannelPrompt',
    pleaseWaitForFullContactInfo: 'purecloud.acdInteraction.relate.displayProfile.pleaseWaitForFullContactInfo',
    propertyChanged: 'purecloud.acdInteraction.relate.channelTypes'
  };
  _exports.I18N_INFO = I18N_INFO;
  var ID = 'id';
  _exports.ID = ID;
  var IDS = 'ids';
  _exports.IDS = IDS;
  var USER_ID = 'userId';
  _exports.USER_ID = USER_ID;
  var CHANNEL_ID_PROPERTY = 'channelIdProperty';
  var CHANNEL_TYPE = 'type';
  var IS_NEW_CHANNEL = 'isNewChannel';
  var AGENT_RESPONDED_TO_PROMPT = 'agentRespondedToPrompt';
  var CHANGES_IGNORED_BY_AGENT = 'changesIgnoredByAgent';
  var CHANNEL_RECORD_PROPERTIES = [CHANNEL_TYPE, CHANNEL_ID_PROPERTY, IS_NEW_CHANNEL, CHANGES_IGNORED_BY_AGENT, AGENT_RESPONDED_TO_PROMPT, USER_ID, IDS, ID]; // Refer to the message-interaction.js MESSENGER_TYPE const for these values.  Could use DRYing

  _exports.CHANNEL_RECORD_PROPERTIES = CHANNEL_RECORD_PROPERTIES;

  var KNOWN_INBOUND_MESSAGE_SUBTYPES = _toConsumableArray(_messageChannels.messagingChannelTypes.map(function (messagingChannelType) {
    return messagingChannelType.type;
  }));
  /**
   * Used to return the "class" of an Ember component `call`ing this function; e.g.
   * `componentClassName.call(this)`
   *
   * Used primarily to include a query parameter in a REST endpoint call to identify a component responsible for making the call.
   */


  _exports.KNOWN_INBOUND_MESSAGE_SUBTYPES = KNOWN_INBOUND_MESSAGE_SUBTYPES;

  function componentClassName() {
    try {
      return encodeURIComponent(this.toString());
    } catch (e) {
      return 'COMPONENT_UNKNOWN';
    }
  }

  function isInboundMessageType(interaction) {
    return interaction && KNOWN_INBOUND_MESSAGE_SUBTYPES.includes(getMessengerType(interaction));
  }

  function getMessengerType(interaction) {
    return interaction && interaction.get('subMessageType');
  }

  function getIdentifier(interaction) {
    if (!interaction) {
      return null;
    }

    var messagingChannelType = (0, _messageChannels.messagingChannelTypeFor)(getMessengerType(interaction));
    return messagingChannelType && messagingChannelType.getReverseWhitepagesLookupValue(interaction);
  }

  function getDisplayableName(interaction) {
    return interaction && interaction.get('participantForCustomer.messages.firstObject.fromAddress.addressDisplayable');
  }

  function isNewId(id, messengerType, externalContact) {
    if (!externalContact) {
      return true; // If the caller supplies no externalContact instance, then consider the id as being new
    }

    var messagingChannelType = _messageChannels.messagingChannelTypes.find(function (mct) {
      return mct.type === messengerType;
    });

    if (!messagingChannelType) {
      return false; // An unrecognized messenger type, so can't determine if the ID is new or not, so return
    }

    return messagingChannelType.isNewId(messagingChannelType.getIdsFromExternalContact(externalContact), id);
  }

  function getMessageChannelType(messengerType) {
    return _messageChannels.messagingChannelTypes.find(function (messagingChannelType) {
      return messagingChannelType.type === messengerType;
    });
  } // Returns an external contact channel object that's specific to the interaction's `subMessageType` type,
  // populated with properties mapping to Relate's Java message channel type models (e.g. FacebookId, LineId, TwitterId, and WhatsappId).


  function getInteractionChannel(interaction) {
    var logger = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

    if (!interaction) {
      return null;
    }

    var interactionId = interaction.get('id');
    var interactionType = interaction.get('type');
    var messengerType = getMessengerType(interaction);
    var messagingChannelType = (0, _messageChannels.messagingChannelTypeFor)(messengerType);

    if (!messagingChannelType) {
      if (logger) {
        logger.error("ACD Relate: Could not determine the messenger channel type for interaction id \"".concat(interactionId, "\" where messenger type is \"").concat(messengerType, "\" and the interaction type is \"").concat(interactionType, "\""));
      }

      return null;
    }

    var id = getIdentifier(interaction);
    var displayOrScreenName = (0, _messageChannels.getDisplayOrScreenName)(interaction);
    var handle = getDisplayableName(interaction); // Returns undefined if not a Twitter interaction and that's OK because the other modelProperties functions will just ognore it

    return messagingChannelType.modelProperties(id, displayOrScreenName, handle);
  }

  function notExcluded(key) {
    return !CHANNEL_RECORD_PROPERTIES.includes(key);
  }
  /**
   * Sets new values into the channel record for purposes of change detection
   * @param interactionChannelProperties An object with properties and values based on a channel-record; e.g. for Facebook:
   * { displayName: 'Don Herkimer', ids: [ { scopedId: '23456' } ] }.  The source of "new" values for the channel gotten
   * from an interaction.
   * @param isNewChannel true if this is a new channel for the contact
   * @param contact An external contact that may (or may not) already have a channel for this interaction.  The source of
   * "old" values for the channel.
   * @param channelIdProperty E.g. 'id', 'ids', 'phoneNumber' etc. (see the message-channels util for details)
   * @param idPropertyName E.g. 'userId' or 'scopedId', if the channel is capable of having an array of ID objects
   * whose every key is the given property name.  Null otherwise.
   * @param channelRecord A channel-record model instance that will track changed attributes (ref. Ember DS.Model's changedAttributes method)
   */


  function setNewValuesInChannelRecord(interactionChannelProperties, isNewChannel, contact, channelIdProperty, idPropertyName, channelRecord) {
    var messagingChannelType = (0, _messageChannels.messagingChannelTypeFor)(channelRecord.get('type')); // Iterate over the interaction's channel-related properties to get potentially new values

    Object.keys(interactionChannelProperties).forEach(function (key) {
      var modelKey = "".concat(messagingChannelType.modelKey);
      var contactChannelIds = !isNewChannel && IDS === key && contact.get("".concat(modelKey, ".").concat(key));

      if (key === IDS && (contactChannelIds || isNewChannel)) {
        addOrReplaceIdsInChannelRecord(idPropertyName, channelRecord, interactionChannelProperties, contactChannelIds);
      } else {
        if ('id' === key) {
          // We don't use an 'id' key in the channel record, since Ember DS will consider that to be the ID of the record.
          // We use 'userId' to hold the value of the inbound message's (user) 'id' property.
          channelRecord.set(USER_ID, interactionChannelProperties[key]);
        } else if (deepNotEqual(channelRecord.get("".concat(key)), interactionChannelProperties[key])) {
          // Set this legit new value in the record
          channelRecord.set("".concat(key), interactionChannelProperties[key]);
        }
      }
    });
  }

  function getChannelRecordInfo(type, freshContactSnapshot, channelIdProperty, interaction, logger) {
    var interactionChannel = getInteractionChannel(interaction, logger);

    if (!interactionChannel) {
      // Not much we can do for this interaction if we can't get channel info from it
      return {};
    }

    var messagingChannelType = getMessageChannelType(type);
    var attributes = {};

    if (freshContactSnapshot) {
      Object.assign(attributes, freshContactSnapshot.get(messagingChannelType.modelKey)); // The source for old (WRT the Ember DS channel record) values
    }

    var interactionChannelProperties = interactionChannel[messagingChannelType.modelKey]; // The source for new (WRT the Ember DS channelRecord) values

    var isNewChannel = !attributes || !attributes[messagingChannelType.channelId]; // The channel's new if either there is no lineId, or twitterId, or ... property, *or* said property has no channel ID

    if (isNewChannel) {
      attributes = interactionChannel;
    }

    attributes.type = type;
    attributes.channelIdProperty = channelIdProperty;
    attributes.isNewChannel = isNewChannel;
    attributes.changesIgnoredByAgent = false;
    attributes.agentRespondedToPrompt = false;
    var messengerType = messagingChannelType.type;
    var idAttribute = messagingChannelType.channelId;
    var idOrIDs = freshContactSnapshot.get("".concat(messengerType, "Id.").concat(idAttribute)) || interactionChannelProperties[interactionChannel.channelId];

    if (idAttribute === IDS) {
      attributes.userId = idOrIDs;
    } else {
      attributes.userId = idOrIDs;
    } // The ids, name, displayName, screenName, and phoneNumber properties for the channel record under-construction
    // (see the channel-record model)


    return {
      attributes: attributes,
      isNewChannel: isNewChannel,
      interactionChannelProperties: interactionChannelProperties
    };
  } // Returns an Ember DS channel-record; see the model for more info.
  // The ID of the returned record is that of the interaction


  function push(store, interaction, freshContactSnapshot, type, channelIdProperty, logger) {
    var _getChannelRecordInfo = getChannelRecordInfo(type, freshContactSnapshot, channelIdProperty, interaction, logger),
        attributes = _getChannelRecordInfo.attributes,
        isNewChannel = _getChannelRecordInfo.isNewChannel,
        interactionChannelProperties = _getChannelRecordInfo.interactionChannelProperties;

    if (!attributes) {
      // Not much we can do for this interaction if we can't get channel info from it
      return null;
    } // The channelRecord's changedAttributes function will return an object
    // with properties storing arrayed pairs denoting an oldValue and
    // a newValue: [oldValue, newValue], plus the numberOfChanges method that helps
    // to determine if the inbound message introduces changes that need to be applied
    // to the freshContactSnapshot's channel.
    //
    // We leverage that capability by setting values below.


    var channelRecord;
    Ember.run(function () {
      // A run is required here for a successful run of the interaction-relate-profile-v2's "should display a confirmation prompt if..." integration test
      channelRecord = store.push({
        data: {
          id: interaction.get('id'),
          // Use the interaction's ID as the ID for the record to allow peeks (see the peekForChannelRecord method)
          type: _relate.CHANNEL_MODEL_TYPE,
          attributes: attributes // The set of "old" values that may be updated by the forEach iterator below

        }
      }); // Add a numberOfChanges function to the channelRecord object so the confirmation prompt component can use it to
      // determine what sort of prompt to offer the user

      channelRecord.numberOfChanges = function () {
        return Object.keys(channelRecord.changedAttributes()).length;
      }; // See if the channel identifies users with an _array_ of IDs


      var ids = interactionChannelProperties.ids; // If so, grab the property of the first of those IDs to use as a property name if IDs are being added (below)

      var idPropertyName = ids ? Object.keys(ids[0])[0] : null;
      setNewValuesInChannelRecord(interactionChannelProperties, isNewChannel, freshContactSnapshot, channelIdProperty, idPropertyName, channelRecord);
    });
    return channelRecord;
  }
  /**
   * Updates the contact with changes recorded by the channel Ember DS record
   * @param channelRecord
   * @param contact
   */


  function updateContactWithChannelChanges(channelRecord, contact) {
    if (!channelRecord) {
      return;
    }

    var channelRecordData = channelRecord.data;
    var changedAttributes = channelRecord.changedAttributes();
    var messagingChannelType = (0, _messageChannels.messagingChannelTypeFor)(channelRecordData.type);
    var modelKey = "".concat(messagingChannelType.modelKey);
    var modelProperties = Object.keys(messagingChannelType.modelProperties()[modelKey]);
    Ember.run.join(function () {
      if (channelRecordData.isNewChannel) {
        // Set up the new channel and its id(s)
        contact.set("".concat(modelKey), channelRecordData["".concat(modelKey)]);
      } else {
        // Copy the updated channel's data into the contact
        Object.keys(changedAttributes).forEach(function (key) {
          if (key === USER_ID) {
            var channelIdProperty = "".concat(messagingChannelType.modelKey, ".").concat(messagingChannelType.channelId); // We don't use an 'id' key in the record because Ember DS will consider that to be the ID of the record.
            // We use 'channelId' to hold the value of the inbound message's 'id' property.

            if (messagingChannelType.idPropertyName) {
              // If the there's an idPropertyName, that means the channel uses an array of objects to denote IDs
              var id = messagingChannelType.getIdFrom(changedAttributes[key][1]);
              var contactChannelIds = contact.get("".concat(channelIdProperty));
              contact.set("".concat(channelIdProperty), [id].concat(contactChannelIds));
            } else {
              contact.set("".concat(channelIdProperty), messagingChannelType.getIdFrom(changedAttributes[key][1]));
            }
          } else if (notExcluded(key) && modelProperties.includes(key)) {
            contact.set("".concat(modelKey, ".").concat(key), changedAttributes[key][1]);
          }
        });
      } // Delete irrelevant, channel-record-specific properties


      CHANNEL_RECORD_PROPERTIES.forEach(function (property) {
        delete contact.get("".concat(modelKey, ".").concat(property));
      });
    });
  }

  function deepNotEqual(a, b) {
    return JSON.stringify(a) !== JSON.stringify(b);
  }

  function addOrReplaceIdsInChannelRecord(idPropertyName, channelRecord, interactionChannel, contactChannelIds) {
    // Currently, FacebookId and LineId (contacts-service Java model class names) objects
    // (facebook-id and line-id Ember DS models) use a property named 'ids' that has an array of
    // objects whose key/value pairs are an ID name ('scopedId' and 'userId' respectively)
    // paired with an ID string value.
    //
    // E.g. a facebook-id model object will have a property named 'ids', and its value could be
    // [ { scopedId: '1234' }, { scopedId: '5678' } ]
    var contactChannelIdValues = contactChannelIds ? contactChannelIds.map(function (id) {
      return id[idPropertyName];
    }) : [];
    var interactionIdValues = interactionChannel.ids.map(function (id) {
      return id[idPropertyName];
    });
    var addedIds = false;
    var newIdValues = interactionIdValues.map(function (interactionId) {
      if (!contactChannelIdValues.includes(interactionId)) {
        addedIds = true;
        return interactionId;
      }
    });

    if (addedIds) {
      // As of this writing (31AUG2018), there is only one element in any contact's ids array.
      //
      // The uncommented statement map invocation below works under this assumption.  To relax that assumption at
      // some point in the future, use the commented-out map line below instead.
      var ids = newIdValues.map(function (idVal) {
        // const ids = contactChannelIdValues.concat(newIdValues).map(idVal => {
        var id = {};
        id[idPropertyName] = idVal;
        return id;
      }); // Invoking the setter on the record engages the Ember DS changed attribute logic for models

      channelRecord.set(IDS, ids);
    }
  }
});