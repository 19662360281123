define("web-directory/components/app-carousel-toolbar/component", ["exports", "@genesys/apps-v2-host-layout"], function (_exports, _appsV2HostLayout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['app-carousel-toolbar'],
    classNameBindings: ['name'],
    attributeBindings: ['ariaHidden:aria-hidden'],
    intl: Ember.inject.service('intl'),
    ariaHidden: Ember.computed('isVisible', function () {
      return this.get('isVisible') ? 'false' : 'true';
    }),

    /**
     * @Attribute
     */
    name: undefined,
    init: function init() {
      this._super.apply(this, arguments);

      (0, _appsV2HostLayout.registerAppCarouselToolbar)();
      (0, _appsV2HostLayout.setLocale)(this.get('intl').get('isoLocale'));
    }
  });

  _exports.default = _default;
});