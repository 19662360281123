define("web-directory/adapters/role", ["exports", "web-directory/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL: function buildURL(type, id) {
      var modelEndpoint = this.pathForType(type);
      var url = "/platform/".concat(this.namespace, "/v2/authorization/").concat(modelEndpoint);

      if (id) {
        url += "/".concat(id);
      }

      return url;
    },
    findAll: function findAll() {
      var _this = this;

      var initialPage = 1;
      var pageSize = 100;
      var records = [];
      var aggregatePayload;
      return this._getPage(initialPage, pageSize).then(function (result) {
        aggregatePayload = result;
        records.concat(result.entities);
        var promises = [];

        for (var page = 2; page <= result.pageCount; page++) {
          promises.push(_this._getPage(page, pageSize));
        }

        return Ember.RSVP.all(promises);
      }).then(function (results) {
        results.forEach(function (result) {
          aggregatePayload.entities = aggregatePayload.entities.concat(result.entities);
        });
        aggregatePayload.entities.sort(function (roleA, roleB) {
          if (roleA.name < roleB.name) {
            return -1;
          } else if (roleA.name > roleB.name) {
            return 1;
          } else {
            return 0;
          }
        });
        return aggregatePayload;
      });
    },
    _getPage: function _getPage(pageNumber, pageSize) {
      return this.ajax("/platform/api/v2/authorization/roles?pageSize=".concat(pageSize, "&pageNumber=").concat(pageNumber, "&userCount=false"), 'GET');
    }
  });

  _exports.default = _default;
});