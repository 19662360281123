define("web-directory/services/apps-api-handlers/resource-center-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SHOW_RC_ARTIFACT_APP_API_ACTION_NAME = _exports.SHOW_HELP_APP_API_ACTION_NAME = _exports.HIDE_HELP_APP_API_ACTION_NAME = void 0;
  var RSVP = Ember.RSVP,
      inject = Ember.inject;
  var SHOW_HELP_APP_API_ACTION_NAME = 'showHelp';
  _exports.SHOW_HELP_APP_API_ACTION_NAME = SHOW_HELP_APP_API_ACTION_NAME;
  var HIDE_HELP_APP_API_ACTION_NAME = 'hideHelp';
  _exports.HIDE_HELP_APP_API_ACTION_NAME = HIDE_HELP_APP_API_ACTION_NAME;
  var SHOW_RC_ARTIFACT_APP_API_ACTION_NAME = 'showResourceCenterArtifact';
  _exports.SHOW_RC_ARTIFACT_APP_API_ACTION_NAME = SHOW_RC_ARTIFACT_APP_API_ACTION_NAME;
  var HELP_PANEL_NAME = 'help';

  var _default = Ember.Object.extend({
    help: inject.service(),
    sidebar: inject.service(),

    /**
     * Synchronous check to see if this handler is applicable for the specified AppApiCall instance
     *
     * @param {Object} the AppApiCall instance to check if this handler can handle
     *
     * @returns true if the provided AppApiCall instance could be handled by this handler. This check should
     *  be limited to checking if the handler is responsible for this type of AppApiCall rather than if the
     *  payload itself is valid.  If no handler returns true, the error will be that no handler exists which is
     *  a different case than the AppApiCall is invalie. Practically, this means that validation of the payload
     *  should be done in the handle method
     */
    canHandle: function canHandle(appApiCall) {
      var apiAction = appApiCall.get('action');
      return apiAction === SHOW_HELP_APP_API_ACTION_NAME || apiAction === HIDE_HELP_APP_API_ACTION_NAME || apiAction === SHOW_RC_ARTIFACT_APP_API_ACTION_NAME;
    },

    /**
     * Handle the AppApiCall instance pre-filtered by canHandle
     *
     * @param {Object} An AppApiCall instance
     *
     * @returns A Promise resolving if the api action was successfully handled or rejecting with
     *  optional details as to why the event could not be handled.
     */
    handle: function handle(appApiCall) {
      var apiAction = appApiCall.get('action');

      if (apiAction === SHOW_HELP_APP_API_ACTION_NAME) {
        this.get('sidebar').openPanel(HELP_PANEL_NAME);
        return RSVP.resolve();
      } else if (apiAction === HIDE_HELP_APP_API_ACTION_NAME) {
        if (this.get('sidebar.communicationPanel') === HELP_PANEL_NAME) {
          this.get('sidebar').togglePanel(HELP_PANEL_NAME);
        }

        return RSVP.resolve();
      } else if (apiAction === SHOW_RC_ARTIFACT_APP_API_ACTION_NAME) {
        var resourceCenterRelPath = appApiCall.get('actionDetails.resourceCenterRelPath');

        if (typeof resourceCenterRelPath === 'string') {
          try {
            this.get('help').showResourceCenterArtifact(resourceCenterRelPath);
            return RSVP.resolve();
          } catch (e) {
            return RSVP.reject(e);
          }
        } else {
          return RSVP.reject('Must supply resourceCenterRelPath to open a resource center panel');
        }
      }
    }
  });

  _exports.default = _default;
});