define("web-directory/models/meeting", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    //id on the model is the occurrence id, not the meeting id
    roomId: attr(),
    subject: attr(),
    description: attr(),
    organizer: attr(),
    attendees: attr(),
    notifyAttendees: attr(),
    startDate: attr('iso-date'),
    endDate: attr(),
    duration: attr(),
    recurringType: attr(),
    version: attr(),
    meeting: attr(),
    //object that holds the meeting id
    meetingAttendees: Ember.computed('attendees', function () {
      if (this.get('attendees')) {
        return Ember.RSVP.resolve(this.get('attendees'));
      }

      return this.get('store').findRecord('meeting', this.get('id'), {
        reload: true
      }).then(function (meeting) {
        return meeting.get('attendees');
      });
    })
  });

  _exports.default = _default;
});