define("web-directory/helpers/tag-lookup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params) {
      var field = params[0];
      var tag = field;

      if (field) {
        var section = field.replace('.', '_');
        var key = section.substring(section.indexOf('_') + 1);
        tag = this.get('intl').lookup("person_".concat(section, "_labelKey_").concat(key));
      }

      return tag;
    }
  });

  _exports.default = _default;
});