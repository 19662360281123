define("web-directory/components/acd-interactions-panel/interaction-card-v2/message-card/component", ["exports", "web-directory/components/acd-interactions-panel/interaction-card-v2/base-card/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INTL_KEY = {
    TYPING: 'purecloud.acdInteraction.roster.cards.typing'
  };

  var _default = _component.default.extend({
    classNames: ['acd-interaction-card-v2-message'],

    /**
     * @Attribute
     * Array of conversation guids currently flagged as typing
     */
    isTypingInteractions: null,

    /**
     * @Attribute
     * mcb participant name
     */
    mcbParticipantName: null,

    /**
     * Primary roster card state
     */
    state: Ember.computed('inWrapup', 'lastMessageWasCustomer', 'isAttendedTransferring', function () {
      if (this.get('isAttendedTransferring')) {
        return {
          loading: true,
          class: 'transferring-state'
        };
      } else if (this.get('inWrapup')) {
        return {
          icon: 'icon-spark-badge-check',
          class: 'wrapup-state'
        };
      } else if (this.get('lastMessageWasCustomer')) {
        return {
          textKey: 'purecloud.acdInteraction.roster.cards.lastMessageWasCustomer',
          class: 'alert-state last-message-was-customer alert-state-abbreviated'
        };
      }

      return null;
    }),

    /**
     * Supplemental roster card state
     */
    supplementalState: Ember.computed('inWrapup', 'isTyping', 'lastMessageText', 'lastMessageTime', function () {
      if (this.get('inWrapup')) {
        return null;
      }

      if (this.get('isTyping')) {
        return {
          textKey: INTL_KEY.TYPING,
          class: '',
          elapsed: this.get('lastMessageTime')
        };
      } else if (this.get('lastMessageTime')) {
        return {
          text: this.get('lastMessageText') || '',
          class: '',
          elapsed: this.get('lastMessageTime')
        };
      }

      return null;
    }),

    /**
     * Flag indicating if the last message was inbound or outbound
     */
    lastMessageWasCustomer: Ember.computed.readOnly('interaction.latestMessageRegardlessOfParticipantWasCustomer'),

    /**
     * Text of the last message sent or received.  Will be undefined if we it cannot be parsed from
     * message objects.
     */
    lastMessageText: Ember.computed.or('interaction.latestMessageRegardlessOfParticipant.normalizedMessage.text', 'interaction.latestMessageRegardlessOfParticipant.textBody'),

    /**
     * Time object from last message object. Read from mixins/acd.js
     */
    lastMessageTime: Ember.computed.readOnly('interaction.latestMessageRegardlessOfParticipant.timestamp'),

    /**
     * True/false if interaction customer is flagged as typing
     */
    isTyping: Ember.computed('isTypingInteractions.[]', 'interaction.id', function () {
      var typingInteractions = this.get('isTypingInteractions') || [];
      var id = this.get('interaction.id');
      return typingInteractions.indexOf(id) > -1;
    })
  });

  _exports.default = _default;
});