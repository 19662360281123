define("web-directory/routes/engage/telephony-admin-extensions-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init: function init() {
      this._super.apply(this, arguments);
    },
    redirect: function redirect() {
      return this.replaceWith('/admin/telephony/extensions');
    }
  });

  _exports.default = _default;
});