define("web-directory/components/video-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed,
      inject = Ember.inject;

  var _default = Component.extend({
    classNames: ['chat-panel', 'video-panel'],
    session: inject.service(),
    mediaProviders: inject.service(),
    activeProvider: computed.reads('mediaProviders.activeProvider'),
    isZoomActiveProvider: computed.equal('activeProvider.id', 'zoom'),
    canEmbedZoomClient: false,
    showZoomPanel: computed.and('activeProvider', 'isZoomActiveProvider', 'canEmbedZoomClient'),
    isTeamsActiveProvider: computed.equal('activeProvider.id', 'teams'),
    showTeamsPanel: computed('activeProvider', 'isTeamsActiveProvider', function () {
      var provider = this.get('activeProvider');
      var teamsProvider = this.get('isTeamsActiveProvider');
      var access = this.get('activeProvider.haveAccess');
      return provider && teamsProvider && access;
    }),
    hideWebRTCPanel: computed.or('showZoomPanel', 'showTeamsPanel')
  });

  _exports.default = _default;
});