define("web-directory/components/navigation-org-switcher/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m5t4Lzou",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"isShowingInfo\"]]],null,{\"statements\":[[0,\"    \"],[11,\"div\",[]],[15,\"class\",\"org-info\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"info\"],[13],[0,\"\\n            \"],[11,\"span\",[]],[15,\"class\",\"header\"],[15,\"tabindex\",\"0\"],[15,\"role\",\"button\"],[5,[\"action\"],[[28,[null]],\"showDrawer\"]],[13],[1,[26,[\"orgName\"]],false],[14],[0,\"\\n            \"],[11,\"p\",[]],[15,\"class\",\"link\"],[13],[0,\"\\n                \"],[11,\"span\",[]],[15,\"tabindex\",\"0\"],[15,\"role\",\"button\"],[5,[\"action\"],[[28,[null]],\"logout\"]],[13],[11,\"a\",[]],[15,\"class\",\"fa fa-home\"],[13],[14],[1,[33,[\"t\"],[\"components.navOrgSwitcher.homeOrg\"],null],false],[14],[0,\"\\n            \"],[14],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/navigation-org-switcher/template.hbs"
    }
  });

  _exports.default = _default;
});