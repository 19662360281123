define("web-directory/models/skillgroup", ["exports", "ember-data", "lodash"], function (_exports, _emberData, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var COMPARATORS = {
    EqualTo: {
      label: 'equals (==)',
      value: 'EqualTo',
      op: '=='
    },
    NotEqualTo: {
      label: 'not equals (!=)',
      value: 'NotEqualTo',
      op: '!='
    },
    GreaterThan: {
      label: 'greater than (>)',
      value: 'GreaterThan',
      op: '>'
    },
    GreaterThanOrEqualTo: {
      label: 'greater than or equal to (>=)',
      value: 'GreaterThanOrEqualTo',
      op: '>='
    },
    LessThan: {
      label: 'less than (<)',
      value: 'LessThan',
      op: '<'
    },
    LessThanOrEqualTo: {
      label: 'less than or equal to (<=)',
      value: 'LessThanOrEqualTo',
      op: '<='
    }
  };
  var PROFICIENCIES = [{
    name: '0',
    value: 0
  }, {
    name: '1',
    value: 1
  }, {
    name: '2',
    value: 2
  }, {
    name: '3',
    value: 3
  }, {
    name: '4',
    value: 4
  }, {
    name: '5',
    value: 5
  }];

  var SkillGroupModel = _emberData.default.Model.extend({
    ajax: Ember.inject.service(),
    logger: Ember.inject.service('stash-logger'),
    intl: Ember.inject.service(),
    application: Ember.inject.service(),
    notification: Ember.inject.service(),
    session: Ember.inject.service(),
    description: _emberData.default.attr(),
    division: _emberData.default.attr(),
    memberCount: _emberData.default.attr(),
    name: _emberData.default.attr(),
    selfUri: _emberData.default.attr(),
    skillConditions: _emberData.default.attr(),
    type: 'group',
    expression: Ember.computed('_expression', function () {
      return this.parseExpression(this.get('_expression'));
    }),
    _expression: Ember.computed.reads('skillConditions'),
    membership: [],
    nextMembersPage: null,
    _memberDivisions: Ember.computed('division', 'id', function () {
      var _this = this;

      var path = "api/v2/routing/skillgroups/".concat(this.get('id'), "/members/divisions");
      var url = this.get('application').pcV2Uri(path);
      var data = {
        expand: 'entities'
      };
      var promise = this.get('ajax').ajaxGet(url, {
        data: data
      }).then(function (res) {
        return res.entities.map(function (div) {
          if (div.id === '*') {
            return {
              name: _this.get('intl').t('skillgroups.divisions.all'),
              id: '*'
            };
          } else {
            Ember.set(div, 'deleteable', div.id !== _this.get('division').id);
            return div;
          }
        });
      }).catch(function (error) {
        _this.get('logger').error('Error fetching memberDivisions on skillgroup', {
          error: error
        });

        var title = _this.get('intl').t('skillgroups.divisions.fetchErrorTitle');

        var message = _this.get('intl').t('skillgroups.divisions.fetchErrorMessage');

        _this.get('notification').error(title, message);

        return [];
      });
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    memberDivisions: Ember.computed.reads('_memberDivisions.content'),
    loadMembership: function loadMembership() {
      var _this2 = this;

      if (this.get('membership').length != this.get('memberCount')) {
        var path = "api/v2/routing/skillgroups/".concat(this.get('id'), "/members");

        if (this.get('nextMembersPage')) {
          path = this.get('nextMembersPage').substring(1); // we need to remove a leading `/` character
        }

        var url = this.get('application').pcV2Uri(path);
        this.get('ajax').request(url).then(function (res) {
          _this2.get('membership').pushObjects(res.entities);

          _this2.set('nextMembersPage', res.nextUri);

          _this2.notifyPropertyChange('membership');
        }).catch(function (error) {
          _this2.get('logger').error('Error fetching membership for skillgroup', {
            error: error
          });

          var title = _this2.get('intl').t('skillgroups.membership.errorTitle');

          var message = _this2.get('intl').t('skillgroups.membership.errorMessage');

          if (error.status === 403) {
            title = _this2.get('intl').t('skillgroups.membership.permissionErrorTitle');
            message = _this2.get('intl').t('skillgroups.membership.permissionErrorMessage');
          }

          _this2.get('notification').error(title, message);
        });
      }
    },
    parseExpression: function parseExpression(input) {
      var output = [];

      if (!input || input.length === 0) {
        //no need to parse an empty array
        return output;
      } else if (input[0].routingSkillConditions.length === 0 && input[0].languageSkillConditions.length === 0) {
        return output;
      } // childConditions are all nested, so we gotta recurse


      var addRule = function addRule(condition, rule) {
        var newSkill = rule.routingSkillConditions.length === 0 ? {
          name: rule.languageSkillConditions[0].languageSkill,
          type: 'language'
        } : {
          name: rule.routingSkillConditions[0].routingSkill,
          type: 'routing'
        };
        var skill = rule.routingSkillConditions.length === 0 ? rule.languageSkillConditions[0] : rule.routingSkillConditions[0];
        var children = skill.childConditions;
        var op = rule.operation;
        condition.rules.pushObject({
          comparator: COMPARATORS[skill.comparator],
          proficiency: PROFICIENCIES[skill.proficiency],
          operation: op,
          skill: newSkill
        });

        if (children) {
          children.forEach(function (child) {
            if (!_lodash.default.isEmpty(child.routingSkillConditions) || !_lodash.default.isEmpty(child.languageSkillConditions)) {
              addRule(condition, child);
            }
          });
        }
      }; // so here we'll go through each top-level condition and check recursively for children


      input.forEach(function (condition) {
        var conOp = condition.operation;
        var newCondition = {
          rules: [],
          lastCondition: false,
          operation: conOp
        };
        addRule(newCondition, condition);
        newCondition.rules.set('lastObject.lastRule', true);
        output.pushObject(newCondition);
      });
      output.set('lastObject.lastCondition', true);
      return output;
    },
    reload: function reload() {
      this.set('membership', []);
      this.set('nextMembersPage', null);
      return this._super.apply(this, arguments);
    },
    rollbackAttributes: function rollbackAttributes() {
      this.set('expression', this.parseExpression(this.get('_expression')));
      return this._super.apply(this, arguments);
    },
    delete: function _delete() {
      var uri = this.get('application').pcV2Uri("platform/api/v2/routing/skillgroups/".concat(this.get('id')));
      return this.get('ajax').delete(uri);
    },
    save: function save() {
      var uri = this.get('application').pcV2Uri("platform/api/v2/routing/skillgroups/".concat(this.get('id')));
      var data = {
        name: this.get('name'),
        description: this.get('description'),
        skillConditions: this.get('skillConditions')
      };
      return this.get('ajax').patch(uri, {
        data: data
      });
    }
  });

  SkillGroupModel.reopenClass({
    apiEndpoint: 'v2/routing/skillgroups'
  });
  var _default = SkillGroupModel;
  _exports.default = _default;
});