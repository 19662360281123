define("web-directory/mixins/relate/relate-features", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;
  var alias = computed.alias;
  var PRIVILEGE_CONVERSATION_ASSOCIATION = ['permissions.hasConversationAssociationPrivilege', 'permissions.hasExternalContactsConversationAssociationPrivilege'];
  var FEATURE_RELATE_ACCESS = 'permissions.hasExternalContactsViewContactAccess';
  var FEATURE_CUSTOM_FIELDS = 'session.features.relateDynamicSchema';

  var _default = Ember.Mixin.create({
    session: inject.service(),
    permissions: inject.service(),
    hasRelateAccess: computed.alias(FEATURE_RELATE_ACCESS),
    canShowCustomFields: alias(FEATURE_CUSTOM_FIELDS),
    hasAssociationPrivileges: computed.apply(void 0, PRIVILEGE_CONVERSATION_ASSOCIATION.concat([function () {
      var _this = this;

      return PRIVILEGE_CONVERSATION_ASSOCIATION.some(function (privilege) {
        return _this.get(privilege);
      });
    }])),
    contactRoute: computed('isRelateEngineAvailable', function () {
      return 'relate.contacts-ui-redirect'; // todo: clean up with redirects
    })
  });

  _exports.default = _default;
});