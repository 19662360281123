define("web-directory/utils/intl/missing-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = missingMessage;
  var serverPrefix = 'server_translations';

  function missingMessage(key, locales, suppressError) {
    if (typeof suppressError === 'string') {
      return suppressError;
    } else if (!key) {
      return '';
    } else if (key.indexOf(serverPrefix) !== -1) {
      // When a labelKey is typed in, it is user specified and typed in manually.
      // Show this value rather than the full key if no translation was found.
      return key.split('_').pop();
    } else {
      return key;
    }
  }
});