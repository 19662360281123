define("web-directory/components/group-profile/membership-entry/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed,
      Component = Ember.Component;

  var _default = Component.extend({
    classNames: ['group-membership-entry'],
    chat: inject.service(),
    intl: inject.service(),
    session: inject.service(),
    notification: inject.service(),
    attrs: {
      person: null,
      group: null,
      individuals: [],
      editing: false,
      membership: [],
      deletePerson: ''
    },
    actions: {
      deletePerson: function deletePerson() {
        var group = this.get('group');
        var person = this.get('person');

        if (group.isPersonOwner(person) && this.get('group.owners.owners.length') === 1) {
          var title = this.get('entity.name');
          var message = this.get('intl').lookup('notification.group.noOwners');
          this.get('notification').info(title, message);
        } else {
          this.sendAction('deletePerson', person, this.get('memberTypeList'));
        }
      }
    },
    showChat: computed('isLoggedInUser', 'chat.enabled', function () {
      return !this.get('isLoggedInUser') && this.get('chat.enabled');
    }),
    isLoggedInUser: computed('person', 'session.person', function () {
      var person = this.get('person');
      var sessionPerson = this.get('session.person');
      return person.get('id') === sessionPerson.get('id');
    }),
    cannotDeleteMember: computed('isLoggedInUser', 'group.owners.owners.@each.value', function () {
      var person = this.get('person');
      var group = this.get('group');
      var isOwnerAndSelf = group.isPersonOwner(person) && this.get('isLoggedInUser');
      var multipleOwners = group.get('owners.owners.length') > 1;
      return isOwnerAndSelf && !multipleOwners;
    }),
    canViewRules: computed('group.membershipVisible', 'session.person', 'session.user.isTrustee', function () {
      if (this.get('session.user.isTrustee')) {
        return true;
      }

      var group = this.get('group');
      var person = this.get('session.person');
      var visible = this.get('group.membershipVisible');
      var namedPermissions = person.get('namedPermissions');
      var hasPermissions = namedPermissions.includes('admin') || namedPermissions.includes('group_administration');

      if (!visible) {
        return hasPermissions || group.isPersonOwner(person);
      }

      return true;
    }),
    memberType: computed('person', 'group', 'isCurrentMember', 'individuals.[]', 'group.isDynamicGroup', function () {
      var intl = this.get('intl');
      var group = this.get('group');
      var person = this.get('person');
      var individuals = this.get('individuals');
      var translation = intl.lookup('common.removed');

      if (this.get('isCurrentMember')) {
        if (group.isPersonOwner(person)) {
          translation = intl.lookup('groups.memberTypes.owner');
        } else if (_lodash.default.includes(individuals, person) || group.isPersonIndividual(person)) {
          translation = intl.lookup('groups.memberTypes.individual');
        } else if (this.get('group.isDynamicGroup')) {
          translation = intl.lookup('groups.memberTypes.dynamic');
        } else {
          translation = intl.lookup('groups.memberTypes.rule');
        }
      }

      return translation.toString();
    }),
    memberTypeList: computed('person', 'group', function () {
      var group = this.get('group');
      var person = this.get('person');
      var paths = [];

      if (group.isPersonOwner(person)) {
        paths.push('owner');
      }

      if (group.isPersonIndividual(person)) {
        paths.push('individual');
      }

      return paths;
    }),
    isRule: computed('person', 'group', 'isAddedIndividual', function () {
      var group = this.get('group');
      var person = this.get('person');
      return !group.isPersonOwner(person) && !group.isPersonIndividual(person) && !this.get('isAddedIndividual');
    }),
    isLinkable: computed.not('editing'),
    isAddedIndividual: computed('person', 'individuals.[]', 'membership.[]', function () {
      var id = this.get('person.id');
      var membership = this.get('membership').mapBy('guid');
      return _lodash.default.includes(membership, id);
    }),
    isCurrentMember: computed('group.owners.owners.@each.value', 'group.individuals.individuals.@each.value', 'isAddedIndividual', function () {
      var group = this.get('group');
      var person = this.get('person');
      return group.isPersonOwner(person) || group.isPersonIndividual(person) || this.get('isAddedIndividual');
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.attachTooltip();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.$('.delete').tooltip('destroy');
    },
    didUpdateAttrs: function didUpdateAttrs(_ref) {
      var newAttrs = _ref.newAttrs,
          oldAttrs = _ref.oldAttrs;

      if (newAttrs.editing.value !== oldAttrs.editing.value) {
        this.attachTooltip();
      }
    },
    attachTooltip: function attachTooltip() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', function () {
        if (_this.get('editing') && _this.get('isRule')) {
          _this.$('.delete').tooltip({
            title: _this.get('intl').lookup('groups.cannotRemoveRuleEntry').toString(),
            placement: 'left'
          });
        }
      });
    },
    click: function click(event) {
      var target = Ember.$(event.target);

      if (target.is('.person-name')) {
        this.bubbleAction({
          action: 'transitionToEntityDetail',
          actionContext: [this.get('person')]
        });
      }
    }
  });

  _exports.default = _default;
});