define("web-directory/models/fax", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Object.extend({
    intl: inject.service(),
    id: null,
    name: null,
    dateCreated: null,
    dateModified: null,
    contentUri: null,
    workspace: null,
    createdBy: null,
    contentType: null,
    contentLength: null,
    filename: null,
    read: false,
    thumbnails: null,
    selfUri: null,
    callerAddress: '',
    receiverAddress: '',
    selected: false,
    isDefaultName: computed('name', 'callerAddress', function () {
      var name = this.get('name');

      if (!name) {
        return false;
      }

      return name.toLowerCase().indexOf('fax from ') === 0;
    }),
    faxName: computed('isDefaultName', 'name', 'callerAddress', function () {
      if (this.get('isDefaultName')) {
        return this.get('intl').t('notification.inbox.faxFrom', {
          callerAddress: this.get('callerAddress')
        }).toString();
      }

      return this.get('name');
    }),
    faxFrom: computed('callerAddress', 'createdBy', function () {
      var callerAddress = this.get('callerAddress');

      if (callerAddress) {
        return callerAddress;
      }

      return this.get('intl').lookup('notification.inbox.unknownSender');
    }),
    faxFile: computed('filename', 'name', function () {
      var name = this.get('name');

      if (name) {
        return name;
      }

      return this.get('filename');
    }),
    displayThumbnail: computed('thumbnails.[]', function () {
      var thumbnails = this.get('thumbnails');
      return thumbnails[0] || {
        imageUri: null
      };
    }),

    /**
     *  Converts 'this' into an object that can be passed to the faxes standalone app
     * @returns An object with all of the properties of 'this' that are specified in props
     */
    getProperties: function getProperties() {
      var _this = this;

      var props = ['id', 'name', 'dateCreated', 'dateModified', 'contentUri', 'workspace', 'createdBy', 'contentType', 'contentLength', 'filename', 'read', 'thumbnails', 'pageCount', 'selfUri', 'callerAddress', 'receiverAddress', 'selected'];
      return props.reduce(function (accumulator, prop) {
        return Object.assign(accumulator, _defineProperty({}, prop, _this.get(prop)));
      }, {});
    }
  });

  _exports.default = _default;
});