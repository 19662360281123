define("web-directory/instance-initializers/reopen-component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'reopen-component',
    initialize: function initialize(instance) {
      var session = instance.lookup('service:session');
      var preferences = instance.lookup('service:preferences');
      Ember.Component.reopen({
        bubbleAction: function bubbleAction(data) {
          try {
            this.triggerAction(data);
          } catch (e) {// Turn on to debug component stack
            // Ember.Logger.warn(e.message, e.stack);
          }

          var parentView = this.get('parentView');

          if (parentView && parentView.bubbleAction) {
            parentView.bubbleAction(data);
          }
        }
      });
      Ember.Component.reopen({
        init: function init() {
          this._super();

          var services = this.get('needsServices');

          if (!services) {
            services = [];
          }

          var current = this.get('services');

          if (!current) {
            current = {};
          }

          _lodash.default.each(services, function (name) {
            var service = instance.lookup('service:' + name);
            Ember.set(current, name, service);
          });

          this.set('services', current);

          if (!this.get('session')) {
            this.set('session', session);
          }

          if (!this.get('preferences')) {
            this.set('preferences', preferences);
          }
        }
      });
    }
  };
  _exports.default = _default;
});