define("web-directory/services/center-stage-frame", ["exports", "web-directory/iframe-client-config", "web-directory/utils/query-parser", "boon-js", "lodash/groupBy"], function (_exports, _iframeClientConfig, _queryParser, _boonJs, _groupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var computed = Ember.computed;

  var _default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    sidebar: Ember.inject.service('sidebar'),
    centerStageClients: null,
    activeRoute: null,
    // Prompt the user with this message if they try to leave the current route
    promptOnLeave: null,
    // Group center stage clients by assigned path, and where duplicates are assigned to the same
    // path, resolve that conflict.
    activeCenterStageClients: computed('centerStageClients', 'session.features', function () {
      return this.filterActiveClients(this.get('centerStageClients'));
    }),
    clientConfigList: computed('activeCenterStageClients', function () {
      return Object.values(this.get('activeCenterStageClients')).sort(function (client1, client2) {
        // This leads to testing longer routes for matches first when using `find`
        // This protects iframe configs with routes that are a substring of
        // another route from accidentally matching the longer route.
        // E.G. for the frame routes `/foo/bar` and `/foo`, a transition to
        // `/foo/bar/baz` will always be checked against `/foo/bar` first and
        // exit before it can be tried against `/foo`.
        return client2.assignedRoute.length - client1.assignedRoute.length;
      });
    }),
    // Collect all of the query parameters that clients care about so they can
    // be registered for the one actual center-stage-frame route
    allClientQueryParams: computed('centerStageClients', function () {
      return _toConsumableArray(new Set(Object.values(this.get('centerStageClients')).filter(function (client) {
        return client.queryParams && client.queryParams.length;
      }).map(function (client) {
        return client.queryParams;
      }).flat()));
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('centerStageClients', this.applyQueryOverrides(_iframeClientConfig.centerStageClients));
    },
    promptOnLeaveChanged: Ember.observer('promptOnLeave', function promptOnLeaveChanged() {
      var promptOnLeave = this.get('promptOnLeave'); // Always unregister any prior beforeunload handler. Even if the promptOnLeave value changed from one string
      // to another string, we still will unregister the old handler and re-register a new one.

      if (typeof this.get('promptOnLeaveBeforeUnload') === 'function') {
        window.removeEventListener('beforeunload', this.get('promptOnLeaveBeforeUnload'));
        this.set('promptOnLeaveBeforeUnload', null);
      } // If we have a promptOnLeave message, then register a new beforeunload handler for it.


      if (typeof promptOnLeave === 'string' && promptOnLeave.length > 0) {
        this.set('promptOnLeaveBeforeUnload', function (e) {
          return e.returnValue = promptOnLeave;
        });
        window.addEventListener('beforeunload', this.get('promptOnLeaveBeforeUnload'));
      }
    }),
    filterActiveClients: function filterActiveClients(rawClients) {
      var _this = this;

      var computedConfig = {}; // const rawClients = this.get('centerStageClients');

      var pathGroups = (0, _groupBy.default)(Object.entries(rawClients), function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            clientConfig = _ref2[1];

        return clientConfig.assignedRoute;
      });
      Object.values(pathGroups).forEach(function (pathClients) {
        var _this$findActiveClien = _this.findActiveClient(pathClients),
            _this$findActiveClien2 = _slicedToArray(_this$findActiveClien, 2),
            clientId = _this$findActiveClien2[0],
            clientInfo = _this$findActiveClien2[1];

        computedConfig[clientId] = clientInfo;
      });
      return computedConfig;
    },
    updateActiveRoute: function updateActiveRoute() {
      // Always clear the promptOnLeave value when the route changes to remove the beforeunload handler if present
      this.set('promptOnLeave', null);

      var appRoute = this._getWindowHash().slice(1);

      if (this.isEnabledClientRoute(appRoute)) {
        this.set('activeRoute', appRoute);
        this.get('sidebar').ensureRouteVisibility();
      } else {
        this.set('activeRoute', null);
      }
    },
    isEnabledClientRoute: function isEnabledClientRoute(path) {
      var config = this.configByPath(path);

      if (!config) {
        return false;
      } else {
        return this.isConfigEnabled(config);
      }
    },
    navForPath: function navForPath(path) {
      var config = this.configByPath(path);

      if (config) {
        return config.nav || null;
      } else {
        return null;
      }
    },
    toggleForPath: function toggleForPath(path) {
      var config = this.configByPath(path);

      if (config) {
        return config.featureToggle || null;
      } else {
        return null;
      }
    },
    baseRouteForPath: function baseRouteForPath(path) {
      var config = this.configByPath(path);

      if (config) {
        return config.assignedRoute || null;
      } else {
        return null;
      }
    },
    applyQueryOverrides: function applyQueryOverrides(clientConfig) {
      var queryParams = this._getParsedQueryParams();

      Object.keys(clientConfig).forEach(function (clientId) {
        var urlOverride = queryParams["center-stage-".concat(clientId)];

        if (urlOverride !== undefined) {
          if (typeof clientConfig[clientId].timeMachineUrl === 'function') {
            clientConfig[clientId].url = clientConfig[clientId].timeMachineUrl(urlOverride);
          } else {
            clientConfig[clientId].url = urlOverride;
          }
        }
      });
      return clientConfig;
    },
    // Given an path within the application, figure out which clientConfing (if any) should be used to
    // evaluate it
    configByPath: function configByPath(path) {
      var sortedConfigList = this.get('clientConfigList');

      if (!path) {
        return null;
      } else {
        return sortedConfigList.find(function (clientConfig) {
          return path.startsWith(clientConfig.assignedRoute);
        });
      }
    },
    // Given multiple client id/config pairs (with matching paths), find one with a feature toggle
    // active, or take the first one in the list if none are active.
    findActiveClient: function findActiveClient(candidateClients) {
      var _this2 = this;

      var enabledClient = candidateClients.find(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 2),
            clientConfig = _ref4[1];

        return _this2.isConfigEnabled(clientConfig);
      });
      return enabledClient || candidateClients[0];
    },
    isConfigEnabled: function isConfigEnabled(clientConfig) {
      var featureExpression = clientConfig.featureToggle && clientConfig.featureToggle.key; // If there is a feature toggle expression

      if (featureExpression) {
        var evaluator = (0, _boonJs.getEvaluator)(featureExpression); // Evaluate it with boon

        var toggles = this.get('session.features') || {};
        return evaluator(toggles);
      } else {
        // Routes without toggles are considered active
        return true;
      }
    },
    // For test mocking
    _getWindowHash: function _getWindowHash() {
      return window.location.hash;
    },
    _getParsedQueryParams: function _getParsedQueryParams() {
      return (0, _queryParser.parseQueryParams)();
    }
  });

  _exports.default = _default;
});