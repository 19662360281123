define("web-directory/components/acd-interactions-panel/interaction-responses/structured-response-detail/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['structured-response-detail'],
    classNameBindings: ['enabled'],
    intl: Ember.inject.service(),
    interaction: null,
    responses: Ember.inject.service(),
    response: null,
    enabled: true,
    isSearchMode: false,
    isResponseExpanded: false,
    responsePreview: null,
    sendAttempted: false,
    responseText: Ember.computed.reads('responseFields.initialText'),
    responseSubstitutions: Ember.computed.reads('responseFields.substitutions'),
    warningIcon: Ember.computed.reads('', function () {
      return this.get('applicationService.urlprefix') + 'assets/images/svg/ic_alert_triangle.svg';
    }),
    validationError: Ember.computed('uniqueSubstitutions.@each.validationError', 'sendAttempted', function () {
      var hasEmpty = !_lodash.default.isEmpty(this.get('uniqueSubstitutions')) && this.get('uniqueSubstitutions').mapBy('validationError').reduce(function (a, b) {
        return a || !!b;
      }, false);
      return this.get('sendAttempted') && hasEmpty;
    }),
    validateInputFields: Ember.observer('uniqueSubstitutions.@each.value', 'sendAttempted', function () {
      var _this = this;

      Ember.run.throttle(this, function () {
        _this.get('responseSubstitutions').forEach(function (sub) {
          if (_lodash.default.isEmpty(sub.value)) {
            // first, try to find matching value from uniqueSubstitutions
            var uniq = _this.get('uniqueSubstitutions').findBy('id', sub.id);

            if (uniq && uniq.value) {
              Ember.set(sub, 'value', uniq.value);
            } else {
              Ember.set(sub, 'validationError', true);
            }
          } else {
            Ember.set(sub, 'validationError', false);
          }
        });
      }, 500);
    }),
    // if two fields have the same name, they edit the same value, so there should only be 1 input field
    uniqueSubstitutions: Ember.computed('responseSubstitutions', function () {
      return this.get('responseSubstitutions').uniqBy('id');
    }),
    actions: {
      toggleOpen: function toggleOpen() {
        this.get('response').toggleProperty('isOpen');
      },
      sendResponse: function sendResponse() {
        this.set('sendAttempted', true);
        var hasEmptyFields = this.get('validationError');

        if (!hasEmptyFields) {
          var response = {
            structuredMessage: {
              responseId: this.get('response.id'),
              parameters: this.get('responseSubstitutions') ? this.get('responseSubstitutions') : this.get('response.substitutions')
            },
            text: this.get('responsePreview'),
            substitutedResponse: this.get('_substitutedResponse'),
            // We stub in a data prop so we can access the default response pojo model in both cases
            data: this.get('response.data')
          };
          this.sendAction('sendResponse', response);
          this.set('sendAttempted', false);
        }
      },
      updateSubstitutions: function updateSubstitutions(event) {
        var substitutions = this.get('responseSubstitutions');
        var modifiedIndex = substitutions.findIndex(function (substitution) {
          return substitution.id === event.target.id;
        });
        Ember.set(substitutions[modifiedIndex], 'value', event.target.value);
        this.set('responseSubstitutions', substitutions);
      }
    },
    updatePreview: Ember.observer('responseSubstitutions.@each.value', function () {
      this._updatePreview();
    }),
    _updatePreview: function _updatePreview() {
      var substitutions = this.get('responseSubstitutions');
      var updatedText = this.get('responseText');
      substitutions.forEach(function (substitution) {
        var substitutionToken = '{\\{' + substitution.id + '\\}}';

        if (!_lodash.default.isEmpty(substitution.value)) {
          updatedText = updatedText.replace(new RegExp(substitutionToken, 'g'), substitution.value);
        }
      });
      var strippedText = $('<div></div>').html($('<div></div>').html(updatedText).html().replace(/(<br>)|(<\/p><p>)/g, '\n')).text();
      this.set('responsePreview', strippedText);
      this.set('_substitutedResponse', updatedText);
    },
    getResponseSubstitutionFields: function getResponseSubstitutionFields() {
      var responses = this.get('responses');
      var response = this.get('response');

      if (response && responses) {
        return responses.replaceSubstitutionTokens(response);
      }

      return [];
    },
    init: function init() {
      this._super.apply(this, arguments);

      var responseFields = this.getResponseSubstitutionFields();
      this.set('responseFields', responseFields);

      this._updatePreview();
    }
  });

  _exports.default = _default;
});