define("web-directory/templates/components/webhooks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jWarDv/G",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"media-item webhooks collapsed\"],[13],[0,\"\\n    \"],[11,\"span\",[]],[15,\"class\",\"webhooks-header\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[16,\"class\",[34,[\"status \",[26,[\"webhooksStatus\"]]]]],[13],[14],[0,\"\\n        \"],[1,[33,[\"html-safe\"],[[28,[\"webhooksHeader\"]]],null],false],[0,\"\\n    \"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/templates/components/webhooks.hbs"
    }
  });

  _exports.default = _default;
});