define("web-directory/components/search-result-context/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SearchResultContextComponent = Ember.Component.extend({
    classNameBindings: ['searchResultContextVisible:search-result-context-area'],
    searchResultContextVisible: false,
    _scrollToPreviousTop: function _scrollToPreviousTop(messages, $messagePane, $lastMessage) {
      var _this = this;

      if (messages.length > 0) {
        Ember.run.scheduleOnce('afterRender', null, function () {
          var targetHeight = $lastMessage.position().top - $lastMessage.height() - _this.$('.history-bar').outerHeight();

          $messagePane.animate({
            scrollTop: targetHeight
          });
        });
      }
    },
    closeOnContextSwitch: Ember.observer('chatService.activeRoom', function () {
      this.send('close');
    }),
    actions: {
      close: function close() {
        this.attrs.close();
      },
      replyToMessage: function replyToMessage(message) {
        this.attrs.close();
        this.attrs.replyToMessage(message);
      },
      loadHistoryAfterLast: function loadHistoryAfterLast() {
        var promise = this.attrs.loadMoreHistoryAfter(this.get('historyRoom'));
        promise.then(function (messages) {
          return messages;
        });
      },
      loadHistoryBeforeFirst: function loadHistoryBeforeFirst() {
        var _this2 = this;

        var $messagePane = this.$('.search-result-context-messages');
        var $lastMessage = this.$('.search-result-context .chat-message').first();
        var promise = this.attrs.loadMoreHistoryBefore(this.get('historyRoom'));
        promise.then(function (messages) {
          _this2._scrollToPreviousTop(messages, $messagePane, $lastMessage);

          return messages;
        });
      }
    }
  });
  var _default = SearchResultContextComponent;
  _exports.default = _default;
});