define("web-directory/instance-initializers/backburner-debug", ["exports", "ember-purecloud-components/instance-initializers/backburner-debug"], function (_exports, _backburnerDebug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _backburnerDebug.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _backburnerDebug.initialize;
    }
  });
});