define("web-directory/components/inbox-panel-v2/reporting-alert/reporting-alert-summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "arFki/Hv",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"reporting-alert-summary name\"],[13],[0,\"\\n\\n    \"],[11,\"div\",[]],[15,\"class\",\"reporting-alert-rule-name-container\"],[13],[0,\"\\n        \"],[11,\"p\",[]],[15,\"class\",\"reporting-alert-rule-name\"],[13],[0,\"\\n            \"],[1,[33,[\"t\"],[\"notification.inbox.reportingAlertSummary.ruleName\"],[[\"mainEntity\",\"ruleName\",\"separator\"],[[28,[\"mainEntity\"]],[28,[\"ruleName\"]],[28,[\"ruleNameSeparator\"]]]]],false],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\\n    \"],[11,\"div\",[]],[15,\"class\",\"reporting-alert-entity-details-container\"],[13],[0,\"\\n\"],[6,[\"each\"],[[33,[\"await\"],[[28,[\"otherEntityDetails\"]]],null]],null,{\"statements\":[[6,[\"if\"],[[28,[\"otherEntityDetail\",\"names\"]]],null,{\"statements\":[[0,\"                \"],[11,\"p\",[]],[15,\"class\",\"reporting-alert-entity-detail\"],[13],[0,\"\\n                    \"],[1,[33,[\"t\"],[\"notification.inbox.reportingAlertSummary.entityDetail\"],[[\"type\",\"names\"],[[28,[\"otherEntityDetail\",\"type\"]],[28,[\"otherEntityDetail\",\"names\"]]]]],false],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[\"otherEntityDetail\"]},null],[0,\"    \"],[14],[0,\"\\n\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/inbox-panel-v2/reporting-alert/reporting-alert-summary/template.hbs"
    }
  });

  _exports.default = _default;
});