define("web-directory/services/timezones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _zeroPad(num) {
    var s = num + '';
    return s.length >= 2 ? s : '0' + s;
  }
  /**
   * Formats the timeZone offset.
   * @param offset - the timezone offset in minutes
   * @returns {string} - a string of the format '+HH:mm' or '-HH:mm'
   * @private
   */


  function _formatOffset(offset) {
    var hrs = Math.floor(Math.abs(offset) / 60);
    var mins = Math.abs(offset) % 60;
    var sign = offset >= 0 ? '+' : '-';
    return sign + _zeroPad(hrs) + ':' + _zeroPad(mins);
  }

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.loadOnAuthenticated();
    },
    loadOnAuthenticated: Ember.observer('session.isAuthenticated', function () {
      var _this = this;

      if (!this.get('session.isAuthenticated')) {
        return;
      }

      var timeZones = this.getTimeZones(); // set the cache to the promise immediately so that multiple rapid calls will get the promise
      // and can all wait for the result

      this.set('timeZoneCache', timeZones);
      timeZones.catch(function (err) {
        // if the cache (promise) fails, log it and clear it out
        Ember.Logger.error('Failed to load timezones', {
          error: err
        });

        _this.set('timeZoneCache', null);
      });
    }),

    /**
     * Localizes and formats raw data for timeZones.
     * @param timeZones
     * @returns {array} a list of localized timeZones with the following properties:
     *     id                           - the id of the timeZone
     *     formattedOffset              - the offset formatted as '+HH:mm' or '-HH:mm'
     *     localName                    - the localized name of the timeZone
     *     localNameWithFormattedOffset - the localized name of the time zone, including the formatted offset
     *     offset                       - the offset of the timeZone in minutes
     */
    localizeAndFormatTimeZones: function localizeAndFormatTimeZones(timeZones) {
      var intl = this.get('intl');
      return timeZones.map(function (timeZone) {
        var localName = intl.lookup('common.timeZone.' + timeZone.id);

        var formattedOffset = _formatOffset(timeZone.offset);

        var localNameWithFormattedOffset = intl.t('common.timeZone.withOffset', {
          timeZoneName: localName,
          timeZoneOffset: formattedOffset
        });
        return {
          localName: localName,
          formattedOffset: formattedOffset,
          localNameWithFormattedOffset: localNameWithFormattedOffset,
          id: timeZone.id,
          offset: timeZone.offset
        };
      }).filter(function (timeZone) {
        return timeZone.localName;
      });
    },
    getTimeZones: function getTimeZones(cacheBust) {
      var _this2 = this;

      var cache = this.get('timeZoneCache');

      if (cache && !cacheBust) {
        return cache;
      }

      return this.get('ajax').ajaxGet('/platform/api/v2/timeZones?pageSize=1000').then(function (res) {
        return res.entities ? _this2.localizeAndFormatTimeZones(res.entities) : [];
      });
    }
  });

  _exports.default = _default;
});