define("web-directory/components/entity-fields/validators/location", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    isValid: function isValid(location) {
      if (!location) {
        return false;
      }

      var value = location.value;
      return !_lodash.default.isEmpty(value.locationId);
    },
    isEmpty: function isEmpty(location) {
      if (!location) {
        return true;
      }

      var value = location.value;
      return value === undefined || value === null || _lodash.default.isEmpty(value);
    }
  };
  _exports.default = _default;
});