define("web-directory/components/skillgroup-profile/dynamic-rules/condition-display/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var observer = Ember.observer,
      Component = Ember.Component,
      inject = Ember.inject;

  var _default = Component.extend({
    classNames: ['condition-display'],
    intl: inject.service(),
    comparators: null,
    values: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('values', [{
        name: '0',
        value: 0
      }, {
        name: '1',
        value: 1
      }, {
        name: '2',
        value: 2
      }, {
        name: '3',
        value: 3
      }, {
        name: '4',
        value: 4
      }, {
        name: '5',
        value: 5
      }]);
      this.set('operators', {
        Not: 'Not',
        And: 'And',
        Or: 'Or'
      });
      this.set('comparators', [{
        label: 'equals (==)',
        value: 'EqualTo',
        class: 'equalto',
        op: '=='
      }, {
        label: 'not equals (!=)',
        value: 'NotEqualTo',
        class: 'notequalto',
        op: '!='
      }, {
        label: 'greater than (>)',
        value: 'GreaterThan',
        class: 'greaterthan',
        op: '>'
      }, {
        label: 'greater than or equal to (>=)',
        value: 'GreaterThanOrEqualTo',
        class: 'greaterthanorequalto',
        op: '>='
      }, {
        label: 'less than (<)',
        value: 'LessThan',
        class: 'lessthan',
        op: '<'
      }, {
        label: 'less than or equal to (<=)',
        value: 'LessThanOrEqualTo',
        class: 'lessthanorequalto',
        op: '<='
      }]);
      this.get('condition.rules').forEach(function (rule) {
        //check to see if we're starting fresh
        if (rule.comparator === null) return; //set comparators to proper objects

        var compPlace = _this.get('comparators').mapBy('op').indexOf(rule.comparator.op);

        if (compPlace >= 0) {
          Ember.set(rule, 'comparator', _this.get('comparators')[compPlace]);
        } else {
          Ember.set(rule, 'comparator', null);
        }

        if (!isNaN(rule.proficiency.value)) {
          Ember.set(rule, 'proficiency', _this.get('values')[rule.proficiency.value]);
        }
      });
      Ember.run.scheduleOnce('afterRender', this, function () {
        _this.set('condition.display', _this.display());
      });
    },
    maximumConditions: Ember.computed('condition.rules.length', function () {
      return this.get('condition.rules.length') >= 10;
    }),
    updateConditionDisplay: observer('condition', 'condition.rules.@each.{skill,comparator,proficiency,operation,lastRule}', function () {
      this.set('condition.display', this.display());
      this.set('condition.length', this.get('condition.rules').length);
    }),
    actions: {
      addRule: function addRule() {
        var rules = this.get('condition').rules;
        rules.forEach(function (rule) {
          Ember.set(rule, 'lastRule', false);
        });
        rules.pushObject({
          skill: null,
          comparator: null,
          proficiency: null,
          operation: 'And',
          lastRule: true
        });
      },
      removeRule: function removeRule(index) {
        var con = this.get('condition').rules;
        Ember.A(con).removeAt(index);
        con.forEach(function (rule) {
          Ember.set(rule, 'lastRule', false);
        });
        con.set('lastObject.lastRule', true);
        this.set('condition.rules', con);
      },
      ruleOperation: function ruleOperation(rule, op) {
        Ember.set(rule, 'operation', op);
      },
      skillChanged: function skillChanged(rule, skill, value) {
        if (value) {
          Ember.set(rule, skill, value);
        }
      }
    },

    /**
     * This is the same function found in `/components/group-profile/dynamic-rules/condition-display` component.
     * TODO: Remove these functions from component level and make reusable util.
     */
    display: function display() {
      var rules = this.get('condition.rules');
      var display = '(';
      var valid = true;

      var _iterator = _createForOfIteratorHelper(rules),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var rule = _step.value;
          // if a rule is not valid then break the rest of the for-loop and set the valid to `false`
          var hasEmptyFields = !rule.skill || !rule.comparator || !rule.proficiency;

          if (hasEmptyFields) {
            valid = false;
            return;
          }

          if (rule !== rules[0]) {
            display += "".concat(this.get('intl').t("groups.expressions.".concat(rule.operation.toLowerCase())), " ");
          }

          display += "".concat(rule.skill.name, " ").concat(rule.comparator.op, " ").concat(rule.proficiency.value);

          if (rule.lastRule) {
            display += ')';
          } else {
            display += ' ';
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return valid ? display : null;
    }
  });

  _exports.default = _default;
});