define("web-directory/components/acd-interactions-panel/interaction-card-v2/alerting-workitem-card/component", ["exports", "web-directory/utils/acd", "web-directory/utils/dates"], function (_exports, _acd, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['acd-interaction-card-v2-alerting'],
    intl: Ember.inject.service('intl'),
    logger: Ember.inject.service('stashLogger'),
    participantName: Ember.inject.service('participant-name'),

    /**
     * @Attribute
     * Workitem represented by card
     */
    workitemInteraction: null,

    /**
     * Show alerting timeout if timeout value available
     */
    showAlertingTimeout: Ember.computed.bool('workitemInteraction.workitem.alertTimeoutSeconds'),

    /**
     * Alerting time end for workitem interaction
     */
    alertingTimeEnd: Ember.computed('workitemInteraction', function () {
      var timeoutValue = this.get('workitemInteraction.workitem.alertTimeoutSeconds');
      var alertingStartTime = this.get('workitemInteraction.workitem.dateAssignmentStateChanged');
      return (0, _dates.default)(alertingStartTime).add(timeoutValue, 's');
    }),

    /**
     * @DidInsertElement
     * Animate the alerting timeout bar
     */
    animateAlertingTimeout: function animateAlertingTimeout() {
      var workitemInteraction = this.get('workitemInteraction');
      var timeoutSeconds = workitemInteraction === null || workitemInteraction === void 0 ? void 0 : workitemInteraction.workitem.alertTimeoutSeconds;
      var alertingStartTime = workitemInteraction === null || workitemInteraction === void 0 ? void 0 : workitemInteraction.workitem.dateAssignmentStateChanged;

      if (!timeoutSeconds || !alertingStartTime) {
        return;
      }

      var elapsedTime = (0, _dates.default)().diff((0, _dates.default)(alertingStartTime), 's');
      var timeRemainingMs = (timeoutSeconds - elapsedTime) * 1000;
      var elapsedPercent = elapsedTime / timeoutSeconds * 100;
      /*
       Hide if time remaining is greater than alerting timeout when agent transfer, queue transfer alert
       timeout expiry should be handled by assignment service.
       */

      if (timeRemainingMs < 0) {
        this.$('.int-timeout').css('width', "100%");
        return;
      }

      this.$('.int-timeout').css('width', "".concat(elapsedPercent, "%"));

      if (elapsedPercent < 100) {
        this.$('.int-timeout').animate({
          width: '100%'
        }, timeRemainingMs, 'linear');
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.animateAlertingTimeout();
    },

    /**
     * @Action
     * Closure action passed to the component to be invoked at the parent level
     */
    actionAnswerWorkitemInteraction: function actionAnswerWorkitemInteraction(workitemInteraction) {
      return (0, _acd.warnClosureAction)('actionAnswerWorkitemInteraction', {
        workitemInteraction: workitemInteraction
      });
    },

    /**
     * @Action
     * Closure action passed to the component to be invoked at the parent level
     */
    actionDeclineWorkitemInteraction: function actionDeclineWorkitemInteraction(workitemInteraction) {
      return (0, _acd.warnClosureAction)('actionDeclineWorkitemInteraction', {
        workitemInteraction: workitemInteraction
      });
    },
    actions: {
      answer: function answer(evt) {
        evt.preventDefault(); // ACE-2364

        this.actionAnswerWorkitemInteraction(this.get('workitemInteraction'));
      },
      decline: function decline(evt) {
        evt.preventDefault(); // ACE-2364

        this.actionDeclineWorkitemInteraction(this.get('workitemInteraction'));
      }
    }
  });

  _exports.default = _default;
});