define("web-directory/adapters/application", ["exports", "ember-data", "ember-inflector", "web-directory/models/group", "web-directory/models/person", "web-directory/search/adapter-mixin"], function (_exports, _emberData, _emberInflector, _group, _person, _adapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var versions = {
    people: 'v2',
    groups: 'v2',
    locations: 'v1',
    users: 'v1',
    messages: 'v1',
    notifications: 'v1'
  };

  var ApplicationAdapter = _emberData.default.RESTAdapter.extend(_adapterMixin.default, {
    logger: Ember.inject.service('stash-logger'),
    session: Ember.inject.service(),
    defaultSerializer: 'application',
    namespace: 'api',
    shouldReloadAll: function shouldReloadAll() {
      return true;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },
    normalizeErrorResponse: function normalizeErrorResponse(status, headers, payload) {
      // force json-api error format
      var data = {};
      data.errors = [payload];
      return this._super(status, headers, data);
    },
    modelFor: function modelFor(type) {
      return this.pathForType(type);
    },
    pathForType: function pathForType(type) {
      if (type.modelName) {
        type = type.modelName;
      }

      type = Ember.String.camelize(type);
      return (0, _emberInflector.pluralize)(type);
    },
    createRecord: function createRecord(store, type, record) {
      var snapshot = record._createSnapshot();

      var data = store.serializerFor(type.modelName).serialize(snapshot, {
        includeId: true
      });
      return this.ajax(this.buildURL(type.modelName), 'POST', {
        data: data
      });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      store.serializerFor(type.modelName).serializeIntoHash(data, type, snapshot);

      if (data[type.modelName]) {
        data = data[type.modelName];
      }

      Ember.set(data, 'version', snapshot.attr('version'));
      return this.ajax(this.urlForUpdateRecord(snapshot.id, type.modelName), 'POST', {
        data: data
      });
    },
    buildURL: function buildURL(type, id) {
      return this._directoryUrl(type, id);
    },
    findRecord: function findRecord(store, type, id, snapshot) {
      var data = {};
      var traditional = true;

      if (type.modelName === 'person') {
        data = Object.assign({}, data, _person.FULL_PERSON);
      } else {
        data = Object.assign({}, data, {
          fl: '*'
        });
      }

      return this.ajax(this.buildURL(type.modelName, id, snapshot, 'findRecord'), 'GET', {
        data: data,
        traditional: traditional
      });
    },
    findQuery: function findQuery() {
      return this.query.apply(this, arguments);
    },
    entityEndpoint: function entityEndpoint(path, type, id) {
      var modelEndpoint = this.pathForType(type);
      var apiVersion = versions[modelEndpoint];
      var host = !!this.host ? this.host : '';

      if (type === 'person') {
        return "".concat(host, "/platform/directory/").concat(this.namespace, "/").concat(apiVersion, "/").concat(modelEndpoint, "/").concat(id, "/").concat(path);
      }

      return "".concat(host, "/").concat(this.namespace, "/").concat(apiVersion, "/").concat(modelEndpoint, "/").concat(id, "/").concat(path);
    },
    findMultiEntity: function findMultiEntity(store, params, requestType) {
      if (!params) {
        params = {
          limit: 25,
          fl: '*',
          _entity: 'all',
          facets: '*'
        };
      }

      if (requestType === 'search') {
        // new style search instead of query
        params = Object.assign({}, params, {
          returnFields: [].concat(_toConsumableArray(_person.BASE_PERSON.fl.split(',')), _toConsumableArray(_group.BASE_GROUP_FIELDS))
        });
        return this.performSearch(params);
      }

      return Ember.RSVP.reject(new Error('old style search has been removed. Please update to new search'));
    },
    findFavorites: function findFavorites(store, type, id, params) {
      return this.findFavoritesV3(store, type, id, params);
    },
    findFavoritesV3: function findFavoritesV3(store, type, id) {
      var _this = this;

      var params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      var modelEndpoint = this.pathForType('person');
      var host = !!this.host ? this.host : '';
      var url = "".concat(host, "/directory/").concat(this.namespace, "/v3/").concat(modelEndpoint, "/").concat(id, "/favorites");
      var deferred = Ember.RSVP.defer();

      this._findFavoritesV3(url, params).then(function (res) {
        _this._handleFavorites(res, [], deferred, params, url, 1);
      }).catch(function (error) {
        _this.get('logger').error('Error trying to find favorites:', {
          error: error,
          params: params
        });

        deferred.resolve([]);
      });

      return deferred.promise;
    },
    _findFavoritesV3: function _findFavoritesV3(url, params) {
      var page = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
      var count = 25;
      var offset = count * (page - 1);
      params = Ember.assign({}, params, {
        offset: offset,
        limit: count
      });
      return this.ajax(url, 'GET', {
        data: params,
        cache: false
      });
    },
    _handleFavorites: function _handleFavorites(data, collection, deferred, params, url, page) {
      var _this2 = this;

      var results = data.res;

      if (results && results.length === 0) {
        return deferred.resolve(collection);
      }

      collection = [].concat(collection, results);
      var nextPage = page + 1;
      return this._findFavoritesV3(url, params, nextPage).then(function (data) {
        return _this2._handleFavorites(data, collection, deferred, params, url, nextPage);
      }).catch(function (error) {
        _this2.get('logger').error('Error while loading favorites:', {
          error: error,
          params: params,
          page: nextPage,
          currentCount: collection.length
        });

        deferred.resolve(collection);
      });
    },
    forwardVoicemail: function forwardVoicemail(voicemail, recipient, type) {
      var params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      var host = !!this.host ? this.host : '';
      var url = "".concat(host, "/platform/").concat(this.namespace, "/v2/voicemail/messages");
      params = Object.assign({}, params, {
        voicemailMessageId: voicemail,
        userId: type === 'person' ? recipient : null,
        groupId: type === 'group' ? recipient : null
      });
      return this.ajax(url, 'POST', {
        data: params,
        cache: false
      });
    },
    _findDirectReports: function _findDirectReports(url) {
      var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      var count = 25;
      var offset = count * (page - 1);
      return this.ajax(url, 'GET', {
        data: {
          count: count,
          offset: offset,
          fl: _person.BASE_PERSON.fl
        }
      });
    },
    _handleReports: function _handleReports(data, collection, deferred, url, page) {
      var _this3 = this;

      var results = data.res;

      if (results && results.length === 0) {
        return deferred.resolve(collection);
      }

      collection = [].concat(collection, results);
      var nextPage = page + 1;
      return this._findDirectReports(url, nextPage).then(function (data) {
        return _this3._handleReports(data, collection, deferred, url, nextPage);
      }).catch(function (error) {
        _this3.get('logger').error('Error while loading directs report page', {
          error: error,
          page: nextPage,
          currentCount: collection.length
        });

        deferred.resolve(collection);
      });
    },
    findDirectReports: function findDirectReports(store, type, id, params) {
      var _this4 = this;

      if (!params) {
        params = {
          fl: '*'
        };
      }

      var deferred = Ember.RSVP.defer();
      var url = this.entityEndpoint('directReports', type, id);

      this._findDirectReports(url, 1).then(function (data) {
        return _this4._handleReports(data, [], deferred, url, 1);
      }).catch(function (error) {
        _this4.get('logger').error('Error while attempting to find reports', {
          error: error,
          id: id
        });

        deferred.resolve([]);
      });

      return deferred.promise;
    },
    findSuperiors: function findSuperiors(store, type, id, params) {
      if (!params) {
        params = {
          fl: '*'
        };
      }

      var url = this.entityEndpoint('superiors', type, id);
      return this.ajax(url, 'GET', {
        data: params
      });
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, type) {
      var modelEndpoint = this.pathForType(type);
      var apiVersion = versions[modelEndpoint];
      var url = "/".concat(this.namespace, "/").concat(apiVersion, "/").concat(modelEndpoint);

      if (id) {
        url += "/".concat(id);
      }

      return url;
    },
    _directoryUrl: function _directoryUrl(type, id) {
      var modelEndpoint = this.pathForType(type);
      var apiVersion = versions[modelEndpoint];
      var url = "/".concat(this.namespace, "/").concat(apiVersion, "/").concat(modelEndpoint);

      if (type === 'person') {
        url = "/platform/directory".concat(url);
      }

      if (id) {
        url += "/".concat(id);
      }

      return url;
    }
  });

  var _default = ApplicationAdapter;
  _exports.default = _default;
});