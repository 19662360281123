define("web-directory/controllers/signup", ["exports", "web-directory/mixins/password-rules", "web-directory/mixins/auth-controller"], function (_exports, _passwordRules, _authController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_authController.default, {
    intl: Ember.inject.service(),
    token: null,
    email: null,
    password: null,
    passwordRepeat: null,
    resetType: 'set',
    error: false,
    errorMessages: [],
    actions: {
      reset: function reset() {
        var _this = this;

        if (this.get('buttonDisabled')) {
          return;
        }

        var intl = this.get('intl');
        var email = this.get('email');
        var password = this.get('password');
        var type = this.get('resetType');
        this.get('session').resetPassword(type, email, password, this.get('token')).then(function () {
          return _this.transitionToRoute('login');
        }).catch(function (error) {
          _this.set('error', true);

          var errorData = (0, _passwordRules.extractServerPasswordRulesMessages)(error.payload);
          var errorMessages = errorData.map(function (_ref) {
            var key = _ref.key,
                options = _ref.options;
            return intl.t(key, options);
          }).filter(function (message) {
            return !!message;
          }); // filter empty messages

          if (errorMessages.length === 0) {
            errorMessages.push(intl.t('auth.resetError'));
          }

          _this.set('errorMessages', errorMessages);
        });
      }
    },
    repeatDisabled: Ember.computed('password', function () {
      var password = this.get('password');
      return !password || !password.length;
    }),
    repeatError: Ember.computed('password', 'passwordRepeat', 'repeatDisabled', function () {
      var password = this.get('password');
      var repeat = this.get('passwordRepeat');
      var disabled = this.get('repeatDisabled');

      if (!disabled) {
        return password !== repeat;
      }
    }),
    buttonDisabled: Ember.computed('email', 'password', 'repeatError', function () {
      var email = this.get('email');
      var password = this.get('password');
      var repeatError = this.get('repeatError');
      return !email || !password || repeatError;
    }),
    _emailPlaceholder: Ember.computed('applicationService.locale', function () {
      return this.get('intl').lookup('signIn.emailPlaceholder').toString();
    }),
    _passwordPlaceholder: Ember.computed('applicationService.locale', function () {
      return this.get('intl').lookup('signIn.passwordPlaceholder').toString();
    }),
    _heading: Ember.computed(function () {
      return this.get('intl').lookup('auth.signUpHeading').toString();
    }),
    _button: Ember.computed(function () {
      return this.get('intl').lookup('auth.signIn').toString();
    })
  });

  _exports.default = _default;
});