define("web-directory/components/global-modal/component", ["exports", "web-directory/iframe-client-config"], function (_exports, _iframeClientConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var inject = Ember.inject,
      Component = Ember.Component;

  var _default = Component.extend({
    classNames: ['global-modal-wrapper'],
    modal: inject.service('modal'),
    displayedModal: Ember.computed.reads('modal.displayedModal'),
    modalData: Ember.computed.reads('modal.modalData'),
    parsedQueryParams: Ember.computed.reads('modal.parsedQueryParams'),
    clientConfig: _iframeClientConfig.modalClients,
    modalClass: Ember.computed('displayedModal', 'modalRoute', function () {
      if (this.get('displayedModal') && this.get('modalRoute')) {
        return 'shownModal hydrated';
      } else {
        return 'hidden-modal';
      }
    }),
    modalDataToQueryString: function modalDataToQueryString() {
      var modalData = this.get('modalData');

      if (_typeof(modalData) === 'object') {
        var route = '?';
        route += Object.entries(modalData).map(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
              key = _ref2[0],
              value = _ref2[1];

          var valueStr = value;

          if (_typeof(value) === 'object') {
            valueStr = JSON.stringify(value);
          }

          return "".concat(key, "=").concat(valueStr);
        }).join('&');
        return route;
      }

      return '';
    },
    modalRoute: Ember.computed('displayedModal', function () {
      var displayedModal = this.get('displayedModal');

      if (displayedModal) {
        var modalFrameData = this.get('clientConfig')[displayedModal];

        if (modalFrameData) {
          var queryStr = this.modalDataToQueryString();
          return "".concat(modalFrameData.assignedRoute).concat(queryStr);
        } else {
          return null;
        }
      }
    }),
    guxDismiss: function guxDismiss(ev) {
      ev.preventDefault();
      ev.stopPropagation();
      this.get('modal').closeModal();
    },
    closeModalHandler: function closeModalHandler(event) {
      if (event.payload['modalType'] === this.get('displayedModal')) {
        this.get('modal').closeModal();
      }
    },
    modalDataHandler: function modalDataHandler(event) {
      if (event.payload['modalType'] === this.get('displayedModal')) {
        this.get('modal').onData(event.payload);
      }
    },
    setRouter: function setRouter() {
      var _this = this;

      var router = Ember.getOwner(this).lookup('router:main');

      if (router) {
        router.on('willTransition', Ember.run.bind(this, function () {
          _this.get('modal').closeModal();
        }));
      }
    },
    didInsertElement: function didInsertElement() {
      var currData = this.get('clientConfig');
      var newData = this.applyQueryOverrides(currData);
      this.set('clientConfig', newData);

      this._super.apply(this, arguments);

      this.element.addEventListener('guxdismiss', this.guxDismiss.bind(this));
      var frame = this.element.querySelector('frame-router');

      if (frame) {
        frame.messaging.addListener('closeModal', this.closeModalHandler.bind(this));
        frame.messaging.addListener('modalData', this.modalDataHandler.bind(this));
      }

      this.setRouter();
    },
    applyQueryOverrides: function applyQueryOverrides(modalData) {
      var queryParams = this.get('parsedQueryParams');

      if (!queryParams) {
        return this.get('clientConfig');
      }

      var clientConfig = this.get('clientConfig');
      Object.keys(clientConfig).forEach(function (clientId) {
        var urlOverride = queryParams["global-modal-".concat(clientId)];

        if (urlOverride !== undefined) {
          if (typeof modalData[clientId].timeMachineUrl === 'function') {
            modalData[clientId].url = modalData[clientId].timeMachineUrl(urlOverride);
          } else {
            modalData[clientId].url = urlOverride;
          }
        }
      });
      return modalData;
    }
  });

  _exports.default = _default;
});