define("web-directory/models/app-instance", ["exports", "web-directory/config/environment", "lodash", "web-directory/utils/newrelic", "web-directory/models/internally-hosted-app"], function (_exports, _environment, _lodash, _newrelic, _internallyHostedApp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LIFECYCLE_HOOK_EVENT = _exports.LIFECYCLE_HOOKS = _exports.APP_STATES = _exports.APP_PROBLEMS = _exports.APP_LIFECYCLE_CALLBACKS = void 0;

  var _STATE_CONFIGS;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Evented = Ember.Evented,
      computed = Ember.computed,
      run = Ember.run,
      get = Ember.get;
  var AppState = Ember.Object.extend({
    name: null,
    ordinal: 0,
    compareTo: function compareTo(anotherState) {
      if (!anotherState) {
        return 1;
      } else if (Object.getPrototypeOf(anotherState) !== Object.getPrototypeOf(this)) {
        var errorMsg = 'Cannot compare AppState another object type';
        Ember.Logger.error(errorMsg);
        throw new Error(errorMsg);
      }

      return this.get('ordinal') - anotherState.get('ordinal');
    }
  });
  /**
   * List of lifecycle-oredered, AppState instances
   *
   * Callers can use the @see AppState#copareTo method to test for state order
   */

  var APP_STATES = {
    LOADING: AppState.create({
      ordinal: 100
    }),
    BOOTSTRAPPING: AppState.create({
      ordinal: 200
    }),
    RUNNING: AppState.create({
      ordinal: 300
    }),
    STOPPING: AppState.create({
      ordinal: 400
    }),
    STOPPED: AppState.create({
      ordinal: 500
    })
  };
  /**
   * The event name (internal eventing) that will be fired when the
   * lifecycle is changing (opt-in).  The primary consumer of this event will
   * be the app-instance component for re-broadcasting to app iframes.
   */

  _exports.APP_STATES = APP_STATES;
  var LIFECYCLE_HOOK_EVENT = 'appLifecycleHook';
  /**
   * The event payload values for @see {LIFECYCLE_HOOK_EVENT} that will
   * notify of a new lifecycle state (opt-in).  Again, this will typically be used
   * by the app-instance componet for re-broadcasting to app iframes.
   */

  _exports.LIFECYCLE_HOOK_EVENT = LIFECYCLE_HOOK_EVENT;
  var LIFECYCLE_HOOKS = {
    BOOTSTRAP: 'bootstrap',
    FOCUS: 'focus',
    BLUR: 'blur',
    STOP: 'stop'
  };
  /**
   * The callback types utilized by the Apps SDK (or app devs directly) as the
   * postMessage payload confirming the succes of app-side work within a lifecycle
   * phase.
   */

  _exports.LIFECYCLE_HOOKS = LIFECYCLE_HOOKS;
  var APP_LIFECYCLE_CALLBACKS = {
    BOOTSTRAPPED: 'bootstrapped',
    STOPPED: 'stopped'
  };
  /**
   * The list of problems which can affect this app-instance
   */

  _exports.APP_LIFECYCLE_CALLBACKS = APP_LIFECYCLE_CALLBACKS;
  var APP_PROBLEMS = {
    APP_UPDATED: 'updated',
    APP_DELETED: 'deleted',
    LICENSE_UNAVAILABLE: 'licenseUnavailable'
  };
  /*
   * Internal, configuration details of each app lifecycle state which will be ordered by ordinal.
   *
   * Heads up, this config is not validated internally.  So you break it, you bought it.
   */

  _exports.APP_PROBLEMS = APP_PROBLEMS;
  var STATE_CONFIGS = (_STATE_CONFIGS = {}, _defineProperty(_STATE_CONFIGS, APP_STATES.LOADING, {
    lifecycleDetails: {
      maxDurationMillis: get(_environment.default, 'purecloud-apps.lifecycle.transitionalStateTimeouts.loading')
    }
  }), _defineProperty(_STATE_CONFIGS, APP_STATES.BOOTSTRAPPING, {
    lifecycleDetails: {
      stateOptInPropName: 'hooks.bootstrap',
      maxDurationMillis: get(_environment.default, 'purecloud-apps.lifecycle.transitionalStateTimeouts.bootstrapping'),
      broadcastHookName: LIFECYCLE_HOOKS.BOOTSTRAP,
      appCallbackEventName: APP_LIFECYCLE_CALLBACKS.BOOTSTRAPPED
    }
  }), _defineProperty(_STATE_CONFIGS, APP_STATES.RUNNING, {}), _defineProperty(_STATE_CONFIGS, APP_STATES.STOPPING, {
    lifecycleDetails: {
      stateOptInPropName: 'hooks.stop',
      maxDurationMillis: get(_environment.default, 'purecloud-apps.lifecycle.transitionalStateTimeouts.stopping'),
      broadcastHookName: LIFECYCLE_HOOKS.STOP,
      appCallbackEventName: APP_LIFECYCLE_CALLBACKS.STOPPED
    }
  }), _defineProperty(_STATE_CONFIGS, APP_STATES.STOPPED, {}), _STATE_CONFIGS); // Ordered list of lifecycle states

  var LIFECYCLE_ORDERED_APP_STATES = Object.keys(APP_STATES).map(function (currStateName) {
    return APP_STATES[currStateName];
  }).sort(function (state1, state2) {
    return state1.compareTo(state2);
  });
  /**
   * An app-instance manages the runtime state of a particular instance of an app.
   * It contains the current lifecyle state, tracks transitional states, stores
   * any problems that might be affecting this instance, provides an api to
   * manipulate the lifecycle, and broadcasts events to integrate
   * with the rendering portion of the lifecycle.
   *
   * Important Notes:
   * app-instances are managed by an app-pool and must also be rendered with
   * an app-pool (transitively: app-instance and app-embed) component to ensure the
   * lifecycle correctly accounts for rendering and callbacks.
   *
   * Problems can occur at any state and may or may not exclusively affect state.  Therefore,
   * there is no app-state "PROBLEM".  Rather, users should consult the problem attribute
   * in addition to state.
   *
   * Instance Lifecycle States:
   *  <ol>
   *      <li>LOADING - The app's iframe is loading</li>
   *      <li>BOOTSTRAPPING - App-specific startup activities (Opt-in)
   *          <ul>
   *              <li>'bootstrap' hook (event fired)</li>
   *              <li>Awaits 'bootstrapped' app callback (Will timeout)</li>
   *          </ul>
   *      </li>
   *      <li>RUNNING
   *          <ul>
   *              <li>'blur' hook (event fired) on app blur (Opt-in)</li>
   *              <li>'focus' hook (event fired) on subsequent app focus (Opt-in)</li>
   *          </ul>
   *      </li>
   *      <li>STOPPING - App-specific startup activities (Opt-in)
   *          <ul>
   *              <li>'stop' hook (event fired) after stop requested</li>
   *              <li>Awaits 'stopped' app callback (Will timeout)</li>
   *          </ul>
   *      </li>
   *      <li>STOPPED - The app has shut down and the instance can safely be removed from PureCloud</li>
   *  </ol>
   *
   * Lifecycle Hooks (Opt In):
   * If an app is so configured, this class will broadcast lifecyle events out.  The
   * primary consumer of these events will be the app-instance component who will re-broadcast
   * the event onto the app's iframe.
   *
   * <ul>
   *   <li>bootstrap</li>
   *   <li>focus</li>
   *   <li>blur</li>
   *   <li>stop</li>
   * </ul>
   *
   * Lifecycle Callbacks:
   * App developers can acknowledge completion of certain app-side work via callbacks.
   * Callbacks are opt-in and will eventually time out to prevent an app from locking up
   * <ul>
   *   <li>bootstrapped</li>
   *   <li>stopped</li>
   * </ul>
   */

  var AppInstance = Ember.Object.extend(Evented, {
    instanceId: null,
    app: null,
    currState: null,
    _currStateStartTimestamp: null,
    problem: {
      name: null,
      details: null
    },
    lastUserAccessTimestamp: null,
    _stateTimeoutTimer: null,
    // sets a badging count for the app instance
    attentionCount: 0,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('instanceId', Ember.guidFor(this));
      this.set('problem', {
        name: null,
        details: null
      }); // Attach to beforeunload event to get instrumentation on app shutdown
      // Note: beforeunload listeners should not be attached via jquery

      if (window && window.addEventListener) {
        var reportStoppedInteraction = function reportStoppedInteraction() {
          Ember.run(function () {
            // Inactive app integrations will have already been reported
            if (_this._inAppInteraction()) {
              _this._appendAppInteraction('windowUnload', true);
            }
          });
        };

        this.set('reportStoppedInteraction', reportStoppedInteraction);
        window.addEventListener('beforeunload', reportStoppedInteraction);
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      var timeout = this.get('_stateTimeoutTimer');

      if (timeout) {
        run.cancel(timeout);
        this.set('_stateTimeoutTimer', null);
      }

      var reportStoppedInteraction = this.get('reportStoppedInteraction');

      if (reportStoppedInteraction) {
        window.removeEventListener('beforeunload', reportStoppedInteraction);
      }
    },
    appId: computed('app.id', function () {
      var appId = this.get('app.id');
      return appId || null;
    }),
    start: function start() {
      this._startAppInteraction('start');

      this._advanceState(APP_STATES.LOADING);

      this.set('lastUserAccessTimestamp', Date.now());
    },
    loaded: function loaded() {
      this._advanceState(APP_STATES.BOOTSTRAPPING);
    },
    bootstrapped: function bootstrapped() {
      this._advanceState(APP_STATES.RUNNING);
    },
    focus: function focus() {
      this._startAppInteraction('focus');

      this.set('lastUserAccessTimestamp', Date.now());

      if (this.get('app.lifecycle.hooks.focus')) {
        this._triggerLifecycleHookEvent(LIFECYCLE_HOOKS.FOCUS);
      }
    },
    blur: function blur() {
      this.set('lastUserAccessTimestamp', Date.now());

      if (this.get('app.lifecycle.hooks.blur')) {
        this._triggerLifecycleHookEvent(LIFECYCLE_HOOKS.BLUR);
      }

      this._appendAppInteraction('blur', true);
    },
    stop: function stop() {
      this._advanceState(APP_STATES.STOPPING);
    },
    stopped: function stopped() {
      this._advanceState(APP_STATES.STOPPED);
    },
    setProblem: function setProblem(name) {
      var details = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      this.set('problem.name', name);
      this.set('problem.details', details);
      this.notifyPropertyChange('problem');
    },
    _advanceState: function _advanceState(requestedState) {
      var currState = this.get('currState');

      if (!requestedState || requestedState.compareTo(currState) <= 0) {
        // Invalid next state
        return;
      } // if monitoring ... cancel


      var timeout = this.get('_stateTimeoutTimer');

      if (timeout) {
        run.cancel(timeout);
        this.set('_stateTimeoutTimer', null);
      }

      var requestedStateConfig = STATE_CONFIGS[requestedState];

      var nextState = this._getNextState(requestedState);

      if (requestedStateConfig.lifecycleDetails && requestedStateConfig.lifecycleDetails.stateOptInPropName && !this.get("app.lifecycle.".concat(requestedStateConfig.lifecycleDetails.stateOptInPropName)) && nextState) {
        // App doesn't need this transitional state ... move to the next
        this._advanceState(nextState);

        return;
      }

      this.set('currState', requestedState);
      this.set('_currStateStartTimestamp', Date.now());

      if (this._inAppInteraction()) {
        this._appendAppInteraction(this._convertStateToLifecycleEvent(requestedState), requestedState === APP_STATES.STOPPED);
      } else if (requestedState === APP_STATES.STOPPED) {
        // Because an app will likely be hidden when stopped, there is no
        // active interaction; however, this event represents a user dismissing
        // an app (updated/deleted) or a pool eviction; so it's important to log
        this._logInactiveAppStopped();
      }

      if (requestedStateConfig.lifecycleDetails) {
        if (requestedStateConfig.lifecycleDetails.broadcastHookName) {
          this._triggerLifecycleHookEvent(requestedStateConfig.lifecycleDetails.broadcastHookName);
        }

        if (requestedStateConfig.lifecycleDetails.maxDurationMillis) {
          // Start transitional state timeout
          this.set('_stateTimeoutTimer', run.later(this, '_monitorTransitionalState', requestedStateConfig.lifecycleDetails.maxDurationMillis + 1));
        }
      }
    },
    _triggerLifecycleHookEvent: function _triggerLifecycleHookEvent(hook) {
      this.trigger(LIFECYCLE_HOOK_EVENT, {
        hook: hook
      });
    },
    _monitorTransitionalState: function _monitorTransitionalState() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      var currState = this.get('currState');
      var currStateConfig = STATE_CONFIGS[currState];

      var nextState = this._getNextState(currState);

      var currStateEntryTimestamp = this.get('_currStateStartTimestamp');
      var maxDuration = currStateConfig ? get(currStateConfig, 'lifecycleDetails.maxDurationMillis') : undefined;

      if (currStateConfig && currStateEntryTimestamp !== null && maxDuration) {
        var timeInState = Date.now() - currStateEntryTimestamp;

        if (timeInState < maxDuration) {
          // Should not occur, but will re-kick the timer to wait until the state has expired
          this.set('_stateTimeoutTimer', run.later(this, '_monitorTransitionalState', 100));
        } else if (nextState) {
          // The transitional state has expired and can be moved to the next state
          this._advanceState(nextState);
        }
      }
    },
    _getNextState: function _getNextState(currState) {
      var result = null;

      var currStateIndex = _lodash.default.findIndex(LIFECYCLE_ORDERED_APP_STATES, currState);

      if (currStateIndex >= 0 && LIFECYCLE_ORDERED_APP_STATES.length > currStateIndex + 1) {
        result = LIFECYCLE_ORDERED_APP_STATES[currStateIndex + 1];
      }

      return result;
    },
    // Instrumentation
    _interactionStartTimestamp: null,
    _latestInteractionTimestamp: null,
    _inAppInteraction: function _inAppInteraction() {
      return !!this.get('_interactionStartTimestamp');
    },
    _startAppInteraction: function _startAppInteraction(lifecycleEvent) {
      var startTimestamp = Date.now();
      this.set('_interactionStartTimestamp', startTimestamp);
      this.set('_latestInteractionTimestamp', startTimestamp);

      this._logAppInteraction(lifecycleEvent, {
        interactionStart: startTimestamp
      });
    },
    _appendAppInteraction: function _appendAppInteraction(lifecycleEvent, complete) {
      var startTimestamp = this.get('_interactionStartTimestamp');

      if (!startTimestamp) {
        Ember.Logger.error('Attempted to append to an unknown app interaction');
        return;
      }

      var now = Date.now();
      var interactionDetails = {
        interactionStart: startTimestamp,
        timeSinceInteractionStart: now - startTimestamp,
        timeSincePrevInteraction: now - this.get('_latestInteractionTimestamp')
      };

      if (complete) {
        interactionDetails.interactionEnd = now;
        interactionDetails.interactionDuration = now - startTimestamp;
        this.set('_interactionStartTimestamp', null);
        this.set('_latestInteractionTimestamp', null);
      } else {
        this.set('_latestInteractionTimestamp', Date.now());
      }

      this._logAppInteraction(lifecycleEvent, interactionDetails);
    },

    /**
     * logs an app shutdown as a single pageAction regardless of interaction
     * state due to the importance of this event.  Usually, this will be
     * the result of a user dismissal (update/deleted app) or pool
     * eviction.
     */
    _logInactiveAppStopped: function _logInactiveAppStopped() {
      var now = Date.now();

      this._logAppInteraction(this._convertStateToLifecycleEvent(APP_STATES.STOPPED), {
        interactionStart: now,
        timeSinceInteractionStart: 0,
        timeSincePrevInteraction: 0,
        interactionEnd: now,
        interactionDuration: 0
      });
    },
    _logAppInteraction: function _logAppInteraction(lifecycleEvent, interactionDetails) {
      var commonAttrs = {
        lifecycleEvent: lifecycleEvent,
        pcAppTypeId: this.get('app.typeId'),
        pcAppId: this.get('appId'),
        pcAppInstanceId: this.get('instanceId'),
        pcAppDisplayType: this.get('app.displayType'),
        pcAppInternallyHosted: this.get('app') instanceof _internallyHostedApp.default,
        pcAppFeatureCategory: this.get('app.featureCategory'),
        pcAppProblemName: this.get('problem.name'),
        interactionId: this.get('app.interactionId')
      };

      var _iterator = _createForOfIteratorHelper(this.get('app').getURLTemplateQueryParams()),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var queryParam = _step.value;
          commonAttrs["".concat(queryParam, "UrlReplacement")] = true;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      (0, _newrelic.addPageAction)('clientAppInteraction', _lodash.default.merge({}, interactionDetails, commonAttrs));
    },
    _convertStateToLifecycleEvent: function _convertStateToLifecycleEvent(appState) {
      switch (appState) {
        case APP_STATES.LOADING:
          return 'loading';

        case APP_STATES.BOOTSTRAPPING:
          return 'bootstrapping';

        case APP_STATES.RUNNING:
          return 'running';

        case APP_STATES.STOPPING:
          return 'stopping';

        case APP_STATES.STOPPED:
          return 'stopped';

        default:
          return 'unknownAppState';
      }
    }
  });
  var _default = AppInstance;
  _exports.default = _default;
});