define("web-directory/initializers/analytics-service", ["exports", "web-directory/services/analytics"], function (_exports, _analytics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'setupAnalyticsService',
    after: 'setupSessionService',
    initialize: function initialize(application) {
      application.register('service:analytics', _analytics.default);
      application.inject('service:analytics', 'store', 'service:store');
      application.inject('service:analytics', 'applicationService', 'service:application');
      application.inject('controller', 'analyticsService', 'service:analytics');
      application.inject('route', 'analyticsService', 'service:analytics');
      application.inject('component', 'analyticsService', 'service:analytics');
    }
  };
  _exports.default = _default;
});