define("web-directory/components/acd-interactions-panel/interaction-queue-status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['interaction-queue-status'],
    classNameBindings: ['isOnQueue:is-on-queue:is-off-queue'],
    messageKey: Ember.computed('isOnQueue', function () {
      if (this.get('isOnQueue')) {
        return 'purecloud.acdInteraction.waitingOnQueueMessageInteraction';
      }

      return 'purecloud.acdInteraction.noInteractions';
    })
  });

  _exports.default = _default;
});