define("web-directory/components/entity-fields/text-field/component", ["exports", "lodash", "web-directory/components/entity-fields/validators/text", "web-directory/mixins/field-component"], function (_exports, _lodash, _text, _fieldComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var on = Ember.on,
      inject = Ember.inject,
      observer = Ember.observer,
      computed = Ember.computed,
      Component = Ember.Component;
  var requiredFieldPaths = ['general.name', 'contactInfo.im'];

  var _default = Component.extend(_fieldComponent.default, {
    classNames: ['text-field'],
    classNameBindings: ['canEdit:editing', 'onProfile:on-profile'],
    intl: inject.service(),
    attrs: {
      onProfile: false
    },
    onProfile: false,
    isGeneralField: false,
    actions: {
      saveField: function saveField(event) {
        var value = event.target.value;

        if (this.get('onProfile') && this.get('canEdit')) {
          if (event.type === 'keyup' && event.keyCode !== 13) {
            return;
          }

          this.saveField(value);
        } else if (this.get('canEdit')) {
          this.set('entry.value', value);
        }
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
    },
    showPlaceholder: computed.reads('onProfile'),
    isValid: computed('entry.value', function () {
      var fieldData = this.get('fieldData');

      if (fieldData) {
        return fieldData.get('isValid');
      } else {
        var entry = this.get('entry');
        var field = this.get('field');
        return _text.default.isValid(entry, field);
      }
    }),
    watchValidityToDisableModal: observer('isValid', function () {
      var disable = this.get('disableModal');

      if (typeof disable === 'function') {
        Ember.run.next(null, disable, !this.get('isValid'));
      }
    }),
    isEmpty: computed('entry.value', function () {
      return this.isFieldEmpty();
    }),
    placeholderText: computed('showPlaceholder', function () {
      if (this.get('showPlaceholder')) {
        return this.get('field.i18nLabel');
      }

      return '';
    }),
    setMinWidth: observer('canEdit', 'entry.value', function () {
      var _this = this;

      // self sizing behavior...
      if (this.get('onProfile') && this.get('canEdit') && _lodash.default.isEmpty(this.get('entry.value'))) {
        Ember.run.scheduleOnce('afterRender', function () {
          var span = Ember.$('<span class="off-page"></span>');

          var placeholder = _this.get('placeholderText');

          span.text(placeholder);

          _this.$().append(span);

          _this.$('input').css('min-width', span.width() + 15);

          span.remove();
        });
      }
    }).on('didInsertElement'),
    ensurePopulatedWhenEditing: observer('editing', 'canEdit', function () {
      Ember.run.once(this, this.ensurePopulatedField);
    }).on('didInsertElement'),
    ensurePopulatedField: function ensurePopulatedField() {
      if ((!this.get('editing') || this.isDestroyed) && !this.get('isGeneralField')) {
        return;
      }

      if (this.isFieldEmpty()) {
        Ember.run.once(this, this.setValues);
      }
    },
    setWidth: on('didRender', function () {
      if (this.get('onProfile') && this.get('canEdit')) {
        var span = this.$('span');
        this.$('input').width(span.width() + 5);
      }
    }),
    setValues: function setValues() {
      var entity = this.get('entity');
      var field = this.get('field');
      var path = field.getFieldPath();
      var entry = this.get('entry');

      if (!_lodash.default.isEmpty(entry)) {
        Ember.set(entry, 'value', '');

        if (!field.get('repeatable')) {
          entity.set(path, [entry], {
            overwrite: true
          });
        }
      } else {
        entity.set(path, '');
      }

      if (!field.get('repeatable')) {
        var value = entity.getFieldValue(path);

        if (Array.isArray(value)) {
          value = value[0];
        }

        this.set('entry', value);
      }
    },
    isFieldEmpty: function isFieldEmpty() {
      var entry = this.get('entry');

      if (!entry) {
        return true;
      }

      return _lodash.default.isEmpty(entry.value);
    },
    getFieldError: function getFieldError() {
      var intl = this.get('intl');
      var value = this.get('entry.value');
      var maxLength = this.get('field.params.maxLength');
      var fieldPath = this.get('field.fieldPath');

      if (_lodash.default.isEmpty(value) && _lodash.default.includes(requiredFieldPaths, fieldPath)) {
        return intl.lookup('tooltip.fields.text.requiredError').toString();
      }

      return intl.lookup('tooltip.fields.text.lengthError', {
        length: maxLength
      }).toString();
    }
  });

  _exports.default = _default;
});