define("web-directory/services/documents", ["exports", "ember-ajax"], function (_exports, _emberAjax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SEARCH_BODY = {
    pageNumber: 1,
    pageSize: 1,
    facetNameRequests: [],
    sort: [],
    filters: []
  },
      FACETED_SEARCH_PATH = 'api/v2/contentmanagement/query',
      DOCUMENT_SHARE_PATH = 'api/v2/contentmanagement/shares',
      DOCUMENT_PATH = 'api/v2/contentmanagement/documents',
      CONTENT_TYPE = 'application/json; charset=UTF-8';
  /**
   * Service to access the content management documents search.
   */

  var _default = Ember.Service.extend({
    application: Ember.inject.service('application'),
    session: Ember.inject.service('session'),

    /**
     * Used to hold onto the current document route segment when leaving the documents subsection.  When entering the document
     * subsection this is set to null.
     */
    lastSegment: null,

    /**
     * Document routes set the current segment when entering the route. This is transfered to the lastSegment when the user leaves
     * the documents component
     */
    currentSegment: null,
    hasDocuments: false,
    checkedHasDocuments: false,

    /**
     * General purpose faceted search method that returns a promise with the search results
     * @param searchBody
     * @returns {*}
     */
    facetedSearch: function facetedSearch(searchBody) {
      var url = this.get('application').pcUri([FACETED_SEARCH_PATH]);
      return (0, _emberAjax.default)(url, {
        type: 'post',
        contentType: CONTENT_TYPE,
        data: JSON.stringify(searchBody)
      });
    },

    /**
     * Sharing document with other User
     * @param data
     * @returns {*}
     */
    shareDocument: function shareDocument(data) {
      return (0, _emberAjax.default)(this.get('application').pcUri([DOCUMENT_SHARE_PATH]), {
        type: 'post',
        contentType: CONTENT_TYPE,
        data: JSON.stringify(data)
      });
    },

    /**
     * Get shared document details
     * @param data
     * @returns {*}
     */
    getSharedDocumentDetails: function getSharedDocumentDetails(data) {
      return (0, _emberAjax.default)(this.get('application').pcUri([DOCUMENT_SHARE_PATH]), {
        contentType: CONTENT_TYPE,
        data: data
      });
    },

    /**
     * Get document details
     * @param document-id
     * @returns {*}
     */
    getDocumentDetails: function getDocumentDetails(documentId) {
      return (0, _emberAjax.default)(this.get('application').pcUri(["".concat(DOCUMENT_PATH, "/").concat(documentId)]), {
        contentType: CONTENT_TYPE
      });
    },

    /**
     * Delete document shares
     * @param document-id, entity
     * @returns {*}
     */
    deleteDocumentShares: function deleteDocumentShares(documentId, entity) {
      return (0, _emberAjax.default)(this.get('application').pcUri(["".concat(DOCUMENT_SHARE_PATH, "/").concat(documentId, ".").concat(entity)]), {
        contentType: CONTENT_TYPE,
        type: 'DELETE'
      });
    },

    /**
     * Checks to see if there are any documents in the documents service.
     * @returns {*}
     */
    checkHasDocuments: function checkHasDocuments() {
      if (!this.get('checkedHasDocuments')) {
        this.facetedSearch(SEARCH_BODY).then(function (data) {
          this.set('checkedHasDocuments', true);
          this.set('hasDocuments', data.results.total > 0);
        }.bind(this));
      }
    }
  });

  _exports.default = _default;
});