define("web-directory/helpers/expand-line-breaks", ["exports", "web-directory/utils/escape"], function (_exports, _escape) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var Helper = Ember.Helper;

  var _default = Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(_ref) {
      var _this = this;

      var _ref2 = _slicedToArray(_ref, 1),
          text = _ref2[0];

      if (!text) {
        text = '';
      } // Check for promise


      if (typeof text !== 'string' && typeof text.then === 'function' && !text.get('content')) {
        text.then(function () {
          return _this.recompute();
        });
      } else if (_typeof(text) === 'object' && typeof text.get === 'function') {
        text = text.get('content') || '';
      } // Check if we've got a POJO


      if (_typeof(text) === 'object' && typeof text.get !== 'function') {
        return this.get('intl').t('entity.fieldTypes.location.address', {
          street1: text.street1,
          street2: text.street2,
          city: text.city,
          state: text.state,
          country: text.country,
          zipcode: text.zipcode
        }).toString();
      }

      return Ember.String.htmlSafe((0, _escape.default)(text).replace(/\n/g, '<br>'));
    }
  });

  _exports.default = _default;
});