define("web-directory/resolver", ["exports", "ember-resolver"], function (_exports, _emberResolver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var centerStageRouteRegex = /^route:center-stage-frame-[\w-]+/;
  var centerStageShortRegex = /^center-stage-frame-[\w-]+/;

  var _default = _emberResolver.default.extend({
    /**
     * This intercepts route resolution and maps lookups of routes of the form
     * `center-stage-frame-${clientId}` to a lookup of `center-stage-frame` so
     * that we get a single implementation for all iframe-coordinator client apps.
     *
     * @param {*} routeInfo details about the route to be looked up.
     */
    resolveRoute: function resolveRoute(routeInfo) {
      this._transformCenterStageRoutes(routeInfo);

      return this._super.apply(this, arguments);
    },
    _transformCenterStageRoutes: function _transformCenterStageRoutes(routeInfo) {
      var routeName = routeInfo.fullName; // If this is a center-stage route

      if (centerStageRouteRegex.test(routeName)) {
        //Replace all the fields with the route name in them so Ember maps to our center-stage route implementation
        routeInfo.fullName = routeInfo.fullName.replace(centerStageRouteRegex, 'route:center-stage-frame');
        routeInfo.fullNameWithoutType = routeInfo.fullNameWithoutType.replace(centerStageShortRegex, 'center-stage-frame');
        routeInfo.name = routeInfo.name.replace(centerStageShortRegex, 'center-stage-frame');
      }
    }
  });

  _exports.default = _default;
});