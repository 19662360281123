define("web-directory/routes/performance-summary-queues", ["exports", "web-directory/routes/engage-supervisor"], function (_exports, _engageSupervisor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _engageSupervisor.default.extend();

  _exports.default = _default;
});