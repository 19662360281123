define("web-directory/mixins/field-component", ["exports", "ember-data", "lodash"], function (_exports, _emberData, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var run = Ember.run,
      inject = Ember.inject,
      computed = Ember.computed,
      RSVP = Ember.RSVP,
      Mixin = Ember.Mixin;

  var _default = Mixin.create({
    classNames: ['field-component'],
    classNameBindings: ['field.key', 'fieldTypeClass', 'isEmpty:empty-field', 'isNewField:new-field-entry-component'],
    intl: inject.service(),
    logger: inject.service('stash-logger'),
    application: inject.service(),
    notification: inject.service(),
    entity: null,
    allFieldData: [],
    fieldData: null,
    field: computed.reads('fieldData.field'),
    writeable: computed.reads('fieldData.writeable'),
    isValid: computed.reads('fieldData.isValid'),
    isEmpty: computed.alias('fieldData.isEmpty'),
    editing: false,
    showLabel: false,
    isNewField: false,
    savingField: false,
    complexField: false,
    complexValueFields: [],
    // Override complex fields for simple 'entry.value' bindings to fire
    timers: null,
    attrs: {
      cancelAddNew: function cancelAddNew() {}
    },
    init: function init() {
      this.timers = [];

      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.attachObserversForComplexFields();
    },
    willDestroyElement: function willDestroyElement() {
      this.timers.forEach(function (timer) {
        return Ember.run.cancel(timer);
      });
      this.timers = [];

      this._super.apply(this, arguments);
    },
    entry: computed('fieldData.value', 'fieldData.valueReference', function () {
      return this.get('fieldData.value') || this.get('fieldData.valueReference');
    }),
    fieldTypeClass: computed('field.type', function () {
      return "".concat(this.get('field.type'), "-field");
    }),
    canEdit: computed('editing', 'fieldData.writeable', function () {
      var editing = this.get('editing');

      if (!editing) {
        return _emberData.default.PromiseObject.create({
          promise: Ember.RSVP.resolve(false)
        });
      }

      return this.get('fieldData.writeable');
    }),
    attachObserversForComplexFields: function attachObserversForComplexFields() {
      var _this = this;

      var fields = this.get('complexValueFields');

      if (_lodash.default.isEmpty(fields)) {
        return;
      }

      _lodash.default.each(fields, function (field) {
        _this.addObserver(field, _this, _this.notifyComplexPropertyChange);
      });
    },
    isFieldEmpty: function isFieldEmpty() {},
    notifyComplexPropertyChange: function notifyComplexPropertyChange() {
      run.once(this, this.performNotification);
    },
    performNotification: function performNotification() {
      var fieldData = this.get('fieldData');

      if (fieldData) {
        fieldData.notifyPropertyChange('isEmpty');
        fieldData.notifyPropertyChange('isValid');
      }
    },
    // Called before save
    cleanFieldForSave: function cleanFieldForSave() {},
    // Override for empty logic check for complex fields
    isComplexFieldEmpty: function isComplexFieldEmpty() {
      return false;
    },
    saveField: function saveField(value) {
      var _this2 = this;

      if (this.get('savingField')) {
        return new RSVP.Promise(function (resolve) {
          resolve({});
        });
      }

      var entity = this.get('entity');
      var fieldPath = this.get('field').getFieldPath();

      if (value || value === '') {
        this.set('entry.value', value);
        entity.set(fieldPath, value);
      }

      this.set('savingField', true);
      var section = this.get('field.section');

      if (section) {
        return entity.saveSection(section).finally(function () {
          return _this2.set('savingField', false);
        });
      }

      return entity.save();
    }
  });

  _exports.default = _default;
});