define("web-directory/components/select-2/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t2StnLrv",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[11,\"label\",[]],[15,\"class\",\"sr-only\"],[16,\"for\",[34,[[33,[\"concat\"],[[28,[\"elementId\"]],\"-select-2\"],null]]]],[13],[1,[26,[\"label\"]],false],[14],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\n\"],[11,\"input\",[]],[16,\"id\",[34,[[33,[\"concat\"],[[28,[\"elementId\"]],\"-select-2\"],null]]]],[15,\"class\",\"select2 form-control\"],[15,\"type\",\"hidden\"],[16,\"disabled\",[26,[\"disabled\"]],null],[13],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/select-2/template.hbs"
    }
  });

  _exports.default = _default;
});