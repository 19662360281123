define("web-directory/components/acd-interactions-panel/interaction-sms/sms-message-v2/sms-attachments/component", ["exports", "web-directory/utils/acd-ts", "web-directory/utils/net", "web-directory/components/acd-interactions-panel/interaction-sms/sms-message/sms-attachments/template"], function (_exports, _acdTs, _net, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INTERNAL_DL_MATCHER = /\/api\/v[12]\/downloads\/([\w-+]+).*$/i;

  var _default = Ember.Component.extend({
    classNames: ['sms-attachments-box'],
    classNameBindings: ['isSticker'],
    layout: _template.default,
    acdsms: Ember.inject.service('acdsms'),
    logger: Ember.inject.service('stash-logger'),
    application: Ember.inject.service('application'),

    /**
     * @Attrbute
     * The current message
     */
    message: null,

    /**
     * @Attrbute
     * The current interaction being used
     */
    interaction: null,

    /**
     * @Attrbute
     * The attachment content of the message
     */
    media: null,

    /**
     * Returns the filename of an attachment or the url if there is no filename provided.
     */
    fileName: Ember.computed.or('media.attachment.filename', 'url'),

    /**
     * Returns true if the message is an agent outbound message
     */
    isAgentMessage: Ember.computed.equal('message.direction', 'outbound'),

    /**
     * Returns true if the message is a customer inbound message
     */
    isCustomerMessage: Ember.computed.equal('message.direction', 'inbound'),

    /**
     * Retrieves the url of an attachment or an Instagram story
     */
    url: Ember.computed.or('media.attachment.url', 'media.story.url'),

    /**
     * Extracts the general category of attachment (e.g. 'image') from the specific type of attachment (e.g. 'image/png')
     */
    mediaMimiType: Ember.computed('media.attachment.mime', function () {
      var mediaType = this.get('media.attachment.mime');

      if (/^image/.test(mediaType)) {
        return mediaType === 'image/vnd.genesys.sticker' ? 'sticker' : 'image';
      } else if (/^audio/.test(mediaType)) {
        return 'audio';
      } else if (/^video/.test(mediaType)) {
        return 'video';
      }

      return 'file';
    }),

    /**
     * Returns the file size of a message attachment in bytes
     */
    fileSize: Ember.computed.readOnly('media.attachment.contentSizeBytes'),

    /**
     * Determines what specfic type of media is being presented (sticker, image, story, etc.)
     */
    mediaType: Ember.computed('media', 'media.attachment', 'media.attachment.mime', 'media.attachment.length', 'media.story', 'media.story.type', function () {
      if (this.get('media') && this.get('media.attachment') && this.get('media.attachment.length') > 0) {
        return this.get('media.attachment.mime') === 'image/vnd.genesys.sticker' ? 'sticker' : this.get('media.attachment.mime');
      } else if (this.get('media.story.type')) {
        return this.get('media.story.type');
      }
    }),

    /**
     * Returns true if the attachment content is a sticker attachment
     */
    isSticker: Ember.computed.equal('mediaMimiType', 'sticker'),

    /**
     * Returns true if the attachment content is an image attachment
     */
    isImage: Ember.computed.equal('mediaMimiType', 'image'),

    /**
     * Returns true if the attachment content is an audio attachment
     */
    isAudio: Ember.computed.equal('mediaMimiType', 'audio'),

    /**
     * Returns true if the attachment content is a video attachment
     */
    isVideo: Ember.computed.equal('mediaMimiType', 'video'),

    /**
     * Returns true if the attachment content is an attachment, but is not a sticker, image, audio, or video attachment.
     */
    isGenericFile: Ember.computed.equal('mediaMimiType', 'file'),

    /**
     * The state of whether or not the attachment content is hidden/collapsed from view on the UI.
     */
    mediaHidden: Ember.computed.alias('media.attachment.mediaHidden'),

    /**
     * Flag for whether or not the attachment is invalid
     */
    isInvalidMedia: Ember.computed.alias('media.attachment.isInvalidMedia'),

    /**
     * @Action
     * Set the text of the message to empty string when the message is not a sticker and
     * the message text is either initially empty or is outbound (agent).
     */
    updateText: function updateText() {
      var msg = this.get('message');
      var msgText = Ember.get(msg, 'normalizedMessage.text');
      var textBodyHasNoContent = msgText && msgText.trim().length == 0;

      if (!this.get('isSticker') && (textBodyHasNoContent || this.get('isAgentMessage'))) {
        Ember.set(msg, '.normalizedMessage.text', '');
      }
    },

    /**
     * Sets up the image to scroll to bottom of the page when the image is loaded
     */
    setupImageOnLoadEvent: function setupImageOnLoadEvent() {
      var _this = this;

      this.$('img').on('load', function () {
        var interaction = _this.get('interaction');

        _this.get('acdsms').trigger('scrollToBottom', _this, Ember.get(interaction, 'id'));

        var message = _this.get('message');

        _this.actionOnAttachmentLoad(Ember.get(message, 'id'), true);
      });
    },

    /**
     * Sets up the image src to the embeddedUrl when there is an error with loading the image
     */
    setupImageOnErrorEvent: function setupImageOnErrorEvent() {
      var _this2 = this;

      var imageTag = this.$('img');
      imageTag.on('error', function () {
        var imgSrc = imageTag.attr('src');

        var altSrc = _this2.embeddedUrl(imgSrc);

        if (imgSrc && altSrc && imgSrc !== altSrc) {
          imageTag.attr('src', altSrc);
        }

        var message = _this2.get('message');

        _this2.actionOnAttachmentLoad(Ember.get(message, 'id'), false);
      });
    },

    /**
     * Generates and returns the embedded url for an image
     * @param imageUrl - The provided url of an image, if defined.
     * @returns the string of the url provided or a download url
     */
    embeddedUrl: function embeddedUrl(imageUrl) {
      var url = imageUrl;

      if (!url || this.get('application').get('runtime').local) {
        return url;
      }

      try {
        // Only transform internal PC urls
        if (!_net.default.isExternalDomain(url)) {
          var matches = INTERNAL_DL_MATCHER.exec(url);

          if (matches && matches[1]) {
            var id = matches[1];
            return "".concat(this.get('application').pcV2Uri(['api/v2/apps/downloads', id]), "?contentDisposition=INLINE");
          }
        }
      } catch (error) {
        // Failed to check URL, returning unmodified url below
        this.get('logger').info("ACD embeddedUrl", {
          error: error,
          imageUrl: url
        });
        return url;
      }

      return url;
    },
    init: function init() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, this.updateText);
    },
    didInsertElement: function didInsertElement() {
      var _this3 = this;

      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, function () {
        _this3.setupImageOnLoadEvent();

        _this3.setupImageOnErrorEvent();
      });
    },

    /**
     * @action
     * @param {string} id The id for the attachment in a message
     * @param {boolean} value True if image fails to load
     */
    actionOnAttachmentLoad: function actionOnAttachmentLoad(id, value) {
      return (0, _acdTs.warnClosureAction)('actionOnAttachmentLoad', {
        id: id,
        value: value
      });
    },
    actions: {
      /**
       * Invoked when the participant selects the toggle attachment icon, which can hide or display the attachment
       */
      toggleMediaHidden: function toggleMediaHidden() {
        var _this4 = this;

        this.toggleProperty('mediaHidden');

        if (!this.get('mediaHidden')) {
          Ember.run.next(this, function () {
            _this4.setupImageOnErrorEvent();
          });
        }
      }
    }
  });

  _exports.default = _default;
});