define("web-directory/routes/data-models/external-orgs", ["exports", "ember-engine-custom-fields/routes/data-models/external-orgs"], function (_exports, _externalOrgs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _externalOrgs.default;
    }
  });
});