define("web-directory/components/acd-interactions-panel/interaction-voicemail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1sZQycyT",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"loadingVoicemail\"]]],null,{\"statements\":[[0,\"    \"],[11,\"div\",[]],[15,\"class\",\"loading-voicemail\"],[13],[0,\"\\n        \"],[1,[26,[\"snail-progress\"]],false],[0,\"\\n        \"],[11,\"span\",[]],[15,\"class\",\"loading-text\"],[13],[1,[33,[\"t\"],[\"purecloud.acdInteraction.loadingVoicemail\"],null],false],[0,\" \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"    \"],[1,[33,[\"audio-player-v2\"],null,[[\"recording\",\"theme\",\"inErrorState\",\"audioPlayerOptions\",\"audioOutputDeviceId\"],[[28,[\"voicemail\"]],\"dark\",[28,[\"errorLoadingVoicemail\"]],[28,[\"audioPlayerOptions\"]],[28,[\"audioOutputDeviceId\"]]]]],false],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/acd-interactions-panel/interaction-voicemail/template.hbs"
    }
  });

  _exports.default = _default;
});