define("web-directory/components/grouped-facet-term/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['grouped-facet-term'],
    classNameBindings: ['label'],
    intl: Ember.inject.service(),
    group: null,
    showDropdown: false,
    actions: {
      removeSelectedTerm: function removeSelectedTerm() {
        var terms = this.get('group.terms');
        var term = terms.objectAt(0);
        this.send('removeSingleTerm', term);
      },
      removeSingleTerm: function removeSingleTerm(term) {
        var facet = this.get('group.facet');
        this.bubbleAction({
          action: 'removeSelectedTerm',
          actionContext: [term, facet]
        });
      },
      toggleDropdown: function toggleDropdown() {
        if (!this.get('isSingleTerm')) {
          this.toggleProperty('showDropdown');
        }
      }
    },
    isSingleTerm: Ember.computed('group.terms.[]', function () {
      return this.get('group.terms.length') === 1;
    }),
    label: Ember.computed('group.facet.facetName', function () {
      var facetName = this.get('group.facet.facetName');

      if (facetName) {
        return facetName.toLowerCase().replace(/\./gi, '_');
      }
    }),
    value: Ember.computed('isSingleTerm', function () {
      var terms = this.get('group.terms');

      if (this.get('isSingleTerm')) {
        var term = terms.objectAt(0);
        return term.term;
      } else {
        return this.get('intl').lookup('search.multipleFacetsSelected', {
          count: terms.length
        }).toString();
      }
    })
  });

  _exports.default = _default;
});