define("web-directory/components/follow-me-settings-panel/component", ["exports", "web-directory/components/follow-me-settings-panel/forwarding-helpers"], function (_exports, _forwardingHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var get = Ember.get,
      set = Ember.set,
      run = Ember.run,
      inject = Ember.inject,
      computed = Ember.computed,
      observer = Ember.observer,
      Component = Ember.Component;
  var ROUTE_ENTRY_LIMIT = 4;
  var VOICEMAIL_CHOICES = {
    LASTCALL: 'LASTCALL',
    PURECLOUD: 'PURECLOUD',
    NONE: 'NONE'
  };
  var DISABLE_VOICEMAIL_ERROR_CODE = 'callforwarding.voicemaillastcallmustbeanexternalphonenumber';

  function emptyStationData() {
    return _forwardingHelpers.CallForwardingWrapper.create({
      new: true
    });
  }

  var _default = Component.extend({
    classNames: ['follow-me-settings-panel', 'phone-settings', 'panel-content'],
    intl: inject.service(),
    phone: inject.service(),
    store: inject.service(),
    logger: inject.service('stash-logger'),
    pigeon: inject.service(),
    session: inject.service(),
    permissions: inject.service(),
    sidebar: inject.service(),
    notification: inject.service(),
    model: null,
    callRoutes: null,
    currentStation: computed.reads('phone.station'),
    calls: computed.reads('model.calls'),
    enableCallRoutes: computed.reads('model.enabled'),
    isExternalVoicemail: computed.reads('model.isExternalVoicemail'),
    actions: {
      toggleCallRoutes: function toggleCallRoutes() {
        var _this = this;

        var model = this.get('model');
        model.toggleProperty('enabled'); // If we do not have any routes configured, server errors on enable
        // We need to delay saving enabled until future

        if (this.get('calls.length') > 0) {
          model.save({
            adapterOptions: {
              patch: true
            }
          }).catch(function (error) {
            _this.get('logger').error('There was an error toggling call route state:', {
              error: error,
              toggled: model.get('enabled')
            });

            model.reload();

            _this.showErrorNotification(error);
          });
        }
      },
      selectUserStation: function selectUserStation(station) {
        return this.get('phone').chooseStation(station.get('id'));
      },
      deselectUserStation: function deselectUserStation() {
        return this.get('phone').disassociateStation();
      },
      selectNumber: function selectNumber(stationData, number) {
        stationData.set('number', number);
        this.saveCallRoutes(stationData);
      },
      selectStation: function selectStation(stationData, station) {
        stationData.set('station', station);
        this.saveCallRoutes(stationData);
      },
      addStation: function addStation(station) {
        var stations = this.get('callRoutes');

        if (stations.length === ROUTE_ENTRY_LIMIT) {
          return;
        }

        set(station, 'saved', true);
        stations.pushObject(emptyStationData());
      },
      removeStation: function removeStation(station) {
        this.get('callRoutes').removeObject(station);
        var noNewRoutes = this.get('callRoutes').filterBy('new').length === 0;

        if (noNewRoutes) {
          this.get('callRoutes').pushObject(emptyStationData());
        }

        this.saveCallRoutes();
      },
      updateVoicemail: function updateVoicemail(station, externalVoicemailState) {
        var _this2 = this;

        var model = this.get('model');
        var collection = this.get('callRoutes');
        var callRoutes = collection.reject(function (route) {
          return !route.get('target.value');
        });

        if (externalVoicemailState === true) {
          model.set('voicemail', VOICEMAIL_CHOICES.LASTCALL);
        } else if (this.get('permissions.hasVoicemailAccess')) {
          model.set('voicemail', VOICEMAIL_CHOICES.PURECLOUD);
        } else {
          model.set('voicemail', VOICEMAIL_CHOICES.NONE);
        }

        station.set('savingVoicemail', true);
        callRoutes = callRoutes.map(function (obj) {
          return obj.toAPIJSON();
        });
        model.set('calls', callRoutes);
        model.save({
          adapterOptions: {
            patch: true
          }
        }).then(function () {
          station.set('isInternalDID', false);
        }).catch(function (error) {
          _this2.get('logger').error('There was an issue setting voicemail options:', {
            error: error
          });

          _this2._checkForInternalDID(station, error);

          _this2.showErrorNotification(error);
        }).finally(function () {
          if (_this2.isDestroyed) {
            return;
          }

          station.set('savingVoicemail', false);
        });
      },
      disassociateStation: function disassociateStation() {
        return this.get('phone').disassociateStation();
      },
      openStationPicker: function openStationPicker() {
        this.set('showStationPicker', true);
      },
      disconnectPersistentConnection: function disconnectPersistentConnection() {
        return this.get('phone').disconnectPersistentConnection();
      },
      chooseStation: function chooseStation(uri) {
        return this.get('phone').chooseStation(uri);
      },
      closeStationPicker: function closeStationPicker() {
        this.set('showStationPicker', false);
      },
      openWebrtcSettings: function openWebrtcSettings() {
        this.get('sidebar').togglePanelSubstate('callControls', 'currentTab', 'phoneSettings');
      }
    },
    init: function init() {
      var _this3 = this;

      this._super.apply(this, arguments);

      if (!this.get('permissions.canCallForward')) {
        return;
      }

      var store = this.get('store');
      var guid = this.get('session.person.guid');
      var pigeon = this.get('pigeon');
      pigeon.registerPriorityTopic("v2.users.".concat(guid, ".callforwarding"));
      pigeon.on('callforwarding', this, this._updateCallForwarding);
      this.set('loading', true);
      store.findRecord('call-forwarding', guid).then(function (model) {
        if (_this3.isDestroyed) {
          return;
        }

        _this3.set('model', model);

        _this3._buildCallRoutes();
      }).catch(function (error) {
        _this3.get('logger').error('Call forwarding data not found:', {
          guid: guid,
          error: error
        });
      }).finally(function () {
        if (_this3.isDestroyed) {
          return;
        }

        _this3.set('loading', false);
      });
    },
    currentPhoneNumber: computed('session.person.primaryContactInfo.voice.[]', function () {
      var phoneField = this.get('session.person.phone');

      if (phoneField) {
        return get(phoneField, 'number');
      }

      return null;
    }),
    cannotEnable: computed('session.person.isOnQueue', function () {
      return this.get('session.person.isOnQueue');
    }),
    callsChanged: observer('calls', function () {
      Ember.run.once(this, this._buildCallRoutes);
    }),
    saveCallRoutes: function saveCallRoutes(routeToSave) {
      var _this4 = this;

      var adjustVoicemail = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var model = this.get('model');
      var collection = this.get('callRoutes');
      var addingNewCallRoute = routeToSave !== undefined && routeToSave.get('new');
      var callRoutes = collection.reject(function (route) {
        return !route.get('target.value');
      });

      if (routeToSave) {
        routeToSave.set('saving', true);
      }

      if (adjustVoicemail) {
        if (callRoutes.length === 0) {
          model.set('voicemail', VOICEMAIL_CHOICES.NONE);
        } else if (routeToSave && routeToSave.get('isStation') || get(callRoutes, 'lastObject').get('isStation')) {
          if (this.get('permissions.hasVoicemailAccess')) {
            model.set('voicemail', VOICEMAIL_CHOICES.PURECLOUD);
          } else {
            model.set('voicemail', VOICEMAIL_CHOICES.NONE);
          }
        } else {
          if (model.get('voicemail') !== VOICEMAIL_CHOICES.LASTCALL) {
            model.set('voicemail', VOICEMAIL_CHOICES.NONE);
          }
        }
      }

      callRoutes = callRoutes.map(function (obj) {
        return obj.toAPIJSON();
      });
      model.set('calls', callRoutes);
      return model.save({
        adapterOptions: {
          patch: true
        }
      }).then(function () {
        if (_this4.isDestroyed) {
          return;
        }

        if (routeToSave) {
          routeToSave.set('new', false);
          routeToSave.set('saved', true);
          routeToSave.set('isInternalDID', false); // Pretend we are doing stuff

          run.later(function () {
            routeToSave.set('saving', false);
          }, 250);
        }

        if (collection && collection.length < ROUTE_ENTRY_LIMIT && addingNewCallRoute) {
          collection.pushObject(emptyStationData());
        }
      }).catch(function (error) {
        if (_this4._isInternalDIDError(error)) {
          // If we get an internal DID error due to the voicemail prop not matching
          // we are going to retry saving after resetting the voicemail prop
          model.set('voicemail', VOICEMAIL_CHOICES.PURECLOUD);
          return _this4.saveCallRoutes(routeToSave, false).then(function () {
            // Continue to mark as internal DID
            routeToSave.set('isInternalDID', true);
          });
        }

        _this4.get('logger').error('Error saving call routes:', {
          error: error
        });

        _this4._checkForInternalDID(routeToSave, error);

        _this4.showErrorNotification(error);

        return model.reload().then(function () {
          if (_this4.isDestroyed) {
            return;
          }

          if (routeToSave) {
            routeToSave.set('saving', false);

            if (routeToSave.get('new')) {
              routeToSave.set('callRoute', {
                targets: [{}]
              });
            }
          }
        });
      });
    },
    showErrorNotification: function showErrorNotification(error) {
      var intl = this.get('intl');
      var title = intl.t('followMe.errorSaving.title');
      var message = intl.t('followMe.errorSaving.message');

      if (this._isInternalDIDError(error)) {
        return;
      }

      if (error && error.errors) {
        var code = error.errors[0].code;

        var _code$split = code.split('.'),
            _code$split2 = _slicedToArray(_code$split, 2),
            errorType = _code$split2[1];

        var titleKey = "followMe.".concat(errorType, ".title"),
            messageKey = "followMe.".concat(errorType, ".message");

        if (intl.exists(titleKey) && intl.exists(titleKey)) {
          title = intl.t(titleKey);
          message = intl.t(messageKey);
        }
      }

      this.get('notification').error(title, message);
    },
    _buildCallRoutes: function _buildCallRoutes() {
      var store = this.get('store');
      var calls = this.get('calls') || [];
      var callRoutes = (this.get('callRoutes') || []).rejectBy('new');

      if (!!calls.length && !!callRoutes.length) {
        // Modify currently cached data. Makes QA happy that elements
        // arent deleted out from under them. Also performance, I guess?
        calls.forEach(function (call, index) {
          if (callRoutes.length > index) {
            var callRoute = callRoutes.objectAt(index);
            callRoute.setProperties({
              new: false,
              saved: true,
              callRoute: call
            });
          } else {
            var wrapper = _forwardingHelpers.CallForwardingWrapper.create({
              store: store,
              saved: true,
              callRoute: call
            });

            callRoutes.pushObject(wrapper);
          }
        });
      } else {
        var stationData = calls.map(function (callRoute) {
          return _forwardingHelpers.CallForwardingWrapper.create({
            store: store,
            saved: true,
            callRoute: callRoute
          });
        });

        if (stationData.length < ROUTE_ENTRY_LIMIT) {
          stationData.pushObject(emptyStationData());
        }

        this.set('callRoutes', stationData);
      }
    },
    _updateCallForwarding: function _updateCallForwarding(data) {
      this.get('logger').info('Call forwarding pigeon change:', {
        data: data
      });
      this.get('model').reload();
    },
    _isInternalDIDError: function _isInternalDIDError() {
      var error = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var details = get(error, 'errors.firstObject');
      return details && details.code === DISABLE_VOICEMAIL_ERROR_CODE;
    },
    _checkForInternalDID: function _checkForInternalDID(station, error) {
      if (!station || !error) {
        return;
      }

      if (this._isInternalDIDError(error)) {
        station.set('isInternalDID', true);
      }
    }
  });

  _exports.default = _default;
});