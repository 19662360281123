define("web-directory/components/entity-fields/tag-field/tag-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "11x3t97G",
    "block": "{\"statements\":[[11,\"label\",[]],[16,\"for\",[34,[[26,[\"elementId\"]],\"-tag-add\"]]],[15,\"class\",\"hidden\"],[13],[1,[33,[\"t\"],[\"common.add\"],null],false],[14],[0,\"\\n\"],[1,[33,[\"input\"],null,[[\"class\",\"id\",\"value\",\"placeholder\"],[\"form-control tag-editor-input\",[33,[\"concat\"],[[28,[\"elementId\"]],\"-tag-add\"],null],[28,[\"tagValue\"]],[33,[\"t\"],[\"common.add\"],null]]]],false],[0,\"\\n\\n\"],[11,\"span\",[]],[15,\"class\",\"add\"],[13],[0,\"+\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/entity-fields/tag-field/tag-editor/template.hbs"
    }
  });

  _exports.default = _default;
});