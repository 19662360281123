define("web-directory/components/modal-dialog/component", ["exports", "ember-modal-dialog/components/modal-dialog"], function (_exports, _modalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // This is a wrapper for the modal-dialog in the ember-modal-dialog library
  var _default = _modalDialog.default.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._initEscListener();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      Ember.$('body').off('keyup.modal-dialog');
    },
    _initEscListener: function _initEscListener() {
      var _this = this;

      var closeOnEscapeKey = function closeOnEscapeKey(ev) {
        if (ev.key === 'Escape' || ev.key === 'Esc') {
          _this.sendAction('onClose');
        }
      };

      Ember.$('body').on('keyup.modal-dialog', closeOnEscapeKey);
    }
  });

  _exports.default = _default;
});