define("web-directory/components/entity-mini/component", ["exports", "web-directory/utils/presence-label-util"], function (_exports, _presenceLabelUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['entity-mini'],
    classNameBindings: ['entityTypeClass', 'isClickable:clickable'],
    intl: Ember.inject.service(),
    entity: null,
    showDepartment: true,
    showPresence: true,
    // fallback to `showPresence` when this is undefined
    subscribeToPresence: undefined,
    showFavorite: true,
    showActions: true,
    clickToProfile: true,
    isSearchResult: false,
    isEntityLocationToggleEnabled: Ember.computed.readOnly('session.features.epcDecompEntityLocation'),
    geolocation: Ember.inject.service(),
    isGeolocationEnabled: Ember.computed.readOnly('geolocation.isEnabled'),
    actions: {
      sendToProfile: function sendToProfile() {
        var person = this.get('entity');
        this.bubbleAction({
          action: 'transitionToEntityDetail',
          actionContext: [person]
        });
      }
    },
    phoneTooltip: Ember.computed(function () {
      return this.get('intl').lookup('tooltip.contactIcon.phone');
    }),
    entityTypeClass: Ember.computed('entity.type', function () {
      if (!this.get('entity.type')) {
        return 'empty-type';
      }

      return Ember.String.dasherize(this.get('entity.type'));
    }),
    entityIsPerson: Ember.computed.equal('entity.type', 'person'),
    entityIsQueue: Ember.computed.equal('entity.type', 'queue'),
    entityIsMe: Ember.computed('entity.id', 'session.person.id', function () {
      return this.get('entity.id') === this.get('session.person.id');
    }),
    entityIsGroup: Ember.computed.equal('entity.type', 'group'),
    entityIsContact: Ember.computed.equal('entity.type', 'externalContact'),
    canContactEntity: Ember.computed('entityIsGroup', 'entityIsPerson', 'entityIsContact', function () {
      return this.get('entityIsPerson') || this.get('entityIsGroup') || this.get('entityIsContact');
    }),
    isPresenceLabelToggleEnabled: Ember.computed.readOnly('session.features.epcDecompPresenceLabel'),
    useInitials: Ember.computed.reads('entityIsContact'),
    showOrganization: Ember.computed('entityIsContact', function () {
      return this.get('entityIsContact') && !!this.get('entity.organization');
    }),
    entityRoutePath: Ember.computed('entity.type', function () {
      var type = this.get('entity.type');

      if (type === 'floor') {
        type = 'location';
      }

      return "".concat(type, ".detail");
    }),
    isClickable: Ember.computed('entity._entity', 'entity.type', function () {
      var type = this.get('entity.type');

      if (type === 'floor' || type === 'location' || type === 'externalContact') {
        return false;
      }

      var entity = this.get('entity._entity');
      return entity !== 'locations' && this.get('clickToProfile');
    }),
    _subscribePresence: Ember.computed('showPresence', 'subscribeToPresence', function () {
      var subscribe = this.get('subscribeToPresence');

      if (subscribe === undefined) {
        return this.get('showPresence');
      }

      return subscribe;
    }),
    conversationSummary: Ember.computed.readOnly('entity.activities.conversationSummary'),
    message: Ember.computed.readOnly('conversationSummary.message'),
    socialExpression: Ember.computed.readOnly('conversationSummary.socialExpression'),
    totalMessages: Ember.computed('message.{contactCenter,enterprise}', 'message.contactCenter.active', 'message.enterprise.active', function () {
      var contactCenter = this.get('message.contactCenter');
      var enterprise = this.get('message.enterprise');
      return (0, _presenceLabelUtil.activityCounter)(contactCenter, enterprise);
    }),
    totalSocialExpression: Ember.computed('socialExpression.{contactCenter,enterprise}', 'socialExpression.contactCenter.active', 'socialExpression.enterprise.active', function () {
      var contactCenter = this.get('socialExpression.contactCenter');
      var enterprise = this.get('socialExpression.enterprise');
      return (0, _presenceLabelUtil.activityCounter)(contactCenter, enterprise);
    }),
    click: function click(event) {
      if (!this.get('isClickable')) {
        return;
      }

      var target = Ember.$(event.target);

      if (target.is('a') || target.is('i') || target.parents().is('.entity-contact')) {
        // Defer to link-to or primary contact icon action
        //event.stopPropagation();
        //event.preventDefault();
        return;
      }

      if (this.get('isSearchResult')) {
        // When this is a search result, only allow the link-to elements for transitioning due to the extra realestate.
        return false;
      }

      if (!target.is('a')) {
        this.send('sendToProfile');
      }
    },
    eLocation: Ember.computed('entity.geolocation.{city,region,country}', function () {
      return {
        city: Ember.get(this.entity, 'geolocation.city'),
        region: Ember.get(this.entity, 'geolocation.region'),
        country: Ember.get(this.entity, 'geolocation.country')
      };
    }),
    selfLocation: Ember.computed('session.person.geolocation.{city,region,country}', function () {
      return {
        city: Ember.get(this.session.person, 'geolocation.city'),
        region: Ember.get(this.session.person, 'geolocation.region'),
        country: Ember.get(this.session.person, 'geolocation.country')
      };
    }),
    entityLocationData: Ember.computed('eLocation', 'selfLocation', 'isGeolocationEnabled', function () {
      return {
        isGeolocationEnabled: this.get('isGeolocationEnabled'),
        eLocation: this.get('eLocation'),
        selfLocation: this.get('selfLocation')
      };
    }),
    presenceLabelData: Ember.computed('entity.{activityPresenceClass,presenceLabel,chatStatus,showConversationSummaryIndicator}', 'entity.activityValues.{calls,chats,emails}', function () {
      return {
        wrap: false,
        showStatus: true,
        showActivity: true,
        eActivityPresenceClass: Ember.get(this.entity, 'activityPresenceClass'),
        totalCalls: Ember.get(this.entity, 'activityValues.calls'),
        totalChats: Ember.get(this.entity, 'activityValues.chats'),
        totalEmails: Ember.get(this.entity, 'activityValues.emails'),
        totalMessages: Ember.get(this, 'totalMessages'),
        totalSocialExpression: Ember.get(this, 'totalSocialExpression'),
        presenceLabel: Ember.get(this.entity, 'presenceLabel'),
        eChatStatus: Ember.get(this.entity, 'chatStatus'),
        isShowingActivitySummary: Ember.get(this.entity, 'showConversationSummaryIndicator')
      };
    })
  });

  _exports.default = _default;
});