define("web-directory/utils/log-e164-info", ["exports", "web-directory/utils/external-contact-messaging", "web-directory/utils/newrelic"], function (_exports, _externalContactMessaging, _newrelic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = logE164Info;
  var get = Ember.get,
      set = Ember.set;
  /**
   * Detects the format of the provided phone number string and logs it
   * to Sumo. We are doing this to gather information about the kinds
   * of phone number formats the UI is provided from conversation-service.
   *
   * @param interaction The interaction source for the phone number to be examined
   * @returns {validInput: boolean, normalized?: boolean, converted?: boolean, missingPlusPrefix?: boolean, type?:string, subType?:string, format?: string}
   */

  function logE164Info(interaction) {
    var loggingObject = {
      evaluateAndLog: function evaluateAndLog() {
        var loggedE164Info = get(interaction, 'loggedE164Info');
        var result;
        var phoneNumberString = '';

        if (!loggedE164Info) {
          var participants = get(interaction, 'participants') || [];
          var customer = participants.findBy('purpose', 'customer');

          if (customer) {
            var type = get(interaction, 'type');
            var subType = get(interaction, 'subMessageType');
            var typesForPhone = ['call', 'callback', 'sms'];

            if (typesForPhone.indexOf(type) > -1 || typesForPhone.indexOf(subType) > -1) {
              phoneNumberString = get(customer, 'address') || get(customer, 'messages.firstObject.fromAddress.addressNormalized');
            } else {
              phoneNumberString = (0, _externalContactMessaging.getIdentifier)(interaction);
            }

            if (!phoneNumberString || typeof phoneNumberString !== 'string') {
              result = {
                validInput: false
              };
            }

            if (!result) {
              result = {
                validInput: true,
                normalized: false,
                converted: false,
                missingPlusPrefix: false,
                type: type,
                subType: subType,
                format: phoneNumberString.replace(/\d/g, '▲')
              };

              if (phoneNumberString.indexOf('+') < 0) {
                result.missingPlusPrefix = true;
              }

              var sanitizedPhoneNumber = phoneNumberString.replace(/tel:/, '');
              var normalizedPhoneNumber = intlTelInputUtils.formatNumber(sanitizedPhoneNumber);

              if (intlTelInputUtils.isValidNumber(normalizedPhoneNumber)) {
                result.normalized = true;
                result.converted = normalizedPhoneNumber !== sanitizedPhoneNumber;
              }

              try {
                (0, _newrelic.addPageAction)('logE164Info', result);
                set(interaction, 'loggedE164Info', true);
              } catch (e) {// logger message failed do nothing
              }

              interaction.removeObserver('participants.[]', loggingObject, 'evaluateAndLog');
            }
          }
        }

        return result;
      }
    };
    interaction.addObserver('participants.[]', loggingObject, 'evaluateAndLog');
    return loggingObject.evaluateAndLog();
  }
});