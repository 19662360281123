define("web-directory/components/fixtable-row-toggle", ["exports", "fixtable-ember/components/fixtable-row-toggle"], function (_exports, _fixtableRowToggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _fixtableRowToggle.default;
    }
  });
});