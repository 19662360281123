define("web-directory/utils/names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getInitials = getInitials;
  _exports.initialsSupported = initialsSupported;
  // eslint-disable-next-line no-control-regex
  var OnlyLatinChars = /^[\u0000-\u024F\s]+$/i;
  /**
   * Determines if the provided name can have initials generated from it. Not all languages support
   * the ability to reduce a name to initials, such as Latin.
   *
   * @note
   * The currently unsupported characters are:
   * - Latin
   *
   * @param name the name to test with
   * @returns boolean value depicting if initials can be generated
   */

  function initialsSupported(name) {
    return OnlyLatinChars.test(name);
  }
  /**
   * Generates the initials from a given name. If initials are not supported based on the characters of
   * the name provided then `null` will be returned.
   *
   * @note
   * This will adhere to a name string that has been formatted by a comma such as "Last, First" and will give
   * the correct initials of "FL" (for that instance).
   *
   * @param name the name of the initials in which to generate from
   * @returns the generated initials from the provided name, if none could be generated then `null` is returned
   */


  function getInitials(name) {
    if (!initialsSupported(name)) return null;
    var commaIndex = name.indexOf(',') > -1;
    var spaceIndex = name.indexOf(' ') > -1;
    var left = name[0].toUpperCase();
    var right = name.charAt(name.indexOf(' ') + 1).toUpperCase();

    if (commaIndex) {
      return right + left;
    } else if (!spaceIndex) {
      return left;
    }

    return left + right;
  }
});