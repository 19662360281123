define("web-directory/instance-initializers/entity-contact-group", ["exports", "ember-purecloud-components/components/entity-contact-group/component", "web-directory/mixins/entity-image-defaults"], function (_exports, _component, _entityImageDefaults) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    _component.default.reopen(_entityImageDefaults.default);
  }

  var _default = {
    name: 'entity-contact-group',
    initialize: initialize
  };
  _exports.default = _default;
});