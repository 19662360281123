define("web-directory/models/interaction/chat-interaction", ["exports", "web-directory/utils/dates", "web-directory/models/interaction/interaction", "web-directory/mixins/acd/session-share", "ember-purecloud-components/utils/jid-helpers"], function (_exports, _dates, _interaction, _sessionShare, _jidHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      Evented = Ember.Evented;

  var _default = _interaction.default.extend(_sessionShare.default, Evented, {
    type: 'chat',
    jid: null,
    room: null,
    interaction: Ember.inject.service(),
    provider: Ember.computed.reads('participantForCurrentUser.chats.firstObject.provider'),
    isInternalProvider: Ember.computed('provider', function () {
      var provider = this.get('provider') || '';
      return provider.indexOf('PureCloud') > -1;
    }),
    isV2Provider: Ember.computed.equal('provider', 'PureCloud Webchat v2'),
    roomId: computed('participantForCurrentUser.chats.firstObject.roomId', function () {
      return this.get('participantForCurrentUser.chats.firstObject.roomId');
    }),
    chatTimer: Ember.computed('lastMessageTime', function () {
      var lastMessageTime = this.get('lastMessageTime');
      var start;

      if ((0, _dates.default)(lastMessageTime).isBefore((0, _dates.default)(this.get('connectedTime')))) {
        lastMessageTime = null;
      }

      if (lastMessageTime && !(0, _dates.default)(lastMessageTime).isBefore(this.get('connectedTime'))) {
        start = lastMessageTime;
      } else {
        start = this.get('connectedTime');
      }

      return start;
    }),
    animateChatNotifications: Ember.observer('lastMessageWasCustomer', function () {
      // React to incoming customer chat messages
      if (!this.get('interaction.isInteractionViewOpen') && this.get('lastMessageWasCustomer')) {
        this.get('interaction').animateAcdIcon();
      }
    }),
    lastMessageText: computed('messages.lastObject', 'room.messages.lastObject', 'isChat', function () {
      var message;
      var last = this.get('room.messages.lastObject.messageBody');

      if (last && !this.get('isV2Provider')) {
        message = last;
      } else if (this.get('isV2Provider')) {
        message = this.get('messages.lastObject.body');
      } // TODO: Handle non-text types


      if (message) {
        return message.replace(/(<([^>]+)>)/gi, '');
      }
    }),
    lastMessageWasCustomer: computed('messages.lastObject', 'room.messages.lastObject', 'isChat', function () {
      var last = this.get('room.messages.lastObject');

      if (last && !this.get('isV2Provider')) {
        var address = this.get('participantForCustomer.address');
        var customerId = (0, _jidHelpers.getEntityObjectId)(address); // test is relying on the fact that there is no chatUser to return false, so need to add this if starting Ember 2.6

        if (last.get('chatUser')) {
          var chatUserId = last.get('chatUser.id');
          return (0, _jidHelpers.compareJids)(customerId, chatUserId);
        }
      } else if (this.get('isV2Provider')) {
        var _customerId = this.get('participantForCustomer.chats.0.id');

        var messageId = this.get('messages.lastObject.sender.id');
        return _customerId === messageId;
      }

      return false;
    })
  });

  _exports.default = _default;
});