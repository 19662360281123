define("web-directory/services/sidebar", ["exports", "lodash", "web-directory/services/apps", "web-directory/models/app-pool", "web-directory/utils/masonry", "web-directory/utils/focus-management", "web-directory/utils/newrelic", "web-directory/utils/presence-utils", "web-directory/router"], function (_exports, _lodash, _apps, _appPool, _masonry, _focusManagement, _newrelic, _presenceUtils, _router) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
      computed = Ember.computed,
      inject = Ember.inject,
      observer = Ember.observer,
      run = Ember.run,
      Service = Ember.Service,
      getOwner = Ember.getOwner;

  var _default = Service.extend({
    application: inject.service(),
    apps: inject.service(),
    chat: inject.service(),
    interaction: inject.service(),
    acdworkitem: inject.service(),
    phone: inject.service(),
    preferences: inject.service(),
    session: inject.service(),
    webrtc: inject.service(),
    permissions: inject.service(),
    fullscreen: inject.service(),
    help: inject.service(),

    /**
     * Holds the panel value, if any, that was restored from user preferences.
     *
     * undefined - no restored value (e.g. not stored, pref load failure)
     * null/empty - preference is no open sidebar panel
     * panel name - the panel name restored from preferences at service init
     */
    _restoredCommunicationPanel: undefined,
    communicationPanel: '',
    communicationSidebarExpanded: false,
    unreadCount: 0,
    _appsUsageCount: 0,
    allowedPopoutPanels: null,
    panelExpandedState: null,
    _monitoredAppPool: null,
    _widgetApps: null,
    _widgetAppsFailureReason: null,
    _widgetAppsAttentionCount: computed.reads('apps.widgetAppPool.totalAttentionCount'),
    listViewActionHandler: null,
    activateListView: false,
    substates: Ember.Object.create({
      callControls: Ember.Object.create({
        currentTab: 'callHistory'
      })
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this._loadStateFromPreferences();

      this._addWindowResize();

      this._onAppsEnvChange();

      this.set('allowedPopoutPanels', []);
      this.set('panelExpandedState', {
        acdInteractions: false,
        conversations: false,
        help: false,
        webrtc: false,
        agentAssistance: false,
        workitemsList: false,
        listView: false
      });
      this.set('_router', getOwner(this).lookup('router:main'));
      this.addListenerForNoopTransitions();
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      Ember.$(window).off('resize.sidebar');
      Ember.run.cancel(this.get('resizeDebounceTimer'));
    },
    // TODO: Should probably renamed to indicate boolean state as we no longer render
    // a count of active conversations
    acdCount: computed('interaction.acdCount', 'acdworkitem.activeWorkitems', function () {
      var acdCount = this.get('interaction.acdCount') || 0;
      var workitemCount = this.get('acdworkitem.activeWorkitems.length') || 0;
      return acdCount + workitemCount;
    }),
    hasActiveWorkitems: computed.bool('acdworkitem.activeWorkitems.length'),
    hasActiveWorkitemsWithFT: computed.and('hasActiveWorkitems', 'enableWorkitems'),
    deferAppsDisplay: Ember.computed.lt('_appsUsageCount', 1),
    restrictSidebar: Ember.computed.or('application.adminUIContext', 'application.isPopout'),
    showAgentAssistance: computed.equal('communicationPanel', 'agentAssistance'),
    showCallControls: computed.equal('communicationPanel', 'callControls'),
    showContactsInbox: computed.equal('communicationPanel', 'contactsInbox'),
    showConversations: computed.equal('communicationPanel', 'conversations'),
    showNotificationInbox: computed.equal('communicationPanel', 'notificationInbox'),
    showUser: computed.equal('communicationPanel', 'user'),
    showWebrtc: computed.equal('communicationPanel', 'webrtc'),
    showAcdInteractions: computed.equal('communicationPanel', 'acdInteractions'),
    showMeetings: computed.equal('communicationPanel', 'meetings'),
    showHelp: computed.equal('communicationPanel', 'help'),
    showWorkitemsList: computed.equal('communicationPanel', 'workitemsList'),
    showListView: computed.equal('communicationPanel', 'listView'),

    /**
     * `true` if the panel should be forced to expanded state while showing acd panel.
     */
    acdForceExpanded: computed.or('session.features.acdFullWidth', 'session.useMultipanel'),
    communicationOffset: computed('communicationPanel', 'session.isAuthenticated', 'showApps', 'enableApps', function () {
      var panel = this.get('communicationPanel');

      if (panel && this.get('session.isAuthenticated')) {
        var appPanelGeneralOffset = '';

        if (this.get('showApps')) {
          if (this.get('enableApps')) {
            // Add a common selector for all apps
            appPanelGeneralOffset = 'apps-offset';
          } else {
            // No Offset - trying to restore the app panel, but apps aren't available
            return '';
          }
        }

        if (panel === 'notificationInbox') {
          return "action-bar-open notification-inbox-v2-offset ".concat(appPanelGeneralOffset, " ").concat(Ember.String.dasherize(panel), "-offset");
        } else {
          return "action-bar-open ".concat(appPanelGeneralOffset, " ").concat(Ember.String.dasherize(panel), "-offset");
        }
      } else {
        return '';
      }
    }),
    sidebarCollapsed: computed('communicationPanel', 'communicationSidebarExpanded', function () {
      return this.get('communicationPanel') === '' && !this.get('communicationSidebarExpanded');
    }),
    sidebarExpanded: computed('communicationPanel', 'communicationSidebarExpanded', function () {
      return this.get('communicationPanel') !== '' && this.get('communicationSidebarExpanded');
    }),
    enableAgentAssistance: computed('chat.enabled', 'session.person.isEngageSupervisor', 'permissions.canGoOnQueue', 'permissions.canViewBusinessChat', function () {
      if (!this.get('permissions.canViewBusinessChat')) {
        if (this.get('communicationPanel') === 'agentAssistance') {
          Ember.run.throttle(this, this.closePanel, 200);
        }

        return false;
      }

      var isChatEnabled = this.get('chat.enabled');
      var isEngageAgent = this.get('permissions.canGoOnQueue');
      var isEngageSupervisor = this.get('session.person.isEngageSupervisor');
      return isChatEnabled && (isEngageAgent || isEngageSupervisor);
    }),
    enableInteractions: computed('permissions.canGoOnQueue', 'acdCount', 'session.hasTrusteeAccess', function () {
      // If we have an interaction, we should show it, otherwise, make sure we can go on queue and that we are not a trustee
      var isEnabled = this.get('acdCount') || this.get('permissions.canGoOnQueue') && !this.get('session.hasTrusteeAccess');

      if (!isEnabled && this.get('communicationPanel') === 'acdInteractions') {
        Ember.run.throttle(this, this.closePanel, 200);
      }

      return isEnabled;
    }),
    enableWorkitems: computed.readOnly('permissions.canViewWorkitem'),
    enableParkedConversations: computed.readOnly('permissions.canViewParkedEmails'),

    /**
     * Computed to dictate if the apps menu item and panel should be shown.
     *
     * @returns true if apps are enabled (FT, browser support, etc.) and
     * apps are available or the initial load failed and we know a user was
     * supposed to have apps (i.e. apps panel restore from prefs)
     */
    enableApps: computed('apps.appsSupported', '_widgetApps', '_widgetAppsFailureReason', '_restoredCommunicationPanel', function () {
      var restoredPanel = this.get('_restoredCommunicationPanel');
      return !!(this.get('apps.appsSupported') && (this.get('_widgetApps.length') || this.get('_widgetAppsFailureReason') && this._isAppPanel(restoredPanel)));
    }),

    /**
     * Returns the id of the widget app that is currently shown; null otherwise.
     *
     * In this case, shown means the widget that is selected when the apps
     * sidebar is open.
     *
     * @return the id of the widget app that is currently shown; null otherwise.
     */
    shownAppId: computed('communicationPanel', function () {
      var result = null;
      var communicationPanel = this.get('communicationPanel');

      if (this._isAppPanel(communicationPanel) && communicationPanel.length > _apps.APP_SIDEBAR_PANEL_NAME_PREFIX.length) {
        result = communicationPanel.substring(_apps.APP_SIDEBAR_PANEL_NAME_PREFIX.length);
      }

      return result;
    }),
    visibleChatRoom: computed('communicationPanel', 'phone.activeRoom', 'chat.activeRoom', 'webrtc.activeRoom', 'chat.rooms.supervisorassistance', function () {
      switch (this.get('communicationPanel')) {
        case 'conversations':
          return this.get('chat.activeRoom');

        case 'callControls':
          return this.get('phone.activeRoom');

        case 'webrtc':
          return this.get('webrtc.activeRoom');

        case 'agentAssistance':
          return this.get('chat.rooms.supervisorassistance');

        default:
          return null;
      }
    }),
    showApps: computed('communicationPanel', function () {
      // Should be true for 'apps-' (No selected app - sidebar icon click) and 'apps-<appId>'
      return this._isAppPanel(this.get('communicationPanel'));
    }),
    saveSidebarStateToPreferences: observer('communicationPanel', 'communicationSidebarExpanded', function () {
      this.get('preferences').setPreference('sidebar.global', this.getProperties('communicationPanel', 'communicationSidebarExpanded'));
    }),
    _onAppsEnvChange: observer('apps.appsSupported', 'session.isAuthenticated', 'apps.widgetAppPool', function () {
      var monitoredAppPool = this.get('_monitoredAppPool');
      var widgetAppPool = this.get('apps.widgetAppPool');

      if (!this.get('apps.appsSupported') || !this.get('session.isAuthenticated') || !widgetAppPool) {
        // Apps are not applicable at this time ... stop listening and clear out props to hide apps icon
        this.set('_widgetApps', null);

        if (monitoredAppPool) {
          monitoredAppPool.off(_appPool.APPS_RELOADED_EVENT, this, '_fetchWidgetApps');
          this.set('_monitoredAppPool', null);
        }
      } else if (widgetAppPool !== monitoredAppPool) {
        if (monitoredAppPool) {
          monitoredAppPool.off(_appPool.APPS_RELOADED_EVENT, this, '_fetchWidgetApps');
        }

        widgetAppPool.on(_appPool.APPS_RELOADED_EVENT, this, '_fetchWidgetApps');
        this.set('_monitoredAppPool', widgetAppPool);

        this._fetchWidgetApps();
      }
    }),

    /**
     * Sets the general apps panel or the specified app as the active sidebar panel.
     * In additon to visibly showing the panel, will be used to restore the sidebar across application reloads
     */
    openAppsPanel: function openAppsPanel(appId) {
      this.openPanel("".concat(_apps.APP_SIDEBAR_PANEL_NAME_PREFIX).concat(appId));
    },
    isExpandable: function isExpandable(panel) {
      if (panel === 'conversations' && !this.get('permissions.canViewBusinessChat')) {
        return false;
      }

      return panel === 'acdInteractions' || panel === 'conversations' || panel === 'help' || panel === 'webrtc' || panel === 'agentAssistance' || panel === 'workitemsList' || panel === 'listView';
    },
    togglePanelSubstate: function togglePanelSubstate(panel, key, value) {
      var subStatePanel = this.get('substates').get(panel);

      if (subStatePanel) {
        subStatePanel.set(key, value); // Sometimes the key doesnt change, but you want the panel change to fire listeners

        subStatePanel.notifyPropertyChange(key);
      }
    },
    togglePanel: function togglePanel(panel) {
      if (panel === 'conversations' && !this.get('permissions.canViewBusinessChat')) {
        return;
      }

      if (this.get('restrictSidebar') && !this.get('allowedPopoutPanels').includes(panel) || panel === 'webrtc' && !this.get('webrtc.videoCallCapable')) {
        return;
      }

      if (panel === 'acdInteractions' && this.get('session.features.acdHybridToastNoActiveQueue') && !(0, _presenceUtils.isPurecloudSource)(this.get('session.user.presence.source'))) {
        return;
      }

      var prevPanel = this.get('communicationPanel');
      var prevExpanded = this.get('communicationSidebarExpanded');

      if (panel === this.get('communicationPanel') || this.get('showApps') && panel === _apps.APP_SIDEBAR_PANEL_NAME_PREFIX) {
        this.closePanel(false);

        this._instrumentSidebarPanel('togglePanel', {
          prevPanel: prevPanel,
          prevExpanded: prevExpanded,
          newPanel: '',
          newExpanded: false
        });

        return;
      }

      this.openPanel(panel, false);

      if (panel === 'acdInteractions' && this.get('acdForceExpanded')) {
        this.expandCommunicationSidebar();
        return;
      }

      if (panel === 'workitemsList' || panel === 'listView') {
        this.expandCommunicationSidebar();

        if (this.get('permissions.canViewParkedEmails')) {
          this.set('activateListView', true);
          this.get('listViewActionHandler')();
        }

        return;
      }

      var newExpanded = false;

      if (panel === 'conversations' || panel === 'acdInteractions' || panel === 'webrtc' || panel === 'help' || panel === 'agentAssistance' || panel === 'workitemsList' || panel === 'listView') {
        if (this.get("panelExpandedState.".concat(panel))) {
          this.expandCommunicationSidebar();
          newExpanded = true;

          if (this.get('permissions.canViewParkedEmails')) {
            this.set('activateListView', true);
            this.get('listViewActionHandler')();
          }
        } else {
          this.minimizeCommunicationSidebar();
        }
      } else {
        this.minimizeCommunicationSidebar();
      }

      this._instrumentSidebarPanel('togglePanel', {
        prevPanel: prevPanel,
        prevExpanded: prevExpanded,
        newPanel: panel,
        newExpanded: newExpanded
      });

      this.checkThresholds();
    },

    /**
     * Prevent the parked conversation router from restarting when being navigated away to a different view.
     * @param handler Function to trigger setActiveView when the list view button is interacted.
     */
    registerListViewActionHandler: function registerListViewActionHandler(handler) {
      if (this.get('permissions.canViewParkedEmails')) {
        this.set('listViewActionHandler', handler);
      }
    },
    updateMasonry: function updateMasonry() {
      (0, _masonry.rerunLayouts)();
    },
    openPanel: function openPanel(panel) {
      var _this = this;

      var instrumentCall = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      if (panel === 'conversations' && !this.get('permissions.canViewBusinessChat')) {
        return;
      }

      if (this.get('restrictSidebar') && !this.get('allowedPopoutPanels').includes(panel)) {
        return;
      }

      var prevPanel = this.get('communicationPanel');
      this.set('communicationPanel', panel);
      this.updateMasonry();

      if (instrumentCall) {
        this._instrumentSidebarPanel('openPanel', {
          prevPanel: prevPanel,
          newPanel: panel
        });
      }

      this.checkThresholds();

      if (panel === 'conversations') {
        run.later(function () {
          return _this.get('chat').refocusActiveRoom();
        }, 500);
      }

      if (this._isAppPanel(panel)) {
        this.incrementProperty('_appsUsageCount');
      }

      if (this.get('fullscreen.isFullScreen')) {
        this.get('fullscreen').endFullScreen();
      }

      var focusTarget = '.action-panel:not(.hidden-panel)';
      (0, _focusManagement.doFocus)(Ember, focusTarget);
    },
    closePanel: function closePanel() {
      var instrumentCall = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      if (instrumentCall) {
        this._instrumentSidebarPanel('closePanel', {
          prevPanel: this.get('communicationPanel'),
          newPanel: ''
        });
      }

      this.setProperties({
        communicationPanel: '',
        communicationSidebarExpanded: false
      });
      this.updateMasonry();
    },
    toggleCommunicationSidebarExpanded: function toggleCommunicationSidebarExpanded() {
      var currExpanded = this.get('communicationSidebarExpanded');

      if (currExpanded) {
        this.minimizeCommunicationSidebar();
      } else {
        this.expandCommunicationSidebar();
      }

      this._instrumentSidebarPanel('togglePanelExpanded', {
        prevExpanded: currExpanded,
        newExpanded: !currExpanded
      });

      this.checkThresholds();
    },
    setCommunicationSidebarState: function setCommunicationSidebarState(state) {
      this.set('communicationSidebarExpanded', state);
      var panel = this.get('communicationPanel');

      if (panel) {
        this.set("panelExpandedState.".concat(panel), state); // Prevent parked conversation router from restarting when listView is the initial active view

        if (this.get('permissions.canViewParkedEmails') && this.get('listViewActionHandler')) {
          this.get('listViewActionHandler')();
        }
      }
    },
    expandCommunicationSidebar: function expandCommunicationSidebar() {
      Ember.run.once(this, this.setCommunicationSidebarState, true);
    },
    minimizeCommunicationSidebar: function minimizeCommunicationSidebar() {
      var panel = this.get('communicationPanel');

      if (this.get('acdForceExpanded')) {
        if (panel !== 'acdInteractions') {
          Ember.run.once(this, this.setCommunicationSidebarState, false);
        }
      } else {
        Ember.run.once(this, this.setCommunicationSidebarState, false);
      }
    },
    ensureRouteVisibility: function ensureRouteVisibility() {
      var panel = this.get('communicationPanel');

      if (this.isExpandable(panel)) {
        var forceExpandACD = this.get('acdForceExpanded') && panel === 'acdInteractions';
        var isWorkitemsListPanel = 'workitemsList' === panel;
        var isListViewPanel = 'listView' === panel;

        if (forceExpandACD || this._isBelowWidthThreshold() || isWorkitemsListPanel || isListViewPanel) {
          this.closePanel();

          this._instrumentSidebarPanel('ensureRouteVisibility', {
            op: 'sidebarClosed'
          });
        } else if (!forceExpandACD) {
          var panelExpanded = this.get('communicationSidebarExpanded');
          this.minimizeCommunicationSidebar();

          this._instrumentSidebarPanel('ensureRouteVisibility', {
            op: panelExpanded ? 'sidebarMinimized' : 'noOp'
          });
        }
      }
    },
    checkThresholds: function checkThresholds() {
      var panel = this.get('communicationPanel');
      var forceExpandACD = this.get('acdForceExpanded') && panel === 'acdInteractions';

      if (forceExpandACD) {
        this.expandCommunicationSidebar();
      } else if (this._isBelowWidthThreshold() && this.isExpandable(panel)) {
        var panelExpanded = this.get('communicationSidebarExpanded');
        this.expandCommunicationSidebar();

        if (!panelExpanded) {
          this._instrumentSidebarPanel('checkThresholds', {
            op: 'sidebarExpanded'
          });
        }
      }

      this.set('application.belowMobileThreshold', this._isBelowMobileThreshold());
    },
    addListenerForNoopTransitions: function addListenerForNoopTransitions() {
      var _this2 = this;

      var router = this.get('_router');

      if (router) {
        router.on(_router.NOOP_TRANSITION, function () {
          _this2.ensureRouteVisibility();
        });
      }
    },
    _fetchWidgetApps: function _fetchWidgetApps() {
      var _this3 = this;

      // findAllApps will only fail on initial load, otherwise, this method is called in response to a successful reload
      this.get('apps.widgetAppPool').findAllApps().then(function (widgetApps) {
        _this3.set('_widgetApps', widgetApps);

        _this3.set('_widgetAppsFailureReason', null);
      }, function (reason) {
        Ember.Logger.error('Failed to fetch widget apps for sidebar', {
          error: reason
        });

        _this3.set('_widgetApps', null);

        _this3.set('_widgetAppsFailureReason', reason || true);
      });
    },
    _loadStateFromPreferences: function _loadStateFromPreferences() {
      var _this4 = this;

      if (!this.get('application.adminUIContext') && !this.get('application.isPopout')) {
        var canDisplayVideoPanel = true;
        this.get('preferences').getPreference('sidebar.global').then(function (preferences) {
          if (!preferences || _this4.isDestroyed || _this4.isDestroying) {
            return;
          }

          var restoredPanel = get(preferences, 'communicationPanel');

          if (restoredPanel === 'conversations' && !_this4.get('permissions.canViewBusinessChat')) {
            restoredPanel = '';
          }

          if (_this4.get('acdForceExpanded') && restoredPanel === 'acdInteractions') {
            Ember.set(preferences, 'communicationSidebarExpanded', true);
          }

          if (_this4._isAppPanel(restoredPanel)) {
            _this4.incrementProperty('_appsUsageCount');
          }

          if (restoredPanel === '') {
            Ember.set(preferences, 'communicationSidebarExpanded', false);
          }

          _this4.set('_restoredCommunicationPanel', restoredPanel);

          _this4.setProperties(preferences);
        }).catch(function (e) {
          if (!_this4.get('isDestroying') && !_this4.get('isDestroyed')) {
            Ember.Logger.error('Error getting preference:', {
              error: e
            });
          }
        }).finally(function () {
          if (!_this4.get('isDestroying') && !_this4.get('isDestroyed')) {
            // Defer this check until afterRender to maximize chances all sidebar layout is complete
            // This should be moved into a sidebar component long-term
            if (canDisplayVideoPanel) {
              run.scheduleOnce('afterRender', function () {
                return _this4.checkThresholds();
              });
            } else {
              _this4.closePanel();
            }
          }
        });
      }
    },
    _addWindowResize: function _addWindowResize() {
      if (!this.get('application.adminUIContext')) {
        Ember.$(window).on('resize.sidebar', Ember.run.bind(this, function () {
          var timer = Ember.run.debounce(this, this.checkThresholds, 250);
          this.set('resizeDebounceTimer', timer);
        }));
      }
    },
    _isBelowWidthThreshold: function _isBelowWidthThreshold() {
      return Ember.$(window).width() < 1280;
    },
    _isBelowMobileThreshold: function _isBelowMobileThreshold() {
      return Ember.$(window).width() < 1200;
    },
    _isAppPanel: function _isAppPanel(panel) {
      return _lodash.default.startsWith(panel, _apps.APP_SIDEBAR_PANEL_NAME_PREFIX);
    },
    instrumentSidebarPanel: function instrumentSidebarPanel(sidebarChangeType) {
      var additionalData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      this._instrumentSidebarPanel(sidebarChangeType, additionalData);
    },
    _instrumentSidebarPanel: function _instrumentSidebarPanel(sidebarChangeType) {
      var additionalData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var sidebarData = {
        currPanel: this.get('communicationPanel'),
        // Heads up: can get set via run.once, so, not guaranteed to be correct in all events
        currExpanded: this.get('communicationSidebarExpanded')
      };
      var router = this.get('_router');

      if (router) {
        sidebarData.currRouteName = get(router, 'currentRouteName');
      }

      var activeInteractions = this.get('interaction.activeInteractions') || [];
      var interactionData = {
        totalInteractionCount: activeInteractions.length
      };
      activeInteractions.forEach(function (curr) {
        var currInteractionType = curr.get('type');

        if (currInteractionType) {
          var propName = "".concat(currInteractionType, "InteractionCount");

          if (interactionData[propName]) {
            interactionData[propName]++;
          } else {
            interactionData[propName] = 1;
          }
        }
      });
      var selectedInteraction = this.get('interaction.selectedInteraction');

      if (selectedInteraction) {
        interactionData.selectedInteractionId = get(selectedInteraction, 'id');
        interactionData.selectedInteractionType = get(selectedInteraction, 'type');
      }

      var contextualHelpAnchor = this.get('help.contextualHelpAnchor');

      if (contextualHelpAnchor) {
        this.set('help.contextualHelpAnchor', undefined);
      }

      var evtPayload = Object.assign({
        sidebarChangeType: sidebarChangeType
      }, sidebarData, interactionData, additionalData);
      (0, _newrelic.addPageAction)('sidebarStateChange', evtPayload);
    }
  });

  _exports.default = _default;
});