define("web-directory/mixins/phone-service/call-forward", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    callForwardingActive: false,
    callForwardingNumber: null,
    callForwardingExtension: null,
    fetchCallForwarding: function fetchCallForwarding() {
      var _this = this;

      if (this.get('canCallForward')) {
        this.getCallForwarding().then(function (response) {
          return _this._updateCallForwarding(response);
        }).catch(function () {
          _this.set('callForwardingActive', false);
        });
      }
    },
    getCallForwarding: function getCallForwarding() {
      var url = this.get('application').pcV2Uri('api/v2/users/' + this.get('personGuid') + '/callForwarding');
      return this.get('ajax').ajaxGet(url);
    },
    _updateCallForwarding: function _updateCallForwarding(response) {
      var enabled = _lodash.default.isEmpty(response) ? false : response.enabled;
      this.set('callForwardingActive', enabled);
      var phoneNumber = response.phoneNumber;
      var hasPhoneNumber = !_lodash.default.isEmpty(phoneNumber);

      if (hasPhoneNumber) {
        if (_lodash.default.startsWith(phoneNumber, ';ext=')) {
          var re = /^;ext=(\d{1,})$/;

          var number = _lodash.default.last(re.exec(phoneNumber));

          this.set('callForwardingExtension', number);
          return this.set('callForwardingNumber', null);
        } else {
          this.set('callForwardingNumber', phoneNumber);
          return this.set('callForwardingExtension', null);
        }
      }
    }
  });

  _exports.default = _default;
});