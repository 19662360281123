define("web-directory/instance-initializers/setup-application", ["exports", "web-directory/utils/markdown", "ember-purecloud-components/utils/emoji", "web-directory/services/favicon"], function (_exports, _markdown, _emoji, _favicon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'setup-application',
    initialize: function initialize(instance) {
      var application = instance.lookup('application:main'); // looking up sound service here loads all of the sounds and creates handlers for onplay and onstop events.
      // see PCDWEBK-3861 for more info.

      var sound = instance.lookup('service:sound'); // eslint-disable-line no-unused-vars

      Ember.$(window).on('beforeunload', function (event) {
        return Ember.run(function () {
          // mailto: links cause beforeunload to fire.
          var isLink = false;
          var $target = Ember.$(Ember.get(event, 'target.activeElement'));
          var href = $target.attr('href');

          if (!href) {
            $target = Ember.$($target).closest('a');
            href = $target.attr('href');
          }

          if (href) {
            isLink = href.indexOf('mailto') > -1 || href.indexOf('tel') > -1;
          }

          if (isLink) {
            return;
          }

          try {
            var webrtcSdkService = instance.lookup('service:webrtc-sdk');
            var hasActiveCall = webrtcSdkService.get('hasActiveSoftphoneConversation');

            if (!webrtcSdkService.get('useSdk')) {
              var webrtcService = instance.lookup('service:webrtc');
              var phoneService = instance.lookup('service:phone');
              var conversation = webrtcService.get('conversations').find(function (c) {
                return c.get('isConnected');
              });
              var persistentConnectionActive = phoneService.get('persistentConnectionActive');
              var hasPhoneConversation = phoneService.get('activeConversations.length');
              var idlePersistent = persistentConnectionActive && !hasPhoneConversation;
              hasActiveCall = !!conversation && !idlePersistent;
            }

            if (hasActiveCall) {
              var intl = instance.lookup('service:intl');
              return intl.lookup('purecloud.promptForRefreshActiveCall');
            }
          } catch (e) {
            Ember.Logger.error('Could not find active conversation and active persistent connection', {
              error: e
            });
          }

          try {
            var pigeonService = instance.lookup('service:pigeon');

            if (pigeonService) {
              // Avoid reconnecting upon a refresh.
              pigeonService.reconnect = function () {};

              pigeonService.disconnect();
            }
          } catch (e) {
            Ember.Logger.error('Could not find pigeon service', {
              error: e
            });
          }
        });
      });
      var urlPrefix = application.get('urlprefix');
      (0, _emoji.initializeEmojiRendering)(urlPrefix);

      var isApplicationRoute = function isApplicationRoute(href) {
        return !!/^\/?#\/.*\/[a-f0-9]{24}/.test(href) || !!/^\/?#\/.*\/[a-z0-9-]{36}$/i.test(href);
      };

      (0, _markdown.initializeMarkdownRendering)(isApplicationRoute);
      (0, _favicon.initializeFavicon)();
    }
  };
  _exports.default = _default;
});