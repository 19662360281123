define("web-directory/services/batch-data-requests", ["exports", "lodash", "web-directory/utils/entity-fields", "ember-purecloud-components/mixins/logger", "ember-purecloud-components/utils/jid-helpers"], function (_exports, _lodash, _entityFields, _logger, _jidHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isJid = isJid;
  var RSVP = Ember.RSVP;

  function isGuid(id) {
    if (!id) {
      return false;
    }

    return !!id.match(/^[a-z0-9-]{36}$/i);
  }

  function isJid(id) {
    if (!id) {
      return false;
    }

    return !!id.match(/(?!.*\/).*@.*$/i);
  }

  var _default = Ember.Service.extend(_logger.default, {
    store: Ember.inject.service(),
    peopleQueue: {},
    groupQueue: {},
    getUser: function getUser(id) {
      var _this = this;

      return new RSVP.Promise(function (resolve) {
        _this.checkCacheForEntity(id, 'person', resolve);
      });
    },
    refreshUser: function refreshUser(id) {
      var _this2 = this;

      return new RSVP.Promise(function (resolve) {
        _this2.queueEntity('person', id, resolve);
      });
    },
    getChatUser: function getChatUser(jid) {
      var _this3 = this;

      return new RSVP.Promise(function (resolve) {
        _this3.checkCacheForEntity(jid, 'person', resolve);
      });
    },
    getGroup: function getGroup(jid) {
      var _this4 = this;

      return new RSVP.Promise(function (resolve) {
        _this4.checkCacheForEntity(jid, 'group', resolve);
      });
    },
    checkCacheForEntity: function checkCacheForEntity(objectId, entityType, resolve) {
      var cachedModel = this.get('store').peekRecord(entityType, objectId);

      if (cachedModel) {
        return resolve(cachedModel);
      }

      if (objectId.indexOf('@') > -1) {
        var possibleCache = this.get('store').peekAll(entityType);

        if (possibleCache) {
          cachedModel = possibleCache.findBy('jid', objectId);
        }
      }

      if (cachedModel) {
        return resolve(cachedModel);
      }

      this.queueEntity(entityType, objectId, resolve);
    },
    queueEntity: function queueEntity(entityType, entityId, resolve) {
      var possibleJid = entityId;

      if (isJid(entityId)) {
        entityId = (0, _jidHelpers.getEntityObjectId)(entityId);
      }

      if (!isGuid(entityId) && !(0, _jidHelpers.isDirectoryId)(entityId) && !(0, _jidHelpers.isDirectoryId)((0, _jidHelpers.getEntityObjectId)(entityId))) {
        var placeholder = this.get('store').push({
          data: {
            type: entityType,
            id: entityId,
            _id: entityId,
            general: {
              name: [{
                labelKey: 'name',
                value: entityId
              }]
            }
          }
        });
        return resolve(placeholder);
      }

      var queue = {};

      switch (entityType) {
        case 'person':
          queue = this.get('peopleQueue');
          break;

        case 'group':
          queue = this.get('groupQueue');
          break;
      }

      if (queue[possibleJid] == null) {
        queue[possibleJid] = [];
      }

      queue[possibleJid].push(resolve);
      this.scheduleQueues();
    },
    scheduleQueues: function scheduleQueues() {
      Ember.run.debounce(this, this._scheduleQueues, 500);
    },
    _scheduleQueues: function _scheduleQueues() {
      Ember.run.once(this, this._handleQueues);
    },
    _handleQueues: function _handleQueues() {
      if (!(this.isDestroyed || this.isDestroying)) {
        var people = this.clearQueue('peopleQueue');
        var groups = this.clearQueue('groupQueue');

        if (!_lodash.default.isEmpty(people)) {
          this.processQueue('person', people);
        }

        if (!_lodash.default.isEmpty(groups)) {
          this.processQueue('group', groups);
        }
      }
    },
    clearQueue: function clearQueue(queue) {
      var value = this.get(queue);
      this.set(queue, {});
      return value;
    },
    processQueue: function processQueue(entityType, queue) {
      var _this5 = this;

      var ids = _lodash.default.keys(queue);

      this.logger.debug('BATCH: Processing queue', entityType, ids.length);
      return this.get('store').findMany(entityType, ids, _entityFields.FL[entityType]).then(function (results) {
        return _this5.resolveResults(entityType, queue, results);
      }).catch(function (err) {
        _this5.logger.error('BATCH: Failed to fetch entities in bulk:', {
          entityType: entityType,
          ids: ids.length < 25 ? ids.join(',') : ids.length,
          err: err
        });

        return _this5.resolveResults(entityType, queue, []);
      });
    },
    resolveResults: function resolveResults(entityType, queue, results) {
      var _this6 = this;

      var ids = Object.keys(queue);

      _lodash.default.forEach(results, function (entity) {
        // entities are requested/queued by jid, id (guid) or id (_id, mongoid)
        ['id', 'jid', '_id'].forEach(function (id) {
          var entityId = entity.get(id);
          var promises = queue[entityId];

          if (promises) {
            promises.forEach(function (resolve) {
              return resolve(entity);
            });
          }

          _lodash.default.remove(ids, function (id) {
            return id === entityId;
          });
        });
      });

      if (ids.length > 0) {
        var missingIds = ids.length < 100 ? ids.join(',') : ids.length;
        this.logger.info('BATCH: Queue had remaining items not resolved, resolving with placeholder for single request:', {
          entityType: entityType,
          missingIds: missingIds
        });

        _lodash.default.forEach(ids, function (id) {
          var keyId = id;

          if (isJid(id)) {
            id = (0, _jidHelpers.getEntityObjectId)(id);
          }

          var placeholder = _this6.get('store').push({
            data: {
              type: entityType,
              id: id,
              _id: id,
              general: {
                name: [{
                  labelKey: 'name',
                  value: id
                }]
              }
            }
          });

          _lodash.default.forEach(queue[keyId], function (resolve) {
            return resolve(placeholder);
          });
        });
      }
    }
  });

  _exports.default = _default;
});