define("web-directory/components/free-trial-support/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['free-trial-support'],
    application: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    route: '/trial-support',
    init: function init() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.setupFrameRouter();
    },
    setupFrameRouter: function setupFrameRouter() {
      var appUrl = "".concat(window.location.origin, "/trial-support-ui/#/");

      if (window.location.hostname.indexOf('localhost') > -1) {
        appUrl = 'http://localhost:5000/#/';
      }

      var frame = this.element.querySelector('frame-router');

      if (!frame) {
        return;
      }

      frame.setupFrames({
        support: {
          url: appUrl,
          assignedRoute: this.get('route')
        }
      }, {
        locale: this.get('intl.isoRegionalLocale'),
        hostRootUrl: window.location.origin,
        custom: {
          authToken: this.get('session.accessToken')
        }
      });
    },
    actions: {
      closeTrialSupport: function closeTrialSupport() {
        this.get('application').dismissTrialSupport();
      }
    }
  });

  _exports.default = _default;
});