define("web-directory/helpers/equals", ["exports", "fixtable-ember/helpers/equals"], function (_exports, _equals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _equals.default;
    }
  });
  Object.defineProperty(_exports, "equals", {
    enumerable: true,
    get: function get() {
      return _equals.equals;
    }
  });
});