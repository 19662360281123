define("web-directory/components/inbox-panel-v2/share-content/shared-entity-image/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed,
      Component = Ember.Component;

  var _default = Component.extend({
    store: inject.service(),
    sharedEntity: null,
    entity: computed('sharedEntity.id', function () {
      var sharedEntity = this.get('sharedEntity');
      var modelName = 'person';

      if (Ember.get(sharedEntity, 'memberType') === 'GROUP') {
        modelName = 'group';
      }

      return this.store.find(modelName, sharedEntity.id);
    })
  });

  _exports.default = _default;
});