define("web-directory/components/entity-profile/section-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZvoC15Bl",
    "block": "{\"statements\":[[11,\"span\",[]],[15,\"class\",\"icon\"],[13],[0,\"\\n    \"],[11,\"i\",[]],[15,\"class\",\"pc-moon pc-contacts\"],[13],[14],[0,\"\\n\"],[14],[0,\"\\n\"],[11,\"span\",[]],[15,\"class\",\"name\"],[13],[0,\"\\n    \"],[11,\"a\",[]],[15,\"href\",\"#\"],[13],[1,[28,[\"section\",\"sectionName\"]],false],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/entity-profile/section-icon/template.hbs"
    }
  });

  _exports.default = _default;
});