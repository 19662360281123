define("web-directory/transforms/iso-date", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Transform = _emberData.default.Transform;

  var _default = Transform.extend({
    deserialize: function deserialize(serialized) {
      return new Date(serialized);
    },
    serialize: function serialize(deserialized) {
      return deserialized.toISOString();
    }
  });

  _exports.default = _default;
});