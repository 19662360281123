define("web-directory/components/common-badge/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oPXEFSNs",
    "block": "{\"statements\":[[11,\"p\",[]],[15,\"class\",\"common-badge__text\"],[13],[0,\"\\n    \"],[18,\"default\"],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/common-badge/template.hbs"
    }
  });

  _exports.default = _default;
});