define("web-directory/components/acd-interactions-panel/interaction-notes/interaction-notes-history/interaction-notes-history-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['interaction-notes-history-item'],

    /**
     * `true` if the current user can view the chat when clicking on the authors avatar
     */
    canViewChat: false,

    /**
     * The item object needed to render the component view
     */
    item: null
  });

  _exports.default = _default;
});