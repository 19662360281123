define("web-directory/components/card-tree/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component;

  var _default = Component.extend({
    classNames: ['card-tree-container', 'container-fluid'],
    didInsertElement: function didInsertElement() {
      this.attachTextInputListeners();
    },
    willDestroyElement: function willDestroyElement() {
      this.removeTextInputListeners();
    },
    attachTextInputListeners: function attachTextInputListeners() {
      var fields = $.merge(Ember.$('.ember-text-area'), Ember.$('.ember-text-field'));
      fields.on('keydown', function (event) {
        return event.stopPropagation();
      });
    },
    removeTextInputListeners: function removeTextInputListeners() {
      var fields = $.merge(Ember.$('.ember-text-area'), Ember.$('.ember-text-field'));
      fields.off('keydown');
    }
  });

  _exports.default = _default;
});