define("web-directory/components/mini-card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MiniCardComponent = Ember.Component.extend({
    tagName: 'a',
    attributeBindings: ['href'],
    classNameBindings: [':mini-card', 'isManager:manager-card:'],
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    entity: null,
    currentPerson: null,
    managerCount: null,
    geolocation: Ember.inject.service(),
    isGeolocationEnabled: Ember.computed.readOnly('geolocation.isEnabled'),
    isEntityLocationToggleEnabled: Ember.computed.readOnly('session.features.epcDecompEntityLocation'),
    isManager: Ember.computed('entity', 'currentPerson', function () {
      return this.get('entity.id') === this.get('currentPerson.manager.id');
    }),
    managerCountText: Ember.computed('managerCount', function () {
      var intl = this.get('intl');
      var count = this.get('managerCount');

      if (count === 1) {
        return intl.lookup('person.relationships.peerCount.singular', {
          count: count
        });
      } else {
        return intl.lookup('person.relationships.peerCount.plural', {
          count: count
        });
      }
    }),
    click: function click() {
      this.sendAction('action', this.get('entity'));
    },
    eLocation: Ember.computed('entity.geolocation.{city,region,country}', function () {
      return {
        city: Ember.get(this.entity, 'geolocation.city'),
        region: Ember.get(this.entity, 'geolocation.region'),
        country: Ember.get(this.entity, 'geolocation.country')
      };
    }),
    selfLocation: Ember.computed('session.person.geolocation.{city,region,country}', function () {
      return {
        city: Ember.get(this.session.person, 'geolocation.city'),
        region: Ember.get(this.session.person, 'geolocation.region'),
        country: Ember.get(this.session.person, 'geolocation.country')
      };
    }),
    entityLocationData: Ember.computed('eLocation', 'selfLocation', 'isGeolocationEnabled', function () {
      return {
        isGeolocationEnabled: this.get('isGeolocationEnabled'),
        eLocation: this.get('eLocation'),
        selfLocation: this.get('selfLocation')
      };
    })
  });
  var _default = MiniCardComponent;
  _exports.default = _default;
});