define("web-directory/components/entity-mini/queue/component", ["exports", "lodash", "web-directory/utils/dates"], function (_exports, _lodash, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['entity-mini-queue'],
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    engageanalytics: Ember.inject.service(),
    queueService: Ember.inject.service('queue'),
    permissions: Ember.inject.service(),
    makingCall: false,
    isDetailsLoaded: false,
    isDetailsLoading: false,
    queue: null,
    clickToProfile: false,
    showActions: false,
    canViewQueueDetails: Ember.computed.bool('permissions.canViewQueueObservations'),
    actions: {
      getQueueData: function getQueueData() {
        var _this = this;

        var interval = (0, _dates.generateCurrentInterval)();
        var id = this.get('queue.id'); // Ultimately, UX wants to show the following:
        //   - Active agents
        //   - Waiting customers by media type
        //   - Longest wait time (AR-1102)
        //   - Queue description  (CW-724 is the closest JIRA that mentions this, https://projects.invisionapp.com/share/545F0JYVS#/screens/124326256)

        if (id) {
          this.set('isDetailsLoading', true);
          var promises = {
            joinedPromise: this.get('engageanalytics').getQueueObservations([id], interval, {
              metrics: ['oOnQueueUsers', 'oWaiting']
            }),
            waitTimePromise: this.get('queueService').getQueueEstimatedWaitTime(id, this.get('interactionType'))
          };
          Ember.RSVP.hash(promises).then(function (hash) {
            var joinedData = hash.joinedPromise;
            var waitTimeResults = hash.waitTimePromise.results;

            var getMetricCount = function getMetricCount(array, metricName) {
              var metricData = _lodash.default.find(array, {
                metric: metricName
              });

              return metricData.stats.count;
            };

            var getOnQueueCounts = function getOnQueueCounts(array, routingStatus) {
              var getRoutingStatusCount = function getRoutingStatusCount(datum, routingStatus) {
                if (routingStatus) {
                  return datum.metric === 'oOnQueueUsers' && datum.qualifier === routingStatus;
                }

                return datum.metric === 'oOnQueueUsers';
              };

              return _lodash.default.chain(array).filter(_lodash.default.partial(getRoutingStatusCount, _lodash.default, routingStatus)).map(_lodash.default.property('stats.count')).filter(function (value) {
                return typeof value === 'number';
              }).sum().value();
            };

            var getWaitTime = function getWaitTime(array) {
              var bestTime = _lodash.default.find(array, {
                formula: 'BEST'
              });

              if (bestTime) {
                return bestTime.estimatedWaitTimeSeconds;
              }
            };

            var joined = _lodash.default.find(joinedData, function (element) {
              return element.group.queueId === id && element.group.mediaType === undefined;
            });

            var callData = _lodash.default.find(joinedData, function (element) {
              return element.group.queueId === id && element.group.mediaType === 'voice';
            });

            var callbackData = _lodash.default.find(joinedData, function (element) {
              return element.group.queueId === id && element.group.mediaType === 'callback';
            });

            var chatData = _lodash.default.find(joinedData, function (element) {
              return element.group.queueId === id && element.group.mediaType === 'chat';
            });

            var emailData = _lodash.default.find(joinedData, function (element) {
              return element.group.queueId === id && element.group.mediaType === 'email';
            });

            var messageData = _lodash.default.find(joinedData, function (element) {
              return element.group.queueId === id && element.group.mediaType === 'message';
            });

            var onQueueCount = 0;
            var idleCount = 0;

            if (joined) {
              onQueueCount = getOnQueueCounts(joined.data);
              idleCount = getOnQueueCounts(joined.data, 'IDLE');
            }

            var callWaiting;

            if (callData) {
              callWaiting = getMetricCount(callData.data, 'oWaiting');
            }

            var callbackWaiting;

            if (callbackData) {
              callbackWaiting = getMetricCount(callbackData.data, 'oWaiting');
            }

            var chatWaiting;

            if (chatData) {
              chatWaiting = getMetricCount(chatData.data, 'oWaiting');
            }

            var emailWaiting;

            if (emailData) {
              emailWaiting = getMetricCount(emailData.data, 'oWaiting');
            }

            var messageWaiting;

            if (messageData) {
              messageWaiting = getMetricCount(messageData.data, 'oWaiting');
            }

            var queueData = {
              agentData: "".concat(idleCount, "/").concat(onQueueCount),
              callCount: callWaiting,
              callbackCount: callbackWaiting,
              chatCount: chatWaiting,
              emailCount: emailWaiting,
              messageCount: messageWaiting
            };
            var waitStr;
            var bestWaitTime = getWaitTime(waitTimeResults);

            if (bestWaitTime !== undefined) {
              // show estimated wait time
              var wait = _dates.default.duration(bestWaitTime * 1000); // convert from sec to ms


              var intl = _this.get('intl');

              var hr = wait.hours(),
                  min = wait.minutes(),
                  sec = wait.seconds();

              if (hr) {
                // use hours, minutes, & seconds
                waitStr = intl.t('purecloud.analytics.statValue.hoursMinutesSeconds', {
                  seconds: sec.toString(),
                  minutes: min.toString(),
                  hours: hr.toString()
                });
              } else if (min) {
                // use minutes & seconds
                waitStr = intl.t('purecloud.analytics.statValue.minutesSeconds', {
                  seconds: sec.toString(),
                  minutes: min.toString()
                });
              } else {
                // use seconds
                waitStr = intl.t('purecloud.analytics.statValue.seconds', {
                  seconds: sec.toString()
                });
              }

              queueData.waitTime = waitStr;
            }

            _this.set('queue.queueDetails', queueData);

            _this.set('isDetailsLoaded', true);
          }).finally(function () {
            return _this.set('isDetailsLoading', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});