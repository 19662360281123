define("web-directory/components/call-input/component", ["exports", "lodash", "web-directory/mixins/search", "web-directory/models/person", "web-directory/models/queue", "web-directory/models/external-contact", "web-directory/models/externalcontacts/contact", "web-directory/models/group", "web-directory/mixins/relate/relate-features", "web-directory/services/phone-integrations", "web-directory/utils/escape", "web-directory/utils/keystroke"], function (_exports, _lodash, _search, _person, _queue, _externalContact, _contact, _group, _relateFeatures, _phoneIntegrations, _escape, _keystroke) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var computed = Ember.computed,
      inject = Ember.inject,
      observer = Ember.observer;

  var _default = Ember.Component.extend(_search.SearchMixin, _relateFeatures.default, {
    classNames: ['call-input'],
    classNameBindings: ['hasParties', 'expandDialpadWidth'],
    session: inject.service(),
    intl: inject.service(),
    addressformat: inject.service(),
    permissions: inject.service(),
    canViewQueues: computed.reads('permissions.canViewQueue'),
    input: null,
    tagsinput: null,
    participants: null,
    maxTags: 10,
    queue: null,
    blockNonEntities: false,
    dialpadValue: null,
    searchValue: null,
    entity: null,
    queueCleared: null,
    showResults: false,
    enableQueueSearch: true,
    divisionAgnostic: computed.reads('permissions.canTargetQueueSearchOutsideOfDivision'),
    entityFilter: 'people,externalContacts',
    placeholderKey: 'purecloud.callEntry',
    hasInvalidNumber: false,
    integrationKey: _phoneIntegrations.DEFAULT_PHONE_INTEGRATION_KEY,
    allowInvalidNumbers: false,
    resetValues: function resetValues() {
      this.get('tagsinput').$input.val('');
      this.get('tagsinput').focus();
      this.set('showResults', false);
      this.set('searchResults', []);
    },
    getFirstEntityFromFilteredList: function getFirstEntityFromFilteredList() {
      return _lodash.default.head(this.get('filteredSearchResults'));
    },
    pushParticipant: function pushParticipant(participant) {
      if (this.get('participants.length') > this.get('maxTags')) {
        return;
      }

      var participants = this.get('participants');

      if (participants.findBy('id', participant.id)) {
        return;
      }

      if (participant instanceof _person.default || participant instanceof _queue.default) {
        if (this.get('numberInputMode')) {
          var number = Ember.get(participant, 'phone.number');
          participants.addObject({
            id: number,
            value: number
          });
          this.get('tagsinput').add({
            id: number,
            value: number
          });
        } else {
          participants.addObject({
            id: participant.id,
            entity: participant
          });
          this.get('tagsinput').add({
            id: participant.id,
            value: participant.get('name')
          });
        }
      } else if (participant instanceof _externalContact.default) {
        var name = participant.get('name');

        var _number = participant.get('phone.value.number');

        participants.addObject({
          id: participant.id,
          value: _number
        });
        this.get('tagsinput').add({
          id: participant.id,
          value: name
        });
      } else if (participant instanceof _contact.default) {
        var _name = participant.get('name');

        var _number2 = participant.get('phoneNumber') || participant.get('bestPhoneNumber');

        var phoneType = participant.get('phoneType') || participant.get('bestPhoneType');
        var tagName;
        tagName = (0, _escape.unescape)(this.get('intl').t('purecloud.externalContactNameTag', {
          name: _name,
          phoneType: phoneType
        }));

        if (_number2) {
          participants.addObject({
            id: participant.id,
            value: _number2,
            entity: {
              type: 'externalcontacts/contact'
            }
          });
          this.get('tagsinput').add({
            id: participant.id,
            value: tagName.toString().replace('&amp;', '&')
          });
        }
      } else if (participant instanceof _group.default) {
        var _number3 = participant.get('number.value.number') || participant.get('number.value.extension');

        participants.addObject({
          id: participant.id,
          value: _number3
        });
        this.get('tagsinput').add({
          id: participant.id,
          value: participant.get('name')
        });
      } else if (!this.get('blockNonEntities') && !participants.findBy('id', participant)) {
        participants.addObject({
          value: participant,
          id: participant
        });
        this.get('tagsinput').add({
          value: participant,
          id: participant
        });
      }

      if (this.get('numberInputMode')) {
        this.formatNumbers();
      }

      this.set('searchValue', '');
      this.sendAction('updateValue', this.get('searchValue'));
    },
    actions: {
      selectEntity: function selectEntity(entity) {
        this.pushParticipant(entity);
        this.resetValues();
      }
    },
    hasParties: computed.notEmpty('participants'),
    placeholder: computed('participants.[]', function () {
      var placeholder = '';

      if (!this.get('participants.length')) {
        placeholder = this.get('intl').t(this.get('placeholderKey'));
      }

      return placeholder;
    }),
    setPlaceholder: observer('placeholder', function () {
      var _this = this;

      var placeHolderKey = this.get('placeholderKey');
      Ember.run(function () {
        var placeholder = '';

        if (!_lodash.default.isEmpty(_this.get('placeholder'))) {
          placeholder = _this.get('intl').t(placeHolderKey);
        }

        try {
          _this.$('input').attr('placeholder', placeholder);
        } catch (err) {// noop
        }
      });
    }),
    // Search when integration key changes (user has changed the search tab)
    setIntegrationKey: observer('integrationKey', function () {
      var integrationKey = this.get('integrationKey');

      if (integrationKey && integrationKey !== '' && integrationKey !== _phoneIntegrations.DEFAULT_PHONE_INTEGRATION_KEY) {
        this.set('searchIntegrationKey', integrationKey);
      } else {
        this.set('searchIntegrationKey', null);
      }

      Ember.run.debounce(this, this._makeSearchCall, 400);
    }),
    clearParticipants: observer('participants.[]', function () {
      Ember.run(this, function () {
        if (!this.get('participants.length')) {
          this.get('tagsinput').removeAll();

          if (this.get('numberInputMode')) {
            this.set('hasInvalidNumber', false);
          }
        }
      });
    }),
    showSearchResults: computed('showResults', 'searchResults.[]', function () {
      return this.get('filteredSearchResults.length') && this.get('showResults');
    }),
    hasExternalContacts: computed('filteredSearchResults', function () {
      var foundRelateContact = _lodash.default.some(this.get('filteredSearchResults'), function (result) {
        return result.get('type') === 'externalcontacts/contact';
      });

      return foundRelateContact;
    }),
    expandDialpadWidth: computed('hasExternalContacts', 'showSearchResults', 'isDialpadOpen', function () {
      var shouldExpand = this.get('hasExternalContacts') && this.get('showSearchResults');

      if (this.get('isDialpadOpen') && shouldExpand && this.get('sidebarService.showAcdInteractions')) {
        this.set('showDialpad', false);
      }

      return shouldExpand;
    }),
    searchQueue: computed.and('enableQueueSearch'),
    filteredSearchResults: computed('searchResults.[]', function () {
      var _this2 = this;

      return _lodash.default.reject(this.get('searchResults'), function (entity) {
        var isGroup = entity.get('type') === 'group';
        var hasGroupNumber = isGroup && entity.get('contactInfo.groupNumber.length');
        var entityId = entity.get('id');

        var parties = _this2.get('participants');

        return isGroup && !hasGroupNumber || _lodash.default.some(parties, function (party) {
          return party.id === entityId;
        });
      });
    }),
    attachOutsideListener: Ember.on('didInsertElement', function () {
      var _this3 = this;

      Ember.$(window).on("click.".concat(this.get('elementId')), function (event) {
        Ember.run(function () {
          var target = Ember.$(event.target);

          if (!target.parents('.call-input').length) {
            _this3.set('showResults', false);
          }
        });
        return true;
      });
    }),
    removeListener: Ember.on('willDestroyElement', function () {
      Ember.$(window).off(".".concat(this.get('elementId')));
    }),
    makeSelection: function makeSelection(entity) {
      this.pushParticipant(entity);
      this.resetValues();
    },
    // Handle arrow down, up, and enter keys for search result selection
    attachKeyboardNavigation: function attachKeyboardNavigation() {
      var _this4 = this;

      var currentIndex = 0;
      this.get('tagsinput').$input.bind('keyup', function (event) {
        return Ember.run(function () {
          var str = (0, _keystroke.eventToString)(event);
          var isArrow = (0, _keystroke.isNavigation)(event);
          var isEnter = str === 'ENTER';

          if (!(isArrow || isEnter)) {
            return;
          }

          event.preventDefault();

          if (str === 'LEFT_ARROW' || str === 'RIGHT_ARROW') {
            return;
          }

          var elems = _this4.$('li');

          if (isEnter) {
            event.stopPropagation();
            var index = elems.index(elems.filter('.active'));

            var results = _this4.get('filteredSearchResults');

            if (index < elems.length && index < results.length) {
              var entity = results.objectAt(index);

              if (entity) {
                _this4.makeSelection(entity);
              }
            }
          } else if (!_lodash.default.isEmpty(elems)) {
            if (!elems.hasClass('active')) {
              currentIndex = 0;
            } else if (str === 'UP_ARROW') {
              currentIndex = Math.max(0, currentIndex - 1);
            } else if (str === 'DOWN_ARROW') {
              currentIndex = Math.min(elems.length - 1, currentIndex + 1);
            }

            elems.removeClass('active');
            var target = Ember.$(elems[currentIndex]);

            if (!target) {
              return;
            }

            var parent = target.parent();
            var topHeight = target.position().top;
            var parentHeight = parent.height();

            if (parentHeight - topHeight < target.height() || topHeight < 0) {
              var position = target.offset().top - parent.offset().top + parent.scrollTop();
              target.parent().scrollTop(position);
            }

            target.addClass('active');
          }
        });
      });
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var newDialpadValue = Ember.get(arguments, '0.newAttrs.dialpadValue.value');

      if (newDialpadValue === null) {
        return;
      }

      this._handleDialpadInputChange();
    },
    _getSelectInput: function _getSelectInput() {
      return this.$('select') || this.$("#".concat(this.elementId, "-tags-input"));
    },
    init: function init() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, function () {
        var _this5 = this;

        var $tagsinput = this._getSelectInput().tagsinput({
          itemValue: 'id',
          itemText: 'value',
          maxTags: this.get('maxTags')
        });

        this.set('tagsinput', $tagsinput[0]);
        this.get('participants').forEach(function (party) {
          var id = party.id;
          var value = party.entity ? party.entity.get('name') : party.value;

          _this5.get('tagsinput').add({
            id: id,
            value: value
          });
        });
        var $input = this.get('tagsinput').$input;
        $input.focus();
        $input.bind('keyup change input', function (event) {
          return Ember.run(function () {
            var value = event.target.value;

            _this5.set('searchValue', event.target.value);

            _this5.sendAction('updateValue', value); // Clear old dialpad value


            _this5.set('dialpadValue', null); // Collapse the results list if escape key is pressed


            if ((0, _keystroke.isEscape)(event)) {
              _this5.set('showResults', false);
            }

            var enterHit = 'ENTER' === (0, _keystroke.eventToString)(event);

            var isActive = _this5.$('li').hasClass('active');

            if (!enterHit || isActive) {
              return;
            }

            if (!_lodash.default.isEmpty(value)) {
              _this5.pushParticipant(value);

              _this5.resetValues();
            } else if (_this5.get('participants.length')) {
              _this5.sendAction('makeCall');
            }
          });
        }); // Show results list on focus of input

        $input.bind('focus', function () {
          return Ember.run(function () {
            if (_this5.get('filteredSearchResults.length')) {
              _this5.set('showResults', true);
            }
          });
        });
        this.attachKeyboardNavigation();
        $input.contextmenu(function () {
          _this5.set('searchResults', []);
        });

        this._getSelectInput().on('itemRemoved', function (event) {
          Ember.run(function () {
            if (_this5.get('queue')) {
              _this5.set('queueCleared', true);
            }

            _this5.get('participants').forEach(function (item, index) {
              if (event.item && item.id === event.item.id) {
                _this5.get('participants').removeAt(index);
              }
            });

            if (_this5.get('numberInputMode')) {
              _this5.formatNumbers();
            }
          });
        });
      });
    },
    removeBehavior: Ember.on('willDestroyElement', function () {
      this.$('input').off();
      this.$('select').off().tagsinput('destroy');
    }),
    setSearchQuery: observer('searchValue', function () {
      var value = this.get('searchValue');

      if (typeof this.get('checkTransferInput') === 'function') {
        this.get('checkTransferInput')(value);
      }

      var entity = this.get('entity');
      this.set('showResults', true);

      if (entity && entity.name === value) {
        this.set('searchQuery', '');
        return;
      }

      var hasAsteriskAndNumbers = value && value.startsWith('*') && value.length > 1 && _toConsumableArray(value.split('*').slice(1).join('')).every(function (number) {
        return Number.isInteger(parseInt(number));
      });

      if (value === '' || hasAsteriskAndNumbers) {
        var deferred = this.get('pendingSearchRequest');

        if (deferred) {
          deferred.reject('Request Cancelled');
        }

        this.set('searchQuery', '');
        this.set('searchResults', []);
        this.set('entity', null);
        return;
      }

      var number = typeof value === 'string' ? parseInt(value.charAt(0), 10) : null; // Must allow numbers in search query if queues can be searched as they can start with a digit
      // But, do not query if dialpad is being used

      var shouldSetSearchQuery = this._shouldSetSearchQuery({
        number: number,
        value: value,
        enableQueueSearch: this.get('enableQueueSearch'),
        dialpadValue: this.get('dialpadValue')
      });

      if (shouldSetSearchQuery) {
        this.set('searchQuery', value);
      }
    }),
    _shouldSetSearchQuery: function _shouldSetSearchQuery(_ref) {
      var number = _ref.number,
          value = _ref.value,
          enableQueueSearch = _ref.enableQueueSearch,
          dialpadValue = _ref.dialpadValue;
      return dialpadValue === null && (typeof number !== 'number' || isNaN(value) || enableQueueSearch);
    },
    _handleDialpadInputChange: function _handleDialpadInputChange() {
      Ember.run.next(this, function () {
        if (this.get('dialpadValue') === null) {
          return;
        }

        var $input = this.get('tagsinput').$input;
        $input.focus();
        var caretInput = $input[0];
        var caretPosition = caretInput.selectionStart;
        var current = $input.val();
        var newValue = current.substr(0, caretPosition) + this.get('dialpadValue') + current.substr(caretPosition);
        $input.val(newValue);
        caretInput.selectionStart = caretPosition + 1;
        caretInput.selectionEnd = caretPosition + 1;
        $input.trigger('keyup');
      });
    },
    _makeSearchCall: function _makeSearchCall() {
      this.performSearch();
    },
    entityChanged: observer('entity', function () {
      var entity = this.get('entity');
      this.sendAction('updateValue', Ember.get(entity, 'name'));
    }),
    formatNumber: function formatNumber(number) {
      var newNumber = this.get('addressformat').isValidNumber(number, null, true) ? number : this.get('addressformat').isValidNumber('+' + number, null, true) ? '+' + number : null;
      var e164Number = this.get('addressformat').formatAddress(newNumber);
      var displayNumber = this.get('addressformat').formatAddressForDisplay(e164Number);

      if (this.get('allowInvalidNumbers') && !displayNumber) {
        displayNumber = number;
      }

      return {
        id: number,
        value: displayNumber
      };
    },
    formatNumbers: function formatNumbers() {
      var _this6 = this;

      var formattedNumbers = _lodash.default.map(this.get('participants'), function (entry) {
        return _this6.formatNumber(entry.value);
      });

      var hasInvalidNumber = _lodash.default.some(formattedNumbers, function (entry) {
        return entry.value === null;
      });

      this.set('hasInvalidNumber', hasInvalidNumber);
      this.set('participants', formattedNumbers);
    }
  });

  _exports.default = _default;
});