define("web-directory/components/favorites-panel/favorites-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lCVaq7Ja",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"loading\"]]],null,{\"statements\":[[0,\"    \"],[11,\"span\",[]],[15,\"class\",\"loading-indicator\"],[13],[0,\"\\n        \"],[11,\"i\",[]],[15,\"class\",\"pc-moon pc-loading pc-spin\"],[13],[14],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\n\"],[11,\"div\",[]],[15,\"class\",\"favorites\"],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"favoritesList\"]]],null,{\"statements\":[[0,\"        \"],[1,[33,[\"entity-mini/default\"],null,[[\"entity\",\"showPresence\",\"showFavorite\"],[[28,[\"entity\"]],true,true]]],false],[0,\"\\n\"]],\"locals\":[\"entity\"]},{\"statements\":[[6,[\"unless\"],[[28,[\"loading\"]]],null,{\"statements\":[[0,\"            \"],[11,\"div\",[]],[15,\"class\",\"no-results\"],[13],[0,\"\\n                \"],[1,[26,[\"emptyStateText\"]],false],[0,\"\\n            \"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]}],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/favorites-panel/favorites-list/template.hbs"
    }
  });

  _exports.default = _default;
});