define("web-directory/models/role", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    intl: Ember.inject.service(),
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    permissions: _emberData.default.attr(),
    permissionPolicies: _emberData.default.attr(),
    userCount: _emberData.default.attr('number'),
    default: _emberData.default.attr('boolean'),
    base: _emberData.default.attr('boolean'),
    type: Ember.computed('default', function () {
      return this.get('default') ? this.get('intl').lookup('groups.roles.default') : this.get('intl').lookup('groups.roles.custom');
    })
  });

  _exports.default = _default;
});