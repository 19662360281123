define("web-directory/serializers/station", ["exports", "web-directory/serializers/purecloud"], function (_exports, _purecloud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _purecloud.default.extend({
    attrs: {
      webrtcMediaDscp: 'webRtcMediaDscp',
      webrtcForceTurn: 'webRtcForceTurn',
      persistentConnection: 'webRtcPersistentEnabled',
      requireMediaHelper: 'webRtcRequireMediaHelper'
    }
  });

  _exports.default = _default;
});