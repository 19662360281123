define("web-directory/components/inbox-panel-v2/share-content/searchbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h38od2dn",
    "block": "{\"statements\":[[11,\"input\",[]],[15,\"class\",\"select2 form-control\"],[15,\"type\",\"hidden\"],[13],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/inbox-panel-v2/share-content/searchbox/template.hbs"
    }
  });

  _exports.default = _default;
});