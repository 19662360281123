define("web-directory/models/chat-communication", ["exports", "ember-purecloud-components/models/chat-communication"], function (_exports, _chatCommunication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _chatCommunication.default;
    }
  });
});