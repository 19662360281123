define("web-directory/instance-initializers/attach-application-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(instance) {
    var applicationController = instance.lookup('controller:application');
    var chatService = instance.lookup('service:chat');
    var phoneService = instance.lookup('service:phone');
    phoneService.set('applicationController', applicationController);
    chatService.set('applicationController', applicationController);
  }

  var _default = {
    name: 'attach-application-controller',
    initialize: initialize
  };
  _exports.default = _default;
});