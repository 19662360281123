define("web-directory/routes/reset-password", ["exports", "web-directory/routes/signup"], function (_exports, _signup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Note: UnauthenticatedRouteMixin comes from SignupRoute
  var _default = _signup.default.extend({
    intl: Ember.inject.service(),
    routeTitle: Ember.computed(function () {
      return this.get('intl').lookup('auth.reset');
    })
  });

  _exports.default = _default;
});