define("web-directory/app-providers/integration-interapptions-provider", ["exports", "web-directory/utils/apps", "web-directory/utils/acd", "web-directory/models/app"], function (_exports, _apps, _acd, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.boolExpr = _exports.INTEGRATIONS_INTERAPPTIONS_PROVIDER_ID = void 0;
  var inject = Ember.inject,
      typeOf = Ember.typeOf,
      isBlank = Ember.isBlank;
  var PROVIDER_LOG_NAMESPACE = 'Integration Interapptions Provider';
  var INTEGRATIONS_INTERAPPTIONS_PROVIDER_ID = 'builtInIntegrationsInterapptionsProvider';
  _exports.INTEGRATIONS_INTERAPPTIONS_PROVIDER_ID = INTEGRATIONS_INTERAPPTIONS_PROVIDER_ID;
  var IntegrationsInterapptionsProvider = Ember.Object.extend({
    apps: inject.service(),
    stashLogger: inject.service('stash-logger'),
    id: INTEGRATIONS_INTERAPPTIONS_PROVIDER_ID,
    type: 'programmatic',
    trustedProvider: true,
    monitorInteractionUpdates: true,
    _cachedApps: null,
    fetchInterapptions: function fetchInterapptions(interactionId) {
      var _this = this;

      return this.get('apps').findAllInterapptions().then(function (apps) {
        _this.set('_cachedApps', apps);

        return apps;
      }).catch(function (reason) {
        _this.get('stashLogger').error(_apps.default.buildInterapptionsLogMsg('Failed to fetch interapption defs from service - falling back to the cached list of apps.', PROVIDER_LOG_NAMESPACE), {
          reason: reason,
          remoteData: {
            interactionId: interactionId
          }
        });

        return _this.get('_cachedApps') || [];
      });
    },
    getAppConfigs: function getAppConfigs(appContext) {
      var _this2 = this;

      /*
       * Interapptions are access controlled by groups (ad-hoc) or permissions (premium)
       * They are assumed included unless a filter is specified and fails
       * Filters are applied as logical ANDs
       */
      return this.fetchInterapptions(appContext.id).then(function (interapptions) {
        return interapptions.filter(function (currInterapption) {
          return _this2._filterForQueue(currInterapption, appContext) && _this2._filterForInteraction(currInterapption, appContext);
        });
      });
    },
    _filterForInteraction: function _filterForInteraction(interapption, appContext) {
      var interaction = appContext.payload,
          appContextType = appContext.type,
          interactionId = appContext.id;

      if (appContextType === 'workitem') {
        var enableWorkitem = interapption.get('enableWorkitem');

        if (typeof enableWorkitem !== 'boolean') {
          this.get('stashLogger').debug(_apps.default.buildInterapptionsLogMsg('Unsupported enableWorkitem type. Interaction Widget will be filtered out.', PROVIDER_LOG_NAMESPACE), {
            interaction: interaction,
            remoteData: {
              interactionId: interactionId,
              type: appContextType
            }
          });
          return false;
        }

        return enableWorkitem;
      }

      var enabledCommunicationTypes = interapption.get('enabledCommunicationTypes');

      if (enabledCommunicationTypes && enabledCommunicationTypes.length > 0) {
        // If 'none' is included in the list of enabled communication types, then it will not be displayed for any conversation.
        if (enabledCommunicationTypes.includes('none')) return false;
        var currInteractionCommType = (0, _acd.getCommunicationType)(interaction);

        if (!_app.INTERAPPTION_COMMUNICATION_TYPE_FILTERS.includes(currInteractionCommType)) {
          this.get('stashLogger').debug(_apps.default.buildInterapptionsLogMsg('Unsupported Interaction commmunication type.  Interaction Widget will be filtered out.', PROVIDER_LOG_NAMESPACE), {
            interaction: interaction,
            remoteData: {
              interactionId: interactionId,
              type: interaction.get('type')
            }
          });
        }

        if (!currInteractionCommType || !enabledCommunicationTypes.includes(currInteractionCommType)) {
          return false;
        }
      }

      return true;
    },
    _filterForQueue: function _filterForQueue(interapption, appContext) {
      var interaction = appContext.payload,
          appContextType = appContext.type;
      /* Filter by queue:
       *
       * Here, we use the current user's queue rather than the customer for a few reasons:
       * * The original customer queue is not updated on transfers
       * * The interapptions can change in response to queue transfers (Consult, bad IVR route, etc.)
       * * Transfers to a direct user inherit the queue ID of the transfer-requesting agent.
       *
       * In the future, we could add different queue filtering mechanisms:
       * * Queue matched at any point in conversation history
       * * Any Agent queue (all or active)
       */

      var enabledQueueIds = interapption.get('enabledQueueIds');

      if (enabledQueueIds && enabledQueueIds.length > 0) {
        var currQueueId = appContextType === 'workitem' ? Ember.get(interaction, 'agent.queueId') : interaction.get('participantForCurrentUser.queueId');
        return typeOf(currQueueId) === 'string' && !isBlank(currQueueId) && enabledQueueIds.includes(currQueueId.trim());
      }

      return true;
    }
  });
  var boolExpr = 'bool:true';
  _exports.boolExpr = boolExpr;
  var _default = IntegrationsInterapptionsProvider;
  _exports.default = _default;
});