define("web-directory/components/group-profile/roles-edit/component", ["exports", "lodash", "web-directory/mixins/job"], function (_exports, _lodash, _job) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_job.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    notification: Ember.inject.service(),
    session: Ember.inject.service(),
    groupAssignedRoles: Ember.inject.service(),
    // tracks the roles/division relationships for display
    isEditingRoles: false,
    displayRoleRemoveModal: false,
    rolesToDelete: [],
    displayRulesRolesWarningModel: false,
    // tracks all unsaved grant changes when editing roles for group
    unsavedGrants: new Ember.Object({
      roles: {},
      //map of roles that contains a map of unsaved-grant objects
      _hasUnsavedGrants: false,
      parent: undefined,
      //pass reference after instantiation
      hasUnsavedGrants: undefined,
      //create computed after instantiation
      add: function add(_ref) {
        var grantId = _ref.grantId,
            roleId = _ref.roleId,
            divisionId = _ref.divisionId;
        var grantModel = this.get('parent.store').peekRecord('grant', grantId);

        if (!this.get('roles')[roleId]) {
          this.get('roles')[roleId] = {};
        } //if grant already exists, then just make sure we don't delete it


        if (grantModel) {
          delete this.get('roles')[roleId][grantId];
        } else {
          this.get('roles')[roleId][grantId] = {
            action: 'save',
            roleId: roleId,
            divisionId: divisionId
          };
        }

        this.recalculateHasUnsavedGrants();
      },
      remove: function remove(_ref2) {
        var grantId = _ref2.grantId,
            roleId = _ref2.roleId;

        if (!this.get('roles')[roleId]) {
          this.get('roles')[roleId] = {};
        }

        var grantModel = this.get('parent.store').peekRecord('grant', grantId);

        if (grantModel) {
          this.get('roles')[roleId][grantId] = {
            action: 'delete'
          };
        } else {
          delete this.get('roles')[roleId][grantId];
        }

        this.recalculateHasUnsavedGrants();
      },
      addAllFutureDivisions: function addAllFutureDivisions(_ref3) {
        var grantId = _ref3.grantId,
            roleId = _ref3.roleId;
        this.get('roles')[roleId] = {};
        this.add({
          divisionId: '*',
          roleId: roleId,
          grantId: grantId
        });
      },
      removeAllFutureDivisions: function removeAllFutureDivisions(roleId, grantId) {
        this.remove({
          roleId: roleId,
          grantId: grantId
        });
      },
      recalculateHasUnsavedGrants: function recalculateHasUnsavedGrants() {
        var grantMaps = _lodash.default.values(this.get('roles'));

        var hasUnsavedGrants = false;
        grantMaps.forEach(function (grantMap) {
          if (Object.keys(grantMap).length) {
            hasUnsavedGrants = true;
          }
        });
        this.set('_hasUnsavedGrants', hasUnsavedGrants);
      },
      clear: function clear() {
        this.set('roles', {});
        this.recalculateHasUnsavedGrants();
      }
    }),
    groupRolesViewColumns: Ember.computed('model', 'session.features', function () {
      var nameColumn = {
        key: 'role.name',
        header: this.get('intl').lookup('groups.roles.name'),
        cellClass: 't-role-name-view'
      };
      var typeColumn = {
        key: 'role.type',
        header: this.get('intl').lookup('groups.roles.type'),
        cellClass: 't-role-type-view'
      };
      var descriptionColumn = {
        key: 'role.description',
        header: this.get('intl').lookup('common.description'),
        cellClass: 't-role-description-view'
      };
      var divisionsViewColumn = {
        key: 'divisions',
        header: this.get('intl').lookup('groups.roles.divisions'),
        width: '255px',
        component: 'group-profile/roles-edit/fixtable/division-view',
        cellClass: 't-role-divisions-view'
      };
      var deleteColumn = {
        header: 'delete button',
        hideLabel: true,
        component: 'group-profile/roles-edit/fixtable/list-remove',
        iconClass: 'fa fa-times',
        width: '1px',
        class: 'list-remove-button'
      };
      return [nameColumn, typeColumn, descriptionColumn, divisionsViewColumn, deleteColumn];
    }),
    groupRolesEditColumns: Ember.computed('model', 'session.features', function () {
      var nameColumn = {
        key: 'name',
        header: this.get('intl').lookup('groups.roles.name'),
        cellClass: 't-role-name-edit'
      };
      var typeColumn = {
        key: 'type',
        header: this.get('intl').lookup('groups.roles.type'),
        cellClass: 't-role-type-edit'
      };
      var descriptionColumn = {
        key: 'description',
        header: this.get('intl').lookup('common.description'),
        cellClass: 't-role-description-edit'
      };
      var divisionsEditColumn = {
        key: 'divisions',
        header: this.get('intl').lookup('groups.roles.divisions'),
        width: '350px',
        component: 'group-profile/roles-edit/fixtable/division-select',
        cellClass: 't-role-divisions-edit'
      };
      var assignmentColumn = {
        header: this.get('intl').lookup('groups.roles.selection'),
        component: 'group-profile/roles-edit/fixtable/is-assigned-toggle',
        width: '1px',
        cellClass: 't-role-is-assigned'
      };
      return [nameColumn, typeColumn, descriptionColumn, divisionsEditColumn, assignmentColumn];
    }),
    hasRules: Ember.computed('model.membership.inclusions', 'model.membership.exclusions', function () {
      return !_lodash.default.isEmpty(this.get('model.membership.inclusions')) || !_lodash.default.isEmpty(this.get('model.membership.exclusions'));
    }),
    actions: {
      editRoles: function editRoles() {
        if (this.get('hasRules')) {
          this.set('displayRulesRolesWarningModel', true);
        } else {
          this.set('isEditingRoles', true);
          this.set('allRolesFiltered', this.get('allRoles'));
        }
      },
      toggleRolesEnabled: function toggleRolesEnabled() {
        this._toggleRolesEnabled();
      },
      confirmWarningModal: function confirmWarningModal() {
        this.set('displayRulesRolesWarningModel', false);

        this._enableRoles();
      },
      closeRulesRolesWarningModal: function closeRulesRolesWarningModal() {
        this.set('displayRulesRolesWarningModel', false);
      },
      getGroupRoles: function getGroupRoles() {
        this._getGroupRoles();
      },
      deleteRoles: function deleteRoles() {
        this._deleteRoles();
      },
      openConfirmModelSingleRole: function openConfirmModelSingleRole(roleId) {
        var grantRole = this.get('groupAssignedRoles.list').find(function (grantRoles) {
          return Ember.get(grantRoles, 'id') === roleId;
        });
        this.set('rolesToDelete', [grantRole.role]);
        this.set('displayRoleRemoveModal', true);
      },
      openConfirmModelMultipleRole: function openConfirmModelMultipleRole(divisionRoles) {
        var roles = divisionRoles.map(function (divisionRole) {
          return divisionRole.role;
        });
        this.set('rolesToDelete', roles);
        this.set('displayRoleRemoveModal', true);
      },
      save: function save() {
        var _this = this;

        this.set('isLoadingAllRoles', true);
        var rolesToUpdate = {}; //map a role Id to a list of division ids to add that role for bulk save

        var grantsToDelete = [];
        var version = this.get('model.grantVersion');

        _lodash.default.values(this.get('unsavedGrants.roles')).forEach(function (grantMap) {
          _lodash.default.forOwn(grantMap, function (_ref4, grantId) {
            var action = _ref4.action,
                roleId = _ref4.roleId,
                divisionId = _ref4.divisionId;

            if (action === 'save') {
              if (rolesToUpdate[roleId]) {
                rolesToUpdate[roleId].pushObject(divisionId);
              } else {
                rolesToUpdate[roleId] = [divisionId];
              }
            } else if (action === 'delete') {
              var undeletedGrant = _this.get('store').peekRecord('grant', grantId);

              if (undeletedGrant) {
                grantsToDelete.pushObject(undeletedGrant);
              }
            } else {
              Ember.Logger.error('grant actions other than save or delete are not supported');
            }
          });
        }); // Make API calls sequentially to avoid api clobbering data


        _lodash.default.reduce(rolesToUpdate, function (promise, divisionIds, roleId) {
          return promise.then(function () {
            return _this.get('store').adapterFor('grant').saveBulk(roleId, [_this.get('model.guid')], divisionIds);
          });
        }, Ember.RSVP.Promise.resolve()).then(function () {
          return grantsToDelete.reduce(function (promise, undeletedGrant) {
            return promise.then(function () {
              return undeletedGrant.destroyRecord();
            });
          }, Ember.RSVP.Promise.resolve());
        }).then(function () {
          _this.get('unsavedGrants').clear();

          _this._stopEditingRoles();

          _this.get('notification').success(null, _this.get('intl').lookup('groups.roles.roleAssignmentSaveSuccess'));

          return _this._getGroupRoles(version);
        }).catch(function (err) {
          if (_this._isTooManyGrantsError(err)) {
            _this.get('notification').error(null, _this.get('intl').lookup('groups.roles.tooManyGrantsSaveError'));
          } else {
            _this.get('notification').error(null, _this.get('intl').lookup('groups.roles.roleAssignmenSaveError'));
          }

          Ember.Logger.error('error saving grants', {
            error: err
          });
        }).finally(function () {
          _this.set('isLoadingAllRoles', false);
        });
      },
      searchAssignedRoles: function searchAssignedRoles(query) {
        if (query.length < 2) {
          this.set('groupAssignedRolesFiltered', this.get('groupAssignedRoles.list'));
        } else {
          var filteredRoles = this.get('groupAssignedRoles.list').filter(function (roleDivision) {
            return Ember.get(roleDivision, 'role.name').toLowerCase().includes(query.toLowerCase());
          });
          this.set('groupAssignedRolesFiltered', filteredRoles);
        }
      },
      searchAllRoles: function searchAllRoles(query) {
        if (query.length < 2) {
          this.set('allRolesFiltered', this.get('allRoles'));
        } else {
          var filteredRoles = this.get('allRoles').filter(function (role) {
            return role.get('name').toLowerCase().includes(query.toLowerCase());
          });
          this.set('allRolesFiltered', filteredRoles);
        }
      },
      cancel: function cancel() {
        this._stopEditingRoles();
      },
      updateUnsavedGrants: function updateUnsavedGrants(roleId, selectedDivisions, previouslySelectedDivisions) {
        this._updateUnsavedGrants(roleId, selectedDivisions, previouslySelectedDivisions);
      },
      closeWarningModal: function closeWarningModal() {
        this.set('rolesToDelete', []);
        this.set('displayRoleRemoveModal', false);
      },
      confirmHasRolesWarningModal: function confirmHasRolesWarningModal() {
        this.set('displayHasRolesWarningModel', false);

        this._disableRoles();
      },
      closeHasRolesWarningModal: function closeHasRolesWarningModal() {
        this.set('displayHasRolesWarningModel', false);
      },
      toggleRoleAssignment: function toggleRoleAssignment(roleId, isAssigned) {
        if (isAssigned) {
          this.get('groupAssignedRoles').addRole(roleId, {});
        } else {
          this.get('groupAssignedRoles').removeRole(roleId);
        }
      }
    },
    _stopEditingRoles: function _stopEditingRoles() {
      this.get('unsavedGrants').clear();

      this._getGroupRoles();

      Ember.trySet(this, 'isEditingRoles', false);
    },
    _updateUnsavedGrants: function _updateUnsavedGrants(roleId, selectedDivisions, previouslySelectedDivisions) {
      var _this2 = this;

      //add selected divisions to unsaved grants map
      selectedDivisions.forEach(function (division) {
        var grantId = _this2.get('model.guid') + roleId + division.id;

        _this2.get('unsavedGrants').add({
          grantId: grantId,
          roleId: roleId,
          divisionId: division.id
        });
      }); //remove unselected divisions

      previouslySelectedDivisions.forEach(function (division) {
        var stillSelectedDivision = selectedDivisions.find(function (stillSelectedDivision) {
          return division.id === stillSelectedDivision.id;
        });

        if (!stillSelectedDivision) {
          var grantId = _this2.get('model.guid') + roleId + division.id;

          _this2.get('unsavedGrants').remove({
            grantId: grantId,
            roleId: roleId
          });
        }
      }); //keep unsaved divisions change between role pages

      if (selectedDivisions.length) {
        this.get('groupAssignedRoles').addRole(roleId, {
          id: roleId,
          role: this.get('store').peekRecord('role', roleId),
          divisions: selectedDivisions
        });
      } else {
        this.get('groupAssignedRoles').removeRole(roleId);
      }
    },
    _removeRole: function _removeRole(role) {
      var _this3 = this;

      var grantsToDelete = [];
      var divisionRole = this.get('groupAssignedRoles').getRoleWithDivisions(role.id);
      Ember.get(divisionRole, 'divisions').forEach(function (division) {
        var grantId = _this3.get('model.guid') + Ember.get(role, 'id') + division.get('id');

        var grantRecord = _this3.get('store').peekRecord('grant', grantId);

        grantsToDelete.pushObject(grantRecord);
      });
      Ember.set(divisionRole, 'divisions', []); // Make API calls sequentially to avoid api clobbering data

      return grantsToDelete.reduce(function (promise, undeletedGrant) {
        return promise.then(function () {
          return undeletedGrant.destroyRecord();
        });
      }, Ember.RSVP.Promise.resolve()).then(function (data) {
        _this3.get('notification').success(null, _this3.get('intl').lookup('groups.roles.removeSuccess'));

        return data;
      }).catch(function (err) {
        _this3.get('notification').error(null, _this3.get('intl').lookup('groups.roles.removeError'));

        return err;
      });
    },
    _deleteRoles: function _deleteRoles() {
      var _this4 = this;

      Ember.trySet(this, 'isLoadingGroupRoles', true);
      var version = this.get('model.grantVersion'); // Make API calls sequentially to avoid api clobbering data

      return this.get('rolesToDelete').reduce(function (promise, role) {
        return promise.then(function () {
          return _this4._removeRole(role);
        });
      }, Ember.RSVP.Promise.resolve()).then(function () {
        return _this4._getGroupRoles(version);
      }).catch(function (err) {
        Ember.Logger.error('error removing selected roles', {
          error: err
        });
      }).finally(function () {
        _this4.set('rolesToDelete', []);

        _this4.set('displayRoleRemoveModal', false);
      });
    },
    _getGroupRoles: function _getGroupRoles(previousVersion) {
      var _this5 = this;

      var attempt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

      if (attempt >= 10) {
        return Ember.RSVP.reject('timed out waiting for group roles to update');
      }

      Ember.trySet(this, 'isLoadingGroupRoles', true);
      var group = this.get('model');

      if (!group) {
        Ember.Logger.error('no group for roles edit component. (expected if test)');
        return;
      }

      group.getGrants().then(function (grants) {
        //TODO: Bulk Update Does not Increment Version Number https://inindca.atlassian.net/browse/PLAT-1953
        // if (previousVersion && this.get('model.grantVersion') <= previousVersion) {
        //     return new Ember.RSVP.Promise((resolve) => {
        //         Ember.run.later(() => {
        //             resolve(this._getGroupRoles(previousVersion, ++attempt));
        //         }, 500);
        //     });
        // }
        var assignedRolesMap = {};

        _this5.get('store').unloadAll('grant');

        grants.forEach(function (grant) {
          grant.subjectId = _this5.get('model.guid'); // push grants, roles and divisions to the store

          _this5.get('store').push(_this5.get('store').normalize('grant', grant));

          var role = _this5.get('store').push(_this5.get('store').normalize('role', grant.role));

          var division = _this5.get('store').push(_this5.get('store').normalize('division', grant.division));

          if (!assignedRolesMap[grant.role.id]) {
            assignedRolesMap[grant.role.id] = {
              id: grant.role.id,
              role: role,
              divisions: [division]
            };
          } else {
            Ember.get(assignedRolesMap[grant.role.id], 'divisions').pushObject(division);
          }
        });

        _this5.get('groupAssignedRoles').setMap(assignedRolesMap);

        _this5.set('groupAssignedRolesFiltered', _this5.get('groupAssignedRoles.list'));
      }).catch(function (err) {
        Ember.Logger.error('failed to load grants', {
          error: err
        });
      }).finally(function () {
        _this5.isDestroyed || Ember.trySet(_this5, 'isLoadingGroupRoles', false); // workaround trySet until we get this fix https://github.com/emberjs/ember.js/pull/13355
      });
    },
    _isTooManyGrantsError: function _isTooManyGrantsError(err) {
      return err && err.errors && err.errors.length && err.errors[0].message && err.errors[0].message.includes('Too many grants of role');
    },
    _toggleRolesEnabled: function _toggleRolesEnabled() {
      var turnOnRoles = !this.get('model.allowPermissionGroup');

      if (turnOnRoles) {
        this._toggleRolesOn();
      } else {
        this._toggleRolesOff();
      }
    },
    _toggleRolesOn: function _toggleRolesOn() {
      if (this.get('hasRules')) {
        this.set('displayRulesRolesWarningModel', true);
      } else {
        this._enableRoles();
      }
    },
    _toggleRolesOff: function _toggleRolesOff() {
      if (this.get('groupAssignedRoles.list.length')) {
        this.set('rolesToDelete', this.get('groupAssignedRoles.list'));
        this.set('displayHasRolesWarningModel', true);
      } else {
        this._disableRoles();
      }
    },
    _enableRoles: function _enableRoles() {
      var _this6 = this;

      var hadRules = false;
      this.set('isLoadingGroupRoles', true);

      if (this.get('hasRules')) {
        hadRules = true;
        this.set('model.membership.exclusions', []);
        this.set('model.membership.inclusions', []);
      }

      this.set('model.allowPermissionGroup', true);
      return this.get('model').save().then(function (res) {
        var job = Ember.get(res, 'meta.job');

        if (hadRules) {
          var message = _this6.get('intl').lookup('notification.group.membership').toString();

          _this6.get('notification').success(null, message, {
            forceBrowserNotification: true
          });
        }

        if (job && job.jobId) {
          _this6.addJob(job, function () {
            _this6.get('model').reload();
          }, _this6);
        }

        _this6.set('isLoadingGroupRoles', false);
      });
    },
    _disableRoles: function _disableRoles() {
      var _this7 = this;

      this.set('isLoadingGroupRoles', true);
      this.set('rolesToDelete', this.get('groupAssignedRoles.list'));
      return this._deleteRoles().then(function () {
        _this7.set('model.allowPermissionGroup', false);

        return _this7.get('model').save();
      }).then(function () {
        _this7.set('isLoadingGroupRoles', false);
      });
    },
    init: function init() {
      var _this8 = this;

      this.get('groupAssignedRoles').clear();
      this.get('store').findAll('division'); //load divisions into the store for use in the fixtable table

      this.get('unsavedGrants').parent = this; // pass parent reference into unsavedGrants object to provide access to injected properties

      this.get('unsavedGrants').hasUnsavedGrants = Ember.computed('_hasUnsavedGrants', function () {
        return this.get('_hasUnsavedGrants');
      });
      this.get('unsavedGrants').clear(); //in case the component didn't get the chance to clean itself up before it was destroyed

      this._getGroupRoles(); //Load All Roles


      this.set('isLoadingAllRoles', true);
      this.get('store').findAll('role').then(function (roles) {
        if (_this8.isDestroyed || _this8.isDestroying) {
          return;
        }

        _this8.set('allRoles', roles);

        _this8.set('allRolesFiltered', roles);

        _this8.set('isLoadingAllRoles', false);
      });

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});