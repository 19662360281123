define("web-directory/initializers/extend-ember-computed", ["exports", "rate-limit-computed/throttled", "rate-limit-computed/debounced"], function (_exports, _throttled, _debounced) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'extend-ember-computed',
    initialize: function initialize() {
      Ember.computed.throttle = _throttled.default;
      Ember.computed.debounce = _debounced.default;
    }
  };
  _exports.default = _default;
});