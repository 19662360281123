define("web-directory/components/floating-calls/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var on = Ember.on,
      inject = Ember.inject,
      computed = Ember.computed,
      observer = Ember.observer,
      Component = Ember.Component;

  var _default = Component.extend({
    classNameBindings: [':floating-calls', 'expanded', 'isMouseDown:dragging:', 'phone.showFloatingCalls::hide'],
    phone: inject.service('phone'),
    sidebar: inject.service('sidebar'),
    expanded: false,
    minimized: false,
    maxLeft: 0,
    maxTop: 0,
    actions: {
      minimize: function minimize() {
        this.toggleProperty('minimized');
        this.setBounds();
      },
      close: function close() {
        this.$().hide();
      },
      popback: function popback() {
        this.get('sidebar').togglePanel('callControls');
      },
      toggleExpanded: function toggleExpanded() {
        this.toggleProperty('expanded');
      },
      changeActiveCall: function changeActiveCall(conversation) {
        this.set('phone.activeCall', conversation);
      }
    },
    setInitialPosition: on('didInsertElement', function () {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', function () {
        return _this.setBounds(true);
      });
    }),
    setBounds: function setBounds(useStored) {
      var _this2 = this;

      var $app = Ember.$('.application-scroll');
      var $self = this.$();
      var top;
      var left;

      if (useStored) {
        var bounds = this.get('phone.floatingBounds');

        if (bounds) {
          top = bounds.top;
          left = bounds.left;
        }
      }

      if (!top) {
        top = $self.position().top;
      }

      if (!left) {
        left = $self.position().left;
      }

      var width = '225px';
      this.maxLeft = Ember.$(document).width() - Ember.$('.navigation-action-bar').width() - 180;
      this.maxTop = $app.height() - 50;

      if (this.get('minimized')) {
        this.maxLeft = Ember.$(document).width() - Ember.$('.navigation-action-bar').width() - 50;
        this.maxTop = $app.height() - 5;
        width = '90px';
      }

      $self.draggable({
        handle: '.top-bar,.move-bar .btn',
        containment: [75, 80, this.maxLeft, this.maxTop],
        cancel: false,
        iframeFix: true,
        stop: function stop(evt) {
          _this2.get('phone').set('floatingBounds', {
            top: evt.target.offsetTop,
            left: evt.target.offsetLeft
          });
        }
      });

      if (left > this.maxLeft) {
        left = this.maxLeft;
      }

      if (top > this.maxTop) {
        top = this.maxTop;
      }

      if (!left) {
        left = 85;
      }

      if (!top) {
        top = 70;
      }

      $self.css({
        left: left,
        top: top,
        width: width
      });
      this.get('phone').set('floatingBounds', {
        left: left,
        top: top
      });
    },
    attachWindowResize: on('didInsertElement', function () {
      var _this3 = this;

      Ember.$(window).on("resize.".concat(this.get('elementId')), _lodash.default.debounce(function () {
        Ember.run(function () {
          return _this3.setBounds();
        });
      }, 200));
    }),
    removeEvents: on('willDestroyElement', function () {
      Ember.$(window).off(".".concat(this.get('elementId')));
    }),
    activeConversation: computed.reads('phone.activeCall'),
    incomingConversations: computed('phone.incomingConversations.[]', function () {
      return this.get('phone.incomingConversations');
    }),
    conversations: computed('activeConversation', 'phone.activeConversations', function () {
      var active = this.get('activeConversation');

      if (!active) {
        return [];
      }

      var activeId = active.get('id');
      var conversations = this.get('phone.activeConversations');
      return conversations.filter(function (conversation) {
        return conversation.get('id') !== activeId;
      });
    }),
    conversationsCount: computed.reads('phone.activeConversations.length'),
    showMore: computed('phone.activeConversations.[]', function () {
      return 1 < this.get('phone.activeConversations.length');
    }),
    moreCount: computed('expanded', 'phone.activeConversations.[]', function () {
      return this.get('phone.activeConversations.length') - 1;
    }),
    resetExpanded: observer('conversationsCount', function () {
      if (this.get('conversationsCount') === 1) {
        this.set('expanded', false);
      }
    }),
    resetBoundsOnSidebarChange: observer('phone.showFloatingCalls', function () {
      if (this.get('phone.showFloatingCalls')) {
        this.$().show();
        this.setBounds(true);
      }
    })
  });

  _exports.default = _default;
});