define("web-directory/templates/time-machine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6BTc+Ovu",
    "block": "{\"statements\":[[1,[33,[\"time-machine-confirmation\"],null,[[\"buildTag\",\"confirm\",\"cancel\"],[[28,[\"model\",\"buildTag\"]],[33,[\"route-action\"],[\"confirm\"],null],[33,[\"route-action\"],[\"cancel\"],null]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/templates/time-machine.hbs"
    }
  });

  _exports.default = _default;
});