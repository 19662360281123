define("web-directory/components/chat-panel/conversation/conversation-pane/textarea/component", ["exports", "ember-chat-components/components/chat-panel/conversation/conversation-pane/textarea/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _component.default;
    }
  });
});