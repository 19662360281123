define("web-directory/components/application-preferences/theme-picker/component", ["exports", "web-directory/utils/theme-loader"], function (_exports, _themeLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed,
      inject = Ember.inject;

  var _default = Component.extend({
    classNames: ['theme-picker-setting'],
    application: inject.service(),
    session: inject.service(),

    /**
     * Computes the supported themes from the theme loader based on feature toggle states
     * in the session service.
     */
    themes: computed(function () {
      var _this = this;

      var themes = (0, _themeLoader.getThemeList)(this.get('session'));
      return themes.map(function (theme) {
        return {
          name: theme,
          preview: "".concat(_this.get('application.urlprefix'), "assets/images/themepreviews/").concat(theme, ".png"),
          label: "preferences.theme.".concat(theme),
          active: (0, _themeLoader.getThemeClass)() == theme
        };
      });
    }),
    actions: {
      /**
       * Action called by the theme button in the template to set the applications theme as well as the
       * users theme preference through a persisting cookie.
       * @param {{name: string}} theme object containing the name of the theme to set the app to
       */
      setTheme: function setTheme(theme) {
        (0, _themeLoader.setThemeCookie)({
          theme: theme.name
        });
        this.notifyPropertyChange('themes');
      }
    }
  });

  _exports.default = _default;
});