define("web-directory/utils/markdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initializeMarkdownRendering = initializeMarkdownRendering;
  _exports.markdownToHtml = markdownToHtml;
  var hljs = window.hljs;
  hljs.configure({
    languages: ['bash', 'clojure', 'coffeescript', 'cpp', 'cs', 'css', 'elm', 'go', 'xml', 'handlebars', 'http', 'ini', 'java', 'javascript', 'json', 'makefile', 'objectivec', 'python', 'ruby', 'rust', 'sql', 'swift', 'typescript']
  });
  var markdown = window.markdownit;
  var md = markdown({
    html: false,
    linkify: true,
    typographer: false,
    highlight: function highlight(code, lang) {
      if (lang === 'no-highlight') {
        return Ember.Handlebars.Utils.escapeExpression(code);
      } else if (lang) {
        try {
          return hljs.highlight(lang, code).value;
        } catch (e) {
          return Ember.Handlebars.Utils.escapeExpression(code);
        }
      } else {
        return hljs.highlightAuto(code).value;
      }
    }
  });

  function isMailTo(href) {
    return href.indexOf('mailto:') === 0;
  }

  function initializeMarkdownRendering(isApplicationRoute) {
    var defaultRender = md.renderer.rules.link_open || function (tokens, idx, options, env, self) {
      return self.renderToken(tokens, idx, options);
    };

    md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
      var token = tokens[idx];
      var targetIndex = token.attrIndex('href');

      if (targetIndex !== -1) {
        var href = token.attrGet('href');

        if (!isApplicationRoute(href) && !isMailTo(href)) {
          token.attrPush(['target', '_blank']);
          token.attrPush(['rel', 'noopener noreferrer']);
          var pattern = /:\/\//i;

          if (!pattern.test(href)) {
            token.attrSet('href', "http://".concat(href));
          }
        }
      } // pass token to default renderer.


      return defaultRender(tokens, idx, options, env, self);
    };
  }

  function markdownToHtml(markdown) {
    return markdown ? md.render(markdown) : '';
  }
});