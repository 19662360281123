define("web-directory/components/acd-interactions-panel/interaction-type-icon/component", ["exports", "web-directory/utils/supported-messaging-subtypes"], function (_exports, _supportedMessagingSubtypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed;

  var _default = Component.extend({
    classNames: ['interaction-icon'],
    classNameBindings: ['iconName'],
    tagName: 'span',
    type: null,
    subtype: null,
    isDisconnected: null,
    isCampaign: null,
    accessibleTextKey: computed('type', 'subtype', 'isCampaign', function () {
      var type = this.get('type');
      var subtype = this.get('subtype');

      if ('callback' == type && this.get('isCampaign')) {
        type = 'preview';
      }

      var key = "purecloud.acdInteraction.interactionTypes.".concat(type);

      if ('message' === type) {
        if (_supportedMessagingSubtypes.MESSAGING_SUBTYPES.includes(subtype)) {
          key += "Types.".concat(subtype);
        } else {
          key += "Types.unknown";
        }
      }

      return key;
    }),
    iconName: computed('type', 'isDisconnected', 'subtype', 'isCampaign', function () {
      var name;
      var type = this.get('subtype') || this.get('type');
      var isConnected = !this.get('isDisconnected');

      if ('callback' == type && this.get('isCampaign')) {
        type = 'preview';
      }

      switch (type) {
        case 'chat':
          name = isConnected ? 'roster-chat' : 'roster-chat-disable';
          break;

        case 'callback':
          name = 'phone-callback';
          break;

        case 'preview':
          name = isConnected ? 'phone-outbound' : 'roster-phone-disable';
          break;

        case 'call':
          name = isConnected ? 'phone' : 'legacy/phone-disable';
          break;

        case 'email':
          name = isConnected ? 'roster-email' : 'roster-email-disable';
          break;

        case 'sms':
          name = isConnected ? 'legacy/roster-sms' : 'legacy/roster-sms-disable';
          break;

        case 'line':
          name = isConnected ? 'roster-line' : 'roster-line-disable';
          break;

        case 'facebook':
          name = isConnected ? 'roster-messenger' : 'roster-messenger-disable';
          break;

        case 'instagram':
          name = isConnected ? 'roster-instagram' : 'roster-instagram-disable';
          break;

        case 'twitter':
          name = isConnected ? 'roster-twitter' : 'roster-twitter-disable';
          break;

        case 'wechat':
          name = isConnected ? 'roster-wechat' : 'roster-wechat-disable';
          break;

        case 'whatsapp':
          name = isConnected ? 'roster-whatsapp' : 'roster-whatsapp-disable';
          break;

        case 'webmessaging':
          name = isConnected ? 'chat' : 'chat-disable';
          break;

        case 'workitem':
          name = isConnected ? 'fa/clipboard-list-regular' : 'custom/clipboard-list-slash-regular';
          break;

        default:
          // legacy fallback
          name = isConnected ? 'comment-ellipsis' : 'comment-ellipsis-off';
          break;
      }

      return name;
    })
  });

  _exports.default = _default;
});