define("web-directory/initializers/application-service", ["exports", "web-directory/services/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'setupApplicationService',
    after: 'orgspan',
    initialize: function initialize(application) {
      application.register('service:application', _application.default);
      application.inject('controller', 'applicationService', 'service:application');
      application.inject('route', 'applicationService', 'service:application');
      application.inject('component', 'applicationService', 'service:application');
      application.inject('model', 'applicationService', 'service:application');
    }
  };
  _exports.default = _default;
});