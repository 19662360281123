define("web-directory/helpers/title-for-entity-property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var titleForEntityPropertyHelper = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params) {
      var entity = params[0];
      var entityType = params[1];
      var entityPropertyType = params[2];

      if (entityType === 'phone') {
        return entity.getPhoneTitleFor(entityPropertyType);
      }

      if (entityType === 'email') {
        return entity.getEmailTitleFor(entityPropertyType);
      }

      return '';
    }
  });
  var _default = titleForEntityPropertyHelper;
  _exports.default = _default;
});