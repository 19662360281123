define("web-directory/initializers/webrtc-settings-service", ["exports", "web-directory/services/webrtc-settings"], function (_exports, _webrtcSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'setupWebrtcSettingsService',
    before: 'setupApplicationService',
    initialize: function initialize(application) {
      application.register('service:webrtc-settings', _webrtcSettings.default);
      application.inject('controller', 'webrtc-settings', 'service:webrtc-settings');
      application.inject('route', 'webrtc-settings', 'service:webrtc-settings');
      application.inject('component', 'webrtc-settings', 'service:webrtc-settings');
    }
  };
  _exports.default = _default;
});