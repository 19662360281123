define("web-directory/search/store-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      Mixin = Ember.Mixin;

  var _default = Mixin.create({
    searchService: inject.service('search'),
    search: function search(query) {
      var _this = this;

      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var adapter = this.adapterFor('search');
      var promise;

      if (query && !params.query) {
        // This is a simple keyword search, no special queries
        promise = adapter.performSimpleKeywordSearch(query, params);
      } else {
        promise = adapter.performSearch(params);
      }

      promise = Ember.RSVP.resolve(promise, 'Adapter#performSearch');
      return promise.then(function (payload) {
        var aggregations = payload.aggregations;

        var data = _this._extractData(payload, 'search');

        return {
          aggregations: aggregations,
          data: data,
          payload: payload
        };
      }, null, 'Store#search').then(function (_ref) {
        var aggregations = _ref.aggregations,
            data = _ref.data,
            payload = _ref.payload;
        Ember.set(data, '_aggregations', aggregations);
        Ember.set(data, '_meta', payload);
        return data;
      });
    },
    suggest: function suggest(query) {
      var _this2 = this;

      var adapter = this.adapterFor('search');
      var promise = adapter.suggest(query);
      promise = Ember.RSVP.resolve(promise, 'Adapter#suggest');
      return promise.then(function (payload) {
        return _this2._extractData(payload, 'search');
      }, null, 'Store#suggest');
    },
    _extractData: function _extractData(payload) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'findMany';
      var serializer = this.serializerFor('application');

      if (!payload.results && payload.aggregations) {
        return payload.aggregations;
      }

      if (!payload.results) {
        payload.results = [];
      }

      payload = serializer.normalizeArrayResponse(this, null, payload, null, type);

      if (!payload.data) {
        payload.data = [];
      }

      if (payload.included) {
        payload.data = [].concat(payload.data, payload.included);
        payload.included = [];
      }

      return this.push(payload);
    }
  });

  _exports.default = _default;
});