define("web-directory/services/learning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      Service = Ember.Service;

  var _default = Service.extend({
    application: inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    getLearningAssignments: function getLearningAssignments() {
      var hasLearningAssessmentUI = this.get('session.features')['learning.release.assessment.ui'];
      var typesParam = "";

      if (!hasLearningAssessmentUI) {
        typesParam = "&types=Informational";
      }

      var stateParam = "&states=".concat(encodeURIComponent('InProgress,Assigned,InvalidSchedule'));
      return this.get('ajax').ajaxGet(this.get('application').pcUri("api/v2/learning/assignments/me?pageSize=250".concat(typesParam).concat(stateParam))).then(function (response) {
        return response.entities;
      }).catch(function (reason) {
        Ember.Logger.warn('Failed to retrieve assignments:', {
          reason: reason
        });
        throw reason;
      });
    }
  });

  _exports.default = _default;
});