define("web-directory/mixins/paging", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PagingMixin = Ember.Mixin.create({
    entityType: null,
    currentPage: null,
    cachedTotal: null,
    limit: 10,
    extraParams: Ember.computed(function () {
      return {};
    }),
    fieldList: null,
    actions: {
      nextPage: function nextPage() {
        if (this.get('hasNextPage')) {
          this.incrementProperty('currentPage');
          this.loadPage();
        }
      },
      prevPage: function prevPage() {
        if (this.get('hasPrevPage')) {
          this.decrementProperty('currentPage');
          this.loadPage();
        }
      }
    },
    ensureCorrectProperties: Ember.on('init', function () {
      if (!this.get('entityType')) {
        this.set('entityType', 'person');
      }

      this.set('currentPage', 0);
      this.set('cachedTotal', 0);
    }),
    offset: Ember.computed('limit', 'currentPage', function () {
      return this.get('limit') * this.get('currentPage');
    }),
    currentPageDisplay: Ember.computed('currentPage', function () {
      return this.get('currentPage') + 1;
    }),
    totalPages: Ember.computed('currentPage', 'content.[]', 'page', 'cachedTotal', function () {
      var cachedTotal = this.get('cachedTotal') || 0;
      return Math.ceil(cachedTotal / this.get('limit'));
    }),
    hasNextPage: Ember.computed('currentPageDisplay', 'totalPages', function () {
      return this.get('currentPageDisplay') < this.get('totalPages');
    }),
    hasPrevPage: Ember.computed('currentPage', function () {
      return this.get('currentPage') !== 0;
    }),
    haveContent: Ember.computed('content.[]', 'currentPage', function () {
      var content = this.get('content');

      if (content) {
        return content.get('length') > 0;
      }

      return false;
    }),
    loadPage: function loadPage() {
      var _this = this;

      var directory = true;
      var entityType = this.get('entityType');
      var extraParams = this.get('extraParams');
      var letterUpdate = {};

      if (entityType === 'group') {
        letterUpdate = {
          letter: null
        };
      }

      var params = Object.assign({}, {
        limit: this.get('limit'),
        offset: this.get('offset'),
        directory: directory
      }, extraParams, letterUpdate);

      if (this.get('fieldList')) {
        params = Object.assign({}, params, {
          fl: this.get('fieldList')
        });
      }

      var page = this.store.query(entityType, params);
      page.then(function (data) {
        var content = data;

        if (data.content) {
          content = data.content;
        }

        content = content.map(function (x) {
          return x.getRecord();
        });

        _this.set('content', content);

        var cachedTotal = Ember.get(data, 'meta.count');

        if (cachedTotal > 0) {
          _this.set('cachedTotal', cachedTotal);
        }

        return content;
      });
      this.set('page', page);
      return page;
    }
  });
  var _default = PagingMixin;
  _exports.default = _default;
});