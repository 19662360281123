define("web-directory/components/menu-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HZDlPQFx",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"menuItem\",\"route\"]]],null,{\"statements\":[[6,[\"if\"],[[28,[\"menuItem\",\"route\",\"segment\"]]],null,{\"statements\":[[6,[\"link-to\"],[[28,[\"menuItem\",\"route\",\"name\"]],[28,[\"menuItem\",\"route\",\"segment\"]]],null,{\"statements\":[[0,\"            \"],[11,\"span\",[]],[16,\"class\",[34,[[26,[\"labelIdentifierClass\"]]]]],[13],[1,[28,[\"menuItem\",\"label\"]],false],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},{\"statements\":[[6,[\"link-to\"],[[28,[\"menuItem\",\"route\"]]],null,{\"statements\":[[0,\"            \"],[11,\"span\",[]],[16,\"class\",[34,[[26,[\"labelIdentifierClass\"]]]]],[13],[1,[28,[\"menuItem\",\"label\"]],false],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]}]],\"locals\":[]},{\"statements\":[[6,[\"if\"],[[28,[\"menuItem\",\"url\"]]],null,{\"statements\":[[0,\"    \"],[11,\"a\",[]],[16,\"href\",[34,[[28,[\"menuItem\",\"url\"]]]]],[16,\"target\",[34,[[33,[\"if\"],[[28,[\"menuItem\",\"target\"]],[28,[\"menuItem\",\"target\"]],\"_blank\"],null]]]],[13],[0,\"\\n        \"],[11,\"span\",[]],[16,\"class\",[34,[[26,[\"labelIdentifierClass\"]]]]],[13],[1,[28,[\"menuItem\",\"label\"]],false],[14],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"    \"],[11,\"a\",[]],[15,\"href\",\"#\"],[5,[\"action\"],[[28,[null]],\"customClick\",[28,[\"menuItem\"]]]],[13],[0,\"\\n        \"],[11,\"span\",[]],[16,\"class\",[34,[[26,[\"labelIdentifierClass\"]]]]],[13],[1,[28,[\"menuItem\",\"label\"]],false],[14],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[]}]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/menu-item/template.hbs"
    }
  });

  _exports.default = _default;
});