define("web-directory/components/group-profile/dynamic-rules/condition-display/skill-search/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      Component = Ember.Component,
      inject = Ember.inject;

  var _default = Component.extend({
    classNames: ['skill-search'],
    application: inject.service(),
    ajax: inject.service(),
    intl: inject.service(),
    logger: inject.service('stash-logger'),
    skillResults: null,
    languageResults: null,
    showSearchResults: false,
    searchvalue: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('skillResults', []);
      this.set('languageResults', []);
    },
    results: computed('skillResults', 'languageResults', function () {
      return this.get('skillResults').concat(this.get('languageResults'));
    }),
    placeHolder: computed('skill', function () {
      return this.get('skill') ? this.get('skill.name') : this.get('intl').t('groups.expressions.defaultSkill');
    }),
    skillSelected: computed('skill', function () {
      return !!this.get('skill');
    }),
    actions: {
      select: function select(result) {
        this.set('skill', result);
        this.reset();
      },
      clearSearch: function clearSearch() {
        this.reset();
      },
      searchInputUpdated: function searchInputUpdated(event) {
        this.set('searchValue', event.target.value);
        this.updateSearchOnChange(event.target.value);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.attachOutsideHandler();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.removeOutsideHandler();
    },
    attachOutsideHandler: function attachOutsideHandler() {
      var _this = this;

      Ember.$(window).on("click.".concat(this.get('elementId')), function (event) {
        Ember.run(function () {
          if (_this.get('dismissed')) {
            return;
          }

          var target = Ember.$(event.target);

          if (!target.parents('.skill-search').length) {
            _this.reset();
          }
        });
      });
    },
    removeOutsideHandler: function removeOutsideHandler() {
      Ember.$(window).off(".".concat(this.get('elementId')));
    },
    reset: function reset() {
      this.set('showSearchResults', false);
      this.set('searchValue', null);
      this.set('languageResults', []);
      this.set('skillResults', []);
    },
    updateSearchOnChange: function updateSearchOnChange(query) {
      if (!_lodash.default.isEmpty(query)) {
        Ember.run.debounce(this, this.runSearch, query.toLowerCase(), 250);
      } else {
        this.reset();
      }
    },
    runSearch: function runSearch(query) {
      this.set('showSearchResults', true);
      this.languageSearch(query);
      this.skillSearch(query);
    },
    skillSearch: function skillSearch(query) {
      var _this2 = this;

      var url = this.get('application').pcV2Uri("api/v2/routing/skills");
      this.get('ajax').ajaxGet(url, {
        data: {
          name: query
        },
        retry: true
      }).then(function (res) {
        var skills = res.entities;
        skills.forEach(function (skl) {
          skl.type = 'routing';
          skl.class = skl.name.toLowerCase().replaceAll(' ', '_');
        });

        _this2.set('skillResults', skills);
      }).catch(function (error) {
        // uhhhh
        _this2.get('logger').error('Error trying to fetch routing skills', {
          error: error
        });

        _this2.set('skillResults', []);
      });
    },
    languageSearch: function languageSearch(query) {
      var _this3 = this;

      var url = this.get('application').pcV2Uri("api/v2/routing/languages");
      this.get('ajax').ajaxGet(url, {
        data: {
          name: query
        },
        retry: true
      }).then(function (res) {
        var langs = res.entities;
        langs.forEach(function (lang) {
          lang.type = 'language';
          lang.class = lang.name.toLowerCase().replaceAll(' ', '_');
        });

        _this3.set('languageResults', langs);
      }).catch(function (error) {
        // uhhhh
        _this3.get('logger').error('Error trying to fetch routing languages', {
          error: error
        });

        _this3.set('languageResults', []);
      });
    }
  });

  _exports.default = _default;
});