define("web-directory/components/admin-ui-modal/component", ["exports", "ember-purecloud-components/mixins/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var on = Ember.on,
      inject = Ember.inject,
      Component = Ember.Component;

  var _default = Component.extend(_logger.default, {
    classNames: ['admin-ui-modal'],
    application: inject.service(),
    adminui: inject.service(),
    session: inject.service(),
    src: '',
    name: 'admin-ui-modal',
    sendClose: function sendClose() {
      this.sendAction('closeAdminModal');
    },
    handleMessageEvent: function handleMessageEvent(event) {
      var _this = this;

      Ember.run(function () {
        if (!event) {
          return;
        }

        try {
          var sameHost = Ember.get(event, 'originalEvent.source.document.location.host') === location.host;

          var sameName = Ember.get(event, 'originalEvent.source.name') === _this.get('name');

          if (!(sameHost & sameName)) {
            return;
          }

          var message = Ember.get(event, 'originalEvent.data');

          if (message.type === 'modal:close') {
            _this.sendClose();
          } else if (message.type === 'content:route:changed') {
            if (!message.data.hash.match(/^\/directory\/people\/(add|import|link)/)) {
              var hash = "#/admin".concat(message.data.hash);
              location.hash = hash;
            }
          } else if (message.type === 'toast') {
            _this.get('adminui').toast(message.data);
          }
        } catch (error) {
          if (error && error.name === 'SecurityError') {
            return;
          }

          _this.logger.error('error handling frame message', {
            error: error
          });
        }
      });
    },
    startMessageListener: on('didInsertElement', function () {
      $(window).on("message.".concat(this.elementId), this.handleMessageEvent.bind(this));
    }),
    stopMessageListener: on('willDestroyElement', function () {
      $(window).off("message.".concat(this.elementId));
    })
  });

  _exports.default = _default;
});