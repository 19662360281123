define("web-directory/components/acd-interactions-panel/interaction-details/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Known IVR attributes
  var IVR_EMAIL_KEY = 'ivr.IVRCustomerEmailAddress1';
  var IVR_EMAIL_TYPE_KEY = 'ivr.IVRCustomerEmailAddress1Type';
  var IVR_PHONE_KEY = 'ivr.IVRCustomerPhone1';
  var IVR_PHONE_TYPE_KEY = 'ivr.IVRCustomerPhone1Type';
  var IVR_ADDRESS_STREET_KEY = 'ivr.IVRCustomerAddress1Street';
  var IVR_ADDRESS_CITY_KEY = 'ivr.IVRCustomerAddress1City';
  var IVR_ADDRESS_STATE_KEY = 'ivr.IVRCustomerAddress1State';
  var IVR_ADDRESS_POSTAL_KEY = 'ivr.IVRCustomerAddress1PostalCode';
  var IVR_CUSTOMER_ID_KEY = 'ivr.IVRCustomerId'; // Known Context Attributes

  var CONTEXT_STREET_ADDRESS = 'context.addressStreet';
  var CONTEXT_CITY = 'context.addressCity';
  var CONTEXT_POSTAL = 'context.addressPostalCode';
  var CONTEXT_STATE = 'context.addressState';
  var CONTEXT_PHONE = 'context.phoneNumber';
  var CONTEXT_PHONE_TYPE = 'context.phoneType';
  var CONTEXT_CUSTOMER_ID = 'context.customerId';
  var CONTEXT_EMAIL = 'context.email'; // Custom Context Attribtues

  var CUSTOM_FIELD_1 = 'context.customField1';
  var CUSTOM_FIELD_1_LABEL = 'context.customField1Label';
  var CUSTOM_FIELD_2 = 'context.customField2';
  var CUSTOM_FIELD_2_LABEL = 'context.customField2Label';
  var CUSTOM_FIELD_3 = 'context.customField3';
  var CUSTOM_FIELD_3_LABEL = 'context.customField3Label';

  var _default = Ember.Component.extend({
    classNames: ['sub-panel', 'interaction-chat-details'],
    classNameBindings: ['enabled', 'idClassName'],
    attributeBindings: ['role:role', 'tabindex:tabindex'],
    role: 'tabpanel',
    idClassName: 'tabpanel-details',
    tabindex: '-1',
    interaction: null,
    enabled: false,
    queueParticipant: Ember.computed.alias('interaction.participantForQueue'),
    customerParticipant: Ember.computed.alias('interaction.participantForCustomer'),
    customerStreetAddress: Ember.computed('queueParticipant.attributes', 'customerParticipant.attributes', function () {
      var queue = this.get('queueParticipant.attributes');
      var cust = this.get('customerParticipant.attributes');
      var qpAddress, cpAddress;

      if (queue) {
        qpAddress = queue[IVR_ADDRESS_STREET_KEY];
      }

      if (cust) {
        cpAddress = cust[CONTEXT_STREET_ADDRESS];
      }

      if (cpAddress) {
        return cpAddress;
      } else if (qpAddress) {
        return qpAddress;
      }

      return null;
    }),
    customerCityStateZip: Ember.computed('queueParticipant.attributes', 'customerParticipant.attributes', function () {
      var queue = this.get('queueParticipant.attributes');
      var cust = this.get('customerParticipant.attributes');
      var qpCity, qpState, qpPostal;
      var cpCity, cpState, cpPostal;

      if (queue) {
        qpCity = queue[IVR_ADDRESS_CITY_KEY];
        qpState = queue[IVR_ADDRESS_STATE_KEY];
        qpPostal = queue[IVR_ADDRESS_POSTAL_KEY];
      }

      if (cust) {
        cpCity = cust[CONTEXT_CITY];
        cpState = cust[CONTEXT_STATE];
        cpPostal = cust[CONTEXT_POSTAL];
      }

      var qpCityStateZip = "".concat(qpCity, ", ").concat(qpState, " ").concat(qpPostal);
      var cpCityStateZip = "".concat(cpCity, ", ").concat(cpState, " ").concat(cpPostal);

      if (cpCity) {
        return cpCityStateZip;
      } else if (qpCity) {
        return qpCityStateZip;
      }

      return null;
    }),
    customerPhoneNumber: Ember.computed('queueParticipant.attributes', 'customerParticipant.attributes', function () {
      var queue = this.get('queueParticipant.attributes');
      var cust = this.get('customerParticipant.attributes');
      var qpNumber, cpNumber;

      if (queue) {
        qpNumber = queue[IVR_PHONE_KEY];
      }

      if (cust) {
        cpNumber = cust[CONTEXT_PHONE];
      }

      if (cpNumber) {
        return cpNumber;
      } else if (qpNumber) {
        return qpNumber;
      }

      return null;
    }),
    customerPhoneNumberType: Ember.computed('queueParticipant.attributes', 'customerParticipant.attributes', function () {
      var queue = this.get('queueParticipant.attributes');
      var cust = this.get('customerParticipant.attributes');
      var qpNumberType, cpNumberType;

      if (queue) {
        qpNumberType = queue[IVR_PHONE_TYPE_KEY];
      }

      if (cust) {
        cpNumberType = cust[CONTEXT_PHONE_TYPE];
      }

      if (cpNumberType) {
        return cpNumberType;
      } else if (qpNumberType) {
        return qpNumberType;
      }

      return null;
    }),
    customerEmail: Ember.computed('queueParticipant.attributes', function () {
      var queue = this.get('queueParticipant.attributes');
      var cust = this.get('customerParticipant.attributes');
      var qpEmail, cpEmail;

      if (queue) {
        qpEmail = queue[IVR_EMAIL_KEY];
      }

      if (cust) {
        cpEmail = cust[CONTEXT_EMAIL];
      }

      if (cpEmail) {
        return cpEmail;
      } else if (qpEmail) {
        return qpEmail;
      }

      return null;
    }),
    customerEmailType: Ember.computed('queueParticipant.attributes', function () {
      var queue = this.get('queueParticipant.attributes');

      if (queue) {
        var qpEmailType = queue[IVR_EMAIL_TYPE_KEY];

        if (qpEmailType) {
          return qpEmailType;
        }
      }

      return null;
    }),
    customerId: Ember.computed('queueParticipant.attributes', 'customerParticipant.attributes', function () {
      var queue = this.get('queueParticipant.attributes');
      var cust = this.get('customerParticipant.attributes');
      var qpCustomerID, cpCustomerID;

      if (queue) {
        qpCustomerID = queue[IVR_CUSTOMER_ID_KEY];
      }

      if (cust) {
        cpCustomerID = cust[CONTEXT_CUSTOMER_ID];
      }

      if (cpCustomerID) {
        return cpCustomerID;
      } else if (qpCustomerID) {
        return qpCustomerID;
      }

      return null;
    }),
    customField1: Ember.computed('customerParticipant.attributes', function () {
      var cust = this.get('customerParticipant.attributes');
      var customLabel, customValue;

      if (cust) {
        customLabel = cust[CUSTOM_FIELD_1_LABEL];
        customValue = cust[CUSTOM_FIELD_1];
      }

      if (customValue || customLabel) {
        return {
          label: customLabel,
          value: customValue
        };
      }

      return null;
    }),
    customField2: Ember.computed('customerParticipant.attributes', function () {
      var cust = this.get('customerParticipant.attributes');
      var customLabel, customValue;

      if (cust) {
        customLabel = cust[CUSTOM_FIELD_2_LABEL];
        customValue = cust[CUSTOM_FIELD_2];
      }

      if (customValue || customLabel) {
        return {
          label: customLabel,
          value: customValue
        };
      }

      return null;
    }),
    customField3: Ember.computed('customerParticipant.attributes', function () {
      var cust = this.get('customerParticipant.attributes');
      var customLabel, customValue;

      if (cust) {
        customLabel = cust[CUSTOM_FIELD_3_LABEL];
        customValue = cust[CUSTOM_FIELD_3];
      }

      if (customValue || customLabel) {
        return {
          label: customLabel,
          value: customValue
        };
      }

      return null;
    })
  });

  _exports.default = _default;
});