define("web-directory/components/entity-field-section/add-new/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var on = Ember.on,
      computed = Ember.computed,
      Component = Ember.Component;

  var _default = Component.extend({
    classNames: ['add-new'],
    classNameBindings: ['hide'],
    attrs: {
      section: null,
      fieldValues: [],
      disableDropdown: false,
      toggleAddValue: null,
      addNewEntry: function addNewEntry() {}
    },
    fieldValues: [],
    showDropdown: false,
    actions: {
      addNewEntry: function addNewEntry(value) {
        this.attrs.addNewEntry(value);
        this.set('showDropdown', false);
      },
      toggleAddValue: function toggleAddValue() {
        if (this.get('disableDropdown')) {
          this.attrs.toggleAddValue();
        } else {
          this.toggleProperty('showDropdown');
        }
      }
    },
    hide: computed('fieldValues.[]', function () {
      return _lodash.default.isEmpty(this.get('fieldValues'));
    }),
    attachClickListener: on('didInsertElement', function () {
      var _this = this;

      Ember.$(document).on("click.".concat(this.get('elementId')), function (event) {
        Ember.run(function () {
          var target = Ember.$(event.target);

          if (!target.parents('.add-new').length) {
            _this.set('showDropdown', false);
          }
        });
      });
    }),
    removeClickListener: on('willDestroyElement', function () {
      Ember.$(document).off(".".concat(this.get('elementId')));
    })
  });

  _exports.default = _default;
});