define("web-directory/components/follow-me-settings-panel/route-entry/component", ["exports", "ember-data", "rate-limit-computed/debounced", "web-directory/utils/keystroke", "web-directory/components/follow-me-settings-panel/forwarding-helpers"], function (_exports, _emberData, _debounced, _keystroke, _forwardingHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var run = Ember.run,
      inject = Ember.inject,
      computed = Ember.computed,
      Component = Ember.Component;
  var PromiseArray = _emberData.default.PromiseArray;

  var _default = Component.extend({
    classNames: ['follow-me-station-input', 'follow-me-route-entry'],
    classNameBindings: ['isLast:last-station-input'],
    store: inject.service(),
    permissions: inject.service(),
    addressformat: inject.service(),
    index: null,
    routeData: null,
    callRoutes: null,
    isExternalVoicemail: false,
    selectNumber: function selectNumber() {},
    selectStation: function selectStation() {},
    addStation: function addStation() {},
    removeStation: function removeStation() {},
    currentSearch: null,
    currentRequest: null,
    currentNumber: null,
    actions: {
      performSearch: function performSearch(event) {
        this.set('currentSearch', event.target.value);
      },
      submit: function submit(event) {
        var value = event.target.value;

        if ((0, _keystroke.eventToString)(event) === 'ENTER' && !!value) {
          this.send('selectNumber', value);
        }
      },
      selectStation: function selectStation(station, isSelectedRoute) {
        if (isSelectedRoute) {
          return;
        }

        this.set('currentSearch', '');
        this.get('selectStation')(station);
      },
      selectNumber: function selectNumber(number) {
        if (!number) {
          return;
        }

        var addressformat = this.get('addressformat'); // Treat numbers in an invalid format as extensions

        if (!addressformat.isValidNumber(number, null, true)) {
          if (number.charAt(0) === '+') {
            number = number.substring(1);
          }

          number = ";ext=".concat(number);
        }

        this.set('currentNumber', number);
        this.get('selectNumber')(number);
      },
      updateVoicemail: function updateVoicemail(event) {
        this.get('updateVoicemail')(event.target.checked);
      },
      removeStation: function removeStation() {
        if (this.get('isLast') && this.get('isOnlyRoute')) {
          return;
        }

        this.get('removeStation')();
      },
      attemptSave: function attemptSave(event) {
        var isSameNumber = this.get('isNumber') && this.get('routeData.number') === event.target.value;

        if (this.get('routeData.new') || isSameNumber) {
          return;
        }

        if (this.get('startsWithNumber')) {
          this.send('selectNumber', event.target.value);
        }
      }
    },
    saved: computed.reads('routeData.saved'),
    saving: computed.reads('routeData.saving'),
    isNumber: computed.reads('routeData.isNumber'),
    isExtension: computed.reads('routeData.isExtension'),
    isStation: computed.reads('routeData.isStation'),
    isRemoteStation: computed.reads('routeData.isRemoteStation'),
    voicemailDisabled: computed.and('routeData.isInternalDID', 'isNumber'),
    stations: (0, _debounced.default)('currentSearch', function () {
      var _this = this;

      var store = this.get('store');
      var search = this.get('currentSearch');

      if (search && search.length > 1) {
        var promise = (0, _forwardingHelpers.searchForStation)(store, search, this.get('session')).then(function (stations) {
          var routes = _this.get('callRoutes') || [];
          var routeIds = routes.filterBy('isStation').mapBy('station.id');
          return stations.reject(function (station) {
            return routeIds.indexOf(station.get('id')) > -1;
          });
        });
        return PromiseArray.create({
          promise: promise
        });
      }

      return PromiseArray.create({
        promise: Ember.RSVP.resolve([])
      });
    }, 250),
    displayValue: computed('routeData.number', 'routeData.station.name', 'isNumber', function () {
      var station = this.get('routeData');

      if (this.get('isNumber')) {
        var number = station.get('number');

        if (number.indexOf(';ext') === 0) {
          return number.split(';ext=')[1];
        }

        return number;
      }

      return station.get('station.name');
    }),
    showDropdown: computed('stations.[]', 'stations.isFulfilled', {
      get: function get() {
        return this.get('stations.length') !== 0 && this.get('stations.isFulfilled');
      },
      set: function set(key, value) {
        return value;
      }
    }),
    startsWithNumber: computed('currentSearch', function () {
      var search = this.get('currentSearch');

      if (search) {
        // super naive checking
        var number = search.charAt(0);

        if (search.charAt(0) === '+') {
          number = search.charAt(1);
        }

        return !isNaN(Number(number));
      }

      return false;
    }),
    isValidNumber: computed('currentNumber', function () {
      var number = this.get('currentNumber');

      if (!number || number.length === 0) {
        return true;
      }

      return this.get('addressformat').isValidNumber(number, null, true);
    }),
    isLast: computed('index', 'callRoutes.[]', 'callRoutes.@each.new', function () {
      var savedRoutes = this.get('callRoutes').reject(function (route) {
        return route.get('new');
      });
      return this.get('routeData') === savedRoutes.get('lastObject');
    }),
    isOnlyRoute: computed('callRoutes.[]', 'callRoutes.@each.new', function () {
      var savedRoutes = this.get('callRoutes').reject(function (route) {
        return route.get('new');
      });
      return this.get('routeData') === savedRoutes.get('lastObject') && savedRoutes.length === 1;
    }),
    showCheckbox: computed('saved', 'isNumber', 'isLast', 'isExtension', function () {
      return this.get('isLast') && this.get('saved') && this.get('isNumber') && !this.get('isExtension');
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.$(window).on("click.".concat(this.elementId), run.bind(this, this.detectOutsideClick));
      this.set('showDropdown', false);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      Ember.$(window).off(".".concat(this.elementId));
    },
    detectOutsideClick: function detectOutsideClick(event) {
      var $target = Ember.$(event.target);

      if ($target.is(this.$('input.station-search-input')) && !this.get('showDropdown')) {
        this.send('performSearch', event);
      } else if ($target.closest(this.elementId).length !== 1 || $target.closest('.dropdown-menu').length !== 1) {
        this.set('currentSearch', '');
        this.set('currentPromise', null);

        if (this.get('isStation')) {
          this.$('input.station-search-input').val(this.get('displayValue'));
        }
      }
    }
  });

  _exports.default = _default;
});