define("web-directory/initializers/authenticators", ["exports", "web-directory/config/environment", "web-directory/utils/net"], function (_exports, _environment, _net) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.deprecateJqueryCookie = deprecateJqueryCookie;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function loadOauthProps() {
    var oauthProps = _environment.default.oauthProps;

    if (oauthProps && _typeof(oauthProps) === 'object' && !Object.prototype.hasOwnProperty.call(oauthProps, 'clientId')) {
      try {
        var _netUtils$getEnvDetai = _net.default.getEnvDetails(),
            runtime = _netUtils$getEnvDetai.runtime; // Authenticator will handle `null` from a UI perspective


        return runtime.name in oauthProps ? oauthProps[runtime.name] : null;
      } catch (e) {
        return null;
      }
    }

    return oauthProps;
  }

  function deprecateJqueryCookie() {
    var jQueryCookieDeprecation = {
      id: 'directory-core.deprecate-jquery-cookie',
      until: '8.0.0',
      url: 'https://bitbucket.org/inindca/web-directory/src/develop/DEPRECATIONS.md#markdown-header-jquery-cookie'
    };

    if (Ember.$.cookie) {
      var cookie = Ember.$.cookie;

      Ember.$.cookie = function _overrideCookie(name, value) {
        var message = 'Please use `service:ember-cookies`.read(name) instead of jQuery.$.cookie';

        if (value) {
          message = 'Please use `service:ember-cookies`.write(name, value, [opts]) instead of jQuery.$.cookie';
        }

        Ember.deprecate(message, false, jQueryCookieDeprecation);
        return cookie.apply(Ember.$, arguments);
      };
    }

    if (Ember.$.removeCookie) {
      var removeCookie = Ember.$.removeCookie;

      Ember.$.removeCookie = function _overrideRemoveCookie() {
        var message = 'Please use `service:ember-cookies`.clear(name) instead of jQuery.$.removeCookie';
        Ember.deprecate(message, false, jQueryCookieDeprecation);
        return removeCookie.apply(Ember.$, arguments);
      };
    }
  }

  var _default = {
    name: 'authenticators',
    before: 'ember-simple-auth',
    after: 'setupSessionService',
    initialize: function initialize(application) {
      deprecateJqueryCookie();
      application.register('authenticator:oauthProps', Ember.Object.extend(loadOauthProps() || {}));
      application.inject('authenticator:directory', 'oauthProps', 'authenticator:oauthProps');
    }
  };
  _exports.default = _default;
});