define("web-directory/search/service", ["exports", "lodash", "web-directory/search/request-builder", "ember-inflector"], function (_exports, _lodash, _requestBuilder, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SEARCH_QUERY_TYPES = _exports.SEARCH_QUERY_OPERATORS = _exports.SEARCH_FACET_KEYPATHS = _exports.SEARCH_AGGREGATE_TYPES = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var computed = Ember.computed,
      inject = Ember.inject,
      RSVP = Ember.RSVP,
      Service = Ember.Service;
  var resourceTypes = ['resource_article', 'resource_page', 'resource_faq', 'resource_glossary', 'resource_releasenote'];
  var SEARCH_FACET_KEYPATHS = {
    profileSkills: 'skills.skills',
    department: 'general.department',
    title: 'general.title',
    hobbies: 'bio.hobbies',
    interests: 'bio.interests',
    certifications: 'skills.certs',
    languages: 'languages.name'
  };
  _exports.SEARCH_FACET_KEYPATHS = SEARCH_FACET_KEYPATHS;
  var SEARCH_QUERY_TYPES = ['EXACT', 'CONTAINS', 'STARTS_WITH', 'REQUIRED_FIELDS', 'RANGE', 'DATE_RANGE', 'LESS_THAN', 'LESS_THAN_EQUAL_TO', 'GREATER_THAN', 'GREATER_THAN_EQUAL_TO', 'SIMPLE', 'TERM', 'TERMS', 'QUERY_STRING', 'MATCH_ALL'];
  _exports.SEARCH_QUERY_TYPES = SEARCH_QUERY_TYPES;
  var SEARCH_QUERY_OPERATORS = ['AND', 'OR', 'NOT'];
  _exports.SEARCH_QUERY_OPERATORS = SEARCH_QUERY_OPERATORS;
  var SEARCH_AGGREGATE_TYPES = ['COUNT', 'SUM', 'AVERAGE', 'CONTAINS', 'STARTS_WITH', 'ENDS_WITH', 'TERM'];
  _exports.SEARCH_AGGREGATE_TYPES = SEARCH_AGGREGATE_TYPES;
  var ALL_FIELDS = 'ALL_FIELDS';

  var _default = Service.extend({
    ajax: inject.service(),
    store: inject.service(),
    application: inject.service(),
    apiEndpoint: computed.reads('_searchUrl'),
    suggestEndpoint: computed.reads('_suggestUrl'),
    _searchUrl: computed(function () {
      return this.get('application').pcV2Uri('api/v2/search');
    }),
    _suggestUrl: computed('_searchUrl', function () {
      return "".concat(this.get('_searchUrl'), "/suggest");
    }),
    _docUrl: computed('_searchUrl', function () {
      return this.get('application').pcV2Uri('api/v2/documentation/search');
    }),
    search: function search(data) {
      var url = this.get('_searchUrl');
      return this.get('ajax').post(url, {
        data: data
      });
    },
    suggest: function suggest(data) {
      var url = this.get('_suggestUrl');
      return this.get('ajax').post(url, {
        data: data
      });
    },
    searchDocumentation: function searchDocumentation(data) {
      var url = this.get('_docUrl');
      return this.get('ajax').post(url, {
        data: data
      });
    },
    modelResults: function modelResults(results) {
      // turn search results into DS models
      var store = this.get('store');
      var entities = results.map(function (result) {
        var type = (0, _emberInflector.singularize)(result._type);

        if (type === 'external_contact') {
          type = 'external-contact';
        }

        if (type === 'user') {
          type = 'person';
        }

        if (resourceTypes.includes(type)) {
          type = 'resource-article';
          var normObj = store.normalize(type, result).data; // id fails to be set on normalized object when
          // running locally.

          normObj.id = result.id;
          return normObj;
        }

        result.id = result.id || result.guid || result._id;
        return store.normalize(type, result).data;
      });
      return store.push({
        data: entities
      });
    },
    performSearch: function performSearch(query, page) {
      var _this$requestBuilder$,
          _this = this;

      var entity = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'all';
      var facets = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
      var fields = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [ALL_FIELDS];
      var type = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 'QUERY_STRING';
      var returnFields = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : [];
      var pageSize = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : 25;

      if (type === 'QUERY_STRING' && (!query || query.length < 2)) {
        return RSVP.resolve([]);
      }

      var store = this.get('store');
      var defaultAggregations = [['department', null, 'department', 'TERM'], ['title', null, 'title', 'TERM'], ['profileSkills', null, 'profileSkills', 'TERM'], ['hobbies', null, 'hobbies', 'TERM'], ['interests', null, 'interests', 'TERM'], ['certifications', null, 'certifications', 'TERM'] // ['languages', null, 'languages', 'TERM']
      ];

      if (entity === null) {
        entity = 'all';
      } // Two requests while working out issues with Ember Data


      var entityRequest = this.requestBuilder().setPaging(page + 1, pageSize).setReturnFields(returnFields);

      var facetsRequest = (_this$requestBuilder$ = this.requestBuilder().setPaging(page + 1, 0)).addAggregations.apply(_this$requestBuilder$, defaultAggregations);

      if (entity !== 'all') {
        if (entity === 'people') {
          entity = 'users';
        }

        if (!Array.isArray(entity)) {
          entity = [entity];
        }

        entityRequest.setTypes(entity);
      }

      if (!_lodash.default.isEmpty(facets)) {
        var selectedTerms = facets.rejectBy('isKeyword');

        _lodash.default.forEach(selectedTerms, function (term) {
          var searchField = term.get('searchField');
          var value = term.get('term.term');
          entityRequest.addQuery(value, 'AND', 'EXACT', [searchField]);
        });

        if (!_lodash.default.isEmpty(selectedTerms)) {
          entityRequest.setTypes(['users']);
        }
      } else if (_lodash.default.isEmpty(facets) && entity === 'users') {
        entityRequest.addAggregations.apply(entityRequest, defaultAggregations);
      }

      if (_lodash.default.isEmpty(query)) {
        entityRequest.addQuery(null, 'OR', 'MATCH_ALL', fields);
        facetsRequest.addQuery(null, 'OR', 'MATCH_ALL');
      } else {
        entityRequest.addQuery(query, 'AND', type, fields);
        facetsRequest.addQuery(query);
      }

      var searchResults = store.search(null, entityRequest.get('data'));
      var aggregateResults = Ember.RSVP.resolve([]);

      if (entity === 'all') {
        aggregateResults = store.search(null, facetsRequest.get('data'));
      }

      var data = Ember.RSVP.all([searchResults, aggregateResults]);
      return data.then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            results = _ref2[0],
            facets = _ref2[1];

        if (!facets.get('_aggregations')) {
          facets = results.get('_aggregations');
        } else {
          facets = facets.get('_aggregations');
        }

        facets = _this.processFacets(facets);
        return {
          results: results,
          facets: facets
        };
      });
    },
    performSuggestSearch: function performSuggestSearch(query) {
      var entity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'all';
      var store = this.get('store');
      var request = this.requestBuilder().addQuery(query).setPaging(1, 4);

      if (entity === 'people') {
        entity = 'users';
      }

      if (entity !== 'all') {
        request.setTypes([entity]);
      }

      return store.suggest(request.get('data'));
    },
    performCustomSearch: function performCustomSearch(requestBuilder) {
      return this.get('store').search(null, requestBuilder.get('data'));
    },
    processFacets: function processFacets(facets) {
      facets = _lodash.default.chain(facets).map(function (terms, key) {
        if (!Object.prototype.hasOwnProperty.call(SEARCH_FACET_KEYPATHS, key)) {
          return null;
        }

        return {
          key: key,
          facetName: SEARCH_FACET_KEYPATHS[key],
          terms: _lodash.default.map(terms, function (term) {
            return {
              term: term.key,
              count: term.count
            };
          })
        };
      }).filter().value();
      var orderKeys = ['department', 'title', 'profileSkills', 'hobbies', 'interests', 'certifications'];
      return _lodash.default.sortBy(facets, function (_ref3) {
        var key = _ref3.key;
        return orderKeys.indexOf(key);
      });
    },
    requestBuilder: function requestBuilder() {
      return _requestBuilder.default.create({
        service: this
      });
    }
  });

  _exports.default = _default;
});