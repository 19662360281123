define("web-directory/components/free-trial-support/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tTftSP4D",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"trial-support-enabled\"],[13],[0,\"\\n    \"],[9,\"frame-router\",{\"attrs\":[[15,\"class\",\"trial-router\"],[15,\"route\",\"trial-support\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[[0,\"\\n        \"],[11,\"button\",[]],[15,\"type\",\"button\"],[16,\"onclick\",[33,[\"action\"],[[28,[null]],\"closeTrialSupport\"],null],null],[13],[0,\"\\n            \"],[9,\"gux-icon\",{\"attrs\":[[15,\"class\",\"trial-support__close\"],[16,\"screenreader-text\",[33,[\"t\"],[\"freeTrial.supportClose\"],null],null],[15,\"icon-name\",\"close\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[[0,\"\\n            \"]]}],[0,\"\\n        \"],[14],[0,\"\\n    \"]]}],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/free-trial-support/template.hbs"
    }
  });

  _exports.default = _default;
});