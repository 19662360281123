define("web-directory/templates/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Tr6efP4N",
    "block": "{\"statements\":[[19,\"partials/signup-reset\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":true}",
    "meta": {
      "moduleName": "web-directory/templates/reset-password.hbs"
    }
  });

  _exports.default = _default;
});