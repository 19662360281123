define("web-directory/helpers/icon-for-activity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var activityIcons = {
    sms: 'ion-iphone',
    voice: 'ion-ios-telephone',
    email: 'ion-email',
    chat: 'ion-chatboxes'
  };

  var _default = Ember.Helper.helper(function (params) {
    var activity = params[0];
    return Ember.String.htmlSafe("<i class='".concat(activityIcons[activity.method], "'></i>"));
  });

  _exports.default = _default;
});