define("web-directory/routes/admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    adminuiService: Ember.inject.service('adminui'),
    sidebar: Ember.inject.service(),
    ajax: Ember.inject.service(),
    application: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      if (transition.isActive === false) {
        // Route canceled for auth
        return;
      }

      var url = transition.intent.url;

      if (url && url.indexOf('/admin/interactions') === 0) {
        this.replaceWith('/analytics/interactions');
      }

      return this.loadFreeTrial();
    },
    // Loads information used for displaying how many days are left in a free trial
    loadFreeTrial: function loadFreeTrial() {
      var _this = this;

      if (this.get('session').get('freeTrial')) {
        return Ember.RSVP.resolve();
      }

      var url = this.get('application').pcV2Uri('api/v2/billing/freetrial');
      return this.get('ajax').request(url, {
        retry: false
      }).then(function (data) {
        return _this.get('session').set('freeTrial', data);
      }).catch(function () {
        _this.get('session').set('freeTrial', {}); // This request is allowed to fail, as we don't want missing
        // free trial info to trigger the maintenance page


        return Ember.RSVP.resolve();
      });
    },
    actions: {
      didTransition: function didTransition() {
        this.get('sidebar').ensureRouteVisibility();
        return true;
      },
      gotoRoute: function gotoRoute(route) {
        this.transitionTo(route);
      }
    },
    afterModel: function afterModel(model) {
      if (model) {
        this.set('adminuiService.adminPath', model.adminPath);
        this.set('adminuiService.currentRouteName', model.adminPath);
      }
    }
  });

  _exports.default = _default;
});