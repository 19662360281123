define("web-directory/components/rated-tag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zFBD7z+R",
    "block": "{\"statements\":[[11,\"span\",[]],[15,\"class\",\"stars\"],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"fullStars\"]]],null,{\"statements\":[[0,\"        \"],[11,\"i\",[]],[15,\"class\",\"ion-ios-star\"],[13],[14],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\n\"],[6,[\"each\"],[[28,[\"emptyStars\"]]],null,{\"statements\":[[0,\"        \"],[11,\"i\",[]],[15,\"class\",\"ion-ios-star-outline\"],[13],[14],[0,\"\\n\"]],\"locals\":[]},null],[14],[0,\"\\n\\n\"],[11,\"span\",[]],[15,\"class\",\"key\"],[13],[0,\"\\n    \"],[1,[28,[\"tag\",\"key\"]],false],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/rated-tag/template.hbs"
    }
  });

  _exports.default = _default;
});