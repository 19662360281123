define("web-directory/utils/file-extensions-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.videoTypes = _exports.textDocTypes = _exports.powerPointTypes = _exports.pdfTypes = _exports.imageTypes = _exports.excelTypes = _exports.audioTypes = void 0;
  var imageTypes = ['apng', 'avif', 'bmp', 'cur', 'eps', 'gif', 'ico', 'jpeg', 'jpg', 'png', 'svg', 'svg+xml', 'tif', 'tiff', 'webp'];
  _exports.imageTypes = imageTypes;
  var audioTypes = ['aac', 'aif', 'aifc', 'aiff', 'caf', 'flac', 'm4a', 'm4r', 'mogg', 'mp3', 'oga', 'ogg', 'pcm', 'wav', 'wave', 'wma', 'wmf'];
  _exports.audioTypes = audioTypes;
  var videoTypes = ['3g2', '3gp', 'amv', 'asf', 'avi', 'f4a', 'f4b', 'f4p', 'f4v', 'flv', 'gifv', 'm4p', 'm4v', 'mkv', 'mng', 'mod', 'mov', 'mp2', 'mp4', 'mpe', 'mpeg', 'mpg', 'mpv', 'mxf', 'nsv', 'ogv', 'qt', 'rm', 'roq', 'rrc', 'svi', 'vob', 'webm', 'wmv', 'yuv'];
  _exports.videoTypes = videoTypes;
  var textDocTypes = ['doc', 'docm', 'docx', 'dot', 'dotm', 'dotx', 'odt', 'txt'];
  _exports.textDocTypes = textDocTypes;
  var pdfTypes = ['pdf'];
  _exports.pdfTypes = pdfTypes;
  var powerPointTypes = ['key', 'pptx', 'pptm', 'ppt'];
  _exports.powerPointTypes = powerPointTypes;
  var excelTypes = ['xlsx', 'xls'];
  _exports.excelTypes = excelTypes;
});