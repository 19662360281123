define("web-directory/helpers/is-favorite", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    var user = params[0];
    var entity = params[1];
    var favorites = user.get('_favorites');
    var isFavorite = !!favorites && !_lodash.default.isEmpty(favorites.findBy('id', entity.get('id')));

    if (isFavorite) {
      return 'favorite';
    } else {
      return '';
    }
  });

  _exports.default = _default;
});