define("web-directory/models/ember-intl-translation", ["exports", "ember-intl/models/translation"], function (_exports, _translation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* Allows support for both dot-separated keys and nested objects in the dictionary
   * For example, all of the following are supported
         {
              'greeting': "Hello!",
              "error": {
                  "catastrophic.failure": "The world has ended."
              },
              "my.favorite.color": "fuchsia"
         }
  */
  var _default = _translation.default.extend({
    addTranslation: function addTranslation(key, value) {
      this.get('translations')[key] = value;
    },
    getValue: function getValue(key) {
      if (key) {
        var segments = key.split('.');
        var level = this.get('translations');
        var i = 0;

        for (i = 0; i < segments.length; i++) {
          var value = level[segments.slice(i).join('.')];

          if (typeof value === 'string') {
            return value;
          }

          var segment = segments[i];

          if (Object.prototype.hasOwnProperty.call(level, segment)) {
            level = level[segment];
          } else {
            break;
          }
        }
      }

      return undefined;
    }
  });

  _exports.default = _default;
});