define("web-directory/initializers/set-application-theme", ["exports", "web-directory/utils/theme-loader"], function (_exports, _themeLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'set-application-theme',
    before: 'orgspan',

    /**
     * Sets the theme to an out-of-the-box supported theme until FT's load in, and then will update the theme
     * of the application to the actual cached theme if the FT is supported in the Environment. That way the
     * theme will never show until it's confirmed to be supported no matter the value of the theme cookie.
     */
    initialize: function initialize() {
      return (0, _themeLoader.setThemeClass)((0, _themeLoader.loadTheme)());
    }
  };
  _exports.default = _default;
});