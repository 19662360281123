define("web-directory/components/menu-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component;
  var MenuItemComponent = Component.extend({
    showIcon: true,
    classNames: ['menu-item'],
    labelIdentifierClass: 'menu-item-label',
    actions: {
      customClick: function customClick(tgt) {
        this.sendAction('onCustomClick', tgt.node);
      }
    }
  });
  var _default = MenuItemComponent;
  _exports.default = _default;
});