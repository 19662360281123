define("web-directory/templates/chat-room", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R24+IDij",
    "block": "{\"statements\":[[6,[\"if\"],[[33,[\"not\"],[[28,[\"permissions\",\"canViewBusinessChat\"]]],null]],null,{\"statements\":[[0,\"    \"],[11,\"div\",[]],[15,\"class\",\"no-access\"],[13],[0,\"\\n        \"],[11,\"p\",[]],[15,\"class\",\"text-center\"],[13],[0,\"\\n            \"],[11,\"strong\",[]],[13],[0,\"\\n                \"],[1,[33,[\"t\"],[\"chat.permissions.accessErrorTitle\"],null],false],[0,\"\\n            \"],[14],[0,\"\\n        \"],[14],[0,\"\\n        \"],[11,\"p\",[]],[15,\"class\",\"text text-center\"],[13],[0,\"\\n            \"],[1,[33,[\"t\"],[\"chat.permissions.accessError\"],null],false],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/templates/chat-room.hbs"
    }
  });

  _exports.default = _default;
});