define("web-directory/components/agent-assistance-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FHFmkwyA",
    "block": "{\"statements\":[[11,\"div\",[]],[16,\"class\",[34,[\"disconnected-overlay \",[33,[\"if\"],[[28,[\"chat\",\"connected\"]],\"connected\"],null]]]],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"box\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"message\"],[13],[11,\"i\",[]],[15,\"class\",\"pc-moon pc-loading pc-spin\"],[13],[14],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[11,\"div\",[]],[15,\"class\",\"chat-container\"],[13],[0,\"\\n    \"],[11,\"h1\",[]],[15,\"class\",\"sr-only\"],[13],[1,[33,[\"t\"],[\"components.navMenu.agentAssistance\"],null],false],[14],[0,\"\\n\"],[6,[\"if\"],[[33,[\"and\"],[[28,[\"room\"]],[28,[\"active\"]]],null]],null,{\"statements\":[[0,\"        \"],[1,[33,[\"chat-panel/conversation\"],null,[[\"class\",\"activeRoom\",\"disableFileUploads\",\"hideActionBar\"],[\"rosterless actionless\",[28,[\"room\"]],[28,[\"disableFileUploads\"]],true]]],false],[0,\"\\n\"]],\"locals\":[]},null],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/agent-assistance-panel/template.hbs"
    }
  });

  _exports.default = _default;
});