define("web-directory/adapters/user", ["exports", "web-directory/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var UserAdapter = _application.default.extend({
    findQuery: function findQuery(store, type, query) {
      return this.ajax(this.buildURL(type), 'GET', {
        data: query
      });
    }
  });

  var _default = UserAdapter;
  _exports.default = _default;
});