define("web-directory/phone/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      run = Ember.run;

  var _default = Ember.Route.extend({
    chat: inject.service(),
    sidebar: inject.service(),
    afterModel: function afterModel() {
      this._super.apply(this, arguments);

      run.scheduleOnce('afterRender', this, function () {
        var sidebar = this.get('sidebar');
        sidebar.expandCommunicationSidebar();
        sidebar.openPanel('callControls');
      });
    }
  });

  _exports.default = _default;
});