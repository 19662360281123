define("web-directory/components/embedded-admin/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tBJnhQE8",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"showFreeTrialBanner\"]]],null,{\"statements\":[[0,\"    \"],[11,\"div\",[]],[15,\"class\",\"free-trial\"],[13],[0,\"\\n        \"],[1,[33,[\"t\"],[\"admin.free_trial.banner\"],[[\"freeTrialDaysRemaining\"],[[28,[\"freeTrialDaysRemaining\"]]]]],false],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]},null],[6,[\"if\"],[[28,[\"breadcrumbs\",\"crumbs\"]]],null,{\"statements\":[[0,\"    \"],[1,[33,[\"breadcrumb-bar\"],null,[[\"breadcrumbs\",\"home\"],[[28,[\"breadcrumbs\",\"crumbs\"]],[28,[\"breadcrumbs\",\"home\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},null],[11,\"div\",[]],[15,\"class\",\"admin-outlet\"],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"location\"]]],null,{\"statements\":[[6,[\"if\"],[[28,[\"navigationSubMenuFeatureToggleEnabled\"]]],null,{\"statements\":[[0,\"            \"],[1,[33,[\"navigation-sub-menu-v2\"],null,[[\"location\",\"navStructure\",\"linkTo\",\"onClosePanel\"],[[28,[\"location\"]],[28,[\"navStructure\"]],\"admin\",[33,[\"action\"],[[28,[null]],[28,[\"ensureRouteVisibility\"]]],null]]]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"            \"],[1,[33,[\"navigation-sub-menu\"],null,[[\"location\",\"navStructure\",\"linkTo\",\"onClosePanel\"],[[28,[\"location\"]],[28,[\"navStructure\"]],\"admin\",[33,[\"action\"],[[28,[null]],[28,[\"ensureRouteVisibility\"]]],null]]]],false],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[]},null],[0,\"    \"],[11,\"iframe\",[]],[16,\"title\",[34,[[26,[\"name\"]]]]],[16,\"name\",[34,[[26,[\"name\"]]]]],[15,\"seamless\",\"seamless\"],[16,\"src\",[34,[[26,[\"src\"]]]]],[13],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/embedded-admin/template.hbs"
    }
  });

  _exports.default = _default;
});