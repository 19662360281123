define("web-directory/components/entity-fields/image-field/component", ["exports", "web-directory/components/entity-fields/text-field/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend();

  _exports.default = _default;
});