define("web-directory/services/vendor-implementations/jabra-native", ["exports", "ember-softphone-integration/services/vendor-implementations/jabra-native"], function (_exports, _jabraNative) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _jabraNative.default;
    }
  });
});