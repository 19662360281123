define("web-directory/components/group-profile/group-contact-panel/ring-sequence/component", ["exports", "ember-data", "web-directory/mixins/batch-data-requests", "web-directory/components/group-profile/group-contact-panel/component"], function (_exports, _emberData, _batchDataRequests, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_batchDataRequests.default, {
    classNames: ['ring-sequence'],
    application: Ember.inject.service(),
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    group: null,
    searchInput: '',
    searchQuery: '',
    personSearch: false,
    sequenceContainer: null,
    resultsContainer: null,
    resizeListener: null,
    isSequential: false,
    displayIndices: false,
    editing: false,
    listDirty: false,
    ringRotations: null,
    listType: null,
    owners: Ember.computed.reads('group.owners.owners'),
    individuals: Ember.computed.reads('group.individuals.individuals'),
    _editing: false,
    isOwners: Ember.computed.equal('listType', _component.LIST_TYPES.OWNERS),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.sequenceContainer = this.$('.sequence-container');

      if (this.attrs.appendSave) {
        this.saveFunction = Ember.run.bind(this, this.save);
        this.attrs.appendSave(this.saveFunction);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._removeListeners();

      if (this.attrs.removeSave) {
        this.attrs.removeSave(this.saveFunction);
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var editing = this.get('editing');
      var cachedEditing = this.get('_editing');

      if (editing !== cachedEditing) {
        this.set('_editing', editing);
        this.notifyPropertyChange('owners');
        this.notifyPropertyChange('individuals');
      }
    },
    sequenceList: Ember.computed('isOwners', 'individualsSequenceList.[]', 'ownersSequenceList.[]', function () {
      return this.get('isOwners') ? this.get('ownersSequenceList') : this.get('individualsSequenceList');
    }),
    individualsSequenceList: Ember.computed('individuals.[]', function () {
      return _emberData.default.PromiseArray.create({
        promise: this._sequenceListForPeople(this.get('individuals') || [])
      });
    }),
    ownersSequenceList: Ember.computed('owners.[]', function () {
      return _emberData.default.PromiseArray.create({
        promise: this._sequenceListForPeople(this.get('owners') || [])
      });
    }),
    renderedList: Ember.computed('sequenceList.[]', 'individualsSequenceList.[]', 'ownersSequenceList.[]', 'ringRotations', function () {
      return _emberData.default.PromiseArray.create({
        promise: this._appendRotationListData()
      });
    }),
    searchLabel: Ember.computed('listType', function () {
      if (this.get('listType') === 'owners') {
        return this.get('intl').t('groups.groupRing.ownersSearch');
      } else {
        return this.get('intl').t('groups.groupRing.membersSearch');
      }
    }),
    searchPlaceholder: Ember.computed('listType', function () {
      if (this.get('listType') === 'owners') {
        return this.get('intl').t('groups.groupRing.searchPlaceholderOwners');
      } else {
        return this.get('intl').t('groups.groupRing.searchPlaceholder');
      }
    }),
    addMemberPlaceholder: Ember.computed(function () {
      return this.get('intl').t('groups.groupRing.addMemberPlaceholder');
    }),
    clearSearch: Ember.observer('editing', function () {
      this.set('searchInput', '');
      this.set('searchQuery', '');
    }),
    actions: {
      inputEntered: function inputEntered() {
        Ember.run.throttle(this, this._updateSearchQuery, 500, false);
      },
      togglePersonSearch: function togglePersonSearch() {
        this.toggleProperty('personSearch');
        this.handleListeners();
      },
      addPerson: function addPerson(person) {
        var _this = this;

        return this.get('sequenceList').then(function (sequenceList) {
          var isOwner = _this.get('group').isPersonOwner(person);

          var index = sequenceList.get('length') + 1;
          var listItem = Ember.Object.create({
            person: person,
            isOwner: isOwner,
            index: index
          });

          _this.set('personSearch', false);

          sequenceList.pushObject(listItem);
          sequenceList.set('lastObject.scrollIntoView', true);

          _this.set('listDirty', true);
        });
      },
      listModified: function listModified() {
        this.set('listDirty', true);
      }
    },
    handleListeners: function handleListeners() {
      var _this2 = this;

      if (this.get('personSearch')) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          _this2._attachListeners();

          _this2._handleResizeEvent();
        });
      } else {
        Ember.run.scheduleOnce('afterRender', this, this._removeListeners);
      }
    },
    save: function save() {
      this.set('personSearch', false);
      var owners = this.get('ownersSequenceList');
      var individuals = this.get('individualsSequenceList');
      return {
        type: 'ring-sequence',
        individuals: individuals.then(function (people) {
          return people.mapBy('person.id');
        }),
        owners: owners.then(function (people) {
          return people.mapBy('person.id');
        })
      };
    },
    _appendRotationListData: function _appendRotationListData() {
      var _this3 = this;

      return this.get('sequenceList').then(function (sequenceList) {
        var ringRotations = _this3.get('ringRotations');

        var listLength = sequenceList.get('length');

        if (ringRotations !== null) {
          sequenceList.forEach(function (listModel, index) {
            listModel.setProperties({
              beginningOutOfRange: index === ringRotations,
              outOfRange: index >= ringRotations,
              endOutOfRange: index > ringRotations - 1 && index === listLength - 1,
              popupDismissed: false
            });
          });
        }

        return sequenceList;
      });
    },
    _sequenceListForPeople: function _sequenceListForPeople(people) {
      var _this4 = this;

      var batchPromises = people.map(function (person) {
        return _this4.getUser(person.guid || person.value);
      });
      return Ember.RSVP.all(batchPromises).then(function (personArray) {
        return personArray.map(function (person, index) {
          var isOwner = _this4.get('group').isPersonOwner(person);

          return Ember.Object.create({
            person: person,
            isOwner: isOwner,
            index: ++index
          });
        });
      });
    },
    _updateSearchQuery: function _updateSearchQuery() {
      this.set('searchQuery', this.get('searchInput'));
    },
    _attachListeners: function _attachListeners() {
      var _this5 = this;

      this.resizeListener = Ember.$(window).on("resize.".concat(this.get('elementId')), function () {
        Ember.run.throttle(_this5, _this5._handleResizeEvent, 100, false);
      });
      this.resultsContainer = this.$('.results-container');
    },
    _removeListeners: function _removeListeners() {
      Ember.$(window).off(".".concat(this.get('elementId')));
      this.resultsContainer = null;
    },
    _handleResizeEvent: function _handleResizeEvent() {
      var height = this.sequenceContainer.height();
      this.resultsContainer.css('height', "".concat(height - 4, "px"));
    }
  });

  _exports.default = _default;
});