define("web-directory/routes/skillgroup/detail", ["exports", "web-directory/mixins/model-route"], function (_exports, _modelRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SkillGroupDetailRoute = Ember.Route.extend(_modelRoute.default, {
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    notification: Ember.inject.service(),
    routeTitle: 'Skill Group Profile'
  });
  var _default = SkillGroupDetailRoute;
  _exports.default = _default;
});