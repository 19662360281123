define("web-directory/components/group-profile/roles-edit/fixtable/division-view/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    divisionsDisplayList: Ember.computed('dataRow.divisions.[]', function () {
      var divisions = this.get('dataRow.divisions');
      var displayString = '';

      if (divisions.length) {
        for (var i = 0; i < divisions.length - 1; i++) {
          displayString += divisions.objectAt(i).id === '*' ? this.get('intl').lookup('groups.roles.allDivisions') : divisions.objectAt(i).get('name');
          displayString += ', ';
        }

        displayString += divisions.objectAt(i).id === '*' ? this.get('intl').lookup('groups.roles.allDivisions') : divisions.objectAt(i).get('name');
      }

      return displayString;
    })
  });

  _exports.default = _default;
});