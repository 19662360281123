define("web-directory/models/org", ["exports", "ember-data", "lodash", "web-directory/mixins/entity"], function (_exports, _emberData, _lodash, _entity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _emberData.default.Model.extend(_entity.default, {
    name: computed('general.name.@each.value', function () {
      var name = this.getFieldValue('general.name');

      if (name.value) {
        return name.value;
      }

      return '';
    }),
    shortName: computed('general.shortName.@each.value', function () {
      var shortName = this.getFieldValue('general.shortName');

      if (shortName.value) {
        return shortName.value;
      }

      return '';
    }),
    hasUnifiedCommunications: computed('enabledFeatures.unifiedCommunications.@each.value', function () {
      var comms = this.getFieldValue('enabledFeatures.unifiedCommunications');

      if (_lodash.default.isEmpty(comms)) {
        return false;
      }

      return comms.value;
    }),
    hasChatEnabled: computed('enabledFeatures.chat.@each.value', function () {
      var chat = this.getFieldValue('enabledFeatures.chat');
      return chat.value;
    }),
    hasHipaaEnabled: computed('enabledFeatures.hipaa.@each.value', function () {
      var hipaa = this.getFieldValue('enabledFeatures.hipaa');
      return hipaa.value;
    }),
    orgDefaultCountryCode: computed('general.defaultCountryCode.@each.value', function () {
      return this.getFieldValue('general.defaultCountryCode') ? this.getFieldValue('general.defaultCountryCode').value : null;
    }),
    orgPreferences: computed('preferences.preferences.@each.value', function () {
      var preferences = this.getFieldValue('preferences.preferences');
      var parsedPreferences;

      try {
        parsedPreferences = JSON.parse(preferences.value);
      } catch (err) {
        parsedPreferences = {};
      }

      return parsedPreferences;
    })
  });

  _exports.default = _default;
});