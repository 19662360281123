define("web-directory/routes/engage/embedded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var QUERY_REGEX = /\?.*$/;
  var EMBEDDED_TARGETS = ['engage', 'engage.index', 'engage.embedded'];

  var _default = Ember.Route.extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var hashMatch = QUERY_REGEX.exec(window.location.hash);

      if (hashMatch) {
        model.engagePath = model.engagePath + hashMatch[0];
      }

      controller.setEngageActive();
    },
    actions: {
      didTransition: function didTransition() {
        this.send('closePanel');
      },
      willTransition: function willTransition(transition) {
        if (EMBEDDED_TARGETS.indexOf(transition.targetName) === -1) {
          this.controller.setEngageInactive();
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});