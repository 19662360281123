define("web-directory/components/entity-profile/add-section/component", ["exports", "web-directory/utils/focus-management"], function (_exports, _focusManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var run = Ember.run,
      Component = Ember.Component;

  var _default = Component.extend({
    classNames: ['add-section'],
    addingSection: false,
    attrs: {
      sections: null,
      emptySections: null,
      addSection: function addSection() {}
    },
    actions: {
      toggleAddingSection: function toggleAddingSection() {
        this.toggleProperty('addingSection');

        if (this.get('addingSection')) {
          var focusTarget = '.add-section .name a:first';
          Ember.run.later(this, function () {
            (0, _focusManagement.doFocus)(Ember, focusTarget);
          }, 250);
        }
      },
      addSection: function addSection() {
        var _this$attrs;

        (_this$attrs = this.attrs).addSection.apply(_this$attrs, arguments);

        this.set('addingSection', false);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      run.scheduleOnce('afterRender', this, this.attachHandler);
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(window).off(".".concat(this.elementId));

      this._super.apply(this, arguments);
    },
    attachHandler: function attachHandler() {
      var _this = this;

      Ember.$(window).on("click.".concat(this.elementId), function (event) {
        Ember.run(function () {
          var target = Ember.$(event.target);

          if (!target.parents('.add-section').length) {
            _this.set('addingSection', false);
          }
        });
      });
    }
  });

  _exports.default = _default;
});