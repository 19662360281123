define("web-directory/components/acd-interactions-panel/interaction-roster/component", ["exports", "web-directory/utils/acd", "web-directory/utils/dates"], function (_exports, _acd, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  /**
   * List of focusable items inside the modal
   */
  var MODAL_FOCUSABLES = ['div.start-new-interaction a[href]:not([disabled])', 'div.start-new-interaction button:not([disabled])', 'div.start-new-interaction textarea:not([disabled])', 'div.start-new-interaction input[type="text"]:not([disabled])', 'div.start-new-interaction input[type="radio"]:not([disabled])', 'div.start-new-interaction input[type="checkbox"]:not([disabled])', 'div.start-new-interaction select:not([disabled])'];
  /**
   * Limits the number of outbound media icons shown before using modal
   */

  var MAX_ICON_SIZE = 2;
  /**
   * Configuration for outbound modal/dropdown UI
   */

  var OUTBOUND_MEDIA_CONFIG = {
    CALL: {
      media: 'call',
      buttonClass: 'new-call',
      iconClass: 'pc-moon pc-phone',
      title: 'purecloud.makeACall'
    },
    EMAIL: {
      media: 'email',
      buttonClass: 'new-email',
      iconClass: 'pc-moon pc-email',
      title: 'purecloud.acdEmail.sendEmail'
    },
    SMS: {
      media: 'sms',
      buttonClass: 'new-message',
      iconType: 'svg',
      title: 'purecloud.acdInteraction.sms.sendMessage'
    }
  };

  function _isWorkitem(interaction) {
    return !!interaction.workitem;
  }

  var _default = Ember.Component.extend({
    classNames: ['acd-interactions-list', 'chat-roster', 'gray-arrow-v2'],
    classNameBindings: ['hasActiveInteraction:has-active-interaction:not-has-active-interaction', 'onQueueOrInteracting', 'showDialpad:dialpad-open:dialpad-closed'],

    /**
     * @Attribute
     * Values passed from the parent component context.
     * These values are bound to readonly properties for render below.
     */
    state: null,

    /**
     * The currently selected outbound modal tab
     * TODO: When refactoring the modal, it would be good to self contain
     * flags like this.
     */
    activeTab: null,

    /**
     * Flag for showing interaction settings
     * TODO: Another good candidate for a small render component
     */
    showSettings: false,

    /**
     * Flag for showing the outbound modal
     * TODO: When refactoring the modal, it would be good to self contain
     * flags like this
     */
    showNewInteractionDialog: false,

    /**
     * Flag for showing the number dialpad for call tab in outbound modal
     * TODO: When refactoring the modal, it would be good to self contain
     * flags like this
     */
    showDialpad: false,

    /**
     * `true` if the dial pad dropdown (mini conversation creation popovers) should
     * be open
     */
    showCallControls: false,

    /**
     * `true` if the email dropdown (mini conversation creation popovers) should
     * be open
     */
    showEmailDialog: false,

    /**
     * `true` if the message dropdown (mini conversation creation popovers) should
     * be open
     */
    showMessageDialog: false,

    /**
     * Modal node
     */
    modalCard: null,

    /**
     * Object of interaction start timestamps used for stably sorting
     * groups of conversations keyed by group guid
     */
    _groupSortTimes: null,

    /**
     * `true` if the multipanel preference has been enabled by the current user
     */
    multipanelPreferenceEnabled: Ember.computed.readOnly('state.multipanelPreferenceEnabled'),

    /**
     * `true` if the multipanel experience should be shown
     */
    useMultipanel: Ember.computed.readOnly('state.useMultipanel'),

    /**
     * The currently selected interaction
     */
    selectedInteraction: Ember.computed.readOnly('state.selectedInteraction'),

    /**
     * An array of all interactions we consider active
     */
    activeInteractions: Ember.computed.readOnly('state.activeInteractions'),

    /**
     * An array of Workitems we consider active
     */
    activeWorkitems: Ember.computed.readOnly('state.activeWorkitems'),

    /**
     * True/false if our presence is 'on queue'
     */
    isOnQueue: Ember.computed.readOnly('state.isOnQueue'),

    /**
     * True/false if the app is in the legacy popout context (i.e. PEF)
     */
    isPopout: Ember.computed.readOnly('state.isPopout'),

    /**
     * True/false if streaming service (i.e. the Hawk websocket) is connected
     */
    streamingConnected: Ember.computed.readOnly('state.streamingConnected'),

    /**
     * True/false if ACD panel is the selected panel and not closed
     */
    acdPanelOpen: Ember.computed.readOnly('state.acdPanelOpen'),

    /**
     * An array of conversation guids where customers are flagged as typing
     */
    isTypingInteractions: Ember.computed.readOnly('state.isTypingInteractions'),

    /**
     * Pass through property containing emergency location data
     */
    emergencyLocationData: Ember.computed.readOnly('state.e911.emergencyLocationData'),

    /**
     * True/false whether we should render the emergency location component
     */
    emergencyLocationOn: Ember.computed.readOnly('state.e911.emergencyLocationOn'),

    /**
     * The current user's guid
     */
    currentUserId: Ember.computed.readOnly('state.currentUserId'),

    /**
     * An array of external contact objects from external contacts service
     */
    externalContacts: Ember.computed.readOnly('state.externalContacts.contacts'),

    /**
     * True/false permission check for canCreateEmail
     */
    permCanCreateEmail: Ember.computed.readOnly('state.permissions.canCreateEmail'),

    /**
     * True/false permission check for permCanSendMessage
     */
    permCanSendMessage: Ember.computed.readOnly('state.permissions.canSendMessage'),

    /**
     * True/false permission check for permCanMakeCall
     */
    permCanMakeCall: Ember.computed.readOnly('state.permissions.canMakeCall'),

    /**
     * True/false permission check for permCanViewExternalContacts
     */
    permCanViewExternalContacts: Ember.computed.readOnly('state.permissions.canViewExternalContacts'),

    /**
     * True/false feature toggle flag for active conversations
     */
    hasActiveInteraction: Ember.computed.bool('interactionGroups.length'),

    /**
     * True/false if the current user's presence is 'on queue' or otherwise has active conversations
     */
    onQueueOrInteracting: Ember.computed.or('isOnQueue', 'hasActiveInteraction'),

    /**
     * Computed to group interactions together by externalContactId.  If there
     * is not externalContactId present or the conversation is in an alerting state,
     * the conversation will be grouped by itself.  Conversation in the group will
     * be sorted by oldest to newest.
     */
    interactionGroups: Ember.computed('activeInteractions.[]', 'activeWorkitems.[]', 'externalContacts.[]', 'actionGetCanonicalContactSync', 'activeInteractions.@each.externalContactId', 'activeInteractions.@each.startTimestamp', 'activeWorkitems.@each.workitem.externalContactId', function () {
      var conversations = this.get('activeInteractions').sortBy('startTimestamp');
      var workitems = this.get('activeWorkitems');
      var groups = [];
      var getCanonicalContactSync = this.get('actionGetCanonicalContactSync');
      var interactions = conversations.concat(workitems);
      interactions.forEach(function (interaction) {
        var externalContactId = Ember.get(interaction, 'externalContactId') || Ember.get(interaction, 'customer.externalContactId') || undefined;

        var _ref = getCanonicalContactSync(externalContactId) || {},
            groupId = _ref.id;

        var isAlerting = _isWorkitem(interaction) ? Ember.get(interaction, 'workitem.assignmentState') === 'Alerting' : Ember.get(interaction, 'isAlerting');
        var group;

        if (groupId && !isAlerting) {
          group = groups.find(function (group) {
            var id = group.groupId;
            return groupId === id;
          });
        }

        if (!groupId || isAlerting) {
          // conversations without contacts or in alerting state
          // are added as groups with a single conversation
          groups.push({
            interactions: [interaction]
          });
        } else if (!group) {
          groups.push({
            groupId: groupId,
            interactions: [interaction]
          });
        } else {
          group.interactions.push(interaction);
        }
      });
      return groups;
    }),

    /**
     * Computed to decorate groups with the following attributes:
     *
     * 1. id: either the externalContactId or conversationId)
     * 2. sortTimestamp: either the startTimestamp or a recent timestamp for stable sort
     *
     * Additionally, the computed will store timestamps for the oldest conversation
     * in a group for stable sorting.
     */
    decoratedGroups: Ember.computed('interactionGroups.[]', function () {
      var _this = this;

      var groups = this.get('interactionGroups');
      return groups.map(function (group) {
        var ecId = group.groupId;
        var sortTimes = _this.get('_groupSortTimes') || {};
        var startTimestamps = group.interactions.map(function (interaction) {
          if (_isWorkitem(interaction)) {
            var ts = Ember.get(interaction, 'workitem.dateDue');
            return (0, _dates.default)(ts).valueOf();
          }

          return Ember.get(interaction, 'startTimestamp');
        });
        var oldest = Math.min.apply(Math, _toConsumableArray(startTimestamps));

        if (ecId) {
          if (sortTimes[ecId] && sortTimes[ecId] < oldest) {
            oldest = sortTimes[ecId];
          } else {
            sortTimes[ecId] = oldest;
          }

          group.sortTimestamp = oldest;
        } else if (Ember.get(group.interactions[0], 'isAlerting')) {
          // alerting interactions at top
          group.sortTimestamp = Date.now();
        } else {
          group.sortTimestamp = oldest;
        }

        _this.set('_groupSortTimes', sortTimes); // bound with {{each}} to reduce re-renders


        group.id = ecId || group.interactions[0].id;
        return group;
      });
    }),

    /**
     * Computed to start the groups by the newest sortTimestamp to oldest timestamp.
     */
    sortedGroups: Ember.computed('decoratedGroups.[]', function () {
      var groups = this.get('decoratedGroups').sortBy('sortTimestamp').reverse();
      return groups;
    }),

    /**
     * Computed to determine if the user can make outbound conversations.
     */
    enableOutboundContact: Ember.computed.and('outboundMedia.length', 'permCanViewExternalContacts'),

    /**
     * Computed to enable to outbound modal, vs individual outbound dropdowns.
     */
    enableOutboundDialog: Ember.computed('outboundMedia.@each.canCreate', function () {
      var allowedOutboundMedia = this.get('outboundMedia').filter(function (media) {
        return media.canCreate === true;
      });
      return allowedOutboundMedia.length > MAX_ICON_SIZE;
    }),

    /**
     * Computed to determine if a session share is active (i.e. cobrowse or screenshare). If so,
     * we want to disable outbound controls.
     */
    sessionShareActive: Ember.computed.readOnly('selectedInteraction.sessionShareActive'),

    /**
     * Configuration objects for outbound media modal.
     */
    outboundMedia: Ember.computed('permCanSendMessage', 'permCanCreateEmail', 'permCanMakeCall', function () {
      return [_objectSpread(_objectSpread({}, OUTBOUND_MEDIA_CONFIG.CALL), {}, {
        canCreate: this.get('permCanMakeCall')
      }), _objectSpread(_objectSpread({}, OUTBOUND_MEDIA_CONFIG.EMAIL), {}, {
        canCreate: this.get('permCanCreateEmail')
      }), _objectSpread(_objectSpread({}, OUTBOUND_MEDIA_CONFIG.SMS), {}, {
        canCreate: this.get('permCanSendMessage')
      })];
    }),

    /**
     * @Action
     * Closure action passed to the component to be invoked at the parent level
     */
    actionGoOnQueue: function actionGoOnQueue() {
      return (0, _acd.warnClosureAction)('actionGoOnQueue', {});
    },

    /**
     * @Action
     * Closure action passed to the component to be invoked at the parent level
     */
    actionGetCanonicalContactSync: function actionGetCanonicalContactSync(externalContactId) {
      return (0, _acd.warnClosureAction)('actionGetCanonicalContactSync', {
        externalContactId: externalContactId
      });
    },

    /**
     * Remove sort time record for stored contact groups
     */
    _cleanUpSortTimes: function _cleanUpSortTimes() {
      if (!this.get('isDestroyed') && !this.get('isDestroying')) {
        var groupIds = this.get('sortedGroups').map(function (g) {
          return g.groupId;
        });
        var sortTimes = this.get('_groupSortTimes') || {};
        var updatedSortTimes = Object.fromEntries(Object.entries(sortTimes).filter(function (_ref2) {
          var _ref3 = _slicedToArray(_ref2, 1),
              ts = _ref3[0];

          return groupIds.includes(ts);
        }));
        this.set('_groupSortTimes', updatedSortTimes);
      }
    },

    /**
     * Close the outbound modal
     */
    closeOutboundDialog: function closeOutboundDialog() {
      if (!this.get('isDestroyed') && !this.get('isDestroying')) {
        this.set('showNewInteractionDialog', false);
      }
    },

    /**
     * @action
     */
    actionSetMultipanelPreference: function actionSetMultipanelPreference(value) {
      return (0, _acd.warnClosureAction)('actionSetMultipanelPreference', {
        value: value
      });
    },

    /**
     * @Action
     * Sets up finding the modal and pulls focus inside it for keyboard navigation
     */
    trapFocus: function trapFocus() {
      var _this$get;

      var modalCard = document.querySelector('div.start-new-interaction');
      this.set('modalCard', modalCard);
      modalCard === null || modalCard === void 0 ? void 0 : modalCard.addEventListener('keydown', this.trapFocusTabListener.bind(this));
      var focusableElsNodes = (_this$get = this.get('modalCard')) === null || _this$get === void 0 ? void 0 : _this$get.querySelectorAll(MODAL_FOCUSABLES.join(', '));

      if (focusableElsNodes && focusableElsNodes.length > 0) {
        var firstFocusableEl = focusableElsNodes[0];
        firstFocusableEl === null || firstFocusableEl === void 0 ? void 0 : firstFocusableEl.focus();
      }
    },

    /**
     * @Listener
     * Listens for tab key usage inside of the modal
     */
    trapFocusTabListener: function trapFocusTabListener(e) {
      if (e.key === 'Tab' || e.keyCode === 9) {
        var _this$get2;

        var focusableElsNodes = (_this$get2 = this.get('modalCard')) === null || _this$get2 === void 0 ? void 0 : _this$get2.querySelectorAll(MODAL_FOCUSABLES.join(', '));

        if (focusableElsNodes && focusableElsNodes.length > 1) {
          var firstFocusableEl = focusableElsNodes[0];
          var lastFocusableEl = focusableElsNodes[focusableElsNodes.length - 1];

          if (document.activeElement === (e.shiftKey ? firstFocusableEl : lastFocusableEl)) {
            e.preventDefault();
          }
        }
      }
    },

    /**
     * @Action
     * Helper function to reset state of the outbound modal
     */
    _resetSelections: function _resetSelections() {
      var outboundMedia = this.get('outboundMedia');
      this.set('activeTab', outboundMedia[0].media);
      this.set('showDialpad', false);
    },
    init: function init() {
      this._super.apply(this, arguments);

      this._resetSelections();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var _this2 = this;

      this._super.apply(this, arguments);

      if (!this.get('acdPanelOpen') && this.get('showNewInteractionDialog')) {
        Ember.run.debounce(function () {
          return _this2.closeOutboundDialog();
        }, 100);
      }

      Ember.run.debounce(function () {
        return _this2._cleanUpSortTimes();
      }, 100);
    },
    actions: {
      startNewInteraction: function startNewInteraction() {
        this._resetSelections();

        this.set('showNewInteractionDialog', true);
        Ember.run.later(this, this.trapFocus.bind(this), 100);
      },
      setActiveTab: function setActiveTab(tab) {
        this.set('activeTab', tab);
      },
      closeModal: function closeModal() {
        var _this3 = this,
            _this$get3;

        Ember.run.debounce(function () {
          return _this3.closeOutboundDialog();
        }, 100);
        (_this$get3 = this.get('modalCard')) === null || _this$get3 === void 0 ? void 0 : _this$get3.removeEventListener('keydown', this.trapFocusTabListener);
      },
      goOnQueue: function goOnQueue() {
        this.actionGoOnQueue();
      },
      onInteractionDialogClose: function onInteractionDialogClose() {
        this.closeOutboundDialog();
      },
      onCloseDialpad: function onCloseDialpad() {
        this.set('showCallControls', false);
      },
      onCloseEmailDialog: function onCloseEmailDialog() {
        this.set('showEmailDialog', false);
      },
      onCloseMessageDialog: function onCloseMessageDialog() {
        this.set('showMessageDialog', false);
      },
      openPopover: function openPopover() {
        this.set('showSettings', true);
      },
      closePopover: function closePopover() {
        this.set('showSettings', false);
      },
      onSetMultipanelPreference: function onSetMultipanelPreference(value) {
        this.actionSetMultipanelPreference(value);
      }
    }
  });

  _exports.default = _default;
});