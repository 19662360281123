define("web-directory/services/breadcrumb-nav", ["exports", "ember-engine-custom-fields/services/breadcrumb-nav"], function (_exports, _breadcrumbNav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _breadcrumbNav.default;
    }
  });
});