define("web-directory/controllers/relate/externalcontact", ["exports", "web-directory/controllers/relate/index"], function (_exports, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var RelateExternalContactController = _index.default.extend({// Nothing to override.  This is instantiated to provide the same services from RelateExternalContactRoute to
    // the RelateExternalContactRoute
  });

  var _default = RelateExternalContactController;
  _exports.default = _default;
});