define("web-directory/components/third-party-message-utils/line/component", ["exports", "lodash/uniqBy"], function (_exports, _uniqBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed,
      inject = Ember.inject;

  var _default = Component.extend({
    classNames: ['third-party-line'],
    line: inject.service(),
    logger: inject.service('stash-logger'),
    loadingStickers: false,
    loadingError: false,
    stickers: null,
    packages: null,
    selectedPackage: null,
    selectedPackageStickers: computed('selectedPackage.id', 'stickers', function () {
      var _this = this;

      return (this.get('stickers') || []).filter(function (sticker) {
        return sticker.packageId === _this.get('selectedPackage.id');
      });
    }),
    getStickerPackages: function getStickerPackages(entities) {
      return (0, _uniqBy.default)((entities || []).map(function (sticker) {
        return {
          id: sticker.providerPackageId,
          name: sticker.packageName
        };
      }), 'id');
    },
    getStickers: function getStickers(entities) {
      return (entities || []).map(function (sticker) {
        return {
          id: sticker.id,
          providerStickerId: sticker.providerStickerId,
          packageId: sticker.providerPackageId,
          url: sticker.uriLocation
        };
      });
    },
    loadStickers: function loadStickers() {
      var _this2 = this;

      this.set('loadingStickers', true);
      this.set('loadingError', false);
      this.get('line').loadStickers().then(function (response) {
        _this2.set('packages', _this2.getStickerPackages(response.entities));

        _this2.set('stickers', _this2.getStickers(response.entities));

        _this2.set('selectedPackage', _this2.get('packages.firstObject'));
      }).catch(function (error) {
        _this2.set('loadingError', true);

        _this2.get('logger').error('ACD: sms/messages loading line stickers failed', "".concat(error, " for interaction id ").concat(_this2.get('interaction.id')));
      }).finally(function () {
        _this2.set('loadingStickers', false);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.set('stickers', []);
      this.set('packages', []);
      this.loadStickers();
    },
    actions: {
      selectPackage: function selectPackage(selectedPackage) {
        this.set('selectedPackage', selectedPackage);
      },
      chooseLineSticker: function chooseLineSticker(selectedSticker) {
        this.triggerAction({
          action: 'chooseLineSticker',
          actionContext: [selectedSticker]
        });
      },
      retryLoadStickers: function retryLoadStickers() {
        this.loadStickers();
      }
    }
  });

  _exports.default = _default;
});