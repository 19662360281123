define("web-directory/components/resizable-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v5k1Z8oh",
    "block": "{\"statements\":[[18,\"default\"],[0,\"\\n\\n\"],[11,\"a\",[]],[15,\"href\",\"#\"],[15,\"class\",\"resize-handle btn btn-purecloud\"],[5,[\"action\"],[[28,[null]],\"toggleSize\"]],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"minimized\"]]],null,{\"statements\":[[0,\"        \"],[11,\"span\",[]],[15,\"class\",\"sr-only\"],[13],[1,[33,[\"t\"],[\"common.expand\"],null],false],[14],[0,\"\\n        \"],[9,\"gux-icon\",{\"attrs\":[[15,\"icon-name\",\"arrow-solid-down\"],[15,\"decorative\",\"true\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n        \"],[9,\"gux-icon\",{\"attrs\":[[15,\"icon-name\",\"arrow-solid-down\"],[15,\"decorative\",\"true\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n        \"],[9,\"gux-icon\",{\"attrs\":[[15,\"icon-name\",\"arrow-solid-down\"],[15,\"decorative\",\"true\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"        \"],[11,\"span\",[]],[15,\"class\",\"sr-only\"],[13],[1,[33,[\"t\"],[\"common.collapse\"],null],false],[14],[0,\"\\n        \"],[9,\"gux-icon\",{\"attrs\":[[15,\"icon-name\",\"arrow-solid-up\"],[15,\"decorative\",\"true\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n        \"],[9,\"gux-icon\",{\"attrs\":[[15,\"icon-name\",\"arrow-solid-up\"],[15,\"decorative\",\"true\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n        \"],[9,\"gux-icon\",{\"attrs\":[[15,\"icon-name\",\"arrow-solid-up\"],[15,\"decorative\",\"true\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n\"]],\"locals\":[]}],[14]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/resizable-content/template.hbs"
    }
  });

  _exports.default = _default;
});