define("web-directory/components/timeline-stream/mention-renderer/component", ["exports", "web-directory/components/timeline-stream/renderer-component-base"], function (_exports, _rendererComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var TimelineMentionRenderer = _rendererComponentBase.default.extend({
    classNameBindings: [':timeline-event', ':mention-timeline-event', 'acknowledged'],
    chat: Ember.inject.service(),
    sidebar: Ember.inject.service(),
    entity: null,
    messageBinding: 'entity.message',
    fromPersonBinding: 'entity.message.chatUser',
    roomBinding: 'entity.message.room',
    useCollaborateChat: Ember.computed.readOnly('session.useCollaborateChat'),
    roomSubject: Ember.computed('entity.message', 'useCollaborateChat', function () {
      var message = this.get('entity.message');
      var useCollabChat = this.get('useCollaborateChat');

      if (useCollabChat && message) {
        return message.roomName;
      }

      var roomName = this.get('entity.message.room.subject');
      return roomName;
    }),
    click: function click() {
      this._super();

      if (this.get('session.useCollaborateChat')) {
        var room = this.get('room');
        var jid = room.get('jid');
        this.get('chat').openRoom(jid);
      } else {
        this.get('chat').changeActiveRoom(this.get('room'));
      }

      this.get('sidebar').openPanel('conversations');
    }
  });

  var _default = TimelineMentionRenderer;
  _exports.default = _default;
});