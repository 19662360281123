define("web-directory/controllers/maintenance", ["exports", "web-directory/models/unrecoverable-app-failure", "web-directory/utils/types"], function (_exports, _unrecoverableAppFailure, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller,
      computed = Ember.computed,
      inject = Ember.inject;
  var MaintenanceController = Controller.extend({
    intl: inject.service(),
    hasDesc: computed.bool('messageKeys.descKey'),
    messageKeys: computed('model', function () {
      var model = this.get('model');

      if (!(0, _unrecoverableAppFailure.isUnrecoverableAppFailure)(model)) {
        return {
          titleKey: 'unrecoverableAppFailure.unknownFailure.title',
          descKey: 'unrecoverableAppFailure.unknownFailure.desc',
          actionKey: 'unrecoverableAppFailure.unknownFailure.action'
        };
      }

      if (model.type === 'sessionBootstrapError') {
        var _descKey;

        var failedStep = model.failedStep;

        switch (failedStep) {
          case 'loadFeatureToggles':
          case 'setupTheme':
          case 'setupUserAndTrustee':
          case 'setupLogCapture':
          case 'establishDirectorySession':
          case 'initServerTranslations':
          case 'loadPresences':
          case 'loadUserDetails':
          case 'loadUserFavorites':
          case 'setupCarrierPigeon':
          case 'initAppSession':
          case 'loadOrgPermissions':
          case 'loadOrgTranscriptSettings':
          case 'loadPerson':
            // Initial steps are all mapped 1:1 to translations
            // When adding a new step, be sure to add a translation or map it to a common translation
            _descKey = "unrecoverableAppFailure.sessionBootstrap.stepFailures.".concat(failedStep);
            break;

          default:
            (0, _types.assertNever)(failedStep);
        }

        return {
          titleKey: 'unrecoverableAppFailure.sessionBootstrap.errorPageTitle',
          descKey: _descKey,
          actionKey: model.status === 429 ? 'unrecoverableAppFailure.sessionBootstrap.userRateLimited' : 'unrecoverableAppFailure.sessionBootstrap.systemLoad'
        };
      }

      var rootKey;
      var descKey = undefined;

      switch (model.type) {
        case 'timeMachineFailure':
        case 'missingAuthenticator':
        case 'sessionInvalidationFailure':
        case 'invalidEnv':
        case 'profileFetchServerFailure':
        case 'generalAuthenticationFailure':
          // Initial failure types are all mapped 1:1 to translations
          // When adding a new step, be sure to add a translation or map it to a common translation
          rootKey = "unrecoverableAppFailure.".concat(model.type);

          if (this.get('intl').exists("".concat(rootKey, ".desc"))) {
            descKey = "".concat(rootKey, ".desc");
          }

          break;

        default:
          (0, _types.assertNever)(model.type);
      }

      return {
        titleKey: "".concat(rootKey, ".title"),
        descKey: descKey,
        actionKey: "".concat(rootKey, ".action")
      };
    })
  });
  var _default = MaintenanceController; // DO NOT DELETE: This registers controllers by key with the Ember Container allowing TS to know the types of items in the container

  _exports.default = _default;
});