define("web-directory/components/tooltip-on-element", ["exports", "web-directory/config/environment", "ember-tooltips/components/tooltip-on-element"], function (_exports, _environment, _tooltipOnElement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _didUpdateTimeoutLength = _environment.default.environment === 'test' ? 0 : 1000;

  var _default = _tooltipOnElement.default.extend({
    _didUpdateTimeoutLength: _didUpdateTimeoutLength
  });

  _exports.default = _default;
});