define("web-directory/services/purecloud-oauth", ["exports", "lodash", "should-send-same-site-none", "web-directory/utils/dates"], function (_exports, _lodash, _shouldSendSameSiteNone, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LOGOUT_BOUNCE_COOKIE_NAME = 'lastLogoutTimestamp';
  var LOGOUT_BOUNCE_COOKIE_DURATION_MILLIS = 5000;
  var LOGOUT_BOUNCE_THREASHOLD_MILLIS = 5000;
  var inject = Ember.inject,
      typeOf = Ember.typeOf,
      Logger = Ember.Logger,
      Service = Ember.Service;
  /**
   *  Service containing utility methods and metadata used in the PureCloud OAuth flows
   *
   *  service:purecloud-oauth - utilities and state for the oauth flow
   *  authenticator:directory - Handles direct interface with the auth endpoints
   *  service:session - holds state for the current user authentication/authorization context
   *  authorizer:directory - Handles future request authorization
   */

  var _default = Service.extend({
    cookies: inject.service(),
    session: inject.service(),
    _restoreState: null,
    // Flag to indicate if we're in the logout/login bounce used to re-prime the oauth flow to Collaborate upon logout
    inLogoutBounce: false,
    init: function init() {
      this._super.apply(this, arguments);

      if (this._isInLogoutBounce()) {
        this.set('inLogoutBounce', true);

        this._clearLogoutTime();
      }
    },

    /**
     * Generates a string value that can be passed via the state parameter
     * to restore the current state post authentication
     */
    serializeRestoreState: function serializeRestoreState() {
      var baseLocation = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.location;
      var result = null;
      var searchString = baseLocation.search;
      var internalRoute = this.get('session.attemptedTransition.intent.url');

      if (searchString && _lodash.default.trim(searchString).length > 0) {
        result = (result || '') + searchString;
      }

      if (internalRoute && _lodash.default.trim(internalRoute).length > 0) {
        result = (result || '') + "#".concat(internalRoute);
      } // Make sure we don't redirect to login-oauth; should never be the case though


      return result && result.indexOf('login-oauth') === -1 ? result : null;
    },

    /**
     * Parse and store the state param for later use to restore the app to
     * pre-auth state.
     */
    deserializeRestoreState: function deserializeRestoreState(stateParamValue) {
      var baseLocation = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : window.location;
      var redirectUrl = null;
      var prunedStateParamValue = typeOf(stateParamValue) === 'string' ? stateParamValue.trim() : undefined;

      if (prunedStateParamValue) {
        var firstChar = prunedStateParamValue.charAt(0);

        if (firstChar === '?' || firstChar === '#') {
          // Rebuild pre-redirect state
          try {
            // ensure pathname ends with .html or / for s3 redirect
            var s3SafePathname = baseLocation.pathname;

            if (!s3SafePathname.endsWith('.html') && !s3SafePathname.endsWith('/')) {
              s3SafePathname += '/';
            }

            var parsedStateParam = new URL(prunedStateParamValue, 'https://example.com'); // if no search was provided via state, use the current
            // FUTURE: We could merge the query params if we wanted, but not currently applicable and probably overkill

            var finalSearch = parsedStateParam.search || baseLocation.search; // if no hash was provided via state, use the current

            var finalHash = parsedStateParam.hash || baseLocation.hash;
            redirectUrl = new URL("".concat(s3SafePathname).concat(finalSearch).concat(finalHash), baseLocation.origin || "".concat(baseLocation.protocol, "//").concat(baseLocation.host)).toString();
          } catch (e) {
            if (baseLocation) {
              // prunedStateParamValue or final url is invalid
              Logger.error('Failed to restore URL state after authentication', {
                error: e
              });
            }

            redirectUrl = null;
          }
        }
      }

      if (redirectUrl) {
        this.set('_restoreState', {
          url: redirectUrl
        });
      } else {
        this.set('_restoreState', null);
      }
    },

    /**
     * Pop the restore state object {url: 'url'}
     * to restore the app to pre-auth state
     */
    popRestoreState: function popRestoreState() {
      var result = this.get('_restoreState');
      this.set('_restoreState', null);
      return result;
    },

    /**
     * Persist the logout time in browser storage for logout bounce detection on
     * the next application load
     */
    recordLogoutTime: function recordLogoutTime() {
      var now = (0, _dates.default)();
      var cookieOptions = {
        expires: (0, _dates.default)(now).add(LOGOUT_BOUNCE_COOKIE_DURATION_MILLIS, 'milliseconds').toDate()
      };

      if (window && window.location && window.location.protocol === 'https:') {
        cookieOptions.secure = true;
      }

      if (navigator && navigator.userAgent && (0, _shouldSendSameSiteNone.isSameSiteNoneCompatible)(navigator.userAgent)) {
        cookieOptions.sameSite = 'none';
      }

      this.get('cookies').write(LOGOUT_BOUNCE_COOKIE_NAME, '' + now.valueOf(), cookieOptions);
    },
    _clearLogoutTime: function _clearLogoutTime() {
      this.get('cookies').clear(LOGOUT_BOUNCE_COOKIE_NAME);
    },
    _isInLogoutBounce: function _isInLogoutBounce() {
      var result = false;
      var now = (0, _dates.default)();
      var lastLogout = Number(this.get('cookies').read(LOGOUT_BOUNCE_COOKIE_NAME));

      if (lastLogout) {
        result = now.diff((0, _dates.default)(lastLogout)) <= LOGOUT_BOUNCE_THREASHOLD_MILLIS;
      }

      return result;
    }
  });

  _exports.default = _default;
});