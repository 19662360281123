define("web-directory/models/externalcontacts/whatsapp-id", ["exports", "ember-data", "web-directory/models/externalcontacts/channel"], function (_exports, _emberData, _channel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.WHATSAPP_TYPE = void 0;
  var WHATSAPP_TYPE = 'externalcontacts/whatsapp-id';
  _exports.WHATSAPP_TYPE = WHATSAPP_TYPE;

  var _default = _channel.default.extend({
    type: 'whatsapp',
    displayName: _emberData.default.attr('string'),
    phoneNumber: _emberData.default.attr() // includes e164: an e164-formatted phone number, and display: a display-friendly phone number

  });

  _exports.default = _default;
});