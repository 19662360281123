define("web-directory/components/agent-assist-summary-iframe/component", ["exports", "web-directory/mixins/acd-utilities", "web-directory/utils/acd"], function (_exports, _acdUtilities, _acd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend(_acdUtilities.default, {
    interaction: null,
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    summaryIframeSrc: computed('interaction.id', 'intl.isoLocale', function () {
      var locale = this.get('intl.isoLocale');
      var hostname = window.location.hostname === 'localhost' ? 'apps.inindca.com' : window.location.hostname;
      var interaction = this.get('interaction');
      var interactionType = (0, _acd.getCommunicationType)(interaction);
      var urlParams = new URLSearchParams({
        host: 'cx',
        locale: locale,
        iid: interaction.id,
        interactionType: interactionType,
        wrapUp: 'true',
        gcUsePopupAuth: !!this.get('session').get('popoutAuthForIframes')
      });
      return "https://".concat(hostname, "/smart-advisor-ui/#/summary?").concat(urlParams);
    })
  });

  _exports.default = _default;
});