define("web-directory/components/entity-fields/tag-field/tag-wrapper/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component;

  var _default = Component.extend({
    tagName: 'span',
    classNames: ['tag', 'tag-label', 'label-default'],
    classNameBindings: ['flash'],
    flash: false,
    timers: [],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var timer = Ember.run.scheduleOnce('afterRender', this, this.flashTag);
      this.timers.pushObject(timer);
    },
    willDestroyElement: function willDestroyElement() {
      this.timers.forEach(function (timer) {
        return Ember.run.cancel(timer);
      });
      this.timers = [];

      this._super.apply(this, arguments);
    },
    flashTag: function flashTag() {
      var _this = this;

      this.set('flash', true);
      var timer = Ember.run.later(this, function () {
        _this.set('flash', false);
      }, 500);
      this.timers.pushObject(timer);
    }
  });

  _exports.default = _default;
});