define("web-directory/components/entity-fields/date-field/component", ["exports", "lodash", "web-directory/utils/dates", "web-directory/mixins/field-component"], function (_exports, _lodash, _dates, _fieldComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      observer = Ember.observer,
      computed = Ember.computed;

  var _default = Ember.Component.extend(_fieldComponent.default, {
    intl: inject.service(),
    complexField: true,
    complexValueFields: ['entry.value.year', 'entry.value.month', 'entry.value.day'],
    actions: {
      selectDate: function selectDate(date) {
        if (!date) {
          this.set('entry.value', null);
        } else {
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();
          var value = {
            day: day,
            month: month,
            year: year
          };
          this.set('entry.value', value);
        }
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var timer = Ember.run.scheduleOnce('afterRender', this, 'ensurePopulatedField');
      this.timers.pushObject(timer);
    },
    populateFieldOnRender: observer('canEdit', function () {
      var timer = Ember.run.scheduleOnce('afterRender', this, 'ensurePopulatedField');
      this.timers.pushObject(timer);
    }),
    value: computed('entry.value', function () {
      var entry = this.get('entry');

      if (!this.isComplexFieldEmpty()) {
        var copy = Ember.copy(entry, true);
        copy.value.month -= 1;
        return (0, _dates.default)(copy.value).toDate();
      }

      return null;
    }),
    formattedValue: computed('value', function () {
      var date = this.get('value');

      if (!date) {
        return '';
      }

      return (0, _dates.default)(date).format('L');
    }),
    options: computed('intl.locale', function () {
      var localeData = _dates.default.localeData();

      return {
        i18n: {
          previousMonth: this.get('intl').t('chat.presence.outOfOffice.picker.previousMonth').toString(),
          nextMonth: this.get('intl').t('chat.presence.outOfOffice.picker.nextMonth').toString(),
          months: localeData._months,
          weekdays: localeData._weekdays,
          weekdaysShort: localeData._weekdaysShort
        }
      };
    }),
    dateFormat: computed('intl.iosLocale', function () {
      var locale = this.get('intl.isoLocale');

      if (locale === 'no') {
        // moment uses nb as its Norwegian locale
        locale = 'nb';
      }

      return _dates.default.localeData(locale)._longDateFormat.L;
    }),
    ensurePopulatedField: function ensurePopulatedField() {
      if (!this.get('editing')) {
        return;
      }

      var entry = this.get('entry');
      var entity = this.get('entity');
      var path = this.get('field.fieldPath');

      if (_lodash.default.isEmpty(entry)) {
        entity.set(path, entry);
      } else {
        entity.set(path, [entry], {
          overwrite: true
        });
      }

      if (_lodash.default.isEmpty(entry.value)) {
        var value = {
          year: null,
          month: null,
          day: null
        };
        Ember.set(entry, 'value', value);
      }

      this.set('entry', entity.getFieldValue(path));
    },
    isComplexFieldEmpty: function isComplexFieldEmpty() {
      var entry = this.get('entry');

      if (!entry || !entry.value) {
        return true;
      }

      return typeof entry.value.year !== 'number' && typeof entry.value.month !== 'number' && typeof entry.value.day !== 'number';
    }
  });

  _exports.default = _default;
});