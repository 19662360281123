define("web-directory/services/queue", ["exports", "ember-data", "lodash", "moment", "ember-purecloud-components/mixins/logger", "web-directory/utils/entity-fields"], function (_exports, _emberData, _lodash, _moment, _logger, _entityFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var QUEUE_ID_LIMIT = 100;
  var QUEUES_BRIEF_ID_LIMIT = 50;
  var BASE_URI = 'api/v2/routing/queues';
  var BRIEF_URI = 'api/v2/routing/queues/divisionviews';
  var USERS_URI = 'api/v2/users';
  var WRAPUP_URI = 'api/v2/routing/wrapupcodes';
  var OFF_QUEUE = 'OFF_QUEUE',
      ON_QUEUE = 'ON_QUEUE',
      INTERACTING = 'INTERACTING',
      COMMUNICATING = 'COMMUNICATING',
      NOT_RESPONDING = 'NOT_RESPONDING',
      IDLE = 'IDLE';

  var _default = Ember.Service.extend(_logger.default, {
    application: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    permissions: Ember.inject.service(),
    presence: Ember.inject.service(),
    preferences: Ember.inject.service(),
    setDefaultQueue: function setDefaultQueue(queue, type) {
      var _this = this;

      var promise = this.get('preferences').getPreference('interactions.defaultQueuesEnabled').then(function (enabled) {
        if (enabled && queue) {
          return Ember.RSVP.resolve(_this.get('preferences').setPreference("interactions.defaultQueue-".concat(type), queue.id));
        }

        return Ember.RSVP.resolve();
      });
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    },
    getDefaultQueue: function getDefaultQueue(type) {
      var _this2 = this;

      var promise = this.get('preferences').getPreference('interactions.defaultQueuesEnabled').then(function (enabled) {
        if (enabled) {
          //
          return _this2.get('preferences').getPreference("interactions.defaultQueue-".concat(type)).then(function (queueId) {
            if (queueId) {
              return _emberData.default.PromiseObject.create({
                promise: _this2.get('store').find('queue', queueId).then(function (q) {
                  q.data.id = queueId;
                  return _this2._validateDefaultQueue(q, 1);
                })
              });
            } else {
              return Ember.RSVP.resolve();
            }
          }); //
        } else {
          return Ember.RSVP.resolve();
        }
      });
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    },
    clearDefaultQueue: function clearDefaultQueue(type) {
      this.get('preferences').setPreference("interactions.defaultQueue-".concat(type), null);
    },
    _validateDefaultQueue: function _validateDefaultQueue(q, pageNumber) {
      var _this3 = this;

      var queueId = q.data.id;
      var parameters = Object.assign({}, {
        pageSize: 25,
        pageNumber: pageNumber
      });
      var uri = this.get('application').pcUri("".concat(BASE_URI, "/me"));
      return this.get('ajax').ajaxGet(uri, {
        data: parameters
      }).then(function (res) {
        var validQueues = !!res.entities > 0 ? res.entities.mapBy('id') : [];

        if (validQueues.indexOf(queueId) >= 0) {
          return Object.assign({}, q.data);
        } else if (res.pageNumber !== res.pageCount) {
          return _this3._validateDefaultQueue(q, res.pageNumber + 1);
        } else {
          return null;
        }
      });
    },
    getQueues: function getQueues(options) {
      var ids = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      var doExactNameSearch = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      return this._buildQueueRequests(options, ids, BASE_URI, QUEUE_ID_LIMIT, 'entities', doExactNameSearch);
    },
    // Returns brief queue objects regardless of access control
    // Takes 1 name string OR some queue IDs
    getQueuesBrief: function getQueuesBrief() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var ids = arguments.length > 1 ? arguments[1] : undefined;

      // We need the routing:queue:search permission
      // We need make sure that we have one of the types of search criteria
      // We need to make sure we do not have both of the types of search criteria
      if (!this.get('permissions').validateDonutPermission('routing:queue:search') || !_lodash.default.has(options, 'name') && !ids || _lodash.default.has(options, 'name') && ids && ids.length) {
        return Ember.RSVP.reject();
      }

      return this._buildQueueRequests(options, ids, BRIEF_URI, QUEUES_BRIEF_ID_LIMIT, 'entities');
    },
    _buildQueueRequests: function _buildQueueRequests(options, ids, uri, limit, resultsString) {
      var _this4 = this;

      var doExactNameSearch = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
      var parameters = Object.assign({}, {
        pageNumber: 1,
        pageSize: 25
      }, options);
      var nameSearchString;

      if (_lodash.default.has(parameters, 'name')) {
        nameSearchString = _lodash.default.trim(parameters.name);
      }

      var baseUri = this.get('application').pcUri(uri);
      var promises = [];
      var divisionIds = parameters.divisionIds; // Division IDs will be manually added, so remove it from the parameters object

      delete parameters.divisionIds;

      var buildUri = function buildUri(queueIds) {
        var isQueueIds = queueIds && queueIds.length;
        var isDivisionIds = divisionIds && divisionIds.length;
        var queryString = isQueueIds || isDivisionIds ? '?' : ''; // API does not allow querying for both queue IDs and division IDs, so only query for division IDs if no queue IDs specified

        if (isQueueIds) {
          queryString += "id=".concat(queueIds.join('&id='));
        } else if (isDivisionIds) {
          queryString += "divisionId=".concat(divisionIds.join('&divisionId='));
        }

        return "".concat(baseUri).concat(queryString);
      };

      if (nameSearchString) {
        if (doExactNameSearch) {
          // do an exact search as well
          parameters.name = nameSearchString;
          promises.push(this.get('ajax').ajaxGet(buildUri(), {
            data: parameters
          }));
        }

        parameters.name = "*".concat(nameSearchString, "*");
      }

      if (ids && ids.length) {
        // If we have too many queus, break it up
        if (ids.length > limit) {
          _lodash.default.chunk(ids, limit).forEach(function (chunk) {
            promises.push(_this4.get('ajax').ajaxGet(buildUri(chunk), {
              data: parameters
            }));
          });
        } else {
          // If we don't have too many, proceed as usual
          promises.push(this.get('ajax').ajaxGet(buildUri(ids), {
            data: parameters
          }));
        }
      } else {
        // if no IDs were passed in, don't add any id query params
        promises.push(this.get('ajax').ajaxGet(buildUri(), {
          data: parameters
        }));
      }

      return Ember.RSVP.allSettled(promises).then(function (results) {
        var data = {};
        data[resultsString] = [];

        if (results && results.length) {
          results.forEach(function (result) {
            if (result.state === 'fulfilled' && result.value && result.value[resultsString]) {
              var value = result.value;
              data[resultsString] = _lodash.default.uniq(data[resultsString].concat(result.value[resultsString]), function (queue) {
                return queue.id;
              }); // if we didn't search for a set of IDs, we are looking at a full list, so make sure we still track these values

              if (!(ids && ids.length)) {
                data.total = value.total;
                data.pageSize = value.pageSize;
                data.pageCount = value.pageCount;
                data.pageNumber = value.pageNumber;
              } else {
                // we have a selected set of queues, so set the total to the length of the entities array
                data.total = data[resultsString].length;
              }
            }
          });
        }

        return data;
      });
    },
    getQueue: function getQueue(queueId) {
      var uri = this.get('application').pcUri(BASE_URI + '/' + queueId);
      return this.get('ajax').ajaxGet(uri);
    },
    getQueueByUri: function getQueueByUri(uri) {
      return this.get('ajax').ajaxGet(uri);
    },
    getQueueEstimatedWaitTime: function getQueueEstimatedWaitTime(queueId) {
      var mediaType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'all';
      var uri = this.get('application').pcUri(BASE_URI) + '/' + queueId + '/mediatypes/' + mediaType + '/estimatedwaittime';
      return this.get('ajax').ajaxGet(uri);
    },
    getWrapupCodes: function getWrapupCodes(options, ids) {
      var parameters = Object.assign({}, {
        pageNumber: 1,
        pageSize: 25
      }, options);

      if (_lodash.default.has(options, 'name')) {
        parameters.name = '*' + _lodash.default.trim(parameters.name) + '*';
      }

      var uri = this.get('application').pcV2Uri(WRAPUP_URI);

      if (ids && ids.length) {
        uri = "".concat(uri, "?id=").concat(ids.join('&id='));
      }

      return this.get('ajax').ajaxGet(uri, {
        data: parameters
      });
    },
    getUsers: function getUsers(queueId, options) {
      var parameters = Object.assign({}, {
        pageSize: 25,
        pageNumber: 1,
        expand: 'routingStatus'
      }, options);

      if (_lodash.default.has(options, 'name')) {
        parameters.name = '*' + _lodash.default.trim(parameters.name) + '*';
      }

      var uri = this.get('application').pcUri(BASE_URI + '/' + queueId + '/users');
      return this.get('ajax').ajaxGet(uri, {
        data: parameters
      });
    },
    updateUsers: function updateUsers(queueId, users) {
      var uri = this.get('application').pcUri(BASE_URI + '/' + queueId + '/users');
      return this.get('ajax').patch(uri, {
        data: users
      });
    },
    updateUser: function updateUser(queueId, user) {
      var uri = this.get('application').pcUri(BASE_URI + '/' + queueId + '/users/' + user.id);
      return this.get('ajax').patch(uri, {
        data: user
      });
    },
    updateUserJoinedQueues: function updateUserJoinedQueues(userId, queues) {
      var uri = this.get('application').pcUri(USERS_URI + '/' + userId + '/queues');
      return this.get('ajax').patch(uri, {
        data: queues
      });
    },
    getAllUsers: function getAllUsers(options, divisions) {
      // not just queue
      var parameters = Object.assign({}, {
        pageNumber: 1,
        pageSize: 25
      }, options);
      var uri = this.get('application').pcUri(USERS_URI);

      if (divisions && divisions.length) {
        uri = "".concat(uri, "?divisionId=").concat(divisions.join('&divisionId='));
      }

      return this.get('ajax').ajaxGet(uri, {
        data: parameters
      });
    },
    // returns a user's queues regardless of divisions
    getMyQueues: function getMyQueues(options) {
      var parameters = Object.assign({}, {
        pageSize: 25,
        pageNumber: 1
      }, options);
      var uri = this.get('application').pcUri("".concat(BASE_URI, "/me"));
      return this.get('ajax').ajaxGet(uri, {
        data: parameters
      });
    },
    getUserQueues: function getUserQueues(userId, options) {
      var parameters = Object.assign({}, {
        pageSize: 25,
        pageNumber: 1
      }, options);
      var uri = this.get('application').pcUri("".concat(USERS_URI, "/").concat(userId, "/queues"));
      var divisionIds = parameters.divisionIds;

      if (divisionIds && divisionIds.length) {
        uri += "?divisionId=".concat(divisionIds.join('&divisionId='));
        delete parameters.divisionIds;
      }

      return this.get('ajax').ajaxGet(uri, {
        data: parameters
      });
    },
    // Computes an agent's status (string) and time in that status (milliseconds) based on routingStatus, presence, and outOfOffice
    // Takes agent as returned from /api/v2/routing/queues/{queueId}/users
    // Returns null if status could not be determined
    getAgentStatus: function getAgentStatus(agent) {
      var showSecondaryPresence = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var presenceOnly = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var routingStatusOnly = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

      function intlStatus(s) {
        var lookupKey = "chat.presence.".concat(s);

        if (intl.query(lookupKey)) {
          return intl.t(lookupKey);
        }

        return intl.t("chat.presence.".concat(s, ".title"));
      }

      var intl = this.get('intl');
      var now = (0, _moment.default)();
      var routingStatus;

      if (agent) {
        routingStatus = Ember.get(agent, 'routingStatus') || Ember.get(agent, 'user.routingStatus');
      } else {
        this.logger.error('[queue-service] In getAgentStatus, agent argument or the routingStatus property of that agent was null or undefined. Status could not be determined.', agent);
        return null;
      }

      if (!agent.user) {
        agent.user = {
          presence: agent.presence,
          outOfOffice: agent.outOfOffice
        };
      } // Time in milliseconds between status start and now


      function tis(start) {
        return start ? now.diff((0, _moment.default)(start)) : 0;
      }

      var presenceService = this.get('presence');
      var presenceDefinition = agent.user.presence ? presenceService.getPresenceDefinition(agent.user.presence.presenceDefinition) : '';
      var systemPresence = presenceDefinition ? presenceDefinition.systemPresence : ''; // System presence On Queue: Idle, Interacting, Not Responding, Off Queue
      // Otherwise: Out of Office (takes precedence over other presence values), Available, Busy, Away, Break, Meal, Meeting, Training

      if (systemPresence === ON_QUEUE && !presenceOnly || routingStatusOnly) {
        var result = {
          timeInStatus: tis(routingStatus.startTime)
        };
        result.enStatusKey = routingStatus.status;

        if (routingStatus.status === NOT_RESPONDING) {
          result.status = intlStatus('notResponding');
        } else if (routingStatus.status === INTERACTING) {
          result.status = intlStatus('interacting');
        } else if (routingStatus.status === COMMUNICATING) {
          result.status = intlStatus('communicating');
        } else if (routingStatus.status === IDLE) {
          result.status = intlStatus('idle');
        } else if (routingStatus.status === OFF_QUEUE) {
          result.status = intlStatus('offQueue');
        } else {
          return null;
        }

        return result;
      } else {
        if (agent.user.outOfOffice && agent.user.outOfOffice.active) {
          return {
            // Time in OOO cannot currently be computed accurately (UI-2517)
            enStatusKey: 'outOfOffice',
            status: intlStatus('outOfOffice'),
            timeInStatus: null
          };
        } else if (agent.user.presence) {
          var status = showSecondaryPresence ? presenceService.localizePresence(agent.user.presence.presenceDefinition) : presenceService.localizePresenceString(systemPresence.toLowerCase());
          return {
            status: status,
            timeInStatus: tis(agent.user.presence.modifiedDate)
          };
        }
      }

      return null;
    },
    // Returns the Person entities for the given list of agent IDs
    getAgentEntities: function getAgentEntities(ids) {
      return this.get('store').findMany('person', ids, _entityFields.FL.person);
    },
    // Given an agent and media type, returns the total number of interactions of that media type currently active or in ACW for the agent
    // Media type can be any key in the conversationSummary hash at the endpoint /api/v2/routing/queues/{queueId}/users (https://developer.mypurecloud.com/api/rest/v2/routing/index.html)
    // Returns null if count could not be determined
    getAgentInteractionCount: function getAgentInteractionCount(agent, mediaType) {
      if (!agent || !agent.user || !agent.user.conversationSummary) {
        this.logger.error('[queue-service] In getAgentInteractionCount, agent, agent.user, or agent.user.conversationSummary was null or undefined. No interaction count could be computed.', agent, mediaType);
        return null;
      }

      if (mediaType) {
        var summary = agent.user.conversationSummary[mediaType].contactCenter;
        return (summary.active || 0) + (summary.acw || 0);
      } else {
        // return count of all interactions
        var conversationSummary = agent.user.conversationSummary;
        return _lodash.default.sum(_lodash.default.map(Object.keys(conversationSummary), function (key) {
          var contactCenter = conversationSummary[key].contactCenter;
          var enterprise = conversationSummary[key].enterprise;
          return (contactCenter.active || 0) + (contactCenter.acw || 0) + (enterprise.active || 0) + (enterprise.acw || 0);
        }));
      }
    }
  });

  _exports.default = _default;
});