define("web-directory/components/agent-assist-summary-iframe/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BuS5xtsm",
    "block": "{\"statements\":[[11,\"iframe\",[]],[15,\"sandbox\",\"allow-same-origin allow-scripts allow-popups allow-forms\"],[16,\"title\",[34,[[33,[\"t\"],[\"purecloud.acdInteraction.summaryIframe\"],null]]]],[16,\"src\",[34,[[26,[\"summaryIframeSrc\"]]]]],[16,\"id\",[34,[\"agent-assist-summary-\",[28,[\"interaction\",\"id\"]]]]],[13],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/agent-assist-summary-iframe/template.hbs"
    }
  });

  _exports.default = _default;
});