define("web-directory/routes/interaction-detail-redirect", ["exports", "web-directory/utils/analytics-routing"], function (_exports, _analyticsRouting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    adminAgentSegment: 'admin',
    model: function model(params, transition) {
      var queryParams = transition.queryParams;
      var isAssigneeAndDisputesFTEnabled = this.get('session.features') && this.get('session.features')['qm.release.evaluation.assignee'] && this.get('session.features')['qm.release.evaluation.disputes'];

      if (!isAssigneeAndDisputesFTEnabled) {
        delete queryParams.assignmentPanel;
      }

      var url = (0, _analyticsRouting.buildInteractionDetailRedirectPath)({
        urlParams: params,
        queryParams: queryParams,
        adminAgentSegment: this.get('adminAgentSegment')
      });
      return this.replaceWith(url);
    }
  });

  _exports.default = _default;
});