define("web-directory/serializers/meeting", ["exports", "web-directory/serializers/purecloud"], function (_exports, _purecloud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _purecloud.default.extend({
    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      var data = this.serialize(snapshot, options);
      Object.assign(hash, data);
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      var type = primaryModelClass.modelName;

      var data = _defineProperty({}, type, payload.entities);

      return this._super(store, primaryModelClass, data, id, requestType);
    }
  });

  _exports.default = _default;
});