define("web-directory/services/admin-nav", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    adminui: Ember.inject.service(),
    engage: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    search: Ember.inject.service(),
    // list of object properties that identify engage navTree nodes which
    // should be displayed despite having no subLinks
    engageSingleLinkSections: [{
      textKey: 'admin.nav.architect'
    }, {
      fromPlaceholder: 'forecasting-component'
    }],
    // Combined admin and engage navs from normalized + flattened source data
    // (any engage multi-level structure is flattened, and admin entries are left in their already-flat default state)
    fullNav: Ember.computed('normalizedAdminNav', 'normalizedEngageNav', function () {
      var normalizedAdminNav = Ember.copy(this.get('normalizedAdminNav'), true) || [];
      var normalizedEngageNav = Ember.copy(this.get('normalizedEngageNav'), true) || [];
      return this.combineAdminAndEngageNavs(normalizedAdminNav, normalizedEngageNav);
    }),
    // Combined admin and engage navs from normalized + "unflattened" source data
    // (any engage multi-level structure is left intact, and admin entries are unflattened if they have tree metadata)
    fullNavTree: Ember.computed('adminui.adminEntries', 'engage.navTree', function () {
      var normalizedAdminNav = this.normalizeAdminNav(this.get('adminui.adminEntries'), false);
      var normalizedEngageNav = this.normalizeEngageNav(this.get('engage.navTree'), false);
      return this.combineAdminAndEngageNavs(normalizedAdminNav, normalizedEngageNav);
    }),
    // Combine the given normalized admin and engage nav structures, and return the resulting unified nav structure
    combineAdminAndEngageNavs: function combineAdminAndEngageNavs(adminNav, engageNav) {
      var normalizedAdminNav = Ember.copy(adminNav, true);
      var normalizedEngageNav = Ember.copy(engageNav, true); // combine nodes from engage navTree into nodes from admin menu

      normalizedAdminNav.map(function (adminNode) {
        var includeLinksFrom = adminNode.includeLinksFrom;

        if (Ember.isArray(includeLinksFrom)) {
          includeLinksFrom.forEach(function (desiredNode) {
            var engageNode = _lodash.default.find(normalizedEngageNav, desiredNode);

            if (engageNode) {
              // overrwrite engage links with admin links if replacements exist
              engageNode.subLinks.forEach(function (engageLink, engageIndex) {
                adminNode.subLinks.forEach(function (adminLink, adminIndex) {
                  if (adminLink.text === engageLink.text) {
                    engageNode.subLinks[engageIndex] = adminLink;
                    adminNode.subLinks.splice(adminIndex, 1);
                  }
                });
              });
              adminNode.subLinks = adminNode.subLinks.concat(engageNode.subLinks);

              _lodash.default.remove(normalizedEngageNav, function (engageNode) {
                return _lodash.default.isMatch(engageNode, desiredNode);
              });
            }
          });
        }
      });
      var combinedNav = normalizedAdminNav.concat(normalizedEngageNav); // omit empty nodes

      return combinedNav.filter(function (navItem) {
        return !_lodash.default.isEmpty(navItem.subLinks);
      });
    },
    normalizedAdminNav: Ember.computed('adminui.adminEntries', function () {
      return this.normalizeAdminNav(this.get('adminui.adminEntries'), true);
    }),
    normalizeAdminNav: function normalizeAdminNav(adminNav) {
      var _this = this;

      var flat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      if (_lodash.default.isEmpty(adminNav)) {
        return [];
      }

      var adminEntries = Ember.copy(adminNav, true);

      if (adminEntries && adminEntries.length) {
        // remove overview node from nav (not needed on dashboard & will never show in left nav)
        adminEntries = adminEntries.filter(function (adminEntry) {
          if (adminEntry.className === 'menu-overview') {
            return false;
          } else {
            return true;
          }
        }); // loop through entries to make other changes

        adminEntries = adminEntries.map(function (entry) {
          return _this.normalizeAdminEntry(entry, flat);
        });
      }

      return adminEntries;
    },
    normalizeAdminEntry: function normalizeAdminEntry(adminNavEntry) {
      var _this2 = this;

      var flat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      var adminEntry = _lodash.default.clone(adminNavEntry);

      if (Array.isArray(adminEntry.subLinks)) {
        adminEntry.subLinks = adminEntry.subLinks.filter(function (subLink) {
          // filter out contacts link unless legacy contacts is toggled for the org
          return subLink.className !== 'menu-contacts';
        });
        var links = adminEntry.subLinks;

        if (!flat && Array.isArray(adminEntry.tree) && adminEntry.tree.length) {
          links = adminEntry.tree;
        }

        adminEntry.subLinks = links.reduce(function (accumulator, subLink) {
          return accumulator.concat(_this2.normalizeAdminSubLink(subLink, flat));
        }, []);
      }

      return adminEntry;
    },
    normalizeAdminSubLink: function normalizeAdminSubLink(adminNavSubLink, flat) {
      var _this3 = this;

      var subLink = _lodash.default.clone(adminNavSubLink);

      if (Array.isArray(subLink.subLinks)) {
        if (flat) {
          return subLink.subLinks.flatMap(function (subSubLink) {
            return _this3.normalizeAdminSubLink(subSubLink, true);
          });
        } else {
          subLink.subLinks = subLink.subLinks.map(function (subLink) {
            return _this3.normalizeAdminSubLink(subLink, false);
          });
        }
      } // skip any links that are marked as "direct" since they are assumed to either be
      // outside of the #/admin hierarchy or are already prefixed as needed


      if (!subLink.direct) {
        // prefix urls for admin ember engine dashboard
        if (subLink.href) {
          // 'external' sublinks do not need to be prefixed, but do need the dashboardHref property set
          // Note: 'Architect' is the only admin subLink that has the external property turned on
          subLink.dashboardHref = subLink.external ? subLink.href : '#/admin/' + subLink.href;
        } // add linkTo property for navigation sub menu component


        subLink.linkTo = 'admin';
      }

      return subLink;
    },
    normalizedEngageNav: Ember.computed('engage.navTree', function () {
      return this.normalizeEngageNav(this.get('engage.navTree'), true);
    }),
    normalizeEngageNav: function normalizeEngageNav(engageNav, flat) {
      if (_lodash.default.isEmpty(engageNav)) {
        return [];
      }

      var navTree = Ember.copy(engageNav, true);
      var engageSingleLinkSections = this.get('engageSingleLinkSections');
      var intl = this.get('intl');

      if (navTree && navTree.length) {
        // recursive function to flatten nested subLinks
        // for pure-cloud-browser-app feature components with multi-level hierarchies
        var flattenSubLinks = function flattenSubLinks(subLinks) {
          return subLinks.reduce(function (subLinks, subLink) {
            if (subLink.subLinks) {
              return subLinks.concat(flattenSubLinks(subLink.subLinks));
            } else {
              return subLinks.concat(subLink);
            }
          }, []);
        };

        navTree.map(function (navTreeItem) {
          // reformat applicable single-link nodes so they'll be included in nav
          if (!navTreeItem.subLinks) {
            engageSingleLinkSections.forEach(function (singleLinkSection) {
              if (_lodash.default.isMatch(navTreeItem, singleLinkSection)) {
                navTreeItem.subLinks = [{
                  text: navTreeItem.text,
                  textKey: navTreeItem.textKey,
                  href: navTreeItem.href
                }];
              }
            });
          } // loop through entries to make other changes


          if (navTreeItem.subLinks && navTreeItem.subLinks.length) {
            // flatten subLinks
            if (flat) {
              navTreeItem.subLinks = flattenSubLinks(navTreeItem.subLinks);
            } // loop through subLinks to make changes


            navTreeItem.subLinks.map(function (subLink) {
              // prefix urls for admin ember engine dashboard
              if (subLink.href) {
                subLink.dashboardHref = subLink.href.replace(/^#/, '#/engage/');
              } // add linkTo property for navigation sub menu component


              subLink.linkTo = 'engage.embedded'; // override names of select links

              var overrideNameKey = 'admin.dashboard_link.' + subLink.textKey.replace(/\./g, '_').toLowerCase();

              if (intl.exists(overrideNameKey)) {
                subLink.text = intl.t(overrideNameKey);
              }
            });
          }
        });
      }

      return navTree;
    }
  });

  _exports.default = _default;
});