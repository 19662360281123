define("web-directory/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H70BluBK",
    "block": "{\"statements\":[[6,[\"application-view\"],null,[[\"openChatRoomWithVideo\",\"panelsOverrideObject\"],[\"openChatRoomWithVideo\",[28,[\"panelsOverrideObject\"]]]],{\"statements\":[[0,\"    \"],[1,[26,[\"outlet\"]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/templates/application.hbs"
    }
  });

  _exports.default = _default;
});