define("web-directory/components/region-chooser/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pLNlhNYp",
    "block": "{\"statements\":[[11,\"p\",[]],[13],[0,\"\\n    \"],[1,[33,[\"t\"],[\"components.region.choose\"],null],false],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[11,\"p\",[]],[15,\"class\",\"extra\"],[13],[0,\"\\n    \"],[1,[33,[\"t\"],[\"components.region.extraNote\"],null],false],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[6,[\"x-select\"],null,[[\"value\",\"classNames\",\"action\"],[[28,[\"desktopRegionId\"]],\"form-control\",[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"desktopRegionId\"]]],null]],null]]],{\"statements\":[[6,[\"each\"],[[28,[\"regions\"]]],null,{\"statements\":[[6,[\"component\"],[[28,[\"xs\",\"option\"]]],[[\"value\"],[[28,[\"region\",\"desktopId\"]]]],{\"statements\":[[0,\"            \"],[1,[28,[\"region\",\"label\"]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[\"region\"]},null]],\"locals\":[\"xs\"]},null],[0,\"\\n\"],[11,\"div\",[]],[15,\"class\",\"submit\"],[13],[0,\"\\n    \"],[11,\"button\",[]],[15,\"class\",\"btn btn-default btn-purecloud\"],[15,\"type\",\"button\"],[5,[\"action\"],[[28,[null]],[28,[\"chooseRegion\"]]]],[13],[0,\"\\n        \"],[1,[33,[\"t\"],[\"common.choose\"],null],false],[0,\"\\n    \"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/region-chooser/template.hbs"
    }
  });

  _exports.default = _default;
});