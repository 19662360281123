define("web-directory/components/modal-component/component", ["exports", "web-directory/components/modal-component/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    translucentOverlay: true,
    containerClassNames: ['modal-component', 'modal', 'in', 'fade'].join(' '),
    overlayClassNames: 'translucent',
    intl: Ember.inject.service(),
    headerText: '',
    confirmText: '',
    cancelText: '',
    cancel: null,
    confirm: null,
    showHeader: true,
    showFooter: true,
    wrapContent: true,
    enforceModality: false,
    extraClassNames: '',
    confirmDisabled: false,
    noCancel: false,
    actions: {
      sendClose: function sendClose() {
        this.send('sendCancel');
      },
      sendConfirm: function sendConfirm() {
        var _this = this;

        if (typeof this.get('confirm') === 'function') {
          var ret = this.get('confirm')();

          if (ret && ret.then) {
            this.set('loading', true);
            ret.then(function () {
              _this.set('loading', false);
            });
          }
        } else {
          this.sendAction('confirm');
        }
      },
      sendCancel: function sendCancel() {
        if (typeof this.attrs.cancel === 'function') {
          this.attrs.cancel();
        } else {
          this.sendAction('cancel');
        }
      },
      backdropDismiss: function backdropDismiss() {
        if (!this.get('enforceModality')) {
          this.send('sendClose');
        }
      },
      setAttribute: function setAttribute(key, value) {
        this.set(key, value);
      }
    },
    setText: Ember.on('init', function () {
      var intl = this.get('intl');

      var _this$getProperties = this.getProperties('headerText', 'confirmText', 'cancelText'),
          headerText = _this$getProperties.headerText,
          confirmText = _this$getProperties.confirmText,
          cancelText = _this$getProperties.cancelText;

      if (headerText === '') {
        headerText = intl.lookup('components.modal.header').toString();
      }

      if (confirmText === '') {
        confirmText = intl.lookup('common.confirm').toString();
      }

      if (cancelText === '') {
        cancelText = intl.lookup('common.cancel').toString();
      }

      this.setProperties({
        headerText: headerText,
        confirmText: confirmText,
        cancelText: cancelText
      });
    }),
    confirmAction: Ember.computed({
      get: function get() {
        return this.get('confirm');
      },
      set: function set(key, value) {
        if (typeof value === 'function') {
          this.set('confirm', value);
        }

        return value;
      }
    }),
    cancelAction: Ember.computed({
      get: function get() {
        return this.get('cancel');
      },
      set: function set(key, value) {
        if (typeof value === 'function') {
          this.set('cancel', value);
        }

        return value;
      }
    }),
    disableAction: Ember.computed({
      get: function get() {
        return this.setConfirmDisabled.bind(this);
      },
      set: function set() {
        return this.setConfirmDisabled.bind(this);
      }
    }),
    attachClassToBody: Ember.on('didInsertElement', function () {
      Ember.$('body').addClass('modal-open');
    }),
    removeClassFromBody: Ember.on('willDestroyElement', function () {
      Ember.$('body').removeClass('modal-open');
    }),
    setConfirmDisabled: function setConfirmDisabled(value) {
      return this.set('confirmDisabled', value);
    }
  });

  _exports.default = _default;
});