define("web-directory/utils/serial-processing-chain", ["exports", "web-directory/utils/types"], function (_exports, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.executeChain = executeChain;
  _exports.isChainStepFailure = isChainStepFailure;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function isSyncPromiseChainStep(toCheck) {
    return 'processSync' in toCheck;
  }

  function isChainStepFailure(toCheck) {
    if (!toCheck || _typeof(toCheck) !== 'object') {
      return false;
    }

    return (0, _types.hasKey)(toCheck, 'origReason') && (0, _types.hasKey)(toCheck, 'stepDesc') && typeof toCheck.stepDesc === 'string';
  }
  /**
   * Serially executes a chain of synchronous and/or asynchronous steps utilizing RSVP promises
   * to work correctly with the Ember runloop.
   *
   * Work will not be started (no promise creation) until the previous step completes successfully
   * The chain will hault and reject with a ChainStepFailure upon the first encountered step rejection or thrown error
   */


  function executeChain(chain) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        loggingConfig = _ref.loggingConfig;

    loggingConfig === null || loggingConfig === void 0 ? void 0 : loggingConfig.logger.debug(buildLogMsg('Chain begin', loggingConfig), {
      remoteData: {
        chainLength: chain.length
      }
    });
    return chain.reduce(function (acc, currChainStep, stepIndex) {
      var stepDesc = currChainStep.stepDesc;
      var stepProgress = "".concat(stepIndex + 1, " of ").concat(chain.length);
      return acc.then(function () {
        return Ember.RSVP.resolve().then(function () {
          loggingConfig === null || loggingConfig === void 0 ? void 0 : loggingConfig.logger.debug(buildLogMsg('Step begin', loggingConfig), {
            remoteData: {
              stepDesc: stepDesc,
              stepProgress: stepProgress
            }
          });

          if (isSyncPromiseChainStep(currChainStep)) {
            return currChainStep.processSync();
          } else {
            return currChainStep.process();
          }
        }).then(function () {
          loggingConfig === null || loggingConfig === void 0 ? void 0 : loggingConfig.logger.debug(buildLogMsg('Step success', loggingConfig), {
            remoteData: {
              stepDesc: stepDesc,
              stepProgress: stepProgress
            }
          });
          return;
        }).catch(function (reason) {
          loggingConfig === null || loggingConfig === void 0 ? void 0 : loggingConfig.logger.warn(buildLogMsg('Step failure', loggingConfig), {
            reason: reason,
            remoteData: {
              stepDesc: stepDesc,
              stepProgress: stepProgress
            }
          });
          return Ember.RSVP.reject({
            origReason: reason,
            stepDesc: stepDesc
          });
        });
      });
    }, Ember.RSVP.resolve()).then(function () {
      loggingConfig === null || loggingConfig === void 0 ? void 0 : loggingConfig.logger.debug(buildLogMsg('Chain success', loggingConfig), {
        remoteData: {
          chainLength: chain.length
        }
      });
    }).catch(function (reason) {
      var logPayload = {
        remoteData: {
          chainLength: chain.length
        }
      }; // Theoretically, we ensure reason will always be a ChainStepFailure, but .. promise rejection typings

      if (isChainStepFailure(reason)) {
        logPayload.origReason = reason.origReason;
        logPayload.remoteData.failedStep = reason.stepDesc;
      }

      loggingConfig === null || loggingConfig === void 0 ? void 0 : loggingConfig.logger.warn(buildLogMsg('Chain failure', loggingConfig), logPayload);
      return Ember.RSVP.reject(reason);
    });
  }

  function buildLogMsg(msg, loggingConfig) {
    return "".concat(loggingConfig !== null && loggingConfig !== void 0 && loggingConfig.logMsgPrefix ? loggingConfig.logMsgPrefix : '').concat(msg);
  }
});