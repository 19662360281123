define("web-directory/routes/relate/organizations-ui-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      var organizationId = transition.params[this.routeName].organization_id;
      this.get('sidebar').closePanel();
      return this.replaceWith("/contactsAndOrganizations/organization/".concat(organizationId));
    }
  });

  _exports.default = _default;
});