define("web-directory/mixins/phone-service/ring", ["exports", "ember-purecloud-components/mixins/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed,
      observer = Ember.observer;

  var _default = Ember.Mixin.create(_logger.default, {
    session: inject.service(),
    headset: inject.service(),
    voicemail: inject.service('acdvoicemail'),
    ring: inject.service(),
    ringerVolume: computed.alias('ring.ringerVolume'),
    callVolume: computed.alias('ring.callVolume'),
    ringing: computed.alias('ring._ringing'),
    shouldRing: true,
    getAlertRingTime: function getAlertRingTime(conversationToDisplayNotification) {
      // The amount of time to ring if we can't find any other
      // alerting timeout configuration.
      var alertTime = 30000;

      if (conversationToDisplayNotification.get('isAgentCall') && !!conversationToDisplayNotification.get('myself.alertingTimeoutMs')) {
        // Check for alerting timeout configure on the participant
        alertTime = conversationToDisplayNotification.get('myself.alertingTimeoutMs');
        return Ember.RSVP.resolve(alertTime);
      } else if (conversationToDisplayNotification.get('isGroupRing')) {
        // Check if the conversaton is part of a group ring
        // and user the group alerting configuration
        var groupRingId = conversationToDisplayNotification.get('groupRingId');
        return this.get('voicemail').getGroupAlertTime(groupRingId).then(function (data) {
          if (data && data.rotateCallsSecs) {
            return data.rotateCallsSecs * 1000;
          } else {
            return alertTime;
          }
        });
      } else {
        // Check to see if the user has a configured timeout for when a
        // conversation is transfer to voicemail
        var userId = this.get('session.user.id');
        return this.get('voicemail').getUserAlertTime(userId).then(function (data) {
          if (data && data.alertTimeoutSeconds) {
            return data.alertTimeoutSeconds * 1000;
          } else {
            return alertTime;
          }
        });
      }
    },
    debuggerLog: function debuggerLog(namespace, message) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (this.get('session.features.acdDebugRinging')) {
        this.logger.debug("".concat(namespace, ": ").concat(message), options);
      }
    },
    _soundIncomingConversationRing: observer('hasIncomingConversations', 'session.features.acdDebugRinging', 'session.features.acdAlerting', function () {
      var _this = this;

      this.set('shouldRing', true);

      if (this.get('session.features.acdAlerting')) {
        return true;
      }

      this.debuggerLog('SERVOPS-33410', 'incoming observer change');

      if (this.get('presence') === 'busy') {
        this.debuggerLog('SERVOPS-33410', 'presence is busy');
        return;
      }

      var loggedInUser = this.get('session.publicApiUser');

      if (this.get('hasIncomingConversations')) {
        this.debuggerLog('SERVOPS-33410', 'hasIncomingConversations');
        var conversationToDisplayNotification = this.get('incomingConversations.lastObject');
        var conversationParticipants = Ember.get(conversationToDisplayNotification, 'participants') || [];
        var iAmAgent = Ember.get(conversationToDisplayNotification, 'isAgentCall');
        var connectedQueue = !!conversationParticipants.filter(function (p) {
          return p.purpose === 'acd' && !p.endTime;
        }).length;

        if (iAmAgent && connectedQueue && loggedInUser.acdAutoAnswer) {
          this.debuggerLog('SERVOPS-33410', 'Auto Answer Enabled');
          return;
        }

        if (!this.get('isOnConnectedCall')) {
          this.debuggerLog('SERVOPS-33410', 'isOnConnectedCall is false');
          var alertTime = 12000;
          this.getAlertRingTime(conversationToDisplayNotification).then(function (newAlertTime) {
            _this.debuggerLog('SERVOPS-33410', 'Fetched alert time', {
              newAlertTime: newAlertTime
            });

            alertTime = newAlertTime;
          }).catch(function (error) {
            _this.debuggerLog('SERVOPS-33410', 'Error retrieving voicemail alert timeout', {
              error: error
            });

            _this.logger.info('phone:_soundIncomingConversationRing: error retrieving voicemail alert time.', error);
          }).finally(function () {
            _this.debuggerLog('SERVOPS-33410', 'Handing off to ring service');

            if (!_this.get('shouldRing')) {
              return;
            }

            _this.get('ring').playRingtone(conversationToDisplayNotification.get('id'), false, alertTime);

            if (!Ember.get(conversationToDisplayNotification, 'hasDisplayedDesktopNotification')) {
              Ember.set(conversationToDisplayNotification, 'hasDisplayedDesktopNotification', true);
              return _this.displayIncomingNotification(conversationToDisplayNotification);
            }
          });
        } else {
          this.debuggerLog('SERVOPS-33410', 'Avoided ringing due to existing call');
          this.logger.debug('phone:_soundIncomingConversationRing: avoiding ring; already on a phone call');

          if (!Ember.get(conversationToDisplayNotification, 'hasDisplayedDesktopNotification')) {
            Ember.set(conversationToDisplayNotification, 'hasDisplayedDesktopNotification', true);
            return this.displayIncomingNotification(conversationToDisplayNotification);
          }
        }
      } else {
        this.set('shouldRing', false);
        this.debuggerLog('SERVOPS-33410', 'Stop ringing');
        this.get('ring').stopRingtone();
      }
    }),
    _answeredSoundIncomingConversationRing: function _answeredSoundIncomingConversationRing() {
      this.logger.debug('phone:_soundIncomingConversationRing: stop ringing (answered)');
      this.get('ring').stopRingtone();
    }
  });

  _exports.default = _default;
});