define("web-directory/mixins/batch-data-requests", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var BatchDataRequestsMixin = Ember.Mixin.create({
    batchDataRequests: Ember.inject.service(),
    getUser: function getUser(id) {
      return this.get('batchDataRequests').getUser(id);
    },
    refreshUser: function refreshUser(id) {
      return this.get('batchDataRequests').refreshUser(id);
    },
    getChatUser: function getChatUser(jid) {
      return this.get('batchDataRequests').getChatUser(jid);
    },
    getGroup: function getGroup(jid) {
      return this.get('batchDataRequests').getGroup(jid);
    }
  });
  var _default = BatchDataRequestsMixin;
  _exports.default = _default;
});