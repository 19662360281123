define("web-directory/components/dial-pad-number/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var on = Ember.on,
      computed = Ember.computed,
      Component = Ember.Component,
      inject = Ember.inject,
      observer = Ember.observer;
  var LETTER_MAPPINGS = ['+', null, 'ABC', 'DEF', 'GHI', 'JKL', 'MNO', 'PQRS', 'TUV', 'WXYZ'];
  var DTMF_VOLUME_PERCENTAGE = 0.5;

  var _default = Component.extend({
    tagName: 'button',
    classNames: ['dialpad-number'],
    classNameBindings: ['hasLetters::no-letters', 'isNumber::no-number', 'isPressed:pressed'],
    sound: inject.service(),
    phone: Ember.inject.service(),
    session: Ember.inject.service(),
    callVolume: Ember.computed.alias('phone.callVolume'),
    number: null,
    soundId: 'softphone',
    isPressed: false,
    attachSoundEvents: on('didInsertElement', function () {
      var _this = this;

      this.$().on('click', function () {
        _this._triggerSound();
      });
    }),
    removeSoundEvents: on('willDestroyElement', function () {
      this.$().off();
    }),
    letters: computed('number', function () {
      return LETTER_MAPPINGS[this.get('number')];
    }),
    hasLetters: computed('letters', function () {
      return !_lodash.default.isEmpty(this.get('letters'));
    }),
    isNumber: computed('number', function () {
      var number = parseInt(this.get('number'), 10);
      return !isNaN(number);
    }),
    class: computed('number', function () {
      switch (this.get('number')) {
        case '*':
          return 'asterisk';

        case '#':
          return 'pound';

        default:
          return "digit digit-".concat(this.get('number'));
      }
    }),
    pressedObserver: observer('isPressed', function () {
      // PCUCC-89
      if (this.get('isPressed')) {
        Ember.run.once(this, function () {
          this._triggerSound();
        });
      }
    }),
    _triggerSound: function _triggerSound() {
      var _this2 = this;

      Ember.run(function () {
        var soundId = _this2.get('soundId');

        var decimalVolume = _this2.get('callVolume') * DTMF_VOLUME_PERCENTAGE / 100;

        _this2.get('sound').playSound(soundId, decimalVolume);
      });
    }
  });

  _exports.default = _default;
});