define("web-directory/components/common-banner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0b5X1BRh",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"icon\"]]],null,{\"statements\":[[0,\"    \"],[9,\"gux-icon\",{\"attrs\":[[15,\"class\",\"common-banner__icon\"],[16,\"icon-name\",[26,[\"icon\"]],null],[15,\"decorative\",\"true\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\n\"],[6,[\"if\"],[[28,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[11,\"p\",[]],[15,\"class\",\"common-banner__label\"],[13],[0,\"\\n        \"],[1,[26,[\"label\"]],false],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\n\"],[11,\"p\",[]],[15,\"class\",\"common-banner__text\"],[13],[0,\"\\n    \"],[18,\"default\"],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/common-banner/template.hbs"
    }
  });

  _exports.default = _default;
});