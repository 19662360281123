define("web-directory/components/email-conversation/message/component", ["exports", "lodash/last", "web-directory/utils/conversation", "web-directory/utils/acd"], function (_exports, _last, _conversation, _acd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Take a contentType string from an email attachment and turn it into a readable format if needed
   */
  var convertTypeToReadableString = function convertTypeToReadableString(contentType) {
    var convertTypeList = [{
      displayName: 'doc',
      option: /wordprocessing/g
    }, {
      displayName: 'ppt',
      option: /presentation/g
    }];
    var content = convertTypeList.find(function (type) {
      return contentType.match(type.option);
    });
    return content ? content.displayName : null;
  };

  var _default = Ember.Component.extend({
    classNames: ['email-message'],
    classNameBindings: ['isOpen:is-open', 'collapsible:collapsible'],
    showImages: false,
    intl: Ember.inject.service(),
    logger: Ember.inject.service('stashLogger'),
    message: null,
    collapsible: false,

    /**
     * @attribute
     * `true` if the corresponding feature toggle is enabled for this user/organization
     * in the parent component.
     */
    acdEmailEnhancementsPhase1: null,
    acdEmailPreviewPerformance: false,
    isFetchingMessage: false,
    htmlBody: Ember.computed.alias('message.htmlBody'),
    isTwelveHour: Ember.computed('intl.locale', function () {
      return this.get('intl').isLocale12Hour();
    }),
    messagePreview: Ember.computed('message.{textBody,textBodyPreview}', 'acdEmailPreviewPerformance', function () {
      if (this.get('acdEmailPreviewPerformance')) {
        return this.get('message.textBodyPreview') || '';
      }

      var chars = 100;
      var text = this.get('message.textBody');

      if (text) {
        this.get('message.attachments').forEach(function (att) {
          if (att.inlineImage) {
            text = text.replace("[cid:".concat(att.attachmentId, "]"), "[image: ".concat(att.name, "]"));
          }
        });
        return text.substring(0, chars);
      }

      return '';
    }),
    hasBccRecipients: Ember.computed.notEmpty('message.bcc'),
    hasCcRecipients: Ember.computed.notEmpty('message.cc'),
    hasAttachment: Ember.computed.notEmpty('message.attachments'),
    attachments: Ember.computed('message.attachments.[]', function () {
      var attachments = this.get('message.attachments') || [];

      if (this.get('acdEmailEnhancementsPhase1')) {
        attachments.forEach(function (attachment) {
          Ember.set(attachment, 'type', convertTypeToReadableString(attachment.contentType) || (0, _last.default)(attachment.contentType.split('/')));
          Ember.set(attachment, 'size', (0, _conversation.formatSize)(attachment.contentLength));
        });
      } else {
        attachments.forEach(function (attachment) {
          return Ember.set(attachment, 'displayType', convertTypeToReadableString(attachment.contentType) || (0, _last.default)(attachment.contentType.split('/')));
        });
      } // make sure all attachments have a contentType


      return attachments;
    }),
    attachedMedia: Ember.computed('attachments.[]', 'inlineMedia.[]', function () {
      var attachments = this.get('attachments') || [];
      var inlineMedia = this.get('inlineMedia');
      var inlineIds = inlineMedia.map(function (m) {
        return m.attachmentId;
      });
      return attachments.filter(function (attachment) {
        return !inlineIds.includes(attachment.attachmentId);
      });
    }),
    inlineMedia: Ember.computed('attachments.[]', 'htmlBody', function () {
      var attachments = this.get('attachments') || [];
      var htmlBody = this.get('htmlBody'); // no html body to parse = no inline attachments

      if (!htmlBody) {
        return [];
      }

      var $renderContainer = $('<textarea/>').html(htmlBody);
      var renderText = $renderContainer.text(); // inline media have a 'cid:' prefix in the message body

      return attachments.filter(function (attachment) {
        return renderText.indexOf("cid:".concat(attachment.attachmentId)) !== -1;
      });
    }),
    isLoading: Ember.computed.not('message'),
    isCollapsed: true,
    isOpen: Ember.computed('collapsible', 'isCollapsed', function () {
      if (!this.get('collapsible')) {
        return true;
      }

      return !this.get('isCollapsed');
    }),

    /**
     * @Action
     * Closure action passed to the component to be invoked at the parent level
     */
    toggleCollapseEmail: function toggleCollapseEmail(message) {
      (0, _acd.warnClosureAction)('toggleCollapseEmail', message);
      return Ember.RSVP.resolve();
    },
    actions: {
      toggleOpen: function toggleOpen() {
        var _this = this;

        if (this.get('acdEmailPreviewPerformance')) {
          if (this.get('isFetchingMessage')) {
            return;
          }

          if (this.get('isCollapsed')) {
            this.set('isFetchingMessage', true);
            this.toggleCollapseEmail(this.get('message')).finally(function () {
              _this.set('isFetchingMessage', false);

              if (_this.get('collapsible')) {
                _this.toggleProperty('isCollapsed');
              }
            });
            return;
          }
        }

        if (this.get('collapsible')) {
          this.toggleProperty('isCollapsed');
        }
      },
      openAttachment: function openAttachment(contentUri) {
        window.open(contentUri);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this2 = this;

      this._super.apply(this, arguments);

      if (this.get('message') && this.get('acdEmailPreviewPerformance')) {
        if (!this.get('htmlBody') && !this.get('isCollapsed') && this.toggleCollapseEmail) {
          this.toggleCollapseEmail(this.get('message')).finally(function () {
            _this2.set('isFetchingMessage', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});