define("web-directory/adapters/location", ["exports", "ember-data", "web-directory/models/location"], function (_exports, _emberData, _location) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;
  var RESTAdapter = _emberData.default.RESTAdapter;

  var _default = RESTAdapter.extend({
    session: inject.service(),
    application: inject.service(),
    host: computed.reads('application.purecloudV2BaseUri'),
    namespace: 'api/v2',
    buildQuery: function buildQuery() {
      return {
        expand: _location.BASE_LOCATION_FIELDS.join(',')
      };
    },
    baseUrl: function baseUrl(id) {
      return "".concat(this.get('host'), "/").concat(this.get('namespace'), "/locations/").concat(id);
    },
    urlForUpdateRecord: function urlForUpdateRecord(id) {
      return this.baseUrl(id);
    },
    urlForFindRecord: function urlForFindRecord(id) {
      // the id provided should be the the user guid
      return this.baseUrl(id);
    },
    urlForHasMany: function urlForHasMany(id) {
      return "".concat(this.baseUrl(id), "/sublocations");
    },
    // Need to overwrite the private function to load activities we need
    findRecord: function findRecord(store, type, id, snapshot) {
      var url = this.buildURL(type.modelName, id, snapshot, 'findRecord');
      var query = this.buildQuery(snapshot);
      return Ember.RSVP.hash({
        location: this.ajax(url, 'GET', {
          data: query
        }),
        floors: this.ajax(this.urlForHasMany(id), 'GET', {
          data: query
        }).catch(function () {
          return Ember.RSVP.resolve({});
        })
      });
    },
    // Need to overwrite so that we can provide the version on all updates
    // and make sure data isnt key'd by type
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      store.serializerFor(type.modelName).serializeIntoHash(data, type, snapshot);

      if (data[type.modelName]) {
        data = data[type.modelName];
      }

      Ember.set(data, 'version', snapshot.attr('version'));
      return this.ajax(this.urlForUpdateRecord(snapshot.id, type.modelName), 'PUT', {
        data: data
      });
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      if (status < 400) {
        return this._super.apply(this, arguments);
      }

      payload.errors = [{
        status: status,
        title: payload.message,
        details: payload
      }];
      return this._super(status, headers, payload, requestData);
    }
  });

  _exports.default = _default;
});