define("web-directory/components/acd-interactions-panel/interaction-sms/sms-message-textarea/mixins/dropzone", ["exports", "lodash", "web-directory/utils/instagram/instagram-properties", "web-directory/utils/sms/sms-properties", "web-directory/utils/twitter/twitter-properties", "web-directory/utils/webmessaging/webmessaging-properties"], function (_exports, _lodash, _instagramProperties, _smsProperties, _twitterProperties, _webmessagingProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var on = Ember.on,
      computed = Ember.computed,
      run = Ember.run,
      inject = Ember.inject,
      Mixin = Ember.Mixin;
  var dropzonePreviewTemplate = "\n<div class=\"dz-preview dz-file-preview\">\n    <span class=\"delete-thumbnail\">\n        <gux-icon icon-name=\"close-circle\" decorative=\"true\"></gux-icon>\n    </span>\n    <div class=\"dz-details\">\n        <gux-icon icon-name=\"file-text\" decorative=\"true\"></gux-icon>\n        <img data-dz-thumbnail />\n    </div>\n    <i class=\"pc-moon pc-loading pc-spin hide\"></i>\n</div>\n";
  var ATTACHMENT_URL = '/';
  var MAX_FILES = 1;
  var MB = 1048576; //in Bytes
  // PLEASE NOTE:
  // This mixin should only be consumed by sms-message-textarea
  //

  var _default = Mixin.create({
    dropzone: null,
    dropzoneFile: null,
    fileUploading: null,
    hasPendingFile: false,
    notification: inject.service(),
    intl: inject.service(),
    session: inject.service(),
    acdsms: inject.service(),
    application: inject.service(),
    isSMS: computed.not('interaction.isThirdPartyMessenger'),
    setupOnInsert: on('didInsertElement', function () {
      run.scheduleOnce('afterRender', this, this.setupDropzone);
    }),
    cleanupOnDestroy: on('didDestroyElement', function () {
      this.destroyDropzone();
    }),
    dropzoneTarget: '/',
    maxFileSize: computed('interaction.subMessageType', 'acdsms.maxAttachmentFileSize', function () {
      var subMessageType = this.get('interaction.subMessageType');
      var size; //SMS is limited to 5MB!

      if (this.get('isSMS')) {
        size = _smsProperties.default.FILE_SIZE;
      } else if (subMessageType === _twitterProperties.default.MESSAGE_TYPE) {
        //Twitter 3MB
        size = _twitterProperties.default.FILE_SIZE;
      } else if (subMessageType === 'webmessaging') {
        size = _webmessagingProperties.default.FILE_SIZE;
      } else if (subMessageType === 'instagram') {
        //Instagram 10 MB - May change as limit is uncertain for instagram
        size = _instagramProperties.default.FILE_SIZE;
      } else {
        size = this.get('acdsms.maxAttachmentFileSize');
      }

      if (this.get('dropzone')) {
        this.set('dropzone.options.maxFilesize', size);
      }

      return size;
    }),
    maxFileSizeInBytes: computed('maxFileSize', function () {
      return this.get('maxFileSize') * MB;
    }),
    purgeUnsentFilesOnSwitch: function purgeUnsentFilesOnSwitch() {
      if (this.get('fileUploading')) {
        return;
      }

      var dropzone = this.get('dropzone');

      while (dropzone && dropzone.files.length > 0) {
        dropzone.removeFile(dropzone.files[0]);
      }
    },
    addNewFiles: function addNewFiles(files) {
      var dropzone = this.get('dropzone');

      _lodash.default.forEach(files, function (file) {
        return dropzone.addFile(file);
      });
    },
    addThumbnail: function addThumbnail() {
      var _this = this;

      this.set('hasPendingFile', true);
      this.$('.delete-thumbnail').on('click', Ember.run.bind(this, function () {
        if (_this.get('sendingAttachmentMessage.state')) {
          return;
        }

        _this.removeThumbnail();

        _this.get('dropzone').removeAllFiles();
      }));
    },
    removeThumbnail: function removeThumbnail() {
      this.set('hasPendingFile', false);
    },
    addFile: function addFile(event) {
      var _this2 = this;

      var dropzone = this.get('dropzone');
      var length = dropzone.files.length;

      if (event.size < this.get('maxFileSizeInBytes')) {
        if (length > 1) {
          dropzone.removeFile(dropzone.files[0]);
        }

        this.set('dropzoneFile', event.name);
        this.addThumbnail();
        this.focusTextarea();
        run.scheduleOnce('afterRender', function () {
          if (!_lodash.default.includes(event.type, 'image')) {
            var prefix = _this2.get('application.urlprefix');

            var img = _this2.$('.input-group-preview img');

            img.attr('class', 'generic-file-image');
            img.attr('src', "".concat(prefix, "assets/images/icons/png/file-text.png"));
          }
        });

        if (dropzone.files.length > 0) {
          dropzone.files[0].interactionId = this.get('interaction.id');
        }
      } else {
        dropzone.removeFile(dropzone.files[length - 1]);
        this.get('notification').show({
          title: this.get('intl').t('purecloud.acdInteraction.sms.errors.fileSizeExceededTitle'),
          message: this.get('intl').t('purecloud.acdInteraction.sms.errors.fileSizeExceeded', {
            fileSize: this.get('maxFileSize')
          }),
          hideAfter: 8
        });
      }
    },
    setupDropzone: function setupDropzone() {
      var _this3 = this;

      if (this.get('dropzone') || !this.get('interaction.allowedOutboundMedia.length') > 0) {
        return;
      } // TODO: This is a fragile way to attach dropdown to the whole conversation
      // and precludes having file uploads in the 'inline-response' flow.


      var target = this.$().parent('.conversation-pane').get(0) || this.$().get(0);
      var allowedOutboundMedia = 'image/*';

      if (this.get('interaction.allowedOutboundMedia.length')) {
        allowedOutboundMedia = this.get('interaction.allowedOutboundMedia').join(',');
      }

      var dropzone = new window.Dropzone(target, {
        url: ATTACHMENT_URL,
        clickable: "#".concat(target.id, " .upload-document-messages"),
        maxFilesize: this.get('maxFileSize'),
        maxFiles: MAX_FILES,
        autoProcessQueue: false,
        previewsContainer: this.$('.input-group-preview')[0],
        previewTemplate: dropzonePreviewTemplate,
        acceptedFiles: allowedOutboundMedia,
        createImageThumbnails: true,
        headers: {
          Authorization: "bearer ".concat(this.get('session.accessToken')),
          'Cache-Control': '',
          'X-Requested-With': ''
        }
      });
      dropzone.on('addedfile', function (event) {
        Ember.run(function () {
          return _this3.addFile(event);
        });
      });
      dropzone.on('removedfile', function () {
        Ember.run(function () {
          return _this3.set('hasPendingFile', false);
        });
      });
      dropzone.on('canceled', function () {
        _this3.set('acdsms.sendingAttachmentMessage', {
          interactionId: null,
          state: false
        });
      });
      dropzone.on('complete', function (file) {
        _this3.set('fileUploading', false);

        _this3.$('.upload-document-messages').removeAttr('disabled');

        var fileHasError = !_lodash.default.isEmpty(file.errorData);

        if (!fileHasError) {
          _this3.get('dropzone').removeAllFiles();
        }

        _this3.get('acdsms').trigger('scrollToBottom', _this3.get('interaction.id'));

        _this3.focusTextarea();

        _this3.get('acdsms').trigger('endSendAttachment', {
          interactionId: _this3.get('dropzone.files.firstObject.interactionId')
        });
      });
      this.set('dropzone', dropzone);
    },
    destroyDropzone: function destroyDropzone() {
      var dropzone = this.get('dropzone');

      if (dropzone) {
        dropzone.destroy();
      }
    }
  });

  _exports.default = _default;
});