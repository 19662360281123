define("web-directory/components/acd-interactions-panel/interaction-session-share/session-share-dropdown/component", ["exports", "popper.js"], function (_exports, _popper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MediaRequestResponseState = void 0;
  var $ = Ember.$;
  var MediaRequestResponseState;
  _exports.MediaRequestResponseState = MediaRequestResponseState;

  (function (MediaRequestResponseState) {
    MediaRequestResponseState[MediaRequestResponseState["PENDING"] = 0] = "PENDING";
    MediaRequestResponseState[MediaRequestResponseState["ACCEPTED"] = 1] = "ACCEPTED";
    MediaRequestResponseState[MediaRequestResponseState["DECLINED"] = 2] = "DECLINED";
    MediaRequestResponseState[MediaRequestResponseState["TIMEDOUT"] = 3] = "TIMEDOUT";
    MediaRequestResponseState[MediaRequestResponseState["CANCELLED"] = 4] = "CANCELLED";
    MediaRequestResponseState[MediaRequestResponseState["ERRORED"] = 5] = "ERRORED";
  })(MediaRequestResponseState || (_exports.MediaRequestResponseState = MediaRequestResponseState = {}));

  var _default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['session-share-dropdown'],
    classNameBindings: ['displayDropdown:display-dropdown:hide'],
    permissions: Ember.inject.service('permissions'),
    intl: Ember.inject.service('intl'),
    attachTo: null,
    attachClickHandler: false,
    interaction: null,
    _displayDropdown: false,
    _popper: null,
    _targetEl: null,
    actions: {
      hide: function hide() {
        this.set('_displayDropdown', false);
      },
      // Default implementation is to just run the provider action
      selectProvider: function selectProvider(provider) {
        Ember.run.once(provider, provider.action, {
          sendNotification: false
        });
        this.send('hide');
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.schedule('afterRender', this, this.setup);
    },
    willDestroyElement: function willDestroyElement() {
      if (this._popper) {
        this._popper.destroy();

        this._popper = null;
      }

      var targetEl = this._targetEl;
      var ev = "click.".concat(this.elementId);
      $(window).off(ev);

      if (targetEl) {
        Ember.$(targetEl).off(ev);
      }

      this._super.apply(this, arguments);
    },
    setup: function setup() {
      var _this = this;

      var targetEl = document.querySelector(this.get('attachTo'));

      if (!targetEl) {
        // Let's wait for a bit and check again...
        // This might happen when we are waiting on asynchronous helpers
        Ember.run.later(function () {
          return _this.setup();
        }, 500);
        return;
      }

      if (this._targetEl) {
        // We've already setup, dont try again
        return;
      }

      this._targetEl = targetEl;
      var popoverEl = this.element;
      this._popper = new _popper.default(targetEl, popoverEl, {
        placement: 'bottom',
        modifiers: {
          flip: {
            behavior: ['bottom', 'top', 'left']
          },
          preventOverflow: {
            padding: 1
          }
        }
      });
      $(targetEl).on("click.".concat(this.elementId), Ember.run.bind(this, function () {
        Ember.run.once(_this, _this.handleClick);
      }));
      $(window).on("click.".concat(this.elementId), Ember.run.bind(this, this.handleOutsideClick));
    },
    handleClick: function handleClick() {
      var _this2 = this;

      this.toggleProperty('_displayDropdown');
      Ember.run.join(function () {
        return Ember.run.scheduleOnce('afterRender', _this2, function () {
          return _this2._popper && _this2._popper.scheduleUpdate();
        });
      });
    },
    handleOutsideClick: function handleOutsideClick(event) {
      if (!this.get('displayDropdown')) {
        return;
      }

      var target = event.target;
      var isAttachTarget = $(this.attachTo).is(target);
      var componentParents = $(target).parent(this.elementId);
      var attachTargetParents = $(target).parents(this.attachTo);

      if (!isAttachTarget && componentParents.length === 0 && attachTargetParents.length === 0) {
        this.send('hide');
      }
    },
    beginCobrowse: function beginCobrowse() {
      console.error('Parent needs to Supply Cobrowse Start Action');
    },
    beginScreenShare: function beginScreenShare() {
      console.error('Parent needs to Supply ScreenShare Start Action');
    },
    providers: Ember.computed('interaction', function () {
      return [{
        title: this.get('intl').t('purecloud.acdInteraction.cobrowseNext.startCobrowse'),
        id: 'cobrowse',
        action: this.beginCobrowse
      }, {
        title: this.get('intl').t('purecloud.acdInteraction.sessionShare.start'),
        id: 'session-share',
        action: this.beginScreenShare
      }];
    }),
    displayDropdown: Ember.computed('_displayDropdown', 'permissions.canViewAcdCoBrowseV2ForVoice', 'permissions.canViewAcdScreenShare', 'interaction.type', function () {
      if (this.get('_displayDropdown')) {
        if (this.get('interaction.type') === 'message') {
          this.set('_displayDropdown', false);
          Ember.run.once(this.get('providers')[0], this.beginCobrowse, {
            sendNotification: true
          });
          return this.get('_displayDropdown');
        }

        if (!this.get('permissions.canViewAcdScreenShare') && this.get('permissions.canViewAcdCoBrowseV2ForVoice')) {
          this.set('_displayDropdown', false);
          Ember.run.once(this.get('providers')[0], this.beginCobrowse, {
            sendNotification: true
          });
          return this.get('_displayDropdown');
        }

        var _targetEl = this._targetEl;

        if (!_targetEl) {
          return false;
        }

        return this.get('_displayDropdown');
      }

      var targetEl = this._targetEl;

      if (!targetEl) {
        return false;
      }

      return this.get('_displayDropdown');
    })
  });

  _exports.default = _default;
});