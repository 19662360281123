define("web-directory/components/inbox-panel-v2/delete-confirmation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9NrXfFSj",
    "block": "{\"statements\":[[6,[\"click-outside\"],null,[[\"action\"],[[33,[\"action\"],[[28,[null]],\"sendCancel\"],null]]],{\"statements\":[[0,\"    \"],[11,\"div\",[]],[15,\"class\",\"delete-confirmation\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"body\"],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"isGroupVoicemail\"]]],null,{\"statements\":[[0,\"                \"],[11,\"small\",[]],[15,\"class\",\"text-muted\"],[13],[0,\"\\n                    \"],[1,[33,[\"t\"],[\"components.inbox.deleteGroupVoicemailWarning\"],null],false],[0,\"\\n                \"],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"                \"],[11,\"div\",[]],[15,\"class\",\"title\"],[13],[1,[33,[\"t\"],[\"chat.attachment.modal.titleDelete\"],null],false],[14],[0,\"\\n                \"],[11,\"small\",[]],[15,\"class\",\"text-muted\"],[13],[1,[33,[\"t\"],[\"common.cannotUndone\"],null],false],[14],[0,\"\\n\"]],\"locals\":[]}],[0,\"        \"],[14],[0,\"\\n\\n        \"],[11,\"div\",[]],[15,\"class\",\"footer\"],[13],[0,\"\\n            \"],[11,\"button\",[]],[15,\"type\",\"button\"],[15,\"class\",\"btn btn-success btn-purecloud\"],[5,[\"action\"],[[28,[null]],\"sendConfirm\"]],[13],[0,\"\\n                \"],[1,[33,[\"t\"],[\"common.delete\"],null],false],[0,\"\\n            \"],[14],[0,\"\\n            \"],[11,\"button\",[]],[15,\"type\",\"button\"],[15,\"class\",\"btn btn-link btn-cancel\"],[5,[\"action\"],[[28,[null]],\"sendCancel\"]],[13],[0,\"\\n                \"],[1,[33,[\"t\"],[\"common.cancelDelete\"],null],false],[0,\"\\n            \"],[14],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/inbox-panel-v2/delete-confirmation/template.hbs"
    }
  });

  _exports.default = _default;
});