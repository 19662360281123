define("web-directory/controllers/person/detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Controller.extend({
    entity: Ember.computed.alias('model.person'),
    queryParams: ['editing', 'editEmergencyLocation'],
    editing: true,
    editEmergencyLocation: false,
    showMobile: false,
    showDropdown: false,
    loadingFieldSections: false,
    editingProfile: computed.alias('editing'),
    editingSection: false,
    actions: {
      toggleEditing: function toggleEditing() {
        this.toggleProperty('editingProfile');
      },
      setEditingSection: function setEditingSection(value) {
        this.set('editingSection', value);
      },
      showGridMode: function showGridMode() {
        this.setProperties({
          stackedMode: false,
          gridMode: true,
          treeMode: false
        });
      },
      showStackedMode: function showStackedMode() {
        this.setProperties({
          stackedMode: true,
          gridMode: false,
          treeMode: false
        });
      },
      showTreeMode: function showTreeMode() {
        this.setProperties({
          stackedMode: false,
          gridMode: false,
          treeMode: true
        });
      },
      toggleLeftRail: function toggleLeftRail() {
        this.toggleProperty('showMobile');
      },
      noop: function noop() {}
    },
    name: computed('entity.general.name.[]', function () {
      return this.get('entity').getFieldValue('general.name');
    }),
    title: computed('entity.general.title.[]', function () {
      return this.get('entity').getFieldValue('general.title');
    }),
    department: computed('entity.general.department.[]', function () {
      return this.get('entity').getFieldValue('general.department');
    }),
    onOwnProfile: computed('entity.id', 'session.person.id', function () {
      return this.get('entity.id') === this.get('session.person.id');
    }),
    showGallery: computed('entity', 'entity.id', function () {
      var gallery = this.get('entity.images.informal');

      if (gallery) {
        return gallery.length > 0;
      }

      return false;
    })
  });

  _exports.default = _default;
});