define("web-directory/routes/card/detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CardDetailRoute = Ember.Route.extend({
    intl: Ember.inject.service(),
    routeTitle: Ember.computed('intl.loaded', function () {
      var intl = this.get('intl');
      var key = 'components.navMenu.cardView';

      if (intl.query(key)) {
        return intl.lookup(key);
      }

      return '';
    }),
    model: function model(params) {
      return this.store.find('person', params.person_id);
    },
    afterModel: function afterModel(model) {
      var promises = [model.get('directReports'), model.get('manager'), model.get('managerSiblings')];
      return Ember.RSVP.all(promises);
    }
  });
  var _default = CardDetailRoute;
  _exports.default = _default;
});