define("web-directory/utils/query-parser", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseQueryParams = parseQueryParams;

  function parseQueryParams() {
    var obj = {};
    var params = window.location.search.slice(1).split('&');

    _lodash.default.each(params, function (param) {
      var split = param.split('=');
      var key = decodeURIComponent(split[0]);
      var value = split.length > 1 ? decodeURIComponent(split[1]) : '';
      obj[key] = value;
    });

    return obj;
  }
});