define("web-directory/components/audio-recorder/component", ["exports", "web-directory/utils/sound-recorder", "web-directory/utils/dates"], function (_exports, _soundRecorder, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var run = Ember.run,
      inject = Ember.inject,
      computed = Ember.computed,
      Component = Ember.Component;

  var _default = Component.extend({
    classNames: ['audio-recorder'],
    webrtc: inject.service(),
    recorder: null,
    startTime: null,
    showOptions: false,
    attrs: {
      file: null,
      // comes from parent, used for notifications
      uploadImmediately: false,
      playerOptions: {},
      reset: function reset() {},
      audioFileReady: function audioFileReady() {}
    },
    actions: {
      startRecording: function startRecording() {
        this.get('recorder').reset();
        this.get('recorder').startRecording();
        this.set('startTime', (0, _dates.default)());
      },
      stopRecording: function stopRecording() {
        this.get('recorder').stopRecording();
        this.set('startTime', null);
      },
      reset: function reset() {
        this.get('recorder').reset();
        this.attrs.reset();
      }
    },
    hasSupport: computed.and('webrtc.audioCallCapable', 'hasMediaRecorder'),
    audioBlob: computed.reads('recorder.blob'),
    audioSource: computed.reads('recorder.blobURL'),
    recording: computed.reads('recorder.recording'),
    hasMediaRecorder: computed(function () {
      return window.MediaRecorder !== undefined;
    }),
    showUploadButton: computed('audioSource', 'uploadImmediately', function () {
      return !!this.get('audioSource') && !this.get('uploadImmediately');
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('hasSupport')) {
        run.scheduleOnce('afterRender', this, this.setupRecording);
      } else {
        run.scheduleOnce('afterRender', this, this.set, 'loaded', true);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var recorder = this.get('recorder');

      if (recorder) {
        recorder.destroy();
      }
    },
    didUpdateAttrs: function didUpdateAttrs(_ref) {
      var newAttrs = _ref.newAttrs;

      if (newAttrs && newAttrs.file) {
        if (!newAttrs.file.value) {
          this.get('recorder').reset();
        }
      }
    },
    setupRecording: function setupRecording() {
      var _this = this;

      var recorder = _soundRecorder.default.create(Ember.getOwner(this).ownerInjection());

      this.set('recorder', recorder);
      recorder.on('blobready', function () {
        Ember.run(function () {
          return _this.attrs.audioFileReady(_this.get('audioBlob'));
        });
      });
    }
  });

  _exports.default = _default;
});