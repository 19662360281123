define("web-directory/components/add-person-search-result/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component;

  var _default = Component.extend({
    tagName: 'a',
    attributeBindings: ['href'],
    href: '',
    classNames: ['add-person-result'],
    classNameBindings: ['result.isInRoom:disabled'],
    click: function click() {
      this.sendAction('loadItem', this.get('result'));
      this.set('result.isInRoom', true);
      return false;
    }
  });

  _exports.default = _default;
});