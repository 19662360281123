define("web-directory/serializers/group-policy", ["exports", "web-directory/serializers/purecloud"], function (_exports, _purecloud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _purecloud.default.extend({
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      if (!payload.id) {
        payload.id = id;
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});