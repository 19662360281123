define("web-directory/initializers/setup-chat-components", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  var require = window.requirejs;

  function initialize(instance) {
    var regex = /^ember-chat-components\/components\/([\w\-/]+)\/component/;
    var packages = Object.keys(require._eak_seen);
    var paths = packages.filter(function (path) {
      return regex.test(path);
    });
    paths.forEach(function (path) {
      var name = [].concat(path.match(regex)).pop();
      instance.register("component:".concat(name), require(path).default);
    });
  }

  var _default = {
    name: 'setup-chat-components',
    initialize: initialize
  };
  _exports.default = _default;
});