define("web-directory/components/entity-fields/select-field/component", ["exports", "lodash/find", "lodash/isEmpty", "web-directory/mixins/field-component"], function (_exports, _find, _isEmpty, _fieldComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldComponent.default, {
    intl: Ember.inject.service(),
    choice: Ember.computed.alias('entry.value'),
    actions: {
      setValue: function setValue(value) {
        if (!value) {
          value = this.get('defaultValue.id');
        }

        this.set('choice', value);
        Ember.set(this, 'entry.value', value);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var timer = Ember.run.scheduleOnce('afterRender', this, 'ensurePopulatedField');
      this.timers.pushObject(timer);
    },
    populateFieldOnRender: Ember.observer('editing', function () {
      var timer = Ember.run.scheduleOnce('afterRender', this, 'ensurePopulatedField');
      this.timers.pushObject(timer);
    }),
    ensurePopulatedField: function ensurePopulatedField() {
      if (!this.get('editing')) {
        return;
      }

      var path = this.get('field').getFieldPath();
      var entry = this.get('entry');
      var entity = this.get('entity');

      if (this.isFieldEmpty()) {
        var value = this.get('field.params.allowedValues')[0];

        if (!value) {
          value = '';
        }

        if (!(0, _isEmpty.default)(entry)) {
          Ember.set(entry, 'value', value);
          entity.set(path, [entry], {
            overwrite: true
          });
        } else {
          entity.set(path, value);
        }

        this.set('entry', entity.getFieldValue(path));
      }
    },
    defaultValue: Ember.computed('content', 'entry.value', function () {
      var value = this.get('entry.value');

      if (!(0, _isEmpty.default)(value)) {
        var defaultValue = (0, _find.default)(this.get('content'), 'id', value);

        if (!(0, _isEmpty.default)(defaultValue)) {
          return defaultValue;
        }
      }

      return this.get('content.0.id');
    }),
    content: Ember.computed('field.params.allowedValues', function () {
      var _this = this;

      var allowedValues = this.get('field.params.allowedValues') || [];
      return allowedValues.map(function (value) {
        var label = _this.translatedAllowedValue(value).toString();

        return {
          id: value,
          label: label
        };
      });
    }),
    translatedValue: Ember.computed('entry.value', function () {
      var value = this.get('entry.value');

      if (value) {
        var fieldKey = this.get('field').getI18nKey();
        var valueKey = value.replace(/\./g, '_');
        var key = "".concat(fieldKey, "_value_").concat(valueKey);

        if (this.get('intl').query(key)) {
          return this.get('intl').lookup(key);
        }
      }

      return value;
    }),
    translatedAllowedValue: function translatedAllowedValue(value) {
      var fieldKey = this.get('field').getI18nKey();
      var valueKey = value.replace(/\./g, '_');
      var key = "".concat(fieldKey, "_value_").concat(valueKey);

      if (this.get('intl').query(key)) {
        return this.get('intl').lookup(key);
      }

      return valueKey;
    },
    isFieldEmpty: function isFieldEmpty() {
      var field = this.get('field');
      var entity = this.get('entity');

      if (field && entity) {
        var path = this.get('field').getFieldPath();
        var value = this.get('entity').getFieldValue(path);
        return (0, _isEmpty.default)(value);
      }
    }
  });

  _exports.default = _default;
});