define("web-directory/adapters/voicemail-message", ["exports", "web-directory/adapters/purecloud"], function (_exports, _purecloud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _purecloud.default.extend({
    pathForType: function pathForType() {
      return 'v2/voicemail/messages';
    },
    urlForQuery: function urlForQuery() {
      return "".concat(this.host, "/").concat(this.namespace, "/v2/voicemail/search");
    },
    updateRecord: function updateRecord() {
      return this._super.apply(this, arguments);
    },
    // Override private function (copying default impl)
    // so we can 'POST' to the API
    // TODO: Remove ds-improved-ajax lands
    query: function query(store, type, _query) {
      var url = this.buildURL(type.modelName, null, null, 'query', _query);

      if (this.sortQueryParams) {
        _query = this.sortQueryParams(_query);
      }

      return this.ajax(url, 'POST', {
        data: _query
      });
    },
    // Use this in place of:
    //   methodForRequest
    //   dataForRequest
    // TODO: Remove ds-improved-ajax lands
    sortQueryParams: function sortQueryParams(query) {
      return {
        pageSize: query.pageSize || 15,
        pageNumber: query.pageNumber || 1,
        sortOrder: 'DESC',
        sortBy: 'createdTime',
        query: [{
          type: 'EXACT',
          fields: ['owner'],
          value: 'ALL'
        }, {
          type: 'EXACT',
          fields: ['deleted'],
          value: 'false'
        }]
      };
    }
  });

  _exports.default = _default;
});