define("web-directory/app-providers/external-contacts-interapptions-provider", ["exports", "web-directory/services/acdchat", "web-directory/utils/boolean-expressions", "web-directory/models/app"], function (_exports, _acdchat, _booleanExpressions, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.boolExpr = void 0;
  var inject = Ember.inject,
      RSVP = Ember.RSVP,
      $ = Ember.$;
  var ExtContactsInterapptionsProvider = Ember.Object.extend({
    intl: inject.service(),
    session: inject.service(),
    application: inject.service(),
    acdchat: inject.service(),
    isLocal: Ember.computed.reads('application.runtime.local'),
    id: 'builtInExternalContactsInterapptionsProvider',
    type: 'programmatic',
    trustedProvider: true,
    monitorInteractionUpdates: true,
    init: function init() {
      this.set('_interactionState', {});
      this.get('acdchat').on(_acdchat.INCONCLUSIVE_AUTO_LINK_CONTACT_LOOKUP_EVENT, this, '_handleInconclusiveContactLookupEvent');
    },
    interactionRemoved: function interactionRemoved(_ref) {
      var id = _ref.id;
      var interactionState = this.get('_interactionState');
      delete interactionState[id];
    },
    getAppConfigs: function getAppConfigs(appContext, callback) {
      var appContextType = appContext.type,
          payload = appContext.payload;
      if (appContextType === 'workitem') return RSVP.resolve([]);

      var _ref2 = Ember.getProperties(payload, 'id', 'participantForCustomer') || {},
          id = _ref2.id,
          participantForCustomer = _ref2.participantForCustomer;

      var _ref3 = participantForCustomer || {},
          externalContactId = _ref3.externalContactId;

      var state = this._getState(id);

      this._setState(id, Object.assign({}, state, {
        externalContactId: externalContactId,
        callback: callback
      }));

      return this._getAppConfigs(payload);
    },
    _getState: function _getState(interactionId) {
      return this.get("_interactionState.".concat(interactionId)) || {};
    },
    _setState: function _setState(interactionId, newState) {
      return this.set("_interactionState.".concat(interactionId), newState);
    },
    _getAppConfigs: function _getAppConfigs(interaction) {
      var externalContactId = interaction.get('participantForCustomer.externalContactId');
      var isUsingProfilePanelRefresh = this.get('session.features.relate-ui-profilePanelRefresh');
      var branch = '';
      var isLocal = this.get('isLocal');
      var forceLocal = isLocal && localStorage && localStorage.getItem('forceLocalACDProfile') === 'true';
      var pcTLD = forceLocal && isLocal || !forceLocal && !isLocal ? this.get('application.envDetails.envTld') // localhost/inindca.com/etc
      : 'inindca.com';
      var appLocalHost = isUsingProfilePanelRefresh ? 'https://localhost:4444/' : 'https://localhost:4400/external-contacts-ACD/#/';
      var externalContactPath = externalContactId ? "agent/contact/".concat(externalContactId) : 'agent/contact/';

      if (isUsingProfilePanelRefresh) {
        externalContactPath = '';
      }

      var defaultCountryCode = this.get('session.org.orgDefaultCountryCode');
      var token = this.get('session.accessToken');
      var paramData = {
        isLocal: forceLocal,
        pcTLD: pcTLD,
        defaultCountryCode: defaultCountryCode
      }; // added to decrease the probability of failure to load profile because we could not load "me" user.
      // All our interapptions in the same session can load "me" user once for permissions and jid for notifications
      // and we can stand up the profile with a higher likelihood of success ( no 429 blockage )
      // we will of course update permissions each time but it will not block load of the profile this way.

      var _ref4 = Ember.getProperties(this.get('session.user'), 'id', 'chat') || {},
          userId = _ref4.id,
          _ref4$chat = _ref4.chat;

      _ref4$chat = _ref4$chat === void 0 ? {} : _ref4$chat;
      var jid = _ref4$chat.jabberId;
      var options = $.param(Object.assign(paramData, {
        userId: userId,
        jid: jid
      }));
      var queryPath = "?".concat(options, "&locale=").concat(_app.GC_LANG_TAG_URL_REPLACEMENT_TOKEN, "&pcEnvironment=").concat(_app.PC_ENV_URL_REPLACEMENT_TOKEN, "&interactionId=").concat(_app.GC_CONVERSATION_ID_URL_REPLACEMENT_TOKEN, "&usePopupAuth=").concat(_app.GC_USE_POPUP_AUTH_URL_REPLACEMENT_TOKEN);

      if (isUsingProfilePanelRefresh) {
        queryPath = "?".concat(options, "&locale=").concat(_app.GC_LANG_TAG_URL_REPLACEMENT_TOKEN, "&gcTargetEnv=").concat(_app.GC_TARGET_ENV_REPLACEMENT_TOKEN, "&gcHostOrigin=").concat(_app.GC_HOST_ORIGIN_REPLACEMENT_TOKEN, "&gcConversationId=").concat(_app.GC_CONVERSATION_ID_URL_REPLACEMENT_TOKEN, "&usePopupAuth=").concat(_app.GC_USE_POPUP_AUTH_URL_REPLACEMENT_TOKEN);
      }

      var localURL = "".concat(appLocalHost).concat(externalContactPath).concat(queryPath, "&token=").concat(token); // A URL in the format of https://apps.inindca.com/directory/?externalContactsACDBranch=BRANCH_NAME#
      // will direct the interapption to that branch build

      var params = new URLSearchParams(window.location.search);

      if (params.has('externalContactsACDBranch')) {
        branch = params.get('externalContactsACDBranch') + '/';
      }

      var icon = {
        guxIcon: {
          name: 'user-circle',
          decorative: true
        }
      };
      var out = {
        id: 'external-contact-acd-profile-panel',
        name: this.get('intl').t('purecloud.acdInteraction.relate.profile'),
        sandbox: ['allow-same-origin', 'allow-scripts', 'allow-modals', 'allow-popups', 'allow-forms', 'allow-downloads'],
        icon: icon,
        monochromicIcon: icon,
        lifecycle: {
          ephemeral: false,
          hooks: {
            stop: true,
            blur: true,
            focus: true,
            bootstrap: true
          }
        }
      };

      if (forceLocal) {
        out.url = localURL;
      } else {
        if (isUsingProfilePanelRefresh) {
          out.path = "profile-panel-ui/".concat(branch);
        } else {
          out.path = "external-contacts-ACD/".concat(branch, "#/").concat(externalContactPath);
        }

        out.query = queryPath;
      }

      return RSVP.resolve([out]);
    },

    /**
     * update the app url if there are inconclusive search results from one of the chat search terms.
     * @param interactionModel
     * @param details
     * @private
     */
    _handleInconclusiveContactLookupEvent: function _handleInconclusiveContactLookupEvent(_ref5) {
      var _this = this;

      var interactionModel = _ref5.interactionModel;

      if (!interactionModel) {
        return;
      }

      var id = interactionModel.id;

      var state = this._getState(id);

      var callback = state.callback;

      if (!callback) {
        // We haven't seen this interaction yet, wait for it to be called with getAppConfigs
        return;
      }

      this._getAppConfigs(interactionModel).then(function (apps) {
        // Run once to avoid thrashing the iframe url
        Ember.run.once(_this, '_invokeCallback', callback, apps);
      });
    },
    _invokeCallback: function _invokeCallback(callback, apps) {
      callback(apps);
    }
  });
  var boolExpr = (0, _booleanExpressions.perm)('externalContacts:contact:view');
  _exports.boolExpr = boolExpr;
  var _default = ExtContactsInterapptionsProvider;
  _exports.default = _default;
});