define("web-directory/components/call-history-v2/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/v4C1kER",
    "block": "{\"statements\":[[6,[\"each\"],[[28,[\"sortedHistory\"]]],[[\"key\"],[\"id\"]],{\"statements\":[[6,[\"if\"],[[28,[\"call\"]]],null,{\"statements\":[[0,\"        \"],[1,[33,[\"call-history-entity\"],null,[[\"call\",\"fetchRecentHistory\"],[[28,[\"call\"]],[33,[\"action\"],[[28,[null]],\"fetchRecentHistory\"],null]]]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[\"call\"]},null],[11,\"button\",[]],[15,\"class\",\"btn btn-link action-fetch-more show-button\"],[15,\"type\",\"button\"],[16,\"onclick\",[33,[\"action\"],[[28,[null]],\"fetchMoreHistory\"],null],null],[13],[0,\"\\n\"],[0,\"    \"],[11,\"span\",[]],[15,\"class\",\"flex\"],[13],[0,\"\\n        \"],[9,\"gux-icon\",{\"attrs\":[[15,\"icon-name\",\"history\"],[15,\"decorative\",\"true\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n        \"],[1,[33,[\"t\"],[\"purecloud.fetchMoreHistory\"],null],false],[0,\"\\n    \"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/call-history-v2/template.hbs"
    }
  });

  _exports.default = _default;
});