define("web-directory/components/integration-badge/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4wOsTXbh",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"integration\"]]],null,{\"statements\":[[0,\"    \"],[11,\"img\",[]],[16,\"class\",[34,[\"integration-icon \",[33,[\"if\"],[[28,[\"isSmall\"]],\"mini-icon\"],null]]]],[16,\"src\",[26,[\"iconUri\"]],null],[16,\"alt\",[26,[\"translation\"]],null],[13],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/integration-badge/template.hbs"
    }
  });

  _exports.default = _default;
});