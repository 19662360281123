define("web-directory/components/timeline-stream/time-ago-indicator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2wHRmID0",
    "block": "{\"statements\":[[11,\"div\",[]],[16,\"class\",[34,[\"event-type-icon \",[28,[\"entity\",\"type\"]]]]],[13],[1,[26,[\"icon\"]],true],[14],[0,\"\\n\"],[11,\"div\",[]],[15,\"class\",\"time-ago date\"],[13],[1,[26,[\"dateDisplay\"]],false],[14],[0,\"\\n\"],[11,\"div\",[]],[15,\"class\",\"time-ago\"],[13],[1,[26,[\"timeDisplay\"]],false],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/timeline-stream/time-ago-indicator/template.hbs"
    }
  });

  _exports.default = _default;
});