define("web-directory/serializers/recorded-call", ["exports", "ember-data", "web-directory/serializers/purecloud"], function (_exports, _emberData, _purecloud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _purecloud.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      conversation: {
        embedded: 'always'
      }
    },
    normalizeArrayResponse: function normalizeArrayResponse() {
      var response = this._super.apply(this, arguments);

      if (!response || !response.data) {
        response.data = [];
      }

      response.data.forEach(function (entity) {
        entity.relationships.conversation.data.type = 'conversation';
      });
      return response;
    }
  });

  _exports.default = _default;
});