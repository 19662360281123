define("web-directory/components/anchor-tag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3dIN3VGv",
    "block": "{\"statements\":[[11,\"a\",[]],[16,\"href\",[26,[\"anchor\"]],null],[16,\"title\",[26,[\"title\"]],null],[16,\"target\",[26,[\"target\"]],null],[13],[1,[26,[\"text\"]],false],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/anchor-tag/template.hbs"
    }
  });

  _exports.default = _default;
});