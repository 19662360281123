define("web-directory/services/apps-api-handlers/propose-message-handler", ["exports", "@genesys/ast"], function (_exports, GAST) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.VALID_INJECTION_MODES = _exports.PROPOSE_MESSAGE_API_ACTION_NAME = void 0;
  var RSVP = Ember.RSVP,
      typeOf = Ember.typeOf;
  var VALID_INJECTION_MODES = ['insert'];
  _exports.VALID_INJECTION_MODES = VALID_INJECTION_MODES;
  var PROPOSE_MESSAGE_API_ACTION_NAME = 'proposeInteractionMessage';
  _exports.PROPOSE_MESSAGE_API_ACTION_NAME = PROPOSE_MESSAGE_API_ACTION_NAME;
  var ProposeMessageHandler = Ember.Object.extend({
    /**
     * Synchronous check to see if this handler is applicable for the specified AppApiCall instance
     *
     * @param {Object} the AppApiCall instance to check if this handler can handle
     *
     * @returns true if the provided AppApiCall instance could be handled by this handler. This check should
     *  be limited to checking if the handler is responsible for this type of AppApiCall rather than if the
     *  payload itself is valid.  If no handler returns true, the error will be that no handler exists which is
     *  a different case than the AppApiCall is invalie. Practically, this means that validation of the payload
     *  should be done in the handle method
     */
    canHandle: function canHandle(appApiCall) {
      return appApiCall.get('action') === PROPOSE_MESSAGE_API_ACTION_NAME;
    },
    handle: function handle(appApiCall) {
      var _this = this;

      return new RSVP.Promise(function (resolve, reject) {
        if (!_this.isActiveInterapption(appApiCall.get('srcApp.id'))) {
          reject('This app is not the active interapption');
          return;
        }

        var text = appApiCall.get('actionDetails.message');

        if (!text || typeOf(text) !== 'string') {
          reject('Message text must be a non-empty string');
          return;
        }

        var mode = appApiCall.get('actionDetails.mode');

        if (!VALID_INJECTION_MODES.includes(mode)) {
          reject("Injection mode must be one of: ".concat(VALID_INJECTION_MODES.join(', ')));
          return;
        }

        var interaction = _this.getInteraction();

        if (!interaction.get('isConnected')) {
          reject('The interaction is not in a connected state');
          return;
        } // Check if we have Appsv2 AST support on the current conversation


        var offerMessage = _this.offerMessage;

        if (offerMessage && typeof offerMessage === 'function') {
          // Build a simple text AST to be offered
          var astResult = GAST.Text.convertToAST(text);

          if (astResult.type === 'failure') {
            // Currently, text based AST conversation cannot fail;
            // handling as it's technically part of the API.
            reject('Failed to generate AST from propose text');
            return;
          }

          offerMessage({
            // insert is the only valid injectionMode for v1
            injectionMode: 'insert',
            proposedMessage: {
              format: 'text',
              tree: astResult.tree
            }
          });
          return resolve();
        } // Only handles 'insert' mode which injects the text at the agent's cursor position,
        // leaving the existing text intact.


        if (interaction.get('isChat')) {
          _this.insertChatText(text);
        } else if (interaction.get('isSms')) {
          _this.insertSmsText({
            strippedText: text
          });
        } else if (interaction.get('isEmail')) {
          // Need to escape HTML chars for email injection (uses innerHTML under the hood)
          _this.insertEmailText(Ember.Handlebars.Utils.escapeExpression(text));
        } else {
          reject("Unsupported interaction type: ".concat(interaction.get('type')));
          return;
        }

        return resolve();
      });
    }
  });
  var _default = ProposeMessageHandler;
  _exports.default = _default;
});