define("web-directory/components/entity-fields/validators/address", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    isValid: function isValid(entry, field) {
      var maxLength = field.get('params.maxLength');

      if (entry && _lodash.default.isEmpty(entry.value)) {
        return true;
      }

      return !!(entry && entry.value && Ember.get(entry, 'value.length') <= maxLength);
    },
    isEmpty: function isEmpty(entry) {
      var value = entry.value;
      return value === undefined || value === null || _lodash.default.isEmpty(value);
    }
  };
  _exports.default = _default;
});