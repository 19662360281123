define("web-directory/routes/engage/recording-management-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      return this.replaceWith('/admin/quality/recordingManagement');
    }
  });

  _exports.default = _default;
});