define("web-directory/search/adapter-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      Mixin = Ember.Mixin;
  var DEFAULT_PARAMS = {
    pageNumber: 1,
    pageSize: 25,
    types: ['users', 'groups', 'locations'],
    sortOrder: 'ASC',
    expand: ['authorization']
  };

  var _default = Mixin.create({
    search: inject.service('search'),
    performSimpleKeywordSearch: function performSimpleKeywordSearch(query, params) {
      params = Object.assign({}, DEFAULT_PARAMS, params, {
        query: [{
          value: query,
          type: 'QUERY_STRING'
        }],
        returnFields: this.get('search.fields')
      });
      var url = this.get('search.apiEndpoint');
      return this.ajax(url, 'POST', {
        data: params
      });
    },
    performSearch: function performSearch(params) {
      params = Object.assign({}, DEFAULT_PARAMS, {
        returnFields: this.get('search.fields')
      }, params);
      var url = this.get('search.apiEndpoint');
      return this.ajax(url, 'POST', {
        data: params
      });
    },
    suggest: function suggest(params) {
      params = Object.assign({}, DEFAULT_PARAMS, {
        returnFields: this.get('search.fields')
      }, params);
      var url = this.get('search.suggestEndpoint');
      return this.ajax(url, 'POST', {
        data: params
      });
    }
  });

  _exports.default = _default;
});