define("web-directory/components/group-profile/roles-edit/fixtable/custom-footer/component", ["exports", "web-directory/components/group-profile/roles-edit/fixtable/custom-footer/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['fixtable-custom-footer'],
    layout: _template.default,
    one: 1,
    isFirstPage: Ember.computed.equal('currentPage', 1),
    isLastPage: Ember.computed('currentPage', 'totalPages', function () {
      return this.get('currentPage') === this.get('totalPages');
    }),
    listedPages: Ember.computed('currentPage', 'totalPages', function () {
      var currentPage = this.get('currentPage');
      var totalPages = this.get('totalPages'); // ideal number of pages to be linked on each side of current page

      var neighbors = 3; // determine how many pages "short" we are on each side

      var pagesToFirst = currentPage - 1;
      var pagesToLast = totalPages - currentPage;
      var shortageL = pagesToFirst < neighbors ? neighbors - pagesToFirst : 0;
      var shortageR = pagesToLast < neighbors ? neighbors - pagesToLast : 0; // increase neighbors by the largest "shortage" value

      neighbors += Math.max(shortageL, shortageR); // calculate indices of first & last pages in range

      var lowerIndex = currentPage - neighbors;
      var upperIndex = currentPage + neighbors;

      if (lowerIndex < 1) {
        lowerIndex = 1;
      }

      if (upperIndex > totalPages) {
        upperIndex = totalPages;
      } // build & return pages array


      var pages = [];

      for (var i = lowerIndex; i <= upperIndex; i++) {
        pages.push({
          index: i,
          isCurrent: i === currentPage
        });
      }

      return pages;
    }),
    firstPageListed: Ember.computed('listedPages', function () {
      return this.get('listedPages').map(function (page) {
        return page.index;
      }).indexOf(1) >= 0;
    }),
    secondPageListed: Ember.computed('listedPages', function () {
      return this.get('listedPages').map(function (page) {
        return page.index;
      }).indexOf(2) >= 0;
    }),
    penultimatePageListed: Ember.computed('listedPages', 'totalPages', function () {
      return this.get('listedPages').map(function (page) {
        return page.index;
      }).indexOf(this.get('totalPages') - 1) >= 0;
    }),
    finalPageListed: Ember.computed('listedPages', 'totalPages', function () {
      return this.get('listedPages').map(function (page) {
        return page.index;
      }).indexOf(this.get('totalPages')) >= 0;
    }),
    showPaginationControls: Ember.computed('listedPages', function () {
      return this.get('listedPages').length > 1;
    }),
    showRangeLabel: Ember.computed.not('isLoading'),
    actions: {
      bulkAction: function bulkAction(selectedDataRows, action) {
        this.sendAction('bubbleAction', action, selectedDataRows);
      },
      showPage: function showPage(pageNumber) {
        this.set('currentPage', pageNumber);
      },
      showNextPage: function showNextPage() {
        this.sendAction('goToNextPage');
      },
      showPreviousPage: function showPreviousPage() {
        this.sendAction('goToPreviousPage');
      }
    }
  });

  _exports.default = _default;
});