define("web-directory/search/request-builder", ["exports", "lodash", "web-directory/search/service", "web-directory/models/person"], function (_exports, _lodash, _service, _person) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var computed = Ember.computed;

  var _default = Ember.Object.extend({
    service: null,
    page: null,
    limit: null,
    types: null,
    queries: null,
    aggregations: null,
    returnFields: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.queries = [];
      this.aggregations = [];
      this.types = ['users', 'groups', 'locations'];
    },
    data: computed('queries.[]', 'aggregations.[]', 'types.[]', function () {
      var _this$getProperties = this.getProperties('queries', 'aggregations', 'page', 'limit', 'types', 'returnFields'),
          queries = _this$getProperties.queries,
          aggregations = _this$getProperties.aggregations,
          page = _this$getProperties.page,
          limit = _this$getProperties.limit,
          types = _this$getProperties.types,
          returnFields = _this$getProperties.returnFields;

      var data = {
        types: types
      };

      if (queries.length) {
        data.query = queries;
      } // You can only search on users when you have aggregations


      if (aggregations.length) {
        data.aggregations = aggregations;
        data.types = ['users'];
      }

      if (typeof page === 'number') {
        data.pageNumber = page;
      }

      if (typeof limit === 'number') {
        data.pageSize = limit;
      }

      if (returnFields && Array.isArray(returnFields)) {
        data.returnFields = returnFields;
      } else {
        if (data.types.indexOf('users') === -1) {
          data.returnFields = ['ALL_FIELDS'];
        } else {
          data.returnFields = _person.BASE_PERSON_FIELDS;
        }
      }

      data.expand = _person.BASE_PERSON_EXPANDS;
      return data;
    }),
    addQuery: function addQuery(value) {
      var operator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'AND';
      var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'QUERY_STRING';
      var fields = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
      Ember.assert("Operator must be one of: ".concat(_service.SEARCH_QUERY_OPERATORS), _lodash.default.includes(_service.SEARCH_QUERY_OPERATORS, operator));
      Ember.assert("Type must be one of: ".concat(_service.SEARCH_QUERY_TYPES), _lodash.default.includes(_service.SEARCH_QUERY_TYPES, type));

      if (type === 'QUERY_STRING') {
        Ember.assert('If query type is `QUERY_STRING`, the query value must have a length >= 2', value.length >= 2);
      }

      var query = {
        type: type
      };

      if (type !== 'MATCH_ALL') {
        query.operator = operator;
        query.value = value;
      }

      if (!_lodash.default.isEmpty(fields)) {
        query.fields = fields;
      }

      this.queries.pushObject(query);
      return this;
    },
    // Add multiple queries at once. Pass in as arrays:
    // [value, operator, ...], [value, operator, ...]
    addQueries: function addQueries() {
      var _this = this;

      var queries = Array.prototype.slice.call(arguments);
      queries.forEach(function (query) {
        _this.addQuery.apply(_this, _toConsumableArray(query));
      });
      return this;
    },
    addTagQuery: function addTagQuery() {
      return this;
    },
    addAggregation: function addAggregation(name, value) {
      var field = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'profileSkills';
      var type = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'CONTAINS';
      var size = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 15;
      Ember.assert("Type must be one of: ".concat(_service.SEARCH_AGGREGATE_TYPES), _lodash.default.includes(_service.SEARCH_AGGREGATE_TYPES, type));

      if (type !== 'COUNT') {
        Ember.assert('You much include a name and value when type is not `COUNT`', type !== 'COUNT' || !!name && !!value);
      }

      var aggregation = {
        name: name,
        field: field,
        type: type,
        size: size
      };

      if (value) {
        aggregation.value = value;
      }

      this.aggregations.pushObject(aggregation);
      return this;
    },
    addAggregations: function addAggregations() {
      var _this2 = this;

      var aggregations = Array.prototype.slice.call(arguments);
      aggregations.forEach(function (aggregation) {
        _this2.addAggregation.apply(_this2, _toConsumableArray(aggregation));
      });
      return this;
    },
    setPaging: function setPaging(page, limit) {
      this.setProperties({
        page: page,
        limit: limit
      });
      return this;
    },
    setTypes: function setTypes(types) {
      types = types.map(function (type) {
        if (type === 'people') {
          return 'users';
        }

        return type;
      });
      this.set('types', types);
      return this;
    },
    setReturnFields: function setReturnFields(returnFields) {
      if (returnFields && returnFields.length) {
        this.set('returnFields', returnFields);
      } else {
        this.set('returnFields', undefined);
      }

      return this;
    }
  });

  _exports.default = _default;
});