define("web-directory/templates/feedback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NiZvn5u2",
    "block": "{\"statements\":[[1,[33,[\"feedback-section-v2\"],null,[[\"mobile\"],[[28,[\"mobile\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/templates/feedback.hbs"
    }
  });

  _exports.default = _default;
});