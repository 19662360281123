define("web-directory/components/skillgroup-profile/component", ["exports", "web-directory/mixins/entity-controller"], function (_exports, _entityController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      inject = Ember.inject;
  var SkillGroupProfileComponent = Component.extend(_entityController.default, {
    classNames: ['skillgroup-profile', 'entity'],
    classNameBindings: ['chatEnabled'],
    notification: inject.service(),
    permissions: inject.service(),
    session: inject.service(),
    isShowingGeneralModal: false,
    modalName: '',
    modalDescription: '',
    isShowingDeleteModal: false,
    activeTab: 'members',
    canEditSkillGroup: Ember.computed('session.user.isTrustee', 'session.person.namedPermissions', 'permissions.canEditSkillGroups', function () {
      if (this.get('session.user.isTrustee')) {
        return this.get('permissions.canEditSkillGroups');
      }

      var person = this.get('session.person');
      var permissions = person.get('namedPermissions');
      return permissions.includes('admin') || permissions.includes('group_administration') || this.get('permissions.canEditSkillGroups');
    }),
    membershipHeader: Ember.computed('model.memberCount', function () {
      var count = this.get('model.memberCount');
      return this.get('intl').lookup('groups.membershipWithCount', {
        count: count
      }).toString();
    }),
    actions: {
      selectTab: function selectTab(tab) {
        this.set('activeTab', tab);
      },
      editGeneralInfo: function editGeneralInfo() {
        this.set('modalName', this.get('model.name'));
        this.set('modalDescription', this.get('model.description'));
        this.set('isShowingGeneralModal', true);
      },
      updateGeneralField: function updateGeneralField(fieldName, event) {
        var value = Ember.get(event, 'target.value');
        this.updateField(fieldName, value);
      },
      confirmGeneral: function confirmGeneral() {
        var _this = this;

        var notification = this.get('notification');
        var group = this.get('model');
        group.set('name', this.get('modalName'));
        group.set('description', this.get('modalDescription'));
        group.save().then(function (data) {
          Ember.Logger.debug('SKILLGROUP-PROFILE: group saved', {
            data: data
          });

          _this.reset();

          group.reload().then(function () {
            // profile isn't rerendered on a save here, so manually reload membership
            group.loadMembership();
          });
          var title = group.get('name');

          var message = _this.get('intl').lookup('skillgroups.general.saved');

          notification.info(title, message, {
            forceBrowserNotification: true,
            hideAfter: 5
          });
        }).catch(function (e) {
          Ember.Logger.error('skillgroup save failed', {
            error: e
          });
          var title = group.get('name');

          var message = _this.get('intl').lookup('notification.group.saveError');

          if (e.payload.message == "group named [".concat(title, "] already exists")) {
            message = _this.get('intl').lookup('skillgroups.createError.duplicate');
          }

          notification.error(title, message, {
            forceBrowserNotification: true
          });

          _this.reset();

          group.reload().then(function () {
            group.rollbackAttributes();
          });
        });
      },
      cancelGeneral: function cancelGeneral() {
        this.get('model').reload();
        this.set('isShowingGeneralModal', false);
      },
      deleteGroup: function deleteGroup() {
        this.set('isShowingDeleteModal', true);
      },
      cancelDelete: function cancelDelete() {
        this.set('isShowingDeleteModal', false);
      },
      confirmDelete: function confirmDelete() {
        var _this2 = this;

        var notification = this.get('notification');
        var model = this.get('model');
        model.delete().then(function () {
          Ember.Logger.debug('SKILLGROUP-PROFILE: Deleted skillgroup: ', {
            model: model
          });
          var title = model.get('name');

          var message = _this2.get('intl').lookup('notification.group.deleting');

          notification.info(title, message, {
            icon: '',
            hideAfter: 8
          });

          _this2.reset();

          window.history.back();
        }).catch(function (e) {
          Ember.Logger.error('SKILLGROUP-PROFILE: Error deleting skillgroup', {
            error: e
          });
          var title = model.get('name');

          var message = _this2.get('intl').lookup('notification.group.deleteError');

          notification.error(title, message, {
            icon: ''
          });

          _this2.reset();
        });
      }
    },
    reset: function reset() {
      this.set('isShowingDeleteModal', false);
      this.set('isShowingGeneralModal', false);
    }
  });
  var _default = SkillGroupProfileComponent;
  _exports.default = _default;
});