define("web-directory/mixins/acd-utilities", ["exports", "lodash", "web-directory/utils/dates", "ember-purecloud-components/mixins/logger"], function (_exports, _lodash, _dates, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_logger.default, {
    notification: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    personGuid: Ember.computed.reads('session.person.guid'),
    // TODO: Ultimately, we need to store these values somewhere other
    // than the model. We may introduce a persistance service that will
    // allow us to store interaction properties by id and persist them
    // back to the public API via the preference API.
    modelSet: function modelSet(model, property, value) {
      if (model && !Ember.get(model, 'isDestroyed') && !Ember.get(model, 'isDestroying')) {
        model.set(property, value);
      }
    },
    _displayApiError: function _displayApiError(error, fallbackMessageKey) {
      var code = _lodash.default.get(error, 'responseJSON.code');

      var key = code && "error.".concat(code);

      this._displayError(key && this.get('intl').exists(key) ? key : fallbackMessageKey);
    },
    _displayError: function _displayError(messageKey, messageKeyOption) {
      var message = this.get('intl').t(messageKey, messageKeyOption).toString();
      this.get('notification').error(null, message, {});
    },
    _displayNotification: function _displayNotification(titleKey, messageKey) {
      var title = null;
      var message = null;

      if (titleKey) {
        title = this.get('intl').t(titleKey).toString();
      }

      if (messageKey) {
        message = this.get('intl').t(messageKey).toString();
      }

      this.get('notification').info(title, message);
    },
    _isInDOM: function _isInDOM() {
      var isDestroyed = this.get('isDestroyed') || this.get('isDestroying') || !this.get('interaction') || this.get('interaction.isDestroyed') || this.get('interaction.isDestroying');

      if (isDestroyed) {
        return false;
      } else if (this.get('interaction.isCall')) {
        var conversation = this.get('interaction.conversation');
        var conversationIsDestroyed = !conversation || conversation.get('isDestroying') || conversation.get('isDestroyed');

        if (conversationIsDestroyed) {
          return false;
        }
      }

      return true;
    },
    getInteractionTypeFromCP: function getInteractionTypeFromCP(interaction) {
      var _this = this;

      var type = {
        isCall: false,
        isFax: false,
        isChat: false,
        isEmail: false,
        isCallback: false,
        isSms: false
      };

      var myselfAgent = _lodash.default.findLast(interaction.participants, function (party) {
        var partyIsUser = _lodash.default.has(party, 'userId') ? party.userId === _this.get('personGuid') : false;
        var partyIsAgent = _lodash.default.has(party, 'purpose') ? party.purpose.toLowerCase() === 'agent' : false;
        return partyIsUser && partyIsAgent;
      });

      type.isCallback = myselfAgent ? _lodash.default.has(myselfAgent, 'callbacks') && myselfAgent.callbacks.length : false;
      type.isCall = _lodash.default.some(interaction.participants, function (party) {
        return !_lodash.default.isEmpty(party.calls);
      });
      type.isFax = _lodash.default.some(interaction.participants, function (party) {
        return _lodash.default.has(party, 'purpose') ? party.purpose === 'fax' : false;
      });
      type.isChat = _lodash.default.some(interaction.participants, function (party) {
        return !_lodash.default.isEmpty(party.chats);
      });
      type.isEmail = _lodash.default.some(interaction.participants, function (party) {
        return !_lodash.default.isEmpty(party.emails);
      });
      type.isSms = _lodash.default.some(interaction.participants, function (party) {
        return !_lodash.default.isEmpty(party.messages);
      });
      return type;
    },
    handleMissedTimedBoxTimer: function handleMissedTimedBoxTimer(ctx, payload, Interaction, conversationModel) {
      try {
        var tmpInteractionModel = Interaction.create(payload, Ember.getOwner(ctx).ownerInjection());

        if (tmpInteractionModel && conversationModel) {
          Ember.set(tmpInteractionModel, 'conversation', conversationModel);
        }

        var interactionHasMissedTimer = Ember.get(tmpInteractionModel, 'hasMissedTimer');

        if (tmpInteractionModel && interactionHasMissedTimer) {
          var titleKey = 'purecloud.acdInteraction.aftercallworkTitleMessage';
          var messageKey = 'purecloud.acdInteraction.aftercallworkTimerExpiredBodyMessage';

          this._displayNotification(titleKey, messageKey);
        }

        if (tmpInteractionModel) {
          Ember.run.later(function () {
            tmpInteractionModel.destroy();
          });
        }
      } catch (error) {
        this.logger.info('ACD: handleMissedBoxTimer error', {
          error: error
        });
      }
    },
    windowInFocus: function windowInFocus() {
      var hosted;
      hosted = this.get('applicationService.hostedContext');

      if (hosted != null ? hosted.isHosted() : void 0) {
        return hosted.applicationHasFocus();
      } else {
        return document.hasFocus();
      }
    },
    timeDiff: function timeDiff(timeCreated) {
      var diff = (0, _dates.default)().diff(timeCreated);

      if (diff < 500) {
        this.logger.info('ACD: interaction create destroy diff', {
          diff: diff
        });
      }
    }
  });

  _exports.default = _default;
});