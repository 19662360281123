define("web-directory/components/app-instance/component", ["exports", "web-directory/models/app-instance", "web-directory/models/app-api-call", "web-directory/utils/apps"], function (_exports, _appInstance, _appApiCall, _apps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed,
      run = Ember.run;

  var _default = Component.extend({
    classNames: ['app-instance'],
    classNameBindings: ['appMarkerClass', 'appInstanceMarkerClass'],
    attributeBindings: ['ariaHiddenAttr:aria-hidden', 'hiddenAttr:hidden'],
    // Public Attributes
    instance: null,
    hidden: false,
    // Private Variables
    _targetAppIframe: null,
    _targetAppWindow: null,
    _targetAppOrigin: null,
    _prevInstance: null,
    appMarkerClass: computed('instance.appId', function () {
      var result = '';
      var appId = this.get('instance.appId');

      if (appId) {
        result = Ember.String.dasherize("app-".concat(appId));
      }

      return result;
    }),
    appInstanceMarkerClass: computed('instance.instanceId', function () {
      var result = '';
      var appInstanceId = this.get('instance.instanceId');

      if (appInstanceId) {
        result = Ember.String.dasherize("app-instance-".concat(appInstanceId));
      }

      return result;
    }),
    ariaHiddenAttr: computed('hidden', function () {
      return this.get('hidden') ? 'true' : 'false';
    }),
    hiddenAttr: computed('hidden', function () {
      return this.get('hidden') ? true : null;
    }),
    appEmbedName: computed('appInstanceMarkerClass', 'appMarkerClass', function () {
      return this.get('appInstanceMarkerClass') || this.get('appMarkerClass') || 'app-instance-unknown';
    }),
    actions: {
      onAppLoad: function onAppLoad() {
        this.get('instance').loaded();
      },
      onAppApiCall: function onAppApiCall(apiCall) {
        var handler = this.get('onAppInstanceApiCall');

        if (handler && typeof handler === 'function') {
          apiCall.set('srcAppInstance', this.get('instance'));
          handler(apiCall);
        }
      },
      reloadUpdatedApp: function reloadUpdatedApp() {
        var reloadFn = this.get('reloadUpdatedApp');

        if (reloadFn) {
          reloadFn(this.get('instance'));
        }
      },
      closeDeletedApp: function closeDeletedApp() {
        var closeFn = this.get('closeDeletedApp');

        if (closeFn) {
          closeFn(this.get('instance'));
        }
      }
    },
    instanceStarting: computed('instance.currState', function () {
      var currState = this.get('instance.currState');
      return _appInstance.APP_STATES.RUNNING.compareTo(currState) > 0;
    }),
    licenseUnavailable: computed('instance.problem.name', function () {
      return this.get('instance.problem.name') === _appInstance.APP_PROBLEMS.LICENSE_UNAVAILABLE;
    }),
    instanceUpdated: computed('instance.problem.name', function () {
      return this.get('instance.problem.name') === _appInstance.APP_PROBLEMS.APP_UPDATED;
    }),
    instanceDeleted: computed('instance.problem.name', function () {
      return this.get('instance.problem.name') === _appInstance.APP_PROBLEMS.APP_DELETED;
    }),
    exclusiveAppProblem: computed('instance.problem.name', function () {
      return this.get('instance.problem.name') === _appInstance.APP_PROBLEMS.LICENSE_UNAVAILABLE;
    }),
    appVisible: computed('hidden', 'instance', 'instance.currState', 'exclusiveAppProblem', function () {
      var result = false;
      var instance = this.get('instance');

      if (!this.get('hidden') && instance) {
        result = instance.get('currState') === _appInstance.APP_STATES.RUNNING && !this.get('exclusiveAppProblem');
      }

      return result;
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var prevAppInstance = this.get('_prevInstance');
      var currAppInstance = this.get('instance');

      if (currAppInstance !== prevAppInstance) {
        this._unsubscribe(prevAppInstance);

        this.setProperties({
          _targetAppIframe: null,
          _targetAppWindow: null,
          _targetAppOrigin: null
        });

        this._subscribe(currAppInstance);

        this.set('_prevInstance', currAppInstance);
      }
    },
    willRender: function willRender() {
      this._super.apply(this, arguments);

      var $iFrame = this.$('iframe');

      if (!$iFrame || $iFrame.length !== 1) {
        this.setProperties({
          _targetAppIframe: null,
          _targetAppWindow: null,
          _targetAppOrigin: null
        });
      } else if ($iFrame[0] !== this._targetAppIframe) {
        this._targetAppIframe = $iFrame[0] || null;
        this._targetAppWindow = $iFrame[0].contentWindow || null;
        this._targetAppOrigin = null;

        if (this._targetAppIframe && this._targetAppIframe.src && document && document.createElement) {
          try {
            var parser = document.createElement('a');
            parser.href = this._targetAppIframe.src;
            var port = parser.port && parser.port.trim().length > 0 ? ":".concat(parser.port) : '';
            this._targetAppOrigin = "".concat(parser.protocol, "//").concat(parser.hostname).concat(port);
          } catch (e) {// ignored; origin already set to null
          }
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._unsubscribe(this.get('instance'));
    },
    _subscribe: function _subscribe(appInstance) {
      if (appInstance != null) {
        appInstance.on(_appInstance.LIFECYCLE_HOOK_EVENT, this, this._onLifecycleHook);
      }
    },
    _unsubscribe: function _unsubscribe(appInstance) {
      if (appInstance != null) {
        appInstance.off(_appInstance.LIFECYCLE_HOOK_EVENT, this, this._onLifecycleHook);
      }
    },
    _onLifecycleHook: function _onLifecycleHook(payload) {
      run.schedule('afterRender', this, '_sendAppNotification', payload.hook);
    },
    _sendAppNotification: function _sendAppNotification(hook) {
      if (this.get('isDestroying') || this.get('isDestroyed') || this._targetAppWindow === null || this._targetAppOrigin === null || this.get('appInstance.currState') <= _appInstance.APP_STATES.LOADING) {
        return;
      }

      if (!this.get('appsSdkEnabled')) {
        var msg = 'Client App SDK is disabled. Lifecycle hook events will not be triggered.';
        Ember.Logger.warn(_apps.default.buildApiLogMsg(msg), {
          appUrl: this.get('instance.app.url'),
          remoteData: {
            appId: this.get('instance.appId')
          }
        });
        return;
      }

      this._targetAppWindow.postMessage((0, _appApiCall.buildOutboundMsgPayload)('appLifecycleHook', {
        hook: hook
      }), this._targetAppOrigin);
    }
  });

  _exports.default = _default;
});