define("web-directory/components/acd-interactions-panel/interaction-notes/interaction-notes-history/interaction-notes-history-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B/Cas7nZ",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"header\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"avatar\"],[13],[0,\"\\n        \"],[1,[33,[\"entity-image\"],null,[[\"entity\",\"enableMiniCard\",\"microCard\",\"size\",\"isLinkable\",\"showPresence\",\"canViewChat\",\"subscribeToPresence\"],[[28,[\"item\",\"person\"]],true,true,48,true,false,[28,[\"canViewChat\"]],true]]],false],[0,\"\\n    \"],[14],[0,\"\\n\"],[6,[\"if\"],[[33,[\"and\"],[[28,[\"item\",\"person\"]],[28,[\"item\",\"person\",\"name\"]]],null]],null,{\"statements\":[[0,\"        \"],[11,\"p\",[]],[15,\"class\",\"author\"],[13],[0,\"\\n            \"],[1,[28,[\"item\",\"person\",\"name\"]],false],[0,\"\\n        \"],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"        \"],[11,\"p\",[]],[15,\"class\",\"author\"],[13],[0,\"\\n            \"],[1,[33,[\"t\"],[\"purecloud.acdInteraction.relate.misc.authorUnknown\"],null],false],[0,\"\\n        \"],[14],[0,\"\\n\"]],\"locals\":[]}],[0,\"    \"],[11,\"p\",[]],[15,\"class\",\"date\"],[13],[0,\"\\n        \"],[1,[33,[\"moment-format\"],[[28,[\"item\",\"endTime\"]],\"lll\"],null],false],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[11,\"p\",[]],[15,\"class\",\"text\"],[13],[1,[28,[\"item\",\"notes\"]],false],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/acd-interactions-panel/interaction-notes/interaction-notes-history/interaction-notes-history-item/template.hbs"
    }
  });

  _exports.default = _default;
});