define("web-directory/components/group-profile/group-contact-panel/group-ring-settings/inline-number-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NHU5vX22",
    "block": "{\"statements\":[[1,[33,[\"t\"],[[28,[\"key\"]]],null],true]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/group-profile/group-contact-panel/group-ring-settings/inline-number-input/template.hbs"
    }
  });

  _exports.default = _default;
});