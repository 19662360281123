define("web-directory/services/contactlist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Service = Ember.Service,
      inject = Ember.inject;
  var uris = {
    base: "/platform/api/v2/outbound/contactlists",
    contactList: function contactList(contactListId) {
      return uris.base + "/".concat(contactListId);
    },
    contact: function contact(contactListId, contactId) {
      return uris.contactList(contactListId) + "/contacts/".concat(contactId);
    }
  };

  var _default = Service.extend({
    ajax: inject.service(),
    getContact: function getContact(contactListId, contactId) {
      return this.get('ajax').ajaxGet(uris.contact(contactListId, contactId));
    }
  });

  _exports.default = _default;
});