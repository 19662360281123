define("web-directory/components/acd-interactions-panel/interaction-notes/interaction-notes-history/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['interaction-notes-history'],

    /**
     * An array of the history of notes taken during this interaction. Should be arranged based on endTime.
     */
    notesHistory: null,

    /**
     * `true` if the user wants the notes history to be shown. Can be toggled on and off from user input.
     */
    showNotesHistory: true,

    /**
     * `true` if the current user can view the chat when clicking on the authors avatar
     */
    canViewChat: false,
    actions: {
      /**
       * Toggles the `showNotesHistory` on and off and updates the visuals to the user based on the value
       */
      toggleShowHistory: function toggleShowHistory() {
        this.toggleProperty('showNotesHistory');
      }
    }
  });

  _exports.default = _default;
});