define("web-directory/components/group-profile/roles-edit/fixtable/division-select/select-footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QUdpniud",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"select-footer\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[13],[0,\"\\n        \"],[11,\"span\",[]],[16,\"class\",[33,[\"if\"],[[28,[\"allFutureDivisions\"]],\"btn btn-link select-all-divisions disabled\",\"btn btn-link select-all-divisions\"],null],null],[15,\"tabindex\",\"0\"],[15,\"role\",\"button\"],[5,[\"action\"],[[28,[null]],\"selectAll\"]],[13],[0,\" \"],[1,[33,[\"t\"],[\"groups.roles.selectFooter.selectAll\"],null],false],[0,\" \"],[14],[0,\"\\n        |\\n        \"],[11,\"span\",[]],[15,\"class\",\"btn btn-link deselect-all-divisions\"],[15,\"tabindex\",\"0\"],[15,\"role\",\"button\"],[5,[\"action\"],[[28,[null]],\"deselectAll\"]],[13],[0,\"\\n            \"],[1,[33,[\"t\"],[\"groups.roles.selectFooter.deselectAll\"],null],false],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n    \"],[11,\"div\",[]],[13],[0,\"\\n        \"],[1,[33,[\"input\"],null,[[\"id\",\"type\",\"disabled\",\"checked\"],[[33,[\"concat\"],[[28,[\"elementId\"]],\"-division-toggle\"],null],\"checkbox\",[33,[\"not\"],[[33,[\"or\"],[[28,[\"allFutureDivisions\"]],[28,[\"allDivisionsSelected\"]]],null]],null],[28,[\"allFutureDivisions\"]]]]],false],[0,\"\\n        \"],[11,\"label\",[]],[16,\"for\",[34,[[26,[\"elementId\"]],\"-division-toggle\"]]],[13],[0,\" \"],[1,[33,[\"t\"],[\"groups.roles.selectFooter.allFutureDivisions\"],null],false],[0,\" \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/group-profile/roles-edit/fixtable/division-select/select-footer/template.hbs"
    }
  });

  _exports.default = _default;
});