define("web-directory/mixins/acd/participants", ["exports", "lodash/findLast", "lodash/last", "lodash/reject", "web-directory/utils/acd"], function (_exports, _findLast, _last, _reject, _acd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MACHINE_PARTICIPANTS = ['acd', 'ivr', 'dialer.system'];
  var CUSTOMER_PARTICIPANTS = ['manual', 'inbound', 'dialer', 'customer', 'external'];
  var MEDIA_TYPES = ['chats', 'calls', 'callbacks', 'emails', 'screenshares', 'cobrowsesessions', 'videos'];

  var _default = Ember.Mixin.create({
    session: Ember.inject.service('session'),
    isCustomerParticipant: function isCustomerParticipant(participant) {
      return CUSTOMER_PARTICIPANTS.includes(participant.purpose);
    },
    isMachineParticipant: function isMachineParticipant(participant) {
      return MACHINE_PARTICIPANTS.includes(participant.purpose);
    },
    isAgentParticipant: function isAgentParticipant(participant) {
      return participant.purpose === 'agent';
    },
    isCurrentUserOrMachineParticipant: function isCurrentUserOrMachineParticipant(participant) {
      return (participant.purpose === 'user' || participant.purpose === 'agent') && participant.userId === this.get('participantForCurrentUser.userId') || participant.userId === this.get('session.person.guid') || this.isMachineParticipant(participant);
    },

    /**
     * Determines who the host participant should be based on the participant array order
     * and if the participant is an agent and is also human and connected.
     *
     * Currently returning `null` to eliminate any role assumptions for participants.
     * Keeping this around to expand upon when the backend implements participant roles
     * for a conversation.
     */
    hostParticipant: Ember.computed('allHumanConnectedParticipants.[]', function () {
      return null; // TODO: Update when the backend provides the participant roles
      // const participants = this.get('allHumanConnectedParticipants') || [];
      // for (const participant of participants) {
      //     const isAgent = participant.purpose === 'agent';
      //     if (isAgent) {
      //         return participant;
      //     }
      // }
    }),
    // Human Participants with purpose 'manual', 'inbound', 'dialer', 'customer', or 'external
    customerParticipants: Ember.computed('participants.@each.purpose', function () {
      var _this = this;

      var participants = this.get('participants') || [];
      return participants.filter(function (participant) {
        return _this.isCustomerParticipant(participant);
      });
    }),
    agentParticipants: Ember.computed('participants.@each.purpose', function () {
      var _this2 = this;

      var participants = this.get('participants') || [];
      return participants.filter(function (participant) {
        return _this2.isAgentParticipant(participant);
      });
    }),
    // Human participants that are not the current user
    otherParticipants: Ember.computed('participants.@each.{purpose,userId}', function () {
      var _this3 = this;

      return (0, _reject.default)(this.get('participants'), function (participant) {
        return _this3.isCurrentUserOrMachineParticipant(participant);
      });
    }),
    // Connected Human Participants and customers (regardless of connection status)
    otherConnectedParticipants: Ember.computed('otherParticipants.@each.endTime', function () {
      return (0, _reject.default)(this.get('otherParticipants'), function (participant) {
        // Reject participant if disconnected or a monitoring
        // participant and not a customer.
        var isDisconnected = !!participant.endTime; // TODO: The public api will eventually filter these out.
        // This can be removed when that is done.

        var isMonitored = !!participant.monitoredParticipantId;
        var isCoached = !!participant.coachedParticipantId;
        var isBarged = !!participant.bargedParticipantId;
        return isDisconnected || isMonitored || isCoached || isBarged;
      });
    }),
    // Connected Human Participants and customers (regardless of connection status)
    allHumanConnectedParticipants: Ember.computed('participants.@each.{purpose,userId,endTime}', function () {
      var _this4 = this;

      return (0, _reject.default)(this.get('participants'), function (participant) {
        var isDisconnected = !!participant.endTime;
        var isMonitored = !!participant.monitoredParticipantId;
        var isCoached = !!participant.coachedParticipantId;
        return isDisconnected || isMonitored || isCoached || _this4.isMachineParticipant(participant);
      });
    }),

    /**
     * All participants that should be showing up in a potential conference.
     * Note that the participant that is being connecting should not show up if they are being connected
     * through and queue that is also connected.
     */
    allConferenceParticipants: Ember.computed('participants.[]', 'participants.@each.{purpose,userId,endTime,queueId,coachedParticipantId,monitoredParticipantId}', function () {
      var _this5 = this;

      var participants = this.get('participants') || [];
      var connectingQueue = new Map();
      return participants.filter(function (participant) {
        if (_this5.isMachineParticipant(participant)) {
          // Filter out any callback typed machine participants
          if (participant['callbacks']) {
            return false;
          } else if (participant.endTime) {
            connectingQueue.delete(participant.queueId);
          } else if (!connectingQueue.has(participant.queueId)) {
            connectingQueue.set(participant.queueId, participant);
            return true;
          }
        }

        if (participant.endTime || participant.monitoredParticipantId || participant.coachedParticipantId) {
          return false;
        } else if (connectingQueue.has(participant.queueId)) {
          return false;
        }

        return true;
      });
    }),
    participantForCustomer: Ember.computed('participants.@each.{purpose,userId,endTime,monitoredParticipantId,coachedParticipantId,bargedParticipantId}', 'session.person.guid', 'onConsult', 'consultParticipant', 'otherConnectedParticipants.[]', 'isDisconnected', 'isTerminated', 'isCall', function () {
      var _this6 = this;

      var consultParticipantId = this.get('consultParticipantId');

      if (consultParticipantId) {
        var participants = this.get('participants') || [];
        var trueCustomerParticipant = participants.findBy('id', consultParticipantId);

        if (trueCustomerParticipant) {
          return trueCustomerParticipant;
        }
      }

      if (this.get('onConsult') && this.get('consultParticipant')) {
        return this.get('consultParticipant');
      }

      var participantCaller = null;
      var customer = (0, _findLast.default)(this.get('participants'), function (participant) {
        var isCurrentUser = _this6.get('session.person.guid') === participant.userId;
        /**
         * Solely used for determining the edge-case of a queue calling a queue.
         * We do not want to return the current user
         */
        // TODO: Remove the `else if` portion of this statement when the `if` feature toggle is removed.

        if (!isCurrentUser) {
          if (_this6.get('session.features.interactionNameQueueCallingQueue') && participant.purpose === 'acd' && Ember.get(participant, 'calls.firstObject.direction') === 'inbound' || participant.purpose === 'agent' && Ember.get(participant, 'calls.firstObject.direction') === 'outbound') {
            participantCaller = participant;
          }
        }

        var isExternalCustomer = CUSTOMER_PARTICIPANTS.includes(participant.purpose);
        var isInternalCustomer = !isCurrentUser && participant.purpose === 'user';
        return !participant.monitoredParticipantId && !participant.coachedParticipantId && !participant.bargedParticipantId && (isExternalCustomer || isInternalCustomer);
      });

      if (customer && customer.endTime) {
        var nextConnectedParticipant = this.get('otherConnectedParticipants.firstObject');
        var isDisconnected = this.get('isDisconnected') || this.get('isTerminated');

        if (nextConnectedParticipant && !isDisconnected) {
          return nextConnectedParticipant;
        }
      }
      /**
       * If we still don't have a customer at this point
       * then consider the scenario where this may be an agent
       * calling a queue on behalf of a queue so we are identifying
       * the caller as the `customer`
       */


      if (!customer && this.get('isCall') && participantCaller) {
        return participantCaller;
      }

      if (customer) {
        /**
         * if there are multiple customers in the participants list the customer returned above is likely incorrect
         * if it is not a user participant. In that case the getTrueCustomerIfMultipleCustomersExist will return the
         * last connected true customer participant - if there is only one customer participant - getTrueCustomerIfMultipleCustomersExist
         * returns nothing and the value of customer falls through
         */
        var customerParticipant = (0, _acd.getTrueCustomerIfMultipleCustomersExist)(this);

        if (customer.purpose !== 'user' && customerParticipant) {
          return customerParticipant;
        }
      }

      return customer;
    }),

    /**
     * Returns all of the active participants. Active is described as trying to connect or connected.
     * It does not include any participants who have an endTime.
     * The id's returned supported external, user, or queue with a regular `id` fallback.
     *
     * [ACE-3276] If an incoming customer who is not associated with an external contact yet may not have
     * any other form of id other than the base `id` or `queueId` it came from.
     */
    activeParticipantIds: Ember.computed('participants.[]', function () {
      var participants = this.get('participants') || [];
      return participants.reduce(function (filtered, participant) {
        var endTime = participant.endTime,
            externalContactId = participant.externalContactId,
            userId = participant.userId,
            queueId = participant.queueId,
            id = participant.id,
            purpose = participant.purpose;

        if (!endTime) {
          if (['agent', 'user'].includes(purpose) && userId) {
            filtered.push(userId);
          } else if (['acd'].includes(purpose) && queueId) {
            filtered.push(queueId);
          } else if (CUSTOMER_PARTICIPANTS.includes(purpose) && externalContactId) {
            filtered.push(externalContactId);
          } else {
            filtered.push(id);
          }
        }

        return filtered;
      }, []);
    }),
    participantsForCurrentUser: Ember.computed('participants.@each.{purpose,userId}', 'session.person.guid', function () {
      var _this7 = this;

      var participants = this.get('participants') || [];
      return participants.filter(function (participant) {
        var purpose = participant.purpose;
        var participantUserId = participant.userId;

        var userId = _this7.get('session.person.guid');

        return userId === participantUserId && (purpose === 'user' || purpose === 'agent');
      });
    }),
    getFirstStuckParticipant: function getFirstStuckParticipant() {
      var _this8 = this;

      var currentUserParticipants = this.get('participantsForCurrentUser');
      var stuckParticipant = currentUserParticipants.find(function (participant) {
        var hasActivePrimary = _this8._doesParticipantHaveActivePrimaryMedia(participant);

        var hasActiveSessionShare = _this8._doesParticipantHaveActiveSessionShare(participant);

        return !hasActivePrimary && hasActiveSessionShare;
      });
      return stuckParticipant;
    },
    participantForCurrentUser: Ember.computed('participantsForCurrentUser.@each.wrapup', function () {
      var currentUserParticipants = this.get('participantsForCurrentUser'); // alerting interactions

      var lastAlertingParticipant = (0, _findLast.default)(currentUserParticipants, function (participant) {
        return (0, _findLast.default)(MEDIA_TYPES, function (type) {
          if (participant[type]) {
            return (0, _findLast.default)(participant[type], function (media) {
              return media.state.toLowerCase() === 'alerting';
            });
          }
        });
      });

      if (lastAlertingParticipant) {
        return lastAlertingParticipant;
      } // non-disconnected interactions


      var lastNonDisconnectedParticipant = (0, _findLast.default)(currentUserParticipants, function (participant) {
        return (0, _findLast.default)(MEDIA_TYPES, function (type) {
          if (participant[type]) {
            return (0, _findLast.default)(participant[type], function (media) {
              return media.state.toLowerCase() !== 'disconnected' && media.state.toLowerCase() !== 'terminated';
            });
          }
        });
      });

      if (lastNonDisconnectedParticipant) {
        return lastNonDisconnectedParticipant;
      } // disconnected interactions


      var lastCurrentUserNeedingWrapup = (0, _findLast.default)(currentUserParticipants, function (participant) {
        return participant.wrapupRequired && !participant.wrapup;
      }); // If everyone has a wrapup code

      if (!lastCurrentUserNeedingWrapup) {
        return (0, _last.default)(currentUserParticipants);
      } // If participant needs wrapup
      // return lastCurrentUserNeedingWrapup;
      // If we reach this point then this means that:
      // 1) All agent participants have wrapped up and therefore should be disconnected
      // 2) All agent participants have no active callbacks
      // In this case we are (may be) dealing with a stuck interaction that conversation service still believes is active.
      // Let's get the first participant for the agent with a stuck interaction
      // TODO: confirm that first object is latest added by conversation service.


      return lastCurrentUserNeedingWrapup || this.getFirstStuckParticipant();
    }),
    participantsForACD: Ember.computed('participants.@each.purpose', function () {
      var participants = this.get('participants') || [];
      return participants.filter(function (participant) {
        return participant.purpose === 'acd';
      });
    }),
    participantForQueue: Ember.computed.alias('participantsForACD.lastObject'),
    dialerParticipant: Ember.computed('participants.@each.purpose', function () {
      var participants = this.get('participants') || [];
      return participants.find(function (participant) {
        return participant.purpose === 'dialer';
      });
    }),

    /**
     * Iterates through the participants in reverse to find the last/newest participant that is
     * considered either a machine or agent. Used to determine if the interaction is being transferred.
     * The return breaks the for loop in this instance to it will only iterates as long as it needs to.
     */
    transferringToParticipant: Ember.computed('participants.[]', 'activeAttendedTransfer', function () {
      var activeTransfer = this.get('activeAttendedTransfer');
      var destination = activeTransfer ? activeTransfer.destination : null;
      if (!destination) return null;
      var participants = this.get('participants') || []; // we want to look at the participants in reverse order to find newest reference

      for (var i = participants.length - 1; i >= 0; i--) {
        var participant = participants[i];

        if (participant.userId === destination.userId) {
          return participant;
        }
      } // participant not found.


      return null;
    })
  });

  _exports.default = _default;
});