define("web-directory/cldrs/fi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*jslint eqeq: true*/
  var _default = [{
    "locale": "fi",
    "pluralRuleFunction": function pluralRuleFunction(n, ord) {
      var s = String(n).split("."),
          v0 = !s[1];
      if (ord) return "other";
      return n == 1 && v0 ? "one" : "other";
    },
    "fields": {
      "year": {
        "displayName": "vuosi",
        "relative": {
          "0": "tänä vuonna",
          "1": "ensi vuonna",
          "-1": "viime vuonna"
        },
        "relativeTime": {
          "future": {
            "one": "{0} vuoden päästä",
            "other": "{0} vuoden päästä"
          },
          "past": {
            "one": "{0} vuosi sitten",
            "other": "{0} vuotta sitten"
          }
        }
      },
      "month": {
        "displayName": "kuukausi",
        "relative": {
          "0": "tässä kuussa",
          "1": "ensi kuussa",
          "-1": "viime kuussa"
        },
        "relativeTime": {
          "future": {
            "one": "{0} kuukauden päästä",
            "other": "{0} kuukauden päästä"
          },
          "past": {
            "one": "{0} kuukausi sitten",
            "other": "{0} kuukautta sitten"
          }
        }
      },
      "day": {
        "displayName": "päivä",
        "relative": {
          "0": "tänään",
          "1": "huomenna",
          "2": "ylihuomenna",
          "-2": "toissa päivänä",
          "-1": "eilen"
        },
        "relativeTime": {
          "future": {
            "one": "{0} päivän päästä",
            "other": "{0} päivän päästä"
          },
          "past": {
            "one": "{0} päivä sitten",
            "other": "{0} päivää sitten"
          }
        }
      },
      "hour": {
        "displayName": "tunti",
        "relativeTime": {
          "future": {
            "one": "{0} tunnin päästä",
            "other": "{0} tunnin päästä"
          },
          "past": {
            "one": "{0} tunti sitten",
            "other": "{0} tuntia sitten"
          }
        }
      },
      "minute": {
        "displayName": "minuutti",
        "relativeTime": {
          "future": {
            "one": "{0} minuutin päästä",
            "other": "{0} minuutin päästä"
          },
          "past": {
            "one": "{0} minuutti sitten",
            "other": "{0} minuuttia sitten"
          }
        }
      },
      "second": {
        "displayName": "sekunti",
        "relative": {
          "0": "nyt"
        },
        "relativeTime": {
          "future": {
            "one": "{0} sekunnin päästä",
            "other": "{0} sekunnin päästä"
          },
          "past": {
            "one": "{0} sekunti sitten",
            "other": "{0} sekuntia sitten"
          }
        }
      }
    }
  }];
  _exports.default = _default;
});