define("web-directory/components/navigation-org-switcher/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['org-switcher'],
    session: Ember.inject.service(),
    isShowingInfo: Ember.computed.or('session.user.isTrustee', 'session.user.isCloned'),
    init: function init() {
      var _this = this;

      if (this.get('isShowingInfo')) {
        this.get('session').refreshPublicApiUser(['organization']).then(function (data) {
          if (data) {
            _this.set('orgName', data.organization.name.toUpperCase());

            if (_this.get('session.user.isCloned')) {
              _this.set('orgId', data.token.clonedUser.organization.id);
            } else {
              _this.set('orgId', data.token.homeOrganization.id);
            }
          }
        });
      }

      this._super.apply(this, arguments);
    },
    actions: {
      showDrawer: function showDrawer() {
        this.toggleProperty('isShowingDrawer');
      },
      logout: function logout() {
        this.set('isShowingDrawer', false);

        if (this.get('orgId')) {
          this.get('session').proxyLogin(this.get('orgId'), '#/partners/clients');
        } else {
          this.get('session').logout();
        }
      }
    }
  });

  _exports.default = _default;
});