define("web-directory/components/group-profile/roles-edit/fixtable/division-select/selected-division/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R343Bviu",
    "block": "{\"statements\":[[6,[\"if\"],[[33,[\"eq\"],[[28,[\"option\",\"id\"]],\"*\"],null]],null,{\"statements\":[[0,\"    \"],[1,[33,[\"t\"],[\"groups.roles.allDivisions\"],null],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"    \"],[1,[28,[\"option\",\"name\"]],false],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/group-profile/roles-edit/fixtable/division-select/selected-division/template.hbs"
    }
  });

  _exports.default = _default;
});