define("web-directory/components/acd-interactions-panel/interaction-session-share/session-share-info/component", ["exports", "ember-purecloud-components/mixins/logger", "web-directory/utils/dates"], function (_exports, _logger, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject,
      run = Ember.run,
      Component = Ember.Component;

  var _default = Component.extend(_logger.default, {
    classNames: ['session-share-info'],
    classNameBindings: ['isSessionShareConnected:is-connected', 'isWaitingForCustomer:is-waiting', 'isSessionShareDisconnected:is-disconnected', 'showTimerOnly:timer-only', 'showAlways:show-always', 'isCoBrowse'],
    phone: inject.service(),
    fullscreen: inject.service(),
    interactionType: computed.reads('interaction.type'),
    isSessionShareConnected: computed.reads('interaction.isSessionShareConnected'),
    isSessionShareDisconnected: computed.reads('interaction.isAgentDisconnectedFromSharedSession'),
    showTimerOnly: false,
    showAlways: true,
    interaction: null,
    isCall: computed.reads('interaction.isCall'),
    isConnected: computed.reads('interaction.isConnected'),
    isCoBrowse: computed.reads('interaction.isCoBrowse'),
    actions: {
      changeState: function changeState(state) {
        this.get('phone').handlePhoneStatusChange(state, this.get('interaction.conversation'));
      },
      exitFullScreen: function exitFullScreen() {
        this.get('fullscreen').endFullScreen();
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.get('interaction')) {
        this.setWaitingTimer();
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('interaction')) {
        this.setUpTooltips();
      }
    },
    setUpTooltips: function setUpTooltips() {
      this.$('[data-toggle="tooltip"]').each(function (i, el) {
        var tooltipClass = $(el).data('tooltip-class');
        $(el).tooltip({
          trigger: 'hover'
        }).on('inserted.bs.tooltip', function () {
          var _this = this;

          Ember.run(function () {
            var tooltipId = $(_this).attr('aria-describedby');
            $("#".concat(tooltipId)).addClass(tooltipClass);
          });
        });
      });
    },
    _fixTooltips: function _fixTooltips() {
      var tooltips = this.$('[data-toggle="tooltip"]');

      if (tooltips) {
        tooltips.tooltip('fixTitle').tooltip('setContent').each(function (i, el) {
          if ($(el).is(':hover')) {
            $(el).tooltip('show');
          }
        });
      }
    },
    muteTooltipTitle: computed('interaction.conversation.isMuted', function () {
      run.scheduleOnce('afterRender', this, this._fixTooltips);

      if (this.get('interaction.conversation.isMuted')) {
        return 'purecloud.acdInteraction.unmute';
      }

      return 'purecloud.acdInteraction.mute';
    }),
    holdTooltipTitle: computed('interaction.conversation.isHeld', function () {
      run.scheduleOnce('afterRender', this, this._fixTooltips);

      if (this.get('interaction.conversation.isHeld')) {
        return 'purecloud.acdInteraction.unhold';
      }

      return 'purecloud.acdInteraction.hold';
    }),
    willDestroyElement: function willDestroyElement() {
      //Cleanup tooltips
      this._super.apply(this, arguments);

      this.$('[data-toggle="tooltip"]').tooltip('destroy');
    },
    isWaitingForAgentToConnect: computed.reads('interaction.isWaitingForAgentToConnect'),
    connectedTime: computed.reads('interaction.sessionShareConnectedTime'),
    waitingTimer: null,
    // Waiting for customer timer
    setWaitingTimer: function setWaitingTimer() {
      if (!this.get('interaction.hasSessionShareCode') && !this.get('waitingTimer')) {
        this.set('waitingTimer', (0, _dates.default)().format());
      }
    },
    sessionShareTypeIcon: computed('interaction.{sessionShareType,isDisconnected,isTerminated}', function () {
      var type = this.get('interaction.sessionShareType');
      var isDisconnected = this.get('interaction.isDisconnected') || this.get('interaction.isTerminated');

      switch (type) {
        case 'screen-share':
          if (isDisconnected) {
            return 'pc-moon pc-interaction-session-share';
          }

          return 'pc-moon pc-interaction-session-share';

        case 'video':
          if (isDisconnected) {
            return 'pc-moon pc-interaction-video-off';
          }

          return 'pc-moon pc-interaction-video';

        case 'co-browse':
          if (isDisconnected) {
            return 'pc-moon pc-interaction-session-share';
          }

          return 'pc-moon pc-interaction-session-share';

        default:
          return '';
      }
    })
  });

  _exports.default = _default;
});