define("web-directory/utils/acdParticipantStatuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var participantStatuses = {
    ALERTING: 'ALERTING',
    CONNECTING: 'CONNECTING',
    CONNECTED: 'CONNECTED',
    CONTACTING: 'CONTACTING',
    DIALING: 'DIALING',
    DISCONNECTED: 'DISCONNECTED',
    PARKED: 'PARKED',
    OFFERING: 'OFFERING',
    TERMINATED: 'TERMINATED',
    TRANSMITTING: 'TRANSMITTING'
  };
  var _default = participantStatuses;
  _exports.default = _default;
});