define("web-directory/components/acd-interactions-panel/interaction-conference-hangup-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GqqSCapU",
    "block": "{\"statements\":[[11,\"button\",[]],[16,\"onclick\",[33,[\"action\"],[[28,[null]],\"onEndForAll\"],null],null],[15,\"type\",\"button\"],[13],[0,\"\\n    \"],[1,[33,[\"t\"],[\"purecloud.acdInteraction.hangupDropdown.endForAll\"],null],false],[0,\"\\n\"],[14],[0,\"\\n\"],[11,\"button\",[]],[16,\"onclick\",[33,[\"action\"],[[28,[null]],\"onLeave\"],null],null],[15,\"type\",\"button\"],[13],[0,\"\\n    \"],[1,[33,[\"t\"],[\"purecloud.acdInteraction.hangupDropdown.leave\"],null],false],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/acd-interactions-panel/interaction-conference-hangup-dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});