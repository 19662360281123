define("web-directory/services/apps-api-handlers/toast-handler", ["exports", "lodash/pick", "lodash/pickBy", "lodash/mapKeys", "lodash/omit"], function (_exports, _pick, _pickBy, _mapKeys, _omit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SHOW_TOAST_APP_API_ACTION_NAME = void 0;
  var RSVP = Ember.RSVP,
      inject = Ember.inject;
  var SHOW_TOAST_APP_API_ACTION_NAME = 'showToast';
  _exports.SHOW_TOAST_APP_API_ACTION_NAME = SHOW_TOAST_APP_API_ACTION_NAME;
  var TOAST_MSG_PROPS = {
    id: {
      type: 'string',
      internalPropName: 'tag'
    },
    type: {
      type: 'enum',
      values: ['info', 'success', 'error'],
      required: true
    },
    title: {
      type: 'string',
      required: true
    },
    message: {
      type: 'string',
      required: true
    },
    markdownMessage: {
      type: 'boolean',
      internalPropName: 'markdownToHtml'
    },
    timeout: {
      type: 'int',
      internalPropName: 'hideAfter'
    },
    showCloseButton: {
      type: 'boolean'
    },
    fallbackToInBrowser: {
      type: 'boolean'
    },
    forceBrowserNotification: {
      type: 'boolean'
    },
    showNotificationEverywhere: {
      type: 'boolean'
    }
  };
  var TOAST_MSG_PROP_NAMES = Object.keys(TOAST_MSG_PROPS);
  var REQUIRED_TOAST_MSG_PROP_NAMES = TOAST_MSG_PROP_NAMES.filter(function (prop) {
    return TOAST_MSG_PROPS[prop].required;
  });

  var _default = Ember.Object.extend({
    notification: inject.service(),
    stashLogger: Ember.inject.service(),

    /**
     * Synchronous check to see if this handler is applicable for the specified AppApiCall instance
     *
     * @param {Object} the AppApiCall instance to check if this handler can handle
     *
     * @returns true if the provided AppApiCall instance could be handled by this handler. This check should
     *  be limited to checking if the handler is responsible for this type of AppApiCall rather than if the
     *  payload itself is valid.  If no handler returns true, the error will be that no handler exists which is
     *  a different case than the AppApiCall is invalie. Practically, this means that validation of the payload
     *  should be done in the handle method
     */
    canHandle: function canHandle(appApiCall) {
      return appApiCall.get('action') === SHOW_TOAST_APP_API_ACTION_NAME;
    },

    /**
     * Handle the AppApiCall instance pre-filtered by canHandle
     *
     * @param {Object} An AppApiCall instance
     *
     * @returns A Promise resolving if the api action was successfully handled or rejecting with
     *  optional details as to why the event could not be handled.
     */
    handle: function handle(appApiCall) {
      var _this = this;

      return new RSVP.Promise(function (resolve, reject) {
        var notification = _this.get('notification');

        var stashLogger = _this.get('stashLogger');

        var appId = appApiCall.get('appId') || 'localApp';
        var apiActionDetails = appApiCall.get('actionDetails');

        var _this$_processToastPa = _this._processToastPayload(apiActionDetails),
            errors = _this$_processToastPa.errors,
            invalidProps = _this$_processToastPa.invalidProps,
            ignoredProps = _this$_processToastPa.ignoredProps,
            data = _this$_processToastPa.validatedToastData;

        if (errors.length > 0) {
          return reject(errors);
        }

        if (ignoredProps.length > 0) {
          var ignored = ignoredProps.join(', ');
          stashLogger.warn('The following props were ignored from your toast request:', {
            props: ignored
          });
        }

        if (invalidProps.length > 0) {
          var _ignored = invalidProps.join(', ');

          stashLogger.warn('The following props were invalid and ignored from your toast request:', {
            props: _ignored
          });
        }

        var fullTag = data.tag ? "".concat(appId, "_").concat(data.tag) : appId;
        data.tag = fullTag;

        if (!['info', 'error', 'success'].includes(data.type)) {
          return reject("Unsupported notification type: ".concat(data.type));
        }

        notification[data.type](data.title, data.message, data);
        return resolve();
      });
    },

    /**
     * Filters, renames, and validates the toast message payload
     *
     * @returns the filtered, validated, renamed payload (if successful) and:
     *  errors - unrecoverable errors
     *  ignoredProps - props not applicable for toast messages
     *  invalidProps - known props which were not provided in the correct format, etc.
     */
    _processToastPayload: function _processToastPayload(apiActionDetails) {
      var _this2 = this;

      var requiredCount = 0;
      var invalidProps = [];
      var errors = [];
      var filteredPickData = (0, _pick.default)(apiActionDetails, TOAST_MSG_PROP_NAMES);
      var filteredData = (0, _pickBy.default)(filteredPickData, function (value, prop) {
        var config = TOAST_MSG_PROPS[prop];

        var result = _this2._checkPropValid(config, value);

        if (!result) {
          invalidProps.push(prop);
        }

        if (config.required && result) {
          requiredCount++;
        }

        return result;
      });

      if (requiredCount !== REQUIRED_TOAST_MSG_PROP_NAMES.length) {
        errors.push('One or more of the following required toast message properties were missing or invalid' + REQUIRED_TOAST_MSG_PROP_NAMES.join(', '));
      }

      if (!filteredData.showCloseButton) {
        if (filteredData.type === 'error') {
          errors.push('Toasts with type error must have a close button');
        } else if (filteredData.timeout === 0) {
          errors.push('Toast must have either a nonzero timeout or a close button');
        }
      }

      var ignoredProps = Object.keys((0, _omit.default)(apiActionDetails, TOAST_MSG_PROP_NAMES));
      var validatedToastData = errors.length > 0 ? null : this._mapDataToInternalProperties(filteredData);
      return {
        errors: errors,
        invalidProps: invalidProps,
        ignoredProps: ignoredProps,
        validatedToastData: validatedToastData
      };
    },
    _mapDataToInternalProperties: function _mapDataToInternalProperties(data) {
      return (0, _mapKeys.default)(data, function (value, prop) {
        return TOAST_MSG_PROPS[prop].internalPropName || prop;
      });
    },
    _checkPropValid: function _checkPropValid(config, value) {
      if (!config) {
        return false;
      }

      switch (config.type) {
        case 'string':
          return typeof value === 'string';

        case 'int':
          return Number.isFinite(value) && parseInt(value, 10) === value;

        case 'boolean':
          return typeof value === 'boolean';

        case 'enum':
          return typeof value === 'string' && config.values.includes(value);

        default:
          return false;
      }
    }
  });

  _exports.default = _default;
});