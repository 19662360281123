define("web-directory/controllers/region-chooser", ["exports", "lodash", "web-directory/utils/net"], function (_exports, _lodash, _net) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller,
      inject = Ember.inject;

  var _default = Controller.extend({
    application: inject.service(),
    intl: inject.service(),
    desktopRegionId: null,
    actions: {
      chooseRegion: function chooseRegion() {
        var context = this.get('application.hostedContext');

        if (context.isHosted()) {
          context.sendRegionToDesktop(this.get('desktopRegionId'));
        }
      }
    },
    init: function init() {
      this._super.apply(this, arguments); // Set Initial/Default Region


      var defaultDesktopRegionId = 'global';
      var locale = this.get('intl.systemLocale');

      if (_lodash.default.includes(locale, 'au')) {
        defaultDesktopRegionId = 'au';
      } else if (_lodash.default.includes(locale, 'ja') || _lodash.default.includes(locale, 'zh')) {
        defaultDesktopRegionId = 'jp';
      }

      this.set('desktopRegionId', _lodash.default.find(_net.PUBLIC_ENVS, {
        desktopId: defaultDesktopRegionId
      }) ? defaultDesktopRegionId : _net.PUBLIC_ENVS[0].desktopId);
    }
  });

  _exports.default = _default;
});