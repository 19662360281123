define("web-directory/utils/sms/sms-properties", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SMS_PROP = {
    FILE_SIZE: 5
  };
  var _default = SMS_PROP;
  _exports.default = _default;
});