define("web-directory/serializers/video-communication", ["exports", "ember-purecloud-components/serializers/video-communication"], function (_exports, _videoCommunication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _videoCommunication.default;
    }
  });
});