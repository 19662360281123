define("web-directory/components/acd-interactions-panel/interaction-sms/message-cards/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WDyJAlh2",
    "block": "{\"statements\":[[6,[\"each\"],[[28,[\"cards\"]]],null,{\"statements\":[[0,\"    \"],[1,[33,[\"acd-interactions-panel/interaction-sms/message-cards/message-card\"],null,[[\"card\"],[[28,[\"card\"]]]]],false],[0,\"\\n\"]],\"locals\":[\"card\"]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/acd-interactions-panel/interaction-sms/message-cards/template.hbs"
    }
  });

  _exports.default = _default;
});