define("web-directory/models/chat-message", ["exports", "web-directory/utils/dates", "web-directory/mixins/chat-message-mixin", "web-directory/mixins/chat-message-media", "ember-purecloud-components/utils/jid-helpers"], function (_exports, _dates, _chatMessageMixin, _chatMessageMedia, _jidHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SECOND_TO_EDIT = 60;
  var ChatMessage = Ember.Object.extend(_chatMessageMedia.default, _chatMessageMixin.default, {
    /**
    Any new functionality that needs to be added to the Chat Message Model must
    be added to the ChatMessageMixin instead. Adding functionality to the model
    instead of the mixin will cause chat messages to break, and cant guarantee
    that message will render as they should.
    */
    // Start of chat message attrs
    id: null,
    oid: null,
    from: null,
    to: null,
    time: null,
    corrects: null,
    raw: null,
    links: null,
    stanza: null,
    files: null,
    // End of chat message attrs
    // set in services/chat.processNewChatMessage()
    chatUser: null,
    messageBody: null,
    hasMentionLinks: false,
    // set in services/chat.markAsHistory()
    history: false,
    // set in services/chat.setMessageReadState()
    unread: false,
    // set in chat-room.processCorrections()
    correction: null,
    startOfBlock: null,
    endOfBlock: null,
    // set in chat-room.addSelfReference()
    room: null,
    // set in chat-room.addMessage()/markConversationBlockBoundaries()
    isYourLastMessage: false,
    //set by services/chat.parseWidgetData()
    widget: null,
    widgetType: 'default',
    //set by chat-room.processSplitMessage()
    splitMessageIndex: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.determineMessageEditability();
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      Ember.run.cancel(this.get('isEditableTimeout'));
    },
    isSplitMessage: Ember.computed('stanza', function () {
      var children = this.get('stanza.children');
      return children.findBy('name', 'multipart-message') !== undefined;
    }),
    isEditable: false,
    editableEndTime: Ember.computed('time', function () {
      var time = this.get('time');

      if (time) {
        return (0, _dates.default)(time).add(SECOND_TO_EDIT, 'seconds');
      }

      return time;
    }),
    isEditing: Ember.computed('widgetType', function () {
      return 'editor' === this.get('widgetType');
    }),
    canEdit: Ember.computed('isEditable', 'isYou', 'isYourLastMessage', 'endOfBlock', 'pending', 'files.[]', 'correction.{isYou,isYourLastMessage,pending}', 'room.isAcdRoom', function () {
      if (!this.get('isEditable') || this.get('files.length') || this.get('room.isAcdRoom')) {
        return false;
      }

      var message = this.get('messageDisplay');
      return !message.get('pending') && message.get('isYou') && message.get('endOfBlock') && message.get('isYourLastMessage');
    }),
    meAction: Ember.computed('raw', 'correction.raw', function () {
      var raw = this.get('correction.raw') || this.get('raw');

      if (raw && raw.indexOf('/me ') === 0) {
        return raw.substr(4, raw.length);
      }

      return null;
    }),
    startEditing: function startEditing() {
      this.set('_originalWidgetType', this.get('widgetType'));
      this.set('widgetType', 'editor');
    },
    stopEditing: function stopEditing() {
      var originalWidgetType = this.get('_originalWidgetType');

      if (originalWidgetType && 'editor' === this.get('widgetType')) {
        this.set('widgetType', originalWidgetType);
      }
    },
    determineMessageEditability: function determineMessageEditability() {
      var _this = this;

      if (!this.get('corrects') && (0, _jidHelpers.compareJids)(this.get('from'), this.get('session.person.jid'))) {
        var now = (0, _dates.default)();
        var seconds = SECOND_TO_EDIT - now.diff(this.get('time'), 'seconds');
        var isEditable = seconds > 0;
        this.set('isEditable', isEditable);

        if (isEditable) {
          var isEditableTimeout = Ember.run.later(this, function () {
            _this.stopEditing();

            _this.set('isEditable', false);
          }, seconds * 1000);
          this.set('isEditableTimeout', isEditableTimeout);
        }
      }
    }
  });
  var _default = ChatMessage;
  _exports.default = _default;
});