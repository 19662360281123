define("web-directory/components/apps-sidebar-panel/component", ["exports", "lodash", "web-directory/models/app-pool", "ember-purecloud-components/mixins/logger"], function (_exports, _lodash, _appPool, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      RSVP = Ember.RSVP;

  var _default = Component.extend(_logger.default, {
    classNames: ['apps-sidebar-panel item-toggle'],
    session: Ember.inject.service(),
    // Attributes
    appPool: null,
    initialAutoSelect: false,
    initialSelectedAppId: null,
    appSelectionChanged: null,
    // Testing Attributes - Defaults will be used during normal runs
    showPanelControls: true,
    // Panel controls bootstrap lots of downstream dependencies that make mocking more difficult
    // Public Props
    widgetApps: [],
    // Private Props
    _prevPool: null,
    _initialSelectAttempted: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('widgetApps', []);

      this._processAppPoolAttr();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this._processAppPoolAttr();
    },
    actions: {
      onDeletedAppClose: function onDeletedAppClose() {
        // NICETOHAVE Select the last used app instead of first in the list
        this._autoSelect();
      }
    },
    itemToggled: function itemToggled(_, value) {
      this.setActive(value);
    },
    _processAppPoolAttr: function _processAppPoolAttr() {
      var _this = this;

      var prevPool = this.get('_prevPool');
      var currPool = this.get('appPool');

      if (prevPool !== currPool) {
        if (prevPool !== null) {
          prevPool.off(_appPool.APPS_RELOADED_EVENT, this, '_onAppsReloaded');
        }

        if (!currPool) {
          // Clear out the state so nothing shows
          this.set('widgetApps', []);
        } else {
          var initialAutoSelect = this.get('initialAutoSelect'); // Mark as attempted if no initial selection is requested

          this.set('_initialSelectAttempted', !initialAutoSelect);

          this._setWidgetApps().then(function () {
            if (initialAutoSelect) {
              _this._autoSelect(_this.get('initialSelectedAppId'));

              _this.set('_initialSelectAttempted', true);
            }
          }).catch(function (reason) {
            // Should only occur if the initial pool load failed
            // Log the failure but subscribe to the reload event to support the initial load recovery
            _this.logger.error('Failed to load widget apps for initial tab load', reason);
          }).finally(function () {
            currPool.on(_appPool.APPS_RELOADED_EVENT, _this, '_onAppsReloaded');
          });
        }

        this.set('_prevPool', currPool);
      }
    },
    _onAppsReloaded: function _onAppsReloaded() {
      var _this2 = this;

      if (this.get('isDestroying') || this.get('isDestroyed')) {
        return;
      }

      this._setWidgetApps().then(function () {
        // Only reattempt initial selection when recovering from initial load failure
        // _initialSelectAttempted is only false if an initial selection was requested and initial load failed
        if (!_this2.get('_initialSelectAttempted')) {
          _this2._autoSelect(_this2.get('initialSelectedAppId'));

          _this2.set('_initialSelectAttempted', true);
        }
      }).catch(function (reason) {
        // Pool and tab state should remain unchanged
        // Highly unlikely because the app fetch should have already been successful and the pool's backing promise should still be cached
        _this2.logger.error('Failed to load widget apps for tabs on pool reload event', reason);
      });
    },
    _setWidgetApps: function _setWidgetApps() {
      var _this3 = this;

      return this.get('appPool').findAllApps().then(function (widgetApps) {
        if (widgetApps.length > 1) {
          widgetApps.sort(function (app1, app2) {
            return app1.get('name').localeCompare(app2.get('name'));
          });
        }

        _this3.set('widgetApps', widgetApps);

        return widgetApps;
      }).catch(function (reason) {
        _this3.set('widgetApps', []);

        return RSVP.reject(reason);
      });
    },
    _autoSelect: function _autoSelect(prefWidgetId) {
      var widgetApps = this.get('widgetApps');

      var prefWidget = _lodash.default.find(widgetApps, {
        id: prefWidgetId
      });

      if (!prefWidget && !_lodash.default.isEmpty(widgetApps)) {
        prefWidget = widgetApps[0];
      }

      if (prefWidget) {
        this.setActive(prefWidget.get('id'));
      }
    },
    setActive: function setActive(widgetAppId) {
      var _this4 = this;

      var appPool = this.get('appPool');
      var currShownAppId = this.get('activeWidgetAppId');

      if (!currShownAppId || currShownAppId !== widgetAppId) {
        var hideCurrAppPromise = RSVP.resolve();

        if (currShownAppId) {
          hideCurrAppPromise = appPool.hideApp(appPool.get('currState.details.instanceId')).catch(function (poolState) {
            // Ignore the failure as we still want to show the new app
            // Log this as it should not happen
            _this4.logger.error("Failed to hide existing app with instanceId ".concat(appPool.get('currState.details.instanceId'), " when trying to show new appId, ").concat(widgetAppId, ". Pool State:"), poolState);

            return RSVP.resolve();
          });
        }

        hideCurrAppPromise.then(function () {
          return appPool.showApp(widgetAppId);
        }).then(function () {
          var notifyFn = _this4.get('appSelectionChanged');

          if (typeof notifyFn === 'function') {
            notifyFn(widgetAppId);
          }
        }).catch(function (reason) {
          // Pool state will alrady be updated and ui will show appropriate error and tab
          // Log this as it should not happen
          _this4.logger.error("Failed to show app with id ".concat(_this4.get('widgetAppId')), reason);
        });
      }
    },
    activeWidgetAppId: Ember.computed('appPool.currState', function () {
      var result = null;
      var currPoolState = this.get('appPool.currState');

      if (currPoolState && currPoolState.name === _appPool.POOL_STATES.SHOWING_APP) {
        result = currPoolState.details.appId;
      }

      return result;
    }),
    widgetAppTabConfigs: Ember.computed('widgetApps', function () {
      var apps = [];
      this.get('widgetApps').forEach(function (widgetApp) {
        var icon;
        var supplementalClassNames = '';

        if (widgetApp.get('assets.monochromicIcon')) {
          icon = widgetApp.get('assets.monochromicIcon');
          supplementalClassNames = 'monochromic-icon';
        } else if (widgetApp.get('assets.icon')) {
          icon = widgetApp.get('assets.icon');
        } else {
          supplementalClassNames = 'monochromic-icon';
        }

        apps.pushObject(Ember.Object.create({
          icon: icon,
          showTab: true,
          supplementalClassNames: supplementalClassNames,
          id: widgetApp.get('id'),
          name: widgetApp.get('name')
        }));
      });
      return apps;
    })
  });

  _exports.default = _default;
});