define("web-directory/components/acd-interactions-panel/interaction-sms/sms-message-icon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed;

  var _default = Component.extend({
    classNames: ['sms-message-icon'],
    classNameBindings: ['subMessageTypeClass'],
    subMessageTypeClass: computed('subMessageType', function () {
      return "sms-message-type-".concat(this.get('subMessageType'));
    }),
    subMessageType: null
  });

  _exports.default = _default;
});