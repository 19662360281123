define("web-directory/utils/analytics-keys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.STAT_KEYS = _exports.MEDIA_TYPES = void 0;
  var MEDIA_TYPES = {
    CHAT: 'chat',
    EMAIL: 'email',
    VOICE: 'voice'
  };
  _exports.MEDIA_TYPES = MEDIA_TYPES;
  var STAT_KEYS = {
    ABANDONED: 'tAbandon',
    ACW: 'tAcw',
    ANSWERED: 'tAnswered',
    CUSTOMERS_WAITING: 'oWaiting',
    HANDLE: 'tHandle',
    HELD: 'tHeld',
    INTERACTING: 'oInteracting',
    IVR: 'tIvr',
    OFFERED: 'nOffered',
    OVERSLA: 'nOverSla',
    SERVICE_LEVEL: 'serviceLevel',
    SERVICE_TARGET: 'serviceTarget',
    TALK: 'tTalk',
    TIME_IN_STATUS: 'tIS',
    // tIS.<Status ID> ms  The time in status for the given status id. At status change time.
    TRANSFERRED: 'nTransferred',
    WAIT_TIME: 'tWait',
    ACTIVE_MEMBERS: 'nActive',
    // Number of active members
    MEMBERS: 'nMembers' // Active members

  };
  _exports.STAT_KEYS = STAT_KEYS;
});