define("web-directory/utils/acd", ["exports", "lodash", "ember-inflector", "web-directory/utils/dates"], function (_exports, _lodash, _emberInflector, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createContactPojo = createContactPojo;
  _exports.getCommunicationType = getCommunicationType;
  _exports.getInteractionTypeFromCP = getInteractionTypeFromCP;
  _exports.getTrueCustomerIfMultipleCustomersExist = getTrueCustomerIfMultipleCustomersExist;
  _exports.interactionHasSipErrors = interactionHasSipErrors;
  _exports.isOutboundEmailInteraction = isOutboundEmailInteraction;
  _exports.isOutboundMessagingInteraction = isOutboundMessagingInteraction;
  _exports.setInteractionModelMetadata = setInteractionModelMetadata;
  _exports.warnClosureAction = warnClosureAction;
  var typeOf = Ember.typeOf,
      get = Ember.get;
  var MEDIA_TYPES = ['callbacks', 'calls', 'chats', 'emails', 'messages'];

  function participantHasMedia(participants, media) {
    return !!participants.find(function (participant) {
      return !!participant[media];
    });
  } // Assigns the interaction data sent from carrier pigeon or REST API response
  // to a property on the model that can be used to retrieve an interaction's "raw" data


  function setInteractionModelMetadata(model, interactionMetadata) {
    try {
      var metadataClone = _lodash.default.cloneDeep(interactionMetadata);

      if (typeOf(model) === 'instance') {
        model.set('__interaction_metadata__', metadataClone);
      } else {
        model.__interaction_metadata__ = metadataClone;
      }
    } catch (err) {
      Ember.Logger.error('Unable to clone and set the interaction metadata for interaction', {
        err: err,
        remoteData: {
          interactionId: Ember.get(model, 'id')
        }
      });
    }
  }

  function getCommunicationType(interactionModel) {
    if (!interactionModel) return null;
    return interactionModel.get('subMessageType') || interactionModel.get('type');
  }

  function getInteractionTypeFromCP(interaction, myGuid) {
    if (!interaction || !myGuid || !interaction.participants) {
      return {};
    }

    var myParticipants = interaction.participants.filter(function (participant) {
      return participant.userId === myGuid;
    });
    var active = {};
    MEDIA_TYPES.forEach(function (type) {
      active[type] = participantHasMedia(myParticipants, type);
    });
    return active;
  }

  function interactionHasSipErrors(interaction) {
    if (!interaction) {
      return false;
    }

    var callsError = false;
    var calls = interaction.get('participantForCurrentUser.calls');

    if (calls) {
      callsError = Ember.A(calls).some(function (call) {
        var code = Ember.get(call, 'disconnectReasons.firstObject.code'); // 487 is Voicemail Not Available, which is not treated as an error
        // 410 is a queue call that gets transfered to an agent for some reason

        return code && code >= 300 && code !== 487 && code !== 410;
      });
    }

    return callsError;
  }
  /**
   * Gets the true customer participant - whether it is the consultParticipant true customer or the last connected customer
   * @param interaction
   * @returns {*}
   */


  function getTrueCustomerIfMultipleCustomersExist(interaction) {
    if (interaction) {
      var participants = get(interaction, 'participants') || [];
      var type = get(interaction, 'type');
      var customerParticipants = participants.filter(function (_ref) {
        var purpose = _ref.purpose;
        return purpose === 'customer' || purpose === 'external';
      }) || Ember.A([]);
      var hasMultipleCustomers = customerParticipants.length > 1;

      if (hasMultipleCustomers) {
        var consultParticipantId = get(interaction, 'consultParticipantId') || customerParticipants.mapBy('consultParticipantId').pop();
        var isCallOrCallback = interaction.get('isCall') || interaction.get('isCallback');

        if (isCallOrCallback) {
          if (consultParticipantId) {
            return customerParticipants.findBy('id', consultParticipantId);
          } else {
            // SERVOPS-13490  when there are multiple customers on a blind transfer call from a previous consult
            // call there can be more than one customer participant in the list so deriving the correct communication
            // ID for the true customer can be wrong.  Since the blind transfer happened - there is no longer information
            // about what user is the consultParticipant so we will take the most recently connected
            // customer as the inferred true customer
            var participant;

            for (var i = 0; i < customerParticipants.length; i++) {
              var customer = customerParticipants[i];
              var communicationType = (0, _emberInflector.pluralize)(type).camelize();
              var communications = customer[communicationType] || [];

              var _ref2 = communications[0] || {},
                  state = _ref2.state;

              if (!get(customer, 'endTime') || state === 'connected') {
                return customer;
              }

              var customerEndTime = (0, _dates.default)(get(customer, 'endTime'));
              var lastParticipantEndTime = participant ? (0, _dates.default)(get(participant, 'endTime')) : null;

              if (!participant || customerEndTime.isAfter(lastParticipantEndTime)) {
                participant = customer;
              }
            }

            return participant;
          }
        }
      }
    }
  }

  function isOutboundEmailInteraction(interactionModel) {
    var agent = (get(interactionModel, 'participants') || []).findBy('purpose', 'agent') || {};
    return get(agent, 'emails.firstObject.direction') === 'outbound';
  }

  function isOutboundMessagingInteraction(interactionModel) {
    var agent = (get(interactionModel, 'participants') || []).findBy('purpose', 'agent') || {};
    return get(agent, 'messages.firstObject.direction') === 'outbound';
  }
  /**
   * Util for creating an immutable pojo for a given contact
   * @param contact
   * @returns {*}
   */


  function createContactPojo(contact) {
    if (!contact) return;
    var derivedProperties = ['name', 'canonicalId', 'isCanonical'];
    var contactJSON = typeof contact.toJSON === 'function' ? contact.toJSON({
      includeId: true
    }) : {};
    var derived = derivedProperties.reduce(function (accumulator, property) {
      var value = get(contact, property);

      if (value) {
        accumulator[property] = value;
      }

      return accumulator;
    }, {});
    return Object.assign(contactJSON, derived);
  }
  /**
   * Issue warning that closure action was not provided to component.
   * @param {string} actionName The name of closure function that was not provided
   */


  function warnClosureAction(actionName, args) {
    var message = "ACD: ".concat(actionName, " closure action has not been set up for this component.");
    Ember.Logger.warn(message, {
      arguments: args
    });
  }
});