define("web-directory/services/apps-api-handlers/open-window-handler", ["exports", "web-directory/models/internally-hosted-app", "@genesys/service-discovery-web-internal"], function (_exports, _internallyHostedApp, _serviceDiscoveryWebInternal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.OPEN_WINDOW_API_ACTION_NAME = void 0;
  var RSVP = Ember.RSVP,
      typeOf = Ember.typeOf,
      inject = Ember.inject;
  var OPEN_WINDOW_API_ACTION_NAME = 'openWindow';
  _exports.OPEN_WINDOW_API_ACTION_NAME = OPEN_WINDOW_API_ACTION_NAME;
  var OpenWindowHandler = Ember.Object.extend({
    session: inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
    },

    /**
     * Synchronous check to see if this handler is applicable for the specified AppApiCall instance
     *
     * @param {Object} the AppApiCall instance to check if this handler can handle
     *
     * @returns true if the provided AppApiCall instance could be handled by this handler. This check should
     *  be limited to checking if the handler is responsible for this type of AppApiCall rather than if the
     *  payload itself is valid.  If no handler returns true, the error will be that no handler exists which is
     *  a different case than the AppApiCall is invalid. Practically, this means that validation of the payload
     *  should be done in the handle method
     */
    canHandle: function canHandle(appApiCall) {
      return appApiCall.get('action') === OPEN_WINDOW_API_ACTION_NAME;
    },
    handle: function handle(appApiCall) {
      var _this = this;

      return new RSVP.Promise(function (resolve, reject) {
        var _appApiCall$get = appApiCall.get('actionDetails'),
            targetUrl = _appApiCall$get.targetUrl;

        if (!targetUrl) {
          reject('targetUrl not found');
          return;
        }

        if (typeOf(targetUrl) !== 'string' || targetUrl === '') {
          reject('targetUrl must be a non-empty string');
          return;
        }

        var app = appApiCall.get('srcApp');

        if (!_this.isActiveApp(app.id)) {
          reject('The source app must be focused');
          return;
        }

        var isKnownUrl = false;

        try {
          isKnownUrl = (0, _serviceDiscoveryWebInternal.isKnown)(targetUrl, {});
        } catch (error) {
          reject('Unable to parse targetUrl');
          return;
        }

        if (isKnownUrl && !(app instanceof _internallyHostedApp.default)) {
          reject('The source app must be internally hosted to open an internal link');
          return;
        }

        window.open(targetUrl, '_blank', 'noopener, noreferrer');
        return resolve();
      });
    }
  });
  var _default = OpenWindowHandler;
  _exports.default = _default;
});