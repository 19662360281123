define("web-directory/components/entity-fields/validators", ["exports", "web-directory/components/entity-fields/validators/email", "web-directory/components/entity-fields/validators/tag", "web-directory/components/entity-fields/validators/history", "web-directory/components/entity-fields/validators/text", "web-directory/components/entity-fields/validators/location", "web-directory/components/entity-fields/validators/date", "web-directory/components/entity-fields/validators/phone", "web-directory/components/entity-fields/validators/boolean", "web-directory/components/entity-fields/validators/address", "web-directory/components/entity-fields/validators/select", "web-directory/components/entity-fields/validators/multiline-text", "web-directory/components/entity-fields/validators/person", "web-directory/components/entity-fields/validators/weblink", "web-directory/components/entity-fields/validators/image"], function (_exports, _email, _tag, _history, _text, _location, _date, _phone, _boolean, _address, _select, _multilineText, _person, _weblink, _image) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var getOwner = Ember.getOwner,
      computed = Ember.computed;

  var _default = Ember.Object.extend({
    fieldConfig: computed(function () {
      return getOwner(this).lookup('field-config:person');
    }),
    default: {
      isValid: function isValid() {
        return false;
      },
      isEmpty: function isEmpty() {
        return true;
      }
    },
    tag: _tag.default,
    text: _text.default,
    date: _date.default,
    email: _email.default,
    phone: _phone.default,
    image: _image.default,
    person: _person.default,
    select: _select.default,
    history: _history.default,
    boolean: _boolean.default,
    address: _address.default,
    location: _location.default,
    weblink: _weblink.default,
    'multiline-text': _multilineText.default
  }).create();

  _exports.default = _default;
});