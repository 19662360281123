define("web-directory/components/inbox-panel-v2/cancellable-textbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2BIiTPtq",
    "block": "{\"statements\":[[11,\"div\",[]],[16,\"class\",[34,[\"form-group input-group-sm ib-cancellable-textbox \",[33,[\"if\"],[[28,[\"isNonEditable\"]],\"only-read-input\"],null]]]],[13],[0,\"\\n\\n    \"],[11,\"div\",[]],[15,\"class\",\"text-input cancellable-text-input-area\"],[13],[0,\"\\n        \"],[11,\"label\",[]],[16,\"for\",[34,[[26,[\"elementId\"]],\"-cancellable\"]]],[15,\"class\",\"hidden\"],[13],[0,\" \"],[1,[33,[\"t\"],[\"components.inbox.labels.callLogName\"],null],false],[0,\" \"],[14],[0,\"\\n        \"],[1,[33,[\"input\"],null,[[\"id\",\"type\",\"class\",\"enter\",\"disabled\",\"value\"],[[33,[\"concat\"],[[28,[\"elementId\"]],\"-cancellable\"],null],\"text\",\"form-control cancellable-text-input\",\"enter\",[28,[\"isNonEditable\"]],[28,[\"value\"]]]]],false],[0,\"\\n\\n        \"],[11,\"div\",[]],[15,\"class\",\"input-group-btn clear-btn-wrapper\"],[13],[0,\"\\n            \"],[11,\"button\",[]],[15,\"class\",\"btn btn-link btn-sm clear-btn\"],[15,\"type\",\"button\"],[5,[\"action\"],[[28,[null]],\"clearText\"],[[\"bubbles\"],[false]]],[13],[0,\"\\n                \"],[11,\"i\",[]],[15,\"class\",\"ion-close-circled\"],[13],[14],[0,\"\\n            \"],[14],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\\n    \"],[11,\"button\",[]],[15,\"class\",\"btn btn-sm btn-link cancel-btn\"],[15,\"type\",\"button\"],[5,[\"action\"],[[28,[null]],\"cancel\"],[[\"bubbles\"],[false]]],[13],[0,\"\\n        \"],[1,[33,[\"t\"],[\"components.inbox.actions.cancel\"],null],false],[0,\"\\n    \"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/inbox-panel-v2/cancellable-textbox/template.hbs"
    }
  });

  _exports.default = _default;
});