define("web-directory/mixins/job", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var currentJobs = [];
  var finishedJobs = [];

  function jobEndpoint(id) {
    return "/api/v2/jobs/".concat(id);
  }

  var _default = Ember.Mixin.create({
    ajax: Ember.inject.service(),
    _currentJobs: currentJobs,
    _finishedJobs: finishedJobs,
    addJob: function addJob(job, callback, scope) {
      var _this = this;

      if (job) {
        job.callback = callback;
        job.scope = scope || this;
        job.interval = window.setInterval(function () {
          _this.getJobData(job);
        }, 5000);
        currentJobs.addObject(job);
      }
    },
    getJobData: function getJobData(job) {
      var _this2 = this;

      return this.get('ajax').ajaxGet(jobEndpoint(job.jobId)).then(function (data) {
        data = data.res;
        var retryJobId = data.retryJobId;

        if (data.status !== 'in-progress' && data.status !== 'pending') {
          window.clearInterval(job.interval);

          if (data.status === 'retry') {
            job.jobId = retryJobId;

            _this2.handleJob(job);
          } else if (job.status === 'failed') {
            currentJobs.removeObject(job);
            finishedJobs.addObject(job);
            job.callback.apply(job.scope, [job]);
          } else {
            currentJobs.removeObject(job);
            finishedJobs.addObject(job);
            job.callback.apply(job.scope, [job]);
          }
        }
      }, function () {
        window.clearInterval(job.interval);
        currentJobs.removeObject(job);
        finishedJobs.addObject(job);
        job.callback.apply(job.scope, [job]);
      });
    }
  });

  _exports.default = _default;
});