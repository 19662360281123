define("web-directory/utils/newrelic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addPageAction = addPageAction;
  _exports.setCustomAttribute = setCustomAttribute;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  /*
   * This is a simple wrapper for New Relic's browser agent.  The goal is to no-op
   * when the browser agent is not available on the page (due to adblockers,
   * unit-tests, etc).
   */
  function addPageAction(actionName, attributes) {
    if (window && 'newrelic' in window && window.newrelic && _typeof(window.newrelic) === 'object' && 'addPageAction' in window.newrelic && typeof window.newrelic.addPageAction === 'function') {
      if (typeof actionName !== 'string' || !actionName.trim()) {
        //actionName cannot be an empty string
        Ember.Logger.error('Invalid actionName for addPageAction ->', {
          remoteData: {
            actionName: actionName,
            actionNameType: _typeof(actionName)
          }
        });
        return;
      }

      if (attributes !== undefined && attributes !== null) {
        // if not null and not undefined, attributes can be null/undefined
        if (_typeof(attributes) !== 'object' || Array.isArray(attributes)) {
          Ember.Logger.error('Invalid attributes for addPageAction ->', {
            remoteData: {
              actionName: actionName,
              attrType: _typeof(attributes)
            }
          });
          return;
        } else {
          var reservedKeys = ['accountId', 'timestamp', 'appId'];
          attributes = Object.fromEntries(Object.entries(attributes).map(function (_ref) {
            var _ref2 = _slicedToArray(_ref, 2),
                key = _ref2[0],
                value = _ref2[1];

            if (reservedKeys.includes(key)) {
              Ember.Logger.warn('Invalid reserved keys for addPageAction ->', {
                remoteData: {
                  actionName: actionName,
                  attrKey: key
                }
              });
              return null;
            }

            return [key, value];
          }).filter(function (curr) {
            return !!curr;
          }));
        }
      }

      window.newrelic.addPageAction(actionName, attributes);
    }
  }

  function setCustomAttribute(key, value, persist) {
    if (window && 'newrelic' in window && window.newrelic && _typeof(window.newrelic) === 'object' && 'setCustomAttribute' in window.newrelic && typeof window.newrelic.setCustomAttribute === 'function') {
      if (typeof key !== 'string') {
        Ember.Logger.error('Invalid key for setCustomAttribute ->', {
          remoteData: {
            key: key,
            keyType: _typeof(key)
          }
        });
        return;
      }

      if (value !== null && !['string', 'number', 'boolean'].includes(_typeof(value))) {
        Ember.Logger.error('Invalid value for setCustomAttribute ->', {
          remoteData: {
            key: key,
            valueType: _typeof(value)
          }
        });
        return;
      }

      if (typeof persist !== 'undefined' && typeof persist !== 'boolean') {
        Ember.Logger.error('Invalid persist for setCustomAttribute ->', {
          remoteData: {
            key: key,
            persistType: _typeof(persist)
          }
        });
        return;
      }

      window.newrelic.setCustomAttribute(key, value, persist);
    }
  }
});