define("web-directory/components/chat-settings/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['chat-settings'],
    activeRoom: null,
    chat: Ember.inject.service(),
    check: Ember.computed('activeRoom.jid', 'chat.mutedRooms.[]', function () {
      var activeJid = this.get('activeRoom.jid');
      var muted = this.get('chat.mutedRooms');
      return activeJid && muted && muted.any(function (jid) {
        return jid === activeJid;
      });
    }),
    actions: {
      toggleRoomMute: function toggleRoomMute() {
        var roomJid = this.get('activeRoom.jid');

        if (roomJid) {
          var areNotificationsDisabled = this.toggleProperty('check');
          this.get('chat').setRoomMuted(roomJid, areNotificationsDisabled);
        }
      }
    }
  });

  _exports.default = _default;
});