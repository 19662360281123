define("web-directory/services/audits", ["exports", "ember-purecloud-components/mixins/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var auditQueryBaseUri = '/platform/api/v2/audits/query';

  var _default = Ember.Service.extend(_logger.default, {
    ajax: Ember.inject.service(),
    executeRealtimeAuditQuery: function executeRealtimeAuditQuery(interval, serviceName, filters, pageNumber, pageSize, sort) {
      if (!pageNumber) pageNumber = 1;
      if (!pageSize) pageSize = 25;
      if (!sort) sort = [];
      var requestBody = {
        interval: interval,
        serviceName: serviceName,
        filters: filters,
        pageNumber: pageNumber,
        pageSize: pageSize,
        sort: sort
      };
      return this.get('ajax').post(auditQueryBaseUri + '/realtime', {
        data: JSON.stringify(requestBody)
      });
    },
    executeAuditQuery: function executeAuditQuery(interval, serviceName, filters) {
      var requestBody = {
        interval: interval,
        serviceName: serviceName,
        filters: filters
      };
      return this.get('ajax').post(auditQueryBaseUri, {
        data: JSON.stringify(requestBody)
      });
    },
    getAuditQueryExecutionStatus: function getAuditQueryExecutionStatus(transactionId) {
      return this.get('ajax').request("".concat(auditQueryBaseUri, "/").concat(transactionId));
    },
    getAuditQueryResults: function getAuditQueryResults(transactionId, cursor, pageSize) {
      if (!cursor) cursor = '';
      if (!pageSize) pageSize = 25;
      var uri = "".concat(auditQueryBaseUri, "/").concat(encodeURIComponent(transactionId), "/results?expand=user&cursor=").concat(encodeURIComponent(cursor), "&pageSize=").concat(encodeURIComponent(pageSize));
      return this.get('ajax').request(uri);
    }
  });

  _exports.default = _default;
});