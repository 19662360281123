define("web-directory/types/index", ["exports", "web-directory/types/maybe.type", "web-directory/types/conversation.type"], function (_exports, _maybe, _conversation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(_maybe).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _maybe[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _maybe[key];
      }
    });
  });
  Object.keys(_conversation).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _conversation[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _conversation[key];
      }
    });
  });
});