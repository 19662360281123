define("web-directory/utils/hosted-context", ["exports", "lodash", "web-directory/utils/net", "web-directory/utils/collab-chat-constants"], function (_exports, _lodash, _net, _collabChatConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.EVENTS = void 0;
  //For CEF:
  //_HostedContextFunctions functions:
  // - register(initData)
  // - showNotification(messageData)
  // - setUnreadMessageCount(count)
  // - doTerminateNow()
  //The app is considered hosted if it's running in an iframe, -or-. This class helps facilitate communication between
  //the app and its parent.
  var HostedContext = Ember.Object.extend(Ember.Evented, {
    applicationService: Ember.inject.service('application'),
    logger: Ember.inject.service('stash-logger'),
    chatFrame: Ember.inject.service(),
    applicationController: null,
    _applicationHasFocus: true,
    _hostType: null,
    //This determines whether we are actually hosted or not. Possible values: 'CEF', or null
    _operatingSystem: '',
    //Mac or Windows
    _version: '(unknown)',
    //Desktop application version
    _hostRegion: null,
    _supportsRegions: false,
    _supportsUnifiedPreferences: false,
    _supportsJabra: true,
    _additionalPreferences: [],
    _requiresNativeGeolocation: false,
    runAsMediaHelper: false,
    previousPresence: null,
    isScreenLockedOrSleep: false,
    hasUserAlreadyReturn: false,
    desktopSleptAt: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var owner = Ember.getOwner(this);
      this.set('applicationController', owner.lookup('controller:application')); //Create the initialization information to pass into the host

      var assetURL = window.location.origin + window.location.pathname;
      var urlPrefix = this.get('applicationService.urlprefix'); //urlprefix may be relative or fully qualified. If it's fully qualified, use that. Otherwise, append it to the origin.

      if (urlPrefix && urlPrefix.length > 0) {
        if (urlPrefix.substr(0, 5) === 'http:' || urlPrefix.substr(0, 6) === 'https:') {
          assetURL = urlPrefix;
        } else {
          assetURL += urlPrefix;
        }
      }

      var initData = {
        assetURL: assetURL,
        callback: this.callback.bind(this),
        supportsTerminationRequest: true,
        supportsUnifiedPreferences: true
      }; //CEF version, talk directly to host via javascript->C++

      if (window._HostedContextFunctions != null) {
        try {
          var appInfo = window._HostedContextFunctions.register(initData);

          this._hostType = appInfo.hostType;
          this._operatingSystem = appInfo.operatingSystem;
          this._version = appInfo.version;
          this._supportsRegions = appInfo.supportsRegions;
          this._hostRegion = appInfo.hostRegion;
          this._additionalPreferences = appInfo.additionalPreferences ? appInfo.additionalPreferences.split('|') : [];
          this._supportsJabra = appInfo.supportsJabra;
          this._requiresNativeGeolocation = appInfo.requiresNativeGeolocation;
          this.runAsMediaHelper = appInfo.runAsMediaHelper;
          this.get('logger').log('Running: ' + this._operatingSystem + ' app version: ' + this._version);
          this.get('logger').log('Supports regions: ' + this._supportsRegions + ' | Current region: ' + this._hostRegion);
          this.get('logger').log('Requires native geolocation: ' + this._requiresNativeGeolocation);
          this._supportsUnifiedPreferences = appInfo.unifiedPreferenceSupport;

          if (appInfo.supportsScreenMediaSelection) {
            window.cefGetScreenMedia = function (callback) {
              return _this.getScreenMedia(callback);
            };
          }

          this.setUnreadMessageCount(0);
        } catch (e) {
          this.get('logger').error('Error initializing hosted context functions', {
            error: e
          });
        }

        return;
      }
    },
    isHosted: function isHosted() {
      return this._hostType !== null;
    },
    isHostedProp: Ember.computed(function () {
      return this.isHosted();
    }),
    requiresNativeGeolocation: function requiresNativeGeolocation() {
      return this._requiresNativeGeolocation;
    },
    getRegion: function getRegion() {
      return this._hostRegion;
    },
    supportsRegions: function supportsRegions() {
      return this._supportsRegions;
    },
    supportsUnifiedPreferences: function supportsUnifiedPreferences() {
      return this._supportsUnifiedPreferences;
    },
    supportsJabra: function supportsJabra() {
      return this._supportsJabra;
    },
    isMac: function isMac() {
      return this._operatingSystem === 'Mac';
    },
    isWindows: function isWindows() {
      return this._operatingSystem === 'Windows';
    },
    getAdditionalPreferences: function getAdditionalPreferences() {
      return this._additionalPreferences;
    },
    doTerminateApplication: function doTerminateApplication(completion) {
      this.get('logger').log('in doTerminationApplication with completion: ' + completion);
      setTimeout(function () {
        if (completion) {
          completion();
        }
      }, 2000);
    },
    //CEF calls functions in Javascript by passing messages here
    callback: function callback(obj) {
      var msg = obj.msg;

      if (msg === 'OpenSearchField') {
        //If there is no modal dialog open, the set focus to the text field
        if (!$('body').hasClass('modal-open')) {
          $('.topbar .search-input input').trigger('focus');
        }
      } else if (msg === 'TerminateApplication') {
        var completion = window._HostedContextFunctions.doTerminateNow;
        this.doTerminateApplication(completion);
        return true;
      } else if (msg === 'OpenPreferences') {
        this.get('applicationController').send('openPreferencesModal');
      } else if (msg === 'HandleExternalURI') {
        var protocol = obj.protocol;
        var data = obj.data;

        if (protocol === 'tel' || protocol === 'callto') {
          var phoneService = Orgspan.serviceFor('phone');

          if (phoneService != null) {
            data = window.decodeURIComponent(data);
            this.get('logger').log('Calling: ' + data);
            phoneService.makeCall(data);
          }
        }
      } else if (msg === 'SetDesktopPresence') {
        //Desktop presence will either be desktopaway or desktopback.
        var desktopPresence = obj.presence;
        var idle = desktopPresence === 'desktopaway';

        try {
          var streamingConnection = Orgspan.serviceFor('streaming').get('connection');

          if (streamingConnection && streamingConnection.connected && typeof streamingConnection.setIdle === 'function') {
            this.get('logger').debug("Setting desktop presence of ".concat(desktopPresence, " and idle state to: ").concat(idle ? 'idle' : 'back'));

            if (this.get('chatFrame.useCollaborateChat')) {
              if (idle) {
                this.set('desktopSleptAt', Date.now());
                this.get('chatFrame').notifyCollaborateChatIFrame(_collabChatConstants.COLLABORATE_CHAT_EVENTS.publish.desktopAsleep, this.get('desktopSleptAt'));
              } else {
                this.get('chatFrame').notifyCollaborateChatIFrame(_collabChatConstants.COLLABORATE_CHAT_EVENTS.publish.desktopWakeUp, this.get('desktopSleptAt'));
              }
            }

            Ember.run.debounce(streamingConnection, 'setIdle', idle, 250);
          } else {
            this.get('logger').log('Cannot call setIdle on streaming service connection.');
          }
        } catch (e) {
          this.get('logger').log('Failed to call setIdle on streaming service connection.', e);
        }
      } else if (msg === 'JabraEvent') {
        var eventName = obj.event; //Mute, Hold, OffHook, ...

        var value = obj.value; //true or false

        var hidInput = obj.hidInput; //Raw int value of input, useful if eventName is not recognized.

        this.get('logger').log('Jabra event received: id: ' + hidInput + ' name:' + eventName + ' value: ' + value);
        this.trigger('JabraEvent', {
          eventName: eventName,
          value: value,
          hidInput: hidInput
        });
      } else if (msg === 'JabraDeviceAttached') {
        var attached = obj.attached; //true if attached, false if detached

        var deviceName = obj.deviceName; //Name of device when attached, empty string when detached

        var deviceId = obj.deviceId; //ID of device

        this.get('logger').log('Jabra device-attached received: id: ' + deviceId + ' name:' + deviceName + ' attached: ' + attached);
        this.trigger('JabraDeviceAttached', {
          deviceName: deviceName,
          deviceId: deviceId,
          attached: attached
        });
      }
    },
    applicationHasFocus: function applicationHasFocus() {
      return window._HostedContextFunctions && window._HostedContextFunctions.doesAppHaveFocus();
    },
    showNativeNotification: function showNativeNotification(data) {
      window._HostedContextFunctions.showNativeNotification(data);
    },
    setUnreadMessageCount: function setUnreadMessageCount(count) {
      try {
        if (this.isHosted() && !this.get('chatFrame.useCollaborateChat')) {
          window._HostedContextFunctions.setUnreadMessageCount(count);
        }
      } catch (e) {
        this.get('logger').error('Error setting unread message count', {
          error: e
        });
      }
    },
    //Send a Jabra event to the desktop.
    //deviceID - ID of the device
    //event - 'offhook', 'hold', 'mute', ringer'
    //value - true or false
    sendJabraEventToDesktop: function sendJabraEventToDesktop(deviceID, event, value) {
      this.sendEventToDesktop(EVENTS.JABRA_EVENT, {
        deviceID: deviceID,
        event: event,
        value: value
      });
    },
    requestJabraDevices: function requestJabraDevices() {
      return this.requestDesktopPromise({
        cmd: 'requestJabraDevices'
      });
    },
    sendEventToDesktop: function sendEventToDesktop(event, data) {
      try {
        if (this.isHosted()) {
          window._HostedContextFunctions.sendEventToDesktop(event, data);
        }
      } catch (e) {
        this.get('logger').error('Error sending event to desktop', {
          error: e
        });
      }
    },
    getScreenMedia: function getScreenMedia(callback) {
      this.requestDesktopPromise({
        cmd: 'getUserSelectedScreenMedia'
      }).then(function (obj) {
        callback(obj.id);
      }).catch(function
        /*reason*/
      () {
        callback(null);
      });
    },
    sendRegionToDesktop: function sendRegionToDesktop(region) {
      this._hostRegion = region;

      try {
        if (this.isHosted()) {
          var matchedRegion = _lodash.default.find(_net.PUBLIC_ENVS, {
            desktopId: region
          });

          if (matchedRegion) {
            var url = "https://apps.".concat(matchedRegion.envTld, "/directory");
            this.get('logger').log('Region: ' + region + ' URL: ' + url);

            window._HostedContextFunctions.sendRegionToDesktop(region, url);
          } else {
            this.get('logger').error("Unable to send region to desktop.  Unknown Region '".concat(region, "'"));
          }
        }
      } catch (e) {
        this.get('logger').error('Error sending region to desktop', {
          error: e
        });
      }
    },
    getShareableScreens: function getShareableScreens() {
      return this.requestDesktopPromise({
        cmd: 'getShareableScreens'
      });
    },
    requestDesktopPromise: function requestDesktopPromise(cmd) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        try {
          var sCmd = JSON.stringify(cmd);
          window.cefQuery({
            request: sCmd,
            persistent: false,
            onSuccess: function onSuccess(response) {
              try {
                var obj = JSON.parse(response);
                resolve(obj);
              } catch (e) {
                resolve({});
              }
            },
            onFailure: function onFailure(response) {
              reject(response);
            }
          });
        } catch (e) {
          this.get('logger').error('Error requesting desktop promise', {
            error: e
          });
          reject();
        }
      });
    }
  });
  var EVENTS = {
    INCOMING_CALL: 'incomingCall',
    TERMINATED_CALL: 'terminatedCall',
    MISSED_CALL: 'missedCall',
    STOPPED_RINGING: 'stoppedRinging',
    PHONE_CONNECTED: 'phoneConnected',
    WEBRTC_CONNECTION_INCOMING: 'webrtcConnectionIncoming',
    WEBRTC_CONNECTION_ANSWERED: 'webrtcConnectionAnswered',
    WEBRTC_CONNECTION_DECLINED: 'webrtcConnectionDeclined',
    WEBRTC_CONNECTION_STARTED: 'webrtcConnectionStarted',
    WEBRTC_CONNECTION_ENDED: 'webrtcConnectionEnded',
    JABRA_EVENT: 'jabraEvent'
  };
  _exports.EVENTS = EVENTS;
  var _default = HostedContext;
  _exports.default = _default;
});