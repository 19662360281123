define("web-directory/components/group-profile/group-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M5UnZS8t",
    "block": "{\"statements\":[[6,[\"each\"],[[28,[\"renderedList\"]]],null,{\"statements\":[[0,\"    \"],[1,[33,[\"group-profile/group-list/list-item\"],null,[[\"editing\",\"listModel\",\"isSequential\",\"displayIndices\",\"canRemove\",\"removeItem\",\"changePosition\"],[[28,[\"editing\"]],[28,[\"listModel\"]],[28,[\"isSequential\"]],[28,[\"displayIndices\"]],[28,[\"canRemove\"]],[33,[\"action\"],[[28,[null]],\"removeItem\"],null],[33,[\"action\"],[[28,[null]],\"changePosition\"],null]]]],false],[0,\"\\n\"]],\"locals\":[\"listModel\"]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/group-profile/group-list/template.hbs"
    }
  });

  _exports.default = _default;
});