define("web-directory/utils/dates", ["exports", "ember-purecloud-components/utils/dates"], function (_exports, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Object.defineProperty(_exports, "displayDateRelativeToToday", {
    enumerable: true,
    get: function get() {
      return _dates.displayDateRelativeToToday;
    }
  });
  Object.defineProperty(_exports, "generateCurrentInterval", {
    enumerable: true,
    get: function get() {
      return _dates.generateCurrentInterval;
    }
  });
  Object.defineProperty(_exports, "splitIntervalString", {
    enumerable: true,
    get: function get() {
      return _dates.splitIntervalString;
    }
  });
  Object.defineProperty(_exports, "timeInMillis", {
    enumerable: true,
    get: function get() {
      return _dates.timeInMillis;
    }
  });

  /*
    DEPRECATION NOTICE:  this file has been moved to `ember-purecloud-components`
    and will be removed.  Please use './node_modules/ember-purecloud-components/addon/utils/dates.js'
    instead.
  */
  var _default = _dates.default;
  _exports.default = _default;
});