define("web-directory/serializers/location", ["exports", "web-directory/serializers/purecloud"], function (_exports, _purecloud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _purecloud.default.extend({
    session: Ember.inject.service(),
    normalizeSingleResponse: function normalizeSingleResponse(store, model, data, guid) {
      var location = data.location;
      location.floors = [];

      if (data.floors && data.floors.entities) {
        location.floors = data.floors.entities.map(function (floor) {
          return store.push(store.normalize('location', floor));
        });
      }

      return this._super(store, model, location, guid);
    }
  });

  _exports.default = _default;
});