define("web-directory/app-providers/canned-responses-gadget-provider", ["exports", "web-directory/utils/boolean-expressions"], function (_exports, _booleanExpressions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.boolExpr = void 0;

  var _default = Ember.Object.extend({
    // Injections
    application: Ember.inject.service('application'),
    intl: Ember.inject.service('intl'),
    // Attributes
    id: 'cannedResponsesProvider',
    type: 'programmatic',
    group: 'contextual',
    trustedProvider: true,
    monitorInteractionUpdates: true,
    gadgetTargetOrigin: Ember.computed('application.runtime', function () {
      var runtimeEnv = this.get('application');

      if (runtimeEnv.runtime.local) {
        return "http://localhost:9092";
      }

      return window.location.origin;
    }),
    getAppConfigs: function getAppConfigs(appContext) {
      var appDefs = [];
      var myselfIsCoachOrMonitor = appContext.payload.get('myselfIsMonitor') || appContext.payload.get('myselfIsCoach') || appContext.payload.get('myselfIsMonitorV2');

      if (appContext.type === 'conversation' && !myselfIsCoachOrMonitor) {
        appDefs.push(buildCannedResponsesGadgetDef(this.get('gadgetTargetOrigin'), this.get('intl').t('purecloud.responses.responses')));
      }

      return Ember.RSVP.resolve(appDefs);
    }
  });

  _exports.default = _default;
  var boolExpr = (0, _booleanExpressions.and)((0, _booleanExpressions.ft)('PURE-5421_CannedResponse_Gadget'), (0, _booleanExpressions.perm)('responses:library:view'), (0, _booleanExpressions.perm)('responses:response:view'));
  _exports.boolExpr = boolExpr;

  var buildCannedResponsesGadgetDef = function buildCannedResponsesGadgetDef(gadgetOrigin, displayName) {
    return {
      id: 'responses',
      displayName: displayName,
      type: 'iframe',
      icon: {
        type: 'sparkV3SvgSprite',
        classNames: ['quote-left']
      },
      trustedDataPassing: true,
      startBehavior: 'lazy',
      lifecycleConfig: {
        bootstrap: true
      },
      sandbox: ['allow-same-origin', 'allow-scripts', 'allow-modals', 'allow-popups', 'allow-forms', 'allow-downloads'],
      src: new URL("".concat(gadgetOrigin, "/responseManagement/#/agentPanel"))
    };
  };
});