define("web-directory/helpers/format-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    var link = params[0];
    return link.replace('http://', '').replace('https://', '').replace('www.', '');
  });

  _exports.default = _default;
});