define("web-directory/initializers/preferences-service", ["exports", "web-directory/services/preferences"], function (_exports, _preferences) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'setupPreferencesService',
    before: 'setupApplicationService',
    initialize: function initialize(application) {
      application.register('service:preferences', _preferences.default);
      application.inject('controller', 'preferences', 'service:preferences');
      application.inject('route', 'preferences', 'service:preferences');
      application.inject('component', 'preferences', 'service:preferences');
    }
  };
  _exports.default = _default;
});