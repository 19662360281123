define("web-directory/components/acd-interactions-panel/interaction-wrapup-codes-copilot/interaction-wrapup-codes-copilot-summary/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['interaction-wrapup-codes-copilot-summary'],
    conversationSummaryEvent: null,
    isCopilotSummaryEnabled: false,
    summary: '',
    reason: '',
    resolution: '',
    isFeedbackSelected: false,
    handleInputChange: function handleInputChange(_name, _val) {},
    feedbackInputChange: function feedbackInputChange(_feedbackType, _val) {},
    overrideInputStyles: function overrideInputStyles() {
      var _this = this;

      var GUX_INPUT_TAG_NAME = 'gux-form-field-text-like';
      var GUX_INPUT_CONTAINER_SELECTOR = '.gux-input-container';
      var styleOverrideCounter = 0;
      var interval = setInterval(function () {
        var guxInputContainerElements = _this.$(GUX_INPUT_TAG_NAME);

        if ((guxInputContainerElements === null || guxInputContainerElements === void 0 ? void 0 : guxInputContainerElements.length) > 0) {
          for (var i = 0; i < guxInputContainerElements.length; i++) {
            var _guxInputContainerEle, _guxInputContainerEle2;

            var guxInputContainerElement = (_guxInputContainerEle = guxInputContainerElements[i]) === null || _guxInputContainerEle === void 0 ? void 0 : (_guxInputContainerEle2 = _guxInputContainerEle.shadowRoot) === null || _guxInputContainerEle2 === void 0 ? void 0 : _guxInputContainerEle2.querySelector(GUX_INPUT_CONTAINER_SELECTOR);

            if (guxInputContainerElement) {
              guxInputContainerElement.style.backgroundColor = 'white';
              guxInputContainerElement.style.boxShadow = 'none';
              styleOverrideCounter++;
            }
          }

          if (styleOverrideCounter === guxInputContainerElements.length) {
            clearInterval(interval);
          }
        }
      }, 100);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.overrideInputStyles();
    },
    actions: {
      handleSummaryInputChange: function handleSummaryInputChange(val) {
        this.handleInputChange('summary', val);
      },
      handleReasonInputChange: function handleReasonInputChange(val) {
        this.handleInputChange('reason', val);
      },
      handleResolutionInputChange: function handleResolutionInputChange(val) {
        this.handleInputChange('resolution', val);
      },
      handleFeedbackInputChange: function handleFeedbackInputChange(_feedbackType, _val) {
        this.feedbackInputChange(_feedbackType, _val);
      }
    }
  });

  _exports.default = _default;
});