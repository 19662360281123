define("web-directory/adapters/response", ["exports", "web-directory/adapters/purecloud", "lodash"], function (_exports, _purecloud, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _purecloud.default.extend({
    defaultSerializer: 'response',
    pathForType: function pathForType() {
      return 'v2/responsemanagement/responses';
    },
    query: function query(store, type, _query) {
      return this.ajax("".concat(this.buildURL(type), "/query"), 'POST', {
        data: _query
      });
    },
    findMany: function findMany(store, type, ids, pageNumber, pageSize) {
      var currentPage = pageNumber || 1,
          currentPageSize = pageSize || 25;
      var query = {
        pageSize: currentPageSize,
        pageNumber: currentPage,
        libraryId: _lodash.default.head(ids)
      };
      return this.ajax(this.buildURL(type), 'GET', {
        data: query
      });
    }
  });

  _exports.default = _default;
});