define("web-directory/components/inbox-panel-v2/notes-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JriIYfzs",
    "block": "{\"statements\":[[11,\"textarea\",[]],[15,\"class\",\"form-control note-field\"],[16,\"oninput\",[33,[\"action\"],[[28,[null]],\"setNotes\"],[[\"on\"],[\"input\"]]],null],[13],[1,[28,[\"recording\",\"note\"]],false],[14],[0,\"\\n\\n\"],[11,\"div\",[]],[15,\"class\",\"buttons\"],[13],[0,\"\\n    \"],[11,\"button\",[]],[15,\"class\",\"btn btn-link save-notes-button\"],[16,\"disabled\",[26,[\"saveDisabled\"]],null],[15,\"type\",\"button\"],[5,[\"action\"],[[28,[null]],[28,[\"save\"]],[28,[\"notes\"]]]],[13],[0,\"\\n        \"],[1,[33,[\"t\"],[\"common.save\"],null],false],[0,\"\\n    \"],[14],[0,\"\\n    \"],[11,\"button\",[]],[15,\"class\",\"btn btn-link cancel-notes-button\"],[15,\"type\",\"button\"],[5,[\"action\"],[[28,[null]],[28,[\"cancel\"]]]],[13],[0,\"\\n        \"],[1,[33,[\"t\"],[\"common.cancel\"],null],false],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[6,[\"if\"],[[28,[\"saveDisabled\"]]],null,{\"statements\":[[0,\"    \"],[11,\"div\",[]],[15,\"class\",\"error-message\"],[13],[0,\"\\n        \"],[1,[33,[\"t\"],[\"components.inbox.notesTooLong\"],null],false],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/inbox-panel-v2/notes-editor/template.hbs"
    }
  });

  _exports.default = _default;
});