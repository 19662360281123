define("web-directory/models/split-message", ["exports", "moment", "lodash", "web-directory/utils/markdown", "web-directory/mixins/chat-message-media", "web-directory/mixins/chat-message-mixin"], function (_exports, _moment, _lodash, _markdown, _chatMessageMedia, _chatMessageMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_chatMessageMedia.default, _chatMessageMixin.default, {
    //must support default streaming service attributes, but most of these must be overridden to allow
    //split message functionality, these will be the static streaming service attributes
    id: null,
    oid: null,
    from: null,
    to: null,
    time: null,
    // End of required chat message attrs
    //beginning of split message attributes
    messageArray: null,
    splitMessageId: null,
    splitMessage: true,
    totalMessages: null,
    //end of split message attributes
    // set in services/chat.processNewChatMessage()
    chatUser: null,
    // set in services/chat.markAsHistory()
    history: false,
    // set in services/chat.setMessageReadState()
    unread: false,
    // set in chat-room.processCorrections()
    startOfBlock: null,
    endOfBlock: null,
    // set in chat-room.addSelfReference()
    room: null,
    // set in chat-room.addMessage()/markConversationBlockBoundaries()
    isYourLastMessage: false,
    //split messages cannot be edited due to the nature of them, so these are all static
    isEditable: false,
    isEditing: false,
    canEdit: false,
    widgetType: 'default',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('time', (0, _moment.default)(this.get('time')));
      this.set('messageArray', Ember.A([]));
    },
    //required streaming service attributes, these are put back together from the individual models.
    raw: Ember.computed('messageArray.@each.raw', function () {
      return this.get('messageArray').map(function (message) {
        return message.get('raw');
      }).join('');
    }),
    links: Ember.computed('messageArray.@each.links', function () {
      return _lodash.default.chain(this.get('messageArray')).map(function (message) {
        return message.get('links');
      }).flatten().value();
    }),
    files: Ember.computed('messageArray.@each.files', function () {
      return _lodash.default.chain(this.get('messageArray')).map(function (message) {
        return message.get('files');
      }).flatten().filter().value();
    }),
    mentions: Ember.computed('raw', function () {
      var match;
      var mentions = [];
      var mentionRegex = /\[@.*\]\(#\/person\/(.*)\)/g;
      var raw = this.get('raw');

      while (match = mentionRegex.exec(raw)) {
        var mention = match[0];
        var oid = match[1];
        mentions.push(Ember.Object.create({
          mention: mention,
          oid: oid
        }));
      }

      return mentions;
    }),
    hasMentionLinks: Ember.computed.gt('mentions.length', 0),
    //unfortunately we have to remarkdown the message since these were all individual parts of a message
    messageBody: Ember.computed('raw', function () {
      return (0, _markdown.markdownToHtml)(this.get('raw'));
    }),
    editableEndTime: Ember.computed('time', function () {
      return (0, _moment.default)(this.get('time'));
    }),
    failedMessages: Ember.computed('messageArray.@each.{sendError}', function () {
      return _lodash.default.chain(this.get('messageArray')).filter(function (message) {
        return message.get('sendError');
      }).value();
    }),
    sendError: Ember.computed.gt('failedMessages.length', 0),
    pending: Ember.computed('history', 'messageArray.@each.pending', 'totalMessages', function () {
      if (this.get('history')) {
        return false;
      } else {
        return this.get('messageArray.length') < this.get('totalMessages');
      }
    }),
    addSplitMessage: function addSplitMessage(message) {
      var messages = this.get('messageArray');
      messages.pushObject(message);
      messages.sort(function (a, b) {
        return a.get('splitMessageIndex') < b.get('splitMessageIndex') ? -1 : 1;
      });
      this.notifyPropertyChange('messageArray');
    }
  });

  _exports.default = _default;
});