define("web-directory/components/acd-interactions-panel/interaction-responses/library-detail/component", ["exports", "web-directory/mixins/acd-utilities", "web-directory/utils/focus-management"], function (_exports, _acdUtilities, _focusManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_acdUtilities.default, {
    classNames: ['library-detail'],
    classNameBindings: ['enabled'],
    library: null,
    enabled: true,
    loading: false,
    allowInsert: false,
    responseFilter: null,
    responses: Ember.inject.service(),
    interaction: Ember.inject.service(),
    // TODO: Move response loading to a Query so we don't have to do this nonsense
    showLoadMore: Ember.computed('library.responses.length', function () {
      return this.get('library.responses.length') % this.get('responses.pageSize') === 0 && this.get('library.responses.length') > 0;
    }),
    filteredResponses: Ember.computed('library.responses', 'library.responses.length', 'responseFilter', function () {
      if (this.get('session.features.responseManagementMessagingTemplate') && this.get('interaction.type') === 'message') {
        if (!!this.get('library.responses') && !!this.get('responseFilter')) {
          var filtered = this.get('library.responses').filter(function (response) {
            // we want structured messages that specifically have whatsapp message templates
            return response.get('isWhatsAppResponse');
          });

          if (filtered.length === 0) {
            this.loadMoreResponses();
          }

          return filtered;
        }
      } else if (!this.get('session.features.responseManagementMessagingTemplate')) {
        // filter out any and all structured messages
        if (this.get('library.responses')) {
          var _filtered = this.get('library.responses').filter(function (response) {
            return !response.get('responseType');
          });

          if (_filtered.length === 0) {
            this.loadMoreResponses();
          }

          return _filtered;
        }
      } // fallback for all other filtering cases


      return this.get('library.responses');
    }),
    loadLibraryResponses: function loadLibraryResponses(pageNumber, library) {
      var _this = this;

      this.set('loading', true);
      return this.get('responses').findAll(library.id, pageNumber).then(function (data) {
        var currentResponses = library.get('responses');

        if (currentResponses) {
          currentResponses.pushObjects(data);
          library.set('responses', currentResponses);
        } else {
          library.set('responses', data);
        }
      }).finally(function () {
        _this.set('loading', false);
      });
    },
    loadInitialResponses: Ember.observer('library', function () {
      var library = this.get('library');

      if (library && !library.get('responses')) {
        var initialPage = 1;
        library.set('currentResponsePage', initialPage);
        this.loadLibraryResponses(initialPage, library);
      }
    }),
    loadMoreResponses: function loadMoreResponses() {
      var library = this.get('library');

      if (library) {
        var pageToLoad = library.get('currentResponsePage') + 1;
        library.set('currentResponsePage', pageToLoad);
        this.loadLibraryResponses(pageToLoad, library);
      }
    },
    interactionSelected: Ember.observer('interaction', function () {
      var responses = this.get('responses'),
          results = this.get('library.responses');

      if (results) {
        results.forEach(function (response) {
          var replacementObj = responses.replaceSubstitutionTokens(response);
          response.set('replaceText', replacementObj.text);
          response.set('message', replacementObj.message);
        });
      }
    }),
    actions: {
      toggleOpen: function toggleOpen(response) {
        response.toggleProperty('isOpen');
      },
      sendResponse: function sendResponse(response) {
        this.sendAction('sendResponse', response);
      },
      expandResponse: function expandResponse(response) {
        this.sendAction('expandResponse', response);
      },
      loadMoreResponses: function loadMoreResponses() {
        this.loadMoreResponses();
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var focusTarget = "#".concat(this.parentId, "-find-response");
      (0, _focusManagement.doFocus)(Ember, focusTarget);
    }
  });

  _exports.default = _default;
});