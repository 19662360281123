define("web-directory/routes/engage/wfm-admin-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    init: function init() {
      this._super.apply(this, arguments);
    },
    redirect: function redirect(model) {
      var features = this.get('session.features'); // WFM-10406 special case for decisionsDataDownload whose nav entry and URL was previously
      // navParent'd within WFM pcba component and is now standalone within forecasting component
      // when wfmUITransition toggle is on because WFM pcba component is deprecated by WFM-7356.
      // Requests for pre-wfmUITransition route:
      //   #/engage/wfm/admin/decisionsDataDownload  (e.g. someone's bookmark or shared link)
      // would be redirected by this wfm-admin-redirect route definition to:
      //   #/admin/wfm/decisionsDataDownload
      // but with this special case will now redirect to here instead:
      //   #/engage/forecasting/admin/decisionsDataDownload

      if (features['wfpDecisionsDataDownload'] && model.wfmPath === 'decisionsDataDownload') {
        this.replaceWith('engage.embedded', {
          engagePath: 'forecasting/admin/decisionsDataDownload'
        });
        return;
      } // Redirect old PCBA/engage WFM routes to iframe-coordinator app, e.g.
      // #/engage/wfm/admin/foo -> #/admin/wfm/foo


      this.replaceWith('/admin/wfm/' + model.wfmPath.replace(/^\//, ''));
    }
  });

  _exports.default = _default;
});