define("web-directory/components/acd-interactions-panel/interaction-sms/message-cards/message-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2J9lXYMK",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"card-container\"],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"card\",\"image\"]]],null,{\"statements\":[[0,\"        \"],[11,\"div\",[]],[15,\"class\",\"card-image card-container-items\"],[13],[0,\"\\n            \"],[11,\"img\",[]],[16,\"src\",[34,[[28,[\"card\",\"image\"]]]]],[15,\"alt\",\"\"],[15,\"role\",\"none\"],[13],[14],[0,\"\\n        \"],[14],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"card-contents card-container-items\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"card-title\"],[13],[0,\"\\n            \"],[11,\"p\",[]],[13],[0,\"\\n                \"],[1,[28,[\"card\",\"title\"]],false],[0,\"\\n            \"],[14],[0,\"\\n        \"],[14],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"card-description\"],[13],[0,\"\\n            \"],[11,\"p\",[]],[13],[0,\"\\n                \"],[1,[28,[\"card\",\"description\"]],false],[0,\"\\n            \"],[14],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[11,\"div\",[]],[15,\"class\",\"card-options-container\"],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"card\",\"actions\"]]],null,{\"statements\":[[0,\"        \"],[11,\"span\",[]],[16,\"class\",[34,[\"card-option \",[33,[\"if\"],[[28,[\"cardOption\",\"selected\"]],\"selected-option\"],null]]]],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"cardOption\",\"selected\"]]],null,{\"statements\":[[0,\"                \"],[9,\"gux-icon\",{\"attrs\":[[15,\"icon-name\",\"checkmark\"],[15,\"decorative\",\"true\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n\"]],\"locals\":[]},null],[0,\"            \"],[1,[28,[\"cardOption\",\"text\"]],false],[0,\"\\n        \"],[14],[0,\"\\n\"]],\"locals\":[\"cardOption\"]},null],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/acd-interactions-panel/interaction-sms/message-cards/message-card/template.hbs"
    }
  });

  _exports.default = _default;
});