define("web-directory/components/entity-fields/multiline-text-field/component", ["exports", "lodash", "web-directory/mixins/field-component"], function (_exports, _lodash, _fieldComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var on = Ember.on,
      run = Ember.run,
      set = Ember.set;

  var _default = Ember.Component.extend(_fieldComponent.default, {
    classNameBindings: ['isValid::invalid-text'],
    ensurePopulatedField: on('didRender', function () {
      var _this = this;

      if (!this.get('editing') || this.isDestroyed) {
        return;
      }

      if (this.isFieldEmpty()) {
        run(function () {
          var timer = run.once(_this, _this.setValues);

          _this.timers.pushObject(timer);
        });
      }
    }),
    isFieldEmpty: function isFieldEmpty() {
      var field = this.get('field');
      var entity = this.get('entity');

      if (field && entity) {
        var path = this.get('field').getFieldPath();
        var value = this.get('entity').getFieldValue(path);
        return _lodash.default.isEmpty(value);
      }
    },
    setValues: function setValues() {
      var entity = this.get('entity');
      var path = this.get('field').getFieldPath();
      var entry = this.get('entry');

      if (!_lodash.default.isEmpty(entry)) {
        set(entry, 'value', '');
        entity.set(path, [entry], {
          overwrite: true
        });
      } else {
        entity.set(path, '');
      }

      var value = entity.getFieldValue(path);

      if (Array.isArray(value)) {
        value = value[0];
      }

      this.set('entry', value);
    }
  });

  _exports.default = _default;
});