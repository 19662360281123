define("web-directory/models/interaction/email-interaction", ["exports", "lodash", "web-directory/models/interaction/interaction"], function (_exports, _lodash, _interaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _interaction.default.extend({
    type: 'email',
    draft: null,
    // hack: property change notifications on this property are used to trigger the active email to refetch its messages
    _shouldReloadMessages: null,
    inlineAttachments: null,
    hasInlineImages: computed('messages.@each.htmlBody', function () {
      var messages = this.get('messages'); // conversation has inline images if one of its messages has an attachment that
      // appears in the message's html body

      return _lodash.default.some(messages, function (message) {
        var htmlBody = message.htmlBody,
            renderText = $('<textarea/>').html(htmlBody).text(),
            renderHtml = $.parseHTML(htmlBody),
            attachments = message.attachments || [],
            embeddedImgs = $(renderHtml).find('img'); // if message contains http

        var isInline = _lodash.default.some(embeddedImgs, function (image) {
          return image.src.match(/\https:/) || image.src.match(/^http:/);
        }); // inline media have a 'cid:' prefix in the message body


        var isAttached = _lodash.default.some(attachments, function (attachment) {
          return renderText.indexOf("cid:".concat(attachment.attachmentId)) !== -1;
        });

        return isInline || isAttached;
      });
    }),
    hasInsecureImages: computed('messages.@each.htmlBody', function () {
      var messages = this.get('messages');
      return _lodash.default.some(messages, function (message) {
        var htmlBody = message.htmlBody,
            renderHtml = $.parseHTML(htmlBody),
            embeddedImgs = $(renderHtml).find('img'); // checks if image source has http:

        return _lodash.default.some(embeddedImgs, function (image) {
          return image.src && image.src.match(/^http:/);
        });
      });
    }),
    currentEmail: computed('participantForCurrentUser.emails.[]', function () {
      return _lodash.default.head(this.get('participantForCurrentUser.emails'));
    }),
    provider: computed.reads('participantForCurrentUser.emails.firstObject.provider'),
    isInternalProvider: computed.equal('provider', 'PureCloud Email'),
    hasMessages: computed('messages.[]', function () {
      var messages = this.get('messages');

      if (messages && messages.length > 0) {
        return true;
      }

      return false;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('messages', []);
      this.set('inlineAttachments', []);
    }
  });

  _exports.default = _default;
});