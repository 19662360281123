define("web-directory/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IndexRoute = Ember.Route.extend({
    session: Ember.inject.service(),
    permissions: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var result = this._super(transition);
      /* @see services/Session.authenticateWithSession() as this is the ember-simple-auth
       * authenicator handler function before this route catch-all (empty) route.
       */


      if (transition.isActive === false) {
        // Route canceled for auth
        return;
      }

      return Ember.RSVP.resolve(result).then(function () {
        var personId = _this.get('session.person.id') || _this.get('session.user.personId');

        if (_this.get('session.user.isSuper') || _this.get('session.user.isTrustee')) {
          return _this.transitionTo('search');
        }

        if (!personId) {
          Ember.Logger.error("Index Route: Standard user has no 'personId'.  Transitioning to no-access.");

          _this.set('session.hasAppAccess', false);

          return _this.transitionTo('no-access');
        }

        if (_this.get('permissions.canViewAgentActivity')) {
          return _this.transitionTo('center-stage-frame-agentHome');
        }

        return _this.transitionTo('person.detail', personId);
      });
    }
  });
  var _default = IndexRoute;
  _exports.default = _default;
});