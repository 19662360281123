define("web-directory/components/entity-fields/validators/phone", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var requireFields = ['contactInfo.phone_other'];

  function isEmptyValue(value) {
    return value === undefined || value === null || _lodash.default.isEmpty(value.trim());
  }

  var _default = {
    isValid: function isValid(entry, field, intl, $input) {
      if (arguments.length !== 4 || !entry || !field || !$input || !intl) {
        return true;
      }

      var path = field.getFieldPath();
      var params = Ember.get(field, 'params');
      var number = Ember.get(entry, 'value.number');
      var extension = Ember.get(entry, 'value.extension');

      var isRequired = _lodash.default.includes(requireFields, path);

      if (_lodash.default.isEmpty(number) && _lodash.default.isEmpty(extension)) {
        return !isRequired;
      }

      if (_lodash.default.isEmpty(number) && !_lodash.default.isEmpty(extension)) {
        return true;
      }

      var isValid = number.length <= params.numberMaxLength;

      if (extension && extension.length) {
        isValid = isValid && extension.length <= params.extensionMaxLength && !!number;
      }

      return isValid;
    },
    isEmpty: function isEmpty(phone) {
      if (!phone || !phone.value) {
        return true;
      }

      var value = phone.value;
      var number = Ember.get(value, 'number');
      var extension = Ember.get(value, 'extension');
      return isEmptyValue(number) && isEmptyValue(extension);
    },
    cleanFieldForEntity: function cleanFieldForEntity(entity, field, entry) {
      var value = Ember.get(entry, 'value');
      var path = field.getFieldPath();

      if (value) {
        if (_lodash.default.isEmpty(value.number)) {
          delete value.number;
        }

        if (_lodash.default.isEmpty(value.extension)) {
          delete value.extension;
        }

        if (!value.number && !value.extension) {
          value = {};
        }
      }

      if (_lodash.default.isEmpty(value)) {
        var data = entity.get(path);

        if (data) {
          data.removeObject(entry);
        }
      } else {
        Ember.set(entry, 'value', value);
      }
    }
  };
  _exports.default = _default;
});