define("web-directory/controllers/relate/index", ["exports", "ember-purecloud-components/mixins/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IndexController = Ember.Controller.extend(_logger.default, {
    session: Ember.inject.service(),
    relateService: Ember.inject.service('relate'),
    // Overridden to preserve hash query parameters.
    transitionToRoute: function transitionToRoute(route) {
      window.location.hash = route;
    },
    // Overridden to preserve hash query parameters.
    replaceRoute: function replaceRoute(route) {
      var search = window.location.search;
      window.location.replace("".concat(window.location.pathname).concat(search, "#").concat(route));
    },
    navigationEvent: Ember.observer('relateService.navigationRoute', function () {
      var navRoute = this.get('relateService.navigationRoute');

      if (navRoute) {
        this.logger.debug('Transitioning to', navRoute);
        this.transitionToRoute(navRoute); //see override above

        this.set('relateService.navigationRoute', null);
      }
    }),
    redirectEvent: Ember.observer('relateService.redirectRoute', function () {
      var redirectRoute = this.get('relateService.redirectRoute');
      this.logger.debug('Redirecting to', redirectRoute.route);
      this.replaceRoute(redirectRoute.route); //see override above
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.get('relateService.redirectRoute');
    }
  });
  var _default = IndexController;
  _exports.default = _default;
});