define("web-directory/services/acdcall", ["exports", "lodash", "web-directory/mixins/acd-utilities", "web-directory/models/interaction/call-interaction"], function (_exports, _lodash, _acdUtilities, _callInteraction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CALL_CONVERSATION_CREATED_EVENT = void 0;
  var inject = Ember.inject,
      observer = Ember.observer,
      computed = Ember.computed,
      run = Ember.run,
      Evented = Ember.Evented;
  var CALL_CONVERSATION_CREATED_EVENT = 'callConversationCreated';
  _exports.CALL_CONVERSATION_CREATED_EVENT = CALL_CONVERSATION_CREATED_EVENT;

  var _default = Ember.Service.extend(Evented, _acdUtilities.default, {
    phone: inject.service(),
    interaction: inject.service(),
    session: inject.service(),
    headset: inject.service(),
    logger: inject.service('stash-logger'),
    webrtcSdkService: inject.service('webrtcSdk'),
    updateDebounce: null,
    _debounceLength: 500,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('activeCalls', []);

      this._updateInteractionModels();

      if (this.get('webrtcSdkService.useSdk')) {
        this._headsetFeatures(this.get('webrtcSdkService'));
      } else {
        this._headsetFeatures(this.get('headset'));
      }

      this.get('phone').on(CALL_CONVERSATION_CREATED_EVENT, this, this._handleCallCreated);
    },
    changeHeadsetSource: observer('webrtcSdkService.useSdk', function () {
      if (this.get('webrtcSdkService.useSdk')) {
        this._headsetFeatures(this.get('webrtcSdkService'));
      }
    }),
    _handleCallCreated: function _handleCallCreated(payload) {
      this.trigger(CALL_CONVERSATION_CREATED_EVENT, payload);
    },
    currentConversation: computed.reads('interaction.selectedInteraction.conversation'),
    currentConversationIsCall: computed.reads('interaction.selectedInteraction.isCall'),
    _headsetFeatures: function _headsetFeatures(headset) {
      var _this = this;

      this.set('headsetListenersActive', true);
      headset.on('deviceMuteStatusChanged', function (isMuted) {
        var currentConversation = _this.get('currentConversation');

        if (!currentConversation) {
          return;
        }

        if (_this.get('currentConversation.isMuted') !== isMuted) {
          _this.get('phone').mute(currentConversation);
        }
      });
      headset.on('deviceHoldStatusChanged', function (holdRequested, toggle) {
        var currentConversation = _this.get('currentConversation');

        if (!currentConversation) {
          return;
        } // this is because jabra sends an unmute request after the call ends,
        // Presumably to get the headset in a vanilla state


        if (toggle && !currentConversation.get('isConnected')) {
          return;
        }

        if (toggle || currentConversation.get('isHeld') !== holdRequested) {
          _this.get('phone').hold(currentConversation);
        }
      });
      headset.on('deviceEndedCall', function () {
        if (!_this.get('currentConversation')) {
          return;
        }

        _this.get('phone').endCall(_this.get('currentConversation'));
      });
      headset.on('deviceAnsweredCall', function () {
        var alertingCalls = Ember.A(_this.get('interaction.alertingInteractions').filterBy('isCall', true));
        var conversation = alertingCalls.get('lastObject.conversation');

        if (conversation) {
          _this.get('phone').answer(conversation);
        }
      });
      headset.on('deviceRejectedCall', function () {
        if (!_this.get('currentConversation')) {
          return;
        }

        _this.get('phone').endCall(_this.get('currentConversation'));
      });
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      if (this.get('updateDebounce')) {
        run.cancel(this.get('updateDebounce'));
      }

      this.get('phone').off(CALL_CONVERSATION_CREATED_EVENT, this, this._handleCallCreated);
    },
    _updateActiveCalls: observer('phone.conversations.[]', function () {
      this.set('updateDebounce', run.debounce(this, this._updateInteractionModels, this.get('_debounceLength')));
    }),
    _updateInteractionModels: function _updateInteractionModels() {
      var _this2 = this;

      var currentInteractions = this.get('activeCalls');
      var updatedInteractions = this.get('phone.conversations');

      _lodash.default.each(updatedInteractions, function (callInteraction) {
        var isAgentCall = Ember.get(callInteraction, 'isAgentCall');

        if (isAgentCall) {
          Ember.setOwner(callInteraction, Ember.getOwner(_this2));
          var interactionDetails = currentInteractions.findBy('id', callInteraction.id);

          if (interactionDetails && !interactionDetails.isDestroyed || !interactionDetails) {
            if (!interactionDetails) {
              interactionDetails = _callInteraction.default.create(callInteraction);
              currentInteractions.addObject(interactionDetails);
            }

            interactionDetails.set('conversation', callInteraction);
            interactionDetails.setProperties(callInteraction);

            var session = _this2.get('session');

            if (!Ember.get(session, 'useMultipanel')) {
              // notify property changes
              if (interactionDetails === _this2.get('interaction.selectedInteraction')) {
                _this2.get('interaction').notifyPropertyChange('selectedInteraction');
              }
            }
          }
        }
      });

      var filteredList = _lodash.default.filter(currentInteractions, function (interaction) {
        return updatedInteractions.findBy('id', interaction.id) && interaction.get('conversation.isAgentCall');
      });

      var activeInteractionIds = _lodash.default.map(updatedInteractions, function (interaction) {
        return interaction.id;
      });

      var oldInteractions = _lodash.default.reject(currentInteractions, function (interaction) {
        return _lodash.default.includes(activeInteractionIds, interaction.id);
      });

      this.set('activeCalls', filteredList);
      this.notifyPropertyChange('activeCalls');

      _lodash.default.each(oldInteractions, function (interaction) {
        _this2.timeDiff(Ember.get(interaction, 'timeCreated'));

        _this2.get('activeCalls').removeObject(interaction);

        interaction.destroy();
      });
    }
  });

  _exports.default = _default;
});