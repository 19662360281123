define("web-directory/components/entity-fields/validators/image", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    isValid: function isValid(text) {
      return text !== undefined;
    },
    isEmpty: function isEmpty(text) {
      if (!text) {
        return true;
      }

      var value = text.value;
      return value === undefined || value === null || _lodash.default.isEmpty(value);
    }
  };
  _exports.default = _default;
});