define("web-directory/components/device-selection-with-preview/component", ["exports", "ember-webrtc-components/mixins/webrtc-service/auto-save-devices", "npm:localmedia"], function (_exports, _autoSaveDevices, _npmLocalmedia) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed,
      inject = Ember.inject,
      RSVP = Ember.RSVP;

  var _default = Component.extend(_autoSaveDevices.default, {
    classNames: ['device-select-preview'],
    previewMedia: null,
    loading: true,
    showTroubleshooter: false,
    fallBackToScreenShareStream: true,
    errorMessage: null,
    intl: inject.service(),
    session: inject.service(),
    webrtc: inject.service(),
    preferences: inject.service(),
    application: inject.service(),
    webrtcTroubleshooterIframe: computed.reads('session.features.webrtcTroubleshooterIframe'),
    init: function init() {
      this._super.apply(this, arguments);

      this.get('webrtc').on('defaultDeviceChange', this, this._handleDeviceChange);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._handleDeviceChange();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('webrtc').off('defaultDeviceChange', this, this._handleDeviceChange);

      this._cleanupPreview();

      this._cleanupLocalMedia();
    },
    _cleanupPreview: function _cleanupPreview() {
      var media = this.get('previewMedia');

      if (media) {
        media.stop();
        this.set('previewMedia', null);
      }
    },
    _cleanupLocalMedia: function _cleanupLocalMedia() {
      if (this.get('localMedia')) {
        this.get('localMedia').stop();
      }
    },
    isHosted: computed.reads('application.hostedContext.isHostedProp'),
    mediaOptions: computed('selectedMicrophone', 'selectedCamera', 'screenStream', function () {
      var options = this.get('webrtc').getMediaOptions({
        camera: this.get('selectedCamera'),
        microphone: this.get('selectedMicrophone')
      });
      options.screenStream = this.get('screenStream');
      return options;
    }),
    _handleDeviceChange: function _handleDeviceChange() {
      var _this = this;

      this.set('errorMessage', null);
      this.get('webrtc').createMedia({
        requestVideo: false
      }).then(function (media) {
        _this.set('loading', false);

        _this._cleanupPreview(true);

        _this.set('previewMedia', media);

        if (media && media.localStreams && media.localStreams.length === 1 && media.localStreams[0].getVideoTracks().length) {
          _this.get('webrtc').attachMediaStream(media.localStreams[0], _this.$('.preview-video')[0], {
            muted: true,
            mirror: true
          });
        }

        _this.set('audioStream', media.localStreams[0]);
      }).catch(function (err) {
        if (err.name === 'NotAllowedError') {
          _this.set('errorMessage', _this.get('intl').t('purecloud.webrtcMediaNotAllowedError'));
        } else {
          _this.set('errorMessage', _this.get('intl').t('purecloud.webrtcMediaGeneralError'));
        }
      });
    },
    getScreenShareStream: function getScreenShareStream() {
      var _this2 = this;

      return new RSVP.Promise(function (resolve, reject) {
        var media = new _npmLocalmedia.default();

        _this2.set('localMedia', media);

        var requestedTime = window.performance.now();
        media.startScreenShare(function (err, stream) {
          if (err) {
            return _this2.get('webrtc').handleScreenShareError(err, requestedTime).then(_this2.getScreenShareStream).then(resolve, reject);
          } else {
            return resolve(stream);
          }
        });
      });
    },
    actions: {
      shareScreen: function shareScreen() {
        var _this3 = this;

        this.set('showScreenShareConfirmation', false);
        this.getScreenShareStream().then(function (stream) {
          return _this3.set('screenStream', stream);
        }).finally(function () {
          return _this3.set('showTroubleshooter', true);
        });
      },
      dontShareScreen: function dontShareScreen() {
        this.set('showScreenShareConfirmation', false);
        this.set('showTroubleshooter', true);
      },
      done: function done() {
        this._cleanupLocalMedia();
      },
      toggleTroubleshoot: function toggleTroubleshoot() {
        if (this.get('showTroubleshooter')) {
          this.set('showTroubleshooter', false);

          this._cleanupLocalMedia();

          this._handleDeviceChange();
        } else {
          this._cleanupPreview();

          if (this.get('fallBackToScreenShareStream')) {
            // try to get the screen stream before loading the troubleshooter, but if it fails continue anyway
            this.set('showScreenShareConfirmation', true);
          } else {
            this.set('showTroubleshooter', true);
          }
        }
      },
      openSoundPreferences: function openSoundPreferences() {
        this.get('webrtc.settings').openSoundPreference();
      }
    }
  });

  _exports.default = _default;
});