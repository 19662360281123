define("web-directory/routes/content-management", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MODE_CAPTURE = /^(.*)(\?mode=.+)$/;
  var CONTENT_MANAGEMENT = 'content-management',
      ENGAGE = 'engage/embedded',
      ContentManagementRoute = Ember.Route.extend({
    controllerName: ENGAGE,
    documents: Ember.inject.service(),
    engage: Ember.inject.service(),
    isLiveRoute: false,
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var isSelectionMode = MODE_CAPTURE.test(model.cmPath);
      model.engagePath = "contentManagement/".concat(model.cmPath);
      controller.setEngageActive();
      this.set('documents.lastSegment', null);
      this.set('documents.currentSegment', isSelectionMode ? null : model.cmPath);
      this.set('isLiveRoute', true);
    },
    observeSidebarExpansion: Ember.observer('sidebarService.sidebarExpanded', 'documents.currentSegment', 'documents.lastSegment', 'isLiveRoute', function () {
      if (this.get('isLiveRoute')) {
        if (this.get('sidebarService.sidebarExpanded')) {
          this.set('documents.lastSegment', this.get('documents.currentSegment'));
        } else {
          this.set('documents.lastSegment', null);
        }
      }
    }),
    actions: {
      willTransition: function willTransition(transition) {
        var isWithinCM = transition.targetName === CONTENT_MANAGEMENT,
            isWithinEngage = transition.targetName === ENGAGE,
            isDirectoryRoute = this.get('engage').isDirectoryRoute(this.get('documents.currentSegment')),
            isEngage = isWithinCM || isWithinEngage,
            isSideBarExpanded = this.get('sidebarService.sidebarExpanded');

        if (!isEngage) {
          this.controller.setEngageInactive();
        }

        this.set('isLiveRoute', false);

        if (!isWithinCM && !isDirectoryRoute || isSideBarExpanded) {
          this.set('documents.lastSegment', this.get('documents.currentSegment'));
        } else {
          this.set('documents.lastSegment', null);
        }

        return true;
      }
    }
  });
  var _default = ContentManagementRoute;
  _exports.default = _default;
});