define("web-directory/models/github-item", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed,
      observer = Ember.observer;
  var ISSUE_REGEX = /http(?:s)?:\/\/github\.com\/[a-zA-Z0-9_\-.]+\/[a-zA-Z0-9\-.]+\/issues\/([0-9]+)$/i;

  var _default = Ember.Object.extend({
    intl: inject.service(),
    preferences: inject.service(),
    mediaType: null,
    username: null,
    url: null,
    repo: null,
    link: null,
    data: null,
    settings: {},
    showEmbedConfirmation: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('preferences.widgets.githubRepo'); // setup observer

      this.get('preferences').getPreference('widgets.githubRepo').then(function (settings) {
        if (settings === undefined) {
          _this.set('showEmbedConfirmation', true);
        } else {
          var showEmbed = false;

          if (settings.showEmbedConfirmation === undefined) {
            showEmbed = true;
          }

          _this.set('settings', settings);

          _this.set('showEmbedConfirmation', showEmbed);
        }
      });
    },
    isRepo: computed.equal('mediaType', 'github-repo'),
    isGist: computed.equal('mediaType', 'github-gist'),
    isIssue: computed('link', 'isRepo', function () {
      if (!this.get('isRepo')) {
        return false;
      }

      return ISSUE_REGEX.test(this.get('link'));
    }),
    issueNumber: computed('link', function () {
      return _lodash.default.last(this.get('link').match(ISSUE_REGEX));
    }),
    title: computed('username', 'repo', 'isRepo', 'isGist', 'isIssue', function () {
      var intl = this.get('intl');
      var username = this.get('username');

      if (this.get('isGist')) {
        return intl.t('chat.inlineMedia.gistTitle', {
          username: username
        }).toString();
      } else if (this.get('isRepo')) {
        var repo = this.get('repo');

        if (this.get('isIssue')) {
          var issue = this.get('issueNumber');
          return intl.t('chat.inlineMedia.githubIssueTitle', {
            issue: issue,
            repo: repo
          }).toString();
        }

        return intl.t('chat.inlineMedia.githubRepoTitle', {
          username: username,
          repo: repo
        }).toString();
      }
    }),
    embedString: computed(function () {
      return this.get('intl').lookup('chat.inlineMedia.githubItems.embedSupport');
    }),
    shouldEmbed: computed('settings.embed', function () {
      return this.get('settings.embed') || false;
    }),
    onSettingsChange: observer('preferences.widgets.githubRepo', function () {
      var _this2 = this;

      this.get('preferences').getPreference('widgets.githubRepo').then(function (settings) {
        _this2.set('settings', settings);

        _this2.set('showEmbedConfirmation', settings.showEmbedConfirmation);
      });
    }),
    setEmbedPreference: function setEmbedPreference(pref) {
      var settings = this.get('settings');
      Ember.setProperties(settings, {
        embed: pref,
        showEmbedConfirmation: false
      });
      this.set('showEmbedConfirmation', false);
      this.get('preferences').setPreference('widgets.githubRepo', settings);
    }
  });

  _exports.default = _default;
});