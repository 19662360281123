define("web-directory/helpers/meeting-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(date) {
      var locale = this.get('intl.isoLocale');

      if ((0, _moment.default)(date, 'YYYY-MM-DD').isSame(new Date(), 'day')) {
        return "TODAY: ".concat((0, _moment.default)(date, 'YYYY-MM-DD').locale(locale).format('dddd MMMM DD'));
      } else {
        return (0, _moment.default)(date, 'YYYY-MM-DD').locale(locale).format('MMMM DD');
      }
    }
  });

  _exports.default = _default;
});