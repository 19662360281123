define("web-directory/components/entity-fields/phone-field/component", ["exports", "lodash", "web-directory/mixins/field-component"], function (_exports, _lodash, _fieldComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
      inject = Ember.inject,
      computed = Ember.computed,
      observer = Ember.observer,
      Component = Ember.Component;
  var requireFields = ['contactInfo.phone_other'];

  var _default = Component.extend(_fieldComponent.default, {
    classNameBindings: ['isNumberError:invalid-value', 'hasErrorCode:has-error-code'],
    intl: inject.service(),
    webrtc: inject.service(),
    phone: inject.service(),
    session: inject.service(),
    application: inject.service(),
    notification: inject.service(),
    addressformat: inject.service(),
    complexField: true,
    complexValueFields: ['entry.value.number', 'entry.value.extension', 'entry.value.acceptSMS'],
    isActive: false,
    entry: computed.alias('fieldData.value'),
    timers: null,
    actions: {
      setPrimary: function setPrimary() {
        this.set('isPrimary', true);

        if (this.get('session.features.emergencyLocation') && this.get('entry.value.number') && this.setPrimaryCallback) {
          this.setPrimaryCallback(this.get('entry.value.number'));
        }
      },
      setPrimarySMS: function setPrimarySMS() {
        this.set('isPrimarySMS', true);
        this.set('entry.value.acceptsSMS', true);
      },
      toggleSMS: function toggleSMS(event) {
        this.toggleProperty('entry.value.acceptsSMS');

        if (event) {
          event.stopPropagation();
        }
      },
      makeCall: function makeCall() {
        var phoneNumber = this.get('entry');
        var phoneService = this.get('phone');
        var errorCode = this.get('entry.rel.errorCode');

        if (phoneNumber && phoneService.get('enabled')) {
          if (errorCode) {
            var intl = this.get('intl');
            var key = "purecloud.error.api.".concat(errorCode);
            var title = intl.lookup('notification.titles.callError').toString();
            var message = intl.lookup(key, null, errorCode.replace(/_/g, ' ')).toString();
            this.get('notification').error(title, message);
          } else {
            this.handlePhoneCall();
          }
        }
      },
      focus: function focus() {
        this.set('isActive', true);
      },
      updateNumber: function updateNumber(event) {
        this.set('entry.value.number', event.target.value);
      },
      updateExtension: function updateExtension(value) {
        this.set('entry.value.extension', value);
      }
    },
    init: function init() {
      this.set('timers', []);

      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.ensurePopulatedField();
    },
    ensurePopulatedField: observer('editing', function () {
      var timer = Ember.run.scheduleOnce('afterRender', this, this._setupData);
      this.get('timers').addObject(timer);
    }),
    addBehavior: observer('editing', function () {
      var timer;

      if (!this.get('editing')) {
        timer = Ember.run.scheduleOnce('afterRender', this, this.setupTooltips);
        this.get('timers').addObject(timer);
        return;
      }

      timer = Ember.run.scheduleOnce('afterRender', this, this._setupInput);
      this.get('timers').addObject(timer);
    }).on('didInsertElement'),
    dialableNumber: computed('entry.rel.e164', 'entry.value.{number,extension}', function () {
      var e164 = this.get('entry.rel.e164');

      if (e164) {
        return e164;
      }

      var number = this.get('entry.value.number');
      var extension = this.get('entry.value.extension');
      number = number ? number.replace(/\D/g, '') : number;
      extension = extension ? extension.replace(/\D/g, '') : extension;

      if (number) {
        number = this.get('addressformat').formatAddress(number);

        if (extension) {
          return "".concat(number, ";ext=").concat(extension);
        } else {
          return number;
        }
      } else if (extension) {
        return extension;
      }
    }),
    isPrimarySMS: computed('entry._id', 'entity.primaryContactInfo.sms', 'isEmpty', {
      get: function get() {
        return this.checkPrimaryForType('sms');
      },
      set: function set(key, value) {
        var entity = this.get('entity');
        var type = 'sms';
        entity.setPrimaryContact(type, this.get('entry'), this.get('field.fieldPath'));
        return value;
      }
    }),
    isPrimary: computed('entry._id', 'entity.primaryContactInfo.voice', 'isEmpty', {
      get: function get() {
        return this.checkPrimaryForType('voice');
      },
      set: function set(key, value) {
        var entity = this.get('entity');
        var type = this.get('fieldData.field.type');
        entity.setPrimaryContact(type, this.get('entry'), this.get('field.fieldPath'));
        return value;
      }
    }),
    dialCode: computed('entry.value.number', function () {
      var $input = this.get('$input');
      var initialCountry = !_lodash.default.isEmpty($input) ? $input.intlTelInput('getSelectedCountryData') : null;

      if (_lodash.default.isEmpty(initialCountry) && this.get('intl.iso2LocaleCountry')) {
        initialCountry = this.get('intl.iso2LocaleCountry');
      }

      return initialCountry.dialCode;
    }),
    errorMsg: computed('entry.value.{number,extension}', function () {
      var intl = this.get('intl');
      var number = this.get('entry.value.number');
      var extension = this.get('entry.value.extension');
      var $input = this.get('$input');
      var iso2LocaleCountry, initialCountry;

      if (this.get('intl.iso2LocaleCountry')) {
        iso2LocaleCountry = this.get('intl.iso2LocaleCountry.iso2');
      }

      if ($input.intlTelInput('getSelectedCountryData')) {
        initialCountry = $input.intlTelInput('getSelectedCountryData').iso2;
      }

      if (!initialCountry) {
        $input.intlTelInput('setCountry', iso2LocaleCountry);
      }

      if (_lodash.default.isEmpty(number) && !_lodash.default.isEmpty(extension)) {
        return null;
      } // https://github.com/Bluefieldscom/intl-tel-input/blob/master/lib/libphonenumber/src/utils.js#L175


      var errorCode = $input.intlTelInput('getValidationError');
      var message = '';

      switch (errorCode) {
        case intlTelInputUtils.validationError.INVALID_COUNTRY_CODE:
          message = intl.lookup('entity.fieldTypes.phone.validation.invalidCountryCode');
          break;

        case intlTelInputUtils.validationError.TOO_SHORT:
          message = intl.lookup('entity.fieldTypes.phone.validation.tooShort');
          break;

        case intlTelInputUtils.validationError.TOO_LONG:
          // I don't understand this error.  This occurs on U.S. numbers as you type them in with an area code.  Using 'Not a number' instead of too long message.
          // this.set('errorMsg', lookup('entity.fieldTypes.phone.validation.tooLong'));
          message = intl.lookup('entity.fieldTypes.phone.validation.notANumber');
          break;

        case intlTelInputUtils.validationError.NOT_A_NUMBER:
          message = intl.lookup('entity.fieldTypes.phone.validation.notANumber');
          break;

        default:
          message = null;
      }

      return message;
    }),
    isRequiredPhone: computed('field', function () {
      var path = this.get('field').getFieldPath();
      return Ember.A(requireFields).includes(path);
    }),
    isRequiredError: computed('entry.value.{number,extension}', 'isRequiredPhone', function () {
      if (!this.get('isRequiredPhone')) {
        return false;
      }

      var number = this.get('entry.value.number');
      var extension = this.get('entry.value.extension');
      return _lodash.default.isEmpty(number) && _lodash.default.isEmpty(extension);
    }),
    isNumberError: computed('entry.value.{number,extension}', '$input', 'isRequiredPhone', function () {
      var number = this.get('entry.value.number');
      var extension = this.get('entry.value.extension');

      if (!this.get('isRequiredPhone') && _lodash.default.isEmpty(number)) {
        return false;
      } else if (this.get('isRequiredPhone') && _lodash.default.isEmpty(number) && !_lodash.default.isEmpty(extension)) {
        // Any extension is allowed because we dont know what the extension pool contains
        return false;
      }

      var $input = this.get('$input');

      if (!$input || !$input.intlTelInput) {
        return false;
      }

      return !$input.intlTelInput('isValidNumber');
    }),
    errorClass: computed('isRequiredError', 'isNumberError', function () {
      if (this.get('isRequiredError')) {
        return 'has-error';
      }

      if (this.get('isNumberError')) {
        return 'has-warning';
      }

      return '';
    }),
    isValid: computed('entry.value.{number,extension}', 'isRequiredError', function () {
      if (!this.get('editing') || this.isDestroyed || this.isDestroying) {
        return true;
      }

      var isRequiredError = this.get('isRequiredError');
      var isValid = this.get('isRequiredPhone') ? !isRequiredError : true;
      var timer = Ember.run.scheduleOnce('afterRender', this, this._setFieldDataValidity, isValid);
      this.get('timers').addObject(timer);
      return isValid;
    }),
    hasError: computed.not('isValid'),
    hasErrorCode: computed('entry.rel.errorCode', function () {
      return !_lodash.default.isEmpty(this.get('entry.rel.errorCode'));
    }),
    errorMessage: computed('entry.rel.errorCode', function () {
      var errorCode = this.get('entry.rel.errorCode');
      var key = "purecloud.error.api.".concat(errorCode);
      return this.get('intl').lookup(key, null, errorCode.replace(/_/g, ' ')).toString();
    }),
    didUpdateAttrs: function didUpdateAttrs(_ref) {
      var newAttrs = _ref.newAttrs;

      if (!get(newAttrs, 'editing.value') && !this.get('isValid')) {
        this.set('entry.value.number', '');
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.get('timers').forEach(function (timer) {
        return Ember.run.cancel(timer);
      });
      this.$('.phone-input').intlTelInput('destroy');

      this._super.apply(this, arguments);
    },
    checkPrimaryForType: function checkPrimaryForType(type) {
      var entryId = this.get('entry._id');
      var fieldId = this.get("entity.primaryContactInfo.".concat(type, ".firstObject.value.fieldId"));
      return fieldId && fieldId === entryId && !this.get('isEmpty');
    },
    focusOut: function focusOut() {
      this._super.apply(this, arguments);

      this.set('isActive', false);
      var value = this.get('entry.value.number');

      if (value === '+1') {
        this.set('entry.value.number', '');
      }
    },
    focusNewField: function focusNewField() {
      var empty = this.get('isEmpty');
      var added = this.get('entry.isAdded');

      if (added) {
        return;
      }

      this.set('entry.isAdded', true);

      if (empty) {
        this.$('.phone-input').trigger('focus');
      }
    },
    cleanFieldForSave: function cleanFieldForSave() {
      if (this.get('entry.value') && this.get('$input')) {
        var countryData = this.get('$input').intlTelInput('getSelectedCountryData');

        if (countryData && countryData.iso2) {
          this.set('entry.value.countryCode', countryData.iso2.toLocaleUpperCase());
        }
      }
    },
    isComplexFieldEmpty: function isComplexFieldEmpty() {
      var number = this.get('entry.value.number');
      var extension = this.get('entry.value.extension');
      return (typeof number === 'string' && number.length === 0 || number === undefined) && (typeof extension === 'string' && extension.length === 0 || extension === undefined);
    },
    handlePhoneCall: function handlePhoneCall() {
      var _this = this;

      var phoneService = this.get('phone');

      if (this.get('loading')) {
        return;
      }

      this.set('loading', true);
      phoneService.makeCall(this.get('entry'), this.get('entity')).then(function () {
        Ember.run.later(_this, function () {
          _this.set('loading', false);
        }, 100);
      }).catch(function () {
        _this.set('loading', false);
      });
    },
    setupTooltips: function setupTooltips() {
      this.$('[data-toggle="tooltip"]').tooltip();
    },
    _setupData: function _setupData() {
      if (!this.get('editing')) {
        return;
      }

      var entry = this.get('entry');
      var entity = this.get('entity');
      var emptyData = {
        number: '',
        extension: '',
        acceptsSMS: false
      };

      if (_lodash.default.isEmpty(entry) || _lodash.default.isEmpty(entry.value) && !this.get('field.repeatable')) {
        var path = this.get('field').getFieldPath();
        entity.set(path, emptyData);
        this.set('entry', _lodash.default.last(entity.get(path)));
      } else if (!_lodash.default.isEmpty(entry) && _lodash.default.isEmpty(entry.value)) {
        Ember.set(entry, 'value', emptyData);
      }
    },
    _setupInput: function _setupInput() {
      if (!this.get('editing')) {
        return;
      }

      var orgCountryCode = this.get('session.org').getFieldValue('general.defaultCountryCode');
      var countryCode = 'us';

      if (orgCountryCode && orgCountryCode.value) {
        countryCode = orgCountryCode.value.toLowerCase();
      }

      var $input = this.$('.phone-input');
      $input.intlTelInput({
        nationalMode: false,
        utilsScript: "".concat(this.get('application.urlprefix'), "assets/intltelinput/utils.js"),
        // preferredCountries: ['us', 'gb', 'es', 'fr']
        initialCountry: countryCode
      });
      $input.on('blur', function () {
        //Let the Ember input binding know that the plugin updated the value after blur event.
        $input.trigger('change');
      });
      this.set('$input', $input);
      this.notifyPropertyChange('isValid');
    },
    _setFieldDataValidity: function _setFieldDataValidity(value) {
      this.set('fieldData.isValid', value);
    }
  });

  _exports.default = _default;
});