define("web-directory/adapters/group", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;
  var RESTAdapter = _emberData.default.RESTAdapter;

  var _default = RESTAdapter.extend({
    session: inject.service(),
    application: inject.service(),
    store: Ember.inject.service(),
    host: computed.reads('application.purecloudV2BaseUri'),
    directoryHost: computed.reads('application.orgspanBaseUri'),
    namespace: 'api/v2',
    platformNameSpace: 'platform/api/v2',
    buildQuery: function buildQuery() {
      return {
        fl: '*'
      };
    },
    baseUrl: function baseUrl() {
      return "".concat(this.get('host'), "/").concat(this.get('platformNameSpace'), "/groups");
    },
    urlForFindRecord: function urlForFindRecord(id) {
      return "".concat(this.baseUrl(), "/").concat(id, "/profile");
    },
    urlForUpdateRecord: function urlForUpdateRecord(id) {
      return "".concat(this.baseUrl(), "/").concat(id, "/profile");
    },
    entityEndpoint: function entityEndpoint(endpoint, type, id) {
      return "".concat(this.baseUrl(), "/").concat(id, "/").concat(endpoint);
    },
    urlForFindMany: function urlForFindMany() {
      return "".concat(this.get('host'), "/").concat(this.get('namespace'), "/profiles/groups");
    },
    findRecord: function findRecord(store, type, id, snapshot) {
      var url = this.buildURL(type.modelName, id, snapshot, 'findRecord');
      var traditional = true;
      var data = Object.assign({}, data, {
        fields: '*'
      });
      return this.ajax(url, 'GET', {
        data: data,
        traditional: traditional
      }).then(function (gProfile) {
        if (snapshot.record.get('version') && gProfile.version !== snapshot.attr('version')) {
          snapshot.record.set('version', gProfile.version);
        }
        /*
            Is this a new Group?
            When creating a group by default there is only one owner so
            the member count will always be 1 on a create.  The bevy service
            is eventually consistent so we may have a member count of 0
            after our next GET.  Therefore we set the memberCount to 1
            by default.
        */


        if (gProfile.general.memberCount[0].value === 0 && gProfile.individuals.individuals.length === 0 && gProfile.owners.owners.length === 1) {
          gProfile.general.memberCount[0].value = 1;
        }

        return gProfile;
      });
    },
    // Need to overwrite so that we can provide the version on all updates
    // and make sure data isn't key'd by type
    updateRecord: function updateRecord(store, type, snapshot) {
      var _this = this;

      var data = {};
      var groupSerializer = store.serializerFor(type.modelName);
      groupSerializer.serializeIntoHash(data, type, snapshot);

      if (this.get('session.features.groupProileNoIndividualsSave') && data.group.individuals) {
        return this.get('store').findRecord('group-policy', data.group.guid).then(function (policy) {
          data = _this.generateGroupProfilePayload(groupSerializer, data, policy);

          if (data[type.modelName]) {
            data = data[type.modelName];
          }

          return _this.ajax(_this.urlForUpdateRecord(snapshot.id, type.modelName), 'PUT', {
            data: data
          });
        });
      } else {
        data = this.generateGroupProfilePayload(groupSerializer, data);

        if (data[type.modelName]) {
          data = data[type.modelName];
        }

        return this.ajax(this.urlForUpdateRecord(snapshot.id, type.modelName), 'PUT', {
          data: data
        });
      }
    },
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var _this2 = this;

      var id = snapshot.id;
      return this.ajax(this.buildURL(type.modelName, id, snapshot, 'deleteRecord'), 'DELETE', {
        dataType: 'text'
      }).then(function () {
        // After a delete using the public API we need to go get that deleted group
        // to keep ember-data happy.
        var jIdNumber = _this2.getJidIdNumber(snapshot.record.data.contactInfo.chat[0].value.jid);

        return _this2.getDeletedGroup(jIdNumber);
      });
    },
    ajaxOptions: function ajaxOptions(url, type) {
      var hash = this._super.apply(this, arguments); // Our apis are silly...


      if (type && type.toLowerCase() === 'delete') {
        hash.dataType = 'text';
      }

      return hash;
    },
    findMany: function findMany(store, type, ids, snapshots) {
      var _this3 = this;

      var url = this.buildURL(type.modelName, ids, snapshots, 'findMany');
      var uuids = '';
      var jIds = '';
      var urlWithAppendedIds = '';
      var urlDeletedWithAppendedIds = "".concat(this.baseUrl(), "/bulk/deleted?jabberId=");
      ids.forEach(function (id) {
        // accepts jIds or ids
        if (id.indexOf('-') > -1) {
          uuids = uuids.concat(id, ',');
        } else {
          id = _this3.getJidIdNumber(id);
          jIds = jIds.concat(id, ',');
        }
      });

      if (uuids.length > 0) {
        uuids = uuids.substring(0, uuids.length - 1);
        urlWithAppendedIds = urlWithAppendedIds.concat('id=', uuids);
        urlWithAppendedIds = "".concat(url, "?").concat(urlWithAppendedIds);
      } else if (jIds.length > 0) {
        jIds = jIds.substring(0, jIds.length - 1);
        urlWithAppendedIds = urlWithAppendedIds.concat('jabberId=', jIds);
        urlWithAppendedIds = "".concat(url, "?").concat(urlWithAppendedIds);
        urlDeletedWithAppendedIds = urlDeletedWithAppendedIds.concat(jIds);
      } else {
        urlWithAppendedIds = "".concat(url);
      }
      /*
          When using public API / bevy deleted groups are not returned yet they are needed.
          We need to make speical request to get those deleted groups.  WebRtc provides a list
          that is used to fill out is roster...in the form of jabberIds.  As part of this
          we need to evaluate the jabberIds that are missing in the initial GET request,
          find the missing deleted ones and make a request to get those deleted groups.
      */


      if (jIds.length > 0) {
        return this.ajax(urlWithAppendedIds, 'GET').then(function (activeResults) {
          return _this3.getDeletedGroups(urlDeletedWithAppendedIds, activeResults);
        });
      }

      return this.ajax(urlWithAppendedIds, 'GET');
    },
    getDeletedGroups: function getDeletedGroups(deleteUrl, groupsResults) {
      var _this4 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this4.ajax(deleteUrl, 'GET').then(function (deletedGroups) {
          deletedGroups.entities.forEach(function (g) {
            groupsResults.entities.push(_this4.normalizeDeletedGroup(g));
          });
          return resolve(groupsResults);
        });
      });
    },
    getDeletedGroup: function getDeletedGroup(id) {
      var _this5 = this;

      var getUrl = "".concat(this.baseUrl(), "/bulk/deleted?jabberId=").concat(id);
      return new Ember.RSVP.Promise(function (resolve) {
        _this5.ajax(getUrl, 'GET').then(function (deletedGroup) {
          return resolve(deletedGroup);
        });
      });
    },
    findFavorites: function findFavorites() {
      var adapter = this.store.adapterFor('application');
      return adapter.findFavorites.apply(adapter, arguments);
    },
    findGroupMembership: function findGroupMembership(id, params) {
      var query = params;

      if (typeof params.offset === 'number' && params.limit) {
        query.pageNumber = 1 + Math.ceil(params.offset / params.limit);
        query.pageSize = params.limit;
      }

      var url = "".concat(this.baseUrl(), "/").concat(id, "/members");
      return this.ajax(url, 'GET', {
        data: params
      });
    },
    generateGroupProfilePayload: function generateGroupProfilePayload(groupSerializer, json, vmPolicy) {
      var gProfile = json.group;
      gProfile.organizationId = this.get('session.org.guid');
      gProfile.id = gProfile.guid;
      delete gProfile.guid;

      if (gProfile.mt) {
        delete gProfile.mt;
      }

      if (gProfile.meta) {
        delete gProfile.meta;
      }

      delete gProfile.membershipDraft;

      if (gProfile.general.needsCommit) {
        delete gProfile.general.needsCommit;
      }

      if (gProfile.general.exchangeSync) {
        delete gProfile.general.exchangeSync;
      }

      if (gProfile.general.links && gProfile.general.links.length > 0) {
        groupSerializer.cleanGroupLinkIds(gProfile.general.links);
      }

      if (gProfile.contactInfo.email && gProfile.contactInfo.email.length > 0 && gProfile.contactInfo.email[0].value == '') {
        delete gProfile.contactInfo.email;
      }

      if (!Array.isArray(gProfile.contactInfo.groupNumber)) {
        delete gProfile.contactInfo.groupNumber;
      }

      if (gProfile.contactInfo.phone) {
        delete gProfile.contactInfo.phone;
      }

      if (gProfile.contactInfo.groupNumber && gProfile.contactInfo.groupNumber.length > 0) {
        if (gProfile.contactInfo.groupNumber[0].labelKey) {
          delete gProfile.contactInfo.groupNumber[0].labelKey;
        }

        if (!this.hasGroupPhone(gProfile.contactInfo.groupNumber[0])) {
          delete gProfile.contactInfo.groupNumber;
        }
      } // The members API already adds individuals to groups,  we
      // do not send the individuals to keep the request size down.


      if (this.get('session.features.groupProileNoIndividualsSave') && gProfile.individuals) {
        // The exception is when a voicemail policy of SEQUENTIAL is in place,
        // then the individuals must be sent to maintain the order of individuals.
        if (!vmPolicy || vmPolicy.data.groupAlertType !== 'SEQUENTIAL') {
          delete gProfile.individuals;
        }
      }

      return gProfile;
    },
    hasGroupPhone: function hasGroupPhone(numberInfo) {
      var hasNumber = numberInfo.value && numberInfo.value.number && numberInfo.value.number !== '';
      var hasExtension = numberInfo.value && numberInfo.value.extension && numberInfo.value.extension !== '';

      if (hasNumber || hasExtension) {
        return true;
      }

      return false;
    },
    getJidIdNumber: function getJidIdNumber(jid) {
      return jid.indexOf('@') > -1 ? jid.split('@')[0] : jid;
    },
    normalizeDeletedGroup: function normalizeDeletedGroup(deletedGroup) {
      deletedGroup.general = {
        name: [{
          value: deletedGroup.name,
          labelKey: 'name'
        }],
        groupType: [{
          value: deletedGroup.type === 'offical' ? 'groups.offical' : deletedGroup.type,
          labelKey: 'groupType'
        }],
        memberCount: [{
          value: deletedGroup.memberCount,
          labelKey: 'memberCount'
        }]
      };
      deletedGroup.contactInfo = {
        chat: [{
          value: {
            jid: deletedGroup.chat.jabberId
          },
          labelKey: 'chat'
        }]
      };
      delete deletedGroup.name;
      delete deletedGroup.chat;
      delete deletedGroup.groupType;
      delete deletedGroup.memberCount;
      return deletedGroup;
    }
  });

  _exports.default = _default;
});