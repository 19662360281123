define("web-directory/controllers/reset-password", ["exports", "web-directory/controllers/signup"], function (_exports, _signup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _signup.default.extend({
    intl: Ember.inject.service(),
    _heading: Ember.computed(function () {
      return this.get('intl').lookup('signIn.forgotPasswordHeading').toString();
    }),
    _button: Ember.computed(function () {
      return this.get('intl').lookup('signIn.forgotPasswordHeading').toString();
    })
  });

  _exports.default = _default;
});