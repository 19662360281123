define("web-directory/serializers/geolocation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    isNewSerializerAPI: true,
    serializeIntoHash: function serializeIntoHash(data, type, record, options) {
      Ember.merge(data, this.serialize(record, options));
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      var data = {
        geolocation: payload
      };
      return this._super(store, primaryModelClass, data, id, requestType);
    }
  });

  _exports.default = _default;
});