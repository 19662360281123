define("web-directory/utils/uri-builder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = UriBuilder;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var APPS_URI_V2 = 'api/v2/apps';
  var BASE_URI_V2 = 'api/v2/conversations';
  var PC_BASE_URI = 'platform';
  var SEND_MESSAGING_TYPING_URI = 'messages/{conversationId}/communications/{communicationId}/typing';
  var GET_APPS_DOWNLOAD_URI = 'downloads/{downloadId}?contentDisposition=INLINE';
  /**
   * Replaces patterns param names with provided values.
   * @param path - the string path to replace
   * @param values - the replacement values
   */

  function paramReplace(path, baseUri, values) {
    for (var _i = 0, _Object$entries = Object.entries(values); _i < _Object$entries.length; _i++) {
      var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
          key = _Object$entries$_i[0],
          value = _Object$entries$_i[1];

      var pattern = new RegExp("{".concat(key, "}"));
      path = path.replace(pattern, value);
    }

    return "/".concat(PC_BASE_URI, "/").concat(baseUri, "/").concat(path);
  }

  function UriBuilder() {
    return {
      /**
       * Path to notify a message conversation's typing state
       * @param conversationId - the conversation guid
       * @param communicationId - the communication guid
       */
      sendMessagingTypingUri: function sendMessagingTypingUri(conversationId, communicationId) {
        var params = {
          conversationId: conversationId,
          communicationId: communicationId
        };
        return paramReplace(SEND_MESSAGING_TYPING_URI, BASE_URI_V2, params);
      },

      /**
       * Path to retrieve the download URL for the specified download.
       * Used for cookie authentication.
       * @param downloadId - the download guid
       */
      getAppsDownloadUri: function getAppsDownloadUri(downloadId) {
        var params = {
          downloadId: downloadId
        };
        return paramReplace(GET_APPS_DOWNLOAD_URI, APPS_URI_V2, params);
      }
    };
  }
});