define("web-directory/app-providers/holistic-journey-interapptions-provider", ["exports", "web-directory/models/app", "web-directory/utils/boolean-expressions"], function (_exports, _app, _booleanExpressions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.boolExpr = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var assign = Ember.assign,
      inject = Ember.inject,
      isPresent = Ember.isPresent,
      computed = Ember.computed,
      RSVP = Ember.RSVP,
      typeOf = Ember.typeOf;
  var JOURNEY_HAWK_PATH = '/journey-micro/gpe-ui-holistic-customer-journey/#/sessions';
  var BASE_GADGET_PARAMS = {
    environment: _app.PC_ENV_URL_REPLACEMENT_TOKEN,
    gcTargetEnvironment: _app.GC_TARGET_ENV_REPLACEMENT_TOKEN,
    gcHostOrigin: _app.GC_HOST_ORIGIN_REPLACEMENT_TOKEN,
    locale: _app.GC_LANG_TAG_URL_REPLACEMENT_TOKEN
  };

  var HOLISTIC_JOURNEY_HAWK_PARAMS = _objectSpread({}, BASE_GADGET_PARAMS);

  var HolisticJourneyInterapptionsProvider = Ember.Object.extend({
    application: inject.service(),
    intl: inject.service(),
    permissions: inject.service(),
    session: inject.service(),
    assetPrefix: computed.reads('application.urlprefix'),
    id: 'builtInHolisticJourneyInterapptionsProvider',
    type: 'programmatic',
    trustedProvider: true,
    monitorInteractionUpdates: true,
    getAppConfigs: function getAppConfigs(appContext) {
      var appContextType = appContext.type,
          payload = appContext.payload;
      if (appContextType === 'workitem') return RSVP.resolve([]);
      var apps = [];
      var externalContactId = payload.get('participantForCustomer.externalContactId');
      externalContactId = typeOf(externalContactId) === 'string' && isPresent(externalContactId) ? externalContactId.trim() : null;
      var permissions = this.get('permissions');
      var hasPermission = permissions.validateDonutPermission('externalContacts:session:view');

      if (hasPermission && externalContactId) {
        apps.push(this._getHcjHawkConfig({
          externalContactId: externalContactId
        }));
      }

      return RSVP.resolve(apps);
    },
    _getHcjHawkConfig: function _getHcjHawkConfig(holisticJourneyHawkParams) {
      var icon = {
        guxIcon: {
          name: 'customer-journey',
          decorative: true
        }
      };
      return {
        id: 'agent-narrow-composite-journey-gadget',
        name: this.get('intl').t('predictiveEngagement.gadgets.holisticJourneyGadget.title'),
        sandbox: ['allow-same-origin', 'allow-scripts', 'allow-popups', 'allow-forms'],
        path: JOURNEY_HAWK_PATH,
        query: this._buildGadgetQueryString(HOLISTIC_JOURNEY_HAWK_PARAMS, holisticJourneyHawkParams),
        icon: icon,
        monochromicIcon: icon
      };
    },
    _buildGadgetQueryString: function _buildGadgetQueryString() {
      var result = '';

      for (var _len = arguments.length, paramObjs = new Array(_len), _key = 0; _key < _len; _key++) {
        paramObjs[_key] = arguments[_key];
      }

      var params = assign.apply(void 0, [{}].concat(paramObjs));
      var paramCount = 0;
      Object.keys(params).forEach(function (currParam) {
        var currValue = params[currParam];
        result += "".concat(paramCount > 0 ? '&' : '?').concat(encodeURIComponent(currParam), "=");

        if (typeOf(currValue) === 'string' && currValue.indexOf('{{') === 0) {
          // Don't encode interpolated param values, they'll be handled by the apps model
          result += currValue;
        } else {
          result += encodeURIComponent(currValue);
        }

        paramCount++;
      });
      return result;
    }
  });
  var boolExpr = (0, _booleanExpressions.or)((0, _booleanExpressions.and)((0, _booleanExpressions.perm)('journey:session:view'), (0, _booleanExpressions.perm)('journey:event:view')), (0, _booleanExpressions.and)((0, _booleanExpressions.perm)('externalContacts:session:view'), (0, _booleanExpressions.perm)('journey:event:view')));
  _exports.boolExpr = boolExpr;
  var _default = HolisticJourneyInterapptionsProvider;
  _exports.default = _default;
});