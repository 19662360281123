define("web-directory/components/entity-field-section/entity-relationships/relationship-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DDSymc/c",
    "block": "{\"statements\":[[6,[\"if\"],[[33,[\"and\"],[[28,[\"editing\"]],[28,[\"isOtherRelationship\"]]],null]],null,{\"statements\":[[0,\"    \"],[11,\"label\",[]],[15,\"class\",\"hidden\"],[16,\"for\",[34,[[26,[\"elementId\"]],\"-relationship\"]]],[13],[1,[33,[\"t\"],[\"entity.fieldTypes.labels.relationship\"],null],false],[14],[0,\"\\n    \"],[11,\"input\",[]],[16,\"id\",[34,[[26,[\"elementId\"]],\"-relationship\"]]],[16,\"value\",[34,[[28,[\"relationship\",\"label\"]]]]],[15,\"class\",\"form-control purecloud-input\"],[16,\"oninput\",[33,[\"action\"],[[28,[null]],\"updateLabel\"],null],null],[16,\"onfocus\",[33,[\"action\"],[[28,[null]],\"focusInput\"],null],null],[13],[14],[0,\"\\n\\n\"],[6,[\"if\"],[[28,[\"hasLabelChoices\"]]],null,{\"statements\":[[0,\"        \"],[11,\"i\",[]],[15,\"class\",\"ion-arrow-down-b\"],[15,\"tabindex\",\"0\"],[15,\"role\",\"button\"],[5,[\"action\"],[[28,[null]],\"toggleChoices\"]],[13],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},{\"statements\":[[0,\"    \"],[1,[28,[\"relationship\",\"label\"]],false],[0,\"\\n\"]],\"locals\":[]}],[0,\"\\n\"],[11,\"ul\",[]],[16,\"class\",[34,[\"label-choices \",[33,[\"unless\"],[[28,[\"canShowLabelChoices\"]],\"hide\"],null]]]],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"labelChoices\"]]],null,{\"statements\":[[0,\"        \"],[11,\"li\",[]],[15,\"class\",\"label-choice\"],[15,\"tabindex\",\"0\"],[15,\"role\",\"button\"],[5,[\"action\"],[[28,[null]],\"selectLabel\",[28,[\"choice\"]]]],[13],[0,\"\\n            \"],[1,[28,[\"choice\",\"text\"]],false],[0,\"\\n        \"],[14],[0,\"\\n\"]],\"locals\":[\"choice\"]},null],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/entity-field-section/entity-relationships/relationship-label/template.hbs"
    }
  });

  _exports.default = _default;
});