define("web-directory/components/timeline-stream/missed-call-renderer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xwlfC5No",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"header\"],[13],[0,\"\\n\"],[6,[\"entity-image\"],null,[[\"canViewChat\",\"entity\",\"showPresence\",\"enableMiniCard\"],[[28,[\"canViewChat\"]],[33,[\"await\"],[[28,[\"person\"]]],null],true,true]],{\"statements\":[[0,\"        \"],[1,[33,[\"integration-badges\"],null,[[\"entity\"],[[33,[\"await\"],[[28,[\"person\"]]],null]]]],false],[0,\"\\n\"]],\"locals\":[]},null],[0,\"    \"],[11,\"div\",[]],[15,\"class\",\"title\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"primary\"],[13],[0,\"\\n            \"],[11,\"span\",[]],[15,\"class\",\"description\"],[13],[1,[26,[\"callText\"]],true],[14],[0,\"\\n        \"],[14],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"secondary\"],[13],[1,[26,[\"timeDisplay\"]],false],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[6,[\"if\"],[[28,[\"phoneNumber\"]]],null,{\"statements\":[[0,\"        \"],[11,\"div\",[]],[15,\"class\",\"btn-group callback\"],[13],[0,\"\\n            \"],[11,\"button\",[]],[15,\"class\",\"btn btn-purecloud btn-phone timeline-callback\"],[15,\"type\",\"button\"],[5,[\"action\"],[[28,[null]],\"makeCall\"]],[13],[0,\"\\n                \"],[11,\"i\",[]],[15,\"class\",\"pc-moon pc-phone\"],[13],[14],[0,\"\\n            \"],[14],[0,\"\\n        \"],[14],[0,\"\\n\"]],\"locals\":[]},null],[14],[0,\"\\n\"],[11,\"div\",[]],[15,\"class\",\"missed-call-content\"],[13],[0,\"\\n\"],[6,[\"if\"],[[33,[\"and\"],[[28,[\"showVoicemail\"]],[28,[\"entity\",\"voicemail\"]]],null]],null,{\"statements\":[[0,\"        \"],[11,\"div\",[]],[15,\"class\",\"recording\"],[13],[0,\"\\n            \"],[1,[33,[\"audio-player\"],null,[[\"source\"],[[28,[\"voicemail\",\"audioSource\"]]]]],false],[0,\"\\n        \"],[14],[0,\"\\n\"]],\"locals\":[]},null],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/timeline-stream/missed-call-renderer/template.hbs"
    }
  });

  _exports.default = _default;
});