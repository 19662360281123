define("web-directory/components/floorplan-popover/component", ["exports", "popper.js"], function (_exports, _popper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var on = Ember.on,
      Component = Ember.Component,
      run = Ember.run;

  var _default = Component.extend({
    classNames: ['floorplan-popover'],
    classNameBindings: ['show::hide'],
    show: false,
    attachmentId: '',
    hidePopover: function hidePopover() {},
    tetherConstraints: {
      pin: ['left', 'right']
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var show = this.get('show');

      if (show) {
        run.scheduleOnce('afterRender', this, this._createPopper);
      } else {
        run.scheduleOnce('afterRender', this, this._destroyPopper);
      }
    },
    _destroyPopper: function _destroyPopper() {
      if (!this._popper) {
        return;
      }

      this._popper.destroy();

      this._popper = null;
    },
    _createPopper: function _createPopper() {
      var _this = this;

      if (this._popper) {
        return;
      }

      var targetEl = document.querySelector("#".concat(this.get('attachmentId')));
      var popoverEl = this.element;

      if (!targetEl || !popoverEl) {
        return;
      }

      this._popper = new _popper.default(targetEl, popoverEl, {
        placement: 'bottom',
        modifiers: {
          flip: {
            behavior: ['bottom', 'top', 'left']
          },
          preventOverflow: {
            padding: 1
          }
        }
      });
      run.later(function () {
        _this.$('img').on('load', Ember.run.bind(_this, function () {
          if (_this._popper) {
            _this._popper.scheduleUpdate();
          }
        }));
      }, 10);
    },
    attachClickHandler: on('didInsertElement', function () {
      var _this2 = this;

      Ember.$(document).on("click.".concat(this.get('elementId')), Ember.run.bind(this, function (event) {
        if (!_this2.get('show')) {
          return;
        }

        var target = Ember.$(event.target);

        if (target.parents('.floorplan-action').length || target.parents('.floorplan-popover').length || target.parent('.location-name').length || target.parent('.mini-card-component-wrapper').length) {
          return;
        } else {
          _this2.get('hidePopover')();
        }
      }));
    }),
    removeHandlers: on('willDestroyElement', function () {
      Ember.$(document).off(".".concat(this.get('elementId')));
    })
  });

  _exports.default = _default;
});