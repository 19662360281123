define("web-directory/models/callback-communication", ["exports", "ember-purecloud-components/models/callback-communication"], function (_exports, _callbackCommunication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _callbackCommunication.default;
    }
  });
});