define("web-directory/templates/no-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PvWdYySl",
    "block": "{\"statements\":[[11,\"h3\",[]],[15,\"class\",\"text-center\"],[13],[0,\"\\n    \"],[1,[33,[\"t\"],[\"auth.noPermissionsHeader\"],null],false],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[11,\"p\",[]],[15,\"class\",\"text text-center\"],[13],[0,\"\\n    \"],[1,[33,[\"t\"],[\"auth.noPermissions\"],null],false],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[11,\"p\",[]],[15,\"class\",\"text text-center\"],[13],[0,\"\\n    \"],[11,\"a\",[]],[15,\"href\",\"#\"],[15,\"class\",\"btn btn-default\"],[5,[\"action\"],[[28,[null]],\"logout\"]],[13],[0,\"\\n        \"],[1,[33,[\"t\"],[\"components.navMenu.logout\"],null],false],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\"],[11,\"div\",[]],[15,\"class\",\"brand-footer\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"genesys-logo-container\"],[13],[14],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"brand-footer-text\"],[13],[1,[33,[\"t\"],[\"about.copyright\"],[[\"currentYear\"],[[28,[\"applicationService\",\"currentYear\"]]]]],false],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/templates/no-access.hbs"
    }
  });

  _exports.default = _default;
});