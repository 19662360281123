define("web-directory/mixins/conversation", ["exports", "ember-purecloud-components/mixins/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;
  var Promise = Ember.RSVP.Promise;

  var _default = Ember.Mixin.create(_logger.default, {
    session: inject.service(),
    ajax: inject.service(),
    defaultLogLevel: 'debug',

    /*
     * Proxies AJAX requests as raw requests capturing
     * response codes, correlations, etc. and loggin them
     * out to SumoLogic automatically. Failed requests
     * Promises will always resolved with the response,
     * payload, and jqXHR object.
     *
     * @param {string} url - The request url path.
     * @param {object} options - The request options.
     * @param {string} type - The type of request to be made.
     *
     * @returns {Promise} Result of Ajax request
     */
    makeRequest: function makeRequest(url) {
      var _this = this;

      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'get';
      var userId = this.get('session.person.guid');
      var logger = this.logger;

      if (type !== 'raw') {
        options.method = (options.method || type).toUpperCase();
        options.cache = false;
      } else if (type === 'raw') {
        options.method = (options.method || 'get').toUpperCase();
      }

      if (options.method && options.method.toLowerCase() !== 'get') {
        options.dataType = options.dataType || 'json';
      }

      if (options.data && options.dataType && options.dataType.toLowerCase() === 'json' && typeof options.data !== 'string' && options.method.toLowerCase() !== 'get') {
        options.contentType = 'application/json; charset=UTF-8';
        options.data = JSON.stringify(options.data);
      }
      /*
       * Logs the request at a specific log level
       *
       * @param {string} path - The request url path.
       * @param {object} options - The request options.
       * @param {object} payload - The response payload.
       * @param {int} status - The response status code.
       * @param {string} correlation - The correlation guid of the response.
       * @param {strign} logLevel - The level to emit the log with.
       */


      var logRequest = function logRequest(path, options, payload, status, correlation, logLevel) {
        var log = {
          namespace: 'conversations',
          message: 'User request',
          path: path,
          options: options,
          response: {
            status: status,
            correlation: correlation
          },
          user: userId
        };

        if (payload) {
          log.response.payload = payload;
        }

        logger[logLevel]('Conversation Request:', {
          log: log
        });
      };
      /*
       * Parses the response from the request to send to logRequest.
       *
       * @param {object} jqXHR - The jqXHR object.
       * @param {object} payload - The response payload.
       * @param {boolean} isError - Flag used to set appropriate log level.
       */


      var captureRequest = function captureRequest(request, isError) {
        var jqXHR = request.jqXHR;
        var payload = request.payload;

        if (jqXHR) {
          var statusCode = jqXHR.status;
          var correlationId = jqXHR.getResponseHeader('ININ-Correlation-id');
          var logLevel = isError ? 'error' : _this.get('defaultLogLevel');
          logRequest(url, options, payload, statusCode, correlationId, logLevel);
        }
      };
      /*
       * Resolves a provies based on the response of a request.
       *
       * @param {object} response - Response from jqXHR request.
       * @param {boolean} error - Flag use to resolve/reject promise.
       *
       * @returns {Promise} Promise resolve/rejecting jqXHR response.
       */


      var resolveRequest = function resolveRequest(response, error) {
        var resolution = !!error ? 'reject' : 'resolve';

        if (type === 'raw' || !!error) {
          return Promise[resolution](response);
        }

        return Promise[resolution](response.response);
      };
      /*
       * Determines if jqXHR response error is merely a parse error
       * of an empty string returned by publicAPI.
       *
       * @param {response} - Response object from request.
       */


      var isSuccessResponse = function isSuccessResponse(response) {
        if (response) {
          var status = response.status || response.jqXHR.status;

          if (status === 200 || status === 202) {
            return true;
          }
        }

        return false;
      };

      return this.get('ajax').raw(url, options).then(function (response) {
        captureRequest(response);
        return resolveRequest(response);
      }).catch(function (response) {
        // Swallow errors if jQuery is just failing to
        // parse an empty respone as a valid JSON object.
        if (isSuccessResponse(response)) {
          captureRequest(response);
          return resolveRequest(response);
        }

        captureRequest(response, true);
        return resolveRequest(response, true);
      });
    }
  });

  _exports.default = _default;
});