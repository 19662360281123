define("web-directory/components/acd-interactions-panel-v2/interaction-active-timer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jijU16hG",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"activeTimerData\"]]],null,{\"statements\":[[0,\"    \"],[1,[33,[\"t\"],[[28,[\"activeTimerData\",\"text\"]]],null],false],[0,\"\\n\"],[0,\"    \"],[1,[33,[\"duration-format\"],null,[[\"class\",\"endTime\"],[[28,[\"countdownDurationClass\"]],[28,[\"activeTimerData\",\"value\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/acd-interactions-panel-v2/interaction-active-timer/template.hbs"
    }
  });

  _exports.default = _default;
});