define("web-directory/components/entity-fields/location-field/component", ["exports", "ember-data", "lodash", "web-directory/mixins/field-component"], function (_exports, _emberData, _lodash, _fieldComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var inject = Ember.inject,
      computed = Ember.computed,
      observer = Ember.observer,
      Component = Ember.Component;

  var _default = Component.extend(_fieldComponent.default, {
    intl: inject.service(),
    store: inject.service(),
    session: inject.service(),
    location: null,
    parentLocation: null,
    floorLocation: null,
    googleMapsHref: '',
    showFloorplan: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('location', this.get('locationData'));
      this.set('filterLocations', this.filterLocationsHelper.bind(this));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, this.focusFieldOnInsert);
    },
    focusFieldOnInsert: function focusFieldOnInsert() {
      var input = this.$('input');

      if (input) {
        input.trigger('focus');
      }
    },
    actions: {
      selectParentLocation: function selectParentLocation(location) {
        this.set('parentLocation', location);
        this.send('selectLocation', location);
        this.set('isEmpty', true);
      },
      selectFloorLocation: function selectFloorLocation(location) {
        this.set('floorLocation', location);
        this.send('selectLocation', location);
        this.set('isEmpty', false);
      },
      saveNewLocation: function saveNewLocation() {
        var parentLocation = this.get('parentLocation');
        var floorLocation = this.get('floorLocation');

        if (floorLocation) {
          this.send('selectLocation', floorLocation);
        } else {
          this.send('selectLocation', parentLocation);
        }
      },
      cancelNewLocation: function cancelNewLocation() {
        this.get('cancelAddNew')(this.get('fieldData'));
      },
      selectLocation: function selectLocation(location) {
        var entry = this.get('entry');
        var id = location.get('id');
        var value = {
          locationId: id,
          coordinates: {},
          notes: ''
        };

        if (location.get('floorplanImage') && location.get('floorplanImage.length') > 0) {
          value.coordinates = {
            x: 0,
            y: 0
          };
        }

        Ember.set(entry, 'value', value);
        this.set('location', this.get('locationData'));
      },
      removeNewParentLocation: function removeNewParentLocation() {
        this.set('parentLocation', null);
      },
      noop: function noop() {},
      toggleFloorplan: function toggleFloorplan() {
        Ember.run.once(this, this.toggleProperty, 'showFloorplan');
      },
      hideFloorplan: function hideFloorplan() {
        this.set('showFloorplan', false);
        this.set('location', this.get('locationData'));
      },
      confirm: function confirm() {
        var _this = this;

        var location = this.get('location');
        var entity = this.get('entity');

        if (location === undefined) {
          location = Ember.RSVP.resolve(this.get('modal.location'));
        }

        if (entity === undefined) {
          entity = this.get('modal.entity');
        }

        return location.then(function () {
          return entity.save();
        }).then(function () {
          return entity.reload();
        }).catch(function (resp) {
          if (resp.responseText) {
            var json = JSON.parse(resp.responseText);
            var code = Ember.get(json, 'payload.code');

            if (code === 'VERSION_MISMATCH') {
              _this.bubbleAction({
                action: 'confirm'
              });
            }
          }
        });
      },
      cancel: function cancel() {
        var _this2 = this;

        var location = this.get('location');

        if (location === undefined) {
          location = Ember.RSVP.resolve(this.get('modal.location'));
        }

        location.then(function (location) {
          _this2.get('modal.controller.entity').reset();

          location.reload();
        });
      }
    },
    parentLocationName: computed('location.parent.name', function () {
      return this.get('intl').lookup('field.in', {
        location: this.get('location.parent.name')
      });
    }),
    isValid: computed('entry.value.locationId', 'parentLocation', function () {
      var entry = this.get('entry');
      var selectedFloor = !_lodash.default.isEmpty(Ember.get(entry, 'value')) && !_lodash.default.isEmpty(Ember.get(entry, 'value.locationId'));
      var haveParent = !_lodash.default.isEmpty(this.get('parentLocation'));
      return haveParent || selectedFloor;
    }),
    haveFloor: computed('editing', 'location', 'locationData', 'parentLocation', 'selectedFloor', function () {
      var location;

      if (!this.get('location')) {
        location = this.get('locationData');
      } else {
        location = this.get('location');
      }

      if (!this.get('editing')) {
        return true;
      }

      if (this.get('parentLocation') && !this.get('selectedFloor')) {
        return false;
      }

      return location || this.get('selectedFloor');
    }),
    locationData: computed('entry.value.locationId', 'editing', function () {
      var id = this.get('entry.value.locationId');

      var emptyPromise = _emberData.default.PromiseObject.create({
        promise: Ember.RSVP.resolve(undefined)
      });

      if (this.get('parentLocation') && !this.get('floorLocation')) {
        return emptyPromise;
      } else if (id) {
        return this.get('store').findRecord('location', id);
      }

      return emptyPromise;
    }),
    locationAddress: computed('location', 'locationData', function () {
      var location;

      if (!this.get('location')) {
        location = this.get('locationData');
      } else {
        location = this.get('location');
      }

      var promise = location.then(function (location) {
        if (!location) {
          return '';
        }

        if (location.get('isFloor')) {
          return location.get('parent.address');
        }

        return location.get('address');
      });
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    isRichAddress: computed('locationAddress', function () {
      var promise = this.get('locationAddress').then(function (address) {
        return typeof address !== 'string';
      });
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    parentLocations: computed(function () {
      var promise = this.store.findAll('location').then(function (locations) {
        return _lodash.default.filter(locations.content.map(function (x) {
          return x.getRecord();
        }), function (location) {
          return location.get('state') !== 'deleted' && location.get('type') !== 'floor' && location.get('id') !== null;
        });
      });
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    canShowEdit: computed('editing', 'location', function () {
      return this.get('editing') && !_lodash.default.isEmpty(this.get('location.content.parent.content.sublocationOrder'));
    }),
    locationChoices: computed('parentLocation', function () {
      var parentLocation = this.get('parentLocation');

      if (!parentLocation) {
        return _emberData.default.PromiseArray.create({
          promise: Ember.RSVP.resolve([])
        });
      }

      return this.get('parentLocation.floors');
    }),
    choiceText: computed('parentLocation', function () {
      var intl = this.get('intl');
      var parentLocation = this.get('parentLocation');

      if (!parentLocation) {
        return intl.lookup('locations.selectLocation');
      }

      return "".concat(intl.lookup('locations.selectFloor')).concat(parentLocation.get('name'));
    }),
    selectedParentLocation: computed('parentLocation', function () {
      return !_lodash.default.isEmpty(this.get('parentLocation'));
    }),
    editText: computed('location', 'location.isFloor', function () {
      var intl = this.get('intl');

      if (this.get('location.isFloor')) {
        return intl.lookup('locations.selectOfficeShort');
      }

      return intl.lookup('locations.editLocation');
    }),
    attachmentId: computed('elementId', function () {
      return "".concat(this.elementId, "-attachment");
    }),
    setGoogleMapsLinkInfo: observer('location.parent', function () {
      var _this3 = this;

      var parent = this.get('location.parent');

      if (parent) {
        parent.then(Ember.run.bind(this, function (parentLocation) {
          if (_this3.get('_state') === 'inDOM') {
            var address;

            if (parentLocation) {
              address = parentLocation.get('address');
            } else if (!parentLocation) {
              address = _this3.get('location.address');
            }

            if (typeof address === 'string') {
              address = address.replace(/^\s+|\s+$/g, '').replace('\n', ' ');

              if (address.length > 0) {
                _this3.set('googleMapsHref', "https://maps.google.com/?q=".concat(address));
              }
            } else if (address && _typeof(address) === 'object') {
              var street1 = address.street1 || '';
              var street2 = address.street2 || '';
              var street = street1 && street2 ? "".concat(street1, " ").concat(street2) : street1;
              var city = address.city || '';
              var state = address.state || '';
              var zipcode = address.zipcode || '';
              var country = address.country || '';
              address = "".concat(street, " ").concat(city, ", ").concat(state, ", ").concat(zipcode, ", ").concat(country).replace(/^\s+|\s+$/g, '').replace('\n', ' ');

              if (address.length > 0) {
                _this3.set('googleMapsHref', "https://maps.google.com/?q=".concat(address));
              }
            }
          }
        }));
      }
    }),
    parentLocationUpdated: observer('parentLocation', function () {
      if (this.get('parentLocation')) {
        var dropdown = this.$('.dropdown-menu-view');

        if (dropdown) {
          dropdown.addClass('flash');
          Ember.run.scheduleOnce('afterRender', this, this.toggleFlash, dropdown);
        }
      }
    }),
    filterLocationsHelper: function filterLocationsHelper(data) {
      var _this4 = this;

      var entity = this.get('entity');
      var fieldPath = this.get('field.fieldPath');
      var locationIds = entity.getFieldValue(fieldPath).mapBy('value.locationId');

      var locations = _lodash.default.filter(locationIds.map(function (id) {
        if (!id) {
          return;
        }

        var location = _this4.get('store').peekRecord('location', id);

        if (!location) {
          return;
        }

        if (location.get('parent.id')) {
          return location.get('parent.content');
        }

        return location;
      }));

      return _lodash.default.reject(data, function (location) {
        return _lodash.default.includes(locations.mapBy('id'), location.get('id')) || location.get('isFloor');
      });
    },
    toggleFlash: function toggleFlash(dropdown) {
      Ember.run.later(this, function () {
        dropdown.removeClass('flash');
      }, 150);
    },
    showFloorPlan: function showFloorPlan() {
      this.set('showFloorplan', true);
    }
  });

  _exports.default = _default;
});