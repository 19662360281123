define("web-directory/components/multi-select-target-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F32UdzwB",
    "block": "{\"statements\":[[6,[\"unless\"],[[28,[\"hideTargetDropdown\"]]],null,{\"statements\":[[0,\"    \"],[9,\"gux-icon\",{\"attrs\":[[15,\"class\",\"search-icon\"],[15,\"icon-name\",\"search\"],[15,\"decorative\",\"true\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\n\"],[11,\"select\",[]],[15,\"id\",\"targetSelect\"],[15,\"multiple\",\"\"],[15,\"data-role\",\"tagsinput\"],[15,\"class\",\"form-control purecloud-input target-tag\"],[16,\"placeholder\",[26,[\"placeholder\"]],null],[13],[14],[0,\"\\n\\n\"],[6,[\"if\"],[[33,[\"and\"],[[28,[\"open\"]],[33,[\"not\"],[[28,[\"hideTargetDropdown\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[1,[33,[\"target-dropdown\"],null,[[\"authUser\",\"interaction\",\"hideHeader\",\"searchValue\",\"includeFilters\",\"activeParticipantIds\",\"allowRawInputSelection\",\"actionSelected\",\"inputClasses\"],[[28,[\"authUser\"]],[28,[\"mockInteraction\"]],true,[28,[\"searchValue\"]],[28,[\"includeFilters\"]],[28,[\"valueIds\"]],false,[33,[\"action\"],[[28,[null]],\"onTargetSelected\"],null],[28,[\"inputClasses\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/multi-select-target-dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});