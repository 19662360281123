define("web-directory/helpers/is-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Helper = Ember.Helper;

  var _default = Helper.helper(function (params) {
    var left = params[0];
    var right = params[1];
    return left === right;
  });

  _exports.default = _default;
});