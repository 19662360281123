define("web-directory/components/acd-interactions-panel/interaction-script/component", ["exports", "ember-purecloud-components/mixins/logger", "web-directory/mixins/chat-service/message-notifications", "web-directory/mixins/embedded-message-event-handler"], function (_exports, _logger, _messageNotifications, _embeddedMessageEventHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      Component = Ember.Component,
      inject = Ember.inject,
      observer = Ember.observer;

  var _default = Component.extend(_logger.default, _messageNotifications.default, _embeddedMessageEventHandler.default, {
    classNames: ['interaction-script'],
    tagName: 'iframe',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._updateFrameUrl(this.get('frameUrl'));
    },
    intl: inject.service(),
    session: inject.service(),
    permissions: inject.service(),
    hasScriptViewPermission: computed.reads('permissions.canViewPublishedScripts'),
    frameUrl: computed('selectedInteraction', 'intl.isoLocale', 'session.publicApiUser.id', function () {
      var pathName = '/scripter/';
      var queryParameter = "?locale=".concat(this.get('intl.isoLocale'), "&user=").concat(this.get('session.publicApiUser.id'));
      var interactionId = this.get('selectedInteraction.id');
      var isNotListeningOrAssistingAgent = !this.get('selectedInteraction.myselfIsMonitorV2');
      var interactionSupportsScripts = (this.get('selectedInteraction.isCall') || this.get('selectedInteraction.isCallback') || this.get('selectedInteraction.isChat') || this.get('selectedInteraction.isSms') || this.get('selectedInteraction.isEmail')) && isNotListeningOrAssistingAgent;
      var hash = '#';

      if (this.get('hasScriptViewPermission') && interactionId && interactionSupportsScripts) {
        hash = "#interactionId=".concat(interactionId);
      }

      var url = "".concat(pathName).concat(queryParameter).concat(hash);
      this.get('logger').debug('Scripter component - handleLocationChange change', url);
      return url;
    }),
    // Uses location.replace to update the url (rather than binding to "src")
    //  in order to prevent the URL change from adding an entry to the page history
    //  and allowing the browser back button to reverse it.
    _updateFrameUrl: function _updateFrameUrl(newUrl) {
      var _this = this;

      // If the frame isn't ready yet, set a callback for later
      if (!this.element.contentWindow) {
        this.element.onload = function () {
          _this._updateFrameUrl(newUrl);

          _this.element.onload = null;
        };
      } else {
        this.element.contentWindow.location.replace(newUrl);
        var interactionId = this.get('selectedInteraction.id');

        if (interactionId && (!newUrl || !newUrl.includes("#interactionId=".concat(interactionId)))) {
          // Scripter is not applicable for this interaction (i.e. permissions/interaction type)
          // Dispatch an event so the parent component does not indefinitely wait on script resolution
          var scriptReportCallback = this.get('scriptAvailabilityChanged');

          if (typeof scriptReportCallback === 'function') {
            scriptReportCallback(false);
          }
        }
      }
    },
    _updateFrameUrlOnChange: observer('frameUrl', function () {
      this._updateFrameUrl(this.get('frameUrl'));
    }),
    //These handlers are used by the EmbeddedMessageEventHandlerMixin when the message type matches the key
    messageHandlers: {
      toast: function toast(data) {
        var _this2 = this;

        if (data.publishOnUserInteraction) {
          data.clickHandler = function () {
            _this2.sendMessage('publish', data.publishOnUserInteraction);
          };
        }

        this.sendEngageNotification(data);
      },
      scriptexists: function scriptexists(data) {
        this.get('scriptAvailabilityChanged')(data);
      }
    }
  });

  _exports.default = _default;
});