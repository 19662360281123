define("web-directory/components/group-profile/membership-search/component", ["exports", "lodash", "web-directory/mixins/search"], function (_exports, _lodash, _search) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
      run = Ember.run,
      inject = Ember.inject,
      computed = Ember.computed,
      observer = Ember.observer,
      Component = Ember.Component;

  function removeDuplicates(group, key, results) {
    var currentIds;
    var current = group.getFieldValue(key);

    if (current) {
      currentIds = current.mapBy('value');
    }

    if (_lodash.default.includes(key, 'membership')) {
      currentIds = currentIds.map(function (id) {
        var data = _lodash.default.head(id.q) || {};
        return Ember.get(data, 'value');
      });
    }

    if (results && currentIds.length) {
      return results.filter(function (result) {
        var id = result.get('id');
        return !currentIds.includes(id);
      });
    }

    return results;
  }

  var _default = Component.extend(_search.SearchMixin, {
    classNames: ['group-membership-search'],
    intl: inject.service(),
    search: inject.service(),
    entityFilter: 'people',
    group: null,
    searchValue: null,
    mode: null,
    filter: null,
    searchLimit: 6,
    editing: false,
    actions: {
      clearSearch: function clearSearch() {
        this.set('searchValue', '');
        this.updateSearchOnChange();
      },
      select: function select(person) {
        this.sendAction('selectPerson', person);
        this.resetPersonSearch();
      },
      searchInputUpdated: function searchInputUpdated(event) {
        this.set('searchValue', event.target.value);
        this.updateSearchOnChange();
      }
    },
    isRules: computed('filter.key', function () {
      var key = this.get('filter.key');
      return key === 'inclusions' || key === 'exclusions';
    }),
    iconClass: computed('iconClass', function () {
      if (this.get('mode') === 'search') {
        return 'pc-moon pc-search';
      }

      return 'pc pc-add-person';
    }),
    searchText: computed('mode', function () {
      var intl = this.get('intl');

      if (this.get('mode') === 'search') {
        return intl.lookup('groups.searchMembers').toString();
      }

      return intl.lookup('groups.addPerson').toString();
    }),
    dismissed: computed('results.[]', function () {
      return _lodash.default.isEmpty(this.get('results'));
    }),
    results: computed('searchResults.[]', function () {
      var group = this.get('group');
      var key = this.get('filter.path');
      return removeDuplicates(group, key, this.get('searchResults'));
    }),
    showSearchResults: computed('mode', 'dismissed', 'results.[]', function () {
      var mode = this.get('mode');
      var dismissed = this.get('dismissed');
      return mode === 'add' && !dismissed;
    }),
    currentSearchRequestUpdated: observer('currentSearchRequest', function () {
      var _this = this;

      this.get('currentSearchRequest').then(function () {
        _this.set('loading', false);
      });
    }),
    didUpdateAttrs: function didUpdateAttrs(_ref) {
      var newAttrs = _ref.newAttrs,
          oldAttrs = _ref.oldAttrs;

      if (!newAttrs || !oldAttrs) {
        return;
      }

      if (get(newAttrs, 'editing.value') !== get(oldAttrs, 'editing.value')) {
        this.set('searchValue', null);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var filter = this.get('filter');
      var previousFilter = this.get('_previousFilter');

      if (previousFilter && filter !== previousFilter) {
        this.set('searchValue', '');
      }

      this.set('_previousFilter', filter);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.attachOutsideHandler();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.removeOutsideHandler();
    },
    updateSearchOnChange: function updateSearchOnChange() {
      if (this.get('mode') === 'search') {
        this.sendAction('updateSearch', this.get('searchValue'));
      } else {
        run.debounce(this, this.performPersonSearch, 300);
      }
    },
    attachOutsideHandler: function attachOutsideHandler() {
      var _this2 = this;

      Ember.$(window).on("click.".concat(this.get('elementId')), function (event) {
        Ember.run(function () {
          if (_this2.get('dismissed')) {
            return;
          }

          var target = Ember.$(event.target);

          if (!target.parents('.group-membership-search').length) {
            _this2.resetPersonSearch();
          }
        });
      });
    },
    removeOutsideHandler: function removeOutsideHandler() {
      Ember.$(window).off(".".concat(this.get('elementId')));
    },
    performPersonSearch: function performPersonSearch() {
      var _this3 = this;

      var search = this.get('searchValue');

      if (!_lodash.default.isEmpty(search.trim())) {
        this.set('loading', true);
        this.get('search').performSearch(search, 0, 'users').then(function (_ref2) {
          var results = _ref2.results;

          _this3.set('loading', false);

          _this3.set('searchResults', results);
        });
      } else {
        this.set('searchResults', []);
        this.set('searchMeta', {});
      }
    },
    resetPersonSearch: function resetPersonSearch() {
      this.set('searchValue', '');
      this.set('searchResults', []);
      this.set('searchMeta', {});
    }
  });

  _exports.default = _default;
});