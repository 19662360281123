define("web-directory/components/inbox-panel-v2/notes-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      Component = Ember.Component;

  var _default = Component.extend({
    classNames: ['notes-editor'],
    recording: null,
    notes: '',
    attrs: {
      save: function save() {},
      cancel: function cancel() {}
    },
    actions: {
      setNotes: function setNotes(event) {
        this.set('notes', event.target.value);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.set('notes', this.get('recording.note') || '');
    },
    isValid: computed.lt('notes.length', 2000),
    saveDisabled: computed.not('isValid')
  });

  _exports.default = _default;
});