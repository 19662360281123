define("web-directory/initializers/pigeon-service", ["exports", "ember-purecloud-components/services/pigeon"], function (_exports, _pigeon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'setupPigeonService',
    before: 'setupPhoneService',
    initialize: function initialize(application) {
      application.register('service:pigeon', _pigeon.default);
    }
  };
  _exports.default = _default;
});