define("web-directory/group/store-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Mixin = Ember.Mixin,
      RSVP = Ember.RSVP;
  var DEFAULT_PARAMETERS = {
    offset: 0,
    limit: 15
  };

  var _default = Mixin.create({
    findGroupMembership: function findGroupMembership(id) {
      var _this = this;

      var parameters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_PARAMETERS;
      var label = 'Adapter#findGroupMembership';
      var adapter = this.adapterFor('group');
      var serializer = this.serializerFor('person');
      var modelClass = this.modelFor('person');
      var promise = adapter.findGroupMembership(id, parameters);
      promise = RSVP.Promise.resolve(promise, label);
      return promise.then(function (payload) {
        payload = serializer.normalizeArrayResponse(_this, modelClass, payload, null, 'findMany');

        var records = _this.push(payload);

        return records;
      }, null, 'Store#findGroupMembership');
    }
  });

  _exports.default = _default;
});