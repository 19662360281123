define("web-directory/components/acd-interactions-panel/profile-search-result/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Eqxpm5qH",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"search-result-heading\"],[13],[0,\"\\n    \"],[11,\"span\",[]],[15,\"class\",\"user-name truncated-name\"],[13],[1,[28,[\"result\",\"from\",\"name\"]],false],[14],[0,\"\\n    /\\n    \"],[11,\"span\",[]],[15,\"class\",\"timestamp\"],[13],[1,[28,[\"result\",\"time\"]],false],[14],[0,\"\\n\"],[14],[0,\"\\n\"],[11,\"div\",[]],[15,\"class\",\"messages search-result-body\"],[13],[0,\"\\n    \"],[1,[33,[\"render-markdown\"],[[28,[\"result\",\"body\"]]],null],false],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/acd-interactions-panel/profile-search-result/template.hbs"
    }
  });

  _exports.default = _default;
});