define("web-directory/mixins/email-attachments/str-diff", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var StringDiff = Ember.Mixin.create({
    diff: function diff(str1, str2) {
      var y = str1.split('');
      var x = str2.split('');
      var result = '';
      var i = 0;
      var j = 0;
      var preY = '';
      var postY = '';
      var preX = '';
      var postX = ''; // tells difference between two strings when a single change has been made with one or more characters

      while (j < x.length) {
        if (y[i] !== x[j]) {
          result += x[j];
          j++;
          postX = x[j] + x[j + 1];
          continue;
        }

        if (i > 0 && j > 0) {
          if (preX !== preY && postX !== postY && y[i] === x[j]) {
            result += x[j];
            j++;
            postX = x[j] + x[j + 1];
            continue;
          }
        }

        i++;
        j++;
        postX = x[j] + x[j + 1];
        postY = y[i] + y[i + 1];
        preX = x[j - 1] + x[j];
        preY = y[i - 1] + y[i];
      }

      return result.trim();
    }
  });
  var _default = StringDiff;
  _exports.default = _default;
});