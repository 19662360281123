define("web-directory/components/acd-interactions-panel/interaction-interapptions-pool/component", ["exports", "web-directory/services/apps-api-handlers/propose-message-handler", "web-directory/services/apps-api-handlers/open-panel-handler", "web-directory/services/apps-api-handlers/open-window-handler"], function (_exports, _proposeMessageHandler, _openPanelHandler, _openWindowHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed,
      inject = Ember.inject,
      getOwner = Ember.getOwner;

  var _default = Component.extend({
    classNames: ['sub-panel', 'interaction-interapptions-pool'],
    classNameBindings: ['enabled'],
    attributeBindings: ['ariaHiddenAttr:aria-hidden', 'hiddenAttr:hidden'],
    apps: inject.service(),
    stashLogger: inject.service(),
    // Attributes
    appPool: null,
    interapptionHandlers: computed('offerMessage', function () {
      var _this = this;

      var handlers = [];
      handlers.push(_proposeMessageHandler.default.create({
        isActiveInterapption: function isActiveInterapption(id) {
          return id === _this.get('selectedApp.id');
        },
        getInteraction: function getInteraction() {
          return _this.get('interaction');
        },
        offerMessage: this.get('offerMessage'),
        insertChatText: this.get('insertChatText'),
        insertSmsText: this.get('insertSmsText'),
        insertEmailText: this.get('insertEmailText')
      }));
      handlers.push(_openPanelHandler.default.create({
        isActiveInterapption: function isActiveInterapption(id) {
          return id === _this.get('selectedApp.id');
        },
        openPanel: function openPanel(panelId) {
          return _this.get('openPanel')(null, panelId);
        }
      }));
      handlers.push(_openWindowHandler.default.create({
        isActiveApp: function isActiveApp(id) {
          return id === _this.get('selectedApp.id');
        }
      }, getOwner(this).ownerInjection()));
      return handlers;
    }),
    // V3 Attributes
    selectedPanel: null,
    interapptionPanels: [],
    enabled: computed.bool('selectedApp'),
    selectedApp: computed('interapptionPanels', 'selectedPanel', function () {
      var selectedPanel = this.get('selectedPanel');
      var interapptionPanels = this.get('interapptionPanels');

      if (!selectedPanel || !interapptionPanels) {
        return null;
      }

      return interapptionPanels.findBy('stateKey', selectedPanel);
    }),
    // Computeds
    ariaHiddenAttr: computed('enabled', function () {
      return this.get('enabled') ? 'false' : 'true';
    }),
    hiddenAttr: computed('enabled', function () {
      return this.get('enabled') ? null : true;
    }),
    actions: {
      onAppInstanceApiCall: function onAppInstanceApiCall(apiCall) {
        this.get('apps').handleAppApiCall(apiCall, this.get('interapptionHandlers'));
      }
    }
  });

  _exports.default = _default;
});