define("web-directory/components/timeline-stream/time-ago-indicator/component", ["exports", "web-directory/utils/dates"], function (_exports, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var eventTypeIcons = {
    mention: '@',
    'direct-message': '<i class="pc-moon pc-chat"></i>',
    'room-message': '<i class="pc pc-social-group"></i>',
    'missed-call': '<i class="pc-moon pc-phone"></i>'
  };

  var _default = Ember.Component.extend({
    classNames: ['timeline-time-ago-indicator'],
    entity: null,
    dateDisplay: null,
    icon: Ember.computed('entity.type', function () {
      return eventTypeIcons[this.get('entity.type')] || ' ';
    }),
    timeDisplay: Ember.computed('entity.timeAt', function () {
      var locale = this.get('applicationService.locale');
      var mt = this.get('entity.timeAt');
      var now = (0, _dates.default)();
      var wasToday = mt.diff(now, 'days') === 0;
      var wasLastWeek = mt.diff(now, 'days') <= 6;
      mt.locale(locale);

      if (wasToday) {
        // was Today
        return mt.format('LT');
      } else if (wasLastWeek) {
        // was within 7 days
        return mt.format('ddd') + ' ' + mt.format('LT');
      } else {
        // other
        this.set('dateDisplay', mt.format('L'));
        return mt.format('LT');
      }
    })
  });

  _exports.default = _default;
});