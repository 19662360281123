define("web-directory/components/carousel-menu/component", ["exports", "lodash/cloneDeep", "lodash/isEmpty", "lodash/pick"], function (_exports, _cloneDeep, _isEmpty, _pick) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * TODO (Ember Dep):
   * Use the current-when property of the menu component here and
   * in the template as soon as Ember cuts a 1.13.x release
   * supporting the bounded version of current-when.
   *
   * See: https://github.com/emberjs/ember.js/pull/12344
   */
  var CarouselMenu = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['carousel-menu'],
    classNameBindings: ['inSubMenu'],
    root: null,
    currItem: null,
    showIcons: true,
    onMenuChange: Ember.on('init', Ember.observer('menu', function () {
      var root = buildMenuTree(this.get('menu'));
      this.set('root', root);
      this.set('currItem', root);
    })),
    actions: {
      onMenuClick: function onMenuClick(tgt) {
        // Defer to next run loop to allows external listener work; specifically the outside click listener of the drop-down-component
        // NICETOHAVE Can the drop-down-component somehow listen within Ember's run loop?
        Ember.run.next(this, function () {
          if (tgt.back) {
            this.set('currItem', tgt.back);
          } else if (tgt.forward) {
            this.set('currItem', tgt.forward);
          } else {
            this.sendAction('leafItemClick', tgt.node);
          }
        });
      }
    },
    currItems: Ember.computed('currItem', function () {
      var result = [];
      var root = this.get('root');
      var curr = this.get('currItem');

      if (!curr) {
        return result;
      }

      if (curr !== root) {
        result.push(buildMenuItemViewModel(curr, true));
      }

      curr.children && curr.children.forEach(function (child) {
        result.push(buildMenuItemViewModel(child));
      });
      return result;
    }),
    inSubMenu: Ember.computed('currItem', 'root', function () {
      var curr = this.get('currItem');
      var root = this.get('root');

      if (root === null || curr === null) {
        return false;
      }

      return curr !== root;
    })
  });

  function buildMenuItemViewModel(menuNode, isBackNode) {
    var result = (0, _pick.default)(menuNode, ['className', 'iconClassNames', 'label', 'route', 'url', 'target']);
    result.node = menuNode; // Used as payload for custom (i.e. non-link) actions

    if (isBackNode) {
      result.back = menuNode.parent;
    } else {
      // Back nodes are headers; they aren't forward nodes even though they have children
      var forward = menuNode;

      if (menuNode.children === null || menuNode.children === undefined) {
        forward = null;
      }

      result.forward = forward;
    }

    return result;
  }
  /**
   * Converts an array of menu items into an internal tree structure.
   */


  function buildMenuTree(items) {
    var root = {
      children: []
    };

    if (Array.isArray(items) && !(0, _isEmpty.default)(items)) {
      root.children = (0, _cloneDeep.default)(items);
      root.children.forEach(function (child) {
        decorateMenuItem(child, root);
      });
    }

    return root;
  }

  function decorateMenuItem(item, parent) {
    item.parent = parent;

    if (item.children) {
      if (Array.isArray(item.children) && !(0, _isEmpty.default)(item.children)) {
        item.children.forEach(function (child) {
          decorateMenuItem(child, item);
        });
      } else {
        item.children = null;
      }
    }
  }

  var _default = CarouselMenu;
  _exports.default = _default;
});