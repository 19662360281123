define("web-directory/initializers/chat-service", ["exports", "web-directory/services/chat"], function (_exports, _chat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'setupChatService',
    after: 'setupPhoneService',
    initialize: function initialize(application) {
      application.register('service:chat', _chat.default, {
        singleton: true,
        initialize: true
      });
      application.inject('service:chat', 'store', 'service:store');
      application.inject('service:chat', 'session', 'service:session');
      application.inject('service:chat', 'preferences', 'service:preferences');
      application.inject('service:chat', 'analyticsService', 'service:analytics');
      application.inject('service:chat', 'applicationService', 'service:application');
      application.inject('service:chat', 'phoneService', 'service:phone');
      application.inject('service:chat', 'sidebarService', 'service:sidebar');
      application.inject('controller', 'chatService', 'service:chat');
      application.inject('route', 'chatService', 'service:chat');
      application.inject('component', 'chatService', 'service:chat');
    }
  };
  _exports.default = _default;
});