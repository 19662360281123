define("web-directory/components/group-profile/roles-edit/fixtable/list-remove/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    iconClass: Ember.computed('columnDef.iconClass', function () {
      return this.get('columnDef.iconClass') || 'fa fa-minus';
    }),
    class: Ember.computed('columnDef.class', function () {
      return this.get('columnDef.class') ? this.get('columnDef.class') : 'btn btn-danger btn-xs';
    }),
    disabledMessage: Ember.computed('columnDef.disabledMessage', 'dataRow.disabledMessage', function () {
      return this.get('dataRow.disabledMessage') || this.get('columnDef.disabledMessage');
    }),
    actions: {
      remove: function remove(id) {
        if (!this.get('dataRow.isDeleteDisabled')) {
          this.bubbleAction('remove', id);
        }
      }
    }
  });

  _exports.default = _default;
});