define("web-directory/components/search-facet-term/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['search-facet-term'],
    facet: null,
    term: null,
    selectedTerms: [],
    isSelected: false,
    actions: {
      selectTerm: function selectTerm() {
        var term = this.get('term');
        var facet = this.get('facet');
        var action = 'selectTerm';
        this.bubbleAction({
          action: action,
          actionContext: [term, facet]
        });
      }
    },
    selectedTermsChanged: Ember.observer('selectedTerms.[]', function () {
      var terms = this.get('selectedTerms');
      var term = terms.findBy('term', this.get('term'));
      this.set('isSelected', term !== undefined);
    }) //    isSelectedChanged: Ember.observer('isSelected', function () {
    //        var selected = this.get('isSelected');
    //        if (!selected) {
    //            this.send('selectTerm');
    //        }
    //    })
    //    isSelected: Ember.computed('selectedTerms.[]', function () {
    //        var terms = this.get('selectedTerms');
    //        var term = terms.findBy('term', this.get('term'));
    //        return term !== undefined;
    //    })

  });

  _exports.default = _default;
});