define("web-directory/components/preferences-video/component", ["exports", "ember-purecloud-components/mixins/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Component.extend(_logger.default, {
    classNames: ['video-preferences'],
    webrtcSettings: inject.service(),
    session: inject.service(),
    settings: computed.reads('webrtcSettings.settings'),
    updateSettings: function updateSettings() {
      this.get('webrtcSettings').updateSettings(this.get('settings'));
    },
    actions: {
      toggleMic: function toggleMic() {
        this.toggleProperty('settings.voltSettings.startMicMuted');
        this.updateSettings();
        this.logger.info('Video join settings have changed: ', this.get('settings.voltSettings'));
      },
      toggleCamera: function toggleCamera() {
        this.toggleProperty('settings.voltSettings.startCameraMuted');
        this.updateSettings();
        this.logger.info('Video join settings have changed: ', this.get('settings.voltSettings'));
      },
      toggleAlwaysPopout: function toggleAlwaysPopout() {
        this.toggleProperty('settings.directorySettings.alwaysPopoutVideo');
        this.updateSettings();
        this.logger.info('Video join settings have changed ', this.get('settings.directorySettings'));
      },
      togglePrejoin: function togglePrejoin() {
        this.toggleProperty('settings.voltSettings.skipPrejoin');
        this.updateSettings();
        this.logger.info('Video join settings have changed: ', this.get('settings.voltSettings'));
      }
    }
  });

  _exports.default = _default;
});