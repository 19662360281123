define("web-directory/components/audio-player/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LJYAGAHK",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"loading\"]]],null,{\"statements\":[[0,\"    \"],[11,\"span\",[]],[15,\"class\",\"loading\"],[13],[0,\"\\n        \"],[1,[33,[\"t\"],[\"common.loading\"],null],false],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[6,[\"if\"],[[28,[\"loadingError\"]]],null,{\"statements\":[[0,\"        \"],[1,[33,[\"t\"],[\"components.audioPlayer.loadingError\"],null],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"        \"],[11,\"button\",[]],[15,\"class\",\"btn btn-link play-toggle\"],[15,\"type\",\"button\"],[5,[\"action\"],[[28,[null]],\"togglePlay\"]],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"playing\"]]],null,{\"statements\":[[0,\"                \"],[11,\"i\",[]],[15,\"class\",\"pc-moon pc-hold\"],[13],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"                \"],[11,\"i\",[]],[15,\"class\",\"pc pc-play\"],[13],[14],[0,\"\\n\"]],\"locals\":[]}],[0,\"        \"],[14],[0,\"\\n\\n        \"],[1,[33,[\"audio-player/seek-bar\"],null,[[\"sound\",\"duration\",\"currentTime\",\"didSeek\"],[[28,[\"sound\"]],[28,[\"duration\"]],[28,[\"currentTime\"]],\"seekSound\"]]],false],[0,\"\\n\\n        \"],[11,\"div\",[]],[15,\"class\",\"duration-container\"],[13],[0,\"\\n            \"],[1,[33,[\"duration-format\"],null,[[\"classNames\",\"durationInMs\"],[\"current-time\",[28,[\"currentTime\"]]]]],false],[0,\"\\n            |\\n            \"],[1,[33,[\"duration-format\"],null,[[\"classNames\",\"durationInMs\"],[\"total-time\",[28,[\"duration\"]]]]],false],[0,\"\\n        \"],[14],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[]}]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/audio-player/template.hbs"
    }
  });

  _exports.default = _default;
});