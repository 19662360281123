define("web-directory/app-providers/email-gadget-provider", ["exports", "web-directory/utils/boolean-expressions"], function (_exports, _booleanExpressions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.boolExpr = void 0;

  var _default = Ember.Object.extend({
    // Injections
    application: Ember.inject.service('application'),
    intl: Ember.inject.service('intl'),
    session: Ember.inject.service('session'),
    // Attributes
    id: 'emailGadgetProvider',
    type: 'programmatic',
    group: 'conversation',
    trustedProvider: true,
    monitorInteractionUpdates: true,
    // Derived
    features: Ember.computed.readOnly('session.features'),
    isEmailGadgetInIframe: Ember.computed('features', function () {
      return !!this.get('features').nanEmailGadgetIframe;
    }),
    gadgetTargetOrigin: Ember.computed('application.runtime', 'isEmailGadgetInIframe', function () {
      var runtimeEnv = this.get('application.runtime');

      if (runtimeEnv.local) {
        return "https://apps.".concat(runtimeEnv.publicDomainName);
      }

      return window.location.origin;
    }),
    // Methods
    getAppConfigs: function getAppConfigs(interaction) {
      var appDefs = [];
      var urlParams = new URLSearchParams(window.location.search);
      var emailGadgetVersion = urlParams.get('emailGadgetVersion');
      var gadgetOrigin = this.get('gadgetTargetOrigin');
      var local = emailGadgetVersion === 'local';
      var isEmailGadgetInIframe = this.get('isEmailGadgetInIframe');
      var loaderFile = isEmailGadgetInIframe ? 'email-gadget.html' : 'loader.js';
      var url = emailGadgetVersion ? local ? "https://localhost:3000/".concat(loaderFile) : "".concat(gadgetOrigin, "/email-gadget/").concat(emailGadgetVersion, "/").concat(loaderFile) : "".concat(gadgetOrigin, "/email-gadget/").concat(loaderFile);

      if (interaction && interaction.type === 'conversation' && Ember.typeOf(interaction.payload) === 'instance') {
        if (interaction.payload.get('type') === 'email' && interaction.payload.get('isInternalProvider')) {
          appDefs.push(buildEmailGadgetDef(url, isEmailGadgetInIframe, this.get('intl').t('purecloud.email')));
        }
      }

      return Ember.RSVP.resolve(appDefs);
    }
  });

  _exports.default = _default;
  var boolExpr = (0, _booleanExpressions.ft)('ateamEmailGadget');
  _exports.boolExpr = boolExpr;

  var buildEmailGadgetDef = function buildEmailGadgetDef(url, isEmailGadgetInIframe, displayName) {
    return isEmailGadgetInIframe ? {
      id: 'emailConversationGadget',
      displayName: displayName,
      monochromicIcon: {
        type: 'sparkV3SvgSprite',
        classNames: ['roster-email']
      },
      type: 'iframe',
      src: new URL(url),
      sandbox: ['allow-scripts', 'allow-downloads', 'allow-same-origin', 'allow-popups'],
      trustedDataPassing: true,
      startBehavior: 'lazy',
      lifecycleConfig: {
        bootstrap: true,
        bootstrapTimeoutMillis: 15000,
        focus: true,
        blur: true,
        stop: true
      }
    } : {
      id: 'emailConversationGadget',
      displayName: displayName,
      monochromicIcon: {
        type: 'sparkV3SvgSprite',
        classNames: ['roster-email']
      },
      type: 'dynaWebComp',
      startBehavior: 'lazy',
      loaderUrl: url,
      tagName: 'email-gadget',
      lifecycleConfig: {
        bootstrap: true,
        bootstrapTimeoutMillis: 15000,
        focus: true,
        blur: true,
        stop: true
      }
    };
  };
});