define("web-directory/mixins/chat-service/message-splitting", ["exports", "moment", "web-directory/utils/markdown", "web-directory/models/chat-message", "uuid"], function (_exports, _moment, _markdown, _chatMessage, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    batchDataRequests: Ember.inject.service(),
    messageDelayDefault: 200,
    messageDelay: 200,
    maxDelay: 25000,
    _splitOverSizedMessage: function _splitOverSizedMessage(message) {
      //this regex pattern will try to split the message as close to 4000 characters as possible,
      //if 4000 character falls on the middle of a word it will split before the word begins. If splitting
      //isn't possible due to a long string of characters without a space it will just split at the 4,000
      //character mark.
      var pattern = /^(?:([\s\S]{1,4000}(?=[\s]|$))|(?!([\s\S]{1,4000}(?=[\s]|$)))[\s\S]{1,4000})/i;
      var messageQueue = [];

      while (message.length > 0) {
        var result = pattern.exec(message);
        messageQueue.push(result[0]);
        message = message.slice(result[0].length);
      }

      return messageQueue;
    },
    _convertSplitMessagesToWidgets: function _convertSplitMessagesToWidgets(room, messageQueue) {
      var jid = room.get('jid');

      var id = _uuid.default.v4();

      var index = 0;
      return messageQueue.map(function (message) {
        var stanza = {
          name: 'multipart-message',
          contents: JSON.stringify({
            id: id,
            index: index++,
            messageCount: messageQueue.length
          })
        };
        return {
          to: jid,
          body: message,
          children: [stanza]
        };
      });
    },
    sendSplitMessageQueue: function sendSplitMessageQueue(room, messageQueue) {
      var _this = this;

      var deferred = Ember.RSVP.defer();

      this._delayedMessageSender(room, deferred, messageQueue, null);

      deferred.promise.catch(function (unsentMessageQueue) {
        _this._addFailedMessagesToRoom(room, unsentMessageQueue);

        deferred.resolve(null);
      });
      return deferred.promise;
    },
    _addFailedMessagesToRoom: function _addFailedMessagesToRoom(room, unsentMessageQueue) {
      var _this2 = this;

      unsentMessageQueue.forEach(function (splitWidget) {
        var jid = room.get('jid');
        var owner = Ember.getOwner(_this2);

        var stanza = _this2._manuallyCreateStanzaForFailedMessage(splitWidget);

        var message = _chatMessage.default.create({
          from: _this2.get('session.person.jid'),
          oid: _uuid.default.v4(),
          time: (0, _moment.default)(),
          stanza: stanza,
          links: [],
          raw: splitWidget.body,
          to: jid,
          widget: splitWidget,
          sendError: true
        }, owner.ownerInjection());

        message.set('messageBody', (0, _markdown.markdownToHtml)(splitWidget.body));

        _this2.checkForMentions(message);

        _this2.get('batchDataRequests').getChatUser(message.get('from')).then(function (person) {
          message.set('chatUser', person);

          _this2.setMessageReadState(message);

          room.addMessage(message);
        });
      });
    },
    _manuallyCreateStanzaForFailedMessage: function _manuallyCreateStanzaForFailedMessage(splitWidget) {
      var childName = Ember.get(splitWidget, 'children.lastObject.name');
      var childPayload = Ember.get(splitWidget, 'children.lastObject.contents');
      return {
        children: [{
          name: childName,
          children: [childPayload]
        }]
      };
    },
    findMessageById: function findMessageById(room, messageId) {
      var messageCache = room.get('messageCache');

      if (Object.prototype.hasOwnProperty.call(messageCache, messageId)) {
        return messageCache.get(messageId);
      }

      return null;
    },
    _delayedMessageSender: function _delayedMessageSender(room, deferred, messageQueue, pendingMessage) {
      var _this3 = this;

      if (messageQueue.length === 0) {
        deferred.resolve(null);
        return;
      }

      if (this.get('messageDelay') >= this.get('maxDelay')) {
        this.set('messageDelay', this.get('messageDelayDefault'));
        var message = this.findMessageById(room, pendingMessage);
        var splitWidget = messageQueue.shift();
        message.setProperties({
          sendError: true,
          pending: false,
          widget: splitWidget
        });
        deferred.reject(messageQueue);
        return;
      }

      if (pendingMessage !== null) {
        var failedMessage = messageQueue.get('firstObject');
        var splitMessageData = JSON.parse(Ember.get(failedMessage, 'children.lastObject.contents'));
        var splitMessageParent = room.get('splitMessageCache').get(splitMessageData.id);

        if (splitMessageParent.get('messageArray.length') > 1) {
          var _message = this.findMessageById(room, pendingMessage);

          splitMessageParent.get('messageArray').removeObject(_message);
        } else {
          room.get('messages').removeObject(splitMessageParent);
          var splitMessageCache = room.get('splitMessageCache');
          delete splitMessageCache[splitMessageData.id];
        }
      }

      this.sendMessageToRoom(room.get('jid'), null, messageQueue[0]).then(function () {
        messageQueue.shift();

        _this3.set('messageDelay', _this3.get('messageDelayDefault'));

        Ember.run.later(_this3, _this3._delayedMessageSender, room, deferred, messageQueue, null, _this3.get('messageDelay'));
      }).catch(function (messageId) {
        _this3.set('messageDelay', _this3.get('messageDelay') * 2);

        Ember.run.later(_this3, _this3._delayedMessageSender, room, deferred, messageQueue, messageId, _this3.get('messageDelay'));
        return Ember.RSVP.reject();
      });
    }
  });

  _exports.default = _default;
});