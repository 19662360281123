define("web-directory/components/media-providers/video-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7nNe8mWe",
    "block": "{\"statements\":[[6,[\"each\"],[[28,[\"providers\"]]],null,{\"statements\":[[6,[\"if\"],[[28,[\"provider\",\"haveAccess\"]]],null,{\"statements\":[[0,\"        \"],[11,\"li\",[]],[13],[0,\"\\n            \"],[11,\"a\",[]],[15,\"href\",\"#\"],[16,\"class\",[34,[[33,[\"if\"],[[33,[\"await\"],[[28,[\"provider\",\"disabled\"]]],null],\"disabled\"],null]]]],[16,\"aria-disabled\",[33,[\"await\"],[[28,[\"provider\",\"disabled\"]]],null],null],[5,[\"action\"],[[28,[null]],[28,[\"selectProvider\"]],[28,[\"provider\"]]]],[13],[0,\"\\n                \"],[1,[28,[\"provider\",\"label\"]],false],[0,\"\\n            \"],[14],[0,\"\\n\"],[6,[\"if\"],[[33,[\"await\"],[[28,[\"provider\",\"disabled\"]]],null]],null,{\"statements\":[[0,\"                \"],[1,[33,[\"tooltip-on-element\"],null,[[\"side\",\"text\"],[\"right\",[33,[\"t\"],[[33,[\"await\"],[[28,[\"provider\",\"disabledMessage\"]]],null]],null]]]],false],[0,\"\\n\"]],\"locals\":[]},null],[0,\"        \"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[\"provider\"]},null],[11,\"div\",[]],[15,\"class\",\"media-provider-arrow\"],[13],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/media-providers/video-dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});