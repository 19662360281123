define("web-directory/components/field-label/component", ["exports", "lodash", "web-directory/utils/dates"], function (_exports, _lodash, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var on = Ember.on,
      inject = Ember.inject,
      computed = Ember.computed,
      observer = Ember.observer;

  var _default = Ember.Component.extend({
    classNameBindings: ['field.customLabels:custom-label', 'editing', ':label-component'],
    intl: inject.service(),
    field: null,
    entry: null,
    entity: null,
    editing: false,
    isEmpty: false,
    fallbackToEmpty: true,
    showHistoryYearRange: true,
    showInstructionText: true,
    choices: null,
    choicesHidden: true,
    customLabelText: null,
    actions: {
      toggleChoicesView: function toggleChoicesView() {
        this.toggleProperty('choicesHidden');
      },
      selectChoice: function selectChoice(id) {
        this.set('entry.labelKey', id);
        this.set('customLabelText', null);
        this.set('choicesHidden', true);
      }
    },
    setupCustomLabelText: on('init', function () {
      if (this.hasCustomLabelKey()) {
        this.set('customLabelText', this.get('entry.labelKey'));
      }
    }),
    canEdit: computed('editing', 'writeable', 'writeable.isFulfilled', function () {
      var editing = this.get('editing');
      var writeable = this.get('writeable');

      if (writeable && _typeof(writeable) === 'object') {
        return editing && writeable.content;
      }

      return editing && writeable;
    }),
    label: computed('entry.labelKey', 'editing', 'entry.{value.startDate.year,value.endDate.year}', function () {
      // isEmpty is set by the display-field.hbs template
      if (this.get('isEmpty')) {
        return this.get('field.i18nLabel');
      }

      if (!this.get('editing')) {
        if (this.get('field.type') === 'history' && this.get('showHistoryYearRange')) {
          return this.labelForHistoryField();
        }
      }

      var baseKey = this.get('field').getI18nKey();
      var labelKey = this.get('entry.labelKey'); // A custom label is not configured for this field.
      // Or custom labels are in use, but no label key is found, then this is a label key for an empty value.
      // Or group link field

      if (!this.get('field.customLabels') || !labelKey || this.get('field.fieldPath') === 'general.links') {
        return this.get('field.i18nLabel');
      }

      var key = "".concat(baseKey, "_labelKey_").concat(labelKey); // If there is a translation for the custom label key, use it.

      if (!this.hasCustomLabelKey()) {
        return this.get('intl').lookup(key).toString();
      } // Otherwise return the label key as it was typed in.


      return labelKey;
    }),
    customLabels: computed('field.{customLabels,fieldPath}', 'isEmpty', function () {
      return this.get('field.customLabels') && !this.get('isEmpty') && this.get('field.fieldPath') !== 'general.links';
    }),
    labelChoices: computed('entry', function () {
      var _this = this;

      var baseKey = this.get('field').getI18nKey();
      return _lodash.default.map(this.get('field.labelKeys'), function (labelKey) {
        return {
          id: labelKey,
          label: _this.get('intl').lookup("".concat(baseKey, "_labelKey_").concat(labelKey)).toString()
        };
      });
    }),
    instructionText: computed('field.instructionText', function () {
      var field = this.get('field').getI18nKey();
      var key = "".concat(field, "_instructionText");
      var intl = this.get('intl');

      if (intl.query(key)) {
        return intl.lookup(key).toString();
      } else {
        return this.get('field.instructionText');
      }
    }),
    _placeholder: computed(function () {
      return this.get('intl').lookup('common.label').toString();
    }),
    customLabelTextChanged: observer('customLabelText', function () {
      var label = this.get('customLabelText');
      var entry = this.get('entry');

      if (!entry) {
        return;
      }

      if (label) {
        this.set('entry.labelKey', label);
      } else if (_lodash.default.isEmpty(this.get('labelChoices'))) {
        this.set('entry.labelKey', '');
      } else {
        this.set('entry.labelKey', this.get('labelChoices')[0].id);
      }
    }),
    attachClickListener: on('didInsertElement', function () {
      var _this2 = this;

      var clickId = this.get('elementId');
      Ember.$(document).on('click.' + clickId, function (event) {
        Ember.run(function () {
          var pickerParent = $(event.target).parents('.picker'),
              containerParent = $(event.target).parents("#".concat(clickId));

          if (!pickerParent.length || !containerParent.length) {
            _this2.set('choicesHidden', true);
          }
        });
      });
    }),
    attachTooltip: on('didInsertElement', function () {
      var _this3 = this;

      if (this.get('instructionText') && this.get('showInstructionText')) {
        this.$('.picker .btn').tooltip({
          html: true,
          title: function title() {
            return _this3.get('instructionText');
          },
          placement: 'right',
          trigger: 'hover'
        });
      }
    }),
    removeClickListener: on('willDestroyElement', function () {
      Ember.$(document).off(".".concat(this.get('elementId')));
      this.$('i.instruction-text').tooltip('destroy');
    }),
    keyPress: function keyPress(event) {
      if (this.$('input').is(':focus') && event.keyCode === 13) {
        this.set('customLabelText', this.get('customLabelText'));
        this.send('toggleChoicesView');
      }
    },
    // Returned from label computed above if the label is a history field. Label is not like a form label here.
    // It's the value of the thing from entry.value
    labelForHistoryField: function labelForHistoryField() {
      _dates.default.locale(this.get('intl.isoLocale'));

      var startDate = this.get('entry.value.startDate');
      var endDate = this.get('entry.value.endDate');

      if (_lodash.default.isEmpty(startDate) && _lodash.default.isEmpty(endDate)) {
        if (this.get('fallbackToEmpty')) {
          return '';
        }

        return this.get('field.i18nLabel');
      }

      var hasStartDate = Ember.get(startDate, 'year');
      var hasEndDate = endDate ? Ember.get(endDate, 'year') : false;

      if (!hasStartDate && !hasEndDate) {
        return this.get('field.i18nLabel');
      }

      var now = (0, _dates.default)();
      var regex = /DD\W|\WDD/g;
      var localeFormat = now._locale._longDateFormat.L;
      localeFormat = localeFormat.replace(/MM/, 'M');
      var monthYearLocaleFormat = localeFormat.replace(regex, '');
      var monthYearFmt = monthYearLocaleFormat; // cannot hardcode this to a particular format because then we can't adjust based on locale

      var yearFmt = 'YYYY';
      var startDateFmt = yearFmt;
      var endDateFmt = yearFmt;

      if (hasStartDate && startDate.month) {
        startDate = _lodash.default.clone(startDate);
        startDate.month = startDate.month - 1;
        startDateFmt = monthYearFmt;
      } else if (hasStartDate) {
        startDate = _lodash.default.clone(startDate);
        startDate.month = 0;
      }

      if (hasEndDate && endDate.month) {
        endDate = _lodash.default.clone(endDate);
        endDate.month = endDate.month - 1;
        endDateFmt = monthYearFmt;
      } else if (hasStartDate && hasEndDate) {
        endDate = _lodash.default.clone(endDate);
        endDate.month = new Date().getMonth();
      }

      var result;

      if (!startDate && endDate) {
        result = (0, _dates.default)(endDate).format(endDateFmt);
      } else {
        var mStartDate = (0, _dates.default)(startDate);
        var mEndDate = endDate ? (0, _dates.default)(endDate) : (0, _dates.default)();

        if (endDate && endDate.month) {
          mEndDate = mEndDate.endOf('month');
        }

        var difference = mEndDate.diff(mStartDate);

        var duration = _dates.default.duration(difference).humanize();

        var placeholder = this.get('intl').lookup('entity.fieldTypes.history.toPlaceholder');
        var formattedStartDate = mStartDate.format(startDateFmt);
        var formattedEndDate = hasEndDate ? mEndDate.format(endDateFmt) : placeholder.toString();
        result = "".concat(formattedStartDate, "&ndash;").concat(formattedEndDate, "<div class=\"duration label label-default\">").concat(duration, "</div>");
      }

      return Ember.String.htmlSafe(result);
    },
    hasCustomLabelKey: function hasCustomLabelKey() {
      var baseKey = this.get('field').getI18nKey();
      var labelKey = this.get('entry.labelKey');
      return !this.get('intl').query("".concat(baseKey, "_labelKey_").concat(labelKey));
    }
  });

  _exports.default = _default;
});