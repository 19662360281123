define("web-directory/components/entity-fields/validators/email", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function isValidEmail(email) {
    var reg = /^(([^<>()\[\]\.,;:\s@"]+(\.[^<>()\[\]\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,})$/;
    return email.match(reg);
  }

  function isEmpty(email) {
    var value = email && email.value;
    return !!(value === undefined || value === null || _lodash.default.isEmpty(value));
  }

  var _default = {
    isEmpty: isEmpty,
    isValid: function isValid(entry, field) {
      var isGroupField = field.get('section.fieldConfig.entityType') === 'group';

      if (isEmpty(entry) && isGroupField) {
        return true;
      }

      var maxLength = field.get('params.maxLength');
      return !!(entry && entry.value && entry.value.length <= maxLength && !!isValidEmail(entry.value));
    }
  };
  _exports.default = _default;
});