define("web-directory/mixins/embedded-message-event-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var $ = Ember.$,
      Mixin = Ember.Mixin,
      bind = Ember.run.bind;

  var _default = Mixin.create({
    handleMessageEvent: function handleMessageEvent(event) {
      var _this = this;

      Ember.run(function () {
        var iframe = Ember.get(_this, 'element');

        if (iframe) {
          var messageEvent = event.originalEvent;
          var messageData = messageEvent.data;
          var messageOptions = Object.assign({}, messageData.options);

          if (messageEvent.source === iframe.contentWindow && messageData.type) {
            _this.triggerMessageAction(messageData.type.toLowerCase(), messageData.data, messageOptions);
          }
        }
      });
    },
    triggerMessageAction: function triggerMessageAction(type, data) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (this.messageHandlers[type]) {
        this.logger.debug('Handling message type', type, data);
        this.messageHandlers[type].call(this, data, options);
      } else {
        // An in-house utility generates messages in this
        // format that we do not want to log. See: PCDWEBK-4163
        if (type !== 'rpc:request') {
          this.logger.info('Unknown message type', {
            type: type,
            data: data
          });
        }
      }
    },
    startMessageListener: Ember.on('didInsertElement', function () {
      $(window).on("message.".concat(this.elementId), bind(this, this.handleMessageEvent));
    }),
    stopMessageListener: Ember.on('willDestroyElement', function () {
      $(window).off("message.".concat(this.elementId));
    })
  });

  _exports.default = _default;
});