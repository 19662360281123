define("web-directory/components/multi-select-target-dropdown-v2/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t+GZPJeC",
    "block": "{\"statements\":[[1,[33,[\"tags-input\"],null,[[\"value\",\"autofocus\",\"searchValue\",\"placeholder\",\"inputClasses\",\"allowCommaSeparatedParsing\",\"actionAdd\",\"actionSubmit\",\"actionBulkAdd\",\"actionRemove\",\"actionInputFocus\",\"actionInputChange\"],[[28,[\"value\"]],[28,[\"autofocus\"]],[28,[\"searchValue\"]],[28,[\"placeholder\"]],[28,[\"inputClasses\"]],true,[33,[\"action\"],[[28,[null]],\"onAddTag\"],null],[33,[\"action\"],[[28,[null]],\"onSubmit\"],null],[33,[\"action\"],[[28,[null]],\"onBulkAddTags\"],null],[33,[\"action\"],[[28,[null]],\"onRemoveTag\"],null],[33,[\"action\"],[[28,[null]],\"onInputFocus\"],null],[33,[\"action\"],[[28,[null]],\"onInputChange\"],null]]]],false],[0,\"\\n\\n\"],[6,[\"if\"],[[33,[\"and\"],[[28,[\"open\"]],[33,[\"not\"],[[28,[\"hideTargetDropdown\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[1,[33,[\"target-dropdown\"],null,[[\"authUser\",\"interaction\",\"hideHeader\",\"searchValue\",\"includeFilters\",\"activeParticipantIds\",\"allowRawInputSelection\",\"actionSelected\",\"inputClasses\"],[[28,[\"authUser\"]],[28,[\"mockInteraction\"]],true,[28,[\"searchValue\"]],[28,[\"includeFilters\"]],[28,[\"valueIds\"]],false,[33,[\"action\"],[[28,[null]],\"onTargetSelected\"],null],[28,[\"inputClasses\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/multi-select-target-dropdown-v2/template.hbs"
    }
  });

  _exports.default = _default;
});