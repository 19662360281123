define("web-directory/utils/acd-ts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.singularizeMediaType = singularizeMediaType;
  _exports.warnClosureAction = warnClosureAction;
  var COMMUNICATION_TYPE_SINGULAR = ['call', 'email', 'message', 'chat', 'callback'];

  /**
   * Issue warning that closure action was not provided to component.
   * @param {string} actionName The name of closure function that was not provided
   */
  function warnClosureAction(actionName) {
    var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var message = "ACD: ".concat(actionName, " closure action has not been set up for this component.");
    console.warn(message, {
      arguments: args
    });
  }

  function singularizeMediaType(type) {
    return type.substring(0, type.length - 1);
  }
});