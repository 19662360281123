define("web-directory/templates/card/detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hJhjeNUR",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"banner\"],[13],[0,\"\\n    \"],[11,\"h3\",[]],[13],[1,[33,[\"t\"],[\"components.navMenu.cardView\"],null],false],[14],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"banner-nav\"],[13],[0,\"\\n\"],[6,[\"link-to\"],[\"card.tree\",[28,[\"currentPerson\",\"id\"]]],null,{\"statements\":[[0,\"            \"],[11,\"span\",[]],[16,\"aria-label\",[34,[[33,[\"t\"],[\"tooltip.person.treeView\"],null]]]],[13],[0,\"\\n                \"],[11,\"i\",[]],[15,\"class\",\"pc pc-tree-view\"],[15,\"id\",\"tree-view-icon\"],[13],[14],[0,\"\\n                \"],[9,\"gux-tooltip\",{\"attrs\":[[15,\"for\",\"tree-view-icon\"],[15,\"placement\",\"bottom\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[[1,[33,[\"t\"],[\"tooltip.person.treeView\"],null],false]]}],[0,\"\\n            \"],[14],[0,\"\\n\"]],\"locals\":[]},null],[0,\"        \"],[11,\"div\",[]],[15,\"class\",\"card-view-icon-container\"],[15,\"tabindex\",\"-1\"],[16,\"aria-label\",[34,[[33,[\"t\"],[\"tooltip.person.cardView\"],null]]]],[13],[0,\"\\n            \"],[11,\"i\",[]],[15,\"class\",\"pc pc-card-view\"],[15,\"id\",\"card-view-icon\"],[13],[14],[0,\"\\n            \"],[9,\"gux-tooltip\",{\"attrs\":[[15,\"for\",\"card-view-icon\"],[15,\"placement\",\"bottom\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[[1,[33,[\"t\"],[\"tooltip.person.cardView\"],null],false]]}],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[1,[33,[\"card-view\"],null,[[\"entity\",\"setCurrentPerson\"],[[28,[\"model\"]],[33,[\"action\"],[[28,[null]],\"setCurrentPerson\"],null]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/templates/card/detail.hbs"
    }
  });

  _exports.default = _default;
});