define("web-directory/models/group-policy", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    group: _emberData.default.attr(),
    enabled: _emberData.default.attr(),
    groupAlertType: _emberData.default.attr(),
    overflowGroupId: _emberData.default.attr(),
    rotateCallsSecs: _emberData.default.attr(),
    sendEmailNotifications: _emberData.default.attr(),
    disableEmailPii: _emberData.default.attr(),
    stopRingingAfterRotations: _emberData.default.attr(),
    includeEmailTranscriptions: _emberData.default.attr(),
    languagePreference: _emberData.default.attr()
  });

  _exports.default = _default;
});