define("web-directory/components/preferences-language/component", ["exports", "ember-purecloud-components/mixins/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed,
      Component = Ember.Component;

  var _default = Component.extend(_logger.default, {
    classNames: ['language-preferences'],
    session: inject.service(),
    intl: inject.service(),
    selection: null,
    applyFailed: false,
    actions: {
      selectLanguage: function selectLanguage(locale) {
        if (locale === this.get('selection')) {
          return;
        }

        if (locale === this.get('intl.isoLocale')) {
          this.set('selection', null);
          return;
        }

        this.set('selection', locale);
      },
      applyAndReload: function applyAndReload() {
        var _this = this;

        var intl = this.get('intl');
        var locale = this.get('selection');

        if (!locale || locale === intl.get('isoLocale')) {
          return;
        }

        intl.setPreferredLanguage(locale).then(function () {
          _this.set('applyFailed', false);

          window.location.reload();
        }).catch(function (reason) {
          _this.set('applyFailed', true);

          _this.logger.error('setting preferred language failed', {
            error: reason
          });
        });
      }
    },
    languages: computed('intl.{languages,isoLocale,supportedLocales}', 'selection', function () {
      var intl = this.get('intl');
      var current = intl.get('isoLocale');
      var selected = this.get('selection') || current;
      var supportedLocales = intl.get('supportedLocales');
      var languages = intl.get('languages');
      return supportedLocales.map(function (locale) {
        return {
          locale: locale,
          display: languages[locale],
          selected: locale === selected,
          current: locale === current
        };
      }).sort(function (a, b) {
        return a.display.localeCompare(b.display);
      });
    }),
    canApplyAndReload: computed('intl.isoLocale', 'selection', function () {
      var selection = this.get('selection');
      var isoLocale = this.get('intl.isoLocale');
      return selection && selection !== isoLocale;
    })
  });

  _exports.default = _default;
});