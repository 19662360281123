define("web-directory/components/acd-interactions-panel-v2/interaction-type-icon/component", ["exports", "web-directory/utils/supported-messaging-subtypes"], function (_exports, _supportedMessagingSubtypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * The classes to be applied to the component upon render
     */
    classNames: ['interaction-type-icon-v2'],

    /**
     * Bindings of attributes to apply to the component upon render
     */
    attributeBindings: ['accessibleTextKey:title'],

    /**
     * The classes to be dynamically applied to the component based on the values
     * of the corresponding properties
     */
    classNameBindings: ['iconName', 'size'],

    /**
     * The html element to be rendered for this component. Default is `div`
     */
    tagName: 'span',

    /**
     * translation service used to translate the accessibility keys in the component
     */
    intl: Ember.inject.service('intl'),

    /**
     * @attribute
     *
     * The interaction type of the icon being displayed. The type changes the base icon
     */
    type: null,

    /**
     * @attribute
     *
     * The subtype of the interaction. Not applicable to all interaction types.
     */
    subtype: null,

    /**
     * @attribute
     *
     * `true` if the interaction has been marked as disconnected. Changes what icon is rendered if so.
     */
    isDisconnected: false,

    /**
     * @attribute
     *
     * `true` if the interaction is a part of a campaign. This will change the icon to be of preview variety.
     */
    isCampaign: false,

    /**
     * @attribute
     *
     * The size of the icon based on presets to keep the values consistent
     */
    size: 'small',

    /**
     * The locale key to use for accessibility reasons based on provided values
     */
    accessibleTextKey: Ember.computed('type', 'subtype', 'isCampaign', function () {
      var type = this.get('type');
      var baseKey = 'purecloud.acdInteraction.interactionTypes';

      if ('callback' == type && this.get('isCampaign')) {
        return this.get('intl').lookup("".concat(baseKey, ".preview"));
      }

      var subtype = this.get('subtype');

      if ('message' === type) {
        return _supportedMessagingSubtypes.MESSAGING_SUBTYPES.includes(subtype) ? this.get('intl').lookup("".concat(baseKey, ".").concat(type, "Types.").concat(subtype)) : this.get('intl').lookup("".concat(baseKey, ".").concat(type, "Types.unknown"));
      }

      return this.get('intl').lookup("".concat(baseKey, ".").concat(type));
    }),

    /**
     * The name of the icon to render based on provided values
     */
    iconName: Ember.computed('type', 'isDisconnected', 'subtype', 'isCampaign', function () {
      var type = this.get('subtype') || this.get('type');
      var isConnected = !this.get('isDisconnected');

      if ('callback' == type && this.get('isCampaign')) {
        return isConnected ? 'phone-outbound' : 'roster-phone-disable';
      }

      switch (type) {
        case 'callback':
          return 'phone-callback';

        case 'chat':
          return isConnected ? 'roster-chat' : 'roster-chat-disable';

        case 'call':
          return isConnected ? 'phone' : 'legacy/phone-disable';

        case 'email':
          return isConnected ? 'roster-email' : 'roster-email-disable';

        case 'sms':
          return isConnected ? 'legacy/roster-sms' : 'legacy/roster-sms-disable';

        case 'line':
          return isConnected ? 'roster-line' : 'roster-line-disable';

        case 'facebook':
          return isConnected ? 'roster-messenger' : 'roster-messenger-disable';

        case 'instagram':
          return isConnected ? 'roster-instagram' : 'roster-instagram-disable';

        case 'twitter':
          return isConnected ? 'roster-twitter' : 'roster-twitter-disable';

        case 'wechat':
          return isConnected ? 'roster-wechat' : 'roster-wechat-disable';

        case 'whatsapp':
          return isConnected ? 'roster-whatsapp' : 'roster-whatsapp-disable';

        case 'webmessaging':
          return isConnected ? 'chat' : 'chat-disable';

        case 'workitem':
          return isConnected ? 'fa/clipboard-list-regular' : 'custom/clipboard-list-slash-regular';

        default:
          // legacy fallback
          return isConnected ? 'comment-ellipsis' : 'comment-ellipsis-off';
      }
    })
  });

  _exports.default = _default;
});