define("web-directory/components/group-profile/roles-edit/fixtable/bulk-actions/selected-elements/component", ["exports", "web-directory/components/group-profile/roles-edit/fixtable/bulk-actions/selected-elements/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    selectionIsEmpty: Ember.computed('selectedDataRows', function () {
      var selectedDataRows = this.get('selectedDataRows');
      return !selectedDataRows || !selectedDataRows.length;
    })
  });

  _exports.default = _default;
});