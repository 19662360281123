define("web-directory/components/help-panel-v2/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed,
      inject = Ember.inject;

  var _default = Component.extend({
    classNames: ['help-panel-v2'],
    intl: inject.service(),
    application: inject.service(),
    help: inject.service(),
    logger: inject.service('stash-logger'),
    notification: inject.service(),
    showHelp: false,
    contextualHelpTab: true,
    searchHelpTab: false,
    moreHelpTab: false,
    resourceCenter: false,
    currentUrl: '',
    embedConfig: computed('contextualHelpUrl', 'contextualHelpAnchor', function () {
      return {
        id: 'help-panel-embed',
        url: this.get('contextualHelpAnchor') ? "".concat(this.get('contextualHelpUrl'), "?theme=simplified").concat(this.get('contextualHelpAnchor')) : "".concat(this.get('contextualHelpUrl'), "?theme=simplified"),
        sandbox: ['allow-same-origin', 'allow-scripts', 'allow-popups', 'allow-forms']
      };
    }),
    contextualHelpUrl: computed.reads('help.contextualHelpUrl'),
    contextualHelpAnchor: computed.readOnly('help.contextualHelpAnchor'),
    desktopAppUrl: computed('application.isMobile', function () {
      var isMac = navigator.userAgent.indexOf('Mac OS') >= 0;
      var iPhoneLink = 'https://itunes.apple.com/us/app/interactive-intelligence-collaborate/id777864400?mt=8';
      var androidLink = 'https://play.google.com/store/apps/details?id=com.inin.purecloud.directory';
      var link = 'https://apps.mypurecloud.com/directory-windows';

      if (isMac) {
        link = link.replace('windows', 'mac');
      }

      if (this.get('application.isMobile')) {
        var version = navigator.appVersion;

        if (version.indexOf('iPhone') >= 0 || version.indexOf('iPad') >= 0) {
          link = iPhoneLink;
        } else if (version.indexOf('Android') >= 0) {
          link = androidLink;
        }
      }

      return link;
    }),
    _onShowRcArtifact: function _onShowRcArtifact(options) {
      this.send('changeTab', 'searchHelpTab');
      this.set('selectedHelpPage', options.externalArtifactUrl);
    },
    _onOpenContextualHelpTab: function _onOpenContextualHelpTab() {
      this.send('changeTab', 'contextualHelpTab');
    },
    actions: {
      changeTab: function changeTab(propertyName) {
        if (propertyName) {
          this.beginPropertyChanges();
          this.setProperties({
            contextualHelpTab: false,
            moreHelpTab: false,
            searchHelpTab: false,
            resourceCenter: false
          });
          this.set(propertyName, true);

          if (!this.get('contextualHelpTab') && this.get('contextualHelpAnchor')) {
            this.set('help.contextualHelpAnchor', undefined);
          }

          this.endPropertyChanges();
        }
      },
      closePanel: function closePanel() {
        if (this.get('contextualHelpAnchor')) {
          this.set('help.contextualHelpAnchor', undefined);
        }

        this.triggerAction({
          action: 'closePanel'
        });
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var helpService = this.get('help');
      helpService.on(helpService.EVENT_NAMES.SHOW_RC_ARTIFACT, this, this._onShowRcArtifact);
      helpService.on(helpService.EVENT_NAMES.OPEN_CONTEXTUAL_HELP, this, this._onOpenContextualHelpTab);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var helpService = this.get('help');
      helpService.off(helpService.EVENT_NAMES.SHOW_RC_ARTIFACT, this, this._onShowRcArtifact);
      helpService.off(helpService.EVENT_NAMES.OPEN_CONTEXTUAL_HELP, this, this._onOpenContextualHelpTab);
    }
  });

  _exports.default = _default;
});