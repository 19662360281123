define("web-directory/components/wrap-urls", ["exports", "ember-wrap-urls/components/wrap-urls"], function (_exports, _wrapUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _wrapUrls.default;
    }
  });
});