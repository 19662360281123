define("web-directory/components/group-profile/roles-edit/fixtable/is-assigned-toggle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    groupAssignedRoles: Ember.inject.service(),
    isAssigned: Ember.computed('groupAssignedRoles.changeCount', function () {
      return this.get('groupAssignedRoles').containsRole(this.get('dataRow.id'));
    }),
    actions: {
      toggle: function toggle() {
        var isAssigned = !this.get('groupAssignedRoles').containsRole(this.get('dataRow.id'));
        var roleId = this.get('dataRow.id');
        this.bubbleAction('assignRole', roleId, isAssigned);
      }
    }
  });

  _exports.default = _default;
});