define("web-directory/components/entity-field-section/entity-contact-info/contact-group-choices/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "45iQZmc5",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"field\",\"repeatable\"]]],null,{\"statements\":[[6,[\"drop-down\"],null,[[\"showDropdown\",\"dropdownInfo\"],[[28,[\"showDropdown\"]],\"entity-field-section.entity-contact-info.contact-group-choices\"]],{\"statements\":[[0,\"        \"],[11,\"button\",[]],[15,\"class\",\"menu-selector dropdown-button\"],[15,\"type\",\"button\"],[13],[0,\"\\n            \"],[11,\"span\",[]],[13],[1,[26,[\"currentLabel\"]],false],[14],[0,\"\\n            \"],[11,\"i\",[]],[15,\"class\",\"pc pc-caret-down\"],[13],[14],[0,\"\\n        \"],[14],[0,\"\\n        \"],[11,\"ul\",[]],[16,\"class\",[34,[\"dropdown-menu \",[33,[\"if\"],[[28,[\"showDropdown\"]],\"show\"],null]]]],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"fieldChoices\"]]],null,{\"statements\":[[0,\"                \"],[11,\"li\",[]],[15,\"class\",\"clickable\"],[13],[0,\"\\n                    \"],[1,[33,[\"t\"],[[28,[\"choice\",\"keyPath\"]]],null],false],[0,\"\\n                \"],[14],[0,\"\\n\"]],\"locals\":[\"choice\"]},null],[0,\"        \"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},{\"statements\":[[0,\"    \"],[11,\"span\",[]],[13],[1,[26,[\"currentLabel\"]],false],[14],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/entity-field-section/entity-contact-info/contact-group-choices/template.hbs"
    }
  });

  _exports.default = _default;
});