define("web-directory/utils/request", ["exports", "ember-data", "ember-ajax/errors", "web-directory/utils/types"], function (_exports, _emberData, _errors, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLoggableRequestFailureDetails = getLoggableRequestFailureDetails;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function getLoggableRequestFailureDetails(errorResponse) {
    if (!errorResponse || _typeof(errorResponse) !== 'object') {
      return undefined;
    }

    try {
      if (errorResponse instanceof _emberData.default.AdapterError) {
        return getAdapterErrorLoggableDetails(errorResponse);
      } else if ((0, _errors.isAjaxError)(errorResponse)) {
        return getAjaxErrorLoggableDetails(errorResponse);
      } else if (isRawErrorResponse(errorResponse)) {
        return getRawAjaxErrorLoggableDetails(errorResponse);
      }
    } catch (_) {}

    return undefined;
  }

  function getAdapterErrorLoggableDetails(adapterError) {
    if ((0, _types.hasKey)(adapterError, 'errors') && Array.isArray(adapterError.errors) && adapterError.errors.length > 0) {
      var error = adapterError.errors[0];

      if (error && _typeof(error) === 'object') {
        // Tragically, all the various adapters in this baseline are inconsistent
        // So, we need to check the root error as well as error.detail and error.details for the server payload
        return Object.assign.apply(Object, [{}].concat(_toConsumableArray([error, error.detail, error.details].map(function (curr) {
          var payloadDetails = getLoggableRequestFailureDetailsPartial(curr);

          if (!payloadDetails) {
            return undefined;
          } // Prune out undefined values so they won't override previous, non-undefined values in Object.assign


          return Object.fromEntries(Object.entries(payloadDetails).filter(function (_ref) {
            var _ref2 = _slicedToArray(_ref, 2),
                value = _ref2[1];

            return value !== undefined;
          }));
        }))));
      }
    }

    return undefined;
  }

  function getAjaxErrorLoggableDetails(_ref3) {
    var status = _ref3.status,
        payload = _ref3.payload;
    var result = {
      status: status
    };

    if (payload && _typeof(payload) === 'object' && (0, _types.hasKey)(payload, 'contextId') && typeof payload.contextId === 'string' && isUuid(payload.contextId)) {
      result.correlationId = payload.contextId;
    }

    return result;
  }

  function getRawAjaxErrorLoggableDetails(rawErrorResponse) {
    return {
      status: rawErrorResponse.jqXHR.status,
      correlationId: rawErrorResponse.jqXHR.getResponseHeader('inin-correlation-id') || undefined
    };
  }

  function getLoggableRequestFailureDetailsPartial(payload) {
    if (!payload || _typeof(payload) !== 'object') {
      return undefined;
    }

    var result = undefined;

    if ((0, _types.hasKey)(payload, 'status')) {
      if (typeof payload.status === 'number' && Number.isInteger(payload.status)) {
        result = result || {};
        result.status = payload.status;
      } else if (typeof payload.status === 'string') {
        var statusInt = parseInt(payload.status, 10);

        if (Number.isInteger(statusInt) && payload.status === statusInt + '') {
          result = result || {};
          result.status = statusInt;
        }
      }
    }

    if ((0, _types.hasKey)(payload, 'contextId') && typeof payload.contextId === 'string' && isUuid(payload.contextId)) {
      result = result || {};
      result.correlationId = payload.contextId;
    }

    return result;
  }

  var UUID_REGEXP = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/;

  function isUuid(toCheck) {
    return UUID_REGEXP.test(toCheck);
  }

  function isRawErrorResponse(toCheck) {
    if (!toCheck || _typeof(toCheck) !== 'object') return false; // We can reasonably assume this is a ember-ajax RawErrorResponse

    return ['payload', 'textStatus', 'jqXHR', 'errorThrown', 'response'].every(function (currProp) {
      return Object.prototype.hasOwnProperty.call(toCheck, currProp);
    });
  }
});