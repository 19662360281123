define("web-directory/components/integration-badge/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed,
      inject = Ember.inject;

  var _default = Component.extend({
    classNames: ['integration-badge'],
    tagName: 'span',
    intl: inject.service(),
    // represents what integration we want to show
    integration: null,
    isSmall: false,
    useLightIcon: false,
    iconUri: computed('integration.icon', 'isSmall', 'useLightIcon', function () {
      if (this.get('isSmall')) {
        return this.get('useLightIcon') ? this.get('integration.badgeIcons.light') : this.get('integration.badgeIcons.dark');
      }

      return this.get('integration.icon');
    }),
    translation: computed.reads('integration.translation')
  });

  _exports.default = _default;
});