define("web-directory/components/time-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9bj8x0kh",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[11,\"label\",[]],[16,\"class\",[34,[[33,[\"if\"],[[28,[\"srOnly\"]],\"sr-only\"],null]]]],[16,\"for\",[34,[[33,[\"concat\"],[[28,[\"elementId\"]],\"-time-picker\"],null]]]],[13],[1,[33,[\"t\"],[[28,[\"label\"]]],null],false],[14],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\n\"],[1,[33,[\"input\"],null,[[\"id\",\"class\",\"type\",\"placeholder\",\"disabled\"],[[33,[\"concat\"],[[28,[\"elementId\"]],\"-time-picker\"],null],\"timepicker form-control input-small\",\"text\",[28,[\"placeholder\"]],[28,[\"disabled\"]]]]],false],[0,\"\\n\\n\"],[6,[\"if\"],[[28,[\"showIcon\"]]],null,{\"statements\":[[0,\"    \"],[11,\"svg\",[]],[15,\"class\",\"svg-icon\"],[13],[0,\"\\n        \"],[11,\"use\",[]],[15,\"xlink:href\",\"#icon-clock\",\"http://www.w3.org/1999/xlink\"],[13],[14],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/time-picker/template.hbs"
    }
  });

  _exports.default = _default;
});