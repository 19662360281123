define("web-directory/components/entity-contact-queue/component", ["exports", "lodash", "web-directory/mixins/flag", "ember-purecloud-components/mixins/logger", "web-directory/models/person", "web-directory/components/entity-contact/component"], function (_exports, _lodash, _flag, _logger, _person, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_flag.default, _logger.default, {
    classNames: ['entity-contact'],
    session: Ember.inject.service(),
    sidebar: Ember.inject.service(),
    phoneService: Ember.inject.service('phone'),
    layoutName: 'components/entity-contact',
    makingCall: false,
    queue: null,
    phoneNumber: null,
    call: null,
    actions: {
      makeCall: function makeCall() {
        var _this = this;

        if (!this.get('makingCall')) {
          var person = this.get('otherParty.content') || this.get('person');
          var queue = this.get('queue.content');
          var phoneNumber = this.get('phoneNumber');
          var callPromise;
          this.set('makingCall', true);

          if (this.get('call.isAcdCall')) {
            if (person instanceof _person.default) {
              callPromise = this.phoneService.makeCall(person, null, queue);
            } else if (this.get('person') && _lodash.default.isEmpty(phoneNumber)) {
              this.set('makingCall', false);

              this._super();

              return;
            } else {
              callPromise = this.phoneService.makeCall(phoneNumber, null, queue);
            }
          } else {
            callPromise = this.phoneService.makeCall(queue, null, false);
          }

          callPromise.then(function () {
            if (_this.get('call.isAcdCall') && !_lodash.default.isEmpty(queue)) {
              if (_this.get('sidebar.communicationPanel') !== 'acdInteractions') {
                _this.get('sidebar').togglePanel('acdInteractions');
              }
            }
          }).finally(function () {
            _this.set('makingCall', false);
          });
        }
      },
      flagInteraction: function flagInteraction(action) {
        this.flagInteractionAction(action);
      }
    }
  });

  _exports.default = _default;
});