define("web-directory/components/target-dropdown/detail-popover/detail-popover-list-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h+kbim8a",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"icon\"]]],null,{\"statements\":[[0,\"    \"],[11,\"div\",[]],[15,\"class\",\"detail-popover-list-item-icon\"],[13],[0,\"\\n        \"],[9,\"gux-icon\",{\"attrs\":[[16,\"icon-name\",[26,[\"icon\"]],null],[15,\"decorative\",\"true\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]},null],[11,\"div\",[]],[15,\"class\",\"detail-popover-list-item-info\"],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"label\"]]],null,{\"statements\":[[0,\"        \"],[11,\"p\",[]],[15,\"class\",\"detail-popover-list-item-label\"],[13],[1,[26,[\"label\"]],false],[14],[0,\"\\n\"]],\"locals\":[]},null],[6,[\"if\"],[[28,[\"value\"]]],null,{\"statements\":[[0,\"        \"],[11,\"p\",[]],[15,\"class\",\"detail-popover-list-item-value\"],[13],[1,[26,[\"value\"]],false],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[6,[\"if\"],[[28,[\"emptyState\"]]],null,{\"statements\":[[0,\"        \"],[11,\"p\",[]],[15,\"class\",\"detail-popover-list-item-empty\"],[13],[1,[26,[\"emptyState\"]],false],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"        \"],[18,\"default\"],[0,\"\\n    \"]],\"locals\":[]}]],\"locals\":[]}],[14]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/target-dropdown/detail-popover/detail-popover-list-item/template.hbs"
    }
  });

  _exports.default = _default;
});