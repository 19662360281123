define("web-directory/mixins/phone-service/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var observer = Ember.observer,
      Logger = Ember.Logger;

  var _default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);

      this.get('pigeon').on('my-conversations', this, this._onMyConversationEvent);
      this.get('pigeon').on('callforwarding', this, this._updateCallForwarding); // in mixins/phone-service/call-forward

      this.get('pigeon').on('connect', this, this._onPigeonConnect);
      this.get('communication').on('endCall', this, this._endCall);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      if (this.get('communication').off) {
        this.get('communication').off('endCall', this, this._endCall);
      }

      this.get('pigeon').off('my-conversations', this, this._onMyConversationEvent);
      this.get('pigeon').off('station', this, this._updateStationEvent);
      this.get('pigeon').off('callforwarding', this, this._updateCallForwarding);
      this.get('pigeon').off('connect', this, this._onPigeonConnect);
    },
    registerCPTopics: observer('user.id', function () {
      var id = this.get('user.id');
      var pigeon = this.get('pigeon');
      var topics = [];

      if (id && !this.get('registeredTopics')) {
        this.set('registeredTopics', true);
        topics = ['station', 'conversations', 'voicemail.messages', 'userrecordings', 'fax.documents', 'evaluations'];
        this.get('pigeon').on("v2.users.".concat(id, ".station"), this, this._updateStationEvent); // in mixins/phone-service/station

        return topics.map(function (t) {
          return "v2.users.".concat(id, ".").concat(t);
        }).forEach(function (t) {
          return pigeon.registerPriorityTopic(t);
        });
      }
    }),
    updateActiveConversationEntry: function updateActiveConversationEntry(conversation) {
      var type = this.getInteractionTypeFromCP(conversation);

      if (!type.isCall || type.isCall && type.isCallback || type.isFax) {
        return;
      }

      var conversationData;
      var currentCallState;
      var conversationDetails = this.get('conversations').findBy('id', conversation.id);

      if (conversationDetails) {
        var participantOnExistingConversation = conversationDetails.get('participants').findBy('userId', this.get('personGuid'));

        if (!participantOnExistingConversation) {
          Logger.error("updateActiveConversationEntry: Expected to find person ".concat(this.get('personGuid'), " on conversation ").concat(conversation.id, " but person was not found"));
        } else {
          var participantCall = this.getParticipantCall(participantOnExistingConversation);
          currentCallState = participantCall.state ? participantCall.state.toLowerCase() : null;
        }
      }

      var participants = Ember.get(conversation, 'participants');
      var isAgentCall = participants.any(function (party) {
        return party.purpose ? party.purpose === 'agent' : false;
      });
      var participantsForUser = participants.filterBy('userId', this.get('personGuid'));
      var participantOnUpdatedConversation;

      if (participantsForUser.length) {
        participantOnUpdatedConversation = participantsForUser[participantsForUser.length - 1];
      }

      var updatedParticipantCall = this.getParticipantCall(participantOnUpdatedConversation);
      var participantOnCall = updatedParticipantCall ? ['alerting', 'contacting', 'dialing', 'connected'].indexOf(updatedParticipantCall.state.toLowerCase()) > -1 : false;
      var participantIsNotVoicemail = participantOnUpdatedConversation.purpose ? participantOnUpdatedConversation.purpose.toLowerCase() !== 'voicemail' : false;
      var isActiveOnCall = updatedParticipantCall && participantOnCall && participantIsNotVoicemail;
      var newCallState = updatedParticipantCall.state.toLowerCase();

      if (isAgentCall && currentCallState !== 'connected' && newCallState === 'alerting') {
        Ember.set(conversation, 'acceptedByClient', false);
      }

      if (currentCallState && participantIsNotVoicemail && currentCallState !== 'connected' && newCallState === 'connected') {
        this.get('headset').answerCall(conversation.id, this.get('connectedConversations.length') >= 1);
      }

      if (currentCallState && currentCallState !== 'disconnected' && newCallState === 'disconnected') {
        this.get('headset').endCall(conversation.id, this.get('connectedConversations.length') > 1);
      }

      var onCall = conversationDetails ? conversationDetails.get('onCall') : false;

      if (isAgentCall || isActiveOnCall || onCall || newCallState === 'terminated') {
        conversationData = this.updateConversationArray(conversation, conversationDetails, isAgentCall);
      }

      if (conversationData || newCallState === 'terminated') {
        this.notifyPropertyChange('conversations');
        this.notifyPropertyChange('recentDisconnectedAgentCalls');

        this._logConversationDetails(conversationData);
      }

      conversationData = this._updateModelForConsult(conversationData, conversation);
      return conversationData;
    },
    //TODO | SERVOPS-11175 | change this if/when service updates to include consultParticipant status for participants after customer departs
    _updateModelForConsult: function _updateModelForConsult(conversationData, conversation) {
      if (conversation && conversation.participants) {
        var consultParticipantId = conversation.participants.filterBy('purpose', 'customer').mapBy('consultParticipantId').pop(); // the consultParticipantId needs to be retained even when the call is no longer
        // on consult because of use cases identified in SERVOPS-11175

        if (consultParticipantId) {
          conversationData.consultParticipantId = consultParticipantId;
        }
      }

      return conversationData;
    }
  });

  _exports.default = _default;
});