define("web-directory/mixins/interaction/tooltips", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Mixin.create({
    acdVoicemailTooltip: computed('acdVoicemailPlayerVisible', function () {
      if (!this.get('acdVoicemailPlayerVisible')) {
        return 'purecloud.acdInteraction.voicemail.showAudioPlayer';
      }

      return 'purecloud.acdInteraction.voicemail.hideAudioPlayer';
    }),
    holdTooltipTitle: computed('interaction.isHeld', function () {
      if (this.get('interaction.isHeld')) {
        return 'purecloud.acdInteraction.unhold';
      }

      return 'purecloud.acdInteraction.hold';
    }),
    muteTooltipTitle: computed('interaction.conversation.isMuted', function () {
      if (this.get('interaction.conversation.isMuted')) {
        return 'purecloud.acdInteraction.unmute';
      }

      return 'purecloud.acdInteraction.mute';
    }),
    scriptToolTip: computed('disabledScriptButton', function () {
      if (this.get('disabledScriptButton')) {
        return 'purecloud.acdInteraction.scriptsNotAvailable';
      }

      return 'purecloud.acdInteraction.scripts';
    }),
    skipACWTooltipTitle: computed('interaction.isCommunicationAfterCallWorkRequested', function () {
      if (this.get('interaction.isCommunicationAfterCallWorkRequested')) {
        return 'purecloud.acdInteraction.skipACW';
      }

      return 'purecloud.acdInteraction.requestACW';
    })
  });

  _exports.default = _default;
});