define("web-directory/templates/components/document-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1cSuomMW",
    "block": "{\"statements\":[[6,[\"unless\"],[[28,[\"hidden\"]]],null,{\"statements\":[[6,[\"if\"],[[28,[\"showSeparatorBefore\"]]],null,{\"statements\":[[1,[28,[\"previous\",\"separator\"]],false]],\"locals\":[]},null],[6,[\"if\"],[[28,[\"template\"]]],null,{\"statements\":[[18,\"default\"]],\"locals\":[]},{\"statements\":[[1,[26,[\"title\"]],false]],\"locals\":[]}],[6,[\"if\"],[[28,[\"showSeparatorAfter\"]]],null,{\"statements\":[[1,[26,[\"separator\"]],false]],\"locals\":[]},null]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/templates/components/document-title.hbs"
    }
  });

  _exports.default = _default;
});