define("web-directory/services/apps-api-handlers/show-interaction-details-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SHOW_INTERACTION_DETAILS_VIEW = void 0;
  var RSVP = Ember.RSVP,
      inject = Ember.inject;
  var SHOW_INTERACTION_DETAILS_VIEW = 'showInteractionDetails';
  _exports.SHOW_INTERACTION_DETAILS_VIEW = SHOW_INTERACTION_DETAILS_VIEW;
  var ShowInteractionDetailsHandler = Ember.Object.extend({
    permissions: inject.service(),
    _window: window,
    // Exposed for test assertions

    /**
     * Synchronous check to see if this handler is applicable for the specified AppApiCall instance
     *
     * @param {Object} the AppApiCall instance to check if this handler can handle
     *
     * @returns true if the provided AppApiCall instance could be handled by this handler. This check should
     *  be limited to checking if the handler is responsible for this type of AppApiCall rather than if the
     *  payload itself is valid.  If no handler returns true, the error will be that no handler exists which is
     *  a different case than the AppApiCall is invalid. Practically, this means that validation of the payload
     *  should be done in the handle method
     */
    canHandle: function canHandle(appApiCall) {
      return appApiCall.get('action') === SHOW_INTERACTION_DETAILS_VIEW;
    },
    handle: function handle(appApiCall) {
      var _this = this;

      return new RSVP.Promise(function (resolve, reject) {
        var apiActionDetails = appApiCall.get('actionDetails');

        var permissionsService = _this.get('permissions');

        if (!permissionsService || !permissionsService.get('canViewInteractionDetails')) {
          reject('Insufficient permissions for interactions view');
          return;
        }

        if (apiActionDetails && Ember.typeOf(apiActionDetails.conversationId) === 'string' && apiActionDetails.conversationId.trim().length > 0) {
          var routePath = "#/engage/admin/interactions/".concat(encodeURIComponent(apiActionDetails.conversationId));

          _this._window.location.assign(routePath);

          resolve();
        } else {
          reject('Missing/Invalid conversationId provided to view interaction');
          return;
        }
      });
    }
  });
  var _default = ShowInteractionDetailsHandler;
  _exports.default = _default;
});