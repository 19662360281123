define("web-directory/components/acd-interactions-panel/interaction-consult-controls-v2/component", ["exports", "web-directory/utils/dates"], function (_exports, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['acd-consult-controls-v2'],
    store: inject.service(),
    communication: inject.service(),
    interaction: null,
    displayError: null,
    // Closure actions
    confineConsultedParty: null,
    completeConsultTransfer: null,
    cancelTransfer: null,
    // Tooltip translations
    confineTitleKey: computed('interaction.consultAgentParticipant.calls.firstObject.{confined,held}', function () {
      var held = this.get('interaction.consultAgentParticipant.calls.firstObject.held');
      var confined = this.get('interaction.consultAgentParticipant.calls.firstObject.confined');
      var key = 'purecloud.acdInteraction.consultTransferV2.destination';

      if (held) {
        key += '.held';
      }

      if (confined) {
        key += '.unconfine';
      } else {
        key += '.confine';
      }

      return key;
    }),
    iAmConsultInitiator: computed.reads('interaction.iAmConsultInitiator'),
    iAmConsulted: computed.reads('interaction.iAmConsulted'),
    iAmConsultObject: computed.reads('interaction.iAmConsultObject'),
    consultAgentParticipant: computed.reads('interaction.consultAgentParticipant'),
    consultInitiator: computed.reads('interaction.consultInitiator'),
    isConsultAgentParticipantHolding: computed.reads('interaction.isConsultAgentParticipantHolding'),
    isConsultParticipantHolding: computed.reads('interaction.isConsultParticipantHolding'),
    isConsultInitiatorHolding: computed.reads('interaction.isConsultInitiatorHolding'),
    consultAgentParticipantName: computed('consultAgentParticipant', function () {
      if (this.get('consultAgentParticipant')) {
        if (this.get('consultAgentParticipant.userId')) {
          return this.store.find('person', this.get('consultAgentParticipant.userId'));
        } else if (this.get('consultAgentParticipant.queueId')) {
          return this.get('store').find('queue', this.get('consultAgentParticipant.queueId'));
        }

        return {
          name: this.get('consultAgentParticipant.name')
        };
      }
    }),
    consultInitiatorName: computed('consultInitiator', function () {
      if (this.get('consultInitiator')) {
        if (this.get('consultInitiator.userId')) {
          return this.store.find('person', this.get('consultInitiator.userId'));
        } else if (this.get('consultInitiator.queueId')) {
          return this.get('store').find('queue', this.get('consultInitiator.queueId'));
        }

        return {
          name: this.get('consultInitiator.name')
        };
      }
    }),
    isSecondParticipantHolding: computed('isConsultAgentParticipantHolding', 'isConsultInitiatorHolding', function () {
      if (this.get('iAmConsultInitiator') || this.get('iAmConsultObject')) {
        return this.get('isConsultAgentParticipantHolding');
      } else if (this.get('iAmConsulted')) {
        return this.get('isConsultInitiatorHolding');
      }
    }),
    secondParticipantHoldTime: computed('isSecondParticipantHolding', function () {
      if (this.get('isSecondParticipantHolding')) {
        return (0, _dates.default)().toISOString();
      }
    }),
    secondParticipant: computed('consultInitiator', 'consultAgentParticipant', function () {
      if (this.get('iAmConsultInitiator') || this.get('iAmConsultObject')) {
        return this.get('consultAgentParticipant');
      } else if (this.get('iAmConsulted')) {
        return this.get('consultInitiator');
      }
    }),
    secondParticipantName: computed('consultAgentParticipantName', 'consultInitiatorName', function () {
      if (this.get('iAmConsultInitiator') || this.get('iAmConsultObject')) {
        return this.get('consultAgentParticipantName');
      } else if (this.get('iAmConsulted')) {
        return this.get('consultInitiatorName');
      }
    }),
    actions: {
      confineConsultedParty: function confineConsultedParty() {
        if (this.confineConsultedParty) {
          var consultAgentParticipant = this.get('consultAgentParticipant');
          var interaction = this.get('interaction');
          this.confineConsultedParty(interaction, consultAgentParticipant);
        }
      },
      completeTransfer: function completeTransfer() {
        if (this.completeConsultTransfer) {
          var consultInitiator = this.get('consultInitiator');
          var interaction = this.get('interaction');
          this.completeConsultTransfer(interaction, consultInitiator);
        }
      },
      cancelTransfer: function cancelTransfer() {
        if (this.cancelConsultTransfer) {
          var consultAgentParticipant = this.get('consultAgentParticipant');
          var interaction = this.get('interaction');
          this.cancelConsultTransfer(interaction, consultAgentParticipant);
        }
      }
    }
  });

  _exports.default = _default;
});