define("web-directory/mixins/purecloud-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PurecloudModelMixin = Ember.Mixin.create({});
  var _default = PurecloudModelMixin;
  _exports.default = _default;
});