define("web-directory/components/acd-interactions-panel/interaction-chat/component", ["exports", "web-directory/elm-modules", "web-directory/utils/dates", "ember-purecloud-components/mixins/logger", "ember-purecloud-components/utils/display-geolocation"], function (_exports, _elmModules, _dates, _logger, _displayGeolocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  function agentImage(person, prefix) {
    var agentImage = person.getFieldValue('agent.profileImage');

    if (agentImage.value && agentImage.value.x150) {
      return agentImage.value.x150;
    }

    var profileImage = person.get('profilePicture');

    if (profileImage && profileImage.x200) {
      return profileImage.x200;
    }

    return "".concat(prefix, "assets/images/svg/person.svg");
  }

  function agentName(person) {
    var agentName = person.getFieldValue('agent.name');

    if (agentName.value) {
      return agentName.value;
    }

    return person.get('name');
  }

  function agentPhoneNumber(person) {
    var primaryNumber = person.getFieldValue('primaryContactInfo.voice', true);

    if (primaryNumber && primaryNumber.rel) {
      return primaryNumber.rel.e164;
    }

    return null;
  }

  var _default = Ember.Component.extend(_logger.default, {
    classNames: ['acd-interaction-chat'],
    intl: inject.service(),
    store: inject.service(),
    session: inject.service(),
    acdchat: inject.service(),
    sidebar: inject.service(),
    application: inject.service(),
    notification: inject.service(),
    Elm: _elmModules.default,
    elmPorts: null,
    interaction: null,
    messages: null,
    events: null,
    appContext: computed('intl.isoLocale', function () {
      return {
        translations: this.get('intl._cache')[this.get('intl.isoLocale')] || null
      };
    }),
    chatConversationContext: computed('session.person.{geolocation,presence}', 'interaction.id', 'acdchat.activeChats.[]', 'acdchat.activeChats.@each.{isConnected,isLoading}', 'interaction.messages.[]', 'interaction.messages.@each.{unread,failed}', 'interaction.participants.@each.{externalContactId,externalContact}', 'acdchat._typingIndicators.@each.timer', function () {
      var _this = this;

      var chatConversations = this.get('acdchat.activeChats') || [];
      var person = this.get('session.person');
      return chatConversations.filter(function (chat) {
        return chat.get('isV2Provider');
      }).map(function (chat) {
        var typingIndicator = _this.get('acdchat').getTypingIndicator(chat.get('id'));

        var cachedMessage = _this.get('acdchat').retrieveCachedMessage(chat.get('id'));

        return {
          id: chat.get('id') || null,
          isConnected: chat.get('isConnected'),
          isDisconnected: chat.get('isDisconnected'),
          isLoading: !!chat.get('isLoading'),
          isTyping: !!typingIndicator,
          cachedMessage: cachedMessage || null,
          messages: Ember.A(chat.get('messages')).uniqBy('id').map(function (msg) {
            var entry = {
              id: msg.id,
              time: msg.timestamp,
              formattedTime: (0, _dates.default)(msg.timestamp).isSame((0, _dates.default)(), 'day') ? (0, _dates.default)(msg.timestamp).format('LT') : (0, _dates.default)(msg.timestamp).format('lll'),
              sender: msg.sender.id,
              bodyType: msg.bodyType,
              data: {
                message: msg.body,
                unread: msg.unread || false,
                sending: msg.sending || false,
                failed: msg.failed || false
              }
            };

            if (entry.bodyType === 'media-request' && typeof entry.data.message === 'string') {
              entry.data.message = JSON.parse(entry.data.message);
              entry.data.message.type_ = entry.data.message.type;
            }

            return entry;
          }),
          agent: {
            id: _this.get('session.person.guid'),
            name: person.get('name'),
            image: agentImage(person, _this.get('application.urlprefix'))
          },
          participants: chat.get('participants').map(function (participant) {
            // we actually need to map sender to the
            // communication id
            var id = participant.chats[0].id;
            var externalContactId = Ember.get(participant, 'externalContactId') || Ember.get(participant, 'externalContact.id');
            return {
              id: id,
              name: participant.name,
              userId: participant.userId,
              purpose: participant.purpose,
              avatarImageUrl: Ember.get(participant, 'chats.0.avatarImageUrl'),
              profile: null,
              externalContactId: externalContactId
            };
          }),
          selected: chat.get('id') === _this.get('interaction.id') ? true : false
        };
      });
    }),
    flags: computed('appContext', 'chatConversationContext', function () {
      return {
        chatConversationAppContext: this.get('appContext'),
        chatConversationContext: this.get('chatConversationContext')
      };
    }),
    sendElmData: function sendElmData(portName, data) {
      var sendPort = this.get("elmPorts.".concat(portName));

      if (sendPort) {
        this.logger.debug(["Sending on ".concat(portName, " to Elm module"), data]);
        sendPort.send(data);
      } else {
        this.logger.debug(["".concat(portName, " not ready to send"), data]);
      }
    },
    sendAppContext: function sendAppContext(data) {
      this.sendElmData('chatConversationAppContext', data);
    },
    sendConversationContext: function sendConversationContext(data) {
      this.sendElmData('chatConversationContext', data);
    },
    getResponseTextareaSelectionState: function getResponseTextareaSelectionState() {
      var textarea = this.element.querySelector('textarea');

      if (!textarea) {
        return {
          selectionStart: 0,
          selectionEnd: 0
        };
      }

      var selectionStart = textarea.selectionStart,
          selectionEnd = textarea.selectionEnd;
      return {
        selectionStart: selectionStart,
        selectionEnd: selectionEnd
      };
    },
    sendCannedResponse: function sendCannedResponse(text) {
      var _this$getResponseText = this.getResponseTextareaSelectionState(),
          selectionStart = _this$getResponseText.selectionStart,
          selectionEnd = _this$getResponseText.selectionEnd;

      this.sendElmData('insertResponseTextData', {
        text: text,
        selectionStart: selectionStart,
        selectionEnd: selectionEnd
      });
      this.set('cannedResponseChatText', null);
    },
    contextUpdater: Ember.observer('appContext', function () {
      Ember.run.once(this, 'sendAppContext', this.get('appContext'));
    }),
    chatConversationUpdater: Ember.observer('chatConversationContext', function () {
      Ember.run.once(this, '_updateChatConversationContext');
    }),
    _updateChatConversationContext: function _updateChatConversationContext() {
      var _this2 = this;

      var conversationContext = this.get('chatConversationContext');
      var promises = [];
      conversationContext.forEach(function (conversation) {
        conversation.participants.forEach(function (participant) {
          if (participant.userId) {
            var record = _this2.get('store').recordForId('person', participant.userId);

            var promise = Ember.RSVP.resolve(record);

            if (!record) {
              promise = _this2.get('store').findRecord('person', participant.userId);
            }

            var profilePromise = promise.then(function (person) {
              participant.name = participant.name || agentName(person) || participant.userId;
              participant.profile = {
                id: person.get('guid'),
                title: person.get('title') || '',
                department: person.get('department') || '',
                email: person.getFieldValue('primaryContactInfo.email').value,
                phone: agentPhoneNumber(person),
                location: (0, _displayGeolocation.default)(person, _this2.get('session.person')),
                presence: person.get('presence'),
                image: agentImage(person, _this2.get('application.urlprefix'))
              };
            });
            promises.push(profilePromise);
          } else if (participant.externalContactId) {
            var _record = _this2.get('store').recordForId('externalcontacts/contact', participant.externalContactId);

            var _promise = Ember.RSVP.resolve(_record);

            if (!_record) {
              _promise = _this2.get('store').findRecord('externalcontacts/contact', participant.externalContactId);
            }

            var contactPromise = _promise.then(function (contact) {
              participant.name = contact.get('name').toString();
            });

            promises.push(contactPromise);
          }
        });
      });
      Ember.RSVP.allSettled(promises).then(function () {
        Ember.run.once(_this2, 'sendConversationContext', conversationContext);
      });
    },
    actions: {
      setupPorts: function setupPorts(ports) {
        var _this3 = this;

        ports.chatConversationActions.subscribe(function (message) {
          try {
            _this3.logger.debug('Elm sent:', message);

            if (message.action) {
              _this3.send(message.action, new Ember.Object(message.payload));
            }
          } catch (e) {
            // We need to catch even if the notification throws an error, to prevent the
            // Elm runtime from halting on the exception.
            try {
              var title = _this3.get('intl').t('chat.presence.actionError.title');

              var _message = _this3.get('intl').t('chat.presence.actionError.message');

              _this3.get('notification').error(title, _message);
            } catch (e2) {
              setTimeout(function () {
                throw e2;
              }, 0);
            }
          }
        });
        this.set('elmPorts', ports);
      },
      notifyError: function notifyError(errorInfo) {
        var nowTime = Date.now();
        var title = this.get('intl').t(errorInfo.title);
        var userMessage = this.get('intl').t(errorInfo.userMessage);
        var compositeMsg = "".concat(title, " ").concat(userMessage);

        if (this.get('lastError') != compositeMsg || nowTime - this.get('lastErrorTime') > 5000) {
          this.set('lastError', compositeMsg);
          this.set('lastErrorTime', nowTime);
          this.get('notification').error(title, "".concat(userMessage));
        }

        this.logger.error('ACD: error from Elm chat', {
          errorInfo: errorInfo
        });
      },
      sendTyping: function sendTyping(data) {
        if (data && data.chatId) {
          var acdChat = this.get('acdchat');
          Ember.run.throttle(acdChat, acdChat.sendTypingIndicator, data.chatId, 750);

          if (data.message) {
            this.get('acdchat').storeCachedMessage(data.chatId, data.message);
          }
        }
      },
      sendResponse: function sendResponse(messageInfo) {
        if (messageInfo && messageInfo.response) {
          this.get('acdchat').sendMessage(this.get('interaction'), messageInfo.response);
          this.get('acdchat').deleteCachedMessage(this.get('interaction.id'));
        }
      },
      focusResponseTextareaWithSelection: function focusResponseTextareaWithSelection(data) {
        if (!data) return;
        var selectionStart = data.selectionStart,
            selectionEnd = data.selectionEnd;
        if (selectionStart === undefined || selectionEnd === undefined) return;
        var textarea = this.element.querySelector('textarea');
        if (!textarea) return; // Wrap to ensure that Elm has finished updating the textarea DOM element with the
        // new value before we update cursor position

        requestAnimationFrame(function () {
          textarea.focus();
          textarea.setSelectionRange(selectionStart, selectionEnd);
        });
      },
      clearUnreadMessages: function clearUnreadMessages(data) {
        if (data && data.chatId) {
          this.get('acdchat').clearUnreadMessages(data.chatId);
        }
      },
      hoverCardContact: function hoverCardContact(data) {
        var _this4 = this;

        var userId = data.userId;
        var method = data.method;

        if (!userId && !method) {
          return;
        }

        this.get('store').find('person', userId).then(function (person) {
          if (method === 'chat') {
            _this4.bubbleAction({
              action: 'openChatRoom',
              actionContext: [person.get('jid')]
            });
          } else if (method === 'phone') {
            _this4.get('phoneService').makeCall(person);
          } else if (method === 'webrtc') {
            _this4.bubbleAction({
              action: 'openChatRoomWithVideo',
              actionContext: [person.get('jid')]
            });
          } else if (method === 'profile') {
            _this4.get('sidebar').closePanel();
          } else {
            _this4.logger.warn('Attempted to contact via hover card using method that does not exist', {
              remoteData: {
                userId: userId
              }
            });
          }
        });
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.set('messages', []);
      this.set('events', []);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var cannedResponse = this.get('cannedResponseChatText');

      if (cannedResponse) {
        Ember.run.once(this, 'sendCannedResponse', cannedResponse);
      }
    }
  });

  _exports.default = _default;
});