define("web-directory/components/floorplan-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var on = Ember.on,
      inject = Ember.inject,
      computed = Ember.computed,
      observer = Ember.observer,
      Component = Ember.Component;

  var _default = Component.extend({
    classNames: ['floorplan-editor'],
    store: inject.service('store'),
    session: inject.service(),
    entity: null,
    fieldData: null,
    field: null,
    location: null,
    parentLocation: null,
    editing: true,
    saveLocation: function saveLocation() {},
    coordinates: {},
    coordinatesRatio: {},
    type: 'edit',
    selectFloorplan: true,
    entry: computed.alias('fieldData.value'),
    actions: {
      setCurrentFloor: function setCurrentFloor(floor) {
        var entry = this.get('entry');
        var data = {
          coordinates: {},
          locationId: floor.get('id'),
          notes: ''
        };
        var floorplans = floor.get('floorplanImages');
        floorplans = floor.get('floorplanImage');

        if (floorplans) {
          data.coordinates = {
            x: 0,
            y: 0
          };
        }

        Ember.set(entry, 'value', data);
        var currentLocation = this.get('location');
        var floors = this.get('floors');
        this.set('location', floor);
        this.send('showFloorplan');

        if (currentLocation.get('isFloor')) {
          floors.addObject(currentLocation);
        }

        floors.removeObject(floor);
        floors = floors.sortBy('name');
        this.set('floors', floors);
      },
      showFloorplan: function showFloorplan() {
        if (this.get('floorplanImageUrl')) {
          this.set('selectFloorplan', true);
        }
      },
      saveFloorplan: function saveFloorplan() {},
      closeFloorplan: function closeFloorplan() {
        this.set('selectFloorplan', false);
      }
    },
    loadInitialPinLocation: on('didRender', function () {
      Ember.run.scheduleOnce('afterRender', this, function () {
        var _this = this;

        var coordinates = this.get('entry.value.coordinates');
        var img = this.$('.floorplan-image img');

        if (!img) {
          return;
        }

        img.one('load', function () {
          var coords = _this.getRatioCoordinates(coordinates, img[0], function (a, b) {
            return a * b;
          });

          _this.attachPinForXY(coords.x, coords.y);
        });
      });
    }),
    floorplanImageUrl: computed('location.floorplanImage', function () {
      var images = this.get('location.floorplanImage');

      if (images && images.length > 0) {
        var image = images.find(function (image) {
          return image.resolution === 'x800';
        });

        if (image) {
          return image.imageUri;
        }
      }

      return undefined;
    }),
    showFloorplan: computed('floorplanImageUrl', 'selectFloorplan', function () {
      return this.get('floorplanImageUrl') && this.get('selectFloorplan');
    }),
    floors: computed('location.floors.[]', 'parentLocation.floors.[]', 'location.{id,isFloor}', function () {
      var _this2 = this;

      var locationFloors = this.get('location.floors');
      var parentFloors = this.get('parentLocation.floors');
      var floors = [];

      if (locationFloors && locationFloors.length > 0) {
        floors = locationFloors;
      }

      if (parentFloors && parentFloors.length > 0) {
        floors = parentFloors;
      } // Remove yourself


      if (this.get('location.isFloor') && floors.length > 0) {
        floors = floors.filter(function (floor) {
          return floor.get('id') !== _this2.get('location.id');
        });
      }

      return floors;
    }),
    loadFloors: function loadFloors() {
      return;
    },
    updateCoordinatesRatio: observer('coordinates', function () {
      var coordinates = this.get('coordinates');

      if (!!coordinates) {
        var img = this.$('.floorplan-image img')[0];
        var coords = this.getRatioCoordinates(coordinates, img, function (a, b) {
          return a / b;
        });

        if (img) {
          this.set('coordinatesRatio', {
            x: coords.x,
            y: coords.y
          });
        }
      } else {
        this.set('coordinatesRatio', {});
      }
    }),
    updateCoordinatesOnLocation: observer('coordinatesRatio', function () {
      var ratio = this.get('coordinatesRatio');
      var entry = this.get('entry');
      var value = entry.value;
      value.coordinates = ratio;
      Ember.set(entry, 'value', value);
    }),
    click: function click(event) {
      var target = Ember.$(event.target);

      if (target.parent().is('.floorplan-image') && target.is('img') && this.get('editing')) {
        var x = event.offsetX || event.clientX - $(event.target).offset().left + window.pageXOffset;
        var y = event.offsetY || event.clientY - $(event.target).offset().top + window.pageYOffset;
        this.attachPinForXY(x, y);
        this.set('coordinates', {
          x: x,
          y: y
        });
      }
    },
    getRatioCoordinates: function getRatioCoordinates(coordinates, image, operation) {
      if (!image) {
        return {
          x: 0,
          y: 0
        };
      }

      var naturalHeight = image.naturalHeight;
      var naturalWidth = image.naturalWidth;
      var heightDiff = naturalHeight - image.height;
      var widthDiff = naturalWidth - image.width;
      var x = operation(coordinates.x, naturalWidth - widthDiff);
      var y = operation(coordinates.y, naturalHeight - heightDiff);
      return {
        x: x,
        y: y
      };
    },
    attachPinForXY: function attachPinForXY(x, y) {
      var icon = $('<i class="pin pc pc-location"></i><i class="pin shadow pc pc-location"></i>').css({
        position: 'absolute',
        left: x - 12,
        top: y - 12
      });
      var currentPin = this.$('i.pin');

      if (currentPin) {
        this.$('i.pc-location').remove();
      }

      var image = this.$('.floorplan-image');

      if (image) {
        image.append(icon);
      }
    },
    floorSorter: function floorSorter(a, b) {
      var init = [a.get('name'), b.get('name')];
      var sorted = init.sort();

      if (sorted[0] === a.get('name')) {
        return 1;
      } else {
        return -1;
      }
    }
  });

  _exports.default = _default;
});