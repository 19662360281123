define("web-directory/components/time-elapsed/component", ["exports", "web-directory/utils/dates", "web-directory/components/time-elapsed/template"], function (_exports, _dates, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MINUTE = 1000 * 60;
  var HOUR = MINUTE * 60;
  var DAY = HOUR * 24;

  var _default = Ember.Component.extend({
    classNames: ['time-elapsed'],
    tagName: 'span',
    layout: _template.default,
    clock: Ember.inject.service(),
    intl: Ember.inject.service(),
    timeSince: null,
    _calculateElapsedTime: function _calculateElapsedTime(durationInMs) {
      durationInMs = durationInMs > 0 ? durationInMs : 0; // Do not show negative durations.

      var duration = _dates.default.duration(durationInMs);

      var days = duration.asDays();
      var hours = duration.hours();
      var minutes = duration.minutes();

      if (days) {
        if (hours) {
          return this.get('intl').t('components.timeElapsed.detail.daysHours', {
            days: days,
            hours: hours
          });
        }

        return this.get('intl').t('components.timeElapsed.detail.days', {
          days: days
        });
      } else if (hours) {
        if (minutes) {
          return this.get('intl').t('components.timeElapsed.detail.hoursMinutes', {
            hours: hours,
            minutes: minutes
          });
        }

        return this.get('intl').t('components.timeElapsed.detail.hours', {
          hours: hours
        });
      }

      return this.get('intl').t('components.timeElapsed.detail.minutes', {
        minutes: minutes || '0'
      });
    },
    _formatDuration: function _formatDuration() {
      var timeSince = this.get('timeSince');
      var msSince = (0, _dates.default)().diff((0, _dates.default)(timeSince));
      var unit, cnt;

      if (msSince > DAY) {
        unit = 'days';
      } else if (msSince > HOUR) {
        unit = 'hours';
      } else if (msSince > MINUTE) {
        unit = 'minutes';
      } else {
        return this.get('intl').t('components.timeElapsed.now');
      }

      cnt = (0, _dates.default)().diff((0, _dates.default)(timeSince), unit);

      if (cnt < 2) {
        return this.get('intl').t("components.timeElapsed.singular.".concat(unit));
      }

      return this.get('intl').t("components.timeElapsed.plural.".concat(unit), {
        count: cnt
      });
    },
    tickingDuration: Ember.computed('timeSince', 'clock.second', function () {
      return this._formatDuration();
    })
  });

  _exports.default = _default;
});