define("web-directory/models/location", ["exports", "ember-data", "lodash"], function (_exports, _emberData, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.BASE_LOCATION_FIELDS = _exports.BASE_LOCATION = void 0;
  var BASE_LOCATION_FIELDS = ['name', 'address', 'images', 'contactPersonId', 'sublocationOrder'];
  _exports.BASE_LOCATION_FIELDS = BASE_LOCATION_FIELDS;
  var BASE_LOCATION = {
    fl: BASE_LOCATION_FIELDS.join(',')
  };
  _exports.BASE_LOCATION = BASE_LOCATION;

  var LocationModel = _emberData.default.Model.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    _id: _emberData.default.attr(),
    name: _emberData.default.attr(),
    address: _emberData.default.attr(),
    imageHistory: _emberData.default.attr(),
    contactPersonId: _emberData.default.attr(),
    notes: _emberData.default.attr(),
    sublocationOrder: _emberData.default.attr(),
    images: _emberData.default.attr(),
    profileImage: _emberData.default.attr(),
    floorplanImage: _emberData.default.attr(),
    floorplanImageHistory: _emberData.default.attr(),
    path: _emberData.default.attr(),
    orgId: _emberData.default.attr(),
    version: _emberData.default.attr(),
    _entity: _emberData.default.attr(),
    floors: _emberData.default.attr(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    profilePicture: Ember.computed('profileImage', 'parent.{images,isFulfilled}', 'images', function () {
      var profileImages = this.get('profileImage');

      if (!_lodash.default.isEmpty(profileImages)) {
        var imagex48 = profileImages.find(function (image) {
          return image.resolution === 'x48';
        });
        var imagex96 = profileImages.find(function (image) {
          return image.resolution === 'x96';
        });
        var imagex128 = profileImages.find(function (image) {
          return image.resolution === 'x128';
        });
        var imagex200 = profileImages.find(function (image) {
          return image.resolution === 'x200';
        });

        if (imagex48 && imagex96 && imagex128 && imagex200) {
          return {
            x48: "".concat(imagex48.imageUri),
            x96: "".concat(imagex96.imageUri),
            x128: "".concat(imagex128.imageUri),
            x200: "".concat(imagex200.imageUri)
          };
        }
      } else if (this.get('images')) {
        var images = this.get('images');
        return images;
      }

      return undefined;
    }),
    floorCount: Ember.computed('sublocationOrder', function () {
      var subs = this.get('sublocationOrder');

      if (_lodash.default.isEmpty(subs)) {
        return 0;
      }

      return subs.length;
    }),
    type: Ember.computed('path', function () {
      var path = this.get('path');

      if (path && Array.isArray(path) && path.length > 0) {
        return 'floor';
      }

      return 'location';
    }),
    isFloor: Ember.computed('type', function () {
      return this.get('type') === 'floor';
    }),
    deleted: Ember.computed('state', function () {
      return this.get('state') === 'deleted';
    }),
    hasContactPerson: Ember.computed('contactPersonId', function () {
      return this.get('contactPersonId') !== null && this.get('contactPersonId') !== undefined;
    }),
    contactPerson: Ember.computed('contactPersonId', function () {
      var id = this.get('contactPersonId');

      if (id) {
        return this.store.find('person', id);
      }
    }),
    floorplan: Ember.computed('floorplanImage', function () {
      var images = this.get('floorplanImage');

      if (!_lodash.default.isEmpty(images)) {
        var image = images.find(function (image) {
          return image.resolution === 'x1024';
        });

        if (image) {
          return image.imageUri;
        }
      }

      return undefined;
    }),
    _parent: null,
    parent: Ember.computed('type', 'path', function () {
      if (this.get('type') === 'floor') {
        var promise;

        if (this.get('_parent')) {
          promise = this.get('_parent');
        } else {
          promise = this.get('store').findRecord('location', this.get('path')[0]);
          this.set('_parent', promise);
        }

        return _emberData.default.PromiseObject.create({
          promise: promise
        });
      }

      return _emberData.default.PromiseObject.create({
        promise: Ember.RSVP.resolve(undefined)
      });
    }),
    containsRichAddress: Ember.computed('address', function () {
      var address = this.get('address');
      return typeof address !== 'string';
    }),
    setFloor: function setFloor() {
      return;
    }
  });

  var _default = LocationModel;
  _exports.default = _default;
});