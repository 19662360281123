define("web-directory/components/emoji-picker-base/emoji-picker-unicode/component", ["exports", "web-directory/components/emoji-picker-base/component", "web-directory/utils/emoji-json-helper", "web-directory/utils/emoji-json-helper-expanded"], function (_exports, _component, _emojiJsonHelper, _emojiJsonHelperExpanded) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['emoji-picker-unicode'],
    session: Ember.inject.service(),
    showEmojiPicker: false,
    emojiCategory: 'smiles',
    emojiSkinTone: 'defaultSkinTone',
    showSkinTonePicker: false,
    isUnicode: true,
    useExpandedEmojiList: true,
    emoji: null,
    interaction: null,
    actions: {
      showTab: function showTab(tab) {
        this.set('showSkinTonePicker', false);
        this.set('emojiCategory', tab);
        this.$('.tab-content.show-flex').scrollTop(0);
      },
      showSkinTone: function showSkinTone(skinTone) {
        this.set('emojiSkinTone', skinTone);
        this.$('.people-emoji-tab').trigger('focus');
        this.set('showSkinTonePicker', false);
      },
      toggleSkinTonePicker: function toggleSkinTonePicker() {
        this.set('showSkinTonePicker', !this.get('showSkinTonePicker'));
      },
      chooseLineSticker: function chooseLineSticker(selectedSticker) {
        this.triggerAction({
          action: 'chooseLineSticker',
          actionContext: [selectedSticker]
        });
      },
      toggleEmojiDropdown: function toggleEmojiDropdown(status) {
        this.set('showEmojiPicker', !status);
        this.set('showSkinTonePicker', false);
        this.set('emojiCategory', 'smiles');
        this.$('.tab-content.show-flex').scrollTop(0);
      }
    },
    emojiPickerFeatureToggleEnabled: Ember.computed('session.features', function () {
      return this.get('session.features') && this.get('session.features')['epc.decomp.drop-down.emoji-picker'];
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('useExpandedEmojiList')) {
        this.set('emoji', _emojiJsonHelperExpanded.default);
      } else {
        this.set('emoji', _emojiJsonHelper.default);
      }
    }
  });

  _exports.default = _default;
});