define("web-directory/components/entity-field-section/entity-relationships/relationship-search/component", ["exports", "web-directory/components/entity-search/component", "web-directory/components/entity-search/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var on = Ember.on,
      run = Ember.run,
      inject = Ember.inject,
      RSVP = Ember.RSVP;

  var _default = _component.default.extend({
    classNames: ['relationship-search'],
    layout: _template.default,
    intl: inject.service(),
    session: inject.service(),
    permissions: inject.service(),
    entity: null,
    relationship: null,
    searchLimit: 6,
    actions: {
      selectResult: function selectResult(result) {
        var isOtherRelationship = this.get('relationship.isOtherRelationship') || this.get('relationship.isAssistantsRelationship');
        var entity = this.get('entity');
        var canEdit = this.get('permissions.canEditProfiles');

        if (isOtherRelationship) {
          canEdit = entity.get('id') === this.get('session.person.id') || canEdit;
        }

        if (canEdit) {
          this.set('searchQuery', '');
          this.set('userInput', '');
          this.sendAction('select', result);
        }
      }
    },
    resultsLoaded: on('didRender', function () {
      var _this = this;

      if (!this.get('currentSearchRequest.isFulfilled')) {
        return false;
      }

      this.get('currentSearchRequest').then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 1),
            people = _ref2[0];

        // Lets be safe...
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        _this.$('.disabled').tooltip('destroy');

        return RSVP.all(people.map(function (person) {
          return person.get('readWritePermissions');
        }));
      }).then(function () {
        run.scheduleOnce('afterRender', _this, function () {
          // ...doubly safe
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          if (this.get('relationship.isManagerRelationship')) {
            this.$('.disabled').tooltip({
              title: this.get('intl').lookup('person.relationships.cantModify').toString(),
              placement: 'top',
              trigger: 'hover',
              container: "#".concat(this.elementId, " .search-results")
            });
          }
        });
      });
    })
  });

  _exports.default = _default;
});