define("web-directory/services/apps-api-handlers/lifecycle-handler", ["exports", "web-directory/models/app-instance"], function (_exports, _appInstance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.APP_STOPPED_API_ACTION_NAME = _exports.APP_BOOTSTRAPPED_API_ACTION_NAME = void 0;
  var RSVP = Ember.RSVP;
  var APP_STOPPED_API_ACTION_NAME = _appInstance.APP_LIFECYCLE_CALLBACKS.STOPPED;
  _exports.APP_STOPPED_API_ACTION_NAME = APP_STOPPED_API_ACTION_NAME;
  var APP_BOOTSTRAPPED_API_ACTION_NAME = _appInstance.APP_LIFECYCLE_CALLBACKS.BOOTSTRAPPED;
  _exports.APP_BOOTSTRAPPED_API_ACTION_NAME = APP_BOOTSTRAPPED_API_ACTION_NAME;
  var LifecycleHandler = Ember.Object.extend({
    /**
     * Synchronous check to see if this handler is applicable for the specified AppApiCall instance
     *
     * @param {Object} the AppApiCall instance to check if this handler can handle
     *
     * @returns true if the provided AppApiCall instance could be handled by this handler. This check should
     *  be limited to checking if the handler is responsible for this type of AppApiCall rather than if the
     *  payload itself is valid.  If no handler returns true, the error will be that no handler exists which is
     *  a different case than the AppApiCall is invalie. Practically, this means that validation of the payload
     *  should be done in the handle method
     */
    canHandle: function canHandle(appApiCall) {
      return appApiCall.get('action') === APP_STOPPED_API_ACTION_NAME || appApiCall.get('action') === APP_BOOTSTRAPPED_API_ACTION_NAME;
    },
    handle: function handle(appApiCall) {
      return new RSVP.Promise(function (resolve, reject) {
        var appInstance = appApiCall.get('srcAppInstance');

        if (!appInstance) {
          reject('No AppInstance provided');
          return;
        }

        if (!(appInstance instanceof _appInstance.default)) {
          reject('Invalid AppInstance provided');
          return;
        }

        var action = appApiCall.get('action');

        if (action === APP_STOPPED_API_ACTION_NAME) {
          appInstance.stopped();
        } else if (action === APP_BOOTSTRAPPED_API_ACTION_NAME) {
          appInstance.bootstrapped();
        }

        return resolve();
      });
    }
  });
  var _default = LifecycleHandler;
  _exports.default = _default;
});