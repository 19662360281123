define("web-directory/utils/uuid", ["exports", "uuid"], function (_exports, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function generate() {
    return _uuid.default.v4();
  }

  var _default = {
    generate: generate
  };
  _exports.default = _default;
});