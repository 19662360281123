define("web-directory/components/embedded-admin/helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getIfNavLinkMatches = getIfNavLinkMatches;

  /**
   * Returns the navLink object if it matches part of the location string.  Otherwise will return null.
   * Some match logic overrides or mutations may be are performed in the process.
   *
   * @param {string} location
   * @param {NavLink} navLink
   * @returns {?NavLink} matching navLink or null
   */
  function getIfNavLinkMatches(location, navLink) {
    var output = null; // special case added for responseManagement since there are subroutes in responseManagement that are not reflected in the
    // navigation submenu list

    var navPath = navLink.href.split('#/admin/').pop();

    if (location.startsWith(navPath) || isResponseManagementSublink(location, navLink)) {
      output = Object.assign({}, navLink);

      if (isQueueDetails(location)) {
        // Special case for queue's detail.  Queue list was moved to admin-v3 but the queue details page remains in v1.
        delete output.basePath;
      }
    }

    return output;
  }
  /**
   * @param {string} location
   * @param {NavLink} item
   * @returns {boolean}
   */


  function isResponseManagementSublink(location, item) {
    var RESPONSE_MANAGEMENT = 'responseManagement';
    return location.indexOf(RESPONSE_MANAGEMENT) > -1 && item.href.indexOf(RESPONSE_MANAGEMENT) > -1;
  }
  /**
   * @param {string} location
   * @returns {boolean}
   */


  function isQueueDetails(location) {
    var QUEUE_DETAILS = /_queuesV2\/\w+/i;
    return location.match(QUEUE_DETAILS);
  }
  /**
   * @typedef {object} NavLink
   * @property {string} href
   * @property {?string} basePath
   * @property {?string} subRoute
   */

});