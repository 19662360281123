define("web-directory/components/acd-interactions-panel/interaction-responses/search-results/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['responses-search-results', 'library-detail'],
    classNameBindings: ['enabled'],
    enabled: true,
    results: [],
    searching: false,
    headerText: '',
    allowInsert: false,
    responses: Ember.inject.service(),
    interaction: Ember.inject.service(),
    interactionSelected: Ember.observer('interaction', function () {
      var responses = this.get('responses'),
          results = this.get('results');

      if (results) {
        results.forEach(function (response) {
          var replacementObj = responses.replaceSubstitutionTokens(response);
          response.set('replaceText', replacementObj.text);
          response.set('message', replacementObj.message);
        });
      }
    }),
    actions: {
      sendResponse: function sendResponse(response) {
        this.sendAction('sendResponse', response);
      },
      expandResponse: function expandResponse(response) {
        this.sendAction('expandResponse', response);
      },
      toggleOpen: function toggleOpen(response) {
        response.toggleProperty('isOpen');
      }
    }
  });

  _exports.default = _default;
});