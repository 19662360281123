define("web-directory/components/skillgroup-profile/dynamic-rules/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      Component = Ember.Component,
      inject = Ember.inject;

  var _default = Component.extend({
    classNames: ['group-acd-expressions'],
    application: inject.service(),
    ajax: inject.service(),
    intl: inject.service(),
    logger: inject.service('stash-logger'),
    notification: inject.service(),
    group: null,
    showSaveModal: false,
    showCancelModal: false,
    loading: false,
    previewValue: null,
    previewUrl: null,
    expressionDisplay: computed('group.expression.@each.{display,operation}', function () {
      var _this = this;

      var conditions = this.get('group.expression');
      var display = '';
      var valid = true;

      if (!conditions && conditions.length === 0) {
        return this.get('intl').t('groups.expressions.empty');
      }

      conditions.forEach(function (con, index) {
        if (con.display === null || con.display === undefined) {
          valid = false;
          return;
        }

        if (index !== 0) {
          display += " ".concat(_this.get('intl').t("groups.expressions.".concat(con.operation.toLowerCase())), " ");
        }

        display += con.display;
      });
      var empty = display.replace(/[()]/g, '').trim().length === 0;

      if (empty) {
        display = this.get('intl').t('groups.expressions.empty');
      } else if (!valid) {
        display = this.get('intl').t('groups.expressions.invalid');
      }

      return display;
    }),
    saveDisabled: computed('group.expression.@each.display', 'loading', function () {
      var invalid = this.get('group.expression').filter(function (condition) {
        return !condition.display;
      }).length > 0;
      var empty = this.get('group.expression').length == 0;
      var loading = this.get('loading');
      return invalid || empty || loading;
    }),
    maximumExpressions: computed('group.expression.@each', function () {
      return this.get('group.expression.length') >= 10;
    }),
    numberPreview: computed('previewValue', function () {
      return isNaN(this.get('previewValue')) ? this.get('intl').t('purecloud.unknown') : this.get('previewValue');
    }),
    actions: {
      selectTab: function selectTab(tab) {
        this.selectTab(tab);
      },
      addCondition: function addCondition() {
        if (this.get('group.expression') && this.get('group.expression').length > 0) {
          var exp = this.get('group.expression');
          exp.forEach(function (condition) {
            Ember.set(condition, 'lastCondition', false);

            if (condition.operation === null) {
              Ember.set(condition, 'operation', 'And');
            }
          });
          exp.pushObject({
            lastCondition: true,
            operation: 'And',
            display: null,
            rules: [{
              skill: null,
              comparator: null,
              proficiency: null,
              operation: 'And',
              lastRule: true
            }]
          });
          Ember.set(exp, 'lastObject.lastCondition', true);
        } else {
          this.set('group.expression', [{
            lastCondition: true,
            operation: 'And',
            display: null,
            rules: [{
              skill: null,
              comparator: null,
              proficiency: null,
              operation: 'And',
              lastRule: true
            }]
          }]);
        }
      },
      removeCondition: function removeCondition(index) {
        var exp = this.get('group.expression');

        if (exp.length === 1) {
          this.set('group.expression', []);
        } else {
          Ember.A(exp).removeAt(index);
          exp.forEach(function (condition) {
            Ember.set(condition, 'lastCondition', false);
          });
          exp.set('lastObject.lastCondition', true);
          this.set('group.expression', exp);
        }
      },
      conditionOperation: function conditionOperation(condition, op) {
        Ember.set(condition, 'operation', op);
      },
      save: function save() {
        var _this2 = this;

        this.set('loading', true);
        var skillConditions = this.prepareExpression(this.get('group.expression'));
        var group = this.get('group');
        group.set('skillConditions', skillConditions);
        group.save().then(function () {
          _this2.get('notification').info(_this2.get('intl').t('groups.expressions.saveTitle'), _this2.get('intl').t('groups.expressions.saveMessage'));

          _this2.reset();
        }).catch(function (error) {
          var title = _this2.get('intl').t('groups.expressions.saveErrorTitle');

          var message = _this2.get('intl').t('groups.expressions.saveErrorMessage');

          if (error.status === 403 && (error.payload.code === 'missing.permissions' || error.payload.code === 'forbidden')) {
            message = _this2.get('intl').t('groups.expressions.editPermissionsErrorMessage');
          }

          _this2.get('notification').error(title, message);

          _this2.get('logger').error('Error trying to save dynamic expression to group', {
            error: error
          });

          group.reload().then(function () {
            group.rollbackAttributes();
          });

          _this2.reset();
        });
      },
      openCancelModal: function openCancelModal() {
        this.set('showCancelModal', true);
      },
      closeCancelModal: function closeCancelModal() {
        this.set('showCancelModal', false);
      },
      cancel: function cancel() {
        this.set('showCancelModal', false);
        var group = this.get('group');
        group.reload().then(function () {
          group.rollbackAttributes();
        });
        this.selectTab('members');
      },
      closeSaveModal: function closeSaveModal() {
        this.reset();
      }
    },
    reset: function reset() {
      this.set('previewValue', null);
      this.set('previewUrl', null);
      this.set('showSaveModal', false);
      this.get('group').reload();
      this.selectTab('members');
    },
    prepareExpression: function prepareExpression(input) {
      var output = [];
      input.forEach(function (con) {
        var parent = null;
        var save = {
          routingSkillConditions: [],
          languageSkillConditions: []
        };
        con.rules.forEach(function (rule, index) {
          var skillPropName = rule.skill.type == 'routing' ? 'routingSkillConditions' : 'languageSkillConditions';
          var ruleType = rule.skill.type == 'routing' ? 'routingSkill' : 'languageSkill';

          if (index === 0) {
            var newObj = {
              comparator: rule.comparator.value,
              proficiency: rule.proficiency.value,
              childConditions: []
            };
            newObj[ruleType] = rule.skill.name;
            parent = newObj;
            save[skillPropName].pushObject(newObj);
            save.operation = con.operation;
          } else {
            // we're adding this skill as a childCondition
            var newCondition = {
              operation: rule.operation,
              routingSkillConditions: [],
              languageSkillConditions: []
            };
            var _newObj = {
              comparator: rule.comparator.value,
              proficiency: rule.proficiency.value
            };
            _newObj[ruleType] = rule.skill.name;
            newCondition[skillPropName].pushObject(_newObj);
            parent.childConditions.pushObject(newCondition);
          }
        });
        output.pushObject(save);
      });
      return output;
    }
  });

  _exports.default = _default;
});