define("web-directory/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var isNonEmptyStringQueryParam = function isNonEmptyStringQueryParam(paramStr) {
    return typeof paramStr === 'string' && !Ember.isBlank(paramStr) && // Handles 'https://wikipedia.org/?param' case.
    // Ember parses as the literal string 'true'
    paramStr !== 'true';
  };

  var _default = Ember.Controller.extend({
    chat: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    settings: Ember.inject.service(),
    sidebar: Ember.inject.service(),
    application: Ember.inject.service(),
    permissions: Ember.inject.service(),
    phoneService: Ember.inject.service('phone'),
    sidebarService: Ember.computed.reads('sidebar'),
    appsService: Ember.inject.service('apps'),
    webrtc: Ember.inject.service('webrtc'),
    queryParams: ['mobile', 'panels', 'initialPanel'],
    panels: null,
    initialPanel: null,
    updateCurrentRouteName: Ember.observer('currentRouteName', function () {
      Ember.run.once(this, this.set, 'application.currentRouteName', this.get('currentRouteName'));
    }),
    panelsOverrideObject: Ember.computed('panels', 'initialPanel', function () {
      var panelsOverrideObject = {};
      var panelsOverride = this.get('panels');
      var initialPanelOverride = this.get('initialPanel');

      if (isNonEmptyStringQueryParam(panelsOverride)) {
        panelsOverrideObject.panelsOverride = panelsOverride.split(',');
      } else if (panelsOverride === '') {
        panelsOverrideObject.panelsOverride = [];
      }

      if (isNonEmptyStringQueryParam(initialPanelOverride)) {
        panelsOverrideObject.initialPanelOverride = initialPanelOverride;
      }

      return panelsOverrideObject;
    }),
    actions: {
      reportError: function reportError(notification) {
        if (notification) {
          notification.hide();
        }

        this.get('sidebar').closePanel();
        this.transitionToRoute('feedback');
      },
      openPreferencesModal: function openPreferencesModal() {
        this.get('settings').open();
      }
    }
  });

  _exports.default = _default;
});