define("web-directory/models/group", ["exports", "ember-data", "lodash", "web-directory/mixins/entity", "ember-purecloud-components/components/entity-image/component"], function (_exports, _emberData, _lodash, _entity, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.BASE_GROUP_FIELDS = _exports.BASE_GROUP = void 0;
  var BASE_GROUP_FIELDS = ['general', 'owners', 'membership', 'individuals', 'images.activeProfilePicture', 'extMongoId', 'contactInfo'];
  _exports.BASE_GROUP_FIELDS = BASE_GROUP_FIELDS;
  var BASE_GROUP = BASE_GROUP_FIELDS.join(',');
  _exports.BASE_GROUP = BASE_GROUP;
  var COMPARATORS = {
    EqualTo: {
      label: 'equals (==)',
      value: 'EqualTo',
      op: '=='
    },
    NotEqualTo: {
      label: 'not equals (!=)',
      value: 'NotEqualTo',
      op: '!='
    },
    GreaterThan: {
      label: 'greater than (>)',
      value: 'GreaterThan',
      op: '>'
    },
    GreaterThanOrEqualTo: {
      label: 'greater than or equal to (>=)',
      value: 'GreaterThanOrEqualTo',
      op: '>='
    },
    LessThan: {
      label: 'less than (<)',
      value: 'LessThan',
      op: '<'
    },
    LessThanOrEqualTo: {
      label: 'less than or equal to (<=)',
      value: 'LessThanOrEqualTo',
      op: '<='
    }
  };
  var PROFICIENCIES = [{
    name: '0',
    value: 0
  }, {
    name: '1',
    value: 1
  }, {
    name: '2',
    value: 2
  }, {
    name: '3',
    value: 3
  }, {
    name: '4',
    value: 4
  }, {
    name: '5',
    value: 5
  }];

  var GroupModel = _emberData.default.Model.extend(_entity.default, {
    ajax: Ember.inject.service(),
    logger: Ember.inject.service('stash-logger'),
    application: Ember.inject.service(),
    session: Ember.inject.service(),
    notification: Ember.inject.service(),
    permissions: Ember.inject.service(),
    meta: _emberData.default.attr(),
    policy: _emberData.default.belongsTo('group-policy'),
    acdSkills: null,
    lastSavedExpression: null,
    expression: Ember.computed('_expression.content', function () {
      if (!this.get('_expression.content')) {
        return null;
      }

      return this.parseExpression(this.get('_expression.content.skillConditions'));
    }),
    isDynamicGroup: Ember.computed('expression', 'session.features.PURE3301-dynamicGroups', 'permissions.canViewGroups', function () {
      return this.get('session.features.PURE3301-dynamicGroups') && this.get('permissions.canViewGroups') ? !!this.get('expression') : false;
    }),
    reload: function reload() {
      this.notifyPropertyChange('greeting');
      this.get('policy').then(function (policy) {
        if (policy) {
          policy.reload();
        }
      });
      return this._super.apply(this, arguments);
    },
    rollbackAttributes: function rollbackAttributes() {
      this.notifyPropertyChange('greeting');
      this.notifyPropertyChange('individuals');
      this.get('policy').then(function (policy) {
        if (policy) {
          policy.rollbackAttributes();
        }
      });
      return this._super.apply(this, arguments);
    },
    // convert to attribute on Ember-Data upgrades
    greeting: Ember.computed('guid', function () {
      var path = "api/v2/groups/".concat(this.get('guid'), "/greetings/defaults");
      var url = this.get('application').pcV2Uri(path);
      return _emberData.default.PromiseObject.create({
        promise: this.get('ajax').request(url)
      });
    }),
    _expression: Ember.computed('guid', 'session.features.PURE3301-dynamicGroups', 'permissions.canViewGroups', function () {
      var _this = this;

      if (this.get('session.features.PURE3301-dynamicGroups') && this.get('permissions.canViewGroups')) {
        var path = "api/v2/groups/".concat(this.get('guid'), "/dynamicsettings");
        var url = this.get('application').pcV2Uri(path);
        return _emberData.default.PromiseObject.create({
          promise: this.get('ajax').ajaxGet(url, {
            retry: true
          }).then(function (res) {
            return Ember.RSVP.resolve(res.query);
          }).catch(function (err) {
            if (err.status === 404) {
              //this group is confirmed not a dynamic group
              return Ember.RSVP.resolve(null);
            } else if (err.status === 403 && // check and see if it's a permission issue
            err.payload.code === 'missing.any.permissions' && err.payload.message.indexOf('directory:group:view') > 0) {
              // this user needs the updated group:view permissions
              _this.get('logger').error('Error trying to load dynamic expression for group', {
                err: err
              });

              _this.get('notification').error(_this.get('intl').t('groups.expressions.loadErrorTitle'), _this.get('intl').t('groups.expressions.permissionErrorMessage'));
            } else {
              //actual error
              _this.get('logger').error('Error trying to load dynamic expression for group', {
                err: err
              });

              _this.get('notification').error(_this.get('intl').t('groups.expressions.loadErrorTitle'), _this.get('intl').t('groups.expressions.loadErrorMessage'));

              return Ember.RSVP.resolve(null);
            }
          })
        });
      }
    }),
    description: Ember.computed('general.description.@each.value', function () {
      return this.getFieldValue('general.description').value;
    }),
    groupType: Ember.computed('general.groupType', function () {
      var groupTypeValue = this.getFieldValue('general.groupType').value;

      if (groupTypeValue) {
        var actualType = groupTypeValue.split('.')[1];
        var key = "group_general_groupType_value_".concat(actualType);
        return this.get('intl').lookup(key, null, true).toString();
      }

      return '';
    }),
    memberCount: Ember.computed('general.memberCount.@each.value', function () {
      if (this.get('general.memberCount')) {
        return this.getFieldValue('general.memberCount').value;
      }

      return 0;
    }),
    profilePictureURL: Ember.computed('images.activeProfilePicture', function () {
      var images = this.get('images');

      if (images && images.activeProfilePicture && images.activeProfilePicture[0].ref) {
        return images.activeProfilePicture[0].ref.x128;
      } else {
        return _component.default.placeholderImageForSize(this, 'x200');
      }
    }),
    uploadedProfilePictures: Ember.computed('images.profilePictures', function () {
      var field = this.getFieldValue('images.profilePictures');

      if (field && field.length && field[0].value) {
        return field;
      }

      return [];
    }),
    uploadedInformalPhotos: Ember.computed('images.gallery', function () {
      var field = this.getFieldValue('images.gallery');

      if (field && field.length && field[0].value) {
        return field;
      }

      return [];
    }),
    groupVisibility: Ember.computed('privacy.groupVisibility', function () {
      return this.getFieldValue('privacy.groupVisibility').value;
    }),
    membershipVisible: Ember.computed('privacy.membershipVisible', function () {
      return this.getFieldValue('privacy.membershipVisible').value;
    }),
    includeOwners: Ember.computed('membership.includeOwners', function () {
      var visible = this.getFieldValue('membership.includeOwners').value;
      return this.get('intl').lookup("entity.fieldTypes.boolean.checked-".concat(visible)).toString();
    }),
    areOwnersIncluded: Ember.computed('membership.includeOwners', function () {
      return this.getFieldValue('membership.includeOwners').value;
    }),
    groupEmailAddress: Ember.computed('general.exchangeNameOverride', {
      get: function get() {
        return this.getFieldValue('general.exchangeNameOverride').value;
      },
      set: function set(key, value) {
        if (value === undefined) {
          value = '';
        }

        return this.set('general.exchangeNameOverride', value)[0].value;
      }
    }),
    needsCommit: Ember.computed('general.needsCommit', function () {
      return this.getFieldValue('general.needsCommit').value;
    }),
    membershipSearchQuery: Ember.computed('membership.compiledQuery.[]', function () {
      var compiledQuery = this.getFieldValue('membership.compiledQuery');
      var query = Ember.get(compiledQuery, 'value.q');

      if (query && query.length) {
        return query[0].value;
      }

      return '';
    }),
    generalSearchQuery: Ember.computed('id', 'general.groupType', function () {
      var groupType = this.getFieldValue('general.groupType').value;
      return "".concat(groupType, ":").concat(this.get('guid'));
    }),
    isActive: Ember.computed('state', function () {
      return this.get('state') === 'active';
    }),
    isDeleted: Ember.computed('state', function () {
      return this.get('state') === 'deleted';
    }),
    jid: Ember.computed('_id', function () {
      var id = this.get('_id');
      var org = this.get('application.session.org');
      var name = Ember.get(org, 'general.shortName')[0].value;
      return "".concat(id, "@conference.").concat(name, ".orgspan.com");
    }),
    readWritePermissions: Ember.computed(function () {
      return _emberData.default.PromiseArray.create({
        promise: Ember.RSVP.resolve()
      });
    }),
    // -- Handles Grants and Roles Associated with the Group --
    allowPermissionGroup: Ember.computed('general.allowPermissionGroup', {
      get: function get() {
        return this.getFieldValue('general.allowPermissionGroup').value;
      },
      set: function set(key, value) {
        return this.set('general.allowPermissionGroup', value)[0].value;
      }
    }),
    uniqueRoleCount: 0,
    grantVersion: -1,
    getGrants: function getGrants() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this2.get('ajax').ajaxGet("/platform/api/v2/authorization/subjects/".concat(_this2.get('guid'))).then(function (data) {
          var rolesMap = {};
          var uniqueRoleCount = 0;
          data.grants.forEach(function (grant) {
            if (!rolesMap[grant.role.id]) {
              rolesMap[grant.role.id] = true;
              uniqueRoleCount++;
            }
          });

          _this2.set('uniqueRoleCount', uniqueRoleCount);

          _this2.set('grantVersion', data.version);

          resolve(data.grants);
        });
      });
    },
    // --
    number: Ember.computed('contactInfo.groupNumber.@each.value', 'contactInfo.groupNumber.@each.rel', function () {
      return this.getFieldValue('contactInfo.groupNumber');
    }),
    //TODO: Delete these references to the policy model when group contact modal is deleted
    numberOfRotations: Ember.computed('policy.stopRingingAfterRotations', function () {
      var _this3 = this;

      return _emberData.default.PromiseObject.create({
        promise: function () {
          return _this3.get('policy').then(function (policy) {
            return policy.get('stopRingingAfterRotations');
          });
        }()
      });
    }),
    rotateCallSeconds: Ember.computed('policy.rotateCallsSecs', function () {
      var _this4 = this;

      return _emberData.default.PromiseObject.create({
        promise: function () {
          return _this4.get('policy').then(function (policy) {
            return policy.get('rotateCallsSecs');
          });
        }()
      });
    }),
    emailNotificationEnabled: Ember.computed.reads('policy.sendEmailNotifications', function () {
      var _this5 = this;

      return _emberData.default.PromiseObject.create({
        promise: function () {
          return _this5.get('policy').then(function (policy) {
            return policy.get('sendEmailNotifications');
          });
        }()
      });
    }),
    voicemailEnabled: Ember.computed('policy.enabled', function () {
      var _this6 = this;

      return _emberData.default.PromiseObject.create({
        promise: function () {
          return _this6.get('policy').then(function (policy) {
            return policy.get('enabled');
          });
        }()
      });
    }),
    membershipChange: Ember.observer('membership.inclusions.[]', 'membership.exclusions.[]', function () {
      var membership = this.get('membership');
      this.set('membershipDraft', membership);
    }),
    isPersonOwner: function isPersonOwner(person) {
      if (!person) {
        return false;
      }

      var owner = this.getFieldValue('owners.owners').findBy('value', person.get('id'));
      var guidOwner = this.getFieldValue('owners.owners').findBy('guid', person.get('id'));
      return !_lodash.default.isEmpty(owner) || !_lodash.default.isEmpty(guidOwner);
    },

    /**
     * We derive the Jabber ID for a group from the `_id` property.
     * Normally, this is set in the group serializer.
     * Search results do not go through the group serializer.
     * We need to set the `_id` on the group entity so that `group.get('jid')` resolves properly.
     * @returns
     */
    setIdForGroupEntity: function setIdForGroupEntity() {
      var _this7 = this;

      var existingId = this.get('_id');

      if (existingId) {
        return;
      }

      var jid = this.get('contactInfo.chat.firstObject.value.jid');

      if (jid) {
        var idParts = jid.split('@');
        var id = idParts.length > 0 ? idParts[0] : '';
        Ember.run(function () {
          _this7.set('_id', id);
        });
      }
    },
    isPersonIndividual: function isPersonIndividual(person) {
      if (!person) {
        return false;
      }

      var individual = this.getFieldValue('individuals.individuals').findBy('value', person.get('id'));
      var guidIndividual = this.getFieldValue('individuals.individuals').findBy('guid', person.get('id'));
      return !_lodash.default.isEmpty(individual) || !_lodash.default.isEmpty(guidIndividual);
    },
    getReadableSections: function getReadableSections() {
      return this.fieldConfig.get('sections').mapBy('key');
    },
    getWriteableSections: function getWriteableSections() {
      var currentPerson = this.get('application.session.person');
      var ownerIds = this.get('owners.owners').mapBy('value');
      var sections = this.fieldConfig.get('sections').mapBy('key');

      if (currentPerson && currentPerson.get('isAdmin')) {
        return sections;
      }

      if (currentPerson && ownerIds.indexOf(currentPerson.get('id')) !== -1) {
        return sections;
      }

      return [];
    },
    getReadableFieldsForSection: function getReadableFieldsForSection(section) {
      return section.get('fieldList').mapBy('key');
    },
    setProfilePicture: function setProfilePicture(image) {
      var profileImageSection = this.get('images.activeProfilePicture');

      if (profileImageSection === undefined) {
        this.set('images.activeProfilePicture', {});
        profileImageSection = this.get('images.activeProfilePicture');
      }

      if (profileImageSection && profileImageSection.length) {
        var profileImage = profileImageSection[0];
        Ember.set(profileImage, 'ref', image.value);
        Ember.set(profileImage, 'value.fieldId', image._id);
        Ember.set(profileImage, 'value.fieldPath', 'images.profilePictures');
        this.notifyPropertyChange('profilePicture');
      }
    },
    deleteGrant: function deleteGrant(grant) {
      return this.get('ajax').del("/platform/api/v2/authorization/subjects/".concat(this.get('id'), "/scopes/").concat(grant.get('scope.id'), "/roles/").concat(grant.get('role.id')));
    },
    parseExpression: function parseExpression(input) {
      var output = [];

      if (!input || input.length === 0) {
        //no need to parse an empty array
        return output;
      } // childConditions are all nested, so we gotta recurse


      var addRule = function addRule(condition, rule) {
        var newSkill = rule.routingSkillConditions.length === 0 ? {
          name: rule.languageSkillConditions[0].languageSkill,
          type: 'language'
        } : {
          name: rule.routingSkillConditions[0].routingSkill,
          type: 'routing'
        };
        var skill = rule.routingSkillConditions.length === 0 ? rule.languageSkillConditions[0] : rule.routingSkillConditions[0];
        var children = skill.childConditions;
        var op = rule.operation ? rule.operation.toUpperCase() : null;

        if (children && (!_lodash.default.isEmpty(children.routingSkillConditions) || !_lodash.default.isEmpty(children.languageSkillConditions))) {
          op = children.operation.toUpperCase();
        }

        condition.rules.pushObject({
          comparator: COMPARATORS[skill.comparator],
          proficiency: PROFICIENCIES[skill.proficiency],
          operation: op,
          opAnd: op == 'AND' ? true : false,
          skill: newSkill
        });

        if (children && (!_lodash.default.isEmpty(children.routingSkillConditions) || !_lodash.default.isEmpty(children.languageSkillConditions))) {
          addRule(condition, children);
        }
      }; // so here we'll go through each top-level condition and check recursively for children


      input.forEach(function (condition) {
        var conOp = condition.operation ? condition.operation.toUpperCase() : null;
        var newCondition = {
          rules: [],
          lastCondition: false,
          operation: conOp,
          opAnd: conOp == 'AND' ? true : false
        };
        addRule(newCondition, condition);
        newCondition.rules.set('lastObject.lastRule', true);
        newCondition.rules.set('lastObject.operation', 'OR');
        newCondition.rules.set('lastObject.opAnd', false);
        output.pushObject(newCondition);
      });
      output.set('lastObject.lastCondition', true);
      output.set('lastObject.operation', 'OR');
      output.set('lastObject.opAnd', false);
      return output;
    }
  });

  GroupModel.reopenClass({
    apiEndpoint: 'v2/groups'
  });
  var _default = GroupModel;
  _exports.default = _default;
});