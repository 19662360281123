define("web-directory/components/volume-selection/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['volume-selection'],
    phone: Ember.inject.service(),
    sound: Ember.inject.service(),
    ring: Ember.inject.service(),
    ringerVolume: Ember.computed.alias('phone.ringerVolume'),
    callVolume: Ember.computed.alias('phone.callVolume'),
    convertSliderValueToDecimal: function convertSliderValueToDecimal(sliderVal) {
      return sliderVal / 100 || 1;
    },
    actions: {
      playAlertSound: function playAlertSound() {
        var ringerVolume = this.get('ringerVolume');
        var ring = this.get('ring');
        this.get('sound').playRingtone(this.convertSliderValueToDecimal(ringerVolume), ring.getRingerOutputDeviceId(), ring.ringtoneSelection || 'phone');
        Ember.run.later(this, function () {
          this.get('sound').stopRingtone();
        }, 2500);
      },
      playCallSound: function playCallSound() {
        var callVolume = this.get('callVolume');
        var ring = this.get('ring');
        this.get('sound').playRingtone(this.convertSliderValueToDecimal(callVolume), ring.getRingerOutputDeviceId(), ring.ringtoneSelection || 'phone');
        Ember.run.later(this, function () {
          this.get('sound').stopRingtone();
        }, 2500);
      }
    }
  });

  _exports.default = _default;
});