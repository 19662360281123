define("web-directory/components/mobile-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var on = Ember.on,
      inject = Ember.inject,
      Component = Ember.Component;
  var iPhoneLink = 'https://itunes.apple.com/us/app/interactive-intelligence-collaborate/id777864400?mt=8';
  var androidLink = 'https://play.google.com/store/apps/details?id=com.inin.purecloud.directory';

  var _default = Component.extend({
    classNames: ['mobile-modal'],
    intl: inject.service(),
    sidebar: inject.service('sidebar'),
    application: inject.service('application'),
    userId: null,
    mobileVersion: '',
    appLink: '',
    appGreeting: null,
    actions: {
      sendClose: function sendClose() {
        this.sendAction('sendClose');
      }
    },
    checkMobileVersion: on('init', function () {
      var version = navigator.appVersion;
      var intl = this.get('intl');
      var isApple = version.includes('iPhone') || version.includes('iPad');
      var isAndroid = version.includes('Android');
      var userId = this.get('session.user.id');
      var appLink = '';
      var appGreeting = intl.lookup('mobile.version.other').toString();
      var mobileVersion = '';

      if (isApple) {
        var device = intl.lookup('mobile.version.apple').toString();
        appLink = iPhoneLink;
        appGreeting = intl.lookup('mobile.greeting', {
          device: device
        }).toString();
        mobileVersion = 'iphone';
      }

      if (isAndroid) {
        var _device = intl.lookup('mobile.version.android').toString();

        appLink = androidLink;
        appGreeting = intl.lookup('mobile.greeting', {
          device: _device
        }).toString();
        mobileVersion = 'android';
      }

      this.setProperties({
        userId: userId,
        appLink: appLink,
        appGreeting: appGreeting,
        mobileVersion: mobileVersion
      });
    })
  });

  _exports.default = _default;
});