define("web-directory/components/inbox-panel-v2/evaluations-v2/component", ["exports", "web-directory/utils/dates", "lodash"], function (_exports, _dates, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['inbox-message', 'clickable'],
    classNameBindings: ['isRead:read:unread'],
    hovered: false,
    clock: Ember.inject.service(),
    inbox: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    mouseEnter: function mouseEnter() {
      this.set('hovered', true);
    },
    mouseLeave: function mouseLeave() {
      this.set('hovered', false);
    },
    person: Ember.computed('evaluation.agent.id', function () {
      var userGuid = this.get('evaluation.agent.id');

      if (userGuid) {
        return this.get('store').findRecord('person', userGuid);
      }

      return null;
    }),
    actions: {
      openChat: function openChat(jid) {
        this.bubbleAction({
          action: 'openChatRoom',
          actionContext: [jid]
        });
      },
      toggleRead: function toggleRead() {
        this.get('inbox').readEvaluation(this.get('evaluation'));
      }
    },
    redacted: Ember.computed('evaluation.redacted', function () {
      return this.get('evaluation.redacted');
    }),
    date: Ember.computed('evaluation.changedDate', function () {
      var date = (0, _dates.default)(this.get('evaluation.changedDate'));
      return date.format('LL');
    }),
    time: Ember.computed('evaluation.changedDate', function () {
      var date = (0, _dates.default)(this.get('evaluation.changedDate'));
      return date.locale(this.get('intl.isoLocale')).format('LT');
    }),
    dateFromNow: Ember.computed('clock.minute', 'evaluation.changedDate', function () {
      var date = (0, _dates.default)(this.get('evaluation.changedDate'));
      return date.fromNow();
    }),
    evaluationAssigneeFeatureToggleEnabled: Ember.computed('session.features', function () {
      return this.get('session.features') && this.get('session.features')['qm.release.evaluation.assignee'];
    }),
    evaluationDisputesFeatureToggleEnabled: Ember.computed('session.features', function () {
      return this.get('session.features') && this.get('session.features')['qm.release.evaluation.disputes'];
    }),
    userAction: Ember.computed('evaluationAssigneeFeatureToggleEnabled', 'evaluation.status', function () {
      var status = this.get('evaluation.status');

      if (this.get('evaluationAssigneeFeatureToggleEnabled')) {
        var userId = this.get('session.user.id');
        var evaluator = this.get('evaluation.evaluator');
        var assignee = this.get('evaluation.assignee');
        var isPendingOrInProgress = status && (status.toLowerCase() === 'pending' || status.toLowerCase() === 'inprogress');

        if (evaluator && evaluator.id === userId && isPendingOrInProgress) {
          return 'SCORE';
        }

        if (assignee && assignee.id === userId) {
          return isPendingOrInProgress ? 'SCORE_ASSIGNED' : 'REVIEW_ASSIGNED';
        }

        return 'REVIEW';
      } else {
        if (status && (status.toLowerCase() === 'pending' || status.toLowerCase() === 'inprogress')) {
          return 'SCORE';
        } else {
          return 'REVIEW';
        }
      }
    }),
    showAgent: Ember.computed('userAction', function () {
      var userAction = this.get('userAction');

      if (userAction === 'SCORE' || userAction === 'SCORE_ASSIGNED' || userAction === 'REVIEW_ASSIGNED') {
        return true;
      }

      return false;
    }),
    showAssignee: Ember.computed('userAction', function () {
      return false;
    }),
    svgKey: Ember.computed('evaluation.mediaType', function () {
      if (_lodash.default.includes(this.get('evaluation.mediaType'), 'CALL')) {
        if (this.get('userAction') === 'SCORE') {
          // TODO: Update with new icon when available - Austin Miller
          return 'phone-success';
        } else {
          return 'phone';
        }
      } else if (_lodash.default.includes(this.get('evaluation.mediaType'), 'CHAT') || _lodash.default.includes(this.get('evaluation.mediaType'), 'MESSAGE')) {
        if (this.get('userAction') === 'SCORE') {
          // TODO: Update with new icon when available - Austin Miller
          return 'chat';
        } else {
          return 'chat';
        }
      } else if (_lodash.default.includes(this.get('evaluation.mediaType'), 'EMAIL')) {
        if (this.get('userAction') === 'SCORE') {
          // TODO: Update with new icon when available - Austin Miller
          return 'message-email';
        } else {
          return 'message-email';
        }
      }
    }),
    isRead: Ember.computed('evaluation.agent', 'evaluation.status', function () {
      var userId = this.get('session.user.id');
      var agent = this.get('evaluation.agent');
      var status = this.get('evaluation.status');
      var linkContext = this.get('evaluation.linkContext');
      return linkContext && linkContext.toLowerCase() === 'agent' && agent && userId === agent.id && status && status.toLowerCase() === 'inreview';
    }),
    conversationDate: Ember.computed('evaluation.conversationDate', function () {
      if (this.get('evaluation.conversationDate')) {
        var date = (0, _dates.default)(this.get('evaluation.conversationDate'));
        return date.format('LL');
      } else {
        return null;
      }
    })
  });

  _exports.default = _default;
});