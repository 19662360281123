define("web-directory/instance-initializers/entity-favorite", ["exports", "ember-purecloud-components/components/entity-favorite/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    _component.default.reopen({
      favorites: Ember.inject.service(),
      toggleFavorite: function toggleFavorite(entity) {
        this.get('favorites').toggleFavorite(entity);
      }
    });
  }

  var _default = {
    name: 'entity-favorite',
    initialize: initialize
  };
  _exports.default = _default;
});