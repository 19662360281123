define("web-directory/components/preferences-general/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      on = Ember.on,
      computed = Ember.computed,
      observer = Ember.observer,
      Component = Ember.Component;

  var _default = Component.extend({
    application: inject.service(),
    session: inject.service(),
    geolocation: inject.service(),
    checkForUpdates: true,
    startOnLogin: false,
    allowGeolocation: false,
    orgHasGeolocation: computed.reads('geolocation.isEnabled'),
    downloadDirectory: '',
    isMac: false,
    isWindows: false,
    //Array of additional preferences supported by the desktop app
    //Possible values:
    //   downloadDirectory
    additionalPreferences: [],
    loadPreferences: on('init', function () {
      var _this = this;

      this.set('isMac', this.get('application.hostedContext').isMac());
      this.set('isWindows', this.get('application.hostedContext').isWindows());
      this.set('additionalPreferences', this.get('application.hostedContext').getAdditionalPreferences());
      var request = {
        cmd: 'getPreferences',
        prefs: this.prefNames()
      };
      this.get('application.hostedContext').requestDesktopPromise(request).then(function (prefs) {
        _this.set('checkForUpdates', prefs.notification_checkForUpdates);

        _this.set('startOnLogin', prefs.notification_autoStartOnLogin);

        _this.set('allowGeolocation', prefs.permission_geolocation);

        if (_this.get('hasDownloadDirectory')) {
          _this.set('downloadDirectory', prefs.download_directory);
        }

        _this.shouldSetDesktopPreferences = true;
      });
    }),
    hasDownloadDirectory: computed('additionalPreferences', function () {
      return this.get('additionalPreferences').includes('downloadDirectory');
    }),
    prefNames: function prefNames() {
      var names = ['permission_geolocation'];

      if (this.get('isMac')) {
        names.push('notification_checkForUpdates');
      }

      if (this.get('isWindows')) {
        names.push('notification_autoStartOnLogin');
      }

      if (this.get('hasDownloadDirectory')) {
        names.push('download_directory');
      }

      return names;
    },
    prefDict: function prefDict() {
      var dict = {
        permission_geolocation: this.get('allowGeolocation')
      };

      if (this.get('isMac')) {
        dict.notification_checkForUpdates = this.get('checkForUpdates');
      }

      if (this.get('isWindows')) {
        dict.notification_autoStartOnLogin = this.get('startOnLogin');
      }

      if (this.get('hasDownloadDirectory')) {
        dict.download_directory = this.get('downloadDirectory');
      }

      return dict;
    },
    changeDesktopPreferences: observer('checkForUpdates', 'startOnLogin', 'allowGeolocation', 'downloadDirectory', function () {
      var _this2 = this;

      if (!this.shouldSetDesktopPreferences) {
        return;
      }

      var request = {
        cmd: 'setPreferences',
        prefs: this.prefDict()
      };
      this.get('application.hostedContext').requestDesktopPromise(request).then(function () {
        if (_this2.get('isWindows')) {
          if (_this2.get('allowGeolocation') && !_this2.get('geolocation.isStarted')) {
            _this2.get('geolocation').start();
          } else if (!_this2.get('allowGeolocation') && _this2.get('geolocation.isStarted')) {
            _this2.get('geolocation').stop();
          }
        }
      });
    }),
    actions: {
      toggleCheckForUpdates: function toggleCheckForUpdates() {
        this.toggleProperty('checkForUpdates');
      },
      toggleStartOnLogin: function toggleStartOnLogin() {
        this.toggleProperty('startOnLogin');
      },
      toggleAllowGeolocation: function toggleAllowGeolocation() {
        this.toggleProperty('allowGeolocation');
      },
      browseForDownloadDirectory: function browseForDownloadDirectory() {
        var _this3 = this;

        return this.get('application.hostedContext').requestDesktopPromise({
          cmd: 'browseForDownloadDirectory'
        }).then(function (val) {
          if (val && val.newDirectory && val.newDirectory.length > 0) {
            _this3.set('downloadDirectory', val.newDirectory);
          }
        });
      }
    }
  });

  _exports.default = _default;
});