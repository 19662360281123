define("web-directory/models/queue", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var QueueModel = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    type: _emberData.default.attr(),
    memberCount: _emberData.default.attr('number'),
    outboundMessagingAddresses: _emberData.default.attr(),
    outboundEmailAddress: _emberData.default.attr(),
    directRouting: _emberData.default.attr(),
    cannedResponseLibraries: _emberData.default.attr({
      defaultValue: {
        mode: 'All'
      }
    })
  });

  var _default = QueueModel;
  _exports.default = _default;
});