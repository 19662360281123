define("web-directory/mixins/conversation-v2/chats-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // TODO: Fill in this stub for answer a chat
    // with code that will actually answer a chat.
    answerChat: function answerChat(conversation) {
      return Ember.RSVP.resolve(conversation);
    }
  });

  _exports.default = _default;
});