define("web-directory/components/phone-settings/component", ["exports", "ember-webrtc-components/mixins/webrtc-service/auto-save-devices", "ember-purecloud-components/mixins/logger"], function (_exports, _autoSaveDevices, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Component.extend(_autoSaveDevices.default, _logger.default, {
    classNames: ['phone-settings'],
    classNameBindings: ['isPhoneEnabled:phone-enabled:phone-disabled'],
    webrtc: inject.service(),
    phone: inject.service(),
    session: inject.service(),
    permissions: inject.service(),
    addressformat: inject.service(),
    headset: inject.service(),
    preferences: inject.service(),
    application: inject.service(),
    intl: inject.service(),
    showMediaHelperStatus: false,
    station: null,
    showStationPicker: false,
    showAudioControls: false,
    selectingWebrtcSettings: false,
    showTroubleshoot: false,
    showPhoneOptions: false,
    entity: null,
    isPhoneEnabled: computed.reads('permissions.phoneEnabled'),
    isoLocale: computed.reads('intl.isoLocale'),
    // remove when followMe is on globally
    callForwardingActive: computed.reads('phone.callForwardingActive'),
    callForwardingNumber: computed.reads('phone.callForwardingNumber'),
    callForwardingExtension: computed.reads('phone.callForwardingExtension'),
    formattedNumber: computed('callForwardingNumber', 'callForwardingExtension', function () {
      if (this.get('callForwardingNumber')) {
        return this.get('addressformat').formatAddressForDisplay(this.get('callForwardingNumber'));
      }

      var extension = this.get('callForwardingExtension');
      return "extension: ".concat(extension);
    }),
    // end remove when followme is on globally
    webrtcTroubleshooterIframe: computed.reads('session.features.webrtcTroubleshooterIframe'),
    webrtcTroubleshooterUrl: computed(function () {
      var location = window.location.origin === 'https://localhost:4300' ? 'https://localhost:8445' : window.location.origin;
      var locale = this.get('isoLocale');
      var baseUrl = "/webrtc-troubleshooter/#/?locale=".concat(locale, "/");
      return location + baseUrl;
    }),
    requireMediaHelper: computed('phone.station.webRtcRequireMediaHelper', 'application.mediaHelperMode', function () {
      return this.get('phone.station.webRtcRequireMediaHelper') && !this.get('application.mediaHelperMode');
    }),
    attachOutsideListener: Ember.on('didInsertElement', function () {
      var _this = this;

      Ember.$(window).on("click.".concat(this.get('elementId')), function (event) {
        Ember.run(function () {
          var target = Ember.$(event.target);

          if (!target.parents('.station-setting').length) {
            _this.set('showPhoneOptions', false);
          }
        });
        return true;
      });
    }),
    removeListener: Ember.on('willDestroyElement', function () {
      Ember.$(window).off(".".concat(this.get('elementId')));
    }),
    micPermissionGranted: Ember.computed.reads('webrtc.hasMicPermission'),
    audioCallCapable: Ember.computed.reads('webrtc.audioCallCapable'),
    notShowAudioControls: computed.not('showAudioControls'),
    canSelectDevices: computed.and('webrtc.audioCallCapable', 'currentStation.isWebrtc'),
    stationIsWebrtcAndSelectingSettings: computed.and('selectingWebrtcSettings', 'currentStation.isWebrtc'),
    stationIsWebrtcAndNotSelectingSettings: computed('selectingWebrtcSettings', 'currentStation.isWebrtc', function () {
      return this.get('currentStation.isWebrtc') && !this.get('selectingWebrtcSettings');
    }),
    showWebrtcControlsByDefault: computed.and('showAudioControls', 'canSelectDevices'),
    showNoStationMessage: computed('showAudioControls', 'currentStation', function () {
      return this.get('showAudioControls') && !this.get('currentStation');
    }),
    showNoStationSettingsMessage: computed('showAudioControls', 'currentStation.isWebrtc', function () {
      return this.get('showAudioControls') && this.get('currentStation') && !this.get('currentStation.isWebrtc');
    }),
    currentStation: computed('station', function () {
      var stationId = this.get('station.id');

      if (stationId) {
        return this.get('station');
      }
    }),
    isHosted: computed.reads('application.hostedContext.isHostedProp'),
    canActivateQueues: Ember.computed('permissions.canActivateQueues', 'permissions.canGoOnQueue', 'session.hasTrusteeAccess', function () {
      return this.get('permissions.canActivateQueues') && this.get('permissions.canGoOnQueue') && !this.get('session.hasTrusteeAccess');
    }),
    mediaOptions: computed('selectedMicrophone', 'selectedCamera', 'screenStream', function () {
      var options = this.get('webrtc').getMediaOptions({
        camera: this.get('selectedCamera'),
        microphone: this.get('selectedMicrophone')
      });
      return options;
    }),
    canDeselectPhone: Ember.computed('permissions.canDisassociateSelf', 'session.person.isOnQueue', function () {
      return this.get('session.person.isOnQueue') ? this.get('permissions.canDisassociateSelf') : true;
    }),
    willDestroyElement: function willDestroyElement() {
      this.set('webrtc.webrtcTroubleshooterCall', false);
    },
    actions: {
      closeMenu: function closeMenu() {
        if (this.get('closeMenu')) {
          this.get('closeMenu')();
        }
      },
      changePhoneSettings: function changePhoneSettings() {
        this.toggleProperty('showPhoneOptions');
      },
      callVoicemail: function callVoicemail() {
        this.get('phone').callVoicemail();
      },
      toggleWebrtcSettings: function toggleWebrtcSettings() {
        if (this.get('openWebrtcSettings')) {
          this.get('openWebrtcSettings')();
        } else {
          this.toggleProperty('selectingWebrtcSettings');
        }
      },
      openStationPicker: function openStationPicker() {
        if (this.get('openStationPicker')) {
          this.get('openStationPicker')();
        }
      },
      openCallForwarding: function openCallForwarding() {
        this.get('callForwarding')();
      },
      toggleTroubleshoot: function toggleTroubleshoot() {
        this.toggleProperty('showTroubleshoot');
        this.toggleProperty('webrtc.webrtcTroubleshooterCall');
      },
      disassociateStation: function disassociateStation() {
        this.get('disassociateStation')();
        this.set('showPhoneOptions', false);
      },
      disconnectPersistentConnection: function disconnectPersistentConnection() {
        this.get('disconnectPersistentConnection')();
        this.set('showPhoneOptions', false);
      },
      toggleBrowserAnswer: function toggleBrowserAnswer() {
        this.get('phone').toggleBrowserAnswer();
      }
    }
  }).reopenClass({
    positionalParams: ['station', 'canCallForward', 'showEnableCallForwarding']
  });

  _exports.default = _default;
});