define("web-directory/components/link-to-external", ["exports", "ember-engines/components/link-to-external-component"], function (_exports, _linkToExternalComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _linkToExternalComponent.default;
    }
  });
});