define("web-directory/utils/message-channels", ["exports", "web-directory/utils/external-contact-messaging"], function (_exports, _externalContactMessaging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDisplayOrScreenName = getDisplayOrScreenName;
  _exports.getDisplayablePhoneNumberFrom = getDisplayablePhoneNumberFrom;
  _exports.messagingChannelTypeFor = messagingChannelTypeFor;
  _exports.messagingChannelTypes = void 0;
  _exports.modelKeyFor = modelKeyFor;

  /* global intlTelInputUtils */

  /**
   * A list of all the available messaging channel types in the external contact model
   */
  function getNameFrom(interaction) {
    return interaction.get('participantForCustomer.messages.firstObject.fromAddress.name');
  }

  function getTwitterDisplayNameFrom(interaction) {
    return (0, _externalContactMessaging.getDisplayableName)(interaction) || interaction.get('participantForCustomer.messages.firstObject.fromAddress.name');
  }

  function getRawAddress(interaction) {
    return interaction && interaction.get('participantForCustomer.messages.firstObject.fromAddress.addressRaw');
  }

  function getDisplayOrScreenName(interaction) {
    if (!interaction) {
      return null;
    }

    var messagingChannelType = messagingChannelTypeFor((0, _externalContactMessaging.getMessengerType)(interaction));
    return messagingChannelType && getNameFrom(interaction);
  } // Returns an object representing a channel property of an Ember DS contact model instance.
  // E.g. messagingChannelTypes[0].modelProperties(...)


  function getModelProperties(channelId, idProperties) {
    var properties = {};
    properties[channelId] = idProperties;
    return properties;
  }

  function messagingChannelTypeFor(messageType) {
    return messagingChannelTypes.find(function (messagingChannelType) {
      return messagingChannelType.type === messageType;
    });
  }

  function modelKeyFor(messageType) {
    var messagingChannelType = messagingChannelTypeFor(messageType);
    return messagingChannelType ? messagingChannelType.modelKey : null;
  } // New message channel types must expose a `modelProperties` method that
  // creates an object whose structure is the same as that for that message channel's identifier
  // field in a contact model object.
  //
  // For example, a contact model instance with a Twitter channel looks like this:
  //      {
  //          id: 'df445345abdc',
  //          firstName: 'Elon',
  //          lastName: 'Musk',
  //          address: {
  //                      ...
  //                   }
  //          ...
  //          twitterId: {
  //              id: 'df445345abdc',
  //              name: 'Elon Musk'
  //              screenName: '@elonmusk
  //          }
  //          ...
  //      }
  //
  //     Thus,
  //          messagingChannelTypes.modelProperties('df445345abdc', 'Elon Musk', '@elonmusk')
  //     for a Twitter channel will return
  //          {
  //              twitterId: {
  //                  id: 'df445345abdc',
  //                  name: 'Elon Musk'
  //                  screenName: '@elonmusk
  //             }
  //          }
  //
  // The contacts-service returns a displayable phone number, while Smooch may send
  // an e164.  Use this to hopefully match them up.


  function getDisplayablePhoneNumberFrom(phoneNumber) {
    // Assume a US country code;
    // obviously a short-term kludge that will be unnecessary when the real bug is (hopefully quickly) fixed!
    return intlTelInputUtils.formatNumber(phoneNumber, 'US', intlTelInputUtils.numberFormat.INTERNATIONAL);
  }

  function _getChannelPropertiesFromInteraction(interaction, channelType) {
    var messagingChannelType = messagingChannelTypeFor(channelType);
    var id = (0, _externalContactMessaging.getIdentifier)(interaction);
    var displayableName = (0, _externalContactMessaging.getDisplayableName)(interaction);
    var name = (0, _externalContactMessaging.getDisplayableName)(interaction);
    return messagingChannelType.modelProperties(id, name, displayableName);
  } // The array of objects below represent the various inbound messaging channels.
  // The properties and functions exposed by message channel types are used for display purposes and other things.
  //
  //
  // For the hasCrucialChannelInfo functions: they should return true iff their condition holds, in service of
  // https://inindca.atlassian.net/browse/RELATE-2536: WhatsApp ACD: Allow the ACD UI to tolerate missing inbound message channel information
  //
  // "The ACD UI should be aware of and behave tolerably in response to Smooch's updating their messaging protocol and payload, and the consequential
  // implication for possibly missing channel property values (e.g. a missing Twitter ID or handle)."
  //
  // Although missing information should happen very rarely, the UI still needs to cope. The coping strategy is the same for any message type.
  // There are two cases:
  //
  // 1) The agent is creating a new external contact for an inbound message. The UI should not automatically populate any messaging fields.
  // Whatever info the agent supplies for the contact should be accepted and saved.
  //
  // 2) The agent has an existing contact to associate with the message. Because the message may be missing crucial information, the contact
  // is not automatically updated with the new/incomplete message channel information.


  var messagingChannelTypes = [{
    modelKey: 'twitterId',
    // The Ember DS externalcontacts/contact model's
    channelId: 'id',
    type: 'twitter',
    idPropertyName: null,
    modelProperties: function modelProperties(id, name, twitterHandle) {
      return getModelProperties(this.modelKey, {
        id: id,
        name: name,
        screenName: twitterHandle
      });
    },
    getReverseWhitepagesLookupValue: getRawAddress,
    getChannelPropertiesFromExternalContact: function getChannelPropertiesFromExternalContact(externalContact) {
      return [externalContact.get('twitterId')];
    },
    getChannelPropertiesFromInteraction: function getChannelPropertiesFromInteraction(interaction) {
      return _getChannelPropertiesFromInteraction(interaction, 'twitter');
    },
    getIdsFromExternalContact: function getIdsFromExternalContact(externalContact) {
      return [externalContact.get('twitterId.id')];
    },
    getIdFrom: function getIdFrom(id) {
      return id;
    },
    display: function display(channelId) {
      return channelId ? channelId.screenName ? channelId.screenName : '' : '';
    },
    present: function present(channelId) {
      return !!(channelId && channelId.screenName);
    },
    hasCrucialChannelInfo: function hasCrucialChannelInfo(channelId) {
      return !!(channelId && channelId.id && channelId.screenName);
    },
    displayNameFrom: function displayNameFrom(interaction) {
      return getTwitterDisplayNameFrom(interaction);
    },
    isNewId: function isNewId(ids, id) {
      return !ids || ids.length !== 1 || ids[0] !== id;
    },
    // ids is null or a single-element string array with a Twitter ID
    svgIconKey: '#icon-twitter-white-on-blue'
  }, {
    modelKey: 'lineId',
    channelId: 'ids',
    type: 'line',
    idPropertyName: 'userId',
    modelProperties: function modelProperties(userId, displayName) {
      return getModelProperties(this.modelKey, {
        ids: [{
          userId: userId
        }],
        displayName: displayName
      });
    },
    getReverseWhitepagesLookupValue: getRawAddress,
    getChannelPropertiesFromExternalContact: function getChannelPropertiesFromExternalContact(externalContact) {
      return [externalContact.get('lineId')];
    },
    getChannelPropertiesFromInteraction: function getChannelPropertiesFromInteraction(interaction) {
      return _getChannelPropertiesFromInteraction(interaction, 'line');
    },
    getIdsFromExternalContact: function getIdsFromExternalContact(externalContact) {
      return externalContact.get('lineId.ids');
    },
    getIdFrom: function getIdFrom(userId) {
      return {
        userId: userId
      };
    },
    display: function display(channelId) {
      return channelId.displayName ? channelId.displayName : '';
    },
    present: function present(channelId) {
      return !!(channelId && channelId.ids);
    },
    hasCrucialChannelInfo: function hasCrucialChannelInfo(channelId) {
      return !!(channelId && channelId.ids && channelId.ids.every(function (id) {
        return id.userId;
      }));
    },
    displayNameFrom: function displayNameFrom(interaction) {
      return getDisplayOrScreenName(interaction);
    },
    isNewId: function isNewId(ids, id) {
      return ids && ids.some(function (lineId) {
        return lineId.userId !== id;
      });
    },
    svgIconKey: '#icon-line-messenger'
  }, {
    modelKey: 'facebookId',
    channelId: 'ids',
    type: 'facebook',
    idPropertyName: 'scopedId',
    modelProperties: function modelProperties(userId, displayName) {
      return getModelProperties(this.modelKey, {
        ids: [{
          scopedId: userId
        }],
        displayName: displayName
      });
    },
    getReverseWhitepagesLookupValue: getRawAddress,
    getChannelPropertiesFromExternalContact: function getChannelPropertiesFromExternalContact(externalContact) {
      return [externalContact.get('facebookId')];
    },
    getChannelPropertiesFromInteraction: function getChannelPropertiesFromInteraction(interaction) {
      return _getChannelPropertiesFromInteraction(interaction, 'facebook');
    },
    getIdsFromExternalContact: function getIdsFromExternalContact(externalContact) {
      return externalContact.get('facebookId.ids');
    },
    getIdFrom: function getIdFrom(scopedId) {
      return {
        scopedId: scopedId
      };
    },
    display: function display(channelId) {
      return channelId.displayName ? channelId.displayName : '';
    },
    present: function present(channelId) {
      return !!(channelId && channelId.ids);
    },
    hasCrucialChannelInfo: function hasCrucialChannelInfo(channelId) {
      return !!(channelId && channelId.ids && channelId.ids.every(function (id) {
        return id.scopedId;
      }));
    },
    displayNameFrom: function displayNameFrom(interaction) {
      return getDisplayOrScreenName(interaction);
    },
    isNewId: function isNewId(ids, id) {
      return ids && ids.some(function (facebookId) {
        return facebookId.scopedId !== id;
      });
    },
    svgIconKey: '#icon-facebook-messenger'
  }, {
    modelKey: 'whatsAppId',
    channelId: 'phoneNumber',
    nestedIdProperty: 'display',
    type: 'whatsapp',
    idPropertyName: null,
    modelProperties: function modelProperties(phoneNumber, displayName) {
      return getModelProperties(this.modelKey, {
        displayName: displayName,
        phoneNumber: {
          display: getDisplayablePhoneNumberFrom(phoneNumber)
        }
      });
    },
    getReverseWhitepagesLookupValue: function getReverseWhitepagesLookupValue(interaction) {
      var address = getRawAddress(interaction);

      if (address && /^\+?[\d]+$/.test(address)) {
        return /^\+/gi.test(address) ? address : "+".concat(address);
      }

      return address;
    },
    getChannelPropertiesFromExternalContact: function getChannelPropertiesFromExternalContact(externalContact) {
      return [externalContact.get('whatsAppId')];
    },
    getChannelPropertiesFromInteraction: function getChannelPropertiesFromInteraction(interaction) {
      return _getChannelPropertiesFromInteraction(interaction, 'whatsapp');
    },
    getIdsFromExternalContact: function getIdsFromExternalContact(externalContact) {
      return [externalContact.get('whatsAppId.phoneNumber')];
    },
    getIdFrom: function getIdFrom(phoneNumber) {
      return {
        display: getDisplayablePhoneNumberFrom(phoneNumber)
      };
    },
    display: function display(channelId) {
      return channelId.displayName ? channelId.displayName : channelId.phoneNumber.display ? channelId.phoneNumber.display : channelId.phoneNumber.e164 ? channelId.phoneNumber.e164 : '';
    },
    present: function present(channelId) {
      return !!(channelId && channelId.phoneNumber);
    },
    hasCrucialChannelInfo: function hasCrucialChannelInfo(channelId) {
      return !!(channelId && channelId.phoneNumber && channelId.phoneNumber.display);
    },
    displayNameFrom: function displayNameFrom(interaction) {
      return getDisplayOrScreenName(interaction);
    },
    // These two value getters are used by the confirmation-prompt component to
    // get changes to a whatpsapp channel's displayed phone number in order to prompt
    // the agent properly.
    oldIdValue: function oldIdValue(channelId, changedAttributes) {
      return changedAttributes["".concat(channelId)][0].display;
    },
    newIdValue: function newIdValue(channelId, changedAttributes) {
      return changedAttributes["".concat(channelId)][1].display;
    },
    isNewId: function isNewId(ids, id) {
      return !ids || ids.length !== 1 || ids[0] !== id;
    },
    // ids is null, or a single-element string array containing an e164 phone number; id is an e164 number
    svgIconKey: '#icon-whatsapp'
  }];
  _exports.messagingChannelTypes = messagingChannelTypes;
});