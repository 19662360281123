define("web-directory/components/acd-interactions-panel-v2/interaction-roster/interaction-roster-header/component", ["exports", "web-directory/utils/acd-ts"], function (_exports, _acdTs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PopoverProperties = ['showSettings', 'showCall', 'showEmail', 'showMessaging'];

  var _default = Ember.Component.extend({
    classNames: ['interaction-roster-header'],

    /**
     * @attribute
     */
    canCreateCall: false,

    /**
     * @attribute
     */
    canCreateMessage: false,

    /**
     * @attribute
     */
    canCreateEmail: false,

    /**
     * @attribute
     */
    canShowActions: false,

    /**
     * @attribute
     */
    currentUserId: null,

    /**
     * @attribute
     */
    emergencyLocationData: null,

    /**
     * @attribute
     */
    emergencyLocationOn: false,

    /**
     * @attribute
     */
    acdOutboundWhatsApp: false,

    /**
     * @attribute
     */
    showMultipanelOptIn: false,

    /**
     * @attribute
     */
    acdPanelOpen: false,

    /**
     * @attribute
     */
    outboundMedia: null,

    /**
     * @attribute
     *
     * `true` if the multipanel preference has been enabled by the current user
     */
    multipanelPreferenceEnabled: false,

    /**
     * `true` if the settings popover is currently being rendered
     */
    showSettings: false,

    /**
     * `true` if the outbound call popover is currently being rendered
     */
    showCall: false,

    /**
     * `true` if the outbound email popover is currently being rendered
     */
    showEmail: false,

    /**
     * `true` if the outbound messaging popover is currently being rendered
     */
    showMessaging: false,

    /**
     * `true` if the outbound dialog is currently being rendered
     */
    showOutboundDialog: false,

    /**
     * `true` if the outbound dialog action should be rendered instead of the individual
     * outbound actions.
     */
    enableOutboundDialog: Ember.computed.and('canCreateCall', 'canCreateEmail', 'canCreateMessage'),

    /**
     * @action
     */
    actionSetMultipanelPreference: function actionSetMultipanelPreference(value) {
      return (0, _acdTs.warnClosureAction)('actionSetMultipanelPreference', {
        value: value
      });
    },
    actions: {
      onTogglePopover: function onTogglePopover(prop) {
        this.toggleProperty(prop);
      },
      onClosePopover: function onClosePopover(prop) {
        this.set(prop, false);
      },
      onToggleDialog: function onToggleDialog() {
        this.toggleProperty('showOutboundDialog');
      },
      onCloseDialog: function onCloseDialog() {
        this.set('showOutboundDialog', false);
      },
      onSetMultipanelPreference: function onSetMultipanelPreference(value) {
        this.actionSetMultipanelPreference(value);
      }
    }
  });

  _exports.default = _default;
});