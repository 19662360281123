define("web-directory/models/contact", ["exports", "ember-custom-fields-components/models/contact"], function (_exports, _contact) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _contact.default;
    }
  });
});