define("web-directory/components/entity-fields/history-field/component", ["exports", "lodash", "web-directory/mixins/field-component"], function (_exports, _lodash, _fieldComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var on = Ember.on,
      inject = Ember.inject,
      computed = Ember.computed,
      observer = Ember.observer;

  var _default = Ember.Component.extend(_fieldComponent.default, {
    classNames: ['history'],
    intl: inject.service(),
    complexField: true,
    complexValueFields: ['entry.value.where', 'entry.value.startDate.month', 'entry.value.startDate.year', 'entry.value.endDate.month', 'entry.value.endDate.year'],
    errors: null,
    isActive: false,
    actions: {
      saveHistoryEntry: function saveHistoryEntry() {
        this.set('isNewField', false);
        this.set('isEmpty', false);
      },
      cancelHistoryEntry: function cancelHistoryEntry() {
        this.attrs.cancelAddNew(this.get('fieldData'));
      },
      focus: function focus() {
        this.set('isActive', true);
      }
    },
    focusOut: function focusOut() {
      this.set('isActive', false);
    },
    showTooltip: computed('isValid', 'entry.value.where', 'isActive', function () {
      var active = this.get('isActive');
      var valid = this.get('isValid');

      var empty = _lodash.default.isEmpty(this.get('entry.value.where'));

      return !active && (empty || !valid);
    }),
    focusNewField: function focusNewField() {
      var empty = _lodash.default.isEmpty(this.get('entry.value.where'));

      var added = this.get('entry.isAdded');

      if (empty && !added) {
        var $input = this.$('.where input');
        this.set('entry.isAdded', true);
        $input.trigger('focus');
      }
    },
    ensureDateObjectsExist: function ensureDateObjectsExist() {
      if (!this.get('editing')) {
        return;
      }

      var entry = this.get('entry');

      if (_typeof(entry.value) !== 'object') {
        Ember.set(entry, 'value', {});
      }

      if (!entry.value.endDate) {
        Ember.set(entry, 'value.endDate', {
          month: null,
          year: null
        });
      }

      if (!entry.value.startDate) {
        Ember.set(entry, 'value.startDate', {
          month: null,
          year: null
        });
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.set('errors', {
        where: false,
        date: false
      });
      Ember.run.once(this, this.ensureDateObjectsExist);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, this.focusNewField);
      Ember.run.scheduleOnce('afterRender', this, this.calculateValidityOnInsert);
    },
    calculateValidityOnInsert: function calculateValidityOnInsert() {
      this.validityChanged();
    },
    resetData: on('willDestroyElement', function () {
      this.cleanFieldForSave();
    }),
    section: computed.reads('fieldData.field.section'),
    useEducationLabels: computed.equal('section.key', 'education'),
    isNewField: computed('entry._id', 'entry.tempId', function () {
      return !this.get('entry._id') && this.get('entry.tempId');
    }),
    where: computed('entry.value.where', 'entity.bio.workHistory.[]', function () {
      return this.get('entry.value.where');
    }),
    what: computed('entry.value.what', 'entity.bio.workHistory.[]', function () {
      return this.get('entry.value.what');
    }),
    notes: computed('entry.value.notes', 'entity.bio.workHistory.[]', function () {
      return this.get('entry.value.notes');
    }),
    whereLabel: computed('useEducationLabels', function () {
      var intl = this.get('intl');

      if (this.get('useEducationLabels')) {
        return intl.lookup('entity.fieldTypes.history.educationWhere');
      }

      return intl.lookup('entity.fieldTypes.history.where').toString();
    }),
    datesLabel: computed('useEducationLabels', function () {
      var intl = this.get('intl');

      if (this.get('useEducationLabels')) {
        return intl.lookup('entity.fieldTypes.history.educationDates');
      }

      return intl.lookup('entity.fieldTypes.history.dates').toString();
    }),
    whatLabel: computed('useEducationLabels', function () {
      var intl = this.get('intl');

      if (this.get('useEducationLabels')) {
        return intl.lookup('entity.fieldTypes.history.educationWhat');
      }

      return intl.lookup('entity.fieldTypes.history.what').toString();
    }),
    notesLabel: computed(function () {
      return this.get('intl').lookup('entity.fieldTypes.history.notes');
    }),
    validityChanged: observer('showTooltip', 'isEmpty', function () {
      var isValid = this.get('isValid');
      var isEmpty = this.get('isEmpty');
      Ember.run.scheduleOnce('afterRender', this, this.whereErrorChanged, isEmpty);
      Ember.run.scheduleOnce('afterRender', this, this.dateErrorChanged, !isValid);
    }).on('init'),
    whereErrorChanged: function whereErrorChanged(error) {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      if (!this.get('editing')) {
        return;
      }

      var showTooltip = this.get('showTooltip');
      var input = this.get('whereInput');

      if (error && showTooltip) {
        var elem = this.$('.where input');

        if (!input && elem.length) {
          input = elem.tooltip({
            placement: 'top',
            title: this.get('intl').lookup('entity.fieldTypes.history.validation.whereError').toString(),
            trigger: 'manual'
          });
          this.set('whereInput', input);
        }

        if (input) {
          input.tooltip('show');
        }
      } else {
        if (input) {
          input.tooltip('hide');
        }
      }
    },
    dateErrorChanged: function dateErrorChanged(error) {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      if (!this.get('editing')) {
        return;
      }

      var input = this.get('dateInput');

      if (error) {
        var elem = this.$('.from-date input');

        if (!input && elem.length) {
          input = elem.tooltip({
            placement: 'top',
            title: this.get('intl').lookup('entity.fieldTypes.history.validation.dateError').toString(),
            trigger: 'manual'
          });
          this.set('dateInput', input);
        }

        if (input) {
          input.tooltip('show');
        }
      } else {
        if (input) {
          input.tooltip('hide');
        }
      }
    },
    isComplexFieldEmpty: function isComplexFieldEmpty() {
      // only required field is `where`
      var where = this.get('entry.value.where');
      return where === undefined || _lodash.default.isEmpty(where.trim());
    }
  });

  _exports.default = _default;
});