define("web-directory/components/acd-interactions-panel-v2/interaction-roster/feature-opt-in-section/component", ["exports", "web-directory/utils/acd-ts"], function (_exports, _acdTs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['interaction-feature-opt-in-section'],
    classNameBindings: ['isEnabled:enabled:disabled'],

    /**
     * @attribute
     */
    title: null,

    /**
     * @attribute
     */
    description: null,

    /**
     * @attribute
     *
     * We can think about making this more dynamic based on timestamps
     */
    isNew: false,

    /**
     * @attribute
     */
    isEnabled: false,

    /**
     * @action
     * action for when the feature opt-in enable value has updated
     *
     * @param value the value to emit for updating the enable state
     */
    actionOnSetEnable: function actionOnSetEnable(value) {
      return (0, _acdTs.warnClosureAction)('actionOnSetEnable', {
        value: value
      });
    },
    actions: {
      onSetEnable: function onSetEnable(value) {
        this.actionOnSetEnable(value);
      }
    }
  });

  _exports.default = _default;
});