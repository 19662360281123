define("web-directory/components/acd-interactions-panel/interaction/panel-manager/component", ["exports", "web-directory/utils/acd"], function (_exports, _acd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.NONE_PANEL = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var NONE_PANEL = 'none';
  _exports.NONE_PANEL = NONE_PANEL;

  var _default = Ember.Component.extend({
    // Need to set to '' to avoid extra div wrapper
    tagName: '',
    // List of available panels
    panels: null,
    // If set, overrides user & initial selection
    forcedPanelSelection: null,
    // Maps panelContextKey -> user panel selection
    // Maintain state to support "sticky" panel behavior
    userPanelSelectionMap: null,
    panelContextKey: null,
    activePanelContextKeys: null,
    _prevSelectionState: null,
    _prevPanelContextKey: null,
    // Represents user selection states, overrides initial selection
    userPanelSelection: Ember.computed('panelContextKey', 'userPanelSelectionMap', function () {
      var panelContextKey = this.get('panelContextKey');
      return this.get('userPanelSelectionMap')[panelContextKey] || null;
    }),
    // The panel selection to honor if the user has not explicitly selected a panel
    initialPanelSelection: null,
    // Compute the actual panel selection
    effectivePanelSelection: Ember.computed('panels', 'forcedPanelSelection', 'userPanelSelection', 'initialPanelSelection', function () {
      var forced = this.get('forcedPanelSelection');
      var user = this.get('userPanelSelection');
      var initial = this.get('initialPanelSelection');
      if (forced && this._isPanelAvailable(forced)) return forced;
      if (user && this._isPanelAvailable(user)) return user;
      if (initial && this._isPanelAvailable(initial)) return initial;
      return NONE_PANEL;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('_prevSelectionState', {});
      this.set('userPanelSelectionMap', {});

      if (this.setOpenPanel) {
        this.setOpenPanel(this.openPanel.bind(this));
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var userSelectionMap = this.get('userPanelSelectionMap');
      var activePanelContextKeys = this.get('activePanelContextKeys');
      var cleanedUserSelectionMap = activePanelContextKeys.reduce(function (acc, key) {
        if (key in userSelectionMap) {
          acc[key] = userSelectionMap[key];
        }

        return acc;
      }, {});
      this.set('userPanelSelectionMap', cleanedUserSelectionMap);
    },
    onPanelSelectionChanged: function onPanelSelectionChanged(selection) {
      return (0, _acd.warnClosureAction)('onPanelSelectionChanged', {
        selection: selection
      });
    },
    clearForcedPanelSelection: function clearForcedPanelSelection() {
      return (0, _acd.warnClosureAction)('clearForcedPanelSelection');
    },
    onUserInitiatedPanelSelection: function onUserInitiatedPanelSelection() {
      return (0, _acd.warnClosureAction)('onUserInitiatedPanelSelection');
    },
    instrumentPanelManagerStateUpdate: function instrumentPanelManagerStateUpdate() {
      return (0, _acd.warnClosureAction)('instrumentPanelManagerStateUpdate');
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      var prevPanelContextKey = this.get('_prevPanelContextKey');
      var prevSelection = this.get('_prevSelectionState');
      var prevUserSelection = prevSelection.user || null;
      var prevForcedSelection = prevSelection.forced || null;
      var prevInitialSelection = prevSelection.initial || null;
      var prevEffectiveSelection = prevSelection.effective || null;
      var newPanelContextKey = this.get('panelContextKey');
      var newForcedSelection = this.get('forcedPanelSelection');
      var newUserSelection = this.get('userPanelSelection');
      var newInitialSelection = this.get('initialPanelSelection');
      var newEffectiveSelection = this.get('effectivePanelSelection');
      var effectiveSelectionChanged = newEffectiveSelection !== prevEffectiveSelection;
      var panelContextKeyChanged = prevPanelContextKey !== newPanelContextKey;

      if (this.onPanelSelectionChanged && effectiveSelectionChanged) {
        this.onPanelSelectionChanged(newEffectiveSelection);
      }

      if (this.instrumentPanelManagerStateUpdate && (effectiveSelectionChanged || panelContextKeyChanged)) {
        this.instrumentPanelManagerStateUpdate({
          prevEffectiveSelection: prevEffectiveSelection,
          prevForcedSelection: prevForcedSelection,
          prevUserSelection: prevUserSelection,
          prevInitialSelection: prevInitialSelection,
          prevPanelContextKey: prevPanelContextKey,
          newEffectiveSelection: newEffectiveSelection,
          newForcedSelection: newForcedSelection,
          newUserSelection: newUserSelection,
          newInitialSelection: newInitialSelection,
          newPanelContextKey: newPanelContextKey
        });
      }

      this.set('_prevPanelContextKey', newPanelContextKey);
      this.set('_prevSelectionState', {
        forced: newForcedSelection,
        user: newUserSelection,
        initial: newInitialSelection,
        effective: newEffectiveSelection
      });
    },
    openPanel: function openPanel(panelId) {
      var prevPanel = this.get('effectivePanelSelection');
      var panelContextKey = this.get('panelContextKey');
      var newPanel = prevPanel === panelId ? NONE_PANEL : panelId;
      var updatedMap = Object.assign({}, this.get('userPanelSelectionMap'), _defineProperty({}, panelContextKey, newPanel));
      this.set('userPanelSelectionMap', updatedMap); // Since a user has manually clicked a panel, clear the forced selection

      if (this.clearForcedPanelSelection) {
        this.clearForcedPanelSelection();
      }

      if (this.onUserInitiatedPanelSelection) {
        this.onUserInitiatedPanelSelection({
          prevPanel: prevPanel,
          newPanel: newPanel
        });
      }
    },
    actions: {
      onUserPanelSelection: function onUserPanelSelection(_, panel) {
        this.openPanel(panel);
      }
    },
    _isPanelAvailable: function _isPanelAvailable(panel) {
      return this.get('panels').indexOf(panel) >= 0;
    }
  });

  _exports.default = _default;
});