define("web-directory/components/email-conversation/render-html/component", ["exports", "ember-purecloud-components/mixins/logger", "web-directory/mixins/acd-attachment-url-mixin"], function (_exports, _logger, _acdAttachmentUrlMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IMAGE_HIDDEN_ICON = '<i class="fa fa-picture-o hidden-image">';
  var IMAGE_NOT_FOUND_ICON = '<i class="fa fa-times-rectangle-o broken-image">';
  var DEFAULT_ICON_FONT_HEIGHT = 20;

  var replaceImageWithIcon = function replaceImageWithIcon(image, icon) {
    var $replacementIcon = $(icon); // different email clients may or may not supply dimensions for attached images
    // so use a standard size instead of sizing equal content differently for different clients

    $replacementIcon.css({
      'font-size': DEFAULT_ICON_FONT_HEIGHT
    });
    $(image).replaceWith($replacementIcon);
  };

  var _default = Ember.Component.extend(_logger.default, _acdAttachmentUrlMixin.default, {
    logger: Ember.inject.service('stash-logger'),
    session: Ember.inject.service(),
    application: Ember.inject.service(),
    htmlBody: null,
    inlineImages: null,
    showImages: false,
    renderBody: Ember.computed.oneWay('htmlBody'),
    replacedHtmlBody: Ember.computed('renderBody', 'inlineImages', 'showImages', function () {
      var renderBody = this.get('renderBody');
      var inlineImages = this.get('inlineImages') || [];
      var showImages = this.get('showImages');
      var $renderContainer = $('<div/>').html(renderBody);
      $renderContainer.find('*').removeClass();
      var images = $renderContainer.find('img'); // make all images dom-ready

      this.formatImages(images, inlineImages, showImages);
      return $renderContainer.html();
    }),

    /**
     * Do whatever dom manipulation needs done after the dom has rendered,
     * like attaching event handlers
     */
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.$('a').attr('target', '_blank'); // replace broken images with a nicer picture

      var parentCtx = this;

      var handleImageError = function handleImageError() {
        if (parentCtx.get('session.features.acdEmailReloadImageBtn')) {
          if (!parentCtx.get('hasImageLoadError')) {
            parentCtx.set('hasImageLoadError', true);
          }

          if (!parentCtx.get('loadTrueImageSrc')) {
            replaceImageWithIcon(this, IMAGE_NOT_FOUND_ICON);
          }
        } else {
          replaceImageWithIcon(this, IMAGE_NOT_FOUND_ICON);
        }
      };

      var imgTag = this.$('img');
      imgTag.on('error', Ember.run.bind(imgTag, handleImageError));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.$('img').off('error');
    },

    /**
     * Format a set of images so they will render properly
     *
     * 1) Remove images if user hasn't allowed images to show
     * 2) Replace image cid sources with valid content uri's if possible
     *
     * @param {JQuery<HTMLELement>} images set of DOM images
     * @param {any[]} inlineImages data about all images to be rendered
     * @param {boolean} showImages flag to disable showing / loading images
     */
    formatImages: function formatImages(images, inlineImages, showImages) {
      var _this = this;

      inlineImages = inlineImages || []; // find attached media that correspond to the image

      var findAttachment = function findAttachment(image) {
        return inlineImages.find(function (mediaItem) {
          var decodedImgSrc = _this.$("<div>".concat(image.src, "</div>"))[0].textContent;

          var comparisonString = 'cid:' + mediaItem.attachmentId;
          var foundMatch = comparisonString === decodedImgSrc;
          return foundMatch;
        });
      };

      images.each(function (index) {
        var image = images.get(index); // determines if the image should be in the inlineImages

        var hasCidSrc = image.src.indexOf('cid:') === 0; // replace the image src appropriately

        if (hasCidSrc && showImages) {
          // try to find a corresponding attachment
          var foundAttachment = findAttachment(image);

          if (foundAttachment) {
            // matching attachment on message
            var updatedUrl = _this.embeddedUrl(foundAttachment.contentUri);

            image.src = updatedUrl;
          } else {
            // no matching attachment
            replaceImageWithIcon(image, IMAGE_NOT_FOUND_ICON);
          }
        } else if (!showImages) {
          // user has showing images disabled
          replaceImageWithIcon(image, IMAGE_HIDDEN_ICON);
        } // regular images (ex. <img src="https://some/site/picture.jpg">)
        // are left alone and allowed to render if images are shown

      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});