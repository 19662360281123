define("web-directory/helpers/-label-for-activity", ["exports", "web-directory/utils/dates"], function (_exports, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var activityMessages = {
    sms: 'components.recentContact.sms',
    voice: 'components.recentContact.voice',
    email: 'components.recentContact.email',
    chat: 'components.recentContact.chat'
  };

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params) {
      var activity = params[0];
      var message = activityMessages[activity.method];
      var date = (0, _dates.default)(activity.ts * 1000);
      return this.get('intl').lookup(message, {
        date: date.format('L')
      });
    }
  });

  _exports.default = _default;
});