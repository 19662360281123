define("web-directory/utils/types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assertNever = assertNever;
  _exports.hasKey = hasKey;
  _exports.includes = includes;

  /*
   * Utility and Common types
   * Note: Feature-specific types should be kept within their own or separate util files.
   */
  function assertNever(x) {
    throw new Error("Unexpected object: ".concat(x));
  } // TS can't narrow `object` to an object with a known key, so we need a type-guard for that


  function hasKey(obj, key) {
    return key in obj;
  } // Includes type-guard/utility for readonly arrays/tuples/string unions


  function includes(haystack, needle) {
    var _haystack = haystack;
    return _haystack.includes(needle);
  }
});