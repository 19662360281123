define("web-directory/components/radio-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component;

  var _default = Component.extend({
    tagName: 'input',
    attributeBindings: ['type', 'disabled', 'isChecked:checked'],
    checked: false,
    disabled: false,
    onClick: function onClick() {},
    type: 'radio',
    isChecked: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('isChecked', this.get('checked'));
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.set('isChecked', this.get('checked'));
    },
    click: function click(event) {
      this.set('isChecked', true);
      this.get('onClick')();
      event.stopPropagation();
    }
  });

  _exports.default = _default;
});