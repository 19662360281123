define("web-directory/services/favicon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initializeFavicon = initializeFavicon;
  var Tinycon = window.Tinycon;

  function initializeFavicon() {
    // Explictly grab the first favicon tag since tinycon selects the first link tag containing any icon string
    // See https://github.com/tommoor/tinycon/issues/89
    var faviconTag = null;

    if (document && document.querySelectorAll) {
      var iconTags = document.querySelectorAll('head link[rel~="icon"]');

      if (iconTags && iconTags.length > 0) {
        faviconTag = iconTags[0];
      }
    }

    Tinycon.setOptions({
      fallback: false,
      abbreviate: true,
      crossOrigin: true,
      background: '#FF5A20' // @see variables.less - @roster-new-chat-emphasis-color

    });

    if (faviconTag) {
      // setImage only works as an update.
      // Forcing a render of the tinycon canvas; otherwise, the setImage call doesn't persist the new canvas
      Tinycon.setBubble(0);
      Tinycon.setImage(faviconTag.getAttribute('href')); // Force a render to use the new img url immediately and not wait for first bubble call

      Tinycon.setBubble(0);
    }
  }

  var _default = Ember.Service.extend({
    setFaviconBubbleText: function setFaviconBubbleText(text) {
      Tinycon.setBubble(text);
    }
  });

  _exports.default = _default;
});