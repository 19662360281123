define("web-directory/components/entity-fields/tag-field/tag-editor/component", ["exports", "web-directory/utils/keystroke"], function (_exports, _keystroke) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var on = Ember.on,
      Component = Ember.Component,
      inject = Ember.inject;

  var _default = Component.extend({
    classNames: ['tag-editor'],
    ajax: inject.service(),
    search: inject.service(),
    attrs: {
      field: null,
      addTag: function addTag() {}
    },
    tagValue: null,
    currentRequest: null,
    initTagInput: on('didInsertElement', function () {
      var _this = this;

      var $typeahead = this.$('input').typeahead({
        highlight: true,
        hint: false
      }, {
        name: 'tags',
        async: true,
        limit: 100,
        displayKey: 'text',
        source: Ember.run.bind(this, function (query, syncResults, asyncResults) {
          Ember.run.debounce(_this, _this.performSearch.bind(_this, query, asyncResults), 300);
        })
      });
      $typeahead.on('typeahead:selected', this.addTagFromTypeahead.bind(this));
      $typeahead.on('typeahead:change', this.clearInput.bind(this));
      this.$('input').on('blur', this.clearInput.bind(this));
    }),
    removeBehavior: on('willDestroyElement', function () {
      this.$('input').typeahead('destroy');
    }),
    performSearch: function performSearch(query, cb) {
      var type = 'purecloud.skills';
      var fieldPath = this.get('field').getFieldPath();

      if (fieldPath === 'skills.spokenLanguages') {
        type = 'purecloud.languages';
      }

      var request = this.get('search').search({
        types: ['tags'],
        query: [{
          type: 'TERM',
          value: type,
          fields: ['namespace']
        }, {
          type: 'STARTS_WITH',
          value: query,
          fields: ['name']
        }],
        returnFields: ['key']
      });
      this.set('currentRequest', request);
      return request.then(function (data) {
        var tags = data.results;
        var suggestions = Ember.A(tags).map(function (tag) {
          return {
            value: tag.key,
            text: tag.key
          };
        });
        cb(suggestions);
      }).catch(function () {
        cb([]);
      });
    },
    addTagFromTypeahead: function addTagFromTypeahead(event, suggestion
    /*, dataset*/
    ) {
      var _this2 = this;

      Ember.run(function () {
        _this2.attrs.addTag(suggestion.value);

        _this2.set('tagValue', '');

        _this2.$('input').typeahead('close');
      });
    },
    clearInput: function clearInput() {
      var _this3 = this;

      Ember.run(function () {
        if (!_this3.get('tagValue')) {
          _this3.$('input').val('');
        }
      });
    },
    keyDown: function keyDown(event) {
      if ((0, _keystroke.eventToString)(event) === 'ENTER') {
        this.attrs.addTag(this.get('tagValue'));
        this.set('tagValue', '');
        this.$('input').typeahead('close');
        var currentRequest = this.get('currentRequest');

        if (currentRequest && currentRequest.abort) {
          currentRequest.abort();
        }
      }
    }
  });

  _exports.default = _default;
});