define("web-directory/mixins/chat-user", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject,
      Mixin = Ember.Mixin;

  var _default = Mixin.create({
    chatService: inject.service('chat'),
    oneToOneRoom: null,
    presenceSubscriptionCounter: 0,
    jid: computed('contactInfo.chat', function () {
      var chatField = this.getFieldValue('contactInfo.chat');

      if (chatField) {
        return Ember.get(chatField, 'value.jid');
      }

      return null;
    }),
    // Deprecated: Please use entity-presence mixin properties where possible.
    presence: computed.reads('activityPresence'),
    chatStatus: computed.reads('primaryStatusMessage'),
    hasChatStatus: computed.notEmpty('chatStatus'),
    // TODO: these hasMedia computeds should move "up" a layer to "participant" - a new model layer for persons in rooms
    hasMedia: computed('mediaPresence', function () {
      var mediaInfo = this.get('mediaPresence.media');
      return !!_lodash.default.find(mediaInfo, function (media) {
        return media.audio || media.video || media.listener;
      });
    }),
    hasMediaInActiveChatRoom: computed('hasMedia', 'chatService.activeRoom', function () {
      return this.get('mediaPresence.roomJid') === this.get('chatService.activeRoom.jid');
    })
  });

  _exports.default = _default;
});