define("web-directory/components/upload-picture/helpers", ["exports", "ember-ajax"], function (_exports, _emberAjax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.linkImage = linkImage;
  _exports.uploadImageUrl = uploadImageUrl;

  function linkImage(entity, imageData, imageInfo, crop, uploadType) {
    return entity.linkUploadedImage(imageData, crop, imageInfo, uploadType);
  }

  function uploadImageUrl(application, url) {
    var formData = new window.FormData();
    formData.append('imageUrl', url);
    return (0, _emberAjax.default)(application.uri('api/v2/images'), {
      method: 'post',
      data: formData,
      contentType: false,
      processData: false
    });
  }
});