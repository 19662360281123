define("web-directory/field-config/entities/external-contact", ["exports", "web-directory/field-config/field-config", "web-directory/models/external-contact"], function (_exports, _fieldConfig, _externalContact) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fieldConfig.default.extend({
    modelClass: _externalContact.default
  }).reopenClass({
    modelClass: _externalContact.default
  });

  _exports.default = _default;
});