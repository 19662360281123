define("web-directory/models/evaluation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    id: null,
    agent: null,
    agentHasRead: null,
    assignedDate: null,
    releaseDate: null,
    changedDate: null,
    conversation: null,
    evaluationForm: null,
    evaluator: null,
    status: null,
    redacted: false,
    selected: false,
    rescore: false,
    mediaType: [],
    conversationDate: null,
    assignee: null,
    dateAssigneeChanged: null,
    declinedReview: false,
    displayThumbnail: Ember.computed('agent.userImages', function () {
      if (this.get('agent.userImages.length')) {
        return this.get('agent.userImages.firstObject');
      }

      return {
        imageUri: null
      };
    })
  });

  _exports.default = _default;
});