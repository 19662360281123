define("web-directory/templates/components/webhooks-collapsible", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e3h8ib8a",
    "block": "{\"statements\":[[11,\"div\",[]],[16,\"class\",[34,[\"media-item webhooks collapsible \",[33,[\"unless\"],[[28,[\"expanded\"]],\"collapsed\"],null]]]],[13],[0,\"\\n    \"],[11,\"button\",[]],[15,\"class\",\"collapse-toggle btn btn-link\"],[15,\"type\",\"button\"],[5,[\"action\"],[[28,[null]],\"toggleExpanded\"]],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"webhooks-header\"],[13],[0,\"\\n            \"],[1,[33,[\"html-safe\"],[[28,[\"webhooksHeader\"]]],null],false],[0,\"\\n            \"],[11,\"i\",[]],[15,\"class\",\"ion-arrow-down-b\"],[13],[14],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\\n\"],[6,[\"if\"],[[28,[\"expanded\"]]],null,{\"statements\":[[0,\"        \"],[11,\"div\",[]],[15,\"class\",\"webhooks-body\"],[13],[0,\"\\n            \"],[1,[33,[\"html-safe\"],[[28,[\"webhooksTitle\"]]],null],false],[0,\"\\n            \"],[11,\"div\",[]],[15,\"class\",\"webhooks-details\"],[13],[0,\"\\n                \"],[11,\"div\",[]],[13],[0,\" \"],[1,[33,[\"html-safe\"],[[28,[\"webhooksDetails\"]]],null],false],[0,\" \"],[14],[0,\"\\n            \"],[14],[0,\"\\n        \"],[14],[0,\"\\n\"]],\"locals\":[]},null],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/templates/components/webhooks-collapsible.hbs"
    }
  });

  _exports.default = _default;
});