define("web-directory/initializers/session-service", ["exports", "web-directory/services/session"], function (_exports, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'setupSessionService',
    after: 'setupApplicationService',
    initialize: function initialize(application) {
      application.register('service:session', _session.default);
      application.inject('service:session', 'application', 'service:application');
      application.inject('controller', 'session', 'service:session');
      application.inject('route', 'session', 'service:session');
      application.inject('component', 'session', 'service:session');
      application.inject('adapter', 'session', 'service:session');
    }
  };
  _exports.default = _default;
});