define("web-directory/utils/credit-card", ["exports", "ember-chat-components/utils/credit-card"], function (_exports, _creditCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "hasCreditCard", {
    enumerable: true,
    get: function get() {
      return _creditCard.hasCreditCard;
    }
  });
  Object.defineProperty(_exports, "redactCreditCard", {
    enumerable: true,
    get: function get() {
      return _creditCard.redactCreditCard;
    }
  });
});