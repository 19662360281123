define("web-directory/initializers/field-config", ["exports", "web-directory/field-config/field-config", "web-directory/field-config/entities/person", "web-directory/field-config/entities/group", "web-directory/field-config/entities/org", "web-directory/field-config/entities/external-contact"], function (_exports, _fieldConfig, _person, _group, _org, _externalContact) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _register(application, id, config) {
    application.register(id, config);
  }

  function initialize(application) {
    _register(application, 'field-config:base', _fieldConfig.default);

    _register(application, 'field-config:person', _person.default);

    _register(application, 'field-config:group', _group.default);

    _register(application, 'field-config:org', _org.default);

    _register(application, 'field-config:externalContact', _externalContact.default);
  }

  var _default = {
    name: 'setupFieldConfig',
    after: 'orgspan',
    initialize: initialize
  };
  _exports.default = _default;
});