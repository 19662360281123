define("web-directory/controllers/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['entity', 'query', 'queryFacets', 'saveHistory'],
    entity: null,
    query: null,
    queryFacets: null,
    saveHistory: true
  });

  _exports.default = _default;
});