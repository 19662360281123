define("web-directory/components/skillgroup-profile/membership-entry/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dTuZpxK2",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"image\"],[13],[0,\"\\n\"],[6,[\"entity-image\"],null,[[\"entity\",\"showPresence\",\"isLinkable\"],[[28,[\"person\"]],true,true]],{\"statements\":[[0,\"        \"],[1,[33,[\"integration-badges\"],null,[[\"entity\"],[[28,[\"person\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},null],[14],[0,\"\\n\\n\"],[11,\"div\",[]],[15,\"class\",\"information\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"person-name\"],[13],[0,\"\\n        \"],[1,[28,[\"person\",\"name\"]],false],[0,\"\\n    \"],[14],[0,\"\\n\\n    \"],[11,\"div\",[]],[15,\"class\",\"person-title\"],[13],[0,\"\\n        \"],[1,[28,[\"person\",\"title\"]],false],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[11,\"div\",[]],[15,\"class\",\"member-contact\"],[13],[0,\"\\n    \"],[1,[33,[\"entity-contact\"],null,[[\"contact\",\"showChat\",\"showFavorite\",\"shouldTether\"],[[28,[\"person\"]],[28,[\"showChat\"]],false,false]]],false],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/skillgroup-profile/membership-entry/template.hbs"
    }
  });

  _exports.default = _default;
});