define("web-directory/utils/analytics-routing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildInteractionDetailRedirectPath = buildInteractionDetailRedirectPath;

  /**
   * Params from the URL in the router. Pattern could be any of the following.
   *   - engage/admin/interactions/:interactionId/
   *   - engage/admin/interactions/:interactionId/*interactionDetailPath
   *   - engage/agent/interactions/:interactionId/
   *   - engage/agent/interactions/:interactionId/*interactionDetailPath
   */

  /** Builds a path to the analytics-ui iframe coordinator route for the interaction detail view given parameters from the old /engage/admin/interactions/ path. */
  function buildInteractionDetailRedirectPath(args) {
    var queryParams = args.queryParams || {};
    var queryParamSegments = [];
    Object.entries(queryParams).forEach(function (keyValuePair) {
      return queryParamSegments.push(keyValuePair.join('='));
    });
    var queryParamString = queryParamSegments.join('&');
    var interactionId = args.urlParams.interactionId;
    var interactionDetailPath = args.urlParams.interactionDetailPath;
    var adminAgentSegment = args.adminAgentSegment;
    var url = "/analytics/interactions/".concat(interactionId, "/").concat(adminAgentSegment, "/");

    if (interactionDetailPath) {
      url += interactionDetailPath;
    }

    if (queryParamString) {
      url += "?".concat(queryParamString);
    }

    return url;
  }
});