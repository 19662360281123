define("web-directory/services/apps-api-handlers/attention-count-handler", ["exports", "web-directory/models/app-instance"], function (_exports, _appInstance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SET_ATTENTION_COUNT_API_ACTION_NAME = void 0;
  var RSVP = Ember.RSVP;
  var SET_ATTENTION_COUNT_API_ACTION_NAME = 'setAttentionCount';
  _exports.SET_ATTENTION_COUNT_API_ACTION_NAME = SET_ATTENTION_COUNT_API_ACTION_NAME;
  var AttentionCountHandler = Ember.Object.extend({
    /**
     * Synchronous check to see if this handler is applicable for the specified AppApiCall instance
     *
     * @param {Object} the AppApiCall instance to check if this handler can handle
     *
     * @returns true if the provided AppApiCall instance could be handled by this handler. This check should
     *  be limited to checking if the handler is responsible for this type of AppApiCall rather than if the
     *  payload itself is valid.  If no handler returns true, the error will be that no handler exists which is
     *  a different case than the AppApiCall is invalie. Practically, this means that validation of the payload
     *  should be done in the handle method
     */
    canHandle: function canHandle(appApiCall) {
      return appApiCall.get('action') === SET_ATTENTION_COUNT_API_ACTION_NAME;
    },
    handle: function handle(appApiCall) {
      return new RSVP.Promise(function (resolve, reject) {
        var appInstance = appApiCall.get('srcAppInstance');

        if (!appInstance) {
          reject('No appInstance provided');
          return;
        }

        if (!(appInstance instanceof _appInstance.default)) {
          reject('Invalid appInstance provided');
          return;
        }

        var count = appApiCall.get('actionDetails.count');

        if (typeof count !== 'number') {
          reject('You must provide an integer count property to set the notification count');
          return;
        }

        if (count < 0) {
          reject('You must provide a count property >= 0 to set the notification count');
          return;
        }

        appInstance.set('attentionCount', count);
        return resolve();
      });
    }
  });
  var _default = AttentionCountHandler;
  _exports.default = _default;
});