define("web-directory/components/group-profile/membership-tag-search/component", ["exports", "web-directory/search/service", "lodash"], function (_exports, _service, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var on = Ember.on,
      inject = Ember.inject,
      computed = Ember.computed,
      observer = Ember.observer,
      Component = Ember.Component;

  var _default = Component.extend({
    classNames: ['group-membership-search', 'tag-search'],
    intl: inject.service(),
    search: inject.service(),
    group: null,
    searchValue: null,
    mode: null,
    filter: null,
    loading: false,
    editing: false,
    actions: {
      clearSearch: function clearSearch() {
        this.set('searchValue', '');
      },
      keyword: function keyword(_keyword) {
        this.sendAction('selectKeyword', _keyword);
        this.resetSearch();
      },
      updateSearch: function updateSearch(event) {
        var value = event.target.value;
        this.set('searchValue', value);

        if (this.get('mode') === 'search') {
          this.sendAction('updateSearch', value);
        } else if (value === '') {
          this.resetSearch();
        } else {
          Ember.run.debounce(this, this.performSearch, value, 250);
        }
      }
    },
    searchText: computed(function () {
      return this.get('intl').lookup('groups.addKeyword').toString();
    }),
    results: computed('tagSearchResults.[]', function () {
      return this.get('tagSearchResults');
    }),
    iconClass: computed('iconClass', function () {
      if (this.get('mode') === 'search') {
        return 'pc-search';
      }

      return 'pc-add-person';
    }),
    dismissed: computed('results.[]', function () {
      return _lodash.default.isEmpty(this.get('results'));
    }),
    editingStateChanged: observer('editing', function () {
      if (!_lodash.default.isEmpty(this.get('searchValue'))) {
        this.set('searchValue', '');
      }
    }),
    currentSearchRequestUpdated: observer('currentSearchRequest', function () {
      var _this = this;

      this.get('currentSearchRequest').then(function () {
        _this.set('loading', false);
      });
    }),
    showSearchResults: computed('mode', 'dismissed', 'results.[]', function () {
      var mode = this.get('mode');
      var dismissed = this.get('dismissed');
      return mode === 'add' && !dismissed;
    }),
    attachOutsideHandler: on('didInsertElement', function () {
      var _this2 = this;

      return Ember.$(window).on("click.".concat(this.get('elementId')), function (event) {
        Ember.run(function () {
          if (_this2.get('dismissed')) {
            return;
          }

          var target = Ember.$(event.target);

          if (!target.parents('.group-membership-search').length) {
            _this2.resetSearch();
          }
        });
      });
    }),
    removeOutsideHandler: on('willDestroyElement', function () {
      Ember.$(window).off(".".concat(this.get('elementId')));
    }),
    performSearch: function performSearch(search) {
      var _this3 = this;

      if (!_lodash.default.isEmpty(search.trim())) {
        this.set('loading', true);
        var type = 'CONTAINS';
        var builder = this.get('search').requestBuilder();
        var fields = ['profileSkills', 'department', 'certifications', 'title'];
        builder.addAggregations(['profileSkills', search, 'profileSkills', type, 5], ['department', search, 'department', type, 5], ['certifications', search, 'certifications', type, 5], ['title', search, 'title', type, 5]);
        this.get('search').performCustomSearch(builder).then(function (data) {
          _this3.set('loading', false);

          var results = fields.map(function (field) {
            var aggregates = data[field];
            return aggregates.map(function (aggregate) {
              return {
                field: _service.SEARCH_FACET_KEYPATHS[field],
                value: aggregate.key
              };
            });
          });

          _this3.set('results', _lodash.default.flatten(results));
        });
      }
    },
    resetSearch: function resetSearch() {
      this.set('loading', false);
      this.set('searchValue', '');
      this.set('results', []);
    }
  });

  _exports.default = _default;
});