define("web-directory/components/group-profile/roles-edit/fixtable/item-range/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZIadWWbP",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"totalRows\"]]],null,{\"statements\":[[6,[\"if\"],[[28,[\"isSingleItem\"]]],null,{\"statements\":[[0,\"        \"],[1,[26,[\"firstItemIndex\"]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"        \"],[1,[26,[\"firstItemIndex\"]],false],[1,[26,[\"dash\"]],false],[1,[26,[\"lastItemIndex\"]],false],[0,\"\\n\"]],\"locals\":[]}],[0,\"\\n    \"],[11,\"span\",[]],[15,\"class\",\"text-muted\"],[13],[1,[33,[\"t\"],[\"groups.roles.fixtable.of\"],null],false],[14],[0,\"\\n\\n    \"],[1,[26,[\"totalRows\"]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/group-profile/roles-edit/fixtable/item-range/template.hbs"
    }
  });

  _exports.default = _default;
});