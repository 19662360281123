define("web-directory/services/apps-api-handlers/directory-nav-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SHOW_PROFILE_APP_API_ACTION_NAME = _exports.SHOW_GROUP_APP_API_ACTION_NAME = void 0;
  var RSVP = Ember.RSVP,
      getOwner = Ember.getOwner,
      typeOf = Ember.typeOf,
      inject = Ember.inject;
  var SHOW_PROFILE_APP_API_ACTION_NAME = 'showProfile';
  _exports.SHOW_PROFILE_APP_API_ACTION_NAME = SHOW_PROFILE_APP_API_ACTION_NAME;
  var SHOW_GROUP_APP_API_ACTION_NAME = 'showGroup';
  _exports.SHOW_GROUP_APP_API_ACTION_NAME = SHOW_GROUP_APP_API_ACTION_NAME;
  var DirectoryNavHandler = Ember.Object.extend({
    session: inject.service(),
    routerService: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('routerService', getOwner(this).lookup('router:main'));
    },

    /**
     * Synchronous check to see if this handler is applicable for the specified AppApiCall instance
     *
     * @param {Object} the AppApiCall instance to check if this handler can handle
     *
     * @returns true if the provided AppApiCall instance could be handled by this handler. This check should
     *  be limited to checking if the handler is responsible for this type of AppApiCall rather than if the
     *  payload itself is valid.  If no handler returns true, the error will be that no handler exists which is
     *  a different case than the AppApiCall is invalid. Practically, this means that validation of the payload
     *  should be done in the handle method
     */
    canHandle: function canHandle(appApiCall) {
      return appApiCall.get('action') === SHOW_PROFILE_APP_API_ACTION_NAME || appApiCall.get('action') === SHOW_GROUP_APP_API_ACTION_NAME;
    },
    handle: function handle(appApiCall) {
      if (appApiCall.get('action') === SHOW_PROFILE_APP_API_ACTION_NAME) {
        return this._handleShowProfile(appApiCall);
      } else if (appApiCall.get('action') === SHOW_GROUP_APP_API_ACTION_NAME) {
        return this._handleShowGroup(appApiCall);
      }
    },
    _handleShowProfile: function _handleShowProfile(appApiCall) {
      var _this = this;

      return new RSVP.Promise(function (resolve, reject) {
        var _appApiCall$get = appApiCall.get('actionDetails'),
            profileId = _appApiCall$get.profileId;

        if (!profileId) {
          reject('profileId not found');
          return;
        }

        if (typeOf(profileId) !== 'string') {
          reject('profileId is not a string');
          return;
        }

        var routerService = _this.get('routerService');

        if (!routerService) {
          reject('Handler Error: router not found');
          return;
        }

        routerService.transitionTo('person.detail', profileId);
        return resolve();
      });
    },
    _handleShowGroup: function _handleShowGroup(appApiCall) {
      var _this2 = this;

      return new RSVP.Promise(function (resolve, reject) {
        var _appApiCall$get2 = appApiCall.get('actionDetails'),
            groupId = _appApiCall$get2.groupId;

        if (!groupId) {
          reject('groupId not found');
          return;
        }

        if (typeOf(groupId) !== 'string') {
          reject('groupId is not a string');
          return;
        }

        var routerService = _this2.get('routerService');

        if (!routerService) {
          reject('Handler Error: router not found');
          return;
        }

        routerService.transitionTo('group.detail', groupId);
        return resolve();
      });
    }
  });
  var _default = DirectoryNavHandler;
  _exports.default = _default;
});