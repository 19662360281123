define("web-directory/components/entity-fields/person-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IZImQ/sC",
    "block": "{\"statements\":[[11,\"div\",[]],[16,\"class\",[34,[\"field-value values \",[28,[\"entry\",\"_id\"]]]]],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"person\",\"id\"]]],null,{\"statements\":[[0,\"        \"],[1,[33,[\"person-icon\"],null,[[\"personId\"],[[28,[\"person\",\"id\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},null],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/entity-fields/person-field/template.hbs"
    }
  });

  _exports.default = _default;
});