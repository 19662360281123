define("web-directory/components/email-conversation/attachment-card/component", ["exports", "web-directory/utils/file-extensions-type", "web-directory/utils/acd"], function (_exports, _fileExtensionsType, _acd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['message-attachment-card'],

    /**
     * @attribute
     *
     * The attachment data that this component is in context of.
     */
    attachment: null,

    /**
     * @attribute
     *
     * The attachment type.
     */
    attachmentType: Ember.computed('attachment.type', function () {
      return this.get('attachment.type') && this.get('attachment.type').toLowerCase();
    }),

    /**
     * @attribute
     *
     * `true` if the extension name is an image type.
     */
    isImage: Ember.computed('attachmentType', function () {
      return _fileExtensionsType.imageTypes.includes(this.get('attachmentType'));
    }),

    /**
     * @attribute
     *
     * `true` if the extension name is an audio type.
     */
    isAudio: Ember.computed('attachmentType', function () {
      return _fileExtensionsType.audioTypes.includes(this.get('attachmentType'));
    }),

    /**
     * @attribute
     *
     * `true` if the extension name is a video type.
     */
    isVideo: Ember.computed('attachmentType', function () {
      return _fileExtensionsType.videoTypes.includes(this.get('attachmentType'));
    }),

    /**
     * @attribute
     *
     * `true` if the extension name is a text document type.
     */
    isTextDoc: Ember.computed('attachmentType', function () {
      return _fileExtensionsType.textDocTypes.includes(this.get('attachmentType'));
    }),

    /**
     * @attribute
     *
     * `true` if the extension name is a power point type.
     */
    isPowerPoint: Ember.computed('attachmentType', function () {
      return _fileExtensionsType.powerPointTypes.includes(this.get('attachmentType'));
    }),

    /**
     * @attribute
     *
     * `true` if the extension name is a pdf type.
     */
    isPdf: Ember.computed('attachmentType', function () {
      return _fileExtensionsType.pdfTypes.includes(this.get('attachmentType'));
    }),

    /**
     * @attribute
     *
     * `true` if the extension name is a excel type.
     */
    isExcel: Ember.computed('attachmentType', function () {
      return _fileExtensionsType.excelTypes.includes(this.get('attachmentType'));
    }),

    /**
     * @attribute
     *
     * `true` if the download button should be shown.
     */
    showDownloadAttachmentButton: null,

    /**
     * @attribute
     *
     * `true` if the delete button should be shown.
     */
    showDeleteAttachmentButton: null,

    /**
     * @attribute
     *
     * `true` if the user action should not be interactable while transmitting.
     */
    disableUserActionOnTransmitting: null,

    /**
     * @action
     *
     * Invoked when the user has opted to delete the attachment from within the card.
     * @param {string} attachmentId The attachment id.
     */
    actionDeleteAttachment: function actionDeleteAttachment(attachmentId) {
      return (0, _acd.warnClosureAction)('actionDeleteAttachment', attachmentId);
    },
    actions: {
      onDeleteAttachment: function onDeleteAttachment(attachmentId) {
        this.actionDeleteAttachment(attachmentId);
      }
    }
  });

  _exports.default = _default;
});