define("web-directory/routes/conversations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    routeTitle: function routeTitle() {
      return this.get('intl').lookup('chat.conversations');
    },
    afterModel: function afterModel(model, transition) {
      transition.send('togglePanel', 'conversations');
      transition.send('toggleCommunicationSidebarExpanded');
      return this.transitionTo('index');
    }
  });

  _exports.default = _default;
});