define("web-directory/components/follow-me-settings-panel/stations-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+l1tnmqN",
    "block": "{\"statements\":[[6,[\"each\"],[[28,[\"stations\"]]],null,{\"statements\":[[0,\"    \"],[11,\"li\",[]],[16,\"class\",[34,[\"station-choice\\n            \",[33,[\"if\"],[[33,[\"not\"],[[28,[\"station\",\"available\"]]],null],\"in-use\"],null],\"\\n            \",[33,[\"if\"],[[33,[\"is-selected-call-route\"],[[28,[\"callRoutes\"]],[28,[\"station\"]]],null],\"selected-as-route\"],null]]]],[13],[0,\"\\n        \"],[11,\"a\",[]],[15,\"href\",\"#\"],[16,\"data-name\",[34,[[28,[\"station\",\"name\"]]]]],[5,[\"action\"],[[28,[null]],[28,[\"selectStation\"]],[28,[\"station\"]],[33,[\"is-selected-call-route\"],[[28,[\"callRoutes\"]],[28,[\"station\"]]],null]]],[13],[0,\"\\n            \"],[11,\"span\",[]],[15,\"class\",\"station-name\"],[13],[0,\"\\n                \"],[1,[28,[\"station\",\"name\"]],false],[0,\"\\n            \"],[14],[0,\"\\n\"],[6,[\"if\"],[[33,[\"not\"],[[28,[\"station\",\"available\"]]],null]],null,{\"statements\":[[0,\"                \"],[11,\"span\",[]],[15,\"class\",\"status\"],[13],[0,\"\\n                    \"],[1,[28,[\"station\",\"state\"]],false],[0,\"\\n                \"],[14],[0,\"\\n\"]],\"locals\":[]},null],[0,\"        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[\"station\"]},{\"statements\":[[0,\"    \"],[11,\"li\",[]],[15,\"class\",\"station-choice no-results\"],[13],[0,\"\\n        \"],[1,[33,[\"t\"],[\"search.noResults\"],null],false],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/follow-me-settings-panel/stations-list/template.hbs"
    }
  });

  _exports.default = _default;
});