define("web-directory/components/audit-footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nSFurw06",
    "block": "{\"statements\":[[11,\"div\",[]],[16,\"class\",[34,[\"audit-footer application-scroll\\n        \",[33,[\"unless\"],[[28,[\"auditDrawerOpen\"]],\"audit-peekup\"],null],\"\\n        \",[33,[\"if\"],[[28,[\"showAppChrome\"]],[28,[\"sidebar\",\"communicationOffset\"]]],null]]]],[16,\"title\",[26,[\"auditDrawerTitle\"]],null],[15,\"tabindex\",\"0\"],[15,\"role\",\"button\"],[5,[\"action\"],[[28,[null]],\"toggleAuditDrawer\"]],[13],[0,\"\\n    \"],[11,\"button\",[]],[15,\"class\",\"audit-button\"],[16,\"aria-label\",[26,[\"auditDrawerTitle\"]],null],[15,\"type\",\"button\"],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"auditDrawerOpen\"]]],null,{\"statements\":[[0,\"            \"],[11,\"i\",[]],[15,\"class\",\"fa fa-chevron-down\"],[13],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"            \"],[11,\"i\",[]],[15,\"class\",\"fa fa-chevron-up audit-button-content\"],[13],[14],[0,\"\\n\"]],\"locals\":[]}],[0,\"    \"],[14],[0,\"\\n\\n    \"],[11,\"div\",[]],[16,\"class\",[34,[\"panel-content \",[33,[\"unless\"],[[28,[\"auditDrawerOpen\"]],\"hidden\"],null]]]],[13],[0,\"\\n        \"],[9,\"frame-router\",{\"attrs\":[[16,\"route\",[26,[\"route\"]],null]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n    \"],[14],[0,\"\\n\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/audit-footer/template.hbs"
    }
  });

  _exports.default = _default;
});