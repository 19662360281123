define("web-directory/components/light-gallery-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oNqgs8WS",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"showing\"]]],null,{\"statements\":[[6,[\"modal-component\"],null,[[\"confirmText\",\"cancel\",\"headerText\",\"cancelText\"],[null,\"cancel\",[33,[\"t\"],[\"person.modals.viewPictures\"],null],[33,[\"t\"],[\"common.close\"],null]]],{\"statements\":[[0,\"        \"],[11,\"div\",[]],[15,\"class\",\"light-gallery-modal\"],[13],[0,\"\\n            \"],[11,\"ul\",[]],[15,\"id\",\"lightGallery\"],[15,\"class\",\"row\"],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"gallery\"]]],null,{\"statements\":[[0,\"                    \"],[11,\"li\",[]],[16,\"data-src\",[28,[\"image\",\"value\",\"x1024\"]],null],[13],[0,\"\\n                        \"],[11,\"img\",[]],[16,\"alt\",[33,[\"t\"],[\"images.userSubmitted\"],null],null],[16,\"src\",[28,[\"image\",\"value\",\"x1024\"]],null],[13],[14],[0,\"\\n                    \"],[14],[0,\"\\n\"]],\"locals\":[\"image\"]},null],[0,\"            \"],[14],[0,\"\\n        \"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/light-gallery-modal/template.hbs"
    }
  });

  _exports.default = _default;
});