define("web-directory/mixins/evaluations", ["exports", "web-directory/models/evaluation", "web-directory/utils/dates"], function (_exports, _evaluation, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EvaluationsMixin = Ember.Mixin.create({
    application: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    evaluationsAjax: Ember.$.ajax,
    getEvaluations: function getEvaluations() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        var promises = [];
        var agentEvaluations = [];
        var evaluatorEvaluations = [];
        var assigneeEvaluations = [];
        var payload = {
          agentId: _this.get('session.person.guid'),
          evaluationState: ['Pending', 'InProgress'],
          userType: 'evaluator'
        };

        var isAssigneeFeatureEnabled = _this.get('session.features') && _this.get('session.features')['qm.release.evaluation.assignee'];

        var isDisputesFeatureEnabled = _this.get('session.features') && _this.get('session.features')['qm.release.evaluation.disputes'];

        var evaluatorUri = "api/v2/quality/evaluations/query?";

        if (isAssigneeFeatureEnabled) {
          evaluatorUri = evaluatorUri + "expand=agent" + "&expand=assignee" + "&evaluatorUserId=".concat(payload.agentId) + "&evaluationState=Pending" + "&evaluationState=InProgress" + "&pageSize=25";
        } else {
          evaluatorUri = evaluatorUri + "expand=agent" + "&evaluatorUserId=".concat(payload.agentId) + "&evaluationState=Pending" + "&evaluationState=InProgress" + "&pageSize=25";
        }

        var agentFinishedUri = "api/v2/quality/evaluations/query?" + "agentUserId=".concat(payload.agentId) + "&isReleased=true" + "&agentHasRead=false" + "&evaluationState=Finished" + "&pageSize=25";
        var agentInReviewUri = "api/v2/quality/evaluations/query?" + "agentUserId=".concat(payload.agentId) + "&isReleased=true" + "&evaluationState=InReview" + "&pageSize=25";
        var assigneeUri = "api/v2/quality/evaluations/query?" + "expand=agent" + "&expand=assignee" + "&assigneeUserId=".concat(payload.agentId) + "&pageSize=25";
        var startTime = (0, _dates.default)().add(-1, 'month').toISOString();
        agentFinishedUri = agentFinishedUri + "&startTime=".concat(startTime);
        agentInReviewUri = agentInReviewUri + "&startTime=".concat(startTime);
        evaluatorUri = evaluatorUri + "&startTime=".concat(startTime);
        assigneeUri = assigneeUri + "&startTime=".concat(startTime);
        var evaluatorRequest = {
          url: _this.get('application').pcUri(evaluatorUri),
          cache: false,
          method: 'GET',
          contentType: 'application/json; charset=UTF-8'
        };
        var requestAgentFinished = {
          url: _this.get('application').pcUri(agentFinishedUri),
          cache: false,
          method: 'GET',
          contentType: 'application/json; charset=UTF-8'
        };
        var requestAgentInReview = {
          url: _this.get('application').pcUri(agentInReviewUri),
          cache: false,
          method: 'GET',
          contentType: 'application/json; charset=UTF-8'
        };
        var requestAssignee = {
          url: _this.get('application').pcUri(assigneeUri),
          cache: false,
          method: 'GET',
          contentType: 'application/json; charset=UTF-8'
        };

        var locale = _this.get('intl.isoLocale');

        promises.push(_this.get('evaluationsAjax')(evaluatorRequest).then(function (_ref) {
          var _ref$entities = _ref.entities,
              entities = _ref$entities === void 0 ? [] : _ref$entities;
          evaluatorEvaluations.push(entities.map(function (evaluation) {
            if (evaluation) {
              evaluation.changedDate = isAssigneeFeatureEnabled && !evaluation.calibration ? evaluation.dateAssigneeChanged || evaluation.assignedDate : evaluation.assignedDate;
              evaluation.assignedDate = evaluation.assignedDate && (0, _dates.default)(evaluation.assignedDate).locale(locale).format('LLLL');
              evaluation.linkContext = 'admin';
            }

            return _evaluation.default.create(evaluation);
          }));
        }));
        promises.push(_this.get('evaluationsAjax')(requestAgentFinished).then(function (_ref2) {
          var _ref2$entities = _ref2.entities,
              entities = _ref2$entities === void 0 ? [] : _ref2$entities;
          agentEvaluations.push(entities.map(function (evaluation) {
            if (evaluation) {
              evaluation.changedDate = evaluation.releaseDate;
              evaluation.assignedDate = evaluation.assignedDate && (0, _dates.default)(evaluation.assignedDate).locale(locale).format('LLLL');
              evaluation.linkContext = 'agent';
            }

            return _evaluation.default.create(evaluation);
          }));
        }));

        if (isAssigneeFeatureEnabled) {
          promises.push(_this.get('evaluationsAjax')(requestAssignee).then(function (_ref3) {
            var _ref3$entities = _ref3.entities,
                entities = _ref3$entities === void 0 ? [] : _ref3$entities;
            assigneeEvaluations.push(entities.map(function (evaluation) {
              if (evaluation) {
                evaluation.changedDate = evaluation.dateAssigneeChanged;
                evaluation.assignedDate = evaluation.assignedDate && (0, _dates.default)(evaluation.assignedDate).locale(locale).format('LLLL');
                evaluation.linkContext = 'admin';
              }

              return _evaluation.default.create(evaluation);
            }));
          }));
        }

        if (isDisputesFeatureEnabled) {
          promises.push(_this.get('evaluationsAjax')(requestAgentInReview).then(function (_ref4) {
            var _ref4$entities = _ref4.entities,
                entities = _ref4$entities === void 0 ? [] : _ref4$entities;
            agentEvaluations.push(entities.map(function (evaluation) {
              if (evaluation) {
                evaluation.changedDate = evaluation.releaseDate;
                evaluation.assignedDate = evaluation.assignedDate && (0, _dates.default)(evaluation.assignedDate).locale(locale).format('LLLL');
                evaluation.linkContext = 'agent';
              }

              return _evaluation.default.create(evaluation);
            }));
          }));
        }

        var evaluationIdSet = {};
        Ember.RSVP.all(promises).then(function () {
          // It is important that the results are processed this way: evaluator results first, then assignee, and then agent
          // That allows the de-duplication to use the most relevant of the results first and reject the same evaluation
          // from less relevant queries
          var resultData = evaluatorEvaluations.concat(assigneeEvaluations, agentEvaluations).reduce(function (totalResult, queryResult) {
            return totalResult.concat(queryResult.reduce(function (result, evaluation) {
              if (!evaluationIdSet[evaluation.id]) {
                evaluationIdSet[evaluation.id] = true;
                result.push(evaluation);
              }

              return result;
            }, []));
          }, []);
          resolve(resultData); //resolve the promise when all inner promises are resolved
        });
      });
    },
    readEvaluation: function readEvaluation(evaluation) {
      var evaluationIsAdminContext = evaluation && evaluation.linkContext && evaluation.linkContext.toUpperCase() === 'ADMIN';
      return window.location.hash = "#/engage/".concat(evaluationIsAdminContext ? 'admin' : 'agent', "/interactions/").concat(evaluation.conversation.id, "/?evaluationId=").concat(evaluation.id);
    }
  });
  var _default = EvaluationsMixin;
  _exports.default = _default;
});