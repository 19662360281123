define("web-directory/components/acd-interactions-panel/participant-name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R3X4vS6V",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"name\"]]],null,{\"statements\":[[0,\"    \"],[11,\"span\",[]],[16,\"aria-label\",[34,[[26,[\"name\"]]]]],[13],[0,\"\\n        \"],[1,[26,[\"name\"]],false],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"    \"],[11,\"span\",[]],[16,\"aria-label\",[34,[[33,[\"t\"],[\"purecloud.acdInteraction.noName\"],null]]]],[13],[0,\"\\n        \"],[1,[33,[\"t\"],[\"purecloud.acdInteraction.noName\"],null],false],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/acd-interactions-panel/participant-name/template.hbs"
    }
  });

  _exports.default = _default;
});