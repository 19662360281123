define("web-directory/mixins/interaction/session-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed; // ==========================================================
  // NOTICE: This mixin is intended to be consumed by the
  // Interaction component. It inherits servies injects and
  // dependencies from the parent component.
  // ==========================================================
  //
  // Rules for showing/hiding/disabling session share button
  //
  // 1. If the agent has session share permissions then the
  // session share button should be visible (it may be disabled
  // when end customer's browser cannot support screen share).
  //
  // 2. If the agent has session share permissions and the end
  // user is using Firefox - button is enabled.
  //
  // 3. If the agent has session share permissions and the end
  // user is using Chrome AND has the screen share extension
  // installed - button is enabled.
  //
  // 4. If the agent has session share permissions and the end
  // user is using any browser except Firefox or Chrome the
  // screen share button is disabled with tooltip.
  //
  // 5. If the agent has session share permissions and the end
  // user is using Chrome but does NOT have the screen share
  // extension, button is disabled with tooltip.
  //
  // ==========================================================

  var _default = Ember.Mixin.create({
    // Configuration
    customerBrowserDetails: computed('interaction.customerDetails.{isChrome,isFirefox,isEdgeChromium,hasChromeExtension,cobrowseType,standAloneApplication,canScreenshare,canCobrowse}', function () {
      var customerDetails = this.get('interaction.customerDetails');

      if (!customerDetails) {
        return {};
      } // because the object we get has stringified the boolean values, need to convert these to true and false


      var isChrome = Ember.get(customerDetails, 'isChrome') === 'true' ? true : false;
      var isFirefox = Ember.get(customerDetails, 'isFirefox') === 'true' ? true : false;
      var isEdgeChromium = Ember.get(customerDetails, 'isEdgeChromium') === 'true' ? true : false;
      var hasChromeExtension = Ember.get(customerDetails, 'hasChromeExtension') === 'true' ? true : false;
      var isStandAloneApplication = Ember.get(customerDetails, 'standAloneApplication') === 'true' ? true : false;
      var canScreenshare = Ember.get(customerDetails, 'canScreenshare') === 'true' ? true : false;
      var canCobrowse = Ember.get(customerDetails, 'canCobrowse') === 'true' ? true : false;
      var cobrowseType = Ember.get(customerDetails, 'cobrowseType') || canCobrowse ? true : false;
      return {
        isChrome: isChrome,
        isFirefox: isFirefox,
        isEdgeChromium: isEdgeChromium,
        hasChromeExtension: hasChromeExtension,
        cobrowseType: cobrowseType,
        isStandAloneApplication: isStandAloneApplication,
        canScreenshare: canScreenshare,
        canCobrowse: canCobrowse
      };
    }),
    isCustomerConfiguredForCobrowse: computed.or('customerBrowserDetails.cobrowseType', 'customerBrowserDetails.canCobrowse'),
    isCustomerConfiguredForScreenShare: computed('interaction.isV2Provider', 'customerBrowserDetails.{isChrome,isFirefox,isEdgeChromium,hasChromeExtension,isStandAloneApplication}', function () {
      // screenshare is not supported for v2 providers
      if (this.get('interaction.isV2Provider')) {
        return false;
      }

      var customerBrowserDetails = this.get('customerBrowserDetails');

      if (Ember.get(customerBrowserDetails, 'canScreenshare') || Ember.get(customerBrowserDetails, 'isFirefox') || Ember.get(customerBrowserDetails, 'isChrome') && Ember.get(customerBrowserDetails, 'hasChromeExtension') || Ember.get(customerBrowserDetails, 'isEdgeChromium') || Ember.get(customerBrowserDetails, 'isStandAloneApplication')) {
        return true;
      }

      return false;
    }),
    screenShareMisconfiguration: computed('isCustomerConfiguredForScreenShare', 'isAgentAllowedToScreenShare', function () {
      return this.get('isCustomerConfiguredForScreenShare') === !this.get('isAgentAllowedToScreenShare');
    }),
    cobrowseMisconfiguration: computed('isCustomerConfiguredForCobrowse', 'isAgentAllowedToCobrowse', function () {
      return this.get('isCustomerConfiguredForCobrowse') === !this.get('isAgentAllowedToCobrowse');
    }),
    // Permissions & Feature Toggles
    canCobrowseChat: computed.reads('permissions.canViewAcdCoBrowseForChat'),
    canCobrowseCall: computed.reads('permissions.canViewAcdCoBrowseForCall'),
    isAgentAllowedToCobrowseCall: computed.and('isCall', 'canCobrowseCall'),
    isAgentAllowedToCobrowseChat: computed.and('isChat', 'canCobrowseChat'),
    isAgentAllowedToCobrowse: computed.or('isAgentAllowedToCobrowseCall', 'isAgentAllowedToCobrowseChat'),
    canScreenShare: computed.reads('permissions.canViewAcdScreenShare'),
    isAgentAllowedToScreenShareCall: computed.and('isCall', 'canScreenShare'),
    isAgentAllowedToScreenShareChat: computed.and('isChat', 'canScreenShare'),
    isAgentAllowedToScreenShare: computed.or('isAgentAllowedToScreenShareCall', 'isAgentAllowedToScreenShareChat'),
    // Tooltips
    sessionShareStartVideoTooltip: computed('webrtc.activeBridge', function () {
      if (!this.get('webrtc.activeBridge')) {
        return 'purecloud.acdInteraction.sessionShare.noVideoWarning';
      }

      return 'purecloud.acdInteraction.sessionShare.startVideo';
    }),
    sessionShareStartTooltip: computed('webrtc.{activeBridge,screenShareViaHawk}', 'disableShareButtonReason', 'customerBrowserDetails.isStandAloneApplication', 'interaction.canScreenShare', function () {
      var canScreenShareViaHawk = this.get('interaction.canScreenShare') && this.get('webrtc.screenShareViaHawk');
      /* if we cannot screen share via hawk, we need to check the activeBridge */

      if (!canScreenShareViaHawk && !this.get('webrtc.activeBridge')) {
        return 'purecloud.acdInteraction.sessionShare.noVideoWarning';
      } else if (this.get('disableShareButtonReason') && this.get('isChat')) {
        return this.get('disableShareButtonReason');
      } else if (this.get('customerBrowserDetails.isStandAloneApplication') && this.get('isChat')) {
        return 'purecloud.acdInteraction.sessionShare.customerUsingStandAloneApplication';
      }

      return 'purecloud.acdInteraction.sessionShare.start';
    }),
    webMessagingCobrowseStartTooltip: computed('interaction.cobrowseSessionShareActive', function () {
      if (!this.get('interaction.cobrowseSessionShareActive')) {
        return 'purecloud.acdInteraction.cobrowseNext.startCobrowse';
      }

      return 'purecloud.acdInteraction.sessionShare.screenShareActive';
    }),
    // Session Share State
    isCoBrowse: computed.bool('interaction.isCoBrowse'),
    isRequestingScreenShareOrCoBrowse: computed.alias('interaction.isRequestingScreenShareOrCoBrowse'),
    isRequestingVideo: computed.alias('interaction.isRequestingVideo'),
    isAgentDisconnectedFromSharedSession: computed.reads('interaction.isAgentDisconnectedFromSharedSession'),
    isCustomerDisconnectedFromSharedSession: computed.reads('interaction.isCustomerDisconnectedFromSharedSession'),
    isSessionCodeRequired: computed.alias('interaction.isSessionCodeRequired'),
    // Button Enablement
    disableShareButtonReason: computed('screenShareMisconfiguration', 'cobrowseMisconfiguration', 'customerBrowserDetails.{isChrome,isFirefox,isEdgeChromium,hasChromeExtension,isStandAloneApplication}', function () {
      var customerBrowserDetails = this.get('customerBrowserDetails');
      var isConfigured = this.get('isCustomerConfiguredForCobrowse') || this.get('isCustomerConfiguredForScreenShare') || this.get('customerBrowserDetails.isStandAloneApplication');

      if (!customerBrowserDetails || isConfigured) {
        return false;
      }

      if (this.get('screenShareMisconfiguration')) {
        if (Ember.get(customerBrowserDetails, 'isChrome') && !Ember.get(customerBrowserDetails, 'hasChromeExtension')) {
          return 'purecloud.acdInteraction.sessionShare.error.noChromeExtension';
        }

        if (!Ember.get(customerBrowserDetails, 'isFirefox') && !Ember.get(customerBrowserDetails, 'isChrome') && !Ember.get(customerBrowserDetails, 'isEdgeChromium')) {
          return 'purecloud.acdInteraction.sessionShare.error.notUsingSupportedBrowser';
        }
      } else if (this.get('cobrowseMisconfiguration')) {
        return 'purecloud.acdInteraction.sessionShare.error.notConfiguredForCobrowse';
      }
    }),
    disableVideoButton: computed.bool('interactionService.hasActiveSessionShare'),
    disableShareButton: computed('disableVideoButton', 'isCustomerConfiguredForCobrowse', 'isCustomerConfiguredForScreenShare', 'interaction.isV2Provider', function () {
      if (this.get('interaction.isV2Provider')) {
        return false;
      }

      return this.get('disableVideoButton') || !(this.get('isCustomerConfiguredForCobrowse') || this.get('isCustomerConfiguredForScreenShare'));
    }),
    shouldShowSessionShareButton: computed('interaction.isV2Provider', 'isAgentAllowedToScreenShare', 'isAgentAllowedToCobrowse', 'interaction.myselfIsMonitor', 'interaction.myselfIsCoach', function () {
      // session-share is not supported for v2 widgets or for MCB participants
      if (this.get('interaction.isV2Provider') || this.get('interaction.myselfIsMonitor') || this.get('interaction.myselfIsCoach')) {
        return false;
      } // Show the button if agent has either permission


      return this.get('isAgentAllowedToScreenShare') || this.get('isAgentAllowedToCobrowse');
    }),
    enableAcdSessionShare: computed('isConnected', 'isCall', 'isChat', 'interaction.onConsult', 'isInternalProvider', function () {
      var isInternalProvider = this.get('isInternalProvider');
      return isInternalProvider && (this.get('isCall') || this.get('isChat')) && this.get('isConnected') && !this.get('interaction.onConsult');
    })
  });

  _exports.default = _default;
});