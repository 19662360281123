define("web-directory/components/add-person-search-result/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LgalLKyX",
    "block": "{\"statements\":[[6,[\"entity-image\"],null,[[\"entity\",\"size\",\"class\",\"isLinkable\",\"showPresence\",\"enableMiniCard\"],[[28,[\"result\"]],96,\"roster-avatar\",false,true,false]],{\"statements\":[[0,\"    \"],[1,[33,[\"integration-badges\"],null,[[\"entity\"],[[28,[\"result\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\n\"],[11,\"div\",[]],[15,\"class\",\"info\"],[13],[0,\"\\n\\n    \"],[11,\"ul\",[]],[13],[0,\"\\n        \"],[11,\"li\",[]],[15,\"class\",\"name\"],[13],[1,[28,[\"result\",\"name\"]],false],[14],[0,\"\\n        \"],[11,\"li\",[]],[15,\"class\",\"title\"],[13],[1,[28,[\"result\",\"title\"]],false],[14],[0,\"\\n        \"],[11,\"li\",[]],[15,\"class\",\"department\"],[13],[1,[28,[\"result\",\"department\"]],false],[14],[0,\"\\n        \"],[11,\"li\",[]],[15,\"class\",\"presence\"],[13],[1,[33,[\"presence-label\"],null,[[\"entity\",\"showActivity\"],[[28,[\"result\"]],false]]],false],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[6,[\"if\"],[[28,[\"result\",\"isInRoom\"]]],null,{\"statements\":[[0,\"        \"],[11,\"i\",[]],[15,\"class\",\"pc pc-check\"],[13],[14],[0,\"\\n\"]],\"locals\":[]},null],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/add-person-search-result/template.hbs"
    }
  });

  _exports.default = _default;
});