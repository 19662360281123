define("web-directory/components/icon-bootstrap/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    bootstrapIcons: Ember.computed.reads('session.isAuthenticated')
  });

  _exports.default = _default;
});