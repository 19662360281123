define("web-directory/routes/timeline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    meeting: Ember.inject.service(),
    templateName: 'timeline',
    afterModel: function afterModel() {
      this.set('routeTitle', this.get('intl').lookup('timeline.title') + ' - ' + this.get('session.org.name'));
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('features', this.get('session.features'));
    }
  });

  _exports.default = _default;
});