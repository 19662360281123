define("web-directory/services/side-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Service = Ember.Service;

  var _default = Service.extend({
    template: null,
    templateProps: {},
    panelClasses: null,
    headerText: null,
    confirmText: null,
    cancelText: null,
    showHeader: true,
    showFooter: true,
    cancelAction: null,
    confirmAction: null,
    confirmDisabled: false,
    init: function init() {
      this._super.apply(this, arguments);
    },
    resetSidePanel: function resetSidePanel() {
      this.setProperties({
        template: null,
        templateProps: {},
        panelClasses: null,
        headerText: null,
        confirmText: null,
        cancelText: null,
        showHeader: true,
        showFooter: true,
        cancelAction: null,
        confirmAction: null,
        confirmDisabled: false
      });
    }
  });

  _exports.default = _default;
});