define("web-directory/templates/partials/-no-station-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xaSuJ5dz",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"no-station-notification\"],[13],[0,\"\\n    \"],[11,\"i\",[]],[15,\"class\",\"pc-moon pc-phone-off\"],[13],[14],[0,\"\\n    \"],[11,\"span\",[]],[15,\"class\",\"no-phone\"],[13],[0,\"\\n        \"],[11,\"span\",[]],[15,\"class\",\"no-phone-text\"],[13],[1,[33,[\"t\"],[\"purecloud.noPhoneSelected\"],null],false],[14],[0,\"\\n        \"],[11,\"button\",[]],[15,\"type\",\"button\"],[15,\"class\",\"btn btn-link action-open-station-picker-v2\"],[5,[\"action\"],[[28,[null]],\"openStationPicker\"]],[13],[0,\"\\n            \"],[11,\"span\",[]],[15,\"class\",\"select-text\"],[13],[1,[33,[\"t\"],[\"purecloud.station.change\"],null],false],[14],[0,\"\\n            \"],[11,\"i\",[]],[15,\"class\",\"pc pc-angle-right\"],[13],[14],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/templates/partials/-no-station-notification.hbs"
    }
  });

  _exports.default = _default;
});