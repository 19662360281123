define("web-directory/components/mini-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H+3VC5md",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"col-xs-4 col-sm-4 col-md-4 col-lg-4 profile-pic\"],[13],[0,\"\\n    \"],[1,[33,[\"entity-image\"],null,[[\"entity\",\"size\",\"isLinkable\",\"showPresence\"],[[28,[\"entity\"]],200,false,true]]],false],[0,\"\\n\"],[14],[0,\"\\n\"],[11,\"div\",[]],[15,\"class\",\"col-xs-4 col-sm-8 col-md-8 col-lg-8 profile-general\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"name-row\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"name\"],[13],[0,\"\\n            \"],[11,\"h4\",[]],[13],[1,[28,[\"entity\",\"name\"]],false],[14],[0,\"\\n        \"],[14],[0,\"\\n\"],[6,[\"if\"],[[28,[\"managerCount\"]]],null,{\"statements\":[[0,\"            \"],[11,\"div\",[]],[15,\"class\",\"managers\"],[13],[0,\"(\"],[1,[26,[\"managerCountText\"]],false],[0,\")\"],[14],[0,\"\\n\"]],\"locals\":[]},null],[0,\"    \"],[14],[0,\"\\n\\n    \"],[11,\"div\",[]],[15,\"class\",\"title\"],[13],[1,[28,[\"entity\",\"title\"]],false],[14],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"status\"],[13],[1,[33,[\"entity-status\"],null,[[\"entityId\"],[[28,[\"entity\",\"id\"]]]]],false],[14],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"location\"],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"isEntityLocationToggleEnabled\"]]],null,{\"statements\":[[0,\"            \"],[9,\"entity-location\",{\"attrs\":[[16,\"entityLocationData\",[26,[\"entityLocationData\"]],null]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"            \"],[1,[33,[\"entity-location\"],null,[[\"entity\",\"class\"],[[28,[\"entity\"]],\"entity-location\"]]],false],[0,\"\\n\"]],\"locals\":[]}],[0,\"    \"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/mini-card/template.hbs"
    }
  });

  _exports.default = _default;
});