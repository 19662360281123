define("web-directory/mixins/create-group", ["exports", "lodash", "web-directory/field-config/field-config"], function (_exports, _lodash, _fieldConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      Mixin = Ember.Mixin;

  function newPublicApiGroupData(name, type, vis) {
    return {
      name: name,
      type: type.includes('groups.') ? type.split('.')[1] : type,
      visibility: vis.toLowerCase(),
      rulesVisible: true
    };
  }

  var _default = Mixin.create({
    intl: inject.service(),
    ajax: inject.service(),
    session: inject.service(),
    notification: inject.service(),
    application: inject.service(),
    groupName: null,
    groupType: null,
    groupVisibility: null,
    groupTypeDefault: Ember.computed.reads('newGroupTypeChoices.0'),
    groupVisibilityDefault: Ember.computed.reads('newGroupVisibilityChoices.0'),
    groupMembershipDefault: Ember.computed.reads('newGroupMembershipChoices.0'),
    groupModal: null,
    nameLengthExceeded: false,
    creatingGroup: false,
    createGroup: function createGroup() {
      var _this = this;

      var intl = this.get('intl');
      var name = this.get('groupName');
      var type = this.get('groupType');
      var visibility = this.get('groupVisibility');

      if (!type) {
        type = this.get('groupTypeDefault.value');
      }

      if (!visibility) {
        visibility = this.get('groupVisibilityDefault.value');
      }

      if (visibility.toLowerCase() === 'public') {
        visibility = 'public';
      } else if (visibility.toLowerCase() === 'members-only') {
        visibility = 'members';
      } else {
        visibility = 'owners';
      }

      var data = newPublicApiGroupData(name, type, visibility);
      var request = this.get('ajax').post('/platform/api/v2/groups', {
        data: data
      });
      return request.then(function (data) {
        try {
          _this.setProperties({
            groupName: null,
            groupType: null,
            groupVisibility: null
          });
        } catch (err) {
          Ember.Logger.error('Failed to set properties to null', {
            error: err
          });
        }

        _this.sendAction('cancel');

        _this.bubbleAction({
          action: 'transitionToRoute',
          actionContext: ['group.detail', data.id]
        });
      }).catch(function (err) {
        _this.setProperties({
          groupName: null,
          groupType: null,
          groupVisibility: null
        });

        _this.sendAction('cancel');

        var error = Ember.get(err, 'errors.0.detail');
        var title = intl.lookup('notification.group.errorTitle');
        var message = intl.lookup('notification.group.createError');

        if (Ember.get(error, 'payload.code') === 'DUPLICATE_VALUE') {
          message = intl.lookup('notification.group.duplicate');
        }

        _this.get('notification').error(title, message, {
          hideAfter: 5,
          forceBrowserNotification: true
        });

        Ember.Logger.error('Error creating group:', {
          error: err
        });
      });
    },
    canCreateGroup: Ember.computed('session.person.namedPermissions', 'session.person.isAdmin', function () {
      var permissions = this.get('session.person.namedPermissions');
      var isAdmin = this.get('session.person.isAdmin');

      if (_lodash.default.includes(permissions, 'admin') || isAdmin) {
        return true;
      }

      var createGroup = _lodash.default.includes(permissions, 'group_creation');

      var adminGroup = _lodash.default.includes(permissions, 'group_administration');

      return createGroup || adminGroup;
    }),
    groupTypeI18nLabel: Ember.computed('intl.isoLocale', function () {
      return _fieldConfig.default.getFieldConfig('group', Ember.getOwner(this)).get('general.groupType.i18nLabel');
    }),
    newGroupTypeChoices: Ember.computed('intl.isoLocale', function () {
      var _this2 = this;

      var types = _fieldConfig.default.getFieldConfig('group', Ember.getOwner(this)).get('groupTypes');

      return types.map(function (type) {
        var lookupType = type.replace('.', '_');

        var label = _this2.get('intl').lookup("group_general_groupType_value_".concat(lookupType));

        return {
          value: type,
          label: label
        };
      });
    }),
    groupVisibilityI18nLabel: Ember.computed('intl.isoLocale', function () {
      return _fieldConfig.default.getFieldConfig('group', Ember.getOwner(this)).get('privacy.groupVisibility.i18nLabel');
    }),
    newGroupVisibilityChoices: Ember.computed('intl.isoLocale', function () {
      var _this3 = this;

      var choices = _fieldConfig.default.getFieldConfig('group', Ember.getOwner(this)).getAllowedValues('privacy.groupVisibility');

      return choices.map(function (type) {
        var lookupType = type.replace('.', '_');

        var label = _this3.get('intl').lookup("group_privacy_groupVisibility_value_".concat(lookupType));

        return {
          value: type,
          label: label
        };
      });
    })
  });

  _exports.default = _default;
});