define("web-directory/controllers/engage/embedded", ["exports", "ember-purecloud-components/mixins/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EngageController = Ember.Controller.extend(_logger.default, {
    engageService: Ember.inject.service('engage'),
    // Overridden to preserve hash query parameters.
    transitionToRoute: function transitionToRoute(route) {
      window.location.hash = route;
    },
    // Overridden to preserve hash query parameters.
    replaceRoute: function replaceRoute(route) {
      var search = window.location.search;
      window.location.replace("".concat(window.location.pathname).concat(search, "#").concat(route));
    },
    setEngageActive: function setEngageActive() {
      this.set('engageService.route', this.get('model.engagePath'));
      this.set('engageService.isEngageActive', true);
    },
    setEngageInactive: function setEngageInactive() {
      this.set('engageService.isEngageActive', false);
    },
    navigationEvent: Ember.observer('engageService.navigationRoute', function () {
      var navRoute = this.get('engageService.navigationRoute');

      if (navRoute) {
        var route = engageRouteToCollaborateRoute(navRoute);
        this.logger.debug('Transitioning to', route);
        this.transitionToRoute(route); //see override above

        this.set('engageService.navigationRoute', null);
      }
    }),
    redirectEvent: Ember.observer('engageService.redirectRoute', function () {
      var route = engageRouteToCollaborateRoute(this.get('engageService.redirectRoute'));
      this.replaceRoute(route); //see override above

      this.logger.debug('Redirecting to', route);
    })
  });

  function engageRouteToCollaborateRoute(route) {
    // Content management routes live under their own top-level position in collaborate
    var newRoute = route.replace(/^#contentManagement/, '/content-management'); // Everything else is mapped to the /engage/... routes

    if (!/^\/content-management/.test(newRoute)) {
      newRoute = route.replace(/^#\/?/, '/engage/');
    }

    return newRoute;
  }

  var _default = EngageController;
  _exports.default = _default;
});