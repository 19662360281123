define("web-directory/helpers/format-filesize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      var bytes = params[0];
      var units = ['bytes', 'KB', 'MB', 'GB', 'TB'];

      for (var i = units.length - 1; i >= 0; i--) {
        var converted = bytes / Math.pow(1000, i);

        if (converted >= 1 && converted < 1000) {
          var decimals = i === 0 ? 0 : 1;
          return "".concat(converted.toFixed(decimals), " ").concat(units[i]);
        }
      }
    }
  });

  _exports.default = _default;
});