define("web-directory/components/location-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t8i5FjR+",
    "block": "{\"statements\":[[1,[33,[\"entity-image\"],null,[[\"entity\",\"size\"],[[28,[\"location\"]],200]]],false],[0,\"\\n\\n\"],[11,\"div\",[]],[15,\"class\",\"location-name\"],[13],[0,\"\\n    \"],[11,\"h5\",[]],[13],[1,[28,[\"location\",\"name\"]],false],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/location-display/template.hbs"
    }
  });

  _exports.default = _default;
});