define("web-directory/components/teams-panel/component", ["exports", "web-directory/mixins/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CREATE_MEETING_TIMEOUT = 10000;

  var _default = Ember.Component.extend(_logger.default, {
    classNames: ['teams-panel'],
    application: Ember.inject.service(),
    intl: Ember.inject.service(),
    notification: Ember.inject.service(),
    mediaProviders: Ember.inject.service(),

    /**
     * Corresponds to the frame-router element. Will be used to set up event listeners
     * from the Teams client app. Will be used to push events to the Teams client app.
     */
    frame: null,

    /**
     * A Promise object that when resolves, indicates that a Teams meeting was created.
     */
    createTeamsMeetingDeferred: null,

    /**
     * Timer to determine if the Teams client app has responded fast enough.
     */
    deferredTimer: null,

    /**
     * Route to start the iframe app. This route could be modified for embedded Teams Meetings
     * in the future.
     */
    route: '/',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.setupFrameRouter(true); // set the 'createTeamsMeetingHandler' in the mediaProviders service
      // when this handler is called, it will run the createTeamsMeeting method here

      this.get('mediaProviders').setCreateTeamsMeetingHandler(this.createTeamsMeeting.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      this.get('mediaProviders').removeCreateTeamsMeetingHandler();
    },
    setupFrameRouter: function setupFrameRouter() {
      var addListeners = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var appUrl = this.appUrl();
      var frame = this.element.querySelector('frame-router');
      frame.setupFrames({
        purecloud: {
          url: "".concat(appUrl, "/#/"),
          assignedRoute: '/'
        }
      }, {
        locale: this.get('intl.isoRegionalLocale'),
        hostRootUrl: window.location.origin
      });

      if (addListeners) {
        frame.messaging.addListener('createdTeamsMeeting', Ember.run.bind(this, this.handleCreatedTeamsMeeting));
      }

      this.set('frame', frame);
    },
    appUrl: function appUrl() {
      if (this.get('application').get('runtime').local) {
        return 'https://localhost:5000';
      }

      return "".concat(window.location.origin, "/msteams-integration");
    },
    createTeamsMeeting: function createTeamsMeeting(data) {
      var _this = this;

      // if we already have the createTeamsMeetingDeferred promise set
      // then we can just return that promise
      if (this.get('createTeamsMeetingDeferred')) {
        return this.get('createTeamsMeetingDeferred');
      }

      var frame = this.frame;

      if (frame) {
        var deferred = Ember.RSVP.defer();
        this.set('createTeamsMeetingDeferred', deferred);

        try {
          frame.publish({
            topic: 'createTeamsMeeting',
            payload: data
          });
        } catch (error) {
          this.logger.error('Error publishing to iframe', {
            error: error
          });
          return Ember.RSVP.reject(new Error('component/teams-panel.createTeamsMeeting: Could not publish to the teams frame to create meeting'));
        } // Lets give the frame 10 seconds to respond


        var deferredTimer = window.setTimeout(function () {
          var currentDeferred = _this.get('createTeamsMeetingDeferred');

          if (currentDeferred) {
            _this.set('createTeamsMeetingDeferred', null);

            currentDeferred.reject(new Error('component/teams-panel.createTeamsMeeting: Timed out creating new meeting'));
          }
        }, CREATE_MEETING_TIMEOUT);
        this.set('deferredTimer', deferredTimer);
        return deferred.promise;
      }

      return Ember.RSVP.reject(new Error('component/teams-panel.createTeamsMeeting: Could not find the zoom frame to create meeting'));
    },
    handleCreatedTeamsMeeting: function handleCreatedTeamsMeeting(data) {
      var deferred = this.get('createTeamsMeetingDeferred');
      var deferredTimer = this.get('deferredTimer');

      if (deferred && data && data.payload) {
        this.set('createTeamsMeetingDeferred', null);

        if (data.payload.error) {
          deferred.reject(new Error('component/teams-panel.handleCreatedTeamsMeeting: Error creating meeting inside ms-teams-integration'));
        } else {
          deferred.resolve(data.payload);
        }

        window.clearTimeout(deferredTimer);
      } else {
        throw new Error('component/teams-panel.handleCreatedTeamsMeeting: Could not find the deferred promise to share new meeting info');
      }
    }
  });

  _exports.default = _default;
});