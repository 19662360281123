define("web-directory/components/entity-field-section/component", ["exports", "ember-purecloud-components/mixins/logger", "lodash/curry", "lodash/groupBy", "lodash/isEmpty", "lodash/map", "lodash/reject", "web-directory/utils/focus-management", "web-directory/utils/masonry", "web-directory/components/entity-profile/profiles/field-data", "web-directory/components/entity-profile/profiles/profile"], function (_exports, _logger, _curry, _groupBy, _isEmpty, _map, _reject, _focusManagement, _masonry, _fieldData, _profile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var on = Ember.on,
      run = Ember.run,
      inject = Ember.inject,
      getOwner = Ember.getOwner,
      computed = Ember.computed,
      observer = Ember.observer,
      Component = Ember.Component;
  var EXCLUDE_TYPES = ['image', 'reference'];

  function filterExcludedField(excludedFields, field) {
    if (!excludedFields) {
      return true;
    }

    return EXCLUDE_TYPES.indexOf(field.type) === -1 && excludedFields.indexOf(field.key) === -1;
  }

  var _default = Component.extend(_logger.default, {
    classNames: ['entity-field-section', 'content'],
    classNameBindings: ['section.key', 'editing'],
    attributeBindings: ['tabindex:tabindex'],
    tabindex: '-1',
    intl: inject.service(),
    session: inject.service(),
    permissions: inject.service(),
    notification: inject.service(),
    // required
    entity: null,
    sectionInfo: null,
    // public
    hideHeader: false,
    editingProfile: false,
    scrollOnAddNew: true,
    excludedFields: [],
    excludedEditingFields: [],
    attrs: {
      entity: null,
      sectionInfo: null,
      hideHeader: false,
      editingProfile: false,
      excludedFields: [],
      excludedEditingFields: [],
      removeSection: function removeSection() {}
    },
    section: computed.reads('sectionInfo.section'),
    sectionName: computed.reads('section.sectionName'),
    sectionKey: computed.reads('sectionInfo.section.key'),
    editingSection: computed.alias('sectionInfo.editingSection'),
    editing: computed.and('editingSection', 'editingProfile'),
    // others
    canViewChat: computed.reads('permissions.canViewBusinessChat'),
    canViewVideo: computed.reads('permissions.canViewCollaborateVideo'),
    saving: false,
    showModal: false,
    cancelling: false,
    hideEmptyValues: true,
    showInstructionText: false,
    disableAddValueDropdown: false,
    showRemoveSectionModal: false,
    fallbackToEmptyLabel: false,
    hideFooterRemoveButton: false,
    timer: null,
    // legacy support
    isEditing: computed.alias('editing'),
    actions: {
      toggleInstructionText: function toggleInstructionText() {
        this.toggleProperty('showInstructionText');
      },
      editSection: function editSection() {
        this.set('editingSection', true);
        this.updateMasonry();

        if (this.get('hideFooterRemoveButton')) {
          return;
        }

        var key = this.get('section.key');

        if (this.get('profile.writeableSections').includes(key)) {
          var readableFields = this.get('profile').getReadableFieldPathsForSection(this.get('section'));
          var writeableFields = this.get('profile').getEditableFieldPathsForSection(this.get('section'));
          this.set('hideFooterRemoveButton', readableFields.length !== writeableFields.length);
        }
      },
      saveSection: function saveSection() {
        var _this = this;

        this.saveSection().then(function () {
          _this.set('editingSection', false);

          _this.updateMasonry();
        }).catch(function (err) {
          _this.logger.error('Error saving field section:', {
            err: err
          });
        }).finally(function () {
          _this.set('saving', false);

          Ember.run.once(_this, 'reconcileNewFields');
          var focusTarget = '.add-section button:first';
          (0, _focusManagement.doFocus)(Ember, focusTarget);
        });
      },
      cancel: function cancel() {
        var _this2 = this;

        this.set('cancelling', true);
        this.reset().finally(Ember.run.bind(this, function () {
          if (_this2.isDestroyed || _this2.isDestroying) {
            return;
          }

          _this2.set('editingSection', false);

          _this2.set('cancelling', false);

          if (_this2.cancelEditElocation) {
            _this2.cancelEditElocation();
          }

          Ember.run.scheduleOnce('afterRender', _this2, function () {
            if (this.isDestroyed || this.isDestroying) {
              return;
            }

            var top = this.$().position().top;
            Ember.$('#orgspan').animate({
              scrollTop: top
            }, 600);
            this.updateMasonry();
            var focusTarget = '.add-section button:first';
            (0, _focusManagement.doFocus)(Ember, focusTarget);
          });
        }));
      },
      addNewEntry: function addNewEntry(field) {
        var entity = this.get('entity');
        var path = field.get('fieldPath');
        entity.set(path, '');
        var valueReference = [].concat(entity.get(path)).pop();

        var fieldData = _fieldData.default.create({
          entity: entity,
          field: field,
          valueReference: valueReference,
          session: this.get('session')
        });

        this.get('fieldData').pushObject(fieldData);
        this.get('_newFields').pushObject(fieldData);
        this.notifyPropertyChange('groupedFieldData');

        if (this.get('scrollOnAddNew')) {
          Ember.run.scheduleOnce('afterRender', this, this.scrollToNewField, fieldData);
        }

        this.updateMasonry();
      },
      removeEntry: function removeEntry(fieldData) {
        this.removeEntryFromFieldData(fieldData);
      },
      removeContactSection: function removeContactSection(fieldData) {
        this.removeEntryFromFieldData(fieldData);
      },
      confirmRemoveSection: function confirmRemoveSection() {
        this.set('showRemoveSectionModal', true);
        var focusTarget = '.remove-section-confirmation button:first';
        (0, _focusManagement.doFocus)(Ember, focusTarget);
      },
      cancelRemoveSection: function cancelRemoveSection() {
        this.set('showRemoveSectionModal', false);
        var focusTarget = '.add-section button:first';
        (0, _focusManagement.doFocus)(Ember, focusTarget);
      },
      removeSection: function removeSection() {
        var _this3 = this;

        var entity = this.get('entity');
        var fieldList = this.get('section.fieldList');
        fieldList.forEach(function (field) {
          var path = field.get('fieldPath');

          if (entity.get(path) && !field.get('required')) {
            entity.set(path, [], {
              overwrite: true
            });
          }
        });
        return this.saveSection().then(function () {
          _this3.set('sectionInfo.adding', false);

          _this3.set('sectionInfo.editingSection', false);

          if ((0, _isEmpty.default)(fieldList.filterBy('required'))) {
            _this3.attrs.removeSection(_this3.get('sectionInfo'));
          }

          _this3.send('cancelRemoveSection');
        });
      },
      toggleAddValue: function toggleAddValue() {}
    },
    removeEntryFromFieldData: function removeEntryFromFieldData(fieldData) {
      var entity = this.get('entity');
      var field = fieldData.get('field');
      var entry = fieldData.get('entry');
      var path = field.getFieldPath();
      this.get('fieldData').removeObject(fieldData);
      this.get('_newFields').removeObject(fieldData);
      entity.getFieldValue(path).removeObject(entry);
      this.updateMasonry();
    },
    _groupedFieldData: null,
    _newFields: null,
    init: function init() {
      var entity = this.get('entity');
      var section = this.get('section'); // Ensure section exists

      if (section && section.key) {
        if (entity.get(section.key) === undefined) {
          entity.set(section.key, {});
        }

        var sectionData = entity.get(section.key);
        var fieldPath = this.get('field') && this.get('field.key');

        if (fieldPath && !Ember.get(sectionData, fieldPath)) {
          var fullPath = "".concat(section.key, ".").concat(fieldPath);
          Ember.run.once(entity, entity.set, fullPath, [], {
            overwrite: true
          });
        }
      }

      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this.timer);
    },
    setupCacheValues: on('init', function () {
      this.setProperties({
        _groupedFieldData: [],
        _newFields: []
      });
    }),
    instructionTextTooltipOnInsert: on('didInsertElement', function () {
      this.timer = run.scheduleOnce('afterRender', this, 'setupInstructionText');
    }),
    isViewMode: computed.not('editingProfile'),
    ownProfile: computed('entity.id', 'session.person.id', function () {
      return this.get('entity.id') === this.get('session.person.id');
    }),
    profile: computed('entity.id', function () {
      var entity = this.get('entity');
      return _profile.default.getProfileForModel(entity, getOwner(this));
    }),
    filteredFields: computed('entity.id', 'section', 'excludedFields.[]', 'profile.readableSections.[]', function () {
      var _this4 = this;

      var section = this.get('section');
      var fieldList = section.get('fieldList');
      var isSuper = this.get('session.user.isAdmin') && !this.get('session.user.personId');
      var fields = [],
          filteredFields = [];
      var excludedFields = this.get('excludedFields');
      var excludedFieldsFilter = (0, _curry.default)(filterExcludedField)(excludedFields);

      if (isSuper) {
        filteredFields = fieldList.filter(excludedFieldsFilter);
      } else {
        var readableFieldPaths = this.get('profile').getReadableFieldPathsForSection(section);
        filteredFields = fieldList.filter(excludedFieldsFilter).filter(function (field) {
          var section = _this4.get('section');

          var fieldPath = field.get('fieldPath');

          if (!section) {
            return false;
          }

          return readableFieldPaths.indexOf(fieldPath) !== -1;
        }).filter(function (field) {
          return field.type !== 'group';
        });
      }

      fields.addObjects(filteredFields);
      return fields;
    }),
    fieldData: computed('entity.id', 'filteredFields.[]', 'editingSection', '_newFields.[]', function () {
      var filteredFields = this.get('filteredFields');
      return this.getFieldEntries(filteredFields);
    }),
    groupedFieldData: computed('fieldData.[]', 'fieldData.length', function () {
      var fieldData = this.get('fieldData');
      var groupedData = (0, _groupBy.default)(fieldData, function (data) {
        return data.get('field.key');
      });
      return (0, _map.default)(groupedData, function (data, key) {
        return {
          key: key,
          fieldData: data
        };
      });
    }),
    instructionText: computed('intl.isoLocale', function () {
      var intl = this.get('intl');
      var section = this.get('section').getI18nKey();
      var key = "".concat(section, "_instructionText");

      if (intl.query(key)) {
        return intl.lookup(key).toString();
      } else {
        return this.get('section.instructionText');
      }
    }),
    fieldValuesToAdd: computed('filteredFields.[]', function () {
      var fields = this.get('filteredFields');
      return fields.filterBy('repeatable');
    }),
    fieldSectionHasData: computed('fields.@each.isEmpty', 'editing', function () {
      if (this.get('editing')) {
        return true;
      }

      var fields = this.get('fieldData') || [];
      return fields.some(function (field) {
        return !field.get('isEmpty');
      });
    }),
    canEditSection: computed('editingProfile', 'entity.id', 'session.user.isAdmin', 'profile.writeableSections.[]', function () {
      var editingProfile = this.get('editingProfile');
      var permissions = this.get('profile.writeableSections');

      if (this.get('session.user.isAdmin') && editingProfile) {
        return true;
      }

      return permissions.indexOf(this.get('section.key')) !== -1 && editingProfile;
    }),
    hideAddNew: computed('section.key', 'section.fieldList', function () {
      var sections = ['location', 'education', 'bio'];
      return !sections.includes(this.get('section.key')) && (0, _isEmpty.default)(this.get('section.fieldList').filterBy('repeatable'));
    }),
    disableSave: computed('entity.type', 'fieldData.@each.{isValid,isEmpty}', function () {
      var fieldData = this.get('fieldData');
      var isGroup = this.get('entity.type') === 'group';
      var validFields = fieldData.map(function (data) {
        var emptyCheck = ['weblink', 'email'];

        if (emptyCheck.includes(data.get('field.type')) && !isGroup) {
          return data.get('isValid') && !data.get('isEmpty');
        }

        return data.get('isValid');
      });
      return (0, _reject.default)(validFields).length > 0;
    }),
    showEditAction: computed('canEditSection', 'editingProfile', 'editingSection', function () {
      var canEditSection = this.get('canEditSection');
      var editingProfile = this.get('editingProfile');
      var editingSection = this.get('editingSection');
      return canEditSection && editingProfile && !editingSection;
    }),
    showHeaderLock: computed('editingProfile', 'canEditSection', function () {
      return this.get('editingProfile') && !this.get('canEditSection');
    }),
    showAddNew: computed('editing', 'hideAddNew', function () {
      return this.get('editing') && !this.get('hideAddNew');
    }),
    onEditingChange: observer('editingProfile', function () {
      Ember.run.once(this, 'handleEditingChange');
    }),
    onValidityChange: observer('disableSave', function () {
      var disableModal = this.get('disableModal');

      if (disableModal) {
        Ember.run.once(this, disableModal, this.get('disableSave'));
      }
    }),
    getFieldEntries: function getFieldEntries(fields) {
      var _this5 = this;

      return fields.map(function (field) {
        var entity = _this5.get('entity');

        var path = field.get('fieldPath');
        var repeatable = Ember.get(field, 'repeatable');
        var values = _this5.get('entity').getFieldValue(path) || [];

        if ((0, _isEmpty.default)(values) && !_this5.get('hideEmptyValues')) {
          entity.set(path, '');
          values = entity.get(path);
        }

        if (repeatable) {
          return values.map(function (valueReference) {
            return _fieldData.default.create({
              entity: entity,
              field: field,
              valueReference: valueReference,
              session: _this5.get('session'),
              permissions: _this5.get('permissions')
            });
          });
        }

        return _fieldData.default.create({
          entity: entity,
          field: field,
          valueReference: values,
          session: _this5.get('session'),
          permissions: _this5.get('permissions')
        });
      }).flat().filter(function (v) {
        return !!v;
      });
    },
    handleEditingChange: function handleEditingChange() {
      this.set('editingSection', false);
    },
    reconcileNewFields: function reconcileNewFields() {
      this.set('_newFields', []);
    },
    saveSection: function saveSection() {
      var _this6 = this;

      var reload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      var intl = this.get('intl');
      var notification = this.get('notification');
      var entity = this.get('entity');
      var section = this.get('section');
      this.set('saving', true);
      return entity.saveSection(section).then(function () {
        if (reload) {
          entity.reload();
        }

        if (Ember.get(section, 'key') === 'contactInfo' && _this6.get('session.features.emergencyLocation')) {
          return;
        }

        var title = intl.lookup('notification.titles.profileSave');
        var message = intl.lookup('notification.fieldSection.saved');
        notification.info(title, message, {
          markdownToHtml: false,
          tag: 'profile'
        });
      }).catch(function (response) {
        var errors = response.errors || response.payload;
        var error = !(0, _isEmpty.default)(errors) && errors[0];

        _this6.set('saving', false);

        if (!error) {
          return Ember.RSVP.reject(response);
        }

        if (error.payload && error.payload.code === 'VERSION_MISMATCH') {
          return Ember.RSVP.resolve();
        }

        var statusCode = error.code || error.status;

        if (statusCode === 500 || statusCode === 400) {
          var errorTitle = intl.lookup('notification.titles.profileSaveError');
          var errorMessage = error && error.detail ? error.detail : intl.lookup('notification.fieldSection.error');
          var title = error.title || error.details && error.details.title;

          if (title) {
            // Check if its a duplicate error and let the user know
            var regex = /Duplicate value (?:.*) (.*)=[\S]+/;
            var match = title.match(regex);

            if (match && match[1]) {
              var field = match[1];

              if (field === 'contactInfo.email_main') {
                errorMessage = intl.lookup('notification.fieldSection.duplicateEmailError');
              }
            }
          }

          notification.error(errorTitle, errorMessage, {
            markdownToHtml: false,
            tag: 'profile'
          });

          _this6.reset();
        }

        return Ember.RSVP.reject(response);
      });
    },
    reset: function reset() {
      var _this7 = this;

      return this.get('entity').reload().then(function () {
        _this7.notifyPropertyChange('section');

        var entity = _this7.get('entity');

        var fieldPaths = _this7.get('section.fieldList').mapBy('fieldPath');

        fieldPaths.forEach(function (path) {
          var currentValue = entity.getFieldValue(path);

          if (Array.isArray(currentValue)) {
            var tempValues = currentValue.filter(function (value) {
              return !!value.tempId;
            });

            if (tempValues.length && currentValue.length) {
              entity.set(path, [], {
                overwrite: true
              });
            } else if (tempValues.length) {
              tempValues.forEach(function (value) {
                currentValue.removeObject(value);
              });
            }
          } else if (currentValue.tempId && !currentValue._id) {
            entity.set(path, [], {
              overwrite: true
            });
          }
        });
      });
    },
    scrollToNewField: function scrollToNewField(fieldData) {
      var elem = this.$().find("[data-field-id=".concat(fieldData.get('fieldId'), "]"));
      var parent = elem.parents('.field-section');

      if (!(0, _isEmpty.default)(elem)) {
        var top = elem.position().top;
        var parentTop = parent.position().top;
        Ember.$('#orgspan').animate({
          scrollTop: top + parentTop
        }, 600);
      }
    },
    setupInstructionText: function setupInstructionText() {
      var text = this.get('instructionText');

      if (text) {
        this.$('.title').tooltip({
          title: text,
          placement: 'right'
        });
      }
    },
    _updateMasonry: function _updateMasonry() {
      (0, _masonry.rerunLayouts)();
    },
    updateMasonry: function updateMasonry() {
      var _this8 = this;

      Ember.run(function () {
        return Ember.run.scheduleOnce('afterRender', _this8, _this8._updateMasonry);
      });
    }
  });

  _exports.default = _default;
});