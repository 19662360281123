define("web-directory/routes/login-oauth", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin", "web-directory/services/session"], function (_exports, _unauthenticatedRouteMixin, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Route = Ember.Route,
      inject = Ember.inject,
      RSVP = Ember.RSVP;
  var OAuth2LoginRoute = Route.extend(_unauthenticatedRouteMixin.default, {
    controllerName: 'login-oauth',
    intl: inject.service(),
    session: inject.service(),
    application: inject.service(),
    purecloudOauth: inject.service(),
    authenticator: null,
    routeTitle: Ember.computed(function () {
      return this.get('intl').lookup('auth.login');
    }),
    actions: {
      mobileOfferDeclined: function mobileOfferDeclined() {
        this.set('application.offerMobileApp', false);
        return Ember.RSVP.resolve();
      },
      authenticate: function authenticate() {
        return this._authenticate(this.modelFor(this.routeName));
      }
    },
    beforeModel: function beforeModel(transition) {
      var hostedContext = this.get('application.hostedContext');

      if (hostedContext.isHosted() && hostedContext.supportsRegions() && !hostedContext.getRegion()) {
        return this.transitionTo('region-chooser');
      }

      if (!this.get('authenticator')) {
        var authenticator = Ember.getOwner(this).lookup('authenticator:directory');

        if (authenticator) {
          this.set('authenticator', authenticator);
        } else {
          Ember.Logger.error("Failed to locate 'authenticator:directory' in Container.");
          this.set('application.errorState', {
            type: 'missingAuthenticator'
          });
          return this.transitionTo('maintenance');
        }
      }

      this._super(transition);

      return this.get('intl').loadTranslations();
    },
    model: function model() {
      return Ember.Object.create(this.get('authenticator').extractAuthData());
    },
    afterModel: function afterModel(resolvedModel) {
      var _this = this;

      if (resolvedModel.access_token) {
        // Returning from Successful Login

        /*
         * Set denied so we don't get reprompted by this route or application
         * The user is either not mobile or previously denied the app before login
         */
        this.set('application.offerMobileApp', false);
        resolvedModel.set('offerMobileApp', this.get('application.offerMobileApp')); // Resolve with the model to show loading screen and init local session

        return RSVP.resolve(resolvedModel);
      } else {
        // Need authentication
        resolvedModel.state = this.get('purecloudOauth').serializeRestoreState();
        var offerMobileApp = this.get('application.offerMobileApp') && !this.get('purecloudOauth.inLogoutBounce');

        if (offerMobileApp) {
          // Resolve with the model to show mobile offer and then auth
          resolvedModel.set('offerMobileApp', true);
          return RSVP.resolve(resolvedModel);
        } else {
          // Return a non-resolving promise so the redirect can complete without a flash of the route
          return new RSVP.Promise(function () {
            _this.set('holdPromiseRef', _this._authenticate(resolvedModel));
          });
        }
      }
    },

    /**
     * Invokes the OAuth authenticator.
     *
     * @returns An promise which will either:
     * <ul>
     * <li>Resolve on authenticator success.  [Note:] ESA will transition on success/failure.</li>
     * <li>Handle the authenticator failure (auth UI, no-access, failure) and reject for callers.  Callers do not need to take any action.</li>
     * </ul>
     */
    _authenticate: function _authenticate(authData) {
      var _this2 = this;

      return new RSVP.Promise(function (resolve, reject) {
        _this2.get('session').authenticate('authenticator:directory', authData).then(resolve).catch(function (rejectionDetails) {
          if (rejectionDetails && rejectionDetails.reason === 'noToken') {
            reject('unauthenticated');
            window.location = rejectionDetails.authUrl;
          } else if (rejectionDetails && rejectionDetails.reason === 'noAccess') {
            // Currently don't distinguish between 400s and 500s in local session.  Assuming our services are ok
            reject('unauthorized');

            _this2.transitionTo('no-access');
          } else if (rejectionDetails && rejectionDetails.reason === _session.SESSION_BOOTSTRAP_ERROR_TYPE) {
            // Bootstrap error will already have been logged
            reject('failure');

            _this2.set('application.errorState', {
              type: _session.SESSION_BOOTSTRAP_ERROR_TYPE,
              failedStep: rejectionDetails.failedStep,
              status: rejectionDetails.status
            });

            _this2.transitionTo('maintenance');
          } else {
            var authFailureReason = rejectionDetails && rejectionDetails.reason ? rejectionDetails.reason : 'authFailure';
            Ember.Logger.error('Authentication Failed:', {
              rejectionDetails: rejectionDetails,
              remoteData: {
                sensitive: {
                  reason: authFailureReason
                }
              }
            });
            reject('failure');

            _this2.set('application.errorState', {
              type: 'generalAuthenticationFailure'
            });

            _this2.transitionTo('maintenance');
          }
        });
      });
    }
  });
  var _default = OAuth2LoginRoute;
  _exports.default = _default;
});