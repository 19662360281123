define("web-directory/components/favorites-panel/favorites-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed,
      inject = Ember.inject;

  var _default = Component.extend({
    classNames: ['favorites-panel'],
    intl: inject.service(),
    favorites: inject.service(),
    presenceSubscription: inject.service(),
    loading: false,
    isTabbed: null,
    entityType: null,
    favoritesList: computed('favorites._favorites.@each.name', 'entityType', function () {
      return this.get('favorites').get('_favorites').filterBy('type', this.get('entityType')).filter(function (model) {
        return !!model.get('name');
      }).sortBy('name');
    }),
    managePresenceSubscription: Ember.observer('isTabbed', 'entityType', 'favoritesList', 'sidebarService.showContactsInbox', function () {
      if (this.get('entityType') === 'person') {
        var people = this.get('isTabbed') && this.get('sidebarService.showContactsInbox') ? this.get('favoritesList') : null;
        this.get('presenceSubscription').autoSubscribe(people, this);
      }
    }),
    emptyStateText: computed('entityType', function () {
      var intl = this.get('intl');
      var entityType = this.get('entityType');

      if (entityType === 'person') {
        return intl.t('emptyResults.peopleFavorites');
      }

      if (entityType === 'group') {
        return intl.t('emptyResults.groupsFavorites');
      }

      return intl.t('emptyResults.favorites');
    })
  });

  _exports.default = _default;
});