define("web-directory/components/resizable-content/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var on = Ember.on,
      observer = Ember.observer,
      Component = Ember.Component;

  var _default = Component.extend({
    classNameBindings: [':resizable-content', 'minimized:minimized:maximized'],
    isMouseDown: false,
    minimized: true,
    minHeight: 0,
    maxHeight: null,
    actions: {
      toggleSize: function toggleSize() {
        this.toggleProperty('minimized');
      }
    },
    attachCurrentHeight: on('didInsertElement', function () {
      this.$().css('min-height', this.get('minHeight'));
    }),
    minimizedChanged: observer('minimized', function () {
      if (this.get('minimized')) {
        this.$().css('min-height', this.get('minHeight'));
      } else {
        var maxHeight = this.get('maxHeight');

        if (!maxHeight) {
          maxHeight = 'calc(100% - 8px)';
          this.set('maxHeight', maxHeight);
        }

        this.$().css('min-height', maxHeight);
      }
    })
  });

  _exports.default = _default;
});