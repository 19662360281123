define("web-directory/components/inbox-panel-v2/shift-trade-requests-v2/component", ["exports", "web-directory/utils/dates"], function (_exports, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['inbox-message', 'shiftTradeRequest-message-v2', 'clickable', 'shiftTradeRequest-message'],
    classNameBindings: ['shiftTradeRequest.markedAsRead:read:unread'],
    hovered: false,
    clock: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    wfm: Ember.inject.service(),
    mouseEnter: function mouseEnter() {
      this.set('hovered', true);
    },
    mouseLeave: function mouseLeave() {
      this.set('hovered', false);
    },
    actions: {
      changeStatus: function changeStatus(status) {
        this.get('wfm').changeShiftTradeRequestReadState(this.get('shiftTradeRequest'), status);
      },
      readAndViewDetails: function readAndViewDetails() {
        this.get('wfm').changeShiftTradeRequestReadState(this.get('shiftTradeRequest'), true);
        this.get('wfm').readShiftTradeRequest(this.get('shiftTradeRequest'));
      },
      handleKeyDown: function handleKeyDown(event) {
        if (event.key !== 'Enter') {
          return;
        }

        this.send('readAndViewDetails');
        event.stopPropagation();
      }
    },
    shiftTrade: Ember.computed('shiftTradeRequest.{shiftTrade,alternativeShift}', function () {
      return this.get('shiftTradeRequest.alternativeShift') ? this.get('shiftTradeRequest.alternativeShift') : this.get('shiftTradeRequest.shiftTrade');
    }),
    initiatingUserId: Ember.computed('shiftTrade', function () {
      return this.get('shiftTrade.initiatingUser.id');
    }),
    initiatingUserName: Ember.computed('initiatingUserId', function () {
      var userGuid = this.get('initiatingUserId');

      if (userGuid && userGuid !== 'System') {
        return this.get('wfm').getAgentNameById(userGuid);
      }

      return null;
    }),
    initiatingDisplayedUserName: Ember.computed('initiatingUserId', function () {
      return this.get('initiatingUserName') || this.get('intl').t('notification.inbox.unknownAgent');
    }),
    receivingUserId: Ember.computed('shiftTrade', function () {
      return this.get('shiftTrade.receivingUser.id');
    }),
    receivingUserName: Ember.computed('receivingUserId', function () {
      var userGuid = this.get('receivingUserId');

      if (userGuid && userGuid !== 'System') {
        return this.get('wfm').getAgentNameById(userGuid);
      }

      return null;
    }),
    receivingDisplayedUserName: Ember.computed('receivingUserId', function () {
      return this.get('receivingUserName') || this.get('intl').t('notification.inbox.unknownAgent');
    }),
    alternativeShiftUserName: Ember.computed('initiatingUserName', 'receivingUserName', function () {
      if (this.get('initiatingUserName')) {
        return this.get('initiatingDisplayedUserName');
      } else if (this.get('receivingUserName')) {
        return this.get('receivingDisplayedUserName');
      }

      return this.get('intl').t('notification.inbox.unknownAgent');
    }),
    areAgentsValid: Ember.computed('initiatingUserName', 'receivingUserName', function () {
      return this.get('initiatingUserName') || this.get('receivingUserName');
    }),
    isMyRequest: Ember.computed('shiftTradeRequest.agentNotification', function () {
      return this.get('shiftTradeRequest.agentNotification');
    }),
    localizedStatus: Ember.computed('shiftTrade', function () {
      var newState = this.get('shiftTrade.newState');
      var shiftTradeRequestStatus = "notification.inbox.shiftTradeRequest.".concat(newState);
      return this.get('intl').t(shiftTradeRequestStatus);
    }),
    shiftDate: Ember.computed('shiftTrade', function () {
      var initiatingShiftDate = this.get('shiftTrade.initiatingShiftDate');
      var date = (0, _dates.default)(initiatingShiftDate);
      var dateFormat = this.get('wfm.isWfmDateFormatEnhancementToggleEnabled') ? this.get('intl').t('notification.inbox.dateShortMonthDayFormat') : this.get('intl').t('notification.inbox.dateRangeMDFormat');
      return date.format(dateFormat);
    }),
    isWeeklyTrade: Ember.computed('shiftTrade.granularity', function () {
      return this.get('shiftTrade.granularity') === 'Weekly';
    }),
    weeklyTradeRange: Ember.computed('isWeeklyTrade', function () {
      if (this.get('isWeeklyTrade')) {
        var weekStart = (0, _dates.default)(this.get('shiftTrade.weekDate'));
        var weekEnd = weekStart.clone().add(1, 'week').subtract(1, 'day');
        var dateFormat = this.get('wfm.isWfmDateFormatEnhancementToggleEnabled') ? this.get('intl').t('notification.inbox.dateShortMonthDayFormat') : this.get('intl').t('notification.inbox.dateRangeMDFormat');

        if (this.get('wfm.isWfmDateFormatEnhancementToggleEnabled')) {
          return this.get('intl').t('components.inbox.dateRange', {
            start: weekStart.format(dateFormat),
            end: weekEnd.format(dateFormat)
          });
        }

        return this.get('intl').t('notification.inbox.timeOffRequest.dateRangeFormat', {
          rangeStart: weekStart.format(dateFormat),
          rangeEnd: weekEnd.format(dateFormat)
        });
      }

      return '';
    }),
    date: Ember.computed('shiftTradeRequest.timestamp', function () {
      var date = (0, _dates.default)(this.get('shiftTradeRequest.timestamp'));
      var locale = this.get('intl.isoLocale');
      return date.locale(locale).format('LL');
    }),
    time: Ember.computed('shiftTradeRequest.timestamp', function () {
      var date = (0, _dates.default)(this.get('shiftTradeRequest.timestamp'));
      var locale = this.get('intl.isoLocale');
      return date.locale(locale).format('LT');
    }),
    dateFromNow: Ember.computed('clock.minute', 'shiftTradeRequest.timestamp', function () {
      var date = (0, _dates.default)(this.get('shiftTradeRequest.timestamp'));
      var locale = this.get('intl.isoLocale');
      return date.locale(locale).fromNow();
    })
  });

  _exports.default = _default;
});