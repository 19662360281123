define("web-directory/routes/admin-entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    adminui: Ember.inject.service(),
    adminAccess: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      // replaceWith for better history preservation
      return Ember.RSVP.resolve(this._super.apply(this, arguments)).then(function () {
        if (transition.isActive) {
          return _this.get('adminui.loadingPromise').then(function () {
            if (_this.get('adminAccess.userHasAdminAccess')) {
              _this.replaceWith('admin', {
                adminPath: 'welcomeV2'
              });
            } else {
              _this.replaceWith('index');
            }
          });
        }
      });
    }
  });

  _exports.default = _default;
});