define("web-directory/components/acd-interactions-panel-v2/interaction-header/component", ["exports", "web-directory/utils/acd-ts"], function (_exports, _acdTs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * The class to be applied to this component upon render
     */
    classNames: ['interaction-header-v2'],

    /**
     * @attribute
     *
     * The type of interaction being rendered into the header
     */
    type: null,

    /**
     * @attribute
     *
     * The subtype of interaction being rendered if applicable
     */
    subtype: null,

    /**
     * @attribute
     *
     * `true` if the interaction has been disconnected. The icon will render differently based on
     * being connected or disconnected.
     */
    isDisconnected: false,

    /**
     * @attribute
     *
     * `true` if the interaction is determined to be apart of a campaign. The icon will change to be
     * of a preview type if this is the case.
     */
    isCampaign: false,

    /**
     * @attribute
     *
     * `true` if the interaction is currently being held
     */
    isHeld: false,

    /**
     * @attribute
     *
     * `true` if the agent is currently be confined by an initiating agent of the interaction. Typically
     * used only for consults
     */
    isConfined: false,

    /**
     * @attribute
     *
     * the state that should take priority for visibility to the user. Typically the user wants to see the
     * state that they are in control over as priority, with the other being the fallback.
     *
     * i.e. if a consulting agent is both held and confined, they want to see that they are held first
     * so they know what they have control over, if they unhold then they will see they are being
     * confined.
     */
    priorityState: 'held',

    /**
     * @attribute
     *
     * `true` if the external campaign warning should be showing in the header to warn users about
     * dialing externally
     */
    showExternalCampaignWarning: false,

    /**
     * @attribute
     *
     * `true` if the bottom container of the header should be displayed. The bottom container
     * shows the interactions duration and hold states. We may not want to show this information
     * for specific interactions such as workitems.
     */
    showBottomContainer: true,

    /**
     * @attribute
     *
     * The name to display within the header showing what interaction the user has selected in this moment
     */
    name: null,

    /**
     * @attribute
     *
     * The tooltip to render when the name has been hovered or focused upon.
     */
    nameTooltip: null,

    /**
     * @attribute
     *
     * The time that the interaction was determined to be in a connected state for the first time
     */
    connectedTime: null,

    /**
     * @attribute
     *
     * The display friendly value of the `connectedTime` above provided by the parent
     */
    connectedTimeDisplay: null,

    /**
     * @attribute
     *
     * The current duration that the interaction has been connected for
     */
    connectedDuration: null,

    /**
     * @attribute
     *
     * `true` if the extra actions for the side panel state should be visible to the user. Typically decided
     * by if the application is in a "popout" state.
     */
    showExtraActions: false,

    /**
     * @action
     *
     * Invoked when the copy action takes place in the header
     */
    actionCopy: function actionCopy() {
      return (0, _acdTs.warnClosureAction)('actionCopy');
    },

    /**
     * @action
     *
     * Invoked when the user decides to close the sidebar entirely using the "hide" action
     */
    actionCloseSidebar: function actionCloseSidebar() {
      return (0, _acdTs.warnClosureAction)('actionCloseSidebar');
    },
    actions: {
      /**
       * Invoked when the user initiates the copy action from the header
       */
      onCopy: function onCopy() {
        this.actionCopy();
      },

      /**
       * Invoked when the user decides to completely close, or hide, the sidebar from the view
       */
      onCloseSidebar: function onCloseSidebar() {
        this.actionCloseSidebar();
      }
    }
  });

  _exports.default = _default;
});