define("web-directory/utils/emergency-location-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var API_ERROR_MESSAGES = {
    userNotAssignedNumber: 'USER_NOT_ASSIGNED_TO_NUMBER',
    e911Support: 'NUMBER_DOES_NOT_SUPPORT_ENHANCED_911',
    invalidCity: 'INVALID_CITY',
    notValidNumber: 'PHONE_NUMBER_NOT_US_OR_CA'
  };

  var getErrorString = function getErrorString(err, defaultMessage) {
    var errorMessage = defaultMessage || 'emergencyLocation.errors.e911LocationSet';

    if (err.payload && err.payload.message) {
      var messageType = err.payload.message;

      switch (messageType) {
        case API_ERROR_MESSAGES.userNotAssignedNumber:
          errorMessage = 'emergencyLocation.errors.userNotAssignedNum';
          break;

        case API_ERROR_MESSAGES.e911Support:
          errorMessage = 'emergencyLocation.errors.e911NotEligible';
          break;

        case API_ERROR_MESSAGES.invalidCity:
          errorMessage = 'emergencyLocation.errors.invalidAddressProvided';
          break;

        case API_ERROR_MESSAGES.notValidNumber:
          errorMessage = 'emergencyLocation.errors.e911NotEligible';
          break;
      }
    }

    return errorMessage;
  };

  var _default = {
    getErrorString: getErrorString
  };
  _exports.default = _default;
});