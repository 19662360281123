define("web-directory/components/entity-fields/validators/history", ["exports", "lodash", "ember-purecloud-components/utils/dates"], function (_exports, _lodash, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    isValid: function isValid(history) {
      if (history && history.value) {
        var startDate = history.value.startDate;
        var endDate = history.value.endDate;
        var haveStartDate = !!(startDate && startDate.month && startDate.year);
        var haveEndDate = !!(endDate && endDate.month && endDate.year);

        if (!haveStartDate && !haveEndDate) {
          return true;
        } else if (!haveStartDate && haveEndDate) {
          return false;
        } else if (haveStartDate && !haveEndDate) {
          return true;
        } else {
          if (startDate.year < endDate.year) {
            return true;
          } else if (startDate.year > endDate.year) {
            return false;
          } else {
            return startDate.month <= endDate.month;
          }
        }
      }

      return history;
    },
    isEmpty: function isEmpty(history) {
      var value = history.value; // only required field is `where`

      var where = Ember.get(value, 'where');
      return where === undefined || _lodash.default.isEmpty(where.trim());
    },
    cleanFieldForEntity: function cleanFieldForEntity(entity, field, entry) {
      var month, year, day;
      var startDate = Ember.get(entry, 'value.startDate');
      var endDate = Ember.get(entry, 'value.endDate');

      if (_dates.default.isMoment(startDate)) {
        startDate = {
          month: Ember.get(entry, 'value.startDate').format('M'),
          year: Ember.get(entry, 'value.startDate').format('YYYY')
        };
      }

      if (_dates.default.isMoment(endDate)) {
        endDate = {
          month: Ember.get(entry, 'value.endDate').format('M'),
          year: Ember.get(entry, 'value.endDate').format('YYYY')
        };
      }

      if (startDate) {
        day = parseInt(startDate.day);
        month = parseInt(startDate.month);
        year = parseInt(startDate.year);

        if (typeof day !== 'number' || isNaN(day)) {
          delete startDate.day;
        }

        if (typeof month !== 'number' || isNaN(month)) {
          delete startDate.month;
        }

        if (typeof year !== 'number' || isNaN(year)) {
          delete startDate.year;
        }
      } else if (startDate === undefined) {
        startDate = {};
      }

      if (endDate) {
        day = parseInt(endDate.day);
        month = parseInt(endDate.month);
        year = parseInt(endDate.year);

        if (typeof day !== 'number' || isNaN(day)) {
          delete endDate.day;
        }

        if (typeof month !== 'number' || isNaN(month)) {
          delete endDate.month;
        }

        if (typeof year !== 'number' || isNaN(year)) {
          delete endDate.year;
        }
      } else if (endDate === undefined) {
        endDate = {};
      }

      if (entry.value) {
        Ember.set(entry, 'value.startDate', startDate);
        Ember.set(entry, 'value.endDate', endDate);
      }
    }
  };
  _exports.default = _default;
});