define("web-directory/components/inbox-panel-v2/share-content/searchbox/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed,
      Component = Ember.Component;
  var entityType = {
    GROUP: 'GROUP',
    USER: 'USER'
  };

  var _default = Component.extend({
    application: inject.service(),
    session: inject.service(),
    intl: inject.service(),
    sharedEntities: null,
    listOfEntities: Ember.A([]),
    _getURL: function _getURL(type) {
      if (type === entityType.USER) {
        return this.get('application').pcV2Uri(['api/v2/users/search']);
      }

      return this.get('application').pcV2Uri(['api/v2/groups/search']);
    },
    _getParams: function _getParams(type, term) {
      var query = {
        pageSize: 30,
        query: [{
          fields: ['name'],
          value: term,
          type: 'STARTS_WITH',
          operator: 'AND'
        }]
      };
      return {
        url: this._getURL(type),
        type: 'POST',
        contentType: 'application/json; charset=UTF-8',
        dataType: 'json',
        data: JSON.stringify(query),
        cache: false
      };
    },
    _query: function _query(query) {
      var _this = this;

      var term = query.term;

      if (term) {
        var promises = {
          users: Ember.$.ajax(this._getParams(entityType.USER, term)),
          groups: Ember.$.ajax(this._getParams(entityType.GROUP, term))
        };
        Ember.RSVP.hash(promises).then(function (hash) {
          var combinedResults = [],
              ids = _lodash.default.map(_this.get('sharedEntities'), 'id');

          var hasUsers = _lodash.default.get(hash, 'users.results.length');

          if (hasUsers) {
            var myId = _this.get('session.person.guid');

            _lodash.default.each(hash.users.results, function (user) {
              if (user.id !== myId && !_lodash.default.find(ids, function (id) {
                return id === user.id;
              })) {
                user.memberType = entityType.USER;
                combinedResults.push(user);
              }
            });
          }

          var hasGroups = _lodash.default.get(hash, 'groups.results.length');

          if (hasGroups) {
            _lodash.default.each(hash.groups.results, function (group) {
              if (!_lodash.default.find(ids, function (id) {
                return id === group.id;
              })) {
                group.memberType = entityType.GROUP;
                combinedResults.push(group);
              }
            });
          }

          query.callback({
            results: combinedResults
          });

          _this.set('listOfEntities', combinedResults);
        });
      }
    },
    select2Options: computed(function () {
      var _this2 = this;

      var intl = this.get('intl');
      return {
        placeholder: intl.lookup('components.inbox.labels.shareModal.searchPlaceholder'),
        query: function query(_query2) {
          return Ember.run.debounce(_this2, _this2._query, _query2, 200);
        },
        formatResult: function formatResult(item) {
          return Ember.Handlebars.Utils.escapeExpression(item.name);
        },
        formatSelection: function formatSelection(item) {
          return Ember.Handlebars.Utils.escapeExpression(item.name);
        },
        formatNoMatches: function formatNoMatches() {
          return intl.t('components.select2.noMatches').toString();
        },
        formatSearching: function formatSearching() {
          return intl.t('components.select2.searching').toString();
        },
        language: {
          searching: function searching() {
            return intl.t('components.select2.searching').toString();
          },
          noResults: function noResults() {
            return intl.t('components.select2.noMatches').toString();
          }
        }
      };
    }),
    didInsertElement: function didInsertElement() {
      var _this3 = this;

      this._super.apply(this, arguments);

      var searchBox = this.$('.select2');
      searchBox.select2(this.get('select2Options'));
      searchBox.on('change', function (e) {
        Ember.run(function () {
          if (e.val) {
            var chosenEntity = _lodash.default.find(_this3.get('listOfEntities'), function (entity) {
              return entity.id === e.val;
            });

            searchBox.select2('val', ''); // clear searchbox after User selects entity

            _this3.sendAction('onSelect', chosenEntity);
          }
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.$('.select2').select2('destroy');
    }
  });

  _exports.default = _default;
});