define("web-directory/serializers/externalcontacts/contact", ["exports", "ember-data", "web-directory/serializers/purecloud", "web-directory/serializers/externalcontacts/external-organization"], function (_exports, _emberData, _purecloud, _externalOrganization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var EMPTY_MODEL_OBJECTS = {
    workPhone: {
      display: '',
      extension: '',
      acceptsSMS: false
    },
    cellPhone: {
      display: '',
      extension: '',
      acceptsSMS: true
    },
    homePhone: {
      display: '',
      extension: '',
      acceptsSMS: false
    },
    otherPhone: {
      display: '',
      extension: '',
      acceptsSMS: false
    },
    address: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
      countryCode: ''
    }
  };
  var EXTERNAL_ORGANIZATION = 'externalOrganization';

  var _default = _purecloud.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      address: {
        embedded: 'always'
      },
      workPhone: {
        embedded: 'always'
      },
      cellPhone: {
        embedded: 'always'
      },
      homePhone: {
        embedded: 'always'
      },
      otherPhone: {
        embedded: 'always'
      },
      externalOrganization: {
        embedded: 'always'
      }
    },
    _normalizeContact: function _normalizeContact(contact) {
      if (contact.type) {
        contact.contactType = contact.type.toLowerCase();
        delete contact.type;
      }

      if (!contact.canonicalContact) {
        contact.canonicalContact = {
          id: contact.id
        };
      }

      if (!contact.mergeSet) {
        contact.mergeSet = [{
          id: contact.id
        }];
      }

      if (!contact.middleName) {
        contact.middleName = '';
      }

      return contact;
    },
    _getPojo: function _getPojo(jsonProfile) {
      return JSON.parse(JSON.stringify(jsonProfile));
    },
    normalizeDeleteRecordResponse: function normalizeDeleteRecordResponse(store, primaryModelClass, payload, id, requestType) {
      payload = {
        id: id,
        type: 'externalcontacts/contact'
      };
      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      var normalizedPayload = this._fillEmptyPropertiesWithModelObjects(store)(payload);

      var response = this._super(store, primaryModelClass, normalizedPayload, id, requestType);

      if (response.data) {
        response.data.data = normalizedPayload;
        response.data.attributes = normalizedPayload;
      }

      return response;
    },
    // move the payload's entities to the root node expected by ember data; prep metadata
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      var filledEntities = (payload.entities || payload.contacts || []).map(this._fillEmptyPropertiesWithModelObjects(store));
      payload = {
        'externalcontacts/contacts': filledEntities,
        meta: {
          total: payload.total || filledEntities.length,
          pageOffset: payload.pageNumber || 1,
          pageSize: payload.pageSize,
          pageCount: payload.pageCount
        }
      };
      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    serialize: function serialize() {
      var jsonProfile = this._super.apply(this, arguments);

      return this._getPojo(jsonProfile);
    },
    _fillEmptyPropertiesWithModelObjects: function _fillEmptyPropertiesWithModelObjects(store) {
      var _this = this;

      return function (payload) {
        var emptyModel = EMPTY_MODEL_OBJECTS;
        var emptyOrgModel = _externalOrganization.EMPTY_ORGANIZATION_MODEL;

        var _iterator = _createForOfIteratorHelper(Object.getOwnPropertyNames(emptyModel)),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var _property = _step.value;

            _this._fillPropertyIfNecessary(_property, payload, emptyModel);
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        if (payload[EXTERNAL_ORGANIZATION]) {
          var organization = payload[EXTERNAL_ORGANIZATION];

          try {
            store.hasRecordForId('externalcontacts/externalOrganization', organization.id);
          } catch (err) {
            var _iterator2 = _createForOfIteratorHelper(Object.getOwnPropertyNames(emptyOrgModel)),
                _step2;

            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var property = _step2.value;

                _this._fillPropertyIfNecessary(property, organization, emptyOrgModel);
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }
          }
        }

        return _this._normalizeContact(payload);
      };
    },

    /**
     * Updates the property value with the pre-filled defaultData if the new data
     * does not contain any values.
     * @param {*} property the property of the data and defaultData to access
     * @param {*} data the new data
     * @param {*} defaultData the original preset data
     */
    _fillPropertyIfNecessary: function _fillPropertyIfNecessary(property, data, defaultData) {
      var payloadValue = data[property]; // We never want to override a populated array value

      if (Array.isArray(payloadValue)) {
        return;
      }

      if (!payloadValue && Array.isArray(defaultData[property])) {
        data[property] = _toConsumableArray(new Set(defaultData[property]));
      } else if (payloadValue === undefined || _typeof(payloadValue) === 'object') {
        data[property] = Object.assign({}, defaultData[property], payloadValue);
      }
    }
  });

  _exports.default = _default;
});