define("web-directory/components/rated-tag/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['rated-tag'],
    tag: null,
    fullStars: Ember.computed('tag.rating', function () {
      return new Array(this.get('tag.rating'));
    }),
    emptyStars: Ember.computed('tag.rating', function () {
      return new Array(5 - this.get('tag.rating'));
    })
  });

  _exports.default = _default;
});