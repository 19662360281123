define("web-directory/routes/card/tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    sidebar: Ember.inject.service(),
    routeTitle: '',
    model: function model(params) {
      this.set('routeTitle', this.get('intl').lookup('components.navMenu.treeView').toString());
      return this.store.find('person', params.person_id);
    }
  });

  _exports.default = _default;
});