define("web-directory/initializers/webrtc-service", ["exports", "ember-webrtc-components/services/webrtc"], function (_exports, _webrtc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'setupWebrtcService',
    after: 'setupChatService',
    initialize: function initialize(application) {
      application.register('service:webrtc', _webrtc.default, {
        singleton: true,
        initialize: true
      });
      application.inject('service:webrtc', 'store', 'service:store');
      application.inject('service:webrtc', 'session', 'service:session');
      application.inject('service:webrtc', 'preferences', 'service:preferences');
      application.inject('service:webrtc', 'analyticsService', 'service:analytics');
      application.inject('service:webrtc', 'applicationService', 'service:application');
      application.inject('service:webrtc', 'phoneService', 'service:phone');
      application.inject('service:webrtc', 'sidebarService', 'service:sidebar');
      application.inject('service:webrtc', 'chatService', 'service:chat');
      application.inject('controller', 'webrtcService', 'service:webrtc');
      application.inject('route', 'webrtcService', 'service:webrtc');
      application.inject('component', 'webrtcService', 'service:webrtc');
    }
  };
  _exports.default = _default;
});