define("web-directory/mixins/auth-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    _privacyStatement: Ember.computed('applicationService.locale', function () {
      return this.get('intl').t('chrome.privacyStatement');
    }),
    _securityDisclaimer: Ember.computed('applicationService.locale', function () {
      return this.get('intl').t('signIn.securityDisclaimer');
    })
  });

  _exports.default = _default;
});