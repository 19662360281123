define("web-directory/services/admin-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    adminui: Ember.inject.service(),
    engage: Ember.inject.service(),
    userHasAdminAccess: Ember.computed('adminui.adminEntries', 'engage.navTree', function () {
      // check for access to any items in admin menu
      var adminAuthorizedEntries = this.get('adminui.adminEntries').filter(function (adminEntry) {
        if (adminEntry.href === 'welcomeV2') {
          // ignore always-present entry for admin dashboard itself
          return false;
        } else if (adminEntry.href) {
          // any other link should give access
          return true;
        } else if (adminEntry.subLinks && adminEntry.subLinks.length) {
          // or any section with subLinks
          return true;
        } else {
          return false;
        }
      });

      if (adminAuthorizedEntries.length) {
        return true;
      } // check for access to any items in engage navTree that show up on admin dashboard


      if (this.get('engage.navTree')) {
        return this.get('engage.navTree').any(function (navTreeItem) {
          if (navTreeItem.subLinks && navTreeItem.subLinks.length) {
            return true;
          }

          if (navTreeItem.textKey === 'admin.nav.architect') {
            return true;
          }

          if (navTreeItem.fromPlaceholder === 'forecasting-component') {
            return true;
          }
        });
      } // default to false


      return false;
    })
  });

  _exports.default = _default;
});