define("web-directory/components/entity-field-section/entity-groups/component", ["exports", "ember-data", "lodash", "web-directory/components/entity-field-section/component"], function (_exports, _emberData, _lodash, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _component.default.extend({
    classNames: ['entity-groups'],
    actions: {
      changeGroups: function changeGroups(group) {
        this.set('showMore', false);
        this.set('activeKey', group);
      },
      toggleMore: function toggleMore() {
        this.toggleProperty('showMore');
      }
    },
    allKeys: null,
    activeKey: null,
    showMore: false,
    flowTabsInline: true,
    init: function init() {
      this._super.apply(this, arguments);

      var entity = this.get('entity');
      var fieldConfig = entity.get('fieldConfig');
      var hasOfficialField = !!fieldConfig.getFieldByKey('groups.official');
      var hasSocialField = !!fieldConfig.getFieldByKey('groups.social');
      var allKeys = [];

      if (hasOfficialField) {
        allKeys.push('official');
      }

      if (hasSocialField) {
        allKeys.push('social');
      }

      var activeKey = 'official';

      if (!hasOfficialField) {
        activeKey = hasSocialField ? 'social' : 'owned';
      }

      this.setProperties({
        allKeys: allKeys,
        activeKey: activeKey,
        hasSocialField: hasSocialField,
        hasOfficialField: hasOfficialField
      });
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var section = this.get('sectionInfo');
      var promises = this.get('allKeys').map(function (key) {
        return _this.getGroups(key);
      });
      Ember.RSVP.all(promises).then(function (results) {
        var groups = _lodash.default.flatten(results);

        section.set('isEmpty', groups.length === 0);
      });
      Ember.run.scheduleOnce('afterRender', this, function () {
        var title = this.$('.title');
        var tabs = this.$('.nav-tabs li');

        if (title && !_lodash.default.isEmpty(tabs)) {
          var titleWidth = title.width();

          var tabWidth = _lodash.default.chain(tabs).map(function (tab) {
            return Ember.$(tab).width();
          }).reduce(function (acc, val) {
            return val + acc;
          }, 0);

          this.set('flowTabsInline', tabWidth + titleWidth < this.$().width());
        }
      });
    },
    canEditSection: computed(function () {
      return false;
    }),
    activeGroups: computed('entity.id', 'activeKey', 'showMore', function () {
      var _this2 = this;

      var promise = this.getGroups(this.get('activeKey')).then(function (groups) {
        if (!_this2.get('showMore')) {
          return _lodash.default.slice(groups, 0, 4);
        }

        return groups;
      });
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    section: computed('entity.fieldConfig.groups', function () {
      return this.get('entity.fieldConfig.groups');
    }),
    shouldShowMore: computed('activeKey', function () {
      var promise = this.getGroups(this.get('activeKey')).then(function (groups) {
        return groups.length > 4;
      });
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    getGroups: function getGroups(key) {
      return this.get("entity.".concat(key, "Groups"));
    }
  });

  _exports.default = _default;
});