define("web-directory/adapters/geolocation-settings", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend({
    application: Ember.inject.service(),
    urlForFindRecord: function urlForFindRecord() {
      return this.get('application').pcV2Uri("api/v2/geolocations/settings");
    }
  });

  _exports.default = _default;
});