define("web-directory/helpers/time-duration", ["exports", "web-directory/utils/dates"], function (_exports, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var run = Ember.run,
      inject = Ember.inject,
      addObserver = Ember.addObserver,
      removeObserver = Ember.removeObserver,
      Helper = Ember.Helper;

  function padZero(num) {
    return num.toString().length === 1 ? "0".concat(num) : num;
  } // clone of duration format


  var _default = Helper.extend({
    clock: inject.service(),
    willDestroy: function willDestroy() {
      removeObserver(this, 'clock.second', this, this._scheduleRecompute);

      this._super.apply(this, arguments);
    },
    compute: function compute(params, _ref) {
      var startTime = _ref.startTime,
          endTime = _ref.endTime,
          durationInMs = _ref.durationInMs;
      var shouldTick = (startTime || endTime) && !durationInMs;
      this.set('startTime', startTime);
      this.set('endTime', endTime);
      this.set('durationInMs', durationInMs);

      if (shouldTick) {
        this._setupObserver();
      }

      return this._formatDuration();
    },
    _formatDuration: function _formatDuration() {
      var startTime = this.get('startTime');
      var endTime = this.get('endTime');
      var durationInMs;

      if (startTime && endTime) {
        durationInMs = endTime.diff(startTime);
      } else if (startTime) {
        durationInMs = (0, _dates.default)().diff(startTime);
      } else if (endTime) {
        durationInMs = endTime.diff((0, _dates.default)());
      } else {
        durationInMs = this.get('durationInMs');
      }

      durationInMs = durationInMs > 0 ? durationInMs : 0; // Do not show negative durations.

      var duration = _dates.default.duration(durationInMs);

      var days = duration.days();
      var hours = duration.hours() + days * 24;
      var minutes = '';

      if (hours) {
        hours = "".concat(hours, ":");
        minutes = duration.minutes() ? "".concat(padZero(duration.minutes()), ":") : '00:';
      } else {
        hours = '';
        minutes = duration.minutes() ? "".concat(duration.minutes(), ":") : '0:';
      }

      var seconds = duration.seconds() ? "".concat(padZero(duration.seconds())) : '00';
      return "".concat(hours).concat(minutes).concat(seconds);
    },
    _setupObserver: function _setupObserver() {
      addObserver(this, 'clock.second', this, this._scheduleRecompute);
      this.get('clock.second'); // start observation
    },
    _scheduleRecompute: function _scheduleRecompute() {
      run.next(this, this.recompute);
    }
  });

  _exports.default = _default;
});