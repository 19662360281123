define("web-directory/components/acd-interactions-panel/interaction-interapptions-pool/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FZEHe9XK",
    "block": "{\"statements\":[[11,\"h2\",[]],[15,\"class\",\"sr-only\"],[13],[1,[28,[\"selectedApp\",\"name\"]],false],[14],[0,\"\\n\\n\"],[11,\"div\",[]],[15,\"class\",\"sub-panel-body\"],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"appPool\"]]],null,{\"statements\":[[0,\"        \"],[1,[33,[\"app-pool\"],null,[[\"appPool\",\"appsSdkEnabled\",\"onAppInstanceApiCall\"],[[28,[\"appPool\"]],true,[33,[\"action\"],[[28,[null]],\"onAppInstanceApiCall\"],null]]]],false],[0,\"\\n\"]],\"locals\":[]},null],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/acd-interactions-panel/interaction-interapptions-pool/template.hbs"
    }
  });

  _exports.default = _default;
});