define("web-directory/components/navigation-sub-menu-v2/component", ["exports", "web-directory/components/navigation-sub-menu-v2/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Determine if a given hash is currently part
   * of the browser's current location. Used to
   * determine if the we should set a sub-link into
   * an visual active state.
   *
   * @param {string} hash
   * @returns {boolean}
   */
  function linkIsActive(hash, location) {
    if (!hash || !location) {
      return false;
    }

    var regexStr = '^' + hash;
    var match = new RegExp(regexStr).test(location);

    if (!match) {
      var RESPONSE_MANAGEMENT = 'responseManagement'; // special case - Response Management has multiple subroutes under the admin UI menu list.
      // When the subroute is run the admin menu remains accessible so that other admin paths can be reached

      if (location.indexOf(RESPONSE_MANAGEMENT) > -1) {
        return hash.indexOf(RESPONSE_MANAGEMENT) > -1;
      }
    }

    return match;
  }

  function generateClassName(str) {
    var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var whitespace = /\s+/g;
    var nonAlpha = /[^A-Za-z-]+/g; // When receiving a SafeString

    str = str.toString();
    var classValue = str.replace(whitespace, '-').replace(nonAlpha, '-').toLowerCase();
    return "".concat(prefix).concat(classValue);
  }

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['navigation-sub-menu-v2'],
    classNameBindings: ['nested:is-nested', 'expanded:is-expanded', 'hasSubMenu:is-visible', 'isHidden:is-hidden'],
    actions: {
      toggleHide: function toggleHide() {
        this.toggleProperty('expanded');
      },
      closePanel: function closePanel() {
        this.onClosePanel();
      }
    },
    onClosePanel: function onClosePanel() {},
    location: '',
    navStructure: [],
    linkTo: null,
    expanded: true,
    nested: false,
    nestedHeader: null,
    subMenu: Ember.computed('navStructure', 'location', 'linkTo', function () {
      var location = this.get('location');
      var navTree = this.get('navStructure');
      var componentLinkTo = this.get('linkTo');

      if (!location || !navTree) {
        return;
      }

      if (this.nested) {
        this.nestedHeader = navTree.text;
      }

      var subNav = [];
      var sublinkTree;
      var hashIndex = location.indexOf('#');

      if (hashIndex >= 0) {
        location = location.slice(hashIndex);
      }

      sublinkTree = this.nested ? [navTree] : (navTree || []).filter(function (navEntry) {
        if (location.indexOf('outboundCampaigns') > -1) {
          // special case - Outbound Campaigns resides under performance,
          // so if that's the view we are on we do not want to show the
          // Outbound Dialing sub menu
          return false;
        } else if (linkIsActive(navEntry.href, location)) {
          return true;
        } else if (navEntry.subLinks) {
          return navEntry.subLinks.some(function (subLink) {
            if (subLink.subLinks) {
              return subLink.subLinks.some(function (sub) {
                return linkIsActive(sub.href, location);
              });
            }

            return linkIsActive(subLink.href, location);
          });
        }

        return false;
      });

      if (sublinkTree && sublinkTree.length > 0 && sublinkTree[0].subLinks) {
        var nav = sublinkTree[0].subLinks.map(function (entry) {
          // If this is a nested menu with only one child, replace this with the child
          if (entry.subLinks && entry.subLinks.length === 1) {
            entry = entry.subLinks[0];
          }

          var path = entry.href || null;

          if (entry.href && !entry.direct) {
            path = entry.href.replace('#', '');
          }

          var navObj = {
            text: entry.text,
            direct: entry.direct,
            // if true, render link with exact href (see above and template)
            path: path,
            route: entry.route,
            externalRoute: entry.externalRoute,
            linkTo: entry.linkTo,
            active: false,
            activeMenu: false,
            className: generateClassName(entry.text, 'sub-menu-'),
            external: entry.external
          };

          if (entry.subLinks) {
            navObj.subLinks = entry.subLinks;
            var activeSubLinks = entry.subLinks.some(function (subLink) {
              return linkIsActive(subLink.href, location);
            });

            if (activeSubLinks) {
              navObj.activeMenu = true;
            }
          } else if ( // 1/10/2019 (Cheely) - I don't know why the "linkTo" field is involved in this check. If you know,
          // please refactor this code to make it clear.
          linkIsActive(entry.href, location) && (componentLinkTo || navObj.linkTo)) {
            navObj.active = true;
          }

          return navObj;
        });
        subNav = nav;
      }

      return subNav;
    }),
    hasSubMenu: Ember.computed('subMenu', function () {
      var subMenu = this.get('subMenu');

      if (!subMenu || subMenu.length === 0) {
        return false;
      }

      var rootMenu = subMenu[0] || {};
      return subMenu.length > 1 || rootMenu.subLinks && rootMenu.subLinks.length > 1;
    }),
    isHidden: Ember.computed.not('hasSubMenu')
  });

  _exports.default = _default;
});