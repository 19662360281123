define("web-directory/components/target-dropdown/data/conversation-summary-config.const", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ConversationSummaryConfigData = void 0;
  var ConversationSummaryConfigData = {
    call: {
      icon: 'phone',
      enabled: true
    },
    callback: {
      icon: 'phone-callback',
      enabled: true
    },
    chat: {
      icon: 'chat',
      enabled: true
    },
    email: {
      icon: 'message-email',
      enabled: true
    },
    message: {
      icon: 'sms',
      enabled: true
    },
    socialExpression: {
      icon: 'web',
      enabled: true
    },
    video: {
      icon: 'video',
      enabled: true
    },
    voice: {
      icon: 'phone-voice',
      enabled: true
    }
  };
  _exports.ConversationSummaryConfigData = ConversationSummaryConfigData;
});