define("web-directory/initializers/apps-service", ["exports", "web-directory/services/apps-api-handlers/my-interactions-handler", "web-directory/services/apps-api-handlers/external-contacts-handler", "web-directory/services/apps-api-handlers/resource-center-handler", "web-directory/services/apps-api-handlers/directory-nav-handler", "web-directory/services/apps-api-handlers/toast-handler", "web-directory/services/apps-api-handlers/attention-count-handler", "web-directory/services/apps-api-handlers/lifecycle-handler", "web-directory/services/apps-api-handlers/show-interaction-details-handler"], function (_exports, _myInteractionsHandler, _externalContactsHandler, _resourceCenterHandler, _directoryNavHandler, _toastHandler, _attentionCountHandler, _lifecycleHandler, _showInteractionDetailsHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  var APPS_API_HANDLER_TYPE = 'apps-api-handler';

  function initialize(application) {
    // Register the API Handlers and the list of handler keys for dynamic injection
    var handlerMap = {
      'my-interactions-handler': _myInteractionsHandler.default,
      'external-contacts-handler': _externalContactsHandler.default,
      'resource-center-handler': _resourceCenterHandler.default,
      'directory-nav-handler': _directoryNavHandler.default,
      'toast-handler': _toastHandler.default,
      'attention-count-handler': _attentionCountHandler.default,
      'lifecycle-handler': _lifecycleHandler.default,
      'show-interaction-details-handler': _showInteractionDetailsHandler.default
    };
    var handlerKeys = [];
    Object.keys(handlerMap).forEach(function (currName) {
      var currFullName = "".concat(APPS_API_HANDLER_TYPE, ":").concat(currName);
      handlerKeys.push(currFullName);
      application.register(currFullName, handlerMap[currName]);
    });
    application.register("".concat(APPS_API_HANDLER_TYPE, ":keys"), handlerKeys, {
      instantiate: false
    });
    application.inject('service:apps', 'routerService', 'router:main');
  }

  var _default = {
    name: 'apps-service',
    after: 'setupSessionService',
    initialize: initialize
  };
  _exports.default = _default;
});