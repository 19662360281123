define("web-directory/components/card-tree/card-tree-entry/component", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed,
      Component = Ember.Component;

  var _default = Component.extend({
    classNames: ['card-tree-entry'],
    classNameBindings: ['hasChildren'],
    intl: inject.service(),
    permissions: inject.service(),
    canViewChat: computed.reads('permissions.canViewBusinessChat'),
    person: null,
    isChild: false,
    isSuperior: false,
    expand: false,
    actions: {
      toggleExpand: function toggleExpand() {
        if (!this.get('isSuperior')) {
          this.toggleProperty('expand');
        }
      }
    },
    loading: computed('children.isPending', 'superiors.isPending', function () {
      return this.get('children.isPending') && this.get('superiors.isPending');
    }),
    children: computed('person', function () {
      var children = this.get('person.directReports') || [];
      var promise = Ember.RSVP.resolve(children);
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    superiors: computed('person', 'isChild', function () {
      var _this = this;

      if (this.get('isChild')) {
        return _emberData.default.PromiseArray.create({
          promise: Ember.RSVP.resolve([])
        });
      }

      var superiors = this.get('person.superiors');
      var promise = superiors.then(function (people) {
        var orderedPeople = [];
        return new Ember.RSVP.Promise(function (resolve) {
          function getNextPerson(person) {
            if (person && person.get('id')) {
              orderedPeople.push(person);
              var manager = person.getFieldValue('relationships.manager');

              if (!manager) {
                resolve(orderedPeople);
                return;
              }

              var nextPerson = people.find(function (p) {
                return p.get('id') === manager.guid;
              });

              if (!nextPerson) {
                resolve(orderedPeople);
                return;
              }

              return getNextPerson(nextPerson);
            }

            resolve(orderedPeople);
          }

          _this.get('person.manager').then(getNextPerson).catch(function () {
            return resolve(people);
          });
        });
      });
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    hasChildren: computed.gt('children.content.length', 0),
    hasSuperiors: computed.gt('superiors.content.length', 0),
    directReportsLabel: computed('children.content.length', function () {
      var intl = this.get('intl');
      var directReports = this.get('children.content.length');

      if (directReports > 1) {
        return intl.lookup('components.cardTree.directReports', {
          directReports: directReports
        });
      }

      return intl.lookup('components.cardTree.directReport');
    }),
    showTotalReports: computed('person.totalReports.content', 'children.content.length', function () {
      return this.get('person.totalReports.content') > this.get('children.content.length');
    }),
    totalReportsLabel: computed('person.totalReports.content', function () {
      var intl = this.get('intl');
      var totalReports = this.get('person.totalReports.content');

      if (totalReports > 1) {
        return intl.lookup('components.cardTree.totalReports', {
          totalReports: totalReports
        });
      }

      return intl.lookup('components.cardTree.totalReport');
    })
  });

  _exports.default = _default;
});