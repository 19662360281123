define("web-directory/components/acd-interactions-panel/interaction-group/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['interaction-group'],
    classNameBindings: ['isSelected', 'isAlerting', 'isSingle'],

    /**
     * @Attribute
     * Array of external contacts from external contact service
     */
    externalContacts: null,

    /**
     * @Attribute
     * The adhoc group object create by the roster component
     */
    group: null,

    /**
     * @Attribute
     * The currently selected interaction
     */
    selectedInteraction: null,

    /**
     * @Attribute
     * The currently selected workitem
     */
    selectedWorkitem: null,

    /**
     * @Attribute
     * Flag to enable outbound contact, passed through this component
     */
    enableOutboundContact: null,

    /**
     * @Attribute
     * useEmailDraftEvent FT
     */
    useEmailDraftEvent: false,

    /**
     * @Attribute
     * Object containing draft updates
     */
    draftUpdateHash: null,

    /**
     * Group conversation interactions sorted by startTimestamp.
     * Group workitem interactions by dueDate or dateCreated if dueDate is not present.
     */
    interactions: Ember.computed.sort('group.interactions', function (a, b) {
      function getStartTime(conversation) {
        var startTime = Ember.get(conversation, 'startTimestamp') || Ember.get(conversation, 'workitem.dateDue');
        return new Date(startTime).valueOf();
      }

      var startA = getStartTime(a);
      var startB = getStartTime(b);
      return startA - startB;
    }),

    /**
     * Flag to indicate there is only one conversation in group
     */
    isSingle: Ember.computed.equal('interactions.length', 1),

    /**
     * Flag to determine if the group is in an alerting state
     */
    isAlerting: Ember.computed('interactions.firstObject.isAlerting', 'interactions.firstObject.workitem.assignmentState', 'isSingle', function () {
      var isSingle = this.get('isSingle');
      var isAlerting = this.get('interactions.firstObject.isAlerting') || this.get('interactions.firstObject.workitem.assignmentState') === 'Alerting';
      return isSingle && isAlerting;
    }),

    /**
     * Maintain a reference to the external contact object for the group
     */
    externalContact: Ember.computed('externalContacts.[]', 'group.groupId', function () {
      var contactFromGroupId = this.get('group.groupId');
      return contactFromGroupId ? this.get('externalContacts').findBy('id', contactFromGroupId) : null;
    }),

    /**
     * Determine if group contains the currently selected interaction
     */
    isSelected: Ember.computed('interactions', 'selectedInteraction', function () {
      var interactions = this.get('interactions');
      var selectedInteractionId = this.get('selectedInteraction.id');
      var selectedWorkitemId = this.get('selectedWorkitem.id');
      return !!interactions.find(function (i) {
        return Ember.get(i, 'id') === selectedInteractionId || Ember.get(i, 'id') === selectedWorkitemId;
      });
    })
  });

  _exports.default = _default;
});