define("web-directory/components/entity-fields/select-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qlVl/Re2",
    "block": "{\"statements\":[[11,\"div\",[]],[16,\"class\",[34,[\"field-value values \",[28,[\"entry\",\"_id\"]]]]],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"canEdit\"]]],null,{\"statements\":[[0,\"        \"],[11,\"div\",[]],[15,\"class\",\"row\"],[13],[0,\"\\n            \"],[11,\"div\",[]],[15,\"class\",\"col-sm-10\"],[13],[0,\"\\n                \"],[11,\"select\",[]],[15,\"class\",\"form-control\"],[16,\"onchange\",[33,[\"action\"],[[28,[null]],\"setValue\"],[[\"value\"],[\"target.value\"]]],null],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"content\"]]],null,{\"statements\":[[0,\"                        \"],[11,\"option\",[]],[16,\"value\",[28,[\"item\",\"id\"]],null],[16,\"selected\",[33,[\"eq\"],[[28,[\"choice\"]],[28,[\"item\",\"id\"]]],null],null],[13],[0,\"\\n                            \"],[1,[28,[\"item\",\"label\"]],false],[0,\"\\n                        \"],[14],[0,\"\\n\"]],\"locals\":[\"item\"]},null],[0,\"                \"],[14],[0,\"\\n            \"],[14],[0,\"\\n        \"],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"        \"],[11,\"span\",[]],[13],[1,[26,[\"translatedValue\"]],false],[14],[0,\"\\n\"]],\"locals\":[]}],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/entity-fields/select-field/template.hbs"
    }
  });

  _exports.default = _default;
});