define("web-directory/components/person-graph/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bVHBo+nz",
    "block": "{\"statements\":[[6,[\"unless\"],[[28,[\"mini\"]]],null,{\"statements\":[[0,\"    \"],[1,[33,[\"entity-image\"],null,[[\"entity\"],[[28,[\"person\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/person-graph/template.hbs"
    }
  });

  _exports.default = _default;
});