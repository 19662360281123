define("web-directory/components/entity-fields/validators/date", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    isValid: function isValid(date, field) {
      if (field.get('required')) {
        var value = date.value;
        return !_lodash.default.isEmpty(value) && value.day && value.month && value.year;
      }

      return true;
    },
    isEmpty: function isEmpty(date) {
      if (!date) {
        return true;
      }

      var value = date.value;
      return _lodash.default.isEmpty(value);
    },
    cleanFieldForEntity: function cleanFieldForEntity(entity, field, entry) {
      var value = Ember.get(entry, 'value');
      var path = field.getFieldPath();

      if (value) {
        if (typeof value.year !== 'number') {
          delete value.year;
        }

        if (typeof value.month !== 'number') {
          delete value.month;
        }

        if (typeof value.day !== 'number') {
          delete value.day;
        }

        if (!value.year && !value.month && !value.day) {
          value = {};
        }
      }

      if (_lodash.default.isEmpty(value)) {
        var data = entity.get(path);

        if (data) {
          data.removeObject(entry);
        }
      } else {
        Ember.set(entry, 'value', value);
      }
    }
  };
  _exports.default = _default;
});