define("web-directory/components/uploaded-image/component", ["exports", "lodash", "ember-purecloud-components/mixins/logger"], function (_exports, _lodash, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var profilePaths = {
    person: 'images.profile',
    group: 'images.activeProfilePicture'
  };

  var _default = Ember.Component.extend(_logger.default, {
    classNames: ['uploaded-image'],
    session: Ember.inject.service(),
    application: Ember.inject.service(),
    notification: Ember.inject.service(),
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    image: null,
    entity: null,
    size: '128',
    labelKey: '',
    editingCaption: false,
    canAddCaption: false,
    type: 'profile',
    attrs: {
      select: function select() {},
      delete: function _delete() {},
      editing: false,
      canEdit: false,
      showingInformal: false
    },
    actions: {
      selectUploadedImage: function selectUploadedImage() {
        var type = this.get('type');
        var image = this.get('image');
        var active = this.get('isActive');
        var selectUploadedImage = this.attrs.select;

        if (this.get('editing') && this.get('canEdit') && this.get('type') === 'profile') {
          selectUploadedImage(type, image, active);
        } else {
          var current = this.get('currentInformal');

          if (current !== this.get('image')) {
            this.set('currentInformal', this.get('image'));
            this.showImage(image, type);
          } else {
            this.set('currentInformal', null);
          }
        }
      },
      deleteUploadedImage: function deleteUploadedImage() {
        var _this = this;

        var type = this.get('type');
        var image = this.get('image');
        var active = this.get('isActive');
        var deleteUploadedImage = this.attrs.delete;

        if (this.get('canDelete')) {
          var entityType = Ember.get(this, 'entity.type');

          if (entityType === 'group') {
            var groupId = Ember.get(this, 'entity.id');
            var imageId = Ember.get(this, 'image._id');
            return this.get('ajax').delete(Ember.get(this, 'application').pcV2Uri("api/v2/groups/".concat(groupId, "/images/").concat(imageId))).then(function () {
              deleteUploadedImage(type, image, false);
              return;
            }).catch(function (err) {
              var intl = _this.get('intl');

              _this.logger.error('failed to upload image', {
                error: err
              });

              _this.get('notification').error(intl.lookup('notification.titles.profileSaveError').toString(), intl.lookup('notification.fieldSection.error').toString(), {
                forceBrowserNotification: true,
                tag: 'image-upload'
              });

              return;
            });
          }

          this.get('delete')(type, image, active);
        }
      }
    },
    addCaption: function addCaption() {
      this.set('openCaption', this);
      Ember.run.scheduleOnce('afterRender', this, function () {
        this.$('.caption textarea').focus();
      });
    },
    saveCaption: function saveCaption() {
      this.set('openCaption', null);
      var imageList = this.get('entity').getFieldValue('images.informal');
      var image = imageList.findBy('_id', this.get('image._id'));
      Ember.set(image, 'labelKey', this.get('labelKey'));
      this.get('entity').save();
    },
    initCaption: Ember.on('init', function () {
      this.set('canAddCaption', this.get('type') === 'informal' && this.get('entity.type') === 'person');
      this.set('labelKey', this.get('image.labelKey'));
    }),
    ownImage: Ember.computed('entity', 'entity.id', function () {
      var _this2 = this;

      if (!this.get('canEdit')) {
        return false;
      }

      var isPerson = this.get('entity.id') === this.get('session.person.id');
      var isGroupOwner = false;

      if (this.get('entity.type') === 'group') {
        var owners = this.get('entity').getFieldValue('owners.owners') || [];
        isGroupOwner = _lodash.default.find(owners, function (owner) {
          return owner.value === _this2.get('session.person.id');
        });
      }

      return isPerson || isGroupOwner;
    }),
    closeCaption: Ember.observer('openCaption', function () {
      if (this.get('openCaption') !== this) {
        this.set('editingCaption', false);
      } else {
        this.set('editingCaption', true);
      }
    }),
    canDelete: Ember.computed('entity.uploadedProfilePictures.length', function () {
      var entityType = this.get('entity.type');

      if (entityType === 'group') {
        var _isEditing = this.get('editing');

        return _isEditing;
      }

      var type = this.get('type') === 'informal';
      var populated = this.get('entity.uploadedProfilePictures.length') !== 1;
      var isEditing = this.get('editing');
      return (type || populated) && isEditing;
    }),
    src: Ember.computed('image', 'entity', 'size', function () {
      var image = this.get('image.value');
      var size = "x".concat(this.get('size'));

      if (image) {
        return image[size];
      }

      return '';
    }),
    isActive: Ember.computed('image', 'entity', 'entity.profilePicture', function () {
      var image = this.get('image');
      var type = this.get('type');
      var imagePath = profilePaths[this.get('entity.type')];

      if (type === 'profile') {
        var currentImage = this.get('entity').getReferencedField(imagePath);

        if (!_lodash.default.isEmpty(currentImage)) {
          return image._id === currentImage._id;
        }
      }

      return false;
    }),
    showImage: function
      /*image, type*/
    showImage() {
      this.bubbleAction({
        action: 'viewLightGallery',
        actionContext: [this.get('image')]
      });
    }
  });

  _exports.default = _default;
});