define("web-directory/components/active-fax/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['active-fax'],
    faxErrorInfo: computed.reads('fax.errorInfo'),
    faxTransmissionStatus: computed.reads('fax.faxStatus'),
    // uploading, transmitting, converting, dialing, connected, disconnected
    faxState: computed('fax.state', function () {
      return this.get('fax.state').toLowerCase();
    }),
    isDisconnected: computed.equal('faxState', 'disconnected'),
    intl: inject.service(),
    faxStatusMessage: computed('faxState', 'faxStatus', function () {
      var intl = this.get('intl');
      var faxState = this.get('faxState');
      var faxErrors = this.get('faxErrors');
      var statusMessage = '';

      if (faxState === 'uploading') {
        statusMessage = intl.t('purecloud.fax.status.uploading');
      }

      if (_lodash.default.includes(['converting', 'transmitting', 'dialing', 'connected'], faxState)) {
        var localizeMessageKey = 'purecloud.fax.status.preparingFax',
            localizeArguments = null;
        var currentPage = this.get('faxTransmissionStatus.activePage');
        var pageTotal = this.get('faxTransmissionStatus.expectedPages');

        if (currentPage && pageTotal) {
          localizeMessageKey = 'purecloud.fax.status.faxingProgress';
          localizeArguments = {
            currentPage: currentPage,
            pageTotal: pageTotal
          };
        }

        statusMessage = intl.t(localizeMessageKey, localizeArguments);
      }

      if (faxState === 'disconnected' && faxErrors === undefined) {
        statusMessage = intl.t('purecloud.fax.status.successful');
      }

      if (faxState === 'disconnected') {
        var errors = this.get('faxErrorInfo');
        var failMessage = intl.t('purecloud.fax.error.requestFailed');
        var succeedMessage = intl.t('purecloud.fax.status.successful');
        statusMessage = errors ? failMessage : succeedMessage;
      }

      return statusMessage;
    })
  }).reopenClass({
    positionalParams: ['fax']
  });

  _exports.default = _default;
});