define("web-directory/components/entity-fields/validators/text", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var requiredFieldPaths = ['general.name', 'contactInfo.im'];

  function _isEmpty(text) {
    if (typeof text === 'string') {
      return _lodash.default.isEmpty(text);
    } else {
      var value = text.value;
      return value === undefined || value === null || _lodash.default.isEmpty(value);
    }
  }

  var _default = {
    isValid: function isValid(text, field) {
      if (!text) {
        return false;
      }

      var maxLength = field.get('params.maxLength');

      if (maxLength && text.value && text.value.length > maxLength || !text.value && text.length > maxLength) {
        return false;
      }

      if ((field.get('required') || _lodash.default.includes(requiredFieldPaths, field.getFieldPath())) && _isEmpty(text)) {
        return false;
      }

      return text !== undefined && text !== null;
    },
    isEmpty: function isEmpty(text) {
      return _isEmpty(text);
    }
  };
  _exports.default = _default;
});