define("web-directory/mixins/acd/interaction-state", ["exports", "web-directory/utils/acdParticipantStatuses"], function (_exports, _acdParticipantStatuses) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isParked: Ember.computed('interactionState', function () {
      if (!this.get('interactionState')) {
        return false;
      }

      return this.get('interactionState').toLowerCase() === _acdParticipantStatuses.default.PARKED.toLowerCase();
    }),
    isDisconnected: Ember.computed('interactionState', function () {
      if (!this.get('interactionState')) {
        return false;
      }

      return this.get('interactionState').toLowerCase() === _acdParticipantStatuses.default.DISCONNECTED.toLowerCase();
    }),
    isConnected: Ember.computed('interactionState', function () {
      if (!this.get('interactionState')) {
        return false;
      }

      return this.get('interactionState').toLowerCase() === _acdParticipantStatuses.default.CONNECTED.toLowerCase();
    }),
    isAlerting: Ember.computed('interactionState', function () {
      if (!this.get('interactionState')) {
        return false;
      }

      return this.get('interactionState').toLowerCase() === _acdParticipantStatuses.default.ALERTING.toLowerCase();
    }),
    isDialing: Ember.computed('interactionState', function () {
      if (!this.get('interactionState')) {
        return false;
      }

      return this.get('interactionState').toLowerCase() === _acdParticipantStatuses.default.DIALING.toLowerCase();
    }),
    isContacting: Ember.computed('interactionState', function () {
      if (!this.get('interactionState')) {
        return false;
      }

      return this.get('interactionState').toLowerCase() === _acdParticipantStatuses.default.CONTACTING.toLowerCase();
    }),
    isTerminated: Ember.computed('interactionState', function () {
      if (!this.get('interactionState')) {
        return false;
      }

      return this.get('interactionState').toLowerCase() === _acdParticipantStatuses.default.TERMINATED.toLowerCase();
    }),
    isTransmitting: Ember.computed('interactionState', function () {
      if (!this.get('interactionState')) {
        return false;
      }

      return this.get('interactionState').toLowerCase() === _acdParticipantStatuses.default.TRANSMITTING.toLowerCase();
    }),
    isConfined: Ember.computed.reads('participantForCurrentUser.calls.firstObject.confined'),
    isHeld: Ember.computed('type', 'participantForCurrentUser.chats.firstObject.held', 'participantForCurrentUser.calls.firstObject.held', 'participantForCurrentUser.callbacks.firstObject.held', 'participantForCurrentUser.messages.firstObject.held', 'participantForCurrentUser.emails.firstObject.held', function () {
      var type = this.get('type');

      switch (type) {
        case 'call':
          return this.get('participantForCurrentUser.calls.firstObject.held');

        case 'callback':
          return this.get('participantForCurrentUser.callbacks.firstObject.held');

        case 'chat':
          return this.get('participantForCurrentUser.chats.firstObject.held');

        case 'email':
          return this.get('participantForCurrentUser.emails.firstObject.held');

        case 'message':
          return this.get('participantForCurrentUser.messages.firstObject.held');

        default:
          return false;
      }
    }),
    interactionState: Ember.computed('type', 'participantForCurrentUser.chats.firstObject.state', 'participantForCurrentUser.callbacks.firstObject.state', 'participantForCurrentUser.calls.firstObject.state', 'participantForCurrentUser.emails.firstObject.state', 'participantForCurrentUser.messages.firstObject.state', 'participantForCurrentUser.videos.firstObject.state', function () {
      var type = this.get('type');

      switch (type) {
        case 'chat':
          return this.get('participantForCurrentUser.chats.firstObject.state');

        case 'call':
          return this.get('participantForCurrentUser.calls.firstObject.state');

        case 'callback':
          return this.get('participantForCurrentUser.callbacks.firstObject.state');

        case 'email':
          return this.get('participantForCurrentUser.emails.firstObject.state');

        case 'message':
          return this.get('participantForCurrentUser.messages.firstObject.state');

        default:
          return null;
      }
    })
  });

  _exports.default = _default;
});