define("web-directory/components/time-machine-confirmation/component", ["exports", "web-directory/utils/dates"], function (_exports, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CLOCK_MODE_DURATION = 10000;

  var _default = Ember.Component.extend({
    classNames: 'time-machine-confirmation',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('primaryDirection', Math.random() < 0.5 ? 1 : -1);
      this.set('minuteDirection', this.get('primaryDirection') * -1); // Set to undefined to init the clock with the current time and tick as normal

      this.set('_clockStandardMode', true);
      this.set('randomTime', undefined);
    },
    willDestroyElement: function willDestroyElement() {
      var timer = this.get('_updInterval');

      if (timer) {
        Ember.run.cancel(timer);
        this.set('_updInterval', null);
      }

      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('_updInterval', Ember.run.later(this, 'updRandTime', CLOCK_MODE_DURATION));
    },
    escapedBuildTag: Ember.computed('buildTag', function () {
      var result = '';
      var rawBuildTag = this.get('buildTag');

      if (typeof rawBuildTag === 'string' && Ember.isPresent(rawBuildTag)) {
        result = Ember.Handlebars.Utils.escapeExpression(rawBuildTag);
      }

      return result;
    }),
    updRandTime: function updRandTime() {
      if (this.get('_clockStandardMode')) {
        this.set('_clockStandardMode', false);
        this.set('_randomModeStart', Date.now());
      } else if (Date.now() - this.get('_randomModeStart') > CLOCK_MODE_DURATION) {
        this.set('_clockStandardMode', true);
        this.set('randomTime', undefined);
        this.set('_updInterval', Ember.run.later(this, 'updRandTime', CLOCK_MODE_DURATION));
        return;
      }

      var currTime = (0, _dates.default)(this.get('randomTime'));

      try {
        var currMinutes = currTime.get('minutes');
        var currBin = Math.floor(currMinutes / 5);
        currTime.add(5 * this.get('minuteDirection'), 'minutes');
        currTime.add(10 * this.get('primaryDirection'), 'seconds');
        var nextMinutes = currTime.get('minutes');
        var nextBin = Math.floor(nextMinutes / 5);
        var hourMultiplier = 1;

        if (Math.abs(nextBin - currBin) === 11) {
          // Prevents jitter caused by minutes rolling hours
          hourMultiplier++;
        }

        currTime.add(hourMultiplier * this.get('primaryDirection'), 'hours');

        if (isNaN(currTime) || !currTime.isValid()) {
          // Protect against overflow
          currTime = (0, _dates.default)();
        }
      } catch (e) {
        currTime = (0, _dates.default)();
      }

      this.set('randomTime', currTime);
      this.set('_updInterval', Ember.run.later(this, 'updRandTime', 100));
    }
  });

  _exports.default = _default;
});