define("web-directory/components/entity-fields/address-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yJ+1Swx0",
    "block": "{\"statements\":[[11,\"div\",[]],[16,\"class\",[34,[\"field-value values \",[28,[\"entry\",\"_id\"]]]]],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"showLabel\"]]],null,{\"statements\":[[0,\"        \"],[1,[33,[\"field-label\"],null,[[\"field\",\"entry\",\"entity\",\"editing\"],[[28,[\"fieldData\",\"field\"]],[28,[\"entry\"]],[28,[\"entity\"]],[28,[\"editing\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\n\"],[6,[\"if\"],[[28,[\"canEdit\"]]],null,{\"statements\":[[0,\"        \"],[11,\"div\",[]],[15,\"class\",\"row\"],[13],[0,\"\\n            \"],[11,\"div\",[]],[15,\"class\",\"col-sm-12\"],[13],[0,\"\\n                \"],[1,[33,[\"textarea\"],null,[[\"value\",\"classNames\",\"class\"],[[28,[\"entry\",\"value\"]],\"form-control\",[33,[\"unless\"],[[28,[\"isValid\"]],\"validation-error\"],null]]]],false],[0,\"\\n            \"],[14],[0,\"\\n        \"],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"        \"],[11,\"span\",[]],[13],[1,[33,[\"anchor-tag\"],null,[[\"protocol\",\"value\"],[\"map\",[28,[\"entry\",\"value\"]]]]],false],[14],[0,\"\\n\"]],\"locals\":[]}],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/entity-fields/address-field/template.hbs"
    }
  });

  _exports.default = _default;
});