define("web-directory/field-config/field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Field = Ember.Object.extend({
    intl: Ember.computed.reads('section.intl'),
    application: Ember.computed.reads('section.application'),
    section: null,
    key: null,
    type: null,
    labelKeys: [],
    instructionText: '',
    customLabels: false,
    repeatable: false,
    params: null,
    maxRepeat: Ember.computed.reads('params.maxRepeat'),
    i18nLabel: Ember.computed(function () {
      var intl = this.get('intl');
      var key = "server_translations.".concat(this.getI18nKey());
      var labelKey = "server_translations.".concat(this.getI18nLabelKey());

      if (intl.exists(labelKey)) {
        return intl.t(labelKey).toString();
      }

      if (intl.exists(key)) {
        return intl.t(key).toString();
      }

      return this.get('key');
    }),
    i18nKey: Ember.computed(function () {
      return this.getI18nKey();
    }),
    elemId: Ember.computed(function () {
      return "#".concat(this.get('key'));
    }),
    fieldPath: Ember.computed(function () {
      return this.getFieldPath();
    }),
    fieldPathClass: Ember.computed(function () {
      var path = this.getFieldPath();
      return path.replace(/\./gi, '-');
    }),
    componentName: Ember.computed('type', function () {
      var type = this.get('type');
      return "entity-fields/".concat(type, "-field");
    }),
    getI18nKey: function getI18nKey() {
      return "".concat(this.section.getI18nKey(), "_").concat(this.key);
    },
    getI18nLabelKey: function getI18nLabelKey() {
      return "".concat(this.getI18nKey(), "_labelKey_").concat(this.key);
    },
    getFieldPath: function getFieldPath() {
      return this.section.key + '.' + this.key;
    },
    getEntityType: function getEntityType() {
      return this.section.getEntityType();
    },
    cleanForEntity: function cleanForEntity(entity) {
      Ember.Logger.warn('DEPRECATION: Stop using this method please...it breaks a lot of rules...');
      var name = this.get('componentName');

      if (requirejs._eak_seen[name]) {
        var module = requirejs(name);

        if (module.cleanFieldForEntity) {
          return module.cleanFieldForEntity(entity, this, entity.getFieldValue(this.getFieldPath()));
        }
      }
    },
    component: Ember.computed(function () {
      Ember.Logger.warn('DEPRECATION: Use component helper and `field.componentName` property to use field components'); // We have fields with hyphens (multiline-text). Make sure they get Ember-ized.

      var className = this.get('componentName');
      var factory = this.get('application.container').lookupFactory('component:' + className);

      if (factory) {
        return factory;
      }

      Ember.Logger.warn('Could not find view for:', {
        className: className
      });
      return this.get('application.container').lookupFactory('component:text-field');
    })
  });
  var _default = Field;
  _exports.default = _default;
});