define("web-directory/models/coaching-appointment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    id: null,
    name: null,
    markedAsRead: false,
    actionType: null,
    relationship: null,
    dateStart: null,
    lengthInMinutes: null,
    status: null,
    appointment: {
      id: null,
      selfUri: null,
      isOverdue: false
    }
  });

  _exports.default = _default;
});