define("web-directory/initializers/clock", ["exports", "web-directory/services/clock"], function (_exports, _clock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'clock',
    initialize: function initialize() {
      var app = arguments[1] || arguments[0];
      app.register('clock:main', _clock.default);
      app.inject('controller', 'clock', 'clock:main');
      app.inject('component', 'clock', 'clock:main');
    }
  };
  _exports.default = _default;
});