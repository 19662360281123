define("web-directory/controllers/chat/participants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    chat: Ember.inject.service(),
    sidebar: Ember.inject.service(),
    managePresenceSubscriptions: Ember.observer('model.participantsVisible', function () {
      var subscribeTo = this.get('model.participantsVisible') ? this.get('model.participants') : null;
      return this.get('presenceSubscription').autoSubscribe(subscribeTo, this);
    }),
    actions: {
      inviteUserToRoom: function inviteUserToRoom(person) {
        var room = this.get('model');

        if (room.isOneToOneRoom()) {
          return this.promoteToAdhocRoom(person);
        } else {
          return this.get('chat').inviteToRoom(room, person);
        }
      },
      toggleParticipants: function toggleParticipants() {
        this.toggleProperty('model.participantsVisible');
      },
      collapseChat: function collapseChat() {
        this.get('sidebar').minimizeCommunicationSidebar();
      }
    },
    promoteToAdhocRoom: function promoteToAdhocRoom(person) {
      var chat = this.get('chat');
      var oneToOneTarget = this.get('model.oneToOneTarget');
      return chat.makeNewEmptyRoom().then(function (room) {
        chat.inviteToRoom(room, oneToOneTarget);
        return chat.inviteToRoom(room, person);
      });
    }
  });

  _exports.default = _default;
});