define("web-directory/utils/phone-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isValidPhoneNumber = isValidPhoneNumber;

  /**
   * A common regex for many different number formats. Includes the following and more:
   *
   * 18005551234
   * 1 800 555 1234
   * +1 800 555-1234
   * +86 800 555 1234
   * 1-800-555-1234
   * 1 (800) 555-1234
   * (800)555-1234
   * (800) 555-1234
   * (800)5551234
   * 800-555-1234
   * 800.555.1234
   * 800 555 1234x5678
   * 8005551234 x5678
   * 1    800    555-1234
   * 1----800----555-1234
   */
  var PHONE_NUMBER_REGEX = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
  /**
   * Tests the value provided against a phone number regex to determine if the number is valid.
   * @param value phone number to test against
   * @returns boolean depicting whether or not the number provided is valid
   */

  function isValidPhoneNumber(value) {
    return value ? PHONE_NUMBER_REGEX.test(value.trim()) : false;
  }
});