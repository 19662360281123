define("web-directory/utils/twitter/twitter-properties", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TWITTER_PROP = {
    FILE_SIZE: 3,
    MESSAGE_TYPE: 'twitter'
  };
  var _default = TWITTER_PROP;
  _exports.default = _default;
});