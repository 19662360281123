define("web-directory/components/acd-interactions-panel-v2/interaction-header/header-dropdown/component", ["exports", "web-directory/utils/acd-ts"], function (_exports, _acdTs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @notes
   * instead of using `onclick` (or other on<event>) during the implementation, custom components that use
   * interactive elements in ember as an alias do not work with the `on` portion prefixed. When wanting to
   * add a listener to the events you should drop the `on` and just use `click`.
   *
   * @usage
   * {{acd-interactions-panel-v2/interaction-header/header-dropdown click=(action 'onEvent')}}
   */
  var _default = Ember.Component.extend({
    /**
     * List of class names to be applied to the component on render
     */
    classNames: ['interaction-header-dropdown'],

    /**
     * List of dynamic class names that will change based on the corresponding property values
     */
    classNameBindings: ['accent'],

    /**
     * List of dynamic attributes that will change based on the corresponding property values
     */
    attributeBindings: ['accent', 'disabled', 'disabled:aria-disabled'],

    /**
     * @attribute
     *
     * `true` if the component should not be interacted with
     */
    disabled: false,

    /**
     * @attribute
     *
     * The color the button should render as. Defaults to primary
     */
    accent: 'primary',

    /**
     * @attribute
     */
    options: [],

    /**
     * @attribute
     */
    tooltip: undefined,

    /**
     * @attribute
     */
    selected: undefined,

    /**
     * used to create a unique id for the tooltip and header button
     */
    tooltipId: Ember.computed('elementId', function () {
      return "".concat(this.get('elementId'), "-header-dropdown-button");
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this$get;

      this._super.apply(this, arguments); // if we were given options and nothing has been selected, we need to provide a default


      if (!this.get('selected') && (_this$get = this.get('options')) !== null && _this$get !== void 0 && _this$get.length) {
        this.set('selected', this.get('options')[0]);
      }
    },

    /**
     * @action
     */
    actionSelected: function actionSelected(option) {
      return (0, _acdTs.warnClosureAction)('actionSelected', option);
    },
    actions: {
      onOptionSelect: function onOptionSelect(option) {
        this.set('selected', option);
      },
      onAction: function onAction() {
        if (this.selected) {
          this.actionSelected(this.selected);
        }
      }
    }
  });

  _exports.default = _default;
});