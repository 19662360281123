define("web-directory/utils/objects", ["exports", "web-directory/utils/dates"], function (_exports, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.extractRawData = extractRawData;
  _exports.isDestroyed = isDestroyed;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var typeOf = Ember.typeOf,
      Service = Ember.Service,
      Application = Ember.Application,
      get = Ember.get;
  /**
   * @callback extractRawDataKeyFilter
   * @param {Array.<string>} path - An array of the ancesteral keys of this key. Array indices will be converted to strings. Note that the root node will not be included in this path so this array will be empty for root props/indices.
   * @param {string} key The current key to evaluate; Array indices will be converted to strings
   * @returns {boolean} true if the key and its associated value should be included; false to exclude.
   *
   * @callback extractRawDataValueFilter
   * @param {Array.<string>} path - An array of the keys down to the current value.  Array indices will be converted to strings. Note that the root node will not be included in this path so this array will be of length 1 for root values.
   * @param {*} value The current value to evaluate
   * @returns {boolean} true if the value and it's associated key should be included; false to exclude.
   */

  var ALLOWED_VALUE_TYPES = ['null', 'undefined', 'regexp', 'date', 'moment', 'boolean', 'number', 'string', 'array', 'object', 'instance'];
  /**
   * Extract raw data from the provided input. Practically, this can be
   * used for extracting the raw data from Ember Object instances with some gotchas:
   *
   * * Non-string keys are NOT included
   * * All non-enumerable properties are NOT included
   *   * This implicitly excludes prototype properties
   * * Certain value types are NOT included
   *   * symbol, filelist, class, error
   *   * function (computeds, observers, etc.)
   *
   * Other important items to note:
   *
   * * If any object (other than Date or moment instances) in the hierarchy implements toJSON, it will be called with the current key and the result will then be processed through the depth check, key filters, and value filters.
   * * Cycles are replaced with [Cycle] and are more likely if services or the container are referenced (Best Practice: Use the defaults to filter underscore keys and Ember Services)
   * * Depth of objects will affect efficiency (Best Practice: Remove unnecessary keys/values with filters and built-in options)
   * * Objects and arrays will be truncated with '[Object]' and '[Array(<length>)]' if the maxDepth is exceeded.
   *
   * @param {null|undefined|Array|object} input - The object to extract
   * @param {Object} options - Options for parsing the object
   * @param {number?} options.maxDepth - An integer indicating the max allowed traversal depth.  Once reached, Objects and Arrays will be replaced with '[Object]' and '[Array(<length>)]' respectively. Root properties considered at depth 1.  Defaults to no max depth.
   * @param {boolean?} options.excludeUnderscoreKeys [true] - if keys starting with _ should be excluded; defaults to true
   * @param {boolean?} options.excludeEmberServices [true] - if Ember Service values should be excluded; defaults to true
   * @param {extractRawDataKeyFilter?} options.keyFilter [null] - A filter function to apply to keys which are not already excluded by other means; true will include the key/value pair, false will exclude
   * @param {extractRawDataValueFilter?} options.valueFilter [null] - A filter function to apply to values which are not already excluded by other means; true will include the key/value pair, false will exclude
   * @throws Error if the provided input is not undefined, null, a custom object, an Ember instance, or an array
   * @throws Error if the provided input cannot be extracted.  This could occur because of excessive depth, invalid input parameter or object structure, a faulty toJSON nested implementation, etc.
   * @throws Error if any options are invalid
   * @returns {null|undefined|Array|object} output
   */

  function extractRawData(input) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var safeOptions = {
      maxDepth: Number.MAX_SAFE_INTEGER,
      excludeUnderscoreKeys: true,
      excludeEmberServices: true,
      excludeEmberApplications: true
    };

    if (typeOf(options) !== 'object') {
      throw new Error('options can only be an object if specified');
    } else {
      if (Object.prototype.hasOwnProperty.call(options, 'maxDepth')) {
        if (typeOf(options.maxDepth) === 'number' && options.maxDepth < Number.MAX_SAFE_INTEGER) {
          safeOptions.maxDepth = Math.floor(options.maxDepth);
        } else {
          throw new Error('maxDepth must be a number less than Number.MAX_SAFE_INTEGER if specified');
        }
      }

      if (Object.prototype.hasOwnProperty.call(options, 'keyFilter')) {
        if (typeof options.keyFilter === 'function') {
          safeOptions.keyFilter = options.keyFilter;
        } else {
          throw new Error('keyFilter must be a function if specified');
        }
      }

      if (Object.prototype.hasOwnProperty.call(options, 'valueFilter')) {
        if (typeof options.valueFilter === 'function') {
          safeOptions.valueFilter = options.valueFilter;
        } else {
          throw new Error('valueFilter must be a function if specified');
        }
      }

      if (Object.prototype.hasOwnProperty.call(options, 'excludeUnderscoreKeys')) {
        if (typeOf(options.excludeUnderscoreKeys) === 'boolean') {
          safeOptions.excludeUnderscoreKeys = options.excludeUnderscoreKeys.valueOf();
        } else {
          throw new Error('excludeUnderscoreKeys must be a boolean if specified');
        }
      }

      if (Object.prototype.hasOwnProperty.call(options, 'excludeEmberServices')) {
        if (typeOf(options.excludeEmberServices) === 'boolean') {
          safeOptions.excludeEmberServices = options.excludeEmberServices.valueOf();
        } else {
          throw new Error('excludeEmberServices must be a boolean if specified');
        }
      }

      if (Object.prototype.hasOwnProperty.call(options, 'excludeEmberApplications')) {
        if (typeOf(options.excludeEmberApplications) === 'boolean') {
          safeOptions.excludeEmberApplications = options.excludeEmberApplications;
        } else {
          throw new Error('excludeEmberApplications must be a boolean if specified');
        }
      }
    }

    var inputType = myTypeOf(input);

    if (['null', 'undefined'].indexOf(inputType) >= 0) {
      return input;
    } else if (['array', 'object', 'instance'].indexOf(inputType) >= 0) {
      return _extractRawDataHelper(null, input, safeOptions);
    } else {
      throw new Error('Input must be undefined, null, a custom object (not date, moment, regexp, filelist, error, etc), an Ember Instance, or an array');
    }
  }
  /**
   * Recursive raw data extraction util.
   *
   * An item of the stack used to track history in traversing a nested array/object.
   * @typedef {Object} ContextStackItem
   * @property {array|object} scope - The parent object or array of the specified property
   * @property {string} propertyName - The name of the property (or index for arrays)
   *
   * A stack used track history while traversing a nested array/object.
   * @typedef {Array.<ContextStackItem>} ContextStack
   *
   * @param {ContextStack} contextStack The stack representing the lineage of the input parameter.  If null, input is considered the root input to extract.
   * @param {*} input The current object to extract
   * @param {*} options Type-safe and valid extraction options
   * @returns {Object|string|null} raw data representation of input
   */


  function _extractRawDataHelper(contextStack, input, options) {
    var inputType = myTypeOf(input);

    if (['null', 'undefined', 'regexp', 'date', 'moment'].indexOf(inputType) >= 0) {
      return input;
    }

    if (['boolean', 'number', 'string'].indexOf(inputType) >= 0) {
      if (typeof input.valueOf === 'function') {
        return input.valueOf();
      }

      return input;
    } // Return null for all other cases: 'function', 'symbol', 'filelist', 'class', 'error'


    if (['array', 'object', 'instance'].indexOf(inputType) < 0) {
      return null;
    } // Found an array, object, or instance that may need to be recursed
    // Check for cycles before decending


    if (contextStack) {
      var found = contextStack.find(function (currStackItem) {
        return currStackItem.scope === input;
      });

      if (found) {
        return '[Cycle]';
      }
    } // Check maxDepth before decending


    var currLevel = contextStack ? contextStack.length : 0;

    if (currLevel + 1 > options.maxDepth) {
      if (inputType === 'array') {
        return "[Array(".concat(input.length, ")]");
      } else {
        return '[Object]';
      }
    } // Safe to recurse sub-object or array


    if (inputType === 'array') {
      return input.reduce(function (result, currItem, currIndex) {
        var currStack = buildNewContextStack(contextStack, input, currIndex.toString());

        if (isAllowed(currStack, currItem, options)) {
          result.push(_extractRawDataHelper(currStack, currItem, options));
        }

        return result;
      }, []);
    } else {
      if (typeof input.toJSON === 'function') {
        try {
          var customJsonValue = input.toJSON(contextStack && contextStack[contextStack.length - 1].propertyName || ''); // Process the custom json value back through the _extractRawDataHelper to process filters, rules, and depth checks (max depth, fitlers, etc.)
          // The original stack should be kept

          return _extractRawDataHelper(contextStack, customJsonValue, options);
        } catch (e) {
          var wrappedJsonError = new Error('Failed to marshal nested toJSON representation');
          wrappedJsonError.baseError = e;
          throw wrappedJsonError;
        }
      } // iterate on props


      return Object.keys(input).reduce(function (acc, currKey) {
        var currValue = input[currKey];
        var currStack = buildNewContextStack(contextStack, input, currKey);

        if (!isAllowed(currStack, currValue, options)) {
          return acc;
        }

        acc[currKey] = _extractRawDataHelper(currStack, input[currKey], options);
        return acc;
      }, {});
    }
  }
  /**
   * Assesses if provided stack (containing the implicit curr key) and value pass various filters to be
   * included in the extracted object.
   *
   * @param {ContextStack} contextStack The historical stack from the root down to the current key
   * @param {*} value The value to check
   * @param {object} options The configurable filtering options (assumed to be valid)
   * @param {?boolean} options.excludeUnderscoreKeys [false] - if keys starting with _ should be excluded
   * @param {?boolean} options.excludeEmberServices [false] - if Ember Service values should be excluded
   * @param {?extractRawDataKeyFilter} options.keyFilter [null] - A filter function to apply to keys which are not already excluded by other means; true will include the key/value pair, false will exclude
   * @param {?extractRawDataValueFilter} options.valueFilter [null] - A filter function to apply to values which are not already excluded by other means; true will includ the key/value pair, false will exclude
   */


  function isAllowed(contextStack, value, options) {
    var key = contextStack[contextStack.length - 1].propertyName;
    var path = contextStack.slice(0, -1).map(function (curr) {
      return curr.propertyName;
    }); // Check Filters

    if (key) {
      if (myTypeOf(key) !== 'string') {
        return false;
      }

      if (options.excludeUnderscoreKeys && key.charAt(0) === '_') {
        return false;
      }

      var keyFilter = options.keyFilter || null;

      if (keyFilter && !keyFilter(path, key)) {
        return false;
      }
    }

    var valueType = myTypeOf(value);

    if (ALLOWED_VALUE_TYPES.indexOf(valueType) < 0) {
      return false;
    }

    if (options.excludeEmberServices && valueType === 'instance' && value instanceof Service) {
      return false;
    }

    if (options.excludeEmberApplications && valueType === 'instance' && value instanceof Application) {
      return false;
    }

    var valueFilter = options.valueFilter || null;

    if (valueFilter && !valueFilter([].concat(_toConsumableArray(path), [key]), value)) {
      return false;
    }

    return true;
  }

  function buildNewContextStack(currStack, scope, propertyName) {
    var newStackItem = {
      scope: scope,
      propertyName: propertyName
    };

    if (currStack) {
      return [].concat(_toConsumableArray(currStack), [newStackItem]);
    } else {
      return [newStackItem];
    }
  }

  function myTypeOf(value) {
    if (_typeof(value) === 'symbol') {
      return 'symbol';
    }

    if (value instanceof _dates.default) {
      return 'moment';
    }

    return typeOf(value);
  }
  /**
   * Utility to determine if object is destroyed
   * or being destroyed.
   * @param obj - Ember object to check
   * @returns boolean
   */


  function isDestroyed(obj) {
    return get(obj, 'isDestroyed') || get(obj, 'isDestroying');
  }

  var _default = {
    extractRawData: extractRawData
  };
  _exports.default = _default;
});