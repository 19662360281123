define("web-directory/components/fixtable-empty-state", ["exports", "fixtable-ember/components/fixtable-empty-state"], function (_exports, _fixtableEmptyState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _fixtableEmptyState.default;
    }
  });
});