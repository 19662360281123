define("web-directory/adapters/meeting", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = _emberData.default.RESTAdapter.extend({
    session: inject.service(),
    application: inject.service(),
    host: computed.reads('application.purecloudV2BaseUri'),
    namespace: 'api/v2',
    init: function init() {
      this._super.apply(this, arguments);
    },
    urlForFindAll: function urlForFindAll() {
      var userId = this.get('session.person.guid');
      return "".concat(this.get('host'), "/").concat(this.get('namespace'), "/users/").concat(userId, "/meetings");
    },
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var meeting = snapshot.attr('meeting');

      if (meeting) {
        var meetingId = meeting.id;
        return "".concat(this.get('host'), "/").concat(this.get('namespace'), "/meetings/").concat(meetingId, "/occurrences/").concat(id);
      }

      return "".concat(this.get('host'), "/").concat(this.get('namespace'), "/meetings/").concat(id);
    },
    urlForQuery: function urlForQuery() {
      return this.urlForFindAll();
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      if (!!Ember.get(snapshot, 'adapterOptions.updateSeries')) {
        return "".concat(this.get('host'), "/").concat(this.get('namespace'), "/meetings/").concat(id);
      }

      var meetingId = snapshot.attr('meeting').id;
      return "".concat(this.get('host'), "/").concat(this.get('namespace'), "/meetings/").concat(meetingId, "/occurrences/").concat(id);
    },
    urlForCreateRecord: function urlForCreateRecord() {
      return "".concat(this.get('host'), "/").concat(this.get('namespace'), "/meetings");
    },
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      var meeting = snapshot.attr('meeting');

      if (!meeting) {
        return "".concat(this.get('host'), "/").concat(this.get('namespace'), "/meetings/").concat(id);
      }

      var meetingId = meeting.id;

      if (!!Ember.get(snapshot, 'adapterOptions.deleteSeries')) {
        return "".concat(this.get('host'), "/").concat(this.get('namespace'), "/meetings/").concat(meetingId);
      }

      return "".concat(this.get('host'), "/").concat(this.get('namespace'), "/meetings/").concat(meetingId, "/occurrences/").concat(id);
    }
  });

  _exports.default = _default;
});