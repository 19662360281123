define("web-directory/components/date-picker/component", ["exports", "lodash", "web-directory/utils/dates"], function (_exports, _lodash, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var minViewMode = {
    days: 0,
    months: 1,
    years: 2
  };

  var _default = Ember.Component.extend({
    classNames: ['date-picker'],
    label: null,
    srOnly: false,
    intl: Ember.inject.service(),
    entity: null,
    // now takes moment.js format
    value: null,
    placeholder: null,
    minDate: null,
    maxDate: null,
    inputMode: false,
    format: null,
    minView: 'days',
    //months
    isoLocale: Ember.computed.reads('intl.isoLocale'),

    /**
     * Some dates need to be displayed as just Month and Year.
     * The ordering and separator depends on user's locale (e.g., Japanese (ja) should display YYYY/M and German (de) would prefer M.YYYY)
     * Moment does not provide an api to do this.
     */
    monthYearLocaleFormat: Ember.computed('value', function () {
      _dates.default.locale(this.get('isoLocale'));

      var now = (0, _dates.default)();
      var localeFormat = now.creationData().locale.longDateFormat('L');
      var regex = /DD\W|\WDD/g;
      localeFormat = localeFormat.replace(regex, '');
      return localeFormat.replace(/MM/, 'M');
    }),
    // Used to populate the bootstrap datepicker in setupDatepicker below.
    datepickerValue: Ember.computed('value', function () {
      var monthYearLocaleFormat = 'L';

      if (this.get('format') === 'monthYear') {
        monthYearLocaleFormat = this.get('monthYearLocaleFormat');
      }

      var value = this.get('value');
      var dateObject = (0, _dates.default)();

      if (!_lodash.default.isEmpty(value)) {
        var year = Ember.get(value, 'year');
        var month = Ember.get(value, 'month') - 1;
        var day = Ember.get(value, 'day'); // We don't chain calls in case year or month is empty/null

        dateObject.year(year);
        dateObject.month(month);
        dateObject.date(day);
      }

      if (_lodash.default.isEmpty(this.get('value'))) {
        return '';
      }

      var view = this.get('minView');

      if (view === 'months') {
        return dateObject.format(monthYearLocaleFormat);
      }

      return dateObject.format(monthYearLocaleFormat);
    }),
    initDatepicker: Ember.on('didInsertElement', function () {
      Ember.run.scheduleOnce('afterRender', this, 'setupDatepicker');
    }),
    removeBehavior: Ember.on('willDestroyElement', function () {
      this.$('.datepicker').datepicker('remove');
    }),
    didUpdateAttrs: function didUpdateAttrs(changes) {
      this._super.apply(this, arguments);

      if (changes.newAttrs.entity.value !== changes.oldAttrs.entity.value) {
        this.$('.datepicker').datepicker('update', this.get('datepickerValue'));
      }
    },
    // This is called when editing the field. It initializes the bootstrap datepicker and passes in the values from the datepickerValue computed.
    setupDatepicker: function setupDatepicker() {
      var _this = this;

      if (!this.get('inputMode')) {
        return;
      } // lowercase because bootstrap datepicker expects mm instead of MM


      var format = this.get('format') || _dates.default.localeData()._longDateFormat.L.toLowerCase(); // if the format is monthYear then get the localized format and pass that to the datepicker


      var monthYearLocaleFormat = format === 'monthYear' ? this.get('monthYearLocaleFormat').toLowerCase() : format;
      var minView = Ember.get(minViewMode, this.get('minView'));
      var language = this.get('intl.isoRegionalLocale');
      var options = {
        orientation: 'left bottom',
        format: monthYearLocaleFormat,
        minViewMode: minView,
        todayHighlight: true,
        clearBtn: true,
        language: language,
        container: "#".concat(this.get('elementId'))
      };
      var minDate = this.get('minDate');

      if (minDate) {
        options = Object.assign({}, options, {
          startDate: (0, _dates.default)(minDate).toDate()
        });
      }

      var maxDate = this.get('maxDate');

      if (maxDate) {
        options = Object.assign({}, options, {
          endDate: (0, _dates.default)(maxDate).toDate()
        });
      } // Some bootstrapper-datepicker languages do not include a
      // translation for the "Clear" button


      var dates = $.fn.datepicker.dates;

      if (dates[language] && !dates[language].clear) {
        var selectedLanguage = this.get('intl.isoLocale');

        if (selectedLanguage == language) {
          dates[language].clear = this.get('intl').t('common.clear');
        }
      } // bootstrap datepicker is setup with datePickerValue from above.


      this.$('.datepicker').datepicker(options).on('changeDate', function (event) {
        Ember.run(function () {
          return _this.setData(event);
        });
      }).on('clearDate', function (event) {
        Ember.run(function () {
          return _this.setData(event);
        });
      }).on('hide', function (event) {
        Ember.run(function () {
          return _this.setData(event);
        });
      }).on('show', function () {
        Ember.run(function () {
          var classNames = _this.get('classNames');

          var datepicker = _this.$('.datepicker').data('datepicker');

          if (datepicker && datepicker.picker) {
            datepicker.picker.addClass(classNames.join(' '));
          }
        });
      }).datepicker('update', this.get('datepickerValue'));

      if (_lodash.default.isEmpty(this.get('value')) || !this.get('value.month') && !this.get('value.year')) {
        this.$('input').val('');
      }
    },
    setData: function setData(event) {
      var date;
      var str = this.$('input').val();

      _dates.default.locale(this.get('intl.isoLocale'));

      if (event.date && !_lodash.default.isEmpty(str)) {
        date = (0, _dates.default)(event.date);
      } else {
        date = (0, _dates.default)(str);

        if (_lodash.default.isEmpty(str) || isNaN(date.valueOf())) {
          return this.clearValue();
        }
      }

      var value = {};
      var view = this.get('minView');
      var day = date.date();
      var month = date.month() + 1;
      var year = date.year();

      if (view === 'days') {
        Ember.set(value, 'day', day);
        Ember.set(value, 'month', month);
      } else if (view === 'months') {
        Ember.set(value, 'month', month);
      }

      Ember.set(value, 'year', year);
      this.set('value', value);
    },
    clearValue: function clearValue() {
      if (_lodash.default.isEmpty(this.get('value'))) {
        return;
      }

      if (this.get('outOfOffice')) {
        // TODO: remove check for outOfOffice feature toggle when on in all environments
        this.set('value', null);
      } else {
        this.set('value.day', null);
        this.set('value.month', null);
        this.set('value.year', null);
      }
    },
    _placeholder: Ember.computed('placeholder', function () {
      var placeholder = this.get('placeholder');

      if (placeholder) {
        return this.get('intl').lookup(placeholder, null, true).toString();
      }

      return '';
    })
  });

  _exports.default = _default;
});