define("web-directory/components/acd-interactions-panel/interaction-voicemail/component", ["exports", "web-directory/mixins/acd-utilities", "ember-purecloud-components/mixins/logger"], function (_exports, _acdUtilities, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      inject = Ember.inject,
      computed = Ember.computed;

  var _default = Component.extend(_acdUtilities.default, _logger.default, {
    classNames: ['interaction-voicemail'],
    classNameBindings: ['audioPlayerOptions.basicAudioPlayer:basic-audio-player'],
    acdVoicemailService: inject.service('acdvoicemail'),
    webrtc: inject.service('webrtc'),
    session: inject.service('session'),
    selectedInteraction: null,
    loadingVoicemail: false,
    voicemail: null,
    voicemailId: null,
    errorLoadingVoicemail: false,
    audioPlayerOptions: {
      basicAudioPlayer: true
    },
    shouldPlayAudioFromSelectedProfileDevice: computed.reads('session.features.playRecordingFromSelectedOutput'),
    audioOutputDeviceId: computed('webrtc.defaultOutputDevice.deviceId', function () {
      if (!this.get('shouldPlayAudioFromSelectedProfileDevice')) {
        return null;
      }

      return this.get('webrtc.defaultOutputDevice.deviceId');
    }),
    getVoicemail: function getVoicemail(voicemailId) {
      var _this = this;

      this.set('loadingVoicemail', true);
      this.set('errorLoadingVoicemail', false);
      this.get('acdVoicemailService').getVoicemailMediaInfo(voicemailId).then(function (voicemail) {
        var mediaInfo = {
          mediaUri: voicemail.mediaFileUri,
          waveformData: voicemail.waveformData
        };
        var audioObject = {
          mediaUris: [mediaInfo]
        };

        _this.set('voicemail', audioObject);

        _this.set('voicemailId', voicemailId);
      }).catch(function (err) {
        _this.set('errorLoadingVoicemail', true);

        var messageKey = 'purecloud.acdInteraction.voicemail.errors.loadingVoicemail';

        _this._displayError(messageKey);

        _this.logger.error('ACD: Error retrieving voicemail:', {
          error: err,
          remoteData: {
            voicemailId: voicemailId
          }
        });
      }).finally(function () {
        _this.set('loadingVoicemail', false);
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var newVoicemailId = this.get('selectedInteraction.participantForCurrentUser.callbacks.firstObject.voicemail.id');

      if (this.get('selectedInteraction') && newVoicemailId && this.get('voicemailId') != newVoicemailId) {
        this.getVoicemail(newVoicemailId);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.set('voicemail', null);
    }
  });

  _exports.default = _default;
});