define("web-directory/services/file-upload", ["exports", "ember-ajax/services/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    application: Ember.inject.service(),
    ajax: Ember.inject.service(),
    trustedHosts: Ember.computed.alias('ajax.trustedHosts'),
    upload: function upload(uri, formData) {
      return this.raw(uri, {
        contentType: false,
        processData: false,
        data: formData,
        method: 'POST'
      });
    },

    /**
     * Fax upload using provided headers. This will use the Darth service for upload.
     * @param {*} uri
     * @param {*} formData
     * @param {*} headers
     * @returns
     */
    faxUploadWithHeaders: function faxUploadWithHeaders(uri, formData, headers) {
      var file = formData.get('file');

      if (file) {
        return this.raw(uri, {
          contentType: false,
          processData: false,
          // darth upload doesn't like the form data object for all file types
          // using the raw file appears to work consistently
          data: file,
          method: 'PUT',
          headers: headers,
          dataType: 'text' // fax upload will have an empty response

        });
      } else {
        Ember.Logger.error('<file-upload/faxUploadWithHeaders> file is undefined!');
      }
    }
  });

  _exports.default = _default;
});