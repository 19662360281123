define("web-directory/session-stores/application", ["exports", "web-directory/config/environment", "web-directory/utils/dates", "ember-simple-auth/session-stores/adaptive", "ember-simple-auth/session-stores/local-storage", "ember-simple-auth/session-stores/ephemeral", "web-directory/utils/net", "@purecloud/pc-ui-auth/dist/host"], function (_exports, _environment, _dates, _adaptive, _localStorage, _ephemeral, _net, _host) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; } /* globals Modernizr */

  var DEFAULT_STORAGE_KEY = 'pc_auth';

  var DEV_COOKIE_EXPIRATION_SECONDS = _dates.default.duration(10, 'days').asSeconds();

  var LEGACY_PROP = 'secure';
  var CURRENT_PROP = 'authenticated';
  var BaseStore = _ephemeral.default;
  var mode = 'ephemeral';
  var storageKey = DEFAULT_STORAGE_KEY;

  if (_environment.default.environment === 'development') {
    BaseStore = _adaptive.default;
    mode = 'adaptive';

    try {
      var currEnvDetails = _net.default.getEnvDetails();

      if (currEnvDetails && currEnvDetails.local) {
        storageKey = "".concat(DEFAULT_STORAGE_KEY, "-localhost-").concat(currEnvDetails.env);
      }
    } catch (e) {// Unknown env, prob testing ... just use base storage key
    }
  } else if (Modernizr && Modernizr.localstorage) {
    BaseStore = _localStorage.default;
    mode = 'localStorage';
  }
  /**
   * Custom session store that supports:
   *
   * <ul>
   *   <li>All Modes: Backwards compatible with ESA 0.8.3 and previous PC deploys under 0.8.3</li>
   *   <li>Prod Mode: Local Storage persistance with Ephemeral fallback</li>
   *   <li>Dev Mode: Multi-env concurrent authentication to our envs (dev, test, stage, prod) when in dev mode</li>
   *   <li>Dev Mode: Cookie fallback if local storage is unavailable (e.g. Private Safari, Some Mobile)</li>
   * </ul>
   */


  var _default = BaseStore.extend({
    init: function init() {
      var storeProps = null;

      switch (mode) {
        case 'localStorage':
          storeProps = {
            key: storageKey
          };
          break;

        case 'adaptive':
          storeProps = {
            localStorageKey: storageKey,
            cookieName: storageKey,
            cookieExpirationTime: DEV_COOKIE_EXPIRATION_SECONDS
          };
          break;

        default:
          storeProps = {};
      }

      this.setProperties(storeProps);

      this._super.apply(this, arguments);
    },

    /*
     * Override persist/restore to ensure the pre-ESA1.x key
     * - is used as the restored property when new Collaborate version lands
     * - is persisted to localstorage for direct Embeded application use
     */
    persist: function persist(data) {
      data[LEGACY_PROP] = data[CURRENT_PROP]; // Send the auth token to client applications.

      var token = data[CURRENT_PROP].access_token;

      _host.default.sendTokenToClients(token);

      return this._super(data);
    },
    restore: function restore() {
      return this._super.apply(this, arguments).then(function (data) {
        var payload;

        if (data && data[CURRENT_PROP]) {
          payload = data[CURRENT_PROP];
        } else if (data && data[LEGACY_PROP]) {
          // Bridge from 'secure' key to 'authenticated'
          payload = data[LEGACY_PROP]; // Bridge from 'authenticators' registration type to 'authenticator'

          if (payload.authenticator) {
            payload.authenticator = payload.authenticator.replace(/^authenticators:/, 'authenticator:');
          }
        }

        return _defineProperty({}, CURRENT_PROP, payload);
      });
    }
  });

  _exports.default = _default;
});