define("web-directory/components/expander-plugin/component", ["exports", "web-directory/utils/masonry"], function (_exports, _masonry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ExpanderPluginComponent = Ember.Component.extend({
    tagName: 'div',
    classNames: ['expander'],
    intl: Ember.inject.service(),
    init: function init() {
      this._super();
    },
    addBehavior: Ember.on('didInsertElement', function () {
      var intl = this.get('intl');
      var expandText = intl.lookup('common.more').toString().toLowerCase();
      var collapseText = intl.lookup('common.less').toString().toLowerCase();
      Ember.run.scheduleOnce('afterRender', this, function () {
        var $el = this.$();

        if ($el) {
          $el.expander({
            expandText: expandText + ' &raquo;',
            userCollapseText: '&laquo; ' + collapseText,
            afterExpand: _masonry.rerunLayouts,
            afterCollapse: _masonry.rerunLayouts
          });
        }
      });
    }),
    removeBehavior: Ember.on('willDestroyElement', function () {
      this.$().expander('destroy');
    })
  });
  var _default = ExpanderPluginComponent;
  _exports.default = _default;
});