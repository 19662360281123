define("web-directory/models/unrecoverable-app-failure", ["exports", "web-directory/utils/types"], function (_exports, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isGeneralUnrecoverableAppFailure = isGeneralUnrecoverableAppFailure;
  _exports.isSessionBootstrapFailure = isSessionBootstrapFailure;
  _exports.isUnrecoverableAppFailure = isUnrecoverableAppFailure;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var GeneralUnrecoverableAppFailureTypes = ['invalidEnv', 'timeMachineFailure', 'missingAuthenticator', 'generalAuthenticationFailure', 'sessionInvalidationFailure', 'profileFetchServerFailure'];
  var SessionBootstrapSteps = ['loadFeatureToggles', 'setupTheme', 'setupUserAndTrustee', 'setupLogCapture', 'establishDirectorySession', 'initServerTranslations', 'loadPresences', 'loadUserDetails', 'loadUserFavorites', 'setupCarrierPigeon', 'initAppSession', 'loadOrgPermissions', 'loadOrgTranscriptSettings', 'loadPerson'];

  function isGeneralUnrecoverableAppFailure(toCheck) {
    if (!toCheck || _typeof(toCheck) !== 'object') {
      return false;
    }

    if (!(0, _types.hasKey)(toCheck, 'type') || typeof toCheck.type !== 'string') {
      return false;
    }

    return (0, _types.includes)(GeneralUnrecoverableAppFailureTypes, toCheck.type);
  }

  function isSessionBootstrapFailure(toCheck) {
    if (!toCheck || _typeof(toCheck) !== 'object') {
      return false;
    }

    if (!(0, _types.hasKey)(toCheck, 'type') || toCheck.type !== 'sessionBootstrapError') {
      return false;
    }

    if ((0, _types.hasKey)(toCheck, 'status') && !['undefined', 'number'].includes(_typeof(toCheck.status))) {
      return false;
    }

    if (!(0, _types.hasKey)(toCheck, 'failedStep') || typeof toCheck.failedStep !== 'string') {
      return false;
    }

    return (0, _types.includes)(SessionBootstrapSteps, toCheck.failedStep);
  }

  function isUnrecoverableAppFailure(toCheck) {
    return isGeneralUnrecoverableAppFailure(toCheck) || isSessionBootstrapFailure(toCheck);
  }
});