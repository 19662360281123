define("web-directory/components/snail-progress/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    large: false,
    small: false,
    lighten: false,
    classNameBindings: ['large:snail-progress-lg', 'small:snail-progress-sm', 'lighten:snail-progress-light'],
    classNames: ['snail-progress'],
    clock: Ember.inject.service(),
    _activeSegment: -1,
    activeSegment: Ember.computed('clock.second', function () {
      var result = this.incrementProperty('_activeSegment');

      if (result > 2) {
        result = 0;
        this.set('_activeSegment', result);
      }

      return result;
    })
  });

  _exports.default = _default;
});