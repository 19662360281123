define("web-directory/components/entity-field-section/add-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HQmnxpKN",
    "block": "{\"statements\":[[9,\"gux-button\",{\"attrs\":[[15,\"class\",\"add-new-button\"],[16,\"onclick\",[33,[\"action\"],[[28,[null]],\"toggleAddValue\"],null],null],[16,\"aria-expanded\",[34,[[26,[\"showDropdown\"]]]]],[15,\"role\",\"button\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[[0,\"\\n    \"],[11,\"span\",[]],[13],[0,\"\\n        \"],[9,\"gux-icon\",{\"attrs\":[[15,\"icon-name\",\"ic-add-bold\"],[15,\"decorative\",\"true\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n    \"],[14],[0,\"\\n    \"],[1,[33,[\"t\"],[\"fieldSection.addNew\"],null],false],[0,\"\\n\"]]}],[0,\"\\n\\n\"],[11,\"ul\",[]],[16,\"class\",[34,[\"dropdown-menu add-new-dropdown \",[33,[\"if\"],[[28,[\"showDropdown\"]],\"show\"],null]]]],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"fieldValues\"]]],null,{\"statements\":[[0,\"        \"],[11,\"li\",[]],[15,\"class\",\"entity-list-item\"],[13],[0,\"\\n            \"],[11,\"a\",[]],[15,\"href\",\"#\"],[16,\"class\",[34,[\"add-new-entity-\",[28,[\"fieldValue\",\"fieldPathClass\"]]]]],[5,[\"action\"],[[28,[null]],\"addNewEntry\",[28,[\"fieldValue\"]]]],[13],[0,\"\\n                \"],[1,[28,[\"fieldValue\",\"i18nLabel\"]],false],[0,\"\\n            \"],[14],[0,\"\\n        \"],[14],[0,\"\\n\"]],\"locals\":[\"fieldValue\"]},null],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/entity-field-section/add-new/template.hbs"
    }
  });

  _exports.default = _default;
});