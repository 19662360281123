define("web-directory/components/create-entity-menu/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed,
      inject = Ember.inject;

  var _default = Component.extend({
    classNameBindings: [':create-entity-menu', 'showMenu::hidden'],
    application: inject.service(),
    permissions: inject.service(),
    showCreateGroup: false,
    canInvitePeople: computed.reads('permissions.canInvitePeople'),
    canCreateGroups: computed.reads('permissions.canCreateGroups'),
    canCreateLocations: computed(function () {
      return false;
    }),
    showMenu: computed.or('canInvitePeople', 'canCreateGroups', 'canCreateLocations'),
    adminModalSrc: '/admin/#/directory/people/add',
    actions: {
      launchCreateGroup: function launchCreateGroup() {
        this.set('showCreateGroup', true);
      },
      cancelCreateGroup: function cancelCreateGroup() {
        this.set('showCreateGroup', false);
      },
      showAdminModal: function showAdminModal() {
        this.set('showAdminModal', true);
      },
      closeAdminModal: function closeAdminModal() {
        this.set('showAdminModal', false);
      }
    }
  });

  _exports.default = _default;
});