define("web-directory/utils/keystroke", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.eventToString = eventToString;
  _exports.isAlphabet = isAlphabet;
  _exports.isEscape = isEscape;
  _exports.isNavigation = isNavigation;
  _exports.isNumber = isNumber;
  _exports.isTab = isTab;
  _exports.isWhitespace = isWhitespace;
  _exports.keyForKeyCode = keyForKeyCode;
  var WHITE_SPACE_KEYCODES = {
    TAB: 9,
    ENTER: 13,
    SPACE: 32
  };
  var WHITE_SPACE_KEYS = {
    TAB: 'Tab'
  };
  var CAP_ALPHA_KEYCODES = {
    A: 65,
    B: 66,
    C: 67,
    D: 68,
    E: 69,
    F: 70,
    G: 71,
    H: 72,
    I: 73,
    J: 74,
    K: 75,
    L: 76,
    M: 77,
    N: 78,
    O: 79,
    P: 80,
    Q: 81,
    R: 82,
    S: 83,
    T: 84,
    U: 85,
    V: 86,
    W: 87,
    X: 88,
    Y: 89,
    Z: 90
  };
  var LOW_ALPHA_KEYCODES = {
    A: 97,
    B: 98,
    C: 99,
    D: 100,
    E: 101,
    F: 102,
    G: 103,
    H: 104,
    I: 105,
    J: 106,
    K: 107,
    L: 108,
    M: 109,
    N: 110,
    O: 111,
    P: 112,
    Q: 113,
    R: 114,
    S: 115,
    T: 116,
    U: 117,
    V: 118,
    W: 119,
    X: 120,
    Y: 121,
    Z: 122
  };
  var NUM_KEYCODES = {
    0: 48,
    1: 49,
    2: 50,
    3: 51,
    4: 52,
    5: 53,
    6: 54,
    7: 55,
    8: 56,
    9: 57
  };
  var NAV_KEYCODES = {
    LEFT_ARROW: 37,
    UP_ARROW: 38,
    RIGHT_ARROW: 39,
    DOWN_ARROW: 40
  };
  var ESCAPE_KEYCODE = 27;

  function keyForKeyCode(keyCode, object) {
    var key;

    for (key in object) {
      if (object.hasOwnProperty(key) && object[key] === keyCode) {
        return key;
      }
    }

    return null;
  }

  function isNumber(evt) {
    var keyCode = evt.keyCode;
    return keyCode >= NUM_KEYCODES[0] && keyCode <= NUM_KEYCODES[9];
  }

  function isAlphabet(evt) {
    var keyCode = evt.keyCode;
    return keyCode >= CAP_ALPHA_KEYCODES.A && keyCode <= CAP_ALPHA_KEYCODES.Z || keyCode >= LOW_ALPHA_KEYCODES.A && keyCode <= LOW_ALPHA_KEYCODES.Z;
  }

  function isNavigation(evt) {
    var keyCode = evt.keyCode;
    return keyCode >= NAV_KEYCODES.LEFT_ARROW && keyCode <= NAV_KEYCODES.DOWN_ARROW;
  }

  function isWhitespace(evt) {
    var keyCode = evt.keyCode;
    return keyCode === WHITE_SPACE_KEYCODES.SPACE || keyCode === WHITE_SPACE_KEYCODES.ENTER || keyCode === WHITE_SPACE_KEYCODES.TAB;
  }

  function isEscape(evt) {
    var keyCode = evt.keyCode;
    return keyCode === ESCAPE_KEYCODE;
  }

  function isTab(evt) {
    var keyCode = evt.keyCode;
    var key = evt.key;
    return keyCode === WHITE_SPACE_KEYCODES.TAB || key === WHITE_SPACE_KEYS.TAB;
  }

  function eventToString(evt) {
    var keyCode = evt.keyCode;

    if (isAlphabet(evt) || isNumber(evt)) {
      return String.fromCharCode(keyCode);
    } else if (isNavigation(evt)) {
      return keyForKeyCode(keyCode, NAV_KEYCODES);
    } else if (isWhitespace(evt)) {
      return keyForKeyCode(keyCode, WHITE_SPACE_KEYCODES);
    } else if (isEscape(evt)) {
      return 'ESCAPE';
    }

    return '' + keyCode;
  }

  var _default = {
    isAlphabet: isAlphabet,
    isEscape: isEscape,
    isNavigation: isNavigation,
    isNumber: isNumber,
    isTab: isTab,
    isWhitespace: isWhitespace,
    eventToString: eventToString
  };
  _exports.default = _default;
});