define("web-directory/components/acd-interactions-panel/interaction-card-v2/base-card/component", ["exports", "web-directory/components/acd-interactions-panel/interaction-card-v2/base-card/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,

    /**
     * @Attribute
     * `true` if the current user participant is on barge-in
     */
    iAmOnBargeIn: false,

    /**
     * @Attribute
     * `true` if the current user participant is on coach
     */
    iAmOnCoach: false,

    /**
     * @Attribute
     * `true` if the current user participant is on monitor
     */
    iAmOnMonitor: false,

    /**
     * @Attribute
     * Conversation represented by card
     */
    interaction: null,

    /**
     * @Attribute
     * Name of participant (sup/agent) monitoring/ed or coaching/ed or barge-in
     */
    mcbParticipantName: null,
    bargeLabel: Ember.computed('iAmOnBargeIn', function () {
      if (this.get('iAmOnBargeIn')) {
        return 'purecloud.acdInteraction.roster.bargedBy';
      }

      return 'purecloud.acdInteraction.roster.bargedPerson';
    }),
    coachLabel: Ember.computed('iAmOnCoach', function () {
      if (this.get('iAmOnCoach')) {
        return 'purecloud.acdInteraction.roster.coachedBy';
      }

      return 'purecloud.acdInteraction.roster.coachedPerson';
    }),
    monitorLabel: Ember.computed('iAmOnMonitor', function () {
      if (this.get('iAmOnMonitor')) {
        return 'purecloud.acdInteraction.roster.monitoredBy';
      }

      return 'purecloud.acdInteraction.roster.monitoredPerson';
    }),

    /**
     * State attribute which should be implemented by media component
     */
    state: function () {
      Ember.Logger.debug('ACD: Roster state not set for Roster Card component.');
      return null;
    }(),

    /**
     * Supplemental state attribute which should be implemented by media component
     */
    supplementalState: function () {
      Ember.Logger.debug('ACD: Roster supplementalState not set for Roster Card component.');
      return null;
    }(),

    /**
     * Convenience alias for interaction type
     */
    type: Ember.computed.readOnly('interaction.type'),

    /**
     * True/false if conversation is disconnected
     */
    inWrapup: Ember.computed.or('interaction.isTerminated', 'interaction.isDisconnected'),

    /**
     * `true` if the interaction is currently determined to be transferring to another participant while being
     * attended by the initiated participant
     */
    isAttendedTransferring: Ember.computed.readOnly('interaction.isAttendedTransferring'),

    /**
     * true if the interaction is in muted, consult, conference or mcb
     */
    hasInteractionStatus: Ember.computed.or('iAmOnBargeIn', 'iAmOnMonitor', 'iAmOnCoach', 'interaction.onConsult', 'interaction.isActiveConference', 'interaction.myselfIsMonitor', 'interaction.myselfIsCoach', 'interaction.myselfIsBarged', 'isMuted')
  });

  _exports.default = _default;
});