define("web-directory/app", ["exports", "web-directory/resolver", "ember-concurrency-test-waiter/define-modifier", "ember-load-initializers", "web-directory/config/environment", "lodash", "web-directory/utils/query-parser", "genesys-spark-components", "custom-event-polyfill/polyfill", "url-polyfill", "iframe-coordinator", "@genesys/genesys-cloud-webcomponents/dist/loader"], function (_exports, _resolver, _defineModifier, _emberLoadInitializers, _environment, _lodash, _queryParser, _genesysSparkComponents, _polyfill, _urlPolyfill, _iframeCoordinator, _loader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  (0, _genesysSparkComponents.registerElements)(); // Polyfills for iframe-coordinator

  /* eslint-disable no-unused-vars */
  // These imports shouldn't need to be assigned to variables, but without
  // importing this way, babel 6 will load them in after the iframe-coordinator
  // module, defeating the point of the polyfills.

  /* eslint-enable no-unused-vars */

  // Enable the iframe-coordinator custom elements
  _iframeCoordinator.default.registerCustomElements();

  (0, _loader.defineCustomElements)();
  /* eslint-disable */

  /* eslint-enable */
  if (_environment.default.APP && _environment.default.APP.LOG_RUNLOOP_STACKTRACE) {
    Ember.run.backburner.DEBUG = true;
  }

  if (_environment.default.APP && _environment.default.APP.LOG_BINDINGS) {
    Ember.LOG_BINDINGS = true;
  }

  var inject = Ember.inject,
      Application = Ember.Application; // allows ember concurrency tasks to use withTestWaiter()

  (0, _defineModifier.default)();
  var App = Application.extend(_environment.default.APP, {
    cookies: inject.service('cookies'),
    stashLogger: inject.service('stash-logger'),
    applicationService: inject.service('application'),
    customEvents: {
      // frame-router events from iframe-coordinator library
      navRequest: 'navRequest',
      notifyRequest: 'notifyRequest',
      modalRequest: 'modalRequest',
      // Pagination events from inbox-v2
      pageChanged: 'pageChanged',
      itemsPerPageChanged: 'itemsPerPageChanged',
      // GCW Events
      favoriteEvent: 'favoriteEvent',
      contactEvent: 'contactEvent',
      navigateToProfileEvent: 'navigateToProfileEvent'
    },
    checkAccessibility: function checkAccessibility() {
      if (window.axeLive) {
        window.axeLive.run({
          runOnly: {
            type: 'tag',
            values: ['wcag2a', 'wcag2aa', 'wcag21aa']
          }
        });
      }
    },
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default,
    engines: {
      emberEngineCustomFields: {
        dependencies: {
          services: ['application', 'intl', 'session', 'stash-logger', 'ajax', 'store', 'notification', 'permissions', 'sidebar', 'adminNav', 'adminui', 'schema'],
          externalRoutes: {
            personDetail: 'person.detail',
            admin: 'admin'
          }
        }
      }
    },
    entityTypes: ['person', 'group'],
    //TODO - 10/12/14 - Is this used anymore?
    currentRouteName: null,
    // Set by ApplicationController
    debug: false,
    init: function init() {
      this.initVersion();
      this.initContext();
      return this._super.apply(this, arguments);
    },
    isImpersonating: Ember.computed('session.person.id', function () {
      var proxyPersonId = this.get('cookies').read('proxyPerson');

      if (proxyPersonId) {
        return proxyPersonId === this.get('session.person.id');
      }

      return false;
    }),
    iframed: function iframed() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
    initVersion: function initVersion() {
      Ember.libraries.register('PureCloud Collaborate', this.get('semver'));

      if (!Ember.ENV.LOG_VERSION) {
        Ember.Logger.debug('PureCloud Collaborate', this.get('semver'));
      }

      var libs = this.libs || {};

      _lodash.default.forOwn(libs, function (version, key) {
        Ember.libraries.register(key, version);

        if (!Ember.ENV.LOG_VERSION) {
          Ember.Logger.debug(key, version);
        }
      });
    },
    initContext: function initContext() {
      var params = (0, _queryParser.parseQueryParams)();

      if (params.context === 'adminui' && this.iframed()) {
        // we don't want to hide the navigation 'chrome' unless iframed
        this.set('context', 'adminui');
      } else {
        this.set('context', 'directory');
      }
    },
    // Debugging utility functions...not for use in app logic
    controllerFor: function controllerFor(name) {
      return this.instance.lookup("controller:".concat(name));
    },
    // Debugging utility functions...not for use in app logic
    serviceFor: function serviceFor(name) {
      return this.instance.lookup("service:".concat(name));
    },
    // Debugging utility functions...not for use in app logic
    componentFor: function componentFor(id) {
      return this.instance.lookup('-view-registry:main')[id];
    },
    listAsync: function listAsync() {
      this.resolveRegistration('util:debug-backburner').logTimerQueue();
      this.resolveRegistration('util:debug-rsvp').logPendingPromises();
    },
    promiseStackFor: function promiseStackFor(id) {
      this.resolveRegistration('util:debug-rsvp').lookupPromiseStack(id);
    }
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  Error.stackTraceLimit = 100;
  var _default = App;
  _exports.default = _default;
});