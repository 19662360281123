define("web-directory/components/audio-recorder/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7IF6BgGG",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"hasSupport\"]]],null,{\"statements\":[[0,\"    \"],[11,\"div\",[]],[16,\"class\",[34,[\"recording-icon \",[33,[\"if\"],[[28,[\"recording\"]],\"recording\"],null]]]],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"recording\"]]],null,{\"statements\":[[0,\"            \"],[11,\"a\",[]],[15,\"href\",\"#\"],[15,\"class\",\"stop-recording\"],[5,[\"action\"],[[28,[null]],\"stopRecording\"]],[13],[0,\"\\n                \"],[11,\"i\",[]],[15,\"class\",\"pc pc-stop\"],[13],[14],[0,\"\\n            \"],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"            \"],[11,\"a\",[]],[15,\"href\",\"#\"],[15,\"class\",\"start-recording\"],[5,[\"action\"],[[28,[null]],\"startRecording\"]],[13],[0,\"\\n                \"],[11,\"i\",[]],[15,\"class\",\"pc-moon pc-record\"],[13],[14],[0,\"\\n            \"],[14],[0,\"\\n\"]],\"locals\":[]}],[0,\"    \"],[14],[0,\"\\n\\n\"],[6,[\"if\"],[[28,[\"audioSource\"]]],null,{\"statements\":[[0,\"        \"],[1,[33,[\"audio-player\"],null,[[\"source\",\"options\"],[[28,[\"audioSource\"]],[28,[\"playerOptions\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"        \"],[11,\"span\",[]],[15,\"class\",\"audio-source-message\"],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"recording\"]]],null,{\"statements\":[[0,\"                \"],[1,[33,[\"t\"],[\"components.audioRecorder.recordingTime\"],[[\"time\"],[[33,[\"time-duration\"],null,[[\"startTime\"],[[28,[\"startTime\"]]]]]]]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"                \"],[1,[33,[\"t\"],[\"components.audioRecorder.noAudio\"],null],false],[0,\"\\n\"]],\"locals\":[]}],[0,\"        \"],[14],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[]},{\"statements\":[[0,\"    \"],[11,\"span\",[]],[15,\"class\",\"no-support\"],[13],[1,[33,[\"t\"],[\"components.audioRecorder.noSupport\"],null],false],[14],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/audio-recorder/template.hbs"
    }
  });

  _exports.default = _default;
});