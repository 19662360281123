define("web-directory/components/login-oauth/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pYPKqw88",
    "block": "{\"statements\":[[6,[\"if\"],[[33,[\"not\"],[[28,[\"isAuthenticated\"]]],null]],null,{\"statements\":[[6,[\"if\"],[[28,[\"offerMobileApp\"]]],null,{\"statements\":[[0,\"        \"],[11,\"div\",[]],[15,\"class\",\"pre-auth-mobile-offer\"],[13],[0,\"\\n\"],[6,[\"modal-component\"],null,[[\"showHeader\",\"showFooter\",\"extraClassNames\",\"destinationElementId\"],[false,false,\"mobile\",\"app-offer-modal-destination\"]],{\"statements\":[[0,\"                \"],[1,[33,[\"mobile-modal\"],null,[[\"sendClose\"],[[33,[\"action\"],[[28,[null]],[28,[\"onMobileOfferDecline\"]]],null]]]],false],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\n            \"],[11,\"div\",[]],[15,\"id\",\"app-offer-modal-destination\"],[13],[0,\"\\n\"],[0,\"            \"],[14],[0,\"\\n        \"],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"        \"],[1,[26,[\"auth-loading\"]],false],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[]},{\"statements\":[],\"locals\":[]}]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/login-oauth/template.hbs"
    }
  });

  _exports.default = _default;
});