define("web-directory/components/alert-message/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var types = {
    success: 'alert-success',
    info: 'alert-info',
    error: 'alert-danger'
  };

  var _default = Ember.Component.extend({
    classNameBindings: [':alert', ':alert-message', 'typeClass', 'show::hide-message'],
    type: null,
    show: null,
    icon: Ember.computed('type', function () {
      var type = this.get('type');

      if (type === 'success') {
        return 'ion-checkmark-circled';
      } else if (type === 'error') {
        return 'ion-close-circled';
      } else {
        return 'ion-information-circled';
      }
    }),
    typeClass: Ember.computed('type', function () {
      return types[this.get('type')];
    })
  });

  _exports.default = _default;
});