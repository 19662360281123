define("web-directory/components/target-dropdown/data/filter-data.const", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createExternalContactsFilter = _exports.createBaseFilters = void 0;

  var createBaseFilters = function createBaseFilters() {
    return {
      all: {
        icon: 'menu',
        value: 'all',
        label: 'purecloud.acdInteraction.conversation.allCount',
        totalElements: undefined
      },
      user: {
        icon: 'user',
        value: 'user',
        label: 'purecloud.acdInteraction.conversation.usersCount',
        totalElements: undefined
      },
      queue: {
        icon: 'timeline',
        value: 'queue',
        label: 'purecloud.acdInteraction.conversation.queuesCount',
        totalElements: undefined
      }
    };
  };

  _exports.createBaseFilters = createBaseFilters;

  var createExternalContactsFilter = function createExternalContactsFilter() {
    return {
      icon: 'user-contact-id',
      value: 'external',
      label: 'purecloud.acdInteraction.conversation.externalContactsCount',
      totalElements: undefined
    };
  };

  _exports.createExternalContactsFilter = createExternalContactsFilter;
});