define("web-directory/mixins/entity-controller", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EntityControllerMixin = Ember.Mixin.create({
    intl: Ember.inject.service(),
    application: Ember.inject.service(),
    entity: null,
    excludedFieldSections: null,
    lastUploadedImage: null,
    editingPictures: false,
    viewingGallery: false,
    actions: {
      cancelEditProfilePicture: function cancelEditProfilePicture() {
        this.set('editingPictures', false);
      },
      editProfilePicture: function editProfilePicture() {
        this.set('editingPictures', true);
      },
      cancelUpload: function cancelUpload() {
        this.send('editProfilePicture');
      },
      cancelEditPictures: function cancelEditPictures() {
        this.set('uploadType', null);
      },
      viewLightGallery: function viewLightGallery() {
        this.set('viewingGallery', true);
      },
      cancelViewLightGallery: function cancelViewLightGallery() {
        this.set('viewingGallery', false);
      },
      noop: function noop() {}
    },
    setInitialFieldSections: Ember.on('init', function () {
      this.set('excludedFieldsSections', []);
    }),
    fieldSections: Ember.computed('entity', function () {
      var entity = this.get('entity');

      if (entity) {
        var sections = this.get('entity').fieldConfig.sections;

        if (this.get('application.session.user.isAdmin') && !this.get('application.session.user.personId')) {
          return _lodash.default.filter(sections, function (section) {
            var excludedSections = this.get('excludedFieldSections');
            return excludedSections.indexOf(section.key) === -1;
          }, this);
        }

        return _lodash.default.filter(sections, function (section) {
          var readableSections = this.get('entity').getReadableSections(),
              excludedSections = this.get('excludedFieldSections');
          return excludedSections.indexOf(section.key) === -1 && readableSections.indexOf(section.key) !== -1;
        }, this);
      }

      return [];
    })
  });
  var _default = EntityControllerMixin;
  _exports.default = _default;
});