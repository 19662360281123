define("web-directory/services/elm", ["exports", "ember-purecloud-components/utils/dates"], function (_exports, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Service.extend({
    intl: inject.service(),
    session: inject.service(),
    permissions: inject.service(),

    /*
     * This should be the only computed in this file, so all updates trigger a change
     * to appContext, and a push to elm modules via observers in components
     */
    appContext: computed('intl.{isoLocale,isRTL}', 'session.person.{isOutOfOffice,isOnQueue}', 'session.person.presenceDefinition.id', 'session.person.primaryPresence.modifiedDate', 'session.enabledFeatures.[]', 'permissions.donutPermissions', function () {
      var translations = this.get('intl._cache')[this.get('intl.isoLocale')] || this.get('intl._cache')[this.get('intl.baseLocale')]; // en-us fallback

      if (translations === undefined) {
        translations = null;
      }

      var isRTL = this.get('intl.isRTL') || false;
      var context = {
        session: this.generateUserSession(),
        translations: translations,
        features: this.get('session.enabledFeatures'),
        permissions: this.get('permissions.donutPermissions'),
        isRTL: isRTL
      };
      return context;
    }),
    generateUserSession: function generateUserSession() {
      var presenceKey = this.get('session.person.presenceDefinition.systemPresence') || 'unknown';
      return {
        presence: {
          id: this.get('session.person.presenceDefinition.id') || 'UNKNOWN',
          key: presenceKey.toLowerCase(),
          modifiedDate: (0, _dates.default)(this.get('session.person.primaryPresence.modifiedDate')).utc().valueOf()
        },
        isOutOfOffice: this.get('session.person.isOutOfOffice') || false,
        isOnQueue: this.get('session.person.isOnQueue') || false
      };
    }
  });

  _exports.default = _default;
});