define("web-directory/cldrs/th", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*jslint eqeq: true*/
  var _default = [{
    "locale": "th",
    "pluralRuleFunction": function pluralRuleFunction(n, ord) {
      if (ord) return "other";
      return "other";
    },
    "fields": {
      "year": {
        "displayName": "ปี",
        "relative": {
          "0": "ปีนี้",
          "1": "ปีหน้า",
          "-1": "ปีที่แล้ว"
        },
        "relativeTime": {
          "future": {
            "other": "ในอีก {0} ปี"
          },
          "past": {
            "other": "{0} ปีที่แล้ว"
          }
        }
      },
      "month": {
        "displayName": "เดือน",
        "relative": {
          "0": "เดือนนี้",
          "1": "เดือนหน้า",
          "-1": "เดือนที่แล้ว"
        },
        "relativeTime": {
          "future": {
            "other": "ในอีก {0} เดือน"
          },
          "past": {
            "other": "{0} เดือนที่ผ่านมา"
          }
        }
      },
      "day": {
        "displayName": "วัน",
        "relative": {
          "0": "วันนี้",
          "1": "พรุ่งนี้",
          "2": "มะรืนนี้",
          "-2": "เมื่อวานซืน",
          "-1": "เมื่อวาน"
        },
        "relativeTime": {
          "future": {
            "other": "ในอีก {0} วัน"
          },
          "past": {
            "other": "{0} วันที่ผ่านมา"
          }
        }
      },
      "hour": {
        "displayName": "ชั่วโมง",
        "relativeTime": {
          "future": {
            "other": "ในอีก {0} ชั่วโมง"
          },
          "past": {
            "other": "{0} ชั่วโมงที่ผ่านมา"
          }
        }
      },
      "minute": {
        "displayName": "นาที",
        "relativeTime": {
          "future": {
            "other": "ในอีก {0} นาที"
          },
          "past": {
            "other": "{0} นาทีที่ผ่านมา"
          }
        }
      },
      "second": {
        "displayName": "วินาที",
        "relative": {
          "0": "ขณะนี้"
        },
        "relativeTime": {
          "future": {
            "other": "ในอีก {0} วินาที"
          },
          "past": {
            "other": "{0} วินาทีที่ผ่านมา"
          }
        }
      }
    }
  }];
  _exports.default = _default;
});