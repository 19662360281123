define("web-directory/models/shift-trade-request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    id: null,
    mutableGroupId: null,
    timestamp: null,
    type: null,
    shiftTrade: null,
    markedAsRead: null,
    agentNotification: null,
    otherNotificationIdsInGroup: null
  });

  _exports.default = _default;
});