define("web-directory/components/active-fax/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fZcQvJaq",
    "block": "{\"statements\":[[11,\"div\",[]],[16,\"class\",[34,[\"fax-number-status \",[28,[\"fax\",\"id\"]]]]],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"fax-number\"],[13],[1,[28,[\"fax\",\"receiverDisplayName\"]],false],[14],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"fax-status\"],[13],[1,[26,[\"faxStatusMessage\"]],false],[14],[0,\"\\n\"],[14],[0,\"\\n\"],[11,\"div\",[]],[16,\"class\",[34,[\"fax-timer \",[26,[\"faxState\"]],\" \",[33,[\"if\"],[[28,[\"faxErrorInfo\"]],\"error\"],null]]]],[13],[0,\"\\n    \"],[11,\"i\",[]],[15,\"class\",\"pc-moon pc-faxes\"],[13],[14],[0,\"\\n\"],[6,[\"if\"],[[28,[\"isDisconnected\"]]],null,{\"statements\":[[0,\"        \"],[1,[33,[\"duration-format\"],null,[[\"startTime\",\"endTime\"],[[28,[\"fax\",\"connectedTime\"]],[28,[\"fax\",\"endTime\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"        \"],[1,[33,[\"duration-format\"],null,[[\"startTime\"],[[28,[\"fax\",\"startTime\"]]]]],false],[0,\"\\n\"]],\"locals\":[]}],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/active-fax/template.hbs"
    }
  });

  _exports.default = _default;
});