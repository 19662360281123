define("web-directory/components/acd-interactions-panel/interaction-conference-roster-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wgYs/7on",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"dropdown-header\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"header-container\"],[13],[0,\"\\n        \"],[11,\"h2\",[]],[13],[0,\"\\n            \"],[1,[33,[\"t\"],[\"purecloud.acdInteraction.conferenceCount\"],[[\"count\"],[[28,[\"participants\",\"length\"]]]]],false],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[11,\"div\",[]],[15,\"class\",\"content\"],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"participants\"]]],null,{\"statements\":[[0,\"        \"],[1,[33,[\"acd-interactions-panel/interaction-conference-roster-dropdown/participant-row\"],null,[[\"participant\",\"isAuthUser\",\"mute\",\"dismiss\"],[[28,[\"participant\"]],[33,[\"eq\"],[[28,[\"participant\",\"userId\"]],[28,[\"authUser\",\"id\"]]],null],[33,[\"action\"],[[28,[null]],\"onMute\",[28,[\"participant\"]]],null],[33,[\"action\"],[[28,[null]],\"onDismiss\",[28,[\"participant\"]]],null]]]],false],[0,\"\\n\"]],\"locals\":[\"participant\"]},null],[14],[0,\"\\n\\n\"],[11,\"div\",[]],[15,\"class\",\"footer\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"footer-container\"],[13],[0,\"\\n        \"],[11,\"button\",[]],[15,\"popover-action\",\"prevent\"],[15,\"class\",\"invite\"],[16,\"disabled\",[26,[\"disableInviteParticipant\"]],null],[16,\"onclick\",[33,[\"action\"],[[28,[null]],\"onInviteSelected\"],null],null],[15,\"type\",\"button\"],[13],[0,\"\\n            \"],[9,\"gux-icon\",{\"attrs\":[[15,\"icon-name\",\"add\"],[15,\"decorative\",\"true\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n            \"],[1,[33,[\"t\"],[\"purecloud.acdInteraction.conferenceDropdown.inviteParticipant\"],null],false],[0,\"\\n        \"],[14],[0,\"\\n\\n\"],[0,\"    \"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/acd-interactions-panel/interaction-conference-roster-dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});