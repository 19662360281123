define("web-directory/components/acd-interactions-panel/interaction-session-share/session-share-auto-disconnect/component", ["exports", "lodash", "ember-purecloud-components/mixins/logger", "web-directory/mixins/acd-utilities"], function (_exports, _lodash, _logger, _acdUtilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      run = Ember.run,
      Component = Ember.Component;
  var DISCONNECT_WAIT_TIME = 10000; //Wait for cp disconnect message. Try to auto disconnect if not recieved within 10 sec.

  var _default = Component.extend(_logger.default, _acdUtilities.default, {
    classNames: ['session-share-auto-disconnect'],
    communication: inject.service(),
    showLoadingPanel: true,
    selectedInteraction: null,
    disconnectTimeout: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var interaction = this.get('selectedInteraction');
      var id = this.get('selectedInteraction.id');
      var message;

      if (interaction) {
        message = 'ACD: session-share-auto-disconnectDetect: init timer to auto disconnect';
        this.logger.debug(message, id);

        if (this.get('forceDisconnect')) {
          //If we get an explicit disconnect from parent then no need to show loading panel.
          this.set('showLoadingPanel', false);
        }

        this.set('disconnectTimeout', run.later(function () {
          if (_this._isTornDown()) {
            return;
          }

          if (!_this.get('forceDisconnect')) {
            run.next(_this, 'bubbleAction', {
              action: 'forceDisconnectSessionShare'
            });
          }

          _this.set('showLoadingPanel', false);

          message = 'ACD: Detect WebRTC active stream: session-share-auto-disconnectDetect: timer is up auto disconnecting...';

          _this.logger.debug(message, {
            interactionId: id
          });

          var mediaObject, type;

          if (interaction.get('isScreenShare')) {
            mediaObject = interaction.get('participantForCurrentUser.screenshares.firstObject');
            type = 'screenshares';
          } else {
            mediaObject = interaction.get('participantForCurrentUser.cobrowsesessions.firstObject');
            type = 'cobrowsesessions';
          }

          if (mediaObject) {
            var mediaId = mediaObject.id;
            return _this.get('communication').disconnectMediaType(interaction, type, mediaId).then(function () {
              if (_this._isTornDown()) {
                return;
              }

              message = 'ACD: session-share-auto-disconnectDetect: Request successful to disconnect screen share';

              _this.logger.debug(message, {
                interactionId: id
              });
            }).catch(function () {
              if (_this._isTornDown()) {
                return;
              }

              _this.bubbleAction({
                action: 'forceCloseSessionShare'
              });

              _this.displayError('purecloud.acdInteraction.sessionShare.hasErrorDisconnecting');

              message = 'ACD: session-share-auto-disconnectDetect: Request failed to disconnect screen share';

              _this.logger.error(message, {
                remoteData: {
                  interactionId: id
                }
              });
            });
          }
        }, DISCONNECT_WAIT_TIME));
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      run.next(this.get('parentView'), 'setForceDisconnect', false);
      run.cancel(this.get('disconnectTimeout'));
    },
    _isTornDown: function _isTornDown() {
      return this.get('isDestroyed') || this.get('isDestroying');
    },
    displayError: _lodash.default.debounce(function (messageKey) {
      var _this2 = this;

      run(function () {
        return _this2._displayError(messageKey);
      });
    }, 250)
  });

  _exports.default = _default;
});