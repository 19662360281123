define("web-directory/components/toggle-item-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tGuQvG+y",
    "block": "{\"statements\":[[6,[\"each\"],[[28,[\"toggleItems\"]]],[[\"key\"],[\"itemConfig.id\"]],{\"statements\":[[6,[\"if\"],[[29,\"default\"]],null,{\"statements\":[[0,\"        \"],[18,\"default\",[[33,[\"hash\"],null,[[\"itemConfig\",\"isSelected\",\"showTab\",\"attentionStarburst\",\"index\"],[[28,[\"toggleItem\",\"itemConfig\"]],[28,[\"toggleItem\",\"isSelected\"]],[28,[\"toggleItem\",\"itemConfig\",\"showTab\"]],[33,[\"get\"],[[28,[\"attentionStarburst\"]],[28,[\"toggleItem\",\"itemConfig\",\"id\"]]],null],[28,[\"index\"]]]]]]],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[6,[\"if\"],[[28,[\"toggleItem\",\"itemConfig\",\"showTab\"]]],null,{\"statements\":[[0,\"            \"],[1,[33,[\"toggle-item\"],null,[[\"itemConfig\",\"activeTab\",\"click\",\"attentionStarburst\"],[[28,[\"toggleItem\",\"itemConfig\"]],[28,[\"toggleItem\",\"isSelected\"]],[33,[\"action\"],[[28,[null]],[28,[\"clicked\"]],[28,[\"index\"]]],null],[33,[\"get\"],[[28,[\"attentionStarburst\"]],[28,[\"toggleItem\",\"itemConfig\",\"id\"]]],null]]]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]}]],\"locals\":[\"toggleItem\",\"index\"]},null]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/toggle-item-bar/template.hbs"
    }
  });

  _exports.default = _default;
});