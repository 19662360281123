define("web-directory/field-config/field-section", ["exports", "lodash", "web-directory/field-config/field"], function (_exports, _lodash, _field) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FieldSection = Ember.Object.extend({
    intl: Ember.computed.reads('fieldConfig.intl'),
    application: Ember.computed.reads('fieldConfig.application'),
    fieldConfig: null,
    key: null,
    state: null,
    instructionText: null,
    fieldList: [],
    getFieldByKey: function getFieldByKey(key) {
      return this.fieldList.findBy('key', key);
    },
    getFieldsByType: function getFieldsByType(type) {
      return this.fieldList.filterBy('type', type);
    },
    getEntityType: function getEntityType() {
      return this.fieldConfig.entityType;
    },
    getI18nKey: function getI18nKey() {
      var type = this.getEntityType();
      return "".concat(type, "_").concat(this.key);
    },
    unknownProperty: function unknownProperty(property) {
      var fieldList = this.get('fieldList');

      if (_lodash.default.map(fieldList, 'key').indexOf(property) > -1) {
        return this.getFieldByKey(property);
      }

      return null;
    },
    sectionName: Ember.computed(function () {
      var intl = this.get('intl');
      var key = this.getI18nKey();
      var serverKey = "server_translations.".concat(key);

      if (!intl.exists(serverKey)) {
        return key;
      }

      return intl.t(serverKey, null, true).toString();
    })
  });
  FieldSection.reopenClass({
    create: function create(attributes) {
      attributes.fieldList = attributes.fieldList.filter(function (field) {
        return field.state !== 'deleted';
      }).map(function (field) {
        return _field.default.create(field);
      });

      var instance = this._super(attributes);

      instance.fieldList.forEach(function (field) {
        field.section = instance;
      });
      return instance;
    }
  });
  var _default = FieldSection;
  _exports.default = _default;
});