define("web-directory/helpers/chat-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    var time = params[0];
    return time.format('LLLL');
  });

  _exports.default = _default;
});