define("web-directory/mixins/entity-presence", ["exports", "moment", "web-directory/utils/presence-utils"], function (_exports, _moment, _presenceUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isPresence = _isPresence;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var get = Ember.get,
      inject = Ember.inject,
      computed = Ember.computed,
      Mixin = Ember.Mixin;

  function _isPresence(presenceMap, definition, check) {
    if (!definition) {
      return false;
    }

    var apiValue = get(definition, 'systemPresence');
    var id = get(definition, 'id');

    if (apiValue) {
      return apiValue.toLowerCase() === check;
    } else if (id && presenceMap) {
      var presenceMapping = presenceMap.findBy('id', id);

      if (presenceMapping && presenceMapping.systemPresence) {
        return presenceMapping.systemPresence.toLowerCase() === check;
      }
    }

    return false;
  }

  var _default = Mixin.create({
    intl: inject.service(),
    session: inject.service(),
    presenceService: inject.service('presence'),
    phoneIntegrations: inject.service(),
    integrationsPresenceSvc: inject.service('integrations-presence'),
    outOfOffice: computed.reads('activities.outOfOffice'),
    routingStatus: computed.reads('activities.routingStatus'),
    acdDivisionPresenceProfileToggle: computed.readOnly('session.features.acdDivisionPresenceProfileToggle'),
    useDefaultPresenceSource: Ember.computed('session.features', function () {
      return this.get('session.features') && this.get('session.features')['gcucc.ui.defaultPresenceSource'];
    }),
    primaryPresence: computed('activities.presence', 'activities.integrationPresence', 'session.presenceMap', 'presenceList.[]', 'currentPresenceSource', function () {
      var _this = this;

      var integrationsPresenceSvc = this.get('integrationsPresenceSvc');
      var presence = this.get('activities.presence');
      var presenceList = this.get('presenceList');
      var currentPresenceSource = this.get('currentPresenceSource');
      var integrationPresence = this.get('integrationPresence');
      var currentPresence = presence; // use new algorithm for finding which presence to display

      if (integrationsPresenceSvc.get('useThirdPartyPresenceExpands')) {
        // check the presence list for primary presence
        // falls back to `activities.presence`
        if (presenceList && presenceList.length > 0) {
          var existingPresence = presenceList.find(function (pres) {
            var presSource = pres.source;

            if (_this.get('useDefaultPresenceSource') && (0, _presenceUtils.isPurecloudSource)(presSource)) {
              presSource = 'default';
            }

            return presSource.toLowerCase() === currentPresenceSource.toLowerCase();
          });

          if (existingPresence) {
            if (!presence) {
              // there are times where activities.presence is undefined
              currentPresence = existingPresence;
            } else {
              // need to verify if expands presence (from api) is the latest presence
              var existingPresenceDate = (0, _moment.default)(existingPresence.modifiedDate);
              var activitiesPresenceDate = (0, _moment.default)(presence.modifiedDate);
              var isExistingPresenceLatest = existingPresenceDate.isAfter(activitiesPresenceDate);

              if (isExistingPresenceLatest) {
                // if presence value in presence list, is the latest, then use that instead
                currentPresence = existingPresence;
              }
            }
          }
        } // if user has an integration presence


        if (integrationPresence && Ember.get(integrationPresence, 'presenceDefinition')) {
          // check if user is offline for their primary presence source
          var isGCOffline = _isPresence(this.get('session.presenceMap'), !!currentPresence ? Ember.get(currentPresence, 'presenceDefinition') : undefined, 'offline'); // if offline in primary presence source
          // return integration presence


          if (isGCOffline) {
            return integrationPresence;
          }
        }
      }

      return currentPresence;
    }),
    conversationSummary: computed.reads('activities.conversationSummary'),
    geolocation: computed.reads('activities.geolocation'),
    presenceDefinition: computed.reads('primaryPresence.presenceDefinition'),
    // PCUCC-1546 - new integrationPresence expands for 3rd Party Presence
    integrationPresence: computed.reads('activities.integrationPresence'),
    presenceModel: computed('primaryPresence.presenceDefinition.systemPresence', 'presenceDefinition', 'presenceService.allPresences.[]', 'presenceService.outOfBandPresenceDefinitions.[]', 'acdDivisionPresenceProfileToggle', function () {
      var presenceService = this.get('presenceService');
      var presences = this.get('presenceService.allPresences');
      var presenceDefinition = this.get('presenceDefinition');

      if (presenceDefinition && presences && presences.length > 0) {
        var presence = presences.find(function (presence) {
          return get(presenceDefinition, 'id') === get(presence, 'id');
        });

        if (this.get('acdDivisionPresenceProfileToggle')) {
          var outOfBandPresences = this.get('presenceService.outOfBandPresenceDefinitions');
          var outOfBandPresence;

          if (outOfBandPresences) {
            outOfBandPresence = outOfBandPresences.find(function (presence) {
              return get(presenceDefinition, 'id') === get(presence, 'id');
            });
          }

          if (!presence && !outOfBandPresence) {
            // This recalculates the data necessary to handle the use cases where an unknown
            // presence is selected or when a deactivated presence has worked its way into the
            // cache (outside user intervention)
            // integration presence will be undefined at times and trigger many loops based on results
            Ember.run.join(function () {
              return presenceService.findPresence(get(presenceDefinition, 'id'), presenceDefinition);
            });
          }

          return presence || outOfBandPresence;
        } else {
          if (!presence) {
            // This recalculates the data necessary to handle the use cases where an unknown
            // presence is selected or when a deactivated presence has worked its way into the
            // cache (outside user intervention)
            // integration presence will be undefined at times and trigger many loops based on results
            Ember.run.join(function () {
              return presenceService.findPresence(get(presenceDefinition, 'id'));
            });
          }

          return presence;
        }
      }

      return presences.findBy('key', 'offline');
    }),
    presenceLabel: computed('activityPresence', 'presenceDefinition', 'isOutOfOffice', 'outOfOffice.endDate', 'integrationPresence', 'primaryPresence', 'presenceModel', 'acdDivisionPresenceProfileToggle', function () {
      var endOfTime = (0, _moment.default)('9999-12-31T00:00:00.000Z');

      if (this.get('isOutOfOffice')) {
        var end = (0, _moment.default)(this.get('outOfOffice.endDate'));

        if (end.isSame(endOfTime) || end.year() === 9999) {
          return this.get('intl').lookup('chat.presence.outOfOffice.title');
        } else {
          return this.get('intl').lookup('chat.presence.outOfOffice.presence', {
            date: end.locale(this.get('intl.isoLocale')).format('l')
          });
        }
      }

      var integrationsPresenceSvc = this.get('integrationsPresenceSvc');
      var integrationPresenceSource = this.get('integrationPresence.source');
      var primaryPresenceSource = this.get('primaryPresence.source');

      if (this.get('useDefaultPresenceSource')) {
        if ((0, _presenceUtils.isPurecloudSource)(primaryPresenceSource)) {
          primaryPresenceSource = 'default';
        }
      }

      if (this.get('acdDivisionPresenceProfileToggle')) {
        // if using 3rd Party Presence, append 3rd Party labels to presence label
        if (integrationsPresenceSvc.get('useThirdPartyPresenceExpands') && primaryPresenceSource === integrationPresenceSource && integrationPresenceSource) {
          var integrationLabel = this.get('intl').t("integrationPresence.presenceLabels.".concat(integrationPresenceSource.toLowerCase()));
          return "".concat(this.get('presenceModel.label'), " on ").concat(integrationLabel);
        }

        return this.get('presenceModel.label');
      } // if using 3rd Party Presence, append 3rd Party labels to presence label


      if (integrationsPresenceSvc.get('useThirdPartyPresenceExpands') && primaryPresenceSource === integrationPresenceSource && integrationPresenceSource) {
        var _integrationLabel = this.get('intl').t("integrationPresence.presenceLabels.".concat(integrationPresenceSource.toLowerCase()));

        return "".concat(this.get('presenceModel.primaryLabel'), " on ").concat(_integrationLabel);
      }

      return this.get('presenceModel.primaryLabel');
    }),

    /**
     * Generates a label based on the users presence. Includes the secondary presence and the primary presence
     * separated by a bullet symbol. i.e. `Busy 1 • Busy`.
     * This gives the users a further look into the users exact presence
     */
    secondaryPresenceLabel: computed('activityPresence', 'presenceDefinition', 'presenceLabel', 'presenceModel.primaryLabel', 'acdDivisionPresenceProfileToggle', function () {
      if (this.get('acdDivisionPresenceProfileToggle')) {
        if (!this.get('presenceLabel')) {
          return;
        }

        if (this.get('presenceModel.primaryLabel') && this.get('presenceModel.primaryLabel') !== this.get('presenceLabel')) {
          return this.get('presenceModel.primaryLabel');
        }

        return null;
      }

      var secondaryPresence = null;
      var definition = this.get('presenceDefinition');
      var presenceMap = this.get('session.presenceMap');
      var presence = presenceMap.find(function (p) {
        return p.id === definition.id;
      });

      if (presence) {
        var locale = this.get('intl.isoLocale').toLowerCase();
        var fallbackLocale = this.get('intl.baseLocale');

        if (presence.languageLabels && Object.keys(presence.languageLabels).length > 0) {
          /**
           * The language label keys are inconsistent so we will just iterate over them
           * and try to match it up with the info we have
           */
          for (var _i = 0, _Object$entries = Object.entries(presence.languageLabels); _i < _Object$entries.length; _i++) {
            var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
                key = _Object$entries$_i[0],
                value = _Object$entries$_i[1];

            var formattedLocale = key.toLowerCase().replace(/_/g, '-'); // if we found the exact locale that matches the users we can end it

            if (locale === formattedLocale) {
              secondaryPresence = value;
              break;
            } // sets the secondarypresence to the fallback locale if found and needed


            if (fallbackLocale === formattedLocale) {
              secondaryPresence = value;
            }
          }
        }

        var primaryPresence = typeof presence.systemPresence === 'string' ? presence.systemPresence.toLowerCase().trim().replace('_', ' ') : ''; // if the secondaryPresence and the primaryPresence are the same then we don't want to return anything

        if (!secondaryPresence || secondaryPresence.toLowerCase().trim() === primaryPresence) {
          return null;
        }
      }

      return secondaryPresence;
    }),
    primaryStatusMessage: computed.reads('primaryPresence.message'),
    cityExists: computed.notEmpty('geolocation.city'),
    regionExists: computed.notEmpty('geolocation.region'),
    countryExists: computed.notEmpty('geolocation.country'),
    hasGeolocation: computed.or('cityExists', 'regionExists', 'countryExists'),
    isAvailable: computed.reads('isOnline'),
    isOnline: computed('session.presenceMap.[]', 'presenceDefinition', 'isOutOfOffice', function () {
      return this.isPresence('available') && !this.get('isOutOfOffice');
    }),
    isOffline: computed('session.presenceMap.[]', 'presenceDefinition', 'isOutOfOffice', function () {
      return this.isPresence('offline');
    }),
    isAway: computed('session.presenceMap.[]', 'presenceDefinition', 'isOutOfOffice', function () {
      var isAway = this.isPresence('away') || this.isPresence('training') || this.isPresence('break') || this.isPresence('meal') || this.isPresence('idle');
      return isAway && !this.get('isOutOfOffice');
    }),
    isBusy: computed('session.presenceMap.[]', 'presenceDefinition', 'isOutOfOffice', function () {
      var isBusy = this.isPresence('busy') || this.isPresence('meeting');
      return isBusy && !this.get('isOutOfOffice');
    }),
    isOutOfOffice: computed('session.presenceMap.[]', 'presenceDefinition', 'outOfOffice.active', function () {
      return this.get('outOfOffice.active');
    }),
    isOnQueue: computed('session.presenceMap.[]', 'presenceDefinition', 'presenceDefinition.systemPresence', 'routingStatus.status', 'isOutOfOffice', function () {
      var systemPresence = this.get('presenceDefinition.systemPresence') || '';
      return (systemPresence.toLowerCase() === 'on_queue' || systemPresence.toLowerCase() === 'on queue' || this.isPresence('on_queue')) && !this.get('isOutOfOffice');
    }),
    isOfflineOrBusy: computed.or('isOffline', 'isBusy'),
    activityPresence: computed('isOnQueue', 'isOutOfOffice', 'isOnline', 'isOffline', 'isAway', 'isBusy', function () {
      if (this.get('isOnQueue')) {
        return 'on_queue';
      } else if (this.get('isOutOfOffice')) {
        return 'out_of_office';
      } else if (this.get('isOnline')) {
        return 'available';
      } else if (this.get('isAway')) {
        return 'away';
      } else if (this.get('isBusy')) {
        return 'busy';
      }

      return 'offline';
    }),
    activityPresenceClass: computed('activityPresence', 'isOffline', 'isOutOfOffice', function () {
      if (this.get('isOutOfOffice') && this.get('isOffline')) {
        // Display offline CSS colors via class if out of office and signed out
        return 'offline';
      }

      return this.get('activityPresence');
    }),
    isPresence: function isPresence(check) {
      return _isPresence(this.get('session.presenceMap'), this.get('presenceDefinition'), check);
    }
  });

  _exports.default = _default;
});