define("web-directory/components/login-oauth/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      inject = Ember.inject,
      computed = Ember.computed;

  var _default = Component.extend({
    classNames: ['login-oauth'],
    session: inject.service(),
    isAuthenticated: computed.reads('session.isAuthenticated'),
    // attrs
    offerMobileApp: false,
    // Expected Actions: mobileOfferDenied, authenticate
    onMobileOfferDecline: function onMobileOfferDecline() {
      return this.attrs.mobileOfferDeclined().then(this.attrs.authenticate);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!this.get('offerMobileApp')) {
        this.attrs.authenticate();
      }
    }
  });

  _exports.default = _default;
});