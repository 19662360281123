define("web-directory/routes/relate/contacts-ui-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      var contactId = transition.params[this.routeName].contact_id;
      this.get('sidebar').closePanel();
      return this.replaceWith("/contactsAndOrganizations/contact/".concat(contactId));
    }
  });

  _exports.default = _default;
});