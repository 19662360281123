define("web-directory/components/app-img/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fvns2Ly9",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"defaultImgUrl\"]]],null,{\"statements\":[[0,\"    \"],[11,\"img\",[]],[16,\"src\",[34,[[26,[\"defaultImgUrl\"]]]]],[16,\"srcset\",[34,[[26,[\"imgSrcSet\"]]]]],[16,\"alt\",[34,[[28,[\"app\",\"name\"]]]]],[16,\"aria-label\",[34,[[28,[\"app\",\"name\"]]]]],[16,\"width\",[34,[[26,[\"imgWidth\"]]]]],[16,\"height\",[34,[[26,[\"imgHeight\"]]]]],[13],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[6,[\"if\"],[[28,[\"imgClasses\"]]],null,{\"statements\":[[0,\"    \"],[11,\"i\",[]],[16,\"class\",[34,[[26,[\"imgClasses\"]]]]],[16,\"aria-label\",[34,[[28,[\"app\",\"name\"]]]]],[13],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[6,[\"if\"],[[28,[\"svgSpriteHref\"]]],null,{\"statements\":[[0,\"    \"],[11,\"svg\",[]],[15,\"class\",\"svg-icon\"],[16,\"aria-label\",[34,[[28,[\"app\",\"name\"]]]]],[13],[0,\"\\n        \"],[11,\"use\",[]],[16,\"href\",[26,[\"svgSpriteHref\"]],null],[13],[14],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[6,[\"if\"],[[28,[\"guxIcon\"]]],null,{\"statements\":[[0,\"    \"],[9,\"gux-icon\",{\"attrs\":[[16,\"icon-name\",[28,[\"guxIcon\",\"name\"]],null],[16,\"decorative\",[28,[\"guxIcon\",\"decorative\"]],null]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"    \"],[11,\"i\",[]],[15,\"class\",\"pc pc-external-apps-unknown\"],[16,\"aria-label\",[34,[[28,[\"app\",\"name\"]]]]],[13],[14],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[]}]],\"locals\":[]}]],\"locals\":[]}],[6,[\"if\"],[[33,[\"and\"],[[28,[\"showTooltip\"]],[28,[\"app\",\"name\"]]],null]],null,{\"statements\":[[6,[\"tooltip-on-element\"],null,[[\"targetOffset\"],[\"-10px 0\"]],{\"statements\":[[0,\"        \"],[1,[28,[\"app\",\"name\"]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/app-img/template.hbs"
    }
  });

  _exports.default = _default;
});