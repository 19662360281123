define("web-directory/components/drag-drop-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['draggable-item'],
    attributeBindings: ['draggable', 'ariaLabel'],
    ariaLabel: Ember.computed(function () {
      return this.get('intl').lookup('components.inbox.actions.selectCall');
    }),
    draggable: 'true',
    dataType: 'text',
    data: null,
    intl: Ember.inject.service(),
    dragStart: function dragStart(event) {
      event.dataTransfer.setData(this.get('dataType'), this.get('data'));
    }
  });

  _exports.default = _default;
});