define("web-directory/helpers/multi-line-value", ["exports", "web-directory/utils/markdown"], function (_exports, _markdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    if (params && params[0]) {
      return Ember.String.htmlSafe((0, _markdown.markdownToHtml)(params[0]));
    }

    return '';
  });

  _exports.default = _default;
});