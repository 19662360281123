define("web-directory/adapters/org", ["exports", "web-directory/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: 'api/v1',
    buildURL: function buildURL() {
      return "/".concat(this.namespace, "/org");
    }
  });

  _exports.default = _default;
});