define("web-directory/components/skillgroup-profile/membership-entry/component", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed,
      Component = Ember.Component;

  var _default = Component.extend({
    classNames: ['group-membership-entry'],
    chat: inject.service(),
    intl: inject.service(),
    session: inject.service(),
    store: inject.service(),
    notification: inject.service(),
    attrs: {
      person: null,
      group: null,
      membership: []
    },
    showChat: computed('isLoggedInUser', 'chat.enabled', function () {
      return !this.get('isLoggedInUser') && this.get('chat.enabled');
    }),
    isLoggedInUser: computed('person', 'session.person', function () {
      var person = this.get('person');
      var sessionPerson = this.get('session.person');
      return person.get('id') === sessionPerson.get('id');
    }),
    person: computed('entity', function () {
      return _emberData.default.PromiseObject.create({
        promise: this.get('store').find('person', this.get('entity.id'))
      });
    }),
    click: function click(event) {
      var target = Ember.$(event.target);

      if (target.is('.person-name')) {
        this.bubbleAction({
          action: 'transitionToEntityDetail',
          actionContext: [this.get('person')]
        });
      }
    }
  });

  _exports.default = _default;
});