define("web-directory/services/apps-api-handlers/external-contacts-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SHOW_EXTERNAL_ORGANIZATION_PROFILE = _exports.SHOW_EXTERNAL_CONTACT_PROFILE = void 0;
  var RSVP = Ember.RSVP,
      inject = Ember.inject,
      getOwner = Ember.getOwner,
      typeOf = Ember.typeOf;
  var SHOW_EXTERNAL_CONTACT_PROFILE = 'showExternalContactProfile';
  _exports.SHOW_EXTERNAL_CONTACT_PROFILE = SHOW_EXTERNAL_CONTACT_PROFILE;
  var SHOW_EXTERNAL_ORGANIZATION_PROFILE = 'showExternalOrganizationProfile';
  _exports.SHOW_EXTERNAL_ORGANIZATION_PROFILE = SHOW_EXTERNAL_ORGANIZATION_PROFILE;
  var ExternalContactsApiHandler = Ember.Object.extend({
    sidebar: inject.service(),
    permissions: inject.service(),
    ajax: inject.service(),
    application: inject.service(),
    routerService: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('routerService', getOwner(this).lookup('router:main'));
    },

    /**
     * Synchronous check to see if this handler is applicable for the specified AppApiCall instance
     *
     * @param {Object} the AppApiCall instance to check if this handler can handle
     *
     * @returns true if the provided AppApiCall instance could be handled by this handler. This check should
     *  be limited to checking if the handler is responsible for this type of AppApiCall rather than if the
     *  payload itself is valid.  If no handler returns true, the error will be that no handler exists which is
     *  a different case than the AppApiCall is invalie. Practically, this means that validation of the payload
     *  should be done in the handle method
     */
    canHandle: function canHandle(appApiCall) {
      return appApiCall.get('action') === SHOW_EXTERNAL_CONTACT_PROFILE || appApiCall.get('action') === SHOW_EXTERNAL_ORGANIZATION_PROFILE;
    },

    /**
     * Handle the AppApiCall instance pre-filtered by canHandle
     *
     * @param {Object} An AppApiCall instance
     *
     * @returns A Promise resolving if the api action was successfully handled or rejecting with
     *  optional details as to why the event could not be handled.
     */
    handle: function handle(appApiCall) {
      if (appApiCall.get('action') === SHOW_EXTERNAL_CONTACT_PROFILE) {
        return this._handleShowExternalProfile(appApiCall);
      } else if (appApiCall.get('action') === SHOW_EXTERNAL_ORGANIZATION_PROFILE) {
        return this._handleShowExternalOrganizationProfile(appApiCall);
      }
    },
    _handleShowExternalProfile: function _handleShowExternalProfile(_ref) {
      var _this = this;

      var apiActionDetails = _ref.actionDetails;
      return new RSVP.Promise(function (resolve, reject) {
        if (!apiActionDetails || typeOf(apiActionDetails.contactId) != 'string' || apiActionDetails.contactId.trim().length <= 0) {
          reject('Missing/Invalid contactId provided');
          return;
        }

        var routerService = _this.get('routerService');

        if (!routerService || !routerService.transitionTo) {
          reject('Handler Error: router not found');
          return;
        }

        var permissionService = _this.get('permissions');

        if (!permissionService.get('hasExternalContactsViewContactAccess')) {
          reject('Insufficient permissions for viewing External Contacts');
          return;
        }

        _this._ajaxGetExternalContactProfile(apiActionDetails.contactId).then(function () {
          routerService.transitionTo("/contactsAndOrganizations/contact/".concat(encodeURIComponent(apiActionDetails.contactId)));

          _this.get('sidebar').ensureRouteVisibility();

          resolve();
        }).catch(function (reason) {
          if (reason.status === 404) {
            reject('Contact does not exist.');
          } else if (reason.status === 403) {
            reject('User not authorized to view this contact');
          } else {
            reject("Failed to fetch contact. Error status: ".concat(reason.status, " object: ").concat(JSON.stringify(reason)));
          }
        });
      });
    },
    _handleShowExternalOrganizationProfile: function _handleShowExternalOrganizationProfile(_ref2) {
      var _this2 = this;

      var apiActionDetails = _ref2.actionDetails;
      return new RSVP.Promise(function (resolve, reject) {
        if (!apiActionDetails || typeOf(apiActionDetails.externalOrganizationId) != 'string' || apiActionDetails.externalOrganizationId.trim().length <= 0) {
          reject('Missing/Invalid externalOrganizationId provided');
          return;
        }

        var routerService = _this2.get('routerService');

        if (!routerService || !routerService.transitionTo) {
          reject('Handler Error: router not found');
          return;
        }

        var permissionService = _this2.get('permissions');

        if (!permissionService.get('hasExternalContactsViewOrganizationAccess')) {
          reject('Insufficient permissions for viewing External Organizations');
          return;
        }

        _this2._ajaxGetExternalOrganizationProfile(apiActionDetails.externalOrganizationId).then(function () {
          routerService.transitionTo("/contactsAndOrganizations/organization/".concat(encodeURIComponent(apiActionDetails.externalOrganizationId)));

          _this2.get('sidebar').ensureRouteVisibility();

          resolve();
        }).catch(function (reason) {
          if (reason.status === 404) {
            reject('Organization does not exist.');
          } else if (reason.status === 403) {
            reject('User not authorized to view this organization');
          } else {
            reject("Failed to fetch organization. Error status: ".concat(reason.status, " object: ").concat(JSON.stringify(reason)));
          }
        });
      });
    },
    _ajaxGetExternalOrganizationProfile: function _ajaxGetExternalOrganizationProfile(externalOrganizationId) {
      var orgUrl = "api/v2/externalcontacts/organizations/".concat(encodeURIComponent(externalOrganizationId));
      orgUrl = this.get('application').pcV2Uri(orgUrl);
      return this.get('ajax').ajaxGet(orgUrl);
    },
    _ajaxGetExternalContactProfile: function _ajaxGetExternalContactProfile(contactId) {
      var contactUrl = "api/v2/externalcontacts/contacts/".concat(encodeURIComponent(contactId));
      contactUrl = this.get('application').pcV2Uri(contactUrl);
      return this.get('ajax').ajaxGet(contactUrl);
    }
  });
  var _default = ExternalContactsApiHandler;
  _exports.default = _default;
});