define("web-directory/mixins/flag", ["exports", "web-directory/utils/dates"], function (_exports, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    notification: Ember.inject.service(),
    intl: Ember.inject.service(),
    phone: Ember.inject.service(),
    didFlag: Ember.computed.bool('flaggedSelves.length'),
    flagOperationInProcess: false,
    flaggedSelves: Ember.computed('call.participants.@each.flaggedReason', 'call.myGuid', function () {
      var myParticipants = this.get('call.participants');

      if (!myParticipants) {
        return [];
      }

      var myGuid = this.get('call.myGuid');
      var myFlaggedParticipants = myParticipants.filter(function (participant) {
        return participant.user && participant.user.id === myGuid && participant.flaggedReason;
      });
      return myFlaggedParticipants;
    }),
    // allow flagging/unflagging calls for 45 minutes from end time (analytics service's window for accepting new events for a call is 60 minutes)
    allowFlagChange: Ember.computed('call.callEnd', 'clock.minute', function () {
      var minutesPassed = (0, _dates.default)().diff(this.get('call.callEnd'), 'minutes');
      return minutesPassed < 45;
    }),
    flagInteractionAction: function flagInteractionAction(action) {
      var _this = this;

      if (this.get('allowFlagChange')) {
        this.set('flagOperationInProcess', true);
        var participants = action === 'flag' ? [this.get('call.myParticipant')] : this.get('flaggedSelves');
        this.get('phone').flagCallHistoryInteraction(action, this.get('call'), participants).then(function () {
          _this.set('flagOperationInProcess', false);
        });
      } else {
        var message = this.get('intl').lookup('notification.purecloud.tooLateToFlag');
        this.get('notification').info(null, message, {
          hideAfter: 5
        });
      }
    }
  });

  _exports.default = _default;
});