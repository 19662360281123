define("web-directory/utils/boolean-expressions", ["exports", "boon-js"], function (_exports, _boonJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bool = _exports.and = void 0;
  _exports.buildSymbolMap = buildSymbolMap;
  _exports.perm = _exports.or = _exports.not = _exports.ft = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function buildSymbolMap(expression, symbolEvaluator) {
    if (typeof expression !== 'string' || expression === '') {
      throw new Error('Missing/invalid expression');
    }

    if (_typeof(symbolEvaluator) !== 'object' || symbolEvaluator === null || Object.keys(symbolEvaluator).length === 0) {
      throw new Error('Missing/invalid symbol evaluator');
    }

    var symbolMap = {};
    var identifiers = (0, _boonJs.parse)(expression).filter(function (curr) {
      return curr.name == 'IDENTIFIER';
    }).map(function (curr) {
      return curr.value;
    });

    if (identifiers.length === 0) {
      throw new Error('No Identifiers Found');
    }

    identifiers.forEach(function (currIdentifier) {
      var ns = currIdentifier.split(':')[0];
      var variable = currIdentifier.substring(ns.length + 1);

      if (!ns || !variable || ns === '' || variable === '') {
        throw new Error('Invalid identifier');
      }

      var nsSymbolEvaluator = symbolEvaluator[ns];

      if (typeof nsSymbolEvaluator !== 'function') {
        throw new Error('Missing/Invalid namespace symbol evaluator');
      }

      try {
        symbolMap["".concat(ns, ":").concat(variable)] = nsSymbolEvaluator(variable);
      } catch (error) {
        throw new Error('Namespace symbol evaluator threw');
      }
    });
    return symbolMap;
  }

  var expressionBuilder = function expressionBuilder(operator, conditions) {
    return "(".concat(conditions.join(" ".concat(operator, " ")), ")");
  };

  var and = function and() {
    for (var _len = arguments.length, conditions = new Array(_len), _key = 0; _key < _len; _key++) {
      conditions[_key] = arguments[_key];
    }

    return expressionBuilder('AND', conditions);
  };

  _exports.and = and;

  var or = function or() {
    for (var _len2 = arguments.length, conditions = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      conditions[_key2] = arguments[_key2];
    }

    return expressionBuilder('OR', conditions);
  };

  _exports.or = or;

  var not = function not(condition) {
    return "(NOT (".concat(condition, "))");
  };

  _exports.not = not;

  var ft = function ft(name) {
    return "ft:".concat(name);
  };

  _exports.ft = ft;

  var perm = function perm(name) {
    return "perm:".concat(name);
  };

  _exports.perm = perm;

  var bool = function bool(name) {
    return "bool:".concat(name);
  };

  _exports.bool = bool;
});