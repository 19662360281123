define("web-directory/components/change-password/component", ["exports", "lodash", "web-directory/mixins/password-rules", "ember-purecloud-components/mixins/logger"], function (_exports, _lodash, _passwordRules, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_passwordRules.default, _logger.default, {
    classNames: ['change-password'],
    intl: Ember.inject.service(),
    application: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    inputTooltip: null,
    currentPassword: null,
    newPassword: null,
    newPasswordRepeat: null,
    isValidFromPasswordRulesPlugin: false,
    resetError: false,
    resetErrorMessages: [],
    resetSuccess: false,
    debounceTimers: [],
    actions: {
      submit: function submit() {
        var _this = this;

        if (this.get('submitDisabled')) {
          return;
        }

        var intl = this.get('intl');
        var request = this.get('session').resetPassword('change', null, this.get('newPassword'), null, this.get('currentPassword'));
        request.then(function () {
          _this.set('resetSuccess', true);

          _this.set('resetError', false);

          _this.set('resetErrorMessages', []);

          var debounceTimer = Ember.run.later(_this, function () {
            _this.set('resetSuccess', false);
          }, 8000);

          _this.get('debounceTimers').push(debounceTimer);

          _this.emptyValues();
        }).catch(function (err) {
          _this.logger.error('Error changing password:', {
            err: err
          });

          _this.set('resetSuccess', false);

          _this.set('resetError', true);

          var message = intl.t('auth.password.error');

          if (err.payload && err.payload.code) {
            var code = err.payload.code;
            var key = 'default';
            var keyPath = 'auth.password.changeError';

            if (code === 'invalid.new.password') {
              key = 'invalidNewPassword';
            } else if (code === 'invalid.old.password') {
              key = 'invalidOldPassword';
            } else if (code === 'invalid.password') {
              key = 'invalidPassword';
            }

            message = intl.t("".concat(keyPath, ".").concat(key));
          }

          _this.set('resetErrorMessages', [message]);

          var debounceTimer = Ember.run.later(_this, function () {
            _this.set('resetError', false);
          }, 30000);

          _this.get('debounceTimers').push(debounceTimer);

          _this.emptyValues();
        });
      }
    },
    newPasswordError: Ember.computed('newPassword', 'newPasswordRepeat', function () {
      return this.get('newPassword') !== this.get('newPasswordRepeat');
    }),
    passwordLengthError: Ember.computed('newPassword', function () {
      return this.get('newPassword.length') > _passwordRules.maxPasswordLength;
    }),
    submitDisabled: Ember.computed('newPasswordError', 'isValidFromPasswordRulesPlugin', 'passwordLengthError', 'currentPassword', 'newPassword', 'newPasswordRepeat', function () {
      var currentPasswordEmpty = _lodash.default.isEmpty(this.get('currentPassword'));

      var newPasswordEmpty = _lodash.default.isEmpty(this.get('newPassword')) || _lodash.default.isEmpty('newPasswordRepeat');

      return currentPasswordEmpty || newPasswordEmpty || this.get('newPasswordError') || !this.get('isValidFromPasswordRulesPlugin') || this.get('passwordLengthError');
    }),
    resetPlugin: Ember.observer('newPassword', 'resetSuccess', function () {
      if (this.get('resetSuccess') && !this.get('newPassword')) {
        this.removeBehavior();
        this.attachBehavior();
      }
    }),
    newPasswordStrengthIndicator: Ember.observer('newPassword', function () {
      Ember.run.scheduleOnce('afterRender', this, function () {
        if (this.get('isDestroyed') || this.get('isDestroying')) {
          return;
        }

        if (this.$('.progress-bar').length > 0) {
          if (this.get('newPassword') === '') {
            this.$('.progress-bar').removeClass('show-strength');
          } else {
            this.$('.progress-bar').addClass('show-strength');
          }
        }
      });
    }),
    attachTooltipIfError: Ember.observer('newPasswordError', function () {
      var inputTooltip = this.get('inputTooltip');
      var repeatError = this.get('newPasswordError');

      if (repeatError) {
        if (!inputTooltip) {
          inputTooltip = this.$('.password-repeat').tooltip({
            placement: 'bottom',
            title: this.get('intl').lookup('auth.password.validation.passwordRepeatError').toString(),
            trigger: 'manual'
          });
          inputTooltip.tooltip('show');
          this.set('inputTooltip', inputTooltip);
        }
      } else {
        if (inputTooltip) {
          inputTooltip.tooltip('hide');
        }

        this.set('inputTooltip', null);
      }
    }),
    alertMessageFeatureToggleEnabled: Ember.computed('session.features', function () {
      return this.get('session.features')['epc.decomp.alert-message'];
    }),
    cleanup: Ember.on('willDestroyElement', function () {
      this.emptyValues();

      if (this.get('debounceTimers')) {
        this.get('debounceTimers').forEach(function (timer) {
          Ember.run.cancel(timer);
        });
      }
    }),
    emptyValues: function emptyValues() {
      this.setProperties({
        currentPassword: null,
        newPassword: null,
        newPasswordRepeat: null
      });
    }
  });

  _exports.default = _default;
});