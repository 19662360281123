define("web-directory/routes/partners/clients", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      return this.replaceWith("/partners/ui/clients");
    }
  });

  _exports.default = _default;
});