define("web-directory/components/entity-fields/weblink-field/component", ["exports", "lodash", "web-directory/mixins/field-component", "web-directory/components/entity-fields/validators/weblink"], function (_exports, _lodash, _fieldComponent, _weblink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      observer = Ember.observer,
      Component = Ember.Component;

  var _default = Component.extend(_fieldComponent.default, {
    classNames: ['web-link'],
    intl: inject.service(),
    tooltip: null,
    labelChoices: Ember.computed(function () {
      var baseKey = this.get('field').getI18nKey();
      return _lodash.default.map(this.get('field.labelKeys'), function (labelKey) {
        return {
          id: labelKey,
          label: this._s("".concat(baseKey, "_labelKey_").concat(labelKey))
        };
      }, this);
    }),
    customLabel: Ember.computed(function () {
      return '<i class="ion-link"></i>';
    }),
    isValid: Ember.computed('entry.value', function () {
      var entry = this.get('entry');
      return entry && this.isValidUrl(entry);
    }),
    validityChanged: observer('isValid', 'isEmpty', function () {
      Ember.run.scheduleOnce('afterRender', this, 'setupTooltips');
    }).on('didInsertElement'),
    isValidUrl: function isValidUrl(url) {
      return _weblink.default.isValid(url);
    },
    isFieldEmpty: function isFieldEmpty() {
      return _lodash.default.isEmpty(this.get('entry.value'));
    },
    setupTooltips: function setupTooltips() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      var input = this.$('input');

      if (this.get('isValid') && !this.get('isEmpty')) {
        input.tooltip('destroy');
        this.set('tooltip', null);
      } else {
        if (this.get('tooltip')) {
          return;
        }

        var title = this.get('intl').lookup('tooltip.fields.weblink.error');
        var tooltip = input.attr('title', title).tooltip({
          placement: 'right',
          trigger: 'manual'
        }).tooltip('fixTitle').tooltip('show');
        this.set('tooltip', tooltip);
      }
    }
  });

  _exports.default = _default;
});