define("web-directory/components/acd-interactions-panel/interaction-wrapup-state/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['interaction-wrapup-state'],
    intl: Ember.inject.service(),
    interaction: null,
    afterCallWorkHeaderText: Ember.computed('interaction.isAfterCallWorkMandatory', function () {
      var intl = this.get('intl');

      if (this.get('interaction.isAfterCallWorkMandatory')) {
        return intl.lookup('purecloud.acdInteraction.afterCallWorkMandatory');
      } else {
        return intl.lookup('purecloud.acdInteraction.afterCallWorkOptional');
      }
    })
  });

  _exports.default = _default;
});