define("web-directory/components/target-dropdown/detail-popover/detail-popover-list-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['detail-popover-list-item'],
    classNameBindings: ['isFooter'],

    /**
     * @Attribute
     *
     * The icon to use as the display
     */
    icon: '',

    /**
     * @Attribute
     *
     * The label to display above the value if provided
     */
    label: '',

    /**
     * @Attribute
     *
     * The value to display if provided
     */
    value: '',

    /**
     * @Attribute
     *
     * The value to display if no `value` was provided
     */
    emptyState: '',

    /**
     * @Attribute
     *
     * `true` if this list item is intended to be a footer item thus
     * applying additional styling
     */
    isFooter: false
  });

  _exports.default = _default;
});