define("web-directory/components/emoji-picker-base/component", ["exports", "web-directory/utils/keystroke"], function (_exports, _keystroke) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':emoji-picker-base', 'openUp:dropup'],
    session: Ember.inject.service(),
    openUp: true,
    openLeft: true,
    showDropdown: false,
    disableAutoCloseDropdown: false,
    actions: {
      toggleDropdown: function toggleDropdown() {
        this.send('showTab', 'smiles');
      },
      chooseEmoji: function chooseEmoji(emoji) {
        this.sendAction('action', emoji);

        if (!this.get('disableAutoCloseDropdown')) {
          this.set('showDropdown', false);
        }
      }
    },
    attachListeners: Ember.on('didInsertElement', function () {
      var id = this.get('elementId'); //keyup for escape, keypress for enter - they aren't fired the same in chrome

      Ember.$(window).on('keyup.' + id, this.detectOutsideKeypress.bind(this));
      Ember.$(window).on('keypress.' + id, this.detectOutsideKeypress.bind(this));
      this.$('.tab-content').on('scroll.' + id, this.tabBodyScrolled.bind(this));
    }),
    removeListeners: Ember.on('willDestroyElement', function () {
      Ember.$(window).off(".".concat(this.get('elementId')));
      this.$('.tab-content').off();
    }),
    detectOutsideKeypress: function detectOutsideKeypress(event) {
      var _this = this;

      if (event && event.key && event.keyCode) {
        Ember.run(function () {
          var id = _this.get('elementId');

          var target = Ember.$(event.target);
          var key = (0, _keystroke.eventToString)(event);

          if (!target.parents('#' + id).length && (key === 'ESCAPE' || key === 'ENTER')) {
            _this.set('showDropdown', false);
          }
        });
      }
    },
    tabBodyScrolled: function tabBodyScrolled(event) {
      Ember.run(function () {
        var target = Ember.$(event.target);

        if (target.scrollTop() > 5) {
          target.addClass('scrolled');
        } else {
          target.removeClass('scrolled');
        }
      });
    }
  });

  _exports.default = _default;
});