define("web-directory/models/interaction/message-interaction", ["exports", "web-directory/models/interaction/interaction", "web-directory/utils/dates"], function (_exports, _interaction, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var inject = Ember.inject,
      computed = Ember.computed;
  var MESSENGER_TYPE = ['line', 'facebook', 'viber', 'wechat', 'twitter', 'telegram', 'webmessaging', 'whatsapp', 'instagram', 'open'];

  var _default = _interaction.default.extend({
    type: 'message',
    acdsms: inject.service(),
    session: inject.service(),
    allowsLineStickers: false,
    getLatestMessage: function getLatestMessage(messageArray) {
      var messages = (messageArray || []).filter(function (m) {
        return !!m;
      });

      if (messages.length === 0) {
        return null;
      }

      var msg = messages.reduce(function (a, b) {
        var timestampA = (0, _dates.default)(a.messageTime || a.timestamp).valueOf();
        var timestampB = (0, _dates.default)(b.messageTime || b.timestamp).valueOf();
        return timestampA >= timestampB ? a : b;
      });
      var id = msg.id || msg.messageId;
      var messagesCurrentlyLoaded = this.get('acdsms').getInteractionMessages(this.get('id'));
      var messageDetails = messagesCurrentlyLoaded.find(function (message) {
        return message.id === id;
      });

      if (messageDetails) {
        Ember.setProperties(msg, messageDetails);
      }

      return msg;
    },
    allowedInboundMedia: computed.map('supportedContent.mediaTypes.allow.inbound.[]', function (item) {
      item.type = /(?:(?!;).)*/.exec(item.type);
      return item.type[0];
    }),
    allowedOutboundMedia: computed.map('supportedContent.mediaTypes.allow.outbound.[]', function (item) {
      // Filter out sticker type since we don't need it in dropzone and it messes with accept attr.
      if (item.type === 'image/vnd.genesys.sticker') {
        this.set('allowsLineStickers', true);
        return;
      }

      item.type = /(?:(?!;).)*/.exec(item.type);
      return item.type[0];
    }),
    // TODO: When removing acdOutboundWhatsApp FT, the firstObject logic should be removed along with it.
    lastCustomerMessage: computed('participantForCustomer.messages.[]', 'participantForCustomer.messages.firstObject.messages', 'participantForCustomer.messages.@each.messages', 'session.features.acdOutboundWhatsApp', 'id', function () {
      var messages = this.get('session.features.acdOutboundWhatsApp') ? (this.get('participantForCustomer.messages') || []).reduce(function (value, element) {
        if (element.messages && element.messages.length) {
          value.push.apply(value, _toConsumableArray(element.messages));
        }

        return value;
      }, []) : this.get('participantForCustomer.messages.firstObject.messages') || [];
      return this.getLatestMessage(messages);
    }),
    // TODO: When removing acdOutboundWhatsApp FT, the firstObject logic should be removed along with it.
    lastAgentMessage: computed('participantForCurrentUser.messages.[]', 'participantForCurrentUser.messages.firstObject.messages', 'participantForCurrentUser.messages.@each.messages', 'session.features.acdOutboundWhatsApp', 'id', function () {
      var messages = this.get('session.features.acdOutboundWhatsApp') ? (this.get('participantForCurrentUser.messages') || []).reduce(function (value, element) {
        if (element.messages && element.messages.length) {
          value.push.apply(value, _toConsumableArray(element.messages));
        }

        return value;
      }, []) : this.get('participantForCurrentUser.messages.firstObject.messages') || [];
      return this.getLatestMessage(messages);
    }),
    latestMessage: computed('lastCustomerMessage', 'lastAgentMessage', function () {
      var latestMessage = this.getLatestMessage([this.get('lastCustomerMessage'), this.get('lastAgentMessage')]);
      return latestMessage;
    }),
    latestMessageRegardlessOfParticipant: computed('agentParticipants', 'participantForCustomer.messages.@each.messages', function () {
      var agentMessages = this.get('agentParticipants').map(function (participant) {
        return (Ember.get(participant, 'messages') || []).map(function (communication) {
          return Ember.get(communication, 'messages') || [];
        });
      }).flat(2);
      var customerMessages = (this.get('participantForCustomer.messages') || []).map(function (communication) {
        return Ember.get(communication, 'messages') || [];
      }).flat();
      return this.getLatestMessage([].concat(_toConsumableArray(agentMessages), _toConsumableArray(customerMessages)));
    }),
    smsTimer: Ember.computed('lastMessageTime', 'connectedTime', function () {
      var lastMessageTime = this.get('lastMessageTime');
      var start;

      if ((0, _dates.default)(lastMessageTime).isBefore((0, _dates.default)(this.get('connectedTime')))) {
        lastMessageTime = null;
      }

      if (lastMessageTime && !(0, _dates.default)(lastMessageTime).isBefore(this.get('connectedTime'))) {
        start = lastMessageTime;
      } else {
        start = this.get('connectedTime');
      }

      return start;
    }),
    subMessageType: computed('participantForCustomer.messages.firstObject.type', 'participantForCurrentUser.messages.firstObject.type', function () {
      return this.get('participantForCustomer.messages.firstObject.type') || this.get('participantForCurrentUser.messages.firstObject.type') || 'sms';
    }),
    isThirdPartyMessenger: computed('subMessageType', function () {
      return MESSENGER_TYPE.includes(this.get('subMessageType'));
    }),
    lastMessageWasCustomer: computed('latestMessage.direction', function () {
      var direction = this.get('latestMessage.direction');
      return direction && direction.toLowerCase() === 'inbound' ? true : false;
    }),
    latestMessageRegardlessOfParticipantWasCustomer: computed('latestMessageRegardlessOfParticipant.direction', function () {
      var direction = this.get('latestMessageRegardlessOfParticipant.direction');
      return direction && direction.toLowerCase() === 'inbound' ? true : false;
    })
  });

  _exports.default = _default;
});