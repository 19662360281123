define("web-directory/services/store", ["exports", "ember-data", "lodash", "web-directory/adapters/application", "web-directory/utils/promise-array", "web-directory/group/store-mixin", "web-directory/search/store-mixin", "web-directory/utils/entity-fields"], function (_exports, _emberData, _lodash, _application, _promiseArray, _storeMixin, _storeMixin2, _entityFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.batchEntityIds = batchEntityIds;
  _exports.default = void 0;

  /* Batch entity ids into lists of 50. */
  function batchEntityIds(ids) {
    var size = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 50;
    ids = _lodash.default.clone(ids);
    var guids = ids.filter(function (id) {
      return id.indexOf('@') === -1;
    });
    var jids = ids.filter(function (id) {
      return id.indexOf('@') > -1;
    });
    var batches = [];

    while (guids.length > 0) {
      batches.push(guids.splice(0, size));
    }

    while (jids.length > 0) {
      batches.push(jids.splice(0, size));
    }

    return batches;
  }

  function updateGroup(group, data) {
    var updateKeys = ['contactInfo', 'general', 'privacy'];

    if (data.attributes) {
      updateKeys.forEach(function (key) {
        if (data.attributes[key] !== undefined) {
          group.set(key, data.attributes[key], {
            overwrite: true
          });
        }
      });
    }
  }

  var ApplicationStore = _emberData.default.Store.extend(_storeMixin.default, _storeMixin2.default, {
    session: Ember.inject.service(),
    adapter: _application.default,
    applicationAdapter: function applicationAdapter() {
      return Ember.getOwner(this).lookup('adapter:application');
    },
    findQuery: function findQuery(type, query) {
      var store = this;
      var promiseLabel = 'ApplicationStore#_findQuery for: ' + type;
      var adapter = this.adapterFor(type);
      Ember.assert('You tried to load a query but you have no adapter (for ' + type + ')', adapter);
      Ember.assert('You tried to load a query but your adapter does not implement `findQuery`', typeof adapter.findQuery === 'function');
      var result = adapter.query(store, type, query);
      var promise = Ember.RSVP.Promise.cast(result, promiseLabel).then(function (payload) {
        var collection = [];

        if (payload.res) {
          _lodash.default.forEach(payload.res, function (entity) {
            var id = entity.guid || entity._id;

            if (id) {
              entity.id = id;
              collection.push(store.push(type, entity, true));
            }
          });
        } else {
          // if no payload.res then we are not dealing with the application adapter,
          // but we still want to keep the result in case we are using a fixture adapter
          _lodash.default.forEach(payload, function (item) {
            collection.push(store.push(type, item, true));
          });
        }

        return {
          collection: collection,
          payload: payload
        };
      }, null, 'ApplicationStore#_findQuery: Extract payload from findQuery');
      return _promiseArray.default.create({
        promise: promise
      });
    },
    findMultiEntity: function findMultiEntity(params, requestType) {
      var store = this;
      var promiseLabel = 'ApplicationStore#findMultiEntity';
      var adapter = this.applicationAdapter();
      var serializer = this.serializerFor('application');
      var result = adapter.findMultiEntity(store, params, requestType);
      var promise = Ember.RSVP.Promise.cast(result, promiseLabel).then(function (payload) {
        payload = serializer.normalizeArrayResponse(store, null, payload, null, requestType || 'query');

        if (!payload.data && payload.included) {
          payload.data = payload.included;
          payload.included = [];
        }

        var records = store.push(payload);
        Ember.set(records, 'meta', payload.meta);
        return records;
      }, null, 'ApplicationStore#findMultiEntity: Extract payload from multi-entity search');
      return _emberData.default.PromiseArray.create({
        promise: promise,
        meta: Ember.computed.reads('content.meta')
      });
    },
    findFavorites: function findFavorites(type, id, params) {
      return this.findFavoritesV3(type, id, params);
    },
    findFavoritesV3: function findFavoritesV3(type, id, params) {
      var _this = this;

      var promiseLabel = 'Store: Application#findFavoritesV3';
      var adapter = this.adapterFor(type);
      var result = adapter.findFavorites(this, type, id, params);
      var promise = Ember.RSVP.Promise.cast(result, promiseLabel).then(function (res) {
        return _this.findMany(type, Ember.A(res).mapBy('favoriteId'), _entityFields.FL[type]).then(function (favorites) {
          return {
            payload: res,
            collection: favorites
          };
        });
      });
      return _promiseArray.default.create({
        promise: promise
      });
    },
    forwardVoicemail: function forwardVoicemail(voicemail, recipient, type) {
      var adapter = this.adapterFor(voicemail.type);
      return adapter.forwardVoicemail(voicemail.id, recipient.get('guid'), type);
    },
    findSuperiors: function findSuperiors(type, id, params) {
      var store = this;
      var modelClass = this.modelFor(type);
      var adapter = this.adapterFor(type);
      var serializer = this.serializerFor(type);
      var promise = Ember.RSVP.cast(adapter.findSuperiors(type, id, params)).then(function (payload) {
        payload = serializer.normalizeArrayResponse(store, modelClass, payload, null, 'findMany');
        var records = store.push(payload);
        return records || [];
      });
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    },
    findDirectReports: function findDirectReports(type, id, params) {
      var store = this;
      var modelClass = this.modelFor(type);
      var adapter = this.adapterFor(type);
      var serializer = this.serializerFor(type);
      return new Ember.RSVP.Promise(function (resolve) {
        adapter.findDirectReports(type, id, params).then(function (payload) {
          payload = serializer.normalizeArrayResponse(store, modelClass, payload, null, 'findMany');
          var records = store.push(payload);
          resolve(records);
        });
      });
    },
    findLatestCalls: function findLatestCalls(type, ids) {
      return this.query(type, {
        latestCalls: true,
        ids: ids
      });
    },
    findMany: function findMany(type, ids, params) {
      if (Array.isArray(type)) {
        return this._super.apply(this, arguments);
      }

      var store = this;
      var adapter = this.adapterFor(type);
      var serializer = this.serializerFor(type);
      var modelClass = this.modelFor(type);
      var batches = batchEntityIds(ids, 25);
      var batchLimit = 3;

      if (type === 'person' && this.get('session.features.enableUIAjaxRetryHeader')) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          var promises = batches.map(function (batch) {
            return adapter.findMany(store, modelClass, batch, params).then(function (payload) {
              payload = serializer.normalizeArrayResponse(store, modelClass, payload, null, 'findMany');
              var records = store.push(payload);
              return records;
            });
          });
          Ember.RSVP.allSettled(promises).then(function (results) {
            var successfulRequests = results.filter(function (r) {
              return r.state === 'fulfilled';
            }).map(function (r) {
              return r.value;
            });
            var failedRequests = results.filter(function (r) {
              return r.state === 'rejected';
            }).map(function (r) {
              return r.value;
            });

            if (failedRequests.length > 0) {
              reject('store:application#findMany request failed:', {
                failedRequests: failedRequests
              });
              return;
            }

            resolve(_lodash.default.flatten(successfulRequests));
          });
        });
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        window.async.mapLimit(batches, batchLimit, function (batch, callback) {
          Ember.run.join(function () {
            adapter.findMany(store, modelClass, batch, params).then(function (payload) {
              payload = serializer.normalizeArrayResponse(store, modelClass, payload, null, 'findMany');
              var records = store.push(payload);
              callback(null, records);
            }).catch(function (err) {
              callback(err);
            });
          });
        }, function (err, bulkResults) {
          Ember.run.join(function () {
            if (err) {
              return reject('store:application#findMany(): async.mapLimit failed' + err);
            }

            resolve(_lodash.default.flatten(bulkResults));
          });
        });
      }, 'store:application#findMany(): async.mapLimit');
    },

    /**
     * Overrides adapterFor to support type being passed into 'find' as a string.
     */
    adapterFor: function adapterFor(type) {
      if (type.modelName) {
        return this._super(type.modelName);
      }

      return this._super(type);
    },
    didSaveRecord: function didSaveRecord(internalModel, data) {
      // The api returns data instead of an empty object, so we do not want to update
      // our deleted record, otherwise an error is thrown
      var recordInfo = data;

      if (data && data.data) {
        recordInfo = data.data;
      }

      if (!internalModel.currentState.isDeleted && recordInfo && recordInfo.id) {
        if (internalModel.currentState.isSaving && recordInfo.attributes.version) {
          var record = internalModel.record || internalModel.getRecord();

          if (record) {
            record.set('version', recordInfo.attributes.version);

            if (record.get('type') === 'group') {
              updateGroup(record, recordInfo);
            }
          }
        }
      }

      return this._super(internalModel, data);
    }
  });

  var _default = ApplicationStore;
  _exports.default = _default;
});