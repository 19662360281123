define("web-directory/services/video-idle", ["exports", "ember-user-activity/services/user-idle"], function (_exports, _userIdle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/services/user-idle.js
  var _default = _userIdle.default.extend({
    IDLE_TIMEOUT: 3000,
    // 3 second
    userActivity: Ember.inject.service('videoActivity'),
    setListening: function setListening(value) {
      if (value) {
        this.get('userActivity').enableEvent('mousemove');
      } else {
        this.get('userActivity').disableEvent('mousemove');
      }
    }
  });

  _exports.default = _default;
});