define("web-directory/components/email-recipient-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ms0HE0AW",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"searchRelate\"]]],null,{\"statements\":[[0,\"    \"],[9,\"gux-icon\",{\"attrs\":[[15,\"class\",\"search-icon\"],[15,\"icon-name\",\"search\"],[15,\"decorative\",\"true\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\n\"],[11,\"select\",[]],[15,\"id\",\"emailRecipientSelect\"],[15,\"multiple\",\"\"],[15,\"data-role\",\"tagsinput\"],[15,\"class\",\"form-control purecloud-input email-tag\"],[16,\"value\",[26,[\"searchValue\"]],null],[16,\"placeholder\",[26,[\"searchPlaceholder\"]],null],[13],[14],[0,\"\\n\\n\"],[6,[\"if\"],[[28,[\"showResults\"]]],null,{\"statements\":[[0,\"    \"],[1,[33,[\"target-dropdown\"],null,[[\"authUser\",\"interaction\",\"hideHeader\",\"searchValue\",\"includeFilters\",\"actionSelected\"],[[28,[\"authUser\"]],[28,[\"interaction\"]],true,[28,[\"searchValue\"]],[28,[\"includeFilters\"]],[33,[\"action\"],[[28,[null]],\"onExternalContactSelected\"],null]]]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/email-recipient-input/template.hbs"
    }
  });

  _exports.default = _default;
});