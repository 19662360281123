define("web-directory/components/entity-fields/tag-field/component", ["exports", "lodash", "web-directory/mixins/field-component", "web-directory/utils/masonry"], function (_exports, _lodash, _fieldComponent, _masonry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var inject = Ember.inject,
      computed = Ember.computed,
      Component = Ember.Component;

  var _default = Component.extend(_fieldComponent.default, {
    classNames: ['tags'],
    classNameBindings: ['field.fieldPathClass'],
    intl: inject.service(),
    actions: {
      addTag: function addTag(tag) {
        var tags = this.get('entry.value');

        if (!_lodash.default.isEmpty(tag) || typeof tag === 'string' && !_lodash.default.isEmpty(tag.trim())) {
          if (tags.indexOf(tag) !== -1) {
            this.send('showDuplicateError', tag);
          }

          tags.addObject(tag);
          (0, _masonry.rerunLayouts)();
        }
      },
      removeTag: function removeTag(tag) {
        this.get('entry.value').removeObject(tag);
        (0, _masonry.rerunLayouts)();
      },
      showDuplicateError: function showDuplicateError(tag) {
        var addTag = this.$('.add-tag');
        addTag.tooltip({
          title: this.get('intl').lookup('tooltip.fields.tags.duplicate', {
            tag: tag
          }).toString(),
          placement: 'bottom',
          trigger: 'manual'
        }).tooltip('show');
        Ember.run.later(function () {
          addTag.tooltip('destroy');
        }, 3000);
      }
    },
    linkable: computed('field.fieldPath', function () {
      var fieldPath = this.get('field.fieldPath');
      return _lodash.default.includes(['skills.skills', 'skills.certs'], fieldPath);
    }),
    sortedTags: computed('entry.value.[]', function () {
      var tags = this.get('entry.value') || [];
      return _lodash.default.sortBy(tags, function (tag) {
        return tag.toLowerCase();
      });
    }),
    ensurePopulatedField: Ember.on('didRender', function () {
      if (!this.get('editing') || this.isDestroyed) {
        return;
      }

      var timer = Ember.run.once(this, this.setValues);
      this.timers.pushObject(timer);
    }),
    setValues: function setValues() {
      var entry = this.get('entry');
      var fieldPath = this.get('field').getFieldPath();
      var entity = this.get('entity');

      if (entity && (!entry || _typeof(entry.value) !== 'object')) {
        entity.set(fieldPath, [], {
          recordChange: true
        });
        this.set('entry', entity.getFieldValue(fieldPath));
      }
    }
  });

  _exports.default = _default;
});