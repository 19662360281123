define("web-directory/components/toggle-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['enabled', 'toggle-item', 'btn', 'btn-link'],
    classNameBindings: ['activeTab:active', 'idClassName', 'itemConfig.supplementalClassNames'],
    attributeBindings: ['itemConfig.disabled:disabled', 'role:role', 'itemConfig.name:aria-label', 'ariaExpanded:aria-expanded'],
    role: 'tab',
    ariaExpanded: Ember.computed('activeTab', function () {
      return this.get('activeTab') ? 'true' : 'false';
    }),
    idClassName: Ember.computed('itemConfig.id', function () {
      return "toggleItem-".concat(this.get('itemConfig.id'));
    })
  });

  _exports.default = _default;
});