define("web-directory/utils/instagram/instagram-properties", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INSTAGRAM_PROP = {
    FILE_SIZE: 8,
    MESSAGE_TYPE: 'instagram',
    CHARACTER_LIMIT: 1000
  };
  var _default = INSTAGRAM_PROP;
  _exports.default = _default;
});