define("web-directory/models/externalcontacts/facebook-id", ["exports", "ember-data", "web-directory/models/externalcontacts/channel"], function (_exports, _emberData, _channel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FACEBOOKID_TYPE = void 0;
  var FACEBOOKID_TYPE = 'externalcontacts/facebook-id';
  _exports.FACEBOOKID_TYPE = FACEBOOKID_TYPE;

  var _default = _channel.default.extend({
    type: 'facebook',
    ids: _emberData.default.attr(),
    displayName: _emberData.default.attr('string')
  });

  _exports.default = _default;
});