define("web-directory/components/entity-profile/profiles/section-info", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var customSections = ['groups', 'relationships', 'contactInfo', 'location', 'education'];

  var _default = Ember.Object.extend({
    entity: null,
    section: null,
    adding: false,
    editingSection: false,
    init: function init() {
      this._super.apply(this, arguments); // consume computed...


      this.get('groupSectionChange');
    },
    componentName: computed('section.key', function () {
      var key = this.get('section.key');

      if (_lodash.default.includes(customSections, key)) {
        var name = Ember.String.dasherize(key);
        return "entity-field-section/entity-".concat(name);
      }

      return 'entity-field-section';
    }),
    hasCustomComponent: computed('section.key', function () {
      return _lodash.default.includes(customSections, this.get('section.key'));
    }),
    isEmpty: computed('entity', 'section', 'adding', 'entity.groups.official.[]', 'entity.groups.social.[]', 'entity.directReports.content', {
      get: function get() {
        var entity = this.get('entity');
        var section = this.get('section');

        if (section.get('fieldList.length') === 0) {
          return true;
        }

        var emptyFields = _lodash.default.filter(section.get('fieldList'), function (field) {
          var fieldPath = field.get('fieldPath');
          var values = entity.get(fieldPath); // Work-around weird direct reports relationship...

          if (section.get('key') === 'relationships' && field.get('key') === 'directReportCount') {
            return !entity.get('directReports.content.length');
          }

          if (!_lodash.default.isEmpty(values)) {
            var emptyValues = _lodash.default.filter(values, function (value) {
              if (_lodash.default.isEmpty(value._id) && _lodash.default.isEmpty(value.guid)) {
                return true;
              }

              return _lodash.default.isEmpty(value.value);
            });

            return !_lodash.default.isEmpty(emptyValues);
          }

          return true;
        });

        return !_lodash.default.isEmpty(emptyFields) && emptyFields.length === section.get('fieldList.length');
      },
      set: function set(key, value) {
        return value;
      }
    })
  });

  _exports.default = _default;
});