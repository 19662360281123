define("web-directory/services/apps-api-handlers/my-interactions-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SHOW_MY_INTERACTION_DETAILS = _exports.SHOW_MY_EVALUATION_DETAILS = void 0;
  var RSVP = Ember.RSVP,
      inject = Ember.inject,
      typeOf = Ember.typeOf;
  var SHOW_MY_INTERACTION_DETAILS = 'showMyInteractionDetails';
  _exports.SHOW_MY_INTERACTION_DETAILS = SHOW_MY_INTERACTION_DETAILS;
  var SHOW_MY_EVALUATION_DETAILS = 'showMyEvaluationDetails';
  _exports.SHOW_MY_EVALUATION_DETAILS = SHOW_MY_EVALUATION_DETAILS;
  var MyInteractionsApiHandler = Ember.Object.extend({
    session: inject.service(),
    permissions: inject.service(),
    ajax: inject.service(),
    application: inject.service(),
    _window: window,
    // Exposed for test assertions

    /**
     * Synchronous check to see if this handler is applicable for the specified AppApiCall instance
     *
     * @param {Object} the AppApiCall instance to check if this handler can handle
     *
     * @returns true if the provided AppApiCall instance could be handled by this handler. This check should
     *  be limited to checking if the handler is responsible for this type of AppApiCall rather than if the
     *  payload itself is valid.  If no handler returns true, the error will be that no handler exists which is
     *  a different case than the AppApiCall is invalie. Practically, this means that validation of the payload
     *  should be done in the handle method
     */
    canHandle: function canHandle(appApiCall) {
      return appApiCall.get('action') === SHOW_MY_INTERACTION_DETAILS || appApiCall.get('action') === SHOW_MY_EVALUATION_DETAILS;
    },

    /**
     * Handle the AppApiCall instance pre-filtered by canHandle
     *
     * @param {Object} An AppApiCall instance
     *
     * @returns A Promise resolving if the api action was successfully handled or rejecting with
     *  optional details as to why the event could not be handled.
     */
    handle: function handle(appApiCall) {
      if (appApiCall.get('action') === SHOW_MY_INTERACTION_DETAILS) {
        return this._handleShowInteractionDetails(appApiCall);
      } else if (appApiCall.get('action') === SHOW_MY_EVALUATION_DETAILS) {
        return this._handleShowEvaluation(appApiCall);
      }
    },
    _handleShowInteractionDetails: function _handleShowInteractionDetails(_ref) {
      var _this = this;

      var apiActionDetails = _ref.actionDetails;
      return new RSVP.Promise(function (resolve, reject) {
        if (!apiActionDetails || typeOf(apiActionDetails.conversationId) !== 'string' || apiActionDetails.conversationId.trim().length <= 0) {
          reject('Missing/Invalid conversationId provided');
          return;
        }

        _this._ajaxGetConversations(apiActionDetails.conversationId).then(function (response) {
          var participants = response.participants;
          var isParticipant = participants.some(function (participant) {
            var userId = _this.get('session.person.guid');

            return participant.purpose === 'agent' && participant.userId === userId;
          });

          if (!isParticipant) {
            reject('Not my conversation!');
          } else {
            _this._navToInteractionRoute(apiActionDetails.conversationId);

            resolve();
          }
        }).catch(function (reason) {
          if (reason.status === 404) {
            reject("Conversation doesn't exist!");
          } else if (reason.status === 403) {
            reject('User not authorized to view the conversation!');
          } else {
            reject('Failed to fetch conversation. Error status: ' + reason.status);
          }
        });
      });
    },
    _handleShowEvaluation: function _handleShowEvaluation(_ref2) {
      var _this2 = this;

      var apiActionDetails = _ref2.actionDetails;
      return new RSVP.Promise(function (resolve, reject) {
        if (!apiActionDetails || typeOf(apiActionDetails.conversationId) !== 'string' || apiActionDetails.conversationId.trim().length <= 0 || typeOf(apiActionDetails.evaluationId) !== 'string' || apiActionDetails.evaluationId.trim().length <= 0) {
          reject('Missing/Invalid conversationId or evaluationId provided');
          return;
        }

        var permissionsService = _this2.get('permissions');

        if (!permissionsService.get('hasViewEvaluationAccess')) {
          reject('Insufficient permissions for Evaluation View');
          return;
        }

        _this2._ajaxGetEvaluation(apiActionDetails.conversationId, apiActionDetails.evaluationId).then(function (response) {
          if (response.agent.id !== _this2.get('session.person.guid')) {
            reject('Not my evaluation!');
          } else {
            _this2._navToInteractionRoute(apiActionDetails.conversationId, apiActionDetails.evaluationId);

            resolve();
          }
        }).catch(function (reason) {
          if (reason.status === 404) {
            reject("Evaluation doesn't exist!");
          } else if (reason.status === 403) {
            reject('User not authorized to view the evaluation!');
          } else {
            reject('Failed to fetch evaluation. Error status: ' + reason.status);
          }
        });
      });
    },
    _ajaxGetConversations: function _ajaxGetConversations(conversationId) {
      var conversationUrl = "api/v2/conversations/".concat(encodeURIComponent(conversationId));
      conversationUrl = this.get('application').pcV2Uri(conversationUrl);
      return this.get('ajax').ajaxGet(conversationUrl);
    },
    _ajaxGetEvaluation: function _ajaxGetEvaluation(conversationId, evaluationId) {
      var evaluationUrl = "api/v2/quality/conversations/" + "".concat(encodeURIComponent(conversationId), "/evaluations/").concat(encodeURIComponent(evaluationId));
      evaluationUrl = this.get('application').pcV2Uri(evaluationUrl);
      return this.get('ajax').ajaxGet(evaluationUrl);
    },
    _navToInteractionRoute: function _navToInteractionRoute(conversationId, evaluationId) {
      var routePath = "#/engage/agent/interactions/".concat(encodeURIComponent(conversationId));

      if (evaluationId) {
        routePath += "/?evaluationId=".concat(encodeURIComponent(evaluationId));
      }

      this._window.location.assign(routePath);
    }
  });
  var _default = MyInteractionsApiHandler;
  _exports.default = _default;
});