define("web-directory/components/group-profile/roles-edit/fixtable/bulk-actions/selected-elements/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HlK4vyRR",
    "block": "{\"statements\":[[11,\"button\",[]],[15,\"class\",\"btn btn-link\"],[16,\"disabled\",[26,[\"selectionIsEmpty\"]],null],[16,\"data-t\",[34,[[26,[\"bulkActionTitle\"]]]]],[15,\"type\",\"button\"],[5,[\"action\"],[[28,[null]],[33,[\"action\"],[[28,[null]],[28,[\"bulkAction\"]],\"bulkAction\",[28,[\"selectedDataRows\"]]],null]]],[13],[0,\"\\n    \"],[1,[26,[\"bulkActionTitle\"]],false],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/group-profile/roles-edit/fixtable/bulk-actions/selected-elements/template.hbs"
    }
  });

  _exports.default = _default;
});