define("web-directory/components/group-profile/roles-edit/fixtable/list-remove/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "thlYuvMV",
    "block": "{\"statements\":[[6,[\"unless\"],[[28,[\"dataRow\",\"hideButton\"]]],null,{\"statements\":[[0,\"    \"],[11,\"button\",[]],[16,\"class\",[34,[[33,[\"if\"],[[28,[\"dataRow\",\"isDeleteDisabled\"]],\"disabled\"],null],\" \",[26,[\"class\"]]]]],[15,\"data-t\",\"minus-button\"],[16,\"title\",[33,[\"if\"],[[28,[\"dataRow\",\"isDeleteDisabled\"]],[28,[\"disabledMessage\"]]],null],null],[15,\"type\",\"button\"],[5,[\"action\"],[[28,[null]],\"remove\",[28,[\"dataRow\",\"id\"]]]],[13],[0,\"\\n        \"],[11,\"i\",[]],[16,\"class\",[26,[\"iconClass\"]],null],[15,\"aria-hidden\",\"true\"],[13],[0,\" \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[6,[\"if\"],[[33,[\"and\"],[[28,[\"dataRow\",\"isDeleteDisabled\"]],[28,[\"disabledMessage\"]]],null]],null,{\"statements\":[[0,\"        \"],[1,[33,[\"tooltip-on-element\"],null,[[\"side\",\"text\"],[\"left\",[28,[\"disabledMessage\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/group-profile/roles-edit/fixtable/list-remove/template.hbs"
    }
  });

  _exports.default = _default;
});