define("web-directory/instance-initializers/presence-label", ["exports", "ember-purecloud-components/components/presence-label/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    _component.default.reopen({
      chat: Ember.inject.service(),
      isChatEnabled: Ember.computed.reads('chat.enabled')
    });
  }

  var _default = {
    name: 'presence-label',
    initialize: initialize
  };
  _exports.default = _default;
});