define("web-directory/components/focus-helper/component", ["exports", "web-directory/utils/focus-management"], function (_exports, _focusManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component;

  var _default = Component.extend({
    classNames: ['focus-helper'],
    selector: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, function () {
        (0, _focusManagement.requestFocus)(undefined, _this.get('selector'));
      });
    }
  });

  _exports.default = _default;
});