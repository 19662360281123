define("web-directory/helpers/render-markdown", ["exports", "web-directory/utils/markdown"], function (_exports, _markdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.renderMarkdown = renderMarkdown;

  function renderMarkdown(params
  /*, hash*/
  ) {
    if (params && params.length > 0) {
      return Ember.String.htmlSafe((0, _markdown.markdownToHtml)(params[0]));
    }

    return '';
  }

  var _default = Ember.Helper.helper(renderMarkdown);

  _exports.default = _default;
});