define("web-directory/components/entity-field-section/entity-relationships/relationship-label/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed,
      Component = Ember.Component;

  var _default = Component.extend({
    classNames: ['relationship-label'],
    intl: inject.service(),
    attrs: {
      onUpdateLabel: function onUpdateLabel() {}
    },
    entity: null,
    editing: false,
    relationship: null,
    showLabelChoices: false,
    actions: {
      updateLabel: function updateLabel(event) {
        Ember.run(this, this.updateLabel, event.target.value);
      },
      focusInput: function focusInput() {
        this.set('showLabelChoices', true);
      },
      selectLabel: function selectLabel(_ref) {
        var key = _ref.key;
        this.updateLabel(key);
        this.set('showLabelChoices', false);
      },
      toggleChoices: function toggleChoices() {
        this.$('input').trigger('focus');
      }
    },
    isOtherRelationship: computed.reads('relationship.isOtherRelationship'),
    hasLabelChoices: computed.gt('labelChoices.length', 0),
    canShowLabelChoices: computed.and('editing', 'showLabelChoices', 'isOtherRelationship', 'hasLabelChoices'),
    field: computed(function () {
      return this.get('entity.fieldConfig.relationships.relationships');
    }),
    labelChoices: computed('field.labelKeys.[]', function () {
      var intl = this.get('intl');
      var i18nKey = this.get('field.i18nKey');
      var labelKeys = this.get('field.labelKeys');
      return labelKeys.map(function (key) {
        return {
          key: key,
          text: intl.lookup("".concat(i18nKey, "_labelKey_").concat(key))
        };
      });
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (!this.get('isOtherRelationship')) {
        return;
      }

      Ember.$(window).on("click.".concat(this.elementId), function (event) {
        Ember.run(function () {
          var target = Ember.$(event.target);

          if (!target.parents("#".concat(_this.elementId)).length) {
            _this.set('showLabelChoices', false);
          }
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(window).off(".".concat(this.elementId));
    },
    updateLabel: function updateLabel(newLabel) {
      var relationship = this.get('relationship');
      var oldLabel = relationship.get('key');
      relationship.set('key', newLabel);
      this.setLabelKey(newLabel, oldLabel);
    },
    setLabelKey: function setLabelKey(newLabel, oldLabel) {
      this.attrs.onUpdateLabel(newLabel, oldLabel);
      this.handleTooltip(newLabel);
    },
    handleTooltip: function handleTooltip(label) {
      var message = this.get('intl').lookup('person.relationships.emptyLabel');
      var input = this.$('input');

      if (_lodash.default.isEmpty(label)) {
        input.tooltip({
          title: message.toString(),
          placement: 'right',
          trigger: 'manual'
        }).tooltip('show');
      } else {
        input.tooltip('destroy');
      }
    }
  });

  _exports.default = _default;
});