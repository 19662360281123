define("web-directory/components/analog-clock/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A2Q3ikF4",
    "block": "{\"statements\":[[6,[\"if\"],[[33,[\"and\"],[[28,[\"_analogSupported\"]],[33,[\"not\"],[[28,[\"_invalidTime\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[11,\"div\",[]],[15,\"class\",\"clock-face\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"hour-hand\"],[13],[14],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"minute-hand\"],[13],[14],[0,\"\\n\"],[6,[\"if\"],[[28,[\"showSecondHand\"]]],null,{\"statements\":[[0,\"            \"],[11,\"div\",[]],[15,\"class\",\"second-hand\"],[13],[14],[0,\"\\n\"]],\"locals\":[]},null],[0,\"    \"],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"    \"],[1,[26,[\"_currTimeLabel\"]],false],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/analog-clock/template.hbs"
    }
  });

  _exports.default = _default;
});