define("web-directory/components/entity-field-section/entity-relationships/relationship-group", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Object.extend({
    intl: inject.service(),
    session: inject.service(),
    notification: inject.service(),
    permissions: inject.service(),
    key: null,
    entity: null,
    promise: null,
    cache: null,
    reportsToSave: [],
    loading: computed('promise.isPending', 'people.[]', function () {
      return this.get('promise.isPending') && this.get('people.length') === 0;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('cache', []);
    },
    people: computed('promise', function () {
      var cache = this.get('cache');
      this.get('promise').then(function (people) {
        if (people) {
          if (cache.length === 0 || cache.length !== people.length) {
            cache.clear();
            cache.addObjects(people);
          }
        }
      });
      return cache;
    }),
    label: computed('key', 'isOtherRelationship', function () {
      var intl = this.get('intl');
      var key = this.get('key');

      if (this.get('isOtherRelationship')) {
        var field = this.get('session.person.fieldConfig.relationships.relationships');
        var fieldKey = field.getI18nKey();
        var lookupKey = "".concat(fieldKey, "_labelKey_").concat(key);

        if (intl.query(lookupKey)) {
          return intl.lookup(lookupKey);
        }

        return key;
      }

      return intl.lookup("person.relationships.".concat(key)).toString();
    }),
    canEdit: computed('key', function () {
      var key = this.get('key');

      if (key === 'management' || key === 'assistants') {
        return this.get('permissions.canEditProfiles');
      } else if (_lodash.default.includes(key.toLowerCase(), 'siblings')) {
        return false;
      }

      return true;
    }),
    isManagerRelationship: computed.equal('key', 'management'),
    isReportRelationship: computed('key', function () {
      var key = this.get('key');
      return _lodash.default.includes(key.toLowerCase(), 'reports');
    }),
    isAssistantsRelationship: computed.equal('key', 'assistants'),
    isOtherRelationship: computed('key', function () {
      var relationshipKeys = ['management', 'defaultReports', 'defaultSiblings', 'assistants'];
      return !_lodash.default.includes(relationshipKeys, this.get('key'));
    }),
    fieldPath: computed('key', 'isOtherRelationship', function () {
      var key = this.get('key');

      if (key === 'assistants') {
        return 'relationships.administrativeAssistant';
      } else if (_lodash.default.includes(key, 'reports')) {
        return 'children';
      } else if (key === 'otherRelationships' || this.get('isOtherRelationship')) {
        return 'relationships.relationships';
      }
    }),
    values: computed('entity', 'fieldPath', function () {
      var entity = this.get('entity');
      var fieldPath = this.get('fieldPath');

      if (fieldPath) {
        return entity.getFieldValue(fieldPath);
      }

      return null;
    }),
    addPerson: function addPerson(person) {
      var entity = this.get('entity');
      var fieldPath = this.get('fieldPath');
      var key = this.get('key');
      var intl = this.get('intl');

      if (this.get('isManagerRelationship')) {
        this.addManager(entity, person);
      } else {
        this.get('people').addObject(person);

        if (this.get('isReportRelationship')) {
          var personId = person.get('id');
          var currentManager = entity.getFieldValue('relationships.manager');

          if (currentManager && currentManager.value === personId) {
            this.get('people').removeObject(person);
            this.get('notification').error(intl.lookup('notification.titles.profileDataError').toString(), intl.lookup('notification.person.managerReportError').toString(), {
              forceBrowserNotification: true,
              tag: 'relationship'
            });
          } else {
            this.addManager(person, entity);
            this.get('reportsToSave').addObject(person);
          }
        } else {
          var fieldValue = _lodash.default.find(entity.get(fieldPath), function (value) {
            return _lodash.default.isEmpty(value.value);
          });

          if (_lodash.default.isEmpty(fieldValue)) {
            entity.set(fieldPath, person.get('id'));
            fieldValue = _lodash.default.last(entity.get(fieldPath));
          } else {
            Ember.set(fieldValue, 'value', person.get('id'));
          }

          Ember.set(fieldValue, 'labelKey', key);
        }
      }
    },
    removePerson: function removePerson(person) {
      var fieldPath = this.get('fieldPath');

      if (this.get('isManagerRelationship')) {
        this.removeManager(this.get('entity'));
      } else {
        this.get('people').removeObject(person);
        var values = this.get('entity').getFieldValue(fieldPath);

        if (!_lodash.default.isEmpty(values)) {
          var value = values.findBy('value', person.get('id'));
          values.removeObject(value);
        } else if (this.get('isReportRelationship')) {
          this.removeManager(person);
          this.get('reportsToSave').addObject(person);
        }
      }
    },
    addManager: function addManager(person, newManager) {
      this.removeManager(person);
      person.set('relationships.manager', newManager.get('id'));
    },
    removeManager: function removeManager(person) {
      if (!person) {
        person = this.get('entity');
      }

      var manager = person.getFieldValue('relationships.manager');

      if (!_lodash.default.isEmpty(manager)) {
        person.get('relationships.manager').removeObject(manager);
      }
    }
  });

  _exports.default = _default;
});