define("web-directory/serializers/chat-search-result", ["exports", "ember-chat-components/serializers/chat-search-result"], function (_exports, _chatSearchResult) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _chatSearchResult.default;
    }
  });
});