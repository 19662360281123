define("web-directory/components/light-gallery-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LightGalleryModal = Ember.Component.extend({
    intl: Ember.inject.service(),
    entity: null,
    showing: false,
    escaped: false,
    gallery: Ember.computed('entity.images.informal', 'entity.images.gallery', function () {
      if (this.get('entity.type') === 'group') {
        return this.get('entity.images.gallery');
      }

      return this.get('entity.images.informal');
    }),
    actions: {
      cancel: function cancel() {
        this.attrs.close();
      }
    },
    initGallery: Ember.observer('showing', function () {
      if (this.get('showing')) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          var _this = this;

          var gallery = Ember.$('#lightGallery');
          var images = gallery.children('li');
          images.each(function (index
          /*, image */
          ) {
            var caption = '<div class="customHtml"><h3>' + _this.get('gallery')[index].labelKey + '</h3></div>';
            Ember.$(_this).attr('data-sub-html', caption);
          });
          gallery.lightGallery({
            caption: true,
            loop: true,
            speed: 1,
            closable: false,
            escKey: true,
            lang: {
              allPhotos: this.get('intl').lookup('images.allPhotos')
            }
          });
        });
      }
    })
  });
  var _default = LightGalleryModal;
  _exports.default = _default;
});