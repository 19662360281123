define("web-directory/components/acd-interactions-panel/interaction-sms/messaging-system-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xsT02IOB",
    "block": "{\"statements\":[[6,[\"if\"],[[33,[\"and\"],[[28,[\"isSystemMsg\"]],[28,[\"eventSubtype\"]]],null]],null,{\"statements\":[[0,\"    \"],[11,\"span\",[]],[15,\"class\",\"system-msg-text\"],[13],[0,\"\\n        \"],[1,[33,[\"t\"],[[28,[\"systemMessageLabel\"]]],[[\"user\"],[[28,[\"nameForMessage\"]]]]],false],[0,\"\\n    \"],[14],[0,\"\\n    \"],[11,\"span\",[]],[15,\"class\",\"system-msg-timestamp\"],[13],[0,\" \"],[1,[26,[\"systemMessageTime\"]],false],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/acd-interactions-panel/interaction-sms/messaging-system-message/template.hbs"
    }
  });

  _exports.default = _default;
});