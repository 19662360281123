define("web-directory/components/acd-interactions-panel/interaction-conference-roster-dropdown/participant-row/component", ["exports", "web-directory/mixins/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_logger.default, {
    classNames: ['conference-roster-participant'],
    classNameBindings: ['connecting', 'muted', 'type'],
    store: Ember.inject.service('store'),
    addressformat: Ember.inject.service('addressformat'),

    /**
     * The participant object to render the data for
     */
    participant: null,

    /**
     * returns the participants purpose being reflected as their "type" of participant.
     */
    type: Ember.computed('participant.purpose', function () {
      var type = this.get('participant.purpose');
      return type ? type.toLowerCase() : '';
    }),

    /**
     * The name of the participant
     */
    name: Ember.computed.or('person.name', 'participant.name'),

    /**
     * The formatted name of the pacticipant number
     */
    formattedNumber: Ember.computed('name', 'participant.address', function () {
      var isValidNumber = this.get('addressformat').isValidNumber(this.get('participant.address'), null, true);

      if (isValidNumber) {
        return this.get('addressformat').formatAddressForDisplay(this.get('participant.address'));
      }

      return '';
    }),

    /**
     * The initials of the external contact to display in the thumbnail
     */
    initials: Ember.computed.readOnly('participant.externalContact.initials'),

    /**
     * The name of the icon to display in the thumbnail
     */
    iconName: Ember.computed('participant.purpose', function () {
      var type = (this.get('participant.purpose') || '').toLowerCase();

      switch (type) {
        case 'external':
          return '';

        case 'acd':
        case 'ivr':
          return 'timeline';

        default:
          return 'user';
      }
    }),

    /**
     * The person model for participant userId
     */
    person: Ember.computed('participant.userId', function () {
      var userId = this.get('participant.userId');

      if (userId) {
        //TODO: may wish to retrieve user info outside ember data
        return this.get('store').find('person', userId);
      }

      return null;
    }),

    /**
     * `true` if the participant has connected to the conversation
     */
    connecting: Ember.computed.not('participant.calls.firstObject.connectedTime'),

    /**
     * `true` if the participant is currently muted in the conversation
     */
    muted: Ember.computed.bool('participant.calls.firstObject.muted'),

    /**
     * `true` if the muting functionality of this participant should currently be disabled
     */
    mutingDisabled: Ember.computed('muted', 'participant.isExternal', 'isAuthUser', function () {
      // You can always mute and unmute yourself
      if (this.get('isAuthUser')) {
        return false;
      } // You can only affect the mute state of an internal user of the system


      var type = this.get('type');

      if (type !== 'user' && type !== 'agent') {
        return true;
      } // You cannot unmute someone (other than yourself)


      if (this.get('muted')) {
        return true;
      }

      return false;
    }),

    /**
     * `true` if this participant row is the currently authenticated user.
     * Different interactions become available if this is the auth user.
     */
    isAuthUser: false,

    /**
     * @Action
     * Closure action passed to the component to be invoked at the parent level
     */
    mute: function mute(value) {
      Ember.Logger.warn('Mute closure action has not been set up for this component. Emitted value: ', value);
    },

    /**
     * @Action
     * Closure action passed to the component to be invoked at the parent level
     */
    dismiss: function dismiss() {
      Ember.Logger.warn('Dismiss closure action has not been set up for this component');
    },
    actions: {
      /**
       * Invoked when the user mutes the participant in the conference
       */
      onToggleMute: function onToggleMute() {
        this.mute(!this.get('muted'));
      },

      /**
       * Invoked when the user dismisses the participant from the conference
       */
      onDismiss: function onDismiss() {
        this.dismiss();
      }
    }
  });

  _exports.default = _default;
});