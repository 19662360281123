define("web-directory/services/responses", ["exports", "lodash", "ember-purecloud-components/mixins/logger"], function (_exports, _lodash, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var computed = Ember.computed,
      RESPONSE_PAGE_SIZE = 25,
      CANNED_SUBSTITUTIONS = {
    customerName: 'customerName',
    agentName: 'agentName',
    agentAlias: 'agentAlias'
  },
      TRANSLATION_PREFIX = 'purecloud.responses.substitutions';
  /**
   * Service to access library responses
   */

  var _default = Ember.Service.extend(_logger.default, {
    pageSize: RESPONSE_PAGE_SIZE,
    store: Ember.inject.service(),
    interaction: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    cannedSubstitutions: computed(function () {
      var intl = this.get('intl');
      return _lodash.default.map(CANNED_SUBSTITUTIONS, function (entity, key) {
        if (intl.exists("".concat(TRANSLATION_PREFIX, ".").concat(key, ".id"))) {
          return {
            key: key,
            token: '{{' + intl.lookup("".concat(TRANSLATION_PREFIX, ".").concat(key, ".id")) + '}}',
            missingValueMessage: intl.lookup("".concat(TRANSLATION_PREFIX, ".").concat(key, ".missingValueMessage"))
          };
        }

        return {
          key: key,
          token: entity.token,
          missingValueMessage: entity.missingValueMessage
        };
      });
    }),
    getDefaultSubstitutions: function getDefaultSubstitutions() {
      var intl = this.get('intl'),
          DEFAULT_TOKENS = [{
        key: CANNED_SUBSTITUTIONS.agentAlias,
        token: '{{AGENT_ALIAS}}'
      }, {
        key: CANNED_SUBSTITUTIONS.agentName,
        token: '{{AGENT_NAME}}'
      }, {
        key: CANNED_SUBSTITUTIONS.customerName,
        token: '{{CUSTOMER_NAME}}'
      }];
      return _lodash.default.map(DEFAULT_TOKENS, function (entity) {
        var missingValueMessage = intl.exists("".concat(TRANSLATION_PREFIX, ".").concat(entity.key, ".id")) ? intl.lookup("".concat(TRANSLATION_PREFIX, ".").concat(entity.key, ".missingValueMessage")) : '';
        return {
          key: entity.key,
          token: entity.token,
          missingValueMessage: missingValueMessage
        };
      });
    },
    getValueForCannedSubstitution: function getValueForCannedSubstitution(substitutionKey) {
      var customerDetails = this.get('interaction.selectedInteraction.customerParticipants.0');
      var replaceValue = '';

      switch (substitutionKey) {
        case CANNED_SUBSTITUTIONS.agentName:
          replaceValue = this.get('session.person.name') || '';
          break;

        case CANNED_SUBSTITUTIONS.agentAlias:
          replaceValue = this.get('session.person.agentName') || '';
          break;

        case CANNED_SUBSTITUTIONS.customerName:
          replaceValue = customerDetails && customerDetails.name ? customerDetails.name : '';
          break;
      }

      return replaceValue;
    },
    getValueForCustomSubstitution: function getValueForCustomSubstitution(substitutionId) {
      var customerDetails = this.get('interaction.selectedInteraction.customerParticipants.0');
      var replaceValue = '';

      if (customerDetails && customerDetails.attributes) {
        replaceValue = customerDetails.attributes[substitutionId] ? customerDetails.attributes[substitutionId] : '';
      }

      return replaceValue;
    },
    // Roll through the substitutions for a response and attempt to replace the
    // associated {{tokens}} with a value from the system or the default value
    replaceSubstitutionTokens: function replaceSubstitutionTokens(response) {
      var _this = this;

      var initialText = response.get('texts.firstObject.content') || '',
          responseText = initialText,
          missingValueMessage = '',
          cannedSubstitutions = this.get('cannedSubstitutions'),
          responseSubstitutions = [];
      var substitutions = response.get('substitutions'),
          intl = this.get('intl');
      cannedSubstitutions = cannedSubstitutions.concat(this.getDefaultSubstitutions()).uniqBy('token');

      _lodash.default.each(substitutions, function (substitution) {
        var substitutionToken = '{{' + substitution.id + '}}',
            isPresent = responseText.indexOf(substitutionToken) > -1;

        if (isPresent) {
          var foundCannedSubstitution = _lodash.default.find(cannedSubstitutions, function (cannedSubstitution) {
            return cannedSubstitution.token === substitutionToken;
          });

          var replaceValue = '';

          if (foundCannedSubstitution) {
            replaceValue = _this.getValueForCannedSubstitution(foundCannedSubstitution.key);
          } else {
            replaceValue = _this.getValueForCustomSubstitution(substitution.id);
          }

          if (!replaceValue && substitution.defaultValue) {
            replaceValue = substitution.defaultValue;
          }

          if (replaceValue) {
            responseText = responseText.replace(new RegExp(substitutionToken, 'g'), replaceValue);
          } else {
            if (foundCannedSubstitution && foundCannedSubstitution.missingValueMessage) {
              missingValueMessage = foundCannedSubstitution.missingValueMessage;
            } else {
              missingValueMessage = intl.lookup('purecloud.responses.defaultMissingValueMessage');
            }
          }

          responseSubstitutions.push({
            id: substitution.id,
            value: replaceValue ? replaceValue : ''
          });
        }
      });

      return {
        text: responseText,
        initialText: initialText,
        message: missingValueMessage,
        substitutions: responseSubstitutions
      };
    },
    // Workaround for text alignment compatability issue between Canned Responses created via the QuillsJS editor and rendering
    // the response in the TinyMCE editor. QuillJS applies its own classes for text alignment, instead of inline styles like TinyMCE.
    // Here, we will search through the response text for the QuillJS classes and apply the appropriate inline style for text alignment.
    setTextAlignment: function setTextAlignment(responseText) {
      var tmpDiv = document.createElement('div');
      tmpDiv.innerHTML = responseText || '';

      var _iterator = _createForOfIteratorHelper(tmpDiv.querySelectorAll('.ql-align-center')),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var element = _step.value;
          element.style.textAlign = 'center';
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      var _iterator2 = _createForOfIteratorHelper(tmpDiv.querySelectorAll('.ql-align-left')),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var _element = _step2.value;
          _element.style.textAlign = 'left';
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      var _iterator3 = _createForOfIteratorHelper(tmpDiv.querySelectorAll('.ql-align-right')),
          _step3;

      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var _element2 = _step3.value;
          _element2.style.textAlign = 'right';
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }

      var _iterator4 = _createForOfIteratorHelper(tmpDiv.querySelectorAll('.ql-align-justify')),
          _step4;

      try {
        for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
          var _element3 = _step4.value;
          _element3.style.textAlign = 'justify';
        }
      } catch (err) {
        _iterator4.e(err);
      } finally {
        _iterator4.f();
      }

      return tmpDiv.innerHTML;
    },
    applyFontAndSize: function applyFontAndSize(fontFamily, fontSize, text) {
      var tmpDiv = document.createElement('div');
      tmpDiv.innerHTML = text;
      var tagList = ['p', 'li', 'pre', 'span', 'div', 'blockquote', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

      for (var i = 0; i < tagList.length; i++) {
        var _iterator5 = _createForOfIteratorHelper(tmpDiv.querySelectorAll(tagList[i])),
            _step5;

        try {
          for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
            var tag = _step5.value;
            tag.style.fontFamily = fontFamily;
            tag.style.fontSize = fontSize;
          }
        } catch (err) {
          _iterator5.e(err);
        } finally {
          _iterator5.f();
        }
      }

      return tmpDiv.innerHTML;
    },
    findAll: function findAll(libraryId, pageNumber) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.get('store').findMany('response', [libraryId], pageNumber, RESPONSE_PAGE_SIZE).then(function (data) {
          resolve(_lodash.default.map(data, function (response) {
            var replacementObj = _this2.replaceSubstitutionTokens(response);

            response.set('replaceText', replacementObj.text);
            response.set('message', replacementObj.message);
            return response;
          }));
        }).catch(function (err) {
          reject([]);

          _this2.logger.error('ACD-Responses: Failed to load library responses', {
            error: err
          });
        });
      });
    },
    facetedSearch: function facetedSearch(searchText, libraryIds, templateFilter) {
      var _this3 = this;

      var searchQuery = {
        queryPhrase: searchText || '',
        pageSize: RESPONSE_PAGE_SIZE,
        filters: []
      };

      if (libraryIds && libraryIds.length) {
        searchQuery.filters.push({
          name: 'libraryId',
          operator: 'IN',
          values: libraryIds
        });
      }

      if (templateFilter) {
        searchQuery.filters.push({
          name: 'messagingTemplate',
          operator: 'EQUALS',
          values: ['whatsapp']
        });
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this3.get('store').query('response', searchQuery).then(function (data) {
          var responses = data.get('content').map(function (x) {
            return x.getRecord();
          });
          resolve(_lodash.default.map(responses, function (response) {
            var replacementObj = _this3.replaceSubstitutionTokens(response);

            response.set('replaceText', replacementObj.text);
            response.set('message', replacementObj.message);
            return response;
          }));
        }).catch(function (err) {
          reject([]);

          _this3.logger.error('ACD-Responses: Failed to get search responses', {
            error: err
          });
        });
      });
    }
  });

  _exports.default = _default;
});