define("web-directory/adapters/externalcontacts/external-organization", ["exports", "web-directory/adapters/purecloud"], function (_exports, _purecloud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EXPAND = 'externalDataSources';
  var TIME_OUT = 10000;
  var ORIGINATOR = 'web-directory';

  var _default = _purecloud.default.extend({
    relate: Ember.inject.service(),
    namespace: 'api/v2',
    defaultSerializer: 'externalcontacts/organizations',
    pathForType: function pathForType() {
      return 'externalcontacts/organizations';
    },
    createRecord: function createRecord(store, type, record) {
      this.get('relate').clearCachedAdapter();
      var data = Object.assign(store.serializerFor(type.modelName).serialize(record, {
        includeId: true
      }), {
        originator: ORIGINATOR
      });
      return this.ajax(this.buildURL(type.modelName), 'POST', {
        data: data
      });
    },
    findRecord: function findRecord(store, type, id) {
      var urlAndModel = this.buildURL(type.modelName);
      var params = "?".concat(Ember.$.param({
        expand: EXPAND
      }));
      return this.ajax("".concat(urlAndModel, "/").concat(id).concat(params), 'GET', {
        timeout: TIME_OUT
      }); // msec - we saw ~100 msec latency for this call in DCA testing.  This ought to cover it.
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var id = snapshot.id;
      var url = this.buildURL(type.modelName) + '/' + id;
      var dataType = 'json';
      var data = Object.assign(this.serialize(snapshot, {
        includeId: true
      }), {
        originator: ORIGINATOR
      });
      return this.ajax(url, 'PUT', {
        dataType: dataType,
        data: data
      });
    },
    deleteRecord: function deleteRecord() {
      var returnValue = this._super.apply(this, arguments);

      this.get('relate').clearCachedAdapter();
      return returnValue;
    },
    query: function query(store, type, queryParams) {
      var url = this.buildURL(type.modelName);
      var searchTerm = queryParams.searchTerm;
      var pageNumber = queryParams.pageNumber;
      var pageSize = queryParams.pageSize;
      var originator = queryParams.originator;
      var data = {};
      data.expand = EXPAND;

      if (searchTerm) {
        data.q = searchTerm.replace('tel:', ''); // Strip the 'tel:', if any
      }

      if (pageNumber) {
        data.pageNumber = pageNumber;
      }

      if (pageSize) {
        data.pageSize = pageSize;
      }

      if (originator) {
        data.originator = originator;
      }

      return this.get('relate').getCachedAdapter({
        url: url,
        data: data,
        TIME_OUT: TIME_OUT
      }, this);
    }
  });

  _exports.default = _default;
});