define("web-directory/field-config/field-object", ["exports", "ember-data", "lodash"], function (_exports, _emberData, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    entity: null,
    field: null,
    entries: [],
    attachComputedEntries: Ember.on('init', function () {
      var fieldPath = this.get('field').getFieldPath();

      if (typeof fieldPath === 'string') {
        fieldPath = fieldPath.trim();
        var boundTo = "entity.".concat(fieldPath);
        var boundToEach = "entity.".concat(fieldPath, ".[]");
        Ember.defineProperty(this, 'entries', Ember.computed(boundTo, boundToEach, this.getEntriesFromEntity));
      }
    }),
    fieldPath: Ember.computed('field', function () {
      return this.get('field').getFieldPath();
    }),
    fieldPathClass: Ember.computed('fieldPath', function () {
      var fieldPath = this.get('fieldPath');
      return fieldPath.replace(/\./gi, '-');
    }),
    isEmpty: Ember.computed('field', 'entries', 'entries.[]', function () {
      return this.isFieldEmpty(this.get('field'), this.get('entries'));
    }),
    writeable: Ember.computed('entity.readWritePermissions', 'entries.[]', function () {
      var path = this.get('field').getFieldPath();
      var readWritePermissions = this.get('entity.readWritePermissions');
      var promise = Ember.RSVP.resolve(true);

      if (this.get('entity.type') !== 'group') {
        promise = new Ember.RSVP.Promise(function (resolve) {
          readWritePermissions.then(function (permissions) {
            resolve(_lodash.default.includes(permissions.write, path));
          });
        });
      }

      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    getEntriesFromEntity: function getEntriesFromEntity() {
      var path = this.get('field').getFieldPath();
      var value = this.get('entity').getFieldValue(path);

      if (value === undefined) {
        return [];
      }

      return value;
    },
    isFieldEmpty: function isFieldEmpty(field, entry) {
      if (entry === undefined) {
        return true;
      }

      if (field.repeatable) {
        return _lodash.default.isEmpty(entry) || entry.length <= 0;
      } else {
        return !(entry && entry.value);
      }
    }
  });

  _exports.default = _default;
});