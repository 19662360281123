define("web-directory/models/resource-article", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function singularize(type) {
    type = type.replace('resource_', '');
    type = type.replace(/s$/, '');
    return type;
  }

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr(),
    link: _emberData.default.attr(),
    excerpt: _emberData.default.attr(),
    _type: _emberData.default.attr(),
    type: Ember.computed('_type', function () {
      var type = this.get('_type');
      return singularize(type);
    })
  });

  _exports.default = _default;
});