define("web-directory/components/toggle-item-bar/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      Component = Ember.Component,
      typeOf = Ember.typeOf;
  var ToggleItem = Ember.Object.extend({
    isSelected: computed('itemConfig.id', 'selection.[]', {
      get: function get() {
        return this.get('selection').includes(this.get('itemConfig.id'));
      },
      set: function set(_, active) {
        var selection = this.get('selection');
        var selected = selection.includes(this.get('itemConfig.id'));
        var onchange = this.get('onchange');
        var onclick = this.get('itemConfig.onclick');

        if (onclick) {
          onclick();
        }

        if (onchange) {
          var updated = selection.slice();
          var operation;

          if (active && !selected) {
            operation = 'selected';
            updated.addObject(this.get('itemConfig.id'));
          } else if (!active && selected) {
            operation = 'deselected';
            updated.removeObject(this.get('itemConfig.id'));
          }

          onchange(updated, this.get('itemConfig.id'), operation);
        }
        /*
            Clicking a Toggle Item causes this set function to be called and the onclick and/or onchange events to be triggered.
            Only the selection attribute can modify which Toggle Items are currently active.
        */


        return !active;
      }
    })
  });

  var _default = Component.extend({
    classNames: ['toggle-item-container'],
    attributeBindings: ['role:role'],
    role: 'tablist',
    toggleItems: computed('toggleItemConfigs.[]', 'selection', function () {
      var selection = _lodash.default.flatten([this.get('selection')]);

      var onchange = this.get('onchange');
      var toggleItemConfigs = this.get('toggleItemConfigs') || [];
      var toggleItems = [];
      toggleItemConfigs.reduce(function (validItems, currItem) {
        if (validateToggleItemFormat(currItem)) {
          currItem.assets = {};
          currItem.assets.icon = currItem.icon;
          validItems.push(ToggleItem.create({
            itemConfig: currItem,
            selection: selection,
            onchange: onchange
          }));
        }

        return validItems;
      }, toggleItems);
      return toggleItems;
    }),
    clicked: function clicked(index) {
      var toggleItem = this.get('toggleItems')[index];
      var isSelected = toggleItem.get('isSelected');
      toggleItem.set('isSelected', !isSelected);
    }
  });

  _exports.default = _default;

  function validateToggleItemFormat(item) {
    if (typeOf(item) !== 'instance' || item.id === '' || item.onclick && typeOf(item.onclick) !== 'function') {
      return false;
    }

    return true;
  }
});