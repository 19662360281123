define("web-directory/utils/apps-v2-api-handlers", ["exports", "web-directory/utils/types", "@genesys/service-discovery-web-internal"], function (_exports, _types, _serviceDiscoveryWebInternal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validationChecks = _exports.validateRenderedInstance = _exports.isVisible = _exports.isTrusted = _exports.isInternallyHosted = _exports.isActive = void 0;

  var isTrusted = function isTrusted(renderedInstance) {
    var appDef = renderedInstance.appDef;
    var appType = appDef.type;

    switch (appType) {
      case 'dynaWebComp':
      case 'elementFactoryAppDef':
      case 'elementSingletonAppDef':
        // all internal types are trusted and allowed
        break;

      case 'iframe':
        if (!appDef.trustedDataPassing) {
          return false;
        }

        break;

      default:
        (0, _types.assertNever)(appType);
    }

    return true;
  };

  _exports.isTrusted = isTrusted;

  var isActive = function isActive(renderedInstance) {
    return renderedInstance.appState !== 'STOPPING';
  };

  _exports.isActive = isActive;

  var isVisible = function isVisible(renderedInstance) {
    return renderedInstance.appState !== 'STOPPING' && renderedInstance.userVisible;
  };

  _exports.isVisible = isVisible;

  var isInternallyHosted = function isInternallyHosted(renderedInstance) {
    var appDef = renderedInstance.appDef;
    var appType = appDef.type;

    switch (appType) {
      case 'dynaWebComp':
      case 'elementFactoryAppDef':
      case 'elementSingletonAppDef':
        // all internallyHosted types are trusted and allowed
        break;

      case 'iframe':
        // isKnown throws is the url is invalid, so we catch it and return false
        try {
          if (!(0, _serviceDiscoveryWebInternal.isKnown)(appDef.src.href, {})) {
            return false;
          }
        } catch (error) {
          return false;
        }

        break;

      default:
        (0, _types.assertNever)(appType);
    }

    return true;
  }; //exported for testing


  _exports.isInternallyHosted = isInternallyHosted;
  var validationChecks = {
    isTrusted: {
      check: isTrusted,
      invalidReason: 'appDefNotTrusted'
    },
    isActive: {
      check: isActive,
      invalidReason: 'instanceNotActive'
    },
    isVisible: {
      check: isVisible,
      invalidReason: 'instanceNotVisible'
    }
  };
  _exports.validationChecks = validationChecks;

  var validateRenderedInstance = function validateRenderedInstance(renderedInstance) {
    var invalidReasons = [];

    for (var _len = arguments.length, validations = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      validations[_key - 1] = arguments[_key];
    }

    for (var _i = 0, _validations = validations; _i < _validations.length; _i++) {
      var validation = _validations[_i];
      var validationConfig = validationChecks[validation];
      var result = validationConfig.check(renderedInstance);
      if (!result) invalidReasons.push(validationConfig.invalidReason);
    }

    if (invalidReasons.length > 0) {
      return {
        type: 'invalid',
        reasons: invalidReasons
      };
    }

    return {
      type: 'valid'
    };
  };

  _exports.validateRenderedInstance = validateRenderedInstance;
});