define("web-directory/video-participant/model", ["exports", "ember-webrtc-components/models/video-participant"], function (_exports, _videoParticipant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _videoParticipant.default;
    }
  });
});