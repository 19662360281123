define("web-directory/components/acd-interactions-panel/interaction-sms/message-cards/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['message-cards'],
    cards: null
  });

  _exports.default = _default;
});