define("web-directory/models/interaction/email-message", ["exports", "web-directory/utils/dates"], function (_exports, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmailMessage = Ember.Object.extend({
    autoGenerated: false,
    id: '',
    to: [],
    cc: [],
    bcc: [],
    from: null,
    subject: '',
    attachments: [],
    time: null,
    historyIncluded: false,
    textBody: '',
    htmlBody: '',
    init: function init() {
      this._super.apply(this, arguments);

      var time = this.get('time');

      if (time) {
        this.set('time', (0, _dates.default)(time));
      }
    }
  });
  var _default = EmailMessage;
  _exports.default = _default;
});