define("web-directory/components/inbox-panel-v2/cancellable-textbox/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ib-textbox'],
    classNameBindings: ['isNonEditable::editable'],
    inbox: Ember.inject.service(),
    isNonEditable: true,
    defaultValue: null,
    value: null,
    didInsertElement: function didInsertElement() {
      // store the old value
      this.set('defaultValue', this.get('value'));
    },
    actions: {
      enter: function enter() {
        this.sendAction('onEnter', this.get('value'));
        this.set('defaultValue', this.get('value'));
      },
      cancel: function cancel() {
        // roll back the current value to the default value and trigger the hook
        this.set('value', this.get('defaultValue'));
        this.sendAction('onCancel');
      },
      clearText: function clearText() {
        // clear the current text and trigger the hook
        this.set('value', '');
        this.sendAction('onClear');
      }
    }
  });

  _exports.default = _default;
});