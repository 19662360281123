define("web-directory/components/application-preferences/interactions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CqogU33d",
    "block": "{\"statements\":[[1,[33,[\"preference-setting\"],null,[[\"headerKey\",\"labelKey\",\"type\",\"value\",\"setPreference\"],[\"purecloud.acdInteraction.queues\",[28,[\"checkboxName\"]],\"checkbox\",[28,[\"enableDefaultQueues\"]],[33,[\"action\"],[[28,[null]],\"changeSetting\"],null]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/application-preferences/interactions/template.hbs"
    }
  });

  _exports.default = _default;
});