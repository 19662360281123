define("web-directory/adapters/skillgroup", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;
  var RESTAdapter = _emberData.default.RESTAdapter;

  var _default = RESTAdapter.extend({
    session: inject.service(),
    application: inject.service(),
    host: computed.reads('application.purecloudV2BaseUri'),
    namespace: 'api/v2',
    urlForFindRecord: function urlForFindRecord(id) {
      return "".concat(this.get('host'), "/").concat(this.get('namespace'), "/routing/skillgroups/").concat(id);
    }
  });

  _exports.default = _default;
});