define("web-directory/components/card-view/component", ["exports", "ember-data", "lodash", "web-directory/utils/keystroke"], function (_exports, _emberData, _lodash, _keystroke) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var inject = Ember.inject,
      computed = Ember.computed,
      observer = Ember.observer,
      Component = Ember.Component;

  var _default = Component.extend({
    classNames: ['card-detail-view'],
    intl: inject.service(),
    sidebar: inject.service(),
    presenceSubscription: inject.service(),
    entity: null,
    canMove: true,
    flipster: null,
    currentPerson: null,
    currentPersonId: computed.reads('currentPerson.id'),
    selfAndSiblings: null,
    currentPosition: 0,
    reportTracker: {},
    attrs: {
      setCurrentPerson: function setCurrentPerson() {}
    },
    actions: {
      moveToManager: function moveToManager() {
        var _this = this;

        this.get('currentPerson.manager').then(function (manager) {
          if (manager) {
            var name = manager.get('name');

            var position = _this.getPosition(_this.get('currentPerson'));

            var tracker = _this.get('reportTracker');

            if (tracker === null) {
              tracker = {};
            }

            tracker[name] = position;

            _this.set('reportTracker', tracker);

            return _this.setHierarchy(manager);
          }
        }).then(function () {
          _this.set('didPeersLoad', true);

          Ember.run.scheduleOnce('afterRender', _this, function () {
            this.makeCoverFlow();
          });
        });
      },
      moveToReports: function moveToReports(report) {
        var _this2 = this;

        this.get('currentPerson.directReports').then(function (reports) {
          if (reports && !_lodash.default.isEmpty(reports)) {
            var trackedReport = _this2.get('reportTracker')[_this2.get('currentPerson.name')];

            if (report) {
              return _this2.setHierarchy(report);
            } else if (trackedReport) {
              return _this2.setHierarchy(reports[trackedReport]);
            } else {
              return _this2.setHierarchy(reports[0]);
            }
          }
        }).then(function () {
          Ember.run.scheduleOnce('afterRender', _this2, function () {
            this.makeCoverFlow();
          });
        });
      },
      setPerson: function setPerson(person) {
        this.set('currentPerson', person);
        this.attrs.setCurrentPerson(person);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.once(this, this.setupCoverFlow);
      this.attachTextInputListeners();
      this.attachNavigationListener();
    },
    willClearRender: function willClearRender() {
      this._super.apply(this, arguments);

      this.removeTextInputListeners();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.removeListeners();
    },
    setupCoverFlow: function setupCoverFlow() {
      var _this3 = this;

      var promises = [this.setHierarchy(this.get('entity')), this.get('allPeople')];
      Ember.RSVP.all(promises).then(function () {
        Ember.run.next(_this3, _this3.makeCoverFlow);
      });
    },
    allPeople: computed('entity', function () {
      var entity = this.get('entity');
      var promises = [entity.get('directReports'), entity.get('manager'), entity.get('managerSiblings')];
      return _emberData.default.PromiseArray.create({
        promise: Ember.RSVP.all(promises)
      });
    }),
    haveScrolled: computed('currentPersonId', 'entity.id', function () {
      return this.get('currentPersonId') === this.get('entity.id');
    }),
    peerCount: computed('currentPerson.selfAndSiblings.length', function () {
      var count = this.get('currentPerson.selfAndSiblings.length') - 1;

      if (count === -1) {
        count = 0;
      }

      if (count === 1) {
        return this.get('intl').lookup('person.relationships.peerCount.singular', {
          count: count
        });
      } else {
        return this.get('intl').lookup('person.relationships.peerCount.plural', {
          count: count
        });
      }
    }),
    reindexOnSidebarChange: observer('sidebar.communicationPanel', function () {
      $(window).trigger('resize');
    }),
    setHierarchy: function setHierarchy(person) {
      var _this4 = this;

      this.set('didPeersLoad', false);
      var promises = [person.get('selfAndSiblings'), person.get('directReports'), person.get('manager')];
      return Ember.RSVP.all(promises).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 3),
            siblings = _ref2[0],
            directReports = _ref2[1],
            manager = _ref2[2];

        var selfAndSiblings = siblings;

        if (_lodash.default.isEmpty(siblings)) {
          selfAndSiblings = [person];
        }

        if (_lodash.default.isEmpty(directReports)) {
          directReports = [];
        }

        _this4.set('currentPerson', person);

        _this4.get('setCurrentPerson')(person);

        _this4.set('currentPosition', _this4._getPosition(selfAndSiblings, person));

        _this4.set('selfAndSiblings', selfAndSiblings);

        _this4.set('didPeersLoad', true);

        if (selfAndSiblings.length === 1 && directReports.length === 0 && _lodash.default.isEmpty(manager)) {
          _this4.set('canMove', false);
        } else {
          _this4.set('canMove', true);
        }
      });
    },
    makeCoverFlow: function makeCoverFlow() {
      this.get('sidebar.communicationPanel');
      var startPosition = this.get('currentPosition');

      if (_lodash.default.isEmpty(this.$('li')) || !this.$('.flipster')) {
        return;
      }

      if (!_lodash.default.isEmpty(this.$('.flip-content'))) {
        // if we already have flipster elements, we only want to re-index
        this.$('.flip-content').contents().unwrap();
      }

      this.$('.flipster').removeClass('flipster--active');
      var flipster = this.$('.flipster').flipster({
        itemContainer: 'ul',
        itemSelector: 'li',
        style: 'flat',
        start: startPosition,
        fadeIn: 0,
        spacing: -0.3,
        scrollwheel: false,
        keyboard: false,
        touch: false,
        nav: false,
        // If true, flipster will insert an unordered list of the slides
        buttons: false,
        // If true, flipster will insert Previous / Next buttons
        onItemSwitch: function (to
        /*, from*/
        ) {
          Ember.run.debounce(this, this.changeCurrentPerson, to, 300);
        }.bind(this)
      });
      this.set('flipster', flipster);
      this.$('.flipster__item--current').addClass('flip-current');
    },
    changeCurrentPerson: function changeCurrentPerson(toElem) {
      var _this5 = this;

      Ember.run.scheduleOnce('afterRender', function () {
        var card = Ember.$(toElem).find('.card');
        var entityId = card.data('entity-id');

        if (entityId) {
          var entity = _this5.get('selfAndSiblings').findBy('id', entityId);

          _this5.set('currentPerson', entity);

          _this5.attrs.setCurrentPerson(entity);

          _this5.$('li').removeClass('flip-current');

          _this5.$('.flipster__item--current').addClass('flip-current');
        }
      });
    },
    _getPosition: function _getPosition(selfAndSiblings, person) {
      if (_lodash.default.isEmpty(selfAndSiblings)) {
        return 0;
      }

      for (var position = 0; position < selfAndSiblings.length; position++) {
        if (selfAndSiblings[position].get('id') === person.get('id')) {
          return position;
        }
      }
    },
    getPosition: function getPosition(person) {
      return this._getPosition(this.get('selfAndSiblings'), person);
    },
    attachNavigationListener: function attachNavigationListener() {
      var _this6 = this;

      var event = "keydown.".concat(this.elementId);
      Ember.$(document).on(event, function (event) {
        return Ember.run(function () {
          return _this6.keyDown(event);
        });
      });
    },
    attachTextInputListeners: function attachTextInputListeners() {
      var _this7 = this;

      var ns = '.' + this.get('elementId');
      var fields = $.merge(Ember.$('.ember-text-area'), Ember.$('.ember-text-field'));
      fields.on('keydown' + ns, function (event) {
        Ember.run(function () {
          var key = (0, _keystroke.eventToString)(event);

          if (key === 'DOWN_ARROW' || key === 'UP_ARROW' || key === 'RIGHT_ARROW' || key === 'LEFT_ARROW') {
            if (key === 'LEFT_ARROW' || key === 'RIGHT_ARROW') {
              event.stopPropagation();
            }
          }
        });
      });
      fields.on('focus' + ns, function () {
        Ember.run(function () {
          return _this7.set('canMove', false);
        });
      }).on('focusout' + ns, function () {
        Ember.run(function () {
          return _this7.set('canMove', true);
        });
      });
    },
    removeTextInputListeners: function removeTextInputListeners() {
      var ns = '.' + this.get('elementId');
      var fields = $.merge(Ember.$('.ember-text-area'), Ember.$('.ember-text-field'));
      fields.off(ns);
      Ember.$(document).off(ns);
      Ember.$(window).off('.flipster');
    },
    removeListeners: function removeListeners() {
      var ns = ".".concat(this.elementId);
      Ember.$(document).off(ns);
      Ember.$(window).off(ns);
      Ember.$(window).off('.flipster');
    },
    keyDown: function keyDown(event) {
      var key = (0, _keystroke.eventToString)(event);
      var chatOpen = this.get('sidebar.communicationPanel') === 'conversations';

      if (Ember.$(event.target).is('textarea') && chatOpen) {
        return;
      }

      if ((0, _keystroke.isNavigation)(event.keyCode) && this.get('canMove')) {
        if (key === 'LEFT_ARROW') {
          this.get('flipster').flipster('prev');
        } else if (key === 'RIGHT_ARROW') {
          this.get('flipster').flipster('next');
        } else if (key === 'UP_ARROW') {
          this.send('moveToManager');
        } else if (key === 'DOWN_ARROW') {
          this.send('moveToReports');
        }
      }
    }
  });

  _exports.default = _default;
});