define("web-directory/components/multi-select-target-dropdown/component", ["exports", "ember-purecloud-components/utils/email", "web-directory/utils/acd", "web-directory/utils/acd-v2"], function (_exports, _email, _acd, _acdV) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * The default value for max tags to be applied. Configurable in the component.
   */
  var MAX_TAGS = 50;
  /**
   * @deprecated
   *
   * Use `multi-select-target-dropdown-v2 instead. Any updates made in this component should
   * be reflected (if able) in the `v2` component as well.
   *
   * @notes
   *
   * This component can be removed with `acdTagsInput` removal. After removal we should drop
   * the `v2` name from the other component as well.
   */

  var _default = Ember.Component.extend({
    classNames: ['multi-select-target-dropdown'],
    classNameBindings: ['hideTargetDropdown:hide-target-dropdown'],

    /**
     * @attribute
     *
     * The currently authenticated user information
     */
    authUser: null,

    /**
     * @attribute
     *
     * the type of interaction this is in context of
     */
    interactionType: null,

    /**
     * @attribute
     *
     * the placeholder text shown on the input
     */
    placeholder: null,

    /**
     * @attribute
     *
     * the filters to be included in this target selector
     */
    includeFilters: [],

    /**
     * @attribute
     *
     * The currently selected value of the component. This value should be passed
     * in and stored by the parent. We do not want to mutate this value from within the
     * component itself.
     */
    value: [],

    /**
     * @attribute
     *
     * `true` if the target dropdown logic should be supressed and the dropdown be hidden.
     * This should be used if the user has been tasked with added a recipient, but shouldn't
     * use the systems internal data to create the recipient, but rather just leveraging the input
     */
    hideTargetDropdown: false,

    /**
     * Maps the current value of the component into an array of id's or unique values to pass
     * to the target dropdown in order to prevent the ability of selecting the same participant
     * twice.
     */
    valueIds: Ember.computed.map('value', function (tag) {
      var _tag$data$id, _tag$data;

      return (_tag$data$id = (_tag$data = tag.data) === null || _tag$data === void 0 ? void 0 : _tag$data.id) !== null && _tag$data$id !== void 0 ? _tag$data$id : tag.value;
    }),

    /**
     * @attribute
     *
     * the max amount of tags that can be applied to the selector
     */
    maxTags: MAX_TAGS,

    /**
     * @attribute this value can be mutated within this component
     *
     * @example 2-way data binding
     * searchValue=(mut searchValue)
     *
     * the value of the main input. if updated by parent, it will automatically up the input
     * in the template and will search once input has been focused.
     */
    searchValue: '',

    /**
     * @attribute
     *
     * class names passed in to be applied to the input tag
     */
    inputClasses: '',

    /**
     * `true` if the target dropdown is open
     */
    open: false,

    /**
     * Reference to the selector element in the template
     */
    selector: null,

    /**
     * Reference to the input element in the template created after the bootstrap
     * selector component has been initialized
     */
    input: null,

    /**
     * A mock interaction object to set the type of for target selection context
     */
    mockInteraction: Ember.computed('interactionType', function () {
      return {
        type: this.get('interactionType')
      };
    }),

    /**
     * @listener
     *
     * reference to the listener for when the element has been clicked outside of.
     */
    outsideClickListener: function outsideClickListener(event) {
      return (0, _acd.warnClosureAction)('outsideClickListener', event);
    },

    /**
     * Invoked whenever an attribute has been updated in the component. We need to check if a new value has
     * been passed from the parent and then apply that value to the component by creating tags.
     */
    didReceiveAttrs: function didReceiveAttrs(attributes) {
      var _this = this,
          _newAttrs$searchValue,
          _oldAttrs$searchValue,
          _newAttrs$value,
          _newAttrs$value$value,
          _oldAttrs$value,
          _oldAttrs$value$value;

      this._super.apply(this, arguments);

      if (this.get('selector')) {
        var _this$get;

        var value = (_this$get = this.get('value')) !== null && _this$get !== void 0 ? _this$get : [];

        if (value.length) {
          value.forEach(function (tag) {
            return _this.addTag(tag);
          });
        }
      }

      var oldAttrs = attributes.oldAttrs,
          newAttrs = attributes.newAttrs;

      if (newAttrs && (!oldAttrs || (newAttrs === null || newAttrs === void 0 ? void 0 : (_newAttrs$searchValue = newAttrs.searchValue) === null || _newAttrs$searchValue === void 0 ? void 0 : _newAttrs$searchValue.value) !== (oldAttrs === null || oldAttrs === void 0 ? void 0 : (_oldAttrs$searchValue = oldAttrs.searchValue) === null || _oldAttrs$searchValue === void 0 ? void 0 : _oldAttrs$searchValue.value))) {
        var input = this.getInput();

        if (input) {
          input.value = newAttrs.searchValue.value;
        }
      } // if there used to be items in the value array previously, but the new attrs have remove them,
      // the tags should be cleared from the input


      if (!(newAttrs !== null && newAttrs !== void 0 && (_newAttrs$value = newAttrs.value) !== null && _newAttrs$value !== void 0 && (_newAttrs$value$value = _newAttrs$value.value) !== null && _newAttrs$value$value !== void 0 && _newAttrs$value$value.length) && oldAttrs !== null && oldAttrs !== void 0 && (_oldAttrs$value = oldAttrs.value) !== null && _oldAttrs$value !== void 0 && (_oldAttrs$value$value = _oldAttrs$value.value) !== null && _oldAttrs$value$value !== void 0 && _oldAttrs$value$value.length) {
        this.clearTags();
      }
    },

    /**
     * Invoked when the element has been inserted into the DOM
     */
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!this.get('hideTargetDropdown')) {
        this.hideOnClickOutside(this.element);
      }

      this.initSelector();
    },

    /**
     * Invoked when the component has been destroyed
     */
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      document.removeEventListener('click', this.outsideClickListener);
    },

    /**
     * Listeners to determine if the user has clicked outside of the element being
     * passed through. If the element has been clicked outside of, then the dropdown
     * will close.
     *
     * @param element the element to check against
     */
    hideOnClickOutside: function hideOnClickOutside(element) {
      var _this2 = this;

      if (element) {
        this.outsideClickListener = function (event) {
          if (!element.contains(event.target)) {
            _this2.set('open', false);
          }
        };

        document.addEventListener('click', this.outsideClickListener);
      }
    },

    /**
     * Initialized the selector to be a tag based input from bootstrap taginput component
     */
    initSelector: function initSelector() {
      var _this3 = this,
          _this$get2;

      var $targetSelector = this.$('#targetSelect');
      var $selector = $targetSelector.tagsinput({
        itemText: 'text',
        itemValue: 'value',
        maxTags: MAX_TAGS,
        confirmKeys: []
      });
      this.set('selector', $selector[0]);
      $targetSelector.on('itemRemoved', function (_ref) {
        var item = _ref.item;
        return _this3.targetRemoved(item);
      });
      (_this$get2 = this.get('value')) === null || _this$get2 === void 0 ? void 0 : _this$get2.forEach(function (tag) {
        return _this3.addTag(tag);
      });
      this.initInput();
    },

    /**
     * Initializes the input and all of the events that are needed to make searching and
     * selection working properly. The selector needs to be initialized before this can run.
     */
    initInput: function initInput() {
      var _this4 = this;

      var input = this.getInput();
      input.classList.add(this.get('inputClasses'));
      input.addEventListener('focus', function () {
        return _this4.onInputFocus();
      });
      input.addEventListener('input', function (event) {
        return _this4.onInputChange(event);
      });
      input.addEventListener('keypress', function (event) {
        return _this4.onKeypress(event);
      });
    },

    /**
     * Adds the corresponding tag data to the selector. Although the selector will not allow
     * duplicate values to be added, if we do not check if the item exists, then there will be a weird
     * styling animation applied for added tags. So to prevent that, we check if it exists first so we
     * aren't adding the same values multiple times.
     *
     * @param tag the tag to add to the selector
     */
    addTag: function addTag(tag) {
      var selector = this.get('selector');

      if (selector) {
        var itemsArray = selector.itemsArray;
        var exists = itemsArray.some(function (item) {
          return item.value === tag.value;
        });

        if (!exists) {
          var tagCopy = _objectSpread(_objectSpread({}, tag), {}, {
            text: (0, _acdV.replaceHtmlSpecialChars)(tag === null || tag === void 0 ? void 0 : tag.text.toString())
          });

          selector === null || selector === void 0 ? void 0 : selector.add(tagCopy);
        }
      }
    },

    /**
     * Removes all of the tags from the selector
     */
    clearTags: function clearTags() {
      var selector = this.get('selector');

      if (selector) {
        var itemsArray = selector.itemsArray;

        if (itemsArray.length) {
          selector.removeAll();
        }
      }
    },

    /**
     * Gets reference to the html input element.
     */
    getInput: function getInput() {
      var _this$get3;

      var input = this.get('input');
      if (input) return input;
      var $inputs = (_this$get3 = this.get('selector')) === null || _this$get3 === void 0 ? void 0 : _this$get3.$input;

      if ($inputs !== null && $inputs !== void 0 && $inputs.length) {
        input = $inputs[0];
        this.set('input', input);
        return input;
      }

      return null;
    },

    /**
     * Gets reference to the html input element.
     */
    getBootstrapInput: function getBootstrapInput() {
      return this.$('div.bootstrap-tagsinput input');
    },

    /**
     * Resets the search facet of the component back to it's initial state.
     */
    resetSearch: function resetSearch() {
      var input = this.getInput();

      if (input) {
        input.value = '';
        this.updateSearch('');
        input.focus();
      }
    },

    /**
     * Updates the `searchValue` property and any other additional logic that may need
     * to go along with that in one isolated place.
     *
     * @param value the value to update the search property with
     */
    updateSearch: function updateSearch(value) {
      this.set('searchValue', value);
      this.set('open', !!value);
    },

    /**
     * Clears the currently selected value in the component and refocuses on the input
     * after a quick delay. We need to give the template a moment to render the input
     * back before trying to focus back into it.
     */
    clearSelection: function clearSelection() {
      var _this5 = this;

      setTimeout(function () {
        var input = _this5.getInput();

        if (input) {
          input.focus();
        }
      }, 0);
    },

    /**
     * Invoked whenever the user presses down a key on the main input of this component.
     *
     * @param event data corresponding to the keypress
     */
    onKeypress: function onKeypress(event) {
      if (event.key === 'Enter') {
        // prevents the first row of the target dropdown from selecting in this context
        event.preventDefault();
        this.onInputEnter();
      }
    },

    /**
     * Invoked when the user presses the enter key while focused in the input element.
     * We check to see if the current search value is a valid value to be added as a raw
     * tag.
     */
    onInputEnter: function onInputEnter() {
      var searchValue = this.get('searchValue');

      if (!searchValue) {
        this.submit();
        return;
      }

      switch (this.get('interactionType')) {
        case 'email':
          if ((0, _email.isValidEmailAddress)(searchValue)) {
            this.targetSelected({
              email: searchValue
            });
            this.resetSearch();
          }

          break;

        default:
          if (searchValue.trim()) {
            this.targetSelected({
              phoneNumber: searchValue
            });
            this.resetSearch();
          }

          break;
      }
    },

    /**
     * Invoked when the user inputs data into the main input of the component.
     *
     * @param event the event data provided by the input
     */
    onInputChange: function onInputChange(event) {
      var _ref2 = event.target,
          value = _ref2.value;
      this.updateSearch(value);
    },

    /**
     * Invoked when the user focused the input
     */
    onInputFocus: function onInputFocus() {
      if (this.get('searchValue')) {
        this.set('open', true);
      }
    },

    /**
     * @action
     *
     * Handles when the user presses the enter key while the search input is empty
     */
    submit: function submit() {
      return (0, _acd.warnClosureAction)('submit', null);
    },

    /**
     * @action
     *
     * Handles the removal of a target
     *
     * @param target the target to remove from the value
     */
    targetRemoved: function targetRemoved(tag) {
      return (0, _acd.warnClosureAction)('targetRemoved', tag);
    },

    /**
     * @action passed from the parent
     *
     * Handles the selection of a target
     *
     * @param target the selected data corresponding to the target
     */
    targetSelected: function targetSelected(target) {
      return (0, _acd.warnClosureAction)('targetSelected', target);
    },
    actions: {
      /**
       * Invoked when the user selects data from within the target selector component
       *
       * @param target the data that was selected
       */
      onTargetSelected: function onTargetSelected(target) {
        this.set('open', false);
        this.targetSelected(target);
        this.resetSearch();
      }
    }
  });

  _exports.default = _default;
});