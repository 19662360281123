define("web-directory/utils/presence-label-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.activityCounter = activityCounter;

  function activityCounter(contactCenter, enterprise) {
    var contactCenterCount = 0;
    var enterpriseCount = 0;

    if (typeof contactCenter === 'number') {
      contactCenterCount = contactCenter;
    } else if (contactCenter && contactCenter.active) {
      contactCenterCount = contactCenter.active;
    }

    if (typeof enterprise === 'number') {
      enterpriseCount = enterprise;
    } else if (enterprise && enterprise.active) {
      enterpriseCount = enterprise.active;
    }

    return contactCenterCount + enterpriseCount;
  }
});