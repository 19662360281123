define("web-directory/routes/maintenance", ["exports", "web-directory/models/unrecoverable-app-failure", "web-directory/services/analytics", "web-directory/utils/types", "web-directory/mixins/unprotected-route-mixin", "web-directory/utils/newrelic"], function (_exports, _unrecoverableAppFailure, _analytics, _types, _unprotectedRouteMixin, _newrelic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var inject = Ember.inject,
      Route = Ember.Route;
  var MaintenanceRoute = Route.extend(_unprotectedRouteMixin.default, {
    intl: inject.service(),
    session: inject.service(),
    applicationService: inject.service('application'),
    sidebar: inject.service(),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      return Ember.RSVP.resolve().then(function () {
        return _this._super(transition);
      }).then(function () {
        if ((0, _types.hasKey)(transition, 'isActive') && transition.isActive === false) {
          // Route canceled for auth
          return;
        }

        var errorState = _this.get('applicationService').get('errorState');

        if (!errorState) {
          return _this.transitionTo('index');
        }

        var bootstrapErrorDetails;

        if ((0, _unrecoverableAppFailure.isSessionBootstrapFailure)(errorState)) {
          bootstrapErrorDetails = {
            failedBootstrapStep: errorState.failedStep,
            status: errorState.status
          };
        }

        (0, _newrelic.addPageAction)('maintenanceMode', Object.assign({
          failureType: errorState.type
        }, bootstrapErrorDetails, (0, _analytics.buildCommonTelemetryContext)({
          applicationService: _this.get('applicationService'),
          sessionService: _this.get('session'),
          intlService: _this.get('intl')
        })));
        return _this.get('intl').loadTranslations();
      });
    },
    model: function model() {
      var _arguments = arguments,
          _this2 = this;

      return Ember.RSVP.resolve().then(function () {
        return _this2._super.apply(_this2, _toConsumableArray(_arguments));
      }).then(function () {
        // Force close the sidebar to ensure the view can be seen
        _this2.get('sidebar').closePanel(); // We assert errorState exists in the beforeModel hook.
        // The controller still needs to account for untyped errorState until we're fully TS


        return _this2.get('applicationService').get('errorState');
      });
    },
    actions: {
      reloadApp: function reloadApp() {
        this.get('session')._reloadApplication();
      }
    }
  });
  var _default = MaintenanceRoute;
  _exports.default = _default;
});