define("web-directory/serializers/public-api-user", ["exports", "web-directory/serializers/purecloud"], function (_exports, _purecloud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _purecloud.default.extend({
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id) {
      payload.links = {
        profile: "/api/v2/users/".concat(id, "/profile")
      };

      var normalized = this._super.apply(this, arguments);

      return normalized;
    }
  });

  _exports.default = _default;
});