define("web-directory/components/tab-ify/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    changeSelectedTab: 'changeSelectedTab',
    actions: {
      changeSelectedTab: function changeSelectedTab(tab) {
        this.set('selected', tab.key);
        this.sendAction('onChange', tab);
      }
    }
  });

  _exports.default = _default;
});