define("web-directory/components/target-dropdown/types/queue-details.type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.QueueWaitTimeFormula = _exports.QueueDetailQualifier = _exports.QueueDetailMetric = void 0;
  var QueueDetailMetric = ['oWaiting', 'oOnQueueUsers'];
  _exports.QueueDetailMetric = QueueDetailMetric;
  var QueueDetailQualifier = ['NOT_RESPONDING', 'IDLE', 'INTERACTING'];
  _exports.QueueDetailQualifier = QueueDetailQualifier;
  var QueueWaitTimeFormula = ['BEST'];
  _exports.QueueWaitTimeFormula = QueueWaitTimeFormula;
});