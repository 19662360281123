define("web-directory/components/acd-interactions-panel/interaction-conference-hangup-dropdown/component", ["exports", "web-directory/mixins/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_logger.default, {
    classNames: ['interaction-conference-hangup-dropdown'],

    /**
     * @Action
     * Closure action passed to the component to be invoked at the parent level
     */
    endForAll: function endForAll() {
      Ember.Logger.warn('End For All closure action has not been set up for this component');
    },

    /**
     * @Action
     * Closure action passed to the component to be invoked at the parent level
     */
    leave: function leave() {
      Ember.Logger.warn('Leave closure action has not been set up for this component');
    },
    actions: {
      /**
       * Invoked when the user wants to end the call for everyone connected
       */
      onEndForAll: function onEndForAll() {
        this.endForAll();
      },

      /**
       * Invoked when the user just wants to leave the call but keep the call
       * connected for other participants
       */
      onLeave: function onLeave() {
        this.leave();
      }
    }
  });

  _exports.default = _default;
});