define("web-directory/instance-initializers/webrtc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function hasQueryParam(param) {
    var search = window.location.search;

    if (!search) {
      return false;
    }

    var params = new URLSearchParams(window.location.search);
    return params.get(param) === 'true';
  }

  function initialize(instance) {
    ['disableSoftphone', 'disableProactiveDevicePermissions'].forEach(function (param) {
      if (hasQueryParam(param)) {
        var webrtc = instance.lookup('service:webrtc');
        webrtc.logger.info("Setting webrtc.".concat(param, " to true based on query param"));
        webrtc.set(param, true);
      }
    });
  }

  var _default = {
    name: 'webrtc',
    initialize: initialize
  };
  _exports.default = _default;
});