define("web-directory/serializers/externalcontacts/whatsapp-id", ["exports", "ember-data", "web-directory/utils/message-channels"], function (_exports, _emberData, _messageChannels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    primaryKey: (0, _messageChannels.modelKeyFor)('whatsapp')
  });

  _exports.default = _default;
});