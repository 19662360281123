define("web-directory/instance-initializers/recording-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'setupRecordingService',
    initialize: function initialize(instance) {
      instance.lookup('service:recording');
    }
  };
  _exports.default = _default;
});