define("web-directory/models/app-api-call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.APPS_API_PROTOCOL = void 0;
  _exports.buildOutboundMsgPayload = buildOutboundMsgPayload;
  _exports.default = void 0;
  _exports.parseMessage = parseMessage;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var typeOf = Ember.typeOf,
      isPresent = Ember.isPresent,
      copy = Ember.copy,
      computed = Ember.computed,
      assign = Ember.assign;
  var APPS_API_PROTOCOL = 'purecloud-client-apps';
  _exports.APPS_API_PROTOCOL = APPS_API_PROTOCOL;
  var PROTOCOL_FIELD_NAME = 'protocol'; // ----- Inbound Message Properties

  var PROTOCOL_AGENT_FIELD_NAME = 'protocolAgentName';
  var PROTOCOL_AGENT_VERSION_FIELD_NAME = 'protocolAgentVersion';
  var API_ACTION_FIELD_NAME = 'action'; // ----- Outbound Message Properties

  var OUTBOUND_MSG_EVENT_TYPE_FIELD_NAME = 'purecloudEventType';
  /**
   * Model representing an inbound API call from the Apps SDK or from an internal app/frame utilizing direct
   * postMessage calls.
   */

  var AppApiCall = Ember.Object.extend({
    internalPcCall: false,
    srcApp: null,
    srcAppInstance: null,
    protocol: null,
    protocolAgentName: null,
    protocolAgentVersion: null,
    action: null,
    actionDetails: null,
    appId: computed('srcApp', 'srcAppInstance', function () {
      var result = this.get('srcAppInstance.appId');

      if (!result) {
        result = this.get('srcApp.id');
      }

      return result || null;
    })
  });
  /**
   * Parses and validates the provided messagePayload into an AppApiCall instance.
   *
   * actionDetails will only contain properties which are applicable to the specific api call.  i.e.
   * metadata props like the action, protocol, protocol agent, etc. will only exist in their
   * specific instance variables.
   *
   * @param {object} messagePayload - The payload to parse; fails if not an object
   * @param {boolean} requireProtocol - If the validation should fail on protocol fields
   *
   * @returns an AppApiCall if the message is valid
   * @throws Error if the payload structure is invalid;
   *  the protocol is missing/incorrect (in strict mode);
   *  the action is missin/incorrect
   */

  function parseMessage(messagePayload, requireProtocol) {
    var result = null;

    if (typeOf(messagePayload) !== 'object') {
      throw new Error('invalidPayload');
    } else {
      var protocol = messagePayload[PROTOCOL_FIELD_NAME];

      if (requireProtocol) {
        if (!Object.prototype.hasOwnProperty.call(messagePayload, PROTOCOL_FIELD_NAME) || typeOf(protocol) !== 'string' || !isPresent(protocol)) {
          throw new Error('missingProtocol');
        }

        if (protocol !== APPS_API_PROTOCOL) {
          throw new Error('invalidAppsProtocol');
        }
      }

      var action = messagePayload[API_ACTION_FIELD_NAME];

      if (!Object.prototype.hasOwnProperty.call(messagePayload, API_ACTION_FIELD_NAME) || typeOf(action) !== 'string' || !isPresent(action)) {
        throw new Error('missingAction');
      }

      var actionDetails = copy(messagePayload, true); // Prune metadata and control fields from the actionDetails

      [PROTOCOL_FIELD_NAME, PROTOCOL_AGENT_FIELD_NAME, PROTOCOL_AGENT_VERSION_FIELD_NAME, API_ACTION_FIELD_NAME].forEach(function (fieldToPrune) {
        delete actionDetails[fieldToPrune];
      });
      result = AppApiCall.create({
        protocol: protocol,
        protocolAgentName: messagePayload[PROTOCOL_AGENT_FIELD_NAME],
        protocolAgentVersion: messagePayload[PROTOCOL_AGENT_VERSION_FIELD_NAME],
        action: action,
        actionDetails: actionDetails
      });
    }

    return result;
  }
  /**
   * Constructs the full payload of a message to be sent to an Embedded Client App.
   * Metadata fields will be appended to any provided custom payload to create the normalized
   * event structure.
   *
   * @param {string} eventType - The eventType of this message.
   * @param {object|null|undefined} [customPayload={}] - Additional payload to send to the the app
   *
   * @throws Error if eventType is not a non-blank string
   * @throws Error if the customPayload is not null, undefined, or an object
   * @throws Error if any of the reserved, metadata keys are provided in the customPayload
   *
   * @returns A normalized payload with all necessary metadata suitable to dispatch to an Embedded Client App
   */


  function buildOutboundMsgPayload(eventType) {
    var _assign;

    var customPayload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if (typeOf(eventType) !== 'string' || eventType.trim().length === 0) {
      throw new Error('Non-empty string must be provided as the eventType');
    }

    var payloadType = typeOf(customPayload);

    if (payloadType !== 'null' && payloadType !== 'object') {
      throw new Error('Payload must be null, undefined, or an object');
    } // Ensure no metadata fields are in the provided customPayload


    [PROTOCOL_FIELD_NAME, OUTBOUND_MSG_EVENT_TYPE_FIELD_NAME].forEach(function (currMetadataFieldName) {
      if (customPayload && Object.prototype.hasOwnProperty.call(customPayload, currMetadataFieldName)) {
        throw new Error("Payload contains reserved metadata prop '".concat(currMetadataFieldName, "'"));
      }
    });
    return assign((_assign = {}, _defineProperty(_assign, PROTOCOL_FIELD_NAME, APPS_API_PROTOCOL), _defineProperty(_assign, OUTBOUND_MSG_EVENT_TYPE_FIELD_NAME, eventType), _assign), customPayload || {});
  }

  var _default = AppApiCall;
  _exports.default = _default;
});