define("web-directory/utils/theme-loader", ["exports", "should-send-same-site-none", "web-directory/utils/net"], function (_exports, _shouldSendSameSiteNone, _net) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.THEME_COOKIE = _exports.THEME_CLASSES = _exports.TEN_YEARS = _exports.DEFAULT_THEME = _exports.BASE_THEME_LIST = void 0;
  _exports.getThemeClass = getThemeClass;
  _exports.getThemeList = getThemeList;
  _exports.loadTheme = loadTheme;
  _exports.resetTheme = resetTheme;
  _exports.setThemeClass = setThemeClass;
  _exports.setThemeCookie = setThemeCookie;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * The current theme that the user has saved based on cookie value
   */
  var currentTheme = null;
  /**
   * The name of the cookie being set.
   */

  var THEME_COOKIE = 'pureCloudTheme';
  /**
   * The time in seconds that the cookie will expire
   */

  _exports.THEME_COOKIE = THEME_COOKIE;
  var TEN_YEARS = 315360000; // in seconds

  /**
   * List of all possible themes that could be supported based on feature toggles and base classes. Key/value pair
   * to make sure values don't differ throughout the logic.
   * Please add a note next to the theme key/value detailing the ticket and feature toggle associated with it.
   *
   * Future Todo: We should move away from tacking on `-theme` to the end of the classes.
   */

  _exports.TEN_YEARS = TEN_YEARS;
  var THEME_CLASSES = {
    LIGHT: 'light-theme',
    // default theme, always supported
    DARK: 'dark-theme',
    // FT - webUiDarkTheme
    BASE: 'purecloud-theme' // Legacy theme needed for app styling / TODO: Remove upon theme rework

  };
  /**
   * This is the theme that the app defaults to if a theme is not saved or selected yet.
   */

  _exports.THEME_CLASSES = THEME_CLASSES;
  var DEFAULT_THEME = THEME_CLASSES.LIGHT;
  /**
   * This is an array of the base themes that are always supported no matter what. Other themes may need to be hidden behind a feature toggle.
   */

  _exports.DEFAULT_THEME = DEFAULT_THEME;
  var BASE_THEME_LIST = [THEME_CLASSES.LIGHT];
  /**
   * This is the current theme list to select from. This function calculates all supported themes
   * based on feature toggles and returns a value of all themes that can be supported.
   * DEV NOTE: Add all new theme logic to this function to determine what themes to return.
   */

  _exports.BASE_THEME_LIST = BASE_THEME_LIST;

  function getThemeList(session) {
    var extraThemes = []; // Only check feature toggle based themes if session with features object is provided

    if (session && session.features) {
      if (session.features.webUiDarkTheme) {
        extraThemes.push(THEME_CLASSES.DARK);
      }
    }

    return [].concat(BASE_THEME_LIST, extraThemes).sort(function (a, b) {
      if (a > b) {
        return 1;
      } else if (b > a) {
        return -1;
      } else {
        return 0;
      }
    });
  }
  /**
   * Loads in the theme from the browsers cookies if there was a theme already set.
   * @param {string} cookies a long string of cookies that are character separated
   * @returns A new theming object used to set the applications theme
   */


  function loadTheme(session) {
    var cookies = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : document.cookie;
    var cookie = cookies.split(/;[ \t]?/).map(function (cookie) {
      var _cookie$split = cookie.split('='),
          _cookie$split2 = _slicedToArray(_cookie$split, 2),
          name = _cookie$split2[0],
          theme = _cookie$split2[1];

      return {
        name: name,
        theme: theme
      };
    }).find(function (_ref) {
      var name = _ref.name;
      return name === THEME_COOKIE;
    });

    if (cookie && getThemeList(session).find(function (theme) {
      return theme === cookie.theme;
    })) {
      return cookie;
    } else {
      return {
        theme: DEFAULT_THEME
      };
    }
  }
  /**
   * Returns the current theme value being applied to the app
   * @returns the current theme value
   */


  function getThemeClass() {
    return currentTheme;
  }
  /**
   * Sets the class of the provided element with the provided theme. Also removes the old theme class from the same element (if found), and sets the
   * `currentTheme` to the new value.
   * @param {{theme: THEME_CLASSES, element: HTMLELement}} param0 any object that contains at least the new theme and element to apply the class to.
   */


  function setThemeClass() {
    var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref2$theme = _ref2.theme,
        theme = _ref2$theme === void 0 ? THEME_CLASSES.LIGHT : _ref2$theme,
        _ref2$element = _ref2.element,
        element = _ref2$element === void 0 ? document.body : _ref2$element;

    if (currentTheme) {
      element.classList.remove(currentTheme);
    }

    if (!element.classList.contains(THEME_CLASSES.BASE)) {
      element.classList.add(THEME_CLASSES.BASE);
    }

    if (!element.classList.contains(theme)) {
      element.classList.add(theme);
    }

    currentTheme = theme;
  }
  /**
   * Sets the theme cookie on the application to determine how the application should be styled when loaded in. Will alter the cookie values based on conditional values
   * such as the current locations protocol as well as the current browser being viewed on.
   * @param {{theme: THEME_CLASSES, duration: number, path: string, protocol: string, domain: string, setCookie: function, userAgent: string}} param0 an object used to set the new cookie value regarding the application theme
   */


  function setThemeCookie() {
    var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref3$theme = _ref3.theme,
        theme = _ref3$theme === void 0 ? THEME_CLASSES.LIGHT : _ref3$theme,
        _ref3$duration = _ref3.duration,
        duration = _ref3$duration === void 0 ? TEN_YEARS : _ref3$duration,
        _ref3$path = _ref3.path,
        path = _ref3$path === void 0 ? '/' : _ref3$path,
        _ref3$protocol = _ref3.protocol,
        protocol = _ref3$protocol === void 0 ? _getCurrProtocol() : _ref3$protocol,
        _ref3$domain = _ref3.domain,
        domain = _ref3$domain === void 0 ? (0, _net.getEnvCookieDomain)() : _ref3$domain,
        _ref3$setCookie = _ref3.setCookie,
        setCookie = _ref3$setCookie === void 0 ? _setCookie : _ref3$setCookie,
        _ref3$userAgent = _ref3.userAgent,
        userAgent = _ref3$userAgent === void 0 ? _getCurrUserAgent() : _ref3$userAgent;

    var cookieStmt = "".concat(THEME_COOKIE, "=").concat(theme, ";path=").concat(path, ";domain=").concat(domain, ";max-age=").concat(duration);

    if (protocol === 'https:') {
      cookieStmt += ";Secure";
    }

    if (userAgent && typeof userAgent === 'string' && (0, _shouldSendSameSiteNone.isSameSiteNoneCompatible)(userAgent)) {
      cookieStmt += ";SameSite=None";
    }

    setCookie(cookieStmt);
    setThemeClass({
      theme: theme
    });
  }
  /**
   * Resets the current theme back to undefined
   * Mainly used for testing purposes
   */


  function resetTheme() {
    currentTheme = null;
  }
  /**
   * Private function to set the provided cookie on the document
   * @param {any} cookie the cookie w/ value to set
   */


  function _setCookie(cookie) {
    document.cookie = cookie;
  }
  /**
   * Returns the windows current protocol. Expected `https:`
   * @returns the windows protocol
   */


  function _getCurrProtocol() {
    if (window && window.location) {
      return window.location.protocol;
    }

    return undefined;
  }
  /**
   * Returns the users current browser agent
   * @returns the users current browser agent
   */


  function _getCurrUserAgent() {
    if (navigator && navigator.userAgent) {
      return navigator.userAgent;
    }

    return undefined;
  }
});