define("web-directory/components/entity-field-section/entity-contact-info/contact-info-group", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Object.extend({
    intl: Ember.inject.service(),
    permissions: Ember.inject.service(),
    key: null,
    field: null,
    section: null,
    fieldData: [],
    label: computed('key', function () {
      return this.get('intl').lookup("entity.fieldTypes.".concat(this.get('key'), ".name"));
    }),
    addNewLabel: computed('key', function () {
      return this.get('intl').lookup("entity.fieldTypes.".concat(this.get('key'), ".addNew"));
    }),
    isEmpty: computed('fieldData', 'fieldData.@each.isEmpty', 'field', function () {
      var emptyFields = this.get('fieldData').mapBy('isEmpty');
      return emptyFields && _lodash.default.reject(emptyFields).length === 0;
    }),
    containsRepeatable: computed('fieldData.[]', 'field', 'repeatableField', function () {
      var field = this.get('field') || this.get('repeatableField');

      if (field) {
        return field.get('repeatable');
      }

      return this.get('fieldData').filterBy('field.repeatable').length > 0;
    }),
    repeatableField: computed('fieldData.[]', 'key', 'section', function () {
      var key = this.get('key');
      return this.get('section.fieldList').filterBy('repeatable').find(function (field) {
        return field.get('key') === key || field.get('type') === key;
      });
    }),
    disableSave: computed('fieldData.[]', 'fieldData.@each.isValid', function () {
      var fieldData = this.get('fieldData');
      var validFields = fieldData.map(function (data) {
        var emptyCheck = ['weblink', 'email'];

        if (_lodash.default.includes(emptyCheck, data.get('field.type'))) {
          return data.get('isValid') && !data.get('isEmpty');
        }

        return data.get('isValid');
      });
      return _lodash.default.reject(validFields).length > 0;
    }),
    isPhone: computed.equal('key', 'phone'),
    isEmail: computed.equal('key', 'email'),
    isIm: computed.equal('key', 'im'),
    isAddress: computed.equal('key', 'address'),
    showHeadings: computed('isPhone', 'isEmail', 'permissions.canEditProfiles', function () {
      if (this.get('isEmail')) {
        return this.get('permissions.canEditProfiles');
      }

      return this.get('isPhone');
    })
  });

  _exports.default = _default;
});