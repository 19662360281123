define("web-directory/components/anchor-tag/component", ["exports", "ember-purecloud-components/utils/email"], function (_exports, _email) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AnchorTagComponent = Ember.Component.extend({
    tagName: 'span',
    intl: Ember.inject.service(),
    httpRegex: /(http|https):\/\/(?:www\.)?(.*)/i,
    // Anchor tag text body: <a>{{label}}</a>
    label: null,
    //href components: <a href="{{protocol & value}}">{{label}}</a>
    value: null,
    //email address, phone number, URL
    protocol: null,
    //protocol of the href in the case of mailto, tel, sms, etc.
    title: null,
    target: '_blank',
    init: function init() {
      this._super();

      this.decorateLabel();
    },
    text: Ember.computed('value', 'label', function () {
      var label = this.get('label');

      if (label) {
        return label;
      }

      var value = this.get('value'),
          protocol = this.get('protocol'); // If the value already has a protocol for http, gods be good lets not waste space showing it.

      if (value && value.match(this.get('httpRegex'))) {
        value = value.replace(this.get('httpRegex'), '$2');
      }

      if (protocol === 'map') {
        value = Ember.String.htmlSafe(Ember.Handlebars.Utils.escapeExpression(value).replace(/\n/g, '<br />'));
      }

      return value;
    }),
    anchor: Ember.computed('value', 'protocol', function () {
      var value = this.get('value'),
          protocol = this.get('protocol');

      if (protocol === 'map' && value) {
        this.set('target', '_new');
        return 'https://maps.google.com/?q=' + window.encodeURIComponent(value);
      } //mailto:, tel:, sms:


      if (protocol && value) {
        return "".concat(protocol, ":").concat(value);
      }

      if (value && value.match(this.get('httpRegex'))) {
        return value;
      } // No protocol assumes plain http:// unless the value already has it set.


      return "http://".concat(value);
    }),
    decorateLabel: Ember.observer('value', function () {
      var value = this.get('value'),
          protocol = this.get('protocol');

      if (protocol === 'mailto' && value) {
        this.set('target', null); // TODO: [Glimmer] fix this value issue

        value = value.toString();

        if (!(0, _email.isValidEmailAddress)(value)) {
          return this.set('label', this.get('intl').t('purecloud.acdInteraction.relate.validation.invalidEmail'));
        }

        this.set('label', value);
      }
    })
  });
  var _default = AnchorTagComponent;
  _exports.default = _default;
});