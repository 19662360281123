define("web-directory/components/entity-mini/default/component", ["exports", "web-directory/mixins/viewport"], function (_exports, _viewport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var run = Ember.run,
      Component = Ember.Component;

  var _default = Component.extend(_viewport.default, {
    classNameBindings: ['enteredViewport:in-viewport:outside-viewport', 'hasRequiredPermission::no-permission'],
    session: Ember.inject.service(),
    chat: Ember.inject.service(),
    presenceSubscription: Ember.inject.service(),
    entity: null,
    showDepartment: true,
    showPresence: true,
    showFavorite: true,
    showActions: true,
    clickToProfile: true,
    isSearchResult: false,
    requiredPermission: null,
    hasRequiredPermission: Ember.computed('requiredPermission.[]', 'entity.extends.authorization.permissions', function () {
      var requiredPermission = this.get('requiredPermission') || [];
      var perms = this.get('entity.expands.authorization.permissions') || [];

      if (requiredPermission.length && perms.length) {
        var agentHasOnQueue = perms.includes("routing:agent:onqueue");
        var agentHasRequiredPermission = !!requiredPermission.find(function (permission) {
          return perms.includes(permission);
        });
        return agentHasRequiredPermission || !agentHasOnQueue;
      }

      return true;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!Ember.testing) {
        run.join(this, this._subscribeToPresence);
      }
    },
    didEnterViewport: function didEnterViewport() {
      var _this = this;

      run(function () {
        return run.once(_this, _this._subscribeToPresence);
      });

      this._super.apply(this, arguments);
    },
    didExitViewport: function didExitViewport() {
      var _this2 = this;

      // A run loop is needed here because the mixin invokes this
      // function using request animation frame as opposed to Ember
      // backburner.
      run(function () {
        return run.once(_this2, _this2._unsubscribeFromPresence);
      });

      this._super.apply(this, arguments);
    },
    _subscribeToPresence: function _subscribeToPresence() {
      if (this.get('chat.enabled') && this.get('entity.type') === 'person' && this.get('entity.jid')) {
        this.get('presenceSubscription').autoSubscribe([this.get('entity')], this);
      }
    },
    _unsubscribeFromPresence: function _unsubscribeFromPresence() {
      this.get('presenceSubscription').autoSubscribe(null, this);
    }
  });

  _exports.default = _default;
});