define("web-directory/components/entity-fields/validators/boolean", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    isValid: function isValid(bool) {
      var value = bool.value;
      return value !== undefined && value !== null || value === '';
    },
    isEmpty: function isEmpty(bool) {
      var value = bool.value;
      return value === undefined || value === null || value === '';
    }
  };
  _exports.default = _default;
});