define("web-directory/components/analog-clock/component", ["exports", "web-directory/utils/dates"], function (_exports, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      inject = Ember.inject,
      computed = Ember.computed;

  var _default = Component.extend({
    classNames: 'analog-clock',
    classNameBindings: ['_analogSupported:analog-supported:analog-unsupported', '_invalidTime:invalid-time'],
    attributeBindings: ['_currTimeLabel:title', '_currTimeLabel:aria-label'],
    intl: inject.service(),
    // Attrs
    utcOffset: null,
    staticMoment: null,
    showSecondHand: true,
    autoIncrement: true,
    _analogSupported: Modernizr.csstransforms,
    _displayedMoment: null,
    _invalidTime: computed.not('_displayedMoment'),
    _currTimeLabel: null,
    _pendingTick: null,
    willRender: function willRender() {
      this._cancelTimers();

      var time = (0, _dates.default)(this.get('staticMoment') || undefined);
      var formatStr = 'LTS';
      var invalid = false;

      try {
        if (this.get('utcOffset')) {
          time.utcOffset(this.get('utcOffset'));
          formatStr += ' Z';
        }
      } catch (e) {
        invalid = true;
      }

      if (invalid || isNaN(time) || !time.isValid()) {
        this.set('_displayedMoment', null);
        this.set('_currTimeLabel', this.get('intl').t('components.analogClock.invalidTime'));
      } else {
        this.set('_displayedMoment', time);
        this.set('_currTimeLabel', time.format(formatStr));
      }

      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this._cancelTimers();

      this._super.apply(this, arguments);
    },
    didRender: function didRender() {
      var _this = this;

      this._super.apply(this, arguments);

      var time = this.get('_displayedMoment');

      if (time && this.get('_analogSupported')) {
        var hourElement = this.$('.hour-hand')[0];
        var minuteElement = this.$('.minute-hand')[0];
        var hour = time.hour(),
            minute = time.minute(),
            second = time.second(),
            secondAngle = second * 6,
            minuteAngle = minute * 6 + secondAngle / 60,
            hourAngle = hour % 12 / 12 * 360 + 90 + minute / 12,
            hourRule = "rotate(".concat(hourAngle, "deg)"),
            minuteRule = "rotate(".concat(minuteAngle, "deg)"),
            secondRule = "rotate(".concat(secondAngle, "deg)");
        hourElement.style.transform = hourRule;
        minuteElement.style.transform = minuteRule;

        if (this.get('showSecondHand')) {
          var secondElement = this.$('.second-hand')[0];
          secondElement.style.transform = secondRule;
        }
      }

      if (!this.get('staticMoment') && this.get('autoIncrement') && !this.get('_invalidTime')) {
        this.set('_pendingTick', Ember.run.later(this, function () {
          _this.rerender();
        }, 1000));
      }
    },
    _cancelTimers: function _cancelTimers() {
      var _this2 = this;

      ['_pendingTick'].forEach(function (timerName) {
        var currTimer = _this2.get(timerName);

        if (currTimer) {
          Ember.run.cancel(currTimer);

          _this2.set(timerName, null);
        }
      });
    }
  });

  _exports.default = _default;
});