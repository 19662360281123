define("web-directory/components/volt-frame-router/component", ["exports", "ember-purecloud-components/mixins/logger", "web-directory/components/volt-frame-router/template"], function (_exports, _logger, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      inject = Ember.inject,
      computed = Ember.computed;

  var _default = Component.extend(_logger.default, {
    classNames: ['volt-frame-router'],
    classNameBindings: ['activeRoom.showingVideo:visible:hidden'],
    layout: _template.default,
    actions: {},
    volt: inject.service(),
    route: computed.reads('volt.route'),
    didRender: function didRender() {
      this._super.apply(this, arguments);

      if (!this.get('hasInit')) {
        this._initializeFrameRoute();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('volt').cleanup();
    },
    _initializeFrameRoute: function _initializeFrameRoute() {
      var results = this.$('frame-router');

      if (!results || !results.length) {
        this.logger.info('Failed to find volt frame router');
        return;
      }

      this.get('volt').initialize(results[0]);
    }
  });

  _exports.default = _default;
});