define("web-directory/services/streaming", ["exports", "ember-purecloud-components/services/streaming"], function (_exports, _streaming) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _streaming.default;
    }
  });
});