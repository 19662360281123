define("web-directory/models/externalcontacts/channel-record", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // This is like a "union" type pf the various Relate domain messaging ID types (see the FacebookId, LineId, TwitterId
  // and WhatsApp domain classes in the Relate contact-service project).
  //
  // Also refer to the *_id models in this directory
  //
  // An Ember DS record of this type is built from the inbound message to help detect changes between the message's info, and
  // channel info from an external contact in Relate for the same channel.
  var _default = _emberData.default.Model.extend({
    type: _emberData.default.attr('string'),
    // Meta info about the channel
    channelIdProperty: _emberData.default.attr('string'),
    // Meta info about the domain class property name for the channel (e.g. "lineId" or "twitterId")
    isNewChannel: _emberData.default.attr('boolean'),
    // Meta info -- true if this is a new channel for an external contact
    changesIgnoredByAgent: _emberData.default.attr('boolean'),
    // Meta info -- true if the agent was prompted to accept or ignore detected channel info changes, and they elected to ignore
    agentRespondedToPrompt: _emberData.default.attr('boolean'),
    // Meta info -- true if the agent was prompted to accept or ignore detected channel info changes, and they responded
    userId: _emberData.default.attr('string'),
    // The ID of the user from the channel
    ids: _emberData.default.attr(),
    // An array of strings representing an array of user IDs for the channel (for Facebook or LINE)
    name: _emberData.default.attr('string'),
    // The name of the user (for Twitter)
    displayName: _emberData.default.attr('string'),
    // For display name of the user (for LINE, Facebook, and WhatsApp)
    screenName: _emberData.default.attr('string'),
    // The screen name (AKA handle) of the user (for Twitter)
    phoneNumber: _emberData.default.attr() // Includes e164: an e164-formatted phone number, and display: a display-friendly phone number (for Whatsapp),

  });

  _exports.default = _default;
});