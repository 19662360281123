define("web-directory/adapters/call-forwarding", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;
  var RESTAdapter = _emberData.default.RESTAdapter;

  var _default = RESTAdapter.extend({
    session: inject.service(),
    application: inject.service(),
    host: computed.reads('application.purecloudV2BaseUri'),
    namespace: 'api/v2',
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
      var method = 'PUT';

      if (snapshot.adapterOptions && snapshot.adapterOptions.patch) {
        method = 'PATCH';
      }

      return this.ajax(url, method, {
        data: data
      });
    },
    urlForFindRecord: function urlForFindRecord(id) {
      // the id provided should be the the user guid
      return "".concat(this.get('host'), "/").concat(this.get('namespace'), "/users/").concat(id, "/callforwarding");
    },
    urlForUpdateRecord: function urlForUpdateRecord(id) {
      return this.urlForFindRecord(id);
    },
    // Typically we wouldnt rewrite this private function, but we need to modify how errors are extracted
    // See: https://github.com/emberjs/data/issues/4934
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      var data = {};

      if (payload && status !== 200) {
        data.errors = [payload];
      } else {
        data = payload;
      }

      return this._super(status, headers, data, requestData);
    }
  });

  _exports.default = _default;
});