define("web-directory/components/entity-profile/profiles/profile", ["exports", "lodash", "web-directory/components/entity-profile/profiles/section-info"], function (_exports, _lodash, _sectionInfo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.BUILTIN_SECTIONS = _exports.ADMIN_SECTIONS = void 0;
  _exports.customSectionKeys = customSectionKeys;
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var inject = Ember.inject,
      computed = Ember.computed;
  var PROFILE_CACHE = {};
  var EXCLUDED_SECTIONS = ['status', 'general', 'primaryContactInfo', 'images', 'uploads', 'cic', 'scs', 'custserv', 'device', 'purecloud', 'preferences', 'externalContacts'];
  var BUILTIN_SECTIONS = ['general', 'contactInfo', 'skills', 'location', 'bio', 'education', 'hr'];
  _exports.BUILTIN_SECTIONS = BUILTIN_SECTIONS;
  var ADMIN_SECTIONS = ['relationships', 'agent'];
  _exports.ADMIN_SECTIONS = ADMIN_SECTIONS;

  function customSectionKeys(sections) {
    var builtins = [].concat(BUILTIN_SECTIONS, ADMIN_SECTIONS, EXCLUDED_SECTIONS);
    var keys = Ember.A(sections).mapBy('key');
    return keys.filter(function (key) {
      return !Ember.A(builtins).includes(key);
    });
  }

  var Profile = Ember.Object.extend({
    _cache: PROFILE_CACHE,
    session: inject.service('session'),
    permissions: inject.service('permissions'),
    model: null,
    readWritePermissions: null,
    writeableSections: null,
    readableSections: null,
    sections: computed.reads('model.fieldConfig.sections'),
    canEditProfiles: computed.reads('permissions.canEditProfiles'),
    canReadProfiles: computed.reads('permissions.canReadProfiles'),
    init: function init() {
      this._super.apply(this, arguments);

      var writeableSections = [];
      var customKeys = customSectionKeys(this.get('sections'));
      var readableSections = [].concat(BUILTIN_SECTIONS, ['relationships', 'groups'], _toConsumableArray(customKeys));

      if (this.get('model.id') === this.get('session.person.id')) {
        writeableSections = [].concat(BUILTIN_SECTIONS, _toConsumableArray(customKeys));
      }

      if (this.get('canEditProfiles')) {
        writeableSections = [].concat(BUILTIN_SECTIONS, _toConsumableArray(customKeys), ADMIN_SECTIONS);
      }

      if (this.get('canReadProfiles')) {
        readableSections = readableSections.concat(ADMIN_SECTIONS);
      }

      this.setProperties({
        readWritePermissions: {
          write: [],
          read: []
        },
        writeableSections: writeableSections,
        readableSections: readableSections
      });
    },
    profileSections: computed('session.person', 'model', 'readableSections.[]', function () {
      var _this = this;

      var data = [];
      var sections = this.get('sections');
      var isSuper = this.get('session.person.isAdmin') && !this.get('session.user.personId');

      if (isSuper) {
        data = _lodash.default.filter(sections, function (section) {
          return EXCLUDED_SECTIONS.indexOf(section.key) === -1;
        });
      } else {
        data = _lodash.default.filter(sections, function (section) {
          var readableSections = _this.get('readableSections');

          return EXCLUDED_SECTIONS.indexOf(section.key) === -1 && readableSections.indexOf(section.key) !== -1;
        });
      }

      return _lodash.default.map(data, function (section) {
        return _sectionInfo.default.create({
          section: section,
          entity: _this.get('model')
        });
      });
    }),
    canEditProfile: computed('writeableSections.[]', function () {
      return this.get('writeableSections.length') > 0;
    }),
    canEditProfilePictures: computed('model.id', 'session.person.id', 'canEditProfiles', function () {
      if (this.get('model.id') === this.get('session.person.id')) {
        return true;
      }

      return this.get('canEditProfiles');
    }),
    canEditSection: function canEditSection(section) {
      if (this.get('session.person.isAdmin') || this.get('canEditProfiles')) {
        return true;
      }

      var sections = this.get('writeableSections');
      return _lodash.default.includes(sections, section.key);
    },
    canReadSection: function canReadSection(section) {
      if (this.get('session.person.isAdmin') || this.get('canReadProfiles')) {
        return true;
      }

      var sections = this.get('readableSections');
      return _lodash.default.includes(sections, section.key);
    },
    getReadableFieldPathsForSection: function getReadableFieldPathsForSection(section) {
      return section.get('fieldList').mapBy('fieldPath');
    },
    getEditableFieldPathsForSection: function getEditableFieldPathsForSection(section) {
      return section.get('fieldList').mapBy('fieldPath');
    }
  });
  Profile.reopenClass({
    getProfileForModel: function getProfileForModel(model, owner) {
      var id = model.get('id');

      if (PROFILE_CACHE[id]) {
        return PROFILE_CACHE[id];
      }

      var profile = Profile.create({
        model: model
      }, owner.ownerInjection());
      PROFILE_CACHE[id] = profile;
      return profile;
    }
  });
  var _default = Profile;
  _exports.default = _default;
});