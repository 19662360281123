define("web-directory/components/acd-interactions-panel-v2/interaction-active-timer/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The value that will change the countdown timer's color to red to indicate to the user that the
   * timer is about up and they should prepare for an action.
   *
   * @note value is in seconds
   */
  var NearingEnd = 15;
  /**
   * Quick way to tack on the predefined translation key root.
   *
   * @param type the timer type in camel case to tack on to the end of the default translation
   */

  var TimerTranslationKey = function TimerTranslationKey(type) {
    return "purecloud.acdInteraction.timers.".concat(type);
  };

  var _default = Ember.Component.extend({
    classNames: ['interaction-active-timer', 'countdown-timer'],
    classNameBindings: ['activeTimer', 'activeTimer::hidden'],
    clock: Ember.inject.service('clock'),

    /**
     * this component will render as a `p` tag instead of a `div`
     */
    tagName: 'p',

    /**
     * @attribute
     *
     * The interaction object that this timer should calculate against
     */
    interaction: null,

    /**
     * @attribute
     *
     * The callback settings that dictate some of the timer logic
     */
    callbackMediaSettings: null,

    /**
     * @attribute
     *
     * `true` if the customer for the interaction has callback numbers associated with it
     */
    hasCallbackNumbers: false,

    /**
     * @attribute
     *
     * `true` if the customer for the interaction has multiple callback numbers associated with it
     */
    hasMultipleCallbackNumbers: false,

    /**
     * @attribute
     *
     * `true` if the callback is currently dialing
     */
    isCallbackDialing: false,

    /**
     * @attribute
     *
     * The id of the queue for the auto callback
     */
    autoCallbackQueueId: null,

    /**
     * Determines what the currently active timer is.
     * The timer checks need to be in priorty order. The priorty order is labelled at each
     * computed's comment section.
     */
    activeTimer: Ember.computed('showCallbackCountdown', 'showAutoCallbackEndCountdown', 'showAutoCallbackStartCountdown', function () {
      if (this.get('showCallbackCountdown')) return 'callback-timeout';
      if (this.get('showAutoCallbackEndCountdown')) return 'callback-auto-end';
      if (this.get('showAutoCallbackStartCountdown')) return 'callback-auto-start';
      return null;
    }),

    /**
     * data determined by whatever the active timer currently is. the priorty of the
     * active timer data is already being determined by the `activeTimer` logic. Due to
     * The data targeting computed values from within the class, we cannot abstract the
     * `const` data further outside of this context.
     */
    activeTimerData: Ember.computed('{activeTimer,autoCallbackStartTime,autoCallbackEndTime}', 'interaction.callbackTimeout', function () {
      var activeTimer = this.get('activeTimer');
      var interaction = this.get('interaction');
      if (!activeTimer || !interaction) return null;
      var data = {
        'callback-timeout': {
          text: TimerTranslationKey('callbackTimeout'),
          value: Ember.get(interaction, 'callbackTimeout')
        },
        'callback-auto-start': {
          text: TimerTranslationKey('callbackAutoStart'),
          value: this.get('autoCallbackStartTime')
        },
        'callback-auto-end': {
          text: TimerTranslationKey('callbackAutoEnd'),
          value: this.get('autoCallbackEndTime')
        }
      };
      return data[activeTimer];
    }),

    /**
     * `true` if the current active timer data value is nearing the end of it's countdown.
     * The value for "nearing the end" is supplied as a value at the top of the class.
     */
    countdownDurationClass: Ember.computed('activeTimerData.value', 'clock.second', function () {
      var value = ['interaction-active-timer-countdown'];
      var data = this.get('activeTimerData');

      if (data && data.value.diff((0, _moment.default)(), 'seconds') <= NearingEnd) {
        value.push('countdown-ending');
      }

      return value.join(' ');
    }),

    /**
     * `true` if the interaction is considered a dialer call
     */
    isDialerCall: Ember.computed('interaction', 'interaction.{dialerPreview,campaignId,participants}', 'interaction.participants.[]', function () {
      var interaction = this.get('interaction');
      if (!interaction) return false;
      if (Ember.get(interaction, 'dialerPreview') || Ember.get(interaction, 'campaignId')) return true;
      var participants = Ember.get(interaction, 'participants');
      if (!(participants !== null && participants !== void 0 && participants.length)) return false;
      return participants.some(function (p) {
        var _p$attributes;

        return p.dialerPreview || ((_p$attributes = p.attributes) === null || _p$attributes === void 0 ? void 0 : _p$attributes.dialerInteractionId);
      });
    }),

    /**
     * The time that the callback will automatically start the interaction
     * and transition it to a call.
     */
    autoCallbackStartTime: Ember.computed('callbackMediaSettings', 'interaction.callbackNumbers.[]', 'callbackMediaSettings.autoDialDelaySeconds', 'interaction.participantForCurrentUser.connectedTime', function () {
      return this.determineCallbackTime('callback-auto-start');
    }),

    /**
     * The time that the callback will automatically end after the interaction
     * has disconnected and remove it from the user's roster.
     */
    autoCallbackEndTime: Ember.computed('callbackMediaSettings', 'interaction.callbackNumbers.[]', 'callbackMediaSettings.autoEndDelaySeconds', 'interaction.participantForCurrentUser.wrapup.endTime', 'interaction.participantForCurrentUser.endAcwTime', function () {
      return this.determineCallbackTime('callback-auto-end');
    }),

    /**
     * `true` if the callback timeout countdown should be visible to the user
     *
     * @priority 1
     */
    showCallbackCountdown: Ember.computed('hasCallbackNumbers', 'interaction.{isCallback,callbackTimeoutTime,needsCallWrapUp}', function () {
      var interaction = this.get('interaction');
      if (!interaction) return false;
      return this.get('hasCallbackNumbers') && Ember.get(interaction, 'isCallback') && Ember.get(interaction, 'callbackTimeoutTime') && !Ember.get(interaction, 'needsCallWrapUp');
    }),

    /**
     * `true` if the auto callback start timer should be visible to the user.
     *
     * @priority 2
     */
    showAutoCallbackStartCountdown: Ember.computed('isAutoCallbackStartEligible', 'autoCallbackStartTime', 'isAutoStartDispositioned', 'isAutoEndDispositioned', function () {
      return this.get('isAutoCallbackStartEligible') && this.get('autoCallbackStartTime') && !this.get('isAutoStartDispositioned') && !this.get('isAutoEndDispositioned');
    }),

    /**
     * `true` if the auto end timer should be visible to the user
     *
     * @priority 3
     */
    showAutoCallbackEndCountdown: Ember.computed.and('isAutoCallbackEndEligible', 'isAutoEndDispositioned', 'autoCallbackEndTime'),

    /**
     * Determines if the auto callback start is eligible for the user. There are cases
     * that the user should not be rushed to auto start the callback allowing them to manually
     * start it instead.
     */
    isAutoCallbackStartEligible: Ember.computed('{isDialerCall,isCallbackDialing,hasCallbackNumbers,isAutoStartDispositioned}', 'callbackMediaSettings.enableAutoDialAndEnd', 'interaction.{hasVoicemail,isCallback,isConnected,isPlacingCall}', 'interaction.mostRecentTransfer.transferType', function () {
      var interaction = this.get('interaction');
      var callbackMediaSettings = this.get('callbackMediaSettings');
      if (!interaction || !callbackMediaSettings) return false;
      return this.get('hasCallbackNumbers') && !this.get('isDialerCall') && !this.get('isCallbackDialing') && !this.get('isAutoStartDispositioned') && Ember.get(callbackMediaSettings, 'enableAutoDialAndEnd') && Ember.get(interaction, 'isCallback') && Ember.get(interaction, 'mostRecentTransfer.transferType') !== 'unattended' && Ember.get(interaction, 'isConnected') && !Ember.get(interaction, 'isPlacingCall') && !Ember.get(interaction, 'hasVoicemail');
    }),

    /**
     * Determines if the auto callback end is eligible for the user. There are cases that
     * The user should not be rushed to auto finish the callback allowing them to manually
     * end it instead.
     */
    isAutoCallbackEndEligible: Ember.computed('{autoCallbackQueueId,isDialerCall,isAutoEndDispositioned,hasMultipleCallbackNumbers}', 'interaction.{hasVoicemail,isConnected,isCallback}', 'callbackMediaSettings.enableAutoDialAndEnd', function () {
      var interaction = this.get('interaction');
      var callbackMediaSettings = this.get('callbackMediaSettings');
      if (!interaction || !callbackMediaSettings) return false;
      return this.get('autoCallbackQueueId') && this.get('isAutoEndDispositioned') && !this.get('isDialerCall') && !this.get('hasMultipleCallbackNumbers') && Ember.get(callbackMediaSettings, 'enableAutoDialAndEnd') && Ember.get(interaction, 'isConnected') && Ember.get(interaction, 'isCallback') && !Ember.get(interaction, 'hasVoicemail');
    }),

    /**
     * `true` if the interaction is not able to be auto started based on the  wrapup state of the
     * interaction. For instance, if the interaction has a wrapup time, and the wrapup is either
     * expected or required, and the after call work has not been completed, then the auto logic
     * will not initiate. That way the user cannot bypass these requirements.
     */
    isAutoStartDispositioned: Ember.computed('interaction.calls.[]', 'interaction.calls.@each.disconnectedTime', 'interaction.{isAfterCallWorkCompleted,participantForCurrentUser}', 'interaction.participantForCurrentUser.{endAcwTime,wrapupExpected,wrapupRequired}', 'interaction.participantForCurrentUser.wrapup.endTime', function () {
      var _currentUser$calls;

      var interaction = this.get('interaction');
      if (!interaction) return false;
      var currentUser = Ember.get(interaction, 'participantForCurrentUser');
      if (!currentUser) return false;
      if (Ember.get(currentUser, 'wrapup.endTime') || (currentUser.wrapupExpected || currentUser.wrapupRequired) && Ember.get(interaction, 'isAfterCallWorkCompleted')) return true;
      return ((_currentUser$calls = currentUser.calls) !== null && _currentUser$calls !== void 0 ? _currentUser$calls : []).some(function (call) {
        return !!(call !== null && call !== void 0 && call.disconnectedTime);
      });
    }),

    /**
     * `true` if the interaction is not able to be auto ended based on the wrapup state of the interaction.
     * For instance, if the interaction wrapup is either expected or required, and the after call work has
     * not been completed, then the auto logic will not initiate. That way the user cannot bypass these requirements.
     */
    isAutoEndDispositioned: Ember.computed('interaction.calls.[]', 'interaction.calls.@each.disconnectedTime', 'interaction.{isAfterCallWorkCompleted,participantForCurrentUser}', 'interaction.participantForCurrentUser.{endAcwTime,wrapupExpected,wrapupRequired}', function () {
      var interaction = this.get('interaction');
      if (!interaction) return false;
      var currentUser = Ember.get(interaction, 'participantForCurrentUser');
      if (!currentUser) return false;
      return (currentUser.wrapupExpected || currentUser.wrapupRequired) && Ember.get(interaction, 'isAfterCallWorkCompleted');
    }),

    /**
     * Determines what the callback time is based on the type being provided.
     *
     * @todo replace Moment
     *
     * @param type the type of callback time to determine
     * @returns a `Moment` object if the value was determined, `null` if it wasn't
     */
    determineCallbackTime: function determineCallbackTime(type) {
      var callbackSettings = this.get('callbackMediaSettings');
      var interaction = this.get('interaction');

      if (callbackSettings && interaction) {
        var currentUser = Ember.get(interaction, 'participantForCurrentUser');
        var startTime = null;
        var gracePeriod = null;

        switch (type) {
          case 'callback-auto-end':
            startTime = Ember.get(currentUser, 'wrapup.endTime') || Ember.get(currentUser, 'endAcwTime');
            gracePeriod = normalizeTimerSeconds(Ember.get(callbackSettings, 'autoEndDelaySeconds'));
            break;

          case 'callback-auto-start':
            startTime = Ember.get(currentUser, 'connectedTime');
            gracePeriod = normalizeTimerSeconds(Ember.get(callbackSettings, 'autoDialDelaySeconds'));
            break;
        }

        if (this.get('hasCallbackNumbers') && startTime && gracePeriod) {
          return (0, _moment.default)(startTime).add(gracePeriod, 'seconds');
        }
      }

      return null;
    }
  });
  /**
   * Normalizes the provided value
   *
   * @param value the value to normalize
   * @returns the normalized seconds based on the value provided
   */


  _exports.default = _default;

  function normalizeTimerSeconds(value) {
    return value === 0 ? 1 : value !== null && value !== void 0 ? value : 30;
  }
});